import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Input from "../form_elements/Input";
import Tooltip from '../../../common/Tooltip'
import ResourcesService from "../../../service/ResourcesService";
import MultiSelectList from '../form_elements/MultiSelectList';

export default class PrimaryContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timeZones: []
        };

        this.resourceService = new ResourcesService();
        this.tooltipRef = React.createRef();
    }

    componentDidMount() {
        this.resourceService.getTimeZones()
            .then((timeZones) => this.setState({timeZones}));
    }

    render() {
        // depending on separateFirstLastName, we will either have primaryContactName
        // or primaryContactFirstName & LastName
        let primaryContactName = this.props.primaryContactName,
            primaryContactFirstName = this.props.primaryContactFirstName,
            primaryContactLastName = this.props.primaryContactLastName,
            primaryContactJobTitle = this.props.primaryContactJobTitle,
            primaryContactEmail = this.props.primaryContactEmail,
            primaryContactPhone = this.props.primaryContactPhone,
            primaryContactTimeZone = this.props.primaryContactTimeZone,
            descriptionText = this.props.isAdmin
                ? "The Primary Contact is who we will send the Service Direct Account Activation Invitation to." +
                " The Primary Contact's Email Address will be the Username to log in to mySD."
                : "The Primary Contact is who we will contact via phone or email if we need your help." +
                " The Primary Contact's Email Address is the Username to log in to mySD. Updating the email address" +
                " here will update the username as well.";

        return <div className="row padding-20-bottom">
            <div className="col alpha grid-2 empty"/>
            <div className="col grid-8 no-margin-mobile">
                <div className="module">
                    <div className="module__contentbox module__contentbox__no-header module__twothirdswidth">
                        <div className="spacing-40-bottom">
                            <p className="type-large-body type-heavy type-allcaps type-single-line no-margin-top spacing-10-bottom">
                                Primary Contact
                                <span> </span>
                                <Tooltip
                                    content={
                                        <>
                                            <span
                                                className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                Primary Contact
                                            </span>
                                            <span
                                                className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                The Primary Contact will be our point person during campaign production,
                                                and will be the Account Owner. You can adjust the Primary Contact at anytime
                                                in your Account Settings once your campaigns are launched.
                                            </span>
                                        </>
                                    }
                                    modalContainerRef={this.tooltipRef}
                                />
                            </p>
                            <p className="type-normal-body">
                                {descriptionText}
                            </p>
                        </div>
                        {this.props.separateFirstLastName
                            ? <div className="form__row spacing-30-bottom spacing-24-bottom-mobile">
                                <div className="form__cell form__cell__33 form__cell__50__mobile spacing-24-bottom-mobile">
                                    <Input
                                        id={primaryContactFirstName.element_id || ""}
                                        name="primary_contact_first_name"
                                        label="First Name *"
                                        type="text"
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={true}
                                        value={primaryContactFirstName.value}
                                        hasError={!!primaryContactFirstName.error}
                                        inputRef={primaryContactFirstName.ref}
                                    />
                                </div>
                                <div className="form__cell form__cell__33 form__cell__50__mobile spacing-24-bottom-mobile">
                                    <Input
                                        id={primaryContactLastName.element_id || ""}
                                        name="primary_contact_last_name"
                                        label="Last Name *"
                                        type="text"
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={true}
                                        value={primaryContactLastName.value}
                                        hasError={!!primaryContactLastName.error}
                                        inputRef={primaryContactLastName.ref}
                                    />
                                </div>
                                <div className="form__cell form__cell__33 form__cell__100__mobile">
                                    <Input
                                        id={primaryContactJobTitle.element_id || ""}
                                        name="primary_contact_job_title"
                                        label="Primary Contact Job Title *"
                                        type="text"
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={true}
                                        value={primaryContactJobTitle.value}
                                        hasError={!!primaryContactJobTitle.error}
                                        inputRef={primaryContactJobTitle.ref}
                                    />
                                </div>
                            </div>
                            : <div className="form__row spacing-30-bottom spacing-24-bottom-mobile">
                                <div
                                    className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                                    <Input
                                        id={primaryContactName.element_id || ""}
                                        name="primary_contact_name"
                                        label="Primary Contact Name *"
                                        type="text"
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={true}
                                        value={primaryContactName.value}
                                        hasError={!!primaryContactName.error}
                                        inputRef={primaryContactName.ref}
                                    />
                                </div>
                                <div className="form__cell form__cell__50 form__cell__100__mobile">
                                    <Input
                                        id={primaryContactJobTitle.element_id || ""}
                                        name="primary_contact_job_title"
                                        label="Primary Contact Job Title *"
                                        type="text"
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={true}
                                        value={primaryContactJobTitle.value}
                                        hasError={!!primaryContactJobTitle.error}
                                        inputRef={primaryContactJobTitle.ref}
                                    />
                                </div>
                            </div>
                        }
                        <div className="form__row spacing-30-bottom spacing-24-bottom-mobile">
                            <div className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                                <Input
                                    id={primaryContactEmail.element_id || ""}
                                    name="primary_contact_email"
                                    label="Primary Contact Email / Username"
                                    type="text"
                                    onChange={this.props.onChange}
                                    onBlur={this.props.onBlur}
                                    required={true}
                                    value={primaryContactEmail.value}
                                    hasError={!!primaryContactEmail.error}
                                    inputRef={primaryContactEmail.ref}
                                />
                            </div>
                            <div className="form__cell form__cell__50 form__cell__100__mobile">
                                <Input
                                    id={primaryContactPhone.element_id || ""}
                                    name="primary_contact_phone"
                                    label="Primary Contact Phone"
                                    type="phone"
                                    onChange={this.props.onChange}
                                    onBlur={this.props.onBlur}
                                    required={true}
                                    value={primaryContactPhone.value}
                                    hasError={!!primaryContactPhone.error}
                                    inputRef={primaryContactPhone.ref}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__cell form__cell__50 form__cell__100__mobile">
                                {/* <SelectList
                                    name="primary_contact_time_zone"
                                    label="Primary Contact Time Zone"
                                    onChange={this.props.onChange}
                                    setGroups={true}
                                    required={true}
                                    options={this.state.timeZones}
                                    value={primaryContactTimeZone.value}
                                    hasError={!!primaryContactTimeZone.error}
                                    inputRef={primaryContactTimeZone.ref}
                                /> */}
                                <MultiSelectList 
                                    required={true}
                                    setGroups={true}
                                    hasGroups={true}
                                    useTextFilter={true}
                                    name="primary_contact_time_zone"
                                    label="Primary Contact Time Zone"
                                    selections={[primaryContactTimeZone.value]}
                                    options={this.state.timeZones}
                                    // onChange={this.props.onChange}
                                    onChange={(vals) => this.props.updateInputProperty('primary_contact_time_zone', vals[0])}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col omega grid-2 empty"/>
            <div className="clear-block"/>
        </div>;
    }
}

PrimaryContactForm.propTypes = {
    separateFirstLastName: PropTypes.bool,

    // if separateFirstLastName is false, primaryContactName is required. If true, the other 2 are required.
    primaryContactName: PropTypes.object,
    primaryContactFirstName: PropTypes.object,
    primaryContactLastName: PropTypes.object,

    primaryContactJobTitle: PropTypes.object.isRequired,
    primaryContactEmail: PropTypes.object.isRequired,
    primaryContactPhone: PropTypes.object.isRequired,
    primaryContactTimeZone: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
};
