import React, { Component } from 'react';

import ImageUploadGrid from "../ImageUploadGrid";
import UploadFileButton from "../form_elements/UploadFileButton";
import Modal from "../../Modal";
import {del, get, post} from "../../../Requests";
import AreYouSureModal from "../AreYouSureModal";
import AuthService from "../../../service/AuthService";

export default class EditCompanyImages extends Component {
    contractorId;

    constructor(props) {
        super(props);

        this.contractorId = props.contractorId;

        this.state = {
            images: [],
            modalContent: undefined,
            modalHeader: undefined,
        };
    }

    componentDidMount() {
        this.getCompanyImages();
    }

    /**
     * on page load get the existing company gallery images
     **/
    getCompanyImages = () => {
        let url = `contractors/${this.contractorId}/images`;

        get(url)
            .then(resp => {
                if (resp && typeof resp.data === 'object') {
                    let images = resp.data.data.images;
                    if (images.length !== 0) {
                        this.setState({images: resp.data.data.images});
                    }
                }
            });
    };

    /**
     * update state with new modal
     */
    updateModalContent = (content, header) => {
        this.setState({modalContent: content, modalHeader: header});
    };

    uploadImages = (event) => {
        let images = event.target.files,
            imageIndex = this.state.images.length,
            imageClone = [...this.state.images],
            loadingImage = {
                'relative_path': 'loading.gif',
            };

        //console.log("Looping through images");
        for (let image in images) {
            if (images.hasOwnProperty(image)) {
                //console.log("image", image);
                this.postImage(images[image], imageIndex, imageClone);
                imageClone.push(loadingImage);
                this.setState({images: imageClone});
                imageIndex++;
            }
        }

        event.target.value = "";
    };

    /**
     * sets the loading gif and posts the image to the api
     */
    postImage = (file, imageIndex, imageClone) => {
        let url = `contractors/${this.contractorId}/images`,
            formData = new FormData();

        //console.log("multipart image time");
        formData.append("image", file);

        post(url, formData)
            .then( resp => {
                // Hack Alert: wait 2 seconds after the request comes back to give the background server task
                // on app2 a chance to copy the file to sd2, where as of Aug 2022 images are still hosted.
                // This timeout will no longer be needed if we host images on app2 or use an external Images service.
                // ~ RFM 2022
                setTimeout(() => {
                    if (resp && resp.status === 200 && typeof resp.data === "object") {
                        if (resp.data.images) {
                            imageClone.splice(imageIndex, 1, resp.data.images[0]);
                            this.setState({images: imageClone});
                        } else {
                            // reset the image to the previous image
                            //    if we don't get an image object back
                            imageClone.splice(imageIndex, 1);
                            this.setState({images: imageClone})
                        }
                    } else if (resp && resp.status === 400 && typeof resp.data === "object") {
                        let stateClone = {...this.state};
                        let errors = resp.data.errors;

                        if (errors) {
                            imageClone.splice(imageIndex, 1);

                            stateClone.images = imageClone;
                            this.setState(stateClone);
                        }
                    }
                }, 2000);
            });
    };

    showAreYouSureModal = (imageId, imageIndex) => {
        this.updateModalContent(
            <AreYouSureModal
                deleteImage={this.deleteImage}
                updateModalContent={this.updateModalContent}
                imageId={imageId}
                imageIndex={imageIndex}
            />, "Are you sure you want to delete the image?");
    };

    /**
     * Takes the image id and sends delete Method to server.
     *    removes the image from the state on success
     */
    deleteImage = (imageId, imageIndex) => {
        if (!imageId) {
            return;
        }

        let contractorId = AuthService.getItem("contractorId"),
            url = `contractors/${contractorId}/image/${imageId}`;

        del(url)
            .then(() => {
                let imageClone = [...this.state.images];
                imageClone.splice(imageIndex,1);
                this.setState({images: imageClone});
                this.updateModalContent();
            });
    };

    render() {
        return (
            <div>
                <Modal
                    header={this.state.modalHeader}
                    content={this.state.modalContent}
                    updateModalContent={this.updateModalContent}
                    size="extra-small"
                />
                <p className="type-normal-subhead type-heavy type-single-line spacing-10-bottom">
                    Company Images
                </p>
                <p className="type-normal-body spacing-10-bottom">
                    Please upload any additional images that we can use in your campaigns. The more photos the better.
                    They help us personalize and optimize your campaigns and
                    <span className="type-italics type-heavy"> have shown to improve conversion rates by as much as 60%. </span>
                </p>
                <p className="type-normal-body spacing-20-bottom">
                    Please upload any project completion photos, staff photos, coupon images, association graphics,
                    award certificate images, any image that would be used to advertise your business better.
                </p>
                <UploadFileButton
                    name="images"
                    onChange={this.uploadImages}
                    multiple={true}
                    bottomSpacing={this.state.images.length > 0}
                />
                <ImageUploadGrid
                    images={this.state.images}
                    showAreYouSureModal={this.showAreYouSureModal}
                    bottomSpacing={false}
                />
            </div>
        )
    }
}