import React from "react";
import DefaultSecondaryNav from "./DefaultSecondaryNav";

function DefaultLayout(props) {
  const { title, children, filterMessage, secondaryNavRef } = props;
  return (
    <div className="page-width-wide">
      <DefaultSecondaryNav
        filterMessage={filterMessage}
        secondaryNavRef={secondaryNavRef}
      />
      <div className="row padding-50-top padding-30-bottom">
        <div className="wide-format-col">
          <div className="simpleflex__row">
            <div className="simpleflex__cell">
              {title && <h2 className="type-normal-headline type-heavy padding-20-bottom">
                {title}
              </h2>}
            </div>
          </div>
        </div>
        <div className="clear-block" />
      </div>

      <div className="row">
        <div className="wide-format-col page__contentbox page__contentbox__invisible__mobile">
          {children}
          <div className="clear-block"/>
        </div>
      </div>
    </div>
  );
}

export default DefaultLayout;
