import React, { useEffect, useState } from 'react'
import Button from '../../../../common/components/form_elements/Button'
import LoadingGif from '../../../../common/components/LoadingGif'
import { FullColumn } from '../../../../common/layouts'
import { ContentRow, ActionsWrapper } from './styles'
import { getIntBetween } from '../../../../Util'
import { LoadingMessages } from '../../../../common/StaticValues'
import { ContentWindowScroll } from '../../../../common/Modal'

export default () => {
    const [phrase, setPhrase] = useState('Hang tight! Edits in progress.')

    useEffect(() => {
        function updatePhrase() {
            const nextPhrase =
                LoadingMessages[getIntBetween(0, LoadingMessages.length - 1)]
            setPhrase(nextPhrase)
        }
        const interval = setInterval(updatePhrase, 8000)
        return () => clearInterval(interval)
    }, [])

    return (
        <FullColumn>
            <ContentWindowScroll>
                <ContentRow>
                    <span className="type-normal-subhead">
                        This may take a few moments. Please do not close this tab or
                        window!
                    </span>
                </ContentRow>
                <ContentRow>
                    <span className="type-normal">{phrase}</span>
                </ContentRow>
                <ContentRow>
                    <LoadingGif backgroundColor="white" size="large" />
                </ContentRow>
                <ContentRow />
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button disabled={true} variant="text">
                    <span className="type-heavy">Cancel</span>
                </Button>
                <Button disabled={true}>Hang Tight</Button>
            </ActionsWrapper>
        </FullColumn>
    )
}
