import React, { useEffect, useRef } from 'react';

import AuthService from '../service/AuthService';
import OnboardingService from '../service/OnboardingService';

const HubspotScheduler = ({ wrapper, setScheduleRequested }) => {
	const onboardingService = useRef(new OnboardingService());
	
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
		script.async = true;
		
		const div = document.createElement('div');
		div.innerHTML = `<div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/ariel21/marketplace-onboarding-call-round-robin?embed=true"></div>`;
		
		const container = document.getElementById(wrapper);
		container.appendChild(script);
		container.appendChild(div);
		
		const registerMeetingBooking = (e) => {
			if (e.origin === 'https://meetings.hubspot.com' && e.data.meetingBookSucceeded) {
				setScheduleRequested(true);
				AuthService.requestedOnboarding = true;
				onboardingService.current.scheduleOnboarding(AuthService.getItem('contractorId')).then(() => console.log('booked meeting'));
				
				setTimeout(() => {
					window.location = '/onboarding/scheduled';
				}, 5000);
			}
		};
		
		window.addEventListener('message', registerMeetingBooking);
		
		return () => {
			container.innerHTML = "";
			window.removeEventListener('message', registerMeetingBooking);
		};
	}, [onboardingService, wrapper, setScheduleRequested]);
		
	return (
		<div id={`${wrapper}`}></div>
	);
};

export default HubspotScheduler;
