import React from 'react';

const Progress = (props) => {
    function getStepText() {
        let path = props.location;

        if (path.includes("account-setup")) {
            return "step-1";
        } else if (path.includes("your-company")) {
            return "step-2";
        } else if (path.includes("your-campaign")) {
            return "step-3";
        } else if (path.includes("approval")) {
            return "step-4";
        } else {
            return "step-5";
        }
    }

    let path = props.location;
    let stepText = getStepText();

    return ( 
        <div className="row padding-50-top padding-60-bottom">
            <div className="col alpha grid-2 empty"></div>
            <div className="col grid-8 no-margin-mobile">
                <p className="type-normal-subhead-special type-orange type-centered spacing-50-bottom">
                    <span className="type-force-newline-mobile">Join Our Exclusive </span><span className="type-force-newline-mobile">Network of Pros</span>
                </p>
                <div className="signup-progress__wrapper">
                    <div className={"signup-progress__timeline__wrapper signup-progress__timeline__wrapper__" + stepText}>
                        <div className="signup-progress__timeline__line"></div>
                        <div className="signup-progress__timeline__points">
                            <div 
                                className={"signup-progress__timeline__point " + (path.includes("account-setup") 
                                                ? "signup-progress__timeline__point__current" 
                                                : "") }>
                                <div className="signup-progress__timeline__point__bubble"></div>
                                <div className="signup-progress__timeline__point__label">
                                    <span className="type-large-body type-single-line">Account Setup</span>
                                </div>
                            </div>
                            <div 
                                className={"signup-progress__timeline__point " + (path.includes("your-company") 
                                    ? "signup-progress__timeline__point__current  qa-progress-campaign-setup"
                                    : "") }>
                                <div className="signup-progress__timeline__point__bubble"></div>
                                <div className="signup-progress__timeline__point__label">
                                    <span className="type-large-body type-single-line">Campaign Setup</span>
                                </div>
                            </div>
                            <div 
                                className={"signup-progress__timeline__point " + (path.includes("your-campaign") 
                                    ? "signup-progress__timeline__point__current" 
                                    : "") }
                            >
                                <div className="signup-progress__timeline__point__bubble"></div>
                                <div className="signup-progress__timeline__point__label">
                                    <span className="type-large-body type-single-line">Production</span>
                                </div>
                            </div>
                            <div className={"signup-progress__timeline__point " +
                            (path.includes("launch") ? "signup-progress__timeline__point__current" : "") }
                            >
                                <div className="signup-progress__timeline__point__bubble"></div>
                                <div className="signup-progress__timeline__point__label">
                                    <span className="type-large-body type-single-line">Launch</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col omega grid-2 empty"></div>
            <div className="clear-block"></div>
        </div>
    )
};

export default Progress;
