import React from 'react';

import MultiSelectList from '../components/form_elements/MultiSelectList';
import {CampaignTypes} from "../StaticValues";

export const BulkEditCampaignsStep1 = (props) => {
    let hasSelect = false
    let hasMarketplace = false
    const campaignsList = [];
    props.campaigns.forEach((campaign, index) => {
        // if the campaign is not selected, ignore it
        if (!props.selectedCampaignIds[campaign.campaign_id]) {
            return;
        }

        // find the types of campaigns
        if (campaign.type === CampaignTypes.MARKETPLACE) {
            hasMarketplace = true
        }
        else {
            hasSelect = true;
        }

        // collect the select campaigns names
        campaignsList.push(<span key={index} className="type-heavy type-force-newline">
            {typeof (campaign.company_name) !== 'undefined'
                ? <>{campaign.company_name} &mdash; </>
                : ''}
            {campaign.campaign_name}
            </span>)
    })

    const {editTypeOptions} = props
    const optionIndex = editTypeOptions.findIndex((option) => option.value === 'caller_id_option')

    // if both Marketplace and Select campaigns were selected, disable caller id
    if (hasSelect && hasMarketplace) {
        editTypeOptions[optionIndex].disabledText = '(Must edit Select or Marketplace separately)'
        editTypeOptions[optionIndex].disabled = true
    }
    else {
        delete editTypeOptions[optionIndex].disabledText
        editTypeOptions[optionIndex].disabled = false
    }

    return (
        <>
            <p className="type-large-body spacing-60-bottom-full spacing-60-bottom-tablet spacing-40-bottom-mobile">
                <span className="type-force-newline">You are Bulk Editing your Campaigns:</span>
                {campaignsList}
            </p>
            <p className="type-large-body type-heavy type-single-line type-bottom-stroke no-margin-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                SELECT AREA TO BULK EDIT
            </p>
            <div className="popup__form__row padding-120-bottom">
                <div
                    className="popup__form__cell popup__form__cell__60 popup__form__cell__100__mobile popup__form__cell__top-align">
                    <div>
                        <MultiSelectList
                            label="Edit Type"
                            selections={[props.editType || ""]}
                            name="edit_type"
                            onChange={props.selectEditType}
                            options={editTypeOptions}
                        />
                    </div>
                    {props.disableCpl &&
                        <p className="type-normal-body type-narrow-line-height spacing-10-top">
                            Cost Per Lead unavailable for Bulk Edit - multiple Service Categories selected.
                        </p>}
                </div>
                <div
                    className="popup__form__cell popup__form__cell__40 popup__form__cell__100__mobile popup__form__cell__top-align spacing-10-top-mobile">
                    <p className="type-normal-body type-narrow-line-height">
                      <span className="type-force-newline type-heavy">
                        Bulk Edit vs. Single Campaign Edit
                      </span>
                        If you’d like your updates to only affect one specific Campaign, then please use the update
                        link for that Campaign only.
                    </p>
                </div>
            </div>
        </>
    );
}