export const apiUrl = process.env.REACT_APP_API_URL;

export const imagePath = process.env.REACT_APP_IMAGE_PATH;

export const defaultClientUri = '/dashboard';

export default apiUrl;

//console.log("JT test change ...");

if (process.env.NODE_ENV === 'development') {
    // console.log("process.env =", process.env);
}

if (!('REACT_APP_API_URL' in process.env)) {
    console.log("WARNING - Your environment should have a REACT_APP_API_URL setting defined, but it does not. Have you generated .env.local by running the Ansible 'environment' task? If your development environment is on a Mac, you may need to manually create the file or copy it from a server.");
}

if (!('REACT_APP_IMAGE_PATH' in process.env)) {
    console.log("WARNING - Your environment should have a REACT_APP_IMAGE_PATH setting defined, but it does not. Please set it in /WebApp/.env.production.local or /WebApp/.env.development.local, depending if this is a dev server or a build.");
}