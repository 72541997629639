import React, {Component} from 'react';
import axios from "axios";
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react'

import {
    ButtonMailSvg,
    ButtonPlaySvg,
    IconFlagSvg,
    IconExclamationTriangleSvg,
    IconStarFilledSvg,
    IconCaretDownSvg,
    IconCaretUpSvg,
    IconEyeSvg,
    IconLeadReviewSvg,
} from "../Svgs";
import FlagLeadModal from '../../admin/lead_manager/FlagLeadModal';
import LeadDetail from './LeadDetail';
import CplEditor from './CplEditor';
import {
    formatCurrency,
    formattedTimeAndDate,
    formatSecondsToHourMinute, safeDate, getCampaignMenuLinks
} from "../../Util";
import { setUsersSubMenu } from '../../UtilJsx'
import AuthService from "../../service/AuthService";
import {errorHandler, post} from "../../Requests";
import {
    bookedAppointmentOptions,
    callAnsweredExplicitOptions,
    ContractorTypeIds,
    formRespondedExplicitOptions,
    jobWonOptions,
    textOfOption
} from "../StaticValues";
import {put} from "../../Requests";
import ContextualMenu from "../../common/ContextualMenu";
import {getClientMenuLinks} from "../../Util";
import UserService from "../../service/UserService";
import LeadService from "../../service/LeadService";
import type {ILead} from "../../Interfaces/ILead";

const formattedLeadOpenedDateString = (date) => {
    const dateString = date.toISOString().substring(0,10);

    const hour12TimeString = date.toLocaleTimeString('en-US');
    const hour24TimeString = date.toLocaleTimeString('en-US', { hour12: false });

    let timeString = hour24TimeString.substring(0, hour24TimeString.length - 3);
    if (timeString[0] === '0') {
        timeString = timeString.substring(1);
    }
    let amPm = hour12TimeString.substring(hour12TimeString.length - 2).toLowerCase();

    return `${dateString} ${timeString} ${amPm}`;
};

export default class LeadLogRow extends Component {
    constructor(props) {
        super(props);

        this.rowRef = React.createRef();

        this.state = {
            audioPlayerRef: undefined,
            autoPlayCallAudio: false,
            clientMenuLinks: []
        };

        this.cancelSignal = axios.CancelToken.source();
        this.userService = new UserService();
        this.leadService = new LeadService();
    }

    componentDidMount(): void {
        const {lead} = this.props;
        const clientMenuLinks = getClientMenuLinks(lead.contractor_id, lead.hubspot_company_id);
        this.setState({clientMenuLinks});
    }

    componentWillUnmount(): void {
        this.cancelSignal.cancel();
        this.userService.cancelSignal.cancel();
        this.leadService.cancelSignal.cancel();
    }

    getUsersLinks = (): void => {
        const {clientMenuLinks} = this.state;
        this.userService.getByAdmin(this.props.lead.contractor_id)
            .then((users) => {
                setUsersSubMenu(users, clientMenuLinks);
                this.setState({clientMenuLinks});
            });
    };

    addAudioPlayerRefToLogRow = (playerRef) => {
        this.setState({ audioPlayerRef: playerRef });
    };

    flagLead = (leadId, message, companyName) => {
        let url = "leads/" + leadId,
            component = this;

        put(url, { "flagged": 1, "flagged_message": message, "company_name": companyName })
            .then(resp => {
                component.props.getLead(leadId);
                component.props.updateModalContent();
            })
            .catch(errorHandler);
    };

    getUserNameForUserId = (id) => {
        const user = this.props.users.find(user => user.user_id == id);
        return user?.username || '';
    };

    handleCallRecordingPlayed = () => {
        if (AuthService.getItem('accessLevelName') === 'admin' || AuthService.getItem('accessLevelName') === 'superuser') {
            this.postCallRecordingPlayedNote('Support');
            this.props.updateLead(this.props.lead.lead_id, { update_admin_call_played: 1 });
        } else {
            this.postCallRecordingPlayedNote(AuthService.getItem('username'));
            this.props.updateLead(this.props.lead.lead_id, { opened_or_played: 1, update_call_played: 1 });
        }
    }

    handleRowClick = (index, autoPlayCallAudio) => {
        // If is_read == 0, call updateLead({is_read: 1}), otherwise call
        // getLead().  They both return the same lead representation, so either
        // call's response will contain a lead we can then write back into
        // LeadManagerView.state.  - mz 29 Jan 2020

        /* only calling the API when expanding the row */
        if (!this.props.expanded) {
            if (AuthService.getItem('accessLevelName') === 'admin' || AuthService.getItem('accessLevelName') === 'superuser') {
                if (this.props.lead.latest_admin_timestamp_opened_lead === null) {
                    this.postSupportOpenedLeadNote();
                    this.props.updateLead(this.props.lead.lead_id, { update_admin_opened_lead: 1 });
                } else {
                    this.props.getLead(this.props.lead.lead_id);
                }
            } else {
                if (this.props.lead.is_read == 0) {
                    if (this.props.lead.lead_type === 'form') {
                        this.props.updateLead(this.props.lead.lead_id, { is_read: 1, opened_or_played: 1, update_opened_lead: 1 });
                    } else {
                        this.props.updateLead(this.props.lead.lead_id, { is_read: 1, update_opened_lead: 1 });
                    }
                } else {
                    this.props.getLead(this.props.lead.lead_id);
                }
            }

            // Generate a new call insights form if lead is enrolled in the lead call insights program
            // and doesn’t have one yet.
            if (this.props.lead.enrolled_in_call_insights === '1' && this.props.lead.call_insights_started === '0') {
                this.startCallInsights();
            }
        }

        // console.log(this.props.handleRowExpansions);
        this.props.handleRowExpansions(this.props.index);

        this.setState({autoPlayCallAudio: !!autoPlayCallAudio});
    };

    isFlagged = () => {
        const lead = this.props.lead;

        if (lead.flag_resolved_timestamp) {
            // There hasn't been a new flag set since the last one was resolved
            return false;
        }

        if (lead.hubspot_ticket_id) {
            // There is a flag that has not been resolved
            return true;
        }

        // There has never been a flag set on this lead
        return false;
    };

    getReviewJsx = () => {
        const {under_review, is_valid} = this.props.lead;
        const baseClass = 'type-small-body type-ultra-narrow-line-height type-heavy-mobile type-normal-body-mobile';

        // under_review:
        // 0 - not reviewed
        // 1 - under review
        // 2 - reviewed done

        // currently under review
        if (under_review == 1) {
            return <span className={baseClass}>Under Review</span>;
        }

        // if not valid, present the non-billable tag
        if (is_valid == 0) {
            return <span className={`${baseClass} type-grey`}>Reviewed Non‑Billable</span>;
        }

        // otherwise (i.e. is valid) present the tag only if the lead was reviewed
        return under_review == 2
            ? <span className={baseClass}>Reviewed Billable</span>
            : null;
    };

    onFlagLeadClick = (e, leadId, contractorName) => {
        if (e.target.dataset.checked === 'true') {
            this.props.updateLead(leadId, {flagged: 0});
        } else {
            this.renderFlagLeadPopup(leadId, contractorName);
        }
    }

    onLeadReviewClick = (leadId) => {
        this.leadService.setLeadStatusToInReview(leadId)
            .then((lead_review_status) => this.props.updateLead(leadId, {lead_review_status}))
            .catch(errorHandler);
    };

    postCallRecordingPlayedNote = (username) => {
        let leadId = this.props.lead.lead_id,
            url = `leads/${leadId}/notes`,
            data = { note: `${username} played Call Recording`};

        post(url, data, this.cancelSignal.token)
            .then(() => this.props.getLead(leadId))
            .catch(errorHandler);
    };

    postSupportOpenedLeadNote = () => {
        let leadId = this.props.lead.lead_id,
            url = `leads/${leadId}/notes`,
            data = {note: `Support opened Lead`};

        post(url, data, this.cancelSignal.token)
            .catch(errorHandler);
    };

    renderFlagLeadPopup = (leadId, companyName) => {
        this.props.updateModalContent(<FlagLeadModal
                leadId={ leadId }
                companyName={ companyName }
                updateMessageBlocks={ this.props.updateMessageBlocks }
                flagLead={ this.flagLead }
            />,
            'Flag Lead',
            '',
            false
        );
    };

    startCallInsights = () => {
        let leadId = this.props.lead.lead_id,
            url = `leads/${leadId}/call_insights`,
            data = {};

        post(url, data, this.cancelSignal.token)
            .then(() => {
                this.props.getLead(leadId);
            })
            .catch(errorHandler);
    }

    updateLeadProgress = (values) => {
        let leadId = this.props.lead.lead_id;
        let leadProgress = {};

        if (!values) {
            values = {};
        }

        if ('followUp' in values) {
            leadProgress.needs_follow_up = values.followUp;
        }
        else {
            leadProgress.needs_follow_up = document.getElementById("needs_follow_up_" + leadId).dataset.checked;
        }

        // This checkbox will only exist on Form Lead rows
        // if (document.getElementById("responded_to_customer_" + leadId)) {
        //     leadProgress.responded_to_customer = document.getElementById("responded_to_customer_" + leadId).checked;
        // }

        this.props.updateLead(leadId, {
            lead_progress: leadProgress
        });
    };

    updateLeadErrorUrl = () =>
        this.props.updateLead(this.props.lead.lead_id,
            {call_file_url: false},
            null,
            false);

    render() {
        let lead: ILead = this.props.lead;
        let timestamp = lead.creation_timestamp;

        let expanded = this.props.expanded;

        let campaign = lead.campaign_name;
        let client = lead.contractor_name;
        if (lead.contractor_type_id == ContractorTypeIds.ExternalBuyer) {
            client = `${lead.contractor_type} - ${client}`;
        }
        let name = lead.name;
        let leadType = lead.lead_type;
        const isForm = leadType === 'form';
        let email = lead.email;
        let phone = lead.phone_number;
        let duration = isForm
            ? "--"
            : formatSecondsToHourMinute(lead.call_duration);
        let dateTime = formattedTimeAndDate(timestamp);

        let logTypeIcon = isForm
            ? ButtonMailSvg
            : ButtonPlaySvg;
        let leadOpenedOrPlayedMessage = undefined;
        if (lead.opened_or_played === '1') {
            if (leadType === 'form') {
                const openedLeadDateTime = safeDate(lead.latest_timestamp_opened_lead);
                leadOpenedOrPlayedMessage = `${formattedLeadOpenedDateString(openedLeadDateTime)} ${this.getUserNameForUserId(lead.latest_user_id_opened_lead)} opened Form Lead`;
            } else {
                const playedRecordingDateTime = safeDate(lead.latest_timestamp_played_recording);
                leadOpenedOrPlayedMessage = `${formattedLeadOpenedDateString(playedRecordingDateTime)} ${this.getUserNameForUserId(lead.latest_user_id_played_recording)} played Call Recording`;
            }
        }

        let clientRevenue = lead.client_revenue;
        let starIcon = parseInt(lead.starred, 10) ?
            <span className="leadlog__star__filled">{IconStarFilledSvg}</span>
            : IconStarFilledSvg;
        let isValid = Number(lead.is_valid);
        let ppl = isValid === 1
            ? formatCurrency(lead.tender, 2)
            : formatCurrency(0, 2);
        let reviewJsx = this.getReviewJsx();
        let is_read = lead.is_read;
        let index = this.props.index;

        let rowClass = "leadlog__lead-summary";
        if (isForm) {
            rowClass += ' leadlog__form-lead';
        }
        // let styles = {};

        let followUp = false;
        let leadProgressFlags = lead.lead_progress;
        let leadProgressColumnJsx = [];

        //
        // Lead Progress column values comprise of 2 parts.  This is the
        // first, which are of values the user has checked for lead progress.
        //
        if (leadProgressFlags.length) {
            for (let i = 0; i < leadProgressFlags.length; i++) {
                if (leadProgressFlags[i].string_key === "needs_follow_up") {
                    // 'Needs Follow-Up' sets visual indicators
                    followUp = true;
                    rowClass += " leadlog__follow-up ";
                } else {
                    // All other states add a text tag to the Lead Progress column
                    leadProgressColumnJsx.push(leadProgressFlags[i].short_label);
                }
            }
        }
        let flagIcon = this.isFlagged() ?
            <span className="leadlog__flag__filled">{IconFlagSvg}</span>
            : IconFlagSvg;
        let followUpIcon = followUp ?
            <span className="leadlog__star__filled">{IconExclamationTriangleSvg}</span>
            : IconExclamationTriangleSvg;
        let eyeIcon = IconEyeSvg;
        if (lead.enrolled_in_call_insights === '1' && lead.call_insights_complete !== '1') {
            eyeIcon = <span className="leadlog__call-insights__active">{ IconEyeSvg }</span>;
        } else if (lead.call_insights_complete === '1') {
            eyeIcon = <span className="leadlog__call-insights__complete">{ IconEyeSvg }</span>;
        }
        let leadReviewIconClass = '';
        switch (lead.lead_review_status) {
            case 'not in review':
                leadReviewIconClass = 'leadlog__lead-review__not-in-review';
                break;
            case 'in review':
                leadReviewIconClass = 'leadlog__lead-review__in-review';
                break;
            case 'in review submitted by client':
                leadReviewIconClass = 'leadlog__lead-review__client-review';
                break;
            case 'completed':
                leadReviewIconClass = 'leadlog__lead-review__completed-review';
                break;
            default:
                leadReviewIconClass = '';
                break;
        }
        let leadReviewIcon = <span className={ leadReviewIconClass }>{ IconLeadReviewSvg }</span>;

        if (lead.call_answered !== null) {
            leadProgressColumnJsx.push(
                textOfOption(
                    isForm
                        ? formRespondedExplicitOptions
                        : callAnsweredExplicitOptions,
                    lead.call_answered
                )
            );
        }

        if (lead.booked_appointment !== null) {
            leadProgressColumnJsx.push(textOfOption(bookedAppointmentOptions, lead.booked_appointment));
        }

        if (lead.job_won !== null) {
            leadProgressColumnJsx.push(textOfOption(jobWonOptions, lead.job_won));
        }

        // If no progress has been tracked and is a Billable Lead and is not Under Review and call answered status has not been set
        if (
            leadProgressColumnJsx.length === 0 &&
            isValid &&
            lead.under_review != 1 &&
            lead.client_revenue === null
        ) {
            leadProgressColumnJsx.push('Unmanaged');
        }

        if (is_read === "0" || is_read === null) {
            rowClass += " leadlog__new ";
        }
        if (expanded) {
            rowClass += " leadlog__expanded ";
        }

        if (!isValid) {
            rowClass += " type-grey-2"
        }

        let leadDetailsClass = this.props.expanded
            ? "leadlog__lead-controls leadlog__expanded"
            : "leadlog__lead-controls";

        let contractorNameColumnJsx = this.props.isParentContractor
            ? <td headers="log-company-name" className="leadlog__col leadlog__col__company_name">
                <span className="type-regular type-hide-full type-hide-tablet">Company Name: </span>
                {lead.contractor_name}
            </td>
            : null;
        let numCol = contractorNameColumnJsx ? 11 : 10;
        // account for columns that are only in the Admin view
        if (this.props.adminView) {
            numCol += 3;
        }

        const campaignMenuLinks = getCampaignMenuLinks({
            campaignId: lead.campaign_id,
            contractorId: lead.contractor_id,
            profileId: lead.profile_id,
            siteUrl: lead.site_url,
        })

        const leadProgressContent = leadProgressColumnJsx.length < 2
            ? null
            : (
                <ul className='tool-tip__content'>
                    {leadProgressColumnJsx.map((text) =>
                        <li key={text}>{text}</li>
                    )}
                </ul>
            )
        let leadProgressLabel = reviewJsx
        if (leadProgressColumnJsx.length >= 1) {
            let label = leadProgressColumnJsx[0]
            // abbreviate "appointment" to "appt"
            if (leadProgressColumnJsx[0]?.toLowerCase().includes('appointment')) {
                label = leadProgressColumnJsx[0]?.replace('ppointment', 'ppt')
            }
            // abbreviate "Responded to form" to "Responded"
            if (leadProgressColumnJsx[0]?.toLowerCase().includes('responded')) {
                label = 'Responded'
            }
            leadProgressLabel = <span className="type-small-body type-ultra-narrow-line-height">{label}</span>

            // if there are more than 1 lead progress status
            if (leadProgressColumnJsx.length > 1) {
                const isValid = Number(lead.is_valid);
                let moreClassNames = 'type-extra-small-body type-ultra-narrow-line-height type-grey-1'
                // if the lead is not valid, use the grey class to make the text grey
                if (isValid === 0) {
                    moreClassNames += ' type-grey-2'
                }
                leadProgressLabel = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className="type-small-body type-ultra-narrow-line-height">
                            {label}
                        </span>
                        <span className={moreClassNames}>
                            and {leadProgressColumnJsx.length - 1} more
                        </span>
                    </div>
                )
            }
        }

        return (
            <>
                <tr
                    className={rowClass}
                    // style={styles}
                    onClick={() => {
                        // Suppressing taps/clicks on the top part of the card in mobile view
                        // so that expansion is limited to 'View Details' link
                        // TODO: Confirm check against hard-coded window width is sufficient
                        const windowWidth = window.visualViewport ? window.visualViewport.width : window.innerWidth;
                        if (windowWidth > 640) {
                            this.handleRowClick(index);
                        }
                    }}
                    ref={this.rowRef}
                >
                    {this.props.adminView && (
                        <td headers="log-admin-icon" className="leadlog__col leadlog__col__icon">
                            {(lead.lead_review_status === 'not in review') ? (
                                <button
                                    className="button-clean"
                                    onClick={ (event) => {
                                        event.stopPropagation();

                                        this.onLeadReviewClick(lead.lead_id);
                                    }}
                                >
                                    { leadReviewIcon }
                                </button>
                            ) : (
                                <span className="button-clean">
                                    { leadReviewIcon }
                                </span>
                            )}

                            <button
                                className="button-clean"
                                onClick={ (event) => {
                                    event.stopPropagation();

                                    this.onFlagLeadClick(event, lead.lead_id, lead.contractor_name);
                                }}
                            >
                                { flagIcon }
                            </button>
                            <span className="button-clean">
                                {eyeIcon}
                            </span>
                        </td>
                    )}
                    <td headers="log-client-icon" className="leadlog__col leadlog__col__icon">
                        <button className={`button-clean ${(lead.opened_or_played === '0') ? 'highlight' : ''}`}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    const windowWidth = window.visualViewport
                                        ? window.visualViewport.width
                                        : window.innerWidth;
                                    if (windowWidth > 640) {
                                        this.handleRowClick(index, true);
                                    }
                                }}
                                title={leadOpenedOrPlayedMessage}
                        >
                            {logTypeIcon}
                        </button>
                        <button className="button-clean" title="Needs Follow-Up"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this.updateLeadProgress({followUp: !followUp});
                                }}
                        >
                            {followUpIcon}
                        </button>
                        <button className="button-clean" title="Starred"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this.props.updateLead(
                                        lead.lead_id,
                                        {starred: lead.starred == 1 ? 0 : 1}
                                    );
                                }}
                        >
                            {starIcon}
                        </button>
                    </td>
                    <td headers="log-datetime" className="leadlog__col leadlog__col__datetime"
                        title={'Time Zone: ' + AuthService.getItem('timeZone')}>
                        <span
                            className="type-force-newline type-small-body type-single-line spacing-5-bottom">
                            {dateTime.time}
                            <span className="type-extra-small-body">{dateTime.amPm}</span>
                        </span>
                        <span
                            className="type-force-newline type-extra-small-body type-single-line">{dateTime.date}</span>
                    </td>
                    <td headers="log-progress" className="leadlog__col leadlog__col__progress">
                        <span className="type-force-newline">
                            
                            {/*  Desktop view - use the tippy menu and compact display of lead progress */}
                            <span className="type-regular type-hide-mobile">
                                <Tippy
                                    placement="right"
                                    appendTo={() => document.body}
                                    content={leadProgressContent}
                                    duration={0}
                                    interactive={true}
                                    allowHTML={true}
                                    theme="sd-tooltip"
                                    zIndex={1000}
                                    disabled={!!!leadProgressContent}
                                >
                                    <span>{leadProgressLabel}</span>
                                </Tippy>
                            </span>

                            {/* Mobile view - use the preexisting  display style */}
                            <span className="type-regular type-hide-full type-hide-tablet">Progress: </span>
                            <span className="type-regular type-hide-full type-hide-tablet leadlog__lead-progress-value">
                                {leadProgressColumnJsx.map(
                                    (text, index, self) => {
                                        let classes = "type-small-body type-ultra-narrow-line-height type-heavy-mobile type-normal-body-mobile";
                                        if (index < self.length - 1) {
                                            classes += " spacing-5-bottom";
                                        }

                                        return (
                                            <span
                                                className={ classes }
                                                key={`lead_progress_${index}`}
                                            >
                                                {text}
                                            </span>
                                        );
                                    }
                                )}
                                {reviewJsx &&
                                <span className="type-extra-small-body type-ultra-narrow-line-height type-heavy-mobile type-normal-body-mobile">
                                    {reviewJsx}
                                </span>}
                            </span>
                        </span>
                    </td>
                    {contractorNameColumnJsx}
                    <td headers="log-campaign" className="leadlog__col leadlog__col__campaign">
                        <span className="type-regular type-hide-full type-hide-tablet">Campaign: </span>
                        <span className="type-small-body type-ultra-narrow-line-height type-heavy-mobile type-normal-body-mobile">
                            {this.props.adminView
                                ? <ContextualMenu
                                    linkLabel={campaign}
                                    menuLinks={campaignMenuLinks}
                                    scrollboxRef={ this.props.scrollboxRef }
                                />
                                : campaign}
                        </span>
                    </td>
                    {this.props.adminView && (
                        <td headers="log-client" className="leadlog__col leadlog__col__client">
                            <span className="type-regular type-hide-full type-hide-tablet">Client: </span>
                            <span className="type-small-body type-ultra-narrow-line-height">
                                <ContextualMenu
                                    linkLabel={ client }
                                    menuLinks={ this.state.clientMenuLinks }
                                    onFirstOpen={this.getUsersLinks}
                                    scrollboxRef={ this.props.scrollboxRef }
                                />
                            </span>
                        </td>
                    )}
                    <td headers="log-id" className="leadlog__col leadlog__col__id">
                        <span className="type-small-body type-ultra-narrow-line-height type-heavy-mobile type-normal-body-mobile">
                            {name}
                        </span>
                    </td>
                    <td headers="log-contact" className="leadlog__col leadlog__col__contact type-heavy-mobile type-normal-body-mobile">
                        <span className="type-small-body type-force-newline type-narrow-line-height type-heavy-mobile type-normal-body-mobile">
                            {email}
                        </span>
                        <span className="type-small-body type-force-newline type-narrow-line-height">
                            <a className="ui-hide-full type-heavy" href={"tel:" + phone}>{phone}</a>
                            <span className="ui-hide-mobile ui-hide-tablet type-heavy-mobile type-normal-body-mobile">{phone}</span>
                        </span>
                    </td>
                    <td headers="log-duration" className="leadlog__col leadlog__col__duration">
                        <span className="type-regular type-hide-full type-hide-tablet">Duration: </span>
                        <span className="type-small-body type-ultra-narrow-line-height type-heavy-mobile type-normal-body-mobile">
                            {duration}
                        </span>
                    </td>
                    <td headers="log-cost" className="leadlog__col leadlog__col__cost">
                        <span className="type-regular type-hide-full type-hide-tablet">Cost: </span>
                        <span className="type-small-body type-ultra-narrow-line-height type-heavy-mobile type-normal-body-mobile">
                            {(this.props.adminView && isValid) ? (
                                <CplEditor
                                    label={ppl}
                                    tender={lead.tender}
                                    updateCpl={val => {
                                        this.props.updateLead(lead.lead_id, { tender: val });
                                    }}
                                />
                            ) : ppl}
                        </span>
                    </td>
                    <td headers="log-revenue" className={"leadlog__col leadlog__col__revenue"
                            + (this.props.adminView ? " admin_view" : "")}>
                        <span className="type-regular type-hide-full type-hide-tablet">Revenue: </span>
                        <span className="type-small-body type-ultra-narrow-line-height type-heavy-mobile type-normal-body-mobile">
                            {clientRevenue !== null ? formatCurrency(clientRevenue, 2) : "--"}
                        </span>
                    </td>
                    {this.props.adminView && <>
                        <td headers="log-payout-outcome" className="leadlog__col leadlog__col__payout_outcome">
                            <span className="type-regular type-hide-full type-hide-tablet">Payout Outcome: </span>
                            <span className="type-small-body type-ultra-narrow-line-height type-heavy-mobile type-normal-body-mobile">
                                {lead.affiliate_payout_outcome}
                            </span>
                        </td>
                        <td headers="log-payout" className="leadlog__col leadlog__col__payout">
                            <span className="type-regular type-hide-full type-hide-tablet">Payout: </span>
                            <span className="type-small-body type-ultra-narrow-line-height type-heavy-mobile type-normal-body-mobile">
                                {lead.marketplace_cost !== null
                                    ? formatCurrency(lead.marketplace_cost, 2)
                                    : "--"}
                            </span>
                        </td>
                    </>}
                    <td className="leadlog__col leadlog__col__details-action ui-hide-full ui-hide-tablet">
                        <p className="leadlog__lead-controls__show-link type-small-body type-single-line type-align-right spacing-12-top-mobile">
                            <span role="button"
                                  className="type-heavy type-inline-icon type-blue"
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      this.handleRowClick(index);
                                  }}
                            >
                                {IconCaretDownSvg}
                                View Details
                            </span>
                        </p>
                    </td>
                </tr>
                <tr id={"lead-controls-" + lead.lead_id}
                    className={leadDetailsClass}
                >
                    <td colSpan={numCol}>
                        {this.props.expanded &&
                        <LeadDetail
                            updateLead={this.props.updateLead}
                            updateClassification={this.props.updateClassification}
                            updateNote={this.props.updateNote}
                            updateCallInsights={this.props.updateCallInsights}
                            getLead={this.props.getLead}
                            isExpanded={this.props.expanded}
                            loading={this.props.loading}
                            autoPlayCallAudio={this.state.autoPlayCallAudio}
                            lead={{...lead}}
                            industryRevenueAverage={this.props.industryRevenueAverage}
                            index={index}
                            addAudioPlayerRefToLogRow={this.addAudioPlayerRefToLogRow}
                            addLeadMessagePlayer={this.props.addLeadMessagePlayer}
                            stopAllLeadMessagePlayers={this.props.stopAllLeadMessagePlayers}
                            updateModalContent={this.props.updateModalContent}
                            updateMessageBlocks={this.props.updateMessageBlocks}
                            lastLeadChangeTimestamp={this.props.lastLeadChangeTimestamp}
                            updateLeadProgress={this.updateLeadProgress}
                            updateLeadErrorUrl={this.updateLeadErrorUrl}
                            handleCallRecordingPlayed={this.handleCallRecordingPlayed}
                            getUserNameForUserId={this.getUserNameForUserId}
                            adminView={this.props.adminView}
                            leadStatusOptions={this.props.leadStatusOptions}
                            startCallInsights={this.startCallInsights}
                            isFlagged={this.isFlagged}
                            audioPlayerRef={this.state.audioPlayerRef}
                            renderFlagLeadPopup={this.renderFlagLeadPopup}
                            onFlagLeadClick={this.onFlagLeadClick}
                            onLeadReviewClick={this.onLeadReviewClick}
                        />}
                    </td>
                    <td className="ui-hide-full ui-hide-tablet">
                        <div className="leadlog__lead-controls__hide-link type-small-body type-single-line type-align-right spacing-24-top-mobile">
                            <span role="button"
                                  className="type-heavy type-inline-icon type-blue"
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      this.handleRowClick(index);
                                      this.rowRef.current.scrollIntoView();
                                  }}
                            >
                                {IconCaretUpSvg}
                                Hide Details
                            </span>
                        </div>
                    </td>
                </tr>
            </>
        )
    }
}

LeadLogRow.propTypes = {
    leads: PropTypes.object,
    index: PropTypes.number,
    updateLead: PropTypes.func,
};
