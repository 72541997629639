import React, { Component } from 'react';

import BulkEditCampaignsStep2Footer from './BulkEditCampaignsStep2Footer';
import { MoneyInput } from '../components/form_elements/MoneyInput';
import {CampaignTypes} from "../StaticValues";

export default class BulkEditCampaignsStep2CostPerLead extends Component {
    render() {
        let min = null;
        let max = null;
        let isMarketplace;

        if (Object.keys(this.props.selectedCampaignIds).length == 1) {
            let selectedCampaign = this.props.campaigns.find((campaign) => this.props.selectedCampaignIds[campaign.campaign_id]);
            isMarketplace = selectedCampaign.type == CampaignTypes.MARKETPLACE;
        }

        if (this.props.cplIndustry) {
            if (this.props.cplIndustry.min_suggested_cpl) {
                min = isMarketplace
                    ? this.props.cplIndustry.min_suggested_cpl_mp
                    : this.props.cplIndustry.min_suggested_cpl;
            }
            if (this.props.cplIndustry.max_suggested_cpl) {
                max = isMarketplace
                    ? this.props.cplIndustry.max_suggested_cpl_mp
                    : this.props.cplIndustry.max_suggested_cpl;
            }
        }

        //
        // Create the list of campaigns along with their current setting
        //
        let campaignsList = this.props.campaigns.map((campaign, idx) => {
            if (campaign.campaign_id in this.props.selectedCampaignIds) {
                let valueTxt = this.props.getOptionValueTxt('cost_per_lead', campaign.tender[0].price_per_lead);
                return <div key={idx} className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    <span className="type-large-body type-force-newline type-heavy">
                        {typeof(campaign.company_name) !== 'undefined'
                            ? <>{campaign.company_name} &mdash; </>
                            : ''}
                        {campaign.campaign_name}
                    </span>
                    <span className="type-normal-body type-force-newline">Cost Per Lead: { valueTxt }</span>
                </div>
            } else {
                return null;
            }
        });

        return (
        <>
            <div className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                <p className="type-large-body">
                    You are Bulk Editing the <b>Cost Per Lead (CPL)</b> for the Campaigns listed below.
                </p>
                <p className="type-normal-body">
                    Their current CPLs are noted individually, and if you choose a new CPL, all Campaigns will be updated to that.
                </p>
            </div>
            <div className="spacing-60-bottom-full spacing-60-bottom-tablet spacing-40-bottom-mobile">
                {campaignsList}
            </div>

            <p className="type-large-body type-heavy type-single-line type-bottom-stroke no-margin-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                NEW SETTINGS (Replaces current settings)
            </p>

            {/* This markup is redundant with ./CostPerLeadForm */}
            <>
                <div className="popup__form__row spacing-10-bottom">
                    <div className="popup__form__cell popup__form__cell__label popup__form__cell__40 popup__form__cell__100__mobile">
                        <label htmlFor="popup-cpl-cpl" className="type-small-body type-heavy type-narrow-line-height no-margin">
                            Cost Per Lead
                        </label>
                    </div>
                </div>
                <div className="popup__form__row spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                    <div className="popup__form__cell popup__form__cell__40 popup__form__cell__100__mobile spacing-10-bottom-mobile">
                        <MoneyInput
                            id="popup-cpl-cpl"
                            name="cpl"
                            type="number"
                            defaultValue={this.props.formData.cost_per_lead}
                            onChange={(e) => this.props.updateFormData('cost_per_lead', e.target.value)}
                        />
                    </div>
                    {(min && max ?
                    <div className="popup__form__cell popup__form__cell__60 popup__form__cell__100__mobile">
                        <p className="type-small-body no-margin">
                            Based on your business type and location, we recommend a Cost Per Lead of <b>${min}-{max}</b>.
                        </p>
                    </div> : '')}
                </div>
            </>
            {/* End of ./CostPerLeadForm markup */}

            <BulkEditCampaignsStep2Footer
                commitSettings={ this.props.commitSettings }
                updateModalContent={ this.props.updateModalContent }
                updateModalHeader={ this.props.updateModalHeader }
                confirmationHeader="Cost Per Lead Confirmation"
            />
        </>
        );
    }
}