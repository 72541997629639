import React, {Component} from 'react';
import axios from 'axios';

import {get, put} from '../../Requests';
import RecentCampaigns from './RecentCampaigns';
import CampaignService from "../../service/CampaignService";
import {errorHandler} from '../../Requests';
import {parseMonetaryAmount} from "../../common/components/form_elements/MoneyInput";

export default class DashboardCampaignsSnapshot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            enabledCampaigns: [],
            pausedCampaigns: [],
        };

        this.campaignService = new CampaignService();

        this.cancelSignal = axios.CancelToken.source();
    }

    componentDidMount() {
        this.getCampaigns();
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
        this.campaignService.cancelSignal.cancel();
    }

    /**
     * Gets campaigns for this contractor
     */
    getCampaigns = () => {
        get("campaigns", this.cancelSignal.token)
            .then((resp) => {
                let campaigns = resp.data.data.campaigns;

                if (campaigns.length > 0) {
                    const enabledCampaigns = campaigns
                        .filter(campaign => campaign.is_paused_to_client == 0)
                        .sort((a, b) => {
                            if (Number(a.count_of_leads_last_30_days) > b.count_of_leads_last_30_days) {
                                return -1;
                            }
                            if (Number(a.count_of_leads_last_30_days) < b.count_of_leads_last_30_days) {
                                return 1;
                            }

                            return 0;
                        })
                        .slice(0, 4);

                    /* display a minimum of one paused campaign if available, *
                     * or up to 5 minus the number of enabled campaigns       */
                    const pausedCampaigns = campaigns
                        .filter(campaign => campaign.is_paused_to_client == 1)
                        .slice(0, 5 - enabledCampaigns.length);

                    this.setState({
                        enabledCampaigns: enabledCampaigns,
                        pausedCampaigns: pausedCampaigns
                    });
                }
            })
            .catch(errorHandler);
    };

    /**
     * submitPutStatus function
     *
     * Passed in as a prop to CampaignRow component.
     *
     * @param desiredStatus - string, either "paused" or "enabled"
     * @param modalState - state of the UpdateStatus*Modal
     * @param {object} campaign - the target campaign
     * @param callback - will Always be called upon request completion,
     *                   regardless of success or failure
     * @param validationOnly - bool, optional - when true, send validation_only=1
     *                         to the endpoint, causing the validation to run but
     *                         the actual status change not to. Used for the initial
     *                         submit of a Pause Request, in which we need to see the
     *                         error messages but don't want to make the actual change
     *                         until user confirms via the confirmation popup.
     */
    submitPutStatus = (desiredStatus, modalState, campaign, callback, validationOnly) => {
        this.campaignService.updateStatus(
            this.props.updateMessageBlocks,
            campaign,
            desiredStatus,
            modalState,
            callback,
            validationOnly
        )
            .then((campaign) => {
                if (!campaign) {
                    return;
                }

                this.props.updateModalContent();
                this.getCampaigns();

                // TODO: handle updating the screenshot similarly done in CampaignManagerView.reloadScreenshot
            })
            .catch(errorHandler);
    };

    /**
     * Passed in as a prop to CampaignRow component.
     *
     * @param modalState
     * @param key
     * @param callback - will Always be called upon request completion,
     *                   regardless of success or failure
     */
    submitCplChange = (modalState, key, callback) => {
        let modalStateCopy = {...modalState};
        let campaign = modalStateCopy.campaign;
        let url = `profiles/${campaign.profile_id}`;

        //
        // Remove '$' dollar sign and none numeric characters other than "."
        //
        let cpl = parseMonetaryAmount(modalStateCopy.inputs.cpl.value, true);

        //
        // Format the cpl payload as expected by the profile processor
        //
        let data = {
            data: {
                tender: cpl
            }
        };

        //
        // Clear message blocks at beginning of request
        // This way, if they submit twice and receive the same error message,
        // they will see the the message disappear and re-appear, so they will know
        // that the re-submit was attempted.
        //
        this.props.updateMessageBlocks([], 'success');

        put(url, data, this.cancelSignal.token)
            .then((response) => {
                //
                // Call a function the caller passed to be executed
                // upon request completion, e.g. re-enabling submit button
                //
                if (typeof callback === 'function') {
                    callback();
                }
                //
                // In general, the axios interceptor should catch 400/404 errors and they should never get here.
                // But in this case they are getting through, so early return here. ~ RFM 2020-03-27
                //
                if (response.status >= 400) {
                    return;
                }

                let stateClone = {campaigns: [...this.state.enabledCampaigns]};

                // now that we have had a successful request,
                // use that formatted data that we sent to the api
                // to update this campaign's data in the same format
                // that we originally got from the database via this->getCampaignData()
                campaign.tender[0] = {"price_per_lead": cpl};

                stateClone.campaigns[key] = campaign;
                this.props.updateModalContent();
                this.setState(stateClone);

                //
                // Show success message
                //
                this.props.updateMessageBlocks(['Cost per lead successfully updated.'], 'success');
            })
            .catch(errorHandler);
    };

    render() {
        return <>
            <div className="module__header module__halfwidth">
                <h3 className="type-large-subhead type-single-line">
                    Campaigns Manager Snapshot
                </h3>
            </div>
            <div className="module__contentbox module__halfwidth">

                {this.state.enabledCampaigns.length > 0 &&
                <>
                    <p className="type-normal-subhead type-narrow-line-height type-heavy no-margin-top spacing-30-bottom">
                            <span className="type-allcaps type-force-newline">
                                Enabled Campaigns
                            </span>
                        <span className="type-normal-body type-normal type-force-newline">
                                Ordered by Most Leads Last 30 Days
                            </span>
                    </p>

                    <div className="no-margin-top spacing-50-bottom">
                        <RecentCampaigns
                            campaigns={this.state.enabledCampaigns}
                            updateModalContent={this.props.updateModalContent}
                            submitCplChange={this.submitCplChange}
                        />
                    </div>
                </>}

                {this.state.pausedCampaigns.length > 0 &&
                <>
                    <p className="type-normal-subhead type-heavy type-allcaps type-alert no-margin-top spacing-30-bottom">
                        Paused Campaigns
                    </p>

                    <div className="no-margin-top spacing-30-bottom">
                        <RecentCampaigns
                            campaigns={this.state.pausedCampaigns}
                            updateModalContent={this.props.updateModalContent}
                            modalContainerRef={this.props.modalContainerRef}
                            submitCplChange={this.submitCplChange}
                            submitPutStatus={
                                (status, state, index, callback) =>
                                    this.submitPutStatus(status, state, this.state.pausedCampaigns[index], callback)
                            }
                        />
                    </div>
                </>}

                <p className="type-align-right spacing-30-top no-margin-bottom type-centered-mobile">
                    <a href="/campaigns" className="button ui-normal-button">
                        View All Campaigns
                    </a>
                </p>
            </div>
        </>;
    }
}