import React, { useState } from 'react';

import { IconCaretUpSvg, IconCaretDownSvg } from '../common/Svgs';

const ExpandableSection = (props) => {
	const [ expanded, setExpanded ] = useState(false);
	
	return (
		<div className="onboarding-section onboarding-expandable-section">
			<div className="onboarding-expandable-section__header">
				<h3 className="header">
					<span className="headline type-large-subhead">{props.header}</span>
					{(props.subhead && (
						<span className="subhead type-large-body">{props.subhead}</span>
					))}
				</h3>
				<button
					onClick={(e) => setExpanded(!expanded)}
				>
					<div className="actions type-normal-body">
						<span className="label">
							{expanded ? 'Collapse' : 'Expand'}
							<span className="icon">
								{expanded ? IconCaretUpSvg : IconCaretDownSvg }
							</span>
						</span>
					</div>
				</button>
			</div>
			<div className={`onboarding-expandable-section__content onboarding-section__content ${expanded ? 'expanded' : ''}`}>
				{props.children}
			</div>
		</div>
	);
};

export default ExpandableSection;