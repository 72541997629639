import React from 'react';

import {formatCurrency} from "../../../Util";
import {PhoneLeadsSvg, PencilSvg} from "../../../common/Svgs";
import CostPerLeadModal from "../../../common/CostPerLeadModal";
import Tooltip from "../../../common/Tooltip";
import {useModal} from "../../../common/ModalContext";
import {CampaignTypes} from "../../../common/StaticValues";

const PhoneLeads = (props) => {
    const {campaign} = props;
    const updateModalContent = useModal();
    const isMarketplace = campaign.type === CampaignTypes.MARKETPLACE;

    const title = (props.formsEnabled && !isMarketplace)
        ? 'Phone and Form Leads'
        : 'Phone Leads';

    const openCostPerLeadModal = () => {
        updateModalContent({
            content: <CostPerLeadModal
                campaign={campaign}
                campaignIndex={props.campaignIndex}
                submitCplChange={props.submitCplChange}
            />,
            header: `Edit Cost Per Lead for ${campaign.campaign_name}`,
            flatBottom: true
        });
    };

    const getTender = () =>
        campaign?.tender[0]?.price_per_lead;

    return <div>
        <div className="type-grey inline-icon inline-icon__28">
            {PhoneLeadsSvg}
        </div>

        <div className="campaigns-table__segment-header">
            <span className="type-normal-subhead type-heavy">
                {title}{' '}
                <Tooltip
                    position="right"
                    content={<>
                    <span
                        className="type-large-body type-force-newline type-heavy type-narrow-line-height spacing-10-bottom">
                        {title} Settings
                    </span>
                        <span className="type-small-body type-narrow-line-height">
                            {isMarketplace
                                ? <>You can specify the Cost Per Lead (CPL) for the Phone Call Leads for this Campaign.
                                    You are able to set a different Phone Call CPL per Campaign.</>
                                : <>You can specify the Cost Per Lead (CPL) for this Campaign.
                                    You are able to set a different CPL per Campaign.
                                    <a target={"_blank"} href={"https://support.servicedirect.com/select/adjust-cost-per-lead"}> Learn more about Cost Per Lead</a></>}
                        </span>
                    </>}
                />
            </span>
            {campaign.status !== 'canceled' &&
            <button className="button-clean type-blue type-small-body type-heavy"
                    onClick={openCostPerLeadModal}>
                <span className="inline-icon inline-icon__16 inline-icon__16__10">
                    {PencilSvg}
                </span>
                Edit
            </button>}
        </div>

        <p className="type-normal-body type-narrow-line-height">
            <span className="type-heavy">{formatCurrency(getTender())}</span>{' '}
            Cost Per Lead
        </p>
    </div>;
};

export default PhoneLeads;
