// import React from 'react';
import ReactDOM from "react-dom";

import {appInput} from "../formHelpers";

export default class Validator {

    /**
     * A copy of a view's local state.
     * @type object
     */
    stateClone = {};

    /**
     * A copy of a view's local state.inputs object, created when setStateClone
     * is called.
     * @type object
     */
    inputsClone = {};

    /**
     * An array of errors found while
     * @type Array
     */
    errors = [];

    /**
     * Setter for state clone.  One aspect of this class is that it needs its
     * state clone updated when making @todo figure out if the getter is really needed since stateClone is a
     * reference.  It's modified but eventually written back to, so the
     * reference should have access to all changes?
     */
    setStateClone(stateClone) {
        this.stateClone = stateClone;
        this.inputsClone = {...this.stateClone.inputs};
    }

    /**
     * Returns the stateClone provided to a Validator instance via setStateClone().
     * @returns {{}}
     */

    getStateClone() {
        return this.stateClone;
    }

    /**
     * Searches a single stateInput and stores any new instances of
     * appInput() into inputsFound.
     * @param stateInput The state.input property to recurse into.  E.g. state.inputs.service_categories.
     * We're just searching that single object, not it's siblings.
     * @param inputsFound The array reference that stores inputs that are found.
     */

    getInputObjRecursive = (stateInput, inputsFound) => {

        // if we received a stateInput that's already expressed as an
        // appInput(), then push it onto inputsFound and return immediately ...
        if (this.isAppInput(stateInput)) {
            inputsFound.push(stateInput);
            return;
        }

        // ... otherwise recurse into it, based on whether we're dealing w/an
        // object or array.  As an example, state.input.service_categories
        // is an array, but when we pass stateInput[i] recursively, the method
        // receives an object, b/c stateInput[i] (which we're passing) is this:
        //
        // {industry_id: 2, service_area: "abc 123", etc. }
        //
        if (Array.isArray(stateInput)) {
            for(let i = 0; i < stateInput.length; i++){
                this.getInputObjRecursive(stateInput[i], inputsFound);
            }
        }
        // typeof detects an object easily, but also reports null as an object,
        // so we need to filter that out w/a! !== null check.
        else if (typeof stateInput === 'object' && stateInput !== null) {

            for(var key in stateInput){
                this.getInputObjRecursive(stateInput[key], inputsFound);
            }
        }
    }

    /**
     * Acts on the class instance this.stateClone and returns a 1-d array of all
     * appInput()s it finds.
     * @returns {Array}
     */
    getStateInputsToValidate = () => {
        let stateInputsToValidate = [];

        for (let stateInputKey in this.inputsClone) {

            // @todo: we may not need this, given that subsequent code is
            //  filtering inputs it validates on appInput().
            // this is special case code in place just to prevent this validation
            // from possibly iterating over other inputs this logic isn't
            // ready for.

            // @todo Here, we should just be able to call this.getInputObjRecursive()
            // to get all input objects.  That'd do away w/needing both of the
            // following two conditional blocks.
            //
            if (this.isAppInput(this.inputsClone[stateInputKey])) {
                stateInputsToValidate.push(this.inputsClone[stateInputKey]);
                continue;
            }

            if (Array.isArray(this.inputsClone[stateInputKey])) {

                let recursiveInputs = [];
                this.getInputObjRecursive(this.inputsClone[stateInputKey], recursiveInputs);

                //
                // @todo we can eventually pass stateInputsToValidate to
                // getInputObjRecursive().  I'm using a separate array to assist
                // debugging for now.
                //
                recursiveInputs.forEach((element) => {
                    stateInputsToValidate.push(element);
                });
            }
        }

        return stateInputsToValidate;

    }

    /**
     * This method is typically use as an event handler for form inputs.  The
     * accepting of stateClone as a parameter could eventually change in favor
     * of setting stateClone on an instance of this class instead.  It's
     * accepted as a parameter only b/c this method's older counterpart that
     * existed outside this class accepted it.
     *
     * @param {Object} stateClone - A copy of a view's local state that this
     * method needs and updates when it finds an error with a field input.
     * @param {Event} event
     * @returns {Object}
     */
    updateStateCloneWithError = (stateClone, event) => {
        let elem = event.target,
            value = elem.value;

        let stateInputsToValidate = this.getStateInputsToValidate(),
            inputObject = null;

        for (let i = 0; i < stateInputsToValidate.length; i++) {
            inputObject = stateInputsToValidate[i];

            if (inputObject.element_id == elem.id) {
                break;
            }
        }

        if (inputObject.value != value) {
            inputObject.changed = true;
        }

        inputObject.error = this.inputValidation(elem);

        // It's not obvious, but stateClone has been updated by way of
        // references maintained in stateInputsToValidate.
        return stateClone;
    };

    /**
     * Validates all inputs indicated through a component's local state.inputs.
     * @returns {boolean}
     */
    validateAllInputs = (whiteListedElementIds) => {
        whiteListedElementIds = whiteListedElementIds || [];

        let error,
            stateInputsToValidate = this.getStateInputsToValidate(),
            validationPassed = true;

        // at this point all our inputs in state.inputs that are expressed as
        // an appInput() have been found. Now it's much simpler to iterate
        // over them.
        for (let i = 0; i < stateInputsToValidate.length; i++) {

            let inputObject = stateInputsToValidate[i],
                elementId = inputObject.element_id;

            let domElement = document.getElementById(elementId);

            if (!domElement) {
                continue;
            }

            if (whiteListedElementIds.indexOf(elementId) !== -1) {
                continue;
            }

            // @todo: I believe the below write back to stateClone only works
            // b/c references to nested state.inputs remain intact.  We should
            // document that somewhere in this class; it's the kind of thing
            // that isn't obvious and will cost someone big time if they're not
            // aware of it.  E.g. another spread operation might be all that's
            // needed to rid of the assumed references.
            error = this.inputValidation(domElement);

            if (error !== "" && validationPassed === true) {
                validationPassed = false;
            }

            inputObject.error = error;
            this.stateClone.inputs = this.inputsClone;
        }

        return validationPassed;
    }

    /**
     * Accepts an object intended to be an appInput(), but may not be, and
     * returns whether or not it is.  Assists recursive searches through a
     * component's local state.inputs array.
     * @returns {boolean}
     */
    isAppInput = (stateInput) => {

        if (typeof stateInput !== 'object' || stateInput == null) {
            return false;
        }

        let inputObj = appInput(),
            inputObjKeys = Object.keys(inputObj).sort(),
            stateInputbKeys = Object.keys(stateInput).sort();

        stateInputbKeys = stateInputbKeys.join("");
        inputObjKeys = inputObjKeys.join("");

        return inputObjKeys === stateInputbKeys;
    }

    recursionLevel = 0;

    /**
     * Accepts a state.inputs array , or an element in that array, and returns
     * the Accepts an object intended to be an appInput(), but may not be, and
     * returns whether or not it is.  Assists recursive searches through a
     * component's local state.inputs array.
     * @returns {boolean}
     */
    getInputObj = (stateInput) => {

        if(this.isAppInput(stateInput)) {
            return stateInput;
        }

        //
        // This isn't an inputObj, so recurse until we find one, or return null.
        //
        for (let key in stateInput) {
            if(stateInput[key] === undefined)
            {
                continue;
            }

            this.recursionLevel++;

            if (this.recursionLevel > 8) {
                this.recursionLevel = 0;
                return null;
            }

            return this.getInputObj(stateInput[key]);
        }

        return null;
    }

    /**
     * Triggers error UI and scrolls to error
     * @returns {boolean}
     */
    displayUserErrors = () => {
        let stateInputsToValidate = this.getStateInputsToValidate(),
            highestRefPositionTop = 0,
            highestRef = null,
            domNode = null,
            domNodePosition = null;

        for (let i = 0; i < stateInputsToValidate.length; i++) {
            let inputObject = stateInputsToValidate[i];

            if (inputObject?.ref?.current != null && inputObject.error !== "") {

                domNode = ReactDOM.findDOMNode(inputObject.ref.current);
                domNodePosition = domNode.getBoundingClientRect();

                if(highestRefPositionTop > domNodePosition.top)
                {
                    highestRefPositionTop = domNodePosition.top;
                    highestRef = inputObject.ref;
                }
            }
        }


        //This loop is needed b/c service areas don't exist in state.inputs
        //(which were iterated above), but they still need error handling.
        let stateClone = [];

        for (let i in stateClone.service_categories) {
            let sc = stateClone.service_categories[i];
            if (sc?.service_area?.error !== "") {

                domNode = ReactDOM.findDOMNode(stateClone.service_categories[i].service_area.ref.current);
                domNodePosition = domNode.getBoundingClientRect();

                if(highestRefPositionTop > domNodePosition.top)
                {
                    highestRefPositionTop = domNodePosition.top;
                    highestRef = stateClone.service_categories[i].service_area.ref;
                }
            }
        }

        this.jumpToError(highestRef);
    };

    /**
     * Accepts a form input element and returns an error indication from the
     * inputs validity object.
     * @param {Object} elem - An html form input element.
     * @return {string}
     */
    inputValidation = (elem) => {
        let validity = elem.validity;

        // replace _ with a space and capitalize the first letter
        //    let displayName = name.replace(/_/ig," ");
        //    displayName = displayName.charAt(0).toUpperCase() + displayName.slice(1);

        // if valid return empty string
        if (validity.valid) {
            return "";
        }
        else if (validity.patternMismatch) {
            //return  displayName + " is not the right format.";
            return "Incorrect format";
        }
        else if (validity.valueMissing) {
            // return empty string.  We are already displaying it with "*"
            //    But we still want to trigger the truthiness of this error
            //   return displayName + " is required.";
            return " ";
        }
        else if (validity.badInput) {
            //return displayName
            return "Must be Number";
        }
    }

    /**
     * Jumps to a place on the dom using a React.ref
     * @param {React.Ref} ref
     * @param {string} scrollTo - One of center, end, nearest, start.
     */
    jumpToError = (ref, scrollTo) => {
        if (!ref) { return }
        let block = scrollTo ? scrollTo : "center";

        const domNode = ReactDOM.findDOMNode(ref.current);
        domNode.scrollIntoView({
            behavior: "smooth",
            block: block,
            inline: "start"
        });
    };

    /**
     * Updates errors received from an api response and scrolls to the relevent
     * field ref.
     * @param {Object} stateClone - A copy of a view's local state object.
     * @param {Object} response - An api response.
     * @param extraRefs ???  @todo - Not sure what this is used for yet.
     */
    updateErrorsAndScroll = (stateClone, response, extraRefs) => {
        if (response.data.errors) {
            let errors = response.data.errors;
            let ref = this.setApiErrorsOnStateClone(errors, stateClone, extraRefs);
            // update the messages for the message block
            this.updateApiErrorMessages(errors, "error", stateClone);
            // this.setState(stateClone);
            this.jumpToError(ref);
            return stateClone;
        }
    };

    /**
     * Updates errors received from an api response.
     * @param {Array} errors - An array of api error messages.
     * @param {type} type - @todo I'm not sure what this param is used for.
     * @param {Object} stateClone - A copy of a view's local state object.
     */
    updateApiErrorMessages = (errors, type, stateClone) => {
        let apiMessageClone = {...stateClone.apiErrorMessage};
        let errorsArray = [];
        let urlsArray = [];

        for(let errorIndex in errors) {
            errorsArray.push(errors[errorIndex].message);
            urlsArray.push(errors[errorIndex].urls);
        }

        apiMessageClone.messages = errorsArray;
        apiMessageClone.urls = urlsArray;

        apiMessageClone.type = type;
        stateClone.apiErrorMessage = apiMessageClone;

        return stateClone;
    };

    /**
     * Updates errors received from an api response onto a clone of a view's
     * local state object.  Currently this method appears to handle two
     * representations of an error response: one where we simply pass back an
     * error message, and another where we pass back additional error details
     * through a param_details object.  Eventually, the api will return all
     * error messages in the format of the latter.
     * @param {Array} errors - An array of api error messages.
     * @param {type} type - @todo I'm not sure what this param is used for.
     * @param {Object} stateClone - A copy of a view's local state object.
     */
    setApiErrorsOnStateClone = (errors, stateClone, extraRefs) => {
        let ref,
            inputsClone = { ...stateClone.inputs };

        for(let errorIndex in errors) {
            let error = errors[errorIndex];

            /**
             * If this is a special case in the extraRefs
             *      Just grab that ref and go with it
             **/
            if (extraRefs && !error.parameter_details) {
                ref = extraRefs[error.parameter];

                if (ref) {
                    return ref;
                }
            }

            // handle if we have param details.  Note that we may note have
            // error.parameter set, and that's expected in some cases (such
            // as a BrainTree error on the server that produces a response
            // that's received here - such an error may not tie back to one
            // particular form input).
            if (error.parameter) {
                let parameter = error.parameter;
                let paramDetails = error.parameter_details;
                // if we have parameter details handle differently
                if (paramDetails) {
                    let groupFieldName = paramDetails.key.field,
                        groupFieldValue = paramDetails.key.value !== undefined ? paramDetails.key.value : "",
                        groupClone = [...inputsClone[parameter]],
                        fieldWithError = paramDetails.field,
                        inputToUpdate;

                    for(let i in groupClone) {
                        if (typeof groupClone[i][groupFieldName] === 'object'
                            && groupClone[i][groupFieldName].value === groupFieldValue) {
                            inputToUpdate = {...groupClone[i] };
                            //
                            // error is intended to be an error message.  It's set
                            // to a single space here just so that it's seen as a
                            // truthy value when we set the hasError property on a
                            // component.  For context, this is the statement that
                            // sets the hasError property on a component:
                            //
                            // hasError = !!error
                            //
                            inputToUpdate[fieldWithError].error = " ";
                            ref = ref ? ref : inputToUpdate[fieldWithError].ref;
                            groupClone[i] = inputToUpdate;
                        } else if (groupClone[i].value === groupFieldValue) {
                            if (groupFieldName == i) {
                                inputToUpdate = {...groupClone[i]};
                                inputToUpdate.error = " ";
                                ref = ref ? ref : inputToUpdate.ref;
                                groupClone[i] = inputToUpdate;
                            }
                        }
                    }

                    // stateClone[parameter] = groupClone;
                    inputsClone[parameter] = groupClone;
                    stateClone.inputs = inputsClone;
                }
                else {
                    let fieldName = error.parameter;
                    // if we don't have a specific field skip this
                    if (inputsClone[fieldName]) {
                        inputsClone[fieldName].error = " ";
                        ref = ref || inputsClone[fieldName].ref;
                    }
                    stateClone.inputs = inputsClone;
                }
            }
        }
        return ref;
    };

    /**
     * validates a given email address
     * @param {string} email
     * @return {boolean} - true iff the email address is valid
     */
    static validateEmail(email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(String(email).toLowerCase());
    }

    /**
     * normalizes a phone number by removing any non-numeric characters as well as (+1) for US country code
     * e.g. "+1 (512) 555-1234" => "5125551234"
     * @param {string} number
     * @return {string}
     */
    static normalizeNumber(number) {
        // remove US country code
        if (number.indexOf('+1') === 0) {
            number = number.substr(2);
        }

        return number.replace(/[^\d]+/g, '');
    }

    /**
     * checks if a given number is valid
     * @param {string} number
     * @return {boolean}
     */
    static validatePhoneNumber(number) {
        number = this.normalizeNumber(number);
        const pattern = /^[2-9][\d]{2}[2-9][\d]{2}[\d]{4}$/;
        return pattern.test(number);
    }

    /**
     * validates a full name (e.g. Peter Griffin)
     * @param {string} name
     * @return {boolean} true iff the name is valid
     */
    static validateFullName(name: string) {
        return /\w+ +\w+/.test(name);
    }

    /**
     * validates the Luhn algorithm
     * @param {string} cardNumber
     * @return {boolean}
     */
    static validateLuhn(cardNumber: string) {
        let nCheck = 0,
            bEven = false,
            index,
            nDigit;

        for (index = cardNumber.length - 1; index >= 0; index--) {
            nDigit = Number(cardNumber[index]);
            if (bEven && (nDigit *= 2) > 9) {
                nDigit -= 9
            }

            nCheck += nDigit;
            bEven = !bEven;
        }

        return nCheck % 10 == 0;
    }

    /**
     * validates a credit card number
     * @param {string} cardNumber - the card's number
     * @return {boolean} returns true if the card is valid
     */
    static validateCreditCard(cardNumber:string) {
        if (/[^\d-\s]+/.test(cardNumber)) {
            return false;
        }

        // remove spaces and dashes
        cardNumber = cardNumber.replace(/\D/g, '');

        if (cardNumber.length < 13 || 16 < cardNumber.length) {
            return false;
        }

        return this.validateLuhn(cardNumber);
    }

    /**
     * validates a card expiration date and format (e.g. "08/22")
     * @param {string} expiration
     * @return {boolean}
     */
    static validateCardExpiration(expiration:string):boolean {
        let parts = expiration.split('/');

        // validate structure integrity (MM & YY)
        if (!/^\d{2}$/.test(parts[0]) || !/^\d{2}$/.test(parts[1])) {
            return false;
        }

        let now = new Date();
        let cardDate = new Date();
        cardDate.setFullYear(`20${parts[1]}`, parts[0] - 1, 1);

        return now.getTime() < cardDate.getTime();
    }

    /**
     * validates a cvv
     * @param {string} cvv
     * @return {boolean}
     */
    static validateCvv(cvv) {
        return cvv.trim().length === 3;
    }

    /**
     * validates a given URL
     * @param {string} url
     * @return {boolean} - true iff the URL is valid
     */
    static validateUrl(url:string):boolean {
        return /^((https?:)?\/\/)?(www\.)?(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\b([-\w@:%+.~#?&/=]*)$/i.test(url);
    }
}
