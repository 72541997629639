import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class CentralizedBillingNote extends Component {
    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="row padding-50-bottom">
                <div className="wide-format-col">
                    <div className="module">
                        <div className="module__header module__fullwidth">
                            <h3 className="type-large-subhead type-single-line">
                                Centralized Billing
                            </h3>
                        </div>
                        <div className="module__contentbox module__fullwidth">
                            <p className="type-large-body spacing-20-top spacing-20-bottom">
                                Your billing is covered by a Centralized Billing arrangement. You do not need to manage
                                it here.
                            </p>
                            <p className="type-large-body spacing-20-top spacing-20-bottom">
                                For questions about billing, contact {this.props.partnership.white_label_name}.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="clear-block"></div>
            </div>
        );
    }
}

CentralizedBillingNote.protoTypes = {
    partnership: PropTypes.object
};
