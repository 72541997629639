import React from 'react';

import Tooltip from "../../../common/Tooltip";
import AuthService from "../../../service/AuthService";

const BillableLeadBlock = (props) => {
    let tooltipRef = React.createRef();

    // Using this JSX to conditionally show the "Not a Billable Lead" prompt
    let leadStatusId = props.lead.status_id,
        disabledCssClassText = null;

    let leadReviewPromptJsx = <p
        className="type-large-body type-heavy type-single-line no-margin-top spacing-10-bottom">
            <span className={disabledCssClassText}>
                Lead Review{' '}
            </span>
    </p>;

    let reviewLabelJsx = <p className="type-large-body type-heavy type-single-line no-margin-top spacing-10-bottom">
            <span className={disabledCssClassText}>
                ...
            </span>
    </p>;

    let underReview = Number(props.lead.under_review);

    let reviewDeadlineExpired = Number(props.lead.review_deadline_expired);

    if (leadStatusId == 13) {
        if (reviewDeadlineExpired === 1 && underReview === 0) {
            // disabledCssClassText = "disabled";
            reviewLabelJsx = <p className="type-normal-body type-narrow-line-height no-margin-top spacing-10-bottom">
                <span className={disabledCssClassText}>
                    Lead older than {props.lead.num_days_to_review} days, ineligible for Review.
                </span>
            </p>;

            // JT 1-30-20 commenting out Tooltip for now, but might come back in QA, so leaving here
            /*<Tooltip
                            position="left"
                            // hover={true}
                            // anchor={submitForReviewLabelJsx}
                            content={(
                                <React.Fragment>
                                <span
                                    className="type-large-body type-black type-force-newline type-align-left type-heavy type-narrow-line-height type-notransform spacing-10-bottom type-narrow-line-height">
                                    Submitting Leads for Review
                                </span>
                                    <span
                                        className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                    This Lead is no longer eligible for review. Leads may be submitted for review for up
                                    to 30 days from the time they are received.
                                </span>
                                    <span
                                        className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                    Learn more about requesting a Lead Review in <a
                                        className="type-heavy"
                                        href="https://support.servicedirect.com/select/billable-and-non-billable-leads"
                                        target="_blank">Understanding Billable and Non-Billable Leads.</a>
                                </span>

                                </React.Fragment>
                            )}/>*/

        }
        else if (underReview === 0) {

            reviewLabelJsx = <>
                <span className="type-blue type-heavy" role="button"
                      onClick={() => props.renderSubmitForReviewPopup()}>
                    Submit for Review{' '}
                </span>
                <Tooltip
                    position="left"
                    content={
                        <>
                            <span
                                className="type-large-body type-black type-force-newline type-align-left type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                Lead Review
                            </span>
                            <span
                                className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                If you believe a Lead is Non-Billable, please Submit For Review to begin the Lead Review process.
                                Examples of Non-Billable Leads: Wrong Number, Existing Customer, Job Seeker, Spam Call, etc.
                            </span>
                            <span
                                className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                Learn more about requesting a Lead Review in{' '}
                                <a className="type-heavy"
                                   href={AuthService.isMarketplace
                                       ? "https://support.servicedirect.com/billable-and-non-billable-leads"
                                       : "https://support.servicedirect.com/select/billable-and-non-billable-leads"}
                                   target="_blank">Understanding Billable and Non-Billable Leads.</a>
                            </span>
                        </>
                    }
                    modalContainerRef={tooltipRef}
                />
            </>;
        }
        else if (underReview === 1) {
            reviewLabelJsx =
                <p className="type-large-body type-normal-body type-single-line no-margin-top spacing-10-bottom">
                <span className="type-no-break">
                    Lead Under Review
                </span>
                </p>;
        }
        else if (underReview === 2) {
            reviewLabelJsx =
                <p className="type-large-body type-normal-body type-single-line no-margin-top spacing-10-bottom">
                <span className="type-no-break">
                    Lead Review Complete – Billable
                </span>
                </p>;
        }
    }
    else {
        reviewLabelJsx = <p className="type-normal-body type-single-line no-margin-top spacing-10-bottom">
            <span className="type-no-break">
                Lead Review Complete
            </span>
        </p>;
    }

    return <div>
        {leadReviewPromptJsx}
        <div className="type-normal-body type-single-line spacing-10-top no-margin-bottom">
            {reviewLabelJsx}
        </div>
    </div>;
};

export default BillableLeadBlock;