import React from "react";
import PropTypes from "prop-types";
import { LoadingSpan } from './styles'

export default function LoadingGif(props) {
  return <LoadingSpan {...props }/>;
}

LoadingGif.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf([ 'small', 'large' ])
};
