import React from 'react';

const PopupFormRow = (props) => {
	let classes = "popup-form-row";
	
	if (props.fullWidthOnMobile) {
		classes += ' popup-form-row__full-width-mobile';
	}
	if (props.unevenColumns2and1) {
		classes += ' popup-form-row__two-thirds-one-third';
	}
	if (props.noBottomMargin) {
		classes += ' popup-form-row__no-bottom-margin';
	}
	if (props.extraClass) {
		classes += ` ${props.extraClass}`;
	}
	
	return (
		<div className={classes}>
			{props.children}
		</div>
	);
};

export default PopupFormRow;
