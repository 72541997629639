import React from 'react';

import { filterMessage } from '../../common/lead_log/FilterMessage';
import RowsPerPageToggle from './RowsPerPageToggle';

export default (props) => {
    return <div className="admin-leadlog-headline spacing-30-bottom">
    <div>
        { filterMessage(props.managerViewState,
            {
                leadStatusOptions: props.leadStatusOptions(),
                contractorTypeOptions: props.contractorTypeOptions(),
                adNetworkOptions: props.adNetworkOptions(),
                serviceCategoryOptions: props.serviceCategoryOptions(),
                parentContractorOptions: props.parentContractorOptions(),
                managerAccountOptions: props.managerAccountOptions(),
                clientOptions: props.clientOptions(),
                campaignOptions: props.campaignOptions(),
                leadReviewCompleteOptions: props.leadReviewCompleteOptions(),
                defaultFilters: props.defaultFilters
            }) }
    </div>
    <RowsPerPageToggle
        setRowsPerPage={ props.setRowsPerPage }
        rowsPerPage={ props.rowsPerPage }
    />
</div>
}
    