import styled from 'styled-components'

export const Wrapper = styled.div`
    /* padding: 25px 0; */
    display: flex;
    flex-direction: column;
    padding: 1.548387097rem 2.064516129rem;
`

export const ContentWrapper = styled.div`
    /* display: grid; */
    /* row-gap: 16px; */
`

export const ConfirmationLine = styled.div`
    display: grid;
    column-gap: 30px;
    padding-left: 20px;
    padding: 8px 0 8px 20px;
    grid-template-columns: 2fr 2fr 2fr 1fr;
`

export const SuccessLine = styled.div`
    display: grid;
    align-items: center;
    column-gap: 30px;
    padding: 5px 0;
    grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
`

export const CopyWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;
`
