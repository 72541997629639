export const parseBillableStatus = (billableStatus, filters) => {
    if (billableStatus === "all") {
        filters.under_review = undefined;
        filters.is_valid = 'null';
    }

    if (billableStatus === "billable") {
        filters.under_review = undefined;
        filters.is_valid = 1;
    }

    if (billableStatus === "non-billable") {
        filters.under_review = undefined;
        filters.is_valid = 0;
    }

    if (billableStatus === "reviewed-billable") {
        filters.under_review = 2;
        filters.is_valid = 1;
    }

    if (billableStatus === "reviewed-non-billable") {
        filters.under_review = 2;
        filters.is_valid = 0;
    }

    if (billableStatus === "reviewed-all") {
        filters.under_review = 2;
        filters.is_valid = 'null';
    }

    if (billableStatus === "under-review") {
        filters.under_review = 1;
        filters.is_valid = 'null';
    }
    
    return filters;
};

export const constructBillableStatus = (underReview, isValid) => {
    let billableStatus;
    
    if (underReview === undefined && 
        isValid === 1)
    {
        billableStatus = 'billable';
    }
    else if (underReview === undefined && 
             isValid === 0)
    {
        billableStatus = 'non-billable';
    } 
    else if (underReview === 2 &&
             isValid === 1)
    {
        billableStatus = 'reviewed-billable';
    }
    else if (underReview === 2 &&
             isValid === 1)
    {
        billableStatus = 'reviewed-non-billable';
    } 
    else if (underReview === 2 &&
             isValid === 'null')
    {
        billableStatus = 'reviwed-all';
    } 
    else if (underReview === 1 &&
             isValid === 'null')
    {
        billableStatus = 'under-review';
    }
    else
    {
        billableStatus = 'all';
    }
    
    return billableStatus;
}
