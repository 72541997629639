import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import queryString from "query-string";

import ContractorService from "../../service/ContractorService";
import LoadingGif from "../../common/components/LoadingGif";
import SkeletonText from "../../common/components/Skeleton/SkeletonText";
import ContractorRow from "./ContractorRow";
import Pagination from "../../common/components/Pagination/v1.0.1/Pagination";
import type {IPaginationInterface} from "../../Interfaces/IPaginationInterface";
import Radio from "../../common/components/form_elements/Radio";
import {ContractorTypes} from "../../common/StaticValues";
import Checkbox from "../../common/components/form_elements/Checkbox";
import {toCamel} from "../../Util";
import Tooltip from "../../common/Tooltip";
import SearchField from "../../common/components/form_elements/SearchField";
import {errorHandler} from "../../Requests";

/**
 * The component in charge of the contractors view
 */
class ContractorsView extends Component {
    basePagination = {
        rowsPerPage: process.env.REACT_APP_PAGINATION_NUM_PER_PAGE,
        pageNum: 1,
        totalRows: 0,
        totalPages: 0
    };

    constructor(props) {
        super(props);
        document.title = 'Clients';

        this.state = {
            loading: true,
            contractors: [],
            pagination: {...this.basePagination},
            orderBy: 'contractor_id',
            orderByReverse: true,
            search: '',
            type: '',
            enrollable: false
        };

        this.contractorService = new ContractorService();
        this.searchFieldRef = React.createRef();
        this.scrollboxRef = React.createRef();
        this.tooltipRef = React.createRef();
    }

    componentDidMount() {
        this.setAppliedFiltersFromUrl();
    }

    componentWillUnmount() {
        this.contractorService.cancelSignal.cancel();
    }

    /**
     * get the contractors from the server based on the current filters
     */
    getContractors = () => {
        this.setState({loading: true});
        const filter = {orderBy: this.parseOrderBy()};
        if (this.state.type) {
            filter.type = this.state.type;
        }
        if (this.state.search) {
            filter.search = this.state.search;
        }
        if (this.state.enrollable) {
            filter.enrollable = true;
        }

        this.contractorService.get(this.state.pagination, filter)
            .then((data) => this.setState({
                contractors: data.data.contractors,
                pagination: data.pagination
            }))
            .catch(errorHandler)
            .finally(() => this.setState({loading: false}));
    };

    /**
     * returns the className of a header based on the current sort criteria and the base class
     * @param {string} orderBy - sort variable of this header
     */
    getHeaderClass = (orderBy) => {
        let baseClass = 'sortable';
        if (this.state.orderBy === orderBy) {
            baseClass += this.state.orderByReverse
                ? ' sortable-down'
                : ' sortable-up';
        }

        return baseClass;
    };

    /**
     * parses the filter message based on the
     * @return {any} the JSX message
     */
    getFilterMessage = () => {
        if (this.state.loading) {
            return <span className="loading-results-message"><SkeletonText width={350}/></span>;
        }

        if (this.state.contractors.length === 1) {
            return 'Showing 1 Client';
        }

        return <>
            Showing{' '}
            <span className="type-heavy">
                {Math.min((this.state.pagination.pageNum - 1) * this.state.pagination.rowsPerPage + 1, this.state.pagination.totalRows)}
                –
                {Math.min(this.state.pagination.pageNum * this.state.pagination.rowsPerPage, this.state.pagination.totalRows)}
                </span>{' '}
            Clients out of <span className="type-heavy">{this.state.pagination.totalRows}</span>
        </>;
    };

    /**
     * applies filters from the URL
     */
    setAppliedFiltersFromUrl = () => {
        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});

        const newState = {pagination: {...this.basePagination}};
        const fields = ['page_num', 'order_by', 'search', 'type', 'enrollable'];

        fields.forEach((filter) => {
            if (typeof queryParams[filter] === 'undefined') {
                return;
            }

            const camelFilter = toCamel(filter);
            switch (filter) {
                case 'page_num':
                    newState.pagination[camelFilter] = queryParams[filter];
                    break;

                case 'order_by':
                    if (queryParams.order_by[0] === '-') {
                        queryParams.order_by = queryParams.order_by.substr(1);
                        newState.orderByReverse = true;
                    }
                    newState.orderBy = queryParams.order_by;
                    break;

                case 'enrollable':
                    newState[camelFilter] = !!queryParams[filter];
                    break;

                default:
                    newState[camelFilter] = queryParams[filter];
            }
        });

        this.setState(newState, this.getContractors);
    };

    /**
     * sorts the clients by a given field name
     * @param {string} orderBy - the field to sort by
     */
    applySort = (orderBy) => {
        this.setState(
            {
                orderBy,
                orderByReverse: this.state.orderBy === orderBy
                    ? !this.state.orderByReverse
                    : false
            },
            this.updateUrlAndContractors
        );
    };

    /**
     * Update the URL and fetch the corresponding contractors based on the component's state
     */
    updateUrlAndContractors = () => {
        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});

        queryParams.page_num = this.state.pagination.pageNum;
        queryParams.order_by = this.parseOrderBy();

        if (this.state.search) {
            queryParams.search = this.state.search;
        }
        else {
            delete queryParams.search;
        }

        if (this.state.type) {
            queryParams.type = this.state.type;
        }
        else {
            delete queryParams.type;
        }

        if (this.state.enrollable) {
            queryParams.enrollable = true;
        }
        else {
            delete queryParams.enrollable;
        }

        const newPath = '/contractors?' + queryString.stringify(queryParams, {arrayFormat: 'bracket'});

        this.props.history.push(newPath);
        this.getContractors();
    };

    /**
     * Parses the state orders into the API expected value
     * @return {string}
     */
    parseOrderBy = () => {
        let orderBy = this.state.orderByReverse
            ? '-'
            : '';

        return orderBy + this.state.orderBy;
    };

    /**
     * Handles a page change
     * @param {Event} event
     * @param {number} newPageNum
     */
    handlePageChange = (event, newPageNum) => {
        const {pagination}: IPaginationInterface = this.state;
        pagination.pageNum = newPageNum;
        this.setState({pagination});
        this.updateUrlAndContractors();
    };

    /**
     * updates the search string
     * @param {KeyboardEvent} event
     */
    handleSearchChange = (event) =>
        this.setState({search: event.target.value});

    /**
     * handles submitting the search via the search button or clicking enter in the input field
     * @param {MouseEvent|KeyboardEvent} event
     */
    handleSearch = (event) => {
        event.preventDefault();
        this.setState({pagination: {...this.basePagination}},
            this.updateUrlAndContractors);
    };

    /**
     * handles updating the type filter
     * @param event
     */
    handleTypeFilterChange = (event) =>
        this.setState({type: event.target.value, pagination: {...this.basePagination}},
            this.updateUrlAndContractors);

    /**
     * handles updating enrollment change
     */
    handleEnrollableChange = () =>
        this.setState({enrollable: !this.state.enrollable, pagination: {...this.basePagination}},
            this.updateUrlAndContractors);

    render() {
        const fieldsCount = 6;
        let contractorRows;
        if (this.state.loading) {
            contractorRows = <tr>
                <td colSpan={fieldsCount}>
                    <div className="type-centered padding-30">
                        <LoadingGif/>
                    </div>
                </td>
            </tr>;
        }
        else if (this.state.contractors.length === 0) {
            let searchString = this.state.search.trim();
            contractorRows = <tr>
                <td colSpan={fieldsCount}>
                    {searchString
                        ? <div className="type-centered padding-30">
                            No records found for:<br/>
                            <span className="type-heavy">{searchString}</span>
                        </div>
                        : <div className="type-centered padding-30">No records found</div>}
                </td>
            </tr>;
        }
        else {
            contractorRows = this.state.contractors.map((contractor, index) =>
                <ContractorRow key={index} contractor={contractor} scrollboxRef={this.scrollboxRef}/>
            );
        }

        return <div className="page-width-wide">

            <div className="row padding-50-top padding-30-bottom">
                <div className="wide-format-col">
                    <div className="simpleflex__row">
                        <div className="simpleflex__cell">
                            <h2 className="type-normal-headline type-heavy padding-20-bottom">
                                Clients
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="clear-block"/>
            </div>

            <div className="row">
                <div className="wide-format-col page__contentbox">
                    <div className="simpleflex__row spacing-18-bottom">
                        <div className="simpleflex__cell">
                            <SearchField
                                placeholder="Search by Name or Email"
                                onChange={this.handleSearchChange}
                                value={this.state.search}
                                searchFieldRef={this.searchFieldRef}
                                onFormSubmit={this.handleSearch}
                                textLabel={true}
                                wide={true}
                            />
                        </div>
                        <div className="simpleflex__cell">
                            <div className="type-normal-body type-single-line">
                                <Radio
                                    name="type"
                                    id="typeBoth"
                                    label="Both"
                                    value=""
                                    disabled={this.state.enrollable}
                                    onChange={this.handleTypeFilterChange}
                                    checked={!this.state.type && !this.state.enrollable}
                                />
                            </div>
                            <div className="type-normal-body type-single-line spacing-20">
                                <Radio
                                    name="type"
                                    id="typeSelect"
                                    label="Select"
                                    value={ContractorTypes.Select}
                                    disabled={this.state.enrollable}
                                    onChange={this.handleTypeFilterChange}
                                    checked={this.state.type == ContractorTypes.Select && !this.state.enrollable}
                                />
                            </div>
                            <div className="type-normal-body type-single-line no-margin-top">
                                <Radio
                                    name="type"
                                    id="typeMarketplace"
                                    label="Marketplace"
                                    value={ContractorTypes.Marketplace}
                                    disabled={this.state.enrollable}
                                    onChange={this.handleTypeFilterChange}
                                    checked={this.state.type == ContractorTypes.Marketplace || this.state.enrollable}
                                />
                            </div>
                        </div>
                        <div className="simpleflex__cell">
                            <div className="type-normal-body type-single-line">
                                <Checkbox name="eligible-enrollment"
                                          label="Eligible for Select Enrollment"
                                          checked={this.state.enrollable}
                                          onChange={this.handleEnrollableChange}
                                          tooltip={<Tooltip
                                              position="top"
                                              content={
                                                  <span
                                                      className="type-small-body type-black type-force-newline type-notransform type-align-left type-narrow-line-height">
                                                      Filters the Client list to only active Marketplace Clients that
                                                      have completed MP Signup and Onboarding.
                                                  </span>
                                              }
                                              modalContainerRef={this.tooltipRef}
                                          />}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="simpleflex__row simpleflex__row__wrap__mobile type-centered-mobile spacing-18-bottom">
                        <div className="simpleflex__cell simpleflex__cell__middlealigned simpleflex__cell__maxed">
                            <div
                                className="type-normal-subhead type-narrow-line-height no-margin-top spacing-18-bottom-mobile">
                                {this.getFilterMessage()}
                            </div>
                        </div>
                    </div>

                    <div className="scroll-table__container">
                        <div className="scroll-table__container__shadowedge__left" ref={this.leftShadowEdgeRef}/>
                        <div className="scroll-table__container__shadowedge" ref={this.rightShadowEdgeRef}/>
                        <div
                            className="scroll-table__container__scrollbox scroll-table__container__scrollbox__flexible-height scroll-table__container__scrollbox-mobile"
                            ref={this.scrollboxRef}
                        >
                            <table className="scroll-table__table type-normal-body type-single-line sortable-table">
                                <thead>
                                <tr className="type-small-body type-heavy">
                                    <th className={this.getHeaderClass('name')}
                                        onClick={() => this.applySort('name')}>
                                        <div>Client Name</div>
                                    </th>
                                    <th className={this.getHeaderClass('contractor_type_id')}
                                        onClick={() => this.applySort('contractor_type_id')}>
                                        <div>Type</div>
                                    </th>
                                    <th className={this.getHeaderClass('contractor_id')}
                                        onClick={() => this.applySort('contractor_id')}>
                                        <div>Client ID</div>
                                    </th>
                                    <th className={this.getHeaderClass('creation_timestamp')}
                                        onClick={() => this.applySort('creation_timestamp')}>
                                        <div>Created</div>
                                    </th>
                                    <th className={this.getHeaderClass('date_completed_signup')}
                                        onClick={() => this.applySort('date_completed_signup')}>
                                        <div>Date Completed Signup</div>
                                    </th>
                                    <th className={this.getHeaderClass('mp_onboarding_completed_timestamp')}
                                        onClick={() => this.applySort('mp_onboarding_completed_timestamp')}>
                                        <div>MP Onboarding Complete</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {contractorRows}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="spacing-34-top">
                        <Pagination
                            pageNum={this.state.pagination.pageNum}
                            totalRows={this.state.pagination.totalRows}
                            rowsPerPage={this.state.pagination.rowsPerPage}
                            totalPages={this.state.pagination.totalPages}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>

                    <div id="TEMP"
                         style={{height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {/*TODO remove after Brian Jones fixes the contextual menu overflow bug*/}
                        Don't mind me, I'm just a huge temporary div
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(ContractorsView);
