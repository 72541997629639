import React, { useEffect, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import TableHeaderCell from '../../../common/components/TableHeaderCell'
import getColumnDef from './getColumnDef'
import getSummary from './getSummary'
import LoadingGif from '../../../common/components/LoadingGif'

const MPCampaignsTable = ({
    loadingColumns = false,
    loadingRows = false,
    loadingSummary = false,
    mcoRows,
    columns = [],
    sortColumn,
    sortOrder,
    onOrderBy,
    onSelectRow,
    summary,
}) => {
    const gridRef = useRef()
    const [selectedIds, setSelectedIds] = useState([])
    const [selectSource, setSelectSource] = useState()

    // effect to watch for changes to selectedIds
    useEffect(() => {
        if (mcoRows?.length > 0) {
            onSelectRow(selectedIds, selectSource)
        }
        // if no rows are checked, clear the select source
    }, [onSelectRow, selectedIds, selectSource, mcoRows])

    // effect to show the loading overlay when data is loading
    useEffect(() => {
        if (gridRef && gridRef.current && gridRef.current.api) {
            if (loadingRows) {
                gridRef.current.api.showLoadingOverlay()
            } else {
                gridRef.current.api.hideOverlay()
            }
        }
    }, [loadingRows])

    if (loadingColumns) {
        // AG Grid doesn't work well when rendering multiple instances of the data table
        // on the same page. Even rendering a different data table during and after loading
        // causes issues.
        return <LoadingGif />
    }

    // Generate column definitions
    const columnDefs = columns.map((col, i) => {
        // for the first column, add checkbox data
        if (i === 0) {
            col.checkboxSelection = true
            col.headerCheckboxSelection = true
        }
        // if the column is sortable, add the custom sort function
        // so that it can be used by the custom header component a la
        // `headerComponentParams`
        // @see https://www.ag-grid.com/javascript-data-grid/component-header/?#example-custom-header-component
        if (col.sortable) {
            col.headerComponentParams = { sort: () => onOrderBy(col.property) }
        }
        // if the column is the current sort column, add the sort data
        if (col.property === sortColumn) {
            col.sort = sortOrder
        } else {
            col.sort = false
        }
        return getColumnDef(col, { summaryLoading: loadingSummary })
    })

    const defaultColDef = {
        resizable: true,
        // apply a custom header class when the column is sorted
        // so that the background color can be changed
        headerClass: (params) => {
            if (params?.column?.sort) {
                return 'header_sort_active'
            }
        },
    }

    const components = {
        agColumnHeader: TableHeaderCell,
    }

    const gridOptions = {
        onSelectionChanged: ({ api, source }) => {
            const selectedNodes = api.getSelectedNodes()
            const selectedIds = selectedNodes.map(
                (node) => node?.data?.ad_network2campaign_id
            )
            // source will be 'checkboxSelected' when a row is selected
            // and 'uiSelectAll' when the select all checkbox is checked
            // onSelectRow(selectedIds, source)
            setSelectedIds(selectedIds)
            setSelectSource(source)
        },
        onSortChanged: ({ api }) => {
            api.showLoadingOverlay()
        },
        // onColumnMoved: ({ columnApi }) => {
        //     // var columnState = columnApi.getColumnState()
        //     // console.log({columnState})
        // },
        suppressScrollOnNewData: true,
        suppressColumnVirtualisation: true,
        suppressRowClickSelection: true,
        suppressRowHoverHighlight: true,
        overlayNoRowsTemplate:
            '<span>No Campaign Lead Sources - Please Adjust Filters</span>',
    }

    const bottomRows = [getSummary(summary)]
    return  (
        <div className="ag-theme-alpine" style={{ width: '100%', height: '800px' }}>
            <AgGridReact
                rowData={mcoRows}
                ref={gridRef}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowSelection="multiple"
                rowHeight={55}
                headerHeight={80}
                pinnedBottomRowData={bottomRows}
                components={components}
                gridOptions={gridOptions}
            />
        </div>
    )
}

export default MPCampaignsTable
