import React from 'react'
import Button from '../../../common/components/form_elements/Button'
import { FullColumn } from '../../../common/layouts'
import { ContentRow, Actions } from './styles'

export default ({ error, onClose }) => {
    let errorMessage = error
    if (typeof error === 'object') {
        if (error?.message) {
            errorMessage = error.message
        } else {
            errorMessage = JSON.stringify(error, null, 2)
        }
    }
    return (
        <FullColumn>
            <ContentRow>
                <span className="type-normal-subhead">
                    Something went wrong <span role="img" aria-label="skull">💀</span>
                </span>
            </ContentRow>
            <ContentRow>
                <span>
                    {errorMessage}
                </span>
            </ContentRow>
            <ContentRow />
            <Actions>
                <Button onClick={onClose}>Close</Button>
            </Actions>
        </FullColumn>
    )
}
