import React from 'react';

import CallOutBox from './CallOutBox';
import CoreSection from './CoreSection';
import ExpandableSection from './ExpandableSection';
import ImageBox from './ImageBox';
import QuizSection from './QuizSection';
import Sidebar from './sidebar';
import WistiaPlayer from '../common/WistiaPlayer';

const StepOne = (props) => {
	return (
		<>
		<div className="onboarding-contents page-width">
			<Sidebar step={1} {...props} />
			<div className="onboarding-main-column">
				<CoreSection
					headerLabel="Section 1"
					header="Welcome & How Service Direct Works"
				>
					<h4 className="type-large-subhead type-heavy spacing-24-bottom">
						Welcome to the Service Direct Family!
					</h4>
					<p className="type-large-body type-narrow-line-height spacing-40-bottom">
						Howdy from Austin, Texas! For over 17 years we’ve been sending high-quality exclusive Leads to thousands of clients across North America and we’re excited to partner with you to help grow your business.
					</p>
					<h4 className="type-large-subhead type-heavy spacing-24-bottom">
						Let’s Start Your Onboarding Course
					</h4>
					<ul className="type-large-body type-narrow-line-height spacing-40-bottom">
						<li>
							Your Onboarding consists of a few short videos with helpful content and takes around 15 minutes to complete. At the end of each section we'll ask you a couple of questions to make sure you've got the information you need to be successful with Service Direct.
						</li>
						<li>
							Once you have completed this Onboarding Course our team will review your Account and we can start generating Leads.
						</li>
						<li>
							If you need help during this process, just click the links in the Need Help section <span className="onboarding-copy__show-full">on the left</span><span className="onboarding-copy__show-mobile">in the Main Navigation at the top</span>.
						</li>
					</ul>
					<WistiaPlayer
						videoId="qykigabss2"
						wrapper="wistia-player-1"
					/>
				</CoreSection>
				
				<ExpandableSection
					header="What Does Service Direct Do?"
				>
					<ul className="type-large-body type-narrow-line-height spacing-40-bottom">
						<li>
							Service Direct generates exclusive Leads, sending you New Potential Customers who are seeking your services.
						</li>
						<li>
							We connect them to you in real time, no matter where they’re searching online.
						</li>
						<li>
							The mySD Online Platform will show you detailed and transparent info about the Leads we generated, the performance of your Campaigns, and allows you to track Return On Investment.
						</li>
					</ul>
					
					<div className="onboarding__columns-block spacing-40-top">
						<div className="column">
							<ImageBox
								url="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Onboarding%20Screenshots/mySD-Campaigns-Manager-v10-MP-HomeServices.jpg"
								alt="Screenshot of mySD Campaigns Manager"
								caption="mySD Campaigns Manager"
								showPopup={props.showPopup}
							/>
						</div>
						<div className="column">
							<ImageBox
								url="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Onboarding%20Screenshots/mySD-LeadManager-v12-Marketplace-KB-Expanded-PhoneCall.jpg"
								alt="Screenshot of mySD Lead Manager"
								caption="mySD Lead Manager"
								showPopup={props.showPopup}
							/>
						</div>
					</div>
				</ExpandableSection>
				
				<ExpandableSection
					header="What’s the Catch?"
				>
					<ul className="type-large-body type-narrow-line-height spacing-40-bottom">
						<li>
							There really isn’t a catch. We’re an honest company with a quality service that has a proven track record of helping grow businesses with a great Return On Investment.
						</li>
						<li className="type-heavy">
							But that doesn’t mean Service Direct works for every business. Our 17+ years of partnering with Clients has shown what moves the needle for our mutual success.
						</li>
						<li>
							We both need to treat this as a partnership and give it a fair try.
						</li>
						<li>
							Together we can develop and continually optimize your Campaigns to bring you as many Leads as we can given what’s available in your market.
						</li>
						<li>
							<span className="type-heavy">The most important thing you can do is diligently answer your phone</span> (most companies overestimate their phone answer rates) and ensure your team does well at converting Leads into Booked Appointments.
						</li>
						<li>
							In the mySD Platform you can track your Lead Progress to see Job Won rate, Revenue, and Return On Investment so we can prove the effectiveness of our service.
						</li>
					</ul>
					
					<CallOutBox
						headline="Learn More"
						extraClasses="spacing-40-top"
					>
						<ul className="type-large-body type-narrow-line-height">
							<li>
								Read our Help Center Article: <a href="https://support.servicedirect.com/grow-your-account" target="_blank" className="type-heavy">Growing Your Service Direct Account</a>
							</li>
						</ul>
					</CallOutBox>
				</ExpandableSection>
				
				<QuizSection
					{...props}
					questions={[
						{
							name: "step-1-question-1",
							question: "What problem does Service Direct help solve?",
							answers: [
								"Teaches me how to run my business",
								"Generates exclusive Leads to help my business grow",
								"Answers the phone and books service appointments",
							],
							correctAnswer: 2,
						},
						{
							name: "step-1-question-2",
							question: "What is the #1 indicator for success within the Service Direct Platform?",
							answers: [
								"Spend the most on Leads",
								"Consistently answering your phone for incoming Leads",
								"Be the best service provider in your area",
							],
							correctAnswer: 2,
						}
					]}
				/>
			</div>
		</div>
		</>
	);
};

export default StepOne;