import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Input from "../form_elements/Input";
import MultiSelectList from '../form_elements/MultiSelectList';
import { CAN_PROVINCES, US_STATES } from '../../StaticValues';

export default class CompanyDetailsForm extends Component {
    render() {
        const companyName = this.props.companyName,
            country = this.props.country,
            streetAddress = this.props.streetAddress,
            unit = this.props.unit,
            city = this.props.city,
            state = this.props.state,
            postalCode = this.props.postalCode,
            website = this.props.website,
            zipCodeLabel = country.value == 'USA'
                ? 'Zip Code'
                : 'Postal Code';

        return (
            <div className="row padding-20-bottom">
                <div className="col alpha grid-2 empty" />
                <div className="col grid-8 no-margin-mobile">
                    <div className="module">
                        <div className="module__contentbox module__contentbox__no-header module__twothirdswidth">
                            <p className="type-large-body type-heavy type-allcaps type-single-line no-margin-top spacing-40-bottom">
                                Company Details
                            </p>

                            <div className="form__row spacing-30-bottom spacing-24-bottom-mobile">
                                <div className="form__cell form__cell__50 form__cell__100__mobile">
                                    <Input
                                        inputRef={companyName.ref}
                                        label="Company Name *"
                                        type="text"
                                        name="company_name"
                                        value={companyName.value}
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={true}
                                        hasError={!!companyName.error}
                                    />
                                </div>
                                <div className="form__cell form__cell__50 form__cell__hidden__mobile" />
                            </div>
                            <div className="form__row spacing-30-bottom spacing-24-bottom-margin">
                                <div className="form__cell form__cell__50 form__cell__100__mobile">
                                    <MultiSelectList
                                        inputRef={country.ref}
                                        selections={[country.value]}
                                        hasError={!!country.error}
                                        required={true}
                                        onChange={this.props.updateCountryCallback}
                                        options={[
                                            { value: 'USA', text: 'United States' },
                                            { value: 'CAN', text: 'Canada' },
                                        ]}
                                    />
                                </div>
                                <div className="form__cell form__cell__50 form__cell__hidden__mobile" />
                            </div>
                            <div className="form__row spacing-30-bottom spacing-24-bottom-margin">
                                <div className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                                    <Input
                                        name={'street_address'}
                                        label={'Street Address *'}
                                        type={'text'}
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={true}
                                        value={streetAddress.value}
                                        hasError={!!streetAddress.error}
                                        inputRef={streetAddress.ref}
                                    />
                                </div>
                                <div className="form__cell form__cell__25 form__cell__50__mobile">
                                    <Input
                                        name={'unit'}
                                        label={'Unit Number'}
                                        type={'text'}
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={false}
                                        value={unit.value}
                                        hasError={!!unit.error}
                                        inputRef={unit.ref}
                                    />
                                </div>
                                <div className="form__cell form__cell__25 form__cell__50__mobile" />
                            </div>
                            <div className="form__row spacing-30-bottom spacing-24-bottom-margin">
                                <div className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                                    <Input
                                        name="city"
                                        label="City *"
                                        type="text"
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={true}
                                        value={city.value}
                                        hasError={!!city.error}
                                        inputRef={city.ref}
                                    />
                                </div>
                                <div className="form__cell form__cell__25 form__cell__50__mobile form__cell__force-right-margin__mobile">
                                    <MultiSelectList
                                        name="state"
                                        hasError={!!state.error}
                                        countryCode={country.value}
                                        useTextFilter={true}
                                        label={country.value === "USA" ?  "State" : "Province" }
                                        options={ country.value === "USA" ?  US_STATES : CAN_PROVINCES }
                                        inputRef={state.ref}
                                        selections={[state.value]}
                                        onChange={this.props.updateStCallback}
                                        required={true}
                                    />
                                </div>
                                <div className="form__cell form__cell__25 form__cell__50__mobile">
                                    <Input
                                        name="postal_code"
                                        label={`${zipCodeLabel} *`}
                                        type="text"
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={true}
                                        value={postalCode.value}
                                        hasError={!!postalCode.error}
                                        inputRef={postalCode.ref}
                                    />
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                                    <Input
                                        name="website"
                                        label="Website URL"
                                        type="text"
                                        onChange={this.props.onChange}
                                        onBlur={this.props.onBlur}
                                        required={false}
                                        value={website.value}
                                        hasError={!!website.error}
                                        inputRef={website.ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col omega grid-2 empty" />
                <div className="clear-block" />
            </div>
        )
    }
}

CompanyDetailsForm.propTypes = {
    companyName: PropTypes.object.isRequired,
    country: PropTypes.object.isRequired,
    streetAddress: PropTypes.object.isRequired,
    unit: PropTypes.object,
    city: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    postalCode: PropTypes.object.isRequired,
    website: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    updateCountryCallback: PropTypes.func.isRequired,
    updateStCallback: PropTypes.func.isRequired,
};
