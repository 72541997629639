import React from 'react'
import Button from '../../../../common/components/form_elements/Button'
import { formatCurrency, formatNumber, toTitleCase } from '../../../../Util'

const defaultOptions = {
    summaryLoading: false,
}
export default (col, options = defaultOptions, onClick) => {
    const {
        property,
        label,
        format = 'string',
        width,
        tooltip,
        informational_message,
        ...rest
    } = col

    const formatMap = {
        currency: (col) => ({
            valueGetter: (params) => +params?.node?.data[property] || 0,
            field: property,
            // valueFormatter: ({ value = 0 }) => formatCurrency(value),
            cellRenderer: (params) => {
                let spanClass = 'type-normal-body'
                let val
                if (params?.node?.data[property] != null) {
                    val = formatCurrency(params?.node?.data[property] || 0, 0)
                } else {
                    val = '--'
                }

                return <span className={spanClass}>{val}</span>
            },
        }),
        string: (col) => ({
            field: property,
            // to ensure proper sorting, convert to lowercase. ag-grid
            // sorts lowercase before uppercase by default
            valueGetter: (params) =>
                `${params?.node?.data[property]}`?.toLowerCase() || '',
            cellRenderer: (params) => {
                let spanClass = 'type-normal-body'
                let val = toTitleCase(params?.node?.data[property] || '')
                if (val == null) {
                    val = '--'
                    // val = 'N/A'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        boolean: (col) => ({
            field: property,
            valueGetter: (params) => params?.node?.data[property] || 0,
            cellRenderer: (params) => {
                let val
                const propertyVal = params?.node?.data[property]
                if (propertyVal == null) {
                    val = '--'
                    // val = 'N/A'
                } else {
                    val = `${propertyVal}` == '1' ? 'Yes' : 'No'
                }
                return <span className="">{val}</span>
            },
        }),
        percent: (col) => ({
            field: property,
            valueGetter: (params) => +params?.node?.data[property] || 0,
            cellRenderer: (params) => {
                let spanClass = 'type-normal-body'
                let val
                if (params?.node?.data[property] != null) {
                    val = `${formatNumber(params?.node?.data[property] || 0, 2)} %`
                } else {
                    val = '--'
                    // val = 'N/A'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        decimal: (col) => ({
            field: property,
            valueGetter: (params) => +params.node.data[property] || 0,
            cellRenderer: (params) => {
                let spanClass = 'type-normal-body'
                let val
                // if the property is default_mp_phone_bid_multiplier, and the
                // property mp_phone_leads_disconnected_by_default is true, then
                // grey out the value
                const { mp_phone_leads_disconnected_by_default } = params.data
                if (property === 'default_mp_phone_bid_multiplier') {
                    if (mp_phone_leads_disconnected_by_default === '1') {
                        spanClass += ' type-grey-2'
                    }
                }

                // if no phone/form by default, grey out the bid multipliers
                const { no_form_by_default, no_phone_by_default } = params.data
                if (property === 'form_bid_multiplier') {
                    if (no_form_by_default == '1') {
                        spanClass += ' type-grey-2'
                    }
                }
                if (property === 'phone_bid_multiplier') {
                    if (no_phone_by_default == '1') {
                        spanClass += ' type-grey-2'
                    }
                }

                // format the value for display
                if (params?.node?.data[property] != null) {
                    val = formatNumber(params.node.data[property] || 0, 2)
                } else {
                    val = '--'
                    // val = 'N/A'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        integer: (col) => ({
            field: property,
            valueGetter: (params) => +params?.node?.data[property] || 0,
            cellRenderer: (params) => {
                // case for the summary (pinned) row
                let spanClass = 'type-normal-body'
                let val
                if (params?.node?.data[property] != null) {
                    val = formatNumber(params?.node?.data[property] || 0, 0)
                } else {
                    val = '--'
                    // val = 'N/A'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        edit: (col) => ({
            valueGetter: (params) => params.data[property] || '',
            cellRenderer: (params) => {
                return (
                    <Button
                        variant="span"
                        onClick={() => onClick(params.data.ad_network_id)}
                        size="large"
                    >
                        {params.data[property]}
                    </Button>
                )
            },
        }),
    }

    return {
        headerName: label,
        ...formatMap[format](col),
        width: width || 120,
        ...rest,
    }
}
