import React, {Component} from 'react';
import {del, get, put} from '../../Requests';
import axios from 'axios';

import PropTypes from 'prop-types';
import {MoneyInput} from "../../common/components/form_elements/MoneyInput";
import AuthService from "../../service/AuthService";

export default class MonthlyBudgetAlertSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            monthlyBudgetAlertAmount: '',
            previousAmount: null,
            submitting: false
        };

        this.cancelSignal = axios.CancelToken.source();
    }

    componentDidMount() {
        this.getMonthlyBudgetAmount();
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
    }

    /**
     * Sends a put to update the contractor monthly budget alert
     */
    setMonthlyBudgetAlertAmount = (event) => {
        event.preventDefault();

        const amount = parseFloat(this.state.monthlyBudgetAlertAmount);
        if (!amount) {
            this.props.updateMessageBlocks('Monthly Lead Cost Notification amount must be a numeric value.', 'error');
            return;
        }

        const payload = {monthly_budget_alert_amount: amount};

        this.setState({submitting: true});

        put('monthly_budget_alert_amount', payload, this.cancelSignal.token)
            .then(resp => {
                let messageType = resp.status === 200
                    ? "success"
                    : "error",
                    message: string,
                    stateClone = {...this.state};

                stateClone.submitting = false;

                if (resp.status === 200) {

                    // console.log(resp.data);

                    message = resp.data.message;
                    stateClone.previousAmount = resp.data.data.monthly_budget_alert_amount;
                }
                else {
                    let errors = resp.data.errors;
                    message = errors[0].message;
                }

                this.props.updateMessageBlocks(message, messageType);

                this.setState(stateClone);
            })
    }

    /**
     * Removes the user's monthly cost notification.
     **/
    removeMonthlyBudgetAlertAmount = (event) => {

        event.preventDefault();

        this.setState({"submitting": true});

        let url = "monthly_budget_alert_amount/";

        del(url, this.cancelSignal.token)
            .then(resp => {

                let messageType = resp.status === 200 ? "success" : "error",
                    message: string,
                    stateClone = {...this.state};

                stateClone.submitting = false;

                if (resp.status === 200) {

                    message = resp.data.message;

                    //
                    // The way this value is maintained in state coupled with the
                    // input's value set manually merits changing to a controlled
                    // input.
                    //
                    stateClone.monthlyBudgetAlertAmount = null;
                    stateClone.previousAmount = null;
                }
                else {
                    let errors = resp.data.errors;
                    message = errors[0].message;
                }

                this.props.updateMessageBlocks(message, messageType);

                this.setState(stateClone, () => {
                    // console.log("in response", this.state);
                });
            })
    }

    /**
     * Gets the monthly budget alert if we have one
     **/
    getMonthlyBudgetAmount = () => {
        let url = "monthly_budget_alert_amount/";

        get(url, this.cancelSignal.token)
            .then((resp) => {
                //
                // If for example the request was canceled, return
                //
                if (!resp) return;

                if (resp.data) {
                    const monthlyBudgetAlertAmount = resp.data.data.monthly_budget_alert_amount;

                    this.setState({
                        monthlyBudgetAlertAmount: monthlyBudgetAlertAmount,
                        previousAmount: monthlyBudgetAlertAmount
                    });
                }
            });
    };

    render() {
        return (
            <div className="wide-col omega grid-6">
                <div className="module">
                    <div className="module__header module__halfwidth">
                        <h3 className="type-large-subhead type-single-line">
                            Monthly Lead Cost Notification
                        </h3>
                    </div>
                    <div className="module__contentbox module__halfwidth">
                        <p className="type-large-body spacing-30-bottom">
                            Get a notification when your Lead Costs reach the amount you specify below. This alert
                            applies to all Campaigns on your account.{' '}
                            {AuthService.isMarketplace
                                ? 'Note: You'
                                : 'Note: Your Monthly Service Subscription is not reflected in this number and you'}
                            {' '}
                            will continue to receive Leads normally unless you take action to reduce your{' '}
                            <a className="type-heavy" target="_blank"
                               href={AuthService.isMarketplace
                                   ? "https://support.servicedirect.com/lead-volume"
                                   : "https://support.servicedirect.com/select/lead-volume"}>
                                Lead Volume
                            </a>.
                        </p>

                        <p className="type-large-body type-heavy spacing-20-bottom">
                            Monthly Lead Cost Notification
                        </p>

                        <div className="spacing-20-top spacing-30-bottom">
                            <form>
                                <div className="flex-inline-field-with-button">
                                    <div className="flex-inline-field-with-button__field">
                                        <MoneyInput
                                            name="monthly_budget_alert_amount"
                                            defaultValue={this.state.monthlyBudgetAlertAmount}
                                            onChange={(event) =>
                                                this.setState({monthlyBudgetAlertAmount: event.target.value})}
                                        />
                                    </div>
                                    <div className="flex-inline-field-with-button__button">
                                        <button className="button ui-normal-button"
                                                onClick={this.setMonthlyBudgetAlertAmount}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                                <p className="type-small-body type-force-newline spacing-10-top">
                                    <span className="type-heavy type-blue" role="button"
                                          onClick={this.removeMonthlyBudgetAlertAmount}
                                    >
                                        Remove Cost Notification
                                    </span>
                                </p>

                            </form>
                        </div>

                        <p className="type-small-body spacing-30-top">
                            <a className="type-heavy" href="/users">
                                View and Edit who gets Billing Notifications
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

MonthlyBudgetAlertSection.propTypes = {
    contractor: PropTypes.object,
    updateAppContractor: PropTypes.func
};
