import React from 'react';
import PropTypes from 'prop-types';

import {formatCurrency} from "../../../Util";
import Tooltip from "../../Tooltip";
import {MoneyInput} from "../form_elements/MoneyInput";

/**
 * calculates discount percentage
 * @param {number} discountAmount
 * @param {number} retailStartupFee
 * @returns {number}
 */
export const getDiscountPercentage = (discountAmount, retailStartupFee) => {
    // make sure these are numbers
    discountAmount = Math.abs(discountAmount);
    retailStartupFee = Math.abs(retailStartupFee).toFixed(2);
    // no startup fee what are we even doing here?
    if (retailStartupFee === 0) {
        return 100;
    }

    return (discountAmount / retailStartupFee) * 100;
};

/**
 * returns the amount due element
 * @param props
 * @return {Object}
 * @constructor
 */
export const AmountDueSpan = (props) => {
    const price = (props.discountAmount === 0 || !props.discountAmount)
        ? formatCurrency(props.retailStartupFee)
        : <>
            {formatCurrency(props.retailStartupFee - props.discountAmount)}{' '}
            ({getDiscountPercentage(props.discountAmount, props.retailStartupFee)}% discount)
        </>;

    return <span className="type-force-newline">{price}</span>;
};
AmountDueSpan.propTypes = {
    discountAmount: PropTypes.number.isRequired,
    retailStartupFee: PropTypes.number.isRequired
};

export const IndustryLeadCost = (props) =>
    props.tenderData.map((industry, key) => {
        let nameForId = industry.name.replace(/ /g, '-');
        let costPerLead = Math.abs(industry.cost_per_lead).toString();

        if (props.clientCanEditCpl) {
            return <span className=" cpl-row" key={key}>
                <span>
                    <label
                        id={"cplField-" + nameForId + "-label"}
                        className="type-small-body type-narrow-line-height type-heavy"
                    >
                        <span className="spacing-10-bottom">
                            <span className={industry.error ? "type-alert" : ""}>
                                {industry.name}
                            </span>
                        </span>
                        <span>
                            <MoneyInput
                                name={"cpl-" + nameForId}
                                id={"cplField-" + nameForId}
                                defaultValue={(costPerLead === "NaN" || Math.abs(costPerLead) === 0) ? "" : costPerLead}
                                onChange={(event) => props.updateTender(key, parseInt(event.target.value))}
                                data-industry-id={industry.industry_id}
                            />
                            <span className="type-normal-body type-no-break">
                                Suggested CPL Range:{' '}
                                {formatCurrency(industry.min_suggested_tender, 0)}-
                                {formatCurrency(industry.max_suggested_tender, 0)}
                            </span>
                        </span>
                    </label>
                </span>
                <span
                    className={"padding-5-top padding-10-bottom type-small-body" +
                    (industry.error ? " type-alert" : "")}>
                    {industry.error} &nbsp;
                </span>
            </span>;
        }
        else {
            return <span key={key}>
                {industry.name} - {formatCurrency(industry.cost_per_lead, 2)}
            </span>;
        }
    });
IndustryLeadCost.propTypes = {
    tenderData: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateTender: PropTypes.func.isRequired,
    clientCanEditCpl: PropTypes.bool
};

const PricingSummary = (props) => {
    return <div className="row padding-20-bottom">
        <div className="col alpha grid-2 empty"/>
        <div className="col grid-8 no-margin-mobile">
            <div className="module">
                <div className="module__header module__twothirdswidth">
                    <h3 className="type-large-subhead type-single-line">
                        Pricing Details
                    </h3>
                </div>
                <div className="module__contentbox module__twothirdswidth">
                    <div className="threequarter-content__wrapper threequarter-content__hide-sidebar__mobile">
                        <div className="threequarter-content__content">
                            {props.startupFee > 0 &&
                            <>
                                <p className="type-normal-subhead spacing-10-bottom">
                                    <span className="type-force-newline type-heavy">
                                        <span className="type-force-newline-mobile">Select Program Enrollment</span>
                                            <span className={
                                                (props.paid ? "type-alert-text__success" : "")
                                                + " type-alert-text type-small-body type-alert-text__no-margin-left__mobile"}
                                            >
                                                {props.paid ? "Paid" : "Due Now"}
                                            </span>
                                    </span>
                                    <AmountDueSpan discountAmount={props.discountAmount}
                                                   retailStartupFee={props.retailStartupFee}/>
                                </p>
                                <p className="type-normal-body spacing-30-bottom">
                                    {props.paid
                                        ? ("Thanks for your one-time payment of " + formatCurrency(props.startupFee) +
                                            " to activate your account. We charged your credit card ending in " +
                                            props.lastFour +
                                            " and we've emailed you a receipt for your records.")
                                        : "This is a one-time payment of " + formatCurrency(props.startupFee) + " to activate your account. "
                                    }
                                </p>
                            </>}

                            {props.monthlyFee > 0 &&
                            <>
                                <p className="type-normal-subhead spacing-10-bottom">
                                    <span className="type-force-newline type-heavy">Monthly Subscription</span>
                                    <span className="type-force-newline">{formatCurrency(props.monthlyFee)}/month</span>
                                </p>
                                <p className="type-normal-body spacing-30-bottom">
                                    Your first monthly subscription will be charged one (1) month after your Campaigns
                                    are Ready (you will receive an email when your Campaigns are Ready).
                                </p>
                            </>}

                            <p className="type-normal-subhead spacing-10-bottom">
                                <span className="type-force-newline type-heavy">
                                    Pay Per Lead Pricing{' '}
                                    <Tooltip
                                        content={
                                            <>
                                                <span
                                                    className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height spacing-10-bottom">
                                                    Pay Per Lead Pricing
                                                </span>
                                                <span
                                                    className="type-small-body type-black type-force-newline type-narrow-line-height spacing-10-bottom">
                                                    You are responsible for paying for Billable Leads at the Cost Per Lead that is set for each Campaign
                                                    at the time when the lead is delivered. Once we’ve launched your Campaigns, you can adjust the
                                                    Cost Per Lead of your Campaigns at anytime in your mySD Campaign Settings.
                                                </span>
                                                <span
                                                    className="type-small-body type-black type-force-newline type-narrow-line-height spacing-10-bottom">
                                                    You will be charged when your Total Lead Costs reach your Billing Threshold.
                                                    You can see what your Billing Threshold is set at in your mySD Account Settings.
                                                </span>
                                                <span
                                                    className="type-small-body type-black type-force-newline type-narrow-line-height spacing-10-bottom">
                                                    Read more about our &nbsp;
                                                    <a className="type-heavy"
                                                       href="https://mysd-account-activation.helpscoutdocs.com/article/159-how-cost-per-lead-affects-lead-volume"
                                                       target="_BLANK">
                                                        Flexible Pay Per Lead Pricing
                                                    </a>
                                                </span>
                                            </>
                                        }
                                    />
                                </span>
                                <span className="cpl-values padding-10-top">
                                    <IndustryLeadCost tenderData={props.tenderData}
                                                      updateTender={props.updateTender}
                                                      clientCanEditCpl={props.clientCanEditCpl}/>
                                </span>
                            </p>

                            <p className="type-normal-body">
                                You are responsible for paying for Billable leads at the Cost Per Lead that is set for
                                each Campaign.
                            </p>
                        </div>
                        <div className="threequarter-content__sidebar">
                            <div className="tip-sidebar">
                                <p className="type-small-body type-heavy no-bottom-margin">
                                    Payment Methods
                                </p>
                                <p className="type-small-body no-margin-top">
                                    You can verify or change your payment methods in your settings after account setup
                                    is complete.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col omega grid-2 empty"/>
        <div className="clear-block"/>
    </div>;
};

PricingSummary.propTypes = {
    startupFee: PropTypes.number.isRequired,
    monthlyFee: PropTypes.number.isRequired,
    lastFour: PropTypes.string.isRequired,
    paid: PropTypes.bool.isRequired,
    discountAmount: PropTypes.number.isRequired,
    retailStartupFee: PropTypes.number.isRequired,
    tenderData: PropTypes.array.isRequired,
    updateTender: PropTypes.func.isRequired,
    clientCanEditCpl: PropTypes.bool,
};

export default PricingSummary;
