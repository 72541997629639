import React, {Component} from 'react';

import BulkEditCampaignsStep2Footer from './BulkEditCampaignsStep2Footer';
import Tooltip from '../Tooltip';
import MultiSelectList from '../components/form_elements/MultiSelectList';
import {CampaignTypes} from "../StaticValues";
import RadioGroup from "../components/form_elements/RadioGroup";
import {CallerIdTooltip} from "../TooltipsCopy";
import {adjustCallerIdOptions} from "../../UtilJsx";

export default class BulkEditCampaignsStep2CallerID extends Component {
    constructor(props) {
        super(props);

        this.state = {
            campaignsList: [],
            isMarketplace: false,
            callerIdOptions: []
        }
    }

    componentDidMount() {
        // check if at least one campaign is a Marketplace campaign; we only allow one type of campaigns to be edited at once
        let isMarketplace;

        const campaignsList = [];
        this.props.campaigns.forEach((campaign, index) => {
            // if the campaign is not selected, ignore it
            if (!this.props.selectedCampaignIds[campaign.campaign_id]) {
                return;
            }

            isMarketplace = campaign.type === CampaignTypes.MARKETPLACE;

            // collect the select campaigns names
            const callerIdText = this.props.getOptionValueTxt('caller_id_option', campaign.caller_id_option);
            campaignsList.push(<div key={index}
                                    className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                <span className="type-large-body type-force-newline type-heavy">
                    {typeof (campaign.company_name) !== 'undefined'
                        ? <>{campaign.company_name} &mdash; </>
                        : ''}
                    {campaign.campaign_name}
                </span>
                <span className="type-normal-body type-force-newline">Caller ID Preference: {callerIdText}</span>
            </div>)
        })

        const callerIdOptions = adjustCallerIdOptions(this.props.callerIdOptions, isMarketplace);

        this.setState({campaignsList, isMarketplace, callerIdOptions})
    }

    render() {
        return (
            <>
                <div className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    <p className="type-large-body">
                        You are Bulk Editing the <b>Caller ID Preference</b> for the Campaigns listed below.
                    </p>
                    <p className="type-normal-body">
                        Their current settings are noted individually, and if you choose new settings, all Campaigns
                        will be updated to those.
                    </p>
                </div>
                <div className="spacing-60-bottom-full spacing-60-bottom-tablet spacing-40-bottom-mobile">
                    {this.state.campaignsList}
                </div>

                <p className="type-large-body type-heavy type-single-line type-bottom-stroke no-margin-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    NEW SETTINGS (Replaces current settings)
                </p>

                <div className="spacing-40-bottom-full spacing-40-bottom-tablet spacing-30-bottom-mobile">
                    <div className="spacing-20-bottom">
                        <div className="type-large-body type-heavy type-single-line no-margin">
                            Caller ID Preference{' '}
                            <Tooltip
                                content={CallerIdTooltip(!this.state.isMarketplace)}
                                position="right"
                            />
                        </div>
                    </div>

                    <RadioGroup
                        label="Caller ID Setting"
                        name="callerIdSetting"
                        vertical={true}
                        isClearable={false}
                        checkedOption={this.props.formData.caller_id_option}
                        options={this.state.callerIdOptions}
                        onChange={(name, value) => this.props.updateCallerIdSettingRadio(value)}
                    />
                    {/* display the campaign caller id phone number options for Select campaigns only */}
                    {!this.state.isMarketplace &&
                        <div className="spacing-10-bottom" style={{maxWidth: '390px', marginLeft: '2em'}}>
                            <MultiSelectList
                                emptyLabel="Select Tracking Number"
                                options={this.props.callerIdTrackingPhoneOptions}
                                required={false}
                                selections={this.props.formData.caller_id_phone_id ? [this.props.formData.caller_id_phone_id] : []}
                                disabled={this.props.formData.caller_id_option !== '10'}
                                onChange={(vals) => this.props.updateTrackingPhoneSetting(vals[0])}
                            />
                        </div>}
                </div>

                <BulkEditCampaignsStep2Footer
                    commitSettings={this.props.commitSettings}
                    updateModalContent={this.props.updateModalContent}
                    updateModalHeader={this.props.updateModalHeader}
                    confirmationHeader="Caller ID Confirmation"
                />
            </>
        );
    }
}