import React from 'react'
import styled from 'styled-components'
import DefaultSecondaryNav from './DefaultSecondaryNav'

const Wrapper = styled.div`
    /* display: flex; */
    min-height: calc(100vh - 76px - 1.290322581rem);
    max-width: 100%;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 97%;
    margin: auto;
`

export default (props) => {
    const { title, children, filterMessage, secondaryNavRef } = props
    const titleClassNames =
        typeof title === 'string'
            ? 'type-normal-headline type-heavy padding-20-bottom'
            : ''
    const rowClassNames =
        typeof title === 'string'
            ? 'row padding-40-top padding-20-bottom'
            : 'row padding-40-top'
    return (
        <Wrapper className="page-width">
            {secondaryNavRef && (
                <DefaultSecondaryNav
                    filterMessage={filterMessage}
                    secondaryNavRef={secondaryNavRef}
                />
            )}
            {title && (
                <div className={rowClassNames}>
                    <div className="wide-format-col">
                        <div className="simpleflex__row">
                            <div className="simpleflex__cell">
                                {title && <h2 className={titleClassNames}>{title}</h2>}
                            </div>
                        </div>
                    </div>
                    <div className="clear-block" />
                </div>
            )}

            <ContentWrapper>{children}</ContentWrapper>
        </Wrapper>
    )
}
