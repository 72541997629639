import React from 'react';

const CoreSection = (props) => {
	const emptyHeader = !props.headerLabel && !props.header;
	
	let sectionClasses = `onboarding-section onboarding-core-section ${emptyHeader ? 'has-empty-header' : ''}`;
	if (props.extraClasses) {
		sectionClasses = `${sectionClasses} ${props.extraClasses}`;
	}
	
	
	return (
		<div className={sectionClasses}>
			{!emptyHeader && (
				<div className="onboarding-core-section__header">
					<h3 className="type-large-subhead">
						{(props.headerLabel && (
							<span className="type-heavy">{props.headerLabel}:&nbsp;</span>
						))}
						{(props.header && (
							<span>{props.header}</span>
						))}
					</h3>
				</div>
			)}
			<div className="onboarding-core-section__content onboarding-section__content">
				{props.children}
			</div>
		</div>
	);
};

export default CoreSection;