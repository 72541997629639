import React from 'react';

import {inputObj, handleCheckboxClick} from '../formHelpers';
import PopUpModalInputSection from './PopUpModalInputSection';
import Tooltip from './Tooltip';
import FormModal from '../common/components/modal/FormModal';
import AuthService from "../service/AuthService";
import {CALL_RECORDING_TEXT, CampaignTypes, handleCallRecordingUncheck} from "./StaticValues";
import MultiSelectList from './components/form_elements/MultiSelectList';
import RadioGroup from "./components/form_elements/RadioGroup";
import {CallerIdTooltip} from "./TooltipsCopy";
import {adjustCallerIdOptions} from "../UtilJsx";

const RECORD_CALLS = 1;

// NOTE: This is a reverted version of this modal.
// Layout for the upcoming version has been moved to ./LeadDeliverySettingsModal-Upcoming.js

/**
 * LeadDeliverySettingsModal
 *
 * Extends FormModal (subclass of React.Component) in order to get desired
 * scroll-to-top behavior each time the modal is opened.
 */
export default class LeadDeliverySettingsModal extends FormModal {
    constructor(props) {
        super(props);

        let campaign = this.props.campaign;
        let phones = campaign.phones?.map(phone => inputObj(phone.phone_number));
        let emails = campaign.emails.map(email => inputObj(email.email_address));
        let smsPhones = campaign.lead_notification_phones.map(phone => inputObj(phone.phone_number));
        let callRecordingSetting = Number(campaign.call_recording_setting) === RECORD_CALLS;
        let callerIdSetting = campaign.caller_id_option;

        // get dropdown options for Caller ID dropdown
        let callerIdTrackingPhoneNumberDropdownOptions = this.props.callerIdTrackingPhoneOptions;
        // if we have a currently selected caller_id_phone_id which is NOT in the dropdown options
        // add a new option to the dropdown so it's clear what's currently selected and so we don't accidentally
        // erase it when we save
        for (let i = 0; i < callerIdTrackingPhoneNumberDropdownOptions.length; i++) {
            let thisOption = callerIdTrackingPhoneNumberDropdownOptions[i];
            if (campaign.tracking_phone.length > 0 && thisOption.value === campaign.tracking_phone[0].phone_id) {
                thisOption.text += ' (This campaign)';
            }
        }

        if (callerIdSetting === '10' && campaign.caller_id_phone_id) {
            // loop through current options and verify whether we already have the right option
            let foundSelectedPhoneId = false;
            for (let i = 0; i < callerIdTrackingPhoneNumberDropdownOptions.length; i++) {
                let thisOption = callerIdTrackingPhoneNumberDropdownOptions[i];
                if (thisOption.value === campaign.caller_id_phone_id) {
                    foundSelectedPhoneId = true;
                }
            }
            if (!foundSelectedPhoneId) {
                callerIdTrackingPhoneNumberDropdownOptions.push({
                    text: campaign.caller_id_phone_number + ' -  (not on any campaign)',
                    value: campaign.caller_id_phone_id,
                    phone: campaign.caller_id_phone_number,
                });
            }
        }

        this.state = {
            campaign: this.props.campaign,

            leadDeliveryPhones: phones?.length ? phones : [inputObj()],
            leadDeliveryEmails: emails.length ? emails : [inputObj()],
            leadDeliverySmsPhones: smsPhones.length ? smsPhones : [inputObj()],
            callerIdSetting: callerIdSetting,
            callerIdPhoneId: campaign.caller_id_phone_id,

            inputs: {
                recordCallsCheckBox: inputObj(callRecordingSetting),
            },

            //
            // Set to true when form is submitting in order to
            // visually disable the submit button
            //
            submitting: false,

            callerIdTrackingPhoneNumberDropdownOptions: callerIdTrackingPhoneNumberDropdownOptions,
        }
    }

    /**
     * updates the caller id selected option and affected settings
     * @param name passed via the RadioGroup component but is not needed in this case
     * @param {number} callerIdSetting the caller id setting
     */
    updateCallerIdSettingRadio = (name, callerIdSetting) => {
        const data = {callerIdSetting}

        // if not the campaign tracking number option, reset the caller phone id
        if (callerIdSetting != 10) {
            data.callerIdPhoneId = null;
        }

        this.setState(data);
    };

    addAnother = (collectionName) => {
        let stateClone = {...this.state};
        stateClone[collectionName].push(inputObj());
        this.setState(stateClone);
    };

    deleteInput = (collectionName, key) => {
        let stateClone = {...this.state};
        stateClone[collectionName].splice(key, 1);
        this.setState(stateClone);
    };

    onCheckboxClick = (event) => {
        let stateClone = {...this.state};

        handleCallRecordingUncheck(event.target.checked, this.props.updateMessageBlocks);
        stateClone = handleCheckboxClick(stateClone, event);
        this.setState(stateClone);
    };

    /*
     *@TODO factor this!
     */
    handleInputChange = (event, collectionName, key) => {
        let elem = event.target;
        let stateClone = {...this.state};
        let collection = [...stateClone[collectionName]];
        collection[key].value = elem.value;
        this.setState(stateClone);
    };

    render() {
        let phones = [...this.state.leadDeliveryPhones];
        const phoneInputs = (
            <PopUpModalInputSection
                collection={phones}
                collectionName="leadDeliveryPhones"
                name="callLeadNumbers"
                handleInputChange={this.handleInputChange}
                addAnother={this.addAnother}
                deleteInput={this.deleteInput}
            />
        );
        let emails = [...this.state.leadDeliveryEmails];
        const emailInputs = (
            <PopUpModalInputSection
                collection={emails}
                collectionName="leadDeliveryEmails"
                name="callAndFormLeadEmails"
                handleInputChange={this.handleInputChange}
                addAnother={this.addAnother}
                deleteInput={this.deleteInput}
            />
        );
        let smsPhones = [...this.state.leadDeliverySmsPhones];
        const smsPhoneInputs = (
            <PopUpModalInputSection
                collection={smsPhones}
                collectionName="leadDeliverySmsPhones"
                name="callAndFormLeadSmsPhones"
                handleInputChange={this.handleInputChange}
                addAnother={this.addAnother}
                deleteInput={this.deleteInput}
            />
        );

        const callerIdRadioOptions = adjustCallerIdOptions(this.props.callerIdOptions, this.state.campaign.type === CampaignTypes.MARKETPLACE);

        return <form>
            <div className="popup__form__row">
                <div
                    className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile popup__form__cell__top-align">
                    <div className="type-large-body type-heavy type-single-line no-margin-top spacing-20-bottom-full spacing-20-bottom-tablet spacing-20-bottom-mobile">
                        LEAD DELIVERY:{' '}
                        <span className="type-no-break">
                            PHONE CALLS{' '}
                            <Tooltip
                                content={(
                                    <>
                                        <span
                                            className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                            Lead Delivery for Phone Calls
                                        </span>

                                        <span
                                            className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            These are the phone numbers that will receive Phone Leads.
                                            When a potential customer visits your Campaign landing page and dials the Campaign's
                                            associated local tracking number, the phone numbers listed in your Phone Lead Delivery
                                            settings will ring. You are responsible for answering these calls from Phone Leads to qualify the opportunity.
                                        </span>
                                    </>
                                )}
                                position={'bottom'}
                            />
                        </span>
                    </div>
                    <div
                        className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile qa-lead-phone-calls">
                        <label htmlFor="popup-call-leads"
                               className="type-normal-body type-heavy type-narrow-line-height type-force-newline spacing-5-bottom">
                            The following phone numbers will receive Phone Leads:
                        </label>
                        {phoneInputs}
                    </div>

                    <div className="spacing-30-bottom">
                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                checked={this.state.inputs.recordCallsCheckBox.value}
                                onChange={this.onCheckboxClick}
                                name="recordCallsCheckBox"
                                id="popup-record-calls"
                            />
                            <label htmlFor="popup-record-calls">
                                <span className="checkbox">
                                    Record Phone Calls & Enable Lead Review
                                </span>
                            </label>
                            {' '}
                            <Tooltip
                                position={"bottom"}
                                content={(
                                    <>
                                        <span
                                            className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                            Phone Call Recording & Lead Review
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            You must opt in to activate Call Recording. Call Recording is required to
                                            be eligible for Lead Review and if this option is not selected,
                                            all Leads longer than 20 seconds are considered Billable.
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                            Learn more in{' '}
                                            <a className="type-heavy" target="_blank"
                                               href={AuthService.isMarketplace
                                                   ? "https://support.servicedirect.com/billable-and-non-billable-leads"
                                                   : "https://support.servicedirect.com/select/billable-and-non-billable-leads"}>
                                                Understanding Billable and Non-Billable Leads.</a>
                                        </span>
                                    </>
                                )}
                            />
                        </div>
                        <p className="type-normal-body spacing-10-top">
                            <span className="type-heavy">
                                REQUIRED for Lead Review.
                            </span>{' '}
                            {CALL_RECORDING_TEXT}
                        </p>
                    </div>

                    <div className="spacing-20-bottom">
                        <div className="type-large-body type-heavy type-single-line-full type-single-line-tablet spacing-40-top spacing-20-bottom-full spacing-20-bottom-tablet spacing-20-bottom-mobile">
                            CAMPAIGN TRACKING NUMBER {' '}
                            <span> </span>
                            <Tooltip
                                position={"right"}
                                content={(
                                    <>
                                                <span
                                                    className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                    Campaign Tracking Number
                                                </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                <p className="spacing-10-bottom">Each Campaign has its own Tracking Number which is used in advertising for your company.</p>
                                                <p className="spacing-10-bottom">When a caller dials this Tracking Number, the call routes to your company according to the Lead Delivery: Phone Calls Settings.</p>
                                                <p className="spacing-10-bottom">Tracking Numbers are not editable, however you can choose to use any of your Tracking Numbers as Displayed Caller ID.</p>
                                            </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                                    See here for more information.{' '}
                                            <a className="type-heavy" target="_blank"
                                               href={AuthService.isMarketplace
                                                   ? "https://support.servicedirect.com/lead-delivery-notifications"
                                                   : "https://support.servicedirect.com/select/lead-delivery-notifications"}>
                                                        Lead Delivery Settings.
                                                </a>
                                            </span>
                                    </>
                                )}
                            />
                        </div>
                        <label
                            className="type-normal-body type-narrow-line-height type-force-newline spacing-10-top qa-lead-phone-calls">
                            {this.state.campaign.tracking_phone.length
                                ? this.props.campaign.tracking_phone[0].phone_number
                                : 'Number not set'}
                        </label>
                    </div>
                    <div className="spacing-20-bottom">
                        <div className="type-large-body type-heavy type-single-line-full type-single-line-tablet spacing-40-top spacing-20-bottom-full spacing-20-bottom-tablet spacing-20-bottom-mobile">
                            DISPLAYED CALLER ID {' '}
                            <span> </span>
                            <Tooltip
                                position="right"
                                content={CallerIdTooltip()}
                            />
                        </div>
                        <label className="type-normal-body type-narrow-line-height type-force-newline spacing-5-bottom">
                            You can choose to see the Caller ID information of the incoming caller,
                            or you may choose to see a Tracking Number if you want to quickly recognize a Service Direct
                            sourced caller.
                        </label>
                    </div>
                    <RadioGroup
                        label="Caller ID Setting"
                        name="callerIdSetting"
                        vertical={true}
                        isClearable={false}
                        checkedOption={this.state.callerIdSetting}
                        options={callerIdRadioOptions}
                        onChange={this.updateCallerIdSettingRadio}
                    />
                    {/* display the campaign caller id phone number options for Select campaigns only */}
                    {this.state.campaign.type === CampaignTypes.WEBSITE_WITH_TRACKING &&
                    <div className="spacing-10-bottom" style={{maxWidth: '390px', marginLeft: '2em'}}>
                        <MultiSelectList
                            emptyLabel="Select Tracking Number"
                            selections={this.state.callerIdPhoneId ? [this.state.callerIdPhoneId] : []}
                            options={this.state.callerIdTrackingPhoneNumberDropdownOptions}
                            required={false}
                            wideDropdown={true}
                            onChange={(vals) => this.setState({callerIdPhoneId: vals[0]})}
                            disabled={this.state.callerIdSetting != 10}
                        />
                    </div>}
                </div>
                <div
                    className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile popup__form__cell__top-align qa-lead-notifications">
                    <div
                        className="type-large-body type-heavy type-single-line-full type-single-line-tablet no-margin-top spacing-20-bottom-full spacing-20-bottom-tablet spacing-20-bottom-mobile">
                        LEAD DELIVERY: FORM LEADS &{' '}
                        <div className="type-no-break">
                                LEAD NOTIFICATIONS{' '}
                            <Tooltip
                                content={(
                                    <>
                                            <span
                                                className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                Form Lead Delivery & Lead Notifications
                                            </span>
                                        <div
                                            className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            These email addresses receive notifications for:
                                            <ul>
                                                <li>
                                                    Form Leads - when a potential customer fills out a form to contact
                                                    your company.
                                                </li>
                                                <li>
                                                    Phone Call Leads - notification is sent after the call completes.
                                                </li>
                                            </ul>
                                        </div>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                                Learn more about{' '}
                                            <a className="type-heavy" target="_blank"
                                               href={AuthService.isMarketplace
                                                   ? "https://support.servicedirect.com/lead-delivery-notifications"
                                                   : "https://support.servicedirect.com/select/lead-delivery-notifications"}>
                                                    Lead Delivery Settings.</a>
                                            </span>
                                    </>
                                )}
                                position="bottom"
                            />
                            </div>
                    </div>
                    <label
                        className="type-normal-body type-heavy type-narrow-line-height type-force-newline spacing-5-bottom">
                        The following email address(es) will receive inbound Form Leads,
                        as well as email notifications for all Leads (both Form and Phone Leads).
                    </label>
                    {emailInputs}
                    <div className="type-large-body type-heavy type-single-line-full type-single-line-tablet spacing-40-top spacing-20-bottom-full spacing-20-bottom-tablet spacing-20-bottom-mobile">
                        TEXT MESSAGE NOTIFICATIONS {' '}
                        <Tooltip
                            content={(
                                <>
                                    <span
                                        className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                        Text Message Notifications
                                    </span>
                                    <span
                                        className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                        Receive a text message to notify you every time you receive a new lead. Standard text messaging rates may apply.
                                        </span>
                                    <span
                                        className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                            Learn more about{' '}
                                        <a className="type-heavy" target="_blank"
                                           href={AuthService.isMarketplace
                                               ? "https://support.servicedirect.com/lead-delivery-notifications"
                                               : "https://support.servicedirect.com/select/lead-delivery-notifications"}>
                                            Lead Delivery Settings.
                                        </a>
                                    </span>
                                </>
                            )}
                            position="left"
                        />
                    </div>
                    <label
                        className="type-normal-body type-heavy type-narrow-line-height type-force-newline spacing-5-bottom qa-lead-phone-calls">
                        The following mobile numbers will receive a text message when a Form Lead is delivered or a
                        Phone Lead is completed.
                    </label>
                    {smsPhoneInputs}
                </div>
            </div>
            <div className="ui-hide-full ui-hide-tablet">
                <div className="popup__form__row spacing-40-top-mobile">
                    <div className="popup__form__cell popup__form__cell__100__mobile">
                        <button
                            className="button ui-normal-button ui-full-width-button"
                            disabled={this.state.submitting}
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.submitLeadDeliverySettings(
                                    this.state, this.props.campaignIndex
                                );
                                return false;
                            }}
                        >
                            Save Lead Delivery Settings
                        </button>
                    </div>
                </div>
            </div>
            <div className="ui-hide-mobile">
                <div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
                    <div className="popup__form__row popup__form__row__slam-right">
                        <div className="popup__form__cell">
                            {/* todo add some juice here since this request takes a lil bit of time*/}
                            <button
                                className="button ui-normal-button"
                                disabled={this.state.submitting}
                                onClick={(event) => {
                                    event.preventDefault();

                                    // Set submitting = true so that submit button will be disabled
                                    this.setState({submitting: true});

                                    this.props.submitLeadDeliverySettings(this.state, this.props.campaignIndex)
                                        .finally(() => this.setState({submitting: false}));
                                    return false;
                                }}
                            >
                                Save Lead Delivery Settings
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>;
    }
}
