import React, {Component} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import axios from 'axios';

import {del, get, post, put} from '../../Requests';
import MessageBlocks from '../../common/MessageBlocks';
import Modal from "../../common/Modal";
import EditServiceModal from "./EditServiceModal";
import EditIndustryModal from "./EditIndustryModal";
import {PencilSvg, PlusSignSvg} from '../../common/Svgs';

class IndustryDetail extends Component {
    constructor(props) {
        super(props);
        document.title = 'Service Category';

        this.state = {
            industryId: props.match.params.industry_id,
            industry: {},
            industry_services: [],
            messages: [],
            messageType: ''
        };

        this.cancelSignal = axios.CancelToken.source();
    }

    componentDidMount() {
        this.getData();
    }

    updateModalContent = (modalContent, modalHeader, modalWidth, modalFlatBottom) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom
        });
    };

    /**
     * Submits an industry to the API, then updates local state before
     * rerendering.  Thus far assumes we're only supporting editing an industry,
     * b/c I'm not sure if adding an industry should be supported as easily as
     * a modal w/no prereq data or decision requirements.
     */
    submitIndustry = (industry) => {
        let url = "industries/" + industry.industry_id,
            data = {
                industry_id: industry.industry_id,
                default_min_tender: industry.default_min_tender,
                min_suggested_tender: industry.min_suggested_tender,
                max_suggested_tender: industry.max_suggested_tender
            };

        put(url, data).then(resp => {

            if (resp.status == 200) {

                let industryClone = {...this.state.industry};
                industryClone.default_min_tender = industry.default_min_tender;
                industryClone.min_suggested_tender = industry.min_suggested_tender;
                industryClone.max_suggested_tender = industry.max_suggested_tender;

                this.setState({industry: industryClone});
            }

            this.handleApiResponse(resp);
        });
    };

    /**
     * Event handler for the Remove Service button.  Deletes the service via
     * DELETE end point, then removes it from the view's local state.
     * @param {Object} service
     */
    removeService = (service) => {
        let url = `industry_services/${service.industry_service_id}`;

        del(url).then(resp => {

            if (resp.status == 200) {

                let industryServices = [...this.state.industry_services];

                for (let i = 0; i < industryServices.length; i++) {
                    let thisService = industryServices[i];

                    if (thisService.industry_service_id == service.industry_service_id) {
                        industryServices.splice(i, 1);
                        break;
                    }
                }

                this.setState({industry_services: industryServices});
            }

            this.handleApiResponse(resp);
        });
    };

    /**
     * Submits a service to the API, then updates the service in this view's
     * local state.
     */
    submitService = (service) => {
        let industryServiceId = service ? service.industry_service_id : null,
            url = "industry_services",
            data = {
                industry_id: service.industry_id,
                name: service.name,
                subcategory: service.subcategory,
                is_required: service.is_required
            };

        if (service.industry_service_id) {
            data["industry_service_id"] = service.industry_service_id;
        }

        if (!industryServiceId) {
            post(url, data).then(resp => {

                if (resp.status == 200) {

                    let industryServices = [...this.state.industry_services];
                    industryServices.push(resp.data.data.industry_services[0]);
                    this.setState({industry_services: industryServices});
                }

                this.handleApiResponse(resp);
            });
        }
        else {
            url += "/" + industryServiceId;

            put(url, data).then(resp => {

                if (resp.status == 200) {

                    let industryServices = [...this.state.industry_services];

                    for (let i = 0; i < industryServices.length; i++) {
                        let thisService = industryServices[i];

                        if (thisService.industry_service_id == service.industry_service_id) {
                            industryServices[i] = resp.data.data.industry_services[0];
                            break;
                        }
                    }

                    this.setState({industry_services: industryServices});
                }

                this.handleApiResponse(resp);
            });
        }
    };

    handleApiResponse = (resp) => {
        let returnStatus = resp.status,
            message,
            messageType;

        if (returnStatus == 200) {
            message = resp.data.message;
            messageType = "success";
        }
        else {
            message = resp.data.errors[0].message;
            messageType = "error";
        }

        this.props.updateMessageBlocks(message, messageType);
        this.updateModalContent();
    };

    getData = () => {
        let url = `industries/${this.state.industryId}`;

        get(url, this.cancelSignal.token)
            .then(resp => {
                // TODO add pop modal here
                if (resp.status !== 200) {
                    return;
                }

                const industry = resp.data.data.industries[0];
                document.title = `Service Category: ${industry.name}`;

                this.setState({
                    industry,
                    industry_services: resp.data.data.industry_services,
                });
            });
    };

    getServiceRows = () => {
        let rows = this.state.industry_services;

        if (!rows.length) {
            return (
                <tr>
                    <td colSpan={4}>Service Category {this.state.industry.name} has no services.</td>
                </tr>
            );
        }

        let that = this;

        return this.state.industry_services.map((row, key) => {
            return (
                <tr key={key}>
                    <td>{row.name}</td>
                    <td>{row.subcategory}</td>
                    <td>{row.is_required == "1" ? "Yes" : "No"}</td>
                    <td>
                        <div className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-24-bottom-mobile">
                            <p className="type-small-body type-single-line">
                                <span className="type-heavy type-blue" role="button"
                                      onClick={() => {
                                          that.updateModalContent(
                                              <EditServiceModal
                                                  industry_id={row.industry_id}
                                                  industry={that.state.industry}
                                                  service={row}
                                                  updateMessageBlocks={that.props.updateMessageBlocks}
                                                  updateModalContent={that.updateModalContent}
                                                  removeService={that.removeService}
                                                  submitService={that.submitService}
                                              />,
                                              "Edit Service",
                                              "normal",
                                              true
                                          );
                                      }}>
                                    <span className="inline-icon inline-icon__16 inline-icon__16__10">
                                        {PencilSvg}
                                    </span>
                                    Edit
                                </span>
                            </p>
                        </div>
                    </td>
                </tr>
            )
        });
    };

    render() {

        let industry = this.state.industry,
            serviceRows = this.getServiceRows();

        return (
            <div className="interface-container">
                <Modal
                    content={this.state.modalContent}
                    header={this.state.modalHeader}
                    flatBottom={this.state.modalFlatBottom}
                    updateModalContent={this.updateModalContent}
                    size="small"
                />

                <MessageBlocks
                    messages={this.state.messages}
                    type={this.state.messageType}
                    clearErrors={() => {
                        this.setState({messages: [], messageType: ""})
                    }}
                />
                <section className="white-background">
                    <div className="page-width">
                        <div className="row padding-50">
                            <div className="col alpha omega grid-12">
                                <NavLink to="/industries" className="header_back_link">
                                    {"< "}Back to Service Categories
                                </NavLink>
                                <h2 className="type-normal-headline type-heavy">
                                    Service Category: {industry.name}
                                </h2>
                            </div>
                            <div className="clear-block"/>
                        </div>
                        <div className="row padding-120-bottom">
                            <div className="col alpha omega grid-12 padding-50-bottom">
                                <div className="module">
                                    <div
                                        className="module__header module__fullwidth">
                                        <h3 className="type-large-subhead type-single-line">
                                            Tender
                                        </h3>
                                    </div>
                                    <div
                                        className="module__contentbox module__fullwidth">
                                        <table
                                            className="statements__table standardtable">
                                            <thead
                                                className="standardtable__lightheader">
                                            <tr className="type-small-body type-heavy">
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        Default Min Tender
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        Min Suggested Tender
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        Max Suggested Tender
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        &nbsp;
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{industry.default_min_tender}</td>
                                                <td>{industry.min_suggested_tender}</td>
                                                <td>{industry.max_suggested_tender}</td>
                                                <td>
                                                    <div
                                                        className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-24-bottom-mobile">
                                                        <p className="type-small-body type-single-line">
                                                            <span className="type-heavy type-blue" role="button"
                                                                  onClick={() => {
                                                                      this.updateModalContent(
                                                                          <EditIndustryModal
                                                                              industry_id={this.state.industry.industry_id}
                                                                              industry={this.state.industry}
                                                                              updateMessageBlocks={this.props.updateMessageBlocks}
                                                                              updateModalContent={this.updateModalContent}
                                                                              submitIndustry={this.submitIndustry}
                                                                          />,
                                                                          "Edit Service Category",
                                                                          "normal",
                                                                          true
                                                                      );
                                                                  }}>
                                                                <span
                                                                    className="inline-icon inline-icon__16 inline-icon__16__10">
                                                                    {PencilSvg}
                                                                </span>
                                                                Edit
                                                            </span>
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row padding-120-bottom">
                            <div className="col alpha omega grid-12">
                                <div className="module">
                                    <div
                                        className="module__header module__fullwidth">
                                        <h3 className="type-large-subhead type-single-line">
                                            Services
                                        </h3>
                                    </div>
                                    <div
                                        className="module__contentbox module__fullwidth">
                                        <table className="statements__table standardtable spacing-20-bottom">
                                            <thead
                                                className="standardtable__lightheader">
                                            <tr className="type-small-body type-heavy">
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        Name
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        Subcategory
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        Required
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="statements__header__label">
                                                        &nbsp;
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                serviceRows
                                            }
                                            </tbody>
                                        </table>
                                        <div
                                            className="simpleflex__row simpleflex__row__wrap__mobile">
                                            <div className="simpleflex__cell">
                                                <p className="type-small-body">
                                                    <span role="button" className="type-heavy type-blue"
                                                          onClick={() => {
                                                              this.updateModalContent(
                                                                  <EditServiceModal
                                                                      industry_id={this.state.industry.industry_id}
                                                                      industry={this.state.industry}
                                                                      service={null}
                                                                      updateMessageBlocks={this.props.updateMessageBlocks}
                                                                      updateModalContent={this.updateModalContent}
                                                                      removeService={this.removeService}
                                                                      submitService={this.submitService}
                                                                  />,
                                                                  "Add Service",
                                                                  "normal",
                                                                  true
                                                              );
                                                          }}>
                                                        <span
                                                            className="inline-icon inline-icon__middle inline-icon__20">
                                                            {PlusSignSvg}
                                                        </span>
                                                        Add Another Service
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clear-block"/>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withRouter(IndustryDetail);
