import React from 'react'
import Button from '../../../../common/components/form_elements/Button'
import {
    formatCurrency,
    formatDate,
    formatNumber,
    formatPhoneNumber,
    // toTitleCase,
} from '../../../../Util'

const defaultOptions = {
    summaryLoading: false,
}
export default (col, options = defaultOptions, onClick) => {
    const {
        property,
        label,
        format = 'string',
        width,
        tooltip,
        informational_message,
        ...rest
    } = col
    const formatMap = {
        currency: (col) => ({
            valueGetter: (params) => +params?.node?.data[property] || 0,
            field: property,
            // valueFormatter: ({ value = 0 }) => formatCurrency(value),
            cellRenderer: (params) => {
                let spanClass = 'type-normal-body'
                let val
                if (params?.node?.data[property] != null) {
                    val = formatCurrency(params?.node?.data[property] || 0, 0)
                } else {
                    val = '--'
                }

                if (params.node?.data?.is_retired == '1') {
                    spanClass += ' type-disabled'
                }

                return <span className={spanClass}>{val}</span>
            },
        }),
        string: (col) => ({
            field: property,
            // to ensure proper sorting, convert to lowercase. ag-grid
            // sorts lowercase before uppercase by default
            valueGetter: (params) =>
                `${params?.node?.data[property]}`?.toLowerCase() || '',
            cellRenderer: (params) => {
                let spanClass = 'type-normal-body'
                // let val = toTitleCase(params?.node?.data[property] || '')
                let val = params?.node?.data[property]
                if (val == null) {
                    val = '--'
                    // val = 'N/A'
                }

                if (params.node?.data?.is_retired == '1') {
                    spanClass += ' type-disabled'
                }

                return <span className={spanClass}>{val}</span>
            },
        }),
        date: (col) => ({
            field: property,
            // to ensure proper sorting, convert to lowercase. ag-grid
            // sorts lowercase before uppercase by default
            valueGetter: (params) => `${params?.node?.data[property]}` || '',
            cellRenderer: (params) => {
                let spanClass = 'type-normal-body'
                let val = formatDate(params?.node?.data[property])
                if (val == null) {
                    val = '--'
                }
                if (params.node?.data?.is_retired == '1') {
                    spanClass += ' type-disabled'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        phone: (col) => ({
            field: property,
            // to ensure proper sorting, convert to lowercase. ag-grid
            // sorts lowercase before uppercase by default
            valueGetter: (params) => `${params?.node?.data[property]}` || '',
            cellRenderer: (params) => {
                let spanClass = 'type-normal-body'
                let val = '--'
                if (params?.node?.data[property]) {
                    val = formatPhoneNumber(params?.node?.data[property])
                }
                if (params.node?.data?.is_retired == '1') {
                    spanClass += ' type-disabled'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        boolean: (col) => ({
            field: property,
            valueGetter: (params) => params?.node?.data[property] || 0,
            cellRenderer: (params) => {
                let val
                const propertyVal = params?.node?.data[property]
                let spanClass = 'type-normal-body'
                if (propertyVal == null) {
                    val = '--'
                    // val = 'N/A'
                } else {
                    val = `${propertyVal}` == '1' ? 'Yes' : 'No'
                }
                if (params.node?.data?.is_retired == '1') {
                    spanClass += ' type-disabled'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        percent: (col) => ({
            field: property,
            valueGetter: (params) => +params?.node?.data[property] || 0,
            cellRenderer: (params) => {
                let spanClass = 'type-normal-body'
                let val
                if (params?.node?.data[property] != null) {
                    val = `${formatNumber(params?.node?.data[property] || 0, 2)} %`
                } else {
                    val = '--'
                    // val = 'N/A'
                }
                if (params.node?.data?.is_retired == '1') {
                    spanClass += ' type-disabled'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        decimal: (col) => ({
            field: property,
            valueGetter: (params) => +params.node.data[property] || 0,
            cellRenderer: (params) => {
                let spanClass = 'type-normal-body'
                let val

                if (params.node?.data?.is_retired == '1') {
                    spanClass += ' type-disabled'
                }
                // format the value for display
                if (params?.node?.data[property] != null) {
                    val = formatNumber(params.node.data[property] || 0, 2)
                } else {
                    val = '--'
                    // val = 'N/A'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        integer: (col) => ({
            field: property,
            valueGetter: (params) => +params?.node?.data[property] || 0,
            cellRenderer: (params) => {
                // case for the summary (pinned) row
                let spanClass = 'type-normal-body'
                let val
                if (params?.node?.data[property] != null) {
                    val = formatNumber(params?.node?.data[property] || 0, 0)
                } else {
                    val = '--'
                    // val = 'N/A'
                }
                if (params.node?.data?.is_retired == '1') {
                    spanClass += ' type-disabled'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        edit: (col) => ({
            valueGetter: (params) => params.data[property] || '',
            cellRenderer: (params) => {
                return (
                    <Button
                        variant="span"
                        size="large"
                        onClick={() => onClick(params.data.ad_network_id)}
                    >
                        {params.data[property]}
                    </Button>
                )
            },
        }),
    }

    return {
        field: property,
        headerName: label,
        ...formatMap[format](col),
        width: width || 120,
        ...rest,
    }
}
