import React, { Component } from 'react';

export default class BulkEditCampaignsStep3 extends Component {
    render() {

        let campaignsList = this.props.campaigns.map((campaign, idx) => {
            if (campaign.campaign_id in this.props.selectedCampaignIds) {
                return <span key={idx} className="type-heavy type-force-newline">
                    {typeof(campaign.company_name) !== 'undefined'
                        ? <>{campaign.company_name} &mdash; </>
                        : ''}
                    {campaign.campaign_name}
                </span>
            } else {
                return null;
            }
        });

        let changeList = this.props.changes.map((change, idx) => {
            //
            // Status changes: there's only one change, and Status is mentioned
            // in the subheadText; therefore, don't include it here.
            //
            if (change.label == 'Status') {
                if (change.value.includes('Pause')) {
                    return <p key={idx} className="type-normal-body">{change.value}
                        <span className="type-large-body type-force-newline spacing-10-top"><b>Note:</b> Campaigns can only be paused once per hour.</span></p>;
                }
                return <p key={idx} className="type-normal-body">{change.value}</p>;
            }
            return <p key={idx} className="type-normal-body">{change.label}: {change.value}</p>;
        });

        // add an id for cost per lead button, so hubspot can track the click
        let costPerLeadButtonId = this.props.editWhat === "cost_per_lead"
            ? "clicked-save-cost-per-lead-button"
            : undefined;

        return (
            <>
            <div className="spacing-40-bottom-full spacing-40-bottom-tablet spacing-30-bottom-mobile">
                <p className="type-large-body">
                    <span className="type-force-newline">{this.props.campaignLabelText}</span>
                    {campaignsList}
                </p>
            </div>
            <div className="spacing-40-bottom-full spacing-40-bottom-tablet spacing-30-bottom-mobile">
                <p className="type-large-body type-heavy">
                    {this.props.subheadText}
                </p>
                {changeList}
            </div>
            <div className="ui-hide-full ui-hide-tablet">
                <div className="popup__form__row spacing-40-top-mobile">
                    <div className="popup__form__cell popup__form__cell__100__mobile">
                        <button
                            className="button ui-normal-button ui-full-width-button qa-step3-confirm-button-mobile"
                            onClick={ () => {
                                this.props.submitChangesForReal();
                            } }
                            id = {costPerLeadButtonId}
                        >
                            Bulk Update Campaigns
                        </button>
                    </div>
                </div>
            </div>
            <div className="ui-hide-mobile">
                <div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
                    <div className="popup__form__row popup__form__row__slam-right">
                        <div className="popup__form__cell">
                            <span role="button" className="type-small-body type-heavy type-blue"
                                onClick={ () => {
                                    this.props.updateModalHeader('Bulk Edit Selected Campaigns');
                                    this.props.abandonConfirmation();
                                }}
                            >
                                Back to Previous Step
                            </span>
                        </div>
                        <div className="popup__form__cell">
                            <button
                                className="button ui-normal-button qa-step3-confirm-button-full"
                                onClick={ () => {
                                    this.props.submitChangesForReal();
                                } }
                                id = {costPerLeadButtonId}
                            >
                                Bulk Update Campaigns
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}
