import React, { Component } from 'react';
import axios from "axios";
import queryString from "query-string";

import _cloneDeep from 'lodash/cloneDeep';

import { PencilSvg, IconFilterSvg, IconSortSvg, IconSearchSvg, DownloadSvg } from '../../common/Svgs';
import {errorHandler, put} from "../../Requests";
import CampaignService from '../../service/CampaignService';
import CampaignRow from './CampaignRow';
import CampaignManagerSecondaryNav from './CampaignManagerSecondaryNav';
import Modal from '../../common/Modal';
import FilterCampaignsModal from './FilterCampaignsModal';
import SortCampaignsModal from './SortCampaignsModal';
import BulkEditCampaignsModalFrame from '../../common/BulkEditCampaignsModalFrame';
import HistoricalReportModal from './HistoricalReportModal';
import MultiSelectList from '../../common/components/form_elements/MultiSelectList';
import LoadingGif from "../../common/components/LoadingGif";
import MajorAlerts from '../../common/MajorAlerts';
import {parseMonetaryAmount} from '../../common/components/form_elements/MoneyInput';
import EnglishyList from '../../common/components/EnglishyList';
import TableTooFewResults from '../../common/components/TableTooFewResults';
import {CampaignTypes, textOfOption} from "../../common/StaticValues";
import type {Campaign} from "../../Interfaces/Campaign";
import type {ISubcategory} from "../../Interfaces/ISubcategory";

// Function deep copies filter objects so that new copies of the arrays are 
// assigned where spread operator on filter objects would only shallow copy
// references to existing arrays.
const deepCopyFilters = (filters) => {
    return {
        statuses: [...filters.statuses],
        industry_ids: [...filters.industry_ids],
        keyword: filters.keyword,
        contractor_ids: [...filters.contractor_ids],
        campaign_type: filters.campaign_type,
    };
};

export default class CampaignManagerView extends Component {

    LOADING_MESSAGE_MIN_DISPLAY_TIME_MS = 333;

    constructor(props) {
        super(props);

        //
        // set the initial state
        //
        let _state = {
            campaigns: [],
            missedOpportunities: [],

            campaignId: null,

            defaultFilters: {
                statuses: ["enabled","paused"],
                industry_ids: [],
                keyword: "",
                contractor_ids: [], // for parent contractors only
                campaign_type: '',
            },
            appliedFilters: {},
            selectedFilters: {},

            //
            // the value of the SearchField as you type: not always the same
            // as filters.keyword because we may not have submitted yet
            //
            searchFieldValue: '',

            sort_by: "most_leads_30d",


            //
            // Object with campaign_id's as keys and true as values
            // Correspond to the checkboxes to select campaign.
            // If a campaign is not selected, the key is absent.
            //
            selectedCampaignIds: {},

            //
            // true if contractor is paused for billing reasons, (via "Paused - Billing" tag)
            //
            contractorPausedBilling: false,



            //
            // Options for dropdowns etc
            //
            statusesOptions: [
                { "value": "enabled",   "text": "Enabled" },
                { "value": "paused",   "text": "Paused" },
                { "value": "canceled", "text": "Canceled" }
            ],
            industries: [],
            contractorOptions: [], // Parent Contractors only: "Child Client filter"
            callerIdOptions: [], // for Lead Delivery Settings Form

            //
            // When true, say "Loading your Campaigns..." instead of the Filter Description
            // and do not show the "Not finding what you're looking for?" section, even though
            // there are 0 results.
            //
            loadingResults: true,
            //
            // At minimum, we show the "Loading your Campaigns..." message for a certain amount
            // of time. This value is set to true when results start being loaded, and a
            // setTimeout resets it to false after a minimum delay. Ensures people get to see
            // the loading message instead of getting confused.
            //
            loadingMessageMinDelayTimer: false,
            //
            // The ID of the setTimeout() so we can clear it
            //
            loadingMessageMinDelayTimeoutId: undefined,

            modalContent: undefined,
            modalHeader: undefined,
            modalHeaderLink: undefined,
            modalWidth: undefined,
            modalFlatBottom: undefined,

            trackingPhoneDropdown: [],
        };
        _state.appliedFilters = deepCopyFilters(_state.defaultFilters);
        _state.selectedFilters = deepCopyFilters(_state.defaultFilters);

        this.state = _state;

        this.modalScrollerRef = React.createRef();
        this.modalContainerRef = React.createRef();
        this.campaignsTableRef = React.createRef();

        //
        // for axios/ajax: when a request needs to be canceled after sending it
        //
        this.cancelSignal = axios.CancelToken.source();

        this.campaignService = new CampaignService();
    }

    componentDidMount() {
        /* also gets the campaigns via the API */
        this.setAppliedFiltersFromUrl();
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
        this.campaignService.cancelSignal.cancel();
    }

    setAppliedFiltersFromUrl = () => {
        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'}),
            stateChanges = {};

        if (queryParams.campaign_id) {
            stateChanges.campaignId = queryParams.campaign_id;
        }
        else {
            stateChanges.appliedFilters = deepCopyFilters(this.state.appliedFilters);
            if (queryParams.contractor_ids) {
                stateChanges.appliedFilters.contractor_ids = queryParams.contractor_ids.split(',');
            }
            if (queryParams.industry_ids) {
                stateChanges.appliedFilters.industry_ids = queryParams.industry_ids.split(',');
            }
            if (queryParams.statuses) {
                stateChanges.appliedFilters.statuses = queryParams.statuses.split(',');
            }
            if (queryParams.keyword) {
                stateChanges.appliedFilters.keyword = queryParams.keyword;
                stateChanges.searchFieldValue = queryParams.keyword;
            }
            if (queryParams.campaign_type) {
                const campaignType = queryParams.campaign_type.toLowerCase();
                if (['marketplace', 'select'].indexOf(campaignType) !== -1) {
                    stateChanges.appliedFilters.campaign_type = campaignType;
                }
            }
        }

        this.setState(
            stateChanges,
            this.getCampaigns
        );
    };

    applyFilters = (selectedFilters) => {
        let search = '';

        // go over each filter and apply it to the URL
        for (let key in selectedFilters) {
            if (!selectedFilters.hasOwnProperty(key)) {
                continue;
            }

            // ignore empty filters
            if (selectedFilters[key] === undefined || selectedFilters[key] === '') {
                continue;
            }

            // array's values are joined with commas (["1", "2", ...] => "1,2,..."
            const value = Array.isArray(selectedFilters[key])
                ? selectedFilters[key].join(',')
                : selectedFilters[key];

            search += `&${key}=${value}`;
        }

        // remove the prefixed "&"
        search = search.substr(1);

        this.props.history.push(`/campaigns?${search}`);
        this.getCampaigns();
    };

    /**
     * Gets campaigns for this contractor
     */
    getCampaigns = () => {
        //
        // At minimum, we show the "Loading your Campaigns..." message for a certain amount
        // of time. This value is set to true when results start being loaded, and this
        // setTimeout resets it to false after a minimum delay. Ensures people get to see
        // the loading message instead of getting confused.
        //
        this.setLoadingMessageMinDelayTimer();

        //
        // Build up URL and query vars
        // ---------------------------
        //
        let query,
            filtersQueryStr = this.filtersQueryStr();

        if (this.state.campaignId) {
            query = `campaign_id=${this.state.campaignId}`;
        }
        else {
            query = `sort_by=${this.state.sort_by}`;
            if (filtersQueryStr) {
                query += `&${filtersQueryStr}`;
            }
        }


        //
        // Fetch the data and update the state
        //
        this.campaignService.getCampaigns(query)
            .then((stateData) => {
                // @see Api/Controllers/Campaigns/Get.php
                // includes the following properties:
                //  campaigns
                //  industries
                //  callerIdOptions
                //  contractor
                stateData.loadingResults = false;
                stateData.contractorPausedBilling = stateData.contractor.paused_billing;
                stateData.showMpCplNudge = stateData.contractor.show_mp_cpl_nudge == 1;
                stateData.mpFirstEnableTimestamp = stateData.contractor.mp_campaigns_first_enabled_timestamp;
                stateData.dateCompletedSignup = stateData.contractor.date_completed_signup;
                stateData.displayForm = stateData.contractor.display_form == 1;
                stateData.mpFormsEarlyAccess = stateData.contractor.mp_forms_early_access == 1;
                delete stateData.contractor;

                this.setState(stateData);
                if (this.props.location.hash == '#scroll_to_first_campaign') {
                    this.campaignsTableRef.current.scrollIntoView();
                }

                // return this.campaignService.getMissedOpportunities(query)
            })
            // .then((response) => {
            //     this.setState({ missedOpportunities: response })
            // })
            .catch(
                (error) => {
                    if (errorHandler(error)) {
                        this.setState({loadingResults: false});
                    }
                }
            );

    };


    /**
     * Builds the GET var string for the filters. Used for GET /campaigns
     * and also GET /campaigns/historical_report
     */
    filtersQueryStr = () => {
        let query = '';
        //
        // keyword
        //
        if (!!this.state.appliedFilters.keyword) {
            query += '&keyword=' + encodeURIComponent(this.state.appliedFilters.keyword);
        }
        //
        // statuses
        // If no statuses are selected, send all 3 statuses to the API so it shows `All`
        //
        let statusesToSend = this.state.appliedFilters.statuses;
        if (statusesToSend.length === 0) {
            // Get the array of all values, e.g. ["enabled","paused","canceled"]
            statusesToSend = this.state.statusesOptions.map((status) => status.value);
        }
        for (let i=0; i < statusesToSend.length; i++) {
            query += "&statuses[]=" + statusesToSend[i];
        }

        // industry_ids
        for (let i=0; i<this.state.appliedFilters.industry_ids.length; i++) {
            query += "&industry_ids[]=" + this.state.appliedFilters.industry_ids[i].toString();
        }

        // contractor_ids (parent contractors only)
        for (let i=0; i<this.state.appliedFilters.contractor_ids.length; i++) {
            query += "&contractor_ids[]=" + this.state.appliedFilters.contractor_ids[i].toString();
        }

        // campaign_type
        if (this.state.appliedFilters.campaign_type) {
            query += "&campaign_type=" + this.state.appliedFilters.campaign_type;
        }

        // remove the leading '&'
        return query.slice(1);
    };



    /**
     * Clears out the campaign results and calls getCampaigns.
     * While the new results are loading, user will see the LoadingGif.
     */
    reloadCampaigns = () => {
        //
        // re-load the Campaigns so any new data shows up correctly
        // blank out campaign results so user doesn't see / interact
        // with stale data in the meantime
        //
        this.setState({
            loadingResults: true,
            campaigns: [],
            missedOpportunities: [],
            selectedCampaignIds: {},
        },
        this.getCampaigns);
    };



    /**
     * submitLeadDeliverySettings function
     *
     * Passed in as a prop to CampaignRow component.
     *
     * @param {object} modalState - the immutable modal state
     * @param key
     * @param callback - will Always be called upon request completion,
     *                   regardless of success or failure
     */
    submitLeadDeliverySettings = (modalState, key, callback) => {
        let modalStateCopy = {...modalState};
        let campaign = modalStateCopy.campaign;
        let url = `campaigns/${campaign.campaign_id}/lead_delivery_settings`;

        const phoneNumber = textOfOption(modalStateCopy.callerIdTrackingPhoneNumberDropdownOptions,
            modalStateCopy.callerIdPhoneId,
            'phone');

        // format the data from the modal's inputObjs to work with the profile processor
        let data = {
            'data' : {
                'forwarded_phone' : modalStateCopy.leadDeliveryPhones.map(input => input.value),
                'sms_lead_alert_multiple' : modalStateCopy.leadDeliverySmsPhones.map(input => input.value),
                'contact_email' : modalStateCopy.leadDeliveryEmails.map(input => input.value),
                'call_recording' : modalStateCopy.inputs.recordCallsCheckBox.value,
                'caller_id_option' : modalStateCopy.callerIdSetting,
                'caller_id_phone_number' : phoneNumber, // adding the phone number for the notes
                'caller_id_phone_id' : modalStateCopy.callerIdPhoneId,
            },
        };

        //
        // Clear message blocks at beginning of request
        // This way, if they submit twice and receive the same error message,
        // they will see the the message disappear and re-appear, so they will know
        // that the re-submit was attempted.
        //
        this.props.updateMessageBlocks([], 'success');

        return put(url, data, this.cancelSignal.token)
            .then( resp => {
                //
                // Call a function the caller passed to be executed
                // upon request completion, e.g. re-enabling submit button
                //
                if (typeof callback === 'function') {
                    callback();
                }

                let stateClone = {...this.state};

                if (resp.status >= 400) {
                    return;
                }

                // now that we have had a successful request,
                // use that formatted data that we sent to the api
                // to update this campaign's data in the same format
                // that we originally got from the database via this->getCampaignData()
                campaign.phones = data.data.forwarded_phone.map((phoneNumber) => {
                    return {'phone_number': phoneNumber}
                });
                campaign.emails = data.data.contact_email.map((email) => {
                    return {'email_address' : email};
                });
                campaign.lead_notification_phones = data.data.sms_lead_alert_multiple.map((phoneNumber) => {
                    return {'phone_number' : phoneNumber};
                });
                campaign.caller_id_option = modalStateCopy.callerIdSetting;
                campaign.call_recording_setting = !!modalStateCopy.inputs.recordCallsCheckBox.value;
                campaign.caller_id_phone_id = data.data.caller_id_phone_id;
                campaign.caller_id_phone_number = data.data.caller_id_phone_number;

                stateClone.campaigns[key] = campaign;
                this.clearOutModalContent(stateClone);
                this.setState(stateClone);


                //
                // Show success message
                //
                this.props.updateMessageBlocks(['Lead delivery settings successfully updated.'], 'success');
            })
            .catch(errorHandler);
    };

    /**
     * Passed in as a prop to CampaignRow component.
     *
     * @param modalState
     * @param key
     * @param callback - will Always be called upon request completion,
     *                   regardless of success or failure
     * @return Promise
     */
    submitCplChange = (modalState, key, callback) => {
        let modalStateCopy = {...modalState};
        let campaign = modalStateCopy.campaign;
        let url = `profiles/${campaign.profile_id}`;

        //
        // Remove '$' dollar sign and none numeric characters other than "."
        //
        let cpl = parseMonetaryAmount(modalStateCopy.inputs.cpl.value, true);

        //
        // Format the cpl payload as expected by the profile processor
        //
        let data = {
            data: {
                tender: cpl
            }
        };

        //
        // Clear message blocks at beginning of request
        // This way, if they submit twice and receive the same error message,
        // they will see the the message disappear and re-appear, so they will know
        // that the re-submit was attempted.
        //
        this.props.updateMessageBlocks([], 'success');

        return put(url, data, this.cancelSignal.token)
            .then( (response) => {
                //
                // Call a function the caller passed to be executed
                // upon request completion, e.g. re-enabling submit button
                //
                if (typeof callback === 'function') {
                    callback();
                }
                //
                // In general, the axios interceptor should catch 400/404 errors and they should never get here.
                // But in this case they are getting through, so early return here. ~ RFM 2020-03-27
                // NOTE: I don't believe this if/return is necessary. ~ RFM Feb 2021
                //
                if (response.status >= 400) {
                    // console.log("response.status >= 400.");
                    return;
                }

                let stateClone = {campaigns: [...this.state.campaigns]};

                // now that we have had a successful request,
                // use that formatted data that we sent to the api
                // to update this campaign's data in the same format
                // that we originally got from the database via this->getCampaignData()
                campaign.tender[0] = { "price_per_lead" : cpl };

                stateClone.campaigns[key] = campaign;
                this.clearOutModalContent(stateClone);
                this.setState(stateClone);

                //
                // Show success message
                //
                this.props.updateMessageBlocks(['Cost per lead successfully updated.'], 'success');
            })
            .catch(errorHandler);
    };


	/**
	
	 */
	submitPutSchedule = (campaignId, payload, callback) => {
		this.campaignService.updateAdSchedule(
			this.props.updateMessageBlocks,
			campaignId,
			payload,
            callback,
		).then((campaigns) => {
			if (!campaigns) {
				return;
			}
			
			let stateClone = {campaigns: [...this.state.campaigns]};
			
			campaigns.forEach(campaign => {
				const idx = this.state.campaigns.findIndex(obj => obj.campaign_id === campaign.campaign_id);
				stateClone.campaigns[idx] = _cloneDeep(campaign);
			});
            
            this.clearOutModalContent(stateClone);
            console.log(stateClone);
			this.setState(stateClone);
		});
	}
	
    /**
     * submitPutStatus function
     *
     * Passed in as a prop to CampaignRow component.
     *
     * @param desiredStatus - string, either "paused" or "enabled"
     * @param modalState - state of the UpdateStatus*Modal
     * @param campaignIndex - int, index in the array
     * @param callback - will Always be called upon request completion,
     *                   regardless of success or failure
     * @param validationOnly - bool, optional - when true, send validation_only=1
     *                         to the endpoint, causing the validation to run but
     *                         the actual status change not to. Used for the initial
     *                         submit of a Pause Request, in which we need to see the
     *                         error messages but don't want to make the actual change
     *                         until user confirms via the confirmation popup.
     */
    submitPutStatus = (desiredStatus, modalState, campaignIndex, callback, validationOnly) => {
        this.campaignService.updateStatus(
            this.props.updateMessageBlocks,
            this.state.campaigns[campaignIndex],
            desiredStatus,
            modalState,
            callback,
            validationOnly
        ).then((campaign) => {
            //
            // 400 and 500 errors will not return the campaign object
            //
            if (!campaign) {
                return;
            }

            let stateClone = {campaigns: [...this.state.campaigns]};

            stateClone.campaigns[campaignIndex] = campaign;
            this.clearOutModalContent(stateClone);
            console.log(stateClone);
            this.setState(stateClone);

            //
            // We reload the screenshot here, assuming that the API has updated it as needed. The API has
            // issued a request to regenerate the screenshot, via a background process for performance reasons.
            // My experience is that the rest of the API processing takes long enough that we can safely assume
            // the screenshot is updated by now. ~ RFM 2019-12-30
            // https://docs.google.com/document/d/1tLItO3SywEkM3ovRlPRJR0ASIb7DYbngPuya2ctkLno/edit#heading=h.5hoxoysro4mk
            //
            // We only reloadScreenshot for non-Marketplace campaigns. ~ RFM 2020-04-23
            //
            if (!this.isMarketplaceCampaign(campaign)) {
                this.reloadScreenshot(campaign.campaign_id);
                //
                // Occasionally I experienced the screenshot not being ready yet by that first reload,
                // so set a timeout to reload again after 2 seconds. We keep both reloads so we get the
                // advantage of usually updating immediately, while also catching any stragglers.
                //
                setTimeout(() => {
                    this.reloadScreenshot(campaign.campaign_id);
                }, 2000);
            }
        });
    };

    /**
     * submits are zip codes change to the API
     * @param {number} campaignId
     * @param {number} campaignIndex
     * @param {string} zipCodes comma separated list of zip codes
     * @return {Promise<*>}
     */
    submitAreaCodeChange = (campaignId, campaignIndex, zipCodes) => {
        /* clear current message block */
        this.props.updateMessageBlocks([], 'success');

        return this.campaignService.updateZipCodes(campaignId, zipCodes)
            .then((campaign) => {
                let stateClone = {campaigns: [...this.state.campaigns]};
                stateClone.campaigns[campaignIndex] = campaign;
                this.props.updateMessageBlocks(['Updated service area zip codes'], 'success');
                this.clearOutModalContent(stateClone);
                this.setState(stateClone);
            });
    };

    /**
     * handle campaign subcategories change.
     * the submission of the change is handled by the modal; this function merely updates the campaign array of its view
     * @param {number} campaignIndex the index of the campaigns array of the state
     * @param {ISubcategory[]} formSubcategories the updates subcategories
     * @param {string} firstEnabledMpForms the timestamp in which mp forms were first enabled in this campaign
     */
    handleSubcategoriesChange = (campaignIndex, formSubcategories: ISubcategory[], firstEnabledMpForms) => {
        const {campaigns} = this.state;
        campaigns[campaignIndex].forms_subcategories = formSubcategories;
        if (!campaigns[campaignIndex].first_enabled_mp_forms) {
            campaigns[campaignIndex].first_enabled_mp_forms = firstEnabledMpForms;
        }
        this.setState({campaigns});
    };

    /**
     * Given a campaignId, reload the <img> of the screenshot. Add a query var to the URL to ensure the browser
     * actually reloads the image.
     *
     * @param campaignId
     */
    reloadScreenshot = (campaignId) => {
        let imgSelector = `.campaigns-table__row[data-campaign_id="${campaignId}"] img`;
        let img = document.querySelector(imgSelector);
        //
        // If there's no matching <img>, don't worry about it.
        //
        if (!img) { return; }

        //
        // remove any additional query vars after the initial required one, "url=___",
        // and add &t=<timestamp> to ensure browser actually reloads the image
        //
        let imgSrc = img.src;
        let queryPos = imgSrc.indexOf('&');
        if (queryPos !== -1) {
            imgSrc = imgSrc.substr(0, queryPos);
        }
        imgSrc += '&t=' + Date.now();

        //
        // re-assign the img.src to trigger the re-load
        //
        img.src = imgSrc;
    };




    /**
     * When an option is selected/deselected in the <MultiSelectList>, this
     * callback updates the parent's (CampaignManagerView's) state.
     *
     * @param {string[]} newSelectedValues - a list of indexes of chosen options
     */
    updateStatuses = (newSelectedValues) => {
        let appliedFilters = deepCopyFilters(this.state.appliedFilters);
        appliedFilters.statuses = newSelectedValues;
        this.setState({
                appliedFilters,
                loadingResults: true,
                campaigns: [],
                selectedCampaignIds: {},
            },
            () => this.applyFilters(appliedFilters)
        );
    };

    /**
     * When an option is selected/deselected in the <MultiSelectList>, this
     * callback updates the parent's state.
     *
     * @param {number[]} newSelectedValues - a list of indexes of chosen options
     */
    updateIndustryIds = (newSelectedValues) => {
        let appliedFilters = deepCopyFilters(this.state.appliedFilters);
        appliedFilters.industry_ids = newSelectedValues;
        this.setState({
                appliedFilters,
                loadingResults: true,
                campaigns: [],
                selectedCampaignIds: {},
            },
            () => this.applyFilters(appliedFilters)
        );
    };

    /**
     * When an option is selected/deselected in the <MultiSelectList>, this
     * callback updates the parent's state.
     *
     * @param {number[]} newSelectedValues - a list of indexes of chosen options
     */
    updateContractorIds = (newSelectedValues) => {
        let appliedFilters = deepCopyFilters(this.state.appliedFilters);
        appliedFilters.contractor_ids = newSelectedValues;
        this.setState({
                appliedFilters,
                loadingResults: true,
                campaigns: [],
                selectedCampaignIds: {},
            },
            () => this.applyFilters(appliedFilters)
        );
    };

    updateFilterInModal = (filter, value) => {
        let selectedFilters = deepCopyFilters(this.state.selectedFilters);
        selectedFilters[filter] = value;
        this.setState({selectedFilters});
    };

    /**
     * Called when user chooses a sort option from the Sort Dropdown.
     * @param {array} vals
     */
    applySortOptionFromDropdown = (vals) => {
        // let newSortBy = event.target.value;
        let newSortBy = vals[0];
        this.applySortOption(newSortBy);
    };


    applySortOption = (newSortBy) => {
        let stateChanges = {
            sort_by: newSortBy,
            campaigns: [],
            missedOpportunities: [],
            loadingResults: true,
        };
        this.setState(stateChanges, () => {
            this.getCampaigns();
            this.closeModal();
        });
    };


    /**
     * Give a stateClone, populate it in such a way that the modal
     * would be blanked out / closed once the stateClone is applied.
     *
     * See also closeModal() below
     *
     * @param stateClone
     */
    clearOutModalContent = (stateClone) => {
        stateClone.modalContent = null;
        stateClone.modalHeader = null;
        stateClone.modalHeaderLink = null;
        stateClone.modalWidth = null;
        stateClone.modalFlatBottom = null;
    };


    /**
     * A convenience function to close the modal out
     */
    closeModal = () => {
        let stateChanges = {};
        this.clearOutModalContent(stateChanges);
        this.setState(stateChanges);
    };

    updateModalContent = (modalContent, modalHeader, modalHeaderLink, modalWidth, modalFlatBottom) => {
        this.setState({ modalContent: modalContent,
                        modalHeader: modalHeader,
                        modalHeaderLink: modalHeaderLink,
                        modalWidth: modalWidth,
                        modalFlatBottom: modalFlatBottom });
        //
        // If opening a new modal, clear message blocks
        //
        if (modalContent) {
            this.props.updateMessageBlocks([], 'success');
        }
    };

    updateModalHeader = (modalHeader) => {
        this.setState({ modalHeader: modalHeader });
    };


    /**
     * Generate the message at the top of the Campaigns results that describes what the
     * filters are. E.g.:
     *
     * - "Showing 1-4 of 4 Enabled and Paused Campaigns in All Service Categories"
     * - "Showing 1 of 1 Canceled Campaigns in Service Category Plumbing"
     * - "Showing 1-10 of 25 Enabled Campaigns in Service Category Plumbing matching “Denver”"
     * - "Showing 1-3 of 3 Enabled and Paused Campaigns in Service Categories Plumbing and Heating"
     *
     * @param actionWord string (optional) - if passed, will be the first word of the message.
     *                   Otherwise, defaults to "Showing", unless there are 0 results, in which case: "Found"
     *
     * @return the string
     */
    filterMessage = (actionWord) => {

        if (this.state.loadingResults || this.state.loadingMessageMinDelayTimer) {
            return (<><span className="loading-results-message">Loading your Campaigns...</span></>);
        }

        let total = this.state.campaigns.length,
            filters = this.state.appliedFilters,
            campaignsFromFilterSelections = [],
            selectedStatusesJsxList = [],
            andOr = total > 1 ? 'and' : 'or';


        //
        // Statuses selections
        //
        let selectedStatuses = [];
        for (let i = 0; i < this.state.statusesOptions.length; i++) {
            let status = this.state.statusesOptions[i];

            if (filters.statuses.indexOf(status.value) !== -1) {
                selectedStatuses.push(status.text)
            }
        }
        if (selectedStatuses.length) {
            // selectedStatusesJsxList = englishyListJsx(selectedStatuses, andOr);
            selectedStatusesJsxList = <EnglishyList items={selectedStatuses} andOr={andOr} />;
        }

        //
        // Service Category selections
        //
        let selectedIndustryNames = [];
        for (let i = 0; i < this.state.industries.length; i++) {
            let industryId = this.state.industries[i].value,
                industryName = this.state.industries[i].text;

            if (filters.industry_ids.indexOf(industryId) !== -1) {
                selectedIndustryNames.push(industryName)
            }
        }
        //
        // And add to filter message
        //
        if (selectedIndustryNames.length) {
            let label = selectedIndustryNames.length === 1
                ? " in Service Category"
                : " in Service Categories";

            campaignsFromFilterSelections.push(
                <span key={ "filter_msg_campaign_id" }>
                    {/* {label} { englishyListJsx(selectedIndustryNames, andOr) } */}
                    {label} <EnglishyList items={selectedIndustryNames} andOr={andOr} />
                </span>
            );
        }


        //
        // Keyword search term
        //
        let keyword = filters.keyword;
        if (keyword) {
            let prependStr = campaignsFromFilterSelections.length ? "and matching search term " :  "matching search ";
            campaignsFromFilterSelections.push(
                <span key={ "filter_msg_date_range"}> {prependStr}
                    <span className="type-heavy type-no-break">"{ keyword }"</span>
                </span>
            );
        }


        //
        // Parent Contractors only: Child Company selection
        //
        let selectedContractorNames = [];
        if (filters.contractor_ids.length > 0) {
            for (let i = 0; i < this.state.contractorOptions.length; i++) {
                let contractorId = this.state.contractorOptions[i].value,
                    contractorName = this.state.contractorOptions[i].text;

                if (filters.contractor_ids.indexOf(contractorId) !== -1) {
                    selectedContractorNames.push(contractorName)
                }
            }
        }
        //
        // And add to filter message
        //
        if (selectedContractorNames.length > 0) {
            let label = selectedContractorNames.length === 1
                ? " for company" : " for companies";

            campaignsFromFilterSelections.push(
                <span key={ "filter_msg_contractor_id" }>
                    {/* {label} { englishyListJsx(selectedContractorNames, andOr) } */}
                    {label} <EnglishyList items={selectedContractorNames} andOr={andOr} />
                </span>
            );
        }


        //
        // Now build up the description message
        //
        if (actionWord === undefined) {
            if (total > 0) {
                actionWord = 'Showing'; // "Showing 5 results"
            } else {
                actionWord = 'Found'; // "Found 0 results"
            }
        }

        let campaignOrCampaigns = total === 1
            ? 'Campaign'
            : 'Campaigns';

        if (filters.campaign_type) {
            let campaignType = filters.campaign_type.charAt(0).toUpperCase() + filters.campaign_type.substr(1);
            campaignOrCampaigns = campaignType + ' ' + campaignOrCampaigns;
        }

        return (
            <>
                { actionWord } <span className="type-heavy type-no-break-except-mobile">{ total }</span>
                <> </>
                {selectedStatusesJsxList} <span className="type-heavy type-no-break-except-mobile">{campaignOrCampaigns}</span>
                <> </>
                { campaignsFromFilterSelections }
            </>
        );
    };


    /**
     * JSX for invoking <FilterCampaignsModal> gathered here
     * to avoid having to repeat it 3 times and possibly have
     * props fall out of sync etc.
     *
     * Note also that having this be a function is necessary because
     * of a change made to Modal.js where we can pass modalContent
     * as a function returning JSX rather than JSX itself.
     *
     * @param extraProps, e.g. {scrollToSearch: true}
     * @returns JSX
     */
    filterCampaignsModalJsx = (extraProps) => {
        return <FilterCampaignsModal
            scrollerRef={this.modalScrollerRef}
            statusesOptions={this.state.statusesOptions}
            industryOptions={this.state.industries}
            contractorOptions={this.state.contractorOptions}
            selectedFilters={this.state.selectedFilters}
            appliedFilters={this.state.appliedFilters}
            defaultFilters={this.state.defaultFilters}
            resetFilters={this.resetFiltersForModal}
            updateFilter={this.updateFilterInModal}
            submitHandler={this.applySelectedFilters}
            {...extraProps}
        />
    };


    /**
     * Used as click handler on campaign checkbox.
     * @param campaignId
     */
    toggleSelectCampaign = (campaignId) => {
        let selectedCampaignIdsClone = {...this.state.selectedCampaignIds};
        //
        // do the toggle
        //
        if (selectedCampaignIdsClone[campaignId]) {
            delete selectedCampaignIdsClone[campaignId];
        }
        else {
            selectedCampaignIdsClone[campaignId] = true;
        }
        this.setState({selectedCampaignIds: selectedCampaignIdsClone});
    };


    /**
     * @returns {boolean} - true if all campaigns are selected, false otherwise
     */
    allCampaignsAreSelected = () => {
        //
        // Avoid showing box checked for a moment while campaigns are loading
        //
        if (this.state.campaigns.length === 0) {
            return false;
        }

        let nonCanceledCampaigns = this.state.campaigns
            .filter((campaign) => campaign.status !== 'canceled');

        return Object.keys(this.state.selectedCampaignIds).length === nonCanceledCampaigns.length;
    };

    /**
     * checks whether a campaign is a marketplace campaign or not
     * copy from RecentCampaigns until we build a shared component like LeadLog
     * @param {Campaign} campaign - the campaign in question
     * @return {boolean} - true if the campaign is a marketplace campaign
     */
    isMarketplaceCampaign(campaign: Campaign) {
        return campaign.type === CampaignTypes.MARKETPLACE;
    }

    /**
     * Used as click handler on Select All checkbox.
     */
    toggleSelectAllCampaigns = () => {
        let newSelectedCampaignIds = {};

        //
        // are all campaigns currently selected? toggle accordingly
        //
        if (this.allCampaignsAreSelected()) {
            //
            // deselect all campaigns
            //
            newSelectedCampaignIds = {};
        }
        else {
            //
            // select all campaigns
            //
            for (let i = 0; i < this.state.campaigns.length; i++) {
                if (this.state.campaigns[i].status === 'canceled') {
                    continue;
                }
                newSelectedCampaignIds[this.state.campaigns[i].campaign_id] = true;
            }
        }

        this.setState({selectedCampaignIds: newSelectedCampaignIds});
    };


    /**
     * This resets the applied filters and search keyword(s)
     */
    resetFilters = () => {
        // Generally, revert back to the Default Filters...
        const appliedFilters = deepCopyFilters(this.state.defaultFilters);

        this.setState({
            appliedFilters,
            loadingResults: true,
            campaigns: [],
            missedOpportunities: [],
            selectedCampaignIds: {},
        },
        () => this.applyFilters(appliedFilters));
    };



    /**
     * Resets filters then re-renders the filter modal by way of setting
     * updated content in a second setState() call.
     */
    resetFiltersForModal = () => {
        // Generally, revert (selectedFilters only) back to the Default Filters...
        let selectedFilters = deepCopyFilters(this.state.defaultFilters);

        this.setState({selectedFilters});
    };

    setSearchFieldValue = (event) => {
        this.setState({searchFieldValue: event.target.value});
    };


    /**
     * Called from the Modal by pressing Submit
     */
    applySelectedFilters = () => {
        //
        // Close modal first so we can see it "working"
        //
        this.closeModal();

        //
        // Update filters from selectedFilters
        //
        let appliedFilters = deepCopyFilters(this.state.selectedFilters);
        this.setState({
                appliedFilters,
                searchFieldValue: appliedFilters.keyword,
                loadingResults: true,
                campaigns: [],
                missedOpportunities: [],
                selectedCampaignIds: {},
            },
            () => this.applyFilters(appliedFilters)
        );
    };

    clearSelectedCampaigns = () => {
        this.setState({selectedCampaignIds: {}});
    };

    /**
     * At minimum, we show the "Loading your Campaigns..." message for a certain amount
     * of time. This value is set to true when results start being loaded, and a
     * setTimeout resets it to false after a minimum delay. Ensures people get to see
     * the loading message instead of getting confused.
     */
    setLoadingMessageMinDelayTimer = () => {

        //
        // Clear any existing timeout from a previous request
        //
        if (this.state.loadingMessageMinDelayTimeoutId) {
            clearTimeout(this.state.loadingMessageMinDelayTimeoutId);
        }

        //
        // Set a timeout so we will see Loading message for at least a certain time
        //
        let component = this;
        let timeoutId = setTimeout(() => {
            component.setState({loadingMessageMinDelayTimer: false});
        }, this.LOADING_MESSAGE_MIN_DISPLAY_TIME_MS);

        //
        // Set state variables - bool to show Loading Message until timeout expires,
        // and also save timeout ID, to clear later
        //
        this.setState({
            loadingMessageMinDelayTimer: true,
            loadingMessageMinDelayTimeoutId: timeoutId,
        });

    };

    /**
     * Figure out if we have > 1 industry_id "service categories"
     * in our selected campaigns.
     *
     * @returns {boolean} If only 1 industry selected, return the industries obj
     * for the only industry selected - false otherwise.
     */
    exclusiveIndustrySelected = () => {
      let industryIds = {};
      for (let i=0; i < this.state.campaigns.length; i++) {
        let campaign = this.state.campaigns[i];
        //
        // Skip campaign if not selected
        //
        if (!(campaign.campaign_id in this.state.selectedCampaignIds)) {
          continue;
        }

        industryIds[campaign.industry_id] = true;
        if (Object.keys(industryIds).length > 1) {
          //
          // Found a 2nd industry - remove Cost Per Lead option
          //
          return false;
        }
      }

      //
      // Found that we have 1 industry - now find it in industries
      //
      let industryId = Object.keys(industryIds)[0];
      for (let i=0; i < this.state.industries.length; i++) {
        let industry = this.state.industries[i];
        //
        // industry_id is packed behind "value" key
        //
        if (industry.value == industryId) {
          return industry;
        }
      }

      return false;
    };

    moreThanOneIndustrySelected = () => {
      return (this.exclusiveIndustrySelected() === false);
    };




    /**
     * Provide options for the Industry / Service Category <MultiSelect>
     */
    getSortByOptions = () => {
        let options = [
            { value: 'most_leads_30d', text: 'Most Leads in 30 Days' },
            { value: 'least_leads_30d', text: 'Least Leads in 30 Days' },
            { value: 'highest_cpl', text: 'Highest Cost Per Lead' },
            { value: 'lowest_cpl', text: 'Lowest Cost Per Lead' },
            { value: 'newest_first', text: 'Campaign Start Date, Newest' },
            { value: 'oldest_first', text: 'Campaign Start Date, Oldest' },
            { value: 'alphabetical', text: 'Campaign Name A - Z' }
        ];
        //
        // receiving contractorOptions indicates Parent Contractor case
        //
        if (this.state.contractorOptions.length > 0) {
            options.push({ value: 'company_name', text: 'Company Name A - Z' })
        }
        return options;
    };

    bulkEditSelectedCampaigns = (someCampaignsSelected) => {
        if (!someCampaignsSelected) {
            return;
        }

        let trackingPhoneDropdown = this.getCallerIdPhoneNumberDropdownOptions();

        this.updateModalContent(
            <BulkEditCampaignsModalFrame
                modalContainerRef={this.modalContainerRef}
                updateModalContent={this.updateModalContent}
                updateModalHeader={this.updateModalHeader}
                campaigns={this.state.campaigns}
                selectedCampaignIds={this.state.selectedCampaignIds}
                disableCpl={this.moreThanOneIndustrySelected()}
                cplIndustry={this.exclusiveIndustrySelected()}
                callerIdOptions={this.state.callerIdOptions}
                updateMessageBlocks={this.props.updateMessageBlocks}
                ajaxCancelSignal={this.cancelSignal}
                industries={this.state.industries}
                getCampaigns={this.getCampaigns}
                clearSelectedCampaigns={this.clearSelectedCampaigns}
                reloadCampaigns={this.reloadCampaigns}
                callerIdTrackingPhoneOptions={trackingPhoneDropdown}
            />,
            'Bulk Edit Selected Campaigns',
            null,
            'wide',
            true
        )
    };

    /**
     * Add caller id phone options (1st Tracking Number of each campaign)
     */
     getCallerIdPhoneNumberDropdownOptions = () => {
        let trackingPhoneDropdown = [];
        let campaigns = this.state.campaigns;
        for (let i = 0; i < campaigns.length; i++) {
            if (campaigns[i].tracking_phone && campaigns[i].tracking_phone.length) {
                let campaignCallerPhone = '';
                let campaignName = campaigns[i].campaign_name;
                // let campaignTrackingPhoneId = campaigns[i].tracking_phone[0].phone_id;
                let campaignTrackingPhoneId = '';
                campaignCallerPhone = campaigns[i].tracking_phone[0].phone_number;
                campaignTrackingPhoneId = campaigns[i].tracking_phone[0].phone_id;
                trackingPhoneDropdown.push({
                    value: campaignTrackingPhoneId,
                    text: campaignCallerPhone + ' - ' + campaignName,
                    phone: campaignCallerPhone
                });
            }
        }

        return trackingPhoneDropdown;
    };

    render() {
        const { campaigns, missedOpportunities, selectedCampaignIds } = this.state
        let someCampaignsSelected = Object.keys(selectedCampaignIds).length > 0;
        let trackingPhoneDropdown = this.getCallerIdPhoneNumberDropdownOptions();
        const isSelected = (campaignId) => {
            return selectedCampaignIds[campaignId] || false
        }
        let missedOps
        let campaignRows = campaigns.map((campaign, idx) => {
            // find the missed opportunities for this campaign
            missedOps = missedOpportunities?.filter(m => m.campaign_id == campaign.campaign_id)
                                            ?.[0]?.missed_ops || 0
            return (<CampaignRow
                key={`campaign-row-${idx}`}
                campaignIndex={idx}
                campaign={campaign}
                missedOpportunities={missedOps}
                callerIdOptions={this.state.callerIdOptions}
                updateModalContent={this.updateModalContent}
                updateModalHeader={this.updateModalHeader}
                submitLeadDeliverySettings={this.submitLeadDeliverySettings}
                submitCplChange={this.submitCplChange}
				submitPutSchedule={this.submitPutSchedule}
                submitPutStatus={this.submitPutStatus}
                submitAreaCodeChange={this.submitAreaCodeChange}
                handleSubcategoriesChange={this.handleSubcategoriesChange}
                updateMessageBlocks={this.props.updateMessageBlocks}
                isChecked={isSelected(campaign.campaign_id)}
                toggleSelectCampaign={this.toggleSelectCampaign}
                industryOptions={this.state.industries}
                modalContainerRef={this.modalContainerRef}
                contractorPausedBilling={this.state.contractorPausedBilling}
                callerIdTrackingPhoneOptions={trackingPhoneDropdown}
                showMpCplNudgeContractor={this.state.showMpCplNudge}
                mpFirstEnableTimestamp={this.state.mpFirstEnableTimestamp}
                dateCompletedSignUp={this.state.dateCompletedSignup}
                displayForm={this.state.displayForm}
                mpFormsEarlyAccess={this.state.mpFormsEarlyAccess}
            />
            )}
        );

        if (campaignRows.length === 0 && !this.state.loadingResults) {
            campaignRows = <TableTooFewResults />;
        }

        return (
            <div className="page-width-wide">
                <CampaignManagerSecondaryNav
                    filterMessage={ this.filterMessage() }
                    secondaryNavRef={ this.props.secondaryNavRef }
                />
                <Modal
                  content={this.state.modalContent}
                  header={this.state.modalHeader}
                  headerLink={this.state.modalHeaderLink}
                  width={this.state.modalWidth}
                  flatBottom={this.state.modalFlatBottom}
                  updateModalContent={this.updateModalContent}
                  scrollerRef={this.modalScrollerRef}
                  modalContainerRef={this.modalContainerRef}
                />

                <MajorAlerts />

                <div className="row padding-50-top padding-30-bottom">
                    <div className="wide-format-col">
                        <div className="simpleflex__row">
                            <div className="simpleflex__cell">
                                <h2 className="type-normal-headline type-heavy padding-20-bottom">
                                    Campaigns Manager
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="clear-block"/>
                </div>

                <div className="row">
                    <div className="wide-format-col page__contentbox page__contentbox__invisible__mobile">
                        <div className="ui-hide-full ui-hide-tablet">
                            <div className="campaign-filters__mobile-filter-card">
                                <div className="type-normal-subhead no-margin-top spacing-10-bottom">
                                    {this.filterMessage()}
                                </div>
                            </div>
                            <div className="simpleflex__row simpleflex__row__wrap__mobile spacing-10-bottom-mobile">
                                <div className="simpleflex__cell spacing-10-bottom-mobile">
                                    <div className="type-small-body">
                                        <span role="button"
                                            className="mobile-filter-link type-heavy type-blue"
                                            onClick={() => {
                                                //
                                                // Update modal's selectedFilters to match
                                                // applied filters
                                                //
                                                this.setState({
                                                    selectedFilters: deepCopyFilters(this.state.appliedFilters),
                                                }, () => {
                                                    this.updateModalContent(
                                                        this.filterCampaignsModalJsx,
                                                        'Filter Your Campaigns',
                                                        null,
                                                        'wide',
                                                        true
                                                    );
                                                });
                                            }}
                                        >
                                            { IconFilterSvg }
                                            Filter
                                        </span>
                                    </div>
                                </div>
                                <div className="simpleflex__cell spacing-10-bottom-mobile">
                                    <div className="type-small-body">
                                        <span role="button"
                                            className="mobile-filter-link type-heavy type-blue"
                                            onClick={() => this.updateModalContent(
                                                <SortCampaignsModal
                                                    applySortOption = {this.applySortOption}
                                                    sortBy = {this.state.sort_by}
                                                />,
                                                'Adjust Campaign Sort Order',
                                                null,
                                                null,
                                                false
                                            )}
                                        >
                                            { IconSortSvg }
                                            Sort
                                        </span>
                                    </div>
                                </div>
                                <div className="simpleflex__cell">
                                    <div className="type-small-body">
                                        <span role="button"
                                            className="mobile-filter-link type-heavy type-blue"
                                            onClick={() => this.updateModalContent(
                                                () => this.filterCampaignsModalJsx({
                                                    scrollToSearch: true
                                                }),
                                                'Filter Your Campaigns',
                                                null,
                                                'wide',
                                                true
                                            )}
                                        >
                                            { IconSearchSvg }
                                            Search
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="simpleflex__row simpleflex__row__wrap__mobile spacing-24-bottom-mobile">
                                <span  role="button"
                                    className="type-small-body type-heavy type-blue"
                                    onClick={() => this.updateModalContent(<HistoricalReportModal
                                            modalContainerRef={this.modalContainerRef}
                                            updateModalContent={this.updateModalContent}
                                            updateModalHeader={this.updateModalHeader}
                                            filterMessage={this.filterMessage}
                                            filtersQueryStr={this.filtersQueryStr}
                                        />,
                                        'Bulk Edit Selected Campaigns',
                                        null,
                                        null,
                                        false
                                    )}
                                >
                                    <span className="inline-icon inline-icon__middle inline-icon__16">
                                        { DownloadSvg }
                                    </span>
                                    Download Campaign Report
                                </span>
                            </div>
                            <div className="simpleflex__row simpleflex__row__bottomaligned no-margin-top spacing-24-bottom">
                                <div className="simpleflex__cell">
                                    <p className="type-small-body type-heavy">
                                        <label htmlFor="selectAllCampaigns">Select All</label>
                                    </p>
                                    <div className="no-label-checkbox__container">
                                        <div className="no-label-checkbox__cell__checkbox">
                                            <div className="checkbox-container">
                                                <input type="checkbox"
                                                       name="selectAllCampaigns" id="selectAllCampaigns"
                                                       checked={this.allCampaignsAreSelected() ? 'checked' : false}
                                                       onChange={this.toggleSelectAllCampaigns}
                                                />
                                                <label htmlFor="selectAllCampaigns" className="checkbox-container__empty-label">
                                                    <span className="checkbox"/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className={"no-label-checkbox__cell__neighbor type-small-body"}>
                                            {/* Don't forget there are 2 instances of this BulkEdit...Frame! */}
                                            <span role="button"
                                                className={"type-heavy type-blue" + (!someCampaignsSelected ? ' disabled' : '')}
                                                onClick={() => this.bulkEditSelectedCampaigns(someCampaignsSelected)}
                                            >
                                                <span className="inline-icon inline-icon__middle inline-icon__16">
                                                    { PencilSvg }
                                                </span>
                                                Bulk Edit Selected Campaigns
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ui-hide-mobile">
                            <div className="campaign-filters-bar">
                                <div className="campaign-filters-bar__message">
                                    <div className="type-normal-subhead no-margin-top spacing-18-bottom">
                                        {this.filterMessage()}
                                    </div>
                                </div>
                                <div className="campaign-filters-bar__actions">
                                    <div className="campagin-filters-bar__buttons">
                                        <button
                                            className="button ui-small-button"
                                            onClick={() => {
                                                this.setState({
                                                    selectedFilters: deepCopyFilters(this.state.appliedFilters)
                                                }, () => {
                                                    this.updateModalContent(
                                                        () => {
                                                            return this.filterCampaignsModalJsx({ scrollToSearch: true });
                                                        },
                                                        'Filter Your Campaigns',
                                                        null,
                                                        'wide',
                                                        true
                                                    );
                                                });
                                            }}
                                            disabled={this.state.loadingResults}
                                        >
                                            { IconSearchSvg }
                                            Search
                                        </button>{' '}
                                        <button
                                            className="button ui-small-button qa-filter-button"
                                            onClick={() => {
                                                this.setState({
                                                    selectedFilters: deepCopyFilters(this.state.appliedFilters)
                                                }, () => {
                                                    this.updateModalContent(
                                                        this.filterCampaignsModalJsx,
                                                        'Filter Your Campaigns',
                                                        null,
                                                        'wide',
                                                        true
                                                    );
                                                });
                                            }}
                                            disabled={this.state.loadingResults}
                                        >
                                            { IconFilterSvg }
                                            Filter
                                        </button>
                                    </div>
                                    <span className="type-body-small type-heavy type-no-break" role="button"
                                        onClick={this.resetFilters}
                                    >
                                        Clear Search and Filters
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="ui-hide-mobile">
                            <div className="simpleflex__row simpleflex__row__bottomaligned no-margin-top spacing-20-bottom">
                                <div className="simpleflex__cell">
                                    <p className="type-small-body type-heavy spacing-10-bottom">
                                        <label htmlFor="selectAllCampaigns">Select All</label>
                                    </p>
                                    <div className="no-label-checkbox__container">
                                        <div className="no-label-checkbox__cell__checkbox">
                                            <div className="checkbox-container">
                                                <input type="checkbox"
                                                       name="selectAllCampaigns" id="selectAllCampaigns"
                                                       checked={this.allCampaignsAreSelected() ? 'checked' : false}
                                                       onChange={this.toggleSelectAllCampaigns}
                                                />
                                                <label htmlFor="selectAllCampaigns" className="checkbox-container__empty-label">
                                                    <span className="checkbox"/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className={"no-label-checkbox__cell__neighbor"}>
                                            <span className="header-spacer">
                                              {/* Don't forget there are 2 instances of this BulkEdit...Frame! */}
                                              <span role="button"
                                                    className={"type-small-body type-heavy type-blue qa-bulk-edit-selected-link" + (!someCampaignsSelected ? ' disabled' : '')}
                                                    onClick={() => this.bulkEditSelectedCampaigns(someCampaignsSelected)}
                                                >
                                                    <span className="inline-icon inline-icon__middle inline-icon__16">
                                                        { PencilSvg }
                                                    </span>
                                                    Bulk Edit Selected Campaigns
                                                </span>
                                            </span>
                                        </div>
                                        <div className={"no-label-checkbox__cell__neighbor type-left-side-bump-large"}>
                                            <span className="header-spacer">
                                                <span role="button"
                                                    className="type-small-body type-heavy type-blue"
                                                    onClick={() => this.updateModalContent(<HistoricalReportModal
                                                        modalContainerRef={this.modalContainerRef}
                                                        updateModalContent={this.updateModalContent}
                                                        updateModalHeader={this.updateModalHeader}
                                                        filterMessage={this.filterMessage}
                                                        filtersQueryStr={this.filtersQueryStr}
                                                    />,
                                                        'Bulk Edit Selected Campaigns',
                                                        null,
                                                        null,
                                                        false
                                                    )}
                                                >
                                                    <span className="inline-icon inline-icon__middle inline-icon__16">
                                                        { DownloadSvg }
                                                    </span>
                                                    Download Campaign Report
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="simpleflex__cell">
                                    <div className="campaign-sort">
                                        <div className="campaign-sort__cell campaign-sort__cell__label">
                                            <p className="type-normal-body">Sort Campaigns by: </p>
                                        </div>
                                        <div className="campaign-sort__cell campaign-sort__cell__menu">
                                            <MultiSelectList
                                                name="sortByCampaigns"
                                                options={ this.getSortByOptions() }
                                                onChange={this.applySortOptionFromDropdown}
                                                selections={[this.state.sort_by]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="campaigns-table__container" ref={this.campaignsTableRef}>
                            {this.state.loadingResults &&
                                <div className="type-centered padding-30">
                                    <LoadingGif/>
                                </div>}
                            {campaignRows}
                        </div>
                    </div>
                    <div className="clear-block"/>
                </div>
            </div>
        )
    }
}
