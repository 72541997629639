import React, { useEffect, useCallback } from 'react';

import { CloseSvg } from '../common/Svgs';

const ImagePopup = (props) => {
	const { closePopup } = props;
	
	const handleEscapeKey = useCallback((event) => {
		if (event.key === 'Escape' || event.keyCode === 27) {
			closePopup();
		}
	}, [closePopup]);
	
	useEffect(() => {
		window.addEventListener('keydown', handleEscapeKey);
		
		return () => {
			window.removeEventListener('keydown', handleEscapeKey);
		};
	}, [handleEscapeKey]);
	
	return (
		<div
			className={`onboarding__popup-wrapper ${props.visible ? 'visible' : ''}`}
		>
			<div className="onboarding__popup-contents">
				<img
					src={props.url}
					alt={props.alt}
				/>
				{props.caption && (props.caption.length > 0) && (
					<p className="type-normal-body caption">
						{props.caption}
					</p>
				)}
			</div>
			<div
				className="onboarding__popup-shade"
				onClick={() => {
					props.closePopup();
				}}
			>
				<div className="onboarding__popup-close">
					{CloseSvg}
				</div>
			</div>
		</div>
	);
};

export default ImagePopup;