import React, { Component } from 'react';

import { formatCurrency } from "../../Util";
import Tooltip from "../../common/Tooltip";
import AuthService from "../../service/AuthService";
import LoadingGif from '../../common/components/LoadingGif';

export default class CurrentServicePeriodStats extends Component {
    howYouAreBilledUrl = AuthService.isMarketplace
        ? "https://support.servicedirect.com/how-you-are-billed"
        : "https://support.servicedirect.com/select/how-you-are-billed";

    constructor(props) {
        super(props);

        this.tooltipRef = React.createRef();
    }

    
    getDollarAmountOrGif = (amount) => {
        if (amount === null) {
            return <LoadingGif/>;
        }
        return formatCurrency(amount, 0);
    };

    render() {
        let currentBalance = this.getDollarAmountOrGif(this.props.currentBalance),
            leadCost = this.getDollarAmountOrGif(this.props.leadCost),
            // reverseCredit = getDollarAmountOrGif(this.props.reverseCredit),
            leadCredit = this.getDollarAmountOrGif(this.props.leadCredit),
            unpaidStatement = this.getDollarAmountOrGif(this.props.unpaidStatement)
            // reverseCreditJsx = null;

        if (this.props.leadCredit > 0) {
            leadCredit = "-" + leadCredit;
        }

        return (
            <>
            <h4 className="type-normal-subhead type-heavy type-allcaps type-single-line spacing-30-bottom">
                Current Billing Cycle
            </h4>
            <div className="current-balance__stats-grid spacing-30-bottom">
                <div className="current-balance__stats-grid__cell current-balance__stats-grid__current-balance">
                    <span className="type-large-headline type-heavy type-single-line type-force-newline">
                        { currentBalance }
                    </span>
                    <span className="type-large-body type-heavy type-narrow-line-height">
                        Current{' '}
                        <span className="type-no-break">
                            Balance{' '}
                            <Tooltip
                                position="right"
                                content={
                                    <>
                                    <span className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                        Current Balance
                                    </span>
                                    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                        This displays the amount you owe on your Service Direct Account. This includes all
                                        past due balances, current Billable Leads { AuthService.isMarketplace
                                        ? 'and Lead Credits.'
                                        : ', Lead Credits, and Monthly Subscription costs.'}
                                    </p>
                                    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                        Learn more about <a className="type-heavy" target="_blank" href={this.howYouAreBilledUrl}>How You Are Billed.</a>
                                    </p>
                                    </>
                                }
                                modalContainerRef={this.tooltipRef}
                            />
                        </span>
                    </span>
                </div>
            </div>
            <div className="current-balance__stats-grid">
                <div className="current-balance__stats-grid__cell current-balance__stats-grid__lead-cost">
                    <span className="type-small-headline type-single-line type-force-newline">
                        { leadCost }
                    </span>
                    <span className="type-large-body type-heavy type-narrow-line-height">
                        Lead{' '}
                        <span className="type-no-break">
                            Cost{' '}
                            <Tooltip
                                position="right"
                                content={
                                    <>
                                        <span className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                            Lead Cost
                                        </span>
                                        <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            The total cost of all Billable Leads for the current statement period, since your last Lead Statement.
                                        </p>
                                        <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            Learn more about <a className="type-heavy" target="_blank" href={this.howYouAreBilledUrl}>How You Are Billed.</a>
                                        </p>
                                    </>
                                }
                                modalContainerRef={this.tooltipRef}
                            />
                        </span>
                    </span>
                </div>
                <div className="current-balance__stats-grid__cell current-balance__stats-grid__lead-credit">
                    <span className="type-small-headline type-single-line type-force-newline">
                        { leadCredit }
                    </span>
                    <span className="type-large-body type-heavy type-narrow-line-height">
                        Lead{' '}
                        <span className="type-no-break">
                            Credit{' '}
                            <Tooltip
                                position="right"
                                content={
                                    <>
                                        <span className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                            Lead Credit
                                        </span>
                                        <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            Lead Credits are received when a lead is deemed Non-Billable after your credit
                                            card was charged for that lead.
                                        </p>
                                        <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            Learn more about <a className="type-heavy" target="_blank" href={this.howYouAreBilledUrl}>How You Are Billed.</a>
                                        </p>
                                    </>
                                }
                                modalContainerRef={this.tooltipRef}
                            />
                        </span>
                    </span>
                </div>
                <div className="current-balance__stats-grid__cell current-balance__stats-grid__unpaid-statement">
                    <span className="type-small-headline type-single-line type-force-newline">
                        { unpaidStatement }
                    </span>
                    <span className="type-large-body type-heavy type-narrow-line-height">
                        Unpaid{' '}
                        <span className="type-no-break">
                            Statement{' '}
                            <Tooltip
                                position="right"
                                content={
                                    <>
                                        <span className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                            Unpaid Statement
                                        </span>
                                        <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            This is the sum of all past due balances from previous Statement periods. This
                                            amount must be paid in full before we can continue advertising your Campaigns.
                                        </p>
                                        <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            Learn more about <a className="type-heavy" target="_blank" href={this.howYouAreBilledUrl}>How You Are Billed.</a>
                                        </p>
                                    </>
                                }
                                modalContainerRef={this.tooltipRef}
                            />
                        </span>
                    </span>
                </div>
                {/*{ reverseCreditJsx }*/}
            </div>
            </>
        );
    }
}