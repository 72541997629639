import React, { Component } from 'react';
import post from "../Requests";
import axios from 'axios';
import ErrorContainer from './ErrorContainer';

export default class LoginHelpView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {
                email: '',
            },
            email: '',
            submitted: false,
            messages: [],
            messageType: "",
            successMessage: [],
        };

        this.cancelSignal = axios.CancelToken.source();
    }

    componentDidMount() {
        // handle special messaging from password reset expired
        if (this.props.location.state) {
            let message = { text: this.props.location.state.message, type: 'error' };
            this.setState({ messages: [message] });
        }
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
    }

    submit = (event) => {
        event.preventDefault();

        // validate input
        let emailElem = document.getElementById("password-reset-email");
        let hasError = this.validate(emailElem);

        // Don't submit if we have an error
        if (hasError) {
            return false;
        }

        // Don't submit if we have already have a request out
        if (this.state.submitted === true) {
            return;
        }

        // set submitted to true
        this.setState({submitted: true});

        // get the values from the inputs
        let email = emailElem.value.trim();

        // build out the payload
        let payLoad = {email: email};

        let url = "auth/request_password_reset";
        post(url, payLoad, this.cancelSignal.token)
            .then((resp) => {
                if (resp.data) {
                    // never actually update submitted, because that'll kick off
                    // multiple password reset emails which would be bad
                    this.setState({successMessage: [resp.data.message], messageType: "success" });
                }
            })
            // if an error occurred, like blocked email address, reset the button
            .catch(() => this.setState({submitted: false}));
    }

    /**
     * Validate the email field is good.
     *   Checks if it has a value
     *   Checks that it meets email format.
     */
    validate = (email) => {
        let stateClone = { ...this.state };
        let hasError = false;
        let message;
        let error;
        // Handle if the value is missing
        if (email.validity.valueMissing) {
            error = "Email is required.";
            message = { text: error, type: "error" };
            stateClone.messages.push(message);
            stateClone.errors.email = error;
            hasError = true;
        }
        // Handle if the value is not formatted as email
        else if (email.validity.typeMismatch) {
            error = "Email is incorrect format.";
            message = { text: error, type: "error" };
            stateClone.messages.push(message);
            stateClone.errors.email = error;
            hasError = true;
        }
        else {
            stateClone.errors.email = '';
        }

        this.setState(stateClone);

        return hasError;
    }

    render() {
        let emailError = this.state.errors.email;

        return <div className="login-screen">
            {this.props.children}
            <div className="login__gradient-shade" style={this.props.loginGradientStyle()}/>
            <div className="login__content-column">
                <div className="login__logo-frame spacing-20-bottom">
                    <img src={ this.props.whiteLabelPartnership.logo_uri_for_login_page }
                         alt={ this.props.whiteLabelPartnership.white_label_name }
                    />
                </div>
                <div className="login__content-box">
                    <div className="login__content-header">
                        <h1 className="type-small-headline type-heavy type-single-line no-margin">
                            Login Help
                        </h1>
                    </div>
                    <div className="login__content">
                        <p className="type-large-body type-heavy type-single-line no-margin-top spacing-10-bottom" style={{color:'#59bc25'}}>
                            { this.state.successMessage}
                        </p>
                        <p className="type-normal-body type-heavy type-single-line no-margin-top spacing-10-bottom">
                            Forgot Login Password?
                        </p>
                        <p className="type-small-body type-narrow-line-height spacing-10-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                            Type your email address below to reset your password.
                        </p>
                        <form onSubmit={this.submit}>
                            <ErrorContainer errors={[this.state.errors.email]} />
                            <div className="no-margin-top spacing-20-bottom">
                                <input
                                    type="email"
                                    className={"ui-text-field ui-normal-text-input" + (emailError ? " ui-alert" : '') }
                                    name="email"
                                    id="password-reset-email"
                                    required
                                />
                            </div>
                            <div className="popup__form__row popup__form__row__slam-right spacing-20-top no-margin-bottom">
                                <div className="popup__form__cell popup__form__cell__100__mobile">
                                    <button type="submit"
                                            disabled={this.state.submitted}
                                            className="button ui-normal-button ui-full-width-button-mobile">
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
    }
}

/**
 * The props this component takes in are from the react router
 * (history, location, match)
 */
LoginHelpView.propTypes = {
};
