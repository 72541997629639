import React from "react";

const LeadDetailBlock = ({
    title,
    children
}) => {
    return (
        <div className="admin-call-insights-block spacing-20-top">
            <div className="leadlog__detail-section leadlog__detail-section__admin">
                <p className="type-large-body type-heavy spacing-30-bottom">
                    {title}
                </p>
                {children}
            </div>
        </div>
    )
}

export default LeadDetailBlock