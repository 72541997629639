import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { convertToCSV, getIntBetween } from '../../../Util'
import { LoadingMessages } from '../../StaticValues'
import { IconExportSvg } from '../../Svgs'
import { IconButton } from '../form_elements/Button'

const ExportCSV = ({
    dataGetter,
    downloadPrefix = 'data_',
    isDisabled,
    buttonText = 'Export To CSV',
}) => {
    const [isExporting, setIsExporting] = useState(false)
    const [isExportingMessage, setIsExportingMessage] = useState(
        'Export In Progress - Hang Tight'
    )

    const handleExportCsv = () => {
        setIsExporting(true)
        let exportButtonChangerInterval = setInterval(() => {
            const message = LoadingMessages[getIntBetween(0, LoadingMessages.length - 1)]
            setIsExportingMessage(message)
        }, 5000)
        dataGetter &&
            dataGetter()
                .then((data) => {
                    const csvData = convertToCSV(data)
                    // create a Blob for csv data
                    const blob = new Blob([csvData], { type: 'text/csv' })
                    // create an object url for downloading
                    const url = window.URL.createObjectURL(blob)
                    // creating an html element
                    const a = document.createElement('a')
                    // set the blob url on the html element
                    a.setAttribute('href', url)
                    // create the filename and set the anchor attribute
                    const filename = `${downloadPrefix}${format(
                        new Date(),
                        'yyyy_MM_dd_hh_mm_ss'
                    )}.csv`
                    a.setAttribute('download', filename)
                    // trigger the download
                    a.click()
                    setIsExporting(false)
                    // clear the button text loading message interval
                    clearInterval(exportButtonChangerInterval)
                    exportButtonChangerInterval = null
                })
                .catch((error) => {
                    console.error(error)
                    window.alert('Error exporting CSV - see developer console')
                    setIsExporting(false)
                    // clear the button text loading message interval
                    clearInterval(exportButtonChangerInterval)
                    exportButtonChangerInterval = null
                })
    }
    return (
        <Fragment>
            <IconButton
                variant="span"
                size="small"
                disabled={isExporting || isDisabled}
                onClick={handleExportCsv}
                icon={IconExportSvg}
            >
                <span className="type-small-body">
                    {isExporting ? isExportingMessage : buttonText}
                </span>
            </IconButton>
        </Fragment>
    )
}

ExportCSV.propTypes = {
    dataGetter: PropTypes.func,
    downloadPrefix: PropTypes.string,
    isDisabled: PropTypes.bool,
}

export default ExportCSV
