import styled from 'styled-components'

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: start;
    justify-content: start;
    grid-column-gap: 32px;
`

export const StatWrapper = styled.div`
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
    cursor: ${({isClickable}) => isClickable ? 'pointer' : 'auto'};
`
