import React from 'react';

const PopupFormGroup = (props) => {
	let classes = 'popup-form-group';
	
	if (props.grayBackground) {
		classes += ' popup-form-group__gray-background';
	}
	if (props.noBottomMargin) {
		classes += ' popup-form-group__no-bottom-margin';
	}
	if (props.extraClass) {
		classes += ` ${props.extraClass}`;
	}
	
	return (
		<div className={classes}>
			{props.children}
		</div>
	);
};

export default PopupFormGroup;
