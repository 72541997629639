import React, { Component } from 'react';

import Tooltip from '../../common/Tooltip';
import UserNotificationsRow from './UserNotificationsRow';
import AuthService from "../../service/AuthService";

export default class UserNotificationsTable extends Component {
    constructor(props) {
        super(props);

        this.tooltipRef = React.createRef();
    }

    notificationTypesTooltipContent() {
        return <>
            <span className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height spacing-10-bottom">
                Notification Types
            </span>

            <span className="type-normal-body type-black type-force-newline type-heavy type-narrow-line-height">
                Billing
            </span>
            <span className="type-small-body type-black type-force-newline type-narrow-line-height spacing-10-bottom">
                Receive an email for billing matters such as Statements and
                payment confirmations.
            </span>

            <span className="type-normal-body type-black type-force-newline type-heavy type-narrow-line-height">
                Lead Review
            </span>
            <span className="type-small-body type-black type-force-newline type-narrow-line-height spacing-10-bottom">
                Receive an email when there is activity on a Lead submitted for
                review. (This is different from Lead Delivery notifications,
                which are set on a per Campaign basis in Campaigns Manager).
            </span>

            <span className="type-normal-body type-black type-force-newline type-heavy type-narrow-line-height">
                Account
            </span>
            <span className="type-small-body type-black type-force-newline type-narrow-line-height spacing-10-bottom">
                Receive emails about important overall account-related
                information.
            </span>

            <span className="type-small-body type-black type-force-newline type-narrow-line-height">
                Learn more in{' '}
                <a className="type-heavy" target="_blank"
                   href={AuthService.isMarketplace
                       ? "https://support.servicedirect.com/manage-users"
                       : "https://support.servicedirect.com/select/manage-users"}>
                    Manage Users and Notifications.</a>
            </span>
        </>;
    }

    render() {
        return (
            <table className="users-table__table standardtable spacing-20-bottom">
            <thead className="standardtable__lightheader">
                <tr className="type-small-body type-heavy">
                    <th id="user-email" className="users-table__col__email">
                        <div className="users-table__header__label">
                            mySD User
                        </div>
                    </th>
                    <th>
                        Last Login
                    </th>
                    <th id="user-notifications" className="users-table__col__notifications">
                        <div className="users-table__header__label">
                            Receive Notifications For{' '}
                            <Tooltip
                                content={this.notificationTypesTooltipContent()}
                                position="top"
                                modalContainerRef={this.tooltipRef}
                            />
                        </div>
                    </th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {this.props.users.map((user, idx) => (
                    <UserNotificationsRow
                        key={`user-notifications-${idx}`}
                        user={user}
                        timeZones={this.props.timeZones}
                        users={this.props.users}
                        updateModalContent={this.props.updateModalContent}
                        handleNotificationSettingClick={this.props.handleNotificationSettingClick}
                        updateUser={this.props.updateUser}
                        deleteUser={this.props.deleteUser}
                        resendInvite={this.props.resendInvite}
                        sendResetPasswordEmail={this.props.sendResetPasswordEmail}
                        notificationTypesTooltipContent={this.notificationTypesTooltipContent}
                    />
                ))}
            </tbody>
            </table>
        )
    }
}