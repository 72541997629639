import axios from "axios";
import type {CancelTokenSource} from 'axios';

import {post} from '../Requests';
import {toCamelObject, toSnakeObject} from "../Util";

interface Point {
    lat: string;
    lng: string;
}

export interface ZipCode {
    city: string;
    country: string;
    latitude: number;
    longitude: number;
    state: string;
    zip: string;
    shape: Point[] | { MULTIPOLYGON: Point[][] };
    distance: number;
    chosen: boolean;

    // added properties (not received from the API)
    paths?: string[];
    hover?: boolean;
}

export default class ZipCodeApiService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal: CancelTokenSource;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    /**
     * removing non-alphanumeric characters because our API won't accept dashes in the route
     * e.g. a Canadian zip code of "A2B-3C4" will be sent as "A2B3C4"
     * @param {string} zipCode
     * @return {string}
     */
    cleanZipCode(zipCode: string) {
        return zipCode.replace(/[^0-9a-z]/gi, '');
    }

    /**
     * returns a list of zip codes that are within the radius of given zip codes
     * @param {string} zipCode - the centered zip code
     * @param {number} radius - the radius (in miles) from the zip code
     * @param {string[]} [ignore=[]] - array of zip codes already cached and thus (kek) not needed to be fetched
     * @param {boolean} [setChosen=false] - if set to true, will set the "chosen" property on the server side
     *  if the zip code is within the given radius
     * @return {Promise<ZipCode[]>}
     */
    getZipCodesByRadius = (zipCode: string, radius: number, ignore: string[] = [], setChosen: boolean = false)
        : Promise<ZipCode[]> =>
        post(`resources/zip_codes/${this.cleanZipCode(zipCode)}/${radius}`,
            toSnakeObject({ignore, setChosen}),
            this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data.zip_codes));

    /**
     * returns the data (coordinates, shape, etc.) of a given set of zip codes
     * @param {string[]} zipCodes - list of zip codes to get the coordinates of
     * @param {string} [mainZipCode] - optional main zip code; if present, will also return the
     *  distance of each zip code from this main zip code
     * @return {Promise<{zipCodes: ZipCode[], invalidZipCodes: string[]}>}
     */
    getZipCodesCoordinates = (zipCodes: string[], mainZipCode?: string): Promise<{ zipCodes: ZipCode[], invalidZipCodes: string[] }> => {
        let data = {zipCodes};
        if (mainZipCode) {
            data.mainZipCode = this.cleanZipCode(mainZipCode);
        }

        return post('resources/zip_codes/coordinates', toSnakeObject(data), this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));
    }
}