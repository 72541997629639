import _times from 'lodash/times'
import React, { memo, useEffect, useState } from 'react'
import { DateRangeDropdown } from '../../../common/components/form_elements/DateRange'
import MultiSelectList, {
    MultiSelectListSkeleton,
} from '../../../common/components/form_elements/MultiSelectList'
import { DropdownRangeInput } from '../../../common/components/form_elements/RangeInput'
import SkeletonText from '../../../common/components/Skeleton/SkeletonText'
import { FullColumn } from '../../../common/layouts'
import DateRangeService from '../../../service/DateRange'
import {
    InputWrapper,
    FilterButton,
    ButtonsWrapper,
    StyledTextArea,
    GridRow2211,
    GridRow111111,
    GridRow11112,
    GridRow33,
} from './styles'
import Divider from './Divider'
import { cloneDeep } from 'lodash'

export default memo((props) => {
    const {
        filters = [],
        selectedFilters = {},
        handleApplyFilters = () => null,
        handleResetFilters = () => null,
        loading,
        reloading,
        initialCampaignIds = '',
        initialAdNetwork2CampaignIds = '',
    } = props
    const dateRangeService = new DateRangeService()
    const [filterValues, setFilterValues] = useState(selectedFilters)
    const [isTooManyAn2cIds, setIsTooManyAn2cIds] = useState(false)

    /**
     * effect to watch for incoming deep changes to filters. this is used
     * because the sort column and sort order may change by clicking on the
     * table header, and this change needs to be reflected in the filter
     * selection
     */
    useEffect(() => {
        if (selectedFilters && !loading) {
            setFilterValues(selectedFilters)
        }
    }, [selectedFilters, loading])

    // if contractor ids are set, then the campaign dropdown is active,
    // so don't keep up with contractor ids in the campaign text box
    let _initialCampaignIds = initialCampaignIds
    if (filterValues?.contractor_ids) {
        _initialCampaignIds = ''
    }
    const [campaignIds, setCampaignIds] = useState(_initialCampaignIds)
    const [adNetwork2CampaignIds, setAdNetwork2CampaignIds] = useState(
        initialAdNetwork2CampaignIds
    )

    const onSelectFilter = (name) => (values: Array) => {
        const newFilterValues = { ...filterValues, [name]: values }
        setFilterValues(newFilterValues)
    }

    const onSelectRange =
        (name) =>
        ([rangeMin, rangeMax]) => {
            const minKey = `${name}_min`
            const maxKey = `${name}_max`
            const newFilterValues = {
                ...filterValues,
                [minKey]: rangeMin,
                [maxKey]: rangeMax,
            }
            setFilterValues(newFilterValues)
        }

    const onSetDateRange =
        (name) =>
        ([dateFrom, dateTo]) => {
            const minKey = `${name}_date_from`
            const maxKey = `${name}_date_to`
            const newFilterValues = {
                ...filterValues,
                [minKey]: dateRangeService.formatDateYYYYMMDD(dateFrom),
                [maxKey]: dateRangeService.formatDateYYYYMMDD(dateTo),
            }
            setFilterValues(newFilterValues)
        }

    const deselectAll = (name) => () => {
        const newFilterValues = { ...filterValues, [name]: [] }
        setFilterValues(newFilterValues)
    }

    const filterInputs = filters.map((filter) => {
        let {
            type,
            name,
            display,
            values = [],
            disabled: filterDisabled = false,
        } = filter
        // sanitize filter values
        values = values.filter((v) => v.text && v.value)

        let disabled = false
        let emptyLabel = 'Optional'

        // special consideration for campaign selector - we only want the campaign
        // dropdown to be available if at least one client is selected, and we
        // only want to see the campaigns for the selected client(s)
        if (name === 'campaign_ids') {
            disabled = true
            emptyLabel = 'Select Client First'
            if (filterValues?.contractor_ids?.length > 0) {
                disabled = false
                emptyLabel = 'Optional'
                values = values.filter((v) =>
                    filterValues?.contractor_ids?.includes(v.contractor_id)
                )
            }
            // we only want to allow interaction with the dropdown if the campaign id text
            // area is not being used
            if (campaignIds?.length > 0) {
                emptyLabel = 'All'
                disabled = true
            }
        }

        let input
        if (type === 'multiselect' || type === 'select') {
            input = (
                <MultiSelectList
                    multiselect={type === 'multiselect'}
                    label={display}
                    name={display}
                    emptyLabel={filterDisabled ? 'Disabled' : emptyLabel}
                    allowDeselectAll={true}
                    onDeselectAll={deselectAll(name)}
                    useTextFilter={true}
                    options={values}
                    selections={filterValues[name] || []}
                    onChange={onSelectFilter(name)}
                    extraClass={filterValues[name]?.length > 0 ? 'ui-highlight' : ''}
                    disabled={disabled || filterDisabled || reloading}
                    wideDropdown={name === 'campaign_ids' || name === 'contractor_ids'}
                />
            )
        } else if (type === 'range') {
            const {
                range_min,
                range_max,
                value_format,
                range_step,
                // value_min,
                // value_max,
            } = filter
            input = (
                <DropdownRangeInput
                    onChange={onSelectRange(name)}
                    emptyLabel={'Optional'}
                    label={display}
                    format={value_format}
                    rangeMin={+range_min}
                    rangeMax={+range_max}
                    rangeStep={range_step}
                    min={filterValues[`${name}_min`]}
                    max={filterValues[`${name}_max`]}
                    extraClass={
                        filterValues[`${name}_min`] !== undefined ||
                        filterValues[`${name}_max`] !== undefined
                            ? 'ui-highlight'
                            : ''
                    }
                    disabled={disabled || reloading}
                />
            )
        } else if (type === 'daterange') {
            // default date range info
            let defaultDateFrom
            let defaultDateTo = new Date()
            const isEmpty =
                filterValues[`${name}_date_to`] == null &&
                filterValues[`${name}_date_from`] == null &&
                defaultDateFrom == null &&
                defaultDateTo == null
            input = (
                <DateRangeDropdown
                    dateFrom={filterValues[`${name}_date_from`] || defaultDateFrom}
                    isEmpty={isEmpty}
                    dateTo={filterValues[`${name}_date_to`] || defaultDateTo}
                    onChange={onSetDateRange(name)}
                    label={display}
                    extraClass={
                        filterValues[`${name}_date_from`] ||
                        filterValues[`${name}_date_to`]
                            ? 'ui-highlight'
                            : ''
                    }
                    disabled={disabled || reloading}
                />
            )
        }

        return <InputWrapper key={`filter_${name}`}>{input}</InputWrapper>
    })
    const updateCampaignIds = (e) => {
        setCampaignIds(e.target.value)
    }

    const updateAdNetworkIdsIds = (e) => {
        // do not proceed if more than 1000 adnetwork2campaignids are present
        // this seems to cause api errors due to the url length
        if (e.target.value.split(/\D/).filter((v) => v !== '').length > 1000) {
            setIsTooManyAn2cIds(true)
            return
        }
        setIsTooManyAn2cIds(false)
        setAdNetwork2CampaignIds(e.target.value)
    }

    const onApplyFilters = () => {
        // format the campaign ids
        const filtersClone = cloneDeep(filterValues)
        const formattedCampaignIds = campaignIds
            .split(/\D/)
            .filter((v) => v !== '')
            .join(',')
        // if campaign ids are provided in the text box,
        // they should override the campaign selections from
        // the dropdown
        if (campaignIds.length > 0) {
            onSelectFilter('campaign_ids')(campaignIds.split(/\D/))
            filtersClone.campaign_ids = campaignIds.split(/\D/)
        }

        // if campaign ids or ad network 2 campaign ids are present,
        // remove the campaign type filter values
        if (campaignIds.length > 0 || adNetwork2CampaignIds.length > 0) {
            console.log('clearing campaign type filters')
            delete filtersClone?.campaign_type
            onSelectFilter('campaign_type')(null)
        }

        // format the ad network 2 campaign ids
        const formattedAdNetworkIds = adNetwork2CampaignIds
            .split(/\D/)
            .filter((v) => v !== '')
            .join(',')

        handleApplyFilters(filtersClone, formattedCampaignIds, formattedAdNetworkIds)
    }

    const onResetFilters = () => {
        setCampaignIds('')
        setAdNetwork2CampaignIds('')
        setFilterValues({})
        handleResetFilters()
    }

    const isCampaignTextAreaDisabled = filterValues?.contractor_ids?.length > 0
    const filtersEmpty =
        Object.keys(filterValues)?.length == 0 &&
        campaignIds?.length == 0 &&
        adNetwork2CampaignIds?.length == 0
    const isApplyDisabled = reloading || filtersEmpty || isTooManyAn2cIds
    const applyFiltersText = reloading ? 'Loading...' : filtersEmpty ? 'Apply' : 'Apply'

    return (
        <FullColumn>
            <Divider label="Filters" />
            <GridRow2211>{filterInputs.slice(0, 4)}</GridRow2211>
            <GridRow111111>{filterInputs.slice(4, 9)}</GridRow111111>
            <GridRow33>
                <InputWrapper>
                    <StyledTextArea
                        label="Campaign IDs"
                        id="campaign_ids"
                        name="campaign_ids"
                        className="ui-textarea ui-normal-text-input"
                        value={campaignIds}
                        onChange={updateCampaignIds}
                        placeholder={isCampaignTextAreaDisabled ? '' : 'Optional'}
                        disabled={isCampaignTextAreaDisabled}
                    />
                </InputWrapper>
                <InputWrapper>
                    <StyledTextArea
                        label="Campaign Lead Source IDs"
                        id="ad_network_ids"
                        name="ad_network2campaign_ids"
                        className="ui-textarea ui-normal-text-input"
                        value={adNetwork2CampaignIds}
                        onChange={updateAdNetworkIdsIds}
                        placeholder="Optional"
                        hasError={isTooManyAn2cIds}
                        errorMessage={isTooManyAn2cIds ? 'Max 1,000 IDs' : null}
                    />
                </InputWrapper>
            </GridRow33>
            <GridRow111111>{filterInputs.slice(9, 15)}</GridRow111111>
            <GridRow111111>{filterInputs.slice(15, 20)}</GridRow111111>
            <GridRow111111>
                {filterInputs.slice(20, 24)}
                <div />
            </GridRow111111>

            <Divider label="Metrics Settings & Sorting" />
            <GridRow11112>
                {filterInputs.slice(24, 28)}
                <ButtonsWrapper>
                    <FilterButton
                        disabled={reloading}
                        variant="text"
                        onClick={onResetFilters}
                    >
                        <span className="type-heavy">Reset</span>
                    </FilterButton>
                    <FilterButton disabled={isApplyDisabled} onClick={onApplyFilters}>
                        {applyFiltersText}
                    </FilterButton>
                </ButtonsWrapper>
            </GridRow11112>
        </FullColumn>
    )
})

export const FiltersInputSkeleton = (props) => {
    const filterInputs = _times(28, (i) => (
        <InputWrapper width={i <= 1 ? 450 : null} key={`filter_skel_${i}`}>
            <MultiSelectListSkeleton />
        </InputWrapper>
    ))
    return (
        <FullColumn>
            <Divider label="Filters" />
            <GridRow2211>{filterInputs.slice(0, 4)}</GridRow2211>
            <GridRow111111>{filterInputs.slice(4, 9)}</GridRow111111>
            <GridRow33>
                <InputWrapper>
                    <StyledTextArea
                        disabled={true}
                        label={<SkeletonText width={90} />}
                        id="campaign_ids"
                        name="campaign_ids"
                        className="ui-textarea ui-normal-text-input"
                    />
                </InputWrapper>
                <InputWrapper>
                    <StyledTextArea
                        disabled={true}
                        label={<SkeletonText width={120} />}
                        id="ad_network2campaign_ids"
                        name="campaign_ids"
                        className="ui-textarea ui-normal-text-input"
                    />
                </InputWrapper>
            </GridRow33>
            <GridRow111111>{filterInputs.slice(9, 15)}</GridRow111111>
            <GridRow111111>{filterInputs.slice(15, 20)}</GridRow111111>
            <GridRow111111>
                {filterInputs.slice(20, 24)}
                <div />
            </GridRow111111>

            <Divider label="Metrics Settings & Sorting" />
            <GridRow11112>
                {filterInputs.slice(24, 28)}
                <ButtonsWrapper>
                    <FilterButton variant="text" disabled={true}>
                        <span className="type-heavy">Reset</span>
                        {/* Reset Filters */}
                    </FilterButton>
                    <FilterButton disabled={true}>Select Filters</FilterButton>
                </ButtonsWrapper>
            </GridRow11112>
        </FullColumn>
    )
}
