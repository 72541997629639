import React from 'react'
import ServiceCategoryRuleConfirmation from '../../../../../common/components/ServiceCategoryRuleConfirmation'
import { ContentWrapper, RuleWrapper } from '../AddEditRules/styles'
import { Wrapper } from './styles'

export default (props) => {
    const { changes = [], affiliate, isNewAffiliate = false } = props

    let confirmationView =
        changes != null ? (
            <ContentWrapper>
                {changes?.map((c, i) => (
                    <RuleWrapper border={i < changes.length - 1} key={`confirm_${i}`}>
                        <ServiceCategoryRuleConfirmation
                            rule={c}
                            // note={isNewAffiliate ? null : c.informational_message || null}
                            note={c.informational_message || null}
                        />
                    </RuleWrapper>
                ))}
            </ContentWrapper>
        ) : null
    if (changes?.length == 0) {
        confirmationView = (
            <span className="type-normal-body padding-12 spacing-24-bottom">
                No Service Category Rules
            </span>
        )
    }
    const title = isNewAffiliate
        ? 'Confirm Service Category Rules'
        : `Confirm Service Category Rule Changes for ${affiliate.ad_network_name}`
    const tableTitle = isNewAffiliate
        ? 'Service Category Rules'
        : 'Service Category Rules With Changes'
    return (
        <Wrapper>
            <span className="type-normal-subhead type-heavy spacing-6-bottom">
                {title}
            </span>
            {!isNewAffiliate && (
                <span className="type-normal-body">
                    The following service category rules will be updated.
                </span>
            )}
            <span className="spacing-24-bottom" />
            <span className="type-normal-body type-heavy module__header padding-12 spacing-24-bottom">
                {tableTitle}
            </span>
            {confirmationView}
        </Wrapper>
    )
}
