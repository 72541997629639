import React, { Component } from 'react';

/***
 * Add/remove class .button__green to the buttons to toggle green state for saved/unsaved
 * presentation.
 */

export default class FloatingButtonsBlock extends Component {
    render() {
        return (
            <>
                <button className="button ui-small-button no-mobile-view-visible"
                    onClick={ this.props.onClick }
                    disabled={ this.props.disabled }
                >
                    Save Your Progress
                </button>
                <button className="button ui-small-button no-full-view-visible no-tablet-view-visible"
                    onClick={ this.props.onClick }
                    disabled={ this.props.disabled }
                >
                    Save
                </button>
            </>
        );
    }
}