import React, { useState } from 'react'
import DropdownSelect from '../../../components/DropdownSelect'

const AdminLeadTags = ({
    options = [],
    onSaveLeadTags,
    leadTags,
    leadId,
}) => {
    const [isSavingNewTag, setIsSavingNewTag ] = useState(false)
    const handleSetTags = (vals, action) => {
        const { action: actionName, option } = action
        if (actionName === 'create-option') {
            if (!window.confirm(`Create new tag "${option.label}"?`)) {
                vals.pop()
            }
            else {
                // on save, mutate to lowercase
                vals[vals.length-1].label = vals[vals.length-1].label?.toLowerCase()
                vals[vals.length-1].value = vals[vals.length-1].value?.toLowerCase()
            }
            setIsSavingNewTag(true)
        }
        onSaveLeadTags && onSaveLeadTags(vals).then(() => setIsSavingNewTag(false))
    }

    return (
        <DropdownSelect
            label={'Tags'}
            options={options}
            values={leadTags}
            placeholder={'Select...'}
            getOptionValue={(option) => option.id}
            onChange={handleSetTags}
            closeMenuOnSelect={true}
            isDisabled={isSavingNewTag}
            multiselect={true}
        />
    )
}

export default AdminLeadTags
