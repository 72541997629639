import React, {Component} from 'react';
import axios from 'axios';

import BillingThresholdChangeModal from '../billing_center/BillingThresholdChangeModal';
import CurrentServicePeriodStats from '../billing_center/CurrentServicePeriodStats';
import RecentStatements from './RecentStatements';
import ThresholdProgressBar from '../billing_center/ThresholdProgressBar';
import CreditCardService from "../../service/CreditCard";
import { formatCurrency } from "../../Util";
import {errorHandler} from "../../Requests";
import AuthService from "../../service/AuthService";
import LoadingGif from '../../common/components/LoadingGif';

export default class DashboardBillingSnapshot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            primaryCardLastFour: "xxxx"
        };

        this.cancelSignal = axios.CancelToken.source();

        this.creditCardService = new CreditCardService();
    }

    componentDidMount() {
        this.creditCardService.getPrimary()
            .then(
                (primaryCard) =>
                    this.setState({primaryCardLastFour: primaryCard.last_four})
            )
            .catch(errorHandler);
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
    }

    requestThresholdChange = () => {
        this.props.updateModalContent(
            <BillingThresholdChangeModal
                updateModalContent={this.props.updateModalContent}
                currentThreshold={this.props.balanceSummary.threshold}
                updateMessageBlocks={this.props.updateMessageBlocks}
            />,
            'Request Billing Threshold Change',
            null,
            true
        );
    };

    render() {
        const { threshold } = this.props?.balanceSummary
        const thresholdDisplay = threshold ? formatCurrency(threshold, 0) : <LoadingGif />
        return (
            <>
                <div className="module__header module__halfwidth">
                    <h3 className="type-large-subhead type-single-line">
                        Current Balance
                    </h3>
                </div>
                <div className="module__contentbox module__halfwidth">
                    <CurrentServicePeriodStats
                        currentBalance={this.props.balanceSummary.current_balance}
                        leadCost={this.props.balanceSummary.lead_cost}
                        // reverseCredit={this.props.balanceSummary.reverse_credit}
                        leadCredit={this.props.balanceSummary.lead_credit}
                        unpaidStatement={this.props.balanceSummary.unpaid_statement}
                    />

                    <h4 className="type-normal-subhead type-heavy type-allcaps type-single-line spacing-50-top spacing-30-bottom">
                        Billing Threshold
                    </h4>
                    <div className="spacing-30-bottom">
                        <ThresholdProgressBar threshold={this.props.balanceSummary.threshold}
                                              balance={this.props.balanceSummary.current_balance}/>
                    </div>
                    <div className="spacing-50-bottom">
                        <p className="type-small-body">
                            Your credit card ending in{' '}
                            <b>{this.state.primaryCardLastFour}</b>{' '}
                            is billed each time your Current Balance meets your Billing Threshold of{' '}
                            <b>{thresholdDisplay}</b>{' '}
                            or at the end of each month if you have not reached your Threshold.
                        </p>
                        <p className="type-small-body">
                        <span role="button"
                              className="type-heavy type-blue"
                              onClick={this.requestThresholdChange}
                        >
                            Request Billing Threshold Change
                        </span>
                            <span className="minor-side-rails">•</span>
                            <a className=" type-heavy" target="_blank" rel="noopener"
                               href={AuthService.isMarketplace
                                   ? "https://support.servicedirect.com/how-you-are-billed"
                                   : "https://support.servicedirect.com/select/how-you-are-billed"}
                            >
                                Learn More About When You Will Be Billed
                            </a>
                        </p>
                    </div>
                    <h4 className="type-normal-subhead type-heavy type-allcaps type-single-line spacing-20-bottom">
                        Recent Statements
                    </h4>

                    <RecentStatements
                        updateBalanceSummary={this.props.updateBillingSummary}
                        updateModalContent={this.props.updateModalContent}
                        updateMessageBlocks={this.props.updateMessageBlocks}
                        primaryCardLastFour={this.state.primaryCardLastFour}
                    />

                    <p className="type-align-right spacing-30-top no-margin-bottom type-centered-mobile">
                        <a href="/billing" className="button ui-normal-button">
                            View Billing Center
                        </a>
                    </p>
                </div>
            </>
        );
    }
}