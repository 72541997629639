import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import Button from '../../common/components/form_elements/Button'
import FullWidthLayout from '../../common/layouts/FullWidthLayout'
import LeadSources from './lead_sources'
import ServiceCategoryRules from './service_category_rules'
import DIDNumbers from './did_numbers'
import ButtonContext from './ButtonContext'
import { Toolbar, TabsWrapper, ButtonWrapper } from './styles'
import LoadingGif from '../../common/components/LoadingGif'

export default (props) => {
    const [buttonText, setButtonText] = useState('Loading')
    const [buttonAction, setButtonAction] = useState(() => null)
    const setButtonContext = (text, callback) => {
        setButtonText(text)
        setButtonAction(() => callback)
    }
    const history = useHistory()
    // manage the tab index
    const [tabIndex, setTabIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const location = useLocation()
    const tabsOrder = ['lead-sources', 'service-category-rules', 'did-numbers']

    // effect to set the document (page) title
    useEffect(() => {
        document.title = 'Lead Sources'
    }, [])

    // set the tab from the url on the initial render
    useEffect(() => {
        let tab = location?.pathname?.split('/')?.pop()
        // if no tab is detected, set the default to the first tab
        if (tab == '') {
            tab = tabsOrder[0]
        }
        // if the tab is not recognized, default to the first tab
        let _tabIndex = tabsOrder.indexOf(tab)
        if (_tabIndex < 0) {
            _tabIndex = 0
        }
        setTabIndex(_tabIndex)
        setIsLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // handler for when a tab is clicked
    const handleTabClick = (index) => {
        // set the tab index
        setTabIndex(index)
        // update the url
        const path = location?.pathname?.split('/')
        path.pop()
        path.push(tabsOrder[index])
        history.push(path.join('/'))
    }

    const pageTitle = (
        <Toolbar>
            <div style={{ paddingLeft: '20px' }}>
                <h2 className="type-normal-headline type-heavy padding-40-bottom">
                    Lead Sources
                </h2>
            </div>
            <ButtonWrapper>
                <Button
                    onClick={() => {
                        buttonAction()
                    }}
                    disabled={buttonText === 'Loading'}
                >
                    {buttonText}
                </Button>
            </ButtonWrapper>
        </Toolbar>
    )

    let pageContent = <LoadingGif />
    if (!isLoading) {
        pageContent = (
            <Tabs selectedIndex={tabIndex} onSelect={handleTabClick}>
                <TabList>
                    <Tab>Lead Sources</Tab>
                    <Tab>Service Category Rules</Tab>
                    <Tab>DID Numbers</Tab>
                </TabList>

                <TabPanel>{tabIndex === 0 ? <LeadSources {...props} /> : null}</TabPanel>
                <TabPanel>{tabIndex === 1 ? <ServiceCategoryRules /> : null}</TabPanel>
                <TabPanel>{tabIndex === 2 ? <DIDNumbers /> : null}</TabPanel>
            </Tabs>
        )
    }

    return (
        <FullWidthLayout title={pageTitle}>
            <TabsWrapper>
                <ButtonContext.Provider value={setButtonContext}>
                    {pageContent}
                </ButtonContext.Provider>
            </TabsWrapper>
        </FullWidthLayout>
    )
}
