import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import queryString from "query-string";

import LoadingGif from "../../common/components/LoadingGif";
import EmailRow from "./EmailRow";
import Pagination from "../../common/components/Pagination/v1.0.1/Pagination";
import type {IPaginationInterface} from "../../Interfaces/IPaginationInterface";
import {formatNumber, toCamel} from "../../Util";
import SearchField from "../../common/components/form_elements/SearchField";
import Checkbox from "../../common/components/form_elements/Checkbox";
import Tooltip from "../../common/Tooltip";
import ResourcesService from "../../service/ResourcesService";
import type {Email} from "../../Interfaces/Email";
import Modal from "../../common/Modal";
import {errorHandler} from "../../Requests";

/**
 * The component in charge of the users view
 */
class EmailsView extends Component {
    basePagination = {
        rowsPerPage: process.env.REACT_APP_PAGINATION_NUM_PER_PAGE,
        pageNum: 1,
        totalRows: 0,
        totalPages: 0
    };

    constructor(props) {
        super(props);
        document.title = 'Email Addresses';

        this.state = {
            loading: true,
            emails: [],
            pagination: {...this.basePagination},
            orderBy: 'bounce',
            orderByReverse: true,
            search: '',
            bouncedEmails: true,
            associatedEmails: true,
            contractorIds: [],

            modal: {
                content: null,
                header: null,
                width: ''
            }
        };

        this.resourcesService = new ResourcesService();
        this.searchFieldRef = React.createRef();
        this.tooltipRef = React.createRef();
    }

    componentDidMount() {
        this.setAppliedFiltersFromUrl();
    }

    componentWillUnmount() {
        this.resourcesService.cancelSignal.cancel();
    }

    /**
     * get the users from the server based on the current filters
     */
    getEmailAddresses = () => {
        this.setState({loading: true});
        const filter = {orderBy: this.parseOrderBy()};
        if (this.state.search) {
            filter.search = this.state.search;
        }
        if (this.state.bouncedEmails) {
            filter.bouncedEmails = true;
        }
        if (this.state.associatedEmails) {
            filter.associatedEmails = true;
        }
        if (this.state.contractorIds.length > 0) {
            filter.contractorIds = this.state.contractorIds.join(',');
        }

        this.resourcesService.getEmailAddresses(this.state.pagination, filter)
            .then((data) => this.setState({
                emails: data.data.emailAddresses,
                pagination: data.pagination
            }))
            .catch(errorHandler)
            .finally(() => this.setState({loading: false}));
    };

    /**
     * returns the className of a header based on the current sort criteria and the base class
     * @param {string} orderBy - sort variable of this header
     */
    getHeaderClass = (orderBy) => {
        let baseClass = 'sortable';
        if (this.state.orderBy === orderBy) {
            baseClass += this.state.orderByReverse
                ? ' sortable-down'
                : ' sortable-up';
        }

        return baseClass;
    };

    /**
     * parses the filter message based on the
     * @return {any} the JSX message
     */
    getFilterMessage = () => {
        if (this.state.loading) {
            return <span className="loading-results-message">Loading Email Addresses...</span>;
        }

        if (this.state.pagination.totalRows === 1) {
            return 'Showing 1 Email Address';
        }

        return <>
            Showing{' '}
            <span className="type-heavy">
                {formatNumber(Math.min((this.state.pagination.pageNum - 1) * this.state.pagination.rowsPerPage + 1, this.state.pagination.totalRows), 0)}
                –
                {formatNumber(Math.min(this.state.pagination.pageNum * this.state.pagination.rowsPerPage, this.state.pagination.totalRows), 0)}
                </span>{' '}
            Email Addresses out of <span
            className="type-heavy">{formatNumber(this.state.pagination.totalRows, 0)}</span>
        </>;
    };

    /**
     * applies filters from the URL
     */
    setAppliedFiltersFromUrl = () => {
        const queryParams = queryString.parse(this.props.location.search, {
            arrayFormat: 'bracket',
            parseBooleans: true
        });

        const newState = {pagination: {...this.basePagination}};
        const fields = ['page_num', 'order_by', 'search', 'bounced_emails', 'associated_emails', 'contractor_id', 'contractor_ids'];

        fields.forEach((filter) => {
            if (typeof queryParams[filter] === 'undefined') {
                return;
            }

            const camelFilter = toCamel(filter);
            switch (filter) {
                case 'page_num':
                    newState.pagination[camelFilter] = queryParams[filter];
                    break;

                case 'order_by':
                    if (queryParams.order_by[0] === '-') {
                        queryParams.order_by = queryParams.order_by.substr(1);
                        newState.orderByReverse = true;
                    }
                    newState.orderBy = queryParams.order_by;
                    break;

                // convert single contractor id to the plural contractor ids representation
                case 'contractor_id':
                    newState.contractorIds = [queryParams.contractor_id];
                    break;

                case 'contractor_ids':
                    newState.contractorIds = queryParams.contractor_ids.split(',');
                    break;

                case 'bounced_emails':
                case 'associated_emails':
                    newState[camelFilter] = queryParams[filter];
                    break;

                default:
                    newState[camelFilter] = queryParams[filter];
            }
        });

        this.setState(newState, this.getEmailAddresses);
    };

    /**
     * sorts the users by a given field name
     * @param {string} orderBy - the field to sort by
     */
    applySort = (orderBy) => {
        this.setState(
            {
                orderBy,
                orderByReverse: this.state.orderBy === orderBy
                    ? !this.state.orderByReverse
                    : false
            },
            this.updateUrlAndUsers
        );
    };

    /**
     * Update the URL and fetch the corresponding users based on the component's state
     */
    updateUrlAndUsers = () => {
        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});

        queryParams.page_num = this.state.pagination.pageNum;
        queryParams.order_by = this.parseOrderBy();

        if (this.state.search) {
            queryParams.search = this.state.search;
        }
        else {
            delete queryParams.search;
        }

        if (this.state.contractorIds.length > 0) {
            queryParams.contractor_ids = this.state.contractorIds.join(',');
            // since we convert contractor_id to contractor_ids, make sure we delete the original reference if existing
            delete queryParams.contractor_id;
        }
        else {
            delete queryParams.contractor_ids;
        }

        queryParams.bounced_emails = !!this.state.bouncedEmails;
        queryParams.associated_emails = !!this.state.associatedEmails;

        const newPath = '/admin/emails?' + queryString.stringify(queryParams, {arrayFormat: 'bracket'});

        this.props.history.push(newPath);
        this.getEmailAddresses();
    };

    /**
     * Parses the state orders into the API expected value
     * @return {string}
     */
    parseOrderBy = () => {
        let orderBy = this.state.orderByReverse
            ? '-'
            : '';

        return orderBy + this.state.orderBy;
    };

    /**
     * Handles a page change
     * @param {Event} event
     * @param {number} newPageNum
     */
    handlePageChange = (event, newPageNum) => {
        const {pagination}: IPaginationInterface = this.state;
        pagination.pageNum = newPageNum;
        this.setState({pagination});
        this.updateUrlAndUsers();
    };

    /**
     * updates the search string
     * @param {KeyboardEvent} event
     */
    handleSearchChange = (event) =>
        this.setState({search: event.target.value});

    /**
     * handles update "bounced emails only" change
     */
    handleToggleBouncedEmails = () =>
        this.setState({
                bouncedEmails: !this.state.bouncedEmails,
                pagination: {...this.basePagination}
            },
            this.updateUrlAndUsers);

    /**
     * handles update "associated emails only" change
     */
    handleToggleAssociatedEmails = () =>
        this.setState({
                associatedEmails: !this.state.associatedEmails,
                pagination: {...this.basePagination}
            },
            this.updateUrlAndUsers);

    /**
     * handles submitting the search via the search button or clicking enter in the input field
     * @param {MouseEvent|KeyboardEvent} event
     */
    handleSearch = (event) => {
        event.preventDefault();
        this.setState({
                pagination: {...this.basePagination},
                bouncedEmails: false,
                associatedEmails: false
            },
            this.updateUrlAndUsers);
    };

    /**
     * handles a reset bounce score operation by removing the record or resetting its values in the view
     * @param {number} emailId
     */
    handleResetEmailBounceScore = (emailId: number) => {
        const emails: Email[] = this.state.emails;
        const emailIndex = emails.findIndex((email: Email) => email.emailId == emailId);

        // if displaying bounce emails only, remove the "no longer bounced" email
        if (this.state.bouncedEmails) {
            emails.splice(emailIndex, 1);
        }
        // otherwise, reset the email bounce data
        else {
            emails[emailIndex].bounce = 0;
            emails[emailIndex].markedSpam = 0;
            emails[emailIndex].bounceExpire = null;
        }
        this.setState({emails});
    };

    updateModalContent = (content, options = {}) =>
        this.setState({
            modal: {
                content,
                header: options.header,
                width: options.wide
                    ? 'wide'
                    : ''
            }
        });

    render() {
        const fieldsCount = 9;
        let emailRows;
        if (this.state.loading) {
            emailRows = <tr>
                <td colSpan={fieldsCount}>
                    <div className="type-centered padding-30">
                        <LoadingGif/>
                    </div>
                </td>
            </tr>;
        }
        else if (this.state.emails.length === 0) {
            let searchString = this.state.search.trim();
            emailRows = <tr>
                <td colSpan={fieldsCount}>
                    {searchString
                        ? <div className="type-centered padding-30">
                            No records found for:<br/>
                            <span className="type-heavy">{this.state.search}</span>
                        </div>
                        : <div className="type-centered padding-30">No records found</div>}
                </td>
            </tr>;
        }
        else {
            emailRows = this.state.emails.map((email, index) =>
                <EmailRow key={index} email={email} onBounceReset={this.handleResetEmailBounceScore}
                          updateModalContent={this.updateModalContent}/>
            );
        }

        return <div className="page-width-wide">
            <Modal
                content={this.state.modal.content}
                header={this.state.modal.header}
                width={this.state.modal.width}
                updateModalContent={this.updateModalContent}
                flatBottom={true}
            />
            {this.state.loading &&
            <span className="spinny-loader shadowed fixed"/>}

            <div className="row padding-50-top padding-30-bottom">
                <div className="wide-format-col">
                    <div className="simpleflex__row">
                        <div className="simpleflex__cell">
                            <h2 className="type-normal-headline type-heavy padding-20-bottom">
                                Email Addresses
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="clear-block"/>
            </div>

            <div className="row">
                <div className="wide-format-col page__contentbox">
                    <div className="simpleflex__row simpleflex__row__wrap spacing-18-bottom">
                        <div className="simpleflex__cell">
                            <SearchField
                                placeholder="Search by Email Address or Name"
                                onChange={this.handleSearchChange}
                                value={this.state.search}
                                searchFieldRef={this.searchFieldRef}
                                onFormSubmit={this.handleSearch}
                                textLabel={true}
                                wide={true}
                                tooltip={<Tooltip
                                    position="top"
                                    content={
                                        <span
                                            className="type-small-body type-black type-force-newline type-notransform type-align-left type-narrow-line-height">
                                            If the search field contains the "<b className="type-heavy">@</b>" sign, the
                                            search will target the <b className="type-heavy">email addresses</b> field.
                                            <br/>
                                            Otherwise the search will target the <b className="type-heavy">name</b>{' '}
                                            field of the{' '}
                                            <b className="type-heavy">Users, Contractors, Contact, and Campaigns</b>.
                                        </span>
                                    }
                                    modalContainerRef={this.tooltipRef}
                                />}
                            />
                        </div>
                        <div className="simpleflex__cell spacing-12-mobile spacing-12-tablet">
                            <div className="type-normal-body type-single-line">
                                <Checkbox name="bounced-emails"
                                          label="Emails with bounce score"
                                          checked={this.state.bouncedEmails} onChange={this.handleToggleBouncedEmails}
                                          tooltip={<Tooltip
                                              position="top"
                                              content={
                                                  <span
                                                      className="type-small-body type-black type-force-newline type-notransform type-align-left type-narrow-line-height">
                                                      Filters the Email Addresses list to only display email addresses
                                                      that have a bounce of 3 - i.e. "hard bounce".
                                                  </span>
                                              }
                                              modalContainerRef={this.tooltipRef}
                                          />}
                                />
                            </div>
                        </div>
                        <div className="simpleflex__cell spacing-12-mobile spacing-12-tablet">
                            <div className="type-normal-body type-single-line">
                                <Checkbox name="associated-emails"
                                          label="Associated Emails only"
                                          checked={this.state.associatedEmails}
                                          onChange={this.handleToggleAssociatedEmails}
                                          tooltip={<Tooltip
                                              position="top"
                                              content={
                                                  <span
                                                      className="type-small-body type-black type-force-newline type-notransform type-align-left type-narrow-line-height">
                                                      Filters the Email Addresses list to only display email addresses
                                                      that are associated with a user or a campaign
                                                  </span>
                                              }
                                              modalContainerRef={this.tooltipRef}
                                          />}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="simpleflex__row simpleflex__row__wrap__mobile type-centered-mobile spacing-18-bottom">
                        <div className="simpleflex__cell simpleflex__cell__middlealigned simpleflex__cell__maxed">
                            <div
                                className="type-normal-subhead type-narrow-line-height no-margin-top spacing-18-bottom-mobile">
                                {this.getFilterMessage()}
                            </div>
                        </div>
                    </div>

                    <div className="scroll-table__container">
                        <div className="scroll-table__container__shadowedge__left" ref={this.leftShadowEdgeRef}/>
                        <div className="scroll-table__container__shadowedge" ref={this.rightShadowEdgeRef}/>
                        <div
                            className="scroll-table__container__scrollbox scroll-table__container__scrollbox__flexible-height scroll-table__container__scrollbox-mobile">
                            <table className="scroll-table__table type-normal-body type-single-line sortable-table">
                                <thead>
                                <tr className="type-small-body type-heavy">
                                    <th className={this.getHeaderClass('email_address')}
                                        onClick={() => this.applySort('email_address')}>
                                        <div>Email Address</div>
                                    </th>
                                    <th>
                                        <div>Clients</div>
                                    </th>
                                    <th>
                                        <div>Users</div>
                                    </th>
                                    <th>
                                        <div>Campaigns</div>
                                    </th>
                                    <th className={this.getHeaderClass('last_sent')}
                                        onClick={() => this.applySort('last_sent')}>
                                        <div>Last Email Sent</div>
                                    </th>
                                    <th className={this.getHeaderClass('bounce')}
                                        onClick={() => this.applySort('bounce')}>
                                        <div>Bounce Score</div>
                                    </th>
                                    <th className={this.getHeaderClass('marked_spam')}
                                        onClick={() => this.applySort('marked_spam')}>
                                        <div>Marked as Spam</div>
                                    </th>
                                    <th>
                                        <div>Deliveries</div>
                                    </th>
                                    <th className={this.getHeaderClass('creation_timestamp')}
                                        onClick={() => this.applySort('creation_timestamp')}>
                                        <div>Creation Date</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {emailRows}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="spacing-34-top">
                        <Pagination
                            pageNum={this.state.pagination.pageNum}
                            totalRows={this.state.pagination.totalRows}
                            rowsPerPage={this.state.pagination.rowsPerPage}
                            totalPages={this.state.pagination.totalPages}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>

                    <div id="TEMP"
                         style={{height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {/*TODO remove after Brian Jones fixes the contextual menu overflow bug*/}
                        Don't mind me, I'm just a huge temporary div
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(EmailsView);
