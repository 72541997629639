import styled from 'styled-components'
import Button from '../../../common/components/form_elements/Button'
import TextArea from '../../../common/components/form_elements/TextArea'

export const InputWrapper = styled.div`
    display: flex;
    /* flex: 1; */
    flex-direction: column;
    margin: 0px 20px 20px 0;
    &:last-child {
        margin-right: 0;
    }
    /* width: ${(props) => props.width || 300}px; */
`

export const FilterButton = styled(Button)`
    width: 180px;
    align-content: space-around;
    /* height: 100%; */
`

export const ButtonsWrapper = styled.div`
    display: flex;
    align-content: center;
    align-self: center;
    justify-self: flex-end;
    margin-left: 20px;
    width: 380px;
`

export const StyledTextArea = styled(TextArea)`
    min-height: 45px;
    height: 45px;
    resize: vertical;
`

export const GridRow2211 = styled.div`
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
`
export const GridRow111111 = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
`
export const GridRow11112 = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr) 2fr;
`
export const GridRow33 = styled.div`
    display: grid;
    grid-template-columns: 3fr 3fr;
`
