import styled from 'styled-components'

export const HeaderLabel = styled.span`
    display: flex;
    /* flex-shrink: 1; */
    flex-grow: 1;
    align-items: center;
    font-weight: bold;
    color: #fff;
    height: 100%;
    white-space: pre-wrap;
    cursor: pointer;
`

export const ArrowWrapper = styled(HeaderLabel)`
    margin-left: 9px;
    height: 15px;
    max-width: 12px;
    flex-shrink: 0;
`
export const Column = styled.div`
    cursor: ${(props) => (props.selectable ? 'pointer' : 'default')};
    height: 100%;
`

export const HeaderCell = styled(Column)`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
`
