import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatDate, getClientMenuLinks} from "../../Util";
import {setUsersSubMenu} from "../../UtilJsx";
import type {User} from "../../Interfaces/User";
import {CheckMarkSvg, CloseSvg} from "../../common/Svgs";
import UserService from "../../service/UserService";
import ContextualMenu from "../../common/ContextualMenu";
import DeleteUserModal from "../../client/settings/DeleteUserModal";

const sdAdminUrl = process.env.REACT_APP_SD_ADMIN_URL;
const hubSpotEnvironmentId = process.env.REACT_APP_HUBSPOT_ID;

export default class UserRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientMenuLinks: [],
            displayBounce: false
        };

        this.userService = new UserService();
    }

    componentDidMount(): void {
        const {user}: User = this.props;
        const clientMenuLinks = getClientMenuLinks(user.contractorId, user.hubspotCompanyId);
        this.setState({clientMenuLinks});
    }

    componentWillUnmount(): void {
        this.userService.cancelSignal.cancel();
    }

    getUsersLinks = (): void => {
        const {clientMenuLinks} = this.state;
        this.userService.getByAdmin(this.props.user.contractorId)
            .then((users) => {
                setUsersSubMenu(users, clientMenuLinks);
                this.setState({clientMenuLinks});
            });
    };

    toggleBounceInfo = (): void => {
        this.setState({displayBounce: !this.state.displayBounce});
    };

    /**
     * opens the delete user modal
     */
    deleteUser = () => {
        const user: User = this.props.user;

        this.props.updateModalContent(
            <DeleteUserModal
                user={user}
                updateModalContent={this.props.updateModalContent}
                updateHubSpot={true}
                deleteUser={
                    (userId, updateHubSpot) => {
                        this.userService.delete(userId, updateHubSpot)
                            .then((response) => {
                                // notify the parent of the user "deletion"
                                this.props.onDeleteUser(userId);
                                // notify the user of the retire success
                                this.props.updateMessageBlocks(response.data.message, 'success');
                            })
                            .then((response) => this.props.updateModalContent(response))
                    }
                }
            />,
            {header: 'Delete User'}
        );
    };

    render() {
        const user: User = this.props.user;

        return <tr className="sortable-table__row">
            <td>
                <button className="button-clean ui-float-right" title="Delete user"
                        onClick={this.deleteUser}>
                    <span className="inline-icon inline-icon__middle inline-icon__20">{CloseSvg}</span>
                </button>
                <a href={`${sdAdminUrl}edit_user/${user.userId}`} target="_blank">{user.username}</a>
                {user.username != user.userEmailAddress &&
                <p className="text-alert type-heavy">User email: {user.userEmailAddress}</p>}
                {user.contactEmailAddress != user.userEmailAddress &&
                <p className="text-alert type-heavy">Contact email: {user.contactEmailAddress}</p>}
                <p className="type-force-newline type-extra-small-body type-single-line">{user.name}</p>
            </td>
            <td>
                <div className="simpleflex__row">
                    <div className="simpleflex__cell">
                        <ContextualMenu
                            linkLabel={user.contractorName}
                            menuLinks={this.state.clientMenuLinks}
                            onFirstOpen={this.getUsersLinks}
                            scrollboxRef={this.props.scrollboxRef}
                        />
                    </div>
                </div>
            </td>
            <td>{user.phoneNumber}</td>
            <td>
                {user.hubspotContactVid
                ? <a href={`https://app.hubspot.com/contacts/${hubSpotEnvironmentId}/contact/${user.hubspotContactVid}`}
                   target="_blank">{user.hubspotContactVid}</a>
                : <span className="type-grey">&lt;No HS Contact&gt;</span>}
            </td>
            <td>{formatDate(user.lastLogin)}</td>
            <td>{formatDate(user.lastSent)}</td>
            <td>
                {user.bounce > 0 &&
                <>
                    Bounce Score: {user.bounce}
                    <p className="spacing-12">
                        Send Email Disabled on {formatDate(user.latestBounceTimestamp)}
                        {user.bounceExpire &&
                        <span>
                        , expires {formatDate(user.bounceExpire)}
                    </span>}
                    </p>
                </>}
                {user.subType &&
                (this.state.displayBounce
                    ? <>
                        <div className="type-keep-newline spacing-12-bottom">{user.subType}</div>
                        <button className="type-force-newline button-clean type-blue"
                                onClick={this.toggleBounceInfo}>
                            Hide
                        </button>
                    </>
                    : <button onClick={this.toggleBounceInfo}
                              className="type-force-newline button-clean type-blue">More...</button>)}
            </td>
            <td className="spam-column type-centered">{user.markedSpam == 1 && CheckMarkSvg}</td>
            <td>
                <div className={user.deliveries == 0 ? 'type-grey' : ''}>Deliveries: {user.deliveries}</div>
                <div className={user.bounces == 0 ? 'type-grey' : ''}>Bounces: {user.bounces}</div>
                <div className={user.complaints == 0 ? 'type-grey' : ''}>Complaints: {user.complaints}</div>
            </td>
            <td>{formatDate(user.creationTimestamp)}</td>
        </tr>;
    }
}

UserRow.propTypes = {
    user: PropTypes.object.isRequired,
    scrollboxRef: PropTypes.object,
    updateModalContent: PropTypes.func,
    onDeleteUser: PropTypes.func,
    updateMessageBlocks: PropTypes.func
};
