import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingGif from '../../../common/components/LoadingGif'
import MajorAlerts from '../../../common/MajorAlerts'
import Modal from '../../../common/Modal'
import DataTable from './DataTable'
import {
    getAffiliates,
    getServiceCategoryRulesGrouped,
    getServiceCategoryRuleTypes,
    saveServiceCategoryRules,
} from '../../../service/AffiliateService'
import { TableWrapper } from '../lead_sources/styles'
import { PageWrapper, BottomToolbar, TooltipContent } from './styles'
import Toolbar from './Toolbar'
import ButtonContext from '../ButtonContext'
// import AddEditRules from './Modals/AddEditRules'
import CreateNew from './Modals/CreateNew'
import Confirmation from './Modals/Confirmation'
import EditsInProgressModal from '../lead_sources/Modals/EditsInProgressModal'
import EditsErrorModal from '../../mp_campaign_optimizer/Modals/EditsErrorModal'
import ExportCSV from '../../../common/components/ExportCSV'
import Tooltip from '../../../common/Tooltip'
import RuleTypeTooltips from './RuleTypeTooltips'
import usePreventUnload from '../../../common/hooks/usePreventUnload'

export default (props) => {
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const { search = '' } = history.location
    const [allRules, setAllRules] = useState()
    const [columns, setColumns] = useState()
    const [sortColumn, setSortColumn] = useState()
    const [sortOrder, setSortOrder] = useState()
    const [filters, setFilters] = useState()
    // const [filteredRules, setFilteredRules] = useState()
    const [filterValues, setFilterValues] = useState()
    const [modalContent, setModalContent] = useState()
    const [affiliates, setAffiliates] = useState()
    const [modalHeaderText, setModalHeaderText] = useState(
        'Add New Service Category Rules'
    )
    const [tooltipDefinitions, setTooltipDefinitions] = useState([])

    useEffect(() => {
        fetchRules(search.replace('?', ''))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // effect to set the button context in the main view
    const setButtonContext = useContext(ButtonContext)
    useEffect(() => {
        if (allRules != null && filters != null && affiliates != null) {
            setButtonContext('Create Service Category Rule', () => {
                setModalHeaderText('Add New Service Category Rules')
                setModalContent(
                    <CreateNew
                        adNetworks={filters.filter((f) => f.name == 'ad_network_ids')[0]}
                        serviceCategories={
                            filters.filter((f) => f.name == 'industry_ids')[0]
                        }
                        affiliates={affiliates}
                        onPreview={handlePreviewChanges}
                        onClose={handleCloseModal}
                        // onSelectAffiliate={handleSelectAffiliate}
                    />
                )
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allRules, filters, affiliates])

    // hook to prevent the user from accidentally
    // exiting the page while the modal is active
    usePreventUnload(modalContent != null)

    const handleGoBack = (affiliate, changes) => () => {
        setModalContent(
            <CreateNew
                adNetworks={filters.filter((f) => f.name == 'ad_network_ids')[0]}
                serviceCategories={filters.filter((f) => f.name == 'industry_ids')[0]}
                affiliates={affiliates}
                onPreview={handlePreviewChanges}
                onClose={handleCloseModal}
                selectedAffiliate={affiliate}
                changes={changes}
            />
        )
    }

    const handleConfirmChanges = (changes, affiliate) => {
        setModalContent(<EditsInProgressModal />)
        saveServiceCategoryRules(changes)
            .then((result) => {
                setModalContent(null)
            })
            .catch((err) => {
                console.log({ err })
                // get response code
                const { data } = err?.response
                let message = 'The API responded with the following errors: \n'
                if (data?.message) {
                    message += data.message
                    window.alert(message)
                    props?.updateMessageBlocks &&
                        props.updateMessageBlocks([`${message}`], 'error')
                    setModalContent(
                        <CreateNew
                            adNetworks={
                                filters.filter((f) => f.name == 'ad_network_ids')[0]
                            }
                            serviceCategories={
                                filters.filter((f) => f.name == 'industry_ids')[0]
                            }
                            affiliates={affiliates}
                            onPreview={handlePreviewChanges}
                            onClose={handleCloseModal}
                            selectedAffiliate={affiliate}
                            changes={changes}
                        />
                    )
                } else {
                    setModalContent(<EditsErrorModal />)
                }
            })
            .finally(() => {
                // refetch the rules
                fetchRules(search.replace('?', ''))
            })
    }

    const handlePreviewChanges = ({ ruleChanges, affiliate }) => {
        const { deletedRules, newRules, edits } = ruleChanges
        setModalContent(
            <Confirmation
                onConfirm={handleConfirmChanges}
                onGoBack={handleGoBack(affiliate, ruleChanges)}
                affiliate={affiliate}
                changes={[...deletedRules, ...newRules, ...edits]}
            />
        )
    }

    const fetchRules = (query) => {
        setLoading(true)
        getServiceCategoryRulesGrouped(query)
            .then((data) => {
                const { service_category_rules, columns, filters, sort } = data
                setAllRules(service_category_rules)
                // setFilteredRules(service_category_rules)
                setColumns(columns)
                setFilters(filters)
                if (sort && sort.column) {
                    setSortColumn(sort.column)
                }
                if (sort && sort.order) {
                    setSortOrder(sort.order)
                }
                // also get affiliates
                return getAffiliates()
            })
            .then((data) => {
                const { affiliates } = data
                setAffiliates(affiliates)
                // get rule types
                return getServiceCategoryRuleTypes()
            })
            .then((data) => {
                const tooltips = data?.reduce((acc, ruleType) => {
                    const { tooltip = '', display = '' } = ruleType
                    acc.push({ label: display, definition: tooltip })
                    return acc
                }, [])
                setTooltipDefinitions(tooltips)
            })
            .then(() => {
                setLoading(false)
                // also reset filters
            })
            .catch((error) => {
                console.error(error)
                setLoading(false)
            })
    }

    const handleOrderBy = (property) => {
        let order = sortOrder
        // if clicking the same column
        if (sortColumn === property) {
            if (sortOrder === 'asc') {
                order = 'desc'
            } else {
                order = 'asc'
            }
        } else {
            // if the column is new or different, set to desc
            order = 'desc'
        }
        setSortColumn(property)
        setSortOrder(order)
    }

    const handleCloseModal = () => {
        setModalContent(null)
    }

    const handleEditAffiliateRules = (adNetworkId) => {
        const affiliate = affiliates?.filter((a) => a.ad_network_id === adNetworkId)[0]
        setModalHeaderText(`Edit Service Category Rules for ${affiliate.ad_network_name}`)
        setModalContent(
            <CreateNew
                adNetworks={filters.filter((f) => f.name == 'ad_network_ids')[0]}
                serviceCategories={filters.filter((f) => f.name == 'industry_ids')[0]}
                affiliates={affiliates}
                onPreview={handlePreviewChanges}
                onClose={handleCloseModal}
                selectedAffiliate={affiliate}
                // changes={changes}
            />
        )
    }

    // compute the filtered rules
    let rulesClone = allRules ? [...allRules] : {}
    // filter the rules based on service category (industry id)
    // but require at least one value to be set
    if (filterValues?.industry_ids && filterValues?.industry_ids?.length > 0) {
        rulesClone = rulesClone.filter((r) =>
            filterValues?.industry_ids.includes(r.industry_id)
        )
    }
    // filter the rules based on lead source (ad network)
    // but require at least one value to be set
    if (filterValues?.ad_network_ids && filterValues?.ad_network_ids?.length > 0) {
        rulesClone = rulesClone.filter((r) =>
            filterValues?.ad_network_ids.includes(r.ad_network_id)
        )
    }
    // setFilteredRules(rulesClone)
    const filteredRules = rulesClone

    const dataDisplay = loading ? (
        <LoadingGif />
    ) : columns == null ? (
        <div>No data</div>
    ) : (
        <DataTable
            rows={filteredRules}
            columns={columns}
            onEditHandler={handleEditAffiliateRules}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            onOrderBy={handleOrderBy}
        />
    )

    const handleApplyFilters = (filterVals) => {
        setFilterValues(filterVals)
    }

    const getCsvData = () => {
        // the rules from the API do not have all columns for every entry,
        // but we want all columns for the csv so we normalize the rules in this
        // function
        const columnNames = columns.reduce((acc, col) => {
            const { property, format = 'string' } = col
            acc.push({property, format})
            return acc
        }, [])
        const result = []
        filteredRules.forEach((rule) => {
            const { ad_network_id, ad_network_name, id, industry_id, industry_name } =
                rule
            const normalized = {
                ad_network_id,
                ad_network_name,
                id,
                industry_id,
                industry_name,
            }
            columnNames.forEach((c) => {
                const { property, format } = c
                let val = rule[property] || ''
                if (format?.includes('bool')) {
                    if (val == '1') {
                        val = 'True'
                    }
                    if (val == '0') {
                        val = 'False'
                    }
                }  
                normalized[property] = val
            })
            result.push(normalized)
        })
        // return the normalized result
        return Promise.resolve(result)
    }

    const headerTextWithTooltip = (
        <span className="type-normal-subhead type-heavy type-single-line no-margin">
            <span className="type-heavy">{modalHeaderText}</span>{' '}
            <Tooltip
                position="right"
                content={
                    <TooltipContent className="type-normal-body">
                        <RuleTypeTooltips
                            label="Service Category Rule Definitions"
                            tooltipDefinitions={tooltipDefinitions}
                        />
                    </TooltipContent>
                }
            />
        </span>
    )

    return (
        <Fragment>
            <MajorAlerts />
            <Modal
                content={modalContent}
                header={headerTextWithTooltip}
                onCloseButton={handleCloseModal}
                updateModalContent={() => setModalContent(null)}
                flat={true}
                hideCloseButton={false}
                width="extra_wide"
            />
            <PageWrapper>
                <Toolbar
                    filters={filters}
                    filterValues={filterValues}
                    resultsCount={filteredRules?.length}
                    onApplyFilters={handleApplyFilters}
                    label="Service Category Rules"
                />
                <TableWrapper border={false}>{dataDisplay}</TableWrapper>
                <BottomToolbar>
                    <ExportCSV
                        dataGetter={getCsvData}
                        downloadPrefix="service_category_rules_"
                        isDisabled={filteredRules?.length == 0}
                    />
                </BottomToolbar>
            </PageWrapper>
        </Fragment>
    )
}
