import React, { Component } from 'react';
import MultiSelectList from '../../../common/components/form_elements/MultiSelectList';

/**
 * Modal for user to choose a Hubspot Deal to connect their new Sign Up New Select Client form submission to.
 */
export default class ChooseDealModal extends Component {
    render() {
        return <>
            <p className="type-normal-body spacing-20-bottom">
                More than one Deal were found in Hubspot matching the Contact with that email. Choose one.
            </p>
            <div className="form__row">
                <div className="form__cell form__cell__100 spacing-24-bottom-mobile">
                    <MultiSelectList
                        hasError={false}
                        inputRef={null}
                        label=""
                        value=""
                        name="hubspot_deal_id"
                        onChange={(vals) => this.props.chooseDealInModal(vals[0])}
                        options={this.props.dealOptions}
                        // optionMap={{
                        //     "value_key": "deal_id",
                        //     "text_key": "dealname"
                        // }}
                        getOptionValue={option => option.deal_id}
                        getOptionText={option => option.dealname}
                        required={true}
                    />
                </div>
            </div>
        </>;
    }
}
