export const getScrollY = () => {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};

export const handleFloatingHeader = (header, secondaryNav) => {
    // Address TypeError: header is null
    if (!header) {
        return;
    }

    const thisScrollTop = getScrollY() >= 0
        ? getScrollY()
        : 0;
    const lastScrollTop = document.body.getAttribute('data-last-scroll-top');
    const fixedOn = header.classList.contains('sticky');

    if ((thisScrollTop < lastScrollTop) &&
        (thisScrollTop > 0) &&
        (lastScrollTop > header.offsetHeight)) {
        if (!fixedOn) {
            header.classList.remove('scrolled');
            header.classList.add('sticky');

            if (secondaryNav) {
                secondaryNav.classList.add('sticky');
            }
        }
    }
    else if (thisScrollTop > lastScrollTop) {
        header.classList.remove('sticky');

        if (secondaryNav) {
            secondaryNav.classList.remove('sticky');
        }

        if (thisScrollTop > header.offsetHeight) {
            header.classList.add('scrolled');
        }
    }
    else if (thisScrollTop === 0) {
        header.classList.remove('scrolled');
        
        if (secondaryNav) {
            secondaryNav.classList.remove('sticky');
        }
    }

    document.body.setAttribute('data-last-scroll-top', thisScrollTop);
};