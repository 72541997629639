import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {PlusSignSvg} from "./Svgs";

/*
 * this component is used for inputs in the in the lead delivery settings
 * and bulk edit modal
 */
export default class PopUpModalInputSection extends Component {
    constructor(props) {
        super(props);
        this.collection = this.props.collection;
        this.collectionName = this.props.collectionName;
        this.type = this.props.inputType || "text";
        this.inputClassName = this.props.inputClassName
            || "ui-text-field ui-normal-text-input";
        this.id = this.props.id;
        this.name = this.props.name;
    }

    render() {
        let collection = this.props.collection;
        return collection.map( (collectionItem, key, collectionArr) => {
            if (!!collectionItem.error) {
                this.inputClassName += " ui-text-field ui-alert ";
            } else {
                this.inputClassName = this.props.inputClassName
                || "ui-text-field ui-normal-text-input";
            }

            let elemKey = this.collectionName + "_" + key;
            let deleteButton = (
                <p className="type-small-body type-align-right spacing-10-bottom">
                    <span className="type-heavy type-blue" role="button"
                        onClick={() => this.props.deleteInput(this.collectionName, key)}
                    >
                        Delete
                    </span>
                </p>
            );
            let addButton = (
                <p className="type-small-body spacing-10-top spacing-10-bottom">
                    <span role="button" onClick={() => this.props.addAnother(this.collectionName)}
                        className="type-heavy type-blue">
                        <span className="inline-icon inline-icon__middle inline-icon__20">
                            { PlusSignSvg }
                        </span>
                        Add Another
                    </span>
                </p>
            );
            let input = <input
              type={this.type}
              className={this.inputClassName}
              id={this.id}
              name={this.name}
              value={collectionItem.value}
              key={key}
              onChange={(event) => {
                  if (typeof this.props.handleInputChange === 'function') {
                      this.props.handleInputChange(event, this.collectionName, key);
                  }
              }}
              onBlur={(event) => {
                  if (typeof this.props.handleInputBlur === 'function') {
                      this.props.handleInputBlur(event, this.collectionName, key);
                  }
              }}
              ref={collectionItem.ref}
              placeholder={key === 0 ? this.props.placeholderOnFirst : null}
            />;

            // only one element - don't include deleteButton because you can't delete the only Item, e.g. Phone Number
            //                  - and include an addButton so you can add more
            if (collectionArr.length === 1) {
                return (
                    <div key={"spacing-10-bottom wrapper_div_"+elemKey}>
                        {input}
                        <div className="simpleflex__row">
                            <div className="simpleflex__cell">
                                {addButton}
                            </div>
                        </div>
                    </div>
                );
            }

            // if there's more than one element...
            // ...and this is the last item - include an addButton so you can add more
            if (key + 1 === collectionArr.length) {
                return (
                    <div key={"spacing-10-bottom wrapper_div_"+elemKey}>
                        {input}
                        <div className="simpleflex__row">
                            <div className="simpleflex__cell">
                                {addButton}
                            </div>
                            <div className="simpleflex__cell">
                                {deleteButton}
                            </div>
                        </div>
                    </div>
                );
            }
            // ...not the last and there is more left to go through - deleteButton but no addButton until the end
            return (
                <div key={"spacing-10-bottom wrapper_div_"+elemKey}>
                    {input}
                    <div className="simpleflex__row" key={"row_"+elemKey}>
                        <div className="simpleflex__cell" key={"cell_"+elemKey}>
                            {deleteButton}
                        </div>
                    </div>
                </div>
            );
        });
    }
}

PopUpModalInputSection.propTypes = {
    collection: PropTypes.array.isRequired,
    collectionName: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    inputType: PropTypes.string,
    inputClassName: PropTypes.string,
    handleInputChange: PropTypes.func.isRequired,
    handleInputBlur: PropTypes.func,
    addAnother: PropTypes.func.isRequired,
    deleteInput: PropTypes.func.isRequired,
};
