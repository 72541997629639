export function sanitizeBidMultiplierInput(input, editType) {
    // first validate against regex
    const regex = /^\d*\.?\d{0,2}$/
    let match = input.match(regex)
    if (!match) {
        return false
    }

    if (input === '.') {
        return input
    }

    // then check for valid input
    let numVal = Number(input)
    if (editType === 'set_new_value' && numVal >= 0 && numVal <= 0.8) {
        // for set new value, value can be [0, 0.8]
        return input
    } else if (editType === 'increase_by_percent') {
        // for increase by percent, value can be any number
        return input
    } else if (editType === 'decrease_by_percent' && numVal <= 100) {
        // for decrease by percent, value can be [0, 100]
        return input
    }
    return false
}
