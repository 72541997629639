import React from 'react'

const EnglishyList = ({items = [], andOr = 'and'}) => {
  let jsxList = [];
  for (let i = 0; i < items.length; i++) {
      jsxList.push(
          <span key={i} className="type-heavy type-no-break-except-mobile">{items[i]}</span>
      );

      //
      // Add suitable separator if needed
      //
      if (i < items.length - 1) {
          let separator = ', ';
          if (i == items.length - 2) {
              separator = ' ' + andOr + ' ';
          }
          jsxList.push(separator);
      }
  }
  return jsxList;
};

export default EnglishyList