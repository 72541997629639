import React, { Fragment } from 'react'
import NewAffiliateConfirmationTable from './NewAffiliateConfirmationTable'
import ServiceCategoryRulesConfirmation from '../../service_category_rules/Modals/Confirmation/ConfirmationTable'
import { ActionsWrapper } from './styles'
import Button from '../../../../common/components/form_elements/Button'
import { ContentWindowScroll } from '../../../../common/Modal'

export default (props) => {
    const { deletedRules = [], newRules = [] } = props?.rules
    return (
        <Fragment>
            <ContentWindowScroll>
                <NewAffiliateConfirmationTable isNewAffiliate={true} {...props} />
                <ServiceCategoryRulesConfirmation
                    changes={[...deletedRules, ...newRules]}
                    affiliate={props.affiliate}
                    isNewAffiliate={true}
                />
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button onClick={props?.onGoBack} variant="text">
                    <span className="type-heavy">Previous Step</span>
                </Button>
                <Button
                    // disabled={!isPreviewEnabled}
                    onClick={() => {
                        props.onConfirm({ ...props })
                    }}
                >
                    {'Save Lead Source Settings'}
                </Button>
            </ActionsWrapper>
        </Fragment>
    )
}
