import React, {Component} from 'react';
import {imagePath} from '../../Config';
import PropTypes from 'prop-types';

export default class ImageUploadCell extends Component {
    render() {
        const imageUrl = this.props.image
            ? (imagePath + this.props.image.relative_path)
            : null;
        const imageId = this.props.image ?
            this.props.image.image_id
            : null;

        return (
            <div className="image-uploads__cell">
                <div className="image-uploads__image-box">
                    <div className="image-uploads__image-container">
                        <div className="image-uploads__image-placement__vertical">
                            <img src={imageUrl} alt="Preview"/>
                        </div>
                    </div>
                </div>
                {imageId &&
                <div className="image-uploads__delete-link-box spacing-10-top">
                    <span role="button" className="type-small-body type-heavy type-single-line type-blue"
                          onClick={
                              (e) => {
                                  e.preventDefault();
                                  this.props.showAreYouSureModal(imageId, this.props.imageIndex);
                              }
                          }
                    >
                        Delete
                    </span>
                </div>}
            </div>
        )
    }
}

ImageUploadCell.propTypes = {
    image: PropTypes.object,
    imageIndex: PropTypes.number.isRequired,
};