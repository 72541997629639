import styled from "styled-components";
export * from './DefaultLayout'
export * from './FullWidthLayout'
export * from './Section'

export const Row = styled.div`
  display: flex;
`;

export const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
`

export const CenteredRow = styled(Row)`
  align-items: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const FullColumn = styled(Column)`
  width: ${props => props.width ? `${props.width}px` : '100%'};
  height: ${props => props.height ? `${props.height}px` : '100%'};
`;



export const Spacer = styled.div`
  width: 20px;
`

