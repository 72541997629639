import React from 'react';
import PropTypes from 'prop-types';

import Radio from '../../common/components/form_elements/Radio';
import {getDate, getDateNDaysFromNow} from "../../Util";
import FormModal from '../../common/components/modal/FormModal';
import HighlightedNote from '../../common/HighlightedNote';
import Tooltip from '../../common/Tooltip';


/**
 * Update Campaign Status modal for when a campaign is Paused
 * and can be Re-Enabled.
 *
 * Extends FormModal (subclass of React.Component) in order to get desired
 * scroll-to-top behavior each time the modal is opened.
 */

export default class UpdateStatusPausedModal extends FormModal {

    constructor(props) {
        super(props);
        let reEnableDate, reEnable;
        if (this.props.campaign.auto_unpause_timestamp) {
            reEnableDate = getDate(this.props.campaign.auto_unpause_timestamp);
            reEnable = 'on_date';
        } else {
            reEnableDate = getDateNDaysFromNow(1);
            reEnable = 'now';
        }
        this.state = {
            re_enable: reEnable,
            re_enable_date: reEnableDate,
			
			tooltipCloseFunc: undefined,

            //
            // Set to true when form is submitting in order to
            // visually disable the submit button
            //
            submitting: false,
        };
    }

    submitStatusChange = () => {
        //
        // Set submitting = true so that submit button will be disabled
        //
        this.setState({submitting: true});

        this.props.submitPutStatus(
            'enabled',
            this.state,
            this.props.campaignIndex,
            () => {
                //
                // Set submitting = false so that submit button will be re-enabled
                //
                this.setState({submitting: false});
                this.props.updateModalContent();
            }
        );
    };

    render() {
		return (
			<>
				<p className="type-alert-text type-alert-text__error type-alert-text__no-margin-left type-small-body spacing-5-bottom">
					Campaign Paused
				</p>
				<p className="type-normal-subhead no-margin-top">
					<span className="type-heavy">{this.props.campaign.campaign_name}</span>
				</p>
				<p className="type-normal-body type-heavy type-alert spacing-40-bottom">
					All Lead Generation for your Campaign is currently stopped.
				</p>
				
				<div className="spacing-60-bottom">
					<p className="type-large-body type-heavy spacing-10-bottom">
						Do you want to Enable this Campaign?
					</p>
                	<div className="radio-container spacing-10-bottom">
                    	<Radio
                        	name="reenable-when"
                        	label="Enable my Campaign now"
                        	id="reenable-now"
                        	value="reenable-now"
                        	checked={this.state.re_enable === 'now'}
                        	onChange={() => this.setState({re_enable: 'now'})}
                    	/>
                	</div>
                	<div className="radio-container spacing-10-bottom">
                    	<Radio
                        	name="reenable-when"
                        	label={
                            	<>
                                	<span className="type-right-side-bump">Enable my Campaign in the future on</span>
                                	<input
                                    	// type={(this.state.re_enable_date) ? 'date' : 'text'}
                                    	type='date'
                                    	className="ui-text-field ui-normal-text-input ui-text-field-inline"
                                    	id="reenable-date-picker"
                                    	name="re-enable-date"
                                    	placeholder="Select Date"
                                    	value={this.state.re_enable_date}
                                    	onFocus={(event) => {
                                    	//   event.target.type = 'date';
                                      	event.target.click();
                                      	event.target.focus();
                                    	}}
                                    	// onBlur={(event) => {
                                    	//     if (event.target.value.length === 0) {
                                    	//         event.target.type = 'text';
                                    	//     }
                                    	// }}
                                    	onChange={(event) => this.setState({re_enable_date: event.target.value})}
                                	/>
                            	</>
                        	}
                        	id="reenable-date"
                        	value="reenable-date"
                        	checked={this.state.re_enable === 'on_date'}
                        	onChange={() => this.setState({re_enable: 'on_date'})}
                    	/>
                	</div>
				</div>
				
                <div className="ui-hide-full ui-hide-tablet spacing-40-bottom">
                    <div className="popup__form__row spacing-40-top-mobile">
                        <div className="popup__form__cell popup__form__cell__100__mobile">
                            <button className="button ui-normal-button ui-full-width-button"
                                    onClick={this.submitStatusChange}
                                    disabled={this.state.submitting}
                            >
                                Enable Campaign
                            </button>
                        </div>
                    </div>
					<div className="popup__form__row spacing-20-top-mobile">
						<div className="popup__form__cell popup__form__cell__100__mobile type-centered">
							<span role="button"
								onClick={() => this.props.updateModalContent()}
								className="type-small-body type-heavy type-blue"
							>
								Cancel Edits
							</span>
						</div>
					</div>
                </div>
				
				<HighlightedNote
					header="Campaign Schedule"
					body={
						<p className="type-normal-body">
						Set the days and times your Campaign is Enabled with Campaign Schedule.{' '}
						<Tooltip
							content={
								<>
								<span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
									The Campaign Schedule is the time of day during which your Campaigns are actively competing for Leads.
								</span>
								<span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
									If you’d like to reduce your Lead Volume, then set your Campaign Schedule to fewer hours per day of the week.
								</span>
								<span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
									<span role="button"
										onClick={(e) => {
											this.state.tooltipCloseFunction(() => {
												this.props.updateCampaignScheduleModal({status: 'paused'});
											});
										}}
										className="type-small-body type-heavy type-blue"
									>
										View Campaign Schedule
									</span>
								</span>
								</>
							}
							position="bottom"
							updateTooltipCloseFunction={(val) => {
								this.setState({tooltipCloseFunction: val});
							}}
						/>
						</p>
					}
					extraClass="spacing-60-bottom"
				/>
				
                <div className="ui-hide-mobile">
                    <div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
                        <div className="popup__form__row popup__form__row__slam-right">
                            <div className="popup__form__cell">
                            <span role="button"
                                  onClick={() => this.props.updateModalContent()}
                                  className="type-small-body type-heavy type-blue"
                            >
                                Cancel Edits
                            </span>
                            </div>
                            <div className="popup__form__cell">
                                <button className="button ui-normal-button"
                                        onClick={this.submitStatusChange}
                                        disabled={this.state.submitting}
                                >
                                    Enable Campaign
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
			</>
		);
    }
}

UpdateStatusPausedModal.propTypes = {
    submitPutStatus: PropTypes.func.isRequired,
    updateModalContent: PropTypes.func.isRequired,
    campaignIndex: PropTypes.number.isRequired,
    campaign: PropTypes.object.isRequired,
    modalContainerRef: PropTypes.object.isRequired
};
