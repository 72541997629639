import React from 'react';

import {formatDate} from "../../../Util";
import UpdateStatusEnabledModal from "../UpdateStatusEnabledModal";
import AuthService from "../../../service/AuthService";
import Tooltip from "../../../common/Tooltip";
import UpdateStatusPausedModal from "../UpdateStatusPausedModal";
import UpdateScheduleModal from "../UpdateScheduleModal";
import {useModal} from "../../../common/ModalContext";

const CampaignStatus = (props) => {
    const {campaign} = props;
    const tooltipHeaderClasses = "type-large-body type-force-newline type-black type-force-newline type-heavy type-narrow-line-height type-align-left type-notransform spacing-10-bottom";
    const tooltipParagraphClasses = "type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom";
    const updateModalContent = useModal();

	const updateCampaignScheduleModal = ({ status }) => updateModalContent({
		content: <UpdateScheduleModal
			updateModalContent={updateModalContent}
			campaign={campaign}
			status={status}
			campaignIndex={props.campaignIndex}
			submitPutSchedule={props.submitPutSchedule}
			updateMessageBlocks={props.updateMessageBlocks}
			modalContainerRef={props.modalContainerRef}
			updateCampaignPauseStatusModal={updateCampaignPauseStatusModal}
		/>,
		width: 'wide',
		containerRef: props.modalContainerRef,
		header: 'Campaign Schedule',
		flatBottom: true
	});
	
    const updateCampaignStatusModal = () => updateModalContent({
        content: <UpdateStatusEnabledModal
            updateModalContent={updateModalContent}
            campaign={campaign}
            campaignIndex={props.campaignIndex}
            submitPutStatus={props.submitPutStatus}
            updateMessageBlocks={props.updateMessageBlocks}
            modalContainerRef={props.modalContainerRef}
			updateCampaignScheduleModal={updateCampaignScheduleModal}
        />,
        containerRef: props.modalContainerRef,
        header: 'Campaign Status',
        flatBottom: true
    });

    const updateCampaignPauseStatusModal = () => updateModalContent({
        content: <UpdateStatusPausedModal
            updateModalContent={updateModalContent}
            campaign={campaign}
            campaignIndex={props.campaignIndex}
            submitPutStatus={props.submitPutStatus}
            updateMessageBlocks={props.updateMessageBlocks}
            modalContainerRef={props.modalContainerRef}
			updateCampaignScheduleModal={updateCampaignScheduleModal}
        />,
		containerRef: props.modalContainerRef,
        header: 'Campaign Status',
        flatBottom: true
    });
    // modalContent, modalHeader, modalHeaderLink, modalWidth, modalFlatBottom

    const campaignHasPausedBillingTag = campaign.tags.find((tag) => tag['tag'] === 'Paused Campaign - Billing');

    return <div className="campaigns-table__campaign-status">
        {campaign.status === 'enabled' &&
        <>
            <p className="type-alert-text type-alert-text__success type-alert-text__no-margin-left type-small-body">
                Campaign Enabled
            </p>
            <div className="type-small-body spacing-10">
                {campaign.auto_pause_timestamp &&
                <>
                    Campaign is scheduled to pause on{' '}
                    {formatDate(campaign.auto_pause_timestamp)}
                    {campaign.auto_unpause_timestamp && campaign.auto_unpause_timestamp > campaign.auto_pause_timestamp &&
                    <>, and enable again on{' '}{formatDate(campaign.auto_unpause_timestamp)}</>}
                </>}
            </div>
            <p className="type-small-body spacing-10">
                <span role="button" className="type-blue type-heavy" onClick={updateCampaignStatusModal}>
                    Pause Campaign
                </span>
            </p>
			<p className="type-small-body spacing-10">
				{campaign.active_schedule_percentage ? (
					<>
						<span role="button" className="type-blue type-heavy" onClick={() => updateCampaignScheduleModal({status: 'enabled'})}>
							Campaign Schedule
						</span>{' '}
						set to <span className={`type-heavy ${campaign.active_schedule_percentage < 30 ? 'type-red' : ''}`}>{campaign.active_schedule_percentage}%</span> of Week
					</>
				) : (
					<span role="button" className="type-blue type-heavy" onClick={() => updateCampaignScheduleModal({status: 'enabled'})}>
						Set Campaign Schedule
					</span>
				)}
			</p>
        </>}

        {campaign.status === 'paused' &&
        //
        // When Campaign has been Paused due to Billing:
        //     - Show status as PAUSED - BILLING
        //     - Include copy about paying Unpaid Statements
        //     - Include link to Billing Center
        // When whole Client is Paused for Billing:
        //     - PAUSED campaigns still show as just "PAUSED", but:
        //     - Disable "Enable Campaign" link, showing Tooltip on click
        //     - Grey out copy saying Campaign can/will be Enabled
        //
        (campaignHasPausedBillingTag
            ? <>
                <p className="type-alert-text type-alert-text__no-margin-left type-small-body">
                    Campaign Paused - Billing
                </p>
                <p className="type-small-body spacing-10">
                    You can Enable this Campaign after paying your Unpaid Statement(s).
                </p>
                <p className="type-small-body spacing-10">
                    <a href="/billing" className="type-heavy">
                        Review Statements and Payment Methods
                    </a>
                </p>
				<p className="type-small-body spacing-10">
					{campaign.active_schedule_percentage ? (
						<>
							<span role="button" className="type-blue type-heavy" onClick={() => updateCampaignScheduleModal({status: 'paused'})}>
								Campaign Schedule
							</span>{' '}
							set to <span className={`type-heavy ${campaign.active_schedule_percentage < 30 ? 'type-red' : ''}`}>{campaign.active_schedule_percentage}%</span> of Week
						</>
					) : (
						<span role="button" className="type-blue type-heavy" onClick={() => updateCampaignScheduleModal({status: 'paused'})}>
							Set Campaign Schedule
						</span>
					)}
				</p>
            </>
            : <>
                <p className="type-alert-text type-alert-text__no-margin-left type-small-body">
                    Campaign Paused
                </p>
                <div
                    className={"type-small-body spacing-10" + (props.contractorPausedBilling ? " type-grey" : "")}>
                    {AuthService.canUnpauseCampaign
                        ? (campaign.auto_unpause_timestamp
                            ? <>
                                Campaign is scheduled to enable on{' '}
                                {formatDate(campaign.auto_unpause_timestamp)}
                            </>
                            : <>You can Enable this Campaign anytime</>)
                        : 'An Onboarding Specialist will be contacting you soon to enable this campaign'}
                </div>
                {AuthService.canUnpauseCampaign &&
                <p className="type-small-body spacing-10">
                    {props.contractorPausedBilling
                        ? <Tooltip
                            content={
                                <>
                                    <span className={tooltipHeaderClasses}>
                                        Unpaid Statement - Cannot Enable
                                    </span>
                                    <span className={tooltipParagraphClasses}>
                                        You cannot Enable any Campaigns until all outstanding Statements are paid.{' '}
                                    </span>
                                    <span className={tooltipParagraphClasses}>
                                        See and pay your unpaid Statements{' '}
                                        <a href="/billing">here</a>.
                                    </span>
                                </>
                            }
                            anchor={<span className="type-heavy type-grey ui-pointer-cursor">
                                Update Campaign Status
                            </span>}
                            position="left"
                        />
                        : <span role="button" className="type-blue type-heavy" onClick={updateCampaignPauseStatusModal}>
                            Update Campaign Status
                    </span>}
                </p>}
				<p className="type-small-body spacing-10">
					{campaign.active_schedule_percentage ? (
						<>
							<span role="button" className="type-blue type-heavy" onClick={() => updateCampaignScheduleModal({status: 'paused'})}>
								Campaign Schedule
							</span>{' '}
							set to <span className={`type-heavy ${campaign.active_schedule_percentage < 30 ? 'type-red' : ''}`}>{campaign.active_schedule_percentage}%</span> of Week
						</>
					) : (
						<span role="button" className="type-blue type-heavy" onClick={() => updateCampaignScheduleModal({status: 'paused'})}>
							Set Campaign Schedule
						</span>
					)}
				</p>
            </>)}

        {campaign.status === 'canceled' &&
        <>
            <p className="type-alert-text type-alert-text__no-margin-left type-small-body">
                Campaign Canceled
            </p>
            <p className="type-small-body spacing-10">
                <a href="https://support.servicedirect.com" className="type-heavy"
                   target="_blank">
                    Contact Support
                </a> to discuss enabling this Campaign
            </p>
        </>}
    </div>;
};

export default CampaignStatus;
