import AuthService from "./AuthService";
import TagManager from "react-gtm-module";
import queryString from "query-string";

// interface IDataLayerParameters {
//     userType: string;
//     clientId: number;
//     userId: number;
//     clientType: string;
// }

export default class GoogleTagManagerService {
    /**
     * returns an object holding our dataLayer custom data
     * @param {Object} parameters can optionally be sent to override or add to any data in the dataLayer
     * @return {Object}
     */
    static buildGoogleTagManagerDataLayer = (parameters = {}) => {
        let queryVars = queryString.parse(window.location.search),
            assistingAdminUserId = ('assisting_admin_user_id' in queryVars && queryVars.assisting_admin_user_id)
                                    ? queryVars.assisting_admin_user_id
                                    : parameters.assistingAdminUserId
                                        ? parameters.assistingAdminUserId
                                        : AuthService.vicariousAdminId;
        if (!parameters.assistingAdminUserId) {
            parameters.assistingAdminUserId = assistingAdminUserId
        }
        if (!parameters.userType) {
            parameters.userType =
                AuthService.isAdmin
                    ? 'Admin'
                    : assistingAdminUserId
                        ? 'Admin logged in as Client'
                        : AuthService.isAuthenticated
                            ? 'Client'
                            : 'Not Logged In'
        }

        if (!parameters.clientId) {
            parameters.clientId = AuthService.getItem('contractorId')
        }

        if (!parameters.userId) {
            parameters.userId = AuthService.getItem('userId')
        }

        // clientType (Marketplace, Select, Not Logged In, '') empty if user is Admin User on mySD Admin Pages
        if (!parameters.clientType) {
            parameters.clientType = AuthService.isAdmin
                ? ''
                : AuthService.getItem('signupMarketplaceId')
                    ? 'Marketplace'
                    : AuthService.getItem('isSignup')
                        ? 'Select'
                        : (AuthService.isAuthenticated && AuthService.isMarketplace)
                            ? 'Marketplace'
                            : AuthService.isAuthenticated
                                ? 'Select'
                                : 'Not Logged In'
        }
        // clientJourneyStage describes the "stage" a Client is in as one of our customers
        // possible values (Marketplace Sign Up, Select Sign Up, Marketplace Onboarding, Marketplace Active Client,
        // Select Active Client, Not Logged in, '')
        // TODO: did not yet implement "Select Enrollment"
        if(!parameters.clientJourneyStage) {
            parameters.clientJourneyStage = AuthService.isAdmin
                ? ''
                : AuthService.hasQuit
                    ? 'Quit'
                    : AuthService.getItem('signupMarketplaceId')
                        ? 'Marketplace Sign Up'
                        : AuthService.getItem('isSignup')
                            ? 'Select Sign Up'
                            : (AuthService.isAuthenticated && AuthService.isMarketplace && !AuthService.completedOnboarding)
                                ? 'Marketplace Onboarding'
                                : (AuthService.isAuthenticated && AuthService.isMarketplace)
                                    ? 'Marketplace Active Client'
                                    : AuthService.isAuthenticated
                                        ? 'Select Active Client'
                                            : 'Not Logged In'

        }

        return parameters
    }

    /**
     * initializeGoogleTagManager - used to initialize Data Layer and standard parameters
     * @param {Object} parameters can optionally be sent to override set specific data layer parameters
     **/
    static initializeGoogleTagManager = (parameters= {}) => {
        // This must be a loose check b/c the config value is a string, not an int
        if (process.env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER == 0) {
            return;
        }

        //  Google Tag Manager
        const tagManagerArgs = {
            gtmId: 'GTM-KNLX8HD',
            dataLayer: this.buildGoogleTagManagerDataLayer(parameters)
        }

        TagManager.initialize(tagManagerArgs)
    }

    /**
     * updateDataLayer - used to send new values to existing dataLayer
     * @param {Object} parameters
     */
    static updateDataLayer = (parameters= {}) => {
        const tagManagerArgs = {
            dataLayer: this.buildGoogleTagManagerDataLayer(parameters)
        }
        TagManager.dataLayer(tagManagerArgs)
    }

}

