import React, { useCallback, useEffect, useRef, useState } from 'react';



export default (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [newCpl, setNewCpl] = useState(props.tender);

    const inputRef = useRef();

    const expandedClass = isOpen ? 'cpl-editor__expanded ' : '';

    const applyEdit = () => {
        props.updateCpl(newCpl);
        setIsOpen(false);
    };

    const cancelEdit = useCallback(() => {
        setNewCpl(props.tender);
        setIsOpen(false);
    }, [props.tender]);

    const constrainDecimal = (numberString) => {
        const numberParts = numberString.split('.');

        if (numberParts.length > 1 && numberParts[1].length > 2) {
            // If the string has a decimal segment and it has more than two characters,
            // return new string with only first two characters of that segment.
            return `${numberParts[0]}.${numberParts[1].slice(0,2)}`;
        }

        return numberString;
    }

    const constrainInput = (e) => {
        if (e.keyCode == 27) {
            // Cancel on escape
            e.preventDefault();
            cancelEdit();
            return;
        }

        if (e.keyCode == 13) {
            // Save edit on enter
            e.preventDefault();
            applyEdit();
            return;
        }

        if (e.keyCode == 190 && e.target.value.indexOf('.') > 0) {
            // Don't allow multiple decimals
            e.preventDefault();
        }

        if (!( e.ctrlKey || e.altKey || e.metaKey
           || (47<e.keyCode && e.keyCode<58 && e.shiftKey==false)
           || (95<e.keyCode && e.keyCode<106)
           || (e.keyCode==190)
           || (e.keyCode==8) || (e.keyCode==9)
           || (e.keyCode>34 && e.keyCode<40)
           || (e.keyCode==46) )) {
               e.preventDefault();
           }
    };

    const handleChange = (e) => {
        const newVal = constrainDecimal(e.target.value);

        setNewCpl(newVal);
    };

    useEffect(() => {
        const closeEditor = (e) => {
            let ignoreClickOnPopup = '.cpl-editor__container *';

            if (!e.target.matches(ignoreClickOnPopup)) {
                e.preventDefault();
                e.stopPropagation();

                cancelEdit();
            }
        };

        if (isOpen) {
            document.body.addEventListener('click', closeEditor);

            inputRef.current.select();

            return function cleanup() {
                document.body.removeEventListener('click', closeEditor);
            }
        }
    }, [isOpen, cancelEdit]);

    return (
        <div className="cpl-editor__wrapper">
            <span
                className="type-heavy"
                role="button"
                onClick={e => {
                    e.stopPropagation();
                    setIsOpen(!isOpen);
                }}
            >
                {props.label}
            </span>

            <div
                className={`cpl-editor__container ${expandedClass}`}
                onClick={e => e.stopPropagation() }
            >
                <div
                    className="cpl-editor__contents"
                >
                    <div className="form-block">
                        Update the CPL of this Lead to
                        <input
                            type="text"
                            className="ui-text-field ui-normal-text-input inline"
                            name="cpl"
                            onKeyDown={ constrainInput }
                            onChange={ handleChange }
                            value={newCpl}
                            ref={inputRef}
                        />
                    </div>
                    <div className="links-block">
                        <div>
                            <span
                                role="button"
                                className="type-heavy"
                                onClick={ e => cancelEdit() }
                            >
                                Cancel
                            </span>
                        </div>
                        <div>
                            <span
                                role="button"
                                className="type-heavy"
                                onClick={e => applyEdit() }
                            >
                                Save
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
