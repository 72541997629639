import React, { Component } from 'react';

import EditUserModal from './EditUserModal';
import { PencilSvg } from '../../common/Svgs';
import UserNotificationCheckbox from './UserNotificationCheckbox';
import {formatDate} from "../../Util";
import ResendInviteModal from "./ResendInviteModal";
import Tooltip from "../../common/Tooltip";
import ResourcesService from '../../service/ResourcesService';
import type {User} from "../../Interfaces/User";

export default class UserNotificationsRow extends Component {
    constructor(props) {
        super(props);

        this.lockCheckForNotificationType = this.lockCheckForNotificationType.bind(this);
        this.resourcesService = new ResourcesService();
        this.tooltipRef = React.createRef();
    }

    /**
     * Checks if the user in this component is the single user w/a notification
     * type enabled.  If so, its checkbox is disabled.
     * @param type
     * @returns {boolean}
     */
    lockCheckForNotificationType(type) {

        // The setting can't be locked if this user doesn't have it enabled.
        if (!Number(this.props.user[type])) {
            return false;
        }

        const usersWithNotificationType = this.props.users.filter(user => Number(user[type]));

        // It also can't be locked if there are multiple users with it enabled.
        return usersWithNotificationType.length <= 1;
    }

    render() {
        let user: User = this.props.user;
        return (
            <tr className="type-normal-body">
                <td headers="user-email" className="users-table__col users-table__col__email">
                    <div className="ui-hide-full ui-hide-tablet">
                        <p className="type-small-body type-heavy spacing-10-bottom-mobile">
                            User
                        </p>
                    </div>
                    <p className="type-normal-body">
                        {user.name
                            ? <span className="type-force-newline type-heavy">{user.name}</span>
                            : null}
                        {user.jobTitle
                            ? <span className="type-force-newline">{user.jobTitle}</span>
                            : null}
                        {user.username
                            ? <span className="type-force-newline">{user.username}</span>
                            : null}
                        {user.phoneNumber
                            ? <span className="type-force-newline">{user.phoneNumber}</span>
                            : null}
                        <span className="type-force-newline">
                            Time Zone: {this.resourcesService.timeZoneText(user.timeZoneId)}
                        </span>
                        {/*{user.emailBlocked &&
                        <span className="text-alert type-heavy">Email address is blocked</span>}*/}
                    </p>
                </td>
                <td>
                    <div className="ui-hide-full ui-hide-tablet">
                        <p className="type-small-body type-heavy spacing-10-bottom-mobile">
                            Last Login
                        </p>
                    </div>
                    {user.lastLogin
                        ? <p className="type-small-body type-single-line">{formatDate(user.lastLogin)}</p>
                        : <div className="type-small-body type-narrow-line-height">
                            <span className="type-force-newline">No Login Yet</span>
                            <span className="type-force-newline">
                                <span role="button" className="type-heavy type-blue"
                                      onClick={() => this.props.updateModalContent(
                                          <ResendInviteModal
                                              user={user}
                                              updateModalContent={this.props.updateModalContent}
                                              resendInvite={this.props.resendInvite}
                                          />,
                                          'Resend mySD Invitation',
                                          null,
                                          true
                                      )}
                                >
                                    Resend Invite
                                </span>
                            </span>
                        </div>}
                </td>
                <td headers="user-notifications" className="users-table__col users-table__col__notifications">
                    <div className="simpleflex__row simpleflex__row__wrap__mobile">
                        <div className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-20-bottom-mobile">
                            <div className="ui-hide-full ui-hide-tablet">
                                <p className="type-small-body type-heavy spacing-20-bottom-mobile">
                                    Receive Notifications For{' '}
                                    <Tooltip
                                        content={this.props.notificationTypesTooltipContent()}
                                        position="top"
                                        modalContainerRef={this.tooltipRef}
                                    />
                                </p>
                            </div>
                            <UserNotificationCheckbox
                                label="Billing"
                                name={`billing-${user.userId}`}
                                checked={Number(user.billingNotifications)}
                                locked={this.lockCheckForNotificationType('billingNotifications')}
                                onChange={(event) =>
                                    this.props.handleNotificationSettingClick('billingNotifications',
                                        user.userId, event.target.checked)}
                            />
                            {'subaccountBillingNotifications' in user ?
                            <UserNotificationCheckbox
                                label="Include Sub-Account Billing"
                                name={`subaccount-billing-${user.userId}`}
                                checked={Number(user.subaccountBillingNotifications)}
                                locked={false}
                                onChange={(event) =>
                                    this.props.handleNotificationSettingClick('subaccountBillingNotifications',
                                        user.userId, event.target.checked)}
                                secondRow={true}
                            /> : ''}
                        </div>
                        <div className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-20-bottom-mobile">
                            <UserNotificationCheckbox
                                label="Lead Review"
                                name={`review-${user.userId}`}
                                checked={Number(user.leadReviewNotifications)}
                                locked={this.lockCheckForNotificationType('leadReviewNotifications')}
                                onChange={(event) =>
                                    this.props.handleNotificationSettingClick('leadReviewNotifications',
                                        user.userId, event.target.checked)}
                            />
                            {'subaccountLeadReviewNotifications' in user ?
                            <UserNotificationCheckbox
                                label="Include Sub-Account Lead Review"
                                name={`subaccount-lead-review-${user.userId}`}
                                checked={Number(user.subaccountLeadReviewNotifications)}
                                locked={false}
                                onChange={(event:MouseEvent) =>
                                    this.props.handleNotificationSettingClick('subaccountLeadReviewNotifications',
                                        user.userId, event.target.checked)}
                                secondRow={true}
                            />
                            : ''}
                        </div>
                        <div className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-20-bottom-mobile">
                            <UserNotificationCheckbox
                                label="Account"
                                name={`account-${user.userId}`}
                                checked={Number(user.accountNotifications)}
                                locked={this.lockCheckForNotificationType('accountNotifications')}
                                onChange={(event) =>
                                    this.props.handleNotificationSettingClick('accountNotifications',
                                        user.userId, event.target.checked)}
                            />
                        </div>
                    </div>
                </td>
                <td className="users-table__col users-table__col__actions">
                    <span role="button"
                        className="type-heavy type-small-body type-left-side-bump-full type-left-side-bump-tablet type-force-newline-mobile spacing-10-top-mobile type-blue"
                        onClick={() => this.props.updateModalContent(
                            <EditUserModal
                                user={user}
                                timeZones={this.props.timeZones}
                                updateModalContent={this.props.updateModalContent}
                                updateUser={this.props.updateUser}
                                deleteUser={this.props.deleteUser}
                                sendResetPasswordEmail={this.props.sendResetPasswordEmail}
                            />,
                            'Edit mySD User',
                            null,
                            true
                        )}
                    >
                        <span className="inline-icon inline-icon__16 inline-icon__16__10">
                            { PencilSvg }
                        </span>
                        Edit 
                    </span>
                </td>
            </tr>
        );
    }
}