import axios from "axios";

import {del, get, post, put} from '../Requests';

export default class CreditCardService {
    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    /**
     * creates a credit card record
     * @param {Object} data
     * @return {Promise<Object>}
     */
    create = (data): Promise<Object> =>
        post('credit_cards', data, this.cancelSignal.token)
            .then((response) => response.data);

    /**
     * get all available credit cards of the account
     * @return {Promise<Object[]>}
     */
    get = (): Promise<Object[]> =>
        get("credit_cards", this.cancelSignal.token)
            .then((response) => response.data.data.credit_cards);

    /**
     * get the primary credit card of the account
     * @return {Promise<Object>}
     */
    getPrimary = (): Promise<Object> =>
        get("credit_cards?is_primary=1", this.cancelSignal.token)
            .then((response) => response.data.data.credit_cards[0]);

    /**
     * sets a credit card as primary
     * @param {number} cardId
     * @return {Promise<string>}
     */
    setPrimary = (cardId): Promise<string> =>
        put(`/credit_cards/${cardId}/primary`,  this.cancelSignal.token)
            .then((response) => response.data.message);

    /**
     * deletes a credit card
     * @param {number} cardId
     * @return {Promise<string>} the response message of the delete action
     */
    delete = (cardId): Promise<string> =>
        del(`credit_cards/${cardId}`, this.cancelSignal.token)
            .then((response) => response.data.message);
}