import axios from "axios";
import type {CancelTokenSource} from "axios";

import {post, get, put} from "../Requests";
import {toCamelObject, toSnakeObject} from "../Util";
import type {AuthData} from "./TokenService";

export default class SignupMarketplaceService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal: CancelTokenSource;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    lookupMpSignupByEmail = (email: string) =>
        get(`signup_marketplace/lookup-by-email?email=${encodeURIComponent(email)}`, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));

    /**
     * checks if an email address is available or taken
     * @param {string} email - the email address to check
     * @return {Promise<boolean>} - the response will be true if the email is available
     */
    checkEmailAvailability = (email): Promise<boolean> =>
        post('signup_marketplace/check-email-availability', {email}, this.cancelSignal.token)
            .then((response) => !!response.data.data.available);

    /**
     * attempts to create an account by submitting the data to the server (i.e. step one)
     * @param {Object} data - the account data
     * @return {Promise<Object>}
     */
    createAccount = (data): Promise<Object> =>
        post('signup_marketplace/step-one', toSnakeObject(data), this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));

    /**
     * resend the confirmation email based on the given signup id
     * @param {number} signupMarketplaceId
     * @return {Promise<boolean>}
     */
    resendConfirmationEmail = (signupMarketplaceId): Promise<boolean> =>
        post('signup_marketplace/resend-confirmation', toSnakeObject({signupMarketplaceId}), this.cancelSignal.token)
            .then((response) => !!response.data.data.success);

    /**
     * confirms an email address using a token; returns login data
     * @param {string} token
     * @return {Promise<AuthData>}
     */
    confirmEmail = (token): Promise<AuthData> =>
        post('signup_marketplace/step-confirm', {token}, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));

    /**
     * returns the URL of the highest step the user is at
     * @return {Promise<string>}
     */
    getRedirect = (): Promise<string> =>
        get('signup_marketplace/step-redirect', this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.url));

    /**
     * gets the current saved data of step two for the calling contractor
     * @return {Promise<Object>}
     */
    getStepTwo = (): Promise<Object> =>
        get('signup_marketplace/step-two', this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));

    /**
     * attempts to update the step two data
     * @param {Object} data - the account data
     * @return {Promise<Object>}
     */
    updateStepTwo = (data): Promise<Object> =>
        put('signup_marketplace/step-two', toSnakeObject(data), this.cancelSignal.token)
            .then((response) => response.data);

    /**
     * gets the current saved data of step three for the calling contractor
     * @return {Promise<Object>}
     */
    getStepThree = (): Promise<Object> =>
        get('signup_marketplace/step-three', this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));

    /**
     * attempts to update the step three data
     * @param {Object} data - the account data
     * @return {Promise<Object>}
     */
    updateStepThree = (data): Promise<Object> =>
        put('signup_marketplace/step-three', toSnakeObject(data), this.cancelSignal.token)
            .then((response) => response.data);

    /**
     * checks if the user can be on step four - uses HTTP status code 400 if needs to redirect
     * we do not return data on step four
     * @return {Promise}
     */
    getStepFour = (): Promise =>
        get('signup_marketplace/step-four', this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));

    /**
     * attempts to update the step four data
     * @param {Object} data - the account data
     * @return {Promise<Object>}
     */
    updateStepFour = (data): Promise<Object> =>
        put('signup_marketplace/step-four', toSnakeObject(data), this.cancelSignal.token)
            .then((response) => response.data);

    /**
     * get the step-five data (needs_password?)
     */
    getStepFive = (): Promise =>
        get('signup_marketplace/step-five', this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));

    /**
     * submit the step-five data (password and tos)
     */
    updateStepFive = (data): Promise<Object> =>
        put('signup_marketplace/step-five', toSnakeObject(data), this.cancelSignal.token)
            .then((response) => response.data);
}
