import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.548387097rem 2.064516129rem;
`

export const ContentWrapper = styled.div`
    margin-bottom: 24px;
`

export const EditNumberWrapper = styled.div`
    display: grid;
    column-gap: 15px;
    padding: 3px 0;
    /* margin: 10px 0; */
    grid-template-columns: 1.8fr 1.8fr 1.3fr 1.3fr 0.8fr 0.3fr;
`

export const EditNumberCell = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 7px 0;
`

export const EditNumberCellRightAligned = styled(EditNumberCell)`
    align-items: flex-end;
`

export const EditConfirmationLine = styled.div`
    display: grid;
    column-gap: 30px;
    padding: 8px 0 8px 20px;
    grid-template-columns: 1fr 2fr 1fr 1fr;
`

export const AddNewWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
`

export const CopyToClipboardWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`
