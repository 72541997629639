import axios from "axios";
import type {CancelTokenSource} from "axios";

import {post, put} from "../Requests";
import {toCamelObject, toSnakeObject} from "../Util";

export default class OnboardingService {
	/** @member {CancelTokenSource} Axios cancel token */
	cancelSignal: CancelTokenSource;
	
	constructor() {
		this.cancelSignal = axios.CancelToken.source();
	}
		
	/**
	 * attempts to create a new onboarding profile for online onboarding for a new account
	 * @param {Object} data - user and contractor data
	 * @return {Promise<boolean>}
	 */
	beginSelfServeOnboarding = (data): Promise<Object> =>
		post('onboarding_profile/begin', toSnakeObject(data), this.cancelSignal.token)
			.then((response) => toCamelObject(response.data.data));
	
	/**
	 */
	advanceStep = (data): Promise<Object> =>
		post('onboarding_profile/advance-step', toSnakeObject(data), this.cancelSignal.token)
			.then((response) => toCamelObject(response.data.data));
	
	cancel = (): Promise<Object> =>
		post('onboarding_profile/cancel', null, this.cancelSignal.token)
			.then((response) => toCamelObject(response.data.data));
			
	finish = (data): Promise<Object> =>
		post('onboarding_profile/finish', toSnakeObject(data), this.cancelSignal.token)
			.then((response) => toCamelObject(response.data.data));

	scheduleOnboarding = (contractorId: number): Promise<Object> =>
		put(`contractors/${contractorId}/onboarding_scheduling_request`, null, this.cancelSignal.token);
}