import React, {Component} from 'react';

import ServiceCategory from './ServiceCategory';

export default class ServicesToAdvertise extends Component {
    render() {
        const serviceCategories = this.props.serviceCategories.map(
            (serviceCategory, key, array) =>
                <ServiceCategory
                    arrayIndex={key}
                    serviceCategory={serviceCategory}
                    key={key}
                    serviceCategoryIndex={key}
                    updateServiceCategoryCheckBox={this.props.updateServiceCategoryCheckBox}
                    updateServiceAreaText={this.props.updateServiceAreaText}
                    updateMessageBlocks={this.props.updateMessageBlocks}
                    updateModalContent={this.props.updateModalContent}
                    closeModal={this.props.closeModal}
                    serviceAreaTextOnBlur={this.props.serviceAreaTextOnBlur}
                    serviceAreaRequired={true}
                    updateOtherText={this.props.updateOtherText}
                    serviceCategoryStateKey={this.props.serviceCategoryStateKey}
                    bottomSpacing={key < array.length - 1}
                    country={this.props.country}
                />
        );

        return <div className="row padding-20-bottom">
            <div className="col alpha grid-2 empty"/>
            <div className="col grid-8 no-margin-mobile">
                <div className="module">
                    <div className="module__contentbox module__contentbox__no-header module__twothirdswidth">
                        <h4 className="type-normal-subhead type-heavy type-allcaps type-narrow-line-height spacing-10-bottom">
                            Target Service Categories
                        </h4>
                        <p className="type-normal-body spacing-40-bottom">
                            These settings determine the type of leads you want to receive. For each service
                            category selected,
                            please let us know the service offerings you’d like us to advertise,
                            as well as any additional service subcategories that you’d like to receive leads for.
                        </p>
                        {serviceCategories}
                    </div>
                </div>
            </div>
            <div className="col omega grid-2 empty"/>
            <div className="clear-block"/>
        </div>;
    }
}
