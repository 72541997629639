import React from 'react';

import Radio from "../../common/components/form_elements/Radio";
import TextArea from "../../common/components/form_elements/TextArea";
import FormModal from "../../common/components/modal/FormModal";
import AuthService from "../../service/AuthService";

export default class SubmitForReviewModal extends FormModal {
    constructor() {
        super();

        this.state = {
            submitting: false
        };
    }

    clickSubmitReviewLead = (event, leadId) => {
        event.preventDefault();

        //
        // Get reasonId - Figure out which radio button is checked
        //
        let checkedRadio = event.target.querySelector('[type="radio"]:checked');
        if (!checkedRadio) {
            this.props.updateMessageBlocks(['Lead review reason is required.'], 'error');
            return;
        }
        let reason = checkedRadio.value;

        let message = event.target.querySelector('[name="review_message"]').value;
        if (!message) {
            this.props.updateMessageBlocks(['A message is required for each lead review request.'], 'error');
            return;
        }

        this.setState({submitting: true});
        this.props.submitForReview(leadId, {
            reason: reason,
            message: message
        })
            .then(() => this.setState({submitting: false}));
    };

    render() {

        let leadId = this.props.leadId,
            //
            // Jan 23 2020 RM JT - decided to pass the message itself as the value instead of the int status_id.
            // The textual names in the status table aren't guaranteed to line up to these, and we want the message
            // to be consistent.
            //
            reviewReasonOptions = [
                {
                    elId: "review_reason_out_of_service_area",
                    label: "Out of Service Area",
                    value: "Out of Service Area",
                    //value: "63"
                },{
                    elId: "review_reason_service_not_offered",
                    label: "Service Not Offered",
                    value: "Service Not Offered",
                    //value: "67"
                },{
                    elId: "review_reason_solicitor",
                    label: "Solicitor",
                    value: "Solicitor",
                    //value: "29"
                },{
                    elId: "review_reason_job_seeker",
                    label: "Job Seeker",
                    value: "Job Seeker",
                    //value: "62"
                },{
                    elId: "review_reason_other",
                    label: "Other",
                    value: "Other",
                    //value: "82"
                }
            ];
        // for Calls, add the "Wrong Number" option at position 2
        if (!this.props.isForm) {
            reviewReasonOptions.splice(2,0, {
                elId: "review_reason_wrong_number",
                label: "Wrong Number",
                value: "Wrong Number",
                //value: "61"
            });
        }

        let reviewReasonsJsx = [];

        for (let i = 0; i < reviewReasonOptions.length; i++) {
            let option = reviewReasonOptions[i];

            reviewReasonsJsx.push(
                <div key={i} className="type-normal-body type-single-line no-margin-top spacing-20-bottom">
                    <Radio
                        name="review_reason"
                        id={ option.elId }
                        label={ option.label }
                        value={ option.value }
                        onChange={ () => {
                            // Uncontrolled input - no change necessary here

                            //let elem = event.target;
                            //console.log('elem before', elem);
                            //elem.value = event.target.checked ? 1 : 0;
                            //console.log('elem after', elem);
                        }}
                        hasError={false}
                    />
                </div>
            );
        }


        return (
        <>
            <div className="popup__form__row spacing-10-bottom-full spacing-10-bottom-tablet spacing-10-bottom-mobile">
                <p className="type-normal-body type-narrow-line-height spacing-10-bottom">
                    Not a Billable Lead? Tell us why and Service Direct's Quality Assurance team will review each
                    submitted Lead one time within 2-3 business days to determine whether a Lead is Billable.
                </p>
            </div>
            <div className="popup__form__row spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                <p className="type-normal-body type-narrow-line-height spacing-10-bottom">
                    Please include all information needed by the team to make this decision. Missed Calls and
                    Leads submitted without having been followed up on are Billable. See our{' '}
                    <a target="_blank" rel="noopener noreferrer" href={AuthService.isMarketplace
                        ? "https://support.servicedirect.com/lead-review"
                        : "https://support.servicedirect.com/select/lead-review"}>
                        Lead Review Policy here.
                    </a>
                </p>
            </div>
            <form className="lead_review_form" onSubmit={(e) => this.clickSubmitReviewLead(e, leadId)}>
                <div className="popup__form__row spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                         {reviewReasonsJsx}
                    </div>
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <TextArea label="Explanation" name="review_message"/>
                    </div>
                </div>
                <div className="ui-hide-full ui-hide-tablet">
                    <div className="popup__form__row spacing-40-top-mobile">
                        <div className="popup__form__cell popup__form__cell__100__mobile">
                            <div className="spacing-20-bottom-mobile">
                                <button className="button ui-normal-button ui-full-width-button"
                                        disabled={this.state.submitting}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ui-hide-mobile">
                    <div className="padding-24-bottom">
                        <div className="popup__form__row popup__form__row__slam-right">
                            <div className="popup__form__cell">
                                <button className="button ui-normal-button"
                                        disabled={this.state.submitting}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="popup__form__row">
                <p className="type-extra-small-body">
                    <b>TIP:</b> Notifications for Lead Review updates are set in
                    your <a href="/users" className="type-heavy">User Notifications</a>.
                    Learn more about Lead Review by reading our{' '}
                    <a href={AuthService.isMarketplace
                        ? "https://support.servicedirect.com/lead-review"
                        : "https://support.servicedirect.com/select/lead-review"}
                       className="type-heavy" target="_blank" rel="noopener noreferrer">Lead Review Policy</a>.
                </p>
            </div>
        </>
        );
    }
}