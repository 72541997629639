import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Input from "../../../common/components/form_elements/Input";
import ResourcesService from "../../../service/ResourcesService";
import {formatDate} from "../../../Util";
import {OpenNewWindow} from "../../../common/Svgs";
import MultiSelectList from '../../../common/components/form_elements/MultiSelectList';

/**
 * Modal for user to choose a Hubspot Deal to connect their new Sign Up New Select Client form submission to.
 */
export default class ChooseUserAndDealModal extends Component {
    hubSpotEnv = process.env.REACT_APP_HUBSPOT_ID;

    constructor(props) {
        super(props);

        this.state = {
            selectedUserIndex: 0,
            selectedDealIndex: -1,
            users: props.users,
            deals: props.deals,
            dealOptions: this.dealsToOptions(props.deals),
            timeZones: []
        };

        this.resourcesService = new ResourcesService();
        this.resourcesService.getTimeZones()
            .then((timeZones) => this.setState({timeZones}));
    }

    componentWillUnmount(): void {
        this.resourcesService.cancelSignal.cancel();
    }

    usersToOptions(users: Object[]) {
        return users.map((user, index) => {
            return {
                value: index,
                text: user.username
            };
        });
    }

    dealsToOptions(deals: Object[]) {
        const dealOptions = deals.map((deal: Object, index: number) => {
            return {
                value: index,
                text: deal.dealname
            };
        });

        dealOptions.unshift({
            value: -1,
            text: 'Create a new HubSpot Deal'
        });

        return dealOptions;
    }

    hubSpotDealLink = (dealId) =>
        `https://app.hubspot.com/contacts/${this.hubSpotEnv}/deals/${dealId}`;

    render() {
        const selectedUser = this.state.users[this.state.selectedUserIndex];
        const selectedDeal = this.state.deals[this.state.selectedDealIndex];

        return (
            <>
                <div className="popup__form__row spacing-20-bottom">
                    <div className="popup__form__cell popup__form__cell__100 spacing-20-bottom-mobile">
                        {this.state.deals.length > 0 ? (
                            <MultiSelectList
                                label="Select HubSpot Deal"
                                name="deal-selection"
                                selections={[this.state.selectedDealIndex]}
                                onChange={(vals) =>
                                    this.setState({ selectedDealIndex: vals[0] })
                                }
                                options={this.state.dealOptions}
                                addOn={
                                    selectedDeal && (
                                        <a
                                            href={this.hubSpotDealLink(
                                                selectedDeal.dealId
                                            )}
                                            target="_blank"
                                            className="type-left-side-bump"
                                        >
                                            View Deal{' '}
                                            <span className="inline-icon inline-icon__middle inline-icon__20">
                                                {OpenNewWindow}
                                            </span>
                                        </a>
                                    )
                                }
                            />
                        ) : (
                            <Input
                                name="deals"
                                label="Select HubSpot Deal"
                                readOnly
                                type="text"
                                value="No HubSpot Deal found, a new Deal will be created on form submission"
                            />
                        )}
                    </div>
                </div>
                <hr />
                <h3 className="type-small-body type-heavy spacing-5-bottom">
                    Service Direct Client User for Select Enrollment
                </h3>
                <p className="type-normal-body spacing-20-bottom">
                    {this.state.users.length > 1 ? (
                        <>
                            The Client has more than one SD User. Choose the SD User that
                            will complete the Select Program Enrollment - this user will
                            receive email communications and in-app messaging to guide
                            their enrollment.
                        </>
                    ) : (
                        <>
                            This Client has only one SD User. This is the SD User that
                            will complete the Select Program Enrollment - this user will
                            receive email communications and in-app messaging to guide
                            their enrollment.
                        </>
                    )}
                </p>
                <div className="popup__form__row spacing-20-bottom">
                    {this.state.users.length > 1 ? (
                        <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                            <MultiSelectList
                                label="User"
                                name="user-selection"
                                selections={[this.state.selectedUserIndex]}
                                onChange={(vals) =>
                                    this.setState({
                                        selectedUserIndex: vals[0],
                                    })
                                }
                                options={this.usersToOptions(this.state.users)}
                            />
                        </div>
                    ) : (
                        <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                            <Input
                                name="username"
                                label="Email"
                                type="email"
                                readOnly
                                value={selectedUser.username}
                            />
                        </div>
                    )}
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <Input
                            name="name"
                            label="Name"
                            type="text"
                            readOnly
                            value={selectedUser.name || ''}
                        />
                    </div>
                </div>
                <div className="popup__form__row spacing-20-bottom">
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <Input
                            name="job-title"
                            label="Job Title"
                            type="text"
                            readOnly
                            value={selectedUser.jobTitle || ''}
                        />
                    </div>
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <Input
                            name="phone-number"
                            label="Phone Number"
                            type="phone"
                            readOnly
                            value={selectedUser.phoneNumber || ''}
                        />
                    </div>
                </div>
                <div className="popup__form__row spacing-20-bottom">
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <Input
                            name="time-zone"
                            label="Time Zone"
                            type="text"
                            readOnly
                            value={this.resourcesService.timeZoneText(
                                selectedUser.timeZoneId
                            )}
                        />
                    </div>
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <Input
                            name="last-login"
                            label="Last Login"
                            type="text"
                            readOnly
                            value={formatDate(selectedUser.lastLogin)}
                        />
                    </div>
                </div>
                <div className="popup__form__row popup__form__row__slam-right">
                    <div className="popup__form__cell">
                        <span
                            role="button"
                            className="type-small-body type-heavy type-blue"
                            onClick={this.props.cancelUserSelection}
                        >
                            Cancel
                        </span>
                    </div>
                    <div className="popup__form__cell">
                        <button
                            className="button ui-normal-button"
                            onClick={() =>
                                this.props.chooseUserInModal(selectedUser, selectedDeal)
                            }
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </>
        )
    }
}

ChooseUserAndDealModal.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object),
    deals: PropTypes.arrayOf(PropTypes.object),
    cancelUserSelection: PropTypes.func,
    chooseUserInModal: PropTypes.func
};
