import React from "react";
import PropTypes from 'prop-types';

export const Radio = props => {
    let radioClass = 'radio-container',
        labelClass = 'type-normal-body';

    if (props.hasError) {
        // radioClass += ' ui-text-field ui-alert';
        labelClass += ' type-alert';
    }

    if (props.disabled) {
        radioClass += ' disabled';
        labelClass += ' disabled';
    }

    return <div className={radioClass}>
        <input
            type="radio"
            name={props.name}
            id={props.id}
            checked={props.checked}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            onClick={props.onClick}
        />
        {props.label &&
        <label htmlFor={props.id} className={labelClass}>
            <span className="radio" disabled={props.disabled}>{props.label}</span>
        </label>}
    </div>;
};

export default Radio;

//
// NOTE "label" and "hasError" were marked required until 2019-12-30,
// but they aren't always used or needed, so removing this requirement.
// ~ RFM 2019-12-30
//
Radio.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string.isRequired, // required as it is used by the label to target the input
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    hasError: PropTypes.bool,
    placeholder: PropTypes.string,
    helpText: PropTypes.instanceOf("HelpText")
};

