import axios from "axios";

import {get} from '../Requests';

export default class BillingSummaryService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    /**
     * get the billing summary of a contractor
     * @param {number} contractorId - the contractor id
     * @return {Promise<AxiosResponse<any> | boolean>}
     */
    get = (contractorId) => {
        let url = `contractors/${contractorId}/current_balance_summary`;

        return get(url, this.cancelSignal.token)
            .then((response) => response.data.data.summary);
    }
}