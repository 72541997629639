import React from 'react';
import PropTypes from 'prop-types';

import {formatDateNamedMonth} from "../../Util";
import Tooltip from "../../common/Tooltip";
import {CampaignTypes, marketplaceIndustryScreenshots} from '../../common/StaticValues';
import LeadsReceivedCounts from "./CampaignRowComponents/LeadsReceivedCounts";
import PhoneLeads from "./CampaignRowComponents/PhoneLeads";
import SelectCampaignSite from "./CampaignRowComponents/SelectCampaignSite";
import LeadDeliveryAndNotifications from "./CampaignRowComponents/LeadDeliveryAndNotifications";
import MarketplaceServiceArea from "./CampaignRowComponents/MarketplaceServiceArea";
import CampaignStatus from "./CampaignRowComponents/CampaignStatus";
import MissedOpportunities from "./CampaignRowComponents/MissedOpportunities";
import FormLeads from "./CampaignRowComponents/FormLeads";
import AuthService from "../../service/AuthService";

export default class CampaignRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //
            // If the screenshot fails to load, we set this to true and switch to the fallback image
            //
            screenshotFailedToLoad: false,
        };

        this.isMarketplace = props.campaign.type === CampaignTypes.MARKETPLACE;
    }

    campaignScreenshot = () => {
        if (this.isMarketplace) {
            let marketplaceIdx = 'generic';
            if (this.props.campaign.industry_id in marketplaceIndustryScreenshots) {
                marketplaceIdx = this.props.campaign.industry_id;
            }
            return marketplaceIndustryScreenshots.base + marketplaceIndustryScreenshots[marketplaceIdx];
        }

        return (this.state.screenshotFailedToLoad || !this.props.campaign.screenshot_url)
            ? '/images/mySD-Campaigns-CampaignSiteScreenshotUnavailable.png'
            : this.props.campaign.screenshot_url;
    };

    render() {
        if (!this.props.campaign.campaign_name || !this.props.campaign.tender) {
            return null;
        }

        const hasCompanyName = typeof this.props.campaign.company_name !== 'undefined';

        return <div className="campaigns-table__row campaigns-table__bottom-border"
                    data-campaign_id={this.props.campaign.campaign_id}>
            <div className="campaigns-table__header spacing-30-bottom">
                <div className="no-label-checkbox__container campaigns-table__name">
                    <div className="no-label-checkbox__cell__checkbox campaigns-table__name__checkbox">
                        {this.props.campaign.status !== 'canceled' &&
                        <span className="checkbox-container">
                            <input type="checkbox"
                                   name="selectCampaign"
                                   id={`selectCampaign-${this.props.campaign.campaign_id}`}
                                   checked={this.props.isChecked}
                                   onChange={() => this.props.toggleSelectCampaign(this.props.campaign.campaign_id)}
                                   disabled={this.props.campaign.status === 'canceled'}
                            />
                            <label htmlFor={`selectCampaign-${this.props.campaign.campaign_id}`}
                                   className="checkbox-container__empty-label">
                                <span className="checkbox"/>
                            </label>
                        </span>}
                    </div>
                    <div className="no-label-checkbox__cell__neighbor campaigns-table__name__names">
                        {hasCompanyName &&
                        <div className="no-label-checkbox__cell__neighbor__contents campaigns-table__name__names-parent">
                            <span
                                className="type-large-body type-heavy type-allcaps type-single-line-full type-single-line-tablet">
                                {this.props.campaign.company_name}
                            </span>
                        </div>}
                        <div className="no-label-checkbox__cell__neighbor__contents">
                            <div
                                className="type-large-subhead type-single-line-full type-single-line-tablet">
                                {this.props.campaign.campaign_name}
                                {this.isMarketplace &&
                                <Tooltip
                                    position="right"
                                    content={
                                        <>
                                        <span
                                            className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                            Marketplace Campaigns
                                        </span>
                                            <span
                                                className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            Available for select Service Categories, Marketplace Campaigns leverage our
                                            partnerships with websites and publishers to send additional high-quality,
                                            high-converting leads to your business in real-time.{' '}
                                                <a href={AuthService.isMarketplace
                                                    ? 'https://support.servicedirect.com/campaigns-manager'
                                                    : 'https://support.servicedirect.com/select/marketplace-campaigns'}
                                                   target="_blank" rel="noopener">
                                                Learn more about Service Direct Marketplace
                                            </a>
                                        </span>
                                        </>
                                    }
                                />}
                            </div>
                        </div>
                    </div>
                    {this.props.campaign.status !== 'canceled' &&
                    <div className="type-normal-body type-single-line-full type-single-line-tablet campaigns-table__name__date">
                        Campaign Start Date:{' '}
                        <b>{formatDateNamedMonth(this.props.campaign.start_timestamp)}</b>
                    </div>}
                </div>

                <CampaignStatus campaign={this.props.campaign}
                                campaignIndex={this.props.campaignIndex}
								submitPutSchedule={this.props.submitPutSchedule}
                                submitPutStatus={this.props.submitPutStatus}
                                updateMessageBlocks={this.props.updateMessageBlocks}
                                modalContainerRef={this.props.modalContainerRef}
                                contractorPausedBilling={this.props.contractorPausedBilling}
                                submitCplChange={this.props.submitCplChange}/>
            </div>

            <div className="campaigns-table__columns">
                <div className="spacing-24-bottom-mobile">
                    {this.props.campaign.status !== 'canceled'
                        ? <img
                            className="campaigns__screenshot"
                            src={this.campaignScreenshot()}
                            alt={`${this.props.campaign.campaign_name} Website Screenshot`}
                            onError={() => this.setState({screenshotFailedToLoad: true})}
                        />
                        : <div className="campaigns__missing-screenshot__wrapper">
                            <div className="campaigns__missing-screenshot__contents">
                                <div
                                    className="campaigns__missing-screenshot__label type-small-body type-narrow-line-height">
                                    Screenshot Not Available
                                </div>
                            </div>
                        </div>}
                </div>

                <div className="campaigns-table__details-grid">
                    <LeadsReceivedCounts campaign={this.props.campaign}/>

                    <MissedOpportunities campaign={this.props.campaign}
                                         showMpCplNudgeContractor={this.props.showMpCplNudgeContractor}
                                         mpFirstEnableTimestamp={this.props.mpFirstEnableTimestamp}/>

                    <PhoneLeads campaign={this.props.campaign}
                                updateModalContent={this.props.updateModalContent}
                                campaignIndex={this.props.campaignIndex}
                                formsEnabled={this.props.displayForm}
                                submitCplChange={this.props.submitCplChange}/>

                    <FormLeads campaign={this.props.campaign}
                               campaignIndex={this.props.campaignIndex}
                               mpFormsEarlyAccess={this.props.mpFormsEarlyAccess}
                               updateMessageBlocks={this.props.updateMessageBlocks}
                               handleSubcategoriesChange={this.props.handleSubcategoriesChange}/>

                    <div className="campaigns-table__details-grid__campaign-site">
                        {this.props.campaign.status !== 'canceled' &&
                        this.isMarketplace
                            ? <MarketplaceServiceArea campaign={this.props.campaign}
                                                      updateModalContent={this.props.updateModalContent}
                                                      updateMessageBlocks={this.props.updateMessageBlocks}
                                                      submitAreaCodeChange={this.props.submitAreaCodeChange}
                                                      campaignIndex={this.props.campaignIndex}/>
                            : <SelectCampaignSite campaign={this.props.campaign}
                                                  updateModalContent={this.props.updateModalContent}
                                                  industryOptions={this.props.industryOptions}
                                                  updateMessageBlocks={this.props.updateMessageBlocks}/>}
                    </div>
                    {this.props.campaign.status !== 'canceled' &&
                    <LeadDeliveryAndNotifications campaign={this.props.campaign}
                                                  campaignIndex={this.props.campaignIndex}
                                                  callerIdOptions={this.props.callerIdOptions}
                                                  modalContainerRef={this.props.modalContainerRef}
                                                  callerIdTrackingPhoneOptions={this.props.callerIdTrackingPhoneOptions}
                                                  updateMessageBlocks={this.props.updateMessageBlocks}
                                                  submitLeadDeliverySettings={this.props.submitLeadDeliverySettings}/>}
                </div>
            </div>
        </div>;
    }
}

CampaignRow.propTypes = {
    campaign: PropTypes.object,
};
