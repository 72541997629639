import type {AxiosResponse, CancelTokenSource} from 'axios';
import axios from 'axios';

import {get, post, put} from '../Requests';
import {jsDateToSqlDateString, toCamelObject} from '../Util';
import type {IFilteredSummary} from '../Interfaces/IFilteredSummary';
import type {ILeadSummary} from '../Interfaces/ILeadSummary';

export default class LeadService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal: CancelTokenSource;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    /**
     * retrieve a lead by its id, and update the component leads, and industryRevenueAverages arrays
     * this function also updates the given component's "leads", and "industryRevenueAverages" properties
     * as well as, if needed, calling a consecutive call to get the lead's call url
     * @param {number} leadId - the lead id
     * @param {Component} component - a react component initiating the call (must have the "setState" function)
     * @return {Promise<void>}
     */
    get = (leadId, component): Promise<AxiosResponse> => {
        return get(`leads/${leadId}`, this.cancelSignal.token)
            .then((resp) => {
                const newState = {};

                const leads = [...component.state.leads];
                const index = leads.findIndex((lead) => lead.lead_id == leadId);

                leads[index] = resp.data.data.leads[0];
                newState['leads'] = leads;

                // industryRevenueAverages is only relevant to requests made from the Client Lead Manager
                // these values will be undefined on the Admin Lead Manager
                const industryRevenueAverages = component.state.industryRevenueAverages
                    ? [...component.state.industryRevenueAverages]
                    : undefined;
                const industryId = resp.data.data.industry_revenue_average?.industry_id;

                if (industryRevenueAverages && industryId) {
                    industryRevenueAverages[industryId] = resp.data.data.industry_revenue_average?.average_revenue;
                    newState['industryRevenueAverages'] = industryRevenueAverages;
                }

                component.setState(newState);
            });
    };

    /**
     * updates a lead based on the given lead id with the given properties
     * @param {number} leadId
     * @param {object} properties
     * @param {Component} component - a react component initiating the call (must have the "setState" function)
     * @return {Promise<void>}
     */
    update = (leadId, properties, component) => {
        return put(`leads/${leadId}`, properties, this.cancelSignal.token)
            .then((resp) => {
                let newState = {};

                let lead = resp.data.data.lead,
                    leads = [...component.state.leads],
                    index = leads.findIndex((lead) => lead.lead_id == leadId);

                if (!lead) {
                    return;
                }

                // Admin Lead Manager doesn't have industry revenue averages
                let industryRevenueAverages, industryId, averageRevenue;
                if (component.state.industryRevenueAverages) {
                    industryRevenueAverages = [...component.state.industryRevenueAverages];
                    industryId = resp.data.data.industry_revenue_average.industry_id;
                    averageRevenue = resp.data.data.industry_revenue_average.average_revenue;

                    industryRevenueAverages[industryId] = averageRevenue;
                }

                leads[index] = lead;

                let today = new Date();

                newState.leads = leads;
                newState.lastLeadChangeTimestamp = today.getTime();

                if (component.state.industryRevenueAverages) {
                    newState.industryRevenueAverages = industryRevenueAverages;
                }

                component.setState(newState);
            });
    };

    getLeads = (query: string) =>
        get(`leads?${query}`, this.cancelSignal.token)
            .then((response) => response.data);

    getSummary = (): Promise<ILeadSummary> =>
        get('leads/summary', this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data.lead_summary));

    getFilteredSummary = (query: string): Promise<IFilteredSummary> =>
        get(`leads/filtered_summary?${query}`, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data.filtered_summary));

    export = (query: string): Promise<Object> =>
        get(`leads/csv?${query}`, this.cancelSignal.token)
            .then((response) => response.data.data);

    getCallInsightsClients = (freeTrial = false) => {
        return get(
            `leads/qa/call_insights_clients?free_trial=${freeTrial}`,
            this.cancelSignal.token
        )
            .then((response) => {
                let link = null
                if (response.data) {
                    const {contractorIds} = response.data
                    if (contractorIds && contractorIds.length > 0) {
                        const {host, protocol} = document.location
                        const clientIds = contractorIds.join('&client_ids[]=')
                        link = `${protocol}//${host}/leads?is_valid=1&lead_review_select_list_value=not%20in%20review&client_ids[]=${clientIds}`
                        if (freeTrial) {
                            // for free trial, use date range of last 90 days plus today
                            // set the to and from date so that the UI renders it correctly
                            const _from = new Date()
                            _from.setDate(_from.getDate() - 90)
                            const dateFrom = jsDateToSqlDateString(_from)
                            const _to = new Date()
                            const dateTo = jsDateToSqlDateString(_to)
                            link += `&date_range_id=11&date_from=${dateFrom}&date_to=${dateTo}`
                        } else {
                            // for call insights (not free trial) the date range should be "this month"
                            // must set date_to and date_from as well
                            // ie date_from=2023-05-01 date_to=2023-05-21
                            const _from = new Date()
                            _from.setDate(1)
                            const dateFrom = jsDateToSqlDateString(_from)
                            const _to = new Date()
                            const dateTo = jsDateToSqlDateString(_to)
                            link += `&date_range_id=4&date_from=${dateFrom}&date_to=${dateTo}`
                        }
                        // sort by date, ascending
                        link += '&sort_column=date&sort_order=asc'
                    }
                }

                return link
            })
            .catch((e) => {
                console.log(e)
                return null
            })
    }

    getCallInsightsForLead = (id) => {
        return get(`leads/${id}/call_insights`, this.cancelSignal.token)
    }

    updateCallInsightsForLead = (id, body) => {
        return put(`leads/${id}/call_insights`, body, this.cancelSignal.token)
    }

    getLeadProgressForLead = (id) => {
        return get(`leads/${id}/lead_progress`, this.cancelSignal.token)
    }

    updateLeadProgressForLead = (id, body) => {
        return put(`leads/${id}/lead_progress`, body, this.cancelSignal.token)
    }

    getLeadTags = () => {
        return get(`leads/mysd_lead_tag`, this.cancelSignal.token)
    }

    getLeadTagsForLead = (id) => {
        return get(`leads/${id}/mysd_lead_tag`, this.cancelSignal.token)
    }

    saveLeadTags = (id, tags) => {
        return post(`leads/${id}/mysd_lead_tag`, tags, this.cancelSignal.token)
    }

    getWrongNumberReasons = () => {
        return get(`leads/wrong_number_reason`, this.cancelSignal.token)
    }

    getWrongNumberReasonsForLead = (id) => {
        return get(`leads/${id}/wrong_number_reason`, this.cancelSignal.token)
    }

    saveWrongNumberReasons = (id, tags) => {
        return post(`leads/${id}/wrong_number_reason`, tags, this.cancelSignal.token)
    }

    getLeadReviews = (query) => {
        return get(`leads/lead_reviews?${query}`, this.cancelSignal.token)
    }

    /**
     * sets the lead status to 'in review' when requested by admin
     * @param leadId
     * @return {Promise<string>}
     */
    setLeadStatusToInReview = (leadId) =>
        post(`leads/${leadId}/review`, {}, this.cancelSignal.token)
            .then(() => 'in review');

    /**
     * claims a form lead's trusted forms certificate
     * @param leadId
     * @return {Promise<boolean|null>}
     */
    claimCertificate = (leadId) =>
        post(`leads/${leadId}/claim_certificate`, {}, this.cancelSignal.token)
            .then((response) => response.data.data.trusted_form_cert_claimed);
}

export function leadManagerUrl(dateRangeId, campaignId) {
    return `/leads?campaign_ids[]=${campaignId}&date_range_id=${dateRangeId}&is_valid=1`;
}
