import React from 'react';
import PropTypes from 'prop-types';

import type {Email} from "../../Interfaces/Email";

const ResetEmailBounceModal = (props) => {
    const email: Email = props.email;

    return <>
        <div className="type-large-body spacing-50-bottom">
            This will reset the Bounce Score and the system will resume sending mail to this email address:
            <p className="type-heavy spacing-12-top">{email.emailAddress}</p>
        </div>
        <div className="popup__form__gray-bar padding-24">
            <div className="popup__form__row popup__form__row__slam-right">
                <div className="popup__form__cell">
                    <span role="button"
                          className="type-small-body type-heavy type-blue"
                          onClick={() => props.updateModalContent()}>
                        Cancel
                    </span>
                </div>
                <div className="popup__form__cell">
                    <button className="button ui-normal-button"
                            onClick={() => props.resetBounce(email.emailId)}>
                        Reset bounce score
                    </button>
                </div>
            </div>
        </div>
    </>
};

ResetEmailBounceModal.propTypes = {
    email: PropTypes.object.isRequired,
    resetBounce: PropTypes.func,
    updateModalContent: PropTypes.func,
};

export default ResetEmailBounceModal;
