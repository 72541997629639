import React, { Component } from 'react';
import {
    handleInputBlur,
    appInput,
    updateStateCloneWithValue
} from '../../formHelpers';

import Input from "../../common/components/form_elements/Input";
import Validator from "../../lib/Validator";

export default class EditIndustryModal extends Component {
    constructor(props) {
        super(props);

        let industry = this.props.industry;

        this.state = {
            industry: this.props.industry,
            inputs : {
                default_min_tender: appInput("default_min_tender", "default_min_tender",  industry ? industry.default_min_tender : ""),
                min_suggested_tender: appInput("min_suggested_tender", "min_suggested_tender", industry ? industry.min_suggested_tender : ""),
                max_suggested_tender: appInput("max_suggested_tender", "max_suggested_tender", industry ? industry.max_suggested_tender : "")
            },
            modalContent: undefined,
            modalHeader: undefined,
            modalWidth: undefined,
            modalFlatBottom: undefined,
        };

        this.validator = new Validator();
    }

    /**
     * @todo These also exist in CreateClient.js and EditServiceModal.  How can
     * we refactor to provide these input handlers in a uniform way w/out
     * duplication?
     * @param event
     */
    updateValuesToState = (event) => {
        let stateClone = {...this.state};
        updateStateCloneWithValue(stateClone,event);
        this.setState(stateClone);
    };

    handleInputBlur = (event) => {
        let stateClone = {...this.state};
        handleInputBlur(stateClone, event);
        this.setState(stateClone);
    };

    render() {

        let industryFromInputs = {
            industry_id: this.state.industry.industry_id,
            default_min_tender: this.state.inputs.default_min_tender.value,
            min_suggested_tender:  this.state.inputs.min_suggested_tender.value,
            max_suggested_tender: this.state.inputs.max_suggested_tender.value
        };

        return (
            <>
                <form>
                <div className="popup__form__row spacing-30-bottom">
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <label className="type-small-body type-heavy type-narrow-line-height no-margin-top spacing-5-bottom" >
                            Service Category
                        </label>
                        <p>{this.state.industry.name}</p>
                    </div>
                </div>
                <div className="popup__form__row spacing-30-bottom">
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <Input
                            type={"text"}
                            label={"Default Min Tender"}
                            id="default_min_tender"
                            name="default_min_tender"
                            className="ui-textarea ui-normal-text-input"
                            value={this.state.inputs.default_min_tender.value}
                            inputRef={this.state.inputs.default_min_tender.ref}
                            hasError={!!this.state.inputs.default_min_tender.error}
                            required={true}
                            onChange={ this.updateValuesToState }
                            onBlur={ this.handleInputBlur }
                        />
                    </div>
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <Input
                            label={"Min Suggested Tender"}
                            type={"text"}
                            id="min_suggested_tender"
                            name="min_suggested_tender"
                            value={this.state.inputs.min_suggested_tender.value}
                            inputRef={this.state.inputs.min_suggested_tender.ref}
                            hasError={!!this.state.inputs.min_suggested_tender.error}
                            required={true}
                            onChange={ this.updateValuesToState }
                            onBlur={ this.handleInputBlur }
                        />
                    </div>
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <Input
                            label={"Max Suggested Tender"}
                            type={"text"}
                            id="max_suggested_tender"
                            name="max_suggested_tender"
                            value={this.state.inputs.max_suggested_tender.value}
                            inputRef={this.state.inputs.max_suggested_tender.ref}
                            hasError={!!this.state.inputs.max_suggested_tender.error}
                            required={true}
                            onChange={ this.updateValuesToState }
                            onBlur={ this.handleInputBlur }
                        />
                    </div>
                </div>
                <div className="ui-hide-tablet ui-hide-full">
                    <div className="popup__form__row spacing-40-top-mobile">
                        <div className="popup__form__cell popup__form__cell__100__mobile">
                            <button
                                className="button ui-normal-button ui-full-width-button"
                                onClick={ (event) => {
                                    event.preventDefault();

                                    // This validates front end inputs before
                                    // submitting to the server.  - mz 24 Jul 2019.
                                    let stateClone = {...this.state};
                                    this.validator.setStateClone(stateClone);

                                    if(!this.validator.validateAllInputs())
                                    {
                                        stateClone = this.validator.getStateClone(stateClone);
                                        this.setState({inputs: stateClone.inputs});

                                        this.validator.displayUserErrors();
                                        return;
                                    }

                                    this.props.submitIndustry(industryFromInputs);
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                <div className="ui-hide-mobile">
                    <div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
                        <div className="popup__form__row popup__form__row__slam-right">
                            <div className="popup__form__cell">
                                <button
                                    className="button ui-normal-button"
                                    onClick={ (event) => {
                                        event.preventDefault();
                                        let stateClone = {...this.state};
                                        this.validator.setStateClone(stateClone);

                                        if(!this.validator.validateAllInputs())
                                        {
                                            stateClone = this.validator.getStateClone(stateClone);
                                            this.setState({inputs: stateClone.inputs});

                                            this.validator.displayUserErrors();
                                            return;
                                        }

                                        this.props.submitIndustry(industryFromInputs);
                                    }}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </>
        );
    }
}
