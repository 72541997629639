import React from 'react';

const CreditCardHeaderRow = props => (
    <tr>
        <td colSpan="5" className="ui-hide-full ui-hide-tablet">
            <p className="type-normal-subhead">
                { props.header }
            </p>
        </td>
    </tr>
);

export default CreditCardHeaderRow;