import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, Nav, Item, SubNav, SubNavContent } from "./styles";

export const MenuLink = ({ children, ...rest }) => (
  <Link activeClassName="here" {...rest}>
    {children}
  </Link>
);
MenuLink.propTypes = {
  align: PropTypes.oneOf(["left", "middle", "right"]),
};

export const MenuItem = ({ children, ...rest }) => (
  <Item {...rest}>{children}</Item>
);

export const NavMenu = ({ children, ...rest }) => (
  <Nav {...rest}>{children}</Nav>
);

export const SubMenu = ({ children, text = "", ...rest }) => {
  const [open, setOpen] = useState(false);

  /**
   * This function sets up a click listener to listen for clicks
   * anywhere in the document except in the input ref. If a click
   * is detected anywhere except the input ref, the submenu is closed
   * @param {Node} ref 
   */
  function useOutsideRefClickListener(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target)
        ) {
          setOpen(false);
        }
      }

      // Register the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Cleanup / remove the click listener
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const subNavLinkRef = useRef(null);
  useOutsideRefClickListener(subNavLinkRef);
  return (
    <SubNav {...rest}>
      <Link ref={subNavLinkRef} as="span" onClick={() => setOpen(!open)}>
        {text}
      </Link>
      <SubNavContent open={open}>
        {children}
      </SubNavContent>
    </SubNav>
  );
};
