import React from 'react';

import TextArea from "../../common/components/form_elements/TextArea";
import FormModal from "../../common/components/modal/FormModal";

export default class FlagLeadModal extends FormModal {
    clickFlagLead = (event, leadId) => {
        event.preventDefault();

        let message = event.target.querySelector('[name="description"]').value;
        if (!message) {
            this.props.updateMessageBlocks(['A description is required for creating a HubSpot ticket.'], 'error');
            return;
        }
        
        this.props.flagLead(leadId, message, this.props.companyName);
    };

    render() {

        let leadId = this.props.leadId;


        return (
        <>
            <form className="lead_review_form" onSubmit={(e) => this.clickFlagLead(e, leadId)}>
                <div className="popup__form__row spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    <div className="popup__form__cell popup__form__cell__100 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <TextArea label="Please Describe Issue" name="description"/>
                    </div>
                </div>
                <div className="ui-hide-full ui-hide-tablet">
                    <div className="popup__form__row spacing-40-top-mobile">
                        <div className="popup__form__cell popup__form__cell__100__mobile">
                            <div className="spacing-20-bottom-mobile">
                                <button className="button ui-normal-button ui-full-width-button">
                                    Flag Lead and Create Hubspot Ticket
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ui-hide-mobile">
                    <div className="padding-24-bottom">
                        <div className="popup__form__row popup__form__row__slam-right">
                            <div className="popup__form__cell">
                                <button className="button ui-normal-button">
                                    Flag Lead and Create Hubspot Ticket
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
        );
    }
}