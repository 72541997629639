import React, { Fragment } from 'react'
import Button from '../../../../../common/components/form_elements/Button'
import ConfirmationTable from './ConfirmationTable'
import { ActionsWrapper } from './styles'

export default (props) => {
    const { changes, onGoBack, onConfirm, affiliate } = props

    return (
        <Fragment>
            <ConfirmationTable changes={changes} affiliate={affiliate} />
            <ActionsWrapper>
                <Button onClick={onGoBack} variant="text">
                    <span className="type-heavy">Go Back</span>
                </Button>
                <Button
                    onClick={() => {
                        onConfirm && onConfirm(changes, affiliate)
                    }}
                >
                    Confirm And Save
                </Button>
            </ActionsWrapper>
        </Fragment>
    )
}
