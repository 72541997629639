import React from "react";
import PropTypes from 'prop-types'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonText = ({ variant: Variant = "p", width = 300,  className, ...rest }) => {
  const classNameMap = {
    h1: "type-large-headline",
    h2: "type-normal-headline",
    h3: "type-small-headline",
    p: "",
  };

  return (
    <Variant className={classNameMap[Variant] || className}>
      <Skeleton baseColor="#dcdfe3" highlightColor="#f0f3f6" width={width} {...rest} />
    </Variant>
  );
};

SkeletonText.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'p', 'span']),
  enableAnimation: PropTypes.bool,
  width: PropTypes.number
}

export default SkeletonText;
