import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from "../../common/Tooltip";
import AuthService from "../../service/AuthService";

export default class LeadLogHeader extends Component {
    constructor(props) {
        super(props);

        this.tooltipRef = React.createRef();
    }

    render() {
        let companyNameHeaderJsx = this.props.isParentContractor &&
            <th id="log-company-name" className="leadlog__col__company_name" >
                <div className="leadlog__header__contents">
                    <div className="leadlog__header__label">
                        Company Name
                    </div>
                </div>
            </th>;

        const dateLabelJsx = (
            <div className="leadlog__header__label">
                Date
            </div>
        );

        const campaignLabelJsx = (
            <div className="leadlog__header__label">
                Campaign
            </div>
        );

       return  (
        <thead>
            <tr className="type-small-body type-heavy">
                {this.props.adminView && (
                    <th id="log-admin-icon" className="leadlog__col__icon" />
                )}
                <th id="log-client-icon" className="leadlog__col__icon" />
                <th id="log-datetime" className="leadlog__col__datetime">
                    {(this.props.adminView) ? (
                        <div
                            className={`leadlog__header__contents leadlog__header__sortable ${(this.props.sortColumn === 'date') ? 'leadlog__header__sorted' : ''}`}
                            role="button"
                            onClick={(e) => this.props.updateSort('date') }
                        >
                            { dateLabelJsx }
                        </div>
                    ): (
                        <div className="leadlog__header__contents">
                            { dateLabelJsx }
                        </div>
                    )}
                </th>
                <th id="log-progress" className="leadlog__col__progress">
                    <div className="leadlog__header__contents">
                        <div className="leadlog__header__label">
                            Progress
                        </div>
                    </div>
                </th>
                { companyNameHeaderJsx }
                <th id="log-campaign" className="leadlog__col__campaign">
                    {(this.props.adminView) ? (
                        <div
                            className={`leadlog__header__contents leadlog__header__sortable ${(this.props.sortColumn === 'campaign') ? 'leadlog__header__sorted' : ''}`}
                            role="button"
                            onClick={(e) => this.props.updateSort('campaign')}
                        >
                            { campaignLabelJsx }
                        </div>
                    ) : (
                        <div className="leadlog__header__contents">
                            { campaignLabelJsx }
                        </div>
                    )}
                </th>
                {this.props.adminView && (
                    <th id="log-client" className="leadlog__col__client">
                        <div
                            className={`leadlog__header__contents leadlog__header__sortable ${(this.props.sortColumn === 'client') ? 'leadlog__header__sorted' : ''}`}
                            role="button"
                            onClick={(e) => this.props.updateSort('client')}
                        >
                            <div className="leadlog__header__label">
                                Client
                            </div>
                        </div>
                    </th>
                )}
                <th id="log-id" className="leadlog__col__id">
                    <div className="leadlog__header__contents">
                        <div className="leadlog__header__label">
                            Name / Caller ID
                        </div>
                    </div>
                </th>
                <th id="log-contact" className="leadlog__col__contact">
                    <div className="leadlog__header__contents">
                        <div className="leadlog__header__label">
                            Contact Details
                        </div>
                    </div>
                </th>
                <th id="log-duration" className="leadlog__col__duration">
                    <div
                        className={`leadlog__header__contents leadlog__header__sortable ${(this.props.sortColumn === 'duration') ? 'leadlog__header__sorted' : ''}`}
                        role="button"
                        onClick={(e) => this.props.updateSort('duration')}
                    >
                        <div className="leadlog__header__label">
                            Duration
                        </div>
                    </div>
                </th>
                <th id="log-cost" className="leadlog__col__cost">
                    <div className="leadlog__header__contents">
                        <div className="leadlog__header__label">
                            <span className="type-no-break">
                                Cost{' '}
                                {!this.props.adminView && (
                                    <Tooltip
                                        content={
                                            <>
                                                <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                                    This is the amount you have agreed to pay for the Lead, based on the Cost Per Lead set in the associated Campaign's settings.
                                                </span>
                                                <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                                    Learn More about how your{' '}
                                                    <a className="type-heavy"
                                                       href={AuthService.isMarketplace
                                                           ? "https://support.servicedirect.com/lead-volume"
                                                           : "https://support.servicedirect.com/select/lead-volume"}
                                                       target="_blank">Cost Per Lead impacts your Lead Volume.</a>
                                                </span>
                                            </>
                                        }
                                        position="left"
                                        // modalContainerRef={this.tooltipRef}
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                </th>
                <th id="log-revenue" className="leadlog__col__revenue">
                    <div className="leadlog__header__contents">
                        <div className="leadlog__header__label">
                            <span className="type-no-break">
                                Revenue{' '}
                                {!this.props.adminView && (
                                    <Tooltip
                                        content={
                                            <>
                                            <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                                This is the revenue that your company made from a Lead. This data is
                                                only available if you enter the revenue from the jobs you win into your
                                                Service Direct Lead Manager. Adding this information will help you
                                                determine your overall Return on Investment using our services, and help
                                                us to drive more profitable leads to you.
                                            </span>
                                            <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                                Learn More about{' '}
                                                <a className="type-heavy" target="_blank"
                                                   href={AuthService.isMarketplace
                                                       ? "https://support.servicedirect.com/managing-your-leads"
                                                       : "https://support.servicedirect.com/select/managing-your-leads"}>
                                                    Managing Your Leads.
                                                </a>
                                            </span>
                                            </>
                                        }
                                        position="left"
                                        // modalContainerRef={this.tooltipRef}
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                </th>
                {this.props.adminView && <>
                    <th id="log-payout-outcome" className="leadlog__col__payout_outcome">
                        <div className="leadlog__header__contents">
                            <div className="leadlog__header__label">
                                <span className="type-no-break">
                                    Payout Outcome
                                </span>
                            </div>
                        </div>
                    </th>
                    <th id="log-payout" className="leadlog__col__payout">
                        <div className="leadlog__header__contents">
                            <div className="leadlog__header__label">
                                <span className="type-no-break">
                                    Payout
                                </span>
                            </div>
                        </div>
                    </th>
                </>}
            </tr>
        </thead>
        )
    }
}

LeadLogHeader.propTypes = {
    isParentContractor: PropTypes.bool
}
