import React, { useEffect, useState } from 'react'
import { IconEyeSvg } from '../../../Svgs'
import RadioGroup from '../../../components/form_elements/RadioGroup'
import Button from '../../../components/form_elements/Button'
import { isFormUpdated } from '../utils'
import { LabelText } from '../../../components/form_elements/RadioGroup/styles'
import Tooltip from '../../../Tooltip'
import { CSRScorecard } from './InsightDefinitions'
import { times } from 'lodash'
import SkeletonText from '../../../components/Skeleton/SkeletonText'

const defaultRadioOptions = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
]

export default (props) => {
    const {
        callInsights,
        leadProgress,
        updateCallInsights,
        leadId,
        enrolledInCallInsights,
        callInsightsComplete,
        updateLead,
    } = props

    const handleRadioChange = (property, value) => {
        value = parseInt(value)
        updateCallInsights({ [property]: value })
    }

    const setAllCsrScorecardVals = (val) => () => {
        const csrScorecard = {
            csr_greeting_company_name: val,
            csr_greeting_offer_help: val,
            csr_show_empathy: val,
            collected_cust_info: val,
            csr_confirm_property_owner: val,
            csr_collect_service_details: val,
            csr_ask_for_appointment: val,
            csr_overcome_objectives: val,
            csr_confirm_appointment: val,
        }
        updateCallInsights(csrScorecard)
    }

    const [showScorecard, setShowScorecard] = useState(false)

    // show the CSR scorecard if the client is enrolled in call insights or
    // if there is any csr scorecard data
    useEffect(() => {
        if (
            enrolledInCallInsights === '1' ||
            (callInsights != null &&
                Object.entries(callInsights)?.filter(
                    ([key, val]) => {
                        if (key.includes('csr') || key.includes('collected_cust_info')) {
                            return val !== null
                        }
                        return false
                    }
                )?.length > 0)
        ) {
            setShowScorecard(true)
        }
    }, [callInsights, enrolledInCallInsights])

    // if the client is not enrolled in call insights
    // show the button
    if (!showScorecard) {
        return (
            <button
                className="button ui-normal-button"
                onClick={() => setShowScorecard(true)}
                disabled={callInsights == null}
            >
                Show CSR Scorecard Form
            </button>
        )
    }

    // if the client is enrolled but the call insights api has not returned
    // show the skeleton
    if (callInsights == null) {
        return <CSRScorecardSkeleton />
    }

    return (
        <>
            <table className="call-insights spacing-20-bottom">
                <tbody>
                    <tr>
                        <th className="type-normal-body type-single-line type-heavy">
                            CSR Scorecard{' '}
                            <Tooltip position="left" content={<CSRScorecard />} />
                        </th>
                        <td>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button
                                    variant="span"
                                    onClick={setAllCsrScorecardVals('1')}
                                >
                                    All Yes
                                </Button>
                                <Button
                                    variant="span"
                                    onClick={setAllCsrScorecardVals('0')}
                                >
                                    All No
                                </Button>
                                <Button
                                    variant="span"
                                    onClick={setAllCsrScorecardVals(null)}
                                >
                                    Clear All
                                </Button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Greet caller using company name?</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending}
                                showLabel={false}
                                align="spread"
                                name="csr_greeting_company_name"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={callInsights?.csr_greeting_company_name}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Greet caller with offer to help?</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending}
                                showLabel={false}
                                align="spread"
                                name="csr_greeting_offer_help"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={callInsights?.csr_greeting_offer_help}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Show empathy?</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending}
                                showLabel={false}
                                align="spread"
                                name="csr_show_empathy"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={callInsights?.csr_show_empathy}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Collect customer info?</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending}
                                showLabel={false}
                                align="spread"
                                name="collected_cust_info"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={callInsights?.collected_cust_info}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>
                                Confirm property type/service area/owner?
                            </LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending}
                                showLabel={false}
                                align="spread"
                                name="csr_confirm_property_owner"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={callInsights?.csr_confirm_property_owner}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Get details about service need?</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending}
                                showLabel={false}
                                align="spread"
                                name="csr_collect_service_details"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={callInsights?.csr_collect_service_details}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Ask for the appointment?</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending}
                                showLabel={false}
                                align="spread"
                                name="csr_ask_for_appointment"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={callInsights?.csr_ask_for_appointment}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Overcome objections or sell value?</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending}
                                showLabel={false}
                                align="spread"
                                name="csr_overcome_objectives"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={callInsights?.csr_overcome_objectives}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Confirm order/address/time/date?</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending}
                                showLabel={false}
                                align="spread"
                                name="csr_confirm_appointment"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={callInsights?.csr_confirm_appointment}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                {isFormUpdated(callInsights, leadProgress) ? (
                    <>
                        {callInsightsComplete === '0' ? (
                            <span
                                role="button"
                                className="type-allcaps type-small-body"
                                onClick={(e) =>
                                    updateLead(leadId, {
                                        call_insights_complete: '1',
                                    })
                                }
                            >
                                <span className="inline-icon type-inline-icon wide-icon">
                                    {IconEyeSvg}
                                </span>
                                Call Insights Complete
                            </span>
                        ) : (
                            <span className="type-allcaps type-small-body type-green">
                                <span className="inline-icon type-inline-icon wide-icon">
                                    {IconEyeSvg}
                                </span>
                                Call Insights Complete
                            </span>
                        )}
                    </>
                ) : (
                    <span className="type-allcaps type-small-body type-grey">
                        <span className="inline-icon type-inline-icon wide-icon">
                            {IconEyeSvg}
                        </span>
                        Call Insights Complete
                    </span>
                )}
            </div>
        </>
    )
}

const CSRScorecardSkeleton = () => {
    return (
        <table className="call-insights spacing-20-bottom">
            <thead>
                <tr>
                    <th
                        colSpan="3"
                        className="type-normal-body type-single-line type-heavy"
                    >
                        CSR Scorecard
                    </th>
                </tr>
            </thead>
            <tbody>
                {times(9, (i) => (
                    <tr key={`csr-scorecard-row-skeleton-${i}`}>
                        <td className="label">
                            <SkeletonText width={180} />
                        </td>
                        <td>
                            <RadioGroup
                                disabled={true}
                                showLabel={false}
                                align="spread"
                                options={defaultRadioOptions}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
