import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { HamburgerSvg, ButtonNavCloseSvg } from '../common/Svgs';
import PropTypes from 'prop-types';
import {errorHandler} from "../Requests";
import AuthService from "../service/AuthService";
import { getScrollY, handleFloatingHeader } from '../common/FloatingHeader';
import { MenuLink, NavMenu, MenuItem, SubMenu } from '../common/components/Nav/NavMenu'
import TokenService from "../service/TokenService";

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newLeadCount: 0,
            notificationCount: 0,
            lastUpdate: null,
            expandMoreHeader: false,
            mobilePopover: false,
        };

        this.headerRef = React.createRef();
        this.tokenService = new TokenService();
    }

    componentDidMount() {
        document.body.setAttribute('data-last-scroll-top', getScrollY());

        window.addEventListener(
            'scroll',
            () =>
                handleFloatingHeader(this.headerRef.current, this.props.secondaryNavRef.current)
        );
        setInterval(
            () =>
                handleFloatingHeader(this.headerRef.current, this.props.secondaryNavRef.current),
            500
        );
    }

    componentWillUnmount() {
        this.tokenService.cancelSignal.cancel();
    }

    /**
     * Logs user out.
     */
    logout = () =>
        this.tokenService.logout()
            .catch(errorHandler);

    /**
     * Toggle mobile popover menu
     */
    toggleMobilePopover = () => {
        this.setState({mobilePopover: !this.state.mobilePopover});
    };

    /**
     * Toggle the menu dropdown
     */
    toggleMenu = () => {
        this.setState({expandMoreHeader: !this.state.expandMoreHeader});
    };

    /**
     * if menu is open close it
     */
    closeMenuIfOpen = () =>  {
        if (this.state.expandMoreHeader) {
            this.toggleMenu();
        }
    };

    render() {
        let newLeadCount = this.props.headerValues.newLeadCount;

        return <>
            { /** MOBILE MENU */}
            <div className={"ui-hide-full ui-hide-tablet mobile-navigation-popover " +
                            (this.state.mobilePopover ? "expanded" : "") }>
                <span role="button" className="close-button" onClick={this.toggleMobilePopover}>
                    { ButtonNavCloseSvg }
                </span>
                <ul className="spacing-40-bottom">
                    <li className="type-normal-subhead type-single-line">
                        <NavLink to="/dashboard" onClick={this.toggleMobilePopover}>
                            Dashboard
                        </NavLink>
                    </li>
                    {AuthService.isParentOrManager &&
                        <li className="type-normal-subhead type-single-line">
                            <NavLink to="/clients" onClick={this.toggleMobilePopover}>
                                Client Accounts
                            </NavLink>
                        </li>}
                    <li className="type-normal-subhead type-single-line">
                        <NavLink to="/leads" onClick={this.toggleMobilePopover}>
                            Leads
                        </NavLink>
                    </li>
                    <li className="type-normal-subhead type-single-line">
                        <NavLink to="/campaigns" onClick={this.toggleMobilePopover}>
                            Campaigns
                        </NavLink>
                    </li>
                    <li className="type-normal-subhead type-single-line">
                        <NavLink to="/users" onClick={this.toggleMobilePopover}>
                            Users
                        </NavLink>
                    </li>
                    <li className="type-normal-subhead type-single-line">
                        <a href={AuthService.isMarketplace
                            ? "https://support.servicedirect.com/hubfs/Product%20Marketing/Info%20Sheets%20and%20User%20Guides/mySD-QuickStartGuide/mySD-Marketplace-QuickStartGuide.pdf"
                            : "https://support.servicedirect.com/hubfs/Product%20Marketing/Info%20Sheets%20and%20User%20Guides/mySD-QuickStartGuide/mySD-QuickStartGuide.pdf"}
                           onClick={this.toggleMobilePopover} target="_blank">
                            Quick Start
                        </a>
                    </li>
                    <li className="type-normal-subhead type-single-line">
                        <a href={AuthService.isMarketplace
                            ? "https://support.servicedirect.com/"
                            : "https://support.servicedirect.com/select"}
                           onClick={this.toggleMobilePopover} target="_blank">
                            Support
                        </a>
                    </li>
                    {AuthService.isMarketplace &&
                        <li className="type-normal-subhead type-single-line">
                            <a href="https://servicedirect.nickelled.com/welcome-to-mysd-marketplace" onClick={this.toggleMobilePopover} target="_blank">
                                Tour
                            </a>
                        </li>}
                    {!AuthService.isNexstar &&
                        <li className="type-normal-subhead type-single-line">
                            <NavLink to="/billing" onClick={this.toggleMobilePopover}>
                                Billing
                            </NavLink>
                        </li>}

                </ul>
                <div>
                    <button
                        className="button button__outline__white ui-small-button"
                        onClick={ () => this.logout() }>
                        Logout
                    </button>
                </div>
            </div>
            { /** DESKTOP MENU */}
            <header ref={ this.headerRef }>
                <div className="header-container page-width-wide">
                    <div className="wide-format-col header-contents">
                        <div className="header__logo padding-20-mobile">
                            <Link to="/">
                                <img src={this.props.whiteLabelPartnership.logo_uri} alt={this.props.whiteLabelPartnership.white_label_name}/>
                            </Link>
                        </div>
                        <div className="ui-hide-mobile">
                            <div className="header__actions">
                                <NavMenu>
                                    <MenuItem>
                                        <MenuLink to="/dashboard">
                                            Dashboard
                                        </MenuLink>
                                    </MenuItem>
                                    { AuthService.isParentOrManager &&
                                        <MenuItem>
                                            <MenuLink to="/clients">
                                                Client Accounts
                                            </MenuLink>
                                        </MenuItem> 
                                    }
                                    <MenuItem>
                                        <MenuLink to="/leads">
                                            { newLeadCount !== 0
                                                ? (
                                                <div className="header__links__badge__wrap">
                                                    <span className="header__links__badge__badge">{ newLeadCount }</span>
                                                </div>)
                                                : ""
                                            }
                                            Leads
                                        </MenuLink>
                                    </MenuItem>
                                    <MenuItem>
                                        <MenuLink to="/campaigns">
                                            Campaigns
                                        </MenuLink>
                                    </MenuItem>
                                    <SubMenu text="More">
                                        <MenuLink align="left" to="/users">Users</MenuLink>
                                        <MenuLink align="left" as="a" target="_blank" href={AuthService.isMarketplace
                                                ? "https://support.servicedirect.com/hubfs/Product%20Marketing/Info%20Sheets%20and%20User%20Guides/mySD-QuickStartGuide/mySD-Marketplace-QuickStartGuide.pdf"
                                                : "https://support.servicedirect.com/hubfs/Product%20Marketing/Info%20Sheets%20and%20User%20Guides/mySD-QuickStartGuide/mySD-QuickStartGuide.pdf"}>
                                            Quick Start
                                        </MenuLink>
                                        <MenuLink 
                                            align="left"
                                            as="a"
                                            target="_blank"
                                            href={AuthService.isMarketplace
                                                    ? "https://support.servicedirect.com/"
                                                    : "https://support.servicedirect.com/select"}>
                                            Support
                                        </MenuLink>
                                        {AuthService.isMarketplace &&
                                            <MenuLink 
                                                align="left"
                                                as="a" 
                                                href="https://servicedirect.nickelled.com/welcome-to-mysd-marketplace"
                                                target="_blank"
                                            >Tour
                                            </MenuLink>}
                                        {!AuthService.isNexstar && <MenuLink  align="left" to="/billing">Billing</MenuLink>}
                                    </SubMenu>
                                </NavMenu>
                                <div className="header__button-wrap">
                                    <div
                                        className="button button__outline ui-normal-button"
                                        onClick={ () => this.logout() }
                                    >
                                        Logout
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ui-hide-full ui-hide-tablet">
                            <div className="hamburger-menu-container">
                                <span role="button" onClick={this.toggleMobilePopover} className="hamburger-button">
                                    { HamburgerSvg }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="close-menu-div" className={ this.state.expandMoreHeader ? "show" : "" }
                    onClick={ this.closeMenuIfOpen }
                >
                </div>
            </header>
        </>;
    }
}

Header.propTypes = {
    updateAppHeaderCounts: PropTypes.func,
    headerValues: PropTypes.object,
};
