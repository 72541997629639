import React, { Fragment, useRef, useState } from 'react'
import Button, { IconButton } from '../../../../../common/components/form_elements/Button'
import { ContentWindowScroll } from '../../../../../common/Modal'
import CopyToClipboard from '../../../../../common/components/CopyToClipboard'
import { ActionsWrapper, Divider } from '../styles'
import { CopyWrapper } from '../NumberConfirmation/styles'
import EditNumber from '../ViewEditNumbers/EditNumber'
import Label from '../../../../../common/components/form_elements/Label'
import { formatPhoneNumber } from '../../../../../Util'
import {
    ContentWrapper,
    EditNumberWrapper,
    Wrapper,
    EditNumberCell,
    CopyToClipboardWrapper,
    AddNewWrapper,
} from './styles'
import NewNumber from '../CreateNewNumber/NewNumber'
import { Delete } from '../../../../../common/Svgs'
import { DeleteContainer } from '../CreateNewNumber/styles'

export default function ViewEditNumber(props) {
    const {
        onClose,
        onPreview,
        changes,
        newNumbers,
        selectedAffiliate,
        changedIds = [],
        deletedNumberIds = [],
        newNumberTemplate,
        serviceCategories,
    } = props
    const [editNumbers, setEditNumbers] = useState(changes || [])
    const [_newNumbers, setNewNumbers] = useState(newNumbers || [])
    const newNumberId = useRef(_newNumbers?.length || 0)
    const [phoneIdsToUpdate, setPhoneIdsToUpdate] = useState(changedIds)
    const [phoneIdsToDelete, setPhoneIdsToDelete] = useState(deletedNumberIds)
    const [isDeleteAll, setIsDeleteAll] = useState(
        deletedNumberIds.length == changes.length
    )

    const addUniqueToArr = (val, vals) => {
        if (!vals?.includes(val)) {
            return [...vals, val]
        }
        return vals
    }

    // handle the config update by replacing the configuration in the array of new configs
    const handleUpdateNumber = (id, config) => {
        // ensure sorting is respected
        const newConfigs = editNumbers.map((c) => {
            if (c.did_phone_number_id == id) {
                return config
            }
            return c
        })
        setPhoneIdsToUpdate(addUniqueToArr(id, phoneIdsToUpdate))
        setEditNumbers(newConfigs)
    }

    const handleUpdateNewNumber = (id, config) => {
        // ensure sorting is respected
        const update = _newNumbers.map((n) => {
            if (n._id == id) {
                return config
            }
            return n
        })
        setNewNumbers(update)
    }

    const handleAddAnotherNumber = () => {
        // create a new number using a temporary id and action
        // along with the template supplied by the api
        const newNumber = Object.assign(
            {},
            {
                _id: `new_${newNumberId.current}`,
                action: 'create',
            },
            newNumberTemplate
        )
        newNumberId.current++
        setNewNumbers([..._newNumbers, newNumber])
    }

    const handleRemoveNumber = (id) => {
        setNewNumbers([..._newNumbers.filter((n) => n._id != id)])
    }

    /**
     * Delete existing number handler. If the id is already included in the
     * list of ids to delete, it is removed. Otherwise, it is added.
     * @param {string | number} id the id of the phone number to delete
     */
    const handleDeleteExistingNumber = (id) => {
        if (phoneIdsToDelete.includes(id)) {
            setPhoneIdsToDelete(phoneIdsToDelete.filter((i) => i != id))
        } else {
            setPhoneIdsToDelete(addUniqueToArr(id, phoneIdsToDelete))
        }
    }

    const numbersView = editNumbers?.map((n) => (
        <Fragment key={n.did_phone_number_id}>
            <EditNumber
                configuration={n}
                onUpdateNumber={handleUpdateNumber}
                onRemoveNumber={handleDeleteExistingNumber}
                isDeleted={phoneIdsToDelete.includes(n.did_phone_number_id)}
            />
            <Divider borderLight={true} />
        </Fragment>
    ))
    const newNumbersView = _newNumbers?.map((n) => {
        return (
            <div key={`new_${n._id}`} className="spacing-20-top spacing-20-bottom">
                <NewNumber
                    serviceCategories={serviceCategories}
                    configuration={n}
                    onUpdateNumber={handleUpdateNewNumber}
                    onRemoveNumber={handleRemoveNumber}
                />
            </div>
        )
    })

    // function the check if all new numbers are configured
    const areAllNewNumbersConfigured = () => {
        // all numbers are configured if the industry id is set
        // the whisper setting is an unclearable radio button so it is
        // always set to true or false
        // if an area code has been set, it should either be empty, or
        // it should be 3 characters exactly
        for (const n of _newNumbers) {
            if (!n.industry_id) {
                return false
            }
            if (n.area_code && n.area_code.length != 0 && n.area_code.length != 3) {
                return false
            }
        }
        return true
    }

    const numbersPlainText = () =>
        changes?.reduce((acc, n, i) => {
            acc += `${n.industry_name} `
            if (n.phone_name) {
                acc += `(${n.phone_name}) `
            }
            acc += `- ${formatPhoneNumber(n.phone_number)}`
            if (i < changes.length - 1) {
                acc += '\n'
            }
            return acc
        }, '')

    const isContinueDisabled = () => {
        let result = false
        if (editNumbers.length > 0) {
            result = phoneIdsToUpdate.length == 0 && phoneIdsToDelete.length == 0
        }
        if (_newNumbers.length > 0) {
            result = !areAllNewNumbersConfigured()
        }
        return result
    }

    /**
     * If the user is toggling ON delete all, remove all new numbers
     * and add the ids of all existing numbers to be deleted.
     *
     * if the user is toggling OFF delete all, remove all the ids
     * of existing numbers from the phoneNumberIdsToDelete array
     */
    const handleDeleteAll = (e) => {
        e.stopPropagation()
        // if the user is toggling ON delete all
        if (!isDeleteAll) {
            // remove all new numbers
            setNewNumbers([])
            const _phoneIdsToDelete = changes.reduce((acc, n) => {
                // only delete numbers that aren't already retired
                if (n.is_retired != '1') {
                    acc.push(n.did_phone_number_id)
                }
                return acc
            }, [])
            setPhoneIdsToDelete(_phoneIdsToDelete)
            setIsDeleteAll(true)
        } else {
            setPhoneIdsToDelete([])
            setIsDeleteAll(false)
        }
    }

    const isDeleteAllDisabled = false
    // const trashcanFill = isDeleteAll ? '#e10008' : '#0094d9'
    return (
        <Fragment>
            <ContentWindowScroll>
                <Wrapper>
                    <ContentWrapper>
                        <div className="spacing-20-bottom">
                            <span className="type-normal-subhead type-heavy">
                                Edit DID Numbers for {selectedAffiliate.text}
                            </span>
                        </div>
                        <EditNumberWrapper>
                            <EditNumberCell>
                                <Label label="Service Category for DID Number" />
                            </EditNumberCell>
                            <EditNumberCell>
                                <Label label="Phone Name" />
                            </EditNumberCell>
                            <EditNumberCell>
                                <Label label="Phone Number" />
                            </EditNumberCell>
                            <EditNumberCell>
                                <Label label="Play Whisper File" />
                            </EditNumberCell>
                            <EditNumberCell>
                                <Label label="Created" />
                            </EditNumberCell>
                            <DeleteContainer>
                                <IconButton
                                    variant="span"
                                    icon={Delete}
                                    onClick={handleDeleteAll}
                                    disabled={isDeleteAllDisabled} // the delete button is only disabled if the number is currently disabled
                                    // style={{ marginRight: '5px' }}
                                />
                            </DeleteContainer>
                            {/* <Label label="Service Category for DID Number" />
                            <Label label="Phone Name" />
                            <Label label="Phone Number" />
                            <Label label="Play Whisper File" />
                            <Label label="Created" />
                            <IconButton
                                variant="span"
                                icon={Delete}
                                onClick={handleDeleteAll}
                                disabled={isDeleteAllDisabled} // the delete button is only disabled if the number is currently disabled
                                style={{ marginRight: '5px' }}
                            /> */}
                        </EditNumberWrapper>
                        <Divider />
                        {numbersView}
                        <CopyToClipboardWrapper>
                            <div />
                            <CopyWrapper>
                                <CopyToClipboard
                                    dataToCopy={numbersPlainText()}
                                    buttonText="Copy Numbers To Clipboard"
                                />
                            </CopyWrapper>
                        </CopyToClipboardWrapper>
                        <div>
                            <div className="spacing-20-bottom">
                                <span className="type-normal-subhead type-heavy">
                                    Add New DID Numbers for {selectedAffiliate.text}
                                </span>
                            </div>
                            {newNumbersView}
                        </div>
                        <Divider borderLight={true} />
                        <AddNewWrapper>
                            <Button
                                size="small"
                                style={{ margin: 0 }}
                                onClick={handleAddAnotherNumber}
                                disabled={!areAllNewNumbersConfigured()}
                            >
                                Add Another Service Category DID Number
                            </Button>
                        </AddNewWrapper>
                    </ContentWrapper>
                </Wrapper>
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button onClick={onClose} variant="text">
                    <span className="type-heavy">Cancel</span>
                </Button>
                <Button
                    disabled={isContinueDisabled()}
                    onClick={() => {
                        onPreview &&
                            onPreview({
                                changes: editNumbers,
                                newNumbers: _newNumbers,
                                deletedNumberIds: phoneIdsToDelete,
                                changedIds: phoneIdsToUpdate,
                                affiliate: selectedAffiliate,
                            })
                    }}
                >
                    Continue To Confirmation
                </Button>
            </ActionsWrapper>
        </Fragment>
    )
}
