import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MultiSelectList from '../MultiSelectList'
import { DateRange as ReactDateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Wrapper, DropdownWrapper, PresetWrapper } from './styles'
import { dateRangeOptionsWithoutToday as dateRangeOptions } from '../../../StaticValues'
import DateRangeService from '../../../../service/DateRange'
import { CalendarSvg } from '../../../Svgs'

const DateRange = memo(({ onChange, dateFrom, dateTo }) => {
    // preset info for preset list
    const dateRangeService = new DateRangeService()
    const preset = dateRangeService.getPresetFromRange(dateFrom, dateTo)
    const [datePreset, setDatePreset] = useState(preset?.value)

    let startDate
    let endDate
    const now = new Date()
    const timezoneOffsetMinutes = now.getTimezoneOffset()
    if (dateFrom) {
        startDate = new Date(dateFrom)
        startDate.setMinutes(timezoneOffsetMinutes)
    }
    if (dateTo) {
        endDate = new Date(dateTo)
        endDate.setMinutes(timezoneOffsetMinutes)
    }
    const [state, setState] = useState([
        {
            startDate,
            endDate,
            key: 'selection',
        },
    ])

    const handleSelectDatePreset = (vals) => {
        const dateRangeId = vals[0]
        setDatePreset(dateRangeId)

        // get the start and end dates from the presets
        const dateState = state[0]
        let startDate = null
        let endDate = null
        if (dateRangeId === 'custom') {
            startDate = new Date()
            endDate = new Date()
        } else if (dateRangeId === '7') {
            startDate = null
            endDate = new Date()
        } else {
            // Set up to and from dates for all filters except All Time
            let fromToDates = dateRangeService.getFromToDates(dateRangeId, {
                format: false,
            })
            startDate = fromToDates.dateFrom
            endDate = fromToDates.dateTo
        }

        setState([
            {
                ...dateState,
                startDate,
                endDate,
            },
        ])

        // get the display text for the date value
        // const dateValue = dateRangeOptions.filter((o) => o.value === dateRangeId)
        // onChange && onChange(dateValue[0].text)

        onChange && onChange([startDate, endDate], dateRangeId)
    }

    const handleSelectRange = (item) => {
        setState([item.selection])
        setDatePreset('custom')
        // onChange && onChange('Custom')
        const { startDate, endDate } = item.selection
        // const formattedRange = `${dateRangeService.formatDate(
        //     item.selection.startDate
        // )} - ${dateRangeService.formatDate(item.selection.endDate)}`
        // onChange && onChange(formattedRange)

        onChange && onChange([startDate, endDate])
    }

    return (
        <Wrapper>
            <PresetWrapper>
                <MultiSelectList
                    options={dateRangeOptions}
                    selections={[datePreset]}
                    onChange={handleSelectDatePreset}
                    variant="list"
                    itemsToDisplay={8}
                />
            </PresetWrapper>
            {/* Styles for this component can be overridden by using the class names
            for the component in sass/components/react-date-range */}
            <ReactDateRange
                showDateDisplay={true}
                onChange={handleSelectRange}
                moveRangeOnFirstSelection={false}
                ranges={state}
                rangeColors={['#0094d9']}
                color="#006ea1"
            />
        </Wrapper>
    )
})

DateRange.propTypes = {
    onChange: PropTypes.func,
    datePresetId: PropTypes.string,
}

export default DateRange

export const DateRangeDropdown = memo(({
    label,
    onChange,
    extraClass,
    open,
    dateFrom,
    dateTo,
    disabled,
    emptyLabel,
    isEmpty,
    ...rest
}) => {
    // figure out which date preset matches the input to and from date
    const dateRangeService = new DateRangeService()
    // const [dropdownLabel, setDropdownLabel] = useState(preset?.text)
    const [dropdownLabel, setDropdownLabel] = useState()

    useEffect(() => {
        if (isEmpty) {
            setDropdownLabel('Optional')
        } else {
            if (dateTo != null) {
                const preset = dateRangeService.getPresetFromRange(dateFrom, dateTo)
                // update the dropdown label if a non custom preset is found
                if (preset?.value != 'custom') {
                    setDropdownLabel(preset?.text)
                } else {
                    // if a custom preset is determined, format the label properly
                    // parse the date as a sql format yyyy-mm-dd
                    const [df_y,df_m,df_d] = dateFrom.split('-')
                    const _dateFrom = new Date(+df_y, +df_m - 1, +df_d)
                    const [dt_y,dt_m,dt_d] = dateTo.split('-')
                    const _dateTo = new Date(+dt_y, +dt_m - 1, +dt_d)
                    setDropdownLabel(
                        `${dateRangeService.formatDate(
                            _dateFrom
                        )} - ${dateRangeService.formatDate(_dateTo)}`
                    )
                }
            }
        }
    }, [dateFrom, dateRangeService, dateTo, isEmpty, label])

    const handleDateSelection = ([dateFrom, dateTo], presetId) => {
        let _label
        if (presetId) {
            const preset = dateRangeOptions.filter((o) => o.value === presetId)[0]
            _label = preset?.text
        } else {
            _label = `${dateRangeService.formatDate(
                dateFrom
            )} - ${dateRangeService.formatDate(dateTo)}`
        }
        setDropdownLabel(_label)
        onChange && onChange([dateFrom, dateTo])
    }
    const dropdownContent = (
        <DropdownWrapper>
            <DateRange
                onChange={handleDateSelection}
                {...{ dateFrom, dateTo, ...rest }}
            />
        </DropdownWrapper>
    )
    return (
        <MultiSelectList
            label={label}
            dropdownContent={dropdownContent}
            dropdownLabel={dropdownLabel}
            dropdownIcon={CalendarSvg}
            dropdownClassname="dropdown__calendar"
            minDropdownWidth="560px"
            extraClass={extraClass}
            open={open}
            isEmpty={isEmpty}
            disabled={disabled}
        />
    )
})
