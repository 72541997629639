import React from 'react';

import FormModal from '../../common/components/modal/FormModal';
import AuthService from "../../service/AuthService";
import MapZipCodeSelection from "../../common/MapZipCodeSelection";
import {isValidAmericanZip} from "../../Util";

/**
 * Edit zip codes of a campaign in the campaign manager view
 */
export default class EditZipsModal extends FormModal {
    constructor(props) {
        super(props);

        //
        // parse the zip codes by splitting the data by spaces, newlines, and commas;
        // then keep only valid zip codes
        // and remove empty items
        //
        let cleanZips = this.props.zipCodes
            .split(/[\s,]+/)
            .map((zip: string) =>
                isValidAmericanZip(zip)
                    ? zip.trim()
                    : null)
            .filter(Boolean);

        this.state = {
            zipCodes: cleanZips,

            //
            // Set to true when form is submitting in order to
            // visually disable the submit button
            //
            submitting: false
        }
    }

    updateZipCodes = (key, value) => {
        if (key != 'zipCodes') {
            return;
        }
        this.setState({zipCodes: value});
    };

    submitChanges = (event) => {
        event.preventDefault();

        this.setState({submitting: true});
        this.props.handleChange(
            this.props.campaignId,
            this.props.campaignIndex,
            this.state.zipCodes.join(', ')
        )
            .catch(() => this.setState({submitting: false}));
    };

    render() {
        return <>
            <p className="type-normal-body spacing-18-bottom">
                <b>Note:</b>{' '}
                {AuthService.isMarketplace
                    ? <>
                        Adjusting these Zip codes only affects this one campaign. If you'd like to adjust the Service
                        Area for other Campaigns, make sure to update them as well.
                    </>
                    : <>
                        Adjusting these Zip codes only affects the one campaign listed above.
                        If you'd like to adjust the Service Area for all of your Campaigns, please{' '}
                        <a className="type-heavy" target="_blank" href="https://support.servicedirect.com">
                            Contact Support.
                        </a>
                    </>}
            </p>

            <form onSubmit={(event) => this.submitChanges(event)}
                  className="padding-60-bottom">
                <div className="popup__form__cell popup__form__cell__100">
                    <MapZipCodeSelection
                        zipCodes={this.state.zipCodes}
                        onValueChange={this.updateZipCodes}
                        updateMessageBlocks={this.props.updateMessageBlocks}
                        mainZipCode=""
                        containerHeight="400px"
                    />
                </div>
                <div className="zip-code-modal-footer padding-24">
                    <button className="button ui-normal-button"
                            disabled={this.state.submitting || this.state.zipCodes.length == 0}>
                        Save Zip Codes
                    </button>
                </div>
            </form>
        </>;
    }
}
