import React, {Component} from 'react';

import LeadLog from '../../common/lead_log/LeadLog';
import LeadService from "../../service/LeadService";
import {errorHandler} from "../../Requests";

export default class DashboardLeadLogSnapshot extends Component {
    MAXIMUM_VISIBLE_LEADS = 10;

    constructor(props) {
        super(props);

        this.state = {
            leads: [],
            industryRevenueAverages: [],
            modalContent: undefined,
            modalHeader: undefined,
            modalWidth: undefined,
            modalFlatbottom: undefined,
            contractorOptions: [],
        };

        this.leadService = new LeadService();
    }

    componentDidMount() {
        this.getLeads();
    };

    componentWillUnmount() {
        this.leadService.cancelSignal.cancel();
    };

    clearOutModalContent = () => {
        this.setState({
            modalContent: null,
            modalHeader: null,
            modalWidth: null,
            modalFlatBottom: null,
        });
    };

    /**
     * get the leads using the API
     */
    getLeads = () => {
        let url = `pagination[rows_per_page]=${this.MAXIMUM_VISIBLE_LEADS}&pagination[page_num]=1`;
        url += '&is_valid=1';
        this.leadService.getLeads(url)
            .then((response) =>
                this.setState({
                    leads: response.data.leads,
                    contractorOptions: response.data.contractor_options
                })
            )
            .catch(errorHandler);
    };

    /**
     * Receives key values pairs of updated lead properties and submits them to
     * the API.  On receipt, searches for the same lead in this view's state,
     * by id, and updates it there.
     */
    updateLead = (leadId, leadProps, callback) => {
        let leads = [...this.state.leads];
        let index = leads.findIndex((lead) => lead.lead_id == leadId);
        Object.assign(leads[index], leadProps);
        this.setState({leads});

        this.leadService.update(leadId, leadProps, this)
            .then(() => {
                this.props.getLeadSummary();
                if (typeof callback === 'function') {
                    callback();
                }
            })
            .catch((error) => {
                if (errorHandler(error)) {
                    if (typeof callback === 'function') {
                        callback(true);
                    }
                }
            });
    };

    /**
     * This component already has most of what it needs from a lead
     * passed in through the lead property.  But it lacks tags, which
     * this API call provides.
     */
    getLead = (leadId) => {
        this.leadService.get(leadId, this)
            .catch(errorHandler);
    };

    render() {
        return (
            <>
                <div className="module__header module__fullwidth">
                    <h3 className="type-large-subhead type-single-line">
                        Lead Manager Snapshot
                    </h3>
                </div>
                <div className="module__contentbox module__fullwidth">
                    <p className="type-large-body spacing-30-bottom">
                        <b>Below are just your most recent Billable Leads.</b> Go to your{' '}
                        <a href="/leads" className="type-heavy">
                            Lead Manager
                        </a>{' '}
                        to see all your leads and filter them by type.
                    </p>
                    <LeadLog
                        leads={this.state.leads}
                        updateLead={this.updateLead}
                        getLead={this.getLead}
                        industryRevenueAverages={this.state.industryRevenueAverages}
                        updateModalContent={this.props.updateModalContent}
                        updateMessageBlocks={this.props.updateMessageBlocks}
                        managedRoiLast30Days={this.props.leadSummary.managedRoiLast30Days}
                        confidenceLast30Days={this.props.leadSummary.confidenceLast30Days}
                        minConfidence={this.props.leadSummary.minConfidence}
                        leadsWithRevenue={this.props.leadSummary.leadsWithRevenue}
                        isParentContractor={this.state.contractorOptions.length > 0}
                        users={this.props.users}
                    />

                    <div className="spacing-34-top">
                        <div className="simpleflex__row simpleflex__row__wrap__mobile type-centered-mobile">
                            <div className="simpleflex__cell simpleflex__cell__middlealigned simpleflex__cell__maxed order-mobile-2 spacing-18-top-mobile">
                                <p className="type-large-body">
                                    <b>Want more Leads?</b> Increase the Cost Per Lead in your{' '}
                                    <a href="/campaigns" className="type-heavy">Campaigns Manager</a>
                                </p>
                            </div>
                            <div className="simpleflex__cell">
                                <a href="/leads" className="button ui-normal-button type-no-break">
                                    View All Leads
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
