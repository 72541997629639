import styled from 'styled-components'

export const Wrapper = styled.div`
    /* padding: 25px 0; */
    padding: 1.548387097rem 2.064516129rem;
`

export const ContentWrapper = styled.div`
    display: grid;
    row-gap: 30px;
`

export const Divider = styled.div`
    border-bottom: 1px solid #d0e0f2;
`

export const NewNumberWrapper = styled.div`
    display: grid;
    column-gap: 30px;
    grid-template-columns: 2fr 2fr 2fr 1fr 0.5fr;
`

export const DeleteContainer = styled.div`
    display: flex;
    align-items: flex-end;
    padding: 0 0 10px 0;
    justify-content: flex-end;
`
