import React, { useEffect, useState, useRef } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import AdScheduler from '../common/AdScheduler';
import AuthService from '../service/AuthService';
import Checkbox from '../common/components/form_elements/Checkbox';
import ContractorService from '../service/ContractorService';
import CoreSection from './CoreSection';
import Input from '../common/components/form_elements/Input';
import PhoneEditor from './PhoneEditor';
import RadioGroup from '../common/components/form_elements/RadioGroup';
import Sidebar from './sidebar';
import SurveyQuestion from './SurveyQuestion';
import TermsAndConditions from '../client/TermsAndConditions';
import TextArea from '../common/components/form_elements/TextArea';
import Tooltip from '../common/Tooltip';

import industryServices from './industry-services.json';

const StepSix = (props) => {
	const { finalStepState, setFinalStepState } = props.stepProps;
	const [companyName, setCompanyName] = useState('');
	const [contractorIndustries, setContractorIndustries] = useState([]);
	
	const seedSchedule = {
		monday: { start: '24h', end: '24h', },
		tuesday: { start: '24h', end: '24h', },
		wednesday: { start: '24h', end: '24h', },
		thursday: { start: '24h', end: '24h', },
		friday: { start: '24h', end: '24h', },
		saturday: { start: '24h', end: '24h', },
		sunday: { start: '24h', end: '24h', },
	};
	
	const finalStepStateRef = useRef(finalStepState);
	const setFinalStepStateRef = useRef(setFinalStepState);
	
	const additionalBusinessInfoEditorRef = useRef();
	
	useEffect(() => {
		const contractorService = new ContractorService();
		
		contractorService.getIndustries(AuthService.contractorId).then(result => {
			setContractorIndustries(result.map(el => el.industry_name));
		});
		
		contractorService.getOnboardingBusinessInfo(AuthService.contractorId).then(result => {
			const _stepState = {...finalStepStateRef.current};
			if (result['caller_id_preference'] === '9') {
				_stepState.callerId = "Show the Caller ID of the phone lead";
			} else {
				_stepState.callerId = "Show the SD Lead Caller ID Number (512) 877-3656 as Displayed Caller ID";
			}
			_stepState.phoneNumber = result['phone_number'];
			setFinalStepStateRef.current(_stepState);
			setCompanyName(result['company_name']);
		});
	}, [finalStepStateRef, setFinalStepStateRef]);
	
	const launchTermsPopup = () => {
		props.updateModalContent(
			<TermsAndConditions
				whiteLabelPartnership={props.whiteLabelPartnership}
				inAccountActivation={true}
			/>,
			props.whiteLabelPartnership.white_label_name + " Advertising Program Terms",
		);
	};
	
	return (
		<div className="onboarding-contents page-width">
			<Sidebar step={6} {...props} />
			
			<div className="onboarding-main-column">
				<CoreSection extraClasses="no-margin-bottom">
					<p className="type-normal-subhead type-narrow-line-height spacing-24-bottom">
						While we work with many types of businesses across the US and Canada, Service Direct is not for everyone and we need to make sure we’re a good fit for each other.
					</p>
					
					<hr />
					
					<h3 className="type-large-subhead type-heavy spacing-24">
						Your Company
					</h3>
					
					<div className="spacing-24-bottom">
						<SurveyQuestion
							name="contact_time_zone"
							question="Select your company time zone"
							answers={[
								{ label: "Hawaii / Aleutian" },
								{ label: "Alaska" },
								{ label: "Pacific" },
								{ label: "Mountain" },
								{ label: "Central" },
								{ label: "Eastern" },
								{ label: "Atlantic" },
								{ label: "Newfoundland" },
							]}
							onChange={(e) => {
								const _state = {...finalStepState};
								_state.contactTimeZone = e[0];
								setFinalStepState(_state);
							}}
						/>
					</div>
					
					<div className="spacing-24-bottom">
						<SurveyQuestion
							name="years_in_business"
							question="How long has your company been in business?"
							answers={[
								{ label: "Less than 1 year" },
								{ label: "1-5 years" },
								{ label: "6-9 years" },
								{ label: "10+ years" },
							]}
							onChange={(e) => {
								const _state = {...finalStepState};
								_state.yearsInBusiness = e[0];
								setFinalStepState(_state);
							}}
						/>
					</div>
					
					<div className="spacing-24-bottom">
						<SurveyQuestion
							name="current_marketing"
							question="Previous marketing efforts for your company"
							multiselect={true}
							answers={[
								{ label: "Direct Mail" },
								{ label: "Directory Sites" },
								{ label: "Email Marketing" },
								{ label: "Google Local Services" },
								{ label: "Lead Generation Services (Porch, Thumbtack, etc.)" },
								{ label: "Newsletters" },
								{ label: "No Previous Marketing", internalValue: "No Current Marketing" },
								{ label: "Organic Search" },
								{ label: "Other" },
							]}
							onChange={(e) => {
								const _state = {...finalStepState};
								_state.currentMarketing = e;
								setFinalStepState(_state);
							}}
						/>
					</div>
					
					<hr />
					
					<h3 className="type-large-subhead type-heavy spacing-24">
						Billable Lead Type by Service Category
					</h3>
					
					<p className="type-large-body type-narrow-line-height spacing-12-bottom">
						Leads are Billable if they are seeking services typically provided by your Service Categories.
					</p>
					
					{contractorIndustries.map(industry => 
						industryServices[industry] && (
							<div className="spacing-24-bottom" key={`Billable Leads for ${industry}`}>
								<h4 className="type-normal-subhead type-heavy">
									Billable Leads for {industry}
								</h4>
								<div className="type-large-body type-narrow-line-height">
									{industryServices[industry].filter(service => service.core).map((service, idx, self) => {
										return (
											<React.Fragment key={`service-${idx}`}>
											{service.name}
											{(idx < self.length - 1) && ' • '}
											</React.Fragment>
										);
									})}
									
									{(industryServices[industry].filter(service => !service.core).length > 0) && (
										<>
										{' '}
										<Tooltip
											content={<>
												<h3 className="type-large-body type-heavy">Billable Leads for {industry}</h3>
												<ul className="type-normal-body spacing-10-top">
													{industryServices[industry].map(service => <li key={`service ${service.name}`}>{service.name}</li>)}
												</ul>
											</>}
											position="bottom"
											anchor="View All"
										/>
										</>
									)}
								</div>
							</div>
					))}
					
					<hr />
					
					<h3 className="type-large-subhead type-heavy spacing-24">
						Campaign Schedule
					</h3>
					
					<p className="type-large-body type-narrow-line-height spacing-12-bottom">
						The Campaign Schedule is the time of day during which your Campaigns are actively competing for Leads.
					</p>
					
					<p className="type-large-body type-narrow-line-height spacing-12-bottom">
						If you’d like the highest possible Lead Volume, then leave the Campaign Ad Schedule 24/7. But, you can also limit it to specific times and days, resulting in lower Lead Volume.
					</p>
					
					<div className="spacing-40">
						<AdScheduler
							seedSchedule={seedSchedule}
							context={'content'}
							updateScheduleState={(scheduleData) => {
								const _state = _cloneDeep(finalStepState);
								if (!_isEqual(_state.schedule, scheduleData)) {
									_state.schedule = scheduleData;
									setFinalStepState(_state);
								}
							}}
							updateScheduleTextState={(scheduleText) => {
								const _state = _cloneDeep(finalStepState);
								if (_state.scheduleText !== scheduleText) {
									_state.scheduleText = scheduleText;
									setFinalStepState(_state);
								}
							}}
						/>
					</div>
					
					<p className="type-large-body type-narrow-line-height spacing-24-bottom">
						<b>Note:</b> The Campaign Schedule can be edited on a per Campaign basis in your mySD Campaigns Manager.
					</p>
					
					<hr />
					
					<h3 className="type-large-subhead type-heavy spacing-24">
						Phone Setup
					</h3>
					
					<p className="type-large-body type-narrow-line-height spacing-12-bottom">
						<b>The #1 indicator of success within our platform</b> is how well you answer the phone for incoming Leads and how well you can convert those into Booked Appointments.
					</p>
					<p className="type-large-body type-narrow-line-height spacing-24-bottom">
						<b>Missed calls are considered Billable Leads</b> because there is no way for our team to verify the intent of a call without it being answered and recorded.
					</p>
					
					<h4 className="type-normal-subhead type-heavy">
						Forwarding Phone Number
					</h4>
					<p className="type-large-body type-narrow-line-height">
						Forwarding Number that Leads from Service Direct should ring through to:
					</p>
					
					<PhoneEditor
						phoneNumber={finalStepState.phoneNumber}
						updatePhoneNumber={(phoneNumber) => {
							const _state = {...finalStepState};
							_state.phoneNumber = phoneNumber;
							setFinalStepState(_state);
						}}
						extraClass="spacing-12"
					/>
					
					<p className="type-large-body type-narrow-line-height spacing-24-bottom">
						For cell phones, be sure that Spam Blockers are turned off to not accidentally block Service Direct Leads.
					</p>
					
					<h4 className="type-normal-subhead type-heavy">
						Displayed Caller ID Preference
					</h4>
					
					<p className="type-large-body type-narrow-line-height spacing-12-bottom">
						You can choose the Caller ID information of the incoming caller, or you may choose to see an SD Tracking Number if you want to quickly recognize a Service Direct sourced caller.
					</p>
					
					<RadioGroup
						label="Caller ID"
						showLabel={false}
						name="callerId"
						vertical={true}
						isClearable={false}
						checkedOption={finalStepState.callerId}
						options={[
							{
								"value": "Show the Caller ID of the phone lead",
								"label": <span className="type-large-body">Show Phone Lead’s Caller ID as Displayed Caller ID</span>
							},
							{
								"value": "Show the SD Lead Caller ID Number (512) 877-3656 as Displayed Caller ID",
								"label": <span className="type-large-body">Show SD Lead Caller ID Number, (512) 877-3656 as Displayed Caller ID</span>
							}
						]}
						onChange={(name, value) => {
							const _state = {...finalStepState};
							_state.callerId = value;
							setFinalStepState(_state);
						}}
					/>
					
					<hr />
					
					<h3 className="type-large-subhead type-heavy spacing-24">
						Notes or Questions
					</h3>
					
					<p className="type-large-body type-narrow-line-height spacing-12-bottom">
						Please include any additional business info or questions for our team to review:
					</p>
					
					<div className="spacing-24-bottom">
						{/* Change state handling */}
						<TextArea
							name="additional-business-info"
							value={finalStepState.additionalBusinessInfo}
							onChange={ (event) => {
								const _state = {...finalStepState};
								_state.additionalBusinessInfo = event.target.value
								setFinalStepState(_state);
							}}
							inputRef={additionalBusinessInfoEditorRef}
						/>
					</div>
					
					<hr />
					
					<h3 className="type-large-subhead type-heavy spacing-24">
						Certification Statement
					</h3>
					
					<p className="type-large-body type-narrow-line-height spacing-24-bottom">
						I agree to the following, as outlined in the <button className="type-heavy type-large-body type-blue button-clean" onClick={launchTermsPopup}>Service Direct Terms & Conditions</button>.
					</p>
					
					<div className="spacing-40-bottom">
						<Input name="signature"
						   	type="text"
						   	id="signature"
						   	label={null}
						   	value={finalStepState.signature}
						   	placeholder="Type Your Full Name"
						   	onChange={(event) => {
							   	const _state = {...finalStepState};
							   	_state.signature = event.target.value;
							   	setFinalStepState(_state);
						   	}}
						/>
					</div>
					
					<div className="spacing-24-bottom">
						<Checkbox
							name="certification_1"
							label={<>I certify that <span className="type-heavy">{companyName}</span> is in compliance with all applicable laws, codes, and regulations.</>}
							onChange={(e) => {
								const _state = {...finalStepState};
								_state.certification1 = e.target.checked;
								setFinalStepState(_state);
							}}
						/>
					</div>
					<div className="spacing-24-bottom">
						<Checkbox
							name="certification_2"
							label={<>I certify that <span className="type-heavy">{companyName}</span> has and will maintain all proper bonding, licensing, and insurance as required by all applicable laws, codes, and regulations for all services provided.</>}
							onChange={(e) => {
								const _state = {...finalStepState};
								_state.certification2 = e.target.checked;
								setFinalStepState(_state);
							}}
						/>
					</div>
					<div>
						<Checkbox
							name="certification_3"
							label="I have reviewed and consent to all additional provisions of the Service Direct Terms & Conditions."
							onChange={(e) => {
								const _state = {...finalStepState};
								_state.certification3 = e.target.checked;
								setFinalStepState(_state);
							}}
						/>
					</div>
				</CoreSection>
			</div>
		</div>
	);
};

export default StepSix;