import React from 'react'
import Button from '../../../../common/components/form_elements/Button'
import { ContentWindowScroll } from '../../../../common/Modal'
import NewAffiliateConfirmationTable from './NewAffiliateConfirmationTable'
import { ActionsWrapper } from './styles'

export default ({
    affiliate,
    updates,
    onSave,
    onGoBack,
    columns,
    adNetworks,
    isNewAffiliate = false,
}) => {
    const saveButtonText = isNewAffiliate ? "Save And Continue" : "Save Changes"
    return (
        <div>
            <ContentWindowScroll>
                <NewAffiliateConfirmationTable
                    affiliate={affiliate}
                    updates={updates}
                    columns={columns}
                    adNetworks={adNetworks}
                    isNewAffiliate={isNewAffiliate}
                />
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button
                    onClick={() => onGoBack(affiliate, updates, isNewAffiliate)}
                    variant="text"
                >
                    <span className="type-heavy">Go Back</span>
                </Button>
                <Button onClick={() => onSave(affiliate, updates, isNewAffiliate)}>
                    {saveButtonText}
                </Button>
            </ActionsWrapper>
        </div>
    )
}
