import React, { useEffect, useState } from 'react'
import LeadService from '../../../../service/LeadService'
import LeadReviewBlock from './LeadReviewBlock'

const LeadReviewBlockLoadable = ({ leadId, ...rest }) => {
    const [wrongNumberReasons, setWrongNumberReasons] = useState([])
    const [leadWrongNumberReasons, setLeadWrongNumberReasons] = useState([])

    const leadService = new LeadService()
    useEffect(() => {
        leadService
            .getWrongNumberReasons()
            .then((response) => {
                setWrongNumberReasons(response.data)
                return leadService.getWrongNumberReasonsForLead(leadId)
            })
            .then((response) => {
                setLeadWrongNumberReasons(response.data)
            })
            .catch((err) => console.error('error getting wrong number reasons', err))
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSaveLeadWrongNumberReasons = (vals) => {
        return leadService.saveWrongNumberReasons(leadId, vals)
        .then(() => {
            return leadService.getWrongNumberReasons()
        })
        .then((response) => {
            setWrongNumberReasons(response.data)
            return leadService.getWrongNumberReasonsForLead(leadId)
        })
        .then((response) => {
            setLeadWrongNumberReasons(response.data)
        })
        .catch((err) => console.error('error getting wrong number reasons', err))
    }

    return (
        <LeadReviewBlock
            wrongNumberReasons={wrongNumberReasons}
            leadWrongNumberReasons={leadWrongNumberReasons}
            onSaveLeadWrongNumberReasons={handleSaveLeadWrongNumberReasons}
            {...rest}
        />
    )
}

export default LeadReviewBlockLoadable
