import React, { Component } from 'react';

import CreditCardSection from './CreditCardSection';
import MonthlyBudgetAlertSection from './MonthlyBudgetAlertSection';
import CurrentBalanceSection from './CurrentBalanceSection';
import StatementsSection from './StatementsSection';
import CentralizedBillingNote from './CentralizedBillingNote';

import MajorAlerts from '../../common/MajorAlerts';
import Modal from '../../common/Modal';

import PropTypes from 'prop-types';
import BillingSummaryService from "../../service/BillingSummaryService";
import StatementService from "../../service/StatementService";
import {errorHandler} from "../../Requests";
import AuthService from "../../service/AuthService";

export default class BillingCenterView extends Component {
    constructor(props) {
        super(props);

        this.state  = {
            unpaidInvoices: [],
            creditCards: [],
            modalContent: undefined,
            modalHeader: undefined,
            modalWidth: undefined,
            modalFlatBottom: undefined,
            balanceSummary: {
                current_balance: null,
                lead_cost: null,
                lead_credit: null,
                // reverse_credit: null,
                threshold: 0,
                unpaid_statement: null,
            },
            //
            // We increment this to force <MajorAlerts> to re-render, to e.g. hide Alerts that are no longer true.
            //
            majorAlertsRefreshCounter: 0,
        };

        this.billingSummaryService = new BillingSummaryService();
        this.statementService = new StatementService();
    }
    
    updateModalContent = (modalContent, modalHeader, modalWidth, modalFlatBottom) => {
        this.setState({ modalContent: modalContent,
                        modalHeader: modalHeader,
                        modalWidth: modalWidth,
                        modalFlatBottom: modalFlatBottom });
    };

    /**
     * Not used in this component, but rather passed to a child component so
     * that its various methods that update credit cards write them back to
     * this component's state.creditCards array as well.
     * @param stateObj
     */
    setContainerComponentState = (stateObj) => {
        this.setState(stateObj);
    };

    /**
     * Returns the primary credit card from this.state.creditCards, if there is
     * one.
     * @return {null | object}
     */
    getPrimaryCard = () => {
        let primary = null;

        for (let n = 0; n < this.state.creditCards.length; n++) {
            if (this.state.creditCards[n].is_primary == 1) {
                primary = {...this.state.creditCards[n]};
            }
        }

        return primary;
    };

    componentDidMount() {
        this.getCurrentBalanceSummary();
        this.statementService.getUnpaidStatements()
            .then((unpaidInvoices) => this.setState({unpaidInvoices}))
    }

    componentWillUnmount() {
        this.billingSummaryService.cancelSignal.cancel();
        this.statementService.cancelSignal.cancel();
    }

    /**
     * Gets the current balance summary
     */
    getCurrentBalanceSummary = () => {
        let contractorId = AuthService.getItem('contractorId');

        this.billingSummaryService.get(contractorId)
            .then((summary) => {
                let balanceSummary = { ...this.state.balanceSummary };

                for (let key in summary) {
                    if (summary.hasOwnProperty(key)) {
                        balanceSummary[key] = summary[key];
                    }
                }

                this.setState({balanceSummary});
            })
            .catch(errorHandler);
    };

    /**
     * Increment the refresh counter to force <MajorAlerts> to refresh its data
     */
    refreshMajorAlerts = () => {
        this.setState({majorAlertsRefreshCounter: this.state.majorAlertsRefreshCounter + 1});
    };

    render() {

        let primaryCard = this.getPrimaryCard(),
            primaryCardLastFour = primaryCard ? primaryCard.last_four : "";

        return (
            <div className="page-width-wide">
                <Modal
                    content={this.state.modalContent}
                    header={this.state.modalHeader}
                    width={this.state.modalWidth}
                    flatBottom={this.state.modalFlatBottom}
                    updateModalContent={this.updateModalContent}
                />

                <MajorAlerts refreshCounter={this.state.majorAlertsRefreshCounter} />

                <div className="row padding-50">
                    <div className="wide-col-wrapper">
                        <div className="wide-format-col">
                            <h2 className="type-normal-headline type-heavy">
                                Billing Center
                            </h2>
                        </div>
                        <div className="clear-block"/>
                    </div>
                </div>
            {
             this.props.partnership.centralized_billing == 1
                ? <CentralizedBillingNote partnership={this.props.partnership}/>
                : <>
                    <CreditCardSection
                        setContainerComponentState={this.setContainerComponentState}
                        creditCards={this.state.creditCards}
                        updateModalContent={ this.updateModalContent }
                        updateMessageBlocks={ this.props.updateMessageBlocks }
                    />
                    <div className="row padding-50-bottom">
                        <div className="wide-col-wrapper">
                            <CurrentBalanceSection
                                balanceSummary={this.state.balanceSummary}
                                updateModalContent={ this.updateModalContent }
                                updateMessageBlocks={ this.props.updateMessageBlocks }
                                whiteLabelPartnership={ this.props.whiteLabelPartnership }
                            />
                            <MonthlyBudgetAlertSection
                                updateMessageBlocks={ this.props.updateMessageBlocks }
                            />
                            <div className="clear-block"/>
                        </div>
                    </div>
                    <StatementsSection
                        updateBalanceSummary={this.getCurrentBalanceSummary}
                        primaryCardLastFour={primaryCardLastFour}
                        updateMessageBlocks={ this.props.updateMessageBlocks }
                        updateModalContent={ this.updateModalContent }
                        refreshMajorAlerts={ this.refreshMajorAlerts }
                    />
                </>
            }
            </div>
        )
    }
}

BillingCenterView.propTypes = {
    contractor: PropTypes.object,
    payStatement: PropTypes.func,    
    statements: PropTypes.array,
};
