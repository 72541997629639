import React, {Component} from 'react';
import {formatCurrency} from "../../Util";
import {MoneyInput} from "../../common/components/form_elements/MoneyInput";
import {post} from "../../Requests";
import axios from "axios";
import AuthService from "../../service/AuthService";
import LoadingGif from '../../common/components/LoadingGif';

export default class BillingThresholdChangeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            errorMessage: ''
        };

        this.thresholdAmount = React.createRef();
        this.cancelSignal = axios.CancelToken.source();
    }

    submitThresholdChangeRequest = () => {
        let threshold_amount = parseFloat(this.thresholdAmount.current.value),
            url = "threshold_change_request/";

        if (!isFinite(threshold_amount) || threshold_amount <= 0) {
            this.setState({errorMessage: 'Threshold amount must be greater than 0.'});
            return;
        }

        this.setState({submitting: true});

        post(url, {threshold_amount}, this.cancelSignal.token)
            .then((response) => {
                let messageType = response.status === 200
                    ? "success"
                    : "error",
                    message;

                if (response.status === 200) {
                    this.close();
                    message = response.data.message;
                }
                else {
                    let errors = response.data.errors;
                    message = errors[0].message;
                }

                this.props.updateMessageBlocks(message, messageType);
            })
            .finally(() => this.setState({submitting: false}));
    };

    /**
     * removes the threshold error message if needed
     */
    handleThresholdAmountChange = () => {
        if (this.state.amount >= 0) {
            this.setState({errorMessage: ''});
        }
    };

    close = () => this.props.updateModalContent();

    render() {
        const {currentThreshold} = this.props
        const thresholdDisplay = currentThreshold ? formatCurrency(currentThreshold, 0) : <LoadingGif/>
        return (
            <>
                <p className="type-normal-body">
                    You are billed each time your Campaign reaches your Billing
                    Threshold, which is currently set to{' '}
                    <b>{thresholdDisplay}</b>. Higher
                    Thresholds extend more credit to you before we are paid
                    back for our ad spend on your Campaigns, thus requiring
                    more trust. Lower Thresholds are sometimes preferred for
                    more frequent billing intervals.
                </p>
                <p className="type-normal-body">&nbsp;</p>
                <p className="type-normal-body">
                    Please input the requested Billing Threshold below and we
                    will review it compared to your payment performance history.
                </p>
                <p className="type-normal-body">&nbsp;</p>
                <p className="type-normal-body spacing-20-bottom">
                    <a href={AuthService.isMarketplace
                        ? "https://support.servicedirect.com/how-you-are-billed"
                        : "https://support.servicedirect.com/select/how-you-are-billed"}
                       className="type-heavy" target="_blank">
                        Learn more about how we set Billing Thresholds here
                    </a>
                </p>
                <div className="popup__form__row spacing-30-bottom">
                    <div className="popup__form__cell popup__form__cell__50">
                        <label htmlFor="popup-threshold"
                               className="type-small-body type-heavy type-narrow-line-height spacing-5-bottom">
                            Requested Billing Threshold
                        </label>
                        <MoneyInput
                            name="threshold_amount"
                            inputRef={this.thresholdAmount}
                            errorMessage={this.state.errorMessage}
                            onChange={this.handleThresholdAmountChange}
                            uncontrolled={true}
                        />
                    </div>
                </div>
                <div className="popup__form__gray-bar padding-24 spacing-50-top">
                    <div className="popup__form__row popup__form__row__slam-right">
                        <div className="popup__form__cell">
                            <span role="button"
                                  onClick={this.close}
                                  className="type-small-body type-heavy"
                            >
                                Cancel
                            </span>
                        </div>
                        <div className="popup__form__cell">
                            <button
                                disabled={this.state.submitting}
                                onClick={this.submitThresholdChangeRequest}
                                className="button ui-normal-button"
                            >
                                Submit Request
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
