import React from 'react'

export const BasicInsights = () => {
    return (
        <div className="type-normal-body" style={{ minWidth: '400px' }}>
            <span className="type-black type-heavy">Basic Insights</span>
            <br />
            <br />
            <span className="type-black type-heavy">Call Answered</span>
            <br />
            <ul>
                <li>Definition: Was the call answered by a human?</li>
                <li>
                    Programming note: Answering this question completes the Call Answered
                    radio buttons in the Lead Progress and Revenue section.
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">Service Needed</span>
            <br />
            <ul>
                <li>
                    Definition: Is the caller requesting a service need that the client
                    offers and is advertised in that campaign?
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">Booked Appointment</span>
            <br />
            <ul>
                <li>
                    Definition: Does the recording capture a confirmed booked appointment
                    between the caller and the CSR? (Calls to call centers that only pass
                    along collected information are not Booked Appointments.) Do not
                    change the Booked Appointment - Yes status if a client has selected
                    that even though it's not recorded on the call.
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">Appointment Lost Reason</span>
            <br />
            <ul>
                <li>This only applies if the call is billable and call is answered</li>
                <li>
                    <span className="type-black type-heavy">Caller Hung Up</span>
                    <ul>
                        <li>
                            Definition: The caller broke off the call or conversation
                            prior to booking the appointment.
                        </li>
                    </ul>
                </li>
                <li>
                    <span className="type-black type-heavy">
                        Consult Significant Other
                    </span>

                    <ul>
                        <li>
                            Definition: The caller declines to book an appointment
                            indicating that they will need to check with their significant
                            other first.
                        </li>
                    </ul>
                </li>
                <li>
                    <span className="type-black type-heavy">CSR Failed to Convert</span>
                    <ul>
                        <li>
                            Definition: The CSR fails to qualify the call, does not collect 
                            the appropriate information from the caller on the call, has a 
                            lack of knowledge of services offered while talking to the 
                            customer, or has overall unprofessional CSR interaction which 
                            leads the caller to fail to book.
                        </li>
                    </ul>
                </li>
                <li>
                    <span className="type-black type-heavy">No Ability to Book</span>
                    <ul>
                        <li>
                            Definition: The CSR is unable to complete the booking process on 
                            the initial call from the potential customer due to lack of 
                            schedule access or authorization. (ie. Call centers, after hours 
                            receptionists, CSRs without current schedule access).
                        </li>
                    </ul>
                </li>
                <li>
                    <span className="type-black type-heavy">Price</span>
                    <ul>
                        <li>
                            Definition: The caller declines an appointment because the
                            price does not match their expectations.
                        </li>
                    </ul>
                </li>
                <li>
                    <span className="type-black type-heavy">Scheduling Issue</span>
                    <ul>
                        <li>
                            Definition: The caller is unable to book because the available
                            appointment times do not meet their needs.
                        </li>
                    </ul>
                </li>
                <li>
                    <span className="type-black type-heavy">Service Not Offered</span>
                    <ul>
                        <li>
                            Definition: The appointment cannot be booked because the
                            company does not offer that service. (Still billable by
                            Service Direct)
                        </li>
                    </ul>
                </li>
                <li>
                    <span className="type-black type-heavy">Will Call Back</span>
                    <ul>
                        <li>
                            Definition: The caller indicates they need more time prior to
                            booking and will reach out to the company at a later date.
                        </li>
                    </ul>
                </li>
                <li>
                    <span className="type-black type-heavy">Other</span>
                </li>
            </ul>
            <br />
        </div>
    )
}

export const CSRScorecard = () => {
    return (
        <div className="type-normal-body" style={{ minWidth: '400px' }}>
            <span className="type-black type-heavy">CSR Scorecard</span>
            <br />
            <br />
            <span className="type-black type-heavy">
                Greet caller using company name?
            </span>
            <br />
            <ul>
                <li>
                    Definition: The greeting the CSR uses should include the company name
                    in order to verify to the caller what company they have reached.
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">
                Greet caller with offer to help?
            </span>
            <br />
            <ul>
                <li>
                    Definition: The CSR should ask something similar to “How can I help
                    you today?” to show that they are available to assist the caller with
                    their issue.
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">Show empathy?</span>
            <br />
            <ul>
                <li>
                    Definition: The CSR should appropriately respond to the customer’s
                    challenges. This could be simple phrases such as “Oh, no!” or “I’m
                    sorry to hear that.”
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">Collect customer info?</span>
            <br />
            <ul>
                <li>
                    Definition: The CSR should acquire all the necessary information to
                    reach out to the customer again once the call is finished. This could
                    include name, phone number, address, or email address.
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">
                Confirm property type/service area/owner?
            </span>
            <br />
            <ul>
                <li>
                    Definition: While gathering information about the service need, the
                    CSR should verify the details about the specific property type or
                    service area or ownership prior to booking the appointment. The
                    specifics of this will vary depending on the industry.
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">Get details about service need?</span>
            <br />
            <ul>
                <li>
                    Definition: The CSR should collect all pertinent information about the
                    type of service that's needed from their company.
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">Ask for the appointment?</span>
            <br />
            <ul>
                <li>
                    Definition: The CSR should request an appointment from the caller
                    instead of waiting for the customer to ask first.
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">
                Overcome objections or sell value?
            </span>
            <br />
            <ul>
                <li>
                    Definition: During the call, the CSR should address any concerns the
                    caller may have about hiring them and state reasons why the caller
                    should select their company to meet their needs.
                </li>
            </ul>
            <br />
            <span className="type-black type-heavy">
                Confirm order/address/time/date?
            </span>
            <br />
            <ul>
                <li>
                    Definition: The CSR should reiterate the details of the appointment
                    prior to ending the call.
                </li>
            </ul>
            <br />
        </div>
    )
}
