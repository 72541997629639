import React, { Component } from 'react';
import {formatCurrency} from "../../Util";

export default class PaymentConfirmationModal extends Component {

    //
    // TODO create a ProcessingButton component to house all this logic
    //
    BUTTON_STATES = {
        ENABLED: 1, /* button enabled */
        DISABLED: 2, /* button disabled */
        SAVING: 3, /* button disabled */
        DISABLED_SAVE: 4 /* button disabled with "Save" label */
    };

    /**
     * returns an object representing the button's state based on its state id
     * @param {number} stateId - the button state id
     * @return {object}
     *
     * TODO create a ProcessingButton component to house all this logic
     */
    parseButtonState = stateId => {
        switch (stateId) {
            case this.BUTTON_STATES.ENABLED:
                return {
                    label: 'Confirm Payment',
                    disabled: false
                };

            case this.BUTTON_STATES.SAVING:
                return {
                    label: 'Charging...',
                    disabled: true,
                    saving: true
                };

            // //
            // // Not used
            // //
            // case this.BUTTON_STATES.DISABLED:
            //     return {
            //         label: 'Payment Done',
            //         disabled: true
            //     };
            //
            // // case this.BUTTON_STATES.DISABLED_SAVE:
            // //
            // // Not used
            // //
            // default:
            //     return {
            //         label: 'Confirm Payment',
            //         disabled: true
            //     };

                // no default
        }
    };




    constructor(props) {
        super(props);
        this.state = {
            confirmPaymentButtonState: this.BUTTON_STATES.ENABLED
        };
    };

    /**
     * uses this.payStatement function
     */
    payNowClick = () => {
        this.setState({confirmPaymentButtonState: this.BUTTON_STATES.SAVING});
        this.props.payStatement(() => {
            this.setState({confirmPaymentButtonState: this.BUTTON_STATES.ENABLED})
        });
    };


    render() {

        let statement = this.props.statement,
            statementId = statement.original_invoice_id,
            unpaid = formatCurrency(statement.amount_total - statement.amount_credited - statement.amount_paid),
            lastFour = this.props.primaryCardLastFour,
            buttonClasses = 'button ui-normal-button',
            parsedButtonState = this.parseButtonState(this.state.confirmPaymentButtonState);

        //
        // TODO create a ProcessingButton component to house all this logic
        //
        if (parsedButtonState.saving) {
            buttonClasses += ' button__green';
        }

        return (
            <>
                <p className="type-large-body">
                    Confirmation - Pay {unpaid} for Statement {statementId}{' '}
                    with card ending in {lastFour}?
                </p>
                <div className="popup__form__gray-bar padding-24 spacing-50-top">
                    <div className="popup__form__row popup__form__row__slam-right">
                        <div className="popup__form__cell">
                            <span role="button"
                                onClick={() => this.props.updateModalContent()}
                                className="type-small-body type-heavy type-blue"
                            >
                                Cancel Payment
                            </span>
                        </div>
                        <div className="popup__form__cell">
                            <button
                                className={buttonClasses}
                                onClick={this.payNowClick}
                                disabled={parsedButtonState.disabled}
                            >
                                {parsedButtonState.label}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}