import React, {Component} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import queryString from "query-string";

import NavigationFooter from './navigation-footer';
import SignupMarketplaceService from '../service/SignupMarketplaceService';
import {errorHandler} from "../Requests";
import {Input} from "../common/components/form_elements/Input";
import Validator from '../lib/Validator';
import {regExpEscape, toCamel} from "../Util";
import {MockButton} from "../common/MockButton";
import PrivacyPolicy from "../client/PrivacyPolicy";
import AuthService from "../service/AuthService";
import {PartnershipDomains} from "../common/StaticValues";
import GoogleTagManagerService from "../service/GoogleTagManagerService";

export default class StepOne extends Component {
    checkingEmail = false;

    // default parent id of Service Direct
    parentId: number = 2882;

    constructor(props) {
        super(props);

        // errorMessage having value means there's an error
        this.state = {
            data: {
                companyName: {
                    value: '',
                    errorMessage: ''
                },
                companyWebsite: {
                    value: '',
                    errorMessage: ''
                },
                firstName: {
                    value: '',
                    errorMessage: ''
                },
                lastName: {
                    value: '',
                    errorMessage: ''
                },
                email: {
                    value: '',
                    errorMessage: ''
                },
                password: {
                    value: '',
                    errorMessage: ''
                },
                recaptcha: {
                    value: '',
                    errorMessage: ''
                }
            },
            processing: false
        };


        let query = queryString.parse(this.props.location.search);
        // if the parent id is passed in the URL, use it
        if (query.parent_id) {
            this.parentId = query.parent_id;
        }
        else {
            // otherwise, find the parent contractor id based on the app domain
            let index;
            for (index = 0; index < PartnershipDomains.length; index++) {
                // check if the current location ends with the domain and an optional port for dev environments
                let pattern = new RegExp('.*' + regExpEscape(PartnershipDomains[index].text) + '(:\\d+)?$');
                if (window.location.host.match(pattern)) {
                    this.parentId = PartnershipDomains[index].value;
                    break;
                }
            }
        }

        this.recaptchaKey = process.env.REACT_APP_RECAPTCHA;
        this.recaptchaRef = React.createRef();
        this.signupMarketplaceService = new SignupMarketplaceService();
    }

    componentWillUnmount() {
        this.signupMarketplaceService.cancelSignal.cancel();
    }

    mockData = (event) => {
        event.preventDefault();

        let data: Object = {
                companyName: {
                    value: 'jt testing 4-11',
                    errorMessage: ''
                },
                companyWebsite: {
                    value: 'https://servicedirect.com/',
                    errorMessage: ''
                },
                firstName: {
                    value: 'jtt',
                    errorMessage: ''
                },
                lastName: {
                    value: 'testtt',
                    errorMessage: ''
                },
                email: {
                    value: 'john+' + (new Date()).getTime() + '@servicedirect.com',
                    errorMessage: ''
                },
                recaptcha: {
                    value: null,
                    errorMessage: ''
                }
            },
            queryVars = queryString.parse(this.props.location.search),
            assistingAdminUserId = 'assisting_admin_user_id' in queryVars && queryVars.assisting_admin_user_id;

        // populate password but not if Admin initiated signup flow
        if (!assistingAdminUserId) {
            data.password = {
                value: '123456',
                errorMessage: ''
            };
        }

        this.setState({
            data: data
        });
    }

    /**
     * returns an error message for the specific set of field name and its value; empty string when there's no error
     * @param {string} name - the fields name
     * @param {string} value - the fields value
     * @param {boolean} checkingEmail
     * @return {string}
     */
    getErrorMessage(name: string, value: string, checkingEmail: boolean) {
        switch (name) {
            case 'companyWebsite':
                return value && !Validator.validateUrl(value)
                    ? 'Invalid company website URL'
                    : '';

            case 'email':
                if (!value) {
                    return 'Email address is required';
                }

                if (checkingEmail) {
                    return 'Checking email availability';
                }

                if (!Validator.validateEmail(value)) {
                    return 'Invalid email address';
                }

                return '';

            case 'password':
                if (value.length >= 6) {
                    return '';
                }

                return value
                    ? 'Your password must be at least 6 characters long'
                    : 'This field is required';

            case 'recaptcha':
                if (!value) {
                    this.recaptchaRef.current.reset();
                }
            // falls through

            default:
                return !value || !value.trim()
                    ? 'This field is required'
                    : '';
        }
    }

    /**
     * updates regular input
     * TODO: this functionality can be shared between the different steps
     * @param {FocusEvent} event
     */
    handleChange = (event) => {
        let {data} = this.state;
        data[event.target.name].value = event.target.value;

        this.setState({data});
    };

    /**
     * handles validation of the field
     * @param {FocusEvent} event
     */
    handleBlurInput = (event) => {
        let {data} = this.state;
        data[event.target.name].errorMessage = this.getErrorMessage(event.target.name, event.target.value);
        this.setState({data});
    };

    /**
     * handles the special case for email blurring
     * different from regular input because we also check the server that the email is available
     */
    handleBlurEmail = () => {
        let {data} = this.state;
        let emailBeforeChange = data.email.value;

        data.email.errorMessage = this.getErrorMessage('email', data.email.value, this.checkingEmail);
        if (data.email.errorMessage) {
            this.setState({data});

            return;
        }

        this.checkingEmail = true;
        this.setState({data}, () => {
            this.signupMarketplaceService.checkEmailAvailability(data.email.value)
                .then((emailAvailable) => {
                    this.checkingEmail = false;

                    // case of checking an email, while the user changes the email again before the server responds
                    if (emailBeforeChange != this.state.data.email.value) {
                        return;
                    }

                    let {data} = this.state;
                    data.email.errorMessage = emailAvailable
                        ? ''
                        : 'This email address already in use';

                    if (emailAvailable) {
                        this.createHubSpotContact(emailBeforeChange);
                    }

                    this.setState({data});
                });
        });
    };

    handleRecaptchaChange = (value: string) => {
        let {data} = this.state;
        data.recaptcha.value = value;
        data.recaptcha.errorMessage = this.getErrorMessage('recaptcha', value);

        this.setState({data});
    }

    /**
     * builds the data that will be sent to the server
     * @return {object|boolean} the data object on success, or false on error
     */
    buildPostData = () => {
        let {data}: Object = this.state,
            result = {parentContractorId: this.parentId},
            keys = Object.keys(data),
            index,
            item,
            hasErrors: boolean = false,
            queryVars: Object = queryString.parse(this.props.location.search);

        if ('assisting_admin_user_id' in queryVars) {
            result.assisting_admin_user_id = queryVars.assisting_admin_user_id;
        }
        
        if (localStorage.getItem("mpSignupPromoCode") && localStorage.getItem("mpSignupPromoCode").length > 0) {
            result.promo_code = localStorage.getItem("mpSignupPromoCode");
        }

        for (index = 0; index < keys.length; index++) {
            item = data[keys[index]];

            if (result.assisting_admin_user_id && (keys[index] === 'password' || keys[index] === 'recaptcha')) {
                continue;
            }

            if (item.errorMessage) {
                hasErrors = true;
                continue;
            }

            if (item.errorMessage !== undefined) {
                item.errorMessage = this.getErrorMessage(keys[index], item.value, this.checkingEmail);
                if (item.errorMessage) {
                    hasErrors = true;
                    // console.log("found error = ", item.errorMessage, "for key", keys[index]);
                    continue;
                }
            }

            result[keys[index]] = item.value;
        }

        if (hasErrors) {
            this.setState({data});
            return false;
        }

        return result;
    };

    /**
     * creates a HS contact record using the given email address
     * NOTE: we run this on email blur so that we create the contact here BEFORE the API
     *  this is because we want the "hs_analytics_source" to have the "Direct traffic" value
     * @param {string} email - VALID email address
     */
    createHubSpotContact(email) {
        // verify that the user is NOT an admin
        if (AuthService.isAdmin) {
            return;
        }

        window._hsq = window._hsq || [];
        window._hsq.push(
            [
                'identify',
                {email: email}
            ]
        );
        window._hsq.push(['trackPageView']);
    }

    submit = (event) => {
        let queryVars = queryString.parse(this.props.location.search),
            assistingAdminUserId = 'assisting_admin_user_id' in queryVars && queryVars.assisting_admin_user_id;
        event.preventDefault();

        const data = this.buildPostData();
        if (!data) {
            console.log("found no data");
            return;
        }

        this.setState({processing: true});
        this.signupMarketplaceService.createAccount(data)
            .then((response) => {
                GoogleTagManagerService.updateDataLayer({event: "step1Complete"})
                if (assistingAdminUserId) {
                    // assisted flow? user is authed and goes straight to step 2
                    AuthService.clear();
                    AuthService.updateLoginData(response);
                    AuthService.assistingAdminUserId = assistingAdminUserId;
                    GoogleTagManagerService.updateDataLayer({assistingAdminUserId: assistingAdminUserId})
                    // this.props.history.push('/signup-marketplace/step-two');
                }
                else {
                    // we are here if we got a HTTP status of 200, transfer to confirmation "page"
                    AuthService.setItem('signupMarketplaceId', response.signupMarketplaceId);
                    AuthService.setItem('username', response.username);
                    this.props.history.push('/signup-marketplace/step-confirmation');
                }
            })
            .catch((error) => {
                let {data} = this.state;
                data.recaptcha.value = null;

                this.setState({processing: false, data});
                if (errorHandler(error)) {
                    let {data} = this.state;
                    if (error.response?.data?.parameter) {
                        data[toCamel(error.response.data.parameter)].errorMessage = error.response.data.message;
                    }
                    this.recaptchaRef.current.reset();
                    this.setState({data});
                }
            });
    };

    componentDidMount() {
        let queryVars = queryString.parse(this.props.location.search);
        if ('email' in queryVars) {
            this.setState({
                data: {
                    ...this.state.data,
                    email: {value: queryVars.email, errorMessage: ''}
                }
            });
        }
        // Identify this as clientJourneyStage = 'Marketplace Sign Up' (Step one is different from others because user
        // is not yet logged in, nor is there an MP Sign up record created)
        GoogleTagManagerService.updateDataLayer({clientJourneyStage: 'Marketplace Sign Up'})
    }

    render() {
        let queryVars = queryString.parse(this.props.location.search),
            assistingAdminUserId = 'assisting_admin_user_id' in queryVars && queryVars.assisting_admin_user_id;
        return <form onSubmit={this.submit}>
            <MockButton onClick={this.mockData}/>
            <h1 className="module__twothirdswidth type-large-subhead type-single-line module-header">
                Create Account
            </h1>
            <div className="module__contentbox module__twothirdswidth">
                <h2 className="type-normal-subhead type-heavy spacing-10-bottom type-centered">
                    Welcome! Let's start getting Leads for your business.
                </h2>
                <p className="type-normal-body spacing-50-bottom type-centered">
                    We take your{' '}
                    <button type="button" className="type-heavy type-normal-body type-blue button-clean"
                            onClick={() => this.props.updateModalContent(
                                <PrivacyPolicy whiteLabelPartnership={this.props.whiteLabelPartnership}/>,
                                this.props.whiteLabelPartnership.white_label_name + " Privacy Policy"
                            )}
                    >
                        privacy
                    </button>
                    {' '}
                    seriously. You can cancel at anytime.
                </p>
                <div className="form__row spacing-24-bottom">
                    <div
                        className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                        <Input name="companyName"
                               type="text"
                               id="signupCompanyName"
                               label="Company Name"
                               value={this.state.data.companyName.value}
                               onBlur={this.handleBlurInput}
                               onChange={this.handleChange}
                               errorMessage={this.state.data.companyName.errorMessage}
                               required={true}
                        />
                    </div>
                    <div className="form__cell form__cell__50 form__cell__100__mobile">
                        <Input name="companyWebsite"
                               type="text"
                               id="signupCompanyWebsite"
                               label="Company Website (optional)"
                               value={this.state.data.companyWebsite.value}
                               onChange={this.handleChange}
                               onBlur={this.handleBlurInput}
                               errorMessage={this.state.data.companyWebsite.errorMessage}
                        />
                    </div>
                </div>
                <div className="form__row spacing-24-bottom">
                    <div
                        className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                        <Input name="firstName"
                               type="text"
                               id="signupFirstName"
                               label="First Name"
                               value={this.state.data.firstName.value}
                               onBlur={this.handleBlurInput}
                               onChange={this.handleChange}
                               errorMessage={this.state.data.firstName.errorMessage}
                               required={true}
                        />
                    </div>
                    <div className="form__cell form__cell__50 form__cell__100__mobile">
                        <Input name="lastName"
                               type="text"
                               id="signupLastName"
                               label="Last Name"
                               value={this.state.data.lastName.value}
                               onBlur={this.handleBlurInput}
                               onChange={this.handleChange}
                               errorMessage={this.state.data.lastName.errorMessage}
                               required={true}
                        />
                    </div>
                </div>
                <div className="form__row spacing-24-bottom">
                    <div
                        className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                        <Input name="email"
                               type="email"
                               autoComplete="email"
                               id="signupEmail"
                               label="Email"
                               value={this.state.data.email.value}
                               onBlur={this.handleBlurEmail}
                               onChange={this.handleChange}
                               errorMessage={this.state.data.email.errorMessage}
                               required={true}
                        />
                    </div>
                    <div className="form__cell form__cell__50 form__cell__100__mobile">
                        {assistingAdminUserId
                            ? 'User will fill out Password on email confirmation'
                            : <Input name="password"
                                     type="password"
                                     autoComplete="new-password"
                                     id="signupPassword"
                                     label="Password"
                                     value={this.state.data.password.value}
                                     onBlur={this.handleBlurInput}
                                     onChange={this.handleChange}
                                     errorMessage={this.state.data.password.errorMessage}
                                     required={true}
                            />}
                    </div>
                </div>
                {!assistingAdminUserId &&
                <div className="form__row spacing-24-bottom">
                    <div>
                        <ReCAPTCHA
                            ref={this.recaptchaRef}
                            name="recaptcha"
                            sitekey={this.recaptchaKey}
                            onChange={this.handleRecaptchaChange}
                            onExpired={this.handleRecaptchaChange}
                            className={this.state.data.recaptcha.errorMessage && 'ui-text-field ui-alert'}
                        />
                    </div>
                </div>}
            </div>
            <NavigationFooter nextStep={this.submit} processing={this.state.processing}/>
        </form>;
    }
}
