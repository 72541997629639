import React from 'react';

import { 
    IconStarFilledSvg,
    IconExclamationTriangleSvg,
    IconFlagSvg,
    IconLeadReviewSvg,
} from '../../../common/Svgs';


const ControlsBlock = (props) => {
    let flagLeadModifiers = props.flagged ? 'checked' : '';
    let followUpModifiers = props.needsFollowUp ? 'checked' : '';
    let starModifiers = parseInt(props.starred, 10) ? 'checked' : '';
    
    let leadReviewModifiers = '';
    switch (props.leadReview) {
        case 'not in review':
            leadReviewModifiers = 'lead-review__not-in-review';
            break;
        case 'in review':
            leadReviewModifiers = 'lead-review__in-review';
            break;
        case 'in review submitted by client':
            leadReviewModifiers = 'lead-review__client-review';
            break;
        case 'completed':
            leadReviewModifiers = 'lead-review__completed-review';
            break;
        default:
            leadReviewModifiers = '';
            break;
    }
    
    const hubspotEnv = process.env.REACT_APP_HUBSPOT_ID;

    return (
        <div className="leadlog__controls__panel">
            <p className="ui-hide-full ui-hide-tablet type-large-body type-heavy type-single-line no-margin-top spacing-10-bottom">Label this Lead</p>
            {props.adminView && (
                <>
                    <span
                        id={ `lead_review_${props.leadId}` }
                        role={ (props.leadReview === 'not in review') ? "button" : '' }
                        className={ `control-toggle-link type-extra-small-body type-single-line type-right-side-bump ${leadReviewModifiers}` }
                        onClick={ (props.leadReview === 'not in review') ? props.onLeadReviewClick : (e) => null }
                    >
                        <span className="inline-icon inline-icon__middle inline-icon__22">
                            { IconLeadReviewSvg }
                        </span>{' '}
                        LEAD REVIEW
                    </span>
                    <span
                        className="type-right-side-bump"
                    >
                        <span
                            id={ `flag_lead_${props.leadId}` }
                            role="button"
                            className={ `control-toggle-link type-extra-small-body type-single-line ${flagLeadModifiers}` }
                            data-checked={ props.flagged }
                            onClick={ props.onFlagLeadClick }
                        >    
                            <span className="inline-icon inline-icon__middle inline-icon__20">
                                { IconFlagSvg }
                            </span>{' '}
                            {props.flagged ? 
                                'FLAGGED LEAD'
                            : 
                                'FLAG LEAD'
                            }
                        </span>
                        {props.ticketId && (
                            <>
                                {' '}
                                <a 
                                    href={`https://app.hubspot.com/contacts/${hubspotEnv}/ticket/${props.ticketId}`}
                                    className="type-extra-small-body type-single-line type-heavy"
                                    target="_blank"
                                >
                                    View
                                </a>
                            </>
                        )}
                    </span>
                </>
            )}
            <span
                id={ `needs_follow_up_${props.leadId}` }
                role="button"
                className={ `control-toggle-link type-extra-small-body type-single-line ${followUpModifiers}` }
                data-checked={ props.needsFollowUp }
                onClick={ props.onFollowUpClick }
            >
                <span className="inline-icon inline-icon__middle inline-icon__20">
                    { IconExclamationTriangleSvg }
                </span>{' '}
                FOLLOW-UP
            </span>
            <span
                role="button"
                onClick={ props.onStarClick }
                className={ `control-toggle-link type-extra-small-body type-single-line type-left-side-bump ${starModifiers}` }
            >
                <span className="inline-icon inline-icon__middle inline-icon__20">
                    { IconStarFilledSvg }
                </span>{' '}
                STARRED
            </span>
        </div>
    );
};

export default ControlsBlock;
