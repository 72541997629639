import React, {Component} from 'react';

import ServicesSubcategory from './ServicesSubcategory';
import ServiceAreaText from './ServiceAreaText';
import OtherServices from "../../OtherServices";

export default class ServiceCategory extends Component {
    addNewServiceArea = () => {
        this.props.updateServiceAreaText({
            zip_codes: {
                zips: [],
                cities: []
            },
            main_zip_code: '',
            radius: 10
        }, this.props.serviceCategoryIndex);
    };

    render() {
        let serviceCategory = this.props.serviceCategory;

        const serviceSubcategories = serviceCategory.categories.map(
            (serviceSubcategory, key) => {
                // don't try to render an empty subcategory
                if (serviceSubcategory.services.length === 0) {
                    return null;
                }

                return <ServicesSubcategory
                    serviceSubcategory={serviceSubcategory}
                    key={key}
                    subcategoryIndex={key}
                    serviceCategoryIndex={this.props.serviceCategoryIndex}
                    updateServiceCategoryCheckBox={this.props.updateServiceCategoryCheckBox}
                    updateMessageBlocks={this.props.updateMessageBlocks}
                />;
            });

        return <div className={this.props.bottomSpacing ? 'spacing-60-bottom' : ''}>
            <div className="form__row spacing-30-bottom">
                <div className="form__cell form__cell__label form__cell__50 form__cell__100__mobile">
                    <label htmlFor="signup-industry" className="type-normal-subhead type-heavy type-single-line">
                        {serviceCategory.industry.name}
                    </label>
                </div>
                <div className="form__cell form__cell__label form__cell__50 form__cell__hidden__mobile"/>
            </div>

            {serviceSubcategories}

            {<OtherServices
                serviceCategory={serviceCategory}
                serviceCategoryIndex={this.props.serviceCategoryIndex}
                updateOtherText={this.props.updateOtherText}/>}

            <h5 className="type-normal-subhead type-heavy type-single-line">
                Targeted Service Area - {serviceCategory.industry.name}
            </h5>
            <p className="type-normal-body spacing-40-bottom spacing-10-top">
                Location targeting focuses on people who are "in or regularly in
                a location". It is impossible to guarantee that every Lead will
                be within your Service Area, however we do all we can to achieve that goal.{' '}
                <a href="https://support.servicedirect.com/select/account-activation-process#service-area"
                   target="_blank">
                    Click here for more instructions and a demo video.</a>
            </p>

            {serviceCategory.service_area.value.map((serviceArea, index) =>
                <ServiceAreaText
                    key={`service_area_${index}`}
                    arrayIndex={this.props.arrayIndex}
                    serviceAreaIndex={index}
                    positionInGroup={this.props.arrayIndex}
                    id={`service_area_${this.props.arrayIndex}`}
                    name={`service_area_${this.props.arrayIndex}`}
                    updateFunc={(value) =>
                        this.props.updateServiceAreaText(value, this.props.serviceCategoryIndex, index)}
                    updateModalContent={this.props.updateModalContent}
                    updateMessageBlocks={this.props.updateMessageBlocks}
                    industryName={serviceCategory.industry.name}
                    closeModal={this.props.closeModal}
                    serviceArea={serviceArea}
                    serviceAreaTextOnBlur={this.props.serviceAreaTextOnBlur}
                    serviceAreaRequired={this.props.serviceAreaRequired}
                    stateInputKey={this.props.serviceCategoryStateKey}
                    hasMultipleServiceAreas={serviceCategory.service_area.value.length > 1}
                    country={this.props.country}
                />
            )}
            <p className="type-normal-body">
                Do you serve multiple locations or non-contiguous areas? If so,{' '}
                <button className="button-clean type-blue type-normal-body" onClick={this.addNewServiceArea}>
                    add an additional service area for another location
                </button>
            </p>
        </div>;
    }
}
