import styled from 'styled-components'

export const LinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const MarginBottom = styled.div`
    margin-bottom: ${(props) => props.margin || 25}px;
`

export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`
