import styled from 'styled-components'

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr 30px;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    align-items: start;
`

export const MessageWrapper = styled(Wrapper)`
    /* margin-top: -0.5rem; */
`

export const InputWrapper = styled.div`
    width: 100%;
`

export const InputWithSideLabelWrapper = styled(InputWrapper)`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    /* max-width: 300px; */
`

export const CurrentValueWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const CurrentValue = styled.span`
    font-size: 1rem;
    height: calc(1rem + 0.7978035009em + 0.7978035009em);
    padding: 0.7978035009em 0;
`
