import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = (props) => {
    function getCurrentStep() {
        let path = props.location;

        if (path.includes("/step-one") || path.includes("/step-confirmation")) {
            return 1;
        }
        else if (path.includes("/step-two")) {
            return 2;
        }
        else if (path.includes("/step-three")) {
            return 3;
        }
        else if (path.includes("/step-four")) {
            return 4;
        }
        else {
            return false;
        }
    }

    function getCssClass(step) {
        let css = 'signup-progress__timeline__point';

        // if this is the current step, add the "current" CSS class
        if (step <= currentStep) {
            css += ' signup-progress__timeline__point__current';
        }

        return css;
    }

    let currentStep = getCurrentStep();

    if (!currentStep) {
        return null;
    }

    return <div className="padding-50-top padding-60-bottom">
        <p className="type-normal-subhead-special type-orange type-centered spacing-50-bottom">
            <span className="type-force-newline-mobile">Get started with</span>
            {' '}
            <span className="type-force-newline-mobile">Service Direct Marketplace</span>
        </p>
        <div className="signup-progress__wrapper">
            <div
                className={`signup-progress__timeline__wrapper signup-progress__timeline__wrapper__${currentStep}`}>
                <div className="signup-progress__timeline__line"/>
                <div className="signup-progress__timeline__line signup-progress__timeline__dotted-line"/>
                <div className="signup-progress__timeline__points">
                    <div className={getCssClass(1)}>
                        <div className="signup-progress__timeline__point__bubble"/>
                        <div className="signup-progress__timeline__point__label">
                            <span className="type-large-body type-single-line">Create Account</span>
                        </div>
                    </div>
                    <div className={getCssClass(2)}>
                        <div className="signup-progress__timeline__point__bubble"/>
                        <div className="signup-progress__timeline__point__label">
                            <span className="type-large-body type-single-line">Your Customers</span>
                        </div>
                    </div>
                    <div className={getCssClass(3)}>
                        <div className="signup-progress__timeline__point__bubble"/>
                        <div className="signup-progress__timeline__point__label">
                            <span className="type-large-body type-single-line">Your Settings</span>
                        </div>
                    </div>
                    <div className={getCssClass(4)}>
                        <div className="signup-progress__timeline__point__bubble"/>
                        <div className="signup-progress__timeline__point__label">
                            <span className="type-large-body type-single-line">Your Account</span>
                        </div>
                    </div>
                    <div className={getCssClass(5)}>
                        <div className="signup-progress__timeline__point__bubble"/>
                        <div className="signup-progress__timeline__point__label">
                            <span className="type-large-body type-single-line">Onboarding</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default ProgressBar;

ProgressBar.propTypes = {
    location: PropTypes.string.isRequired
};
