import React, {Component} from 'react';

import BulkEditCampaignsStep2Footer from './BulkEditCampaignsStep2Footer';
import TextArea from '../components/form_elements/TextArea';
import Tooltip from '../Tooltip';
import AuthService from "../../service/AuthService";

export default class BulkEditCampaignsStep2Status extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPausedOptions: false,
        };
    }

    render() {

        //
        // Create the list of campaigns along with their current setting
        //
        let campaignsList = this.props.campaigns.map((campaign, idx) => {
            //
            // Note - we pass the full campaign into getOptionValueTxt for status case only
            // because it needs multiple fields, e.g. auto_unpause_timestamp
            //
            if (campaign.campaign_id in this.props.selectedCampaignIds) {
                let valueTxt = this.props.getOptionValueTxt('status', campaign);
                return <div key={idx}
                            className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    <span className="type-large-body type-force-newline type-heavy">
                        {typeof (campaign.company_name) !== 'undefined'
                            ? <>{campaign.company_name} &mdash; </>
                            : ''}
                        {campaign.campaign_name}
                    </span>
                    <span className="type-normal-body type-force-newline">Status: {valueTxt}</span>
                </div>
            } else {
                return null;
            }
        });

        return (
            <>
                <div className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    <p className="type-large-body">
                        You are Bulk Editing the <b>Status</b> for the Campaigns listed below.
                    </p>
                    <p className="type-normal-body">
                        Their current Statuses are noted individually, and if you choose a new Status, all Campaigns
                        will be updated to those.
                    </p>
                </div>
                <div className="spacing-60-bottom-full spacing-60-bottom-tablet spacing-40-bottom-mobile">
                    {campaignsList}

                </div>

                <p className="type-large-body type-heavy type-single-line type-bottom-stroke no-margin-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    NEW SETTINGS (Replaces current settings)
                </p>

                <div className="radio-container spacing-10-bottom">
                    <input
                        type="radio"
                        name="status"
                        id="status-enable"
                        checked={this.props.formData.status === 'enabled' && this.props.formData.re_enable === 'now'}
                        onChange={() => {
                            this.props.updateFormDataMultiple({
                                status: 'enabled',
                                re_enable: 'now',
                                pause_later: false,
                                pause_date: '',
                            });
                        }}
                    />
                    <label htmlFor="status-enable" className="type-normal-body">
                        <span className="radio">
                            Enable Campaigns now
                        </span>
                    </label>
                </div>
                <div className="radio-container spacing-10-bottom">
                    <input
                        type="radio"
                        name="status"
                        id="status-enable-date"
                        checked={this.props.formData.status === 'enabled' && this.props.formData.re_enable === 'on_date'}
                        onChange={() => {
                            console.log("setting status=enabled, re_enable=on_date");
                            this.props.updateFormDataMultiple({
                                status: 'enabled',
                                re_enable: 'on_date',
                                pause_later: false,
                                pause_date: '',
                            });
                        }}
                    />
                    <label htmlFor="status-enable-date" className="type-normal-body">
                        <span className="radio">
                            <span className="type-right-side-bump">Enable Campaigns on</span>
                            <input type="date"
                                   className="ui-text-field ui-normal-text-input ui-text-field-inline"
                                   id="enable-date-picker"
                                   name="from-date"
                                   placeholder="mm/dd/yyyy"
                                   value={this.props.formData.re_enable_date}
                                   onChange={(e) => {
                                       this.props.updateFormDataMultiple({
                                           status: 'enabled',
                                           re_enable: 'on_date',
                                           re_enable_date: e.target.value,
                                           pause_later: false,
                                           pause_date: '',
                                       });
                                   }}
                            />
                        </span>
                    </label>
                </div>
                <div className="radio-container spacing-10-bottom">
                    <input
                        type="radio"
                        name="status-pause"
                        id="status-pause"
                        checked={this.props.formData.status === 'paused' && !this.props.formData.pause_later}
                        onChange={() => {
                            this.props.updateFormDataMultiple({
                                status: 'paused',
                                re_enable: 'on_date',
                                pause_later: false,
                                pause_date: '',
                            });
                        }}
                    />
                    <label htmlFor="status-pause" className="type-normal-body">
                        <span className="radio">
                            Pause Campaigns now
                        </span>
                    </label>
                </div>
                <div className="radio-container">
                    <input
                        type="radio"
                        name="status-pause-later"
                        id="status-pause-later"
                        checked={this.props.formData.status === 'paused' && this.props.formData.pause_later }
                        onChange={() => {
                            this.props.updateFormDataMultiple({
                                status: 'paused',
                                pause_later: true,
                                re_enable: 'on_date',
                            });
                        }}
                    />
                    <label htmlFor="status-pause-later" className="type-normal-body">
                        <span className="radio">
                            <span className="type-right-side-bump">Pause Campaigns on</span>
                            <input type="date"
                                   className="ui-text-field ui-normal-text-input ui-text-field-inline"
                                   id="pause-date-picker"
                                   name="pause-date"
                                   placeholder="mm/dd/yyyy"
                                   value={this.props.formData.pause_date}
                                   onChange={(e) => {
                                       console.log("setting status=paused, pause_later=true, pause_date");
                                       this.props.updateFormDataMultiple({
                                           status: 'paused',
                                           pause_later: true,
                                           pause_date: e.target.value,
                                           re_enable: 'on_date',
                                       });
                                   }}
                            />
                        </span>
                    </label>
                </div>

                {(this.props.formData.status === 'paused') ? (
                    <>
                        <p className="type-normal-body spacing-30">
                            {/*When Paused, all advertising is stopped and all contact information is removed from the Campaign Microsite.*/}
                            When Paused, all Lead generation is stopped. This can take up to several
                            hours with certain lead sources. If you’d prefer to reduce your lead volume, consider
                            lowering your Cost Per Lead rather than Pausing.{' '}
                            <span onClick={() => this.props.goToStep(1)} role="button" className="type-heavy type-blue">
                                Go back to Edit Cost Per Lead
                            </span>
                        </p>
                        <p className="type-normal-body spacing-30-bottom">
                            If you would like to control the time of day your Ads are Enabled, please{' '}
                            <a className="type-heavy" href={AuthService.isMarketplace
                                ? "https://support.servicedirect.com/get-support"
                                : "https://support.servicedirect.com/kb-tickets/new"}>
                                Contact Support</a>{' '}
                            about creating an Ad{' '}
                            <span className="type-no-break">
                                Schedule.{' '}
                                <Tooltip
                                    content={<>
                                         <span
                                             className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                            Add an Ad Schedule
                                        </span>
                                        <span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            {AuthService.isMarketplace
                                                ? 'Set up a Campaign Schedule to control the time you are actively receiving Leads.'
                                                : 'Set up an Ad Schedule to control the time you are actively advertised on Google, Microsoft, and other affiliates.'}
                                            {' '}
                                            <span className={"type-heavy"}>Note: </span>
                                            We are unable to guarantee you will never receive a call outside of these
                                            times and after hours calls are still subject to normal Billable Lead
                                            guidelines.
                                        </span>
                                    </>}
                                    position='bottom'
                                    modalContainerRef={this.props.modalContainerRef}
                                />
                            </span>
                        </p>
                        <div className="spacing-30">
                            <TextArea
                                name="reason"
                                label={<>Reason for Pausing&nbsp;<span className="type-light">(required)</span></>}
                                value={this.props.formData.reason}
                                onChange={(e) => {
                                    this.props.updateFormData('reason', e.target.value)
                                }}
                            />
                        </div>
                        <p className="type-normal-body spacing-10-bottom">
                            After the Pause, would you like to schedule your Campaigns to automatically Re-Enable again?
                        </p>
                        <div className="radio-container spacing-10-bottom">
                            <input
                                type="radio"
                                name="pause"
                                id="pause-date"
                                checked={this.props.formData.status === 'paused' && this.props.formData.re_enable === 'on_date'}
                                onChange={() => {
                                    this.props.updateFormDataMultiple({
                                        status: 'paused',
                                        re_enable: 'on_date',
                                    });
                                }}
                            />
                            <label htmlFor="pause-date" className="type-normal-body">
                                <span className="radio">
                                    <span className="type-right-side-bump">Re-Enable Campaigns on</span>
                                    <input type="date"
                                           className="ui-text-field ui-normal-text-input ui-text-field-inline"
                                           id="pause-date-picker"
                                           name="from-date"
                                           placeholder="mm/dd/yyyy"
                                           value={this.props.formData.re_enable_date}
                                           onChange={(e) => {
                                               this.props.updateFormDataMultiple({
                                                   status: 'paused',
                                                   re_enable: 'on_date',
                                                   re_enable_date: e.target.value,
                                               });
                                           }}
                                    />
                                </span>
                            </label>
                        </div>
                        <div className="radio-container">
                            <input
                                type="radio"
                                name="pause"
                                id="pause-indefinite"
                                checked={this.props.formData.status === 'paused' && this.props.formData.re_enable === 'never'}
                                onChange={() => {
                                    this.props.updateFormDataMultiple({
                                        status: 'paused',
                                        re_enable: 'never',
                                    });
                                }}
                            />
                            <label htmlFor="pause-indefinite" className="type-normal-body">
                                <span className="radio">
                                    Leave my Campaign Paused
                                </span>
                            </label>
                        </div>
                    </>
                ) : null}

                <BulkEditCampaignsStep2Footer
                    commitSettings={this.props.commitSettings}
                    updateModalContent={this.props.updateModalContent}
                    updateModalHeader={this.props.updateModalHeader}
                    confirmationHeader="Status Change Confirmation"
                />
            </>
        );
    }
}