import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from "../../common/Tooltip";

export default class LeadSummaryCell extends Component {
    isVisible = () => {
        if (this.props.cellPosition === undefined) {
            return true;
        }

        return this.props.cellPosition >= this.props.scrollPosition &&
            this.props.cellPosition - this.props.scrollPosition + 1 <= this.props.columnCount;
    };

    styledStatContent = () => {
        let statTypeClassString = this.props.statTypeClassString
                ? this.props.statTypeClassString
                : 'type-headline-special type-heavy type-single-line',
            statIconSizeClass = this.props.statIconSizeClass
                ? this.props.statIconSizeClass
                : "inline-icon__44";

        return <div className={`${statTypeClassString} ${this.typeColorTag()}`}>
            {this.props.statType === 'icon'
                ? <span className={`inline-icon ${statIconSizeClass}`}>
                    { this.props.statContent }
                </span>
                : this.props.statContent}
        </div>;
    };

    typeColorTag = () =>  {
        switch (this.props.color) {
            case 'orange':
                return 'type-orange';
            case 'gray':
                return 'type-grey';
            default:
                return 'type-orange';
        }
    };

    render() {
        let bodyTypeSizeClass = this.props.bodyTypeSizeClass
            ? this.props.bodyTypeSizeClass
            : "type-large-body",
            typeColorClass = this.props.color === "gray"
                ? "type-grey-2"
                : "",
            confidenceTxt = this.props.confidence !== undefined &&
                <div className={typeColorClass + " type-small-body"}>
                    <span className="type-heavy">{this.props.confidence}%</span> Confidence
                </div>;

        return <div
                className="stats-bar__cell"
                style={{
                    width: `${this.props.width}px`,
                    marginRight: `${this.props.gutter}px`,
                    display: `${(this.isVisible()) ? 'block' : 'none' }`
                }}
            >
                { this.styledStatContent() }
                <div className={bodyTypeSizeClass + " type-narrow-line-height " + typeColorClass }>
                    {this.props.onClick
                        ? <span role="button" className="type-heavy type-blue" onClick={this.props.onClick}>
                                {this.props.statDescription}
                        </span>
                        : this.props.statDescription}
                    {' '}
                    <Tooltip
                        content={
                            <>
                                <span className="type-large-body type-force-newline type-black type-force-newline type-heavy type-narrow-line-height type-align-left type-notransform spacing-10-bottom">
                                    {this.props.toolTipTitle}
                                </span>
                                <span className="type-small-body type-force-newline type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                    {this.props.toolTipBody}
                                </span>
                            </>
                        }
                        position={ this.props.toolTipPosition }
                        modalContainerRef={ this.props.modalContainerRef }
                    />
                </div>
                {confidenceTxt}
            </div>;
    }
}

LeadSummaryCell.propTypes = {
    statContent: PropTypes.node.isRequired,
    modalContainerRef: PropTypes.object.isRequired,
    toolTipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    toolTipBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    confidence: PropTypes.number
};
