import React, { Fragment, useMemo } from 'react'
import {
    // Wrapper,
    TableRow,
    RightCell,
    HeaderCell,
    TableWrapper,
    TextCell,
} from './styles'
import { toTitleCase } from '../../../../Util'

export default ({ affiliate, updates, columns, adNetworks, isNewAffiliate = false }) => {
    const { ad_network_name } = affiliate

    const getUpdatePropertyValue = (property) =>
        updates.filter((u) => u.property == property)[0]?.value

    // display a value based on it's property column definition
    const displayVal = (val, property) => {
        const column = columns?.filter((c) => c.property === property)[0]
        // don't manipulate the Lead Source name
        if (property === 'ad_network_name') {
            return val
        }
        if (column == null) {
            return val
        }

        const { format } = column
        if (format === 'integer' || format === 'decimal' || format === 'percent') {
            let v = parseFloat(val)
            if (v == null || isNaN(v)) {
                v = 0
            }
            return v
        } else if (format === 'boolean') {
            if (val === '1') {
                return 'Yes'
            } else if (val === '0') {
                return 'No'
            }
        }
        return toTitleCase(val)
    }
    // sort the updates manually
    const preferredSortOrder = [
        'ad_network_name',
        'ad_network_id',
        'hubspot_company_id',
        'mp_phone_api_access',
        'mp_phone_payout_model',
        'minimum_valid_call_duration',
        'locked_mp_phone_bid_multiplier',
        'mp_phone_bid_multiplier',
        'phone_numbers_limit',
        'disable_whisper_file',
        'mp_form_api_access',
        'mp_form_payout_model',
        'locked_mp_form_bid_multiplier',
        'default_mp_form_bid_multiplier',
        'feed',
        'sem',
        'active',
    ]
    const sortedUpdates = useMemo(
        () =>
            updates
                .slice()
                .sort(
                    (a, b) =>
                        preferredSortOrder.indexOf(a.property) -
                        preferredSortOrder.indexOf(b.property)
                ),
        [updates, preferredSortOrder]
    )

    // Create the table body
    const tableBody = sortedUpdates.map(({ property, value }, i) => {
        const toTitleWithAbbreviationFix = (str) =>
            toTitleCase(str).replace('Sem', 'SEM').replace('Mp', 'MP').replace('Id', 'ID')
        let _val = displayVal(value, property)
        // special case for displaying the minimum duration - if the min call duration
        // is there and its a new affiliate and the payout model is not 'duration', then display
        // 'Not Applicable'
        if (property == 'minimum_valid_call_duration') {
            if (
                isNewAffiliate &&
                getUpdatePropertyValue('mp_phone_payout_model') != 'duration'
            ) {
                _val = 'Not Applicable'
            }
        }
        let displayProperty = property
        if (property == 'ad_network_name') {
            displayProperty = 'lead_source_name'
        }
        // special case - dont display locked_mp_form_bid_multiplier
        // if (property == 'locked_mp_form_bid_multiplier') {
        //     return null
        // }
        return (
            <TableRow key={`update-${i}`}>
                <RightCell>
                    <TextCell>
                        <span className="type-heavy">
                            {toTitleWithAbbreviationFix(displayProperty)}
                        </span>
                    </TextCell>
                </RightCell>
                {!isNewAffiliate && (
                    <RightCell>
                        <span>{displayVal(affiliate[property], property)}</span>
                    </RightCell>
                )}
                <RightCell>
                    <span>{_val}</span>
                </RightCell>
            </TableRow>
        )
    })

    const heading = isNewAffiliate
        ? 'Confirm new Lead Source Settings'
        : `Confirm Settings for ${ad_network_name}`
    const subHeading = isNewAffiliate
        ? 'A new Lead Source will be added with the following settings'
        : 'The following changes will be made upon confirmation:'

    const colGroups = isNewAffiliate ? (
        <colgroup>
            <col span="1" style={{ width: '50%' }} />
            <col span="1" style={{ width: '50%' }} />
        </colgroup>
    ) : (
        <colgroup>
            <col span="1" style={{ width: '50%' }} />
            <col span="1" style={{ width: '25%' }} />
            <col span="1" style={{ width: '25%' }} />
        </colgroup>
    )
    return (
        <Fragment>
            <TableWrapper>
                <span className="type-normal-subhead type-heavy spacing-12-bottom">
                    {heading}
                </span>
                <span className="type-normal-body spacing-24-bottom">{subHeading}</span>
                <table className="spacing-12-bottom">
                    {colGroups}
                    <thead>
                        <TableRow>
                            <HeaderCell>
                                <span className="type-heavy type-contrast ">
                                    Property
                                </span>
                            </HeaderCell>
                            {!isNewAffiliate && (
                                <HeaderCell>
                                    <span className="type-heavy type-contrast">
                                        Old Value
                                    </span>
                                </HeaderCell>
                            )}
                            <HeaderCell>
                                <span className="type-heavy type-contrast">
                                    {isNewAffiliate ? 'Value' : 'New Value'}
                                </span>
                            </HeaderCell>
                        </TableRow>
                    </thead>
                    <tbody>{tableBody}</tbody>
                </table>
            </TableWrapper>
        </Fragment>
    )
}
