import React from 'react';

function SignupLaunchStatusTableHeaders() {
    const defaultClasses = 'top-row type-small-body type-narrow-line-height type-heavy ';
    
    function appendClasses(extraClasses = '') {
        return defaultClasses + extraClasses;
    }

    return <tr>
        <th id="signup-client-name" scope="col"
            className={appendClasses()}>
            Client Name
        </th>
        <th id="signup-client-type" scope="col"
            className={appendClasses('sales-details signup-status-table__contact-name')}>
            Activation Type
        </th>
        <th id="parent-contractor-name" scope="col"
            className={appendClasses('sales-details signup-status-table__contact-name')}>
            Parent Contractor
        </th>
        <th id="signup-contact-name" scope="col"
            className={appendClasses('sales-details signup-status-table__contact-name')}>
            Primary Contact Name
        </th>
        <th id="signup-contact-email" scope="col"
            className={appendClasses('sales-details')}>
            Primary Contact Email
        </th>
        <th id="signup-contact-phone" scope="col"
            className={appendClasses('sales-details signup-status-table__contact-phone')}>
            Primary Contact Phone
        </th>
        <th id="signup-hubspot-deal-id" scope="col"
            className={appendClasses('sales-details')}>
            HubSpot Company
        </th>
        {/*<th id="signup-phone-paid" scope="col" className={appendClasses('sales-details signup-status-table__paid-by-phone')}>*/}
        {/*    Startup Paid via Phone*/}
        {/*</th>*/}
        <th id="signup-invitation-sent" scope="col"
            className={appendClasses('sales-details')}>
            Select Invitation Sent
        </th>
        <th id="paid-startup-fee" scope="col"
            className={appendClasses('sales-details')}>
            Paid Activation Fee
        </th>
        <th id="signup-signup-started" scope="col"
            className={appendClasses('sales-details')}>
            Client Started Signup
        </th>
        <th id="signup-setup-complete" scope="col"
            className={appendClasses('client-details')}>
            Account Setup Complete
        </th>
        <th id="signup-cs-manager" scope="col"
            className={appendClasses('client-details')}>
            Onboarding Specialist
        </th>
        <th id="signup-company-complete" scope="col"
            className={appendClasses('client-details')}>
            Your Company Complete
        </th>
        <th id="signup-record" scope="col"
            className={appendClasses('client-details')}>
            { /* Signup Record */}
            Activation Profile
        </th>
        <th id="signup-campaign-name" scope="col"
            className={appendClasses('campaign-details')}>
            Campaign Name
        </th>
        <th id="signup-campaign-assigned" scope="col"
            className={appendClasses('campaign-details')}>
            Campaign Assigned
        </th>
        <th id="signup-expected-completion" scope="col"
            className={appendClasses('campaign-details')}>
            Expected Completion
        </th>
        <th id="signup-client-approval" scope="col"
            className={appendClasses('campaign-details')}>
            Campaigns Ready for SEM
        </th>
        <th id="signup-completed-signup" scope="col"
            className={appendClasses('campaign-details')}>
            Date Completed Signup
        </th>
        <th id="select-enroll-completed" scope="col"
            className={appendClasses('campaign-details')}>
            Select Program Enrollment Completed
        </th>
        <th id="signup-ad-queue" scope="col"
            className={appendClasses('sem-details')}>
            In Ad Queue
        </th>
        <th id="signup-posted" scope="col"
            className={appendClasses('sem-details')}>
            Posted
        </th>
        <th id="signup-impressions" scope="col"
            className={appendClasses('sem-details')}>
            First Recorded Impressions
        </th>
    </tr>;
}

export default SignupLaunchStatusTableHeaders;
