import React from 'react';
import PropTypes from 'prop-types';
import {formatCurrency} from "../../Util";

const ThresholdProgressBar = (props) => {
    let threshold = props.threshold,
        balance = props.balance,
        width;

    // The progress bar's fill needs to be bound between 0 and 100 percent.
    if ( (balance <= 0) || (threshold === 0) ) {
        width = 0;
    }
    else {
        width = (balance / threshold) * 100;

        if (width > 100) {
            width = 100;
        }
    }

    let progressStyle = { width: width + "%" },
        balanceFormatted = formatCurrency(balance, 0),
        thresholdFormatted = formatCurrency(threshold, 0);

    return (
    <div>
        <div className="spacing-30">
            {balance ? (
                <div className="type-large-headline type-light spacing-10-bottom">
                    <span className="type-heavy">{balanceFormatted}</span> / {thresholdFormatted}
                </div>
            ) : null}
            <div className="progress-indicator">
                <div className="progress-indicator__cell progress-indicator__cell__bar">
                    <div className="progress-indicator__cell__bar__progress" style={ progressStyle }></div>
                </div>
            </div>
        </div>
    </div>
    )   
}

export default ThresholdProgressBar;

ThresholdProgressBar.propTypes = {
    threshold: PropTypes.number,
    balance: PropTypes.number,
}
