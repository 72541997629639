import React from 'react';


export const ErrorContainer = props => {
    let errors = 0;
    props.errors.forEach((value) => {
        if (value) {
            errors++;
        }
    });

    if (errors > 0) {
        const errors = props.errors.map(function(error, key) {
            return (
                <span key={key} className="error-message">
                    {error}
                </span>
            )
        });

        return (
            <div className="inline-error">
                { props.header ? (
                    <p className="type-normal-body type-alert type-heavy type-single-line no-margin-top spacing-10-bottom">
                        { props.header }
                    </p>
                ) : "" }
                <p className="type-small-body type-alert type-single-line spacing-10-top spacing-30-bottom">
                    { errors }
                </p>
            </div>
        );
    }
    else {
        return (
            <span>
            </span>
        )
    }
}

export default ErrorContainer;
