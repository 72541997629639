import React, { useState } from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import MultiSelectList from '../../../../../common/components/form_elements/MultiSelectList'
import RadioGroup from '../../../../../common/components/form_elements/RadioGroup'
import { InputWrapper } from '../styles'
import Input from '../../../../../common/components/form_elements/Input'
import { IconButton } from '../../../../../common/components/form_elements/Button'
import { Delete, QuestionMarkSvg, SvgWrapper } from '../../../../../common/Svgs'
import { DeleteContainer, NewNumberWrapper } from './styles'

export default function NewNumber(props) {
    const {
        serviceCategories = [],
        onUpdateNumber = () => null,
        configuration,
        onRemoveNumber,
    } = props
    const { _id } = configuration

    const [selectedServiceCategory, setSelectedServiceCategory] = useState(
        configuration?.industry_id || null
    )
    const [whisperVal, setVal] = useState(configuration?.is_whisper_audio || '1')
    const [phoneName, setPhoneName] = useState(configuration?.phone_name || '')
    const [areaCode, setAreaCode] = useState(configuration?.area_code || '')

    // service category selection handler
    const handleSelectServiceCategory = (vals) => {
        setSelectedServiceCategory(vals[0])
        const serviceCategoryLabel = serviceCategories.filter(
            (s) => s.value == vals[0]
        )[0]?.text
        onUpdateNumber(_id, {
            ...configuration,
            industry_id: vals[0],
            serviceCategoryLabel,
        })
    }

    const handleChange = (name, val) => {
        setVal(val)
        onUpdateNumber(_id, {
            ...configuration,
            phone_name: phoneName,
            area_code: areaCode,
            is_whisper_audio: val,
        })
    }

    const handleTextChange = (e) => {
        setPhoneName(e.target.value)
        onUpdateNumber(_id, {
            ...configuration,
            is_whisper_audio: whisperVal,
            area_code: areaCode,
            phone_name: e.target.value,
        })
    }

    const handleAreaCode = (e) => {
        // reject non numeric and any character over 3 digits
        if (e.target.value?.length > 3 || !/^\d*$/.test(e.target.value)) {
            return
        }
        setAreaCode(e.target.value)
        onUpdateNumber(_id, {
            ...configuration,
            phone_name: phoneName,
            is_whisper_audio: whisperVal,
            area_code: e.target.value,
        })
    }

    const areaCodeLabel = (
        <>
            <span style={{ marginRight: '5px' }}>Preferred Area Code</span>
            <Tippy
                content={
                    <div>
                        <span>
                            Specify a 3 digit US Area Code or Tool Free Area Code. If a
                            number is available with the preferred area code, it will be
                            used. It will also check the surrounding area of the specified
                            area code and attempt to use a nearby area code
                        </span>
                        <br />
                        <br />
                        <span>
                            If this field is left blank, or if a number cannot be found in
                            or around the requested area code, a random local number will
                            be assigned. Toll Free area codes include 800, 888, 877, 866,
                            855, 844 and 833
                        </span>
                    </div>
                }
                trigger="click"
                theme="sd-tooltip"
                // interactive={true}
                allowHTML={true}
            >
                <span
                    style={{ height: '15px', width: '15px' }}
                    className="type-blue"
                    // role="button"
                >
                    <SvgWrapper>{QuestionMarkSvg}</SvgWrapper>
                </span>
            </Tippy>
        </>
    )

    return (
        <NewNumberWrapper>
            <InputWrapper>
                <MultiSelectList
                    label="Service Category for DID Number"
                    name="service_category"
                    useTextFilter
                    emptyLabel="Required"
                    selections={selectedServiceCategory && [selectedServiceCategory]}
                    onChange={handleSelectServiceCategory}
                    options={serviceCategories}
                />
            </InputWrapper>
            <InputWrapper>
                <Input
                    type="text"
                    label="Phone Name"
                    id="did_phone_name"
                    // name="did_phone_name"
                    onChange={handleTextChange}
                    value={phoneName}
                />
            </InputWrapper>
            <InputWrapper>
                <Input
                    type="text"
                    placeholder="512"
                    inputWidth={70}
                    label={areaCodeLabel}
                    id="did_area_code"
                    // name="did_area_code"
                    onChange={handleAreaCode}
                    value={areaCode}
                />
            </InputWrapper>
            <div>
                <RadioGroup
                    label="Play Whisper File"
                    name={`is_whisper_audio_${configuration._id}`}
                    options={[
                        { label: 'Yes', value: '1' },
                        { label: 'No', value: '0' },
                    ]}
                    onChange={handleChange}
                    checkedOption={whisperVal}
                    isClearable={false}
                />
            </div>
            <DeleteContainer>
                <IconButton
                    variant="span"
                    icon={Delete}
                    onClick={() => onRemoveNumber && onRemoveNumber(_id)}
                />
            </DeleteContainer>
        </NewNumberWrapper>
    )
}
