import React, { Fragment } from 'react'
import { ContentWrapper, ConfirmationLine, Wrapper } from './styles'
import Button from '../../../../../common/components/form_elements/Button'
import { ContentWindowScroll } from '../../../../../common/Modal'
import { ActionsWrapper, Divider } from '../styles'
import Label from '../../../../../common/components/form_elements/Label'

export default function NumberConfirmation(props) {
    const { affiliate, changes, onGoBack = () => null, onConfirm = () => null } = props
    const newNumbersView = changes?.map((n) => {
        return (
            <Fragment key={n._id}>
                <ConfirmationLine borderLight>
                    <span className="type-large-body">{n.serviceCategoryLabel}</span>
                    <span className="type-large-body">{n.phone_name}</span>
                    <span className="type-large-body">{n.area_code}</span>
                    <span className="type-large-body">
                        {n.is_whisper_audio == '1' ? 'Yes' : 'No'}
                    </span>
                    <div />
                </ConfirmationLine>
                <Divider borderLight />
            </Fragment>
        )
    })

    return (
        <Fragment>
            <ContentWindowScroll>
                <Wrapper>
                    <div className="spacing-6-bottom">
                        <span className="type-normal-subhead type-heavy">
                            Confirm Purchase of DID Numbers for {affiliate.text}
                        </span>
                    </div>
                    <span className="type-normal-body">
                        Upon confirmation, phone numbers will be purchased and set up for
                        this Lead Source.
                    </span>
                    <span className="spacing-24-bottom" />
                    <ContentWrapper>
                        <div
                            className="padding-12 module__header"
                            style={{ paddingLeft: '20px' }}
                        >
                            <span
                                className="type-normal-body type-heavy"
                                style={{ paddingLeft: '8px', width: '100%' }}
                            >
                                DID Numbers
                            </span>
                        </div>
                        <ConfirmationLine>
                            <Label label="Service Category" />
                            <Label label="Phone Name" />
                            <Label label="Preferred Area Code" />
                            <Label label="Play Whisper File" />
                        </ConfirmationLine>
                        <Divider />
                        {newNumbersView}
                    </ContentWrapper>
                </Wrapper>
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button onClick={onGoBack({ changes, affiliate })} variant="text">
                    <span className="type-heavy">Previous Step</span>
                </Button>
                <Button
                    onClick={() => {
                        onConfirm && onConfirm({ newNumbers: changes, affiliate })
                    }}
                >
                    Confirm And Save
                </Button>
            </ActionsWrapper>
        </Fragment>
    )
}
