import React, {Component} from 'react';
import axios from 'axios';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import AdminLeadManagerSecondaryNav from './AdminLeadManagerSecondaryNav';
import AuthService from '../../service/AuthService';
import CampaignService from '../../service/CampaignService';
import ContractorService from '../../service/ContractorService';
import DateRangeService from "../../service/DateRange";
import ExportLeadsButton from "../../common/ExporLeadsButton";
import FilterLeadsPanel from './FilterLeadsPanel';
import FilteredLeadsStatsModal from '../../common/lead_manager/FilteredLeadsStatsModal';
import {filterMessage} from '../../common/lead_log/FilterMessage';
import {errorHandler, get, put} from '../../Requests';
import {parseBillableStatus,} from '../../common/lead_log/LeadFilters';
import LeadService from '../../service/LeadService';
import LeadLog from '../../common/lead_log/LeadLog';
import LeadLogHeadline from './LeadLogHeadline';
import LeadSummaryCell from "../../common/lead_log/LeadSummaryCell";
import LoadingGif from "../../common/components/LoadingGif";
import Modal from '../../common/Modal';
import Pagination from '../../common/components/Pagination/v1.0.1/Pagination';
import ResourcesService from '../../service/ResourcesService';
import UserService from '../../service/UserService';
import {formatCurrency, formatNumber} from "../../Util";
import {IconLockSvg, IconStatsSvg,} from "../../common/Svgs";
import cloneDeep from 'lodash/cloneDeep'
import {campaignStatusOptions, campaignTypeOptions} from '../../common/StaticValues';
import type {IFilteredSummary} from "../../Interfaces/IFilteredSummary";

const multiselectFilters = [
    'parent_contractor_ids',
    'ad_network_ids',
    'manager_account_ids',
    'status_id',
    'client_ids',
    'campaign_ids',
    'service_category_ids',
    'campaign_statuses',
    'review_initiated_by',
    'review_completed_by',
    'campaign_types',
    'contractor_type_ids',
];

const defaultDates = () => {
    let dates = {};

    const today = new Date();
    const todayDD = `${today.getDate()}`.padStart(2, '0');
    const todayMM = `${today.getMonth() + 1}`.padStart(2, '0');
    const todayYYYY = today.getFullYear();

    dates['today'] = `${todayYYYY}-${todayMM}-${todayDD}`;

    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastWeekDD = `${lastWeek.getDate()}`.padStart(2, '0');
    const lastWeekMM = `${lastWeek.getMonth() + 1}`.padStart(2, '0');
    const lastWeekYYYY = lastWeek.getFullYear();

    dates['lastWeek'] = `${lastWeekYYYY}-${lastWeekMM}-${lastWeekDD}`;

    const past30Days = new Date();
    past30Days.setDate(past30Days.getDate() - 30);
    const past30DaysDD = `${past30Days.getDate()}`.padStart(2, '0');
    const past30DaysMM = `${past30Days.getMonth() + 1}`.padStart(2, '0');
    const past30DaysYYYY = past30Days.getFullYear();

    dates['past30Days'] = `${past30DaysYYYY}-${past30DaysMM}-${past30DaysDD}`;

    return dates;
};

export default class AdminLeadManagerView extends Component {
    LOADING_MESSAGE_MIN_DISPLAY_TIME_MS = 333

    /**
     * Implemented as an instance variable b/c both the lead table and CSV
     * export need the same query parameters.
     * @type {string}
     */
    apiQueryStr = ''

    constructor(props) {
        super(props)
        document.title = 'Leads'

        let rowsPerPage = 25
        if (document.cookie.indexOf('srvAdminRowsPerPage=')) {
            let rowsPerPageCookie = document.cookie
                .split(';')
                .filter((item) => item.trim().indexOf('srvAdminRowsPerPage=') === 0)[0]
            if (rowsPerPageCookie) {
                rowsPerPage = parseInt(rowsPerPageCookie.split('=')[1])
            }
        }

        const _state = {
            adNetworks: [],
            campaign: undefined,
            loadingResults: true,
            loadingMessageMinDelayTimer: false,
            loadingMessageMinDelayTimeoutId: undefined,
            leads: [],
            leadStatuses: [],
            serviceCategories: [],
            contractorTypes: [],
            users: [],
            leadReviewUsers: [],
            managerAccounts: {},
            pagination: {
                rows_per_page: rowsPerPage,
                page_num: 1,
                total_rows: null,
                total_pages: null,
                page_min_row_number: 1,
                page_max_row_number: rowsPerPage,
            },
            clients: [],
            campaigns: [],
            parentContractors: [],
            /** @property {LeadsFilters} */
            selectedFilters: {},
            /** @property {LeadsFilters} */
            appliedFilters: {},
            /** @property {LeadsFilters} */
            defaultFilters: {
                date_range_id: 9,
                date_from: defaultDates().past30Days,
                date_to: defaultDates().today,
                lead_type: '',
                billable_select_list_value: 'all',
                lead_review_select_list_value: 'all',
                under_review: undefined,
                is_valid: 1,
                call_played_select_list_value: '0',
                flagged_select_list_value: '0',
                starred_select_list_value: '',
                followup_select_list_value: '0',
                call_answered_select_list_value: '0',
                booked_appointment_select_list_value: '0',
                job_won_select_list_value: '0',
                revenue_entered_select_list_value: '0',
                managed_select_list_value: 'all',
                // remove the "all" and "external buyer" options
                // convert to values only
                // [{value: "all", text: "all..."}, ...] => ["website with tracking", ...]
                campaign_types: campaignTypeOptions
                    .filter(option => option.value !== 'all' && option.value !== 'external buyer')
                    .map(option => option.value),
                // status_id: 'all',
                status_id: [],
                contractor_type_ids: ['1', '2', '3'],
                campaign_statuses: [],
                parent_contractor_ids: [],
                ad_network_ids: [],
                lead_ids: [],
                service_category_ids: [],
                manager_account_ids: [],
                client_ids: [],
                campaign_ids: [],
                review_initiated_by: [],
                review_completed_by: [],
                // show_blacklisted: '0',
                campaign_id: undefined,
                lead_id: undefined,
                sort_column: 'date',
                sort_order: 'desc',
                keyword: '',
            },
            leadFilteredSummary: {},
            modalContent: undefined,
            modalHeader: undefined,
            modalFooter: undefined,
            modalWidth: undefined,
            modalFlatbottom: undefined,
        }
        _state.appliedFilters = cloneDeep(_state.defaultFilters)
        _state.selectedFilters = cloneDeep(_state.defaultFilters)

        // multiselectFilters.forEach((key) => {
        //     _state.appliedFilters[key] = []
        //     _state.selectedFilters[key] = []
        // })
        this.state = _state

        this.modalScrollerRef = React.createRef()
        this.modalContainerRef = React.createRef()
        this.tooltipRef = React.createRef()

        this.cancelSignal = axios.CancelToken.source()

        this.campaignService = new CampaignService()

        this.contractorService = new ContractorService()

        this.dateRangeService = new DateRangeService()

        this.leadService = new LeadService()

        this.resourcesService = new ResourcesService()

        this.userService = new UserService()

    }

    componentDidMount() {
        this.setAppliedFiltersFromUrl()

        // Ad networks
        this.resourcesService.getAdNetworksForAdmin().then((response: Object) => {
            this.setState({
                adNetworks: response.adNetworks,
             })
        })

        // Lead Statuses
        this.resourcesService.getLeadStatusesForAdmin().then((response: Object) => {
            // the default behavior of the lead log is to show all but blacklisted leads
            // to make the front end more explicit, we'll default the selections to everything
            // except blacklisted. if actual filter values are set, then they will be used
            // let selectedStatusIds = selectedFilters.status_id
            const defaultSelectedLeadStatusIds = response.statuses
                ?.filter(
                    (option) =>
                        option.name !== 'Blacklist' &&
                        option.name !== 'Failed Spam Filter'
                )
                ?.map((option) => option.statusId)

            // update the state with the filters
            this.setState({
                leadStatuses: response.statuses,
                selectedFilters: {
                    ...this.state.selectedFilters,
                    status_id: [...defaultSelectedLeadStatusIds],
                    // campaign_statuses: campaignStatusOptions.map(o => o.value)
                },
                appliedFilters: {
                    ...this.state.appliedFilters,
                    status_id: [...defaultSelectedLeadStatusIds],
                    // campaign_statuses: campaignStatusOptions.map(o => o.value)
                },
                defaultFilters: {
                    ...this.state.defaultFilters,
                    status_id: [...defaultSelectedLeadStatusIds],
                    campaign_statuses: campaignStatusOptions.map(o => o.value)
                }
            })
        })

        // service categories
        this.resourcesService.getIndustries().then((response: Object) => {
            this.setState({
                serviceCategories: response.serviceCategories,
                defaultFilters: {
                    ...this.state.defaultFilters,
                    service_category_ids: response.serviceCategories.map(o => o.id)
                },
                selectedFilters: {
                    ...this.state.selectedFilters,
                    service_category_ids: response.serviceCategories.map(o => o.id)
                    // campaign_statuses: campaignStatusOptions.map(o => o.value)
                },
                appliedFilters: {
                    ...this.state.appliedFilters,
                    service_category_ids: response.serviceCategories.map(o => o.id)
                    // campaign_statuses: campaignStatusOptions.map(o => o.value)
                },
            })
        })

        this.resourcesService.getContractorTypes().then((response: Object) => {
            const contractorTypes = response.contractorTypes
                .map((contractorType) =>
                    ({ value: contractorType.contractorTypeId, text: contractorType.type }))
            this.setState({ contractorTypes })
        })

        this.contractorService.getParentContractors().then((response: Object) => {
            this.setState({ parentContractors: response })
        })

        this.contractorService.getClients().then((response: Object) => {
            this.setState({ clients: response })
        })

        this.contractorService.getManagerAccounts().then((response: Object) => {
            this.setState({ managerAccounts: response })
        })

        this.userService.getLeadReviewUsers().then((response: Object) => {
            this.setState({
                leadReviewUsers: [...response],
             })
        })

    }

    componentWillUnmount() {
        this.cancelSignal.cancel();

        this.leadService.cancelSignal.cancel();
        this.campaignService.cancelSignal.cancel();
        this.contractorService.cancelSignal.cancel();
        this.dateRangeService.cancelSignal.cancel();
        this.resourcesService.cancelSignal.cancel();
        this.userService.cancelSignal.cancel();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //
        // A url change will land here.  The filters in the URL have already
        // updated, so we just need to set them in state.appliedFilters.
        //
        if (prevProps.location.search != this.props.location.search) {
            this.setAppliedFiltersFromUrl()
        }
    }

    adNetworkOptions = () =>
        this.state.adNetworks.map((network) =>
            ({value: network.adNetworkId, text: network.name}))

    applyFilters = () => {
        let basePath = '/leads?',
            search = ''

        let filtersClone = { ...this.state.selectedFilters }
        filtersClone.lead_id = undefined
        filtersClone.campaign_id = undefined
        multiselectFilters.forEach((key) => {
            filtersClone[key] = [...this.state.selectedFilters[key]]
        })

        filtersClone = parseBillableStatus(
            filtersClone.billable_select_list_value,
            filtersClone
        )

        // Reset sort to Lead Review Status based sorting when applying a new set of filters
        // https://app.asana.com/0/1117679512684605/1200412130502414/f
        if (
            ['in review', 'in review submitted by client', 'completed'].includes(
                filtersClone.lead_review_select_list_value
            )
        ) {
            filtersClone.sort_column = 'lead-review'
        } else {
            if (filtersClone.sort_column === 'lead-review') {
                // The next filter step no longer involves Lead Review status, so clear to search defaults if sort is still set to Lead Review
                filtersClone.sort_column = 'date'
                filtersClone.sort_order = 'desc'
            }
        }

        for (let key in filtersClone) {
            if (filtersClone[key] === undefined || filtersClone[key] === '') {
                continue
            }

            if (key === 'billable_select_list_value') {
                continue
            }

            if (multiselectFilters.includes(key)) {
                if (filtersClone[key].length > 0) {
                    let paramStr = filtersClone[key].join('&' + key + '[]=')
                    paramStr = '&' + key + '[]=' + paramStr
                    search += paramStr
                }
            } else {
                if (filtersClone[key] === null) {
                    search += '&' + key
                } else {
                    search += '&' + key + '=' + filtersClone[key]
                }
            }
        }

        // remove the prefixed '&'
        search = search.substr(1)

        this.props.history.push(basePath + search)
        this.setState({ selectedFilters: filtersClone })
    }

    campaignOptions = () => {
        let campaigns = [...this.state.campaigns]

        return campaigns.map((campaign) => {
            return { value: campaign.campaign_id, text: campaign.name }
        })
    }

    clientOptions = () => {
        let filteredParentContractors = []
        let filteredManagerContractors = []
        if (this.state.selectedFilters.manager_account_ids.length > 0) {
            filteredManagerContractors = [
                ...this.state.selectedFilters.manager_account_ids,
            ]
        } else if (this.state.selectedFilters.parent_contractor_ids.length > 0) {
            filteredParentContractors = [
                ...this.state.selectedFilters.parent_contractor_ids,
            ]
        }

        let clients = [...this.state.clients]
        if (filteredParentContractors.length > 0) {
            clients = clients.filter((client) =>
                filteredParentContractors.includes(client.parent_contractor_id)
            )
        }
        if (filteredManagerContractors.length > 0) {
            clients = clients.filter((client) =>
                filteredManagerContractors.includes(client.manager_contractor_id)
            )
        }

        return clients.map((client) => {
            return { value: client.contractor_id, text: client.name }
        })
    }

    getFilteredSummary = () => {
        this.leadService
            .getFilteredSummary(this.apiQueryStr)
            .then((leadFilteredSummary: IFilteredSummary) => {
                this.setState({ leadFilteredSummary })
            })
            .catch(errorHandler)
    }

    /**
     * This component already has most of what it needs from a lead
     * passed in through the lead property.  But it lacks tags, and address, which
     * this API call provides.
     * @param {number} leadId - the lead id
     * @return Promise<AxiosResponse>
     */
    getLead = (leadId) => this.leadService.get(leadId, this).catch(errorHandler)

    getLeads = () => {
        this.setLoadingStatus()

        let pagination = this.state.pagination,
            appliedFilters = this.state.appliedFilters,
            queryStr = ''

        const queryParams = queryString.parse(this.props.location.search, {
            arrayFormat: 'bracket',
        })

        if (queryParams.lead_id) {
            this.apiQueryStr = queryStr = `lead_id=${queryParams.lead_id}`
        } else {
            for (let key in appliedFilters) {
                if (!appliedFilters.hasOwnProperty(key)) {
                    continue
                }
                if (!appliedFilters[key]) {
                    continue
                }
                // this is skipped because we instead use "is_valid=1" etc for filtering Billable/Non-Billable ~ RFM
                if (key === 'billable_select_list_value') {
                    continue
                }
                queryStr += '&' + key + '=' + appliedFilters[key]
            }

            if (queryStr[0] === '&') {
                queryStr = queryStr.substring(1)
            }

            this.apiQueryStr = queryStr
            queryStr += '&pagination[rows_per_page]=' + pagination.rows_per_page
            queryStr += '&pagination[page_num]=' + pagination.page_num
        }



        this.leadService
            .getLeads(queryStr)
            .then((response) => {
                let leads = response.data.leads,
                    paginationValues = response.pagination

                this.setState(
                    {
                        leads,
                        loadingResults: false,
                        pagination: {
                            rows_per_page: paginationValues.rows_per_page,
                            total_rows: Number(paginationValues.total_rows),
                            total_pages: paginationValues.total_pages,
                            page_num: paginationValues.page_num,
                            page_min_row_number: paginationValues.page_min_row_number,
                            page_max_row_number: paginationValues.page_max_row_number,
                        },
                    },
                    this.getUsers
                )
            })
            .catch(errorHandler)

        this.getFilteredSummary()
    }

    getUsers = () => {
        const contractorIds = [
            ...new Set(this.state.leads.map((lead) => lead.contractor_id)),
        ]

        const apiUrl =
            'contractors/admin/usernames_for_contractors?contractor_ids=' +
            contractorIds.join(',')

        get(apiUrl, this.cancelSignal.token)
            .then((resp) => {
                // TODO add pop modal here
                if (!resp || resp.status !== 200) {
                    return
                }

                this.setState({ users: resp.data.data.usernames })
            })
            .catch((error) => {
                console.log('Error in AdminLeadManagerView.getUsers(), ', error)
            })
    }

    handlePageChange = (event, newPageNum) => {
        //
        // We're not calling event.preventDefault() here b/c we want the
        // browser to handle a "page change" in an otherwise default way, which
        // includes an auto-scroll to the top of the lead manager table.
        //
        const queryParams = queryString.parse(this.props.location.search, {
            arrayFormat: 'bracket',
        })

        queryParams['page_num'] = newPageNum

        let newPath =
            '/leads?' + queryString.stringify(queryParams, { arrayFormat: 'bracket' })

        this.props.history.push(newPath)
    }

    leadStatusOptions = () =>
        this.state.leadStatuses.map((status) =>
            ({value: status.statusId, text: status.name}))

    managerAccountOptions = () => {
        let keys = Object.keys(this.state.managerAccounts)
        let managerAccounts = keys.map((key) => {
            return {
                contractorId: `${key}`,
                name: this.state.managerAccounts[key].name,
                parentContractorId: this.state.managerAccounts[key].parent_contractor_id,
            }
        })

        if (this.state.selectedFilters.parent_contractor_ids.length > 0) {
            // if there are selections made in the Parent Contractor menu, filter Manager Account options
            managerAccounts = managerAccounts.filter((account) =>
                this.state.selectedFilters.parent_contractor_ids.includes(
                    account.parentContractorId
                )
            )
        }

        managerAccounts = managerAccounts.map((account) => {
            return { value: `${account.contractorId}`, text: account.name }
        })

        managerAccounts = managerAccounts.sort((a, b) => {
            if (a.text < b.text) {
                return -1
            }
            if (a.text > b.text) {
                return 1
            }
            return 0
        })

        return managerAccounts
    }

    openFilteredLeadsStatsModal = () => {
        this.updateModalContent(
            <FilteredLeadsStatsModal
                filterMessage={
                    filterMessage(this.state, {
                        includeShowingPrefix: false,
                        defaultFilters: this.state.defaultFilters,
                    })}
                totalRows={this.state.pagination.total_rows}
                apiQueryStr={this.apiQueryStr}
                leadFilteredSummary={this.state.leadFilteredSummary}
                modalContainerRef={this.modalContainerRef}
                scrollerRef={this.modalScrollerRef}
            />,
            <>
                Lead Performance Metrics for {this.state.pagination.total_rows} Currently
                Filtered Leads
                <span className="type-flag type-flag--beta">BETA</span>
            </>,
            'wide',
            false,
            <>
                <ExportLeadsButton
                    totalRows={this.props.totalRows}
                    apiQueryStr={this.props.apiQueryStr}
                />
            </>
        )
    }

    parentContractorOptions = () => {
        let parentContractors = this.state.parentContractors.map((contractor) => {
            return { value: contractor.contractor_id, text: contractor.name }
        })

        return parentContractors
    }

    leadReviewCompleteOptions = () => {
        let leadReviewCompleteUsers = this.state.leadReviewUsers.map((user) => {
            return { value: user.user_id, text: user.username }
        })

        return leadReviewCompleteUsers
    }

    resetFilters = () => {
        // let defaultFiltersClone = { ...this.state.defaultFilters }
        let defaultFiltersClone = cloneDeep(this.state.defaultFilters)

        // multiselectFilters.map((key) => (defaultFiltersClone[key] = []))
        multiselectFilters.map((key) => {
            if (this.state.defaultFilters[key]?.length === 0) {
                defaultFiltersClone[key] = []
            } else {
                defaultFiltersClone[key] = [...this.state.defaultFilters[key]]
            }
            return null
        })

        this.props.history.push(
            `/leads?sort_column=${this.state.appliedFilters.sort_column}&sort_order=${this.state.appliedFilters.sort_order}`
        )
        this.setState({
            selectedFilters: defaultFiltersClone,
            appliedFilters: defaultFiltersClone,
        })
    }

    serviceCategoryOptions = () => {
        let serviceCategories = this.state.serviceCategories.map((category) => {
            return { value: category.id, text: category.name }
        })

        return serviceCategories
    }

    setAppliedFiltersFromUrl = () => {
        const queryParams = queryString.parse(this.props.location.search, {
            arrayFormat: 'bracket',
        })

        let newAppliedFiltersClone = { ...this.state.defaultFilters },
            paginationClone = { ...this.state.pagination }

        // reset the multiselect filters, except the ones that have been
        // set to a default
        multiselectFilters.map((key) => {
            if (this.state.defaultFilters[key]?.length === 0) {
                newAppliedFiltersClone[key] = []
            }
            return null
        })

        if (queryParams.lead_id || queryParams.campaign_id || queryParams.lead_ids) {
            // Clear out more restrictive default filters when there is a single lead or campaign id being filtered.
            newAppliedFiltersClone.date_range_id = '7'
            newAppliedFiltersClone.date_from = ''
            newAppliedFiltersClone.date_to = ''

            newAppliedFiltersClone.is_valid = null
            newAppliedFiltersClone.under_review = undefined
            newAppliedFiltersClone.billable_select_list_value = 'all'

            newAppliedFiltersClone.lead_id = queryParams.lead_id
            newAppliedFiltersClone.campaign_id = queryParams.campaign_id
            newAppliedFiltersClone.lead_ids = queryParams.lead_ids
        } else {
            for (let key in queryParams) {
                if (!(key in newAppliedFiltersClone)) {
                    continue
                }

                newAppliedFiltersClone[key] = queryParams[key]
            }

            if (newAppliedFiltersClone.date_range_id === '7') {
                newAppliedFiltersClone.date_from = ''
                newAppliedFiltersClone.date_to = ''
            }

            let billable_select_list_value = ''
            if (!('is_valid' in queryParams) && !('under_review' in queryParams)) {
                newAppliedFiltersClone.is_valid = undefined
                billable_select_list_value = 'all'

                newAppliedFiltersClone.under_review = undefined
            } else {
                if (queryParams.under_review == 2) {
                    billable_select_list_value = 'reviewed-all'

                    if (queryParams.is_valid == 1) {
                        billable_select_list_value = 'reviewed-billable'
                    } else if (queryParams.is_valid == 0) {
                        billable_select_list_value = 'reviewed-non-billable'
                    }
                } else if (queryParams.under_review == 1) {
                    billable_select_list_value = 'under-review'
                } else if (queryParams.is_valid == 1) {
                    billable_select_list_value = 'billable'
                } else if (queryParams.is_valid == 0) {
                    billable_select_list_value = 'non-billable'
                } else {
                    billable_select_list_value = 'all'
                }
            }

            newAppliedFiltersClone.billable_select_list_value = billable_select_list_value
        }

        if (queryParams.page_num) {
            paginationClone.page_num = queryParams.page_num
        } else {
            paginationClone.page_num = 1
        }
        let selectedFiltersClone = { ...newAppliedFiltersClone }
        multiselectFilters.map(
            (key) => (selectedFiltersClone[key] = [...newAppliedFiltersClone[key]])
        )

        this.setState(
            {
                selectedFilters: selectedFiltersClone,
                appliedFilters: newAppliedFiltersClone,
                pagination: paginationClone,
            },
            () => {
                this.updateCampaignOptions()
                this.getLeads()

                // note that this is for the campaign_id option, not campaign_ids
                // this code updates the state to ensure that the 2 options play well together
                if (newAppliedFiltersClone.campaign_id) {
                    this.campaignService
                        .adminGetCampaign(newAppliedFiltersClone.campaign_id)
                        .then((response: Object) => {
                            const campaign = response.data.data[0]

                            const selectedFiltersClone = { ...this.state.selectedFilters }
                            const appliedFiltersClone = { ...this.state.appliedFilters }

                            // KK 3/2/22 - sometimes, a single campaign may have been used with
                            // multiple contractors. it cannot be assumed that the all
                            // leads for a given campaign belong to the same client id, so the
                            // client id should not be set
                            // selectedFiltersClone.client_ids = [campaign.contractor_id];
                            selectedFiltersClone.campaign_ids = [campaign.campaign_id]

                            // appliedFiltersClone.client_ids = [campaign.contractor_id];
                            appliedFiltersClone.campaign_ids = [campaign.campaign_id]

                            this.setState(
                                {
                                    selectedFilters: selectedFiltersClone,
                                    appliedFilters: appliedFiltersClone,
                                },
                                () => this.updateCampaignOptions()
                            )
                        })
                }
            }
        )
    }

    /**
     * At minimum, we show the "Loading your Campaigns..." message for a certain amount
     * of time. This value is set to true when results start being loaded, and a
     * setTimeout resets it to false after a minimum delay. Ensures people get to see
     * the loading message instead of getting confused.
     */
    setLoadingStatus = () => {
        //
        // Clear any existing timeout from a previous request
        //
        if (this.state.loadingMessageMinDelayTimeoutId) {
            clearTimeout(this.state.loadingMessageMinDelayTimeoutId)
        }

        //
        // Set a timeout so we will see Loading message for at least a certain time
        //
        let timeoutId = setTimeout(() => {
            this.setState({ loadingMessageMinDelayTimer: false })
        }, this.LOADING_MESSAGE_MIN_DISPLAY_TIME_MS)

        //
        // Set state variables - bool to show Loading Message until timeout expires,
        // and also save timeout ID, to clear later
        //
        this.setState({
            loadingResults: true,
            loadingMessageMinDelayTimer: true,
            loadingMessageMinDelayTimeoutId: timeoutId,
        })
    }

    setRowsPerPage = (val) => {
        // let stateClone = { ...this.state }
        // stateClone.pagination.rows_per_page = val
        // stateClone.pagination.page_max_row_number = val
        // this.setState(stateClone, this.getLeads)

        document.cookie = `srvAdminRowsPerPage=${val}; expires=Fri, 31 Dec 9999 23:59:59 GMT`
        this.setState({
            pagination: {
                ...this.state.pagination,
                rows_per_page: val,
                page_max_row_number: val
            }
        })
    }

    updateCampaignOptions = () => {
        if (this.state.selectedFilters.client_ids.length > 0) {
            this.campaignService
                .adminGetCampaignForContractors(this.state.selectedFilters.client_ids)
                .then((response: Object) => {
                    this.setState({ campaigns: response[0] })
                })
        } else {
            this.setState({ campaigns: [] })
        }
    }

    updateClassification = (leadId, event, value) => {
        // const data = { event, value };
        let leads = [...this.state.leads]
        let index = leads.findIndex((lead) => lead.lead_id == leadId)

        let leadScores = Object.assign({}, leads[index].lead_scores)

        leadScores.verified = true

        leadScores.verificationResults.push({ event, value })

        leads[index].lead_scores = leadScores

        this.setState({ leads }, () => {})

        let putData = { event, value: value ? 1 : 0 }
        put(`leads/${leadId}/lead_classification`, putData, this.cancelSignal.token).then(
            (resp) => {}
        )
    }

    updateDateValue = (dateRangeId) => {
        let data = Object.assign({}, this.state.selectedFilters)
        data['date_range_id'] = dateRangeId

        if (dateRangeId !== 'custom' && dateRangeId !== '7') {
            // Set up to and from dates for all filters except Custom and All Time
            let fromToDates = this.dateRangeService.getFromToDates(dateRangeId)

            data['date_from'] = fromToDates.dateFrom
            data['date_to'] = fromToDates.dateTo
        } else if (dateRangeId === '7') {
            // Clear dates for 'All Time' date filter
            data['date_from'] = ''
            data['date_to'] = ''
        }

        this.setState({ selectedFilters: data })
    }

    /**
     * Receives key values pairs of updated lead properties and submits them to
     * the API.  On receipt, searches for the same lead in this view's state,
     * by id, and updates it there.
     * @param {number} leadId the lead id
     * @param {object} leadProps an object of KVPs representing lead properties and their values to update the lead with
     * @param {(isError: boolean) => void} [callback=null] if set to a function, will be executed on update completion
     * @param {boolean} [update=true] if set to false, will not send the update request to the API
     */
    updateLead = (leadId, leadProps, callback = null, update = true) => {
        let leads = [...this.state.leads]
        let index = leads.findIndex((lead) => lead.lead_id == leadId)
        Object.assign(leads[index], leadProps)
        this.setState({ leads })

        if (!update) {
            return;
        }

        this.leadService
            .update(leadId, leadProps, this)
            .then(() => {
                this.getFilteredSummary()

                this.forceUpdate()
                if (typeof callback === 'function') {
                    callback()
                }
            })
            .catch((error) => {
                if (errorHandler(error)) {
                    if (typeof callback === 'function') {
                        callback(true)
                    }
                }
            })
    }

    updateNote = (leadId, noteId, noteProps) => {
        let leads = [...this.state.leads]
        let leadIndex = leads.findIndex((lead) => lead.lead_id === leadId)
        let noteIndex = leads[leadIndex].lead_notes.findIndex(
            (note) => note.note_id === noteId
        )

        Object.assign(leads[leadIndex].lead_notes[noteIndex], noteProps)
        this.setState({ leads })

        put(`leads/${leadId}/notes/${noteId}`, noteProps, this.cancelSignal.token)
    }

    updateCallInsights = (leadId, callInsightsProps) => {
        let leads = [...this.state.leads]
        let leadIndex = leads.findIndex((lead) => lead.lead_id === leadId)

        callInsightsProps['form_inputted_from'] = 'mysd-admin-lead-manager'
        Object.assign(leads[leadIndex].call_insights, callInsightsProps)
        this.setState({ leads })

        return put(
            `leads/${leadId}/call_insights`,
            callInsightsProps,
            this.cancelSignal.token
        )
    }

    updateSort = (newSortColumn) => {
        let newSortOrder

        if (newSortColumn === this.state.appliedFilters.sort_column) {
            // Toggle if already sorting on this column
            newSortOrder =
                this.state.appliedFilters.sort_order === 'desc' ? 'asc' : 'desc'
        } else if (newSortColumn === 'date') {
            // Fresh sort on date defaults to desc
            newSortOrder = 'desc'
        } else {
            // Fresh sort on other columns defaults to asc
            newSortOrder = 'asc'
        }

        const queryParams = queryString.parse(this.props.location.search, {
            arrayFormat: 'bracket',
        })

        queryParams['sort_column'] = newSortColumn
        queryParams['sort_order'] = newSortOrder

        let newPath =
            '/leads?' + queryString.stringify(queryParams, { arrayFormat: 'bracket' })

        this.props.history.push(newPath)
    }

    updateValue = (key, value, callback) => {
        // let data = Object.assign({}, this.state.selectedFilters)
        let data = cloneDeep(this.state.selectedFilters)
        data[key] = value

        if (['date_from', 'date_to'].includes(key)) {
            data['date_range_id'] = 'custom'
        }
        this.setState({ selectedFilters: data }, callback)
    }

    updateValues = (data, callback) => {
        // let _selectedFilters = Object.assign({}, this.state.selectedFilters)
        const _selectedFilters = cloneDeep(this.state.selectedFilters)

        Object.keys(data).forEach((key) => {
            _selectedFilters[key] = data[key]

            if (['data_from', 'date_to'].includes(key)) {
                _selectedFilters['date_range_id'] = 'custom'
            }
        })

        this.setState({ selectedFilters: _selectedFilters }, callback)
    }

    clearOutModalContent = () => {
        this.setState({
            modalContent: null,
            modalHeader: null,
            modalWidth: null,
            modalFlatBottom: null,
        })
    }

    /**
     * @todo This method is everywhere, I think.  How can we consolidate this?
     * @param modalContent
     * @param modalHeader
     * @param modalWidth
     * @param modalFlatBottom
     * @param modalFooter
     */
    updateModalContent = (
        modalContent,
        modalHeader,
        modalWidth,
        modalFlatBottom,
        modalFooter
    ) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom,
            modalFooter: modalFooter,
        })
    }

    render() {
        let minConfidence = this.state.leadFilteredSummary
            ? Number(this.state.leadFilteredSummary.minConfidence)
            : 0
        let revenueLocked =
            !AuthService.isAdmin &&
            minConfidence > this.state.leadFilteredSummary.revenueConfidence

        return (
            <div className="page-width-wide">
                <Modal
                    content={this.state.modalContent}
                    header={this.state.modalHeader}
                    footer={this.state.modalFooter}
                    width={this.state.modalWidth}
                    flatBottom={this.state.modalFlatBottom}
                    updateModalContent={this.updateModalContent}
                    scrollerRef={this.modalScrollerRef}
                    modalContainerRef={this.modalContainerRef}
                />
                <AdminLeadManagerSecondaryNav
                    filterMessage={filterMessage(this.state, {
                        leadStatusOptions: this.leadStatusOptions(),
                        defaultFilters: this.state.defaultFilters,
                        adNetworkOptions: this.adNetworkOptions(),
                        parentContractorOptions: this.parentContractorOptions(),
                        managerAccountOptions: this.managerAccountOptions(),
                        clientOptions: this.clientOptions(),
                        contractorTypeOptions: this.state.contractorTypes,
                        leadReviewCompleteOptions: this.leadReviewCompleteOptions(),
                    })}
                    secondaryNavRef={this.props.secondaryNavRef}
                />
                <FilterLeadsPanel
                    adNetworks={this.state.adNetworks}
                    serviceCategories={this.state.serviceCategories}
                    leadStatuses={this.state.leadStatuses}
                    managerAccounts={this.state.managerAccounts}
                    parentContractors={this.state.parentContractors}
                    clients={this.state.clients}
                    campaigns={this.state.campaigns}
                    selectedFilters={this.state.selectedFilters}
                    defaultFilters={ this.state.defaultFilters }
                    updateValue={this.updateValue}
                    updateValues={this.updateValues}
                    updateDateValue={this.updateDateValue}
                    applyFilters={this.applyFilters}
                    resetFilters={this.resetFilters}
                    leadStatusOptions={this.leadStatusOptions()}
                    contractorTypeOptions={this.state.contractorTypes}
                    adNetworkOptions={this.adNetworkOptions()}
                    serviceCategoryOptions={this.serviceCategoryOptions()}
                    parentContractorOptions={this.parentContractorOptions()}
                    managerAccountOptions={this.managerAccountOptions()}
                    clientOptions={this.clientOptions()}
                    campaignOptions={this.campaignOptions()}
                    updateCampaignOptions={this.updateCampaignOptions}
                    leadReviewCompleteOptions={this.leadReviewCompleteOptions()}
                />
                <div className="row">
                    <div className="wide-format-col page__contentbox">
                        <LeadLogHeadline
                            setRowsPerPage={this.setRowsPerPage}
                            rowsPerPage={this.state.pagination.rows_per_page}
                            managerViewState={this.state}
                            leadStatusOptions={this.leadStatusOptions}
                            contractorTypeOptions={() => this.state.contractorTypes}
                            adNetworkOptions={this.adNetworkOptions}
                            serviceCategoryOptions={this.serviceCategoryOptions}
                            parentContractorOptions={this.parentContractorOptions}
                            managerAccountOptions={this.managerAccountOptions}
                            clientOptions={this.clientOptions}
                            campaignOptions={this.campaignOptions}
                            leadReviewCompleteOptions={this.leadReviewCompleteOptions}
                            defaultFilters={this.state.defaultFilters}
                        />
                        {this.state.loadingResults ? (
                            <LoadingGif backgroundColor="#fff" />
                        ) : (
                            <LeadLog
                                leads={this.state.leads}
                                updateLead={this.updateLead}
                                updateClassification={this.updateClassification}
                                getLead={this.getLead}
                                updateNote={this.updateNote}
                                updateCallInsights={this.updateCallInsights}
                                updateMessageBlocks={this.props.updateMessageBlocks}
                                updateModalContent={this.updateModalContent}
                                adminView={true}
                                sortColumn={this.state.appliedFilters.sort_column}
                                sortOrder={this.state.appliedFilters.sort_order}
                                updateSort={this.updateSort}
                                users={this.state.users}
                                leadStatusOptions={this.leadStatusOptions}
                            />
                        )}
                        <div className="spacing-30-top">
                            <div className="simpleflex__row">
                                <div className="simpleflex__cell simpleflex__cell__middlealigned">
                                    <ExportLeadsButton
                                        totalRows={this.state.pagination.total_rows}
                                        apiQueryStr={this.apiQueryStr}
                                    />
                                </div>
                                <div className="simpleflex__cell simpleflex__cell__middlealigned simpleflex__cell__one-half">
                                    <Pagination
                                        pageNum={this.state.pagination.page_num}
                                        totalRows={this.state.pagination.total_rows}
                                        rowsPerPage={this.state.pagination.rows_per_page}
                                        totalPages={this.state.pagination.total_pages}
                                        handlePageChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>

                        {this.state.leadFilteredSummary && (
                            <div className="spacing-30">
                                <div className="lead-filters__footer-stats">
                                    <div className="type-large-body type-heavy type-centered">
                                        Performance Metrics for Currently Filtered Leads
                                    </div>
                                    <div className="spacing-30 lead-filters__footer-stats__grid">
                                        <div className="lead-filters__footer-stats__grid-cell">
                                            <div className="type-large-subhead type-orange type-heavy type-single-line">
                                                {this.state.leadFilteredSummary
                                                    .billableLeadsCount === undefined ? (
                                                    <LoadingGif
                                                        size="small"
                                                        color="gray"
                                                        backgroundColor="white"
                                                    />
                                                ) : (
                                                    formatNumber(
                                                        this.state.leadFilteredSummary
                                                            .billableLeadsCount,
                                                        0
                                                    )
                                                )}
                                            </div>
                                            <div className="type-normal-body type-narrow-line-height">
                                                Billable Leads
                                            </div>
                                        </div>
                                        <div className="lead-filters__footer-stats__grid-cell">
                                            <div className="type-large-subhead type-orange type-heavy type-single-line">
                                                {this.state.leadFilteredSummary
                                                    .billableLeadsCost === undefined ? (
                                                    <LoadingGif
                                                        size="small"
                                                        color="gray"
                                                        backgroundColor="white"
                                                    />
                                                ) : (
                                                    formatCurrency(
                                                        this.state.leadFilteredSummary
                                                            .billableLeadsCost,
                                                        0
                                                    )
                                                )}
                                            </div>
                                            <div className="type-normal-body type-narrow-line-height">
                                                Cost of Billable Leads
                                            </div>
                                        </div>
                                        <div className="lead-filters__footer-stats__grid-cell">
                                            <div className="type-large-subhead type-orange type-heavy type-single-line">
                                                {this.state.leadFilteredSummary
                                                    .leadsWithRevenueRevenue ===
                                                undefined ? (
                                                    <LoadingGif
                                                        size="small"
                                                        color="gray"
                                                        backgroundColor="white"
                                                    />
                                                ) : (
                                                    formatCurrency(
                                                        this.state.leadFilteredSummary
                                                            .leadsWithRevenueRevenue,
                                                        0
                                                    )
                                                )}
                                            </div>
                                            <div className="type-normal-body type-narrow-line-height">
                                                Leads Marked with Revenue
                                            </div>
                                        </div>
                                        <div className="lead-filters__footer-stats__grid-cell">
                                            <LeadSummaryCell
                                                statContent={
                                                    this.state.leadFilteredSummary.roi ===
                                                    undefined ? (
                                                        <LoadingGif
                                                            size="small"
                                                            color="gray"
                                                            backgroundColor="white"
                                                        />
                                                    ) : revenueLocked ? (
                                                        IconLockSvg
                                                    ) : this.state.leadFilteredSummary
                                                          .roi > 0 ||
                                                      AuthService.isAdmin ? (
                                                        `${formatNumber(
                                                            this.state.leadFilteredSummary
                                                                .roi,
                                                            0
                                                        )}%`
                                                    ) : (
                                                        'N/A'
                                                    )
                                                }
                                                statType={revenueLocked ? 'icon' : ''}
                                                statIconSizeClass="inline-icon__22"
                                                statTypeClassString="type-large-subhead type-orange type-heavy type-single-line"
                                                bodyTypeSizeClass="type-normal-body"
                                                statDescription="ROI of Managed Leads"
                                                color={revenueLocked ? 'gray' : 'orange'}
                                                confidence={
                                                    this.state.leadFilteredSummary
                                                        .revenueConfidence
                                                }
                                                width={null}
                                                gutter={null}
                                                toolTipTitle="ROI of Managed Leads"
                                                toolTipBody={
                                                    <>
                                                        <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                                            This metric is an estimate of
                                                            your Return On Investment of
                                                            Managed Leads, and is
                                                            calculated based on the Job
                                                            Revenue you track in Lead
                                                            Manager for jobs you have won.
                                                        </span>
                                                        <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                                            This metric is{' '}
                                                            <span className="type-heavy">
                                                                Locked
                                                            </span>{' '}
                                                            until you have tracked enough
                                                            Job Revenue data for Leads
                                                            with Job Progress set as Won.
                                                            Once unlocked, the{' '}
                                                            <span className="type-heavy">
                                                                Confidence Percentage
                                                            </span>{' '}
                                                            reflects how confident we are
                                                            in the accuracy of this
                                                            metric. The more Leads you
                                                            Manage, the more accurate
                                                            these metrics become.
                                                        </span>
                                                        <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                                            <i>
                                                                Note: A Billable Lead is
                                                                considered "Managed" when
                                                                Lead Progress has been
                                                                set.
                                                            </i>
                                                        </span>
                                                        <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                                            Learn more about{''}
                                                            <a
                                                                className="type-heavy"
                                                                target="_blank"
                                                                href="https://support.servicedirect.com/select/lead-performance-metrics"
                                                            >
                                                                Understanding Lead
                                                                Performance Metrics.
                                                            </a>
                                                        </span>
                                                    </>
                                                }
                                                toolTipPosition="top"
                                                modalContainerRef={this.tooltipRef}
                                            />
                                        </div>
                                    </div>
                                    {Object.keys(this.state.leadFilteredSummary).length >
                                        0 && (
                                        <div className="footer-lead-filters__view-performance-stats">
                                            <span
                                                role="button"
                                                className="type-normal-body type-heavy type-blue"
                                                onClick={this.openFilteredLeadsStatsModal}
                                            >
                                                <span className="inline-icon inline-icon__middle inline-icon__24">
                                                    {IconStatsSvg}
                                                </span>{' '}
                                                View All Performance Metrics
                                            </span>{' '}
                                            for these Leads
                                            <span className="type-flag type-flag--beta">
                                                BETA
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
};

AdminLeadManagerView.propTypes = {
    secondaryNavRef: PropTypes.object,
    updateMessageBlocks: PropTypes.func,
};
