import React, { Component } from 'react';

export default class BulkEditCampaignsStep2Footer extends Component {
    render() {
        return (
            <>
            <div className="ui-hide-full ui-hide-tablet">
                <div className="popup__form__row spacing-40-top-mobile">
                    <div className="popup__form__cell popup__form__cell__100__mobile">
                        <button
                            className="button ui-normal-button ui-full-width-button qa-continue-button-mobile"
                            onClick={ () => {
                                //
                                // leadDeliverySettings will be undefined for all cases but
                                // Phone Lead Delivery and Form Lead Delivery, which is expected
                                //
                                this.props.commitSettings(this.props.confirmationHeader,
                                                          this.props.leadDeliverySettings);
                            }}
                        >
                            Continue to Confirmation Screen
                        </button>
                    </div>
                </div>
            </div>
            <div className="ui-hide-mobile">
                <div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
                    <div className="popup__form__row popup__form__row__slam-right">
                        <div className="popup__form__cell">
                            <span role="button" className="type-small-body type-heavy type-blue"
                                onClick={() => this.props.updateModalContent()}
                            >
                                Cancel Edits
                            </span>
                        </div>
                        <div className="popup__form__cell">
                            <button
                                className="button ui-normal-button qa-continue-button-full"
                                onClick={ () => {
                                    //
                                    // leadDeliverySettings will be undefined for all cases but
                                    // Phone Lead Delivery and Form Lead Delivery, which is expected
                                    //
                                    this.props.commitSettings(this.props.confirmationHeader,
                                                              this.props.leadDeliverySettings);
                                }}
                            >
                                Continue to Confirmation Screen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}
