import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ServiceAreaText from "../../common/components/service_category/ServiceAreaText";
import OtherServices from "../../common/OtherServices";
import MultiSelectList from '../../common/components/form_elements/MultiSelectList';

export default class ServiceCategoryAndGeo extends Component {
    getServiceCategoryName = (industry_id) => {
        if (!industry_id) {
            return '';
        }

        let serviceCategoriesArray = this.props.dropdownValues;
        for (let index in serviceCategoriesArray) {
            let serviceObject = serviceCategoriesArray[index];
            if (Math.abs(serviceObject.industry_id) === Math.abs(industry_id)) {

                return serviceObject.name;
            }
        }

        return "";
    };

    updateIndustryId = (vals) => {
        let industry_id = vals[0];
        this.setState({industry_id: industry_id});
        this.props.updateServiceCategoryAndGeo(industry_id, 'industry_id', this.props.arrayIndex);
    };

    addNewServiceArea = () => {
        this.props.updateServiceCategoryAndGeo(
            {
                zip_codes: {
                    zips: [],
                    cities: []
                },
                main_zip_code: '',
                radius: 10
            },
            'service_area',
            this.props.arrayIndex
        );
    };

    render() {
        let industry_id = this.props.serviceCategory.industry_id;
        let serviceCategoryName = this.getServiceCategoryName(industry_id.value);
        let dropdownValues = this.props.dropdownValues;
        let error = this.props.serviceCategory.industry_id.error;
        let hasError = !!error;
        let arrayIndex = this.props.arrayIndex;
        let required = arrayIndex === 0;
        return (
            <div className="padding-30-bottom">
                <div>
                    <div className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                        <MultiSelectList
                            hasError={hasError}
                            label="Service Category"
                            selections={[industry_id.value || '']}
                            name={'service_category_' + this.props.arrayIndex}
                            onChange={this.updateIndustryId}
                            options={dropdownValues}
                            getOptionValue={(option) => option.industry_id}
                            getOptionText={(option) => option.name}
                            useTextFilter={true}
                            required={required}
                            inputRef={industry_id.ref}
                            addOn={
                                <span
                                    className="type-small-body type-blue type-heavy type-left-side-bump pointer"
                                    onClick={() =>
                                        this.props.removeServiceCategory(
                                            this.props.arrayIndex
                                        )
                                    }
                                >
                                    Remove
                                </span>
                            }
                        />
                    </div>
                    <div className="form__cell form__cell__50" />
                </div>

                {
                    <OtherServices
                        serviceCategory={this.props.serviceCategory}
                        updateOtherText={this.props.updateServiceCategoryAndGeo}
                        serviceCategoryIndex={this.props.arrayIndex}
                    />
                }

                <p className="type-normal-body">
                    <b className="type-heavy">Note</b>: if you discover a map image that
                    is strange, please send a screenshot and the main zip code to Dev
                </p>
                {this.props.serviceCategory.service_area.value.map(
                    (serviceArea, index) => (
                        <ServiceAreaText
                            key={`service_area_${index}`}
                            serviceAreaIndex={index}
                            arrayIndex={this.props.arrayIndex}
                            name="geo"
                            updateFunc={(value) =>
                                this.props.updateServiceCategoryAndGeo(
                                    value,
                                    'service_area',
                                    this.props.arrayIndex,
                                    index
                                )
                            }
                            positionInGroup={this.props.arrayIndex}
                            updateModalContent={this.props.updateModalContent}
                            updateMessageBlocks={this.props.updateMessageBlocks}
                            industryName={serviceCategoryName}
                            closeModal={this.props.closeModal}
                            serviceArea={serviceArea}
                            serviceAreaTextOnBlur={this.props.serviceAreaTextOnBlur}
                            serviceAreaRequired={this.props.serviceAreaRequired}
                            stateInputKey={this.props.serviceCategoryStateKey}
                            hasMultipleServiceAreas={
                                this.props.serviceCategory.service_area.value.length > 1
                            }
                            country={this.props.country}
                        />
                    )
                )}
                <p className="type-normal-body">
                    Do you serve multiple locations or non-contiguous areas? If so,{' '}
                    <button
                        className="button-clean type-blue type-normal-body"
                        onClick={this.addNewServiceArea}
                    >
                        add an additional service area for another location
                    </button>
                </p>
            </div>
        )
    }
}

ServiceCategoryAndGeo.propTypes = {
    dropdownValues: PropTypes.array.isRequired,
    updateServiceCategoryAndGeo: PropTypes.func.isRequired,
    serviceCategory: PropTypes.object.isRequired,
    removeServiceCategory: PropTypes.func.isRequired,
};
