import React, { useState, useEffect } from 'react'
import { useReward } from 'react-rewards'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
import _times from 'lodash/times'
import { formatNumber, removeWordFromString, toTitleCase } from '../../../Util'
import { StatWrapper, Wrapper } from './styles'
import Metric, { MetricSkeleton } from '../../../common/components/Metric'
/**
 * @typedef {Object} LeadReviewData
 * @property {number} count_my_reviews_today
 * @property {number} count_my_reviews_this_week
 * @property {number} count_my_reviews_this_month
 * @property {number} count_all_reviews_today
 * @property {number} count_all_reviews_this_week
 * @property {number} count_all_reviews_this_month
 */
export default ({
    /** @type {LeadReviewData} */ leadReviewData = {},
    /** @type {boolean} */ loading = false,
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)
    const [tooltipContent, setTooltipContent] = useState()
    const [tooltipTheme, setTooltipTheme] = useState()
    // reward thresholds
    const CELE_THRESHOLDS = {
        count_my_reviews_this_month: 1500,
    }
    // reward hook configuration
    const { reward: confettiReward } = useReward('reward_span', 'confetti', {
        lifetime: 400,
        elementCount: 100,
    })

    const displayCele = (statName) => {
        setTooltipContent(`🎉 ${formatNumber(CELE_THRESHOLDS[statName], 0)}+ Reviews! Great Work!`)
        setTooltipTheme('rainbow')
        // celebrate()
        confettiReward()
        setIsTooltipVisible(true)
        setTimeout(() => setIsTooltipVisible(false), 5000)
    }

    const handleClickStat = (statName) => () => {
        if (leadReviewData[statName] >= CELE_THRESHOLDS[statName]) {
            displayCele(statName)
        } else {
            setTooltipContent(`Keep it up!`)
            setTooltipTheme(null)
            setIsTooltipVisible(true)
            setTimeout(() => setIsTooltipVisible(false), 5000)
        }
    }

    // show the right celebration on initial load
    useEffect(() => {
        if (!loading) {
            const reviews = leadReviewData?.count_my_reviews_this_month
            if (reviews >= CELE_THRESHOLDS.count_my_reviews_this_month) {
                displayCele('count_my_reviews_this_month')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadReviewData.count_my_reviews_this_month, loading])
    // skeleton when loading
    if (loading) {
        return (
            <Wrapper>
                <div>
                    {_times(3, (i) => (
                        <StatWrapper key={`my-${i}`}>
                            <MetricSkeleton />
                        </StatWrapper>
                    ))}
                </div>
                <div>
                    {_times(3, (i) => (
                        <StatWrapper key={`all-${i}`}>
                            <MetricSkeleton />
                        </StatWrapper>
                    ))}
                </div>
            </Wrapper>
        )
    }

    if (leadReviewData == null || leadReviewData == {}) {
        return <></>
    }

    const allStats = []
    const myStats = []
    Object.keys(leadReviewData).forEach((key) => {
        const isRewardMetric = key == 'count_my_reviews_this_month'
        let metric = (
            <Metric
                value={formatNumber(leadReviewData[key], 0)}
                label={toTitleCase(removeWordFromString(key, 'count'))}
            />
        )
        if (isRewardMetric) {
            metric = (
                <Tippy
                    content={tooltipContent}
                    allowHTML={true}
                    visible={isTooltipVisible}
                    animation="scale"
                    theme={tooltipTheme}
                >
                    {metric}
                </Tippy>
            )
        }
        const res = (
            <StatWrapper key={key} isClickable={isRewardMetric} onClick={isRewardMetric ? handleClickStat(key) : null}>
                {/* for 'my reviews today', add the reward span */}
                {isRewardMetric && <span id="reward_span" />}
                {metric}
            </StatWrapper>
        )
        if (key.includes('my')) {
            myStats.push(res)
        } else {
            allStats.push(res)
        }
    })

    return (
        <Wrapper>
            <div>{myStats}</div>
            <div>{allStats}</div>
        </Wrapper>
    )
}
