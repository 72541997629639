import React from 'react'
import ContextMenu from '../../../common/components/ContextMenu'
import SkeletonText from '../../../common/components/Skeleton/SkeletonText'
import {
    capitalizeFirstLetter,
    formatCurrency,
    formatNumber,
    getCampaignMenuLinks,
    getClientMenuLinks,
} from '../../../Util'
import { MarginPercent, Dot } from './styles'

/**
 * This function takes a column input from the SD Marketplace Campaign Optimizer
 * API and produces a column definition compatable with the AG Grid table.
 * @see https://www.ag-grid.com/javascript-data-grid/column-definitions/
 */
const defaultOptions = {
    summaryLoading: false,
}
export default (col, options = defaultOptions) => {
    const { summaryLoading } = { ...defaultOptions, ...options }
    const { property, label, format = 'string', width, ...rest } = col
    delete rest.sort_group
    const formatMap = {
        currency: (col) => ({
            valueGetter: (params) => +params?.node?.data[property],
            field: property,
            // valueFormatter: ({ value = 0 }) => formatCurrency(value),
            cellRenderer: (params) => {
                let spanClass
                let val
                if (params?.node?.data[property] != null) {
                    val = formatCurrency(params?.node?.data[property] || 0, 0)
                } else {
                    val = '$ --'
                }
                // case for summary (pinned) bottom row
                if (params.node?.rowPinned) {
                    spanClass = 'type-heavy'
                    if (params?.node?.data[property] == null) {
                        val = null
                    }
                    // case when the summary is loading
                    if (summaryLoading) {
                        val = <SkeletonText />
                    }
                }

                return <span className={spanClass}>{val}</span>
            },
        }),
        string: (col) => ({
            field: property,
            valueGetter: (params) => params?.node?.data[property],
            cellRenderer: (params) => {
                let spanClass = []
                let val
                if (params?.node?.data[property] != null) {
                    val = capitalizeFirstLetter(`${params?.node?.data[property]}` || '')
                } else {
                    val = null
                }

                // case for summary (pinned) bottom row
                if (params.node?.rowPinned) {
                    spanClass.push('type-heavy')
                    if (params?.node?.data[property] == null) {
                        val = null
                    }
                }
                // color the following words red
                const redVals = ['Disconnected', 'Paused']
                if (redVals.includes(val)) {
                    spanClass.push('type-alert')
                }
                return <span className={spanClass.join(' ')}>{val}</span>
            },
        }),
        percent: (col) => ({
            field: property,
            valueGetter: (params) => +params?.node?.data[property],
            cellRenderer: (params) => {
                let spanClass
                let val
                if (params?.node?.data[property] != null) {
                    val = `${formatNumber(params?.node?.data[property] || 0, 2)} %`
                } else {
                    val = '-- %'
                }

                // case for summary (pinned) bottom row
                if (params.node?.rowPinned) {
                    if (params?.node?.data[property] == null) {
                        val = null
                    }
                    spanClass = 'type-heavy'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        number: (col) => ({
            field: property,
            valueGetter: (params) => +params?.node?.data[property],
            cellRenderer: (params) => {
                // case for the summary (pinned) row
                let spanClass
                let val
                if (params?.node?.data[property] != null) {
                    val = formatNumber(params?.node?.data[property] || 0, 0)
                } else {
                    val = '--'
                }
                if (params.node?.rowPinned) {
                    spanClass = 'type-heavy'
                    if (params?.node?.data[property] == null) {
                        val = null
                    }
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        bidMultiplier: (col) => ({
            valueGetter: (params) => {
                let val = 'Flat'
                const { data } = params?.node
                const { bid_type, form_bid_type } = data
                if (
                    bid_type == 'bid_multiplier' ||
                    bid_type == 'forced_multiplier' ||
                    form_bid_type == 'form_bid_multiplier' ||
                    form_bid_type == 'form_forced_multiplier'
                ) {
                    val = +data[property]
                }
                if (params.node?.rowPinned) {
                    val = null
                }
                return val
            },
            cellRenderer: (params) => {
                let val = 'Flat'
                const { data } = params?.node
                const { bid_type, form_bid_type } = data
                if (
                    bid_type == 'bid_multiplier' ||
                    bid_type == 'forced_multiplier' ||
                    form_bid_type == 'form_bid_multiplier' ||
                    form_bid_type == 'form_forced_multiplier'
                ) {
                    val = formatNumber(+data[property], 2)
                }
                if (params.node?.rowPinned) {
                    val = null
                }
                let spanClass = ''
                // case for phone bid multiplier - ensure that the bid type matches
                // the property
                if (property == 'bid_multiplier' && bid_type == 'forced_multiplier') {
                    spanClass = 'type-alert'
                }
                // case for form bid multiplier - ensure that the bid type matches
                // the property
                if (property == 'form_bid_multiplier' &&  form_bid_type == 'form_forced_multiplier') {
                    spanClass = 'type-alert'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        marginPercent: (col) => ({
            valueGetter: (params) => +params.data.margin_percent,
            cellRenderer: (params) => {
                let color
                const { margin_percent } = params.data
                let marginDisplay = `${formatNumber(margin_percent, 2)} %`

                const mp = Number(margin_percent)
                if (mp < 15) {
                    color = '#fd0606'
                } else if (mp >= 15 && mp < 25) {
                    color = '#fa843b'
                } else if (mp >= 25 && mp < 35) {
                    color = '#fed329'
                } else if (mp >= 35 && mp < 45) {
                    color = '#58bc24'
                } else if (mp >= 45) {
                    color = '#00dfff'
                }

                // adjust for null values
                if (margin_percent == null) {
                    marginDisplay = '-- %'
                }

                // case for the summary (pinned) row
                let spanClass
                if (params.node?.rowPinned) {
                    spanClass = 'type-heavy'
                }

                return (
                    <MarginPercent>
                        <Dot color={color} />
                        <span className={spanClass}>{marginDisplay}</span>
                    </MarginPercent>
                )
            },
        }),
        campaignMenu: (col) => ({
            valueGetter: (params) => params.data.campaign_name,
            cellRenderer: (params) => {
                const clientMenuLinks = getCampaignMenuLinks({
                    campaignId: params.data.campaign_id,
                    contractorId: params.data.contractor_id,
                    showViewCampaign: true,
                })
                // for the pinned row (bottom summary row) show the number of campaigns
                if (params.node?.rowPinned) {
                    if (summaryLoading) {
                        return <SkeletonText />
                    }
                    let label = 'Campaign'
                    if (params.data.campaign_name > 1) {
                        label += 's'
                    }
                    return (
                        <span>
                            <span className="type-heavy">
                                {params.data.campaign_name}
                            </span>
                            {` ${label}`}
                        </span>
                    )
                }
                return (
                    <ContextMenu
                        links={clientMenuLinks}
                        linkLabel={params.data.campaign_name}
                        placement={'bottom-start'}
                    />
                )
            },
        }),
        clientMenu: (col) => ({
            valueGetter: (params) => params.data.client_name,
            cellRenderer: (params) => {
                const clientMenuLinks = getClientMenuLinks(
                    params.data.contractor_id,
                    params.data.hubspot_company_id
                )
                // for the pinned row (bottom summary row) show the number of clients
                if (params.node?.rowPinned) {
                    if (summaryLoading) {
                        return <SkeletonText />
                    }
                    let label = 'Client'
                    if (Number(params.data.client_name) > 1) {
                        label += 's'
                    }
                    return (
                        <span>
                            <span className="type-heavy">{params.data.client_name}</span>
                            {` ${label}`}
                        </span>
                    )
                }
                return (
                    <ContextMenu
                        links={clientMenuLinks}
                        linkLabel={params.data.client_name}
                        placement={'bottom-start'}
                    />
                )
            },
        }),
    }

    return {
        headerName: label,
        ...formatMap[format](col),
        width: width || 150,
        ...rest,
    }
}
