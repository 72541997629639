import React from 'react';
import PropTypes from 'prop-types';

import LoadingGif from '../common/components/LoadingGif';

const NavigationFooter = (props) => {
    const braintreeMerchantId = process.env.REACT_APP_BRAINTREE_MERCHANT_ID;

    return (
        <div className="signup-navigation-footer">
            <div className="signup-navigation__braintree">
                {props.braintree && (
                    <a href={`https://www.braintreegateway.com/merchants/${braintreeMerchantId}/verified`} target="_blank">
                        <img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png"
                             className="braintree-logo" alt="Braintree"/>
                    </a>
                )}
            </div>
            <div className="signup-navigation__buttons">
                <div className="type-centered">
                    {props.processing
                        ? <LoadingGif />
                        : <button className="button ui-large-button ui-full-width-button-mobile"
                                  onClick={props.nextStep} type="submit">
                            <span className="type-hide-full type-hide-tablet">Continue</span>
                            <span className="type-hide-mobile">{props.buttonLabel ? props.buttonLabel : 'Continue to next step'}</span>
                            <img src="/images/icon-next-arrow.svg" alt="Right Arrow" className="right"/>
                        </button>}
                    {typeof props.previousStep === 'string' && !props.processing &&
                        <div className="spacing-20-top">
                            <button type="button" onClick={() => props.history.push(props.previousStep)}
                               className="type-normal-body ui-full-width-button-mobile button-clean sa-back-button">
                                <span className="type-hide-full type-hide-tablet">Previous</span>
                                <span className="type-hide-mobile">Back to previous step</span>
                            </button>
                        </div>}
                </div>
            </div>
        </div>
    );
};

export default NavigationFooter;

NavigationFooter.propTypes = {
    nextStep: PropTypes.func.isRequired,
    previousStep: PropTypes.string,
    processing: PropTypes.bool,
    braintree: PropTypes.bool
};
