import React from 'react';

const LearnMoreFooter = () => {
	return (
		<>
		<hr className="spacing-90-top" />
		<h3 className="type-large-body type-heavy spacing-30-top">
			Learn more about how Service Direct works:
		</h3>
		<ul className="type-large-body type-heavy unstyled-list">
			<li>
				<a href="https://support.servicedirect.com/videos">View Video Library</a>
			</li>
			<li>
				<a href="https://support.servicedirect.com">Visit the Help Center</a>
			</li>
			<li>
				<a href="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Product%20Marketing/Info%20Sheets%20and%20User%20Guides/Service%20Direct%20Marketplace%20for%20Clients/ServiceDirect-Marketplace-Clients-Success-HomeServices.pdf">Download the Success with Service Direct PDF</a>
			</li>
		</ul>
		</>
	);
};

export default LearnMoreFooter;