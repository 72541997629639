import React, { Fragment, useState } from 'react'
import sortBy from 'lodash/sortBy'
import Button from '../../../../../common/components/form_elements/Button'
import Input from '../../../../../common/components/form_elements/Input'
import MultiSelectList from '../../../../../common/components/form_elements/MultiSelectList'
import AddEditRules from '../AddEditRules'
import { Wrapper, InputWrapper, ActionsWrapper } from './styles'
import { rulesAreValid } from './utils'
import { ContentWindowScroll } from '../../../../../common/Modal'

export default (props) => {
    const {
        adNetworks,
        serviceCategories,
        affiliates,
        onClose,
        onPreview,
        changes,
        selectedAffiliate,
        affiliateRules,
    } = props
    const [affiliateId, setAffiliateId] = useState(selectedAffiliate?.ad_network_id)
    const [affiliate, setAffiliate] = useState(selectedAffiliate)
    const [ruleChanges, setRuleChanges] = useState(changes)

    const handleSelectAffiliate = (ids) => {
        setAffiliateId(ids[0])
        const a = affiliates?.filter((a) => a.ad_network_id === ids[0])[0]
        setAffiliate(a)
    }

    const handleRuleChanges = (_changes) => {
        setRuleChanges(_changes)
    }

    const rulesView = affiliateId ? (
        <AddEditRules
            affiliate={affiliate}
            serviceCategories={serviceCategories}
            affiliateId={affiliateId}
            changes={changes}
            onChange={handleRuleChanges}
            affiliateRules={affiliateRules}
            showBlankRuleByDefault={true}
        />
    ) : null

    // if this view is loaded with a selected affiliate, render the input as plain text
    const affiliateView = selectedAffiliate ? (
        <Input
            name="affiliate_name_read_only"
            type="text"
            label="Lead Source"
            value={
                adNetworks?.values.filter((v) => v.value === affiliateId)[0]?.text || ''
            }
            readOnly={true}
        />
    ) : (
        <MultiSelectList
            name={adNetworks?.name}
            label="Lead Source"
            emptyLabel={'Required'}
            options={sortBy(adNetworks?.values, 'text')}
            selections={affiliateId && [affiliateId]}
            onChange={handleSelectAffiliate}
            useTextFilter={true}
        />
    )

    const isPreviewEnabled = ruleChanges != null && rulesAreValid(ruleChanges)
    const previewButtonText = 'Preview Changes'

    return (
        <Fragment>
            <ContentWindowScroll>
                <Wrapper>
                    <InputWrapper>{affiliateView}</InputWrapper>
                    {rulesView}
                </Wrapper>
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button onClick={onClose} variant="text">
                    <span className="type-heavy">Cancel</span>
                </Button>
                <Button
                    disabled={!isPreviewEnabled}
                    onClick={() => {
                        onPreview({ ruleChanges, affiliate })
                    }}
                >
                    {previewButtonText}
                </Button>
            </ActionsWrapper>
        </Fragment>
    )
}
