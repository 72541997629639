import React, {Component} from 'react';
import PropType from 'prop-types';

import EditServiceAreaModal from "../../../client/signup/your_company/EditServiceAreaModal";
import {CloseSvg, PencilSvg} from "../../Svgs";
import {imagesUrls} from "../../StaticValues";

export default class ServiceAreaText extends Component {
    isCanada = false;

    constructor(props) {
        super(props);

        this.isCanada = this.props.country == 'CAN';
    }

    editZips = () => {
        this.props.updateModalContent(
            <EditServiceAreaModal
                zipCodes={this.props.serviceArea.zip_codes.zips}
                mainZipCode={this.props.serviceArea.main_zip_code}
                radius={this.props.serviceArea.radius}
                updateMessageBlocks={this.props.updateMessageBlocks}
                closeModal={this.props.closeModal}
                handleChange={this.props.updateFunc}
                country={this.props.country}
            />,
            {
                header: `Edit Service Area for ${this.props.industryName}`,
                wide: true
            }
        );
    };

    get mapImage() {
        if (this.props.serviceArea.map) {
            return this.props.serviceArea.map;
        }

        return this.isCanada
            ? imagesUrls.canadaMapPlaceholder
            : imagesUrls.usMapPlaceholder;
    };

    render() {
        return <div className="form__row spacing-18 spacing-40-bottom">
            <div className="form__cell form__cell__100">
                <h3 className="type-normal-body type-heavy type-single-line spacing-10-bottom">
                    Your Service Area
                    {this.props.hasMultipleServiceAreas && ` #${this.props.serviceAreaIndex + 1}`}
                    {this.props.industryName && ` (${this.props.industryName})`}
                </h3>
                {this.props.serviceArea.zip_codes !== undefined &&
                <div>
                    <button
                        className={`button-clean type-heavy type-blue type-align-left image-button${this.props.serviceArea.map ? ' image-text-info' : ''}`}
                        onClick={this.editZips} type="button">
                        <img src={this.mapImage} alt="Service Area Map"
                             role="button" onClick={this.editZips}
                             className="static-map-image"/>
                        <span className="inline-icon inline-icon__16 inline-icon__16__10">
                            {PencilSvg}
                        </span>
                        Edit
                    </button>
                    <p className="spacing-10 type-normal-body">
                        {this.props.serviceArea.zip_codes.cities.join(', ')}
                    </p>
                    <p className="spacing-10 type-normal-body">
                        {this.props.serviceArea.zip_codes.zips.join(', ')}
                    </p>

                    {this.props.hasMultipleServiceAreas &&
                    <button className="button-clean type-heavy type-blue button-sibling"
                            onClick={() => this.props.updateFunc(false)}>
                        <span className="inline-icon inline-icon__middle inline-icon__16">
                            {CloseSvg}
                        </span>
                        Remove
                    </button>}
                </div>}
            </div>
        </div>;
    }
}

ServiceAreaText.propTypes = {
    updateModalContent: PropType.func.isRequired,
    arrayIndex: PropType.number,
    name: PropType.string,
    industryName: PropType.string,
    country: PropType.string,
    serviceArea: PropType.object,
    placeholder: PropType.string,
    hasMultipleServiceAreas: PropType.bool,
    updateFunc: PropType.func
};
