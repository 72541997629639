import React from 'react';
import PropTypes from 'prop-types';

import TextArea from "./components/form_elements/TextArea";

const OtherServices = (props) => {
    let updateNote = (event) =>
        props.updateOtherText(event.target.value, 'other_services', props.serviceCategoryIndex);

    return <>
        <div className="form__row spacing-10-bottom">
            <div className="form__cell form__cell__label form__cell__100 spacing-10-bottom">
                <label htmlFor="signup-other" className="type-normal-body type-heavy type-narrow-line-height">
                    Notes / Other
                </label>
            </div>
            <p className="type-normal-body">
                Please list any other service offerings or subcategories that aren't listed and/or
                any notes related to your selections above.
            </p>
        </div>

        <div className="form__row spacing-30-bottom">
            <div className="form__cell form__cell__100">
                <TextArea
                    className="ui-textarea__short"
                    name="signup-other"
                    value={props.serviceCategory.other_services.value}
                    onChange={updateNote}
                    onBlur={updateNote}
                    inputRef={props.serviceCategory.other_services.ref}
                    hasError={!!props.serviceCategory.other_services.error}
                    required={false}
                />
            </div>
        </div>
    </>;
}

export default OtherServices;

OtherServices.propTypes = {
    serviceCategory: PropTypes.object.isRequired,
    serviceCategoryIndex: PropTypes.number,
    updateOtherText: PropTypes.func.isRequired
};
