import { reduce } from 'lodash'
import React, { useState } from 'react'
import Button from '../../../common/components/form_elements/Button'
import SkeletonText from '../../../common/components/Skeleton/SkeletonText'
import { FullColumn } from '../../../common/layouts'
import { ContentRow, Actions, StyledListItem, StyledList } from './styles'

export default ({
    onCancel = () => null,
    onCompleteEdits = () => null,
    onGoBack = () => null,
    confirmation = {},
    edits,
}) => {
    const editVals = reduce(
        edits,
        (acc, o) => {
            acc[o.property] = o.value
            return acc
        },
        {}
    )

    const {
        countTotalPhoneUpdatedRows,
        countTotalFormUpdatedRows,
        countPhoneMaxBidMultiplier,
        countPhoneFlatBidRows,
        countPhoneForcedMultiplierRows,
        countFormMaxBidMultiplier,
        countFormFlatBidRows,
        countFormForcedMultiplierRows,
        countPhoneLeadsConnectedRows,
        countFormLeadsConnectedRows,
        countCampaignStatusRows,
    } = confirmation

    const [completeEditsDisabled, setCompleteEditsDisabled] = useState(false)
    const [actionOkText, setActionOkText] = useState('Complete Edits')
    const handleCompleteEdits = (e) => {
        setCompleteEditsDisabled(true)
        setActionOkText('Working')
        onCompleteEdits(edits)
    }

    return (
        <FullColumn>
            <ContentRow>
                <span className="type-normal-subhead">
                    The following Edits are ready to be made
                </span>
            </ContentRow>
            <ContentRow>
                <StyledList>
                    {countTotalPhoneUpdatedRows != null && (
                        <StyledListItem>
                            <span className="type-normal-body">
                                <span className="type-heavy">
                                    {countTotalPhoneUpdatedRows}
                                </span>{' '}
                                Campaign Lead Source Phone Bid Multiplier(s) will be
                                updated
                            </span>
                        </StyledListItem>
                    )}
                    {countTotalFormUpdatedRows != null && (
                        <StyledListItem>
                            <span className="type-normal-body">
                                <span className="type-heavy">
                                    {countTotalFormUpdatedRows}
                                </span>{' '}
                                Campaign Lead Source Form Bid Multiplier(s) will be
                                updated
                            </span>
                        </StyledListItem>
                    )}
                    {countPhoneMaxBidMultiplier != null && (
                        <StyledListItem>
                            <span className="type-normal-body">
                                <span className="type-heavy">
                                    {countPhoneMaxBidMultiplier}
                                </span>{' '}
                                Campaign Lead Source(s) will be set to the maximum 0.8{' '}
                                Phone Bid Multiplier
                            </span>
                        </StyledListItem>
                    )}
                    {countFormMaxBidMultiplier != null && (
                        <StyledListItem>
                            <span className="type-normal-body">
                                <span className="type-heavy">
                                    {countFormMaxBidMultiplier}
                                </span>{' '}
                                Campaign Lead Source(s) will be set to the maximum 0.8{' '}
                                Form Bid Multiplier
                            </span>
                        </StyledListItem>
                    )}
                    {countPhoneLeadsConnectedRows != null && (
                        <StyledListItem>
                            <span className="type-normal-body">
                                <span className="type-heavy">
                                    {countPhoneLeadsConnectedRows}
                                </span>{' '}
                                Campaign Lead Source(s) will have{' '}
                                <span className="type-heavy">
                                    Phone Leads{' '}
                                    {editVals.phone_leads_connected == '1'
                                        ? 'Connected'
                                        : 'Disconnected'}
                                </span>
                            </span>
                        </StyledListItem>
                    )}
                    {countFormLeadsConnectedRows != null && (
                        <StyledListItem>
                            <span className="type-normal-body">
                                <span className="type-heavy">
                                    {countFormLeadsConnectedRows}
                                </span>{' '}
                                Campaign Lead Source(s) will have{' '}
                                <span className="type-heavy">
                                    Form Leads{' '}
                                    {editVals.form_leads_connected == '1'
                                        ? 'Connected'
                                        : 'Disconnected'}
                                </span>
                            </span>
                        </StyledListItem>
                    )}
                    {countCampaignStatusRows != null && (
                        <StyledListItem>
                            <span className="type-normal-body">
                                <span className="type-heavy">
                                    {countCampaignStatusRows}
                                </span>{' '}
                                Campaign Status(es) will be set to{' '}
                                <span className="type-heavy">
                                    {editVals.campaign_status == '1'
                                        ? 'Enabled'
                                        : 'Paused'}
                                </span>
                            </span>
                        </StyledListItem>
                    )}
                </StyledList>
            </ContentRow>
            {(countPhoneFlatBidRows != null ||
                countPhoneForcedMultiplierRows != null ||
                countFormFlatBidRows != null ||
                countFormForcedMultiplierRows != null) && (
                <>
                    <ContentRow>
                        <span className="type-normal-subhead type-red">
                            The following Edits{' '}
                            <span className="type-heavy">will not</span> be made
                        </span>
                    </ContentRow>
                    <ContentRow>
                        <StyledList>
                            {countPhoneFlatBidRows != null && (
                                <StyledListItem>
                                    <span className="type-normal-body">
                                        <span className="type-heavy">
                                            {countPhoneFlatBidRows}
                                        </span>{' '}
                                        Campaign Lead Source(s) with a Phone Flat Bid
                                        Amount
                                    </span>
                                </StyledListItem>
                            )}
                            {countPhoneForcedMultiplierRows != null && (
                                <StyledListItem>
                                    <span className="type-normal-body">
                                        <span className="type-heavy">
                                            {countPhoneForcedMultiplierRows}
                                        </span>{' '}
                                        Campaign Lead Source(s) with a Phone Forced Bid
                                        Multiplier
                                    </span>
                                </StyledListItem>
                            )}
                            {countFormFlatBidRows != null && (
                                <StyledListItem>
                                    <span className="type-normal-body">
                                        <span className="type-heavy">
                                            {countFormFlatBidRows}
                                        </span>{' '}
                                        Campaign Lead Source(s) with a Form Flat Bid
                                        Amount
                                    </span>
                                </StyledListItem>
                            )}
                            {countFormForcedMultiplierRows != null && (
                                <StyledListItem>
                                    <span className="type-normal-body">
                                        <span className="type-heavy">
                                            {countFormForcedMultiplierRows}
                                        </span>{' '}
                                        Campaign Lead Source(s) with a Form Forced Bid
                                        Multiplier
                                    </span>
                                </StyledListItem>
                            )}
                        </StyledList>
                    </ContentRow>
                </>
            )}
            <Actions>
                <Button
                    variant="text"
                    onClick={onCancel}
                    disabled={completeEditsDisabled}
                >
                    <span className="type-heavy">Cancel</span>
                </Button>
                <Button disabled={completeEditsDisabled} onClick={handleCompleteEdits}>
                    {actionOkText}
                </Button>
            </Actions>
        </FullColumn>
    )
}

export const ConfirmationModalSkeleton = () => {
    return (
        <FullColumn>
            <ContentRow>
                <SkeletonText className="type-normal-subhead" />
            </ContentRow>
            <ContentRow>
                <StyledList>
                    <StyledListItem>
                        <span className="type-normal-body">
                            <SkeletonText variant="span" width={400} />
                        </span>
                    </StyledListItem>
                    <StyledListItem>
                        <span className="type-normal-body">
                            <SkeletonText variant="span" width={400} />
                        </span>
                    </StyledListItem>
                </StyledList>
            </ContentRow>
            <ContentRow>
                <SkeletonText className="type-normal-subhead" />
            </ContentRow>
            <ContentRow>
                <StyledList>
                    <StyledListItem>
                        <span className="type-normal-body">
                            <SkeletonText variant="span" width={400} />
                        </span>
                    </StyledListItem>
                    <StyledListItem>
                        <span className="type-normal-body">
                            <SkeletonText variant="span" width={400} />
                        </span>
                    </StyledListItem>
                </StyledList>
            </ContentRow>
            <Actions>
                <Button disabled={true} variant="text" onClick={() => null}>
                    <span className="type-heavy">Cancel</span>
                </Button>
                <Button disabled={true}>Complete Edits</Button>
            </Actions>
        </FullColumn>
    )
}
