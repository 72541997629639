import React from 'react';

import {CheckMarkSvg} from '../../../common/Svgs';

export default (props) => {
    let stageProgressClasses = 'leadlog__management-stages__stage-progress spacing-10-bottom';

    if (props.checked) {
        stageProgressClasses += ' checked-stage';
    }

    return <>
        <div className={stageProgressClasses}>
            <div>{CheckMarkSvg}</div>
            <div>
                {props.showTimeline &&
                <div className="stage-progress-line"/>}
            </div>
        </div>
        <div className="leadlog__management-stages__stage-header">
            <p className="type-large-body type-heavy type-single-line no-margin-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                {props.label}
                {props.tooltip}
            </p>
        </div>
    </>;
};
