import React from 'react';

const ImageBox = (props) => {
	return (
		<div
			onClick={() => props.showPopup({
				url: props.url,
				alt: props.alt,
				caption: props.caption,
			})}
			className={`onboarding__image-box ${props.extraClasses ? props.extraClasses : ''}`}
		>
			<img
				src={props.url}
				alt={props.alt}
			/>
			{(props.caption && (
				<p className="onboarding__image-box__caption type-normal-body">
					{props.caption}
				</p>
			))}
		</div>
	);
};

export default ImageBox;