import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * Modal to confirm or cancel when admin user clicks Campaign Done button on Launch Status page.
 */
export default class ConfirmCampaignsDoneModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false
        };
    }

    render() {
        return <>
            <p className="type-large-body spacing-20-bottom">
                Invite the Client to log into mySD, and Schedule their Campaigns to automatically go live on the next
                business day?
            </p>
            {!this.props.enroll &&
            <p className="type-large-body spacing-20-bottom">
                Note: Marketplace Campaigns will also be generated at this time.
            </p>}
            <div className="popup__form__gray-bar padding-24 spacing-50-top">
                <div className="popup__form__row popup__form__row__slam-right">
                    <div className="popup__form__cell">
                        <span role="button" className="type-small-body type-heavy type-blue"
                              onClick={() => this.props.updateModalContent()}>
                            Cancel
                        </span>
                    </div>
                    <div className="popup__form__cell">
                        <button className="button ui-normal-button"
                                onClick={() => {
                                    this.setState({submitting: true});
                                    this.props.updateMessageBlocks(
                                        "Processing your request. Please wait 10-20 seconds.", 'info');
                                    this.props.sendCampaignsDoneRequest()
                                        .finally(() =>
                                            this.setState({submitting: false}));
                                }}
                                disabled={this.state.submitting}
                        >
                            Send Email & Schedule Campaigns to Launch
                        </button>
                    </div>
                </div>
            </div>
        </>;
    }
}

ConfirmCampaignsDoneModal.propTypes = {
    updateMessageBlocks: PropTypes.func,
    sendCampaignsDoneRequest: PropTypes.func,
    updateModalContent: PropTypes.func,
    enroll: PropTypes.bool
};
