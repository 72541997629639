import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PlusSignSvg } from '../../common/Svgs';
import {errorHandler} from "../../Requests";
import AddCreditCardModal from './AddCreditCardModal';
import CreditCardRow from './CreditCardRow';
import CreditCardHeaderRow from './CreditCardHeaderRow';
import CreditCardService from "../../service/CreditCard";

export default class CreditCardSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creditCards: [],
            showModal: false,
            // selectedPrimary: for green fading row animation that helps user understand the "set as primary" change
            selectedPrimary: false
        };

        this.creditCardService = new CreditCardService();
    }

    componentDidMount() {
        this.getCreditCards();
    }

    /**
     * Hits api to get the credit cards and updates the App state with them
     * Triggered by component mounting
     */
    getCreditCards = () => {
        this.creditCardService.get()
            .then((creditCards) => {
                // get primary to the top of the array
                let orderedCreditCards = this.organizeCreditCards(creditCards);

                this.setState({ creditCards: orderedCreditCards });
                this.props.setContainerComponentState({ creditCards: orderedCreditCards });
            })
            .catch(errorHandler);
    };

    /**
     * Moves the primary card up to the top
     */
    organizeCreditCards = (creditCards: Object[]) =>
        creditCards.sort((cardA, cardB) => cardB.is_primary - cardA.is_primary);

    /**
     * Deletes (retires) the credit card via API request and removes the card from the view's local state.
     * @param {number} cardId
     * @return {Promise}
     */
    deleteCard = (cardId) => {
        return this.creditCardService.delete(cardId)
            .then((message) => {
                // get the cards without the deleted card
                const creditCards = this.state.creditCards.filter(cc => cc.credit_card_id != cardId);
                this.setState({creditCards});
                this.props.setContainerComponentState({creditCards});

                this.props.updateMessageBlocks(message, 'success');
            })
            .catch(errorHandler);
    };

    /**
     * Sets the card as primary.  Triggered by click event
     */
    setAsPrimary = (event) => {
        let creditCardId = event.target.id;

        this.setState({selectedPrimary: creditCardId});

        this.creditCardService.setPrimary(creditCardId)
            .then((message) => {
                let {creditCards} = this.state;
                // update the primary card
                creditCards.forEach((card) => card.is_primary = card.credit_card_id === creditCardId);

                // sort the cards with the primary first
                creditCards = this.organizeCreditCards(creditCards);

                // update the cards in the component, and parent component, state
                this.setState({ creditCards });
                this.props.setContainerComponentState({ creditCards });

                this.props.updateMessageBlocks(message, "success");
            })
            .catch(errorHandler);
    };

    /**
     * Updates the state.creditCards
     */
    updateCreditCards = (creditCards) => {
        let organizedCards = this.organizeCreditCards(creditCards);
        this.setState({creditCards : organizedCards});
    };

    openAddCardModal = () => {
        this.props.updateModalContent(
            <AddCreditCardModal
                updateCreditCards={ this.updateCreditCards }
                updateModalContent={ this.props.updateModalContent }
                updateMessageBlocks={ this.props.updateMessageBlocks }
            />,
            'Add Credit Card',
            null,
            true
        );
    };

    render() {
        let creditCardRows = [];

        if (this.state.creditCards.length > 0) {
            const primaryCard = this.state.creditCards.find(cc => cc.is_primary === '1');
            if (primaryCard) {
                let classNames = primaryCard.credit_card_id == this.state.selectedPrimary
                    ? 'selected-row-fading'
                    : '';
                const primaryCreditCardRow = (
                    <CreditCardRow
                        creditCard={primaryCard}
                        setAsPrimary={this.setAsPrimary}
                        deleteCard={this.deleteCard}
                        className={classNames}
                        key="primary-card-row"
                    />
                );
                creditCardRows.push(<CreditCardHeaderRow header="Primary Payment Method"
                                                         key="primary-payment-method"/>);
                creditCardRows = creditCardRows.concat(primaryCreditCardRow);
            }

            const secondaryCards = this.state.creditCards.filter(cc => cc.is_primary !== '1');
            const secondaryCreditCardRows = secondaryCards.map((cc, idx) => {
                let classNames = cc.credit_card_id == this.state.selectedPrimary
                    ? 'selected-row-fading'
                    : '';
                return (
                    <CreditCardRow
                        creditCard={cc}
                        setAsPrimary={this.setAsPrimary}
                        deleteCard={this.deleteCard}
                        className={classNames}
                        key={`secondary-cards-${idx}`}
                    />
                )
            });
            creditCardRows.push(<CreditCardHeaderRow header="Secondary Payment Method" key="secondary-payment-method"/>);
            creditCardRows = creditCardRows.concat(secondaryCreditCardRows);
        }

        return (
            <div className="row padding-50-bottom">
            <div className="wide-col-wrapper">
        <div id="billing-center-credit-cards-table" className="wide-format-col">
            <div className="module">
                <div className="module__header module__fullwidth">
                    <h3 className="type-large-subhead type-single-line">
                        Credit Cards
                    </h3>
                </div>
                <div className="module__contentbox module__fullwidth">
                    <p className="type-large-body spacing-50-bottom">
                        Add or delete credit cards and set your Primary Payment Method.
                    </p>

                    <table className="creditcards__table standardtable">
                        <thead className="standardtable__lightheader">
                            <tr className="type-small-body type-heavy">
                                <th id="creditcard-name" className="creditcard__col__name">
                                    <div className="creditcard__header__label">
                                        Name on Card
                                    </div>
                                </th>
                                <th id="creditcard-number" className="creditcard__col__number">
                                    <div className="creditcard__header__label">
                                        Card Number
                                    </div>
                                </th>
                                <th id="creditcard-expiration" className="creditcard__col__expiration">
                                    <div className="creditcard__header__label">
                                        Expiration
                                    </div>
                                </th>
                                <th id="creditcard-notes" className="creditcard__col__notes">
                                    <div className="creditcard__header__label"/>
                                </th>
                                <th id="creditcard-actions" className="creditcard__col_actions">
                                    <div className="creditcard__header__label"/>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { creditCardRows }
                        </tbody>
                    </table>
                    <p className="type-small-body spacing-20-top no-bottom-margin">
                        <span role="button"
                            className="type-heavy type-blue qa-add-credit-card"
                            onClick={this.openAddCardModal}
                        >
                            <span className="inline-icon inline-icon__middle inline-icon__20">
                                { PlusSignSvg }
                            </span> Add Another Card
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div className="clear-block"/>
    </div>
    </div>
        )
    }
}

CreditCardSection.propTypes = {
    updateModalContent: PropTypes.func,
    updateMessageBlocks: PropTypes.func,
    setContainerComponentState: PropTypes.func
};
