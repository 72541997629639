import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
`

export const DropdownWrapper = styled.div`
    padding: 15px 15px 0 15px;
`

export const PresetWrapper = styled.div`
    width: 190px;
    margin-bottom: 15px;
`