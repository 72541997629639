import { useEffect } from 'react'

export default function (shouldPreventUnload) {
    useEffect(() => {
        const preventUnload = (event: BeforeUnloadEvent) => {
            // NOTE: This message isn't used in modern browsers, but is required
            event.preventDefault()
            event.returnValue = ""
        }
        if (shouldPreventUnload) {
            window.addEventListener('beforeunload', preventUnload)
        } else {
            window.removeEventListener('beforeunload', preventUnload)
        }
        return () => window.removeEventListener('beforeunload', preventUnload)
    }, [shouldPreventUnload])
}
