import React, { Component } from 'react';
import {Route, Switch} from 'react-router-dom';
import axios from 'axios';

import AccountSetup from "../account_setup/AccountSetup";
import YourCompany from "../your_company/YourCompany";
import YourCampaigns from "../your_campaigns/YourCampaigns";
import Progress from './Progress';
import AuthService from "../../../service/AuthService";
import ContractorService from "../../../service/ContractorService";
import TokenService from "../../../service/TokenService";
import GoogleTagManagerService from "../../../service/GoogleTagManagerService";

/**
 * Expected props:
 *
 * - location
 * - match (from ReactRouter)
 * - auth (boolean) - whether user is authenticated
 * - whiteLabelPartnership (object) - the white label details:
 *   logo, company name, color scheme, etc.
 */

export default class SignupView extends Component {

    accountSetupToken;

    constructor(props) {
        super(props);

        this.contractorId = AuthService.getItem('contractorId');
        this.state = {
            signup: {},
            tender: [],
            schedule_a_call_link: undefined,
            primary_contact_name: undefined,
        };

        this.cancelSignal = axios.CancelToken.source();
        this.contractorService = new ContractorService();
        this.tokenService = new TokenService();

        let locationArr = this.props.location.pathname.split("/");
        if (locationArr.indexOf("signup") !== -1 && locationArr.indexOf("account-setup") !== -1
            && locationArr.length >= 4
        ) {
            this.accountSetupToken = locationArr[locationArr.length-1];
        }
    }

    componentDidMount() {
        // Note that on Step 1 Account Setup, contractorId will be null, creating this weird but valid request:
        //   /contractors/null/schedule_a_call_link
        this.contractorService.getScheduleCallLink(this.contractorId)
            .then((data) => this.setState({schedule_a_call_link: data.scheduleACallLink}))
            .catch((ignore) => {});

        GoogleTagManagerService.updateDataLayer();
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
        this.contractorService.cancelSignal.cancel();
        this.tokenService.cancelSignal.cancel();
    }

    updateTender = (index, tender) => {
        let stateClone = { ...this.state };
        let tenderClone = { ...stateClone.tender[0] };

        tenderClone.cost_per_lead = tender.toFixed(2).toString();
        stateClone.tender[0] = tenderClone;

        this.setState(stateClone);
    };

    render() {
        let displayLogoutButton = !this.props.match.path.includes("account-setup");

        return (
        <div>
            {this.props.children}
            <header>
                <div className="header-container">
                    <div className="page-width header-contents padding-20">
                        <div className="simpleflex__row">
                            <div className="simpleflex__cell simpleflex__cell__middlealigned">
                                <div className="header__logo">
                                    <a href="/">
                                        <img src={this.props.whiteLabelPartnership.logo_uri}
                                             alt={this.props.whiteLabelPartnership.white_label_name}/>
                                    </a>
                                </div>
                            </div>
                            <div className="simpleflex__cell simpleflex__cell__middlealigned">
                                <div className="type-large-body type-single-line type-align-right">
                                    <div className="no-mobile-view-visible">
                                        <span className="type-heavy">Need Help?</span>
                                        {' '}
                                        <a href={this.state.schedule_a_call_link} target="_blank" className="type-heavy">
                                            Schedule a Call
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {displayLogoutButton &&
                                <div className="header__button-wrap">
                                    <div
                                        className="button button__outline ui-normal-button"
                                        onClick={ () => this.logout() }
                                    >
                                        Logout
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </header>
            <div className="interface-container">
                <section className="grey-background">
                    <div className="page-width">
                        {/* this.props.location is present due to the <SignupView> being wrapped in a <Route>
                            and rendered by React Router */}
                        <Progress location={ this.props.location.pathname } />

                        { !this.props.auth ? (
                        <Switch>
                            <Route path="/signup/account-setup/:token?">
                                <AccountSetup {...this.props}
                                              signup={ this.state.signup }
                                              tender={ this.state.tender }
                                              updateTender={ this.updateTender }
                                              whiteLabelPartnership={ this.props.whiteLabelPartnership }
                                              updateWhiteLabel={this.props.updateWhiteLabel}
                                />
                            </Route>
                        </Switch>
                        ) : (
                        <Switch>
                            <Route path="/signup/your-company">
                                <YourCompany {...this.props}
                                    updateMessageBlocks={this.props.updateMessageBlocks}
                                    whiteLabelPartnership={ this.props.whiteLabelPartnership }
                                />
                            </Route>
                            <Route path="/signup/your-campaigns">
                                <YourCampaigns {...this.props}
                                    schedule_a_call_link={ this.state.schedule_a_call_link }
                                    updateMessageBlocks={this.props.updateMessageBlocks}
                                />
                            </Route>
                            <Route
                                path="/signup/account-setup/:token?"
                                render={(props) =>
                                    <AccountSetup {...props}
                                                  signup={ this.state.signup }
                                                  tender={ this.state.tender }
                                                  updateTender={ this.updateTender }
                                                  whiteLabelPartnership={ this.props.whiteLabelPartnership }
                                                  updateWhiteLabel={this.props.updateWhiteLabel}
                                    />
                                }
                            />
                        </Switch>
                        )}
                    </div>
                </section>
                <footer className="padding-30-top padding-50-bottom grey-background">
                    <div className="page-width">
                        <div className="row">
                            <div className="col alpha omega grid-12">
                                <p className="type-extra-small-body type-single-line type-centered-mobile">
                                    <span className="type-right-side-bump-full type-right-side-bump-tablet type-force-newline-mobile spacing-10-bottom-mobile">
                                        &copy; { (new Date()).getFullYear() } { this.props.whiteLabelPartnership.white_label_name }
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
        )
    }

    /**
     * Logs user out.
     */
    logout = () =>
        this.tokenService.logout()
            .catch((err) =>
                console.log("Caught an exception when posting to auth/logout in <SignupView> component, err =", err));
}
