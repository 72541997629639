import styled from 'styled-components'

const getSize = (size) => {
  const sizeMap = {
    small: '16px',
    default: '32px',
    large: '48px'
  }
  return sizeMap[size] || sizeMap.default
}

const getWidth = (size) => {
  const sizeMap = {
    small: '2px',
    default: '4px',
    large: '6px'
  }
  return sizeMap[size] || sizeMap.default
}

export const LoadingSpan = styled.span`
    display: inline-block;
    border: ${(props) => props.size ? getWidth(props.size) : '4px'} solid ${(props) => props.backgroundColor ? props.backgroundColor : '#F0F3F6'};
    animation: spin 1.2s linear infinite;
    border-top: ${(props) => props.size ? getWidth(props.size) : '4px'} solid ${(props) => props.color ? props.color : '#f6891e'};
    border-radius: 50%;
    width: ${(props) => props.size ? getSize(props.size) : '32px'};
    height: ${(props) => props.size ? getSize(props.size) : '32px'};;
    vertical-align: middle;
`