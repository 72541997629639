import React from 'react';

import MultiSelectList from '../common/components/form_elements/MultiSelectList';

const SurveyQuestion = (props) => {
	return (
		<div className="onboarding-survey-question">
			<p className={`type-large-body spacing-12-bottom ${props.showError ? 'type-red' : ''}`}>
				{props.question}
			</p>
			<div className="answer-menu">
				<MultiSelectList
					multiselect={props.multiselect}
					name={props.name}
					emptyLabel="Please Select"
					options={props.answers.map((answer) => { 
						return (answer.internalValue) ? { text: answer.label, value: answer.internalValue } : { text: answer.label, value: answer.label };
					})}
					hasError={props.showError}
					onChange={props.onChange}
					extraClass={props.showError ? ' ui-alert' : ''}
				/>
			</div>
		</div>
	);
};

export default SurveyQuestion;