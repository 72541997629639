import React from 'react';
import {get, post} from '../Requests';

import {BulkEditCampaignsStep1} from './BulkEditCampaignsModal/BulkEditCampaignsStep1';
import BulkEditCampaignsStep2CallerID from './BulkEditCampaignsModal/BulkEditCampaignsStep2CallerID';
import BulkEditCampaignsStep2CallRecording from './BulkEditCampaignsModal/BulkEditCampaignsStep2CallRecording';
import BulkEditCampaignsStep2CostPerLead from './BulkEditCampaignsModal/BulkEditCampaignsStep2CostPerLead';
import BulkEditCampaignsStep2FormLeadDelivery from './BulkEditCampaignsModal/BulkEditCampaignsStep2FormLeadDelivery';
import BulkEditCampaignsStep2PhoneLeadDelivery from './BulkEditCampaignsModal/BulkEditCampaignsStep2PhoneLeadDelivery';
import BulkEditCampaignsStep2Status from './BulkEditCampaignsModal/BulkEditCampaignsStep2Status';
import BulkEditCampaignsStep3 from './BulkEditCampaignsModal/BulkEditCampaignsStep3';
import BulkEditCampaignsStep4 from './BulkEditCampaignsModal/BulkEditCampaignsStep4';
import {formatCurrency, formatDate} from "../Util";
import FormModal from '../common/components/modal/FormModal';
import {editTypeOptions, handleCallRecordingUncheck} from '../common/StaticValues';
import AuthService from "../service/AuthService";

/**
 * BulkEditCampaignsModalFrame
 *
 * Extends FormModal (subclass of React.Component) in order to get desired
 * scroll-to-top behavior each time the modal is opened.
 */
export default class BulkEditCampaignsModalFrame extends FormModal {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,

      //
      // caller ID radio checked in Step 2 (profile_attribute_value_id in DB)
      //
      formData: {
        edit_what: '',

        status: null,
        re_enable: null,
        re_enable_date: '',
        reason: '',
        pause_later: false,
        pause_date: '',

        caller_id_option: null,
        call_recording: null,
        caller_id_phone_id: null,
        caller_id_phone_number: null,

        cost_per_lead: null,

        //
        // NOTE - all the other formData are just raw values, whereas these 3
        // use inputObj()
        //


        forwarded_phone: [],
        sms_lead_alert_multiple: [],
        contact_email: [],
      },

      //
      // array of objects {label:__, value:__} to be rendered on Step 3 Confirmation
      //
      changes: [],

      //
      // Industry for CPL - used for suggested range
      //
      cplIndustry: null,

      //
      // Once a bulk edit request is running, this is the ID so we can check progress
      //
      bulkEditCampaignsId: null,
      //
      // When a bulk edit in underway, we poll the API to see how many tasks succeeded/failed etc
      //
      numTasks: {
        successful: 0,
        failed: 0,
      },
      //numCampaignsComplete: 0,

      //
      // Once a bulk edit request is running, a setInterval occurs to monitor progress
      // This is the interval's ID to clear it later.
      //
      pollingInterval: null,
    };

    this.confirmationMessages = {
      'caller_id_option': {
        campaignLabel: <>Please confirm that you’d like to Bulk Edit the <b>Caller ID Preference</b> for the Campaigns:</>,
        subhead: <>All settings will be updated to:</>,
      },
      'call_recording': {
        campaignLabel: <>Please confirm that you’d like to Bulk Edit the <b>Call Recording & Lead Review Preference</b> for the Campaigns:</>,
        subhead: <>All settings will be updated to:</>,
      },
      'cost_per_lead': {
        campaignLabel: <>Please confirm that you’d like to Bulk Edit <b>Cost Per Lead</b> for the Campaigns:</>,
        subhead: <>Cost Per Lead for the selected Campaigns will be updated to:</>,
      },
      'form_lead_delivery': {
        campaignLabel: <>Please confirm that you’d like to Bulk Edit <b>Form Lead Delivery</b> settings for the Campaigns:</>,
        subhead: <>All settings will be updated to:</>,
      },
      'phone_lead_delivery': {
        campaignLabel: <>Please confirm that you’d like to Bulk Edit <b>Phone Lead Delivery</b> settings for the Campaigns:</>,
        subhead: <>All settings will be updated to:</>,
      },
      'status': {
        campaignLabel: <>Please confirm that you’d like to Bulk Edit the <b>Status</b> for the Campaigns:</>,
        subhead: <>All Statuses will be updated to:</>,
      }
    };

    //
    // Remember the position of the cost_per_lead option
    // so we can remove it when there are >1 industry
    //
    this.costPerLeadIdx = editTypeOptions.findIndex((item) => item.value == 'cost_per_lead');
    this.statusIdx = editTypeOptions.findIndex((item) => item.value == 'status');
  }

  /**
   * When hitting the cancel button on Step 3 to go back to Step 2.
   */
  abandonConfirmation = () =>
      this.goToStep(2);

  /**
   * Go to step n.
   */
  goToStep = (n) => {
    let stateChanges = {step: n};

    //
    // blank out Edit Type if going back to Step 1
    //
    if (n === 1) {
      stateChanges.formData = {...this.state.formData};
      stateChanges.formData.edit_what = null;
    }

    this.setState(stateChanges);
  }


  /**
   * Make a bulk edit request.
   * @param validationOnly bool
   * @param callback function - run after validation succeeds
   *   used e.g. to go to step 3 confirmation
   */
  makeBulkEditRequest = (validationOnly, callback) => {
    //
    // Get variables in order
    //
    if (validationOnly === undefined) {
      validationOnly = false;
    }
    let url = "campaigns/bulk_edit";

    let data = {
      edit_what: this.state.formData.edit_what,

      status: this.state.formData.status,
      re_enable: this.state.formData.re_enable,
      re_enable_date: this.state.formData.re_enable_date,
      reason: this.state.formData.reason,
      pause_later: this.state.formData.pause_later,
      pause_date: this.state.formData.pause_date,

      caller_id_option: this.state.formData.caller_id_option,
      call_recording: this.state.formData.call_recording,
      caller_id_phone_id: this.state.formData.caller_id_phone_id,
      caller_id_phone_number: this.state.formData.caller_id_phone_number,
      forwarded_phone: this.state.formData.forwarded_phone,
      sms_lead_alert_multiple: this.state.formData.sms_lead_alert_multiple,
      contact_email: this.state.formData.contact_email,
      cost_per_lead: this.state.formData.cost_per_lead,

      validation_only: validationOnly,
      campaign_ids: Object.keys(this.props.selectedCampaignIds),
    };

    //
    // Clear message blocks at beginning of request
    // This way, if they submit twice and receive the same error message,
    // they will see the the message disappear and re-appear, so they will know
    // that the re-submit was attempted.
    //
    this.props.updateMessageBlocks([], 'success');

    //
    // Make the Bulk Edit Request
    //
    post(url, data, this.props.ajaxCancelSignal.token).then( resp => {

      if (resp.status >= 400) {
        //
        // NOTE: Error messages will populate into Red Message Blocks due to an
        // axios interceptor in App.js, around line 81
        //
        return;
      }

      //
      // "Validation Only" mode
      // Get the list of proposed or executed changes into state from the API
      //
      if (validationOnly) {
        this.setState({
          changes: resp.data.data.changes
        }, () => {

          //
          // Call a function the caller passed to be executed
          // upon request completion, e.g. re-enabling submit button
          //
          if (typeof callback == 'function') {
            callback(resp);
          }
        });
        return;
      }

      //
      // Call a function the caller passed to be executed
      // upon request completion, e.g. re-enabling submit button
      //
      if (typeof callback == 'function') {
        callback(resp);
      }
    });
  };

  /**
   * When user submits Step 2 and progresses to Step 3 Confirmation page,
   * the changes are "committed" but not yet confirmed.
   * Hit a bulk_edit_campaigns request with validation_only=1
   *
   * @param confirmationHeader - title to display on Step 3
   *
   * @param leadDeliverySettings (optional) - an obj of data from the popup
   *        that needs to be synced back to parent state prior to making the
   *        Bulk Edit request
   */
  commitSettings = (confirmationHeader, leadDeliverySettings) => {
    //
    // For all steps other than Phone/Form Lead Delivery Settings, no state changes
    // are needed - just go to step 3.
    //
    if (leadDeliverySettings === undefined) {
      this.makeBulkEditRequest(true, () => {
        this.setState({
          step: 3,
        });
        this.props.updateModalHeader(confirmationHeader);
      });
      return;
    }


    //
    // else...
    // Phone/Form Lead Delivery Settings - state must be updated prior to request:
    //
    // leadDeliverySettings is an obj of this format:
    // {forwarded_phone: [inputObj(),...], contact_email: [inputObj(),...], ...}
    //
    // In this case we must sync the state from the Step 2 popup into the parent
    // component's state, mapping the inputObj()'s values straight to raw values.
    //
    let leadDeliveryKeys = ['forwarded_phone','sms_lead_alert_multiple','contact_email'];
    let formDataClone = {...this.state.formData};
    for (let i=0; i < leadDeliveryKeys.length; i++) {
      let key = leadDeliveryKeys[i];
      if (key in leadDeliverySettings) {
        formDataClone[key] = leadDeliverySettings[key]
                                .map((item) => item.value)
                                .filter((item) => (item != ''));
      }
    }

    this.setState({formData: formDataClone}, () => {
      //
      // once the state change is complete, make the Bulk Edit request, just like normal
      //
      this.makeBulkEditRequest(true, () => {
        this.setState({
          step: 3,
        });
        this.props.updateModalHeader(confirmationHeader);
      });
    })
  };



  /**
   * After Step 3 when they submit, run the Bulk Edit with validation_only=0
   * ("For real"). After the request, move to "Step 4", in which we poll the
   * API with a GET request to continue showing job progress as the background
   * task runs.
   */
  submitChangesForReal = (confirmationHeader, leadDeliverySettings) => {
    this.makeBulkEditRequest(false, (resp) => {

      this.setState({
        step: 4,
        bulkEditCampaignsId: resp.data.data.bulk_edit_campaigns.bulk_edit_campaigns_id,
      });
      this.props.updateModalHeader("Editing Your Campaigns");

    });
  };



  /**
   * Check progress after bulk edit is kicked off
   */
  checkJobProgress = () => {
    //
    // Clear message blocks at beginning of request
    // This way, if they submit twice and receive the same error message,
    // they will see the the message disappear and re-appear, so they will know
    // that the re-submit was attempted.
    //
    this.props.updateMessageBlocks([], 'success');

    //
    // Make the Bulk Edit Request
    //
    let url = "campaigns/bulk_edit?bulk_edit_campaigns_id="+this.state.bulkEditCampaignsId.toString();
    get(url).then( resp => {

      if (resp.status >= 400) {
        //
        // NOTE: Error messages will populate into Red Message Blocks due to an
        // axios interceptor in App.js, around line 81
        //
        return;
      }

      this.setState({
        numTasks: resp.data.data.num_tasks
      });

      //
      // If all campaigns are complete, we're done!
      // - Stop polling for progress
      // - Display green SUCCESS message
      // - Close the modal
      // - Update the Campaign Rows
      // - Clear selected campaigns
      //
      if (resp.data.data.num_tasks.successful === resp.data.data.num_tasks.total) {
        clearInterval(this.state.pollingInterval);
        this.props.updateMessageBlocks(
            ["Successfully edited " + resp.data.data.num_tasks.total.toString() + " Campaigns."],
            'success');
        this.props.updateModalContent();
        //
        // re-load the Campaigns so any new data shows up correctly
        // blank out campaign results so user doesn't see / interact
        // with stale data in the meantime
        //
        this.props.reloadCampaigns();
      }
      //
      // Otherwise we may still be "done" yet have some failures...
      // Show the Client a Red Error message detailing the successes and failures.
      //
      else if (resp.data.data.num_tasks.remaining === 0) {
        clearInterval(this.state.pollingInterval);

        //
        // build up the list of failed campaign names with <br/> in between
        //
        let failedCampaignsJsx = [];
        for (let i = 0; i < resp.data.data.failed_campaigns.length; i++) {
          failedCampaignsJsx.push(<span key={i}>{ resp.data.data.failed_campaigns[i].name }</span>);
          failedCampaignsJsx.push(<br key={i + 'br'} />);
        }

        let errorMsg = <>
          {resp.data.data.num_tasks.successful} / {resp.data.data.num_tasks.total} Campaigns successfully edited.<br/>
          {resp.data.data.num_tasks.failed} Campaign(s) failed:<br/><br/>
          {failedCampaignsJsx}<br/>

          To ensure all your Campaigns are updated properly, please try again, or contact Support.
        </>;

        this.props.updateMessageBlocks([errorMsg], 'error', true);
        this.props.updateModalContent();
        //
        // re-load the Campaigns so any new data shows up correctly
        // blank out campaign results so user doesn't see / interact
        // with stale data in the meantime
        //
        this.props.reloadCampaigns();
      }
    });
  };


  startPollingJobProgress = () => {
    let intervalId = setInterval(() => {
      this.checkJobProgress();
    }, 1000);
    this.setState({pollingInterval: intervalId});
  };






  selectEditType = (vals) => {
    if (vals[0]) {
      let formDataClone = {...this.state.formData};
      formDataClone.edit_what = vals[0];
      this.setState({
        step: 2,
        formData: formDataClone,
      });
    }
  }


  /**
   * Get the copy/text for an option, e.g. a Caller ID Option
   * or a Recording Phone Calls option.   * @param optionKey
   * @param rawValue - in most cases, the specific field off the campaign,
   *        in the special case of optionKey='status', the whole campaign.
   * @returns string
   */
  getOptionValueTxt = (optionKey, rawValue) => {

    //
    // caller_id_option
    //
    if (optionKey === 'caller_id_option') {
      let callerIdOptionsHash = this.getCallerIdOptionsHash();
      if (rawValue in callerIdOptionsHash) {
        return callerIdOptionsHash[rawValue];
      }
      console.log("Warning - getOptionValueTxt() - optionKey=",optionKey," unexpected value, rawValue=",rawValue);
      return '[no option chosen]';
    }
    //
    // call_recording
    //
    if (optionKey === 'call_recording') {
      if (!!Number(rawValue) === true) {
        return "Yes, record phone calls and enable lead review";
      }
      return "No, do not record phone calls - leads ineligible for lead review";
    }
    //
    // cost_per_lead
    //
    if (optionKey === 'cost_per_lead') {
      return formatCurrency(rawValue, 2);
    }
    //
    // form_lead_delivery - rawValue is an array of objs
    //
    if (optionKey === 'form_lead_delivery') {
      if (rawValue.length === 0) {
        return 'None';
      }
      return rawValue.map(function(emailObj) {
        return emailObj.email_address;
      }).join(', ');
    }
    //
    // phone_lead_delivery - rawValue is an array of objs
    //
    if (optionKey === 'phone_lead_delivery') {
      if (rawValue.length === 0) {
        return 'None';
      }
      return rawValue.map(function(phoneObj) {
        return phoneObj.phone_number;
      }).join(', ');
    }

    //
    // status - a special case; rawValue = the whole campaign
    //
    if (optionKey === 'status') {
      let campaign = rawValue;
        let statusMap = {
            paused: <span className="type-alert-text type-small-body bulk-edit-step2-status-box">Paused</span>,
            enabled: <span className="type-alert-text type-alert-text__success type-small-body bulk-edit-step2-status-box">Enabled</span>,
            canceled: 'Canceled'
        };
      let status = statusMap[campaign.status];
      if (campaign.status === 'paused' && campaign.auto_unpause_timestamp) {
          status = <>{status} scheduled to enable on {formatDate(campaign.auto_unpause_timestamp)}</>;
      }
      else if (campaign.status === 'enabled' && campaign.auto_pause_timestamp) {
          status = <>
              {status}
              <span className="spacing">
                  scheduled to pause on {formatDate(campaign.auto_pause_timestamp)}
                  {campaign.auto_unpause_timestamp && campaign.auto_unpause_timestamp > campaign.auto_pause_timestamp
                    ? ' and enable on ' + formatDate(campaign.auto_unpause_timestamp)
                    : ''}
              </span>
          </>;
      }
      return status;
    }

    return rawValue.toString();
  };

  /**
   * Update form data.
   * @param key
   * @param value
   */
  updateFormData = (key, value) => {
    if (key === 'call_recording') {
      handleCallRecordingUncheck(!!value, this.props.updateMessageBlocks);
    }

    let formDataClone = {...this.state.formData};
    formDataClone[key] = value;
    this.setState({formData: formDataClone});
  };

  updateFormDataMultiple = (keyValuePairs) => {
      let formDataClone = {...this.state.formData};
      Object.keys(keyValuePairs).forEach( key => {
          formDataClone[key] = keyValuePairs[key];
      });
      this.setState({formData: formDataClone});
  };



  /**
   * - Cost Per Lead edits -
   */

  /**
   * Returns editTypeOptions, with or without CPL as appropriate.
   * If campaigns from 2 or more service categories are selected,
   * leave the CPL out.
   * @returns array
   */
  getEditTypeOptions = () => {
    let myEditTypeOptions = [...editTypeOptions];

    // remove CPL from dropdown options array
    if (this.props.disableCpl) {
      myEditTypeOptions.splice(this.costPerLeadIdx, 1);
    }

    // remove Status from dropdown options array
    if (!AuthService.canUnpauseCampaign) {
      myEditTypeOptions.splice(this.statusIdx, 1);
    }

    return myEditTypeOptions;
  };

  /**
   * - Caller ID Option methods -
   */


  /**
   * Update Caller ID Setting radio button
   * @param id
   */
  updateCallerIdSettingRadio = (id) => {
    let formDataClone = this.state.formData;

    formDataClone.caller_id_option = id;

    if (id === "9") {
      formDataClone.caller_id_phone_id = null;
      formDataClone.caller_id_phone_number = null;
    }

    this.setState({
      formData: formDataClone
    });
  };

  updateTrackingPhoneSetting = (displayPhoneId, phoneNumber) => {

    let formDataClone = this.state.formData;

    let callerIdTrackingPhoneOptions = this.props.callerIdTrackingPhoneOptions;

    formDataClone.caller_id_phone_id = displayPhoneId;

    for (let i = 0; i < callerIdTrackingPhoneOptions.length; i++) {
      let phoneOptionId = callerIdTrackingPhoneOptions[i].value;
      if (displayPhoneId === phoneOptionId) {
        phoneNumber = callerIdTrackingPhoneOptions[i].phone;
        formDataClone.caller_id_phone_number = phoneNumber;
      }
    }

    // auto select radio button and
    // set formDataClone.caller_id_option = '10'
    // when the user pick a callerId from the dropDown
    //
    if (formDataClone.caller_id_phone_id !== null) {
      formDataClone.caller_id_option = '10';
    }

    this.setState({
      formData: formDataClone
    });


  }


  /**
   * create a hash of the callerIdOptions so we can get the
   * text name of each campaign's current option
   */
  getCallerIdOptionsHash = () => {
    let callerIdOptionsHash = {};
    for (let i=0; i < this.props.callerIdOptions.length; i++) {
      let callerIdOption = this.props.callerIdOptions[i];
      callerIdOptionsHash[callerIdOption.id] = callerIdOption.description;
    }
    return callerIdOptionsHash;
  };


  /**
   * Lead Delivery methods
   */





  render() {
    return (
      <>
        {(this.state.step === 1) ? (
          <BulkEditCampaignsStep1
            editType={ this.state.formData.edit_what }
            editTypeOptions={ this.getEditTypeOptions() }
            disableCpl={ this.props.disableCpl }
            selectEditType={ this.selectEditType }
            campaigns={this.props.campaigns}
            selectedCampaignIds={this.props.selectedCampaignIds}
          />
        ) : null}

        {(this.state.step === 2) ? (
          <>
            {(this.state.formData.edit_what === 'caller_id_option') ? (
              <BulkEditCampaignsStep2CallerID
                commitSettings={ this.commitSettings }
                updateModalContent={ this.props.updateModalContent }
                updateModalHeader={ this.props.updateModalHeader }
                campaigns={ this.props.campaigns }
                selectedCampaignIds={this.props.selectedCampaignIds}
                getOptionValueTxt={ this.getOptionValueTxt }
                formData={ this.state.formData }
                updateFormData={ this.updateFormData }

                callerIdOptions={ this.props.callerIdOptions }
                updateCallerIdSettingRadio={this.updateCallerIdSettingRadio}
                callerIdTrackingPhoneOptions={this.props.callerIdTrackingPhoneOptions}
                updateTrackingPhoneSetting={this.updateTrackingPhoneSetting}
              />
            ) : null}

            {this.state.formData.edit_what === 'call_recording' ? (
              <BulkEditCampaignsStep2CallRecording
                commitSettings={ this.commitSettings }
                updateModalContent={ this.props.updateModalContent }
                updateModalHeader={ this.props.updateModalHeader }
                campaigns={ this.props.campaigns }
                selectedCampaignIds={this.props.selectedCampaignIds}
                getOptionValueTxt={ this.getOptionValueTxt }
                formData={ this.state.formData }
                updateFormData={ this.updateFormData }

                callerIdOptions={ this.props.callerIdOptions }
                callerIdSetting={ this.state.callerIdSetting }
                updateCallRecording={this.updateCallRecording}
              />
            ) : null}

            {(this.state.formData.edit_what === 'cost_per_lead') ? (
              <BulkEditCampaignsStep2CostPerLead
                commitSettings={ this.commitSettings }
                updateModalContent={ this.props.updateModalContent }
                updateModalHeader={ this.props.updateModalHeader }
                campaigns={ this.props.campaigns }
                selectedCampaignIds={this.props.selectedCampaignIds}
                getOptionValueTxt={ this.getOptionValueTxt }
                formData={ this.state.formData }
                updateFormData={ this.updateFormData }
                cplIndustry={ this.props.cplIndustry }
              />
            ) : null }

            {(this.state.formData.edit_what === 'form_lead_delivery') ? (
              <BulkEditCampaignsStep2FormLeadDelivery
                commitSettings={ this.commitSettings }
                updateModalContent={ this.props.updateModalContent }
                updateModalHeader={ this.props.updateModalHeader }
                campaigns={ this.props.campaigns }
                selectedCampaignIds={this.props.selectedCampaignIds}
                getOptionValueTxt={ this.getOptionValueTxt }
                formData={ this.state.formData }
                handleInputChange={ this.handleLeadDeliveryInputChange }
              />
            ) : null}

            {(this.state.formData.edit_what === 'phone_lead_delivery') ? (
              <BulkEditCampaignsStep2PhoneLeadDelivery
                commitSettings={ this.commitSettings }
                updateModalContent={ this.props.updateModalContent }
                updateModalHeader={ this.props.updateModalHeader }
                campaigns={ this.props.campaigns }
                selectedCampaignIds={this.props.selectedCampaignIds}
                getOptionValueTxt={ this.getOptionValueTxt }
                formData={ this.state.formData }
                handleInputChange={ this.handleLeadDeliveryInputChange }
              />
            ) : null}

            {(this.state.formData.edit_what === 'status') ? (
              <BulkEditCampaignsStep2Status
                commitSettings={ this.commitSettings }
                modalContainerRef={ this.props.modalContainerRef }
                updateModalContent={ this.props.updateModalContent }
                updateModalHeader={ this.props.updateModalHeader }
                campaigns={ this.props.campaigns }
                selectedCampaignIds={this.props.selectedCampaignIds}
                getOptionValueTxt={ this.getOptionValueTxt }
                formData={ this.state.formData }
                updateFormData={ this.updateFormData }
                updateFormDataMultiple={ this.updateFormDataMultiple }
                goToStep={ this.goToStep }
              />
            ) : null}
          </>
        ) : null}

        {(this.state.step === 3) ? (
          <BulkEditCampaignsStep3
            abandonConfirmation={ this.abandonConfirmation }
            updateModalHeader={ this.props.updateModalHeader }
            campaignLabelText={ this.confirmationMessages[this.state.formData.edit_what].campaignLabel }
            subheadText={ this.confirmationMessages[this.state.formData.edit_what].subhead }
            campaigns={this.props.campaigns}
            selectedCampaignIds={this.props.selectedCampaignIds}
            getOptionValueTxt={ this.getOptionValueTxt }
            changes={ this.state.changes }
            submitChangesForReal={ this.submitChangesForReal }
            editWhat={this.state.formData.edit_what}
          />
        ) : null}

        {/* Bulk Edit request underway - show progress */}
        {(this.state.step === 4) ? (
          <BulkEditCampaignsStep4
            campaigns={this.props.campaigns}
            selectedCampaignIds={this.props.selectedCampaignIds}
            getOptionValueTxt={ this.getOptionValueTxt }
            changes={ this.state.changes }
            startPollingJobProgress={ this.startPollingJobProgress }
            numTasks={ this.state.numTasks }
          />
        ) : null}


        {/*messageText={ this.confirmationMessages[this.state.formData.edit_what].message() }*/}

      </>
    );
  }
}