import React, { useEffect, useCallback } from 'react';

import { ButtonNavCloseSvg } from '../common/Svgs';

const OnboardingOverlay = (props) => {
	const { closePopup } = props;
	
	const handleEscapeKey = useCallback((event) => {
		if (event.key === 'Escape' || event.keyCode === 27) {
			closePopup();
		}
	}, [closePopup]);
	
	useEffect(() => {
		window.addEventListener('keydown', handleEscapeKey);
		
		return () => {
			window.removeEventListener('keydown', handleEscapeKey);
		};
	}, [handleEscapeKey]);
	
	return (
		<div
			className={`onboarding-overlay__wrapper ${props.visible ? 'visible' : ''}`}
		>
			<div className="onboarding-overlay__button-wrapper">
				<button
					onClick={closePopup}
				>
					{ButtonNavCloseSvg}
				</button>
			</div>
			<div className="onboarding-overlay__content-wrapper">
				<div className="onboarding-overlay__contents">
					<p className="type-normal-headline spacing-30-bottom">
						Need Help?
					</p>
					<p className="type-normal-subhead type-narrow-line-height">
						Contact us with a <a href="https://share.hsforms.com/1YGbCjxPqQY2-AB4b4h8C1A2fui9?__hstc=75547978.51c582a5723e98597d4047d92e86a83e.1700264684407.1700264684407.1700264684407.1&__hssc=75547978.1.1700264684407&__hsfp=878167773" className="type-heavy">Support Request</a> or <button className="type-heavy type-blue type-normal-subhead button-clean" onClick={props.cancelSelfServeOnboarding}>Schedule an Onboarding Call</button>
					</p>
					<div className="spacing-30-top">
						<button
							className="button button__outline__white ui-normal-button"
							onClick={props.logout}
						>
							Logout
						</button>
					</div>
				</div>
			</div>
			<div className="onboarding-overlay__shade" />
		</div>
	);
};

export default OnboardingOverlay;