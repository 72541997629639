import React from 'react';
import PropTypes from 'prop-types';

export const ConfirmModal = (props) => {
    return <div>
        {props.title &&
        <p className="type-normal-body spacing-20-bottom">
            {props.title}
        </p>}

        {props.content}
    </div>;
};

ConfirmModal.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export const ConfirmModalFooter = (props) => {
    const confirmText = props.confirmText || 'Yes';
    const cancelText = props.cancelText || 'No';

    return <div className="popup__form__row simpleflex__cell popup__form__row__slam-right">
        {props.handleCancel &&
        <div className="popup__form__cell">
            <button className="button-clean type-heavy type-blue type-small-body"
                    onClick={props.handleCancel}>
                {cancelText}
            </button>
        </div>}
        {props.handleConfirm &&
        <div className="popup__form__cell">
            <button className="button ui-normal-button" onClick={props.handleConfirm}>
                {confirmText}
            </button>
        </div>}
    </div>;
};

ConfirmModalFooter.propTypes = {
    handleCancel: PropTypes.func,
    handleConfirm: PropTypes.func,
    confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
