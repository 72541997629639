import React from 'react';

const PrivacyPolicy = (props) => {

    return (
        <div className="padding-50-bottom">
            <div className="grid-12">
                <div className="module">
                    <h3 className="type-service-headline padding-30-bottom">
                        REVISED: JULY 2023
                    </h3>
                    <p className="type-small-body padding-10">
                        Sagax Media LLC (d.b.a Service Direct), a Texas limited liability company, and its affiliates (“Service Direct,” “we,” “us” or “our”) value your privacy. This Policy describes how Service Direct may collect, use, store, disclose, and process your personal information, through your access to or use of Service Direct products and services, including those at https://www.servicedirect.com/,  related websites, mobile applications, and other online services (collectively, the “Service”). It does not apply to information collected or obtained by or through any other means (including, without limitation, information collected offline, in person, over the telephone and/or by mail, or from third parties outside the Service). IF YOU DO NOT AGREE TO THIS POLICY, PLEASE DO NOT ACCESS OR USE THE SERVICE.  Service Direct may change this Privacy Policy from time to time by posting changes at this URL and may send notification emails. Your continued use of the Services following the posting of such changes will be deemed your acceptance of those changes.
                    </p>
                </div>
            </div>

            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        1.              General
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (a)             Applicability.  This Privacy Policy applies when you access the Services or share information with Service Direct, as indicated below. By using the Services, you consent to this Privacy Policy, which may be updated by us at any time. If you do not consent to this Privacy Policy for any reason, please do not use the Services or share your information with us. Please note that this Privacy Policy applies only to the Services, and not to any other third party website linked to or from it, or any third party website in which Service Direct content or functionality is embedded. We do not control the privacy policies or practices of others.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (b)             Consent. By accessing or using the Service, you consent to the collection, use, disclosure, storage and processing of your information in accordance with: (i) this Privacy Policy; (ii) any related terms of service, end user license agreement, or services agreement, (collectively, “Service Terms”); and (iii) and as permitted or required by applicable law.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (c)             International. The Service may be hosted or operated in countries other than the country in which you reside. Those countries may have different data protection laws and regulations from those that apply in your home country. By accessing or using the Service, you consent to the transfer, storage and processing of your information outside of your home country. Please be aware that the governments, courts, and law enforcement, security or regulatory agencies of a particular country may be able to obtain access to or disclosure of your information as permitted by the laws of that country.
                    </p>
                </div>
            </div>
            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        2.              HOW WE COLLECT AND USE INFORMATION
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (a)             Information That You Provide Directly Through the Service. We may ask for certain information such as your name, institution name, email address, physical address, phone number and other information. We may retain any messages or information you send through any inquiry forms through the Service, and we may also retain other information you voluntarily provide to us. We use this information to perform the Service, and as further described below.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (b)             Information Collected or Received from Third Parties. We may receive information about you from third parties. You should always review and, if necessary, adjust your privacy settings on third-party services before linking or connecting them to the Service. We may also collect information about you from our third-party business associates, publicly-available sources and our affiliates.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (c)             Site Analytics Information. We may collect, measure and analyze traffic and usage trends for the Service, and we may use third-party analytics tools to help us (“Site Analytics Tools”). This allows us to understand, among other things, who is using the Service, how they are using it, and ways to improve the Service. Site Analytics Tools may use cookies and persistent device identifiers to collect and store information such as time of visit, pages visited, time spent on each page, IP address, and type of operating system used. We use Google Analytics to understand how users interact with the Service. You can learn more about Google Analytics and your options for opting out of interest-based Google ads at https://adssettings.google.com and visit the page at https://www.google.com/policies/privacy/partners/ to understand how Google collects and processes data.  You can learn more about targeted ads and your ability to opt out of receiving interest-based ads at optout.aboutads.info and www.networkadvertising.org/choices.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (d)             Cookies . When you use the Service, we may send one or more cookies (small text files containing a string of alphanumeric characters) to your computer that uniquely identify your browser and enhance your navigation on the Service. A cookie may also convey information to us about how you use the Service (e.g., the pages you view, the links you click and other actions you take), and allow us or our third-party business associates to track your usage of the Service. There are at least two different types of cookies: persistent and session cookies. A persistent cookie remains on your hard drive after you close your browser. Persistent cookies may be used by your browser on subsequent visits to the Service. Persistent cookies can be removed by following your web browser’s directions for removal of cookies. A session cookie is temporary and disappears after you close your browser. You can reset your web browser to refuse all cookies or to notify you when a cookie is being sent. However, some features of the Service may not function properly if cookies are disabled.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (e)            Log File. Log file information is automatically reported by your browser each time you access a web page. When you access or use the Service, our servers may automatically record certain log file information, such as your web request, internet protocol (IP) address, browser type, referring/exit pages and URLs, number of clicks and how you interact with links on the Service, domain names, landing pages, and pages viewed.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (f)            Clear GIFs/Web Beacons. Clear GIFs (also known as web beacons) allow for the tracking of a user’s response to an email or usage of a website in a manner that does not reveal personally identifiable information. We may use clear GIFs or similar technologies to assess responses to emails and usage of the Service. For example, we may use clear GIFs to track when emails are opened and which links are clicked by recipients. You can disable the ability of clear GIFs to capture information by blocking cookies.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (g)            Device Identifiers. When you access or use the Service using a mobile device, we may access, collect, monitor and/or remotely store one or more “device identifiers,” such as a universally unique identifier (“UUID”). Device identifiers are small data files or similar data structures stored on or associated with your device that uniquely identify your device. A device identifier may consist of data stored in connection with the device hardware, operating system or other software, or data sent to the device by us. A device identifier may convey information to us about how you browse and use the Service. A device identifier may remain persistently on your device to enhance your navigation on the Service. Some features of the Service may not function properly if use or availability of device identifiers is impaired or disabled.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (h)            Location Data. When you access or use the Service, we may access, collect, monitor and/or remotely store “location data,” which may include GPS coordinates (e.g. latitude and/or longitude) or similar information regarding the location of your device. Location data may convey to us information about how you browse and use the Service. Some features of the site, particularly location-based services, may not function properly if use or availability of location data is impaired or disabled.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (i)            Commercial Communications . We may use the information we collect or receive to communicate directly with you. For example, we may use the information to send you emails containing newsletters, promotions and special offers regarding the Service. We may also use the information to send you related notices (e.g., account verification, technical and security notices). We may send promotional and transactional mobile messages in various formats. Promotional messages advertise and promote our products and services. Transactional messages relate to an existing or ongoing transaction. Mobile messages may be sent using an automated technology, including an autodialer, automated system, or automatic telephone dialing system. Message frequency will vary. You agree that we, our affiliates, and any third-party service providers may send you messages. We do not charge for mobile messages sent through the Program but you are responsible for any message and data rates imposed by your mobile provider, as standard data and message rates may apply for SMS and MMS alerts.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (j)            Use of Certain Service-Type Information. We may use information from cookies, log files, device identifiers, location data and clear GIFs to: (i) remember information so that you will not have to re-enter it during your visit or the next time you visit the site; (ii) provide custom, personalized content or information; (iii) monitor the effectiveness of the Service; (iv) monitor aggregate metrics, such as total number of visitors, traffic and demographic patterns; (v) diagnose or fix technology problems; (vi) provide advertising to your browser or device; and (vii) conduct research or surveys.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (k)            Use of Information with Your Consent. We may use your information for any other purpose for which you provide consent.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (i)            Combining Information. We may combine any of the information we collect from or about you, from one or multiple sources, and use it in the manner described in this Privacy Policy.
                    </p>

                </div>
            </div>
            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        3.              How We May Share Your Information
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (a)             Business Associates and Service Providers. We may share information about you with third-party business associates, partners, contractors, agents and service providers that perform services on our behalf in connection with the Service. We may also share information about you with any of our subsidiaries or affiliates for purposes consistent with this Privacy Policy.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (b)             Business Change. If we become involved in a merger, consolidation, acquisition, sale of assets, joint venture, securities offering, bankruptcy, reorganization, liquidation, dissolution or other transaction, or if the ownership of all or substantially all of our business otherwise changes, we may share or transfer your information to a third party or parties in connection with such transaction or change in ownership.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (c)             Necessary Disclosure: Regardless of the choices you make regarding your information and to the extent permitted or required by applicable law, we may disclose information about you to third parties to: (i) enforce or apply this Privacy Policy or the Service Terms; (ii) comply with laws, subpoenas, warrants, court orders, legal processes or requests of government or law enforcement officials; (iii) protect our rights, reputation, safety or property, or that of our users or others; (iv) protect against legal liability; (v) establish or exercise our rights to defend against legal claims; or (vi) investigate, prevent or take action regarding known or suspected illegal activities; fraud; our rights, reputation, safety or property, or those of our users or others; violation of the Service Terms; or as otherwise required by law.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (d)             Sharing Information. We may share certain service-type information, including information obtained through tools such as cookies, log files, device identifiers, location data and clear GIFs (such as anonymous usage data, referring/exit pages and URLs, platform types, number of clicks) with our third-party business associates for the purposes described in the section titled “How We Collect and Use Information.” We may also aggregate or otherwise strip data of all personally identifying characteristics and share that aggregated, anonymized data with third parties.
                    </p>
                </div>
            </div>
            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        4.              How We Protect Your Information
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        We take physical, technological and administrative measures to protect the information you provide through the Service against loss, theft, and unauthorized access, use, disclosure or modification. However, we cannot ensure or warrant the security of any information you transmit to us or that we collect, or guarantee that information we maintain will not be accessed, disclosed, altered or destroyed. Communications sent to or from the Service may not be secure. You should use caution whenever submitting information online and take special care in deciding what information you send to us via email.
                    </p>
                </div>
            </div>
            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        5.              YOUR CHOICES ABOUT YOUR INFORMATION
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (a)             Controlling Your Settings. You can limit your browser or mobile device from providing certain information by adjusting the settings in the browser, operating system or device. Please consult the documentation for the applicable browser, operating system or device for the controls available to you. You can also stop receiving promotional emails from us by following the unsubscribe instructions in those emails. Note that unsubscribe is not available for certain emails concerning your relationship or dealings with us.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        (b)             Do Not Track. At this time, we do not recognize “do not track” signals sent from web browsers. Third-party services that we use may collect personal information about individual users and their activities over time and across different websites. You can control the information collected by such tracking technologies or be alerted when cookies are sent by adjusting the settings on your Internet browser or devices, but such adjustments may affect or disable certain functionality of the Services. To disable tracking, please consult the documentation for your browser, operating system or mobile device. For some devices, it may not be possible to disable tracking mechanisms.
                    </p>
                </div>
            </div>
            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        6.              How Long We Keep Your Information
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        We will retain your information as long as necessary for the purposes outlined in this Privacy Policy, and for a commercially reasonable time thereafter for backup, archival, fraud prevention or detection, or audit purposes, or as otherwise required by law.
                    </p>
                </div>
            </div>
            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        7.              Children’s Privacy
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        No parts of the Service are directed to children and the Service is intended for use by adults only. We do not knowingly collect personal information from individuals under 13 years of age. If you are under the age of 13, please do not submit any personal information through the Service.
                    </p>
                </div>
            </div>
            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        8.              Third-Party Services
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        The Service may reference or provide links to other websites or resources. If you access any website or resources provided by a third party, our Privacy Policy will not apply. Your interactions with such websites and resources are subject to the privacy policies of the third parties that operate them. Please review those policies carefully to understand how those parties will treat your information.
                    </p>
                </div>
            </div>
            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        9.              Your California Privacy Rights
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        Under California Civil Code Section 1798.83, if you are a California resident and your business relationship with us is primarily for personal, family or household purposes, you may request certain data regarding our disclosure, if any, of personal information to third parties for the third parties’ direct marketing purposes. To make such a request, please send an email to privacy@servicedirect.com with "Request for California Privacy Information" in the subject line. You may make such a request up to once per calendar year. If applicable, we will provide to you via email a list of the categories of personal information disclosed to third parties for their direct marketing purposes during the immediately-preceding calendar year, along with the third parties' names and addresses. Please note that not all personal information sharing is covered by Section 1798.83's requirements.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        In addition, if you are a California resident, you may be able to exercise additional rights granted by California law in relation to personal information about you that we have collected (subject to certain limitations).  For additional information concerning these rights, please visit our <a href="https://servicedirect.com/californiaprivacynotice">California Privacy Notice</a>.
                    </p>
                </div>
            </div>
            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        10.            Updates to this Privacy Policy
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        We may update this policy from time to time, and the updated version of this policy will be effective upon posting. Please check this page to review the most up-to-date version of this policy. By continuing to use the Service after an updated version has been posted, you agree that the new policy will apply to you.
                    </p>
                </div>
            </div>
            <div className="grid-12">
                <div className="module">
                    <p className="type-small-body padding-10">
                        11.            How to Contact Us.
                    </p>
                    <p className="type-small-body padding-10 type-left-side-bump">
                        You can call us at (877) 978-1099, e-mail us at privacy@servicedirect.com or write to us at Sagax Media LLC (d.b.a Service Direct), Attn: Privacy Dept., 4413 Pack Saddle Pass, Austin, TX 78745. Depending on where you are located, you may also have a right to complain to your local data protection regulator.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;

