import React, {Component} from 'react';
import {CloseSvg, CheckMarkSvg, WarningSvg} from './Svgs';
import PropTypes from 'prop-types';

export default class MessageBlocks extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.timeout = Number(props.timeout || process.env.REACT_APP_MESSAGE_DISPLAY_TIMEOUT);
    }

    componentDidUpdate(prevProps) {
        // don't render again if messages are the same
        if (JSON.stringify(prevProps.messages) === JSON.stringify(this.props.messages)) {
            return;
        }

        clearTimeout(this.timer);
        this.startTimer();
    }

    startTimer = () => {
        clearTimeout(this.timer);
        if (!this.props.stayForever) {
            this.timer = setTimeout(() => {
                this.close();
            }, this.timeout);
        }
    };

    close = () => {
        this.props.clearErrors();
        clearTimeout(this.timer);
    };

    render() {
        let closed = this.props.messages.length === 0,
            type = this.props.type,
            messages = this.props.messages;

        if (!messages.length) {
            return <div/>;
        }

        const blocks = messages.map((message, key) =>
            <div className="type-normal-body" key={key}>
                {message.split("\n").map((item, index) => <div key={`message-${index}`}>{item}</div>)}
            </div>
        );

        // for some reason urls may be this array with 1 undefined
        // element: [undefined]
        // We don't want that treated or seen the same as an array of urls
        // in which none of them are undefined, in part b/c of the
        // urls.length conditional below that prepends white space to the
        // url list in the message block.  So this loop needs to continue
        // pass such undefined urls so that we can determine afterward, via
        // urls.length, if we really have valid urls to display or not.
        // - mz 30 Apr 2019
        let url = "",
            urls = [];

        if (this.props.urls) {

            for (let i = 0; i < this.props.urls.length; i++) {
                url = this.props.urls[i];
                if (!url) {
                    continue;
                }

                urls.push(<a href={url} target="_blank" key={i}>
                    {url.substr(0, 30)}...
                </a>);
            }

            // if we have urls, add some white space via line breaks.
            if (urls.length) {
                urls.unshift(<div key={-1}><br/><br/></div>)
            }
        }

        return (
            <div className="message-bubbles__block" id="message-blocks">
                <ul className="message-bubbles__list">
                    <li className={"message-bubbles__message message-bubbles__message__" + type + (closed ? " closed" : "")}>
                        <div className="message-bubbles__message__icon">
                            {type === 'success' ? CheckMarkSvg : WarningSvg}
                        </div>
                        <div className="message-bubbles__message__message">
                            <p className="type-large-body type-heavy type-single-line type-allcaps spacing-10-bottom">
                                {type}
                            </p>
                            {blocks}
                            {urls}
                        </div>
                        <span role="button"
                              className="message-bubbles__close-button type-blue"
                              onClick={this.close}
                        >
                            {CloseSvg}
                        </span>
                    </li>
                </ul>
            </div>
        )
    }
}

MessageBlocks.propTypes = {
    messages: PropTypes.array,
    clearErrors: PropTypes.func.isRequired,
};
