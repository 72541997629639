import React, {useState} from 'react';

import type {User} from "../../Interfaces/User";

const ResendInviteModal = (props: { user: User, updateModalContent: () => void, resendInvite: () => void }) => {
    const [submitting, setSubmitting] = useState(false);

    const submit = () => {
        setSubmitting(true);
        props.resendInvite(props.user.userId)
            .then(() => setSubmitting(false));
    };

    return (
        <>
            <div className="spacing-30-bottom">
                <div className="popup__form__row spacing-20-bottom">
                    <p className="type-normal-body spacing-30-bottom">
                <span className="type-large-body type-force-newline">
                    Resend invite link to <b>{props.user.username}</b>?
                </span>
                    </p>
                </div>
            </div>
            <div className="ui-hide-mobile">
                <div className="popup__form__gray-bar padding-24">
                    <div className="popup__form__row popup__form__row__slam-right">
                        <div className="popup__form__cell">
                    <span role="button"
                          className="type-small-body type-heavy type-blue"
                          onClick={() => props.updateModalContent()}
                    >
                        Cancel
                    </span>
                        </div>
                        <div className="popup__form__cell">
                            <button className="button ui-normal-button"
                                    disabled={submitting}
                                    onClick={submit}>
                                Resend Invite
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ui-hide-full ui-hide-tablet">
                <div className="popup__form__row padding-40-bottom">
                    <button className="button ui-normal-button ui-full-width-button"
                            disabled={submitting}
                            onClick={submit}>
                        Resend Invite
                    </button>
                </div>
            </div>
        </>
    );
}

export default ResendInviteModal;