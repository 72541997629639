import React, { useCallback, useEffect, useRef, useState } from 'react';

import Input from '../common/components/form_elements/Input';

const PhoneEditor = (props) => {
	const { phoneNumber, updatePhoneNumber } = props;
	
	const [editorActive, setEditorActive] = useState(false);
	const [editorValue, setEditorValue] = useState('');
	const [validationError, setValidationError] = useState(false);
	
	const editorRef = useRef();
	
	const cancelEditing = useCallback(() => {
		setEditorValue(phoneNumber);
		setEditorActive(false);	
	}, [phoneNumber]);
	
	const commitEditing = useCallback(() => {
		if (validateUSPhoneNumber(editorValue)) {
			updatePhoneNumber(editorValue);
			setValidationError(false);
			setEditorActive(false);
		} else {
			setValidationError(true);
		}	
	}, [updatePhoneNumber, editorValue]);
	
	const handleKeys = useCallback((event) => {
		if (event.key === 'Escape' || event.keyCode === 27) {
			cancelEditing();
		}
		
		if (event.key === 'Enter' || event.keyCode === 13) {
			commitEditing(event);
		}
	}, [cancelEditing, commitEditing]);
	
	useEffect(() => {
		window.addEventListener('keydown', handleKeys);
		
		return () => {
			window.removeEventListener('keydown', handleKeys);
		}
	}, [editorValue, handleKeys]);
	
	useEffect(() => {
		setEditorValue(props.phoneNumber);
	}, [props.phoneNumber]);
	
	useEffect(() => {
		if (editorActive) {
			editorRef.current.focus();
			editorRef.current.select();
		}
	}, [editorActive]);
	
	const validateUSPhoneNumber = (phone) => {
		var regex = /^(1-?)?(\([0-9]{3}\)|[0-9]{3})[ -]?[0-9]{3}[ -]?[0-9]{4}$/;
		return regex.test(phone);
	};
	
	return (
		<div className={`onboarding__phone-editor  ${props.extraClass ? props.extraClass : ''}`}>
			{!editorActive ? (
				<>
				<div>
					<span className="type-large-subhead type-heavy">{props.phoneNumber}</span>
				</div>
				<div>
					<button
						className="type-heavy type-large-body type-blue button-clean"
						onClick={() => {
							setEditorActive(true);
						}}
					>
						Edit
					</button>
				</div>
				</>
			) : (
				<>
				<div>
					<Input
						id="phoneNumber"
						type="tel"
						name="phoneNumber"
						value={editorValue}
						onChange={(e) => {
							setEditorValue(e.target.value);
						}}
						inputRef={editorRef}
						hasError={validationError}
					/>
				</div>
				<div>
					<button
						className="type-heavy type-large-body type-blue button-clean"
						onClick={commitEditing}
					>
						Save
					</button>
				</div>
				<div>
					<button
						className="type-large-body type-blue button-clean"
						onClick={cancelEditing}
					>
						Cancel
					</button>
				</div>
				</>
			)}
		</div>
	);
};

export default PhoneEditor;