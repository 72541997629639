import React from 'react';

import LearnMoreFooter from './LearnMoreFooter';

const OnboardingScheduled = () => {
	return (
		<div className="onboarding-plain-section">
			<div className="onboarding-section__content">
				<h2 className="type-large-subhead type-heavy">
					Your Call Onboarding has been Scheduled
				</h2>
				<p className="type-large-body">
					One of our Onboarding Specialists is looking forward to connecting with you at your scheduled time.
				</p>
				
				<LearnMoreFooter />
			</div>
		</div>
	);
};

export default OnboardingScheduled;