import React, {Component} from 'react';
import axios from 'axios';
import {withRouter} from "react-router-dom";

import {get} from '../../Requests';
import {formatDate} from "../../Util";
import {EXPECTED_COMPLETION_DEFAULT_DATE} from './your_campaigns/YourCampaigns';
import ContractorService from "../../service/ContractorService";
import AuthService from "../../service/AuthService";
import LoadingGif from "../../common/components/LoadingGif";

class SelectEnrollmentConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initiating: true,

            campaign_build_status: '',
            estimated_completion_date: '',
            scheduleACallLink: '',

            // temporary state for "Clone Campaigns for Contractor" link UI feedback
            cloned_campaigns: false,
            cloning_campaigns: false
        };

        /**
         * Expected props (not complete):
         *  schedule_a_call_link
         */
        this.cancelSignal = axios.CancelToken.source();
        this.contractorService = new ContractorService();
    }

    componentDidMount() {
        this.getYourCampaignsData();
        this.contractorService.getScheduleCallLink(AuthService.getItem('contractorId'))
            .then(({scheduleACallLink}) => this.setState({scheduleACallLink}))
    }

    componentWillUnmount(): void {
        this.cancelSignal.cancel();
        this.contractorService.cancelSignal.cancel();
    }

    getYourCampaignsData() {
        let url = 'signup/your-campaigns';
        get(url, this.cancelSignal.token)
            .then((resp) => {
                if (!resp || resp.status !== 200) {
                    console.log(`Unexpected response from url ${url}, response = :`, resp);
                    return;
                }

                // did not complete enrollment
                if (!resp.data.completed_enrollment) {
                    this.props.history.push('/select-enroll');
                }

                this.setState({
                        initiating: false,
                        campaign_build_status: resp.data.campaign_build_status,
                        estimated_completion_date: resp.data.estimated_completion_date
                    },
                    () => window.scrollTo(0, 0));
            });
    }

    render() {
        if (this.state.initiating) {
            return (<div className="loading-gif-container full-height">
                <LoadingGif/>
                </div>);
            ;
        }

        const estimatedCompletionDate = this.state.estimated_completion_date
            ? formatDate(this.state.estimated_completion_date)
            : EXPECTED_COMPLETION_DEFAULT_DATE;

        return <div>
            <div className="row padding-50-bottom">
                <div className="col alpha grid-2 empty"/>
                <div className="col grid-8 no-margin-mobile">
                    <div className="module">
                        <div className="module__header module__twothirdswidth">
                            <h3 className="type-large-subhead type-single-line">
                                Your Campaigns Are In Production
                            </h3>
                        </div>
                        <div className="module__contentbox module__twothirdswidth">
                            <p className="type-large-body spacing-30-bottom">
                                Thanks for completing your Select Program Enrollment.
                                We will use this information to build your Campaigns.
                            </p>

                            <div className="simpleflex__row simpleflex__row__wrap__mobile spacing-30-bottom">
                                <div
                                    className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-30-bottom-mobile">
                                    <p className="type-normal-subhead type-allcaps type-heavy type-narrow-line-height spacing-20-bottom">
                                        Campaign Status
                                    </p>
                                    <div>
                                        <span
                                            className="type-normal-headline type-orange type-heavy type-single-line">
                                            {this.state.campaign_build_status}
                                        </span>
                                    </div>
                                </div>
                                <div className="simpleflex__cell simpleflex__cell__full-width__mobile">
                                    <p className="type-normal-subhead type-allcaps type-heavy type-narrow-line-height spacing-20-bottom">
                                        Expected Completion
                                    </p>
                                    <div>
                                        <span
                                            className="type-normal-headline type-orange type-heavy type-single-line">
                                            {estimatedCompletionDate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <p className="type-large-body">
                                If you need more help or have any questions, please{' '}
                                <a href={this.state.scheduleACallLink} className="type-heavy" target="_blank">
                                    schedule a call
                                </a>{' '}
                                with your Select Onboarding Specialist.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col omega grid-2 empty"/>
                <div className="clear-block"/>
            </div>
            <div className="row padding-50-bottom">
                <div className="col alpha grid-2 empty"/>
                <div className="col grid-8 no-margin-mobile">
                    <div className="module">
                        <div className="module__header module__twothirdswidth">
                            <h3 className="type-large-subhead type-single-line">
                                What Happens Next?
                            </h3>
                        </div>

                        <div className="module__contentbox module__twothirdswidth">
                            <div className="type-large-body">
                                <div className="spacing-10-bottom">
                                    <p className="type-normal-subhead type-allcaps type-heavy ">Content</p>
                                    <p className="type-left-side-bump">
                                        Our Content Optimization Team will review
                                        your company information and start building your campaign landing pages.
                                    </p>
                                </div>
                                <div className="spacing-10-bottom">
                                    <p className="type-normal-subhead type-allcaps type-heavy">Search Ads</p>
                                    <p className="type-left-side-bump">
                                        Our Search Advertising Analysts will review
                                        your service offerings and service areas to develop a custom strategy.
                                    </p>
                                </div>
                                <div className="spacing-10-bottom">
                                    <p className="type-normal-subhead type-allcaps type-heavy">Launch</p>
                                    <p className="type-left-side-bump">
                                        Once your Campaigns have been built,
                                        you will be notified and invited to log in.
                                        Your Campaigns will be scheduled to go live
                                        on the next business day after you get the invite.
                                    </p>
                                </div>
                            </div>

                            <div className="type-centered spacing-50-top">
                                <a className="button ui-normal-button" href="/dashboard">Go to mySD Dashboard</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col omega grid-2 empty"/>
                <div className="clear-block"/>
            </div>
        </div>;
    }
}

export default withRouter(SelectEnrollmentConfirmation);
