import React from 'react';
import PropTypes from 'prop-types';

import ThresholdProgressBar from './ThresholdProgressBar';
import BillingThresholdChangeModal from './BillingThresholdChangeModal';
import CurrentServicePeriodStats from './CurrentServicePeriodStats';
import AuthService from "../../service/AuthService";

const CurrentBalanceSection = (props) => {
    const openThresholdModal = () => props.updateModalContent(
        <BillingThresholdChangeModal
            currentThreshold={props.balanceSummary.threshold}
            updateModalContent={props.updateModalContent}
            updateMessageBlocks={props.updateMessageBlocks}
        />,
        'Request Billing Threshold Change',
        null,
        true
    );

    return <div className="wide-col alpha grid-6">
        <div className="module">
            <div className="module__header module__halfwidth">
                <h3 className="type-large-subhead type-single-line">
                    Current Balance
                </h3>
            </div>
            <div className="module__contentbox module__halfwidth">
                <CurrentServicePeriodStats
                    currentBalance={props.balanceSummary.current_balance}
                    leadCost={props.balanceSummary.lead_cost}
                    leadCredit={props.balanceSummary.lead_credit}
                    unpaidStatement={props.balanceSummary.unpaid_statement}
                />
                <h4 className="type-normal-subhead type-heavy type-allcaps type-single-line spacing-50-top spacing-30-bottom">
                    Billing Threshold
                </h4>
                <ThresholdProgressBar threshold={props.balanceSummary.threshold}
                                      balance={props.balanceSummary.current_balance}/>
                <p className="type-small-body spacing-30-top">
                    <span className="type-force-newline">
                        This displays the amount you owe on your
                        {' ' + props.whiteLabelPartnership.white_label_name} Account.
                        This includes all past due statements, current
                        Billable Leads (including Today’s leads) {AuthService.isMarketplace
                        ? 'and Lead Credits.'
                        : ', Lead Credits, and Monthly Subscription costs.'}
                    </span>
                    <span className="type-force-newline spacing-10-top">
                        <span role="button"
                              className="type-small-body type-heavy type-blue"
                              onClick={openThresholdModal}
                        >
                            Request Billing Threshold Change
                        </span>
                        <span className="minor-side-rails">•</span>
                        <a className="type-small-body type-heavy" target="_blank"
                           href={AuthService.isMarketplace
                               ? "https://support.servicedirect.com/how-you-are-billed"
                               : "https://support.servicedirect.com/select/how-you-are-billed"}
                        >
                                Learn More About When You Will Be Billed
                        </a>
                    </span>
                </p>
            </div>
        </div>
    </div>;
};

CurrentBalanceSection.propTypes = {
    balanceSummary: PropTypes.object.isRequired,
    whiteLabelPartnership: PropTypes.object,
    updateModalContent: PropTypes.func,
    updateMessageBlocks: PropTypes.func
};

export default CurrentBalanceSection;
