import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {QuestionMarkSvg} from './Svgs';

/**
 * Props and State for <Tooltip>
 *
 *  state.isOpen boolean - whether the Tooltip is open/displaying or not
 *
 *  state.bodyClickListener - EventListener object with handleEvent method.
 *      We store this in the state when we first open the Tooltip and do
 *      addEventListener, so that we can remove this same listener later
 *      when we close the Tooltip (and not accidentally remove other listeners)
 *
 *  props.content JSX - the content to render in the Tooltip Dialog
 *
 */
export default class Tooltip extends Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
        this.notchRef = React.createRef();

        this.xMargin = 20;
        this.yMargin = 20;

        this.mobilePositionRequired = this.mobilePositionRequired.bind(this);
        this.positionTooltip = this.positionTooltip.bind(this);
        this.resetDefaultDisplay = this.resetDefaultDisplay.bind(this);

        this.state = {
            isOpen: false,
            bodyClickListener: null,
        };
    };

    componentDidMount() {
		if (this.props.updateTooltipCloseFunction) {
			this.props.updateTooltipCloseFunction(this.externalTooltipClose);
		}
        window.addEventListener('resize', this.positionTooltip);
    }

    componentDidUpdate() {
        this.positionTooltip();
    }

    toggleTooltip = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (this.state.isOpen) {
            this.closeTooltip();
        }
        else {
            this.openTooltip();
        }
    };

    openTooltip = () => {
        //
        // Set a click handler on the body to close the tooltip again.
        // Store the click handler so we can remove it later.
        //
        let bodyClickListener = {
            handleEvent: this.closeTooltip
        };
        this.setState({isOpen: true, bodyClickListener: bodyClickListener});
        document.body.addEventListener('click', bodyClickListener);
    };

	externalTooltipClose = (callback) => {
        //
        // Remove the body click handler if any
        //
        if (this.state.bodyClickListener) {
            document.body.removeEventListener('click', this.state.bodyClickListener);
        }
        this.setState({isOpen: false, bodyClickListener: null}, callback);
	};
	
    closeTooltip = (event, callback) => {
        // don't close when click on tooltip contents
        let ignoreClickOnTooltip = '.tool-tip__contents *';
        if (!event.target.matches(ignoreClickOnTooltip)) {
            //
            // Make sure that e.g. the body click doesn't kick in
            // when you click on the (?) again
            //
            event.preventDefault();
            event.stopPropagation();

            //
            // Remove the body click handler if any
            //
            if (this.state.bodyClickListener) {
                document.body.removeEventListener('click', this.state.bodyClickListener);
            }
            this.setState({isOpen: false, bodyClickListener: null});
        }
    };

    mobilePositionRequired = () => {
        const tooltip = this.containerRef.current;

        if (tooltip) {
            const {left, right} = tooltip.getBoundingClientRect();
            const windowWidth = window.visualViewport ? window.visualViewport.width : window.innerWidth;

            if ((windowWidth <= 640) &&
                ((left < this.xMargin) || (right > windowWidth - this.xMargin))) {
                return true;
            }
        }

        return false;
    };

    resetDefaultDisplay = () => {
        const tooltip = this.containerRef.current;
        const notch = this.notchRef.current;

        if (tooltip && notch) {
            // Reset to defaults for clean positioning checks
            tooltip.classList.remove('tool-tip__mobile-display');
            tooltip.style.width = null;
            tooltip.style.left = null;
            tooltip.style.top = null;
            tooltip.style.maxWidth = '440px';
            tooltip.style.minWidth = '300px';
            notch.style.left = null;
            notch.style.top = null;
        }
    };

    positionTooltip = () => {
        const tooltip = this.containerRef.current;
        const notch = this.notchRef.current;

        if (!tooltip) {
            return;
        }

        // Remove inline styles from tooltip so that positioning checks will be
        // made against 'natural' CSS-defined display
        this.resetDefaultDisplay();

        const anchor = tooltip.parentNode;
        const rect = anchor.getBoundingClientRect();

        let tooltipRect;
        let notchLeft, notchTop;

        let boundingWidth, boundingHeight;
        if (this.props.modalContainerRef && this.props.modalContainerRef.current) {
            // If the tooltip is in a modal, the dimensions of the modal will define
            // the positioning logic
            const modalContainer = this.props.modalContainerRef.current;
            boundingWidth = modalContainer.clientWidth;

            // we get the clientHeight from the .popup__contentbox node only if it exists; it won't exist in storybook
            boundingHeight = modalContainer.querySelector('.popup__contentbox')
                ? modalContainer.querySelector('.popup__contentbox').clientHeight
                : modalContainer.clientHeight;
        }
        else {
            // If the tooltip is not in a modal, the dimensions of the window will define
            // the positioning logic
            boundingWidth = window.visualViewport ? window.visualViewport.width : window.innerWidth;
            boundingHeight = window.innerHeight;
        }

        // Check for tooltip positioning special cases
        if (this.mobilePositionRequired()) {
            // Use mobile positioning logic, centering the tooltip in the
            // viewport or modal within a margin
            let tooltipLeft;
            if (this.props.modalContainerRef) {
                tooltipLeft = -anchor.offsetLeft + this.xMargin;
            }
            else {
                tooltipLeft = -rect.left + this.xMargin;
            }
            tooltip.classList.add('tool-tip__mobile-display');
            tooltip.style.width = `${boundingWidth - (2 * this.xMargin)}px`;
            tooltip.style.maxWidth = 'none';
            tooltip.style.minWidth = 'none';
            tooltip.style.left = `${tooltipLeft}px`;

            tooltipRect = tooltip.getBoundingClientRect();

            // Check if tooltip extends beyond top of viewport and override default behavior
            if (tooltipRect.top < 0) {
                tooltip.classList.remove('tool-tip__mobile-display');
                tooltip.classList.add('tool-tip__mobile-display__bottom');
            }

            notchLeft = rect.left - tooltipRect.left;

            notch.style.left = `${notchLeft}px`;
        }
        else if (this.props.modalContainerRef) {
            // If the tooltip is contained in a modal,
            // check for collisions with boundaries with 'natural' positioning
            // and move tooltip accordingly
            if (this.props.position === 'left' || this.props.position === 'right') {
                if (anchor.offsetTop + tooltip.offsetTop < 0) {
                    tooltip.style.top = `${-anchor.offsetTop + this.yMargin}px`;
                }
                else if (anchor.offsetTop + tooltip.offsetTop + tooltip.offsetHeight > boundingHeight) {
                    tooltip.style.top = `${boundingHeight - tooltip.offsetHeight - this.yMargin - anchor.offsetTop}px`;
                }

                tooltipRect = tooltip.getBoundingClientRect();
                notchTop = rect.top - tooltipRect.top;

                notch.style.top = `${notchTop}px`;
            }
            else {
                if (anchor.offsetLeft + tooltip.offsetLeft < 0) {
                    tooltip.style.left = `${-anchor.offsetLeft + this.xMargin}px`;
                }
                else if (anchor.offsetLeft + tooltip.offsetLeft + tooltip.offsetWidth > boundingWidth - 16) { // Subtract 16 from boundingWidth for potential scroll bar
                    tooltip.style.left = `${boundingWidth - tooltip.offsetWidth - this.xMargin - anchor.offsetLeft}px`;
                }

                tooltipRect = tooltip.getBoundingClientRect();
                notchLeft = rect.left - tooltipRect.left;

                notch.style.left = `${notchLeft}px`;
            }
        }
        else {
            // Tooltips are now variable widths, so left positioning on top and bottom positioned Tooltips
            // must be updated dynamically rather than relying on style from stylesheet.
            if (this.props.position === 'top' || this.props.position === 'bottom') {
                tooltip.style.left = `calc(-${tooltip.offsetWidth / 2}px + 0.5em)`;
            }
        }
    };

    render() {
        let maybeExpandedClass = this.state.isOpen ? 'tool-tip__expanded' : '';
        let maybePositionClass = this.props.position ? 'tool-tip__container__' + this.props.position : '';
        let maybeMobileClass = this.mobilePositionRequired() ? 'tool-tip__mobile-display' : '';

        const anchor = this.props.anchor
            ? this.props.anchor
            : QuestionMarkSvg;
        const launcher = this.props.hover
            ? <span role="button"
                    className="tool-tip__launcher tool-tip__trigger__hover type-blue type-heavy"
                    onMouseOver={this.openTooltip}
                    onMouseOut={this.closeTooltip}
                    onClick={(e) => this.toggleTooltip(e)}
            >
                {anchor}
            </span>
            : <span role="button"
                    className={`inline-help tool-tip__launcher type-blue type-heavy${this.props.disabledStyle ? ' disabled' : ''}`}
                    onClick={(e) => this.toggleTooltip(e)}
            >
                {anchor}
            </span>;

        return <span className="tool-tip__wrapper">
                {launcher}
            <span ref={this.containerRef} style={{maxWidth: '440px', minWidth: '300px'}}
                  className={`tool-tip__container ${maybePositionClass} ${maybeExpandedClass} ${maybeMobileClass}`}>
                    <span className="tool-tip__contents">{this.props.content}</span>
                    <span ref={this.notchRef} className="tool-tip__notch"/>
                    <span className="tool-tip__notch__mask"/>
                </span>
            </span>;
    };
};

Tooltip.propTypes = {
    modalContainerRef: PropTypes.object,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    position: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};
