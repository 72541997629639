import React, {Component} from "react";
import queryString from "query-string";

import SignupMarketplaceService from '../service/SignupMarketplaceService';
import Modal from "../common/Modal";
import {errorHandler} from "../Requests";
import AuthService from "../service/AuthService";
import TermsAndConditions from "../client/TermsAndConditions";
import Checkbox from "../common/components/form_elements/Checkbox";
import {Input} from "../common/components/form_elements/Input";
import {toCamel} from "../Util";
import Validator from "../lib/Validator";
import LoadingGif from "../common/components/LoadingGif";
import GoogleTagManagerService from "../service/GoogleTagManagerService";

export default class StepFive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalHeader: null,
            modalContent: null,
            data: {
                password: {value: '', errorMessage: ''}
            },
            serviceCategoryInformation: {},
            processing: false,
            termsAndConditions: false,
            termsAndConditionsErrorMessage: '',
            needPassword: false,
        };

        this.signupMarketplaceId = AuthService.getItem('signupMarketplaceId');

        let query = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});
        this.token = query.token;

        this.signupMarketplaceService = new SignupMarketplaceService();
    }

    componentDidMount() {
        this.signupMarketplaceService.getStepFive()
            .then(response => {
                this.setState({needPassword: !!response.needPassword,
                    serviceCategoryInformation: response.serviceCategoryInformation})
            })
            .catch(error => {
                if (errorHandler(error)) {
                    window.location = error.response.data.location;
                }
            });
    }

    componentWillUnmount() {
        this.signupMarketplaceService.cancelSignal.cancel();
    }

    updateModalContent = (modalContent, modalHeader, modalWidth, modalFlatBottom) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom
        });
    };

    /**
     * updates regular input
     * TODO: this functionality can be shared between the different steps
     * @param {FocusEvent} event
     */
    handleChange = (event) => {
        let {data} = this.state;
        data[event.target.name].value = event.target.value;
        this.setState({data});
    };

    /**
     * handles validation of the field
     * @param {FocusEvent} event
     */
    handleBlurInput = (event) => {
        let {data} = this.state;
        data[event.target.name].errorMessage = this.getErrorMessage(event.target.name, event.target.value);
        this.setState({data});
    };

    /**
     * returns an error message for the specific set of field name and its value; empty string when there's no error
     * @param {string} name - the fields name
     * @param {string} value - the fields value
     * @param {boolean} checkingEmail
     * @return {string}
     */
    getErrorMessage = (name: string, value: string, checkingEmail: boolean) => {
        switch (name) {
            case 'companyWebsite':
                return value && !Validator.validateUrl(value)
                    ? 'Invalid company website URL'
                    : '';

            case 'email':
                if (!value) {
                    return 'Email address is required';
                }

                if (checkingEmail) {
                    return 'Checking email availability';
                }

                if (!Validator.validateEmail(value)) {
                    return 'Invalid email address';
                }

                return '';

            case 'password':
                if (value.length >= 6) {
                    return '';
                }

                return value
                    ? 'Your password must be at least 6 characters long'
                    : 'This field is required';

            case 'recaptcha':
                if (!value) {
                    this.recaptchaRef.current.reset();
                }
            // falls through

            default:
                return !value || !value.trim()
                    ? 'This field is required'
                    : '';
        }
    };

    /**
     * builds the data that will be sent to the server
     * @return {object|boolean} the data object on success, or false on error
     */
    buildPostData = () => {
        return {
            password: this.state.data.password.value,
            termsAndConditions: this.state.termsAndConditions,
        };
    };

    /**
     * submits the form to the API
     * @param {Event} event
     */
    submit = (event) => {
        event.preventDefault();
        
        // front-end validation
        if (!this.state.data.password) {
            this.setState({
                data: {
                    ...this.state.data,
                    password: {...this.state.data.password, errorMessage: 'Please enter a password'}
                }
            });
        }
        if (!this.state.termsAndConditions) {
            this.setState({termsAndConditionsErrorMessage: 'Please accept the terms and conditions to continue'});
        }

        const data = this.buildPostData();
        if (!data || !this.state.termsAndConditions) {
            return;
        }

        this.setState({processing: true});
        this.signupMarketplaceService.updateStepFive(data)
            .then((response) => {
                const mpStep4CompletedByAdminUserId = response.data?.mp_step4_completed_by_admin_user_id || 0;
                const masterServiceCategory = this.state.serviceCategoryInformation.masterServiceCategory;
                const serviceCategories = this.state.serviceCategoryInformation.serviceCategories.join(',');

                // send step5Complete and signUpComplete parameters to Data Layer
                GoogleTagManagerService.updateDataLayer({
                    event: "step5Complete",
                    assistingAdminUserId: mpStep4CompletedByAdminUserId,
                    masterServiceCategory,
                    serviceCategories
                })
                GoogleTagManagerService.updateDataLayer({
                    event: "signUpComplete",
                    assistingAdminUserId: mpStep4CompletedByAdminUserId,
                    masterServiceCategory,
                    serviceCategories
                })
                // remove the signup items so that we can log into the real system
                AuthService.isSignup = false;
                AuthService.setItem('signupMarketplaceId', 0);
                
                AuthService.isAwaitingOnboarding = true;
                window.location = '/onboarding/schedule-onboarding';
            })
            .catch((error) => {
                this.setState({processing: false});
                if (errorHandler(error)) {
                    console.table(error);
                    let {data} = this.state;
                    if (error.response?.data?.parameter) {
                        data[toCamel(error.response.data.parameter)].errorMessage = error.response.data.message;
                    }
                    this.props.updateMessageBlocks([error.response.data.message], 'error');
                    this.setState({data});
                }
            });
    };

    render() {
        return <div>
            <Modal
                content={this.state.modalContent}
                header={this.state.modalHeader}
                updateModalContent={this.updateModalContent}
            />
            <h1 className="module__twothirdswidth type-large-subhead type-single-line module-header">
                Welcome to Service Direct!
            </h1>
            <div className="module__contentbox module__twothirdswidth">

                <h2 className="type-normal-subhead type-heavy spacing-10-bottom type-centered">
                    Complete your account setup.
                </h2>

                {this.state.needPassword
                    ? <p className="type-normal-body spacing-50-bottom type-centered">
                        In order to log in to mySD, you must set your password and agree to the Terms of Service.
                    </p>
                    : <p className="type-normal-body type-centered">
                        In order to log in to mySD, you must agree to the Terms of Service.
                    </p>}

                {this.state.needPassword &&
                    <div className="form__cell form__cell__50 form__cell__100__mobile spacing-50-top">
                        <Input name="password"
                               type="password"
                               id="signupPassword"
                               label="Set Password"
                               value={this.state.data.password.value}
                               onBlur={this.handleBlurInput}
                               onChange={this.handleChange}
                               errorMessage={this.state.data.password.errorMessage}
                               required={true}
                        />
                    </div>}

                <label
                    htmlFor="termsAndConditions"
                    className={"type-small-body type-narrow-line-height type-heavy spacing-50-top spacing-20-bottom"
                        + (this.state.termsAndConditionsErrorMessage ? " type-alert" : "")
                    }
                >
                    Terms of Service
                </label>
                <div className="spacing-40-bottom">
                    <Checkbox
                        name="termsAndConditions"
                        label={<>I've read and accept the{' '}
                            <button className="button-clean type-blue type-normal-body" type="button"
                                    onClick={() => this.props.updateModalContent(
                                        <TermsAndConditions whiteLabelPartnership={this.props.whiteLabelPartnership}
                                                            inAccountActivation={false}
                                                            updateModalContent={this.props.updateModalContent}
                                                            history={this.props.history}/>,
                                        this.props.whiteLabelPartnership.white_label_name + " Terms and Conditions"
                                    )}>
                                Service Direct Terms and Conditions
                            </button>
                        </>}
                        onChange={() => this.setState({
                            termsAndConditions: !this.state.termsAndConditions,
                            termsAndConditionsErrorMessage: '',
                        })}
                        checked={this.state.termsAndConditions}
                        errorMessage={this.state.termsAndConditionsErrorMessage}
                    />
                </div>
            </div>

            <div className="padding-120-bottom spacing-30-top flex__row">
                <span/>
                <div className="type-centered">
                    {this.state.processing
                        ? <LoadingGif/>
                        : <button className="button ui-large-button ui-full-width-button-mobile"
                                  onClick={this.submit} type="submit">
                            <span className="type-hide-full type-hide-tablet">Continue</span>
                            <span className="type-hide-mobile">Start Getting Leads</span>
                            <img src="/images/icon-next-arrow.svg" alt="Right Arrow" className="right"/>
                        </button>}
                </div>
            </div>
        </div>;
    }
}
