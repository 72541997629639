import React from 'react';

import Checkbox from '../common/components/form_elements/Checkbox';
import LoadingGif from '../common/components/LoadingGif';

const NavigationFooter = (props) => {
	let quizCompleted;
	if (props.step < 6) {
		// Checking for whether questions have been answered, regardless of correctness
		quizCompleted = props.quizAnsweredState.reduce((acc, val) => {
			if (!val) {
				return false;
			}
			
			return acc;
		}, true) && props.quizErrors.length === 0;
	}
	
	let surveyCompleted;
	if (props.step === 6) {
		// Checking whether required survey and certification questions have been answered
		surveyCompleted = true;
		
		const _state = props.stepProps.finalStepState;
		
		if (!_state.contactTimeZone) {
			surveyCompleted = false;
		}
		if (!_state.yearsInBusiness) {
			surveyCompleted = false;
		}
		if (!_state.currentMarketing) {
			surveyCompleted = false;
		}
		if (_state.signature.length === 0) {
			surveyCompleted = false;
		}
		if (!_state.certification1) {
			surveyCompleted = false;
		}
		if (!_state.certification2) {
			surveyCompleted = false;
		}
		if (!_state.certification3) {
			surveyCompleted = false;
		}
	}
	
	return (
		<div className="onboarding__navigation-footer spacing-60">
			{props.loadingNextStep ? (
				<div>
					<LoadingGif />
				</div>
			) : (
				<>
				{(props.step < 6) ? (
					<>
					<div>
						<Checkbox
							name="onboarding-completion"
							label="I watched the video and completed this Section"
							onChange={(e) => {
								props.setHighlightErrors(false);
								props.setCompletionChecked(e.target.checked)
							}}
							hasError={!props.completionChecked && props.highlightErrors}
						/>
					</div>
					<div>
						<button
							className="button ui-large-button"
							onClick={(e) => props.submit(e, props.step)}
							disabled={!props.completionChecked || !quizCompleted}
							title={(!props.completionChecked || !quizCompleted) ?
								'Please select the correct Quiz Answers and Check the Certification Box' :
								''
							}
						>
							<span>Next Step</span>
							<img src="/images/icon-next-arrow.svg" alt="Right Arrow" className="right" />
						</button>
					</div>
					</>
				) : (
					<div>
						<button
							className="button ui-large-button"
							onClick={(e) => props.submit(e, props.step)}
							disabled={!surveyCompleted}
							title={(!surveyCompleted) ?
								'Please complete all sections on this page' :
								''
							}
						>
							<span>Complete Onboarding</span>
							<img src="/images/icon-next-arrow.svg" alt="Right Arrow" className="right" />
						</button>
					</div>
				)}
				</>
			)}
		</div>
	);
};

export default NavigationFooter;
