import React, {Component} from 'react';
import PopUpModalInputSection from "../../PopUpModalInputSection";
import PropTypes from 'prop-types';
import Checkbox from "../form_elements/Checkbox";
import Tooltip from "../../Tooltip";
import {CALL_RECORDING_TEXT} from "../../StaticValues";
import RadioGroup from "../form_elements/RadioGroup";
import {CallerIdTooltip} from "../../TooltipsCopy";
import {adjustCallerIdOptions} from "../../../UtilJsx";


/**
 * As of 2019-10-31 This is used in AA Step 2, but NOT LDS Popup for Step 4 nor Campaigns Manager
 */

export default class LeadDeliverySettingsForm extends Component {
    render() {
        let callRecording = this.props.callRecording,
            callerIdOption = this.props.callerIdOption;

        const callerIdRadioOptions = adjustCallerIdOptions(this.props.callerIdRadioOptions)

        return (
        <div className="row padding-20-bottom">
        <div className="col alpha grid-2 empty"/>
        <div className="col grid-8">
        <div className="module">
            <div className="module__contentbox module__contentbox__no-header module__twothirdswidth">
                <h4 className="type-normal-subhead type-heavy type-allcaps type-narrow-line-height spacing-10-bottom">
                    Lead Delivery &amp; Notification Settings
                </h4>
                <p className="type-normal-body spacing-40-bottom">
                    These will be the default settings for your campaigns. You can adjust these later as needed.</p>
                <div>
                    <div className="simpleflex__row simpleflex__row__wrap__mobile spacing-30-bottom no-margin-mobile">
                        <div
                            className="simpleflex__cell simpleflex__grid__2-up-in-grid-8 simpleflex__cell__full-width__mobile">
                            <p className="type-large-body type-heavy type-narrow-line-height no-margin-top spacing-30-bottom">
                                LEAD DELIVERY: PHONE CALLS
                                <span> </span>
                                <Tooltip
                                    content={ (
                                        <>
                                            <span className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                Lead Delivery for Phone Calls
                                            </span>
                                            <span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                These are the phone numbers that will receive Phone Leads.
                                                When a potential customer visits your campaign landing page and dials the campaign's
                                                associated local tracking number, the phone numbers listed in your Phone Lead Delivery
                                                settings will ring. You are responsible for answering these calls from Phone Leads to qualify the opportunity.
                                            </span>
                                        </>
                                    ) }
                                    position={ 'right' }
                                />
                            </p>
                            <p className="type-normal-body type-heavy type-narrow-line-height spacing-10-bottom">
                                The following phone number(s) will receive inbound calls from Phone Leads.
                            </p>
                            <div className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                                <PopUpModalInputSection
                                    collection={this.props.forwardedLeadPhone}
                                    collectionName={"forwarded_lead_phone"}
                                    name={"forwarded_lead_phone"}
                                    handleInputChange={this.props.leadDeliveryOnChange}
                                    handleInputBlur={this.props.leadDeliveryOnBlur}
                                    addAnother={this.props.addAnother}
                                    deleteInput={this.props.deleteInput}
                                />
                            </div>
                            <div className="form__row spacing-30-bottom">
                                <div className="form__cell form__cell__100">
                                    <Checkbox
                                        name="call_recording"
                                        label={<span className="type-normal-body">
                                                <span className="type-heavy">
                                                    Record Phone Calls & Enable Lead Review
                                                </span>
                                            </span>}
                                        onChange={(event) =>
                                            this.props.updateLeadDeliveryCheckbox(event.target.name, event.target.checked)}
                                        checked={Number(callRecording.value) !== 0}
                                        hasError={!!callRecording.error}
                                        inputRef={callRecording.ref}
                                        tooltip={<Tooltip
                                            content={(
                                                <>
                                                <span className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                    Phone Call Recording & Lead Review
                                                </span>
                                                <span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                    You must opt in to activate Call Recording. Call Recording is required to be eligible for Lead Review and if this option is not selected, all leads longer than 20 seconds are considered Billable.
                                                </span>
                                                <span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                    Learn more about{' '}
                                                    <a
                                                        className="type-heavy"
                                                        href="https://support.servicedirect.com/select/billable-and-non-billable-leads"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                       Understanding Billable and Non-Billable Leads
                                                    </a>
                                                    .
                                                </span>
                                                </>
                                            )}
                                            position="right"
                                            />
                                        }
                                    />
                                </div>
                                <p className="type-normal-body">
                                    <span className="type-heavy">
                                        REQUIRED for Lead Review.
                                    </span>{' '}
                                    {CALL_RECORDING_TEXT}
                                </p>
                            </div>
                            <div className="form__row spacing-30-bottom-mobile">
                                <div className="form__cell form__cell__100">
                                    <div className="type-large-body type-heavy type-single-line spacing-20-bottom spacing-20-bottom-full spacing-20-bottom-tablet spacing-20-bottom-mobile" >
                                        DISPLAYED CALLER ID{' '}
                                        <Tooltip
                                            content={CallerIdTooltip()}
                                            position="right"
                                        />
                                        <label className="type-normal-body type-narrow-line-height type-force-newline spacing-20-top">
                                            You can choose to see the Caller ID information of the incoming caller,
                                            or you may choose to see a Tracking Number if you want to quickly recognize a Service Direct sourced caller.
                                        </label>
                                    </div>
                                    <RadioGroup
                                        label="Caller ID Setting"
                                        name="caller_id_option"
                                        vertical={true}
                                        isClearable={false}
                                        checkedOption={callerIdOption.value}
                                        hasError={!!callerIdOption.error}
                                        options={callerIdRadioOptions}
                                        onChange={this.props.updateLeadDeliveryCheckbox}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="simpleflex__cell simpleflex__grid__2-up-in-grid-8 simpleflex__cell__full-width__mobile">
                            <div className="type-large-body type-heavy type-narrow-line-height no-margin-top spacing-30-bottom">
                                LEAD DELIVERY: FORM LEADS &amp; LEAD NOTIFICATIONS
                                <span> </span>
                                <Tooltip
                                    content={ (
                                        <>
                                            <span className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                FORM LEAD DELIVERY & LEAD NOTIFICATIONS
                                            </span>
                                            <div className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                These are the email addresses that will receive a notification for:
                                                <ul>
                                                    <li>
                                                        Form Leads: This is when a potential customer fills out a form on your campaign landing page.
                                                    </li>
                                                    <li>
                                                        Phone Call Leads: An email notification that is sent once a Phone Lead has been delivered.
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    ) }
                                    position={ 'left' }
                                />
                            </div>
                            <label
                                className="type-normal-body type-heavy type-narrow-line-height type-force-newline spacing-5-bottom">
                                The following email address(es) will receive inbound Form Leads,
                                as well as email notifications for all leads (both form and phone leads).
                            </label>
                            <PopUpModalInputSection
                                collection={this.props.contactEmail}
                                collectionName={"contact_email"}
                                name={"contact_email"}
                                handleInputChange={this.props.leadDeliveryOnChange}
                                handleInputBlur={this.props.leadDeliveryOnBlur}
                                addAnother={this.props.addAnother}
                                deleteInput={this.props.deleteInput}
                            />

                            <p className="type-large-body type-heavy type-narrow-line-height spacing-40-top spacing-30-bottom">
                                TEXT MESSAGE NOTIFICATIONS
                            </p>
                            <label
                                className="type-normal-body type-heavy type-narrow-line-height type-force-newline spacing-5-bottom">
                                The following mobile numbers will receive a text message when a form lead is delivered or
                                a phone lead is completed.
                            </label>
                            <PopUpModalInputSection
                                collection={this.props.smsLeadAlertMultiple}
                                collectionName={"sms_lead_alert_multiple"}
                                name={"sms_lead_alert_multiple"}
                                handleInputChange={this.props.leadDeliveryOnChange}
                                handleInputBlur={this.props.leadDeliveryOnBlur}
                                addAnother={this.props.addAnother}
                                deleteInput={this.props.deleteInput}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <div className="col omega grid-2 empty"></div>
            <div className="clear-block"></div>
            </div>
        )
    }
}

LeadDeliverySettingsForm.propTypes = {
    forwardedLeadPhone: PropTypes.array.isRequired,
    contactEmail: PropTypes.array.isRequired,
    smsLeadAlertMultiple: PropTypes.array.isRequired,
    leadDeliveryOnChange: PropTypes.func.isRequired,
    callRecording: PropTypes.object.isRequired,
    callerIdOption: PropTypes.object.isRequired,
    updateLeadDeliveryCheckbox: PropTypes.func.isRequired,
};
