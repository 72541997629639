import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../common/Tooltip';
import {formatCurrency, formatNumber} from "../../Util";
import LeadSummaryCell from "../../common/lead_log/LeadSummaryCell";
import LoadingGif from "../../common/components/LoadingGif";
import {IconLockSvg} from "../../common/Svgs";
import {
    AppointmentBookedLikely, AppointmentBookedConfidence, AppointmentBookedRateLocked, AppointmentBookedRateUnlocked,
    CallsAnsweredConfidence, CallsAnsweredLikely, CallsAnsweredRateLocked, CallsAnsweredRateUnlocked,
    JobsWonConfidence, JobsWonLikely, JobsWonRateLocked, JobsWonRateUnlocked,
    RoiConfidence, RoiLocked, RoiUnlocked/*, RoiLikely*/
} from "./FilteredLeadsStatsModalTooltips";
import AuthService from "../../service/AuthService";
import type {IFilteredSummary} from "../../Interfaces/IFilteredSummary";

export default class FilteredLeadsStatsModal extends Component {
    CONFIDENCE_POOR = 60;
    CONFIDENCE_MODERATE = 80;

    confidenceColor(confidence: number): string {
        if (confidence < this.CONFIDENCE_POOR) {
            return 'type-flag--red';
        }
        if (confidence < this.CONFIDENCE_MODERATE) {
            return 'type-flag--gold';
        }

        return 'type-flag--green';
    }

    confidencePrefix(confidence: number): string {
        if (confidence < this.CONFIDENCE_POOR) {
            return 'Poor';
        }
        if (confidence < this.CONFIDENCE_MODERATE) {
            return 'Moderate';
        }

        return 'Good';
    }

    confidenceSpan(confidence): Object {
        return <span className={'type-flag spacing-10-bottom ' + this.confidenceColor(confidence)}>
            {this.confidencePrefix(confidence)} Confidence
            ({formatNumber(confidence, 0)}%)
        </span>;
    }

    render() {
        let filterMessage = this.props.filterMessage,
            leadFilteredSummary: IFilteredSummary = this.props.leadFilteredSummary,
            minConfidence = Number(leadFilteredSummary.minConfidence),
            isRevenueLocked = !AuthService.isAdmin && minConfidence > leadFilteredSummary.revenueConfidence,
            isCallsLocked = !AuthService.isAdmin && minConfidence > leadFilteredSummary.callAnsweredConfidence,
            isAppointmentsLocked = !AuthService.isAdmin && minConfidence > leadFilteredSummary.appointmentBookedConfidence,
            isJobLocked = !AuthService.isAdmin && minConfidence > leadFilteredSummary.jobWonConfidence;

        if (Object.keys(leadFilteredSummary).length === 0) {
            return <div className="type-centered"><LoadingGif/></div>;
        }

        return <>
            <div className="type-normal-subhead ui-bottom-grey-stroke padding-40-bottom">
                <span className="type-heavy">{formatNumber(this.props.totalRows, 0)}</span>{' '}
                {filterMessage}
                <p className="type-small-body type-narrow-line-height spacing-24-top">
                    <b className="type-red">Note:</b> The more Leads you manage, and the more completely you manage
                    them, the more accurate and useful your Performance Metrics become. Adjust your Lead Filters, such
                    as date range, to see more broad or more granular Metrics.
                </p>
            </div>

            <div className="type-normal-subhead type-heavy">
                <div className="spacing-5">Calls Answered</div>
                {' '}
                <div className="type-normal-body type-narrow-line-height">
                    {this.confidenceSpan(leadFilteredSummary.callAnsweredConfidence)}
                    <Tooltip
                        content={CallsAnsweredConfidence(leadFilteredSummary.billableLeadsCount, minConfidence)}
                        position="bottom"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                    <br/>
                    Confidence is based on {leadFilteredSummary.billableCallsManagedCount} Billable Leads with Calls
                    Answered Managed out of the{' '}
                    {leadFilteredSummary.billableLeadsCount}{' '} Billable Leads you are filtering by and excludes
                    Non-Billable Leads.
                    Call Answered Metrics below can include Non-Billable Leads so that you can see your Answered
                    performance on all Leads. {' '}
                </div>
            </div>
            <div
                className={'popup-stats__row ' + (isCallsLocked ? 'performance-row-grey' : 'performance-row-orange')}>
                <div className="popup-stats__cell">
                    <div
                        className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {leadFilteredSummary.callsAnsweredCount}
                    </div>
                    <div className="type-normal-body type-narrow-line-height">Calls Answered</div>
                </div>
                <div className="popup-stats__cell">
                    <div
                        className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {leadFilteredSummary.callsNotAnsweredCount}
                    </div>
                    <div className="type-normal-body type-narrow-line-height">Calls Not Answered</div>
                </div>
                <div className="popup-stats__cell">
                    <LeadSummaryCell
                        statContent={
                            isCallsLocked
                                ? IconLockSvg
                                : `${formatNumber(leadFilteredSummary.callAnsweredRate, 0)}%`
                        }
                        statType={isCallsLocked ? "icon" : ""}
                        statIconSizeClass="inline-icon__22"
                        statTypeClassString="type-small-headline type-semibold type-narrow-line-height"
                        bodyTypeSizeClass="type-normal-body"
                        statDescription="Call Answered Rate"
                        color={isCallsLocked ? "gray" : "orange"}
                        width={null}
                        gutter={null}
                        toolTipTitle="Call Answered Rate"
                        toolTipBody={isCallsLocked
                            ? CallsAnsweredRateLocked(leadFilteredSummary.billableLeadsCount, minConfidence)
                            : CallsAnsweredRateUnlocked}
                        toolTipPosition="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                </div>
                <div className="popup-stats__cell">
                    <LeadSummaryCell
                        statContent={formatNumber(leadFilteredSummary.likelyCallsAnswered, 0)}
                        statType=""
                        statIconSizeClass="inline-icon__22"
                        statTypeClassString="type-small-headline type-semibold type-narrow-line-height"
                        bodyTypeSizeClass="type-normal-body"
                        statDescription={<><b>Likely</b> Calls Answered</>}
                        color={isCallsLocked ? "gray" : "orange"}
                        width={null}
                        gutter={null}
                        toolTipTitle="Likely Calls Answered"
                        toolTipBody={CallsAnsweredLikely}
                        toolTipPosition="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                </div>
            </div>

            <div className="type-normal-subhead type-heavy">
                <div className="spacing-5">Booked Appointments</div>
                {' '}
                <div className="type-normal-body type-narrow-line-height">
                    {this.confidenceSpan(leadFilteredSummary.appointmentBookedConfidence)}
                    <Tooltip
                        content={AppointmentBookedConfidence(leadFilteredSummary.billableLeadsCount, minConfidence)}
                        position="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                    <br/>
                    Confidence is based on {leadFilteredSummary.appointmentManagedCount} Billable Leads with Booked
                    Appointments Managed out of the{' '}
                    {leadFilteredSummary.billableLeadsCount} Billable Leads you are filtering by. Both Booked
                    Appointment Confidence
                    and Metrics below exclude Non-Billable Leads.{' '}
                </div>
            </div>

            <div
                className={'popup-stats__row ' + (isAppointmentsLocked ? 'performance-row-grey' : 'performance-row-orange')}>
                <div className="popup-stats__cell">
                    <div
                        className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {leadFilteredSummary.appointmentBookedCount}
                    </div>
                    <div className="type-normal-body type-narrow-line-height">Booked Appointments</div>
                </div>
                <div className="popup-stats__cell">
                    <div
                        className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {leadFilteredSummary.appointmentMissedCount}
                    </div>
                    <div className="type-normal-body type-narrow-line-height">Did Not Book</div>
                </div>

                <div className="popup-stats__cell">
                    <LeadSummaryCell
                        statContent={
                            isAppointmentsLocked
                                ? IconLockSvg
                                : `${formatNumber(leadFilteredSummary.appointmentBookedRate, 0)}%`
                        }
                        statType={isAppointmentsLocked ? "icon" : ""}
                        statIconSizeClass="inline-icon__22"
                        statTypeClassString="type-small-headline type-semibold type-narrow-line-height"
                        bodyTypeSizeClass="type-normal-body"
                        statDescription="Booked Appointment Rate"
                        color={isAppointmentsLocked ? "gray" : "orange"}
                        width={null}
                        gutter={null}
                        toolTipTitle="Booked Appointment Rate"
                        toolTipBody={isAppointmentsLocked
                            ? AppointmentBookedRateLocked(leadFilteredSummary.billableLeadsCount, minConfidence)
                            : AppointmentBookedRateUnlocked}
                        toolTipPosition="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                </div>
                <div className="popup-stats__cell">
                    <LeadSummaryCell
                        statContent={formatNumber(leadFilteredSummary.likelyBookedAppointments, 0)}
                        statType=""
                        statIconSizeClass="inline-icon__22"
                        statTypeClassString="type-small-headline type-semibold type-narrow-line-height"
                        bodyTypeSizeClass="type-normal-body"
                        statDescription={<><b>Likely</b> Booked Appointments</>}
                        color={isAppointmentsLocked ? "gray" : "orange"}
                        width={null}
                        gutter={null}
                        toolTipTitle="Likely Booked Appointments"
                        toolTipBody={AppointmentBookedLikely}
                        toolTipPosition="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                </div>
            </div>

            <div className="type-normal-subhead type-heavy">
                <div className="spacing-5">Jobs Won</div>
                {' '}
                <div className="type-normal-body type-narrow-line-height">
                    {this.confidenceSpan(leadFilteredSummary.jobWonConfidence)}
                    <Tooltip
                        content={JobsWonConfidence(leadFilteredSummary.billableLeadsCount, minConfidence)}
                        position="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                    <br/>
                    Confidence is based on {leadFilteredSummary.jobManagedCount} Billable Leads with Jobs Won Managed
                    out of the{' '}
                    {leadFilteredSummary.billableLeadsCount} Billable Leads you are filtering by. Both the Jobs Won
                    Confidence
                    and Metrics below exclude Non-Billable Leads.{' '}
                </div>
            </div>
            <div
                className={'popup-stats__row ' + (isJobLocked ? 'performance-row-grey' : 'performance-row-orange')}>
                <div className="popup-stats__cell">
                    <div
                        className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {leadFilteredSummary.jobWonCount}
                    </div>
                    <div className="type-normal-body type-narrow-line-height">Jobs Won</div>
                </div>
                <div className="popup-stats__cell">
                    <div
                        className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {leadFilteredSummary.jobLostCount}
                    </div>
                    <div className="type-normal-body type-narrow-line-height">No Job</div>
                </div>
                <div className="popup-stats__cell">
                    <LeadSummaryCell
                        statContent={
                            isJobLocked
                                ? IconLockSvg
                                : `${formatNumber(leadFilteredSummary.jobWonRate, 0)}%`
                        }
                        statType={isJobLocked ? "icon" : ""}
                        statIconSizeClass="inline-icon__22"
                        statTypeClassString="type-small-headline type-semibold type-narrow-line-height"
                        bodyTypeSizeClass="type-normal-body"
                        statDescription="Jobs Won Rate"
                        color={isJobLocked ? "gray" : "orange"}
                        width={null}
                        gutter={null}
                        toolTipTitle="Jobs Won Rate"
                        toolTipBody={isJobLocked
                            ? JobsWonRateLocked(leadFilteredSummary.billableLeadsCount, minConfidence)
                            : JobsWonRateUnlocked}
                        toolTipPosition="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                </div>
                <div className="popup-stats__cell">
                    <LeadSummaryCell
                        statContent={formatNumber(leadFilteredSummary.likelyJobsWon, 0)}
                        statType=""
                        statIconSizeClass="inline-icon__22"
                        statTypeClassString="type-small-headline type-semibold type-narrow-line-height"
                        bodyTypeSizeClass="type-normal-body"
                        statDescription={<><b>Likely</b> Jobs Won</>}
                        color={isJobLocked ? "gray" : "orange"}
                        width={null}
                        gutter={null}
                        toolTipTitle="Likely Jobs Won"
                        toolTipBody={JobsWonLikely}
                        toolTipPosition="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                </div>
            </div>

            <div className="type-normal-subhead type-heavy">
                <div className="spacing-5">Revenue, Cost, Return On Investment</div>
                {' '}
                <div className="type-normal-body type-narrow-line-height">
                    {this.confidenceSpan(leadFilteredSummary.revenueConfidence)}
                    <Tooltip
                        content={RoiConfidence(leadFilteredSummary.billableLeadsCount, minConfidence)}
                        position="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                    <br/>
                    Confidence is based on {leadFilteredSummary.billableLeadsWithRevenueCount} Billable Leads with
                    Revenue Managed out of the
                    {' '}{leadFilteredSummary.billableLeadsCount} Billable Leads you are filtering by.{' '}
                </div>
            </div>
            <div
                className={'popup-stats__row ' + (isRevenueLocked ? 'performance-row-grey' : 'performance-row-orange')}>
                <div className="popup-stats__cell">
                    <div
                        className={"type-small-headline type-semibold type-narrow-line-height stats__value"}>
                        {formatCurrency(leadFilteredSummary.leadsWithRevenueRevenue, 0)}
                    </div>
                    <div className="type-normal-body type-narrow-line-height">
                        Revenue from {leadFilteredSummary.billableLeadsWithRevenueCount} Leads with known Revenue
                    </div>
                </div>
                <div className="popup-stats__cell">
                    <div
                        className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {formatCurrency(leadFilteredSummary.billableLeadsWithRevenueCost, 0)}
                    </div>
                    <div className="type-normal-body type-narrow-line-height">
                        Cost from {leadFilteredSummary.billableLeadsWithRevenueCount} Leads with known Revenue
                    </div>
                </div>
                <div className="popup-stats__cell">
                    <LeadSummaryCell
                        statContent={
                            isRevenueLocked
                                ? IconLockSvg
                                : (leadFilteredSummary.roi > 0 || AuthService.isAdmin
                                ? <>{formatNumber(leadFilteredSummary.roi, 0)}%</>
                                : 'N/A')}
                        statType={isRevenueLocked ? "icon" : ""}
                        statIconSizeClass="inline-icon__22"
                        statTypeClassString="type-small-headline type-semibold type-narrow-line-height"
                        bodyTypeSizeClass="type-normal-body"
                        statDescription={`Your ROI from ${leadFilteredSummary.billableLeadsWithRevenueCount} Leads with known Revenue`}
                        color={isRevenueLocked ? "gray" : "orange"}
                        width={null}
                        gutter={null}
                        toolTipTitle="Your ROI"
                        toolTipBody={isRevenueLocked
                            ? RoiLocked(leadFilteredSummary.billableLeadsCount, minConfidence)
                            : RoiUnlocked}
                        toolTipPosition="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                </div>
                <div className="popup-stats__cell">
                    <div
                        className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {leadFilteredSummary.roiAmount > 0 || AuthService.isAdmin
                            ? formatCurrency(leadFilteredSummary.roiAmount, 0)
                            : 'N/A'}
                    </div>
                    <div className="type-normal-body type-narrow-line-height">
                        Your Profit from {leadFilteredSummary.billableLeadsWithRevenueCount} Leads with known Revenue
                    </div>
                </div>
            </div>

            <div
                className={'popup-stats__row borderless ' + ((isRevenueLocked || true) ? 'performance-row-grey' : 'performance-row-orange')}>
                <div className="popup-stats__cell">
                    {/*<div className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {leadFilteredSummary.likelyRevenue > 0 || AuthService.isAdmin
                            ? formatCurrency(leadFilteredSummary.likelyRevenue, 0)
                            : 'N/A'}
                    </div>*/}
                    <span className="type-small-headline type-semibold type-narrow-line-height type-grey">
                            <span className="inline-icon inline-icon__20">{IconLockSvg}</span>
                        </span>
                    <div className="type-normal-body type-narrow-line-height">
                        <b>Likely</b> Revenue from all {leadFilteredSummary.billableLeadsCount} Filtered Leads
                        <Tooltip
                            content={
                                <span
                                    className="type-large-body type-force-newline type-black type-force-newline type-heavy type-narrow-line-height type-align-left type-notransform spacing-10-bottom">
                                    Metric Temporarily Locked
                                </span>
                            }
                            position="top"
                            modalContainerRef={this.props.modalContainerRef}
                        />
                    </div>
                </div>
                <div className="popup-stats__cell">
                    {/*<div className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {formatCurrency(leadFilteredSummary.billableLeadsCost, 0)}
                    </div>*/}
                    <span className="type-small-headline type-semibold type-narrow-line-height type-grey">
                        <span className="inline-icon inline-icon__20">{IconLockSvg}</span>
                    </span>
                    <div className="type-normal-body type-narrow-line-height">
                        Cost from all {leadFilteredSummary.billableLeadsCount} Filtered Leads
                        <Tooltip
                            content={
                                <span
                                    className="type-large-body type-force-newline type-black type-force-newline type-heavy type-narrow-line-height type-align-left type-notransform spacing-10-bottom">
                                    Metric Temporarily Locked
                                </span>
                            }
                            position="top"
                            modalContainerRef={this.props.modalContainerRef}
                        />
                    </div>
                </div>
                <div className="popup-stats__cell">
                    <LeadSummaryCell
                        // statContent={leadFilteredSummary.likelyRoi > 0 || AuthService.isAdmin
                        //     ? <>{formatNumber(leadFilteredSummary.likelyRoi, 0)}%</>
                        //     : 'N/A'}
                        statContent={IconLockSvg}
                        statType="icon"
                        statIconSizeClass="inline-icon__22"
                        statTypeClassString="type-small-headline type-semibold type-narrow-line-height"
                        bodyTypeSizeClass="type-normal-body"
                        statDescription={<>
                            <b>Likely</b> ROI from All {leadFilteredSummary.billableLeadsCount} Filtered Leads
                        </>}
                        color={(isRevenueLocked || true) ? "gray" : "orange"}
                        width={null}
                        gutter={null}
                        // toolTipTitle={`Likely ROI from All ${leadFilteredSummary.billableLeadsCount} Filtered Leads`}
                        // toolTipBody={RoiLikely}
                        toolTipTitle="Metric Temporarily Locked"
                        toolTipBody=""
                        toolTipPosition="top"
                        modalContainerRef={this.props.modalContainerRef}
                    />
                </div>
                <div className="popup-stats__cell">
                    {/*<div className="type-small-headline type-semibold type-narrow-line-height stats__value">
                        {leadFilteredSummary.likelyProfit > 0 || AuthService.isAdmin
                            ? formatCurrency(leadFilteredSummary.likelyProfit, 0)
                            : 'N/A'}
                    </div>*/}
                    <span className="type-small-headline type-semibold type-narrow-line-height type-grey">
                        <span className="inline-icon inline-icon__20">{IconLockSvg}</span>
                    </span>
                    <div className="type-normal-body type-narrow-line-height">
                        <b>Likely</b> Profit from All {leadFilteredSummary.billableLeadsCount} Filtered Leads
                        <Tooltip
                            content={
                                <span
                                    className="type-large-body type-force-newline type-black type-force-newline type-heavy type-narrow-line-height type-align-left type-notransform spacing-10-bottom">
                                    Metric Temporarily Locked
                                </span>
                            }
                            position="top"
                            modalContainerRef={this.props.modalContainerRef}
                        />
                    </div>
                </div>
            </div>
        </>;
    }
}

FilteredLeadsStatsModal.propTypes = {
    filterMessage: PropTypes.object,
    leadFilteredSummary: PropTypes.object,
    totalRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    modalContainerRef: PropTypes.object,
    apiQueryStr: PropTypes.string
};
