import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import queryString from "query-string";
import {AgGridReact} from "ag-grid-react";
import type {ColDef} from "ag-grid-community/dist/lib/entities/colDef";
import type {GridOptions, RowClassParams, RowClassRules} from "ag-grid-community";

import {isInteger, toCamelObject} from "../../Util";
import {errorHandler} from "../../Requests";
import ExternalBuyerService from "../../service/ExternalBuyerService";
import {
    arrayLengthComparator,
    caseInsensitiveComparator,
    cellDefRelatedAdNetwork,
    cellDefDate,
    cellDefEditExternalBuyerAnchor,
    cellDefIndustries,
    cellDefOnOff,
    cellDefStatus
} from "./ColumnDefinitions";
import Button from "../../common/components/form_elements/Button";
import EditExternalBuyerModal from "./EditExternalBuyerModal";
import {useModal} from "../../common/ModalContext";
import ConfigureIndustriesModal from "./ConfigureIndustriesModal";
import type {IIndustry} from "../../Interfaces/IIndustry";
import type {IExternalBuyer} from "../../Interfaces/IExternalBuyer";
import { TestPingPostModal } from './TestPingPostModal';

const ROW_HEIGHT = 55;
const HEADER_HEIGHT = 80;

/**
 * External lead buyers view
 */
export default function ExternalBuyersView(props) {
    const [externalBuyers, setExternalBuyers] = useState(null);

    const {current: updateModalContent} = useRef(useModal());
    const containerRef = useRef();
    const history = useHistory();
    const gridRef = useRef();
    // using useRef prevents the re-instantiation of the ExternalBuyerService.
    // Which, in turn, prevents a continues loop of re-fetching the external lead buyers data
    const {current: externalBuyerService} = useRef(new ExternalBuyerService());

    useEffect(() => {
        document.title = 'External Lead Buyers';
    }, []);

    /**
     * applies filters based on the URL
     */
    const filteredIds = useMemo(() => {
        let queryParams = queryString.parse(history.location.search, {arrayFormat: 'bracket'});
        queryParams = toCamelObject(queryParams);
        const filteredIds = [];

        // overload the case of a single id
        if (queryParams.externalBuyerId) {
            queryParams.externalBuyerIds = queryParams.externalBuyerId;
        }
        if (queryParams.externalBuyerIds) {
            queryParams.externalBuyerIds.split(',')
                .forEach(id => {
                    if (isInteger(id)) {
                        filteredIds.push(id);
                    }
                });
        }

        return filteredIds;
    }, [history.location.search]);

    /**
     * get the external lead buyers from the server based on the current filters
     */
    useEffect(() => {
        externalBuyerService.get(filteredIds)
            .then((response) =>
                setExternalBuyers(response.data.externalBuyers))
            .catch(errorHandler);

        return () => {
            externalBuyerService.getToken.cancel();
        }
    }, [filteredIds, externalBuyerService]);

    const updateExternalBuyer = useCallback((updatedExternalBuyer: IExternalBuyer, inUpdate: boolean) => {
        const nextExternalBuyers = [...externalBuyers];

        // when updating, update the external lead buyer in the view
        if (inUpdate) {
            const index = nextExternalBuyers
                .findIndex((externalBuyer: IExternalBuyer) =>
                    externalBuyer.externalBuyerId == updatedExternalBuyer.externalBuyerId);
            nextExternalBuyers[index] = updatedExternalBuyer;
            setExternalBuyers(nextExternalBuyers);
        }
        // when creating a new buyer, we refresh to clean any filters that might be hiding the buyer
        else {
            if (history.location.search) {
                history.push('/admin/external-buyers');
            }
            else {
                nextExternalBuyers.push(updatedExternalBuyer);
                setExternalBuyers(nextExternalBuyers);
            }
        }
    }, [externalBuyers, history]);

    const openEditExternalBuyerModal = useCallback((externalBuyer: IExternalBuyer = {}) => {
        updateModalContent({
            content: <EditExternalBuyerModal
                externalBuyer={externalBuyer}
                updateExternalBuyer={updateExternalBuyer}
                updateMessageBlocks={props.updateMessageBlocks}
            />,
            containerRef: containerRef,
            header: externalBuyer.externalBuyerId
                ? `Edit External Lead Buyer: ${externalBuyer?.name}`
                : 'Set Up New External Lead Buyer',
            flatBottom: true
        });
    }, [props.updateMessageBlocks, updateExternalBuyer, updateModalContent]);

    const updateIndustries = useCallback((industries: IIndustry[], externalBuyerId: number) => {
        const nextExternalBuyers: IExternalBuyer[] = [...externalBuyers];
        const index = nextExternalBuyers
            .findIndex((externalBuyer: IExternalBuyer) => externalBuyer.externalBuyerId == externalBuyerId);
        nextExternalBuyers[index].industries = industries;
        setExternalBuyers(nextExternalBuyers);
    }, [externalBuyers]);

    const openEditLeadSourcesModal = useCallback((externalBuyer: IExternalBuyer = {}) => {
        updateModalContent({
            content: <ConfigureIndustriesModal
                externalBuyer={externalBuyer}
                updateIndustries={updateIndustries}
                updateMessageBlocks={props.updateMessageBlocks}
            />,
            containerRef: containerRef,
            header: externalBuyer.industries.length > 0
                ? `Edit External Lead Buyer: ${externalBuyer?.name}`
                : `Set Up External Lead Buyer: ${externalBuyer?.name}`,
            flatBottom: true,
            width: 'wide'
        });
    }, [props.updateMessageBlocks, updateIndustries, updateModalContent]);
    
    const openTestPingPostModal = useCallback((externalBuyer: IExternalBuyer = {}) => {
        updateModalContent({
            content: <TestPingPostModal
                externalBuyer={externalBuyer}
                updateMessageBlocks={props.updateMessageBlocks}
            />,
            containerRef: containerRef,
            header: `Test Ping / Post for ${externalBuyer?.name}`,
            flatBottom: true,
            width: 'wide'
        });
    }, [props.updateMessageBlocks, updateModalContent]);

    const tableHeight = (externalBuyers?.length > 0 && window.innerHeight > 520)
        ? Math.min(externalBuyers?.length * ROW_HEIGHT + 100, window.innerHeight - 120)
        : 400;

    const rowClassRules: RowClassRules = {
        'external-buyer-deactivated-row': (params: RowClassParams) => params.data.status === 'deactivated'
    };
    const {current: gridOptions}: GridOptions = useRef({
        onFirstDataRendered: ({api}) => {
            const MIN_SCREEN_WIDTH = 1800;
            if (window.innerWidth > MIN_SCREEN_WIDTH) {
                api.sizeColumnsToFit();
            }
        },
        suppressRowClickSelection: true,
        overlayNoRowsTemplate: '<span>No Data</span>'
    });
    const {current: defaultColDef}: ColDef = useRef({
        resizable: true,
        wrapHeaderText: true,
        width: 100,
        sortable: true
    });
    const columnDefs: ColDef[] = useMemo(() => [
        {
            field: 'name',
            cellRenderer: cellDefEditExternalBuyerAnchor(openEditExternalBuyerModal, openEditLeadSourcesModal, openTestPingPostModal),
            headerName: 'Name',
            width: 200,
            pinned: 'left',
            sort: 'asc',
            comparator: caseInsensitiveComparator
        },
        {
            field: 'industries',
            headerName: 'Active Service Categories',
            cellRenderer: cellDefIndustries,
            // this valueFormatter is not needed, but AgGrid warns when the value of a key is complex (in this case an array)
            valueFormatter: (params) => params.data.industries,
            comparator: arrayLengthComparator,
            width: 500
        },
        {field: 'callAudioRecording', cellRenderer: cellDefOnOff, width: 110},
        {field: 'whisperFile', cellRenderer: cellDefOnOff},
        {field: 'status', cellRenderer: cellDefStatus, width: 145},
        {
            field: 'adNetworkName',
            cellRenderer: cellDefRelatedAdNetwork,
            headerName: 'Related Lead Source',
            width: 275,
            comparator: caseInsensitiveComparator
        },
        {field: 'creationTimestamp', cellRenderer: cellDefDate, headerName: 'Created'},
        {field: 'lastUpdatedTimestamp', cellRenderer: cellDefDate, headerName: 'Last Updated'}
    ], [openEditExternalBuyerModal, openEditLeadSourcesModal, openTestPingPostModal]);

    return <div className="page-width-wide external-buyers-view">
        <div className="row padding-50-top padding-40-bottom">
            <div className="simpleflex__row wide-format-col header__main">
                <h2 className="simpleflex__cell type-normal-headline type-heavy">
                    External Lead Buyers
                </h2>
                <div className="simpleflex__cell">
                    <div className="new-elb-button-wrap">
                        <Button onClick={() => openEditExternalBuyerModal()}>
                            Set Up New External Lead Buyer
                        </Button>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="wide-format-col page__contentbox">
                <div
                    className="ag-theme-alpine"
                    style={{width: '100%', height: `${tableHeight}px`}}
                >
                    <AgGridReact
                        rowData={externalBuyers}
                        ref={gridRef}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        gridOptions={gridOptions}
                        rowHeight={ROW_HEIGHT}
                        rowClassRules={rowClassRules}
                        headerHeight={HEADER_HEIGHT}
                    />
                </div>
            </div>
        </div>
    </div>;
}
