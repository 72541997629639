import { isInteger, isNumber, isValidBidMultiplier } from '../../../../../Util'

export function rulesAreValid(rules) {
    /**
     * { newRules: [], deletedRules: [], edits: [] } }
     */
    const { newRules = [], deletedRules = [], edits = [] } = rules
    // if there are no new rules or deleted rules, the rules are not valid
    if (newRules?.length === 0 && deletedRules?.length === 0 && edits?.length === 0) {
        return false
    }
    let result = true

    // validate new rules
    if (newRules?.length > 0) {
        result = newRules.every((rule) => {
            let _r = true
            // new rules must have a service category and rule type
            if (rule?.industry_id == null || rule?.rule_type_id == null) {
                _r = false
            }
            // if the new rule is a bid multiplier type
            if (rule?.rule_name?.includes('multiplier')) {
                if (rule?.rule_value == null || !isValidBidMultiplier(rule?.rule_value)) {
                    _r = false
                }
            }
            // if the new rule is a flat bid, verify its a number
            if (rule?.rule_name?.includes('flat')) {
                if (rule?.rule_value == null || !isNumber(rule?.rule_value)) {
                    _r = false
                }
            }
            // if the new rule is a budget or duration, verify its an integer
            if (rule?.rule_name?.includes('budget') || rule?.rule_name?.includes('duration')) {
                if (rule?.rule_value == null || !isInteger(rule?.rule_value)) {
                    _r = false
                }
            }
            return _r
        })
    }

    // edits must have valid inputs
    if (edits?.length > 0) {
        result = edits.every((rule) => {
            // bid multiplier rules
            let _r = true
            if (rule?.rule_name?.includes('multiplier')) {
                if (!isValidBidMultiplier(rule?.rule_value)) {
                    _r = false
                }
            }
            return _r
        })
    }

    return result
}
