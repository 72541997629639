import React, {Component} from "react";
import PropTypes from "prop-types";

import CommonQuestions from './common-questions';
import NavigationFooter from './navigation-footer';
import SignupMarketplaceService from '../service/SignupMarketplaceService';
import {errorHandler} from "../Requests";
import Input from "../common/components/form_elements/Input";
import Checkbox from "../common/components/form_elements/Checkbox";
import Validator from "../lib/Validator";
import TermsAndConditions from "../client/TermsAndConditions";
import {maskExpDate, toCamel} from "../Util";
import {MockButton} from "../common/MockButton";
import AuthService from "../service/AuthService";
import GoogleTagManagerService from "../service/GoogleTagManagerService";

export default class StepFour extends Component {
    constructor(props) {
        super(props);

        // errorMessage having value means there's an error
        this.state = {
            data: {
                budgetAlert: {
                    value: '',
                    errorMessage: ''
                },
                ccName: {
                    value: '',
                    errorMessage: ''
                },
                ccNumber: {
                    value: '',
                    errorMessage: ''
                },
                ccExpiration: {
                    value: '',
                    errorMessage: ''
                },
                ccCvv: {
                    value: '',
                    errorMessage: ''
                },
                ccZipCode: {
                    value: '',
                    errorMessage: ''
                },
                promoCode: {
                    value: '',
                    errorMessage: ''
                },
            },
			industryIds: [],
            serviceCategoryInformation: {},
            processing: false,
            termsAndConditions: false,
            termsAndConditionsErrorMessage: ''
        };

        this.signupMarketplaceService = new SignupMarketplaceService();
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const savedPromoCode = localStorage.getItem('mpSignupPromoCode');
        if (savedPromoCode) {
            let _clonedState = Object.assign({}, this.state);
            _clonedState.data.promoCode.value = savedPromoCode;
            
            this.setState(_clonedState);
        }
		
        this.signupMarketplaceService.getStepFour()
            .then(response => {
				const {data} = this.state;
				
                this.setState({serviceCategoryInformation: response.serviceCategoryInformation});
				
				const industryIds = response.campaigns.map((campaign: Object) => campaign.industryId);
				this.setState({industryIds: industryIds});
				
				if (this.promoCodeAvailable() && !savedPromoCode) {
					let seededPromoCode;
					
					industryIds.forEach(industryId => {
						if (!seededPromoCode) {
							const codeMatches = this.props.promoCodes.filter(promoCode => promoCode.industry === industryId);
							
							if (codeMatches.length > 0) {
								seededPromoCode = codeMatches[0].code;
							}
						}
					});
					
					data.promoCode = {
						value: seededPromoCode,
						message: '',
					};
					this.setState({data});
				}
            }).catch((error) => {
				console.log(error);
                if (errorHandler(error)) {
                    window.location = error.response.data.location;
                }
            });
    }

    componentWillUnmount() {
        this.signupMarketplaceService.cancelSignal.cancel();
    }

    mockData = (event) => {
        event.preventDefault();

        this.setState({
            data: {
                budgetAlert: {
                    value: '200',
                    errorMessage: ''
                },
                ccName: {
                    value: 'Nim Levy',
                    errorMessage: ''
                },
                ccNumber: {
                    value: '4111111111111111',
                    errorMessage: ''
                },
                ccExpiration: {
                    value: '10/27',
                    errorMessage: ''
                },
                ccCvv: {
                    value: '187',
                    errorMessage: ''
                },
                ccZipCode: {
                    value: '22222',
                    errorMessage: ''
                },
                promoCode: {
                    value: '',
                    errorMessage: ''
                }
            },
            termsAndConditions: true
        });
    }

    /**
     * returns an error message for the specific set of field name and its value; empty string when there's no error
     * @param {string} name - the fields name
     * @param {string} value - the fields value
     * @return {string}
     */
    getErrorMessage(name: string, value: string) {
        value = value.trim();

        if (name === 'budgetAlert') {
            return isNaN(Number(value)) || value < 0
                ? 'Invalid value; must be a positive number'
                : '';
        }

        if (!value && (name !== 'promoCode')) {
            return 'This field is required';
        }

        switch (name) {
            case 'ccName':
                return /\w+ \w+/.test(value)
                    ? ''
                    : 'Invalid value, must be First and Last name';

            case 'ccNumber':
                return Validator.validateCreditCard(value)
                    ? ''
                    : 'Invalid card number';

            case 'ccExpiration':
                return Validator.validateCardExpiration(value)
                    ? ''
                    : 'Invalid expiration date';

            case 'ccCvv':
                return value.length == 3
                    ? ''
                    : 'Invalid CVV';

            case 'ccZipCode':
                return (value.length != 6 && value.length != 3) // Canadian
                && (isNaN(Number(value)) || value.length != 5) // US
                    ? 'Invalid Zip / Postal Code'
                    : '';
            case 'promoCode':
                return !this.promoCodeCheck(value)
                    ? 'Invalid Promo Code'
                    : '';
            // no default
        }
        
        return '';
    }

    /**
     * updates regular input (in this case the zip code)
     * TODO: this functionality can be shared between the different steps
     * @param {FocusEvent} event
     */
    handleChange = (event: FocusEvent) => {
        let data = {...this.state.data};
        data[event.target.name].value = event.target.value;

        this.setState({data});
    };

    /**
     * handles validation of the field
     * @param {FocusEvent} event
     */
    handleBlurInput = (event: FocusEvent) => {
        let {data} = this.state;
        data[event.target.name].errorMessage = this.getErrorMessage(event.target.name, event.target.value);

        this.setState({data});
    };

    /**
     * builds the data that will be sent to the server
     * @return {object|boolean} the data object on success, or false on error
     */
    buildPostData = () => {
        // let {data}: Object = this.state,
		let {data} = this.state,
            result = {},
            keys = Object.keys(data),
            index,
            item,
            hasErrors = false,
            assistingAdminUserId = AuthService.assistingAdminUserId;

        for (index = 0; index < keys.length; index++) {
            item = data[keys[index]];
            if (item.errorMessage) {
                hasErrors = true;
                continue;
            }

            item.errorMessage = this.getErrorMessage(keys[index], item.value);
            if (item.errorMessage) {
                hasErrors = true;
                continue;
            }

            result[keys[index]] = item.value;
        }

        if (hasErrors) {
            this.setState({data});
            return false;
        }

        // Add assistingUserId if present
        if (assistingAdminUserId) {
            result.assistingAdminUserId = assistingAdminUserId;
        }

        return result;
    };

    /**
     * submits the form to the API
     * @param {Event} event
     */
    submit = (event) => {
        event.preventDefault();

        if (!this.state.termsAndConditions) {
            this.setState({termsAndConditionsErrorMessage: 'Please accept the terms and conditions to continue'});
        }

        const data = this.buildPostData();
        if (!data) {
            return;
        }

        if (!AuthService.assistingAdminUserId && !this.state.termsAndConditions) {
            return;
        }

        this.setState({processing: true});
        this.signupMarketplaceService.updateStepFour(data)
            .then((response) => {
                const masterServiceCategory = this.state.serviceCategoryInformation.masterServiceCategory;
                const serviceCategories = this.state.serviceCategoryInformation.serviceCategories.join(',');

                // Assisted Flow: signup isn't done yet, user needs to click email link
                if (!response.data.signup_complete) {
                    // send step4Complete parameter to Data Layer
                    GoogleTagManagerService.updateDataLayer({
                        event: "step4Complete",
                        assistingAdminUserId: AuthService.assistingAdminUserId,
                        masterServiceCategory,
                        serviceCategories
                    })

                    this.props.updateModalContent(<>
                            <p className="type-normal-body spacing-10-bottom">
                                The Client will receive an email with this subject:<br/>
                                "Complete Your Final Step to start getting Leads with Service Direct".
                            </p>
                            <p className="type-normal-body spacing-10-bottom">
                                Remember, the Client must agree to the TOS before their campaigns will be created and
                                their account goes live.
                            </p>
                            <p className="type-normal-body">
                                After clicking OK, you will be logged out of this user's Sign Up.
                            </p>

                            <div className="adding-24 spacing-20-top">
                                <div className="popup__form__row popup__form__row__slam-right">
                                    <div className="popup__form__cell">
                                        <button
                                            className="button ui-normal-button type-small-body type-heavy type-blue ui-full"
                                            onClick={() => {
                                                this.props.updateModalContent();
                                                // log user out so they land on Login page
                                                AuthService.clear();
                                                window.location = '/';
                                            }}
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>,
                        'Success! Sent email to Client to finish Sign Up.',
                        true
                    );
                }
                // non-Assisted Flow: user is now done and proceeds to Tour and mySD
                else {
                    // send step4Complete and signUpComplete parameters to Data Layer
                    GoogleTagManagerService.updateDataLayer({
                        event: "step4Complete",
                        masterServiceCategory,
                        serviceCategories
                    })
                    GoogleTagManagerService.updateDataLayer({
                        event: "signUpComplete",
                        masterServiceCategory,
                        serviceCategories
                    })
                    // remove the signup items so that we can log into the real system
                    AuthService.isSignup = false;
                    AuthService.setItem('signupMarketplaceId', 0);
                    AuthService.isAwaitingOnboarding = true;
                    window.location = '/onboarding/schedule-onboarding';
                }
            })
            .catch((error) => {
                this.setState({processing: false});
                if (errorHandler(error)) {
                    let {data} = this.state;
                    if (error?.response?.data?.parameter) {
                        data[toCamel(error.response.data.parameter)].errorMessage = error.response.data.message;
                        this.setState({data});
                    }
                    else {
                        console.log("Warning: no parameter found to attach errorMessage to");
                        if ('message' in error.response.data) {
                            this.props.updateMessageBlocks([error.response.data.message], 'error');
                        }
                    }
                }
            });
    };
    
    promoCodeCheck = (val) => {
        if (val.length > 0) {
            const matches = this.props.promoCodes.filter(promoCode => promoCode.code === val.toUpperCase());
            
            return matches.length > 0;
        }
        
        return true;
    };
    
    promoCodeStatusMessage = (val) => {
        const matches = this.props.promoCodes.filter(promoCode => promoCode.code === val.toUpperCase());
        
        if (matches.length > 0) {
			return <span style={{textTransform: 'uppercase', fontWeight: 'bold', color: '#59BC25'}}>Great news! You have ${matches[0].credit} in Free Leads!</span>;
        }
        
		return null;
    }
	
	promoCodeAvailable = () => {
		return this.state.industryIds.reduce((acc, val) => {
			if (this.props.promoCodes.map(promo => promo.industry).includes(val)) {
				acc = true;
			}
			
			return acc;
		}, false);
	}

    render() {
        return <form onSubmit={this.submit}>
            <MockButton onClick={this.mockData}/>
            <h1 className="module__twothirdswidth type-large-subhead type-single-line module-header">
                Your Account
            </h1>
            <div className="module__contentbox module__twothirdswidth">
                <h2 className="type-normal-subhead type-heavy spacing-10-bottom type-centered">
                    Billing Credit Card to Verify Your Account
                </h2>
                <p className="type-normal-body type-centered spacing-50-bottom">
                    We need to verify that you’re a real business before we can schedule your Onboarding Call.
                </p>

                <div className="simpleflex__row simpleflex__row__wrap__mobile">
                    <div
                        className="simpleflex__cell simpleflex__cell__one-half simpleflex__cell__full-width__mobile simpleflex__cell__right-padded__full simpleflex__cell__right-padded__tablet">
                        <CommonQuestions/>
                        <div className="content-box content-box__orange spacing-20-top ui-hide-mobile">
                            <p className="type-normal-body type-heavy no-margin">
                                Need Help? Call us at (512) 233-6446
                            </p>
                        </div>
                    </div>
                    <div className="simpleflex__cell simpleflex__cell__one-half simpleflex__cell__full-width__mobile">
                        <div className="form__row spacing-10-bottom">
                            <div className="form__cell form__cell__100">
                                <Input
                                    name="promoCode"
                                    type="text"
                                    label="Promo Code"
                                    value={this.state.data.promoCode.value}
                                    errorMessage={this.state.data.promoCode.errorMessage}
                                    statusMessage={this.promoCodeStatusMessage(this.state.data.promoCode.value)}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlurInput}
                                    required={false}
                                    highlighted={(this.state.data.promoCode.value.length > 0) && this.promoCodeCheck(this.state.data.promoCode.value)}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__cell form__cell__100">
                                <Input
                                    name="ccName"
                                    type="text"
                                    label="Name on Card"
                                    value={this.state.data.ccName.value}
                                    errorMessage={this.state.data.ccName.errorMessage}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlurInput}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__cell form__cell__100">
                                <Input
                                    name="ccNumber"
                                    type="number"
                                    label="Credit Card Number"
                                    value={this.state.data.ccNumber.value}
                                    errorMessage={this.state.data.ccNumber.errorMessage}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlurInput}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__cell form__cell__50 form__cell__100__mobile">
                                <Input
                                    name="ccExpiration"
                                    type="text"
                                    label="Expiration"
                                    placeholder="MM / YY"
                                    value={this.state.data.ccExpiration.value}
                                    errorMessage={this.state.data.ccExpiration.errorMessage}
                                    onChange={this.handleChange}
                                    onKeyUp={(event) => {
                                        event.target.value = maskExpDate(event);
                                        this.handleChange(event);
                                    }}
                                    onBlur={this.handleBlurInput}
                                    required={true}
                                />
                            </div>
                            <div className="form__cell form__cell__50 form__cell__100__mobile">
                                <Input
                                    name="ccCvv"
                                    type="text"
                                    label="CVV"
                                    value={this.state.data.ccCvv.value}
                                    errorMessage={this.state.data.ccCvv.errorMessage}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlurInput}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__cell form__cell__50 form__cell__100__mobile">
                                <Input
                                    name="ccZipCode"
                                    type="text"
                                    label="Zip Code"
                                    value={this.state.data.ccZipCode.value}
                                    errorMessage={this.state.data.ccZipCode.errorMessage}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlurInput}
                                    required={true}
                                />
                            </div>
                        </div>
                        {AuthService.assistingAdminUserId
                            ? ''
                            : <>
                                <div className="spacing-20-top spacing-30-bottom">
                                    Please read the{' '}
                                    <button className="button-clean type-blue type-normal-body" type="button"
                                            onClick={() => this.props.updateModalContent(
                                                <TermsAndConditions
                                                    whiteLabelPartnership={this.props.whiteLabelPartnership}
                                                    inAccountActivation={false}
                                                />,
                                                this.props.whiteLabelPartnership.white_label_name + " Terms and Conditions"
                                            )}>
                                        Service Direct Terms and Conditions
                                    </button>
                                    {' '}
                                    and check the box below to agree.
                                </div>
                                <div className="spacing-40-bottom">
                                    <Checkbox
                                        name="termsAndConditions"
                                        label="I've read and accept the Terms and Conditions"
                                        onChange={() => this.setState({
                                            termsAndConditions: !this.state.termsAndConditions,
                                            termsAndConditionsErrorMessage: '',
                                        })}
                                        checked={this.state.termsAndConditions}
                                        errorMessage={this.state.termsAndConditionsErrorMessage}
                                    />
                                </div>
                            </>}
                    </div>
                </div>
                <div className="ui-hide-full ui-hide-tablet">
                    <p className="type-normal-body no-margin">
                        <b>Need Help?</b> Call us at (512) 233-6446
                    </p>
                </div>

            </div>
            <NavigationFooter braintree={true} nextStep={this.submit} processing={this.state.processing}
                              buttonLabel="Schedule onboarding"
                              history={this.props.history} previousStep="/signup-marketplace/step-three"/>
        </form>;
    }
}

StepFour.propTypes = {
    updateModalContent: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    whiteLabelPartnership: PropTypes.object.isRequired,
};
