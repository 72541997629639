import React from 'react';

/**
 * Single source of truth for static values shared among the views
 * Any DB static options should be placed here and exported until we implement an API route to get them
 */
export const billableOptions = [
    {"value": "all", "text": "All"},
    {"value": "billable", "text": "Billable"},
    {"value": "non-billable", "text": "Non-Billable"},
];

export const adminBillableOptions = [
    {"value": "all", "text": "All"},
    {"value": "billable", "text": "Billable"},
    {"value": "non-billable", "text": "Non-Billable"},
];

export const campaignStatusOptions = [
    {"value": "enabled", "text": "Enabled"},
    {"value": "paused", "text": "Paused"},
    {"value": "canceled", "text": "Canceled"},
];

export const campaignTypeOptions = [
    {"value": "all", "text": "All Campaign Types"},
    {"value": "website with tracking", "text": "Custom Search (Select)"},
    {"value": "marketplace", "text": "Marketplace"},
    {"value": "tracking only", "text": "Tracking Only"},
    {"value": "website only", "text": "Website Only"},
    {"value": "external buyer", "text": "External Lead Buyer"},
];

export const leadReviewInitiatedOptions = [
    { "value": "wrong number", "text": "Lead Classification - Wrong Number" },
    { "value": "spam", "text": "Lead Classification - Spam" },
    { "value": "admin", "text": "Admin User" },
    { "value": "client", "text": "Client User" },
];

export const leadReviewOptions = [
    {"value": "all", "text": "All"},
    {"value": "not in review", "text": "Not in Review"},
    {"value": "in review", "text": "In Review"},
    {"value": "completed", "text": "Completed"},
];

export const clientLeadReviewOptions = [
    {"value": "all", "text": "All"},
    {"value": "1", "text": "Not Reviewed"},
    {"value": "2", "text": "In Review"},
    {"value": "3", "text": "Submitted for Review"},
    {"value": "4", "text": "Review Completed"},
];

export const editTypeOptions = [
    {value: "", text: "Please Select"},
    {value: "caller_id_option", text: "Caller ID"},
    {value: "call_recording", text: "Call Recording & Lead Review"},
    {value: "cost_per_lead", text: "Cost Per Lead (CPL)"},
    {value: "form_lead_delivery", text: "Form Lead Delivery"},
    {value: "phone_lead_delivery", text: "Phone Lead Delivery"},
    {value: "status", text: "Status"}
];

export const dateRangeOptions = [
    {"value": "1", "text": "Today"},
    {"value": "2", "text": "Yesterday"},
    {"value": "3", "text": "Last 7 Days"},
    {"value": "4", "text": "This Month"},
    {"value": "8", "text": "Last 30 Days"},
    {"value": "9", "text": "Last 30 + Today"},
    {"value": "5", "text": "Last Month"},
    {"value": "10", "text": "Last 90 Days"},
    {"value": "11", "text": "Last 90 Days + Today"},
    {"value": "6", "text": "This Year"},
    {"value": "12", "text": "Last Year"},
    {"value": "7", "text": "All Time"},
    {"value": "custom", "text": "Custom"}
];

// export const dateRangeOptions2 = [...dateRangeOptions]
// dateRangeOptions2.splice(3, 0, { value: '13', text: 'Last 7 Days + Today' })

export const dateRangeOptionsWithoutToday = [
    {"value": "2", "text": "Yesterday"},
    {"value": "3", "text": "Last 7 Days"},
    {"value": "4", "text": "This Month"},
    {"value": "8", "text": "Last 30 Days"},
    {"value": "5", "text": "Last Month"},
    {"value": "10", "text": "Last 90 Days"},
    {"value": "6", "text": "This Year"},
    {"value": "12", "text": "Last Year"},
    {"value": "7", "text": "All Time"},
    {"value": "custom", "text": "Custom"}
];

export const leadTypeOptions = [
    {"value": "", "text": "All"},
    {"value": "phone", "text": "Phone Calls"},
    {"value": "form", "text": "Forms"}
];

export const flaggedOptions = [
    {"value": "0", "text": "All"},
    {"value": "1", "text": "Flagged"},
];

export const adminStarredOptions = [
    {"value": "", "text": "All"},
    {"value": "1", "text": "Starred"},
    {"value": "2", "text": "Not Starred"},
];

export const adminFollowUpOptions = [
    {"value": "0", "text": "All"},
    {"value": "1", "text": "Follow Up Needed"},
    {"value": "2", "text": "Not Follow Up Needed"},
];

export const adminManagedOptions = [
    {"value": "all", "text": "All"},
    {"value": "0", "text": "Unmanaged"},
    {"value": "1", "text": "Partially Managed"},
    {"value": "2", "text": "Fully Managed"},
];

export const adminCallAnsweredOptions = [
    {"value": "0", "text": "All"},
    {"value": "1", "text": "Call Answered"},
    {"value": "2", "text": "Call Not Answered"},
];

export const adminBookedAppointmentOptions = [
    {"value": "0", "text": "All"},
    {"value": "1", "text": "Appointment Booked"},
    {"value": "2", "text": "Appointment Not Booked"},
];

export const adminJobWonOptions = [
    {"value": "0", "text": "All"},
    {"value": "1", "text": "Job Won"},
    {"value": "2", "text": "Job Not Won"},
];

export const adminRevenueOptions = [
    {"value": "0", "text": "All"},
    {"value": "1", "text": "Revenue Entered"},
    {"value": "2", "text": "Revenue Not Entered"},
];

export const callPlayedOptions = [
    {"value": "0", "text": "All"},
    {"value": "1", "text": "Call Played / Form Viewed by Client"},
    {"value": "2", "text": "Call Played / Form Viewed by Admin"},
    {"value": "3", "text": "Call Not Played / Form Not Viewed"},
];

export const starredOptions = [
    {"value": "0", "text": "Not Starred"},
    {"value": "1", "text": "Starred"},
];

export const needsFollowUpOptions = [
    {"value": "0", "text": "No Follow-Up Needed"},
    {"value": "1", "text": "Needs Follow-Up"},
];

export const jobWonOptions = [
    {"value": "0", "text": "No Job"},
    {"value": "1", "text": "Job Won"},
];

export const bookedAppointmentOptions = [
    {"value": "0", "text": "Did Not Book The Appointment"},
    {"value": "1", "text": "Booked Appointment"},
];

export const callAnsweredOptions = [
    {"value": "0", "text": "Call Missed (or did not respond to form)"},
    {"value": "1", "text": "Call Answered (or responded to form)"},
];

export const callAnsweredExplicitOptions = [
    {"value": "0", "text": "Call Missed"},
    {"value": "1", "text": "Call Answered"},
];

export const formRespondedExplicitOptions = [
    {"value": "0", "text": "Did not respond to form"},
    {"value": "1", "text": "Responded to form"},
];

export const managedOptions = [
    {"value": "0", "text": "Unmanaged"},
    {"value": "1", "text": "Partially Managed"},
    {"value": "2", "text": "Fully Managed"},
];

export const ciCallAnsweredOptions = [
    {"value": "", "text": ""},
    {"value": "1", "text": "Yes"},
    {"value": "0", "text": "No"},
];

export const ciCallerIntentOptions = [
    {"value": "", "text": ""},
    {"value": "Called Self", "text": "Called Self"},
    {"value": "Caller Hangup on Live Answer", "text": "Caller Hangup on Live Answer"},
    {"value": "DIY/Question/Info Only", "text": "DIY/Question/Info Only"},
    {"value": "EC - Cancel Appt.", "text": "EC - Cancel Appt."},
    {"value": "EC - Not Service Related", "text": "EC - Not Service Related"},
    {"value": "EC - Technician ETA", "text": "EC - Technician ETA"},
    {"value": "Fax Machine", "text": "Fax Machine"},
    {"value": "Job Seeker", "text": "Job Seeker"},
    {"value": "Language Barrier", "text": "Language Barrier"},
    {"value": "No Commercial/No Residential", "text": "No Commercial/No Residential"},
    {"value": "Out of Service Area", "text": "Out of Service Area"},
    {"value": "Parts Related", "text": "Parts Related"},
    {"value": "Personal Call", "text": "Personal Call"},
    {"value": "Service Need", "text": "Service Need"},
    {"value": "Service Not Offered", "text": "Service Not Offered"},
    {"value": "Solicitor", "text": "Solicitor"},
    {"value": "Spam Call", "text": "Spam Call"},
    {"value": "Test Lead", "text": "Test Lead"},
    {"value": "Wrong Number", "text": "Wrong Number"},
];

export const ciAbilityToBookOptions = [
    {"value": "", "text": ""},
    {"value": "1", "text": "Yes"},
    {"value": "0", "text": "No"},
];

export const ciCollectedCustomerInfoOptions = [
    {"value": "", "text": ""},
    {"value": "1", "text": "Yes"},
    {"value": "0", "text": "No"},
];

// export const ciAppointmentBookedOptions = [
//     {"value": "", "text": ""},
//     {"value": "Yes", "text": "Yes"},
//     {"value": "No - Connection Lost", "text": "No - Connection Lost"},
//     {"value": "No - Consult Significant Other", "text": "No - Consult Significant Other"},
//     {"value": "No - Lack of CSR Knowledge", "text": "No - Lack of CSR Knowledge"},
//     {"value": "No - Not Decision-Maker", "text": "No - Not Decision-Maker"},
//     {"value": "No - Other", "text": "No - Other"},
//     {"value": "No - Price", "text": "No - Price"},
//     {"value": "No - Scheduling Issue", "text": "No - Scheduling Issue"},
//     {"value": "No - Service Not Offered", "text": "No - Service Not Offered"},
//     {"value": "No - Will Call Back", "text": "No - Will Call Back"},
//     {"value": "No - Out of Service Area", "text": "No - Out of Service Area"},
// ];

export const ciAppointmentLostReasons = [
    {"value": "", "text": "Please Select"},
    {"value": "Connection Lost"},
    {"value": "Consult Significant Other"},
    {"value": "Lack of CSR Knowledge", "text": "CSR Failed to Convert"},
    {"value": "No Ability to Book"},
    {"value": "Out of Service Area"},
    {"value": "Price"},
    {"value": "Scheduling Issue"},
    {"value": "Service Not Offered"},
    {"value": "Wanted Someone Local"},
    {"value": "Will Call Back"},
    {"value": "Other"},
];

export const ciIvrStepsOptions = [
    {"value": "", "text": ""},
    {"value": "None", "text": "None"},
    {"value": "1", "text": "1"},
    {"value": "2", "text": "2"},
    {"value": "3", "text": "3"},
    {"value": "4", "text": "4"},
    {"value": "5+", "text": "5+"},
];

export const ciGreetingOptions = [
    {"value": "", "text": ""},
    {"value": "Unprofessional", "text": "Unprofessional"},
    {"value": "Professional", "text": "Professional"},
    {"value": "Courteous", "text": "Courteous"},
];

export const ciAnsweredByOptions = [
    {"value": "", "text": ""},
    {"value": "Call Center", "text": "Call Center"},
    {"value": "In-House", "text": "In House"},
];

export const ciCallerSexOptions = [
    {"value": "", "text": ""},
    {"value": "Female", "text": "Female"},
    {"value": "Male", "text": "Male"},
];

export const ciCallerLanguageOptions = [
    {"value": "", "text": ""},
    {"value": "English", "text": "English"},
    {"value": "Not English", "text": "Non-English"},
];

export const ciPlacedOnHoldOptions = [
    {"value": "", "text": ""},
    {"value": "1", "text": "Yes"},
    {"value": "0", "text": "No"},
];

export const ciPriceQuotedOptions = [
    {"value": "", "text": ""},
    {"value": "1", "text": "Yes"},
    {"value": "0", "text": "No"},
];

export const ciServiceFeeOptions = [
    {"value": "", "text": ""},
    {"value": "Not Selected - Empty - Default", "text": "Not Selected - Empty - Default"},
    {"value": "No Service Fee", "text": "No Service Fee"},
    {"value": "< $50", "text": "Under $50"},
    {"value": "$51 - $75", "text": "$51-75"},
    {"value": "$76 - $100", "text": "$76-100"},
    {"value": "> $100", "text": "Over $100"},
];

// this hash table is also available in the admin/edit_profile.php file
export const marketplaceIndustryScreenshots = {
    base: 'https://images.servicedirect.com/images/shared/marketplace/',
    2: 'AirConditioning-v1.jpg',
    9: 'Electrician-v1.jpg',
    12: 'Flooring-v1.jpg',
    13: 'Handyman-v1.jpg',
    14: 'Heating-v1.jpg',
    18: 'MoldRemoval-v1.jpg',
    42: 'PestControl-v1.jpg',
    20: 'Plumbing-v1.jpg',
    194: 'RodentRemoval-v1.jpg',
    27: 'WaterDamageRestoration-v1.jpg',
    159: 'WildlifeRemoval-v1.jpg',
    120: 'Locksmith-v1.jpg',
    19: 'Painting-v1.jpg',
    25: 'Siding-v1.jpg',
    39: 'KitchenRemodeling-v1.jpg',
    106: 'GarageDoors-v1.jpg',
    55: 'BathroomRemodeling-v1.jpg',
    96: 'Gutters-v1.jpg',
    36: 'ApplianceRepair-v1.jpg',
    177: 'Lawyer-AccidentAttorney.jpg',
    37: 'Lawyer-Attorney.jpg',
    163: 'Lawyer-Bankruptcy.jpg',
    216: 'Lawyer-Collections.jpg',
    217: 'Lawyer-Criminal.jpg',
    182: 'Lawyer-DUI.jpg',
    175: 'Lawyer-Divorce.jpg',
    187: 'Lawyer-EminentDomain.jpg',
    218: 'Lawyer-Employment.jpg',
    233: 'Lawyer-Family.jpg',
    219: 'Lawyer-Foreclosure.jpg',
    220: 'Lawyer-HerniaMesh.jpg',
    221: 'Lawyer-Immigration.jpg',
    222: 'Lawyer-MedicalMalpractice.jpg',
    223: 'Lawyer-Mesothelioma.jpg',
    109: 'Lawyer-PersonalInjury.jpg',
    224: 'Lawyer-ProductLiability.jpg',
    181: 'Lawyer-PublicAdjuster.jpg',
    234: 'Lawyer-RealEstate.jpg',
    225: 'Lawyer-Roundup.jpg',
    226: 'Lawyer-SexualHarrasment.jpg',
    227: 'Lawyer-SocialSecurityDisability.jpg',
    228: 'Lawyer-TalcumPowder.jpg',
    229: 'Lawyer-Traffic.jpg',
    50: 'Lawyer.jpg',
    230: 'Lawyer-WillsEstate-v1.jpg',
    231: 'Lawyer-WorkerCompensation.jpg',
    232: 'Lawyer-WrongfulDeath-v1.jpg',
    6: 'AccountantCPA-v1.jpg',
    195: 'AutoBodyShop-v1.jpg',
    196: 'AutoGlassRepair-v1.jpg',
    242: 'AutoInsurance-v1.jpg',
    26: 'BasementWaterproofing-v1.jpg',
    235: 'Chiropractor-v1.jpg',
    247: 'ComputerRepair-v1.jpg',
    248: 'CopierRepair-v1.jpg',
    236: 'Dermatologist-v1.jpg',
    136: 'DoorInstallation-v1.jpg',
    237: 'DrugAndAlcoholAddictionTreatment-v1.jpg',
    249: 'FlightBooking-v1.jpg',
    250: 'Florist-v1.jpg', /** also found an id: 56, but has no suggested_tender **/
    243: 'HealthInsurance-v1.jpg',
    244: 'HomeownersInsurance-v1.jpg',
    251: 'Hotels-v1.jpg',
    252: 'InternetAndCable-v1.jpg',
    245: 'LifeInsurance-v1.jpg',
    238: 'Optometrist-v1.jpg',
    239: 'PhysicalTherapist-v1.jpg',
    240: 'Podiatrist-v1.jpg',
    198: 'TowingService-v1.jpg',
    241: 'Veterinarian-v1.jpg',
    246: 'PetInsurance-v1.jpg',
    197: 'AutoRepairServices-v1.jpg',
    16:  'Landscaping-v1.jpg',
    102: 'Dentist-v1.jpg',
    generic: 'Generic-v1.jpg'
};

// Service Direct contractor id
export const defaultParentContractorId = 2882;

// contractor id to its domain
export const PartnershipDomains = [
    {value: 2882, text: 'servicedirect.com'},
    {value: 117, text: 'nexstarleads.com'}
];

export const ContractorTypes = {
    Marketplace: 'Marketplace',
    Select: 'Select',
    Affiliate: 'Affiliate'
};

export const ContractorTypeIds = {
    Marketplace: 2,
    Select: 1,
    Affiliate: 3,
    ExternalBuyer: 4
};

export const CampaignTypes = {
    WEBSITE_WITH_TRACKING: 'website with tracking',
    WEBSITE_ONLY: 'website only',
    TRACKING_ONLY: 'tracking only',
    MARKETPLACE: 'marketplace',
    EXTERNAL_BUYER: 'external buyer',
};

export const HighlightTypes = {
    GENERAL: 'general',
    ENROLL: 'enroll'
};

export const CALL_RECORDING_TEXT = 'You must Opt-In to Call Recording in order for you to be able to submit Leads for' +
    ' review, which ensures that you will not pay for any Non-Billable Leads.';

export const imagesUrls = {
    usMapPlaceholder: 'https://images.servicedirect.com/images/shared/mysd/us-map-placeholder.png',
    canadaMapPlaceholder: 'https://images.servicedirect.com/images/shared/mysd/canada-map-placeholder.png',
};

export const US_STATES = [
    { value: '', text: 'Select One' },
    { value: 'AK', text: 'AK' },
    { value: 'AL', text: 'AL' },
    { value: 'AR', text: 'AR' },
    { value: 'AZ', text: 'AZ' },
    { value: 'CA', text: 'CA' },
    { value: 'CO', text: 'CO' },
    { value: 'CT', text: 'CT' },
    { value: 'DC', text: 'DC' },
    { value: 'DE', text: 'DE' },
    { value: 'FL', text: 'FL' },
    { value: 'GA', text: 'GA' },
    { value: 'HI', text: 'HI' },
    { value: 'IA', text: 'IA' },
    { value: 'ID', text: 'ID' },
    { value: 'IL', text: 'IL' },
    { value: 'IN', text: 'IN' },
    { value: 'KS', text: 'KS' },
    { value: 'KY', text: 'KY' },
    { value: 'LA', text: 'LA' },
    { value: 'MA', text: 'MA' },
    { value: 'MD', text: 'MD' },
    { value: 'ME', text: 'ME' },
    { value: 'MI', text: 'MI' },
    { value: 'MN', text: 'MN' },
    { value: 'MO', text: 'MO' },
    { value: 'MS', text: 'MS' },
    { value: 'MT', text: 'MT' },
    { value: 'NC', text: 'NC' },
    { value: 'ND', text: 'ND' },
    { value: 'NE', text: 'NE' },
    { value: 'NH', text: 'NH' },
    { value: 'NJ', text: 'NJ' },
    { value: 'NM', text: 'NM' },
    { value: 'NV', text: 'NV' },
    { value: 'NY', text: 'NY' },
    { value: 'OH', text: 'OH' },
    { value: 'OK', text: 'OK' },
    { value: 'OR', text: 'OR' },
    { value: 'PA', text: 'PA' },
    { value: 'RI', text: 'RI' },
    { value: 'SC', text: 'SC' },
    { value: 'SD', text: 'SD' },
    { value: 'TN', text: 'TN' },
    { value: 'TX', text: 'TX' },
    { value: 'UT', text: 'UT' },
    { value: 'VA', text: 'VA' },
    { value: 'VT', text: 'VT' },
    { value: 'WA', text: 'WA' },
    { value: 'WI', text: 'WI' },
    { value: 'WV', text: 'WV' },
    { value: 'WY', text: 'WY' },
]

export const CAN_PROVINCES = [
    { value: '', text: 'Select One' },
    { value: 'AB', text: 'AB' },
    { value: 'BC', text: 'BC' },
    { value: 'MB', text: 'MB' },
    { value: 'NB', text: 'NB' },
    { value: 'NL', text: 'NL' },
    { value: 'NT', text: 'NT' },
    { value: 'NS', text: 'NS' },
    { value: 'NU', text: 'NU' },
    { value: 'ON', text: 'ON' },
    { value: 'PE', text: 'PE' },
    { value: 'QC', text: 'QC' },
    { value: 'SK', text: 'SK' },
    { value: 'YT', text: 'YT' },
]

export const LoadingMessages = [
    'This is taking longer than expected',
    'Working hard on it',
    'Waiting on that sweet data!',
    'Feeding the unicorns',
    'Approaching the event horizon',
    'Sorry I took a nap',
    'Dividing by zero',
    'Charging the gigabits',
    'Working up a sweat!',
    'This is a little embarrasing',
    '¯\\_(ツ)_/¯',
    'Still working on it',
    'Charging flux capacitor to 1.21 gigawatts',
    'Adding the BBQ sauce',
    'Hacking the mainframe',
    'Low and slow',
    'This one needs to marinade a little longer'
]

export const externalBuyerStatusOptions = [
    {"value": "in development", "text": "In Development", disabled: true},
    {"value": "in setup", "text": "In Setup"},
    {"value": "ping only", "text": "Ping Only"},
    {"value": "ping and post", "text": "Ping and Post"},
    {"value": "deactivated", "text": "Deactivated"},
];

export const requireCallerIdOptions = [
    {"value": "disabled", "text": "Disabled"},
    {"value": "optional", "text": "Optional"},
    {"value": "required in post", "text": "Required in Post"},
    {"value": "required in ping and post", "text": "Required in Ping (and Post)"},
];

const LOCAL_STORAGE_REMEMBER_KEY = 'sd-deselect-lead-delivery-first';

/**
 * handles un-checking of call recording by prompting the user of a disclaimer once (per browser)
 * @param {boolean} checked - whether the checkbox was checked or unchecked
 * @param {function} updateMessageBlocks - the App.js function that displays the message block
 */
export function handleCallRecordingUncheck(checked, updateMessageBlocks: (messages: Object, messageType: string) => void) {
    if (checked) {
        return;
    }

    try {
        const alreadyChecked = !!localStorage.getItem(LOCAL_STORAGE_REMEMBER_KEY);
        if (!alreadyChecked) {
            localStorage.setItem(LOCAL_STORAGE_REMEMBER_KEY, true);
            updateMessageBlocks(<><u>Disclaimer</u>: By choosing not to activate Record Calls &
                Enable Lead Review, you are not eligible for Lead Review. If you want to submit Leads for
                Review, please activate Record Calls & Enable Lead Review.</>, 'info');
        }
    } catch (ignore) {
    }
}

/**
 * finds the text? of the option with the given value
 * @param {Object[]} options as available here in StaticValues.js
 * @param {string} value the "value" of the item searched for
 * @param {string} [key="text"] the key to return; default is "text"
 * @return {undefined|string} the text? corresponding to the searched item, undefined if not found
 */
export function textOfOption(options: Object[], value: string, key = 'text') {
    let item = options.find((item) => item.value == value);
    return item?.[key];
}
