import React, { Component } from 'react';

export default class BulkEditCampaignsStep4 extends Component {
    constructor(props) {
        super(props);
        this.props.startPollingJobProgress();
    }

    render() {

        let numTotal = Object.keys(this.props.selectedCampaignIds).length;

        return (
            <>
                <div className="padding-40-bottom">
                    <p className="type-large-body type-centered">
                        { this.props.numTasks.successful } / {numTotal} Campaigns edited...
                    </p>
                {this.props.numTasks.failed > 0
                    ? <p className="type-large-body type-centered type-alert">
                        {this.props.numTasks.failed} Campaigns failed...
                    </p>
                    : ''
                }
                </div>
            </>
        );
    }
}