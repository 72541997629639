import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Copy } from '../../Svgs'
import { IconButton } from '../form_elements/Button'

const CopyToClipboard = (props) => {
    const {
        buttonText = 'Copy Data to Clipboard',
        disabled,
        icon = Copy,
        dataToCopy,
    } = props
    const [_buttonText, setButtonText] = useState(buttonText)
    return (
        <IconButton
            variant="span"
            // size="small"
            icon={icon}
            disabled={disabled}
            onClick={() => {
                setButtonText('Copied!')
                if (process.env.NODE_ENV === 'production') {
                    navigator.clipboard.writeText(dataToCopy)
                } else {
                    console.log(
                        `Data "${dataToCopy}" not copied to clipboard because the browser cannot write to the clipboard in a non production environment`
                    )
                }
                setTimeout(() => {
                    setButtonText(buttonText)
                }, 3000)
            }}
        >
            <span className="type-small-body">{_buttonText}</span>
        </IconButton>
    )
}

CopyToClipboard.propTypes = {
    dataToCopy: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.element
}

export default CopyToClipboard
