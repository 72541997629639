import React, { memo, useState } from 'react'
import pluralize from 'pluralize'
import MultiSelectList from '../../../common/components/form_elements/MultiSelectList'
import { InputWrapper, Toolbar } from './styles'

export default memo(({ filters, filterValues: _fv, resultsCount, onApplyFilters, label }) => {
    const [filterValues, setFilterValues] = useState(_fv || {})
    const [filterTextValues, setFilterTextValues] = useState({})

    const getSentenceForVals = (vals) => {
        if (vals.length === 0) {
            return ''
        } else if (vals.length === 1) {
            return vals[0]
        } else if (vals.length === 2) {
            return vals.join(' and ')
        } else if (vals.length > 10) {
            return `${vals.slice(0, 10).join(', ')} and ${vals.length - 10} more`
        } else {
            return vals.slice(0, -1).join(', ') + ', and ' + vals.slice(-1)
        }
    }

    const onSelectFilter = (name) => (values: Array) => {
        // const newFilterValues = { ...filterValues, [name]: values }
        setFilterValues({ ...filterValues, [name]: values })
        // set the corresponding filter labels
        const filterSet = filters.filter((f) => f.name === name)[0]
        if (filterSet) {
            const textLabels = filterSet.values?.reduce((acc, v) => {
                if (values?.includes(v.value)) {
                    acc.push(v.text)
                }
                return acc
            }, [])
            setFilterTextValues({ ...filterTextValues, [name]: textLabels })
        }
        // notify change handler
        onApplyFilters({ ...filterValues, [name]: values })
    }

    const deselectAll = (name) => () => {
        // clear the values
        setFilterValues({ ...filterValues, [name]: [] })
        // clear the text values
        setFilterTextValues({ ...filterTextValues, [name]: [] })
        // notify change handler
        onApplyFilters({ ...filterValues, [name]: [] })
    }

    const filterInputs = filters?.map((f) => {
        const { name, display, values } = f
        const emptyLabel = `All ${pluralize(display)}`
        return (
            <InputWrapper key={`filter_set_${name}`}>
                <MultiSelectList
                    name={name}
                    emptyLabel={emptyLabel}
                    options={values}
                    multiselect={true}
                    selections={filterValues[name] || []}
                    onChange={onSelectFilter(name)}
                    allowDeselectAll={true}
                    onDeselectAll={deselectAll(name)}
                    useTextFilter={true}
                />
            </InputWrapper>
        )
    })

    const leadSourcesContext =
        filterTextValues?.ad_network_ids?.length > 0
            ? `${
                  filterTextValues?.ad_network_ids?.length == 1
                      ? 'Lead Source'
                      : 'Lead Sources'
              } ${getSentenceForVals(filterTextValues.ad_network_ids)}`
            : 'All Lead Sources'
    const serviceCategoryContext =
        filterTextValues?.industry_ids?.length > 0
            ? `${
                filterTextValues?.industry_ids?.length == 1
                    ? 'Service Category'
                    : 'Service Categories'
            } ${getSentenceForVals(filterTextValues.industry_ids)}`
            : 'All Service Categories'
    const contextSentence = (
        <div>
            <span className="type-normal">Showing </span>
            <span className="type-normal type-heavy">{resultsCount} </span>
            <span className="type-normal type-heavy">{resultsCount == 1 ? label : `${label}s`} </span>
            <span className="type-normal">in </span>
            <span className="type-normal type-heavy">{leadSourcesContext} </span>
            <span className="type-normal">and </span>
            <span className="type-normal type-heavy">{serviceCategoryContext} </span>
        </div>
    )

    return (
        <Toolbar>
            {contextSentence}
            {filterInputs}
        </Toolbar>
    )
})
