import React from 'react';

import {default as TableCell} from './SignupLaunchStatusTableCell';
import {formatDate} from "../../../Util";
import ContextualMenu from "../../../common/ContextualMenu";

export const SignupTableCells = (props) => {
    const signupRow = props.signupRow;
    const key = props.rowKey;
    let rowSpan = signupRow.campaigns
        ? signupRow.campaigns.length
        : 1;

    if (props.extraCampaignRow) {
        rowSpan++;
    }

    const clientStartedSignup = signupRow.client_started_signup ||
        signupRow.select_enroll_company_profile_started_timestamp;
    const accountSetupComplete = signupRow.account_setup_complete ||
        signupRow.select_enroll_company_profile_completed_timestamp;
    const yourCompanyComplete = signupRow.your_company_complete ||
        signupRow.select_enroll_company_profile_completed_timestamp;

    return [
        // client name
        <TableCell header="signup-client-name"
            isClientName={true}
            rowSpan={rowSpan}
            className="client-name"
            key={`${key}signup-client-name`}
        >
            <ContextualMenu
                linkLabel={signupRow.client_name}
                menuLinks={props.clientMenuLinks}
                onFirstOpen={props.onFirstOpen}
                onToggle={props.onConextualMenuToggle}
                scrollboxRef={ props.scrollboxRef }
            />
        </TableCell>,
        <TableCell header="client-type"
                   rowSpan={rowSpan}
                   key={`${key}client-type`}>
            {signupRow.select_enroll_started_timestamp
            ? <>Select Program Enrollment<br/>(Existing Client)</>
            : <>Select Sign Up<br/>(New Client)</>}
        </TableCell>,
        // parent contractor
        <TableCell header="parent-contractor-name"
                   rowSpan={rowSpan}
                   key={`${key}parent-contractor-name`}
        >
            {signupRow.parent_contractor}
        </TableCell>,
        // contact name
        <TableCell header="contact-name"
                   rowSpan={rowSpan}
                   key={`${key}contact-name`}
        >
            {signupRow.primary_contact_name}
        </TableCell>,
        // contact email
        <TableCell header="contact-email"
                   rowSpan={rowSpan}
                   key={`${key}contact-email`}
        >
            {signupRow.primary_contact_email}
        </TableCell>,
        // contact phone
        <TableCell header="contact-phone"
                   rowSpan={rowSpan}
                   key={`${key}contact-phone`}
        >
            {signupRow.primary_contact_phone}
        </TableCell>,
        // HubSpot deal
        <TableCell header="hubspot-deal-id"
                   rowSpan={rowSpan}
                   key={`${key}hubspot-deal-id`}
        >
            {signupRow.hubspot_deal_link &&
            <a className="type-heavy" target="_blank" href={signupRow.hubspot_deal_link}>
                {signupRow.hubspot_deal_id}
            </a>}
        </TableCell>,
        // paid startup fee over the phone
        // <TableCell header={"phone-paid"}
        //     rowSpan={rowSpan}
        //     key={key + "phone-paid"}
        //     className={signupRow.paid_startup_fee_timestamp && !signupRow.account_setup_complete? "type-green" : ""}
        // >
        //     {signupRow.paid_startup_fee_timestamp && !signupRow.account_setup_complete ? "Yes" : "No"}
        // </TableCell>,
        // sent the signup invitation
        <TableCell header="invitation-sent"
                   rowSpan={rowSpan}
                   key={`${key}invitation-sent`}
        >
            {formatDate(signupRow.signup_email_sent || signupRow.select_enroll_started_timestamp)}
        </TableCell>,
        // paid startup / activation fee
        <TableCell header="paid-startup-fee"
                   rowSpan={rowSpan}
                   key={`${key}paid-startup-fee`}
        >
            {formatDate(signupRow.paid_startup_fee)}
        </TableCell>,
        // started signup
        <TableCell header="signup-started"
                   rowSpan={rowSpan}
                   key={`${key}signup-started`}
                   className={clientStartedSignup ? '' : 'type-red'}
        >
            {formatDate(clientStartedSignup) || 'Pending'}
        </TableCell>,
        // account-setup complete
        <TableCell header="setup-complete"
                   rowSpan={rowSpan}
                   key={`${key}setup-complete`}
                   className={accountSetupComplete ? '' : 'type-red'}
        >
            {   // TODO - warning - hard coded link
                // https://app.asana.com/0/1108862586963070/1128151118788495/f
                accountSetupComplete
                    ? <a className="type-heavy" target="_blank"
                         href={
                             process.env.REACT_APP_SD_ADMIN_URL +
                             "db_viewer?sql=select%20contractor_industry.*,%20industry.name%20" +
                             "service_category,%20contractor.name%20client%20" +
                             "from%20contractor_industry,contractor,industry%20" +
                             "where%20contractor_industry.industry_id=industry.industry_id%20" +
                             "and%20contractor_industry.contractor_id=contractor.contractor_id%20" +
                             "and%20contractor_industry.contractor_id=" + signupRow.contractor_id
                         }>
                        {formatDate(accountSetupComplete)}
                    </a>
                    : 'Pending'
            }
        </TableCell>,
        // cs-manager
        <TableCell header="cs-manager"
                   rowSpan={rowSpan}
                   key={`${key}cs-manager`}
        >
            {props.csManagerSelectList}
        </TableCell>,
        // your-company complete
        <TableCell header="company-complete"
                   rowSpan={rowSpan}
                   key={`${key}company-complete`}
                   className={yourCompanyComplete ? '' : 'type-red'}
        >
            {formatDate(yourCompanyComplete) || 'Pending'}
        </TableCell>,
        // link to Account Activation Profile (only if they've gotten to step 2)
        accountSetupComplete
            ? <TableCell header="record"
                         rowSpan={rowSpan}
                         key={`${key}record`}>
                <a className="type-heavy"
                   target="_blank"
                   href={`/account-activations/${signupRow.contractor_id}/campaign-setup`}
                >
                    Account Activation Profile
                </a>
            </TableCell>
            : <TableCell header="record"
                         rowSpan={rowSpan}
                         key={`${key}record`}
                         className="type-red">
                Pending
            </TableCell>
        ,
    ]
};

export default SignupTableCells;
