import React, {Component} from 'react';
import {get} from '../../Requests';
import axios from 'axios';
import MessageBlocks from '../../common/MessageBlocks';
import Modal from "../../common/Modal";
import Pagination from "../../common/components/Pagination/v1.0.1/Pagination";
import {NavLink} from "react-router-dom";

export default class Industries extends Component {
    constructor(props) {
        super(props);
        document.title = 'Service Categories';

        this.state = {
            dataRows: [],
            messages: [],
            messageType: '',
            pagination: {
                rows_per_page: process.env.REACT_APP_PAGINATION_NUM_PER_PAGE || 25,
                page_num: 1,
                total_rows: null,
                total_pages: null
            },
            filters: {
                name: ""
            }
        };

        this.cancelSignal = axios.CancelToken.source();
    }

    /**
     * @codereview: this method appears in other view classes too.  How can we
     * refactor to remove duplication?  Consider defining these in App.js and
     * prop drilling them down as needed.
     * @param modalContent
     * @param modalHeader
     * @param modalWidth
     * @param modalFlatBottom
     */
    updateModalContent = (modalContent, modalHeader, modalWidth, modalFlatBottom) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom
        });
    };

    /**
     * @codereview: this method appears in other view classes too.  How can we
     * refactor to remove duplication?  Consider defining these in App.js and
     * prop drilling them down as needed.
     */
    updateMessageBlocks = (message, messageType) => {

        let stateClone = {...this.state};
        stateClone.messages.push(message);
        if (typeof messageType === "string") {
            stateClone.messageType = messageType
        }
        this.setState(stateClone);
    }

    handlePageChange = (event, newPageNum) => {
        let paginationClone = {...this.state.pagination};
        paginationClone.page_num = newPageNum;

        this.setState({pagination: paginationClone}, this.getData);
    }

    /**
     * Reads current filter values from their form elements, sets then in the
     * view's local state, and subsequently re-renders the view.
     *
     * To avoid erroneous 0 row conditions, this method also resets the
     * pagination values to avoid unintentionally sending those in the API
     * request.
     */
    handleFilterChange = () => {

        let filtersClone = {...this.state.filters},
            paginationClone = {...this.state.pagination},
            searchValue = document.getElementById("filter_name").value;

        filtersClone.name = searchValue;
        paginationClone.page_num = 1;

        this.setState({
            pagination: paginationClone,
            filters: filtersClone
        }, this.getData);
    }

    /**
     * Requests all signup rows, optionally by cs manager user id, and writes
     * them to state for rendering.
     */
    getData = () => {
        let pagination = this.state.pagination,
            url = "industries?";

        url += "&pagination[rows_per_page]=" + pagination.rows_per_page;
        url += "&pagination[page_num]=" + pagination.page_num;

        if (this.state.filters.name) {
            url += "&name=" + this.state.filters.name;
        }

        get(url, this.cancelSignal.token).then(resp => {
            // TODO add pop modal here
            if (!resp || resp.status !== 200) {
                return;
            }

            let dataRows = resp.data.data.industries,
                paginationValues = resp.data.pagination;

            this.setState({
                dataRows: dataRows,
                pagination: {
                    rows_per_page: paginationValues.rows_per_page,
                    total_rows: paginationValues.total_rows,
                    total_pages: paginationValues.total_pages,
                    page_num: paginationValues.page_num
                }
            });
        });
    };

    componentDidMount() {
        this.getData();
    }

    render() {

        let rowsJsx = this.state.dataRows.map(row => (
            <tr key={row.industry_id}>
                <td>
                    <NavLink
                        to={"/industries/" + row.industry_id}
                        activeClassName="here"
                    >
                        <span className="header__links__label">{row.name}</span>
                    </NavLink>
                </td>
                <td>{row.default_min_tender}</td>
                <td>{row.min_suggested_tender}</td>
                <td>{row.max_suggested_tender}</td>
            </tr>
        ));

        return (
            <div className="interface-container">
                <Modal
                    content={this.state.modalContent}
                    header={this.state.modalHeader}
                    flatBottom={this.state.modalFlatBottom}
                    updateModalContent={this.updateModalContent}
                    size="small"
                />

                <MessageBlocks
                    messages={this.state.messages}
                    type={this.state.messageType}
                    clearErrors={() => {
                        this.setState({messages: [], messageType: ""})
                    }}
                />
                <section className="white-background">
                    <div className="page-width">
                        <div className="row padding-50">
                            <div className="col alpha omega grid-12">
                                <a href="/industries/mp-targeted-zips">
                                    Marketplace Service Categories and Zip Codes</a>
                            </div>
                            <div className="col alpha omega grid-12"/>
                            <div className="clear-block"/>
                        </div>
                        <div className="row padding-120-bottom">
                            <div className="col alpha omega grid-12">
                                <div className="module">
                                    <div
                                        className="module__header module__fullwidth">
                                        <h3 className="type-large-subhead type-single-line">
                                            Service Categories
                                        </h3>
                                    </div>
                                    <div className="module__contentbox module__fullwidth">
                                        <div className="ui-hide-mobile">
                                            <div className="simpleflex__row no-margin-top spacing-50-bottom">
                                                <div className="simpleflex__cell">
                                                    <div className="no-margin-top spacing-18-bottom">
                                                        <form onSubmit={(event) => {
                                                            event.preventDefault();
                                                            this.handleFilterChange()
                                                        }}>
                                                            <div className="searchfield">
                                                                <div className="searchfield__input">
                                                                    <input id="filter_name" type="text"
                                                                           placeholder="Search by Keyword"/>
                                                                </div>
                                                                <div className="searchfield__button">
                                                                    <button onClick={this.handleFilterChange}
                                                                            className="ui-small-button">
                                                                        Search
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <p className="type-small-body type-heavy spacing-18-top no-margin-bottom">
                                                        <span className="type-right-side-bump type-blue" role="button"
                                                              onClick={() => {
                                                                  document.getElementById("filter_name").value = "";
                                                                  this.handleFilterChange();
                                                              }}>
                                                            Clear Search
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <table
                                            className="statements__table standardtable">
                                            <thead
                                                className="standardtable__lightheader">
                                            <tr className="type-small-body type-heavy">
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        Name
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        Default Min Tender
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        Min Suggested Tender
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="statements__header__label">
                                                        Max Suggested Tender
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rowsJsx}
                                            </tbody>
                                        </table>
                                        <Pagination
                                            pageNum={this.state.pagination.page_num}
                                            totalRows={this.state.pagination.total_rows}
                                            rowsPerPage={this.state.pagination.rows_per_page}
                                            totalPages={this.state.pagination.total_pages}
                                            pages={[]}
                                            handlePageChange={this.handlePageChange}
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="clear-block"></div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
