import React, {Component} from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import Pagination from "../../common/components/Pagination/v1.0.1/Pagination";
import LeadManagerSecondaryNav from "./LeadManagerSecondaryNav";
import LeadSummary from "./LeadSummary";
import LeadLog from "../../common/lead_log/LeadLog";
import {
    DefaultResetSvg,
    FeedbackSvg,
    IconFilterSvg,
    IconLockSvg,
    IconSearchSvg,
    IconStatsSvg,
} from '../../common/Svgs';
import LeadService from '../../service/LeadService';
import Modal from '../../common/Modal';
import { filterMessage } from '../../common/lead_log/FilterMessage';
import FilterLeadsModal from './FilterLeadsModal';
import FilteredLeadsStatsModal from '../../common/lead_manager/FilteredLeadsStatsModal';
import {
    parseBillableStatus,
} from '../../common/lead_log/LeadFilters';
import {
    formatCurrency,
    formatNumber
} from "../../Util";
import DateRangeService from "../../service/DateRange";
import LoadingGif from "../../common/components/LoadingGif";
import LeadSummaryCell from "../../common/lead_log/LeadSummaryCell";
import TermsAndConditions from "../TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy";
import {errorHandler} from "../../Requests";
import MajorAlerts from '../../common/MajorAlerts';
import AuthService from "../../service/AuthService";
import ExportLeadsButton from "../../common/ExporLeadsButton";
import Button from '../../common/components/form_elements/Button';
import type {IFilteredSummary} from "../../Interfaces/IFilteredSummary";
import type {ILeadSummary} from "../../Interfaces/ILeadSummary";
import UserService from "../../service/UserService";

export default class LeadManagerView extends Component {

    dateRangeService;

    /**
     * Implemented as an instance variable b/c both the lead table and CSV
     * export need the same query parameters.
     * @type {string}
     */
    apiQueryStr = "";

    LOADING_MESSAGE_MIN_DISPLAY_TIME_MS = 333;

    constructor(props) {
		super(props);

        this.dateRangeService = new DateRangeService();

        let rowsPerPage = process.env.REACT_APP_PAGINATION_NUM_PER_PAGE || 25;
        if (document.cookie.indexOf('srvClientRowsPerPage=')) {
            let rowsPerPageCookie = document.cookie.split(';').filter(item => item.trim().indexOf('srvClientRowsPerPage=') === 0)[0];
            if (rowsPerPageCookie) {
                rowsPerPage = parseInt(rowsPerPageCookie.split('=')[1]);
            }
        }


		// the leadLog holds the state of the leads
		let _state = {
            //
            // Populated if the authenticated user is a parent contractor.
            //
		    contractorOptions: [],
            //
            // Populated if the authenticated user is a parent contractor.
            //
            industryRevenueAverages: [],
            //
            // When true, say "Loading your Campaigns..." instead of the Filter Description
            // and do not show the "Not finding what you're looking for?" section, even though
            // there are 0 results.
            //
            loadingResults: true,
            //
            // At minimum, we show the "Loading your Campaigns..." message for a certain amount
            // of time. This value is set to true when results start being loaded, and a
            // setTimeout resets it to false after a minimum delay. Ensures people get to see
            // the loading message instead of getting confused.
            //
            loadingMessageMinDelayTimer: false,
            //
            // The ID of the setTimeout() so we can clear it
            //
            loadingMessageMinDelayTimeoutId: undefined,
		    leads: [],
            users: [],
            totalLeads: null,
            pagination: {
                rows_per_page: rowsPerPage,
                page_num: 1,
                total_rows: null,
                total_pages: null,
                page_min_row_number: 1,
                page_max_row_number: process.env.REACT_APP_PAGINATION_NUM_PER_PAGE
            },
            /** @property {LeadsFilters} */
            selectedFilters: {},
            /** @property {LeadsFilters} */
            appliedFilters: {},
            /** @property {LeadsFilters} */
            defaultFilters: {
                lead_id: undefined,
                is_valid: 'null', // string null shows valid and invalid leads
                needs_follow_up: undefined,
                call_answered: undefined,
                booked_appointment: undefined,
                job_won: undefined,
                lead_type: '',
                contractor_ids: [],
                campaign_ids: [],
                industry_ids: [],
                date_range_id: 7,
                date_from: '',
                date_to: '',
                keyword: '',
                under_review: undefined,
                billable_select_list_value: "all",
                client_lead_review_select_list_value: "all",
                starred: undefined,
                managed: undefined
            },
            /** @property {ILeadSummary} */
            leadSummary: {
                needsFollowUp: undefined,
                adImpressionsAllTime: undefined,
                billableLeadsAllTime: undefined,
                billableLeadsLast30Days: undefined,
                bookedAppointmentRateAllTime: undefined,
                bookedAppointmentsLast30Days: undefined,
                confidence: undefined,
                bookedAppointmentConfidence: undefined,
                bookedAppointmentLast30DaysConfidence: undefined,
                leadsWithRevenue: undefined,
                managedRoi: undefined,
                minConfidence: undefined
            },
            /** @property {IFilteredSummary} */
            leadFilteredSummary: {},
            campaigns: [],
            industries: [],
            modalContent: undefined,
            modalHeader: undefined,
            modalFooter: undefined,
            modalWidth: undefined,
            modalFlatbottom: undefined
        };
        _state.appliedFilters = _state.selectedFilters = _state.defaultFilters;

		this.state = _state;

        this.modalScrollerRef = React.createRef();
        this.modalContainerRef = React.createRef();
        this.tooltipRef = React.createRef();

        this.leadService = new LeadService();
        this.userService = new UserService();
    }

    componentDidMount() {
        this.setAppliedFiltersFromUrl();
        this.getSummary();
        this.getUsers();

        //
        //  This is to provide direct links to the Terms of Service and Privacy Policy which are accessible usually
        //  through a popup.
        //      Now the TOS is at mysd.servicedirect.com/leads?terms=1
        //      and the Privacy is at mysd.servicedirect.com/leads?privacy=1
        //
        let queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});
        if (queryParams.terms) {
            this.updateModalContent(
                <TermsAndConditions inAccountActivation={false}
                    whiteLabelPartnership={this.props.whiteLabelPartnership}
                />,
                this.props.whiteLabelPartnership.white_label_name + " Terms and Conditions"
            );
        }
        if (queryParams.privacy) {
            this.updateModalContent(
                <PrivacyPolicy whiteLabelPartnership={this.props.whiteLabelPartnership}/>,
                this.props.whiteLabelPartnership.white_label_name + " Privacy Policy"
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //
        // A url change will land here.  The filters in the URL have already
        // updated, so we just need to set them in state.appliedFilters.
        //
        if (prevProps.location.search != this.props.location.search) {
            this.setAppliedFiltersFromUrl();
        }
    }

    componentWillUnmount() {
        this.leadService.cancelSignal.cancel();
        this.userService.cancelSignal.cancel();
    }

    isParentContractor = () => {
        return this.state.contractorOptions.length > 0;
    };

    /**
     * get the lead summary
     */
    getSummary = () => {
        this.leadService.getSummary()
            .then((leadSummary: ILeadSummary) => this.setState({leadSummary}))
            .catch(errorHandler);
    };

    getUsers = () =>
        this.userService.get()
            .then((data) => this.setState({users: data.data.users}))
            .catch(error => console.log("Error in LeadManagerView.getUsers(), ", error));

    getFilteredSummary = () => {
        this.leadService.getFilteredSummary(this.apiQueryStr)
            .then((leadFilteredSummary: IFilteredSummary) => this.setState({leadFilteredSummary}))
            .catch(errorHandler);
    };

    clearOutModalContent = () => {
        this.setState({
            modalContent: null,
            modalHeader: null,
            modalWidth: null,
            modalFlatBottom: null
        });
    };

    /**
     * @todo This method is everywhere, I think.  How can we consolidate this?
     * @param modalContent
     * @param modalHeader
     * @param modalWidth
     * @param modalFlatBottom
     * @param modalFooter
     */
    updateModalContent = (modalContent, modalHeader, modalWidth, modalFlatBottom, modalFooter) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom,
            modalFooter: modalFooter
        });
    };

    /**
     * applies filters from the URL
     */
    setAppliedFiltersFromUrl = () => {
        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});

        let newAppliedFiltersClone = {...this.state.defaultFilters},
            paginationClone = {...this.state.pagination};

        newAppliedFiltersClone.campaign_ids = [];
        newAppliedFiltersClone.contractor_ids = [];
        newAppliedFiltersClone.industry_ids = [];

        //
        // Apply query params
        //
        for (let key in queryParams) {
            // NOTE: this check was causing an error because queryString library somehow returns an "object" that does
            // not have hasOwnProperty! Weird... ~ RFM NL JT Oct 2020
            // if (!queryParams.hasOwnProperty(key)) {
            //     continue;
            // }

            if (!(key in newAppliedFiltersClone)) {
                //
                // queryParams will have a campaign_ids[] key, vs. just our
                // campaign_ids state key; that's handled here.
                //
                if (key === "campaign_ids[]" ||
                    key === "industry_ids[]" ||
                    key === "contractor_ids[]")
                {
                    let stateKey = key.substr(0, key.length - 2);

                    //
                    // Well, sheesh ... campaign/industry ids could be an array
                    // or a scalar in queryParams[key], so we need to support
                    // storing both as an array b/c that's what our logic is
                    // built to handle.
                    //
                    if (Array.isArray(queryParams[key])) {
                        newAppliedFiltersClone[stateKey] = queryParams[key];
                    } else {
                        newAppliedFiltersClone[stateKey] = [queryParams[key]];
                    }
                }

                continue;
            }

            newAppliedFiltersClone[key] = queryParams[key];
        }

        //
        // Apply pagination.  page_num is the only parameter we need to detect
        // right now.  Later, we'll detect other parameters, such as a
        // user-selected rows_per_page to display.
        // And if no page_num is specified, make sure it's reset back to 1.
        // This is a case that needs to be handled when a user paginates, then
        // applies different filters; we don't want the old page number
        // remaining.  Instead, we want to push them back to page 1 of their new
        // search results.
        //
        if (queryParams.page_num) {
            paginationClone.page_num = queryParams.page_num;
        }
        else {
            paginationClone.page_num = 1;
        }

        //
        // is_valid needs to be set manually in cases where "All" was
        // selected by the user.
        //
        let billable_select_list_value = "all";
        if (!('is_valid' in queryParams) && !('under_review' in queryParams)) {
            newAppliedFiltersClone.is_valid = 'null';
            billable_select_list_value = "all";
            newAppliedFiltersClone.under_review = undefined;
        }
        else {
            if (queryParams.under_review == 2) {
                billable_select_list_value = "reviewed-all";

                if(queryParams.is_valid == 1) {
                    billable_select_list_value = "reviewed-billable";
                }
                else if(queryParams.is_valid == 0) {
                    billable_select_list_value = "reviewed-non-billable";
                }
            }
            else if (queryParams.under_review == 1) {
                billable_select_list_value = "under-review";
            }
            else if (queryParams.is_valid == 1) {
                billable_select_list_value = "billable";
            }
            else if (queryParams.is_valid == 0) {
                billable_select_list_value = "non-billable";
            }
        }

        newAppliedFiltersClone.billable_select_list_value = billable_select_list_value;

        let selectedFiltersClone = {...newAppliedFiltersClone};
        selectedFiltersClone.campaign_ids = [...newAppliedFiltersClone.campaign_ids];
        selectedFiltersClone.contractor_ids = [...newAppliedFiltersClone.contractor_ids];
        selectedFiltersClone.industry_ids = [...newAppliedFiltersClone.industry_ids];

        this.setState(
            {
                selectedFilters: selectedFiltersClone,
                appliedFilters: newAppliedFiltersClone,
                pagination: paginationClone
            },
            this.getLeads
        );
    };

    /**
     * Applies selected filters into both state.selectedFilters and the url.
     * The url change is detected in componentDidUpdate() afterward and
     * results in a view refresh.
     * @param {object} selectedFilters - selected filters to apply
     */
    applyFilters = (selectedFilters) => {
        let basePath = '/leads?',
            search = '';

        selectedFilters = parseBillableStatus(selectedFilters.billable_select_list_value, selectedFilters);

        //
        // All quick filters, w/the exception of the "Managed" quick filter,
        // are represented through various values in
        // this.state.selectedFilters.  And b/c they are, they consequently
        // wind up unset appropriately in this function if a filter selection
        // changes.
        //
        // However, the "Managed" filter doesn't behave the same, it needs to
        // be manually unset here on each call.
        //

        selectedFilters.lead_id = undefined;

        for (let key in selectedFilters) {
            if (selectedFilters[key] === undefined ||
                selectedFilters[key] === "") {
                continue;
            }

            if (key === "billable_select_list_value") {
                continue;
            }

            //
            // If there are no industry ids, don't attempt to append any
            // industry id filters to the url.
            //
            if (key === "industry_ids" ||
                key === "campaign_ids" ||
                key === "contractor_ids"
            ) {
                if (selectedFilters[key].length) {
                    let paramStr = selectedFilters[key].join("&" + key + "[]=");
                    paramStr = "&" + key + "[]=" + paramStr;
                    search += paramStr;
                }
            }
            else {
                if (selectedFilters[key] === null) {
                    search += "&" + key;
                } else {
                    search += "&" + key + "=" + selectedFilters[key];
                }
            }
        }

        /* remove the prefixed "&" */
        search = search.substr(1);

        this.props.history.push(basePath + search);
        this.setState({selectedFilters});
        this.clearOutModalContent();
    };

    /**
     * At minimum, we show the "Loading your Campaigns..." message for a certain amount
     * of time. This value is set to true when results start being loaded, and a
     * setTimeout resets it to false after a minimum delay. Ensures people get to see
     * the loading message instead of getting confused.
     */
    setLoadingStatus = () => {

        //
        // Clear any existing timeout from a previous request
        //
        if (this.state.loadingMessageMinDelayTimeoutId) {
            clearTimeout(this.state.loadingMessageMinDelayTimeoutId);
        }

        //
        // Set a timeout so we will see Loading message for at least a certain time
        //
        let component = this;
        let timeoutId = setTimeout(() => {
            component.setState({loadingMessageMinDelayTimer: false});
        }, this.LOADING_MESSAGE_MIN_DISPLAY_TIME_MS);

        //
        // Set state variables - bool to show Loading Message until timeout expires,
        // and also save timeout ID, to clear later
        //
        this.setState({
            loadingResults: true,
            loadingMessageMinDelayTimer: true,
            loadingMessageMinDelayTimeoutId: timeoutId,
        });

    };

    /**
     *  Function to hit the endpoint and grab the leads
     **/
    getLeads = () => {
        this.setLoadingStatus();

        let pagination = this.state.pagination,
            appliedFilters = this.state.appliedFilters,
            queryStr = '';

        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});

        //
        // NOTE in one instance I saw multiple lead_id query vars sent to the API ~ 2020-01-29 RFM
        //
        if (queryParams.lead_id) {
            this.apiQueryStr = queryStr = `lead_id=${queryParams.lead_id}`;
        }

        else {
            //
            // We need to apply filter values from selectedFilters, and at the
            // end of this method, we set them in state.appliedFilters.
            //
            for (let key in appliedFilters) {
                if (!appliedFilters.hasOwnProperty(key)) {
                    continue;
                }

                if (!appliedFilters[key]) {
                    continue;
                }

                if (key === "billable_select_list_value") {
                    continue;
                }

                //
                // If there are no industry ids, don't attempt to append any
                // industry id filters to the url.
                //
                if (["industry_ids", "campaign_ids", "contractor_ids"].includes(key)) {
                    if (appliedFilters[key].length) {
                        let paramStr = appliedFilters[key].join("&" + key + "[]=");
                        paramStr = "&" + key + "[]=" + paramStr;
                        queryStr += paramStr;
                    }
                } else {
                    queryStr += "&" + key + "=" + appliedFilters[key];
                }
            }

            if (queryStr[0] == "&") {
                queryStr = queryStr.substring(1);
            }

            //
            // Before setting pagination query parameters, set the current url
            // value on the instance's apiQueryStr variable.
            //
            this.apiQueryStr = queryStr;
            queryStr += "&pagination[rows_per_page]=" + pagination.rows_per_page;
            queryStr += "&pagination[page_num]=" + pagination.page_num;
        }

        this.leadService.getLeads(queryStr)
            .then((response) => {
                let industries = response.data.industries,
                    contractorOptions = response.data.contractor_options,
                    leads = response.data.leads,
                    campaigns = response.data.campaigns,
                    paginationValues = response.pagination;

                this.setState({
                    industries,
                    contractorOptions,
                    leads,
                    campaigns,
                    loadingResults: false,
                    pagination: {
                        rows_per_page: paginationValues.rows_per_page,
                        total_rows: Number(paginationValues.total_rows),
                        total_pages: paginationValues.total_pages,
                        page_num: paginationValues.page_num,
                        page_min_row_number: paginationValues.page_min_row_number,
                        page_max_row_number: paginationValues.page_max_row_number
                    }
                });
            })
            .catch(errorHandler);

        this.getFilteredSummary();
    };

    /**
     * Receives a new page number and sets it in the url.
     * The view will automatically take care of the rest
     * @param {Event} event
     * @param {Number} newPageNum
     */
    handlePageChange = (event, newPageNum) => {

        //
        // We're not calling event.preventDefault() here b/c we want the
        // browser to handle a "page change" in an otherwise default way, which
        // includes an auto-scroll to the top of the lead manager table.
        //
        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});

        queryParams["page_num"] = newPageNum;

        let newPath = "/leads?" + queryString.stringify(queryParams, {arrayFormat: 'bracket'});

        this.props.history.push(newPath);
    };

    resetFilter = () => {
        let selectedFilters = {...this.state.defaultFilters};

        selectedFilters.keyword = this.state.selectedFilters.keyword;

        this.setState(
            {selectedFilters},
            () => this.props.history.push("/leads")
        );
    };

    /**
     * Accepts a filterObject for each quick filter and sets the received
     * parameters in the URL.
     * @param {object} filterObject
     * @param {boolean} [clearModal=false] - if set to true, will close an open modal if present
     */
    quickFilter = (filterObject, clearModal = false) => {
        //
        // All quick filters apply to the All Time date range by default.
        // with the exception of managed or unmanaged leads filters which are set to "this year"
        //
        if (!filterObject.date_range_id) {
            filterObject.date_range_id = 7;
        }
        else if (!filterObject.date_from) {
            let fromToDates = this.dateRangeService.getFromToDates(filterObject.date_range_id);

            filterObject.date_from = fromToDates.dateFrom;
            filterObject.date_to = fromToDates.dateTo;
        }

        let newPath = "/leads?" + queryString.stringify(filterObject, {arrayFormat: 'bracket'});

        this.props.history.push(newPath);
        if (clearModal) {
            this.clearOutModalContent();
        }
    };

    /**
     * Receives key values pairs of updated lead properties and submits them to
     * the API.  On receipt, searches for the same lead in this view's state,
     * by id, and updates it there.
     */
    updateLead = (leadId, leadProps, callback) => {
        let leads = [...this.state.leads];
        let index = leads.findIndex((lead) => lead.lead_id == leadId);
        Object.assign(leads[index], leadProps);
        this.setState({leads});

        this.leadService.update(leadId, leadProps, this)
            .then(() => {
                this.getSummary();
                this.getFilteredSummary();

                if (typeof callback === 'function') {
                    callback();
                }
            })
            .catch((error) => {
                if (errorHandler(error)) {
                    if (typeof callback === 'function') {
                        callback(true);
                    }
                }
            });
    };

    /**
     * This component already has most of what it needs from a lead
     * passed in through the lead property.  But it lacks tags, and address, which
     * this API call provides.
     * @param {number} leadId - the lead id
     * @return Promise<AxiosResponse>
     */
    getLead = (leadId) =>
        this.leadService.get(leadId, this)
            .catch(errorHandler);

    /**
     * opens the filter leads modal
     * @param {string} focusTarget - the target input to focus after opening ("search" or "filter")
     */
    openFilterModal = (focusTarget = 'filter') => {
        this.updateModalContent(
            <FilterLeadsModal
                isParentContractor={this.isParentContractor()}
                clickAction={focusTarget}
                selectedFilters={ this.state.selectedFilters }
                defaultFilters={ this.state.defaultFilters }
                industries={this.state.industries}
                campaigns={this.state.campaigns}
                contractorOptions={this.state.contractorOptions}
                quickFilter={this.quickFilter}
                applyFilters={this.applyFilters}
                scrollerRef={this.modalScrollerRef}
                modalContainerRef={this.modalContainerRef}
                scrollToSearch={focusTarget === 'search'}
            />,
            'Filter and Search Your Leads',
            'wide',
            true
        )
    };

    /**
     * opens the filter leads stats modal
     */
    openFilteredLeadsStatsModal = () => {
        this.updateModalContent(
            <FilteredLeadsStatsModal
                filterMessage={filterMessage(this.state, { includeShowingPrefix: false })}
                totalRows={this.state.pagination.total_rows}
                apiQueryStr={this.apiQueryStr}
                leadFilteredSummary={this.state.leadFilteredSummary}
                modalContainerRef={this.modalContainerRef}
                scrollerRef={this.modalScrollerRef}
            />,
            <>
                Lead Performance Metrics for {this.state.pagination.total_rows} Currently Filtered Leads
                <span className="type-flag type-flag--beta">BETA</span>
            </>,
            'wide',
            false,
            <>
                    <ExportLeadsButton totalRows={this.props.totalRows} apiQueryStr={this.props.apiQueryStr}/>
                <a href={`https://www.research.net/r/leadperfmetrics_cx?email=${AuthService.getItem('username')}`}
                   target="_blank" className="type-large-body type-heavy type-blue">
                    How useful are these metrics?
                    <span className="feedback__icon inline-icon inline-icon__middle inline-icon__right inline-icon__28">
                        {FeedbackSvg}
                    </span>
                </a>
            </>
        );
    };

    setRowsPerPage = (val) => {
        let stateClone = {...this.state};

        stateClone.pagination.rows_per_page = val;
        stateClone.pagination.page_max_row_number = val;

        document.cookie = `srvClientRowsPerPage=${val}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;

        this.setState(stateClone, this.getLeads);
    };

	render() {
        let minConfidence = Number(this.state.leadFilteredSummary.minConfidence);

		return <div className="page-width-wide">
            <LeadManagerSecondaryNav
                filterMessage={ filterMessage(this.state) }
                resetFilter={ this.resetFilter }
                openFilterModal={ this.openFilterModal }
                secondaryNavRef={ this.props.secondaryNavRef }
            />
            <Modal
                content={this.state.modalContent}
                header={this.state.modalHeader}
                footer={this.state.modalFooter}
                width={this.state.modalWidth}
                flatBottom={this.state.modalFlatBottom}
                updateModalContent={this.updateModalContent}
                scrollerRef={this.modalScrollerRef}
                modalContainerRef={this.modalContainerRef}
            />
            <MajorAlerts />
            <LeadSummary
                quickFilter={this.quickFilter}
                leadSummary={this.state.leadSummary}
                lastLeadChangeTimestamp={this.state.lastLeadChangeTimestamp}
            />

                <div className="row padding-30-bottom-full padding-30-bottom-tablet">
                    <div className="wide-format-col">
                        <div className="simpleflex__row">
                            <div className="simpleflex__cell">
                                <h2 className="type-normal-headline type-heavy padding-20-bottom" id="view-header">
                                    Lead Manager
                                </h2>
                            </div>
                            <div className="simpleflex__cell ui-hide-mobile">
                                <p className="type-large-body type-single-line type-align-right position__bottom-attached position__right-attached">
                                    <span className="type-no-break">
                                        <a href={AuthService.isMarketplace
                                            ? "https://support.servicedirect.com/lead-volume"
                                            : "https://support.servicedirect.com/select/lead-volume"}
                                           className="type-heavy" target="_blank">
                                            &nbsp; Want More Leads?
                                        </a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            <div className="row">
                <div className="wide-format-col page__contentbox page__contentbox__invisible__mobile">
                    <div className="ui-hide-full ui-hide-tablet">
                        <div className="leadlog__mobile-filter-card">
                            <div className="spacing-10-bottom">
                                <span role="button"
                                    className="type-small-body type-right-side-bump mobile-filter-link type-heavy"
                                    onClick={() => this.openFilterModal()}>
                                    { IconFilterSvg }
                                    Filter
                                </span>
                                <span role="button"
                                    className="type-small-body type-right-side-bump mobile-filter-link type-heavy"
                                      onClick={() => this.openFilterModal("search")}>
                                    { IconSearchSvg }
                                    Search
                                </span>
                            </div>
                            <div>
                                <span role="button"
                                    className="type-small-body type-right-side-bump mobile-filter-link type-heavy type-blue"
                                    onClick={() => this.resetFilter()}
                                >
                                    { DefaultResetSvg }
                                    View All Billable Leads
                                </span>
                            </div>
                            <div className="type-normal-subhead spacing-32-top spacing-20-bottom">
                                {filterMessage(this.state)}
                            </div>
                            {Object.keys(this.state.leadFilteredSummary).length > 0 &&
                                <div className="type-normal-body spacing-20-top spacing-10-top-mobile-important spacing-32-bottom spacing-10-bottom-mobile-important">
                                    <span className="lead-filters__view-performance-stats lead-filters__performance-stats-mobile">
                                        <span role="button" className="type-heavy type-blue"
                                              onClick={ this.openFilteredLeadsStatsModal }
                                        >
                                            <span className="inline-icon inline-icon__middle inline-icon__24">{ IconStatsSvg }</span>{' '}
                                            View Performance
                                        </span>{' '}
                                        for these Leads
                                        <span className="type-flag type-flag--beta type-flag__beta-mobile">BETA</span>
                                    </span>
                                </div>}
                        </div>
                    </div>
                    <div className="ui-hide-mobile">
                        <div className="simpleflex__row no-margin-top spacing-50-bottom">
                            <div className="simpleflex__cell simpleflex__cell__one-half">
                                <div className="type-normal-subhead type-narrow-line-height no-margin-top spacing-18-bottom">
                                    {filterMessage(this.state)}
                                </div>
                                <div className="type-normal-body type-narrow-line-height">
                                    {process.env.REACT_APP_ENABLE_LM_RESULTS_PER_PAGE ?
                                    <span className="type-no-break">
                                        <span className="type-right-side-small-bump">Display per page:</span>
                                        <span className="type-right-side-small-bump">
                                            {(this.state.pagination.rows_per_page === 25) ? (
                                                <span className="type-heavy">25</span>
                                            ) : (
                                                <span
                                                    role="button"
                                                    className="type-heavy"
                                                    onClick={ () => this.setRowsPerPage(25) }
                                                >
                                                    25
                                                </span>
                                            )}
                                        </span>
                                        <span className="type-right-side-small-bump">
                                            {(this.state.pagination.rows_per_page === 50) ? (
                                                <span className="type-heavy">50</span>
                                            ) : (
                                                <span
                                                    role="button"
                                                    className="type-heavy"
                                                    onClick={ () => this.setRowsPerPage(50) }
                                                >
                                                    50
                                                </span>
                                            )}
                                        </span>
                                        <span>
                                            {(this.state.pagination.rows_per_page === 100) ? (
                                                <span className="type-heavy">100</span>
                                            ) : (
                                                <span
                                                    role="button"
                                                    className="type-heavy"
                                                    onClick={ () => this.setRowsPerPage(100) }
                                                >
                                                    100
                                                </span>
                                            )}
                                        </span>
                                    </span>
                                    : ''}

                                    {Object.keys(this.state.leadFilteredSummary).length > 0 &&
                                        <span className="lead-filters__view-performance-stats">
                                            <span role="button" className="type-heavy type-blue type-normal-body"
                                                  onClick={ this.openFilteredLeadsStatsModal }
                                            >
                                                <span className="inline-icon inline-icon__middle inline-icon__24">{ IconStatsSvg }</span>{' '}
                                                View Performance Metrics
                                            </span>{' '}
                                            for these Leads
                                            <span className="type-flag type-flag--beta">BETA</span>
                                        </span>}
                                </div>
                            </div>
                            <div className="simpleflex__cell type-align-right">
                                <div className="type-large-body type-narrow-line-height no-margin-top spacing-10-bottom">
                                    Quick Lead Filters:{' '}
                                    {/* <span role="button" className="type-heavy type-no-break type-blue"
                                       onClick={ () => this.quickFilter({needs_follow_up: 1}) }
                                    >
                                        Follow-Up
                                    </span> */}
                                    <Button
                                        variant="span"
                                        onClick={ () => this.quickFilter({needs_follow_up: 1}) }
                                        disabled={this.state.loadingResults}
                                    >
                                        <span className="type-normal-body type-no-break type-heavy">
                                            Follow-Up
                                        </span>
                                    </Button>
                                    <span className="type-grey-2">•</span>{' '}
                                    {/* <span role="button" className="type-heavy type-no-break type-blue"
                                       onClick={ () => this.quickFilter({starred: 1}) }
                                    >
                                        Starred
                                    </span> */}
                                    <Button
                                        variant="span"
                                        onClick={ () => this.quickFilter({starred: 1}) }
                                        disabled={this.state.loadingResults}
                                    >
                                        <span className="type-normal-body type-no-break type-heavy">
                                            Starred
                                        </span>
                                    </Button>
                                    <span className="type-grey-2">•</span>
                                    {' '}
                                    {/* <span role="button" className="type-heavy type-no-break type-blue"
                                       onClick={() => this.quickFilter({
                                           managed: 0,
                                           date_range_id: 11
                                       })}
                                    >
                                        Unmanaged
                                    </span> */}
                                    <Button
                                        variant="span"
                                        onClick={ () => this.quickFilter({
                                            managed: 0,
                                            date_range_id: 11
                                        }) }
                                        disabled={this.state.loadingResults}
                                    >
                                        <span className="type-normal-body type-no-break type-heavy">
                                            Unmanaged
                                        </span>
                                    </Button>
                                    {' '}
                                    <span className="type-grey-2">•</span>{' '}
                                    {/* <span role="button" className="type-heavy type-no-break type-blue"
                                       onClick={ () => this.quickFilter({
                                           managed: 1,
                                           date_range_id: 11
                                       }) }
                                    >
                                        Partially Managed
                                    </span> */}
                                    <Button
                                        variant="span"
                                        onClick={ () => this.quickFilter({
                                            managed: 1,
                                            date_range_id: 11
                                        }) }
                                        disabled={this.state.loadingResults}
                                    >
                                        <span className="type-normal-body type-no-break type-heavy">
                                            Partially Managed
                                        </span>
                                    </Button>
                                    {' '}
                                    <span className="type-grey-2">•</span>{' '}
                                    {/* <span role="button" className="type-heavy type-no-break type-blue"
                                       onClick={ () => this.quickFilter({
                                           managed: 2,
                                           date_range_id: 11
                                       }) }
                                    >
                                        Fully Managed
                                    </span> */}
                                    <Button
                                        variant="span"
                                        onClick={ () => this.quickFilter({
                                            managed: 2,
                                            date_range_id: 11
                                        }) }
                                        disabled={this.state.loadingResults}
                                    >
                                        <span className="type-normal-body type-no-break type-heavy">
                                            Fully Managed
                                        </span>
                                    </Button>
                                    {' '}
                                    <span className="type-grey-2">•</span>{' '}
                                    {/* <span role="button" className="type-heavy type-no-break type-blue"
                                       onClick={ () => this.quickFilter({is_valid: 'null'}) }
                                    >
                                        All
                                    </span> */}
                                    <Button
                                        variant="span"
                                        onClick={ () => this.quickFilter({is_valid: 'null'}) }
                                        disabled={this.state.loadingResults}
                                    >
                                        <span className="type-normal-body type-no-break type-heavy">
                                            All
                                        </span>
                                    </Button>

                                </div>
                                <div>
                                    <Button
                                        variant="span"
                                        onClick={ () => this.applyFilters({billable_select_list_value: 'billable', is_valid: 1}) }
                                        disabled={this.state.loadingResults}
                                    >
                                        <span className="type-normal-body type-right-side-bump mobile-filter-link type-heavy">
                                            View Billable Leads Only
                                        </span>
                                    </Button>
                                    {/* <span role="button"
                                        className="type-normal-body type-right-side-bump mobile-filter-link type-heavy type-blue"
                                        onClick={ () => this.applyFilters({billable_select_list_value: 'billable', is_valid: 1}) }
                                    >
                                        View Billable Leads Only
                                    </span> */}
                                    <button
                                        className="button ui-small-button"
                                        onClick={() => this.openFilterModal("search")}
                                        disabled={this.state.loadingResults}
                                    >
                                        { IconSearchSvg }
                                        Search
                                    </button>{' '}
                                    <button
                                        className="button ui-small-button qa-filter-button"
                                        onClick={() => this.openFilterModal()}
                                        disabled={this.state.loadingResults}
                                    >
                                        { IconFilterSvg }
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.loadingResults
                        ? <LoadingGif backgroundColor='#fff'/>
                        : <LeadLog
                            leads={ this.state.leads }
                            updateLead={this.updateLead}
                            getLead={this.getLead}
                            industryRevenueAverages={ this.state.industryRevenueAverages }
                            updateModalContent={this.updateModalContent}
                            updateMessageBlocks={this.props.updateMessageBlocks}
                            lastLeadChangeTimestamp={this.state.lastLeadChangeTimestamp}
                            isParentContractor={this.isParentContractor()}
                            users={this.state.users}
                        />}

                    { /* Full Desktop */ }
                    <div className="spacing-34-top ui-hide-mobile ui-hide-tablet">
                        <div className="simpleflex__row">
                            <div className="simpleflex__cell simpleflex__cell__middlealigned">
                                <ExportLeadsButton totalRows={this.state.pagination.total_rows} apiQueryStr={this.apiQueryStr}/>
                            </div>
                            <div className="simpleflex__cell simpleflex__cell__middlealigned simpleflex__cell__one-half">
                                <Pagination
                                    pageNum={this.state.pagination.page_num}
                                    totalRows={this.state.pagination.total_rows}
                                    rowsPerPage={this.state.pagination.rows_per_page}
                                    totalPages={this.state.pagination.total_pages}
                                    handlePageChange={ this.handlePageChange }
                                >
                                    <a role="button" href="#view-header"
                                          className="type-normal-body type-right-side-bump mobile-filter-link type-heavy type-blue"
                                          onClick={ () => this.resetFilter() }
                                    >
                                        View All Leads
                                    </a>
                                </Pagination>
                            </div>
                        </div>
                    </div>
                    { /* Tablet */ }
                    <div className="spacing-34-top spacing-left ui-hide-mobile ui-hide-full">
                        <div className="simpleflex__row">
                            <div className="simpleflex__cell simpleflex__cell__middlealigned simpleflex__cell__one-half">
                                <ExportLeadsButton totalRows={this.state.pagination.total_rows} apiQueryStr={this.apiQueryStr}/>
                            </div>
                            <div className="simpleflex__cell simpleflex__cell__middlealigned">
                                <Pagination
                                    pageNum={this.state.pagination.page_num}
                                    totalRows={this.state.pagination.total_rows}
                                    rowsPerPage={this.state.pagination.rows_per_page}
                                    totalPages={this.state.pagination.total_pages}
                                    handlePageChange={ this.handlePageChange }
                                >
                                    <a role="button" href="#view-header"
                                          className="type-normal-body type-right-side-bump mobile-filter-link type-heavy type-blue"
                                          onClick={() => this.resetFilter()}
                                    >
                                        View All Billable Leads
                                    </a>
                                </Pagination>
                            </div>
                        </div>
                    </div>
                    { /* Mobile */ }
                    <div className="spacing-34-top ui-hide-full ui-hide-tablet">
                        <Pagination
                            pageNum={this.state.pagination.page_num}
                            totalRows={this.state.pagination.total_rows}
                            rowsPerPage={this.state.pagination.rows_per_page}
                            totalPages={this.state.pagination.total_pages}
                            handlePageChange={ this.handlePageChange }
                        >
                            <a role="button" href="#view-header"
                                  className="type-normal-body type-right-side-bump mobile-filter-link type-heavy type-blue"
                                  onClick={() => this.resetFilter()}
                            >
                                View All Billable Leads
                            </a>
                        </Pagination>
                    </div>
                    <div className="spacing-34-top ui-hide-full ui-hide-tablet">
                        <p className="type-large-body type-centered">
                            <ExportLeadsButton totalRows={this.state.pagination.total_rows} apiQueryStr={this.apiQueryStr}/>
                        </p>
                    </div>
                    <div className="spacing-34-top ui-hide-full ui-hide-tablet">
                        <p className="type-large-body type-centered">
                            <b>Want More Leads?</b> Increase the Cost Per Lead (CPL) in your{' '}
                            <a className="type-heavy" href="/campaigns">Campaign Manager</a>
                        </p>
                    </div>

                    { /* All Viewports */}
                    <div className="spacing-30-top">
                        <div className="lead-filters__footer-stats">
                            <div className="type-large-body type-heavy type-centered">
                                Performance Metrics for Currently Filtered Leads
                            </div>
                            <div className="spacing-30 lead-filters__footer-stats__grid">
                                <div className="lead-filters__footer-stats__grid-cell">
                                    <div className="type-large-subhead type-orange type-heavy type-single-line">
                                        {this.state.leadFilteredSummary.billableLeadsCount === undefined
                                            ? <LoadingGif size='small' color='gray' />
                                            : formatNumber(this.state.leadFilteredSummary.billableLeadsCount, 0)}
                                    </div>
                                    <div className="type-normal-body type-narrow-line-height">
                                        Billable Leads
                                    </div>
                                </div>
                                <div className="lead-filters__footer-stats__grid-cell">
                                    <div className="type-large-subhead type-orange type-heavy type-single-line">
                                        {this.state.leadFilteredSummary.billableLeadsCost === undefined
                                            ? <LoadingGif size='small' color='gray' />
                                            : formatCurrency(this.state.leadFilteredSummary.billableLeadsCost, 0)}
                                    </div>
                                    <div className="type-normal-body type-narrow-line-height">
                                        Cost of Billable Leads
                                    </div>
                                </div>
                                <div className="lead-filters__footer-stats__grid-cell">
                                    <div className="type-large-subhead type-orange type-heavy type-single-line">
                                        {this.state.leadFilteredSummary.leadsWithRevenueRevenue === undefined
                                            ? <LoadingGif size='small' color='gray' />
                                            : formatCurrency(this.state.leadFilteredSummary.leadsWithRevenueRevenue, 0)}
                                    </div>
                                    <div className="type-normal-body type-narrow-line-height">
                                        Leads Marked with Revenue
                                    </div>
                                </div>
                                <div className="lead-filters__footer-stats__grid-cell">
                                    <LeadSummaryCell
                                        statContent={
                                            this.state.leadFilteredSummary.roi === undefined
                                                ? <LoadingGif size='small' color='gray' />
                                                : (minConfidence > this.state.leadFilteredSummary.revenueConfidence
                                                    ? IconLockSvg
                                                    : (this.state.leadFilteredSummary.roi > 0
                                                        ? `${formatNumber(this.state.leadFilteredSummary.roi, 0)}%`
                                                        : 'N/A')
                                                )
                                        }
                                        statType={minConfidence > this.state.leadFilteredSummary.revenueConfidence ? "icon" : ""}
                                        statIconSizeClass="inline-icon__22"
                                        statTypeClassString="type-large-subhead type-orange type-heavy type-single-line"
                                        bodyTypeSizeClass="type-normal-body"
                                        statDescription="ROI of Managed Leads"
                                        color={minConfidence > this.state.leadFilteredSummary.revenueConfidence ? "gray" : "orange"}
                                        confidence={ this.state.leadFilteredSummary.revenueConfidence }
                                        width={null}
                                        gutter={null}
                                        toolTipTitle="ROI of Managed Leads"
                                        toolTipBody={<>
                                            <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                                This metric is an estimate of your Return On Investment of Managed Leads,
                                                and is calculated based on the Job Revenue you track in Lead Manager for jobs you have won.
                                            </span>
                                            <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                                This metric is <span className="type-heavy">Locked</span> until you have tracked enough Job Revenue data for
                                                Leads with Job Progress set as Won. Once unlocked, the <span className="type-heavy">Confidence Percentage</span>{' '}
                                                                        reflects how confident we are in the accuracy of this metric. The more
                                                Leads you Manage, the more accurate these metrics become.
                                            </span>
                                            <span className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                                <i>Note: A Billable Lead is considered "Managed" when Lead Progress has been set.</i>
                                            </span>
                                                <span
                                                    className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom"
                                                >
                                                Learn more about{''}
                                                <a className="type-heavy" target="_blank"
                                                   href={AuthService.isMarketplace
                                                       ? 'https://support.servicedirect.com/lead-performance-metrics'
                                                       : 'https://support.servicedirect.com/select/lead-performance-metrics'}> Understanding Lead Performance Metrics.
                                                </a>
                                            </span>
                                        </>}
                                        toolTipPosition="top"
                                        modalContainerRef={this.tooltipRef}
                                    />
                                </div>
                            </div>
                            {Object.keys(this.state.leadFilteredSummary).length > 0 &&
                                <div className="footer-lead-filters__view-performance-stats">
                                    <span role="button" className="type-normal-body type-heavy type-blue"
                                          onClick={ this.openFilteredLeadsStatsModal }
                                    >
                                        <span className="inline-icon inline-icon__middle inline-icon__24">{ IconStatsSvg }</span>{' '}
                                        View All Performance Metrics
                                    </span>{' '}
                                    for these Leads
                                    <span className="type-flag type-flag--beta">BETA</span>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
	}
}

LeadManagerView.propTypes = {
    secondaryNavRef: PropTypes.object,
    updateMessageBlocks: PropTypes.func,
    whiteLabelPartnership: PropTypes.object
};
