import React from 'react';

import {formatNumber} from "../../../../Util";

/**
 * This class handles rendering our Pagination component.  Here are the cases
 * it determines:
 *
 * 1. If there is <= 1 page, don't render anything.
 * 2. If there are < 8 pages, output all page blocks w/out ellipsis.
 * 3. If there are >= 8 pages, output numbers in this format:
 *          < [1][2][3]...[15] >
 */
const Pagination = (props) => {
    /**
     * Returns left arrow JSX based on pageNum.  If page 1 is the active
     * page, returns a disabled element, otherwise an active link.
     */
    const leftArrowJsx = (pageNum) => {

        if (pageNum === 1) {
            return (
                <div key="pagination-left-arrow" className="pagination__arrow pagination__arrow__left">
                    <span className="pagination__label">‹</span>
                </div>
            );
        }

        return (
            <span key="pagination-left-arrow" role="button"
                  className="pagination__arrow pagination__arrow__left type-blue"
                  onClick={(e) => props.handlePageChange(e, pageNum - 1)}
            >
                <span className="pagination__label">‹</span>
            </span>
        );
    };

    /**
     * Same as leftArrowJsx, but for the right side of the pagination widget.
     */
    const rightArrowJsx = (pageNum, totalPages) => {

        if (pageNum === totalPages) {
            return (
                <div key="pagination-right-arrow"
                     className="pagination__arrow pagination__arrow__right">
                    <span className="pagination__label">›</span>
                </div>
            );
        }

        return (
            <span role="button" key="pagination-right-arrow"
                  className="pagination__arrow pagination__arrow__right"
                  onClick={(e) => props.handlePageChange(e, pageNum + 1)}
            >
                <span className="pagination__label">›</span>
            </span>
        );
    };

    let pageNum = parseInt(props.pageNum, 10),
        rowsPerPage = parseInt(props.rowsPerPage, 10),
        totalRows = parseInt(props.totalRows, 10),
        totalPages = Math.ceil(totalRows / rowsPerPage),
        pagesJsx = [];

    if (1 < totalPages) {

        if (totalPages <= 7) {
            pagesJsx.push(leftArrowJsx(pageNum));

            for (let i = 1; i <= totalPages; i++) {

                let activeClass = pageNum === i
                    ? "pagination__cell__active"
                    : "";

                if (pageNum === i) {
                    pagesJsx.push(
                        <div
                            className={"pagination__cell v1_0_1 " + activeClass}
                            key={"pagination-" + i}
                            onClick={(e) => props.handlePageChange(e, i)}
                        >
                            <span className="pagination__label">{formatNumber(i, 0)}</span>
                        </div>
                    );
                } else {
                    pagesJsx.push(
                        <span role="button" className="pagination__cell v1_0_1 "
                              key={"pagination-" + i}
                              onClick={(e) => props.handlePageChange(e, i)}
                        >
                        <span className="pagination__label">{formatNumber(i, 0)}</span>
                    </span>
                    );
                }
            }

            pagesJsx.push(rightArrowJsx(pageNum, totalPages));
        } else {
            //
            // In this block we have more than 8 pages, and render pagination in
            // this way:  <  [1][...][8][9][*10*][11][12][...][last page num]>
            // ... where [*10*] is the current page.
            //
            // That plays out in these steps 6 steps
            //

            //
            // 1. Determine two pages left and right of the current page.
            //
            let twoPagesLeft = (pageNum - 2) < 1 ? 1 : (pageNum - 2);
            let twoPagesRight = (pageNum + 2) > totalPages ? totalPages : (pageNum + 2);

            //
            // 2. Always render the left arrow and page 1.
            //
            pagesJsx.push(leftArrowJsx(pageNum));

            if (twoPagesLeft > 1) {
                pagesJsx.push(
                    <span role="button"
                          className="pagination__cell v1_0_1 "
                          key={"pagination-1"}
                          onClick={(e) => {
                              props.handlePageChange(e, 1)
                          }}
                    >
                    <span className="pagination__label">{1}</span>
                </span>
                );
            }

            //
            // 3. If twoPagesLeft = 3, render a page 2 block.  But if it's
            // greater than that, render an ellipsis instead.
            //
            if (twoPagesLeft === 3) {
                pagesJsx.push(
                    <span role="button"
                          className="pagination__cell v1_0_1 "
                          key={"pagination-2"}
                          onClick={(e) => {
                              props.handlePageChange(e, 2)
                          }}
                    >
                    <span className="pagination__label">{2}</span>
                </span>
                );
            } else if (twoPagesLeft > 3) {
                pagesJsx.push(
                    <div key="pagination-left-ellipses"
                         className="pagination__cell">
                        <span className="pagination__label">...</span>
                    </div>
                );
            }

            //
            // 4. Now include the middle group of 5 pages.  E.g. in the example
            //      above,  this is where pages 8-12 are included.
            //
            for (let i = twoPagesLeft; i <= twoPagesRight; i++) {

                let activeClass = pageNum === i
                    ? "pagination__cell__active"
                    : "";

                if (pageNum === i) {
                    pagesJsx.push(
                        <div
                            className={"pagination__cell v1_0_1 " + activeClass}
                            key={"pagination-" + i}
                            onClick={(e) => props.handlePageChange(e, i)}
                        >
                            <span className="pagination__label">{formatNumber(i, 0)}</span>
                        </div>
                    );
                } else {
                    pagesJsx.push(
                        <span role="button"
                              className="pagination__cell v1_0_1 "
                              key={"pagination-" + i}
                              onClick={(e) => props.handlePageChange(e, i)}
                        >
                        <span className="pagination__label">{formatNumber(i, 0)}</span>
                    </span>
                    );
                }
            }

            //
            // 4. Now render the [...][last page num] page blocks; logic here
            // corresponds to that used when rendering page 1 and [...] on the left
            // side of the pagination bar.
            //
            if (twoPagesRight === (totalPages - 2)) {
                pagesJsx.push(
                    <span role="button"
                          className="pagination__cell v1_0_1 "
                          key={"pagination-" + (totalPages - 1)}
                          onClick={(e) => {
                              props.handlePageChange(e, totalPages - 1)
                          }}
                    >
                    <span className="pagination__label">{formatNumber(totalPages - 1, 0)}</span>
                </span>
                );
            } else if (twoPagesRight < totalPages - 2) {
                pagesJsx.push(
                    <div key="pagination-right-ellipses"
                         className="pagination__cell">
                        <span className="pagination__label">...</span>
                    </div>
                );
            }

            if (twoPagesRight < totalPages) {
                pagesJsx.push(
                    <span role="button"
                          className="pagination__cell v1_0_1 "
                          key={"pagination-" + totalPages}
                          onClick={(e) => {
                              props.handlePageChange(e, totalPages)
                          }}
                    >
                    <span className="pagination__label">{formatNumber(totalPages, 0)}</span>
                </span>
                );
            }

            pagesJsx.push(rightArrowJsx(pageNum, totalPages));
        }
    }

    //
    // props.children included so that e.g. the bottom "View all Billable Leads" link can be dropped in
    //
    return <div className="simpleflex__row">
        <div
            className="simpleflex__row simpleflex__cell simpleflex__cell__maxed ui-hide-mobile simpleflex__row__centeraligned simpleflex__row__rightstacked simpleflex__row__centeraligned-mobile">
            {props.children}
        </div>
        {pagesJsx.length > 0 &&
        <div className="simpleflex__cell">
            <div className="pagination">
                {pagesJsx}
            </div>
        </div>}
    </div>;
};

export default Pagination;
