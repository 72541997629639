import styled from 'styled-components'

export const StyledLink = styled.a`
    width: 100%;
    padding: 0.8rem 0.9rem;
    cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
    color: ${(props) => props.disabled ? '#adc3db' : ''};

    &:hover {
        background-color: ${(props) => props.disabled ? '' : '#006ea1'};
        color: ${(props) => props.disabled ? '#adc3db' : 'white'};
    };
`

export const StyledAnchor = styled.span`
    float: right;
    
    height: 1.290322581rem;
    max-height: 1.290322581rem;
    width: 1.290322581rem;
    
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin-right: 0.2em;
    vertical-align: baseline;
    
    svg path {
      fill: #0094d9;
    }
    
    ${StyledLink}:hover & svg path {
      fill: white;
    }
`

export const StyledAction = styled.span`
    width: 100%;
    padding: 0.8rem 0.9rem;
    cursor: ${(props) => props.disabled ? 'not-allowed' : 'cursor'};
    color: ${(props) => props.disabled ? '#adc3db' : '#0094d9'};
    &:hover {
        background-color: ${(props) => props.disabled ? '' : '#006ea1'};
        color: ${(props) => props.disabled ? '' : 'white'};
    };
`

export const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    min-width: 215px;
`

export const MenuItem = styled.div`
    display: flex;
    cursor: pointer;
`

export const MenuOpener = styled.span`
    line-height: 120%;
    cursor: pointer;
    color: #0094d9;
`
