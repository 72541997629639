import React, {Component} from 'react';

export default class SignupLaunchStatusTableCell extends Component {
    render() {
        let header = "signup-" + this.props.header;
        let rowSpan = this.props.rowSpan
            ? this.props.rowSpan
            : 1;

        let className;
        if (this.props.children) {
            className = `left-side type-normal-body type-narrow-line-height signup-status-table__${this.props.header}`;
            if (this.props.className) {
                className += ' ' + this.props.className;
            }
        }
        else {
            className = "signup-status-table__empty"
        }

        // the client name cell is a special
        // case where the element should be a th
        // instead of the normal td
        if (this.props.isClientName) {
            return <th headers={header}
                       rowSpan={rowSpan}
                       scope="row"
                       className={className}>
                {this.props.children}
            </th>;
        }

        return <td headers={header}
                   rowSpan={rowSpan}
                   className={className}>
            {this.props.children}
        </td>;
    }
}
