import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import PropTypes from "prop-types";

import { getScrollY } from '../common/FloatingHeader';
import ProgressBar from './progress-bar';
import {errorHandler} from '../Requests';
import StepOne from './step-one';
import StepTwo from './step-two';
import StepConfirmation from './step-confirmation';
import Modal from "../common/Modal";
import StepThree from "./step-three";
import StepFour from "./step-four";
import StepFive from "./step-five";
import StepRedirect from "./step-redirect";
import SignupFooter from "./signup-footer";
import PromoCodesService from '../service/PromoCodesService';
import AuthService from "../service/AuthService";
import TokenService from "../service/TokenService";

const handleFloatingHeader = (header, subheader) => {
    if (!header) {
        return;
    }
    
    const thisScrollTop = getScrollY() >= 0
        ? getScrollY()
        : 0;
    const lastScrollTop = document.body.getAttribute('data-last-scroll-top');
    
    if ((thisScrollTop < lastScrollTop) &&
        (thisScrollTop > 0) &&
        (lastScrollTop > header.offsetHeight)) {
        header.classList.remove('scrolled');
        if (subheader) {
            subheader.classList.remove('scrolled');
        }
    }
    else if (thisScrollTop > lastScrollTop) {
        header.classList.add('scrolled');
        if (subheader) {
            subheader.classList.add('scrolled');
        }
    }
    else if (thisScrollTop === 0) {
        header.classList.remove('scrolled');
        if (subheader) {
            subheader.classList.remove('scrolled');
        }
    }
    
    document.body.setAttribute('data-last-scroll-top', thisScrollTop);
}

export default class SignupMarketplaceView extends Component {

    cta_phone = '(512) 233-6446';

    constructor(props) {
        super(props);

        this.contractorId = AuthService.getItem('contractorId');
        this.state = {
            modalContent: '',
            modalHeader: '',
            promoCodes: [],
        };
        
        this.headerRef = React.createRef();
        this.subheaderRef = React.createRef();
        
        this.tokenService = new TokenService();
        this.promoCodesService = new PromoCodesService();
    }

    componentDidMount() {
        this.getPromoCodes();
        this.savePromoCodeFromQueryString();
        
        document.body.setAttribute('data-last-scroll-top', getScrollY());
    
        window.addEventListener(
            'scroll',
            () =>
                handleFloatingHeader(this.headerRef.current, this.subheaderRef.current)
        );
        setInterval(
            () =>
                handleFloatingHeader(this.headerRef.current, this.subheaderRef.current),
            500
        );
    }
    
    componentWillUnmount() {
        this.tokenService.cancelSignal.cancel();
        this.promoCodesService.cancelSignal.cancel();
    }
    
    getPromoCodes = () => {
        this.promoCodesService.getPromoCodes()
            .then(({ promoCodes }) => {
                this.setState({ promoCodes: promoCodes.map(obj => {
                    return {
                        code: obj.promoCode,
                        credit: obj.creditValue,
						industry: obj.industryId,
                    };
                }) });
            });
    };
    
    savePromoCodeFromQueryString = () => {
        const searchParams = new URLSearchParams(document.location.search);
        
        for (const [key, value] of searchParams) {
            if (key === 'promo_code') {
                localStorage.setItem("mpSignupPromoCode", value);
            }
        }
    };

    /**
     * Logs user out
     */
    logout = () => {
        if (AuthService.token) {
            this.tokenService.logout()
                .catch((error) => {
                    if (errorHandler(error)) {
                        console.log(
                            'Caught an exception when posting to "auth/logout" in' +
                            ' <SignupMarketplaceView> component, error =', error
                        );
                    }
                });
        }
        else {
            AuthService.clear();
            window.location = "/";
        }
    };

    /**
     * @param {Component} content
     * @param {string} header
     * @param {boolean} hideCloseButton
     */
    updateModalContent = (content: Component, header: string, hideCloseButton: boolean = false) => {
        this.setState({modalContent: content, modalHeader: header, modalHideCloseButton: hideCloseButton});
    };

    render() {
        let displayLogoutButton = !this.props.location.pathname.includes("/step-one");
        let displayPhoneCta = !this.props.location.pathname.includes("/step-one") &&
            !this.props.location.pathname.includes("/step-confirmation");
            
        return (
            <div
                id="signup-marketplace"
                className={displayPhoneCta ? 'show-phone-cta' : null}
            >
                {this.props.children}
                <Modal
                    content={this.state.modalContent}
                    header={this.state.modalHeader}
                    updateModalContent={this.updateModalContent}
                    hideCloseButton={this.state.modalHideCloseButton}
                />
                <header
                    ref={this.headerRef}
                >
                    <div className="header-container">
                        {displayPhoneCta &&
                        <div className="marketplace-signup-cta-header type-large-body">
                            <div>
                                <span className="type-heavy">Need Help? Call </span>
                                <a href={'tel:' + this.cta_phone} target="_blank"
                                   className="type-heavy">
                                    {this.cta_phone}
                                </a>
                            </div>
                        </div>}
                        <div
                            ref={this.subheaderRef}
                            className="page-width header-contents padding-20"
                        >
                            <div className="simpleflex__row">
                                <div className="header__logo">
                                    <img src={this.props.whiteLabelPartnership.logo_uri}
                                         alt={this.props.whiteLabelPartnership.white_label_name}/>
                                </div>
                                {displayPhoneCta &&
                                <div className="simpleflex__cell simpleflex__cell__middlealigned">
                                    <div className="type-normal-body type-single-line type-align-right">
                                        <div className="no-mobile-view-visible">
                                            <span className="type-heavy type-allcaps type-right-side-bump">Need Help Signing Up?</span>
                                            {' '}
                                            <a href={'tel:' + this.cta_phone} target="_blank"
                                               className="type-heavy button button__orange ui-normal-button">
                                                {this.cta_phone}
                                            </a>
                                        </div>
                                    </div>
                                </div>}
                                {displayLogoutButton &&
                                <div className="header__button-wrap">
                                    <div onClick={() => this.logout()}
                                         className="button button__outline ui-normal-button">
                                        Logout
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </header>
                <div className="interface-container">
                    <section className="grey-background">
                        <div className="page-width">
                            <div className="row padding-50-bottom">
                                <div className="col alpha grid-2 empty"/>
                                <div className="col grid-8 no-margin-mobile">
                                    <div className="module">
                                        <ProgressBar location={this.props.location.pathname}/>
                                        {!this.props.auth
                                            ? <Switch>
                                                <Route path="/signup-marketplace/step-one">
                                                    <StepOne {...this.props}
                                                             updateModalContent={this.updateModalContent}
                                                             whiteLabelPartnership={this.props.whiteLabelPartnership}/>
                                                </Route>
                                                <Route path="/signup-marketplace/step-confirmation">
                                                    <StepConfirmation {...this.props} />
                                                </Route>
                                                <Redirect to="/login"/>
                                            </Switch>
                                            : <Switch>
                                                {/* the confirmation step can come in both formats - authenticated or not */}
                                                <Route path="/signup-marketplace/step-confirmation">
                                                    <StepConfirmation {...this.props} />
                                                </Route>
                                                <Route path="/signup-marketplace/step-two">
                                                    <StepTwo {...this.props} />
                                                </Route>
                                                <Route path="/signup-marketplace/step-three">
                                                    <StepThree {...this.props}
															   promoCodes={this.state.promoCodes}
                                                               updateModalContent={this.updateModalContent}
                                                               whiteLabelPartnership={this.props.whiteLabelPartnership}/>
                                                </Route>
                                                <Route path="/signup-marketplace/step-four">
                                                    <StepFour {...this.props}
                                                              promoCodes={this.state.promoCodes}
                                                              updateModalContent={this.updateModalContent}/>
                                                </Route>
                                                <Route path="/signup-marketplace/step-five">
                                                    <StepFive {...this.props}
                                                              updateModalContent={this.updateModalContent}/>
                                                </Route>
                                                {/* if no specific path is specified, find out the user step and redirect to it */}
                                                <Route><StepRedirect/></Route>
                                            </Switch>}
                                    </div>
                                </div>
                                <div className="col omega grid-2 empty"/>
                                <div className="clear-block"/>
                            </div>
                        </div>
                    </section>
                    <SignupFooter
                        {...this.props}
                        updateModalContent={this.updateModalContent}
                    />
                </div>
            </div>
        );
    }
}

SignupMarketplaceView.propTypes = {
    auth: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
};
