import React from 'react';
import PropTypes from 'prop-types';

const ChooseClientType = (props) => {
    const selectType = (clientType) => {
        window.scrollTo(0, 0);
        props.onTypeSelect(clientType);
    };

    return <section className="white-background">
        <div className="page-width">
            <div className="row padding-50-top padding-20-bottom">
                <div className="col alpha grid-2 empty"/>
                <div className="col grid-8 no-margin-mobile">
                    <div className="module spacing-50-bottom">
                        <div className="module__header module__twothirdswidth">
                            <h3 className="type-large-subhead type-narrow-line-height">
                                Choose Client Type
                            </h3>
                        </div>
                        <div className="module__contentbox module__twothirdswidth">
                            <div className="form__row">
                                <div className="form__cell form__cell__100 spacing-24-bottom-mobile">
                                    <button
                                        className="button ui-normal-button ui-full-width-button-mobile margin-left-right-20pct"
                                        id="submit-button"
                                        onClick={() => selectType('select')}
                                        type="button">
                                        Select
                                    </button>
                                    <button
                                        className="button ui-normal-button ui-full-width-button-mobile margin-left-right-20pct"
                                        id="submit-button"
                                        onClick={() => selectType('marketplace')}
                                        type="button">
                                        Marketplace
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col omega grid-2 empty"/>
                <div className="clear-block"/>
            </div>
        </div>
    </section>;
};

export default ChooseClientType;

ChooseClientType.propTypes = {
    onTypeSelect: PropTypes.func.isRequired
};
