import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import TokenService from '../service/TokenService';
import type {AuthData} from "../service/TokenService";
import AuthService from "../service/AuthService";

export default class Login extends Component {

    emailInput;

    constructor(props) {
        super(props);

        this.state = {
            errors: {
                email: '',
                password: '',
            },
            email: '',
            password: '',
            submitted: false,
            messages: [],
            messageType: "",
        };

        this.tokenService = new TokenService();
    }

    componentDidMount() {
        const credentials = AuthService.getUrlCredentials();

        //
        // Check if we have user data in the URL parameters
        // If so we want to grab it and try to login using them
        //
        if (credentials.assistingAdminUserId) {
            AuthService.assistingAdminUserId = credentials.assistingAdminUserId;
        }

        if (credentials.username && credentials.password) {
            this.submitLogin(null, credentials.username, credentials.password, credentials.vicariousAdminId);
        }
        else
        {
            this.emailInput.focus();
        }

        // handle special messaging from password reset
        if (this.props.location.state) {
            let message = this.props.location.state.message;
            if (message) {
                let stateClone = {...this.state};
                stateClone.messages.push(message);
                stateClone.messageType = "success";
                this.setState(stateClone);
            }
        }
    }

    componentWillUnmount() {
        this.tokenService.cancelSignal.cancel();
    }

    clearMessages = () => this.setState({ messages: [], messageType: "" });

    /**
     * Submits login when button is clicked.
     *
     * Also used for admin login as client. If both getUsername and getPassword
     * are supplied (getPassword being the hash), they will be sent as username
     * and pass_word to automatically log in as that user.
     */
    submitLogin = (event, getUsername, getPassword, vicariousAdminId) => {
        // prevents the default bubbles
        // on the inputs
        if (event) {
            event.preventDefault();
        }

        let emailElem = document.getElementById("login-email");
        let passwordElem = document.getElementById("login-password");

        if (!getUsername || !getPassword) {
            // validate the fields
            let hasError = this.validate(emailElem, passwordElem);
            if (hasError) {
                return;
            }
        }

        // if we don't have an event
        //      then this is triggered by the login-as-client url params
        //      And not from clicking the button
        if (event) {
            if (this.state.submitted) {
                return;
            }
            else {
                this.setState({submitted: true});
            }

            // validate input
            let hasError = this.validate(emailElem, passwordElem);

            if (hasError) {
                return false;
            }
        }
        // get the values from what is passed in from the get vars or...
        // get the values from the inputs
        let username = getUsername || emailElem.value;
        let password = getPassword || passwordElem.value;

        this.tokenService.login(username, password, !!getPassword, vicariousAdminId)
            .then((response:AuthData) => {
                // /**
                //  * Choosing to early return (before 200/401 status checks)
                //  * if we don't have a fully-formed response object.
                //  *
                //  * This early return was introduced (though since modified)
                //  * in commit ed7c903f Mon Nov 26 2018.
                //  *
                //  * ~ RFM 2019-03-05, excavating
                //  */
                // if (typeof response === "undefined" || !('data' in response)) {
                //     return;
                // }

                this.setState({ submitted: false });

                // Update local storage etc, following a successful POST /auth
                this.props.postLogin(response);
            })
            // 4xx / 5xx response etc
            .catch((error) => {
                let errorClone = {...this.state.errors};
                errorClone.password = error.response?.data?.errors[0]?.message;

                this.setState({errors: errorClone});
            });
    };

    /**
     * Validates the user input fields email and password
     */
    validate = (email, password) => {
        let stateClone = { ...this.state };
        // set default states for errors
        let hasError = false;
        stateClone.errors.email = '';
        stateClone.errors.password = '';

        if (email.validity.valueMissing) {
            stateClone.errors.email = "Email is required";
            hasError = true;
        }

        if (email.validity.typeMismatch) {
            stateClone.errors.email = "Email is incorrect format.";
            hasError = true;
        }

        if (password.validity.valueMissing) {
            stateClone.errors.password = "Password is required";
            hasError = true;
        }

        this.setState(stateClone);

        return hasError;
    };

    render() {
        let emailError = this.state.errors.email;
        let passwordError = this.state.errors.password;
        let errorResponse = this.props.errorMessages;

        // if the error comes from back login attempt
        //   We need to catch it in the props
        if (!passwordError) {
            if (this.props.errorMessages.length > 0) {
                if (this.props.errorMessages[0] !== "No api credentials found.") {
                    passwordError = errorResponse[0];
                }
                else {
                    errorResponse = [];
                }
            }
        }

        let hasError = emailError || passwordError;
        let hasLoginErrorResponse = errorResponse.length > 0;
        const ErrorMessages = () => {
            if (hasError) {
                return <div className="login-error">
                    <p className="type-normal-body type-alert type-heavy type-single-line no-margin-top spacing-10-bottom">
                        LOGIN ERROR
                    </p>
                    <p className="type-small-body type-alert type-narrow-line-height spacing-10-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                        <span className='error-message'>
                            { emailError }
                        </span>
                        <span className='error-message'>
                            { passwordError }
                        </span>
                    </p>
                </div>;
            }
            else {
                return <div/>;
            }
        };

        return <div className="login-screen">
            {this.props.children}
            <div className="login__gradient-shade" style={ this.props.loginGradientStyle() } />
            <div className="login__content-column">
                <div className="login__logo-frame spacing-20-bottom">
                    <img src={ this.props.whiteLabelPartnership.logo_uri_for_login_page }
                         alt={ this.props.whiteLabelPartnership.white_label_name }
                    />
                </div>
                <div className="login__content-box">
                    <div className="login__content-header">
                        <h1 className="type-small-headline type-heavy type-single-line no-margin">
                            Welcome back.
                        </h1>
                    </div>
                    <div className="login__content">
                        <ErrorMessages />
                        <form>
                            <div className="no-margin-top spacing-10-bottom">
                                <label
                                    htmlFor="login-email"
                                    className={ "type-small-body type-heavy type-single-line no-margin "
                                    + ((hasError || hasLoginErrorResponse)
                                        ? "type-alert"
                                        : '')}
                                >
                                    Email
                                </label>
                            </div>
                            <div className="spacing-10-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                                <input
                                    type="text"
                                    className={"ui-text-field ui-normal-text-input " + ((hasError || hasLoginErrorResponse) ? "ui-alert" : '')}
                                    name="email" id="login-email"
                                    onChange={ this.handleInputChange }
                                    required
                                    ref={(input) => { this.emailInput = input; }}
                                />
                            </div>

                            <div className="spacing-30-top-full spacing-30-top-tablet spacing-24-top-mobile spacing-10-bottom">
                                <label
                                    htmlFor="login-password"
                                    className={ "type-small-body type-heavy type-single-line no-margin "
                                    + ((hasError || hasLoginErrorResponse)
                                        ? "type-alert"
                                        : '' )}
                                >
                                    Password
                                </label>
                            </div>
                            <div className="spacing-10-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                                <input
                                    type="password"
                                    autoComplete="password"
                                    className={ "ui-text-field ui-normal-text-input " + ((hasError || hasLoginErrorResponse) ? "ui-alert" : "")}
                                    name="password" id="login-password"
                                    onChange={ this.handleInputChange }
                                    required
                                />
                            </div>

                            <div className="popup__form__row popup__form__row__stretched">
                                <div className="popup__form__cell popup__form__cell__hidden__mobile">
                                    <div className="no-margin">
                                        <Link
                                            to="/login-help"
                                            className="type-normal-body type-single-line type-heavy"
                                        >
                                            Need Help?
                                        </Link>
                                    </div>
                                </div>
                                <div className="popup__form__cell popup__form__cell__100__mobile">
                                    <button
                                        disabled={ this.state.submitted }
                                        className="button ui-normal-button ui-full-width-button-mobile"
                                        onClick={ this.submitLogin }
                                        id="login-button"
                                    >
                                        Login
                                    </button>
                                </div>
                            </div>
                            <div className="ui-hide-full ui-hide-tablet spacing-24-top-mobile">
                                <Link
                                    to="/login-help"
                                    className="type-normal-body type-single-line type-heavy">
                                    Need Help?
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
    }
}

Login.propTypes = {
    errorMessages: PropTypes.array,
    updateAppErrorMessages: PropTypes.func
}
