import React from 'react';

import {leadManagerUrl} from "../../../service/LeadService";
import {formatNumber} from "../../../Util";

const LeadsReceivedCounts = (props) => {
    const {campaign} = props;

    // @see dateRangeOptions in StaticValues.js
    const DATE_RANGE_ALL_TIME = 7;
    const DATE_RANGE_LAST_30_DAYS = 8;

    const headlineSection = (count, dateIdForUrl) => {
        const internalSection = <>
            <span className="type-small-headline type-heavy">
                {formatNumber(count, 0)}
            </span>
            {' '}
            <span className="type-large-subhead type-heavy">
                {count == 1 ? "Lead" : "Leads"}
            </span>
        </>;

        // wrap in a link only if there are leads to display
        // NOTE: we wrap the element in a div to prevent the parent flex property from affecting the internal section
        return count == 0
            ? <div>{internalSection}</div>
            : <a href={leadManagerUrl(dateIdForUrl, campaign.campaign_id)}>{internalSection}</a>;
    };

    return <div className="campaigns-table__details-grid__leads-count">
        <div>
            {headlineSection(campaign.count_of_leads, DATE_RANGE_ALL_TIME)}
            <p className="type-large-body type-narrow-line-height">Received All Time</p>
        </div>
        <div>
            {headlineSection(campaign.count_of_leads_last_30_days, DATE_RANGE_LAST_30_DAYS)}
            <p className="type-large-body type-narrow-line-height">Received Last 30 Days</p>
        </div>
    </div>;
};

export default LeadsReceivedCounts;
