import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "../../../Util";
import SkeletonText from "../Skeleton/SkeletonText";
import { Metric } from "../PaginationSentence/styles";

function MetricSentence(props) {
  const { metric = 0, label = "Item", loading } = props;

  if (loading) {
    return <SkeletonText />;
  }

  let labelText = label;
  if (metric !== 1) {
    labelText = `${label}s`;
  }

  return (
    <span>
      <Metric>{formatNumber(metric, 0)}</Metric>{" "}
      <Metric>{labelText}</Metric>
    </span>
  );
}

MetricSentence.propTypes = {
  metric: PropTypes.number,
  label: PropTypes.string,
};

export default MetricSentence;
