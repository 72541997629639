import React from 'react';

const NeedHelpSection = (props) => (
	<div className="need-help-section">
		<p className="type-normal-body type-heavy type-narrow-line-height spacing-12-bottom">
			Need Help?
		</p>
		<p className="type-normal-body type-narrow-line-height">
			Contact us with a{' '}
			<a href="https://share.hsforms.com/1YGbCjxPqQY2-AB4b4h8C1A2fui9?__hstc=75547978.51c582a5723e98597d4047d92e86a83e.1700264684407.1700264684407.1700264684407.1&__hssc=75547978.1.1700264684407&__hsfp=878167773"
			   className="type-heavy">
				Support Request
			</a>
			{' '}or{' '}
			<button className="type-heavy type-blue type-normal-body button-clean"
					onClick={props.cancelSelfServeOnboarding}>
				Schedule an Onboarding Call
			</button>
		</p>
	</div>
);

export default NeedHelpSection;