import React, { Fragment, useEffect, useState } from 'react'
import Button from '../../../../common/components/form_elements/Button'
// import Input from '../../../../common/components/form_elements/Input'
// import MultiSelectList from '../../../../common/components/form_elements/MultiSelectList'
import AddEditRules from '../../service_category_rules/Modals/AddEditRules'
import { ActionsWrapper, Wrapper } from './styles'
import { rulesAreValid } from '../../service_category_rules/Modals/CreateNew/utils'
import {
    // getServiceCategoryRules,
    getServiceCategoryRulesGrouped,
    // getServiceCategoryRuleTypes,
} from '../../../../service/AffiliateService'
import { ContentWindowScroll } from '../../../../common/Modal'

export default (props) => {
    const {
        serviceCategories,
        onSkip,
        onPreview,
        changes,
        affiliate,
        affiliateRules,
        ...rest
    } = props
    const { ad_network_id } = affiliate
    const [ruleChanges, setRuleChanges] = useState(changes)
    const [_serviceCategories, setServiceCategories] = useState(serviceCategories)

    const handleRuleChanges = (changes) => {
        setRuleChanges(changes)
    }

    useEffect(() => {
        if (serviceCategories == null) {
            getServiceCategoryRulesGrouped().then((data) => {
                const result = data?.filters.filter((f) => f.name == 'industry_ids')[0]
                setServiceCategories(result)
            })
        }
    }, [serviceCategories])

    const rulesView =
        ad_network_id && _serviceCategories != null ? (
            <AddEditRules
                affiliate={affiliate}
                serviceCategories={_serviceCategories}
                affiliateId={ad_network_id}
                changes={changes}
                onChange={handleRuleChanges}
                affiliateRules={affiliateRules}
                showBlankRuleByDefault={true}
            />
        ) : null

    const isPreviewEnabled = rulesAreValid(ruleChanges)

    return (
        <Fragment>
            <ContentWindowScroll>
                <Wrapper>{rulesView}</Wrapper>
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button
                    onClick={() => onSkip({ rules: [], affiliate, ...rest })}
                    variant="text"
                >
                    <span className="type-heavy">Skip</span>
                </Button>
                <Button
                    disabled={!isPreviewEnabled}
                    onClick={() => {
                        onPreview({ rules: ruleChanges, affiliate, ...rest })
                    }}
                >
                    {'Continue To Confirmation'}
                </Button>
            </ActionsWrapper>
        </Fragment>
    )
}
