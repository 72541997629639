import React, {Component} from 'react';
import ClientAccountsRow from "./ClientAccountsRow";
import ContractorService from "../../service/ContractorService";
import {errorHandler} from "../../Requests";
import LoadingGif from "../../common/components/LoadingGif";
import MultiSelectList from '../../common/components/form_elements/MultiSelectList';

export default class ClientAccountsView extends Component {
    sortOptions = [
        {value: 'name', text: 'Company Name A-Z'},
        {value: 'campaignCount', text: 'Campaigns Count'}, /* the value of this sort option is created in getClients */
        {value: 'leads_count_last_30', text: 'Leads Count'},
        {value: 'lead_cost_last_30_days', text: 'Leads Cost'}
    ];

    constructor(props) {
        super(props);

        this.state = {
            initiating: true,
            clients: [],
            campaignsCount: 0,
            sortBy: 'name',
            sortReverse: false
        };

        this.leftShadowEdgeRef = React.createRef();
        this.rightShadowEdgeRef = React.createRef();
        this.scrollboxRef = React.createRef();
        this.tableRef = React.createRef();

        this.contractorService = new ContractorService();
    }

    componentDidMount() {
        this.handleShadowEdges();
        window.addEventListener('resize', this.handleShadowEdges);
        this.scrollboxRef.current.addEventListener('scroll', this.debounce(this.handleShadowEdges, 10));

        this.getClients();
    }

    handleShadowEdges = () => {
        // Avoiding a crash here requires a similar fix used in
        // LeadSummary.sizeStatCells()  See that method, or this Asana card, for
        // more details:
        // https://app.asana.com/0/918599342387311/1148318734974761

        if (!this.scrollboxRef.current) {
            return;
        }

        const scrollboxScrollPos = this.scrollboxRef.current.scrollLeft;
        const scrollboxMaxScroll = this.scrollboxRef.current.scrollWidth - this.scrollboxRef.current.clientWidth;
        const scrollboxWidth = this.scrollboxRef.current.offsetWidth;
        const tableWidth = this.tableRef.current.offsetWidth;
        const leftShadow = this.leftShadowEdgeRef.current;
        const rightShadow = this.rightShadowEdgeRef.current;

        if (scrollboxWidth < tableWidth) {
            if (scrollboxScrollPos === 0) {
                leftShadow.style.display = 'none';
            } else {
                leftShadow.style.display = 'block';
            }
            if (scrollboxScrollPos < scrollboxMaxScroll) {
                rightShadow.style.display = 'block';
            } else {
                rightShadow.style.display = 'none';
            }
        } else {
            leftShadow.style.display = 'none';
            rightShadow.style.display = 'none';
        }
    };

    debounce(func, delay) {
        let inDebounce;

        return () => {
            const context = this;
            const args = arguments;
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(context, args), delay);
        };
    }

    /**
     * gets the clients using the API
     * as well as sets the campaignCount to the total number of
     * campaigns among all clients and in each client
     */
    getClients = () => {
        let campaignsCount = 0;

        this.contractorService.getOfParent()
            .then((clients) => {
                clients.forEach(
                    (client) => {
                        /* set each client's campaign count */
                        client.pausedCampaigns = 0;
                        client.enabledCampaigns = 0;
                        client.campaign_industries.forEach(
                            (industry) => {
                                industry.campaignCount = Number(industry.paused_campaigns)
                                    + Number(industry.enabled_campaigns);
                                client.pausedCampaigns += Number(industry.paused_campaigns);
                                client.enabledCampaigns += Number(industry.enabled_campaigns);
                            }
                        );
                        client.campaignCount = client.enabledCampaigns + client.pausedCampaigns;

                        /* update the total campaign count */
                        campaignsCount += client.pausedCampaigns + client.enabledCampaigns;
                    }
                );

                this.setState({campaignsCount, initiating: false});
                this.applySort(this.state.sortBy, clients);
            })
            .catch(errorHandler);
    };

    /**
     * sorts the clients by a given field name
     * @param {string} sortBy - the field to sort by
     * @param {array} [clients] - an optional array of clients to sort - if none given, uses state's clients array
     */
    applySort = (sortBy, clients = undefined) => {
        clients = clients || [...this.state.clients];
        let sortReverse = this.state.sortBy == sortBy
            ? !this.state.sortReverse
            : false;

        clients.sort((clientA, clientB) => {
            if (sortReverse) {
                let _clientA = clientA;
                clientA = clientB;
                clientB = _clientA;
            }

            return sortBy === 'name'
                ? clientB[sortBy].localeCompare(clientA[sortBy])
                : clientB[sortBy] - clientA[sortBy]
        });
        this.setState({
            clients,
            sortBy,
            sortReverse
        })
    };

    /**
     * parses the filter message based on the
     * @return {any} the JSX message
     */
    getFilterMessage = () => {
        if (this.state.initiating) {
            return <span className="loading-results-message">Loading your clients...</span>;
        } else {
            return <>
                Showing{' '}
                <span className="type-heavy">{this.state.clients.length}</span>{' '}
                {this.state.clients.length === 1 ? 'Company' : 'Companies'} with{' '}
                <span className="type-heavy">{this.state.campaignsCount}</span>{' '}
                {this.state.campaignsCount === 1 ? 'Campaign' : 'Campaigns'}
            </>;
        }
    };

    /**
     * returns the className of a header based on the current sort criteria and the base class
     * @param {string} baseClass - base class specific to this header
     * @param {string} sort - sort variable of this header
     */
    getHeaderClass = (baseClass, sort) => {
        baseClass += ' sortable ';
        if (this.state.sortBy === sort) {
            baseClass += this.state.sortReverse
                ? 'sortable-up'
                : 'sortable-down';
        }

        return baseClass;
    };

    render() {
        let clientRows;
        let loadingGif;
        if (this.state.initiating) {
            loadingGif = <LoadingGif/>;
        } else {
            clientRows = this.state.clients.map((client, index) =>
                <ClientAccountsRow key={index} client={client}/>
            );
        }

        return <div className="page-width-wide" id="client-accounts">
            <div className="row padding-50-top padding-30-bottom">
                <div className="wide-format-col">
                    <div className="simpleflex__row">
                        <div className="simpleflex__cell">
                            <h2 className="type-normal-headline type-heavy padding-20-bottom">
                                Client Accounts
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="clear-block"/>
            </div>

            <div className="row">
                <div className="wide-format-col page__contentbox">
                    <div
                        className="simpleflex__row simpleflex__row__wrap__mobile type-centered-mobile spacing-18-bottom">
                        <div className="simpleflex__cell simpleflex__cell__middlealigned simpleflex__cell__maxed">
                            <div
                                className="type-normal-subhead type-narrow-line-height no-margin-top spacing-18-bottom-mobile">
                                {this.getFilterMessage()}
                            </div>
                        </div>

                        <div className="simpleflex__cell">
                            <div className="campaign-sort">
                                <div className="campaign-sort__cell campaign-sort__cell__label">
                                    <p className="type-normal-body">Sort by: </p>
                                </div>
                                <div className="campaign-sort__cell campaign-sort__cell__menu">
                                    {/* <SelectList
                                        options={this.sortOptions}
                                        onChange={(event) => this.applySort(event.target.value)}
                                        value={this.state.sortBy}
                                    /> */}
                                    <MultiSelectList
                                        options={this.sortOptions}
                                        onChange={(vals) => this.applySort(vals[0])}
                                        selections={[this.state.sortBy || ""]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="scroll-table__container">
                        <div className="scroll-table__container__shadowedge__left" ref={this.leftShadowEdgeRef}/>
                        <div className="scroll-table__container__shadowedge" ref={this.rightShadowEdgeRef}/>
                        <div
                            className="scroll-table__container__scrollbox scroll-table__container__scrollbox__flexible-height scroll-table__container__scrollbox-mobile"
                            ref={this.scrollboxRef}
                        >
                            <table
                                className="scroll-table__table type-normal-body type-single-line sortable-table"
                                ref={this.tableRef}
                            >
                                <thead>
                                <tr className="type-small-body type-heavy">
                                    <th className={this.getHeaderClass('client-accounts__name', 'name')}
                                        onClick={() => this.applySort('name')}>
                                        <div>Company Name</div>
                                    </th>
                                    <th className={this.getHeaderClass('client-accounts__campaigns', 'campaignCount')}
                                        onClick={() => this.applySort('campaignCount')}>
                                        <div>Campaigns</div>
                                    </th>
                                    <th className={this.getHeaderClass('client-accounts__leads-count', 'leads_count_last_30')}
                                        onClick={() => this.applySort('leads_count_last_30')}>
                                        <div>Leads (Last 30 Days)</div>
                                    </th>
                                    <th className={this.getHeaderClass('client-accounts__leads-cost', 'lead_cost_last_30_days')}
                                        onClick={() => this.applySort('lead_cost_last_30_days')}>
                                        <div>Lead Cost (Last 30 Days)</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {clientRows}
                                </tbody>
                            </table>
                            {loadingGif}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
