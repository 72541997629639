import React from 'react';

import {
    formatSecondsToHourMinute,
    formattedTimeAndDate,
} from "../../../Util";
import type {ILead} from "../../../Interfaces/ILead";

const billableStatus = (isValid, underReview) => {
    switch (underReview) {
        case '1':
            return 'Under Review';
        case '2':
            return (isValid === '1') ? 'Reviewed – Billable' : 'Reviewed – Non-Billable';
        default:
            return (isValid === '1') ? 'Billable' : 'Non-Billable';
    }
}

export default (props) => {
    const lead: ILead = props.lead;
    const isPhone = lead.lead_type === 'phone';
    
    let callGroups = {};
    
    if (lead.related_calls && lead.related_calls.length > 1) {
        lead.related_calls.forEach(relatedLead => {
            if (relatedLead.campaign_name in callGroups) {
                callGroups[relatedLead.campaign_name].push(relatedLead);
            } else {
                callGroups[relatedLead.campaign_name] = [relatedLead];
            }
        });
    }
    
    return (
        <>
        {(lead.related_calls && lead.related_calls.length > 1) && (
            <div className="leadlog__related-calls spacing-20-top">
                <div className="leadlog__detail-section leadlog__detail-section__admin">
                    <p className="type-large-body type-heavy type-single-line no-margin-top spacing-30-bottom">
                        Related Leads from this Number
                    </p>
                    {Object.keys(callGroups).map(key => (
                        <table key={`related-calls-${key}`}>
                            <thead>
                                <tr>
                                    <th colSpan="3" className="type-normal-body type-single-line type-heavy">{key}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {callGroups[key].map(relatedLead => {
                                const dateTime = formattedTimeAndDate(relatedLead.creation_timestamp);
                                const dateTimeDisplay = <>{dateTime.date} {dateTime.time} <span className="type-extra-small-body">{dateTime.amPm}</span></>
                                
                                return (
                                    <tr className="type-normal-body type-single-line" key={`related-call-${relatedLead.lead_id}`}>
                                        <td className="timestamp">
                                            {(relatedLead.lead_id === lead.lead_id) ? (
                                                <>{dateTimeDisplay} (This Lead)</>
                                            ) : (
                                                <a href={`/leads?lead_id=${relatedLead.lead_id}`} target="_blank">{dateTimeDisplay}</a>
                                            )}
                                        </td>
                                        <td className="duration">
                                            {isPhone && formatSecondsToHourMinute(relatedLead.call_duration)}
                                        </td>
                                        <td className="status">
                                            {billableStatus(relatedLead.is_valid, relatedLead.under_review)}
                                            {relatedLead.lead_status && relatedLead.lead_status !== 'Billable' && 
                                                ` – ${relatedLead.lead_status}`
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    ))}
                </div>
            </div>
        )}
        </>
    );
};