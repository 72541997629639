import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from "react-router-dom";
import axios from 'axios';

import {get, post} from '../../../Requests';
import {showInputError} from '../../../InputValidation';
import Tooltip from '../../../common/Tooltip'
import Modal from "../../../common/Modal";
import {MoneyInput} from "../../../common/components/form_elements/MoneyInput";
import PaymentMethodSection from '../../../admin/clients/PaymentMethodSection';
import TermsAndConditions from "../../TermsAndConditions";
import PrivacyPolicy from "../../PrivacyPolicy";
import {handleInputBlur, inputObj, syncInputValueToState} from '../../../formHelpers.js';
import {formatCurrency} from '../../../Util.js';
import {jumpToError} from "../../../formHelpers";
import Validator from "../../../lib/Validator";
import AuthService from "../../../service/AuthService";
import {AmountDueSpan} from "../../../common/components/form_sections/PricingSummary";

/**
 * Expected props
 *
 * whiteLabelPartnership
 * match, from ReactRouter
 * history
 * signup, object
 * tender
 * updateTender, fn
 */

class AccountSetup extends Component {
    constructor(props) {
        super(props);

        // create refs for scrolling
        this.passwordRef = React.createRef();
        this.cplRef = React.createRef();
        this.termsRef = React.createRef();

        this.state = {
            modalContent: undefined,
            modalHeader: undefined,
            messages: [],
            inputs: {
                signup_password: {
                    value: "",
                    error: "",
                    ref: this.passwordRef,
                },
                signup_password_confirmation: {
                    value: "",
                    error: "",
                    ref: this.passwordRef,
                },
                cost_per_lead: {
                    value: [],
                    error: [],
                    ref: this.cplRef,
                },
                checkbox_terms: {
                    value: false,
                    error: "",
                    ref: this.termsRef,
                },
                cc_name: inputObj(),
                cc_number: inputObj(),
                cc_expiration: inputObj(),
                cc_cvv: inputObj(),
                cc_zip: inputObj(),
            },
            isLoading: true,
            signupData: [],
            tenderData: [],
            submitting: false,
        };

        this.cancelSignal = axios.CancelToken.source();

        this.extraRefs = {
            credit_card: React.createRef(),
        };

        this.validator = new Validator();
    }

    componentDidMount() {
        // if authenticated and in signup, go to the last signup location
        if (AuthService.isSignup && AuthService.isAuthenticated) {
            window.location = AuthService.getItem('location');
            return;
        }

        this.getSignupData();

        /**
         * All other cases of HelpScout Beacon are handled in App.js, but this
         * is a special case, because you are unauthenticated yet in Signup.
         */
        // if ('addHelpScoutJs' in this.props) {
        //     this.props.addHelpScoutJs();
        // }
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
    }

    /**
     * Get the signup data from the backend tied to the token
     */
    getSignupData = () => {
        // get token from URL - this comes from the <Route exact path="...:token"> in App.js
        let token = this.props.match.params.token;

        if (!token) {
            return;
        }

        // create url
        let url = `signup/?step=account-setup&token=${token}`;

        // hit the backend
        get(url, this.cancelSignal.token)
            .then((resp) => {
                if (!resp || resp.status !== 200) {
                    return;
                }

                let signupData = resp.data.data.signup_data;
                let tenderData = resp.data.data.tender_data;

                this.props.updateWhiteLabel(resp.data.data.white_label_partnership);

                this.setState({
                    isLoading: false,
                    signupData: signupData,
                    tenderData: tenderData,
                });
            })
            .catch((error) => {
                this.props.history.push('/login');
                console.log("Redirected to login due to this error", error);
            });
    };

    /**
     * Callback for the PaymentMethodSection Component to update
     */
    syncCreditCardValues = (event) => {
        let stateClone = {...this.state};
        syncInputValueToState(stateClone, event);
        this.setState(stateClone);
    };

    /**
     * Callback for the creditCardFrom blur events
     */
    validateCreditCardInput = (event) => {
        let stateClone = {...this.state};
        handleInputBlur(stateClone, event);
        this.setState(stateClone);
    };
    /**
     * Jumps to a place on the dom
     */
    scrollTo = (ref) => {
        const domNode = ReactDOM.findDOMNode(ref.current);
        domNode.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
        });
    };

    jumpToError = (ref) => {
        this.scrollTo(ref)
    };

    /**
     * Clears The error messages
     */
    clearErrors = () => {
        this.setState({ messages: [] });
    };

    /**
     * update state with new modal
     */
    updateModalContent = (content, header) => {
        this.setState({modalContent: content, modalHeader: header});
    };

    /**
     * Custom validation used for the password inputs
     */
    validatePasswords = (e, elem) => {
        // allow validation on elem passed in
        //      else grab it from the event
        //      func called vs. triggered
        if (e) {
            elem = e.target;
        }

        let id = elem.id;
        let error;

        if (id === "signup-password-confirmation") {
            let passwordElem = document.getElementById("signup-password");
            let passwordValue = passwordElem.value;
            // check passwords match
            if (elem.value !== passwordValue) {
                error = "Your passwords do not match."
            }
        }

        return showInputError(id, elem, error);
    };

    addErrorsToStateClone = (stateClone) => {
        stateClone.messages = [];
        // setup the messages for the message blocks
        for (let inputIndex in stateClone.inputs) {
            if (!stateClone.inputs.hasOwnProperty(inputIndex)) {
                continue;
            }

            let input = stateClone.inputs[inputIndex];
            let error = input.error;
            if (error.length > 0) {
                if (Array.isArray(error)) {
                    error.forEach((nestedErrorArray) => {
                        stateClone.messages.push(nestedErrorArray.error);
                    });
                }
                else {
                    stateClone.messages.push(error);
                }
            }
        }

        return stateClone;
    };

    onBlurHandler = (event) => {
        let stateClone = { ...this.state };

        let elem = event.target;
        let name = elem.id;
        let value = elem.value;
        let inputName = name.replace(/-/g,"_");
        let error;

        let inputClone = { ...stateClone.inputs[inputName] };

        // handle custom password logic
        if (name.toLowerCase().includes("password")
            && name.toLowerCase().includes("confirmation")) {
                error = this.validatePasswords(event);
        }

        error = showInputError(name, elem, error);

        inputClone.value = value;
        inputClone.error = error;

        stateClone.inputs[inputName] = inputClone;

        this.setState(stateClone);
    };

    onCheckboxClick = () => {
        let stateClone = { ...this.state };
        let checkboxClone = { ...this.state.inputs.checkbox_terms };
        let wasCheckedBeforeClick = checkboxClone.value;

        if (!wasCheckedBeforeClick) {
            checkboxClone.error = "";
        }
        // they are unclicking the checkbox for whatever reason
        else {
            checkboxClone.error = "You must accept the terms and conditions.";
        }

        checkboxClone.value = !checkboxClone.value;

        stateClone.inputs.checkbox_terms = checkboxClone;
        this.setState(stateClone);
    };

    /**
     * Question: can we call jumpToError() directly, instead of wrapping it with
     * this function?  mz / bo 6 December 2018.
     */
    jumpToElement(ref, scrollTo) {
        if (ref) {
            jumpToError(ref, scrollTo);
        }
    };

    /**
     * when you click submit button, do frontend validation, then if ok, submit to API
     */
    submit = () => {

        // if already currently submitting, don't try to submit again
        if (this.state.submitting === true) {
            return;
        }

        /**
         * set submitting: true on the state, and then,
         * call the fn in arg 2, to do the rest of the submit
         **/
        this.setState(
            {submitting: true },
            () => {

            let stateClone = { ...this.state };
            let inputsClone = { ...stateClone.inputs };
            let checkboxClone = { ...inputsClone.checkbox_terms };
            let passwordClone = { ...inputsClone.signup_password };
            let passwordConfirmationClone = { ...inputsClone.signup_password_confirmation };
            let error;

            // we need to validate the credit card inputs
            let creditCardForm = document.getElementById("credit-card-form");
            if (creditCardForm) {
                let inputElems = creditCardForm.getElementsByTagName("input");

                // validate the credit card inputs
                for (let i in inputElems) {
                    if (inputElems.hasOwnProperty(i)) {
                        let id = inputElems[i].id;
                        let value = inputElems[i].value;
                        let inputName = id.replace(/cc-/g,"");

                        let inputClone = { ...stateClone.inputs[inputName] };

                        error = showInputError(id, inputElems[i]);

                        // add it to the state
                        inputClone.error = error;
                        inputClone.value = value;

                        inputsClone[inputName] = inputClone;
                    }
                }
            }

            // validate the checkbox
            if (!checkboxClone.value) {
                checkboxClone.error = "You must accept the terms and conditions.";
                inputsClone['checkbox_terms'] = checkboxClone;
            }

            // validate the passwords if required
            if (this.state.signupData.has_password == 0) {
                let passwordElem = document.getElementById("signup-password");
                let passwordConfirmationElem = document.getElementById("signup-password-confirmation");
                // run'em through the validation
                passwordClone.error = this.validatePasswords(undefined, passwordElem);
                passwordConfirmationClone.error = this.validatePasswords(undefined, passwordConfirmationElem);

                inputsClone.signup_password = passwordClone;
                inputsClone.signup_password_confirmation = passwordConfirmationClone;
            }

            stateClone.inputs = inputsClone;

            stateClone = this.addErrorsToStateClone(stateClone);

            this.setState(stateClone);

            // if we have any frontend errors: show them, scroll up, and abort the submit
            for (let index in inputsClone) {
                if (!inputsClone.hasOwnProperty(index)) {
                    continue;
                }

                let inputError = inputsClone[index].error;
                let inputRef = inputsClone[index].ref;
                if (inputError.length > 0) {
                    //
                    // We encountered a case where ref was an object and had a 'current' key
                    // yet this key had value NULL. This was making it in to call jumpToError
                    // and then bombing. So checking that inputRef.current exists and is
                    // truthy.
                    //
                    // Addresses https://app.asana.com/0/918571211873935/1126114656070689
                    // RM 2019-06-06
                    //
                    if (inputRef && 'current' in inputRef && inputRef.current) {
                        this.jumpToError(inputRef);
                        this.setState({submitting: false});
                        return;
                    }
                }
            }


            /**
             * Nice, we had no frontend errors.
             * So now, construct the payLoad and POST it to the API
             **/

            // create the cpl objects
            // add the cpl to the inputs clone
            let costPerLeadArray = [];
            this.state.tenderData.forEach( (cpl) => {
                let costPerLeadObject = {};
                costPerLeadObject.industry_id = cpl.industry_id;
                costPerLeadObject.cost_per_lead = cpl.cost_per_lead;

                costPerLeadArray.push(costPerLeadObject);
            });

            inputsClone.cost_per_lead.value = costPerLeadArray;

            let payLoad = this.getPayloadFromInputs(inputsClone);
            payLoad.signup_id = this.state.signupData.signup_id;

            /**
             * Now we have the payLoad constructed.
             * Make the API call to POST it.
             **/
            post("signup/account-setup", payLoad, this.cancelSignal.token)
            .then( (resp) => {
                // 200 - Good Response, good POST. Redirect to next page.
                if (resp.status === 200) {
                    let path = resp.data.location;
                    this.props.history.push({pathname: path});
                }
                // 400 - probably got an API Error
                else if (resp.status === 400) {
                    if (resp.data.errors) {

                        // This code appears redundant with the validation that
                        // occurs in App.js; it calls its own version of
                        // updateApiErrorMessages() that does similar things,
                        // but also winds up updating its own state clone.  We
                        // should eventually de-duplicate this.  For now,
                        // documenting here b/c the code in App.js is
                        // responsible for rendering error messages even
                        // w/these 4 lines of coded commented out; I wasn't
                        // sure that was happening until I found that out.
                        // - mz 24 Apr 2019
                        let errors = resp.data.errors;
                        let ref = this.validator.setApiErrorsOnStateClone(errors, stateClone, this.extraRefs);
                        this.setState(stateClone);
                        this.jumpToElement(ref);
                    }
                }

                this.setState({ submitting: false });
            })
                .finally(() => this.setState({ submitting: false }));
        });
    };

    getPayloadFromInputs = (inputsClone) => {
        let payload = {};
        for (let index in inputsClone) {
            if (!inputsClone.hasOwnProperty(index)) {
                continue;
            }

            let input = inputsClone[index];
            payload[index] = input.value;
        }
        return payload;
    };

    getNameFromId = (id) => {
        let hyphenIndex = id.indexOf("-");
        return id.substring(hyphenIndex + 1).replace(/-/g," ");
    };

    handleCplChange = (event, tenderIndex) => {
        let stateClone = { ...this.state };
        let costPerLeadClone = { ...stateClone.inputs.cost_per_lead };
        let costError = '';

        let elem = event.target;
        let id = elem.id;
        let name = this.getNameFromId(id);
        let industryId = elem.dataset.industryId;

        let tenderObj = this.state.tenderData[tenderIndex];

        let hasError = false;

        // if it hasn't changed, don't do anything
        // string comparison
        let currentCpl = Math.abs(tenderObj.cost_per_lead);
        let minTender = Math.abs(tenderObj.default_min_tender);
        let newCpl = Math.abs(elem.value);
        if (currentCpl === newCpl) {
            return;
        }

        // check if the value is too low
        if (minTender > newCpl) {
            costError = "Cost per lead for the " + name + " industry cannot be lower than " +
                formatCurrency(minTender, 2);
            hasError = true;
        }
        else if (isNaN(newCpl)) {
            costError = "Cost per lead must be a number.";
            hasError = true;
        }

        // push any error onto the array
        // replace if it already exists
        let cplErrorsArray = [...costPerLeadClone.error];
        let addedError = false;
        cplErrorsArray.forEach( (error, index) => {
            if (error.industryId === industryId) {
                // if it has an error replace it
                if (hasError) {
                    error.error = costError;
                    addedError = true;
                }
                else { // remove the error if none
                    cplErrorsArray.splice(index,1);
                }
            }
        });

        // if we haven't replaced the error
        // then its a new one.  Add it.
        if (!addedError && hasError) {
            cplErrorsArray.push({ industryId: industryId, error: costError });
        }

        costPerLeadClone.error = cplErrorsArray;
        stateClone.inputs.cost_per_lead = costPerLeadClone;

        this.setState(stateClone);
        this.updateTender(tenderIndex, newCpl);
    };


    /**
     * Update the correct tender
     */
    updateTender = (index, tender) => {
        let stateClone = { ...this.state };
        let tenderClone = { ...stateClone.tenderData[index] };

        tenderClone.cost_per_lead = tender.toFixed(2).toString();
        stateClone.tenderData[index] = tenderClone;

        this.setState(stateClone);
    };

    /**
     * Small func to grab the input Obj from the state
     */
    getInputObj = fieldName => this.state.inputs[fieldName];

    render() {
        if (this.state.isLoading === true) {
            return null;
        }

        let username = this.state.signupData.username;
        let paid = !!this.state.signupData.paid_startup_fee_timestamp;
        let lastFour = this.state.signupData.last_four;
        let monthlyFee = Math.abs(this.state.signupData.monthly_fee);
        let clientCanEditCpl = this.state.signupData.client_can_edit_cpl == 1;
        let retailStartupFee = Math.abs(this.state.signupData.retail_startup_fee);
        let discountAmount = Math.abs(this.state.signupData.discount_amount);

        let amountDue = retailStartupFee - discountAmount;

        let passwordConfirmationError = this.state.inputs.signup_password_confirmation.error;
        let passwordError = this.state.inputs.signup_password.error;

        let checkboxTermsError = this.state.inputs.checkbox_terms.error;
        let ccName = this.getInputObj("cc_name");
        let ccNumber = this.getInputObj("cc_number");
        let ccExpiration = this.getInputObj("cc_expiration");
        let ccCvv = this.getInputObj("cc_cvv");
        let ccZip = this.getInputObj("cc_zip");
        let hasPassword = this.state.signupData.has_password == 1;

        const industryLeadCost = this.state.tenderData.map( (industry, key) => {
            let nameForId = industry.name.replace(/ /g,'-');
            let costPerLead = Math.abs(industry.cost_per_lead).toString();
            let costPerLeadHasError = false;
            let costPerLeadError = '';

            // check if this specific cpl has error
            for (let costPerLeadIndex in this.state.inputs.cost_per_lead.error) {
                if (!this.state.inputs.cost_per_lead.error.hasOwnProperty(costPerLeadIndex)) {
                    continue;
                }

                let cpl = this.state.inputs.cost_per_lead.error[costPerLeadIndex];
                if (cpl.industryId === industry.industry_id) {
                    costPerLeadHasError = true;
                    costPerLeadError = cpl.error;
                }
            }

            if (clientCanEditCpl) {
                return (
                    <span className=" cpl-row" key={ key }>
                        <span>
                            <label
                                id={"cplField-" + nameForId + "-label"}
                                className="type-small-body type-narrow-line-height type-heavy"
                            >
                                <div className="spacing-10-bottom">
                                    <span className={costPerLeadHasError ? "type-alert" : ""}>
                                        { industry.name }
                                    </span>
                                </div>
                                <div>
                                    <MoneyInput
                                        ref={ this.state.inputs.cost_per_lead.ref }
                                        name={"cpl-" + nameForId}
                                        id={"cplField-" + nameForId}
                                        defaultValue={ (costPerLead === "NaN" || Math.abs(costPerLead) === 0) ? "" : costPerLead }
                                        onBlur={ (event) => this.handleCplChange(event, key) }
                                        onChange={ (event) => this.updateTender(key, parseInt(event.target.value) ) }
                                        data-industry-id={ industry.industry_id }
                                    />
                                    <span className="type-normal-body type-no-break">
                                        Suggested CPL Range: {formatCurrency(industry.min_suggested_tender, 0)}-{formatCurrency(industry.max_suggested_tender, 0)}
                                    </span>
                                </div>
                            </label>
                        </span>
                        <span className={"padding-5-top padding-10-bottom type-small-body" + (costPerLeadError ? " type-alert" : "")} >
                            { costPerLeadError } &nbsp;
                        </span>
                    </span>
                )
            }
            else {
                return (
                    <span key={ key }>
                        { industry.name } - { formatCurrency(industry.cost_per_lead, 2) }
                    </span>
                )
            }
        });

        // // set up the password error array for messaging
        // let passwordErrorArray = [];
        // if (this.state.inputs.signup_password.error) {
        //     passwordErrorArray.push(this.state.inputs.signup_password.error);
        // }
        // if (this.state.inputs.signup_password_confirmation.error) {
        //     passwordErrorArray.push(this.state.inputs.signup_password_confirmation.error);
        // }

        // // set up the cost per lead array for messaging
        // let costPerLeadErrorArray = [];
        // this.state.inputs.cost_per_lead.error.forEach( (cplObj, key) => {
        //     costPerLeadErrorArray.push(cplObj.error);
        // });

        // Amount to display as due or paid
        // Also, if this is 0, we don't show the One Time Account Activation cost
        let totalStartupFee = retailStartupFee - discountAmount;

        return (
            <div>
                <Modal
                    content={ this.state.modalContent }
                    header={ this.state.modalHeader }
                    updateModalContent={ this.updateModalContent }
                />
                <div className="row padding-50-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">
                    {!hasPassword &&
                        <div className="module">
                            <div className="module__header module__twothirdswidth">
                                <h3 className="type-large-subhead type-single-line">
                                    Password Setup
                                </h3>
                            </div>
                            <div className="module__contentbox module__twothirdswidth">
                                <p className="type-large-body spacing-30-bottom">
                                    Please set a password for your account. By default, your email address is your Username.
                                </p>
                                <form ref={ this.state.inputs.signup_password.ref }>
                                    <p className="type-small-body spacing-30-bottom">
                                        <label htmlFor="signup-username" className="type-heavy type-force-newline">
                                            Username
                                        </label>
                                        <span className="type-force-newline">
                                            <input className="ui-text-field" id="signup-username" autoComplete="username"
                                                type="text" name="username" value={username} readOnly />
                                        </span>
                                    </p>
                                    <div className="form__row">
                                        <div className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                                            <label
                                                htmlFor="signup-password"
                                                className={"type-small-body type-heavy type-narrow-line-height spacing-5-bottom" + (passwordError ? " type-alert" : "")}
                                                id="signup-password-label"
                                            >
                                                Password *
                                            </label>
                                            <input type="password"
                                                   className={"ui-text-field ui-normal-text-input qa-signup-password" + (passwordError ? " ui-alert" : "")}
                                                   id="signup-password"
                                                   name="password"
                                                   onBlur={ this.onBlurHandler }
                                                   required
                                                   autoComplete="new-password"
                                            />
                                        </div>
                                        <div className="form__cell form__cell__50 form__cell__100__mobile">
                                            <label
                                                htmlFor="signup-password-confirmation"
                                                className={"type-small-body type-heavy type-narrow-line-height spacing-5-bottom" + (passwordConfirmationError ? " type-alert" : "")}
                                                id="signup-password-confirmation-label"
                                            >
                                                Confirm Password *
                                            </label>
                                            <input
                                                type="password"
                                                className={"ui-text-field ui-normal-text-input qa-signup-password-confirmation" + (passwordConfirmationError ? " ui-alert" : "")}
                                                id="signup-password-confirmation"
                                                name="passwordConfirmation"
                                                onBlur={ this.onBlurHandler }
                                                autoComplete="new-password"
                                                required
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>}
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
                <div className="row padding-50-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">
                        <div className="module">
                            <div className="module__header module__twothirdswidth">
                                <h3 className="type-large-subhead type-single-line">
                                    Pricing Details
                                </h3>
                            </div>
                            <div className="module__contentbox module__twothirdswidth">
                                <div className="threequarter-content__wrapper threequarter-content__hide-sidebar__mobile">
                                    <div className="threequarter-content__content">
                                    {totalStartupFee > 0
                                        ?
                                    <React.Fragment>
                                        <p className="type-normal-subhead spacing-10-bottom">
                                            <span className="type-force-newline type-heavy">
                                                <span className="type-force-newline-mobile">Account Activation Cost</span>
                                                    <span className={
                                                        (paid ? "type-alert-text__success" : "")
                                                        + " type-alert-text type-small-body type-alert-text__no-margin-left__mobile"}
                                                    >
                                                        { paid ? "Paid" : "Due Now" }
                                                    </span>
                                            </span>
                                            <AmountDueSpan discountAmount={discountAmount}
                                                           retailStartupFee={retailStartupFee}/>
                                        </p>
                                        <p className="type-normal-body spacing-30-bottom">
                                            { paid
                                                    ?   "Thanks for your one-time payment of " +  formatCurrency(totalStartupFee) +  " to activate your account. " +
                                                        "We charged your credit card ending in " + lastFour +
                                                        " and we've emailed you a receipt for your records."
                                                    : "This is a one-time payment of " +  formatCurrency(totalStartupFee)  + " to activate your account. "
                                            }
                                        </p>
                                    </React.Fragment>
                                        : ""}

                                    {monthlyFee > 0
                                        ?
                                    <React.Fragment>
                                        <p className="type-normal-subhead spacing-10-bottom">
                                            <span className="type-force-newline type-heavy">Monthly Subscription</span>
                                            <span className="type-force-newline">{ formatCurrency(monthlyFee) }/month</span>
                                        </p>
                                        <p className="type-normal-body spacing-30-bottom">
                                            Your first monthly subscription will be charged 1 month after you approve your campaigns.
                                        </p>
                                    </React.Fragment>
                                        : ""}

                                        <p className="type-normal-subhead spacing-10-bottom">
                                            <span className="type-force-newline type-heavy">Pay Per Lead Pricing
                                                {' '}
                                                <Tooltip
                                                    content={
                                                    <>
                                                        <span className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height spacing-10-bottom">
                                                            Pay Per Lead Pricing
                                                        </span>

                                                        <span className="type-small-body type-black type-force-newline type-narrow-line-height spacing-10-bottom">
                                                            You are responsible for paying for Billable Leads at the Cost Per Lead that is set for each Campaign
                                                            at the time when the lead is delivered. Once we’ve launched your Campaigns, you can adjust the
                                                            Cost Per Lead of your Campaigns at anytime in your mySD Campaign Settings.
                                                        </span>
                                                        <span className="type-small-body type-black type-force-newline type-narrow-line-height spacing-10-bottom">
                                                            You will be charged when your Total Lead Costs reach your Billing Threshold.
                                                            You can see what your Billing Threshold is set at in your mySD Account Settings.
                                                        </span>
                                                        <span className="type-small-body type-black type-force-newline type-narrow-line-height spacing-10-bottom">
                                                            Read more about our &nbsp;
                                                            <a className="type-heavy" href="https://mysd-account-activation.helpscoutdocs.com/article/159-how-cost-per-lead-affects-lead-volume" target="_BLANK">
                                                                Flexible Pay Per Lead Pricing
                                                            </a>
                                                        </span>
                                                    </>
                                                    }
                                                />
                                            </span>
                                            <span className="cpl-values padding-10-top">
                                                { industryLeadCost }
                                            </span>
                                        </p>

                                        <p className="type-normal-body">
                                            You are responsible for paying for Billable leads at the Cost Per Lead that is set for each Campaign.                                        </p>
                                    </div>
                                    <div className="threequarter-content__sidebar">
                                        <div className="tip-sidebar">
                                            <p className="type-small-body type-heavy no-bottom-margin">
                                                Payment Methods
                                            </p>
                                            <p className="type-small-body no-margin-top">
                                                You can verify or change your payment methods in your settings after account setup is complete.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
                <div className="row padding-50-bottom" ref={ this.state.inputs.checkbox_terms.ref }>
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">
                        <div className="module">
                            <div className="module__header module__twothirdswidth">
                                <h3 className="type-large-subhead type-single-line">
                                    Terms & Conditions
                                </h3>
                            </div>
                            <div className="module__contentbox module__twothirdswidth">
                                <p className="type-large-body spacing-30-bottom">
                                    Please read the{' '}
                                    <span role="button" className="type-heavy type-blue"
                                        onClick={ () => this.updateModalContent(
                                            <TermsAndConditions
                                                whiteLabelPartnership={this.props.whiteLabelPartnership}
                                                inAccountActivation={true}
                                            />,
                                            this.props.whiteLabelPartnership.white_label_name + " Advertising Program Terms"
                                        ) }
                                    >
                                        { this.props.whiteLabelPartnership.white_label_name } Terms and Conditions
                                    </span>{' '}
                                    and our{' '}
                                    <span role="button" className="type-heavy type-blue"
                                          onClick={ () => this.updateModalContent(
                                        <PrivacyPolicy
                                            whiteLabelPartnership={this.props.whiteLabelPartnership}
                                        />,
                                        this.props.whiteLabelPartnership.white_label_name + " Privacy Policy"
                                    ) }
                                    >
                                        Privacy Policy
                                    </span>{' '}
                                    and check the box below to agree.
                                </p>
                                <div className="form__row">
                                    <div className="form__cell form__cell__100">
                                        <div className="checkbox-container">
                                            <input
                                                type="checkbox"
                                                name="terms"
                                                id="checkbox-terms"
                                                checked={ this.state.inputs.checkbox_terms.value }
                                                onChange={ this.onCheckboxClick }
                                            />
                                            <label
                                                htmlFor="checkbox-terms"
                                            >
                                                <span
                                                    className={"checkbox" + (this.state.inputs.checkbox_terms.error ? " type-alert" : "")}
                                                >
                                                    I’ve read and accept the Terms and Conditions *
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span className={"padding-10-top" + (checkboxTermsError ? " type-small-body type-alert qa-checkboxTermsError" : "")} >
                                        { checkboxTermsError }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
                {!paid &&
                <div ref={this.extraRefs.credit_card}>
                    <PaymentMethodSection
                        selectedCreditCardIndex={-1}
                        name={ ccName }
                        number={ ccNumber }
                        expiration={ ccExpiration }
                        cvv={ ccCvv }
                        zip={ ccZip }
                        amountDue={ amountDue }
                        syncStateToInput={this.syncCreditCardValues}
                        validateInputOnBlur={ this.validateCreditCardInput }
                        required={ true }
                        showCreditCardForm={ true }
                        showCheckbox={ false }
                        primaryPaymentMethodRequired={ true }
                    />
                </div>}
                <div className="row padding-120-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">
                        <div className="type-align-right">
                            <button disabled={ this.state.submitting } className="button ui-large-button ui-full-width-button-mobile" onClick={ this.submit }>
                                <span className="type-hide-full type-hide-tablet">
                                    Continue
                                </span>
                                <span className="type-hide-mobile">
                                    Continue to Next Step
                                </span>
                                <img src="/images/icon-next-arrow.svg" alt="Right Arrow" className="right" />
                            </button>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
        </div>
        )
    }
}

export default withRouter(AccountSetup);
