import React, { useState } from 'react'
import {
    TableRow,
    TableRowSpacer,
    LeftCell,
    RightCell,
    TextCell,
    TooltipContent,
    ActionsWrapper,
    Table,
    TableWrapper,
} from './styles'
import Input, { HideableInput } from '../../../../common/components/form_elements/Input'
import RadioGroup from '../../../../common/components/form_elements/RadioGroup'
import Tooltip from '../../../../common/Tooltip'
import Button from '../../../../common/components/form_elements/Button'
import CopyToClipboard from '../../../../common/components/CopyToClipboard'
import { cloneDeep } from 'lodash'
import {
    isInteger,
    isValidBidMultiplier,
    isValidPhoneNumbersLimit,
} from '../../../../Util'
import { editAffiliateTooltips, newAffiliateTooltips } from './tooltips'
import { ContentWindowScroll } from '../../../../common/Modal'

const defaultRadioOptions = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
]

export default ({
    affiliate: _affiliate,
    isNewAffiliate = false,
    onClose,
    onPreview,
    updates: _updates = [],
}) => {
    // if there are any updates, which may have come from the "go back" action, merge
    // them with the affiliate so that the updates are preserved when "go back" is clicked
    const defaultAffiliate = { ..._affiliate }
    _updates.forEach((u) => {
        const { property, value } = u
        defaultAffiliate[property] = value
    })

    // the affiliate represents the current state of the affiliate while changes
    // are being made
    const [affiliate, setAffiliate] = useState(defaultAffiliate)

    // if the user arrives at this component by clicking the "add new ad network button",
    // treat the default settings like updates so they are captured in the confirmation
    const defaultUpdates = [..._updates]
    if (isNewAffiliate) {
        // only merge the updates if it doesn't exist
        Object.keys(defaultAffiliate).forEach((key) => {
            if (defaultUpdates.findIndex((o) => o.property === key) === -1) {
                defaultUpdates.push({
                    property: key,
                    value: defaultAffiliate[key],
                })
            }
        })
    }

    // the updates are any properties that are changed. they are maintained for
    // the confirmation page which shows the old values and the new values
    // const [updates, setUpdates] = useState(_updates)
    const [updates, setUpdates] = useState(defaultUpdates)

    const addOrRemoveUpdates = (_updates) => {
        let result = cloneDeep(updates)
        // check the update against the original affiliate property
        // if the value is the same and its not a new affiliate, remove the update.
        // otherwise add it
        _updates.forEach((_u) => {
            const { property, value } = _u
            if (_affiliate[property] === value && !isNewAffiliate) {
                result = result.filter((u) => u.property !== property)
            } else {
                result = [
                    { property, value },
                    ...result.filter((u) => u.property != property),
                ]
            }
        })
        return result
    }

    const handlePropertyChanges = (_changes) => {
        setAffiliate({
            ...affiliate,
            ..._changes.reduce((acc, o) => {
                acc[o.property] = o.value
                return acc
            }, []),
        })
        setUpdates(addOrRemoveUpdates(_changes))
    }

    const handleTextChange = (e) => {
        const { value, name } = e.target
        // handlePropertyChange(name, value)
        handlePropertyChanges([{ property: name, value }])
        // clear the error for the property
        const _errors = cloneDeep(errors)
        if (_errors[name]) {
            delete _errors[name]
            setErrors(_errors)
        }
    }

    const handleRadioChange = (name, value) => {
        const result = [{ property: name, value }]
        // manage errors
        const _errors = cloneDeep(errors)
        if (_errors[name]) {
            delete _errors[name]
        }
        // case for phone payout model
        if (name === 'mp_phone_payout_model') {
            // if the mp phone payout model is not 'duration',
            // clear the duration
            if (value !== 'duration') {
                result.push({ property: 'minimum_valid_call_duration', value: '' })
                result.push({ property: 'locked_mp_phone_bid_multiplier', value: '1' })
                // handlePropertyChange('minimum_valid_call_duration', '')
                // also clear the error if there is a min duration error
                if (_errors.minimum_valid_call_duration) {
                    delete _errors.minimum_valid_call_duration
                }
            } else {
                result.push({ property: 'locked_mp_phone_bid_multiplier', value: '0' })
                // if the affiliate has an existing min call duration, reset the input to that value
                if (defaultAffiliate?.minimum_valid_call_duration != null) {
                    result.push({
                        property: 'minimum_valid_call_duration',
                        value: defaultAffiliate.minimum_valid_call_duration,
                    })
                }
            }
        }

        // case for mp form payout model - when the payout model is set, the payout model
        // is not
        if (name === 'mp_form_payout_model') {
            if (value == 'billable_leads') {
                result.push({ property: 'locked_mp_form_bid_multiplier', value: '0' })
            } else {
                result.push({ property: 'locked_mp_form_bid_multiplier', value: '1' })
            }
        }
        setErrors(_errors)
        handlePropertyChanges(result)
    }

    const [errors, setErrors] = useState({})

    const validate = (updates) => {
        let _errors = cloneDeep(errors)
        const updatedFields = updates.reduce((acc, u) => {
            acc[u.property] = u.value
            return acc
        }, {})
        // check for required fields for new affiliate
        if (isNewAffiliate) {
            // ad network name is required for a new affiliate
            if (
                updatedFields?.ad_network_name == null ||
                updatedFields?.ad_network_name === ''
            ) {
                _errors.ad_network_name = 'Required'
            }
            // phone bid multiplier is required
            if (updatedFields?.mp_phone_bid_multiplier == null) {
                _errors.mp_phone_bid_multiplier = 'Required'
            }
            // form bid multiplier is required
            if (updatedFields?.default_mp_form_bid_multiplier == null) {
                _errors.default_mp_form_bid_multiplier = 'Required'
            }
            // mp phone bank limit is required
            if (updatedFields?.phone_numbers_limit == null) {
                _errors.phone_numbers_limit = 'Required'
            }

            // if the payout model is set to duration, the duration is required
            if (isNewAffiliate && updatedFields?.mp_phone_payout_model == 'duration') {
                if (!updatedFields.minimum_valid_call_duration) {
                    _errors.minimum_valid_call_duration =
                        'Required for Duration Payout Model'
                }
            }

            // mp phone payout model is required
            if (isNewAffiliate && updatedFields?.mp_phone_payout_model == null) {
                _errors.mp_phone_payout_model = 'Required'
            } else {
                delete _errors.mp_phone_payout_model
            }
            // mp form payout model is required
            if (isNewAffiliate && updatedFields?.mp_form_payout_model == null) {
                _errors.mp_form_payout_model = 'Required'
            } else {
                delete _errors.mp_form_payout_model
            }
        }
        // check for validity
        updates.forEach((u) => {
            if (u.property === 'ad_network_name') {
                if (u.value !== '') delete _errors.ad_network_name
            }
            if (u.property === 'minimum_valid_call_duration') {
                if (
                    updatedFields?.mp_phone_payout_model == 'duration' ||
                    !isNewAffiliate
                ) {
                    if (isInteger(u.value) && +u.value > 0) {
                        delete _errors.minimum_valid_call_duration
                    } else {
                        _errors.minimum_valid_call_duration = 'Enter a positive integer'
                    }
                }
                if (updatedFields?.mp_phone_payout_model != 'duration') {
                    // if the mp phone payout model is not "duration" always delete
                    // the duration error
                    delete _errors.minimum_valid_call_duration
                }
            }
            if (u.property === 'mp_phone_bid_multiplier') {
                if (isValidBidMultiplier(u.value)) {
                    delete _errors.mp_phone_bid_multiplier
                } else {
                    _errors.mp_phone_bid_multiplier = 'Enter decimal between 0 and 0.8'
                }
            }
            if (u.property === 'default_mp_form_bid_multiplier') {
                if (isValidBidMultiplier(u.value)) {
                    delete _errors.default_mp_form_bid_multiplier
                } else {
                    _errors.default_mp_form_bid_multiplier =
                        'Enter decimal between 0 and 0.8'
                }
            }
            // validation for new affiliate phone number in use.
            if (u.property === 'phone_numbers_limit') {
                if (isNewAffiliate) {
                    if (isValidPhoneNumbersLimit(u.value)) {
                        delete _errors.phone_numbers_limit
                    } else {
                        _errors.phone_numbers_limit =
                            'Enter MP Phone Bank Limit between 1 and 1000'
                    }
                } else {
                    if (
                        Number(affiliate.current_phone_numbers_in_use) > 0 &&
                        Number(u.value) <= Number(affiliate.current_phone_numbers_in_use)
                    ) {
                        _errors.phone_numbers_limit =
                            'MP Phone Bank Limit cannot be less than numbers in use'
                    } else if (!isValidPhoneNumbersLimit(u.value)) {
                        _errors.phone_numbers_limit =
                            'Enter MP Phone Bank Limit between 1 and 1000'
                    } else {
                        delete _errors.phone_numbers_limit
                    }
                }
            }
        })
        setErrors(_errors)
        if (Object.keys(_errors).length > 0) {
            return false
        }
        return true
    }
    const tooltips = isNewAffiliate ? newAffiliateTooltips : editAffiliateTooltips
    const isPreviewDisabled = updates?.length == 0 || Object.keys(errors).length > 0
    const isDurationReadOnly = affiliate?.mp_phone_payout_model !== 'duration'
    // primary action button text
    let previewButtonText = 'Preview Changes'
    if (isNewAffiliate) {
        previewButtonText = 'Save And Continue'
    }
    if (Object.keys(errors).length > 0) {
        previewButtonText = 'Input Errors'
    }
    // for the SD Internal ad network, the bid multiplier is not editable
    const isBidMultiplierReadOnly = affiliate?.ad_network_id == '19'
    return (
        <div>
            <ContentWindowScroll>
                <TableWrapper>
                    <Table
                        width={'100%'}
                        className={isNewAffiliate ? 'spacing-20-bottom' : ''}
                    >
                        <colgroup>
                            <col span="1" style={{ width: '45%' }} />
                            <col span="1" style={{ width: '55%' }} />
                        </colgroup>
                        <tbody>
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">Lead Source Name</span>
                                    {isNewAffiliate && (
                                        <>
                                            {' '}
                                            <Tooltip
                                                position="right"
                                                content={
                                                    <TooltipContent className="type-normal-body">
                                                        {tooltips.ad_network_name}
                                                    </TooltipContent>
                                                }
                                            />
                                        </>
                                    )}
                                </LeftCell>
                                <RightCell>
                                    <Input
                                        type="text"
                                        id="ad_network_name"
                                        name="ad_network_name"
                                        onChange={handleTextChange}
                                        value={affiliate.ad_network_name || ''}
                                        readOnly={!isNewAffiliate}
                                        hasError={!!errors.ad_network_name}
                                        errorMessage={errors.ad_network_name}
                                        autocomplete="off"
                                    />
                                </RightCell>
                            </TableRow>
                            {!isNewAffiliate && (
                                <TableRow>
                                    <LeftCell>
                                        <span className="type-heavy">Lead Source ID</span>
                                    </LeftCell>
                                    <RightCell>
                                        <Input
                                            type="text"
                                            id="ad_network_id"
                                            name="ad_network_id"
                                            onChange={handleTextChange}
                                            value={affiliate.ad_network_id || ''}
                                            readOnly={true}
                                            autocomplete="off"
                                        />
                                    </RightCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">Hubspot Company ID</span>
                                </LeftCell>
                                <RightCell>
                                    <Input
                                        type="text"
                                        id="hubspot_company_id"
                                        name="hubspot_company_id"
                                        onChange={handleTextChange}
                                        value={affiliate.hubspot_company_id || ''}
                                        autocomplete="off"
                                    />
                                </RightCell>
                            </TableRow>
                            <TableRowSpacer divider={true} />
                            <TableRowSpacer />
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        MP Phone Leads{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    {tooltips.mp_phone_api_access}
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <RadioGroup
                                        showLabel={false}
                                        name="mp_phone_api_access"
                                        options={defaultRadioOptions}
                                        onChange={handleRadioChange}
                                        checkedOption={affiliate.mp_phone_api_access}
                                        isClearable={false}
                                    />
                                </RightCell>
                            </TableRow>

                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        MP Phone Payout Model{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    {tooltips.mp_phone_payout_model}
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <RadioGroup
                                        showLabel={false}
                                        name="mp_phone_payout_model"
                                        options={[
                                            {
                                                label: 'Revenue Share',
                                                value: 'revenue_share',
                                            },
                                            { label: 'Duration', value: 'duration' },
                                        ]}
                                        onChange={handleRadioChange}
                                        checkedOption={affiliate.mp_phone_payout_model}
                                        isClearable={false}
                                        hasError={!!errors?.mp_phone_payout_model}
                                        errorMessage={errors?.mp_phone_payout_model}
                                    />
                                </RightCell>
                            </TableRow>
                            {/* Call Duration */}
                            {
                                <TableRow>
                                    <LeftCell>
                                        <span className="type-heavy">
                                            Minimum Call Duration (s){' '}
                                            <Tooltip
                                                position="right"
                                                content={
                                                    <TooltipContent className="type-normal-body">
                                                        {
                                                            tooltips.minimum_valid_call_duration
                                                        }
                                                    </TooltipContent>
                                                }
                                            />
                                        </span>
                                    </LeftCell>
                                    <RightCell>
                                        <Input
                                            type="text"
                                            id="minimum_valid_call_duration"
                                            name="minimum_valid_call_duration"
                                            value={
                                                isDurationReadOnly
                                                    ? ''
                                                    : affiliate.minimum_valid_call_duration
                                            }
                                            placeholder={
                                                affiliate.mp_phone_payout_model ===
                                                'duration'
                                                    ? null
                                                    : 'Not Applicable'
                                            }
                                            onChange={handleTextChange}
                                            readOnly={isDurationReadOnly}
                                            hasError={
                                                !!errors.minimum_valid_call_duration
                                            }
                                            errorMessage={
                                                errors.minimum_valid_call_duration
                                            }
                                            autocomplete="off"
                                        />
                                    </RightCell>
                                </TableRow>
                            }
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        Locked MP Phone Bid Multiplier{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    {
                                                        tooltips.locked_mp_phone_bid_multiplier
                                                    }
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <RadioGroup
                                        showLabel={false}
                                        name="locked_mp_phone_bid_multiplier"
                                        options={defaultRadioOptions}
                                        onChange={handleRadioChange}
                                        checkedOption={
                                            affiliate.locked_mp_phone_bid_multiplier
                                        }
                                        isClearable={false}
                                        disabled={true}
                                    />
                                </RightCell>
                            </TableRow>
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        Default MP Phone Bid Multiplier{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    {tooltips.mp_phone_bid_multiplier}
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <Input
                                        type="text"
                                        id="mp_phone_bid_multiplier"
                                        name="mp_phone_bid_multiplier"
                                        value={affiliate.mp_phone_bid_multiplier || ''}
                                        onChange={handleTextChange}
                                        hasError={!!errors.mp_phone_bid_multiplier}
                                        errorMessage={errors.mp_phone_bid_multiplier}
                                        autocomplete="off"
                                        readOnly={isBidMultiplierReadOnly}
                                    />
                                </RightCell>
                            </TableRow>
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        MP Phone Bank Limit{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    {tooltips.phone_numbers_limit}
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                    {!isNewAffiliate && (
                                        <>
                                            {' '}
                                            <br />{' '}
                                            <span className="type-small-body">
                                                Currently using{' '}
                                                <b>
                                                    {
                                                        affiliate.current_phone_numbers_in_use
                                                    }
                                                </b>{' '}
                                                numbers
                                            </span>{' '}
                                        </>
                                    )}
                                </LeftCell>
                                <RightCell>
                                    <Input
                                        type="text"
                                        id="phone_numbers_limit"
                                        name="phone_numbers_limit"
                                        value={affiliate.phone_numbers_limit || ''}
                                        onChange={handleTextChange}
                                        hasError={!!errors.phone_numbers_limit}
                                        errorMessage={errors.phone_numbers_limit}
                                        autocomplete="off"
                                    />
                                </RightCell>
                            </TableRow>
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        Disable Whisper File{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    {tooltips.disable_whisper_file}
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <RadioGroup
                                        showLabel={false}
                                        name="disable_whisper_file"
                                        options={defaultRadioOptions}
                                        onChange={handleRadioChange}
                                        checkedOption={affiliate.disable_whisper_file}
                                        isClearable={false}
                                    />
                                </RightCell>
                            </TableRow>
                            <TableRowSpacer divider={true} />
                            <TableRowSpacer />
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        MP Form Leads{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    {tooltips.mp_form_api_access}
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <RadioGroup
                                        showLabel={false}
                                        name="mp_form_api_access"
                                        options={defaultRadioOptions}
                                        onChange={handleRadioChange}
                                        checkedOption={affiliate.mp_form_api_access}
                                        isClearable={false}
                                    />
                                </RightCell>
                            </TableRow>
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        MP Form Payout Model{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    “Billable Leads” means Service Direct
                                                    will pay for only the valid MP Form
                                                    Leads we bill our Clients; the MP Form
                                                    Bid Multiplier is not Locked. “Revenue
                                                    Share” means Service Direct will pay
                                                    for only valid MP Form Leads we bill
                                                    our Clients; the MP Form Bid
                                                    Multiplier is Locked because we have a
                                                    contract with the Affiliate that
                                                    determines the margin.
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <RadioGroup
                                        showLabel={false}
                                        name="mp_form_payout_model"
                                        options={[
                                            {
                                                label: 'Revenue Share',
                                                value: 'revenue_share',
                                            },
                                            {
                                                label: 'Billable Leads',
                                                value: 'billable_leads',
                                            },
                                        ]}
                                        onChange={handleRadioChange}
                                        checkedOption={affiliate.mp_form_payout_model}
                                        isClearable={false}
                                        hasError={!!errors?.mp_form_payout_model}
                                        errorMessage={errors?.mp_form_payout_model}
                                    />
                                </RightCell>
                            </TableRow>
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        Locked MP Form Bid Multiplier{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    When this is set to Yes, Service
                                                    Direct will use the Ad Nework's
                                                    default MP Form Bid Multiplier to
                                                    calculate the Effective Bid Amount,
                                                    instead of using the Campaign's Ad
                                                    Network level MP Form Bid Multiplier.
                                                    I.e. The Campaign level Lead Source Bid
                                                    Multiplier is overridden by the Ad
                                                    Network default.
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <RadioGroup
                                        showLabel={false}
                                        name="locked_mp_form_bid_multiplier"
                                        options={defaultRadioOptions}
                                        // onChange={handleRadioChange}
                                        checkedOption={
                                            affiliate.locked_mp_form_bid_multiplier
                                        }
                                        disabled={true}
                                        isClearable={false}
                                    />
                                </RightCell>
                            </TableRow>
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        Default MP Form Bid Multiplier{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    {
                                                        tooltips.default_mp_form_bid_multiplier
                                                    }
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <Input
                                        type="text"
                                        id="default_mp_form_bid_multiplier"
                                        name="default_mp_form_bid_multiplier"
                                        value={
                                            affiliate.default_mp_form_bid_multiplier || ''
                                        }
                                        onChange={handleTextChange}
                                        hasError={!!errors.default_mp_form_bid_multiplier}
                                        errorMessage={
                                            errors.default_mp_form_bid_multiplier
                                        }
                                        autocomplete="off"
                                        readOnly={isBidMultiplierReadOnly}
                                    />
                                </RightCell>
                            </TableRow>

                            <TableRowSpacer divider={true} />
                            <TableRowSpacer />
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        Feed{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    {tooltips.feed}
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <RadioGroup
                                        showLabel={false}
                                        name="feed"
                                        options={defaultRadioOptions}
                                        onChange={handleRadioChange}
                                        checkedOption={affiliate.feed}
                                        isClearable={false}
                                    />
                                </RightCell>
                            </TableRow>
                            <TableRow>
                                <LeftCell>
                                    <span className="type-heavy">
                                        SEM{' '}
                                        <Tooltip
                                            position="right"
                                            content={
                                                <TooltipContent className="type-normal-body">
                                                    {tooltips.sem}
                                                </TooltipContent>
                                            }
                                        />
                                    </span>
                                </LeftCell>
                                <RightCell>
                                    <RadioGroup
                                        showLabel={false}
                                        name="sem"
                                        options={defaultRadioOptions}
                                        onChange={handleRadioChange}
                                        checkedOption={affiliate.sem}
                                        isClearable={false}
                                    />
                                </RightCell>
                            </TableRow>
                            {!isNewAffiliate && (
                                <TableRow>
                                    <LeftCell>
                                        <span className="type-heavy">
                                            Active{' '}
                                            <Tooltip
                                                position="right"
                                                content={
                                                    <TooltipContent className="type-normal-body">
                                                        {tooltips.active}
                                                    </TooltipContent>
                                                }
                                            />
                                        </span>
                                    </LeftCell>
                                    <RightCell>
                                        <RadioGroup
                                            showLabel={false}
                                            name="active"
                                            options={defaultRadioOptions}
                                            onChange={handleRadioChange}
                                            checkedOption={affiliate.active}
                                            isClearable={false}
                                        />
                                    </RightCell>
                                </TableRow>
                            )}
                            {!isNewAffiliate && (
                                <>
                                    <TableRowSpacer divider={true} />
                                    <TableRowSpacer />
                                    <TableRow>
                                        <LeftCell>
                                            <span className="type-heavy">
                                                API Key{' '}
                                                <Tooltip
                                                    position="right"
                                                    content={
                                                        <TooltipContent className="type-normal-body">
                                                            The API Key is used by the
                                                            Affiliate to access the
                                                            Service Direct MP Phone and
                                                            Form APIs. It is sensitive
                                                            information that needs to be
                                                            protected. Use caution when
                                                            sharing this value. For more
                                                            information see the{' '}
                                                            <a href="https://github.com/Service-Direct/php-partners-sdk#request-a-bid---request-format">
                                                                MP Phone API instructions
                                                            </a>{' '}
                                                            on sending a request here.
                                                        </TooltipContent>
                                                    }
                                                />
                                            </span>
                                        </LeftCell>
                                        <RightCell>
                                            <TextCell>
                                                <form>
                                                    <HideableInput
                                                        id="api_key"
                                                        name="api_key"
                                                        readOnly={true}
                                                        value={affiliate.api_key || ''}
                                                        autocomplete="off"
                                                    />
                                                </form>
                                            </TextCell>
                                        </RightCell>
                                    </TableRow>
                                </>
                            )}
                        </tbody>
                    </Table>
                    {!isNewAffiliate && (
                        <div
                            className="spacing-20-bottom spacing-10-top"
                            style={{ paddingLeft: '5px' }}
                        >
                            <CopyToClipboard
                                dataToCopy={`API Key: ${affiliate.api_key}`}
                                buttonText="Copy API Credentials To Clipboard"
                            />
                        </div>
                    )}
                </TableWrapper>
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button onClick={onClose} variant="text">
                    <span className="type-heavy">Cancel</span>
                </Button>
                <Button
                    disabled={isPreviewDisabled}
                    onClick={() => {
                        if (validate(updates)) {
                            onPreview(_affiliate, updates, isNewAffiliate)
                        }
                    }}
                >
                    {previewButtonText}
                </Button>
            </ActionsWrapper>
        </div>
    )
}
