import React, {useRef, useState, useEffect} from 'react';

import {
    ButtonPauseSvg,
    ButtonPlaySvg,
    ButtonPlay1xSvg,
    ButtonPlay1dot5xSvg,
    ButtonPlay2xSvg,
    ButtonBack10sSvg,
    ButtonForward10sSvg,
    DownloadSvg,
} from './Svgs';

const LOCAL_STORAGE_KEY = 'PLAYBACK_RATE';

const AudioPlayer = (props) => {
    const {addLeadMessagePlayer, addAudioPlayerRefToLogRow, path} = props;
    const audioTrackRef: { current: HTMLAudioElement } = useRef();
    const timelineRef: { current: HTMLElement } = useRef();
    const [percentageProgress, setPercentageProgress] = useState('0');
    const [playbackRate, setPlaybackRate] = useState(1);

    const calculatePercentageProgress = () => {
        const audioTrack = audioTrackRef.current;
        if (audioTrack) {
            return `${audioTrack.currentTime / audioTrack.duration * 100}%`;
        }
        return '0';
    };

    const checkForFinishedTrack = () => {
        const audioTrack = audioTrackRef.current;
        if (audioTrack.currentTime >= audioTrack.duration) {
            audioTrack.currentTime = 0;
            props.updateAudioPlaying(false);
        }
    };

    // reload the audio track when the URL changes
    // this is a requirement when changing the source of an HTML audio element
    useEffect(() => {
        const audioTrack = audioTrackRef.current;
        audioTrack.load();
    }, [path]);

    useEffect(() => {
        addLeadMessagePlayer(audioTrackRef);
        addAudioPlayerRefToLogRow(audioTrackRef);

        // get the playback rate from local storage
        try {
            const playbackRate = Number(localStorage.getItem(LOCAL_STORAGE_KEY))
            if (playbackRate) {
                audioTrackRef.current.playbackRate = playbackRate
                setPlaybackRate(playbackRate)
            }
        } catch (error) {
            console.log('error getting playback rate from local storage', error)
        }
    }, [addLeadMessagePlayer, addAudioPlayerRefToLogRow]);

    const formattedDuration = () => {
        let audioTrack = audioTrackRef.current;

        //
        // An edge case exists where an audioTrack, or at least a URL to one,
        // is returned, but the file isn't valid, or may not exist, or
        // otherwise isn't playable.  We need UI to handle that, but for now
        // I've added a test on audioTrack.duration before attempting to
        // format the file's duration, which in the invalid edge case just
        // mentioned, results in NaN displaying to the user.  Certainly the far
        // less significant issue, but committing this is a small step toward
        // progress, even if only to document initial understanding of why NaN
        // has surfaced in this part of our code.  - mz 23 Sept 2019
        //
        if (audioTrack && audioTrack.duration) {
            const minutes = Math.floor(audioTrack.duration / 60);
            let seconds = Math.floor(audioTrack.duration - (minutes * 60));
            // format the total seconds to include leading 0 if needed
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            const currTimeMinutes = Math.floor(audioTrack.currentTime / 60);
            let currTimeSeconds = Math.floor(audioTrack.currentTime - (currTimeMinutes * 60));
            // format the current seconds to include leading 0 if needed
            if (currTimeSeconds < 10) {
                currTimeSeconds = `0${currTimeSeconds}`;
            }

            return `${currTimeMinutes}:${currTimeSeconds} / ${minutes}:${seconds}`;
        }

        return null;
    };

    const handleAudioTimeUpdate = () => {
        setPercentageProgress(calculatePercentageProgress());
        checkForFinishedTrack();
    };

    const handlePlayPauseClick = () => {
        const audioTrack = audioTrackRef.current;

        if (audioTrack.paused) {
            props.stopAllLeadMessagePlayers();
            audioTrack.play();
            if (props.onPlay) {
                props.onPlay();
            }
            props.updateAudioPlaying(true);
        }
        else {
            audioTrack.pause();
            props.updateAudioPlaying(false);
        }
    };

    const handlePause = () => {
        props.updateAudioPlaying(false);
    };

    const handlePlaybackRateClick = () => {
        const audioTrack = audioTrackRef.current;
        let newPlaybackRate;

        switch (playbackRate) {
            case 1:
                newPlaybackRate = 1.5;
                break;
            case 1.5:
                newPlaybackRate = 2;
                break;
            default: // also "case 2"
                newPlaybackRate = 1;
        }

        // setting the playback rate via JS. can not do it via an HTML attribute
        audioTrack.playbackRate = newPlaybackRate;
        setPlaybackRate(newPlaybackRate);

        try {
            localStorage.setItem(LOCAL_STORAGE_KEY, newPlaybackRate)
        } catch (error) {
            console.error('error setting local storage playback rate', error)
        }
    };

    const handleSkipClick = (amount) => {
        const audioTrack = audioTrackRef.current;
        audioTrack.currentTime += amount;
    };

    const handleTimelineClick = (e) => {
        const audioTrack = audioTrackRef.current;
        const timeline = timelineRef.current;

        const timelineRect = timeline.getBoundingClientRect();
        const proportionalClickPosition = (e.clientX - timelineRect.left) / timelineRect.width;

        audioTrack.currentTime = proportionalClickPosition * audioTrack.duration;

        props.updateAudioPlaying(true);
        setPercentageProgress(calculatePercentageProgress());
    };

    const handleErrorLoading = () => {
        props.onError();
    }

    return <>
        <div className="audioplayer">
            <audio ref={audioTrackRef}
                   onPause={handlePause}
                   onError={handleErrorLoading}
                   onTimeUpdate={handleAudioTimeUpdate}>
                <source src={props.path} type={props.format}/>
            </audio>
            <div className="audioplayer__left-side">
                <div className="audioplayer__cell audioplayer__button">
                    <button className="button-clean" onClick={handlePlayPauseClick}>
                        {props.audioPlaying
                            ? ButtonPauseSvg
                            : ButtonPlaySvg}
                    </button>
                </div>
                <div onClick={handleTimelineClick}
                     ref={timelineRef}
                     className="audioplayer__cell audioplayer__timeline">
                    <div className="audioplayer__timeline__background">
                        <div
                            className="audioplayer__timeline__progress"
                            style={{width: percentageProgress}}
                        />
                    </div>
                </div>
            </div>
            <div className="audioplayer__cell audioplayer__timestamp">
                <div className="type-normal-body type-single-line">{formattedDuration()}</div>
            </div>
        </div>
        <div className="audioplayer__playback-control-row">
            <div>
                <button className="audioplayer__playback-control"
                        onClick={handlePlaybackRateClick}>
                    {playbackRate === 1 && ButtonPlay1xSvg}
                    {playbackRate === 1.5 && ButtonPlay1dot5xSvg}
                    {playbackRate === 2 && ButtonPlay2xSvg}
                </button>
                <button className="audioplayer__playback-control"
                        onClick={() => handleSkipClick(-10)}>
                    {ButtonBack10sSvg}
                </button>
                {' '}
                <button className="audioplayer__playback-control"
                        onClick={() => handleSkipClick(10)}>
                    {ButtonForward10sSvg}
                </button>
            </div>
            <div>
                <a className="type-normal-body type-blue"
                   href={props.path}
                   download={`${props.leadId}.mp3`}
                   target="_blank"
                   rel="noopener">
                    <span className="inline-icon inline-icon__middle inline-icon__20">
                        {DownloadSvg}
                    </span>{' '}
                    Download Call
                </a>
            </div>
        </div>
    </>;
};

export default AudioPlayer;


export const AudioPlayerSkeleton = () => {
    return <>
        <div className="audioplayer">
            <div className="audioplayer__left-side">
                <div className="audioplayer__cell audioplayer__button">
                    <button className="button-clean"
                            disabled={true}>
                        <>{ButtonPlaySvg}</>
                    </button>
                </div>
                <div className="audioplayer__cell audioplayer__timeline">
                    <div className="audioplayer__timeline__background">
                        <div className="audioplayer__timeline__progress" style={{width: '0'}}/>
                    </div>
                </div>
            </div>
            <div className="audioplayer__cell audioplayer__timestamp">
                <div className="type-normal-body type-single-line">
                </div>
            </div>
        </div>
        <div className="audioplayer__playback-control-row">
            <div>
                <button className="audioplayer__playback-control disabled">
                    {ButtonPlay1xSvg}
                </button>
                <button className="audioplayer__playback-control disabled">
                    {ButtonBack10sSvg}
                </button>
                {' '}
                <button className="audioplayer__playback-control disabled">
                    {ButtonForward10sSvg}
                </button>
            </div>
            <div>
                <span className="audioplayer__playback-control disabled">
                    <span className="inline-icon inline-icon__middle inline-icon__20">
                        {DownloadSvg}
                    </span>{' '}
                    Download Call
                </span>
            </div>
        </div>
    </>;
}
