import React from 'react';
import PropTypes from 'prop-types';

const TextArea = props => {
    let inputClass = "ui-textarea ui-normal-text-input",
        labelClass = "type-small-body type-heavy type-narrow-line-height spacing-5-bottom",
        ref = props.inputRef || React.createRef();

    if (props.hasError || props.errorMessage) {
        inputClass += " ui-text-field ui-alert";
        labelClass += " type-alert";
    }

    if (props.className) {
        inputClass += ' ' + props.className;
    }

    if (props.disabled) {
        labelClass += " type-grey-2"
        inputClass += " disabled"
    }

    return <>
        <label htmlFor={props.label} className={labelClass}>{props.label}</label>
        {props.displayRequiredAsText
            ? ' (required)'
            : null}
        <textarea
            id={props.id || props.name}
            name={props.name}
            className={inputClass}
            value={props.value}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            onChange={props.onChange}
            required={props.required}
            ref={ref}
            data-position-in-group={props.positionInGroup}
            data-state-input-key={props.stateInputKey}
            data-state-value-property={props.stateValueProperty}
            disabled={props.disabled}
        />
        {props.errorMessage != null
            ? <div className="input-error">{props.errorMessage}</div>
            : null}
    </>;
};

export default TextArea;

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    displayRequiredAsText: PropTypes.bool,
    inputRef: PropTypes.object,
    className: PropTypes.string
};
