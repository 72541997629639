import styled from 'styled-components'
import Button from '../Button'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const getAlignmentForProps = (align) => {
    if (align === 'left') {
        return 'flex-start'
    } else if (align === 'right') {
        return 'flex-end'
    } else if (align === 'center') {
        return 'middle'
    } else {
        return 'space-between'
    }
}

export const Inputs = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
    justify-content: ${(props) => getAlignmentForProps(props.align)};
    align-items: ${(props) => (props.vertical ? 'flex-start' : 'center')};;
`

export const InputWrapper = styled.div`
    margin: 8px 12px 8px 0;
    width: ${(props) => (props.width ? `${props.width}px` : '')};
`

export const LabelWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.32258rem;
`

export const LabelText = styled.span`
    cursor: default;
    line-height: 140%;
    font-weight: 700;
    margin-right: 7px;
`

export const ClearButton = styled(Button)`
    padding: 5px;
`
