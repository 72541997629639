import React, { Component } from 'react';
import FloatingSaveButton from './FloatingSaveButton';

/***
 * .floating-buttons__block with the additional class .floating-buttons__block__under-header
 * has a top position that accommodates the visible header. Remove the class to position correctly 
 * when header is not visible. If/when a sticky header is implemented, under-header
 * presentation would be the default.
 * 
 */

export default class FloatingButtonsBlock extends Component {
  render() {
    return (
      <div className="floating-buttons__block floating-buttons__block__under-header">
          <div className="page-width">
              <div className="floating-buttons__position">
                    <div className="floating-buttons__wrapper">
                        <FloatingSaveButton
                            onClick={ this.props.onClick }
                            disabled={ this.props.disabled }
                        />
                    </div>
              </div>
          </div>
      </div>
    );
  }
}