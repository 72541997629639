import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PlusSvg, SvgWrapper } from '../../Svgs'

const getBackgroundForProps = (props) => {
    const { variant = 'primary', disabled } = props
    return (
        {
            primary: disabled ? '#adc3db' : '#0094d9',
        }[variant] || 'transparent'
    )
}

const getBorderForProps = (props) => {
    const { variant, disabled } = props
    const color = disabled ? '#adc3db' : '#0094d9'
    return (
        {
            outline: `solid 1px ${color}`,
        }[variant] || 'none'
    )
}

const getTextColorForProps = (props) => {
    const { variant, disabled } = props
    const color = disabled ? '#adc3db' : '#0094d9'
    return (
        {
            primary: '#fff',
        }[variant] || color
    )
}

const getHoverBackgroundForProps = (props) => {
    const { variant = 'primary', disabled } = props
    if (disabled) {
        return (
            {
                primary: '#c3c6c9',
                span: 'none',
                text: 'none',
            }[variant] || '#adc3db'
        )
    }
    return (
        {
            primary: '#007fba',
            span: 'none',
            text: 'none',
        }[variant] || '#e8f4ff'
    )
}

const getHoverTextColorForProps = (props) => {
    const { variant, disabled } = props
    const color = disabled ? '#adc3db' : '#0094d9'
    if (disabled) {
        return (
            {
                primary: '#fff',
            }[variant] || '#adc3db'
        )
    }
    return (
        {
            primary: '#fff',
            span: '#007fba',
        }[variant] || color
    )
}

const getPaddingForProps = (props) => {
    const { size, variant } = props
    // if (variant === 'span' && !size) {
    if (variant === 'span') {
        return '0px'
    }
    const paddings = {
        small: '8px 16px',
        default: '0.8378035009em 1.5em',
        large: '23px 29px',
    }
    return paddings[size] || paddings.default
}

const StyledButton = styled.button`
    margin: ${(props) => (props.variant === 'span' ? 0 : '0 10px')};
    background-color: ${(props) => getBackgroundForProps(props)};
    border: ${(props) => getBorderForProps(props)};
    border-radius: 5px;
    font-weight: 400;
    padding: ${(props) => getPaddingForProps(props)};
    color: ${(props) => getTextColorForProps(props)};
    /* height: 100%; */
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    &:hover {
        background-color: ${(props) => getHoverBackgroundForProps(props)};
        color: ${(props) => getHoverTextColorForProps(props)};
    }
`
const getTopTextPadding = (props) => {
    const { size, variant } = props
    // if (variant === 'span' && !size) {
    if (variant === 'span') {
        return '1px'
    }
    const paddings = {
        small: '1px',
        default: '2px',
        large: '3px',
    }
    return paddings[size] || paddings.default
}

const ButtonText = styled.span`
    line-height: 160%;
    padding-top: ${(props) => getTopTextPadding(props)};
    font-weight: 400;
    text-transform: ${(props) => (props.variant === 'span' ? 'none' : 'uppercase')};
    font-family: inherit;
`

const Button = forwardRef((props, ref) => {
    const { onClick = () => null, variant = 'primary', children, ...rest } = props
    // if the button only contains text, wrap it in the
    // button text component and render. otherwise, render
    // whatever is provided as is
    let buttonText = children
    if (typeof children === 'string') {
        const textClasses = {
            large: 'type-normal-body',
            default: 'type-small-body',
            small: 'type-extra-small-body',
        }
        const textClass = textClasses[props?.size] || textClasses['default']
        buttonText = <ButtonText className={textClass} {...rest} variant={variant}>{children}</ButtonText>
    }
    return (
        <StyledButton ref={ref} variant={variant} onClick={onClick} {...rest}>
            {buttonText}
        </StyledButton>
    )
})

Button.propTypes = {
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(['primary', 'outline', 'text', 'span']),
    size: PropTypes.oneOf(['small', 'medium', 'large', 'default']),
    disabled: PropTypes.bool,
}
const getIconBoxWidth = props => {
    if (props?.width) {
        return props.width
    }
    if(props.size == 'small') {
        return '20px'
    } else {
        return '25px'
    }
}
const getIconBoxHeight = props => {
    if (props?.height) {
        return props.height
    }
    if(props.size == 'small') {
        return '15px'
    } else {
        return '20px'
    }
}
const IconBox = styled.div`
    display: flex;
    justify-content: flex-start;
    width: ${getIconBoxWidth};
    height: ${getIconBoxHeight};
`

const IconButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`

const Spacer = styled.div`
    width: ${(props) => (props.size == 'small' ? '5px' : '6px')};
`

export const IconButton = ({ icon, children, ...rest }) => {
    return (
        <Button {...rest}>
            <IconButtonWrapper>
                <IconBox size={rest.size} {...rest}>
                    <SvgWrapper>{icon}</SvgWrapper>
                </IconBox>
                {children && <Spacer size={rest.size} />}
                {children}
            </IconButtonWrapper>
        </Button>
    )
}

const StyledActionButton = styled(StyledButton)`
    height: 64px;
    width: 64px;
    border-radius: 32px;
    padding: 0;
    position: ${({ fixed }) => (fixed ? 'fixed' : '')};
    bottom: 50px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1);
    right: 50px;
`

export const ActionButton = (props) => {
    return (
        <StyledActionButton {...props}>
            <SvgWrapper fill="white" height={30} width={30}>
                {PlusSvg}
            </SvgWrapper>
        </StyledActionButton>
    )
}

export default Button
