import React, {useCallback, useMemo, useState, memo, useRef} from 'react';
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import cloneDeep from "lodash/cloneDeep";

import Input from "../../common/components/form_elements/Input";
import MultiSelectList from "../../common/components/form_elements/MultiSelectList";
import {Delete, IconArrowRightSvg, IconLockSvg, QuestionMarkSvg} from "../../common/Svgs";
import Checkbox from "../../common/components/form_elements/Checkbox";
import {formatCurrency} from "../../Util";
import type {IIndustry} from "../../Interfaces/IIndustry";
import type {IAdNetwork} from "../../Interfaces/IAdNetwork";
import type {IIndustryConnection} from "../../Interfaces/IIndustryConnection";
// import type {IIndustryRule} from "../../Interfaces/IIndustryRule";
import type {IOption} from "./ConfigureIndustriesModal";

function IndustryRow(props) {
    const industry: IIndustry = cloneDeep(props.industry);
    const {index, adNetworks, updateIndustry} = props;
    const identifierTooltipRef = useRef();

    const [expanded, setExpanded] = useState(false);
    const [identifierTooltipVisibility, setIdentifierTooltipVisibility] = useState(false);
    const [deleteTooltipVisibility, setDeleteTooltipVisibility] = useState(false);

    const adNetworksById: { [adNetworkId: number]: IAdNetwork } = useMemo(() => {
        const adNetworksById = {};
        adNetworks.forEach((adNetwork: IAdNetwork) => {
            adNetworksById[adNetwork.adNetworkId] = adNetwork;
        })
        return adNetworksById;
    }, [adNetworks]);

    const industryRules = useMemo(() => {
        return props.industryRules
            .filter((rule: IIndustryRule) => rule.industryId == industry.industryId);
    }, [props.industryRules, industry.industryId]);

    const industryOptions = useMemo(() => {
        const industryOptions: IOption[] = cloneDeep(props.industryOptions);

        // if there's an industry id, set it as an enabled option
        if (industry.industryId) {
            const selectedOption = industryOptions
                .find((option) => option.value == industry.industryId);
            selectedOption.disabled = false;
        }
        return industryOptions;

    }, [props.industryOptions, industry.industryId]);

    function calculatePhoneMultiplierValue(connection: IIndustryConnection) {
        if (connection.phoneFlatBidOverride) {
            return formatCurrency(connection.phoneFlatBidOverride);
        }

        return adNetworksById[connection.adNetworkId].lockedMpPhoneBidMultiplier == 1
            ? adNetworksById[connection.adNetworkId].mpPhoneBidMultiplier
            : connection.multiplier;
    }

    function isLocked(connection: IIndustryConnection) {
        return connection.phoneFlatBidOverride > 0 || connection.lockedPhoneMultiplier == 1;
    }

    const toggleConnection = (adNetworkId) => {
        const connection = industry.connections
            .find((connection: IIndustryConnection) => connection.adNetworkId == adNetworkId);
        connection.connectedMpPhonesApi = !(connection.connectedMpPhonesApi == 1);
        updateIndustry(index, industry);
    }

    const updateMultiplier = (adNetworkId, value) => {
        const connection = industry.connections
            .find((connection: IIndustryConnection) => connection.adNetworkId == adNetworkId);
        connection.multiplier = value;
        updateIndustry(index, industry);
    }

    const updateIndustrySelection = useCallback((selections) => {
        industry.industryId = selections[0];
        updateIndustry(index, industry);

        // set a timeless timeout to execute a focus on the selected industry identifier after the state changes
        setTimeout(() =>
            document.querySelector(`#identifier-${industry.industryId}`)
                .focus());
    }, [industry, index, updateIndustry]);

    const updateIdentifier = (event) => {
        // if not in edit, and we are setting value to the identifier, expand it
        if (!industry.campaignId && !industry.identifier) {
            setExpanded(true);
        }
        industry.identifier = event.target.value;
        updateIndustry(index, industry);
    }

    const toggleRow = () =>
        setExpanded(!expanded);

    const deleteRow = () =>
        props.deleteIndustry(index);

    const toggleRowIdentifierTooltip = (updateValue = false) => {
        setIdentifierTooltipVisibility(!identifierTooltipVisibility);
        if (updateValue === true) {
            industry.identifier = identifierTooltipRef.current.value;
            updateIndustry(index, industry);
        }
    }

    const toggleRowDeleteTooltip = () => {
        setDeleteTooltipVisibility(!deleteTooltipVisibility);
    }

    /**
     * check whether a row can be expanded or not
     * @returns {boolean}
     */
    const canExpandRow = () =>
        (industry.industryId && industry.identifier) || expanded;

    const expandButtonClass = () => {
        let classNames = "inline-icon inline-icon__middle inline-icon__20 expand-arrow";

        // when the row is expanded, add the expanded class to the button so its direction is flipped
        if (expanded) {
            classNames += ' expanded';
        }

        // if the row does not have an industry selected, disable the expand button
        if (!canExpandRow()) {
            classNames += ' disabled';
        }

        return classNames;
    }

    /**
     * returns true iff the row's modifiers are partially checked
     */
    const isPartiallySelected = useCallback(() => {
            let hasConnected = false;
            let hasDisconnected = false;
            industry.connections.forEach((connection: IIndustryConnection) => {
                if (connection.connectedMpPhonesApi == 1) {
                    hasConnected = true;
                }
                else {
                    hasDisconnected = true;
                }
            });

            return hasConnected && hasDisconnected;
        },
        [industry.connections]);


    /**
     * check if the ad network has a no phone by default rule
     * @param {number} adNetworkId
     * @returns {boolean} returns true if the ad network has a no phone by default rule
     */
    const isNoPhoneByDefault = useCallback((adNetworkId: number) => {
        return industryRules.findIndex((rule: IIndustryRule) =>
            rule.adNetworkId == adNetworkId && rule.noPhoneByDefault == 1) > -1;
    }, [industryRules]);

    /**
     * returns true iff all the connections are connected
     * @returns {boolean}
     */
    const isFullySelected = useCallback(() => {
            // does not exist a connection that is not connected
            const connectedToPhones = industry.connections
                .find((connection: IIndustryConnection) =>
                    connection.connectedMpPhonesApi == 0 && !isNoPhoneByDefault(connection.adNetworkId));
            return !connectedToPhones;
        },
        [industry.connections, isNoPhoneByDefault]);

    const updateSelectAll = () => {
        // check if at least one ad network is connected
        const hasAConnection = industry.connections
            .find((connection: IIndustryConnection) =>
                connection.connectedMpPhonesApi == 1);

        // update all the connection so that they are connected iff there is at least on connection
        industry.connections.forEach((connection: IIndustryConnection) => {
            if (hasAConnection) {
                connection.connectedMpPhonesApi = false;
            }
            else {
                connection.connectedMpPhonesApi = !isNoPhoneByDefault(connection.adNetworkId);
            }
        });
        updateIndustry(index, industry);
    }

    return <div className="industry__selection-row">
        <div className="popup__form__row">
            <div
                className="popup__form__cell popup__form__cell__40 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                <MultiSelectList
                    name={`industryId-${industry.industryId || Math.random()}`}
                    label="Service Category"
                    selections={industry.industryId ? [industry.industryId] : []}
                    options={industryOptions}
                    onChange={updateIndustrySelection}
                    useTextFilter={true}
                    disableVirtualList={true}
                    readOnly={industry.inEdit}
                    searchFromBeginning={false}/>
            </div>
            <div
                className="popup__form__cell popup__form__cell__40 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                <label htmlFor={`identifier-${industry.industryId}`} className="type-small-body type-heavy">
                    Service Category Mapping ID
                    <Tippy
                        content={<>
                            The identifier value of a Service Category that the External Lead Buyer (ELB) expects
                            in their API. Some ELBs require more than one value. See the{' '}
                            <a href="https://drive.google.com/drive/folders/1uENVNTcYMnRS74akMgOCRsRZgY_wNjI1"
                               target="_blank">
                                ELBs API instructions for details.
                            </a>
                        </>}
                        theme="sd-tooltip"
                    >
                        <div className="inline-icon icon-help">
                            {QuestionMarkSvg}
                        </div>
                    </Tippy>
                </label>
                <div className="input-group">
                    <Input
                        type="text"
                        name={`identifier-${industry.industryId}`}
                        disabled={!industry.industryId}
                        value={industry.identifier || ''}
                        onChange={updateIdentifier}
                        required={true}
                        readOnly={industry.inEdit}
                        placeholder="Required"
                        maxLength={64}/>
                    {industry.inEdit &&
                        <Tippy
                            content={<>
                                <Input type="text"
                                       label="Edit Service Category Mapping ID"
                                       inputRef={identifierTooltipRef}
                                       defaultValue={industry.identifier || ''}
                                       name="apiKeyEdit"/>
                                <div className="spacing-10-top">
                                    <button type="button"
                                            onClick={() => toggleRowIdentifierTooltip(true)}
                                            className="button ui-normal-button no-margin">
                                        Update
                                    </button>
                                    <button type="button"
                                            onClick={toggleRowIdentifierTooltip}
                                            className="button-clean type-blue">
                                        Cancel
                                    </button>
                                </div>
                            </>}
                            allowHTML={true}
                            visible={identifierTooltipVisibility}
                            appendTo={() => document.body}
                            interactive={true}
                            theme="sd-tooltip"
                        >
                            <div className="button-clean type-blue input-addon"
                                 role="button"
                                 onClick={toggleRowIdentifierTooltip}>
                                Edit
                            </div>
                        </Tippy>}
                </div>
            </div>
            <div className="popup__form__cell popup__form__cell__20 action__buttons-cell">
                <span className={expandButtonClass()}
                      onClick={() => toggleRow()}
                      role="button">
                    {IconArrowRightSvg}
                </span>
                <Tippy
                    content={<>
                        Are you sure you want to delete this Service Category?
                        <div className="spacing-10-top">
                            <button type="button"
                                    onClick={deleteRow}
                                    className="button ui-normal-button no-margin">
                                Delete
                            </button>
                            <button type="button"
                                    onClick={toggleRowDeleteTooltip}
                                    className="button-clean type-blue">
                                Cancel
                            </button>
                        </div>
                    </>}
                    allowHTML={true}
                    visible={deleteTooltipVisibility}
                    appendTo={() => document.body}
                    interactive={true}
                    theme="sd-tooltip">
                    <span className="inline-icon inline-icon__middle inline-icon__24"
                          role="button"
                          onClick={industry.industryId ? toggleRowDeleteTooltip : deleteRow}>
                        {Delete}
                    </span>
                </Tippy>
            </div>
        </div>

        {expanded && <div className="ad-networks__segment spacing-20-top">
            <table>
                <thead>
                <tr>
                    <th className="type-small-body">Lead Sources</th>
                    <th className="type-small-body">
                        Phone Bid Multiplier
                        <Checkbox name={`select-all-phone-${industry.industryId}`}
                                  label="Select All"
                                  checked={isFullySelected()}
                                  onChange={updateSelectAll}
                                  partiallyChecked={isPartiallySelected()}/>
                    </th>
                    <th className="type-small-body type-grey">
                        Form Bid Multiplier
                        <Checkbox name={`select-all-form-${industry.industryId}`}
                                  disabled={true}
                                  label="Select All"/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {industry.connections.map((connection) => {
                    if (!adNetworksById[connection.adNetworkId]) {
                        return null;
                    }

                    return <tr key={'industry-' + connection.adNetworkId}>
                        <td>{adNetworksById[connection.adNetworkId].adNetworkName}</td>
                        <td>
                            <div className="input-group">
                                <div className="input-addon input-addon-checkbox">
                                    <Checkbox
                                        checked={connection.connectedMpPhonesApi == 1}
                                        onChange={() => toggleConnection(connection.adNetworkId)}
                                        name={'industry-phone-enable-' + industry.industryId + '-' + connection.adNetworkId}/>
                                </div>
                                {isLocked(connection)
                                    ? <>
                                        <Input type="text"
                                               defaultValue={calculatePhoneMultiplierValue(connection)}
                                               disabled={true}
                                               className="input-field input-field-multiplier"
                                               name={'industry-phone-multiplier-' + connection.adNetworkId}/>
                                        <div className="input-addon">
                                            <Tippy
                                                content={connection.phoneFlatBidOverride
                                                    ? 'This Lead Source is configured to use a Flat Bid Amount for this Service Category and therefore cannot be changed.'
                                                    : 'This Lead Source has a Locked Phone Bid Multiplier and therefore it cannot be changed.'}
                                                theme="sd-tooltip">
                                                <span
                                                    className="inline-icon inline-icon__20 disabled">{IconLockSvg}</span>
                                            </Tippy>
                                        </div>
                                    </>
                                    : <Input type="number"
                                             step={0.01}
                                             min={0.01}
                                             max={1}
                                             value={calculatePhoneMultiplierValue(connection)}
                                             onChange={(event) =>
                                                 updateMultiplier(connection.adNetworkId, event.target.value)}
                                             className={"input-field input-field-multiplier" +
                                                 (connection.connectedMpPhonesApi == 1 ? '' : ' input-addon-not-checked')}
                                             name={'industry-phone-multiplier-' + industry.industryId + '-' + connection.adNetworkId}/>}
                            </div>
                        </td>
                        <td>
                            <div className="input-group">
                                <div className="input-addon input-addon-checkbox">
                                    <Checkbox
                                        checked={false}
                                        disabled={true}
                                        name={'industry-form-enable-' + industry.industryId + '-' + connection.adNetworkId}/>
                                </div>
                                <Input type="text"
                                       defaultValue={calculatePhoneMultiplierValue(connection)}
                                       disabled={true}
                                       className="input-field input-field-multiplier"
                                       name={'industry-form-multiplier-' + industry.industryId + '-' + connection.adNetworkId}/>
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>}
    </div>;
}

export default memo(IndustryRow);

IndustryRow.propTypes = {
    index: PropTypes.number,
    industry: PropTypes.object,
    industryOptions: PropTypes.array,
    adNetworks: PropTypes.array,
    industryRules: PropTypes.array,
    updateIndustry: PropTypes.func,
    deleteIndustry: PropTypes.func
}
