import React, {Component} from 'react';

export default class AdminHome extends Component {
    constructor(props) {
        super(props);
        document.title = 'Service Direct';
    }

    render() {
        return <section className="interface-container">
            <div className="page-width">
                <div className="row padding-50-top padding-20-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8">
                        <div className="module">
                            <div className="module__header module__twothirdswidth padding-20-top">
                                <h3 className="type-large-subhead type-single-line">
                                    Welcome to mySD!
                                </h3>
                            </div>
                            <div className="module__contentbox module__twothirdswidth">
                                <div className="spacing-40-bottom">
                                    <p className="type-large-body type-heavy type-single-line no-margin-top spacing-10-bottom">
                                        This is the mySD Admin Dashboard view
                                    </p>
                                    <p className="type-normal-body spacing-10-bottom">
                                        You're logged in as an Admin user right now.
                                        Use the menu to navigate to the page of your choice.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
            </div>
        </section>;
    }
}
