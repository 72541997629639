import React from 'react'
import LeadDetailBlock from '../LeadDetailBlock'
import BasicCallInsights from './BasicCallInsights'
import CSRScorecard from './CSRScorecard'

export default (props) => {
    return (
        <LeadDetailBlock title="Call Insights">
            <BasicCallInsights
                {...props}
            />
            <CSRScorecard
                {...props}
            />
        </LeadDetailBlock>
    )
}
