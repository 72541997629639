import styled from 'styled-components'
import { Row } from '../../../../common/layouts'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* max-height: 500px; */
    /* min-height: 400px; */
    padding: 1.548387097rem 2.064516129rem;
    /* overflow-y: scroll; */
`

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const Table = styled.table`
    /* margin-bottom: 20px; */
    width: 100%;
`

export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.548387097rem 2.064516129rem;
    padding-bottom: 0;
`

export const TableRow = styled.tr``

export const TableRowSpacer = styled.tr`
    border-bottom: ${(props) => (props.divider ? 'solid 1px #d1d9e2' : 'none')};
    height: 14px;
`

export const LeftCell = styled.td`
    /* text-align: right; */
    padding: 5px 10px;
    /* width: 20%; */
    vertical-align: middle;
`

export const RightCell = styled(LeftCell)`
    text-align: left;
`

export const HeaderCell = styled.th`
    padding: 10px;
    text-align: left;
    background-color: #002043;
    height: 48px;
    vertical-align: middle;
`

export const TextCell = styled.div`
    min-height: 40px;
    display: flex;
    align-items: center;
`

export const TooltipContent = styled.span`
    display: inline-block;
    text-align: left;
    width: 100%;
`

export const ActionsWrapper = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    bottom: 0;
    justify-content: flex-end;
    background-color: #f0f3f6;
    padding: 25px 20px;
`

export const ContentRow = styled(Row)`
    padding: 0 30px 20px 30px;
    &:first-of-type {
        padding-top: 20px;
    }
    align-items: center;
`
