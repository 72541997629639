import React from 'react';

const HighlightedNote = (props) => {
	const extraClass = props.extraClass ?? '';
	
	return (
		<div className={`highlighted-note__block ${extraClass}`}>
			<p className="type-large-body type-heavy type-allcaps no-margin">
				{props.header}
			</p>
			<div className="type-normal-body no-margin">
				{props.body}
			</div>
		</div>
	);
};

export default HighlightedNote;