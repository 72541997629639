import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import post from "../Requests";
import { upperAllFirst } from "../Util";
import {defaultClientUri} from "../Config";

export default class PasswordResetView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {
                password: '',
                password_confirmation: '',
            },
            password: '',
            password_confirmation: '',
            submitted: false,
            messages: [],
            redirectToLogin: false,
            isLoading: true,
            userLastLogin: null
        }

        this.cancelSignal = axios.CancelToken.source();
    }

    componentDidMount() {
        let token = this.props.match.params.token;
        let payload = {token};

        post("auth/check_password_reset_token", payload, this.cancelSignal.token)
            .then((resp) => {
                if(resp.status === 200) {
                    let userLastLogin = resp.data.data.user.last_login;

                    this.setState({
                        userLastLogin: userLastLogin,
                        isLoading: false
                    });
                }
            });
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
    }

    submit = (event) => {
        event.preventDefault();
        let hasError;
        let stateClone = { ...this.stateClone };
        let errorClone = { ...stateClone.errors };

        for (let fieldName in this.state.errors) {
            if (this.state.errors.hasOwnProperty(fieldName)) {
                let elem = document.getElementById(fieldName);
                errorClone[fieldName] = this.validate(elem);
            }
        }

        hasError = errorClone.password || errorClone.password_confirmation;

        // Don't submit if we have an error
        if (hasError) {
            this.setState({errors : errorClone});
            return false;
        }

        // Don't submit if we have already have a request out
        if (this.state.submitted === true) {
            return;
        }

        // set submitted to true
        this.setState({submitted: true});

        let passwordElem = document.getElementById("password");
        let passwordConfirmationElem = document.getElementById("password_confirmation");

        // get the values from the inputs
        let password = passwordElem.value;
        let passwordConfirmation = passwordConfirmationElem.value;

        // get the token from the url
        let token = this.props.match.params.token;

        // build out the payload
        let payLoad = {
            password,
            password_confirmation: passwordConfirmation,
            token
        };

        post("auth/password_reset", payLoad, this.cancelSignal.token)
            .then((resp) => {
                if (resp.status >= 400) {
                    let errorMessage = resp.message;
                    let newMessage = [{text: errorMessage, type: "error"}];
                    this.setState({submitted: false, messages: newMessage});
                    this.props.updateMessageBlocks(resp.data.message, 'error');
                }
                if (resp.status == 200) {
                    /**
                     * This submits back to the login endpoint, for auto-login.
                     * We could possible handle this a different way, but the
                     * login endpoint handles a lot of checks that I didn't want
                     * to duplicate.
                     */
                    let username = resp.data.username;
                    this.autoLogin(username, password);
                    // this.setState({redirectToLogin: true});
                    this.props.updateMessageBlocks(resp.data.message, 'success');
                }
            }
        )
    }

    autoLogin = (username, password) => {
        let payLoad = {username, password};

        post("auth", payLoad, this.cancelSignal.token)
            .then((resp) => {
                /**
                 * Choosing to early return (before 200/401 status checks)
                 * if we don't have a fully-formed response object.
                 *
                 * This early return was introduced (though since modified)
                 * in commit ed7c903f Mon Nov 26 2018.
                 *
                 * ~ RFM 2019-03-05, excavating
                 */
                if (typeof resp === "undefined" || !('data' in resp)) {
                    return;
                }

                if (resp.status === 200) {
                    this.setState({ submitted: false });

                    this.props.postLogin(resp.data);

                    //
                    // #NL 2020-06-12
                    // overwrite the default behavior fo redirecting to the last URL before the user was logged in
                    // which in this case would be the "https://mysd.servicedirect.com/login-help/{token}"
                    //
                    this.props.history.push(defaultClientUri);
                }
            })
            // 4xx response, etc
            .catch((response) => {
                let errorClone = { ...this.state.errors };

                errorClone.password = response.response.data.errors[0].message;

                this.setState({errors: errorClone});
            });
    }

    /**
     * Validate the fields are good
     *   Checks if it has a value
     *   if password confirmation check that it matches password
     *   Updates messaging if there is an error
     **/
    validate = (elem) => {
        //let message;
        //let name = elem.name;
        let error = '';
        let displayName = elem.name.replace("_", " ");

        // comfirm password confirmation same as password
        if (elem.name === "password_confirmation") {
            if (elem.validity.valueMissing) {
                error = upperAllFirst(displayName) + " is required.";
            }
            else if (elem.value !== document.getElementById("password").value) {
                error = "Confirmation password doesn't match password.";
            }
        }
        else {
            // Handle if the value is missing
            if (elem.validity.valueMissing) {
                error = upperAllFirst(displayName) + " is required.";
            }
        }

        return error;
    }

    render() {
        // redirects
        if (this.state.isLoading) {
            return null;
        }

        if (this.state.redirectToLogin) {
             return <Redirect to={{
                    pathname: "/login",
                    state: { message: "Please login with your new password" }
                }}
            />
        }

        let passwordError = this.state.errors.password;
        let passwordConfirmationError = this.state.errors.password_confirmation;
        let hasError = passwordError || passwordConfirmationError;

        const ErrorMessages = () => {
            if (!hasError) {
                return null;
            }

            return <div className="login-error">
                <p className="type-normal-body type-alert type-heavy type-single-line no-margin-top spacing-10-bottom">
                    PASSWORD RESET ERROR
                </p>
                <p className="type-small-body type-alert type-single-line spacing-10-top spacing-30-bottom">
                    <span className='error-message'>
                        { passwordError }
                    </span>
                    <span className='error-message'>
                        { passwordConfirmationError }
                    </span>
                </p>
            </div>;
        }

        let actionWord = this.state.userLastLogin ? "Reset" : "Set",
            passwordPhrase = this.state.userLastLogin ? "new password" : "password";

        return <div className="login-screen">
            {this.props.children}
            <div className="login__gradient-shade" style={this.props.loginGradientStyle()} />
            <div className="login__content-column">
                <div className="login__logo-frame spacing-20-bottom">
                    <img src={ this.props.whiteLabelPartnership.logo_uri_for_login_page }
                         alt={ this.props.whiteLabelPartnership.white_label_name }
                    />
                </div>
                <div className="login__content-box">
                    <div className="login__content-header">
                        <h1 className="type-small-headline type-heavy type-single-line no-margin">
                            {actionWord}  Your Password
                        </h1>
                    </div>
                    <div className="login__content">
                        <p className="type-normal-body type-heavy type-single-line no-margin-top spacing-10-bottom">
                            {actionWord}  Your Password
                        </p>
                        <p className="type-small-body type-single-line spacing-10-top spacing-30-bottom">
                            Please enter a {passwordPhrase} for your {this.props.whiteLabelPartnership.white_label_app_name} account
                        </p>
                        <ErrorMessages />
                        <form>
                            <div className="no-margin-top spacing-10-bottom">
                                <label htmlFor="password" className={ "type-small-body type-heavy type-single-line no-margin " + ((passwordError) ? "type-alert" : '')}>
                                    {upperAllFirst(passwordPhrase)}
                                </label>
                            </div>

                            <div className="no-margin-top spacing-20-bottom">
                                <input
                                    type="password"
                                    className={"ui-text-field ui-normal-text-input" + (passwordError ? " ui-alert" : '') }
                                    name="password"
                                    id="password"
                                    required
                                />
                            </div>
                            <div className="no-margin-top spacing-10-bottom">
                                <label htmlFor="password_confirmation" className={ "type-small-body type-heavy type-single-line no-margin " + ((passwordConfirmationError) ? "type-alert" : '')}>
                                    Password Confirmation
                                </label>
                            </div>
                            <div className="no-margin-top spacing-20-bottom">
                                <input
                                    type="password"
                                    className={"ui-text-field ui-normal-text-input" + (passwordConfirmationError ? " ui-alert" : '') }
                                    name="password_confirmation"
                                    id="password_confirmation"
                                    required
                                />
                            </div>
                            <div className="popup__form__row popup__form__row__slam-right spacing-20-top no-margin-bottom">
                                <div className="popup__form__cell">
                                    <button
                                        className="button ui-normal-button"
                                        onClick={ this.submit }
                                    >
                                        {actionWord} Password
                                    </button>
                                </div>
                            </div>
                        </form>
                        <p className="type-normal-body type-heavy type-single-line spacing-30-top spacing-10-bottom">
                            Have Additional Questions?
                        </p>
                        <p className="type-small-body type-single-line spacing-10-bottom no-margin-bottom">
                            Contact us at <a href="tel:5122702638">(512) 270 2638</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>;
    }
}

/**
*   The props this component takes in are from the react router
*       (history, location, match)
**/
PasswordResetView.propTypes = {
}
