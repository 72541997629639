import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";

import LeadLogRow from "./LeadLogRow";
import LeadLogHeader from "./LeadLogHeader";
import TableTooFewResults from '../components/TableTooFewResults';

export default class LeadLog extends Component {
    constructor(props) {
        super(props);

        this.rightShadowEdgeRef = React.createRef();
        this.leftShadowEdgeRef = React.createRef();
        this.scrollboxRef = React.createRef();
        this.leadTableRef = React.createRef();

        this.leadMessagePlayerRefs = [];

        this.state = {
            leads: this.props.leads || [],
            rowExpansions: new Array(this.props.leads.length).fill(false) || [],
            messages: [],
            messageType: '',
            pagination: this.props.pagination,
            filters: {
                name: ""
            }
        };

        this.cancelSignal = axios.CancelToken.source();
    }

    addLeadMessagePlayer = (playerRef) => {
        this.leadMessagePlayerRefs.push(playerRef);
    };

    handleRowExpansions = (index) => {
        // Set all rows to collapsed
        const newExpansionState = new Array(this.state.leads.length).fill(false);

        if (!this.state.rowExpansions[index]) {
            // If clicked row is not expanded in previous state,
            // set to expanded in new state.

            newExpansionState[index] = true;

            // Nothing needs to be done if clicked row is currently expanded
            // because the all-collapsed state created above implicitly "toggles" it to collapsed
        }

        this.setState({ rowExpansions: newExpansionState });
    };

    handleShadowEdges = () => {

        // Avoiding a crash here requires a similar fix used in
        // LeadSummary.sizeStatCells()  See that method, or this asana card, for
        // more details:
        // https://app.asana.com/0/918599342387311/1148318734974761

        if (!this.scrollboxRef.current) {
            return;
        }

        const scrollboxScrollPos = this.scrollboxRef.current.scrollLeft;
        const scrollboxMaxScroll = this.scrollboxRef.current.scrollWidth - this.scrollboxRef.current.clientWidth;
        const scrollboxWidth = this.scrollboxRef.current.offsetWidth;
        const leadTableWidth = this.leadTableRef.current.offsetWidth;
        const leftShadow = this.leftShadowEdgeRef.current;
        const rightShadow = this.rightShadowEdgeRef.current;

        if (scrollboxWidth < leadTableWidth) {
            if (scrollboxScrollPos === 0) {
                leftShadow.style.display = 'none';
            } else {
                leftShadow.style.display = 'block';
            }
            if (scrollboxScrollPos < scrollboxMaxScroll) {
                rightShadow.style.display = 'block';
            } else {
                rightShadow.style.display = 'none';
            }
        } else {
            leftShadow.style.display = 'none';
            rightShadow.style.display = 'none';
        }
    };

    /**
     * Pauses/Stops all audio players.  The check on playerRef.current works
     * around a bug that surfaces when the user navigates to page two of a
     * lead manager result sets, expands a row, and chooses to play the lead's
     * call file.
     */
    stopAllLeadMessagePlayers = () => {

        this.leadMessagePlayerRefs.forEach(playerRef => {
            if (playerRef.current) {
                playerRef.current.pause();
            }
        });
    };

    componentDidMount() {

        this.handleShadowEdges();
        window.addEventListener('resize', this.handleShadowEdges);
        this.scrollboxRef.current.addEventListener('scroll', this.debounce(this.handleShadowEdges, 10));
    }

    debounce(func, delay) {
        let inDebounce;

        return () => {
            const context = this;
            const args = arguments;
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(context, args), delay);
        };
    }

    render() {
        let numCols = 10;

        if(this.props.isParentContractor) {
            numCols++;
        }

        let LeadLogRows = this.props.leads.map((lead,key) => {
            let industryId = lead.industry_id,
                industryRevenueAverage = this.props.industryRevenueAverages
                    ? this.props.industryRevenueAverages[industryId]
                    : null;

            return (
                <LeadLogRow
                    getLead={this.props.getLead}
                    updateLead={this.props.updateLead}
                    updateClassification={this.props.updateClassification}
                    updateNote={this.props.updateNote}
                    updateCallInsights={this.props.updateCallInsights}
                    industryRevenueAverage={ industryRevenueAverage }
                    lead={ lead }
                    index={ key }
                    expanded={ this.state.rowExpansions[key] }
                    key={ "lead-log-row-" + lead.lead_id }
                    addLeadMessagePlayer={ this.addLeadMessagePlayer }
                    stopAllLeadMessagePlayers={ this.stopAllLeadMessagePlayers }
                    updateModalContent={this.props.updateModalContent}
                    updateMessageBlocks={this.props.updateMessageBlocks}
                    lastLeadChangeTimestamp={this.props.lastLeadChangeTimestamp}
                    managedRoi={this.props.managedRoi}
                    isParentContractor={this.props.isParentContractor}
                    adminView={ this.props.adminView }
                    users={ this.props.users }
                    leadStatusOptions={ this.props.leadStatusOptions }
                    handleRowExpansions={ this.handleRowExpansions }
                    scrollboxRef={ this.scrollboxRef }
                />
            );
        });

        if (!LeadLogRows.length) {
            LeadLogRows = (
            <tr>
                <td colSpan={numCols} className={"no-results-td"}>
                    <TableTooFewResults />
                </td>
            </tr>
            );
        }

        return (
            <div className="scroll-table__container">
                <div className="scroll-table__container__shadowedge__left" ref={this.leftShadowEdgeRef} />
                <div className="scroll-table__container__shadowedge" ref={this.rightShadowEdgeRef} />
                <div
                    className="scroll-table__container__scrollbox scroll-table__container__scrollbox__flexible-height"
                    ref={this.scrollboxRef}
                >
                    <table
                        className="leadlog__table type-normal-body type-single-line"
                        ref={this.leadTableRef}
                    >
                        <LeadLogHeader
                            isParentContractor={this.props.isParentContractor}
                            adminView={ this.props.adminView }
                            sortColumn={ this.props.sortColumn }
                            sortOrder={ this.props.sortOrder }
                            updateSort={ this.props.updateSort }
                         />
                         <tbody>
                            { LeadLogRows }
                        </tbody>
                    </table>
                </div>
            </div>

        );
    }
}

LeadLog.propTypes = {
    leads: PropTypes.array,
    updateLead: PropTypes.func
};
