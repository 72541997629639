import React, { useState } from 'react';

import { questionsContent } from './common-questions-content';

const CommonQuestions = (props) => {
    const [questionExpansions, setQuestionExpansions] = useState(Array(questionsContent.length).fill(false));

    const handleQuestionClick = (idx) => {
        const _expansionsClone = [...questionExpansions];

        // Collapse all answers
        _expansionsClone.fill(false);
        if (!questionExpansions[idx]) {
            // Expand answer if previous state was collapsed
            _expansionsClone[idx] = true;
        }

        setQuestionExpansions(_expansionsClone);
    };

    return (
        <div className="common-questions spacing-40-bottom-mobile">
            <h3 className="type-large-body type-heavy spacing-20-bottom">
                Common Questions
            </h3>
            {questionsContent.map((content, idx) => (
                <div
                    className={(idx < questionsContent.length - 1) ? 'spacing-20-bottom' : ''}
                    key={`common-questions-${idx}`}
                >
                    <h4
                        role="button"
                        className="question-link type-large-body type-heavy spacing-5-bottom"
                        onClick={(e) => handleQuestionClick(idx)}
                    >
                        + {content.question}
                    </h4>
                    <p
                        className={`type-normal-body common-questions__question ${questionExpansions[idx] ? 'expanded' : ''}`}
                        dangerouslySetInnerHTML={{ __html: content.answer }}
                    />
                </div>
            ))}
        </div>
    );
};

export default CommonQuestions;
