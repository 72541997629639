import React, { Component } from 'react';
import MultiSelectList from '../../common/components/form_elements/MultiSelectList';

export default class SortCampaignsModal extends Component {
    constructor(props) {
        super(props);

        this.sortOrderSelectRef = React.createRef();

        this.state = {
            //
            // This is the state of the Sort dropdown within the modal.
            // Note that the CampaignManagerView has a sort_by state property
            // too, which using this modal must effect upon submit in order
            // to cause a re-sort.
            //
            sort_by: this.props.sortBy
        };
    };

    submitSortOption = () => {
        document.activeElement.blur();
        this.props.applySortOption(this.state.sort_by);
    };

    revertSortOption = () => {
        this.setState({sort_by: 'most_leads_30d'},function() {
            this.submitSortOption();
        });
    };

    handleSelect = (vals) => {
        this.setState({sort_by: vals[0]});
    }

    render() {
        return (
            <>
            <div className="spacing-30-bottom">
                <MultiSelectList
                    label=""
                    name="sort-order"
                    selections={ [this.state.sort_by] }
                    options={[
                        { value: 'most_leads_30d', text: 'Most Leads in 30 Days' },
                        { value: 'least_leads_30d', text: 'Least Leads in 30 Days' },
                        { value: 'highest_cpl', text: 'Highest Cost Per Lead' },
                        { value: 'lowest_cpl', text: 'Lowest Cost Per Lead' },
                        { value: 'newest_first', text: 'Date Created, Newest First' },
                        { value: 'oldest_first', text: 'Date Created, Oldest First' },
                        { value: 'alphabetical', text: 'Alphabetical A - Z' },
                    ]}
                    onChange={ this.handleSelect }
                    required={ false }
                    inputRef={ this.sortOrderSelectRef }
                />
            </div>
            <div className="spacing-10-bottom">
                <button className="ui-normal-button button ui-full-width-button"
                        onClick={ this.submitSortOption }
                >
                    Apply Sorting
                </button>
            </div>
            <div>
                <p className="type-small-body type-centered">
                    <span role="button" className="type-heavy type-blue" onClick={ this.revertSortOption }>
                        Back to Default Sorting
                    </span>
                </p>
            </div>
            </>
        );
    }
}