import React from 'react';
import FormModal from '../../common/components/modal/FormModal';
import MultiSelectList, { MultiSelectListSkeleton } from '../../common/components/form_elements/MultiSelectList';
import PopupForm from '../../common/popup_form/PopupForm';
import PopupFormCell from '../../common/popup_form/PopupFormCell';
import PopupFormDivider from '../../common/popup_form/PopupFormDivider';
import PopupFormGroup from '../../common/popup_form/PopupFormGroup';
import PopupFormRow from '../../common/popup_form/PopupFormRow';
import SearchField from '../../common/components/form_elements/SearchField';
import { compareArrays } from '../../Util';

/**
 * FilterCampaignsModal
 *
 * Extends FormModal (subclass of React.Component) in order to get desired
 * scroll-to-top behavior each time the modal is opened.
 */
export default class FilterCampaignsModal extends FormModal {

    constructor(props) {
        super(props);
        this.searchFieldRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.scrollToSearch) {
            const scrollBox = this.props.scrollerRef.current;
            const scrollingContent = scrollBox.querySelector('.popup__contentbox');

            // Delay hack prevents React from interfering with setting the scroll state
            setTimeout(() => {
                scrollBox.scrollTop = scrollingContent.scrollHeight;
                this.searchFieldRef.current.focus();
            }, 100);
        }
        else {
            //
            // parent FormModal implements scroll-to-top behavior
            //
            super.componentDidMount();
        }
    }


    render() {
        let serviceCategoryDropdown
        if (this.props.industryOptions?.length === 0) {
            serviceCategoryDropdown = <MultiSelectListSkeleton />
        } else {
            serviceCategoryDropdown = (
            <MultiSelectList
                label="Service Category"
                multiselect={true}
                selectAllByDefault={this.props.selectedFilters.industry_ids.length === 0}
                name="category-filter"
                emptyLabel="All Service Categories"
                options={this.props.industryOptions}
                selections={this.props.selectedFilters.industry_ids}
                onChange={(value) => this.props.updateFilter('industry_ids', value)}
                extraClass={
                    (this.props.selectedFilters.industry_ids.length === 0
                    || this.props.selectedFilters.industry_ids.length === this.props.industryOptions.length) ?
                        '' : 'ui-highlight'
                }
            />)
        }
        return (
            <PopupForm>
                <PopupFormGroup>
                    {//
                     // Child Company <MultiSelect>
                     // receiving contractorOptions indicates Parent Contractor case
                     //
                    (this.props.contractorOptions.length > 0) && (
                        <PopupFormRow>
                            <MultiSelectList
                                label="Company"
                                selectAllByDefault={true}
                                multiselect={true}
                                name="child-client-filter"
                                emptyLabel="All Companies"
                                options={ this.props.contractorOptions }
                                selections={ this.props.selectedFilters.contractor_ids }
                                onChange={ (value) => this.props.updateFilter('contractor_ids', value) }
                                extraClass={
                                    compareArrays(this.props.selectedFilters.contractor_ids, this.props.defaultFilters.contractor_ids) ?
                                        '' : 'ui-highlight'
                                }
                            />
                        </PopupFormRow>
                    )}
                    <PopupFormRow
                        fullWidthOnMobile={true}
                    >
                        <PopupFormCell>
                            <MultiSelectList
                                multiselect={true}
                                label="Campaign Status"
                                name="status-filter"
                                emptyLabel="All"
                                options={this.props.statusesOptions}
                                selections={this.props.selectedFilters.statuses}
                                onChange={(value) => this.props.updateFilter('statuses', value)}
                                extraClass={
                                    compareArrays(this.props.selectedFilters.statuses, this.props.defaultFilters.statuses) ?
                                        '' : 'ui-highlight'
                                }
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            {serviceCategoryDropdown}
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>
                
                <PopupFormDivider />
                
                <PopupFormGroup>
                    <PopupFormRow
                        noBottomMargin={true}
                    >
                        <PopupFormCell>
                            <label htmlFor="popup-filter-keyword" className="type-small-body type-heavy type-narrow-line-height no-margin-top spacing-5-bottom">
                                Search by Keyword&nbsp;<span className="type-light type-force-newline-mobile">within Applied Filters</span>
                            </label>
                        </PopupFormCell>
                    </PopupFormRow>
                    <PopupFormRow
                        fullWidthOnMobile={true}
                        unevenColumns2and1={true}
                    >
                        <PopupFormCell
                            extraClass="no-margin-bottom-mobile"
                        >
                            <SearchField
                                popup={true}
                                searchFieldRef={ this.searchFieldRef }
                                placeholder="Search by Keyword, Area Code, Name, etc."
                                value={this.props.selectedFilters.keyword}
                                onChange={(event) => this.props.updateFilter('keyword', event.target.value)}
                                onButtonClick={this.props.submitHandler}
                                allowHighlight={true}
                            />
                        </PopupFormCell>
                        <PopupFormCell
                            extraClass="type-align-right ui-hide-mobile-important"
                        >
                            <span className="type-small-body type-heavy type-blue" role="button"
                                onClick={this.props.resetFilters}
                            >
                                Reset Filters and Search to Default
                            </span>
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>
                
                {/* Mobile View Button Group */}
                <PopupFormGroup
                    extraClass="ui-hide-full ui-hide-tablet"
                >
                    <PopupFormRow>
                        <PopupFormCell>
                            <div className="spacing-20-bottom-mobile">
                                <button
                                    className="button ui-normal-button ui-full-width-button"
                                    onClick={this.props.submitHandler}
                                >
                                    Apply Filters
                                </button>
                            </div>
                            <div className="type-centered spacing-20-bottom-mobile">
                                <span className="type-small-body type-heavy type-blue" role="button"
                                   onClick={this.props.resetFilters}
                                >
                                    Reset Filters
                                </span>
                            </div>
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>
                
                {/* Full View Button Group */}
                <PopupFormGroup
                    grayBackground={true}
                    noBottomMargin={true}
                    extraClass="ui-hide-mobile"
                >
                    <PopupFormRow
                        noBottomMargin={true}
                    >
                        <PopupFormCell
                            slamRight={true}
                        >
                            <button
                                className="button ui-normal-button"
                                onClick={this.props.submitHandler}
                            >
                                Apply Filters
                            </button>
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>
            </PopupForm>
        );
    }
}
