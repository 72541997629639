import React from 'react'
import PropTypes from 'prop-types'
import { toTitleCase } from '../../../../Util'
import Radio from '../Radio'
import {
    Wrapper,
    InputWrapper,
    Inputs,
    ClearButton,
} from './styles'

const RadioGroup = ({
    label,
    name,
    options,
    onChange,
    checkedOption,
    vertical = false,
    width,
    optionWidth,
    showLabel = true,
    align = 'left',
    disabled = false,
    readOnly = false,
    isClearable = true,
    hasError = false,
    errorMessage = '',
}) => {
    const handleRadioChange = (name) => (e) => {
        const { value } = e.target
        onChange(name, value)
    }

    const handleResetOptions = () => {
        onChange(name, null)
    }

    const isChecked = (value) => {
        return value === checkedOption
    }

    const getLabel = (option) => {
        return option?.label || toTitleCase(option?.value || '')
    }

    const radioInputs = readOnly
        ? <InputWrapper width={optionWidth || null} key={`${name}__readOnly`}>
            {getLabel(options.find((option) => isChecked(option.value)))}
        </InputWrapper>
        : options.map((option) => {
            const { value } = option
            return (
                <InputWrapper width={optionWidth || null} key={`${name}_${value}`}>
                    <Radio
                        name={name}
                        id={`${name}_${value}`}
                        label={getLabel(option)}
                        value={value}
                        onChange={handleRadioChange(name)}
                        checked={isChecked(value)}
                        disabled={disabled || option.disabled}
                        hasError={hasError}
                    />
                </InputWrapper>
            )
        })

    return (
        <Wrapper width={width || null}>
            {showLabel && (
                <span className="type-small-body type-narrow-line-height type-heavy spacing-5-bottom">
                    {label || toTitleCase(name)}
                </span>
            )}
            <Inputs vertical={vertical} align={align}>
                {radioInputs}
                {isClearable && (
                    <ClearButton
                        onClick={handleResetOptions}
                        variant="span"
                        size="small"
                        disabled={!!!checkedOption || disabled}
                    >
                        Clear
                    </ClearButton>
                )}
            </Inputs>
            {errorMessage && <span className="input-error">{errorMessage}</span>}
        </Wrapper>
    )
}

export default RadioGroup

RadioGroup.propTypes = {
    align: PropTypes.oneOf(['left', 'right', 'center', 'spread']),
}
