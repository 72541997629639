import React, { useEffect, useState, useRef } from 'react';

import TextArea from '../../components/form_elements/TextArea';
import {upperAllFirst} from "../../../Util";

const LeadNoteView = (props) => {
    const {
        adminView,
        dateTime,
        modifiedDateTime,
        lead,
        note,
        updateNote,
    } = props;
    
    const [ showEditor, setShowEditor ] = useState(false);
    const [ editedMessage, setEditedMessage] = useState(note.note);
    
    const editorRef = useRef();
    
    useEffect(() => {
        if (showEditor) {
            editorRef.current.focus();
            editorRef.current.setSelectionRange(editorRef.current.value.length, editorRef.current.value.length);
            setEditedMessage(note.note);
        }
    }, [showEditor, note.note]);
    
    return (
        <div 
            className={`leadlog__controls__lead-history__item ${(note.is_private === '1') ? 'private-note' : ''}`}
        >
            {adminView && (
                <div
                    style={{ display: showEditor ? 'block' : 'none' }}
                >
                    <TextArea
                        name={`lead-note-editor-${note.note_id}`}
                        value={ editedMessage }
                        onChange={ (event) => setEditedMessage(event.target.value) }
                        inputRef={ editorRef }
                    />
                    <div className="leadlog__controls__lead-history__editor-buttons">
                        <div>
                            <button
                                className="ui-text-button type-normal-body"
                                onClick={(e) => {
                                    setEditedMessage(note.note);
                                    setShowEditor(false);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                        <div>
                            <button 
                                className="button ui-small-button"
                                onClick={(e) => {
                                    updateNote(
                                        lead.lead_id,
                                        note.note_id,
                                        {
                                            note: editedMessage,
                                        }
                                    );
                                    setShowEditor(false);
                                }}
                            >
                                Update Note
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div
                style={{ display: showEditor ? 'none' : 'block' }}
            >
                <p
                    className="type-normal-body no-margin type-charcoal"
                    dangerouslySetInnerHTML={{__html: note.note}}
                />
                {note.subtype &&
                <div>Reason: {upperAllFirst(note.subtype)}</div>}
                <div className="leadlog__controls__lead-history__footer">
                    <div>
                        <p className="type-small-body type-italics no-margin type-grey-1">
                            {dateTime.date} {dateTime.time} by {note.username}
                            {modifiedDateTime && (
                                <>
                                    , Last Modified: {modifiedDateTime.date} {modifiedDateTime.time}
                                </>
                            )}
                        </p>
                    </div>
                    {adminView && !props.excludeButtons && (
                        <div className="leadlog__controls__lead-history__footer__buttons">
                            <button
                                className="type-normal-body"
                                onClick={(e) => updateNote(lead.lead_id, note.note_id, { is_private: (note.is_private === '0') ? '1' : '0' }) }
                            >
                                Set {(note.is_private === '0') ? 'Private' : 'Public'}
                            </button>
                            <button
                                className="type-normal-body"
                                onClick={ (e) => {
                                    setShowEditor(true);
                                }}
                            >
                                Edit
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LeadNoteView;