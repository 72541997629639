import React, {Component} from "react";
import LoadingGif from "../common/components/LoadingGif";

import SignupMarketplaceService from '../service/SignupMarketplaceService';

/**
 * this whole component is used to redirect [authenticated] users to the correct step in case of no specific path
 */
export default class StepRedirect extends Component {
    constructor(props) {
        super(props);

        this.signupMarketplaceService = new SignupMarketplaceService();
    }

    componentDidMount() {
        this.signupMarketplaceService.getRedirect()
            .then((url:string) => window.location = url);
    }

    componentWillUnmount() {
        this.signupMarketplaceService.cancelSignal.cancel();
    }

    render() {
        return <div className="type-centered padding-40"><LoadingGif /></div>;
    }
}
