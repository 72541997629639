import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock'

export default class Modal extends Component {
    constructor(props) {
        super(props)

        this.modalParentRef = React.createRef()
        this.containerRef = props.modalContainerRef || React.createRef()
        this.scrollerRef = props.scrollerRef || React.createRef()

        this.scrollerElement = null
    }

    componentDidMount() {
        this.scrollerElement = this.scrollerRef.current

        // this.scrollerElement.addEventListener('DOMSubtreeModified', () => this.handleScrolling());
        new MutationObserver(() => this.handleScrolling()).observe(this.scrollerElement, {
            childList: true,
            subtree: true,
        })

        window.addEventListener('resize', () => this.handleScrolling())

        this.handleScrolling()
    }

    componentDidUpdate() {
        this.handleScrolling()
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks()
    }

    /**
     * closes the modal when the Esc button is clicked ont he keyboard
     * @param {KeyboardEvent} event
     */
    handleEscapeClick = (event) => {
        if (event.key === 'Escape') {
            this.handleCloseButton()
        }
    }

    handleScrolling = () => {
        const modalParent = this.modalParentRef.current
        const container = this.containerRef.current

        //
        // Encountered an error submitting Step 2 where container
        // didn't exist and thus couldn't have a classList etc.
        // Adding this early return to address.
        // https://app.asana.com/0/928250771089872/1121495489097577
        // ~ RFM 2019-05-06
        //
        if (!container) {
            return
        }

        modalParent.classList.remove('popup__scrolling')
        container.classList.remove('popup__scrolling')

        if (container.offsetHeight > 0.8 * window.innerHeight) {
            modalParent.classList.add('popup__scrolling')
            container.classList.add('popup__scrolling')
        }
    }

    handleCloseButton = () =>
        typeof this.props.onCloseButton === 'function'
            ? this.props.onCloseButton()
            : this.props.updateModalContent()

    render() {
        let content = this.props.content ? this.props.content : null
        let modalClass = content ? 'popup__shade show' : 'popup__shade'
        let classNameAdds = [this.props.size || '']
        if (this.props.width === 'wide') {
            classNameAdds.push('popup__wide')
        }
        if (this.props.width === 'extra_wide') {
            classNameAdds.push('popup__extra_wide')
        }
        classNameAdds = classNameAdds.join(' ')
        if (classNameAdds.length > 0) {
            classNameAdds = ' ' + classNameAdds
        }

        const { hideCloseButton = false } = this.props

        let body = document.body
        // // this is to bypass for testing
        // if (body) {
        //     body.style.overflow = content !== null ? "hidden" : "";
        // }

        if (content) {
            body.addEventListener('keyup', this.handleEscapeClick)
        } else {
            body.removeEventListener('keyup', this.handleEscapeClick)
        }

        // I added disableScrollLock because I was having issues on Create Client form where after closing the
        // modal I could no longer scroll. But this is a sub-optimal fix, I would rather understand why scroll lock
        // wasn't working properly for Create Client form. ~ RFM Dec 2020
        if (!this.props.disableScrollLock && this.scrollerElement) {
            // add in the event handler onto the body and pass in a callback to close
            if (content) {
                disableBodyScroll(this.scrollerElement)
            } else {
                enableBodyScroll(this.scrollerElement)
            }
        }

        //
        // If content is a function, run it before injecting into the JSX
        //
        if (typeof content == 'function') {
            content = content()
        }

        let footerClassNames = 'popup__footer'
        if (this.props.footerClassNames != null) {
            footerClassNames += ` ${this.props.footerClassNames}`
        }

        return (
            <div className={modalClass}>
                <div className="popup__container__wrap">
                    <div
                        className={'modal__parent' + classNameAdds}
                        ref={this.modalParentRef}
                    >
                        <div
                            className={'popup__container modal' + classNameAdds}
                            ref={this.containerRef}
                        >
                            {this.props.header && (
                                <div className="popup__header">
                                    {/* if the header is a string, render the string. otherwise, render the header as-is */}
                                    {typeof this.props.header == 'string' ? (
                                        <p className="type-normal-subhead type-heavy type-single-line no-margin">
                                            {this.props.header}
                                        </p>
                                    ) : (
                                        this.props.header
                                    )}
                                    {this.props.headerLink && (
                                        <div className="popup__header-link spacing-10-top-mobile">
                                            <a
                                                target="_blank"
                                                rel="noopener"
                                                href={this.props.headerLink.url}
                                            >
                                                {this.props.headerLink.description}
                                            </a>
                                        </div>
                                    )}

                                    {!hideCloseButton && (
                                        <div className="popup__close-button">
                                            <img
                                                src="/images/icon-popup-close.svg"
                                                role="button"
                                                className="pointer"
                                                alt="Close"
                                                onClick={this.handleCloseButton}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="popup__scrollbox__clipper">
                                <div
                                    className="popup__scrollbox__scroller"
                                    ref={this.scrollerRef}
                                >
                                    <div
                                        className={
                                            'popup__contentbox ' +
                                            (this.props.flatBottom
                                                ? 'popup__contentbox__flat-bottom'
                                                : '') +
                                            (this.props.flat
                                                ? 'popup__contentbox__flat'
                                                : '')
                                        }
                                    >
                                        {content}
                                    </div>
                                </div>
                            </div>
                            {this.props.footer && (
                                <div className={footerClassNames}>
                                    {this.props.footer}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Modal.propTypes = {
    updateModalContent: PropTypes.func.isRequired,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hideCloseButton: PropTypes.bool,
    onCloseButton: PropTypes.func,
    width: PropTypes.oneOf(['wide', 'extra_wide'])
    // commented out because we also need to allow undefined/null/'' for closing the modal:
    //content: PropTypes.oneOf([PropTypes.object, PropTypes.func]),
}

const ContentWrapper = styled.div`
    height: ${(props) => (props.height ? `${props.height}px` : '500px')};
    /* min-height: 400px; */
    overflow-y: scroll;
`
export const ContentWindowScroll = (props) => {
    const contentHeight = Math.max(window.innerHeight * 0.5, 400)
    return <ContentWrapper height={contentHeight}>{props.children}</ContentWrapper>
}
