export const isFormUpdated = (callInsights, leadProgress) => {
    if (callInsights === false || callInsights == null ||
        leadProgress === false || leadProgress == null) {
        return false
    }
    const { call_answered, booked_appointment } = leadProgress
    const allowed = [
        'csr_confirm_appointment',
        'csr_overcome_objectives',
        'csr_ask_for_appointment',
        'csr_collect_service_details',
        'csr_confirm_property_owner',
        'collected_cust_info',
        'csr_show_empathy',
        'csr_greeting_offer_help',
        'csr_greeting_company_name',
        'appointment_lost_reason',
        'service_needed',
    ]
    const filteredInsights = Object
        .keys(callInsights)
        .filter(key => allowed.includes(key))
        .reduce((acc, key) => {
            acc[key] = callInsights[key];
            return acc;
        }, {});
    const formObj = { call_answered, booked_appointment, ...filteredInsights }
    // the form is valid if at least one property is not null
    return Object.values(formObj).some((v) => v)
}
