import React from 'react'
import { getShortNumber } from '../../../Util'

const ShortStatNumber = (props) => {
  let shortNumber = getShortNumber(props.number),
      suffixJsx = '',
      suffixClass = props.suffixClass
          ? props.suffixClass
          : "",
      typeClass = props.typeClass
          ? props.typeClass
          : "type-extra-large-headline";

  if (shortNumber.suffix || props.suffix) {
      suffixJsx = <span className={`${typeClass} stat-suffix ${suffixClass}`}>
          {shortNumber.suffix + (props.suffix || '')}
      </span>;
  }

  return <>{shortNumber.number}{suffixJsx}</>;
};

export default ShortStatNumber