import React, { Component } from 'react';
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import MultiSelectList, { MultiSelectListSkeleton } from '../../common/components/form_elements/MultiSelectList';
import SearchField from '../../common/components/form_elements/SearchField';
import {
    adminBillableOptions,
    callPlayedOptions,
    dateRangeOptions,
    leadTypeOptions,
    flaggedOptions,
    adminFollowUpOptions,
    adminStarredOptions,
    campaignTypeOptions,
    campaignStatusOptions,
    adminManagedOptions,
    adminCallAnsweredOptions,
    adminBookedAppointmentOptions,
    adminJobWonOptions,
    adminRevenueOptions,
    leadReviewOptions,
    leadReviewInitiatedOptions,
} from "../../common/StaticValues";
import { QuestionMarkSvg, SvgWrapper } from '../../common/Svgs';

export default class FilterLeadsPanel extends Component {
    constructor(props) {
        super(props);

        this.billableStatusSelectRef = React.createRef();
        this.callPlayedSelectRef = React.createRef();
        this.dateRangeSelectRef = React.createRef();
        this.leadSourceSelectRef = React.createRef();
        this.leadStatusSelectRef = React.createRef();
        this.contractorTypeSelectRef = React.createRef();
        this.campaignStatusSelectRef = React.createRef();
        this.followUpSelectRef = React.createRef();
        this.starredSelectRef = React.createRef();
        this.searchFieldRef = React.createRef();
        this.callAnsweredSelectRef = React.createRef();
        this.bookedAppointmentSelectRef = React.createRef();
        this.jobWonSelectRef = React.createRef();
        this.leadReviewSelectRef = React.createRef();
        this.reviewInitiatedBySelectRef = React.createRef();
        this.reviewCompletedBySelectRef = React.createRef();
        this.leadTypeSelectRef = React.createRef();
        // remove the "all" option from the campaign types
        this.campaignTypeOptions = campaignTypeOptions.filter(option => option.value != 'all')
    }

    isDateRangeAllTime = () => {
        // Filter to selected date range option
        const filteredDateRangeOptions = dateRangeOptions
            .filter(option => option.value === this.props.selectedFilters.date_range_id);

        return (filteredDateRangeOptions.length > 0) && (filteredDateRangeOptions[0].text === 'All Time');
    }

    shouldDisableParentContractorMultiselect = () => {
        const managerAccountIds = this.props.selectedFilters.manager_account_ids;
        const clientIds = this.props.selectedFilters.client_ids;

        if (clientIds.length > 0) {
            // Disable if there are selections in the Client menu
            return true;
        }

        if (managerAccountIds.length > 0) {
            // Disable if there are selections in the Manager Account menu
            return true;
        }

        return false;
    }

    shouldDisableManagerAccountMultiselect = () => {
        const clientIds = this.props.selectedFilters.client_ids;

        if (clientIds.length > 0) {
            // Disable if there are selections in the Client menu
            return true;
        }

        if (this.props.managerAccountOptions.length === 0) {
            // Disable if selection in Parent Contractor filter has caused there to be no Manager Account options available
            return true;
        }

        return false;
    }

    shouldDisableClientMultiselect = () => {
        if (this.props.clientOptions.length === 0) {
            // Disable if selecton(s) in Parent Contractor and/or Manager Account filters have caused there to be no clients available
            return true;
        }

        return false;
    }

    shouldDisableCampaignMultiselect = () => {
        if (this.props.campaignOptions.length === 0) {
            // Disable if selection(s) in Client menu have caused there to be no campaigns available
            return true;
        }

        return false;
    }

    render() {
        const { selectedFilters, defaultFilters } = this.props;
        
        // conditional render for lead reviewers dropdown
        const leadReviewDropdown =
            this.props.leadReviewCompleteOptions?.length === 0 ? (
                <MultiSelectListSkeleton labelText="Review Completed By"/>
            ) : (
                <MultiSelectList
                    multiselect={true}
                    useTextFilter={true}
                    allowDeselectAll={true}
                    label="Review Completed By"
                    name="review-completed-by"
                    emptyLabel="Optional"
                    selections={selectedFilters.review_completed_by}
                    options={this.props.leadReviewCompleteOptions}
                    onChange={(vals) =>
                        this.props.updateValue('review_completed_by', vals)
                    }
                    inputRef={this.reviewCompletedBySelectRef}
                    extraClass={
                        selectedFilters.review_completed_by.length > 0 &&
                        selectedFilters.review_completed_by.length !== defaultFilters?.review_completed_by?.length
                            ? 'ui-highlight'
                            : ''
                    }
                    wideDropdown={true}
                />
            )

        // conditional render for clients dropdown
        const clientsDropdown =
            this.props.clientOptions?.length === 0 ? (
                <MultiSelectListSkeleton labelText="Clients"/>
            ) : (
                <MultiSelectList
                    multiselect={true}
                    // wideDropdown={true}
                    minDropdownWidth='450px'
                    useTextFilter={true}
                    name="clients"
                    label="Clients"
                    emptyLabel="Optional"
                    options={this.props.clientOptions || []}
                    selections={selectedFilters.client_ids}
                    onChange={(value) => {
                        // blank out client_ids to disable Clients <select>, then set campaign_ids
                        this.props.updateValue('client_ids', value, () => {
                            if (value.length > 0) {
                                this.props.updateCampaignOptions()
                            } else {
                                this.props.updateValue('campaign_ids', [], () =>
                                    this.props.updateCampaignOptions()
                                )
                            }
                        })
                    }}
                    disabled={this.shouldDisableClientMultiselect()}
                    disabledLabel={this.props.clientOptions.length === 0 ? 'Empty' : null}
                    extraClass={
                        selectedFilters.client_ids.length > 0 ? 'ui-highlight' : ''
                    }
                />
            )
        
        // Service categories
        let serviceCategoryExtraClass = ''
        if  (selectedFilters.service_category_ids.length > 0 &&
            selectedFilters.service_category_ids.length !== defaultFilters?.service_category_ids?.length) {
                serviceCategoryExtraClass = 'ui-highlight'
        } else if (selectedFilters.service_category_ids.length === 0) {
            serviceCategoryExtraClass = 'ui-alert'
        }
        const serviceCategoryDropdown =
            this.props.serviceCategoryOptions?.length === 0 ? (
                <MultiSelectListSkeleton labelText="Service Category"/>
            ) : (
                <MultiSelectList
                    multiselect={true}
                    allowDeselectAll={true}
                    selectAllByDefault={true}
                    useTextFilter={true}
                    name="service-category"
                    label="Service Category"
                    emptyLabel="Please Select"
                    options={this.props.serviceCategoryOptions || []}
                    selections={selectedFilters.service_category_ids}
                    onChange={(value) =>
                        this.props.updateValue('service_category_ids', value)
                    }
                    extraClass={serviceCategoryExtraClass}
                    wideDropdown={true}
                />
            )
        
        // Lead Source
        const adNetworkDropdown =
            this.props.adNetworkOptions?.length === 0 ? (
                <MultiSelectListSkeleton labelText="Lead Source" />
            ) : (
                <MultiSelectList
                    multiselect={true}
                    allowDeselectAll={true}
                    useTextFilter={true}
                    name="ad-network"
                    label="Lead Source"
                    emptyLabel="Optional"
                    options={this.props.adNetworkOptions || []}
                    selections={selectedFilters.ad_network_ids}
                    onChange={(value) => this.props.updateValue('ad_network_ids', value)}
                    extraClass={
                        selectedFilters.ad_network_ids.length > 0 &&
                        selectedFilters.ad_network_ids.length !== defaultFilters?.ad_network_ids?.length
                        ? 'ui-highlight' : ''
                    }
                    wideDropdown={true}
                />
            )
        
        // Campaign Dropdown
        let campaignDropdown = (
            <MultiSelectList
                name="campaign"
                label="Campaign"
                disabled={true}
                disabledLabel="Choose Client First"
            />
        )
        if (this.props.campaignOptions?.length > 0) {
            campaignDropdown = (
                <MultiSelectList
                    key="campaign-dropdown"
                    multiselect={true}
                    allowDeselectAll={true}
                    useTextFilter={true}
                    name="campaign"
                    label="Campaign"
                    emptyLabel="Please Select"
                    options={this.props.campaignOptions || []}
                    selections={selectedFilters.campaign_ids}
                    onChange={(vals) => {
                        this.props.updateValue('campaign_ids', vals)
                    }}
                    extraClass={
                        selectedFilters.campaign_ids.length > 0 ? 'ui-highlight' : ''
                    }
                    wideDropdown={true}
                />
            )
        }

        // Lead Status
        const leadStatusDropdown =
            this.props.leadStatusOptions?.length === 0 ? (
                <MultiSelectListSkeleton labelText="Lead Status"/>
            ) : (
                <MultiSelectList
                    multiselect={true}
                    allowDeselectAll={true}
                    useTextFilter={true}
                    wideDropdown={true}
                    label="Lead Status"
                    name="lead-status"
                    emptyLabel="Please Select"
                    selections={selectedFilters.status_id}
                    options={this.props.leadStatusOptions}
                    onChange={(vals) => this.props.updateValue('status_id', vals)}
                    inputRef={this.leadStatusSelectRef}
                    searchFromBeginning={true}
                    extraClass={
                        selectedFilters.status_id.length > 0
                            ? 'ui-highlight'
                            : 'ui-alert'
                    }
                />
            )

        return (
            <div className="row spacing-30-bottom">
                <div className="wide-format-col page__contentbox">
                    <div className="form__row form__row__bottom-align">
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Date Range"
                                name="date-range"
                                selections={ [`${selectedFilters.date_range_id}`] }
                                options={ dateRangeOptions }
                                onChange={ (vals) => this.props.updateDateValue(vals[0] ) }
                                require={ false }
                                inputRef={ this.dateRangeSelectRef }
                                extraClass={ (this.isDateRangeAllTime()) ? '' : 'ui-highlight' }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <label htmlFor="popup-filter-from-date"
                               className="type-small-body type-heavy type-narrow-line-height no-margin-top spacing-5-bottom">
                                From Date
                            </label>
                            <input
                                type="date"
                                className={`ui-text-field ui-normal-text-input ${(selectedFilters.date_from.length > 0) ? 'ui-highlight' : ''}`}
                                id="popup-filter-from-date"
                                name="from-date"
                                placeholder="mm/dd/yyyy"
                                value={selectedFilters.date_from}
                                onChange={(event) =>
                                    this.props.updateValue("date_from", event.target.value)}
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <label htmlFor="popup-filter-to-date"
                               className="type-small-body type-heavy type-narrow-line-height no-margin-top spacing-5-bottom">
                                To Date
                            </label>
                            <input
                                type="date"
                                className={`ui-text-field ui-normal-text-input ${(selectedFilters.date_to.length > 0) ? 'ui-highlight' : ''}`}
                                id="popup-filter-to-date"
                                name="to-date"
                                placeholder="mm/dd/yyyy"
                                value={selectedFilters.date_to}
                                onChange={(event) =>
                                    this.props.updateValue("date_to", event.target.value)}

                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom"></div>
                        <div className="form__cell form__cell__33 spacing-10-bottom">
                            <label
                                className="type-normal-body type-heavy type-narrow-line-height no-margin-top spacing-5-bottom"
                            >
                                Search
                                <div style={{ marginLeft: '5px', zIndex: 1, cursor: 'pointer' }}>
                                    <Tippy
                                        content={"Search for Caller Name, Caller Phone Number, Tracking / DID Number, or Email Address. To search for a phone number, enter the ten digit number with no formatting. i.e 9999999999. You can also search by part of a phone number, such as the area code. i.e. 512"}
                                        trigger="click"
                                        theme="sd-tooltip"
                                    >
                                        <div style={{ height: '15px', width: '15px' }}>
                                            <SvgWrapper>{QuestionMarkSvg}</SvgWrapper>
                                        </div>
                                    </Tippy>
                                </div>
                            </label>
                            <SearchField
                                popup={ true }
                                value={ selectedFilters.keyword }
                                onChange={ (event) => this.props.updateValue("keyword", event.target.value) }
                                onFormSubmit={(e) => {
                                    this.props.applyFilters();
                                    e.preventDefault();
                                }}
                                searchFieldRef={ this.searchFieldRef }
                                allowHighlight={ true }
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Billable Status"
                                name="billable-status"
                                selections={ [selectedFilters.billable_select_list_value] }
                                options={ adminBillableOptions }
                                onChange={ (vals) => this.props.updateValue('billable_select_list_value', vals[0]) }
                                inputRef={ this.billableStatusSelectRef }
                                extraClass={
                                    (selectedFilters.billable_select_list_value !== adminBillableOptions[0].value) ? 'ui-highlight': ''
                                }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            {leadStatusDropdown}
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Review Status"
                                name="review-status"
                                selections={ [selectedFilters.lead_review_select_list_value] }
                                options={ leadReviewOptions }
                                onChange={ (vals) => this.props.updateValue( 'lead_review_select_list_value', vals[0]) }
                                inputRef={ this.leadReviewStatusSelectRef }
                                extraClass={
                                    (selectedFilters.lead_review_select_list_value !== leadReviewOptions[0].value) ? 'ui-highlight' : ''
                                }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                multiselect={true}
                                // wideDropdown={true}
                                minDropdownWidth='330px'
                                label="Review Initiated By"
                                name="review-initiated-by"
                                emptyLabel="Optional"
                                selections={ selectedFilters.review_initiated_by }
                                options={ leadReviewInitiatedOptions }
                                onChange={ (values) => this.props.updateValue('review_initiated_by', values) }
                                inputRef={ this.reviewInitiatedBySelectRef }
                                extraClass={
                                    selectedFilters.review_initiated_by.length > 0
                                    ? 'ui-highlight' : ''
                                }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            {leadReviewDropdown}
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Lead Type"
                                name="lead-type"
                                selections={ [selectedFilters.lead_type] }
                                options={ leadTypeOptions }
                                onChange={ (vals) => this.props.updateValue('lead_type', vals[0]) }
                                require={ false }
                                inputRef={ this.leadTypeSelectRef }
                                extraClass={
                                    selectedFilters.lead_type !== leadTypeOptions[0].value
                                        ? 'ui-highlight'
                                        : ''
                                }
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Campaign Type"
                                name="campaign-type"
                                selections={ selectedFilters.campaign_types }
                                options={ this.campaignTypeOptions }
                                onChange={ (vals) => this.props.updateValue('campaign_types', vals) }
                                require={ false }
                                inputRef={ this.leadSourceSelectRef }
                                extraClass={
                                    selectedFilters.campaign_types.length > 0 &&
                                    selectedFilters.campaign_types.length !== defaultFilters?.campaign_types?.length
                                        ? 'ui-highlight'
                                        : ''
                                }
                                multiselect={true}
                                allowDeselectAll={true}
                                useTextFilter={true}
                                emptyLabel="Please Select"
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                multiselect={true}
                                selectAllByDefault={true}
                                label="Campaign Status"
                                name="campaign-status"
                                emptyLabel="Please Select"
                                selections={ selectedFilters.campaign_statuses }
                                options={ campaignStatusOptions }
                                onChange={ (value) => this.props.updateValue('campaign_statuses', value) }
                                inputRef={ this.campaignStatusSelectRef }
                                extraClass={
                                    selectedFilters.campaign_statuses.length > 0 &&
                                    selectedFilters.campaign_statuses.length != defaultFilters?.campaign_statuses?.length ? 'ui-highlight' : ''
                                }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            {adNetworkDropdown}
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            { serviceCategoryDropdown }
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            {campaignDropdown}
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                multiselect={true}
                                name="parent-contractor"
                                label="Parent Contractor"
                                emptyLabel="Optional"
                                allowDeselectAll={ true }
                                options={this.props.parentContractorOptions || []}
                                selections={selectedFilters.parent_contractor_ids}
                                onChange={(value) => this.props.updateValue('parent_contractor_ids', value) }
                                disabled={ this.shouldDisableParentContractorMultiselect() }
                                extraClass={
                                    (selectedFilters.parent_contractor_ids.length > 0) ? 'ui-highlight' : ''
                                }
                                wideDropdown={true}
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                multiselect={true}
                                allowDeselectAll={ true }
                                name="manager-account"
                                label="Manager Account"
                                emptyLabel="Optional"
                                options={this.props.managerAccountOptions || []}
                                selections={selectedFilters.manager_account_ids}
                                onChange={(value) => this.props.updateValue('manager_account_ids', value) }
                                disabled={ this.shouldDisableManagerAccountMultiselect() }
                                disabledLabel={ (this.props.managerAccountOptions.length === 0) ? 'Empty' : null }
                                extraClass={
                                    (selectedFilters.manager_account_ids.length > 0) ? 'ui-highlight' : ''
                                }
                                // wideDropdown={true}
                                minDropdownWidth='350px'
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            {clientsDropdown}
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Contractor Type"
                                name="contractor-type"
                                selections={ selectedFilters.contractor_type_ids }
                                options={ this.props.contractorTypeOptions }
                                onChange={ (vals) => this.props.updateValue('contractor_type_ids', vals) }
                                inputRef={ this.contractorTypeSelectRef }
                                extraClass={
                                    selectedFilters.contractor_type_ids.length > 0 &&
                                    selectedFilters.contractor_type_ids.length !== defaultFilters?.contractor_type_ids?.length
                                        ? 'ui-highlight'
                                        : ''
                                }
                                multiselect={true}
                                allowDeselectAll={true}
                                useTextFilter={true}
                                emptyLabel="Please Select"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Call Played / Form Viewed"
                                name="call-played"
                                selections={ [selectedFilters.call_played_select_list_value] }
                                options={ callPlayedOptions }
                                onChange={ (vals) => this.props.updateValue('call_played_select_list_value', vals[0]) }
                                inputRef={ this.callPlayedSelectRef }
                                extraClass={
                                    (selectedFilters.call_played_select_list_value !== callPlayedOptions[0].value) ? 'ui-highlight' : ''
                                }
                                wideDropdown={true}
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Flagged"
                                name="flagged"
                                selections={ [selectedFilters.flagged_select_list_value] }
                                options={ flaggedOptions }
                                onChange={ (vals) => this.props.updateValue('flagged_select_list_value', vals[0]) }
                                inputRef={ this.flaggedSelectedRef }
                                extraClass={ (selectedFilters.flagged_select_list_value !== flaggedOptions[0].value) ? 'ui-highlight': '' }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Follow-Up"
                                name="follow-up"
                                selections={ [selectedFilters.followup_select_list_value] }
                                options={ adminFollowUpOptions }
                                onChange={ (vals) => this.props.updateValue('followup_select_list_value', vals[0]) }
                                inputRef={ this.followUpSelectRef }
                                extraClass={
                                    (selectedFilters.followup_select_list_value !== adminFollowUpOptions[0].value) ? 'ui-highlight' : ''
                                }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Starred"
                                name="starred"
                                selections={ [selectedFilters.starred_select_list_value] }
                                options={ adminStarredOptions }
                                onChange={ (vals) => this.props.updateValue('starred_select_list_value', vals[0]) }
                                inputRef={ this.starredSelectRef }
                                extraClass={
                                    (selectedFilters.starred_select_list_value !== adminStarredOptions[0].value) ? 'ui-highlight' : ''
                                }
                            />
                        </div>
                    </div>
                    <div className="form__row form__row__bottom-align">
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Managed Lead Progress"
                                name="managed-lead-progress"
                                selections={ [selectedFilters.managed_select_list_value] }
                                options={ adminManagedOptions }
                                onChange={ (vals) => {
                                    const data = {};
                                    data['managed_select_list_value'] = vals[0];

                                    if (vals[0] != 'all') {
                                        data['call_answered_select_list_value'] = '0';
                                        data['booked_appointment_select_list_value'] = '0';
                                        data['job_won_select_list_value'] = '0';
                                        data['revenue_entered_select_list_value'] = '0';
                                    }

                                    this.props.updateValues(data);
                                }}
                                inputRef={ this.managedSelectRef }
                                extraClass={
                                    (selectedFilters.managed_select_list_value !== adminManagedOptions[0].value) ? 'ui-highlight' : ''
                                }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Call Answered"
                                name="call-answered"
                                selections={ [selectedFilters.call_answered_select_list_value] }
                                options={ adminCallAnsweredOptions }
                                onChange={ (vals) => this.props.updateValue('call_answered_select_list_value', vals[0]) }
                                inputRef={ this.callAnsweredSelectRef }
                                disabled={ selectedFilters.managed_select_list_value !== adminManagedOptions[0].value }
                                extraClass={
                                    (selectedFilters.call_answered_select_list_value !== adminCallAnsweredOptions[0].value) ? 'ui-highlight' : ''
                                }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Booked Appointment"
                                name="booked-appointment"
                                selections={ [selectedFilters.booked_appointment_select_list_value] }
                                options={ adminBookedAppointmentOptions }
                                onChange={ (vals) => this.props.updateValue('booked_appointment_select_list_value', vals[0]) }
                                inputRef={ this.bookedAppointmentSelectRef }
                                disabled={ selectedFilters.managed_select_list_value !== adminManagedOptions[0].value }
                                extraClass={
                                    (selectedFilters.booked_appointment_select_list_value !== adminBookedAppointmentOptions[0].value) ? 'ui-highlight' : ''
                                }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Job Won"
                                name="job-won"
                                selections={ [selectedFilters.job_won_select_list_value] }
                                options={ adminJobWonOptions }
                                onChange={ (vals) => this.props.updateValue('job_won_select_list_value', vals[0]) }
                                inputRef={ this.jobWonSelectRef }
                                disabled={ selectedFilters.managed_select_list_value !== adminManagedOptions[0].value }
                                extraClass={
                                    (selectedFilters.job_won_select_list_value !== adminJobWonOptions[0].value) ? 'ui-highlight' : ''
                                }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <MultiSelectList
                                label="Revenue Entered"
                                name="revenue-entered"
                                selections={ [selectedFilters.revenue_entered_select_list_value] }
                                options={ adminRevenueOptions }
                                onChange={ (vals) => this.props.updateValue('revenue_entered_select_list_value', vals[0]) }
                                inputRef={ this.revenueEnteredSelectRef }
                                disabled={ selectedFilters.managed_select_list_value !== adminManagedOptions[0].value }
                                extraClass={
                                    (selectedFilters.revenue_entered_select_list_value !== adminRevenueOptions[0].value) ? 'ui-highlight' : ''
                                }
                            />
                        </div>
                        <div className="form__cell form__cell__16 spacing-10-bottom">
                            <div className="admin-filtered-leads__controls-block">
                                <span
                                    role="button"
                                    className="type-heavy type-right-side-bump"
                                    onClick={() => this.props.resetFilters() }
                                >
                                    Reset to Default
                                </span>
                                <button
                                    className="button ui-normal-button"
                                    onClick={() => this.props.applyFilters() }
                                >
                                    Apply Filters
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
