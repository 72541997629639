import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MultiSelectList from "../common/components/form_elements/MultiSelectList";
import ResourcesService from "../service/ResourcesService";

export default class ServiceCategorySelection extends Component {
    MAX_ALLOWED_SERVICE_CATEGORIES = 6;

    constructor(props) {
        super(props);

        this.state = {
            // values
            masterIndustry: props.masterIndustry || 0,
            selectedIndustries: props.selectedIndustries || [],
            selectedIndustriesErrorMessage: props.selectedIndustriesErrorMessage,

            // options - data fetched from the server on mount
            industries: {},
            masterIndustries: [
                {
                    value: 0,
                    text: "- Select Main Category -"
                }
            ]
        };
        this.resourcesService = new ResourcesService();
    }

    componentDidMount() {
        this.resourcesService.getIndustries(true)
            .then((response: Object) => {
                let newState = {
                    industries: {},
                    masterIndustries: this.state.masterIndustries
                };

                // build master categories
                response.masterServiceCategories.forEach((masterCategory: Object) => {
                    newState.masterIndustries.push({
                        value: masterCategory.id,
                        text: masterCategory.name
                    });
                    newState.industries[masterCategory.id] = [];
                });

                // build categories
                response.serviceCategories.forEach((category: Object) => {
                    newState.industries[category.industryMasterId].push({
                        value: category.id,
                        text: category.name
                    });
                });
                this.setState(newState);
            });
    }

    componentDidUpdate(prevProps) {
        let changedData = {};
        if (JSON.stringify(prevProps.selectedIndustries) != JSON.stringify(this.props.selectedIndustries)) {
            changedData.selectedIndustries = this.props.selectedIndustries;
        }
        if (prevProps.masterIndustry != this.props.masterIndustry) {
            changedData.masterIndustry = this.props.masterIndustry;
        }
        if (prevProps.selectedIndustriesErrorMessage != this.props.selectedIndustriesErrorMessage) {
            changedData.selectedIndustriesErrorMessage = this.props.selectedIndustriesErrorMessage;
        }

        if (Object.keys(changedData).length > 0) {
            this.setState(changedData);
        }
    }

    render() {
        return <>
            <h3 className="form-segment-header spacing-30-bottom type-large-body">
                Your Services
            </h3>
            <div className="form__row spacing-24-bottom">
                <div
                    className="form__cell form__cell__50 form__cell__100__mobile">
                    <MultiSelectList
                        name="masterCategory"
                        label="Select your Main Category"
                        selections={[this.state.masterIndustry]}
                        options={this.state.masterIndustries}
                        onChange={this.props.handleMasterIndustryChange}
                    />
                </div>
            </div>
            <div className="form__row spacing-50-bottom">
                <div className="form__cell form__cell__50 form__cell__100__mobile order-2-mobile">
                    <MultiSelectList
                        multiselect={true}
                        name="industries"
                        label="Select your desired Service Categories"
                        disabled={this.state.masterIndustry == 0}
                        disabledLabel="First select a Main Category"
                        internalDisabled={this.state.selectedIndustries.length >= this.MAX_ALLOWED_SERVICE_CATEGORIES}
                        emptyLabel="- None selected -"
                        options={this.state.industries[this.state.masterIndustry] || []}
                        selections={this.state.selectedIndustries}
                        onChange={this.props.handleIndustriesChange}
                        errorMessage={this.state.selectedIndustriesErrorMessage}
                    />
                </div>
                {this.state.selectedIndustries.length >= this.MAX_ALLOWED_SERVICE_CATEGORIES &&
                <div className="form__cell form__cell__50 form__cell__100__mobile form__cell_start order-1-mobile spacing-18-bottom-mobile">
                    <p>
                        New clients can sign up for up to <b className="type-heavy">6 service categories</b> for free!
                        Additional service categories may be available once sign up is completed.
                    </p>
                </div>}
            </div>
        </>;
    }
}

ServiceCategorySelection.propTypes = {
    selectedIndustries: PropTypes.array.isRequired, // really arrayOf(PropTypes.number) but we don't send correct types in our API
    handleIndustriesChange: PropTypes.func.isRequired,
    masterIndustry: PropTypes.string.isRequired, // really number but we don't have that in our DB selection
    handleMasterIndustryChange: PropTypes.func.isRequired,
    selectedIndustriesErrorMessage: PropTypes.string
};

