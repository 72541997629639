import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';

import {ButtonNavCloseSvg, HamburgerSvg} from "../common/Svgs";
import {errorHandler} from "../Requests";
import {getScrollY, handleFloatingHeader} from '../common/FloatingHeader';
import TokenService from "../service/TokenService";
import {SubMenu} from "./SubMenu";

/**
 * Expected props:
 *
 * whiteLabelPartnership (object) - the needed data (logos, color scheme, company name)
 * to white label / brand the experience.
 */

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobilePopover: false,
        };

        this.headerRef = React.createRef();
        this.tokenService = new TokenService();
    }

    componentWillUnmount() {
        this.tokenService.cancelSignal.cancel();
    }

    /**
     * Logs user out.
     */
    logout = () =>
        this.tokenService.logout()
            .catch(errorHandler);

    componentDidMount() {
        document.body.setAttribute('data-last-scroll-top', getScrollY());

        window.addEventListener(
            'scroll',
            () =>
                handleFloatingHeader(this.headerRef.current, this.props.secondaryNavRef.current)
        );
        setInterval(
            () =>
                handleFloatingHeader(this.headerRef.current, this.props.secondaryNavRef.current),
            500
        );
    }

    /**
     * Toggle mobile popover menu
     */
    toggleMobilePopover = () => {
        this.setState({mobilePopover: !this.state.mobilePopover});
    };

    render() {
        return <header ref={this.headerRef}
                       className={this.state.mobilePopover ? 'disable-sticky' : ''}>
            <div className="header-container">
                <div className="page-width-wide header-contents">
                    <div className="header__logo padding-20-mobile">
                        <Link to="/">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src="/images/header-logo.png" alt="Service Direct Admin Page"/>
                                <span className="admin-label">Admin</span>
                            </div>
                        </Link>
                    </div>
                    <div className="ui-hide-full ui-hide-tablet">
                        <div className="hamburger-menu-container">
                            <button className="hamburger-button type-blue close-button"
                                    onClick={this.toggleMobilePopover}>
                                {this.state.mobilePopover
                                    ? ButtonNavCloseSvg
                                    : HamburgerSvg}
                            </button>
                        </div>
                    </div>
                    <div
                        className={"header-actions-container p-fixed-mobile" + (this.state.mobilePopover ? ' d-block-mobile' : '')}>
                        <ul className="header-actions"
                            onClick={this.toggleMobilePopover}>
                            <SubMenu text="Clients">
                                <li>
                                    <NavLink to="/contractors">
                                        Clients
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/create-client" exact>
                                        Sign Up New Client
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/signup-and-launch-status">
                                        Activation Launch Status
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/admin/users">
                                        Users
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/admin/emails">
                                        Emails
                                    </NavLink>
                                </li>
                            </SubMenu>
                            <SubMenu text="Leads">
                                <li>
                                    <NavLink to="/leads">
                                        Leads
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/admin/qa-dashboard">
                                        QA Dashboard
                                    </NavLink>
                                </li>
                            </SubMenu>
                            <SubMenu text="Campaigns">

                                <li>
                                    <NavLink width={180} to="/marketplace-campaign-optimizer">
                                        Marketplace Campaign Optimizer
                                    </NavLink>
                                </li>
                            </SubMenu>
                            <SubMenu text="Settings">
                                <li>
                                    <NavLink to="/admin/lead-sources/lead-sources">
                                        Lead Sources
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/admin/external-buyers">
                                        External Lead Buyers
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/industries">
                                        Service Categories
                                    </NavLink>
                                </li>
                            </SubMenu>
                            <SubMenu text="Tools">
                                <li>
                                    <NavLink to="/admin/map-sandbox">
                                        Map Sandbox
                                    </NavLink>
                                </li>
                                {process.env.REACT_APP_INCLUDE_TEST_CLIENT_LINKS &&
                                    <li>
                                        <NavLink to="/admin/create-test-client">
                                            Create Test Client
                                        </NavLink>
                                    </li>}
                            </SubMenu>
                        </ul>
                        <div className="header__button-wrap">
                            <button onClick={this.logout}
                                    className="button button__outline ui-normal-button logout-button">
                                Log Out
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>;
    }
}
