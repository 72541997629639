import React from 'react';

import CallOutBox from './CallOutBox';
import CoreSection from './CoreSection';
import ExpandableSection from './ExpandableSection';
import QuizSection from './QuizSection';
import Sidebar from './sidebar';
import WistiaPlayer from '../common/WistiaPlayer';

const StepTwo = (props) => {
	return (
		<div className="onboarding-contents page-width">
			<Sidebar step={2} {...props} />
			
			<div className="onboarding-main-column">
				<CoreSection
					headerLabel="Section 2"
					header="How Much Do Leads Cost and What Affects Lead Volume?"
				>
					<h4 className="type-large-subhead type-heavy spacing-24-bottom">
						Let’s Review Cost Per Lead and Lead Volume
					</h4>
					
					<WistiaPlayer
						videoId="xtmltrll2q"
						wrapper="wistia-player-1"
					/>
					
					<h5 className="type-normal-subhead type-heavy spacing-40-top spacing-24-bottom">
						Key Concepts
					</h5>
					
					<div className="onboarding__columns-block spacing-24-top">
						<div className="column">
							<ul className="type-large-body type-narrow-line-height">
								<li>
									You only pay for Billable Leads.
								</li>
								<li>
									You choose the Cost Per Lead (CPL) for each of your Campaigns and can adjust it based on the Lead Volume your business needs.
								</li>
								<li>
									The CPL determines how competitive your Campaigns are for the available Leads in your Service Category and Service Area.
								</li>
							</ul>
						</div>
						<div className="column">
							<ul className="type-large-body type-narrow-line-height">
								<li>
									Lead Volume is affected by: the competitiveness of your CPL, the amount of people in your area looking for your services, and the competitiveness of other businesses in your area that might be bidding on those same calls.
								</li>
								<li>
									Increasing your Cost Per Lead makes your Campaigns more competitive and increases your Lead Volume.
								</li>
							</ul>
						</div>
					</div>
				</CoreSection>
				
				<ExpandableSection
					header="How Much Do Leads Cost?"
				>
					<ul className="type-large-body type-narrow-line-height spacing-40-bottom">
						<li>
							With Service Direct, there are no term contracts, no upfront costs, no monthly support costs and you can cancel at any time.
						</li>
						<li>
							You only pay for Billable Leads and you choose the Cost Per Lead (CPL) for each Campaign based on the Lead Volume your business needs.
						</li>
						<li>
							A recommended price range will be displayed next to the Cost Per Lead input field for each Service Category.
						</li>
					</ul>
					
					<CallOutBox
						headline="Learn More"
					>
						<ul className="type-large-body type-narrow-line-height">
							<li>
								Read our Help Center article <a href="https://support.servicedirect.com/adjust-cost-per-lead" target="_blank" className="type-heavy">Adjusting Your Cost Per Lead</a>
							</li>
						</ul>
					</CallOutBox>
				</ExpandableSection>
				
				<ExpandableSection
					header="What Affects Lead Volume?"
				>
					<ul className="type-large-body type-narrow-line-height spacing-40-bottom">
						<li>
							While we cannot guarantee a specific amount of Leads, we will help optimize your Campaigns to compete for the available Leads in your Service Category and Service Area.
						</li>
						<li>
							You control the Cost Per Lead (CPL) you’re willing to pay for each Billable Lead.
						</li>
						<li>
							Your Cost Per Lead determines your ability to attract and win New Potential Customers. Our Leads are exclusive to you and never shared with other businesses so your CPLs have to outcompete others trying to win the same Leads.
						</li>
						<li>
							Generally, increasing your CPL results in higher Lead Volume and lowering your CPL reduces Lead Volume.
						</li>
						<li>
							The winning CPLs depend on the total amount of available Leads at any given time, which is affected by:
							<ul className="type-large-body type-narrow-line-height">
								<li>
									Your Campaign Schedule, which is the times and days you’re actively competing for Leads.
								</li>
								<li>
									The amount of competition for Leads from other businesses within your Service Category and Service Area.
								</li>
								<li>
									Seasonality factors in your Service Category.
								</li>
							</ul>
						</li>
					</ul>
					
					<CallOutBox
						headline="Learn More"
					>
						<ul className="type-large-body type-narrow-line-height">
							<li>
								Read our Help Center article <a href="https://support.servicedirect.com/lead-volume" target="_blank" className="type-heavy">Controlling Your Lead Volume</a>
							</li>
							<li>
								Read our Help Center article <a href="https://support.servicedirect.com/grow-your-account" target="_blank" className="type-heavy">Growing Your Service Direct Account</a>
							</li>
						</ul>
					</CallOutBox>
				</ExpandableSection>
				
				<QuizSection
					{...props}
					questions={[
						{
							name: "step-2-question-1",
							question: "Who determines the Cost Per Lead (CPL)?",
							answers: [
								"Service Direct",
								"My Company",
								"Other businesses in my Service Category"
							],
							correctAnswer: 2
						},
						{
							name: "step-2-question-2",
							question: "Why would I want to change my CPL?",
							answers: [
								"Increase Lead Volume",
								"Decrease Lead Volume",
								"Reach more New Potential Customers",
								"All of the above",
							],
							correctAnswer: 4,
						},
						{
							name: "step-2-question-3",
							question: "What affects how many Leads I receive?",
							answers: [
								"Days and hours my Campaign is active",
								"Number of other businesses competing for Leads",
								"Total available Leads in my Service Area and Service Category",
								"All of the above"
							],
							correctAnswer: 4,
						}
					]}
				/>
			</div>
		</div>
	);
};

export default StepTwo;