import React from 'react';
import FormModal from '../../common/components/modal/FormModal';
import Radio from '../../common/components/form_elements/Radio';
import { apiUrl } from '../../Config';

/**
 * HistoricalReportModal
 *
 * When user clicks "Download Campaign Report", this modal opens, allowing
 * them to choose either a Monthly or Weekly report.
 */
export default class FilterCampaignsModal extends FormModal {

    constructor(props) {
        super(props);
        this.state = {
            timeSlice: "weekly",
            submitButtonDisabled: false,
        };

        //
        // The download happens via an <a href> as opposed to JS.
        // So, there is no AJAX callback to determine when the download is complete.
        // Therefore, we use a trick: the server providing the downloaded file
        // sets a cookie. JS can poll and when it sees the cookie it knows the download
        // is complete. We clear the cookie at first to make sure it's not leftover.
        //
        this.downloadCookieName = 'downloaded_campaign_report';
        this.deleteDownloadCookie();
    }


    render() {
        return (
            <>
                {/*Download CSV Report for 4 Enabled and Paused Campaigns in All Service Categories*/}
                <div className="type-large-body spacing-20-bottom">
                    {this.props.filterMessage("Download CSV Report for")}
                </div>

                <div className="radio-container spacing-10-bottom">
                    <Radio
                        name="report_time_slice"
                        label="Weekly Report"
                        id="weekly"
                        value="weekly"
                        checked={ this.state.timeSlice == 'weekly' }
                        onChange={() => this.setState({timeSlice: 'weekly'})}
                    />
                </div>
                <div className="radio-container spacing-50-bottom">
                    <Radio
                        name="report_time_slice"
                        label="Monthly Report"
                        id="monthly"
                        value="monthly"
                        checked={ this.state.timeSlice == 'monthly' }
                        onChange={() => this.setState({timeSlice: 'monthly'})}
                    />
                </div>

                <div className="ui-hide-full ui-hide-tablet">
                    <div className="popup__form__row spacing-30-top-mobile">
                        <div className="popup__form__cell popup__form__cell__100__mobile">
                            <div className="spacing-20-bottom-mobile">
                                <a href={this.downloadReportUrl()}
                                   download={true}
                                >
                                    <button
                                        className="button ui-normal-button ui-full-width-button"
                                        onClick={() => {
                                            this.setState({submitButtonDisabled: true});
                                            this.pollForDownloadCookie(() => this.props.updateModalContent());
                                        }}
                                        disabled={this.state.submitButtonDisabled}
                                    >
                                        Download Report
                                    </button>
                                </a>
                            </div>
                            <div className="type-centered spacing-20-bottom-mobile">
                                <span role="button" className="type-small-body type-heavy type-blue"
                                   onClick={() => this.props.updateModalContent()}
                                >
                                    Cancel
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ui-hide-mobile spacing-30-bottom">
                    <div className="popup__form__row popup__form__row__slam-right">
                        <div className="popup__form__cell">
                            <span role="button" className="type-small-body type-heavy type-blue"
                               onClick={() => this.props.updateModalContent()}
                            >
                                Cancel
                            </span>
                        </div>
                        <div className="popup__form__cell">
                            <a href={this.downloadReportUrl()}
                               download={true}
                            >
                                <button
                                    className="button ui-normal-button"
                                    onClick={() => {
                                        this.setState({submitButtonDisabled: true});
                                        this.pollForDownloadCookie(() => this.props.updateModalContent());
                                    }}
                                    disabled={this.state.submitButtonDisabled}
                                >
                                    Download Report
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="type-small-body">
                    <span className="type-heavy">TIP:</span> Use the Filters to adjust which Campaigns you'd like to download reports for.
                </div>
            </>
        );
    }


    /**
     * @return string - the URL for the download report link
     */
    downloadReportUrl = () => {
        return apiUrl + "/campaigns/historical_report?time_slice=" + this.state.timeSlice
            + "&" + this.props.filtersQueryStr();
    };

    /**
     * The download happens via an <a href> as opposed to JS.
     * So, there is no AJAX callback to determine when the download is complete.
     * Therefore, we use a trick: the server providing the downloaded file
     * sets a cookie. JS can poll and when it sees the cookie it knows the download
     * is complete.
     */
    deleteDownloadCookie = () => {
        //
        // TODO - bring domain out into a config var within .env
        // Possibly bring this.downloadCookieName out as a config var too?
        //
        document.cookie = this.downloadCookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.servicedirect.com; SameSite=None; Secure';
    };

    /**
     * Simple utility fn to get the value of a cookie if it exists
     * @param name
     * @returns string (the value of the cookie) or undefined if not present.
     */
    getCookie = (name) => {
        var parts = document.cookie.split(name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    /**
     * Check once a second for the presence of the "downloaded_campaign_report" cookie.
     * Once the cookie is detected, delete it, stop polling, and call callback.
     */
    pollForDownloadCookie = (callback) => {
        let interval = setInterval(() => {
            let cookie = this.getCookie(this.downloadCookieName);
            if (cookie) {
                clearInterval(interval);
                this.deleteDownloadCookie();
                callback();
            }
        }, 1000);
    };

}