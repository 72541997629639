import React, { useEffect, useState } from 'react'
// import MultiSelectList from '../../../common/components/form_elements/MultiSelectList'
import DropdownSelect from '../../../../common/components/DropdownSelect'
import { Row } from '../../../../common/layouts'
// import Input from '../../components/form_elements/Input'

export default ({
    wrongNumberReasons,
    onSaveLeadWrongNumberReasons,
    leadWrongNumberReasons,
    ...props
}) => {
    const [submitted, setSubmitted] = useState(false)
    const [leadStatus, setLeadStatus] = useState(props.lead.status_id)
    // const [leadReviewNote, setLeadReviewNote] = useState(props.lead?.note)
    // const [wrongNumberOptions, setWrongNumberOptions] = useState(wrongNumberReasons)
    const [isNoteDisabled, setIsNoteDisabled] = useState(true)
    const [isSavingNewReason, setIsSavingNewReason] = useState(false)

    // const optionsList = [...props.leadStatusOptions().slice(1)];
    const leadStatusOptions = [...props.leadStatusOptions()]

    // effect to enable / disable the lead review note input
    useEffect(() => {
        const statusesWillEnableNote = ['61']
        if (statusesWillEnableNote.includes(leadStatus)) {
            setIsNoteDisabled(false)
        } else {
            setIsNoteDisabled(true)
        }
    }, [leadStatus])

    let disabled
    if (
        !['in review', 'in review submitted by client'].includes(
            props.lead.lead_review_status
        ) &&
        props.lead.block_review === '1'
    ) {
        disabled = true
        if (submitted) {
            setSubmitted(false)
        }
    } else {
        disabled = false
    }

    const handleSetStatus = (val) => {
        setLeadStatus(val.value)
        // update the lead
        props.updateLead(props.lead.lead_id, {
            status_id: val.value,
        })
        // if the status is being set from wrong number to something else
        // clear the wrong number reason(s)
        if (leadStatus === '61' && val.val !== '61') {
            setIsSavingNewReason(true)
            onSaveLeadWrongNumberReasons &&
                onSaveLeadWrongNumberReasons([]).then(() => setIsSavingNewReason(false))
        }
    }

    const handleSetWrongNumberReason = (vals, action) => {
        const { action: actionName, option } = action
        if (actionName === 'create-option') {
            if (!window.confirm(`Create new wrong number reason "${option.label}"?`)) {
                vals.pop()
            } else {
                // on save, mutate to lowercase
                vals[vals.length - 1].label = vals[vals.length - 1].label?.toLowerCase()
                vals[vals.length - 1].value = vals[vals.length - 1].value?.toLowerCase()
            }
            setIsSavingNewReason(true)
        }
        onSaveLeadWrongNumberReasons &&
            onSaveLeadWrongNumberReasons(vals).then(() => setIsSavingNewReason(false))
    }

    return (
        <div className="admin-lead-review-block">
            <div className="leadlog__detail-section leadlog__detail-section__admin">
                <p className="type-large-body type-heavy spacing-10-bottom">
                    Lead Status
                </p>
                <Row>
                    <div style={{ width: '300px' }}>
                        <DropdownSelect
                            options={leadStatusOptions}
                            defaultValue={leadStatusOptions.filter(
                                (option) => option.value == props.lead.status_id
                            )}
                            placeholder={'Select...'}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.text}
                            onChange={handleSetStatus}
                            closeMenuOnSelect={true}
                            allowCreate={false}
                        />
                    </div>

                    <span className="type-left-side-bump" />
                    <button
                        className={`button ui-normal-button ${
                            submitted ? 'button__green' : ''
                        }`}
                        disabled={disabled}
                        onClick={() => {
                            setSubmitted(true)
                            props.updateLead(props.lead.lead_id, {
                                lead_review_complete: 1,
                            })
                        }}
                    >
                        Lead Review Complete
                    </button>
                </Row>
                {!isNoteDisabled && (
                    <Row>
                        <div style={{ width: '100%', marginTop: '10px' }}>
                            <DropdownSelect
                                label="Wrong Number Reason"
                                options={wrongNumberReasons}
                                values={leadWrongNumberReasons}
                                // defaultValue={optionsList.filter(
                                //     (option) => option.value == props.lead.status_id
                                // )}
                                placeholder={'Select...'}
                                isDisabled={isSavingNewReason}
                                getOptionValue={(option) => option.id}
                                // getOptionLabel={(option) => option.text}
                                onChange={handleSetWrongNumberReason}
                                closeMenuOnSelect={true}
                                allowCreate={true}
                                multiselect={true}
                            />
                        </div>
                    </Row>
                )}
                {props.submittedNotes.length > 0 &&
                <div className="submitted-notes">
                    <h3 className="type-large-body type-heavy spacing-24-top text-alert">
                        Lead Submitted for Review</h3>
                    {props.submittedNotes}
                </div>}
            </div>
        </div>
    )
}
