import React, {Component} from 'react';
import axios from "axios";

import DashboardBillingSnapshot from './DashboardBillingSnapshot';
import DashboardCampaignsSnapshot from './DashboardCampaignsSnapshot';
// import DashboardEventsSnapshot from './DashboardEventsSnapshot';
import DashboardLeadLogSnapshot from './DashboardLeadLogSnapshot';

import LeadSummary from '../lead_manager/LeadSummary';
import Modal from '../../common/Modal';
import LeadService from "../../service/LeadService";
import {errorHandler, get} from '../../Requests';
import BillingSummaryService from "../../service/BillingSummaryService";
import MajorAlerts from "../../common/MajorAlerts";
import queryString from "query-string";
import AuthService from "../../service/AuthService";

export default class DashboardView extends Component {

    billingSummaryService;

    constructor(props) {
        super(props);

        let companyName = AuthService.getItem('contractorName');

        this.state = {
            modalContent: undefined,
            modalHeader: undefined,
            modalWidth: undefined,
            modalFlatBottom: undefined,
            companyName: companyName,
            balanceSummary: {
                current_balance: null,
                lead_cost: null,
                lead_credit: null,
                // reverse_credit: null,
                threshold: null,
                unpaid_statement: null,
            },
            leadSummary: {
                needsFollowUp: undefined,
                adImpressionsAllTime: undefined,
                billableLeadsAllTime: undefined,
                billableLeadsLast30Days: undefined,
                bookedAppointmentRateAllTime: undefined,
                bookedAppointmentsLast30Days: undefined,
                confidence: undefined,
                bookedAppointmentConfidence: undefined,
                bookedAppointmentLast30DaysConfidence: undefined,
                leadsWithRevenue: undefined,
                managedRoi: undefined,
                minConfidence: undefined
            },
            users: [],
        };

        this.modalContainerRef = React.createRef();

        this.billingSummaryService = new BillingSummaryService();
        this.leadService = new LeadService();
        
        this.cancelSignal = axios.CancelToken.source();
    }

    componentDidMount() {
        this.getLeadSummary();
        this.getCurrentBalanceSummary();
        this.getUsers();
    }

    componentWillUnmount() {
        this.billingSummaryService.cancelSignal.cancel();
        this.leadService.cancelSignal.cancel();
    }

    updateModalContent = (modalContent, modalHeader, modalWidth, modalFlatBottom) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom
        });
    };

    /**
     * Gets the current balance summary
     */
    getCurrentBalanceSummary = () => {
        let contractorId = AuthService.getItem('contractorId');

        this.billingSummaryService.get(contractorId)
            .then((summary) => {
                let balanceSummary = {...this.state.balanceSummary};

                for (let key in summary) {
                    if (summary.hasOwnProperty(key)) {
                        balanceSummary[key] = summary[key];
                    }
                }

                this.setState({balanceSummary});
            })
            .catch(errorHandler);
    };

    /**
     * get the lead summary
     */
    getLeadSummary = () => {
        this.leadService.getSummary()
            .then((leadSummary) =>
                this.setState({leadSummary})
            )
            .catch(errorHandler);
    };

    getUsers = () => {
    
        let component = this,
            apiUrl = "users?";
    
        get(apiUrl, this.cancelSignal.token).then(function (resp) {
    
            // TODO add pop modal here
            if (!resp || resp.status !== 200) {
                return;
            }
    
            let users = resp.data.data.users;
            component.setState({users: users});
        })
        .catch(function (error) {
            console.log("Error in DashboardView.getUsers(), ", error);
        });
    }
    
    /**
     * redirects
     */
    leadsQuickFilterRedirect = (filterObject) => {
        if (!filterObject.date_range_id) {
            filterObject.date_range_id = 7;
        }

        let newPath = '/leads?' + queryString.stringify(filterObject, {arrayFormat: 'bracket'});

        this.props.history.push(newPath);
    };

    render() {
        return (
            <>
                <Modal
                    content={this.state.modalContent}
                    header={this.state.modalHeader}
                    width={this.state.modalWidth}
                    flatBottom={this.state.modalFlatBottom}
                    updateModalContent={this.updateModalContent}
                    modalContainerRef={this.modalContainerRef}
                />
                <MajorAlerts/>
                <div className="page-width-wide">
                    <div className="row padding-50-top">
                        <div className="wide-format-col">
                            <h2 className="type-normal-headline type-heavy">
                                Welcome {this.state.companyName}
                            </h2>
                        </div>
                        <div className="clear-block"/>
                    </div>

                    <LeadSummary
                        quickFilter={this.leadsQuickFilterRedirect}
                        leadSummary={this.state.leadSummary}
                        lastLeadChangeTimestamp={this.state.lastLeadChangeTimestamp}
                    />
                    <div className="row padding-50-bottom">
                        <div className="wide-format-col">
                            <div className="module">
                                <DashboardLeadLogSnapshot
                                    updateModalContent={this.updateModalContent}
                                    updateMessageBlocks={this.props.updateMessageBlocks}
                                    leadSummary={this.state.leadSummary}
                                    getLeadSummary={this.getLeadSummary}
                                    users={this.state.users}
                                />
                            </div>
                        </div>
                        <div className="clear-block"/>
                    </div>
                </div>
                <div className="page-width-wide">
                    <div className="row">
                        <div className="wide-col-wrapper">
                            <div className="wide-col alpha grid-6">
                                {/*<div className="module spacing-50-bottom">*/}
                                {/*    <DashboardEventsSnapshot />*/}
                                {/*</div>*/}
                                <div className="module">
                                    <DashboardBillingSnapshot
                                        updateBillingSummary={this.getCurrentBalanceSummary}
                                        balanceSummary={this.state.balanceSummary}
                                        updateModalContent={this.updateModalContent}
                                        updateMessageBlocks={this.props.updateMessageBlocks}
                                    />
                                </div>
                            </div>
                            <div className="wide-col omega grid-6">
                                <div className="module">
                                    <DashboardCampaignsSnapshot
                                        updateMessageBlocks={this.props.updateMessageBlocks}
                                        updateModalContent={this.updateModalContent}
                                        modalContainerRef={this.modalContainerRef}
                                    />
                                </div>
                            </div>
                            <div className="clear-block"/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
