import React, { Fragment } from 'react'
import Button from '../../../../../common/components/form_elements/Button'
import { ContentWindowScroll } from '../../../../../common/Modal'
import { ActionsWrapper, Divider } from '../styles'
import { ContentWrapper, EditConfirmationLine, Wrapper } from './styles'
import Label from '../../../../../common/components/form_elements/Label'
import { formatPhoneNumber } from '../../../../../Util'
// import { ConfirmationLine } from '../NumberConfirmation/styles'

export default function EditConfirmation(props) {
    const {
        affiliate,
        changes,
        newNumbers,
        onGoBack = () => null,
        onConfirm = () => null,
        deletedNumberIds = [],
        changedIds = [],
    } = props
    console.log('confirmation', deletedNumberIds)
    const updatesView = changes?.map((n) => {
        // filter out unchanged numbers for display
        if (changedIds.includes(n.did_phone_number_id)) {
            return (
                <Fragment key={n.did_phone_number_id}>
                    <EditConfirmationLine borderLight>
                        <span className="type-large-body">{n.industry_name}</span>
                        <span className="type-large-body">{n.phone_name}</span>
                        <span className="type-large-body">
                            {formatPhoneNumber(n.phone_number)}
                        </span>
                        <span className="type-large-body">
                            {n.is_whisper_audio == '1' ? 'Yes' : 'No'}
                        </span>
                        <div />
                    </EditConfirmationLine>
                    <Divider borderLight />
                </Fragment>
            )
        } else {
            return null
        }
    })

    const newNumbersView = newNumbers?.map((n) => {
        return (
            <Fragment key={n._id}>
                <EditConfirmationLine borderLight>
                    <span className="type-large-body">{n.serviceCategoryLabel}</span>
                    <span className="type-large-body">{n.phone_name}</span>
                    <span className="type-large-body">{n.area_code}</span>
                    <span className="type-large-body">
                        {n.is_whisper_audio == '1' ? 'Yes' : 'No'}
                    </span>
                </EditConfirmationLine>
                <Divider borderLight />
            </Fragment>
        )
    })

    const deletedNumbersView = changes?.map((n) => {
        // filter out unchanged numbers for display
        if (deletedNumberIds.includes(n.did_phone_number_id)) {
            return (
                <Fragment key={n.did_phone_number_id}>
                    <EditConfirmationLine borderLight>
                        <span className="type-large-body">{n.industry_name}</span>
                        <span className="type-large-body">{n.phone_name}</span>
                        <span className="type-large-body">
                            {formatPhoneNumber(n.phone_number)}
                        </span>
                        <span className="type-large-body">
                            {n.is_whisper_audio == '1' ? 'Yes' : 'No'}
                        </span>
                        <div />
                    </EditConfirmationLine>
                    <Divider borderLight />
                </Fragment>
            )
        } else {
            return null
        }
    })

    return (
        <Fragment>
            <ContentWindowScroll>
                <Wrapper>
                    <div className="spacing-6-bottom">
                        <span className="type-normal-subhead type-heavy spacing-6-bottom">
                            Confirm Change to DID Numbers for {affiliate.text}
                        </span>
                    </div>
                    {changedIds?.length > 0 && (
                        <ContentWrapper className="spacing-24-bottom">
                            <div className="spacing-24-bottom">
                                <span className="type-normal-body">
                                    Upon confirmation, the following phone numbers will be
                                    updated for this Lead Source.
                                </span>
                            </div>
                            <div className="module__header padding-12">
                                <span
                                    className="type-normal-body type-heavy"
                                    style={{ paddingLeft: '8px', width: '100%' }}
                                >
                                    Updates to existing DID Numbers
                                </span>
                            </div>
                            <EditConfirmationLine>
                                <Label label="Service Category" />
                                <Label label="Phone Name" />
                                <Label label="Phone Number" />
                                <Label label="Play Whisper File" />
                            </EditConfirmationLine>
                            <Divider />
                            {updatesView}
                        </ContentWrapper>
                    )}
                    {newNumbers?.length > 0 && (
                        <ContentWrapper>
                            <div className="spacing-24-bottom">
                                <span className="type-normal-body">
                                    Upon confirmation, phone numbers will be purchased and
                                    set up for this Lead Source.
                                </span>
                            </div>
                            <div className="module__header padding-12">
                                <span
                                    className="type-normal-body type-heavy"
                                    style={{ paddingLeft: '8px', width: '100%' }}
                                >
                                    New DID Numbers
                                </span>
                            </div>
                            <EditConfirmationLine>
                                <Label label="Service Category" />
                                <Label label="Phone Name" />
                                <Label label="Area Code" />
                                <Label label="Play Whisper File" />
                            </EditConfirmationLine>
                            <Divider />
                            {newNumbersView}
                        </ContentWrapper>
                    )}
                    {deletedNumberIds?.length > 0 && (
                        <ContentWrapper>
                            <div className="spacing-24-bottom">
                                <span className="type-normal-body">
                                    Upon confirmation, the following phone numbers will be deleted
                                    for this Lead Source.
                                </span>
                            </div>
                            <div className="module__header padding-12">
                                <span
                                    className="type-normal-body type-heavy"
                                    style={{ paddingLeft: '8px', width: '100%' }}
                                >
                                    Deleted DID Numbers
                                </span>
                            </div>
                            <EditConfirmationLine>
                                <Label label="Service Category" />
                                <Label label="Phone Name" />
                                <Label label="Phone Number" />
                                <Label label="Play Whisper File" />
                            </EditConfirmationLine>
                            <Divider />
                            {deletedNumbersView}
                        </ContentWrapper>
                    )}
                    {/* {newNumbersView} */}
                    {/* </ContentWrapper> */}
                </Wrapper>
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button
                    onClick={onGoBack({
                        changes,
                        affiliate,
                        action: 'edit',
                        changedIds,
                        newNumbers,
                        deletedNumberIds,
                    })}
                    variant="text"
                >
                    <span className="type-heavy">Previous Step</span>
                </Button>
                <Button
                    onClick={() => {
                        onConfirm &&
                            onConfirm({ changes, affiliate, changedIds, newNumbers, deletedNumberIds })
                    }}
                >
                    Confirm And Save
                </Button>
            </ActionsWrapper>
        </Fragment>
    )
}
