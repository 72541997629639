import React, { useState, useEffect, Fragment } from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import {
    IconCaretDownSvg,
    IconCaretUpSvg,
    QuestionMarkSvg,
    SvgWrapper,
} from '../../../common/Svgs'
import { HeaderCell, ArrowWrapper, HeaderLabel } from './styles'

export default (props) => {
    const [ascSort, setAscSort] = useState('inactive')
    const [descSort, setDescSort] = useState('inactive')

    const onSortRequested = (order, event) => {
        // props.setSort(order, event.shiftKey)
        console.log(props, order)
        const { sort, progressSort } = props
        // shift key is used for multi sorting
        if (event.shiftKey) {
            progressSort && progressSort(event.shiftKey)
        } else {
            sort && sort(order)
        }
    }

    // add change listener on the column
    useEffect(() => {
        const onSortChanged = () => {
            setAscSort(props.column.isSortAscending() ? 'active' : 'inactive')
            setDescSort(props.column.isSortDescending() ? 'active' : 'inactive')
        }
        props.column.addEventListener('sortChanged', onSortChanged)
        onSortChanged()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSortArrow = () => {
        // if there's a hideArrow column property, use it; otherwise use the column definition default
        const hideArrow = props.hideArrow !== undefined
            ? props.hideArrow
            : props.column.colDef.hideArrow;

        if (props.enableSorting && !hideArrow) {
            let arrow = null
            // console.log({ascSort, descSort, displayName: props.displayName})
            if (ascSort === 'active') {
                // arrow = <ArrowUp color="white" size="small" />
                arrow = <SvgWrapper fill="white">{IconCaretUpSvg}</SvgWrapper>
            } else if (descSort === 'active') {
                // arrow = <ArrowDown color="white" size="small" />
                arrow = <SvgWrapper fill="white">{IconCaretDownSvg}</SvgWrapper>
            }
            if (arrow) {
                return <ArrowWrapper>{arrow}</ArrowWrapper>
            }
        }
        return null
    }

    const getTooltip = () => {
        if (props.tooltip) {
            const content = <Fragment>{props.tooltip}</Fragment>
            return (
                <div style={{ zIndex: 1, cursor: 'pointer' }}>
                    <Tippy
                        content={content}
                        allowHTML={true}
                        trigger="click"
                        interactive={true}
                        appendTo={() => document.body}
                        theme="sd-tooltip"
                    >
                        <div style={{ height: '15px', width: '15px' }}>
                            <SvgWrapper>{QuestionMarkSvg}</SvgWrapper>
                        </div>
                    </Tippy>
                </div>
            )
        }
        return null
    }

    // const isSelected = props.column?.isSortAscending() || props.column?.isSortDescending()
    // console.log({ isSelected, })
    return (
        <HeaderCell
            // selected={isSelected}
            // selectable={props.enableSorting}
            // selectable={false}
            // onClick={props.enableSorting ? (e) => onSortRequested('asc', e) : null}
        >
            <HeaderLabel
                onClick={props.enableSorting ? (e) => onSortRequested(
                    props.column?.isSortAscending() ? 'desc' : 'asc', 
                    e) : null}
                className="customHeaderLabel"
            >
                {props.displayName}
            </HeaderLabel>
            {getSortArrow()}
            {getTooltip()}
        </HeaderCell>
    )
}
