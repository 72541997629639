import React, {useRef} from 'react';
import PropTypes from 'prop-types';
/**
 * ClickableDisabledButton Component
 * 
 * This component wraps a button inside a div and provides functionality to handle click events on a disabled button.
 * When the button is disabled and clicked, it prevents the default action and triggers a custom handler if provided.
 * 
 * @param {Object} props - The props object.
 * @param {boolean} props.disabled - Determines if the button is disabled.
 * @param {Function} [props.disabledClickHandler] - Function to handle clicks on the disabled button.
 * @param {string} [props.title] - The title attribute for the button, used as a tooltip.
 * @param {string} [props.extraClass] - Additional CSS classes to apply to the button.
 * @param {Function} props.onClick - Function to handle clicks on the button when it's not disabled.
 * @param {React.ReactNode} props.children - The content of the button.
 * @returns {JSX.Element} The rendered component.
 */
const ClickableDisabledButton = ({
    title,
    disabled,
    onClick,
    disabledClickHandler,
    extraClass,
    children,
 }) => {
    const buttonRef = useRef(null);

    const handleClick = (e) => {
        if (buttonRef.current && buttonRef.current.disabled) {
            e.preventDefault();
            if (disabledClickHandler) {
                disabledClickHandler();
            }
        }
    };

    const handleMouseOver = (e) => {
        if (buttonRef.current) {
            e.target.title = buttonRef.current.disabled ? title : '';
            e.target.style.cursor = buttonRef.current.disabled ? 'not-allowed' : 'pointer';
        }
    };

    return (
        <div
            className="clickable-disabled-button-wrapper"
            onClick={handleClick}
            onMouseOver={handleMouseOver}
        >
            <button
                className={`button ui-normal-button ${extraClass ?? ''}`}
                ref={buttonRef}
                disabled={disabled}
                title={title}
                onClick={onClick}
            >
                {children}
            </button>
        </div>
    );
};
ClickableDisabledButton.propTypes = {
    disabled: PropTypes.bool,
    disabledClickHandler: PropTypes.func,
    title: PropTypes.string,
    extraClass: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

ClickableDisabledButton.defaultProps = {
    disabled: false,
    disabledClickHandler: null,
    title: '',
    extraClass: '',
};
export default ClickableDisabledButton;