import React from 'react';
import ServiceCategoryAndGeo from './ServiceCategoryAndGeo';

const ServiceCategoryAndGeos = props =>
    props.serviceCategories.map((serviceObject, key) =>
        <ServiceCategoryAndGeo
            dropdownValues={props.options}
            setGroups={props.setGroups}
            arrayIndex={key}
            serviceCategory={serviceObject}
            key={key}
            updateServiceCategoryAndGeo={props.updateServiceCategoryAndGeo}
            updateModalContent={props.updateModalContent}
            closeModal={props.closeModal}
            updateMessageBlocks={props.updateMessageBlocks}
            removeServiceCategory={props.removeServiceCategory}
            country={props.country}
        />
    );

export default ServiceCategoryAndGeos;
