import React from 'react';

import NavigationFooter from './navigation-footer';
import StepSix from './step-six';

const SelfServeOnboardingFinalStep = (props) => {
	const { stepProps } = props;
	
	return (
		<>
		<h2
			className="type-normal-subhead-special spacing-60 onboarding-page-header-spacing"
		>
			Service Direct Business Info & Qualification
		</h2>
		<StepSix {...props} />
		<NavigationFooter {...stepProps} {...props} />
		</>
	);
}

export default SelfServeOnboardingFinalStep;