import React, {Component} from 'react';
import MapZipCodeSelection from "../common/MapZipCodeSelection";
import {imagesUrls} from "../common/StaticValues";
import ResourcesService from "../service/ResourcesService";
import MultiSelectList from '../common/components/form_elements/MultiSelectList';

/**
 * this whole component is used to redirect [authenticated] users to the correct step in case of no specific path
 */
export default class MapSandbox extends Component {
    constructor(props) {
        super(props);
        document.title = 'Map Sandbox';

        this.state = {
            country: 'USA',
            mainZipCode: '',
            radius: [],
            zipCodes: [],
            mapUrl: ''
        };

        this.resourcesService = new ResourcesService();
        this.selectRef = React.createRef();
    }

    valueChange = (key, value) => {
        this.setState({[key]: value});
    };

    updateMap = () => {
        if (this.state.zipCodes.length == 0) {
            return;
        }

        this.resourcesService.getMapByZips(this.state.zipCodes, this.state.country === 'CAN')
            .then((mapUrl) => this.setState({mapUrl}));
    };

    get mapImage() {
        if (this.state.mapUrl) {
            return this.state.mapUrl;
        }

        return this.isCanada
            ? imagesUrls.canadaMapPlaceholder
            : imagesUrls.usMapPlaceholder;
    };

    render() {
        return <section className="white-background">
            <div className="page-width">
                <div className="row">
                    <div className="col alpha omega grid-12">
                        <div className="form__cell form__cell__50 form__cell__100__mobile spacing-30">
                            <MultiSelectList
                            inputRef={this.selectRef}
                            selections={[this.state.country]}
                            onChange={(vals) => this.valueChange('country', vals[0])}
                            options={[
                                { value: 'USA', text: 'United States' },
                                { value: 'CAN', text: 'Canada' },
                            ]}
                        />
                        </div>

                        <div className="form__row">
                            <div className="form__cell form__cell__75 form__cell__100__mobile">
                                <MapZipCodeSelection zipCodes={this.state.zipCodes} mainZipCode={this.state.mainZipCode}
                                                     onValueChange={this.valueChange} country={this.state.country}
                                                     clean={true}/>
                            </div>

                            <div className="form__cell form__cell__25 form__cell__100__mobile center">
                                <button onClick={this.updateMap} className="button-clean type-blue">
                                    Refresh the Map
                                </button>
                                <img src={this.mapImage} alt="Service Area Map" className="static-map-image spacing-10"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }
}
