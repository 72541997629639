import React, {Component} from 'react';

import {del, post, put} from '../../../Requests';
// table cells that deal with signup record for the launch status table
import SignupTableCells from './SignupTableCells';
// table cells that deal with a signup records campaign for the launch status
import CampaignTableCells from './CampaignTableCells';
import UserService from "../../../service/UserService";
import {getClientMenuLinks} from "../../../Util";
import {setUsersSubMenu} from "../../../UtilJsx";

/**
 * The HTML <tr>s are structured as follows: the leftmost columns are specific to the
 * whole Client/Signup, and those cells may have a rowspan in place causing them to
 * span multiple rows. The rightmost cells are the Campaign-specific columns - and there
 * may be multiple <tr>'s, 1 for each campaign, corresponding with and lining up with
 * the 1 Client/Signup <tr>.
 *
 * However, and this is tricky - the 1st Campaign row <td>s must be in the same <tr>
 * along with the Client/Signup row. After the initial <tr> containing the Client/Signup
 * cells and the 1st Campaign cells, there may me additional <tr>s, one for each additional
 * Campaign. These additional <tr>s do not need any of the Client/Signup cells - only
 * the Campaign cells. Due to the rowspan="N" on the Client/Signup cells, the Campaign
 * cells on these subsequent rows will automatically show up to the right of the
 * Client/Signup cells.
 *
 * The Way this is handled is by getting an array of the <tr>s we will need for the signup row
 * and in our Render function just wrap them up with a React.Fragment component which will let you
 * render an array of elements but have a key that way you can have your cake and eat it to.
 */
export default class SignupLaunchStatusTableRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientMenuLinks: [],
            isContextualMenuOpen: false
        };

        this.userService = new UserService();
    }

    componentDidMount(): void {
        const {signupRow} = this.props;
        const clientMenuLinks = getClientMenuLinks(signupRow.contractor_id, signupRow.hubspot_company_id);
        this.setState({clientMenuLinks});
    }

    componentWillUnmount(): void {
        this.userService.cancelSignal.cancel();
    }

    getUsersLinks = (): void => {
        const {clientMenuLinks} = this.state;
        this.userService.getByAdmin(this.props.signupRow.contractor_id)
            .then((users) => {
                setUsersSubMenu(users, clientMenuLinks);
                this.setState({clientMenuLinks});
            });
    };

    updateCSManager = (event) => {

        let csManagerUserId = event.target.value;
        let signupRowClone = {...this.props.signupRow};
        const csManagers = this.props.csManagers;
        let csManager;
        for (let i in csManagers) {
            if (!csManagers.hasOwnProperty(i)) {
                continue;
            }

            if (csManagers[i].user_id === csManagerUserId) {
                csManager = csManagers[i];
            }
        }

        if (!csManager) {
            return;
        }

        let url = 'contractors/' + signupRowClone.contractor_id
            + '/customer_success_manager/' + csManager.user_id;
        put(url).then(resp => {
            // TODO add message blocks
            if (resp.status !== 200) {
                return;
            }
            signupRowClone.customer_success_manager_id = csManager.user_id;
            signupRowClone.customer_success_manager = csManager.username;
            this.props.updateSignupRow(this.props.index, signupRowClone);
        });
    };

    getCsManagerSelectList = () => {
        const signupRow = this.props.signupRow;
        const csManagers = this.props.csManagers;

        let csManager = null;
        for (let i in csManagers) {
            if (!csManagers.hasOwnProperty(i)) {
                continue;
            }

            if (csManagers[i].user_id === signupRow.customer_success_manager_id) {
                csManager = csManagers[i];
            }
        }

        const options = csManagers.map((val, key) => (
            <option value={val.user_id} key={key}>
                {val.username}
            </option>
        ))

        return (
            <div className={'select-container '}>
                <select
                    id="signup-status-cs-manager-select-list"
                    name="signup-status-cs-manager-select-list"
                    value={csManager ? csManager.user_id : ''}
                    onChange={(e) => this.updateCSManager(e)}
                    className="select-list ui-normal-text-size"
                >
                    {options}
                </select>
            </div>
        )
    };

    /**
     * Logic for when admin user hits the "Campaigns Done, Send Notification" button
     */
    sendCampaignsDoneRequest = () =>
        put(`signup/${this.props.signupRow.contractor_id}/campaigns-done`, {})
            .then((res) => {
                // if (resp.status !== 200) {
                //     return;
                // }
                console.log("res", res);
                let signupRowClone = {...this.props.signupRow};
                signupRowClone.date_completed_signup = res.data.data.date_completed_signup;
                this.props.updateSignupRow(this.props.index, signupRowClone);
                this.props.updateModalContent(); // close modal
                if (res.data.message) {
                    this.props.updateMessageBlocks(res.data.message, 'success');
                }
            })
            .catch((error) => {
                console.log("sendCampaignsDoneRequest - caught an error:", error);

                try {
                    // we wrap this in a try & catch to prevent crashing on missing message variable path
                    this.props.updateMessageBlocks(error.response.data.errors[0].message, 'error');
                } catch (ignore) {
                }
            });

    /**
     * @param contractorId
     * @returns Promise so additional callbacks can be added on resolve/reject/both
     */
    generateTestCampaigns = (contractorId: number): Promise =>
        post('signup/clone-campaigns-for-contractor', {contractor_id: contractorId})
            .then((res) => {
                if ('data' in res && 'message' in res.data) {
                    this.props.updateMessageBlocks(res.data.message, 'success');
                }
            })
            .catch((err) => {
                if (!('response' in err)) {
                    console.log("generateTestCampaigns - caught an error:", err);
                    return;
                }
                let res = err.response;
                if (!('data' in res && 'message' in res.data)) {
                    console.log("generateTestCampaigns - caught an error:", err);
                    return;
                }
                this.props.updateMessageBlocks(res.data.message, 'error');
            });

    /**
     * Performs light validation for date format, leaving a more thorough check
     * up to the API, then submits the new estimated completion date for
     * the provided signup row.
     * @param {string} newExpectedCampaignCompletionDate - a valid date of "yyyy-mm-dd" format
     * @return {boolean}
     */
    submitExpectedCampaignCompletionDate = (newExpectedCampaignCompletionDate) => {
        if (!newExpectedCampaignCompletionDate) {
            let message = "Estimated completion date must be in format MM/DD/YYYY.";
            this.props.updateMessageBlocks(message, 'error');
            return false;
        }

        let {signupRow} = this.props,
            contractorId = signupRow.contractor_id,
            url = `signup/${contractorId}/campaign-completion-date`,
            data = {
                expected_campaign_completion_date: newExpectedCampaignCompletionDate
            };

        put(url, data).then((resp) => {
            let returnStatus = resp.status,
                message,
                messageType;

            if (returnStatus == 200) {
                signupRow.expected_campaign_completion_date = resp.data.data.expected_campaign_completion_date;

                message = resp.data.message;
                messageType = "success";

                this.props.updateSignupRow(this.props.index, signupRow);
            }
            else {
                message = resp.data.errors[0].message;
                messageType = "error";
            }

            this.props.updateMessageBlocks(message, messageType);
            this.props.updateModalContent();
        });
    };

    /**
     * Removes expected campaign completion date via DELETE request.
     * @return {boolean}
     */
    removeExpectedCampaignCompletionDate = () => {
        let signupRowClone = {...this.props.signupRow},
            contractorId = signupRowClone.contractor_id,
            url = `signup/${contractorId}/campaign-completion-date`;

        del(url)
            .then((resp) => {
                let message,
                    messageType;

                if (resp.status === 200) {
                    signupRowClone.expected_campaign_completion_date = null;

                    message = resp.data.message;
                    messageType = "success";

                    this.props.updateSignupRow(this.props.index, signupRowClone);
                }
                else {
                    message = resp.data.errors[0].message;
                    messageType = "error";
                }

                this.props.updateMessageBlocks(message, messageType);
                this.props.updateModalContent();
            });
    };

    /**
     * updates the row's state so that we can add a special class when the contextual menu is open to prevent
     * overflowing of the menu by child rows with "position: sticky;"; might be a current webkit bug
     * @param {string} toggleState - the state of the contextual menu: "open" or "close"
     */
    setClassForContextualMenu = (toggleState) =>
        this.setState({isContextualMenuOpen: toggleState === 'open'});

    render() {
        const signupRow = this.props.signupRow;
        const enroll = !!signupRow.select_enroll_started_timestamp;
        const existingCampaigns = [];
        const newCampaigns = [];
        let extraCampaignRow = false;

        if (signupRow.campaigns) {
            const existingCampaignsCount = signupRow.campaigns.reduce((count, campaign) =>
                    campaign.start_timestamp < signupRow.select_enroll_started_timestamp
                        ? ++count
                        : count,
                0);
            const newCampaignsCount = signupRow.campaigns.length - existingCampaignsCount;
            let isFirstOfNewCampaigns = true;
            let isFirstOfExistingCampaigns = true;

            signupRow.campaigns.forEach((campaign, key) => {
                const isAnExistingCampaign = campaign.start_timestamp < signupRow.select_enroll_started_timestamp;
                const targetCampaigns = isAnExistingCampaign
                    ? existingCampaigns
                    : newCampaigns;
                const keyName = this.props.index + '-signup-launch-status-table-row-campaign-cells-' + key;

                targetCampaigns.push(<CampaignTableCells
                    campaign={campaign}
                    signupRow={signupRow}
                    signupRowIndex={this.props.index}
                    existingCampaign={isAnExistingCampaign}
                    key={keyName}
                    rowKey={keyName}
                    firstOfTypes={isAnExistingCampaign
                        ? isFirstOfExistingCampaigns
                        : isFirstOfNewCampaigns}
                    numCampaigns={isAnExistingCampaign
                        ? existingCampaignsCount
                        : newCampaignsCount}
                    updateModalContent={this.props.updateModalContent}
                    updateMessageBlocks={this.props.updateMessageBlocks}
                    submitExpectedCampaignCompletionDate={this.submitExpectedCampaignCompletionDate}
                    removeExpectedCampaignCompletionDate={this.removeExpectedCampaignCompletionDate}
                    sendCampaignsDoneRequest={this.sendCampaignsDoneRequest}
                    generateTestCampaigns={this.generateTestCampaigns}
                    updateSignupRow={this.props.updateSignupRow}
                />);

                // marks the first campaign of the set so we can set the shared column row count
                if (isFirstOfExistingCampaigns && isAnExistingCampaign) {
                    isFirstOfExistingCampaigns = false;
                }
                else if (isFirstOfNewCampaigns && !isAnExistingCampaign) {
                    isFirstOfNewCampaigns = false;
                }
            });
        }

        if (newCampaigns.length === 0) {
            const key = this.props.index + '-signup-launch-status-table-row-campaign-cells--base';
            extraCampaignRow = enroll;
            newCampaigns.push(
                <CampaignTableCells
                    campaign={null}
                    signupRow={signupRow}
                    signupRowIndex={this.props.index}
                    key={key}
                    rowKey={key}
                    firstOfTypes={true}
                    updateModalContent={this.props.updateModalContent}
                    updateMessageBlocks={this.props.updateMessageBlocks}
                    submitExpectedCampaignCompletionDate={this.submitExpectedCampaignCompletionDate}
                    removeExpectedCampaignCompletionDate={this.removeExpectedCampaignCompletionDate}
                    sendCampaignsDoneRequest={this.sendCampaignsDoneRequest}
                    generateTestCampaigns={this.generateTestCampaigns}
                    updateSignupRow={this.props.updateSignupRow}
                />
            );
        }

        const firstCampaign = newCampaigns.shift();
        const restOfCampaigns = newCampaigns.concat(existingCampaigns).map((campaignSection, key) =>
            <tr key={key + 1}>{campaignSection}</tr>);

        const key = `${this.props.index}-signup-launch-status-table-row-cells`;

        const csManagerSelectList = this.getCsManagerSelectList();
        const signupTableCells = <SignupTableCells
            signupRow={signupRow}
            csManagerSelectList={csManagerSelectList}
            key={signupRow.signup_id}
            extraCampaignRow={extraCampaignRow}
            rowKey={key}
            clientMenuLinks={this.state.clientMenuLinks}
            onFirstOpen={this.getUsersLinks}
            onConextualMenuToggle={this.setClassForContextualMenu}
            scrollboxRef={ this.props.scrollboxRef }
        />;

        if (restOfCampaigns.length > 0) {
            return [<tr key={0} className={this.state.isContextualMenuOpen ? 'row-with-open-menu' : ''}>
                {signupTableCells}
                {firstCampaign}
            </tr>, [restOfCampaigns]];
        }

        return [<tr key={0} className={this.state.isContextualMenuOpen ? 'row-with-open-menu' : ''}>
            {signupTableCells}
            {firstCampaign}
        </tr>];
    }
}
