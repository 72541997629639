import React from 'react'
import CreatableSelect from 'react-select/creatable'
import Select, { createFilter } from 'react-select'
import { components } from 'react-select'
import Label from '../form_elements/Label'
import { Wrapper } from './styles'
// import { ArrowDown } from '../Decorators'
import { IconCaretDownSvg, SvgWrapper } from '../../Svgs'

const DropdownSelect = ({
    label,
    onChange = () => null,
    onCreateNew = () => null,
    values,
    multiselect = false,
    options,
    allowCreate = true,
    ...rest
}) => {
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <div
                    style={{
                        marginTop: '-7px',
                        marginRight: '5px',
                        width: '15px',
                        height: '10px',
                    }}
                >
                    {/* {<ArrowDown size="6.3px" />} */}
                    <SvgWrapper fill={rest?.disabled ? '#58728E' : 'black'}>
                        {IconCaretDownSvg}
                    </SvgWrapper>
                </div>
            </components.DropdownIndicator>
        )
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '1px solid #91abc6',
            padding: '2px',
            borderRadius: 0,
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'white',
            paddingLeft: '0px',
            ':hover': {
                backgroundColor: 'transparent',
                color: 'white',
            },
        }),
        input: (provided) => ({
            ...provided,
            padding: '5px 0px 5px 7px',
        }),
        placeholder: (provided) => ({
            ...provided,
            padding: '5px 0px 5px 7px',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#0094d9' : 'black',
            fontWeight: state.isSelected ? '700' : '',
            border: 'none',
            // borderRadius: '4px',
            padding: '0.7978035009em 1em',
            // padding: 20,
            backgroundColor: state.isFocused ? '#f0f3f6' : 'transparent',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#0094d9',
            color: 'white',
            border: 'none',
            borderRadius: '0',
            padding: '2px 0px 2px 5px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'white',
            fontSize: '1em',
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1
            const transition = 'opacity 300ms'
            return { ...provided, opacity, transition, paddingLeft: '7px' }
        },
        menu: (provided, state) => ({
            ...provided,
            borderRadius: '0',
            marginTop: '1px',
        }),
        menuList: (provided, state) => ({
            ...provided,
            padding: '0',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '3px 4px',
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),
    }

    const handleChange = (...args) => {
        onChange(...args)
    }

    let Component = Select
    if (allowCreate) {
        Component = CreatableSelect
    }

    return (
        <Wrapper>
            {label && <Label label={label} />}
            <Component
                key={`create-select-${values?.length}`}
                options={options}
                defaultValue={values}
                className="basic-multi-select"
                classNamePrefix="select"
                components={{ DropdownIndicator }}
                styles={customStyles}
                isMulti={multiselect}
                closeMenuOnSelect={!multiselect}
                blurInputOnSelect={!multiselect}
                isClearable={false}
                onChange={handleChange}
                filterOption={createFilter({
                    matchFrom: 'start',
                    ignoreCase: true,
                    trim: false,
                })}
                {...rest}
            />
            {/* <StyledSelect
                onCreateNew={onCreateNew}
                onChange={onChange}
                dropdownHandleRenderer={dropdownRenderer}
                values={values}
                options={options}
                {...rest}
            /> */}
        </Wrapper>
    )
}

// DropdownSelect.propTypes = StyledSelect.propTypes

export default DropdownSelect
