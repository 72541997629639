import React from 'react';
import {CampaignTypes} from "../../../common/StaticValues";
import Tooltip from "../../../common/Tooltip";
import AuthService from "../../../service/AuthService";
import {formatNumber} from "../../../Util";

const MissedOpportunities = (props) => {
    const {campaign} = props;
    const sevenDaysBeforeToday = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const missedLeads = Math.max(Number(campaign.total_lead_opportunities) - Number(campaign.total_leads), 0);

    // if not a marketplace campaign,
    //  or nudge is disabled for the contractor
    //  or nudge is disabled for the campaign
    //  or the first enabling of a campaign was within 7 days of now
    //  or if there are no missed leads
    //  do not display the nudge
    if (
        campaign.type !== CampaignTypes.MARKETPLACE ||
        !props.showMpCplNudgeContractor ||
        !campaign.show_mp_cpl_nudge ||
        sevenDaysBeforeToday < new Date(props.mpFirstEnableTimestamp) ||
        missedLeads === 0
    ) {
        return <div/>;
    }

    return <div className="campaigns-table__missed-opportunities">
        <div className="header">
            <span className="type-orange missed-leads-count">{formatNumber(missedLeads, 0)}</span>{' '}
            Missed Opportunities
        </div>

        <div className="subheader">
            In the last 7 Days.{' '}
            <Tooltip
                position="left"
                anchor="Want to win more Leads?"
                content={
                    <>
                    <span
                        className="type-large-body type-black type-force-newline type-align-left type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                        Lead Opportunities
                    </span>
                        <span
                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                            We have identified that this Campaign has additional Lead Opportunities and recommend
                            increasing your Cost Per Lead (CPL) to improve your chance of winning more Leads.
                    </span>
                        <span
                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                        While your CPL primarily determines how many Leads you receive, a CPL increase doesn't guarantee more Leads.
                        Additional factors, such as your Ad Schedule and Call Answer Rate, also contribute to your
                        <a className="type-heavy" target="_blank"
                           href={AuthService.isMarketplace
                               ? "https://support.servicedirect.com/lead-volume"
                               : "https://support.servicedirect.com/select/lead-volume"}> Lead Volume
                        </a>.
                    </span>
                    </>
                }
            />
        </div>

        {/*In the last 7 Days, you won
        {campaign.count_billable_leads
            ? Number(campaign.count_billable_leads) + " Lead"
            : Number(campaign.count_billable_leads) + " Leads"}
        <b> but missed out on {missedLeads}.</b>{' '}
        Want to win more? <b>{campaign.is_paused_to_client === '1' ? 'Enable this Campaign and ' : ' '}
        Increase your Cost Per Lead.</b>*/}
    </div>;
};

export default MissedOpportunities;
