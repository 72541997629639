import React from 'react';

import MultiSelectList from '../common/components/form_elements/MultiSelectList';

const QuizQuestion = (props) => {
	// NB: Answers are numbered from 1, not 0, for clarity in content editing
	
	const { question, answers, name, correctAnswer } = props.questionData;
	
	const highlightError = props.quizErrors.includes(props.questionIdx) && props.quizAnsweredState[props.questionIdx];
	
	return (
		<div className="onboarding-quiz-question">
			<p className={`type-large-body spacing-12-bottom ${highlightError ? 'type-red' : ''}`}>
				{question}
			</p>
			<div className="answer-menu">
				<MultiSelectList
					name={name}
					emptyLabel="Please Select"
					options={answers.map((answer, idx) => { return { text: answer, value: `answer${idx + 1}` } })}
					hasError={highlightError}
					onChange={(selections) => {
						// Any answer flips the answered state on this question
						const _answeredState = [...props.quizAnsweredState];
						_answeredState[parseInt(name.substring(name.length - 1)) - 1] = true;
						props.setQuizAnsweredState(_answeredState);
						
						// Remaining logic addresses validating for correct answers
						const selectedAnswer = parseInt(selections[0].substring(selections[0].length - 1));
						
						props.setHighlightErrors(false);
						
						if (selectedAnswer === correctAnswer) {
							if (props.quizErrors.includes(props.questionIdx)) {
								// If the question is in the error list, remove it because answer is now correct
								const _quizErrorsClone = [...props.quizErrors];
								const idxToRemove = _quizErrorsClone.indexOf(props.questionIdx);
								
								_quizErrorsClone.splice(idxToRemove, 1);
								props.setQuizErrors(_quizErrorsClone);
							}
						} else {
							if (!props.quizErrors.includes(props.questionIdx)) {
								// Answer is now incorrect, so add question to error list if it is not there already
								const _quizErrorsClone = [...props.quizErrors];
								_quizErrorsClone.push(props.questionIdx);
								props.setQuizErrors(_quizErrorsClone);
							}
						}
					}}
					extraClass={highlightError ? ' ui-alert' : ''}
				/>
			</div>
		</div>
	);
};

export default QuizQuestion;