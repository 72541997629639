import React, { Fragment, useState, useRef, useEffect } from 'react'
import MultiSelectList from '../form_elements/MultiSelectList'
import { IconButton } from '../form_elements/Button'
import Input from '../form_elements/Input'
import {
    Wrapper,
    InputWrapper,
    MessageWrapper,
    InputWithSideLabelWrapper,
    CurrentValue,
    CurrentValueWrapper,
} from './styles'
import { Delete, SvgWrapper } from '../../Svgs'
import {
    formatNumber,
    isNumber,
    isOnlyNumbers,
    isValidBidMultiplier,
} from '../../../Util'

export default (props) => {
    const {
        serviceCategories,
        ruleTypes,
        disabledRuleTypeIds = [],
        rule,
        defaults = [],
        inputValue,
        onSetInputVal = () => null,
        onSetServiceCategory = () => null,
        onSetRuleType = () => null,
        onDeleteRule = () => null,
        isDeleted: _isDeleted = false,
    } = props

    // get defaults from existing rule if there is one
    const { industry_id, rule_type_id, rule_name, industry_name } = rule || {}
    const ruleTypeForRule = ruleTypes?.filter(
        (r) => r.name == rule_name || r.rule_type_id == rule_type_id
    )?.[0]

    const [ruleType, setRuleType] = useState(ruleTypeForRule)
    const [serviceCatgory, setServiceCategory] = useState(industry_id)
    const [newInputVal, setNewInputVal] = useState(inputValue || rule?.rule_value || '')
    const [inputError, setInputError] = useState()
    const [isDeleted, setIsDeleted] = useState(_isDeleted)
    const inputValidateFunction = useRef(isValidBidMultiplier)

    // effect to update the input validation function based on the rule type
    useEffect(() => {
        // if the rule is a flat amount value or a budget value,
        // validate that the input is a number
        if (ruleType?.name?.includes('flat') || ruleType?.name?.includes('multiplier')) {
            inputValidateFunction.current = isNumber
        }
        // for budget / payable duration rules, the input should only allow numbers
        if (ruleType?.name?.includes('budget') || ruleType?.name?.includes('payable')) {
            inputValidateFunction.current = isOnlyNumbers
        }
    }, [ruleType])

    const handleSetServiceCategory = (ids) => {
        setServiceCategory(ids[0])
        onSetServiceCategory(ids[0])
    }

    const handleSelectRuleType = (ids) => {
        setRuleType(ruleTypes.filter((r) => r.rule_type_id === ids[0])[0])
        onSetRuleType(ids[0])
        // also clear the input value
        setNewInputVal('')
    }

    const handleInputValueChange = (e) => {
        // if the rule type has an input type of currency, validate without
        // allowing a leading decimal
        if (ruleType?.input_type == 'currency' || ruleType?.input_type == 'integer') {
            if (e.target.value == '' || inputValidateFunction.current(e.target.value)) {
                setNewInputVal(`${e.target.value}`)
                setInputError(null)
                onSetInputVal(`${e.target.value}`)
            }
        } else if (
            // allow a leading decimal
            e.target.value == '.' ||
            e.target.value == '' ||
            inputValidateFunction.current(e.target.value)
        ) {
            setNewInputVal(`${e.target.value}`)
            setInputError(null)
            onSetInputVal(`${e.target.value}`)
        } else {
            // setInputError('Invalid Input')
        }
    }

    const validateInput = () => {
        if (inputValidateFunction.current(newInputVal)) {
            setInputError(null)
        } else {
            setInputError('Invalid Input')
        }
    }

    const handleDeleteRule = () => {
        setIsDeleted(!isDeleted)
        onDeleteRule(!isDeleted)
    }

    const ruleTypeOptions = ruleTypes?.map((r) => {
        const res = {
            value: r.rule_type_id,
            text: r.display,
            group: r.rule_group_display,
        }

        if (disabledRuleTypeIds.includes(r.rule_type_id)) {
            res.disabled = true
        }
        return res
    })

    const isNewRule = rule == null || rule?._id != null
    const showAdditionalInput = ruleType?.has_input == '1'

    // disable the rule type selection until a service category is selected
    const isRuleTypeSelectionDisabled = serviceCatgory == null

    // if this is a new rule, the service category is a dropdown. Otherwise, it is
    // a readonly input that shows the service category name
    const serviceCategoryInput = isNewRule ? (
        <MultiSelectList
            label="Service Category"
            name="service-category-options"
            emptyLabel="Select Option"
            useTextFilter={true}
            options={serviceCategories?.values}
            selections={industry_id ? [industry_id] : null}
            onChange={handleSetServiceCategory}
            disabled={isDeleted}
        />
    ) : (
        <Input
            name="service_category_read_only"
            type="text"
            label="Service Category"
            value={industry_name}
            readOnly={true}
            disabled={isDeleted}
        />
    )

    // if this is a new rule, the service category is a dropdown. Otherwise, it is
    // a readonly input that shows the service category name
    const ruleTypeInput = isNewRule ? (
        <MultiSelectList
            label="Rule Type"
            name="rule-type-options"
            emptyLabel={
                isRuleTypeSelectionDisabled
                    ? 'Select Service Category First'
                    : 'Select Option'
            }
            options={ruleTypeOptions}
            selections={ruleType ? [ruleType.rule_type_id] : null}
            hasGroups={true}
            onChange={handleSelectRuleType}
            disabled={isRuleTypeSelectionDisabled}
        />
    ) : (
        <Input
            type="text"
            name="rule_type_read_only"
            label="Rule Type"
            value={ruleType?.display}
            readOnly={true}
            disabled={isDeleted}
            hasError={isDeleted}
        />
    )

    // disable the delete button if the industry and rule type have not been selected
    // const isDeleteButtonDisabled =
    //     isNewRule && (serviceCatgory == null || ruleType == null)
    const isDeleteButtonDisabled = false

    const deleteProps = isDeleted ? { fill: 'red' } : null
    const StyledDelete = <SvgWrapper {...deleteProps}>{Delete}</SvgWrapper>

    // get the default value for the input
    let ruleDefaultValue = '--'
    if (defaults?.length > 0 && ruleType?.rule_name) {
        ruleDefaultValue = defaults.filter((d) => d.property == ruleType.rule_name)[0]
            ?.value
    }

    const getDefaultLabel = () => {
        // remove the word Override from the "current" input label
        const regex = new RegExp(`\\bOverride\\b`, 'gi')
        return `Default ${ruleType?.display?.replace(regex, '').trim()}`
    }

    // function to generate the 'current / default' value
    const getDefaultValue = () => {
        let r
        if (ruleDefaultValue == '--') {
            r = ruleDefaultValue
        }
        if (ruleType?.input_type == 'currency') {
            r = formatNumber(ruleDefaultValue, 0)
        } else if (ruleType?.input_type == 'decimal') {
            r = formatNumber(ruleDefaultValue, 2)
        } else {
            r = ruleDefaultValue
        }
        // add the input unit if its there
        if (ruleType?.input_unit != null) {
            r = `${r} ${ruleType.input_unit}`
        }
        return r
    }

    // hide the 'default value' input when the rule is a flat bid type
    let showCurrentValueInput = true
    if (ruleType?.rule_name?.includes('flat')) {
        showCurrentValueInput = false
    }

    return (
        <Fragment>
            <Wrapper>
                <InputWrapper>{serviceCategoryInput}</InputWrapper>
                <InputWrapper>{ruleTypeInput}</InputWrapper>
                <InputWrapper style={{ alignSelf: 'end' }}>
                    <IconButton
                        onClick={handleDeleteRule}
                        icon={StyledDelete}
                        height="25px"
                        width="25px"
                        variant="span"
                        disabled={isDeleteButtonDisabled}
                    />
                </InputWrapper>

                {showAdditionalInput && (
                    <Fragment>
                        <InputWrapper>
                            {showCurrentValueInput && (
                                <CurrentValueWrapper>
                                    <span className="type-small-body type-narrow-line-height type-heavy spacing-5-bottom">
                                        {getDefaultLabel()}
                                    </span>
                                    <CurrentValue>{`${getDefaultValue()}`}</CurrentValue>
                                </CurrentValueWrapper>
                            )}
                        </InputWrapper>
                        <InputWithSideLabelWrapper>
                            <Input
                                type="text"
                                name="input_new_val"
                                // valueType={ruleType?.input_type || null}
                                label={ruleType?.input_display}
                                value={newInputVal}
                                onChange={handleInputValueChange}
                                hasError={inputError != null}
                                errorMessage={inputError}
                                onBlur={validateInput}
                                inputWidth={ruleType?.input_unit ? 150 : 93}
                                unit={ruleType?.input_unit}
                            />
                            {/* {ruleType?.input_unit && <span style={{padding: '10px'}}>{ruleType?.input_unit}</span>} */}
                        </InputWithSideLabelWrapper>
                    </Fragment>
                )}
            </Wrapper>
            {isDeleted && (
                <MessageWrapper>
                    <InputWrapper />
                    <InputWrapper>
                        <span className="text-alert">
                            This rule will be deleted upon confirmation
                        </span>
                    </InputWrapper>
                    <InputWrapper />
                </MessageWrapper>
            )}
        </Fragment>
    )
}
