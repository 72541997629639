import React, { useEffect, useRef } from 'react';

import QuizQuestion from './QuizQuestion';

const QuizSection = (props) => {
	const setQuizErrors = useRef(props.setQuizErrors);
	const setQuizAnsweredState = useRef(props.setQuizAnsweredState);
	const questionsQuantity = useRef(props.questions.length);
	
	useEffect(() => {
		// When section first loads, fill errors list with indexes of all questions
		// because they are all unanswered.
		setQuizErrors.current(Array.from({ length: questionsQuantity.current }, (val, idx) => idx));
		
		// Fill answer state object with false (unanswered) value for all questions.
		setQuizAnsweredState.current(Array.from({ length: questionsQuantity.current }, (val) => false));
	}, []);
	
	return (
		<div className="onboarding-section onboarding-quiz-section">
			<h3 className="type-large-subhead type-heavy type-single-line spacing-40-bottom">
				Key Concepts Quiz
			</h3>
				
			{props.questions.map((questionData, idx) => (
				<QuizQuestion
					{...props}
					questionIdx={idx}
					questionData={questionData}
				/>
			))}
		</div>
	);
};

export default QuizSection;