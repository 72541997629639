import React from 'react'

const TableTooFewResults = () => (
  <div
      className={"padding-20 type-narrow-line-height"
      + " no-margin-top spacing-18-bottom type-centered"}
  >
      <div className="type-heavy type-normal-subhead  type-no-break">
          Not finding what you're looking for?
      </div>
      <div className="type-large-body">
          Try broadening your filters or keyword search.
      </div>
      <div className="type-large-body">
          Keep in mind results must match all selected filters.
      </div>
  </div>
);

export default TableTooFewResults 