import React, {useEffect, useRef, useState} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import type {Component} from 'react';

import {errorHandler} from '../Requests';
import Modal from '../common/Modal';
import SignupFooter from '../signup-marketplace/signup-footer';
import AuthService from '../service/AuthService';
import ContractorService from '../service/ContractorService';
import TokenService from '../service/TokenService';
import OnboardingScheduled from './OnboardingScheduled';
import ScheduleOnboarding from './ScheduleOnboarding';

const AwaitingOnboardingView = (props) => {
    const [modalContent, setModalContent] = useState();
    const [modalHeader, setModalHeader] = useState();
    const [modalHideCloseButton, setModalHideCloseButton] = useState();
    const [isMQL, setIsMQL] = useState(false);

    const {current: contractorService} = useRef(new ContractorService());
    const {current: tokenService} = useRef(new TokenService());

    useEffect(() => {
            contractorService.isMQL(AuthService.contractorId)
                .then(nextIsMQL => setIsMQL(nextIsMQL));

            return () => contractorService.cancelSignal.cancel();
        },
        [contractorService]);

    const updateModalContent = (content: Component, header: string, hideCloseButton: boolean = false) => {
        setModalContent(content);
        setModalHeader(header);
        setModalHideCloseButton(hideCloseButton);
    };

    const logout = () => {
        if (AuthService.token) {
            tokenService.logout()
                .catch((error) => {
                    if (errorHandler(error)) {
                        console.log(
                            'Caught an exception when posting to "auth/logout" in' +
                            'AwaitingOnboardingView> component, error =', error
                        );
                    }
                });
        }
        else {
            AuthService.clear();
            window.location = "/";
        }
    };

    return (
        <>
            <Modal
                content={modalContent}
                header={modalHeader}
                updateModalContent={updateModalContent}
                hideCloseButton={modalHideCloseButton}
            />
            <header>
                <div className="header-container">
                    <div
                        className="page-width header-contents padding-20"
                    >
                        <div className="simpleflex__row">
                            <div className="header__logo">
                                <img src={props.whiteLabelPartnership.logo_uri}
                                     alt={props.whiteLabelPartnership.white_label_name}/>
                            </div>
                            <div className="header__button-wrap">
                                <button onClick={logout}
                                        className="button button__outline ui-normal-button"
                                >
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="grey-background full-page-height">
                <div className="onboarding-page page-width spacing-120-bottom">
                    <p
                        className="type-normal-subhead-special type-centered spacing-60"
                    >
                        Schedule Your Onboarding
                    </p>
                    <Switch>
                        <Route path="/onboarding/schedule-onboarding">
                            <ScheduleOnboarding
                                {...props}
                                isMQL={isMQL}
                            />
                        </Route>
                        <Route path="/onboarding/scheduled">
                            <OnboardingScheduled {...props} />
                        </Route>
                        {AuthService.requestedOnboarding
                            ? (<Redirect to="/onboarding/scheduled"/>)
                            : (<Redirect to="/onboarding/schedule-onboarding"/>)}
                    </Switch>
                </div>
            </section>
            <SignupFooter {...props}
                          updateModalContent={updateModalContent}
            />
        </>
    );
};

export default AwaitingOnboardingView;
