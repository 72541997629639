/**
 * This page renamed from "Create Client" to "Sign Up New Client" (in the UX not the code). ~ RFM Dec 2020
 */
import React, {Component} from 'react';
import axios from "axios";
import {withRouter} from "react-router-dom";
import set from 'lodash/set';

import {appInput, getPayload, handleCheckboxClick, handleInputBlur, syncInputValueToState} from '../../../formHelpers';
import {errorHandler, get, post} from '../../../Requests';
import ServiceCategoryAndGeos from '../ServiceCategoryAndGeos';
import CostPerLead from '../CostPerLead';
import Modal from '../../../common/Modal';
import SuccessMessageContent from '../SuccessMessageContent';
import PaymentMethodSection from "../PaymentMethodSection";
import PrimaryContactForm from "../../../common/components/form_sections/PrimaryContactForm";
import CompanyDetailsForm from "../../../common/components/form_sections/CompanyDetailsForm";
import Validator from "../../../lib/Validator";
import {MoneyInput} from "../../../common/components/form_elements/MoneyInput";
import {NextArrow, OpenNewWindow, PlusSignSvg} from '../../../common/Svgs';
import {ContractorTypeIds, defaultParentContractorId} from "../../../common/StaticValues";
import ChooseDealModal from "./ChooseDealModal";
import {MockButton} from "../../../common/MockButton";
import ResourcesService from "../../../service/ResourcesService";
import {clone, formatDate, isInteger} from "../../../Util";
import LoadingGif from "../../../common/components/LoadingGif";
import ContractorService from "../../../service/ContractorService";
import type {Contractor} from "../../../Interfaces/Contractor";
import ChooseUserAndDealModal from "./ChooseUserAndDealModal";
import ChooseClientType from "./ChooseClientType";
import CreateMarketplaceClientForm from "./CreateMarketplaceClientForm";
import {ConfirmModal, ConfirmModalFooter} from "../../../common/ConfirmModal";
import {getDiscountPercentage} from "../../../common/components/form_sections/PricingSummary";
import MultiSelectList from '../../../common/components/form_elements/MultiSelectList';

class CreateClient extends Component {
    BASE_SERVICE_AREA = {
        zip_codes: {
            zips: [],
            cities: []
        },
        main_zip_code: '',
        radius: 10
    };

    hubSpotEnv = process.env.REACT_APP_HUBSPOT_ID;

    constructor(props) {
        super(props);
        document.title = 'Sign Up New Client';

        this.state = {
            initiating: true,
            clientType: '', // one of: '', 'select' or 'marketplace'
            dealOptions: [], // list of HubSpot Deals matching the Contact with the entered email
            clientDeals: [],
            clientPrimaryContact: '',
            clientCompanyName: '',

            // inputs - The main storage for the values and error messages of each Form Element.
            // Does not include all the Form Elements. For example, the Service Category sections are in a separate
            // service_category array
            inputs: this.initialInputValues(),

            // enroll variables
            creditCards: [],
            marketplaceCampaignData: {},
            enroll: false,
            contractorId: this.props.match.params.contractor_id,
            userId: null,
            selectedCreditCardIndex: -1,

            parentContractorArray: [],
            serviceCategoriesArray: [],
            modal: {
                content: null,
                header: null,
                footer: null,
                onClose: null,
                closeCallback: null,
                width: ''
            },

            showCreditCardForm: false,
            submitting: false
        };

        // These are not in this.state because they never change and therefore never need to trigger a re-render.
        this.syncInputValueToState = syncInputValueToState;

        // Save a clone of the state so we can clear the form fields after a successful Submit
        let stateClone = {...this.state};
        let inputsClone = this.initialInputValues();

        let serviceArea = appInput('', '', [clone(this.BASE_SERVICE_AREA)]);
        inputsClone.service_category = [{
            industry_id: appInput(),
            service_area: serviceArea,
            cpl: appInput(),
            other_services: appInput()
        }];
        stateClone.inputs = inputsClone;
        stateClone.initiating = false;

        this.initialStateClone = stateClone;

        // Keep track if we are in the middle of submitting
        this.submitting = false;

        this.references = {
            service_category: React.createRef(),
            formTopRef: React.createRef(),
        };

        this.resourcesService = new ResourcesService();
        this.contractorService = new ContractorService();
        this.modalScrollerRef = React.createRef();
        this.cancelSignal = axios.CancelToken.source();

        this.validator = new Validator();
    }

    componentDidMount() {
        this.getAdminFormData()
            .then(this.getContractorDataForSelectEnrollment);
    }

    componentWillUnmount() {
        this.resourcesService.cancelSignal.cancel();
        this.contractorService.cancelSignal.cancel();
        this.cancelSignal.cancel();
    }

    componentDidUpdate(prevProps, d, sa) {
        if (prevProps.match.params.contractor_id != this.props.match.params.contractor_id) {
            this.resetForm();
            this.getContractorDataForSelectEnrollment();
        }
    }

    initialInputValues = () => {
        return {
            // TODO rename these 2
            // rename lookup_select_client_by_email
            lookup_client_by_email: appInput('lookup_client_by_email', 'lookup_client_by_email'),

            hubspot_deal_id: appInput("hubspot_deal_id", "hubspot_deal_id"),
            hubspot_contact_vid: appInput("hubspot_contact_vid"),
            hubspot_company_id: appInput("hubspot_company_id"),
            parent_contractor_id: appInput("parent_contractor_id", "parent_contractor_id",
                defaultParentContractorId, false),
            company_name: appInput("company_name", "company_name"),
            street_address: appInput("street_address", "street_address"),
            unit: appInput("unit"),
            city: appInput("city", "city"),
            state: appInput("state", "state"),
            country: appInput("country", "country", "USA"),
            postal_code: appInput("postal_code", "postal_code"),
            website: appInput("website"),

            // Split First/Last name in the form because that's how Hubspot Contact record is. Still name in our DB.
            // https://app.asana.com/0/1194402135837182/1199612619447631/f ~ RFM Dec 2020
            // primary_contact_name: appInput("primary_contact_name", "primary_contact_name"),
            primary_contact_first_name: appInput("primary_contact_first_name", "primary_contact_first_name"),
            primary_contact_last_name: appInput("primary_contact_last_name", "primary_contact_last_name"),

            primary_contact_job_title: appInput("primary_contact_job_title", "primary_contact_job_title"),
            primary_contact_email: appInput("primary_contact_email", "primary_contact_email"),
            primary_contact_phone: appInput("primary_contact_phone", "primary_contact_phone"),
            primary_contact_time_zone: appInput(
                "primary_contact_time_zone",
                "primary_contact_time_zone",
                process.env.REACT_APP_DEFAULT_TIME_ZONE_ID
            ),

            // Deliberately picking wrong initial values for these 3 values
            // so it's clear that they are set by updateParentContractor()
            retail_startup_fee: appInput("retail_startup_fee", "retail_startup_fee"),
            discount_amount: appInput("discount_amount"),
            monthly_subscription: appInput("monthly_subscription"),
            client_can_edit_cpl: appInput("client_can_edit_cpl", "client_can_edit_cpl"),

            cc_name: appInput("cc_name"),
            cc_number: appInput("cc_number"),
            cc_expiration: appInput("cc_expiration"),
            cc_cvv: appInput("cc_cvv"),
            cc_zip: appInput("cc_zip"),

            service_category: [{
                industry_id: appInput(),
                service_area: appInput('', '', [clone(this.BASE_SERVICE_AREA)]),
                cpl: appInput(),
                other_services: appInput()
            }],
        };
    };

    /**
     * Reset the form to its initial state.
     * Happens after a successful submission.
     */
    resetForm = () => {
        this.setState(
            this.initialStateClone,
            () => {
                this.fillOutInitialPricingDetails();
            }
        );
    };

    /**
     * @param event
     */
    checkboxCallback = (event) => {
        let stateClone = {...this.state};
        handleCheckboxClick(stateClone, event);
        this.setState(stateClone);
    };

    getContractorDataForSelectEnrollment = () => {
        const contractorId = this.props.match.params.contractor_id;
        if (!isInteger(contractorId)) {
            this.setState({
                clientType: '',
                enroll: false,
                contractorId: null,
                initiating: false,
                showCreditCardForm: false
            });
            return;
        }

        this.setState({
            clientType: 'select',
            enroll: true,
            contractorId: contractorId
        });

        this.contractorService.getContractorData(contractorId)
            .then((response) => {
                this.setState({
                    initiating: false,
                    showCreditCardForm: true
                });

                // only enroll marketplace contractors
                if (response.contractor.contractorTypeId != ContractorTypeIds.Marketplace) {
                    this.props.updateMessageBlocks('Can only Enroll Marketplace Contractors', 'error');
                    return;
                }

                //
                // what happens when the contractor already has a signup record?
                // currently we just prevent this
                //
                if (response.signupRecord) {
                    this.updateModalContent(
                        <>
                            <p className="type-normal-body">
                                The Contractor (ID: {response.contractor.contractorId}) already has a{' '}
                                {response.signupRecord.isRetired == 1 &&
                                <span className="type-heavy">completed</span>}
                                {' '}SignUp record.
                            </p>
                            <p className="type-normal-body">
                                The record was created by {response.signupRecord.name} on{' '}
                                {formatDate(response.signupRecord.creationTimestamp)}
                            </p>
                            <p className="type-normal-body type-centered spacing-20-top">
                                Enrolling this contractor again will update the contractor data and save the old
                                SignUp record in our history records
                            </p>
                        </>,
                        {
                            header: 'Contractor already has a SignUp record',
                            closeCallback: () => this.updateFormDataForSelectEnrollment(response)
                        }
                    );
                }
                else {
                    this.updateFormDataForSelectEnrollment(response);
                }
            });
    };

    updateFormDataForSelectEnrollment = (response) => {
        const contractor: Contractor = response.contractor;
        document.title = 'Select Program Enrollment';

        this.updateParentContractor(null, contractor.parentContractorId);

        // company details
        const address = response.address;
        this.updateInput('company_name', contractor.name);
        this.updateInput('street_address', address.address);
        this.updateInput('unit', address.unit);
        this.updateInput('city', address.city);
        this.updateInput('state', address.state);
        this.updateInput('postal_code', address.zip);
        this.updateInput('website', contractor.websiteUrl);
        this.updateInput('country', address.countryCode || "USA");

        for (let industryId in response.marketplaceCampaigns) {
            if (!response.marketplaceCampaigns.hasOwnProperty(industryId)) {
                return;
            }
            const serviceCategory = this.getServiceCategoryObject(industryId);
            serviceCategory.group = 'Current MP Categories';
        }

        this.setState({
            creditCards: response.creditCards,
            selectedCreditCardIndex: response.creditCards.length > 0
                ? 0
                : 1,
            marketplaceCampaignData: response.marketplaceCampaigns,
        });

        // user details
        this.updateModalContent(
            <ChooseUserAndDealModal users={response.users} deals={response.deals}
                                    cancelUserSelection={this.cancelEnrollment}
                                    chooseUserInModal={(user, deal) => this.updateUserData(user, deal, response.possiblePhoneNumber)}/>,
            {
                header: "Choose User & Deal",
                onClose: this.cancelEnrollment
            }
        );
    };

    /**
     * Get the data for the dropdowns that we need to
     *   build them out dynamically
     *   Parent Contractor Data
     *   Service Categories Data
     */
    getAdminFormData = () =>
        get("signup/admin_form_data", this.cancelSignal.token)
            .then((resp) => {
                if (resp.status !== 200 || typeof resp.data !== "object") {
                    return;
                }

                let parentContractorArray = resp.data.data.parent_contractors;
                let serviceCategoriesArray = resp.data.data.service_categories;

                // Add empty field so the first option is empty in the dropdown
                serviceCategoriesArray.unshift({industry_id: "", name: "Select One"});

                this.setState({
                    parentContractorArray: parentContractorArray,
                    serviceCategoriesArray: serviceCategoriesArray,
                });

                // we want to also set it on the initial state clone
                this.initialStateClone.parentContractorArray = parentContractorArray;
                this.initialStateClone.serviceCategoriesArray = serviceCategoriesArray;

                this.fillOutInitialPricingDetails(this.state.inputs.parent_contractor_id.value);
            });

    /**
     * updates the fields data based on a given user
     * @param {Object} user
     * @param {Object} deal
     * @param {string} [possiblePhoneNumber='']
     */
    updateUserData = (user, deal, possiblePhoneNumber = '') => {
        this.updateModalContent();

        this.setState({userId: user.userId});

        // some old users won't have a name, so we check to not crash the app
        if (user.name) {
            const namePair = user.name.split(' ');
            this.updateInput('primary_contact_first_name', namePair.shift());
            this.updateInput('primary_contact_last_name', namePair.join(' '));
        }
        this.updateInput('primary_contact_job_title', user.jobTitle);
        this.updateInput('primary_contact_email', user.username);
        this.updateInput('primary_contact_phone', user.phoneNumber || possiblePhoneNumber);
        this.updateInput('primary_contact_time_zone', user.timeZoneId);

        // update the deal id if an existing deal was selected
        if (deal) {
            this.updateInput('hubspot_deal_id', deal.dealId);
        }
    };

    /**
     * Make the values get set from partnership table even for the first
     * Parent Contractor selected by default (Service Direct, 2882)
     * @param {number} parentContractorId
     */
    fillOutInitialPricingDetails(parentContractorId) {
        this.updateParentContractor(null, parentContractorId || defaultParentContractorId);
    }

    /**
     * The callback to update the country from the dropdown
     */
    updateCountryCallback = (vals) => {
        let value = vals[0];

        let inputClone = {...this.state.inputs};

        // clear the selected if country changes
        if (value !== inputClone.country.value) {
            let stClone = {...inputClone.state};
            stClone.value = "";
            inputClone.state = stClone;
        }

        let field = {...inputClone.country};
        field.value = value;
        inputClone.country = field;

        this.setState({inputs: inputClone});
    };


    /**
     * The callback to update the parent contractor from the dropdown.
     * You may optionally pass a parentContractorId directly in, otherwise
     * it is inferred from the event.
     */
    updateParentContractor = (vals: Event, parentContractorId: number) => {
        parentContractorId = parentContractorId || vals[0];

        let inputClone = {...this.state.inputs};

        inputClone.parent_contractor_id.value = parentContractorId;

        let parentContractorObj = this.findParentContractorObj(parentContractorId);

        // Reset pricing detail fields for parent contractors, based on the partnership table fields
        if (parentContractorObj) {
            inputClone.retail_startup_fee = appInput("retail_startup_fee", "retail_startup_fee", parentContractorObj.default_startup_fee);
            inputClone.discount_amount = appInput("discount_amount", "discount_amount", 0);
            inputClone.monthly_subscription = appInput("monthly_subscription", "monthly_subscription", parentContractorObj.default_monthly_fee);
        }

        this.setState({inputs: inputClone});
    };

    /**
     * Find the element in state.parentContractorArray that corresponds to
     * this parent_contractor_id, so we can lookup partnership details.
     * If found, parentContractorObj will be the record.
     */
    findParentContractorObj(parentContractorId: number) {
        if (!parentContractorId) return null;
        let parentContractorArray = this.state.parentContractorArray;
        let parentContractorObj = null;

        for (let n = 0; n < parentContractorArray.length; n++) {
            let tempParentContractor = parentContractorArray[n];
            if (tempParentContractor.contractor_id == parentContractorId) {
                parentContractorObj = tempParentContractor;
                break;
            }
        }
        return parentContractorObj;
    };

    /**
     * callback to update the state from dropdown
     * "o praise our lord, sanctor of javascript: ol Saint Callback"
     *   - wmb
     */
    updateStCallback = (vals) => {
        // let elem = event.target;
        let value = vals[0];
        let stateClone = {...this.state};
        let inputClone = {...stateClone.inputs};
        let field = {...inputClone.state};

        field.value = value;

        inputClone.state = field;
        stateClone.inputs = inputClone;

        this.setState(stateClone, () => {
            // let elem = document.getElementById('dropdown-field-state');
            // let error = this.validator.inputValidation(elem);
            let stateClone2 = {...this.state};
            let inputClone2 = {...stateClone2.inputs};
            let field2 = {...inputClone2.state};

            // field2.error = error;
            inputClone2.state = field2;
            stateClone.inputs = inputClone2;

            this.setState(stateClone);
        });
    };

    /**
     * Gets the field object { value: , error: }
     * Question: as with jumpToElement(), maybe this can be moved somewhere else
     * so that other components don't need to have a copy of this function?
     * - mz / bo 6 Dec 2018
     */
    getInput = (name) => {
        name = name.trim();
        return {...this.state.inputs[name]};
    };

    /**
     * @param name
     * @param value
     */
    updateInput = (name, value) => {
        let inputs = {...this.state.inputs};
        inputs[name].value = value === null
            ? ''
            : value;
        this.setState({inputs});
    };

    /**
     * Once Submit is clicked, this makes the POST
     * This only gets called if all front-end validation was passed
     */
    submitPost = () => {
        if (this.state.submitting === true) {
            return;
        }

        function clearCardData(data) {
            delete data.cc_cvv;
            delete data.cc_expiration;
            delete data.cc_name;
            delete data.cc_number;
            delete data.cc_zip;
        }

        this.setState(
            {submitting: true},
            () => {
                let inputs = {...this.state.inputs};
                let dataToSend = getPayload(inputs);

                if (this.state.enroll) {
                    dataToSend.contractor_id = this.state.contractorId;
                    dataToSend.user_id = this.state.userId;
                }

                // preselected card instead of credit card data
                if (this.state.showCreditCardForm && this.state.selectedCreditCardIndex !== -1) {
                    const selectedCreditCard = this.state.creditCards[this.state.selectedCreditCardIndex];
                    if (selectedCreditCard) {
                        dataToSend.credit_card_id = selectedCreditCard.creditCardId;
                        clearCardData(dataToSend);
                    }
                }
                else if (!this.state.showCreditCardForm) {
                    clearCardData(dataToSend);
                }

                const serviceCategories = [...this.state.inputs.service_category];

                // strip any empty service categories from the array
                dataToSend.service_category = serviceCategories
                    .filter(serviceCategory => serviceCategory.industry_id.value !== "")
                    .map(category => ({
                        'industry_id': category.industry_id.value,
                        'service_area': category.service_area.value,
                        'cpl': category.cpl.value
                    }));

                const postUrl = this.state.enroll
                    ? 'signup/enroll'
                    : 'signup';
                post(postUrl, dataToSend, this.cancelSignal.token)
                    .then((res) => {
                        this.setState(
                            {submitting: false},
                            () => {
                                if (res && res.status === 200 && typeof res.data === "object") {
                                    let retailStartupFee = Math.abs(this.getInput('retail_startup_fee').value);
                                    let discountAmount = Math.abs(this.getInput('discount_amount').value);

                                    let amountDue = retailStartupFee - discountAmount;

                                    //
                                    // Display the Success Dialog
                                    // NOTE - This function also resets the form fields afterwards.
                                    //
                                    this.updateModalContent(
                                        <SuccessMessageContent
                                            name={res.data.data.contractor.name}
                                            email={res.data.data.email}
                                            isPaid={res.data.data.is_paid}
                                            amountDue={amountDue}
                                            enroll={this.state.enroll}
                                            isMarketplaceOnly={false}
                                        />,
                                        {
                                            header: "Success!",
                                            footer: <ConfirmModalFooter handleConfirm={this.closeModal}
                                                                        confirmText="Continue"/>,
                                            closeCallback: () => this.props.history.push('/signup-and-launch-status')
                                        }
                                    );
                                }
                                else if (res && res.status === 400 && typeof res.data === "object") {
                                    let stateClone = {...this.state};
                                    let errors = res.data.errors;

                                    if (errors) {
                                        let ref = this.validator.setApiErrorsOnStateClone(errors, stateClone, this.references);
                                        this.setState(stateClone);
                                        this.validator.jumpToError(ref);
                                    }
                                }
                            });
                    })
                    .catch((error) => {
                        if (errorHandler(error)) {
                            this.setState({submitting: false});
                        }
                    });
            }
        );
    };

    /**
     * Handle the submit button click
     **/
    submit = () => {
        if (this.submitting) {
            return;
        }
        this.setState({submitting: true});

        let stateClone = {...this.state};
        this.validator.setStateClone(stateClone);

        if (!this.validator.validateAllInputs()) {
            stateClone = this.validator.getStateClone(stateClone);
            this.setState({submitting: false, inputs: stateClone.inputs});

            this.validator.displayUserErrors();
            return;
        }

        this.submitPost();
    };

    /**
     * @param {number} parentContractorId
     * @return string
     *
     * TODO FIXME - now that we have findParentContractorObj, we no longer need this
     * function. "name" is one of the fields we have access to once getting the
     * parentContractorObj.
     **/
    getParentContractorName = (parentContractorId) => {
        let parentContractorArray = this.state.parentContractorArray;
        if (parentContractorId === undefined) {
            parentContractorId = this.state.inputs.parent_contractor_id.value;
        }

        for (let index in parentContractorArray) {
            let parentContractor = parentContractorArray[index];

            // casting these to int via math.abs because they come back as a string from the backend
            // and we may not have a string in the javascript
            // math.abs is decently fast anyways so not a huge performance loss
            if (parentContractor.contractor_id == parentContractorId) {
                return parentContractor.name;
            }
        }

        return "";
    };

    handleSelectingCard = (vals) => {
        this.setState({selectedCreditCardIndex: Number(vals[0])});
    };

    /**
     * Callback for the PaymentMethodSection Component to update
     **/
    syncCreditCardValues = (event, value) => {
        let stateClone = {...this.state};
        this.syncInputValueToState(stateClone, event, value);
        this.setState(stateClone);
    };

    /**
     * Callback for the CreditCardFrom Validation on blur
     **/
    validateCreditCardInput = (event) => {
        let stateClone = {...this.state};
        handleInputBlur(stateClone, event);
        this.setState(stateClone);
    };

    /**
     * This is similar, possibly redundant, to corresponding code for service area onBlur in YourCompany.js.
     * --yijing
     */
    onBlurServiceCategoryAndGeo = (value, field, arrayIndex) => {
        let stateClone = {...this.state};
        let serviceCategoryInputClone = [...stateClone.inputs.service_category];

        serviceCategoryInputClone[arrayIndex][field].error = "";
        stateClone.inputs.service_category = serviceCategoryInputClone;

        this.setState(stateClone);
    };

    /**
     * Update the industry and geo from the ServiceCategoryAndGeo to the state
     */
    updateServiceCategoryAndGeo = (value, field, arrayIndex, serviceAreaIndex) => {
        const stateClone = {...this.state};
        const serviceCategoryInputClone = [...stateClone.inputs.service_category];

        if (serviceAreaIndex !== undefined) {
            if (value === false) {
                serviceCategoryInputClone[arrayIndex][field].value.splice(serviceAreaIndex, 1);
            }
            else {
                serviceCategoryInputClone[arrayIndex][field].value[serviceAreaIndex] = value;
            }
        }
        else {
            // case of adding an item to an array
            if (typeof serviceCategoryInputClone[arrayIndex][field].value.push === 'function') {
                serviceCategoryInputClone[arrayIndex][field].value.push(value);
                serviceAreaIndex = 0;
            }
            else {
                serviceCategoryInputClone[arrayIndex][field].value = value;
            }
        }

        stateClone.inputs.service_category = serviceCategoryInputClone;
        this.setState(stateClone);

        // when changing industry in a service area
        if (field === 'industry_id' && value in this.state.marketplaceCampaignData) {
            const industry = this.getServiceCategoryObject(value);
            this.updateModalContent(
                <ConfirmModal
                    content={<>
                        <p className="type-normal-body">
                            The contractor has a Marketplace Service Area in the {industry.name} Service Category.
                            <br/>
                            Would you like to pre-populate the Service Area for this Service Category?
                        </p>
                        <p className="type-normal-body spacing-20">{this.state.marketplaceCampaignData[value]}</p>
                    </>}/>,
                {
                    header: 'Use Existing Service Area',
                    footer: <ConfirmModalFooter
                        handleCancel={this.closeModal}
                        handleConfirm={() => {
                            serviceCategoryInputClone[arrayIndex].service_area.value[0].zip_codes.zips =
                                this.state.marketplaceCampaignData[value]
                                    .split(',')
                                    .map((zip: string) => zip.trim());
                            stateClone.inputs.service_category = serviceCategoryInputClone;
                            this.setState(stateClone);

                            this.resourcesService.getMapByZips(
                                serviceCategoryInputClone[arrayIndex].service_area.value[0].zip_codes.zips,
                                this.state.inputs.country.value === 'CAN')
                                .then((mapUrl) => {
                                    let stateClone = {...this.state};
                                    stateClone.inputs.service_category[arrayIndex].service_area.value[0].map = mapUrl;
                                    this.setState(stateClone);
                                });
                        }}
                    />,
                    jumpToError: false
                }
            );
        }

        // when changing a service area, update the map
        if (field == 'service_area' && value && value.zip_codes.zips.length > 0) {
            this.resourcesService.getMapByZips(value.zip_codes.zips, this.state.inputs.country.value === 'CAN')
                .then((mapUrl) => {
                    let stateClone = {...this.state};
                    stateClone.inputs.service_category[arrayIndex].service_area.value[serviceAreaIndex].map = mapUrl;
                    this.setState(stateClone);
                });
            this.closeModal();
        }
    };

    /**
     * callback for onlick to add more service category blocks
     */
    addAnotherServiceCategory = () => {
        let stateClone = {...this.state};
        let serviceCategoryInputClone = [...stateClone.inputs.service_category];
        let nextIndex = serviceCategoryInputClone.length;
        serviceCategoryInputClone.push({
            industry_id: appInput(`industry_id_${nextIndex}`),
            service_area: appInput('', '', [clone(this.BASE_SERVICE_AREA)]),
            cpl: appInput(`admin-cpl-${nextIndex}`),
            other_services: appInput()
        });
        stateClone.inputs.service_category = serviceCategoryInputClone;
        this.setState(stateClone);
    };

    /**
     * Remove the selected category from the options
     */
    removeServiceCategory = (arrayIndex) => {
        let stateClone = {...this.state};
        let serviceCategoryInputClone = [...stateClone.inputs.service_category];
        serviceCategoryInputClone.splice([arrayIndex], 1);
        stateClone.inputs.service_category = serviceCategoryInputClone;
        this.setState(stateClone);
    };

    /**
     * Get a specific serviceCategory Object based on industryId
     * @param {number} industryId
     * @return {Object}
     */
    getServiceCategoryObject = (industryId) =>
        this.state.serviceCategoriesArray.find((industry) => industry.industry_id == industryId) || {};

    /**
     * Wrapper for syncInputValuesToState to keep the state in sync with input
     * @param event
     */
    updateValuesToState = (event) => {
        let stateClone = {...this.state};
        syncInputValueToState(stateClone, event);
        this.setState(stateClone);
    };

    updateInputProperty = (name, value) => {
        const stateClone = set({...this.state}, ['inputs', name], {value})
        this.setState(stateClone)
    }

    handleInputBlur = (event) => {
        let stateClone = {...this.state};
        handleInputBlur(stateClone, event);
        this.setState(stateClone);
    };

    /**
     * Passed into <Modal>, which calls it in 2 cases:
     * 1. When you click the Close button, updates it to (undefined, undefined)
     * 2. When you click off the modal (BodyClick) - also (undefined, undefined)
     *
     * Also gets called by this class, to populate the Success content
     *
     * NOTE - This is weird, but it also resets the form fields afterwards.
     * @param {Object|string} content - the modal's content
     * @param {Object} [options] - modal options
     * @param {Object|string} [options.header] - when provided, will display as the modal header
     * @param {Object|string} [options.footer] - when provided, will display as the modal footer
     * @param {boolean} [options.wide] - if set to true, will load a wide modal
     * @param {function} [options.onClose] - when provided, will be executed when the close button is clicked
     * @param {function} [options.closeCallback] - when provided, will be executed when the modal is closed
     * @param {boolean} [options.jumpToError = true] - if set to false, will not scroll the page to the input with the error
     */
    updateModalContent = (content, options = {}) => {
        const isClosing = !content,
            modal = {
                content,
                header: options.header,
                footer: options.footer,
                width: options.wide
                    ? 'wide'
                    : '',
                onClose: options.onClose
            };

        // allow setting a callback for when the modal closes - but can only set it during the previous Modal Open
        // otherwise, when you close, your close callback will just get overwritten with undefined.
        if (!isClosing) {
            modal.closeCallback = options.closeCallback;
        }
        this.setState({modal},
            () => {
                if (isClosing) {
                    if (options.jumpToError) {
                        this.validator.jumpToError(this.references.formTopRef, 'start');
                    }
                    if (this.state.modal.closeCallback) {
                        this.state.modal.closeCallback();
                        const {modal} = this.state;
                        modal.closeCallback = null;
                        this.setState({modal});
                    }
                }
            });
    };

    /**
     * cancels the enrollment process and takes the user back to whence they came from
     */
    cancelEnrollment = () => {
        this.props.history.goBack();
    };

    closeModal = () => {
        this.updateModalContent(null, {jumpToError: false});
    };

    /**
     * Auto-fill Select Client's data, fetched from HubSpot Contact & Company & Deal
     */
    lookupSelectClientByEmail = () => {
        let email = this.state.inputs.lookup_client_by_email.value;

        if (!email) {
            this.props.updateMessageBlocks("Enter an email to lookup client.", "error");
            return;
        }

        get("signup/lookup_client_by_email?email=" + encodeURIComponent(email), this.cancelSignal.token)
            .then(resp => {
                let r = resp.data.data,
                    inputsClone = {...this.state.inputs},
                    dealOptions = [{'dealname': 'Select One', 'deal_id': ''}].concat(r.deals);

                this.setState({clientDeals: r.deals});

                // We might not have both company and contact: only auto-fill respective fields for each entity if provided.
                // - mz 20 May 2019
                if (r.company) {
                    this.setState({clientCompanyName: r.company.company_name});
                    for (let key in r.company) {
                        if (!r.company.hasOwnProperty(key) || r.company[key] === null) {
                            continue;
                        }
                        inputsClone[key].value = r.company[key];
                    }
                }
                if (r.primary_contact) {
                    this.setState({
                        clientPrimaryContact:
                            r.primary_contact.primary_contact_first_name + ' ' +
                            r.primary_contact.primary_contact_last_name
                    });
                    for (let key in r.primary_contact) {
                        if (!r.primary_contact.hasOwnProperty(key) || r.primary_contact[key] === null) {
                            continue;
                        }
                        inputsClone[key].value = r.primary_contact[key];
                    }
                }
                // TODO this service_category logic eemingly not in use, could be removed. However, may be useful later
                //  if we auto-fill existing Sign Up data and allow Updating via this form ~ RFM Dec 2020
                // (See https://app.asana.com/0/0/1199554536496174/f)
                if (r.service_category) {
                    inputsClone.service_category = r.service_category.map((elt) => {
                        let ret = {
                            industry_id: appInput(),
                            service_area: appInput(),
                            cpl: appInput(),
                            other_services: appInput()
                        };
                        ret.industry_id.value = elt.industry_id;
                        ret.cpl.value = elt.cpl;
                        return ret;
                    });
                }
                if (r.retail_startup_fee) {
                    inputsClone.retail_startup_fee.value = r.retail_startup_fee;
                }
                // update hubspot_deal_id even if it's falsy, i.e. blank it out if there is none
                inputsClone.hubspot_deal_id.value = r.hubspot_deal_id;
                this.setState({
                    inputs: inputsClone,
                    dealOptions: dealOptions,
                });

                // add success message
                let successMessages = [resp.data.message];
                if (r.num_contacts > 1) {
                    let numContactsMsg = "Note: There were " + r.num_contacts;
                    numContactsMsg += " Contacts for this Deal Record. We used the";
                    numContactsMsg += " first listed contact to autofill this form.";
                    numContactsMsg += " Please update the fields manually if you need to.";
                    successMessages.push(numContactsMsg);
                }
                if (resp.data.message) {
                    this.props.updateMessageBlocks(successMessages, "success");
                }

                // More than one deal? Need to choose via the modal. If there's 1 deal the choice is made automatically
                // because the hubspot_deal_id is set above.
                if (r.deals.length > 1) {
                    this.updateModalContent(
                        <ChooseDealModal dealOptions={dealOptions} chooseDealInModal={this.chooseDealInModal}/>,
                        {header: "Choose HubSpot Deal"}
                    );
                }
            })
            .catch(error => {
                if (error.response.data.message) {
                    this.props.updateMessageBlocks(error.response.data.message, "error");
                    return;
                }
                throw error;
            });
    };

    chooseDealInModal = (dealId) => {
        this.updateModalContent();
        // set the dealId in the state
        this.setState({
            inputs: {
                ...this.state.inputs,
                hubspot_deal_id: {
                    ...this.state.inputs.hubspot_deal_id,
                    value: dealId
                }
            }
        });
    };

    hubSpotDealLink = (dealId) =>
        `https://app.hubspot.com/contacts/${this.hubSpotEnv}/deals/${dealId}`;

    hubSpotContactLink = (contactId) =>
        `https://app.hubspot.com/contacts/${this.hubSpotEnv}/contact/${contactId}`;

    hubSpotCompanyLink = (companyId) =>
        `https://app.hubspot.com/contacts/${this.hubSpotEnv}/company/${companyId}`;

    mockData = (event: MouseEvent) => {
        console.log("mockData");
        event.preventDefault();
        let uniqId: string = Math.floor(Math.random() * 1000000).toString();
        let serviceArea = [clone(this.BASE_SERVICE_AREA)];
        serviceArea[0].zip_codes.zips = ['78701', '78704', '78705'];
        serviceArea[0].zip_codes.cities = ['Austin'];
        serviceArea[0].map = 'https://maps.googleapis.com/maps/api/staticmap?key=' + process.env.REACT_APP_GOOGLE_API_KEY +
            '&size=640x640&scale=2&path=fillcolor:0x00AA0033|color:0x00000066|weight:1|30.25207,-97.736292|' +
            '30.276458,-97.730968|30.287097,-97.734037|30.292513,-97.747515|30.299484,-97.74711|30.307089,-97.747506|' +
            '30.283227,-97.727965|30.276708,-97.750153|30.272565,-97.751339|30.268609,-97.751708|30.264096,-97.769344|' +
            '30.258698,-97.784275|30.247671,-97.795857|30.243146,-97.793487|30.227257,-97.775974|30.242684,-97.73594|' +
            '30.25207,-97.736292';
        this.setState({
            inputs: {
                lookup_client_by_email: appInput("lookup_client_by_email", "lookup_client_by_email"),
                hubspot_deal_id: appInput("hubspot_deal_id", "hubspot_deal_id"),
                // hubspot_contact_vid: appInput("hubspot_contact_vid"),
                // hubspot_company_id: appInput("hubspot_company_id"),
                parent_contractor_id: appInput("parent_contractor_id", "parent_contractor_id",
                    defaultParentContractorId, false),
                company_name: appInput("company_name", "company_name",
                    "Example Co " + uniqId),
                street_address: appInput("street_address", "street_address", "10 Test Ln"),
                unit: appInput("unit"),
                city: appInput("city", "city", "Austin"),
                state: appInput("state", "state", "TX"),
                country: appInput("country", "country", "USA"),
                postal_code: appInput("postal_code", "postal_code", "78704"),
                website: appInput("website", "website", "examplecompany" + uniqId + ".com"),
                // primary_contact_name: appInput("primary_contact_name", "primary_contact_name",
                //     "Joe Shmo"),
                primary_contact_first_name: appInput("primary_contact_first_name",
                    "primary_contact_first_name", "Joe"),
                primary_contact_last_name: appInput("primary_contact_last_name",
                    "primary_contact_last_name", "Shmo"),
                primary_contact_job_title: appInput("primary_contact_job_title", "primary_contact_job_title",
                    "Head Nurse"),
                primary_contact_email: appInput("primary_contact_email", "primary_contact_email",
                    "joeschmo@examplecompany" + uniqId + ".com"),
                primary_contact_phone: appInput("primary_contact_phone", "primary_contact_phone",
                    "512-462-3623"),
                primary_contact_time_zone: appInput(
                    "primary_contact_time_zone",
                    "primary_contact_time_zone",
                    process.env.REACT_APP_DEFAULT_TIME_ZONE_ID
                ),
                retail_startup_fee: appInput("retail_startup_fee", "retail_startup_fee",
                    400),
                discount_amount: appInput("discount_amount"),
                monthly_subscription: appInput("monthly_subscription", "monthly_subscription",
                    100),
                client_can_edit_cpl: appInput("client_can_edit_cpl", "client_can_edit_cpl"),

                cc_name: appInput("cc_name", "cc_name", "JOE Z SHMO"),
                cc_number: appInput("cc_number", "cc_number", "4111111111111111"),
                cc_expiration: appInput("cc_expiration", "cc_expiration", "01/23"),
                cc_cvv: appInput("cc_cvv", "cc_cvv", "345"),
                cc_zip: appInput("cc_zip", "cc_zip", "78704"),

                service_category: [{
                    industry_id: appInput(null, null, 2),
                    service_area: appInput(null, null, serviceArea),
                    cpl: appInput(null, null, 60),
                    other_services: appInput(null, null, 'note!')
                }],
            },
        });
    };

    renderCreateSelectClientForm = () => {
        let lookupClientByEmail = this.getInput('lookup_client_by_email');
        let hubspotDealId = this.getInput('hubspot_deal_id');
        let hubspotContactVid = this.getInput('hubspot_contact_vid');
        let hubspotCompanyId = this.getInput('hubspot_company_id');
        let parentContractorId = this.getInput('parent_contractor_id');
        let companyName = this.getInput('company_name');
        let streetAddress = this.getInput('street_address');
        let unit = this.getInput('unit');
        let city = this.getInput('city');
        let state = this.getInput('state');
        let country = this.getInput('country');
        let postalCode = this.getInput('postal_code');
        let website = this.getInput('website');
        // let primaryContactName = this.getInput('primary_contact_name');
        let primaryContactFirstName = this.getInput('primary_contact_first_name');
        let primaryContactLastName = this.getInput('primary_contact_last_name');
        let primaryContactJobTitle = this.getInput('primary_contact_job_title');
        let primaryContactEmail = this.getInput('primary_contact_email');
        let primaryContactPhone = this.getInput('primary_contact_phone');
        let primaryContactTimeZone = this.getInput('primary_contact_time_zone');
        let retailStartupFee = this.getInput('retail_startup_fee');
        let discountAmount = this.getInput('discount_amount');
        let monthlySubscription = this.getInput('monthly_subscription');
        let clientCanEditCpl = this.getInput('client_can_edit_cpl');
        let ccName = this.getInput('cc_name');
        let ccNumber = this.getInput('cc_number');
        let ccExpiration = this.getInput('cc_expiration');
        let ccCvv = this.getInput('cc_cvv');
        let ccZip = this.getInput('cc_zip');

        let parentContractorName = this.getParentContractorName(parentContractorId.value);
        let parentContractorObj = this.findParentContractorObj(parentContractorId.value);

        const deal = hubspotDealId.value
            ? this.state.clientDeals.find((deal) => deal.deal_id == hubspotDealId.value)
            : {};

        // Do we even need centralized billing for any clients anymore? TODO remove if we can ~ RFM 2020-12-09
        /**
         * For partners with centralized billing, like NEXSTAR, we never need to fill in Primary Payment
         * Method / Credit Card form. Their corporate account covers all billing directly.
         *
         * Note that this same logic is currently used to hide Pricing Details fields such as Account
         * Activation fee and Monthly Subscription. That's because, currently, we only ever charge those
         * fees to the direct Client's credit card, so they don't currently apply to the sub-clients
         * of a partner with centralized billing. However, in the future, we might change back to using
         * these fields, so we can e.g. charge Nexstar a startup fee or monthly fee for each new client -
         * we just wouldn't charge the end-client's card.
         *
         * ~ 2019-03-26 RFM JT
         */
        let primaryPaymentMethodRequired = !(parentContractorObj && parseInt(parentContractorObj.centralized_billing));

        if (Math.abs(discountAmount.value) > Math.abs(retailStartupFee.value)) {
            discountAmount.value = Math.abs(retailStartupFee.value);
        }

        let amountDue = Math.abs(retailStartupFee.value) - Math.abs(discountAmount.value);
        let discountPercentage = getDiscountPercentage(discountAmount.value, retailStartupFee.value);

        // Create the dynamic Cost Per Lead fields
        const costPerLeads = this.state.inputs.service_category.map((serviceCategory, key) => {
            if (serviceCategory.industry_id.value === "") {
                return null;
            }

            let serviceObject = this.getServiceCategoryObject(serviceCategory.industry_id.value);

            if (!serviceObject) {
                return null
            }

            return <CostPerLead
                service={serviceObject}
                index={key}
                updateServiceCategoryAndGeo={this.updateServiceCategoryAndGeo}
                onBlurServiceCategoryAndGeo={this.onBlurServiceCategoryAndGeo}
                key={key}
                inputRef={serviceCategory.cpl.ref}
                error={serviceCategory.cpl.error}
                isRequired={!clientCanEditCpl.value}
                value={serviceCategory.cpl.value}
            />;
        });

        return <section className="white-background">
            <MockButton onClick={this.mockData}/>
            <div className="page-width">
                <div className="row padding-50-top padding-20-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">

                        <div className="module">
                            <div className="module__header module__twothirdswidth">
                                <h3 className="type-large-subhead type-narrow-line-height">
                                    {this.state.enroll
                                        ? `Select Program Enrollment for ${companyName.value}`
                                        : 'Sign Up New Select Client'}
                                </h3>
                            </div>
                            <div className="module__contentbox module__twothirdswidth">
                                {!this.state.enroll && <>
                                    <div className="form__row">
                                        <div className="form__cell form__cell__100 spacing-10-bottom">
                                            <div>
                                                <span role="button" className="type-blue type-extra-small-body"
                                                      onClick={() => this.setState({clientType: ''}, this.resetForm)}>
                                                    Go Back to Choose Client Type
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="spacing-20-bottom">
                                        <p className="type-large-body type-heavy type-allcaps type-single-line no-margin-top">
                                            Look Up Client in HubSpot
                                        </p>
                                    </div>
                                    <div className="form__row">
                                        <div
                                            className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                                            <label
                                                htmlFor="lookup_client_by_email"
                                                className={"type-small-body type-heavy type-narrow-line-height spacing-10-bottom" +
                                                (lookupClientByEmail.error ? " type-alert" : "")}
                                                ref={lookupClientByEmail.ref}
                                            >
                                                Email Address&nbsp;
                                                <span className="text-alert">
                                                {lookupClientByEmail.error}
                                            </span>
                                            </label>
                                            <input
                                                id="lookup_client_by_email"
                                                type="email"
                                                className={"ui-text-field ui-normal-text-input" + (lookupClientByEmail.error ? " ui-alert" : "")}
                                                name="lookup_client_by_email"
                                                value={lookupClientByEmail.value}
                                                onChange={this.updateValuesToState}
                                                onBlur={this.handleInputBlur}
                                            />
                                        </div>
                                        <div
                                            className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                                            <label
                                                htmlFor="lookup_by_email_button"
                                                className="type-normal-body type-heavy type-narrow-line-height spacing-10-bottom"
                                                style={{visibility: 'hidden'}}
                                            >
                                                (Placeholder)
                                            </label>
                                            <button className="button ui-normal-button ui-full-width-button-mobile"
                                                    id="lookup_by_email_button"
                                                    onClick={this.lookupSelectClientByEmail}
                                            >
                                                Check SD System and Auto-fill Form From HS
                                            </button>
                                        </div>
                                    </div>
                                    {(hubspotDealId.value || hubspotContactVid.value || hubspotCompanyId.value) &&
                                    <div className="form__row">
                                        <div
                                            className="form__cell form__cell__33 form__cell__50__mobile spacing-20-top spacing-24-bottom-mobile">
                                            <label htmlFor="hubspot_deal_id"
                                                   className="type-small-body type-heavy type-narrow-line-height spacing-10-bottom">
                                                HubSpot Deal
                                            </label>
                                            {hubspotDealId.value
                                                ? <div className="ui-static-text-field ui-normal-text-input">
                                                    <a href={this.hubSpotDealLink(hubspotDealId.value)} target="_blank">
                                                        {deal.dealname || hubspotDealId.value}{' '}
                                                        <span
                                                            className="inline-icon inline-icon__middle inline-icon__20">{OpenNewWindow}</span>
                                                    </a>
                                                </div>
                                                : (this.state.dealOptions.length >= 2
                                                    ? <MultiSelectList
                                                        hasError={!!parentContractorId.error}
                                                        inputRef={parentContractorId.ref}
                                                        label=""
                                                        selections={[parentContractorId.value]}
                                                        name="parent_contractor_id"
                                                        onChange={this.updateParentContractor}
                                                        options={this.state.dealOptions}
                                                        getOptionValue={option => option.deal_id}
                                                        getOptionText={option => option.dealname}
                                                        // optionMap={{
                                                        //     "value_key": "deal_id",
                                                        //     "text_key": "dealname"
                                                        // }}
                                                        required={true}
                                                    />
                                                    : 'None')}
                                        </div>
                                        <div
                                            className="form__cell form__cell__33 form__cell__50__mobile spacing-20-top spacing-24-bottom-mobile">
                                            <label
                                                className="type-small-body type-heavy type-narrow-line-height spacing-10-bottom"
                                                htmlFor="hubspot_contact_vid">
                                                HubSpot Contact
                                            </label>
                                            <div className="ui-static-text-field ui-normal-text-input">
                                                {hubspotContactVid.value
                                                    ? <a href={this.hubSpotContactLink(hubspotContactVid.value)}
                                                         target="_blank">
                                                        {this.state.clientPrimaryContact || hubspotContactVid.value}{' '}
                                                        <span
                                                            className="inline-icon inline-icon__middle inline-icon__20">{OpenNewWindow}</span>
                                                    </a>
                                                    : 'None'}
                                            </div>
                                        </div>
                                        <div
                                            className="form__cell form__cell__33 form__cell__50__mobile spacing-20-top spacing-24-bottom-mobile">
                                            <label
                                                htmlFor="hubspot_company_id"
                                                className="type-small-body type-heavy type-narrow-line-height spacing-10-bottom"
                                            >
                                                HubSpot Company
                                            </label>
                                            <div className="ui-static-text-field ui-normal-text-input">
                                                {hubspotCompanyId.value
                                                    ? <a href={this.hubSpotCompanyLink(hubspotCompanyId.value)}
                                                         target="_blank">
                                                        {this.state.clientCompanyName || hubspotCompanyId.value}{' '}
                                                        <span
                                                            className="inline-icon inline-icon__middle inline-icon__20">{OpenNewWindow}</span>
                                                    </a>
                                                    : 'None'}
                                            </div>
                                        </div>
                                    </div>}
                                </>}

                                <div className={'spacing-20-bottom' + (this.state.enroll ? '' : ' spacing-40-top')}>
                                    <p className="type-large-body type-heavy type-allcaps type-single-line no-margin-top">
                                        Select Parent Contractor
                                    </p>
                                </div>
                                <div className="form__row">
                                    <div
                                        className="form__cell form__cell__33 form__cell__100__mobile">
                                        <MultiSelectList
                                            useTextFilter={true}
                                            hasError={!!parentContractorId.error}
                                            inputRef={parentContractorId.ref}
                                            label=""
                                            selections={[parentContractorId.value || ""]}
                                            name="parent_contractor_id"
                                            onChange={this.updateParentContractor}
                                            options={this.state.parentContractorArray}
                                            getOptionValue={option => option.contractor_id}
                                            getOptionText={option => option.name}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
                <PrimaryContactForm
                    separateFirstLastName={true}
                    primaryContactFirstName={primaryContactFirstName}
                    primaryContactLastName={primaryContactLastName}
                    primaryContactJobTitle={primaryContactJobTitle}
                    primaryContactEmail={primaryContactEmail}
                    primaryContactPhone={primaryContactPhone}
                    primaryContactTimeZone={primaryContactTimeZone}
                    isAdmin={true}
                    onChange={this.updateValuesToState}
                    onBlur={event => {
                        let stateClone = {...this.state};
                        this.validator.setStateClone(stateClone);
                        stateClone = this.validator.updateStateCloneWithError(stateClone, event);
                        this.setState(stateClone);
                    }}
                    updateInputProperty={this.updateInputProperty}
                />
                <CompanyDetailsForm
                    companyName={companyName}
                    country={country}
                    streetAddress={streetAddress}
                    unit={unit}
                    postalCode={postalCode}
                    city={city}
                    state={state}
                    website={website}
                    onChange={this.updateValuesToState}
                    onBlur={(event) => {
                        let stateClone = {...this.state};
                        this.validator.setStateClone(stateClone);
                        stateClone = this.validator.updateStateCloneWithError(stateClone, event);
                        this.setState(stateClone);
                    }}
                    updateCountryCallback={this.updateCountryCallback}
                    updateStCallback={this.updateStCallback}
                />

                <div className="row padding-40-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">
                        <div className="module">
                            <div className="module__header module__twothirdswidth">
                                <h3 className="type-large-subhead type-narrow-line-height"
                                    ref={this.references.service_category}>
                                    Target Service Categories &amp; Service Areas
                                </h3>
                            </div>
                            <div className="module__contentbox module__twothirdswidth">
                                <ServiceCategoryAndGeos
                                    serviceCategories={this.state.inputs.service_category}
                                    options={this.state.serviceCategoriesArray}
                                    setGroups={this.state.enroll}
                                    updateServiceCategoryAndGeo={this.updateServiceCategoryAndGeo}
                                    removeServiceCategory={this.removeServiceCategory}
                                    updateModalContent={this.updateModalContent}
                                    closeModal={this.closeModal}
                                    updateMessageBlocks={this.props.updateMessageBlocks}
                                    country={this.state.inputs.country.value}
                                />
                                <p className="type-normal-body">
                                    <span role="button" className="type-heavy type-blue"
                                          onClick={this.addAnotherServiceCategory}>
                                        <span className="inline-icon inline-icon__middle inline-icon__20">
                                            {PlusSignSvg}
                                        </span>
                                        Add Another Service Category
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
                <div className="row padding-40-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">
                        <div className="module">
                            <div className="module__header module__twothirdswidth">
                                <h3 className="type-large-subhead type-narrow-line-height">
                                    Pricing Details
                                </h3>
                            </div>
                            {!primaryPaymentMethodRequired
                                ? <div className="module__contentbox module__twothirdswidth">
                                    <p className="type-large-body type-heavy spacing-20-bottom">
                                        Pricing Details are not required for a {parentContractorName} Client, except
                                        for CPLs.
                                    </p>

                                    <div className="form__row spacing-20-bottom start wrap">
                                        {costPerLeads}
                                    </div>
                                </div>
                                : <div className="module__contentbox module__twothirdswidth">
                                    <div className="form__row spacing-20-bottom">
                                        <div
                                            className="form__cell form__cell__25 form__cell__100__mobile spacing-24-bottom-mobile">
                                            <label
                                                htmlFor="retail_startup_fee"
                                                className={"type-normal-body type-heavy type-narrow-line-height spacing-5-bottom" +
                                                (retailStartupFee.error ? " type-alert" : "")}
                                                ref={retailStartupFee.ref}
                                            >
                                                Account Activation Cost *
                                                <span className="text-alert">
                                                {retailStartupFee.error}
                                            </span>
                                            </label>
                                            <MoneyInput
                                                id="retail_startup_fee"
                                                name="retail_startup_fee"
                                                defaultValue={retailStartupFee.value}
                                                onChange={this.updateValuesToState}
                                                onBlur={(event) => {
                                                    let stateClone = {...this.state};
                                                    this.validator.setStateClone(stateClone);
                                                    stateClone = this.validator.updateStateCloneWithError(stateClone, event);
                                                    this.setState(stateClone);
                                                }}
                                                hasError={!!retailStartupFee.error}
                                                required
                                            />
                                        </div>
                                        <div
                                            className="form__cell form__cell__25 form__cell__100__mobile spacing-24-bottom-mobile">
                                            <label
                                                htmlFor="discount_amount"
                                                className={"type-normal-body type-heavy type-narrow-line-height spacing-5-bottom" +
                                                (discountAmount.error ? " type-alert" : "")}
                                                ref={discountAmount.ref}
                                            >
                                                Discount Amount
                                            </label>
                                            <MoneyInput
                                                id="discount_amount"
                                                name="discount_amount"
                                                defaultValue={discountAmount.value}
                                                onChange={this.updateValuesToState}
                                                onBlur={this.handleInputBlur}
                                                hasError={!!discountAmount.error}
                                            />
                                        </div>
                                        <div className="form__cell form__cell__50 form__cell__100__mobile">
                                            <label
                                                className="type-normal-body type-heavy type-narrow-line-height spacing-5-bottom"
                                            >
                                                Total Account Activation Cost
                                            </label>
                                            <div className="ui-static-text-field ui-normal-text-input">
                                                {/* TODO Calculate and make dynamic */}
                                                ${amountDue.toLocaleString()}{' '}
                                                ({isNaN(discountPercentage) ? 0 : Math.round(discountPercentage.toFixed())}%
                                                Discount)
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__row spacing-20-bottom">
                                        <div className="form__cell form__cell__25 form__cell__100__mobile">
                                            <label
                                                htmlFor="monthly_subscription"
                                                className={"type-normal-body type-heavy type-narrow-line-height spacing-5-bottom" +
                                                (monthlySubscription.error ? " type-alert" : "")}
                                                ref={monthlySubscription.ref}
                                            >
                                                Monthly Subscription *
                                                <span className="text-alert">
                                                {monthlySubscription.error}
                                            </span>
                                            </label>
                                            <MoneyInput
                                                id="monthly_subscription"
                                                name="monthly_subscription"
                                                defaultValue={monthlySubscription.value}
                                                onChange={this.updateValuesToState}
                                                onBlur={this.handleInputBlur}
                                                hasError={!!monthlySubscription.error}
                                                required
                                            />
                                        </div>
                                        <div className="form__cell form__cell__75 form__cell__hidden__mobile"/>
                                    </div>

                                    <div className="form__row spacing-20-bottom start wrap">
                                        {costPerLeads}
                                    </div>

                                    <div className="form__row">
                                        <div className="form__cell form__cell__100 form__cell__100__mobile">
                                            <div className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    name="client_can_edit_cpl"
                                                    id="client_can_edit_cpl"
                                                    checked={clientCanEditCpl.value}
                                                    onChange={this.checkboxCallback}
                                                />
                                                <label
                                                    htmlFor="client_can_edit_cpl"
                                                    className={clientCanEditCpl.error ? " type-alert" : ""}
                                                >
                                                <span className="checkbox">
                                                    Allow Client to Edit Cost Per Lead in next step
                                                </span>
                                                    <span
                                                        className={clientCanEditCpl.error ? "text-alert" : ""}>
                                                    {clientCanEditCpl.error}
                                                </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>

                <PaymentMethodSection
                    creditCards={this.state.creditCards}
                    selectedCreditCardIndex={this.state.selectedCreditCardIndex}
                    handleSelectingCard={this.handleSelectingCard}
                    enroll={true}
                    name={ccName}
                    number={ccNumber}
                    expiration={ccExpiration}
                    cvv={ccCvv}
                    zip={ccZip}
                    showCreditCardForm={this.state.showCreditCardForm}
                    showCheckbox={!this.state.enroll}
                    amountDue={amountDue}
                    syncStateToInput={this.syncCreditCardValues}
                    validateInputOnBlur={this.validateCreditCardInput}
                    checkboxCallback={() => this.setState({showCreditCardForm: !this.state.showCreditCardForm})}
                    required={false}
                    primaryPaymentMethodRequired={primaryPaymentMethodRequired}
                    parentContractorName={parentContractorName}
                />
                <div className="row padding-120-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">
                        <div className="type-align-right">
                            <button className="button ui-large-button ui-full-width-button-mobile icon-right"
                                    id="submit-button"
                                    onClick={this.submit}
                                    disabled={this.state.submitting}>
                                {this.state.submitting
                                    ? "Submitting "
                                    : (this.state.enroll
                                        ? "Enroll Client & Send Enrollment Email"
                                        : "Create Client & Send Signup Email")}
                                {this.state.submitting
                                    ? <div className="spacing-14-left">
                                        <LoadingGif size="small"/>
                                    </div>
                                    : NextArrow}
                            </button>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
            </div>
        </section>;
    };

    render() {
        let innerHtml;

        if (this.state.initiating) {
            innerHtml = <div className="loading-gif-container full-height"><LoadingGif/></div>;
        }
        else {
            switch (this.state.clientType) {
                case '':
                    innerHtml = <ChooseClientType onTypeSelect={(clientType: string) => this.setState({clientType})}/>;
                    break;

                case 'marketplace':
                    innerHtml = <CreateMarketplaceClientForm
                        onReset={() => {
                            this.setState({clientType: ''});
                            this.resetForm();
                        }}
                        updateMessageBlocks={this.props.updateMessageBlocks}
                    />;
                    break;

                case 'select':
                    innerHtml = this.renderCreateSelectClientForm();
                    break;

                default:
                    throw new Error(`Invalid clientType: ${this.state.clientType}`);
            }
        }

        return <div className="interface-container" ref={this.references.formTopRef}>
            <Modal
                content={this.state.modal.content}
                header={this.state.modal.header}
                footer={this.state.modal.footer}
                updateModalContent={this.closeModal}
                scrollerRef={this.modalScrollerRef}
                containerRef={this.references.formTopRef}
                width={this.state.modal.width}
                onCloseButton={this.state.modal.onClose}
                disableScrollLock={true}
            />
            {innerHtml}
            <footer className="padding-50-bottom white-background">
                <div className="page-width">
                    <div className="row">
                        <div className="col alpha omega grid-12">
                            <p className="type-extra-small-body type-single-line ui-hide-mobile">
                                <span className="type-right-side-bump">
                                    &copy;{(new Date()).getFullYear()} Service Direct
                                </span>
                            </p>
                            <p className="type-extra-small-body type-centered ui-hide-full ui-hide-tablet">
                                <span className="type-force-newline">
                                    &copy;{(new Date()).getFullYear()} Service Direct
                                </span>
                            </p>
                        </div>
                        <div className="clear-block"/>
                    </div>
                </div>
            </footer>
        </div>;
    }
}

export default withRouter(CreateClient);
