import React from 'react';
import PropTypes from 'prop-types';

import Input from './form_elements/Input';
import {formatDate} from '../../Util';

const CreditCardFormReadOnly = (props) => {
    const creditCard = props.creditCard;

    const rowClass = props.inPopup
        ? 'popup__form__row spacing-20-bottom'
        : 'form__row spacing-30-bottom';
    
    const cell25Class = props.inPopup
        ? 'popup__form__cell popup__form__cell__25 popup__form__cell__50__mobile'
        : 'form__cell form__cell__25 form__cell__50__mobile';
    
    const cell75Class = props.inPopup
        ? 'popup__form__cell popup__form__cell__75 popup__form__cell__50__mobile'
        : 'form__cell form__cell__75 form__cell__50__mobile';

    return <form id="credit-card-form">
        <div className={rowClass}>
            <div className={cell75Class}>
                <Input
                    label="Name on Card"
                    type="text"
                    name="cc_name"
                    value={creditCard.firstName + ' ' + creditCard.lastName}
                    readOnly
                />
            </div>
            <div className={cell25Class}>
                {creditCard.isPrimary &&
                <div><span className="type-flag type-flag--red">Primary</span></div>}
            </div>
        </div>
        <div className={rowClass}>
            <div className={cell75Class}>
                <Input
                    label="Credit Card Number"
                    type="text"
                    name="cc_number"
                    value={'**** **** **** ' + creditCard.lastFour}
                    readOnly
                />
            </div>
            <div className={cell25Class}>
                <Input
                    label="Expiration"
                    type="text"
                    name="cc_expiration"
                    value={creditCard.expirationMonth + '/' + creditCard.expirationYear}
                    readOnly
                />
            </div>
        </div>
        <div className={rowClass}>
            <div className={cell75Class}>
                <Input
                    label="Billing Zip / Postal Code"
                    type="text"
                    name="cc_zip"
                    value={creditCard.postalCode}
                    readOnly
                />
            </div>
            <div className={cell25Class}>
                <Input
                    label="Created"
                    type="text"
                    name="cc_created"
                    value={formatDate(creditCard.creationTimestamp)}
                    readOnly
                />
            </div>
        </div>
    </form>;
};

export default CreditCardFormReadOnly;

CreditCardFormReadOnly.propTypes = {
    creditCard: PropTypes.object.isRequired,
    inPopup: PropTypes.bool
};
