import React, {useState} from 'react';

import {default as TableCell} from './SignupLaunchStatusTableCell';
import {formatDate} from "../../../Util";
import CampaignCompletionDateModal from "./CampaignCompletionDateModal";
import ConfirmCampaignsDoneModal from "./ConfirmCampaignsDoneModal";

const SD_URL = process.env.REACT_APP_SD_ADMIN_URL;

const CampaignTableCells = (props) => {
    const campaign = props.campaign;
    const key = props.rowKey;
    const signupRow = props.signupRow;
    const [genCampaignsSubmitting, setGenCampaignsSubmitting] = useState(false);
    const enroll = !!signupRow.select_enroll_started_timestamp;

    const signupCompleted = !!(enroll
        ? signupRow.select_enroll_completed_timestamp
        : signupRow.date_completed_signup);
    const dateCompletedSignupTimestamp = signupRow.mp_completed_timestamp || signupRow.date_completed_signup;
    const clientCompletedForm = !!(signupRow.your_company_complete ||
        signupRow.select_enroll_company_profile_completed_timestamp);

    const openCompletionDateModal = () => {
        props.updateModalContent(
            <CampaignCompletionDateModal
                expectedCampaignCompletionDate={signupRow.expected_campaign_completion_date}
                submitExpectedCampaignCompletionDate={props.submitExpectedCampaignCompletionDate}
                removeExpectedCampaignCompletionDate={props.removeExpectedCampaignCompletionDate}
            />,
            signupRow.client_name,
            "normal",
            true
        );
    };

    // case of a pending row
    if (!campaign) {
        return [
            <TableCell header="campaign-name" key={`${key}campaign-name`}/>,
            <TableCell header="assigned" key={`${key}assigned`}/>,
            <TableCell header="completion"
                       key={`${key}completion`}>
                {!signupRow.expected_campaign_completion_date && signupRow.is_retired == 0
                    ? <button className="button ui-normal-button"
                              onClick={openCompletionDateModal}>
                        Set Date
                    </button>
                    : <button className="button-clean type-blue type-heavy type-normal-body"
                              onClick={openCompletionDateModal}>
                        {formatDate(signupRow.expected_campaign_completion_date)}
                    </button>}
            </TableCell>,
            <TableCell header="campaigns-done"
                       key={`${key}campaigns-done`}>
                {process.env.REACT_APP_INCLUDE_TEST_CLIENT_LINKS && clientCompletedForm &&
                <button
                    className="button ui-normal-button type-no-break"
                    onClick={() => {
                        setGenCampaignsSubmitting(true);
                        props.updateMessageBlocks(
                            "Campaigns are being generated. Please wait about 10 seconds.",
                            'info'
                        );
                        props.generateTestCampaigns(signupRow.contractor_id)
                            .finally(() => {
                                setGenCampaignsSubmitting(false);
                            });
                    }}
                    disabled={genCampaignsSubmitting}
                >
                    Gen Campaigns<br/>
                    (Dev Only)
                </button>}
            </TableCell>,
            <TableCell header="date-completed-signup" key={key + "date-completed-signup"}/>,
            <TableCell header="select-enroll-completed" key={key + "select-enroll-completed"}/>,
            <TableCell header="ad-queue" key={key + "ad-queue"}/>,
            <TableCell header="posted" key={key + "posted"}/>,
            <TableCell header="impressions" key={key + "impressions"}/>
        ];
    }

    return ([
        <TableCell header="campaign-name"
                   key={key + "campaign-name"}>
            <a href={SD_URL + "campaigns?campaign_id=" + campaign.campaign_id}
               target="_blank">
                {campaign.campaign_name}
            </a>
        </TableCell>,
        <TableCell header="assigned"
                   key={key + "assigned"}>
            {formatDate(campaign.start_timestamp)}
        </TableCell>,

        // Expected Completion Date
        props.firstOfTypes && !props.existingCampaign
            ? <TableCell header="completion"
                         key={key + "completion"}
                         rowSpan={props.numCampaigns}>
                {!signupRow.expected_campaign_completion_date && signupRow.is_retired == 0
                    ? <button className="button ui-normal-button"
                              onClick={openCompletionDateModal}>
                        Set Date
                    </button>
                    : <button className="button-clean type-blue type-heavy type-normal-body"
                              onClick={openCompletionDateModal}>
                        {formatDate(signupRow.expected_campaign_completion_date)}
                    </button>}
            </TableCell>
            : (props.firstOfTypes && props.existingCampaign &&
            <TableCell header="completion"
                       key={key + "completion"}
                       rowSpan={props.numCampaigns}/>),

        //
        // Campaigns Done button / date
        // only show on the first row and not on mp campaigns for enrollment
        //
        props.firstOfTypes && !props.existingCampaign
            ? <TableCell header="campaigns-done"
                         key={`${key}-campaigns-done`}
                         rowSpan={props.numCampaigns}>
                {signupCompleted
                    ? formatDate(dateCompletedSignupTimestamp)
                    : <button
                        className="button ui-normal-button type-no-break"
                        onClick={() => {
                            props.updateModalContent(
                                <ConfirmCampaignsDoneModal
                                    updateModalContent={props.updateModalContent}
                                    sendCampaignsDoneRequest={props.sendCampaignsDoneRequest}
                                    updateMessageBlocks={props.updateMessageBlocks}
                                    enroll={enroll}
                                />,
                                "Notify Client Campaigns are Done?",
                                "normal",
                                true
                            );
                        }}
                    >
                        Campaigns Done<br/>
                        Send Notification
                        {signupRow.select_enroll_completed_timestamp &&
                        <><br/>{formatDate(signupRow.select_enroll_completed_timestamp)}</>}
                    </button>}
            </TableCell>
            : (props.firstOfTypes && props.existingCampaign &&
            <TableCell header="campaigns-done"
                       key={`${key}-campaigns-done`}
                       rowSpan={props.numCampaigns}/>),

        props.firstOfTypes &&
            <TableCell header="date-completed-signup"
                         rowSpan={props.numCampaigns}
                         key={`${key}date-completed-signup`}>
                {formatDate(dateCompletedSignupTimestamp) || "Pending"}
            </TableCell>,

        props.firstOfTypes && !props.existingCampaign
            ? <TableCell header="select-enroll-completed"
                         rowSpan={props.numCampaigns}
                         key={`${key}select-enroll-completed`}>
                {formatDate(signupRow.select_enroll_completed_timestamp)}
            </TableCell>
            : (props.firstOfTypes && props.existingCampaign &&
            <TableCell header="select-enroll-completed"
                       key={`${key}select-enroll-completed`}
                       rowSpan={props.numCampaigns}/>),

        <TableCell header="ad-queue"
                   key={key + "ad-queue"}>
            {formatDate(campaign.ad_creation_queue_timestamp)}
        </TableCell>,
        <TableCell header="posted"
                   key={key + "posted"}>
            {formatDate(campaign.posted_time)}
        </TableCell>,
        <TableCell header="impressions"
                   key={key + "impressions"}>
            {formatDate(campaign.first_recorded_impression)}
        </TableCell>
    ]);
};

export default CampaignTableCells;
