import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {formatDate, getClientMenuLinks} from "../../Util";
import {setUsersSubMenu} from "../../UtilJsx";
import {CheckMarkSvg, DefaultResetSvg} from "../../common/Svgs";
import UserService from "../../service/UserService";
import ContextualMenu from "../../common/ContextualMenu";
import type {Email} from "../../Interfaces/Email";
import type {Contractor} from "../../Interfaces/Contractor";
import type {User} from "../../Interfaces/User";
import type {Campaign} from "../../Interfaces/Campaign";
import ResourcesService from "../../service/ResourcesService";
import ResetEmailBounceModal from "./ResetEmailBounceModal";

const sdAdminUrl = process.env.REACT_APP_SD_ADMIN_URL;
const hubSpotEnvironmentId = process.env.REACT_APP_HUBSPOT_ID;

export default class EmailRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientMenuLinks: {},
            displayBounceInfo: false
        };

        this.userService = new UserService();
        this.resourcesService = new ResourcesService();
    }

    componentDidMount(): void {
        const email: Email = this.props.email;
        const clientMenuLinks = {};
        email.contractors.forEach((contractor: Partial<Contractor>) => {
            clientMenuLinks[contractor.contractorId] = getClientMenuLinks(contractor.contractorId,
                contractor.hubspotCompanyId);
        });
        this.setState({clientMenuLinks});
    }

    componentWillUnmount(): void {
        this.userService.cancelSignal.cancel();
        this.resourcesService.cancelSignal.cancel();
    }

    getUsersLinks = (contractorId: number): void => {
        const {clientMenuLinks} = this.state;
        this.userService.getByAdmin(contractorId)
            .then((users) => {
                setUsersSubMenu(users, clientMenuLinks[contractorId]);
                this.setState({clientMenuLinks});
            });
    };

    toggleBounceInfo = (): void =>
        this.setState({displayBounceInfo: !this.state.displayBounceInfo});

    /**
     * opens the reset bounce modal
     */
    resetEmailBounceScore = () => {
        const email: Email = this.props.email;

        this.props.updateModalContent(
            <ResetEmailBounceModal
                email={email}
                updateModalContent={this.props.updateModalContent}
                resetBounce={
                    () => this.resourcesService.emailAddressResetBounce(email.emailId)
                        .then(() => this.props.onBounceReset(email.emailId))
                        .then(this.props.updateModalContent)
                }
            />,
            {header: 'Reset Email Bounce Score'}
        );
    };

    render() {
        const email: Email = this.props.email;

        return <tr className="sortable-table__row">
            <td>
                <p className="flex__row align-center">
                    {email.emailAddress}
                    {email.bounce > 0 &&
                    <button className="button-clean mobile-filter-link inline-icon inline-icon__28 reset-email-button"
                            title="Reset"
                            onClick={this.resetEmailBounceScore}>
                        {DefaultResetSvg}
                    </button>}
                </p>
            </td>
            <td>
                {email.contractors.map((contractor: Partial<Contractor>, index) =>
                    this.state.clientMenuLinks[contractor.contractorId] &&
                    <div className="flex__row" key={index}>
                        <div className="flex__cell">
                            <ContextualMenu
                                linkLabel={contractor.name}
                                menuLinks={this.state.clientMenuLinks[contractor.contractorId]}
                                onFirstOpen={() => this.getUsersLinks(contractor.contractorId)}
                            />
                        </div>
                    </div>)}
            </td>
            <td>
                {email.users.map((user: Partial<User>, index) =>
                    <div key={index}>
                        <a href={`${sdAdminUrl}edit_user/${user.userId}`} target="_blank">{user.name || '<No User Name>'}</a>
                        <a href={`https://app.hubspot.com/contacts/${hubSpotEnvironmentId}/contact/${user.hubspotContactVid}`}
                           target="_blank" className="ui-float-right">HS Contact</a>
                    </div>)}
            </td>
            <td>
                {email.campaigns.map((campaign: Partial<Campaign>, index) =>
                    <div key={index}>
                        <a href={`${sdAdminUrl}edit_campaign/${campaign.campaignId}`}
                           target="_blank">{campaign.name}</a>
                        <a href={`${sdAdminUrl}edit_profile/${campaign.profileId}`} target="_blank"
                           className="ui-float-right">Profile</a>
                    </div>)}
            </td>
            <td>{formatDate(email.lastSent)}</td>
            <td>
                {email.bounce > 0 &&
                <>
                    Bounce Score: {email.bounce}
                    <p className="spacing-12">
                        Send Email Disabled on {formatDate(email.latestBounceTimestamp)}
                        {email.bounceExpire &&
                        <span>
                        , expires {formatDate(email.bounceExpire)}
                    </span>}
                    </p>
                </>}
                {email.subType &&
                (this.state.displayBounceInfo
                    ? <>
                        <div className="type-keep-newline spacing-12-bottom">{email.subType}</div>
                        <button className="type-force-newline button-clean type-blue"
                                onClick={this.toggleBounceInfo}>
                            Hide
                        </button>
                    </>
                    : <button onClick={this.toggleBounceInfo}
                              className="type-force-newline button-clean type-blue">More...</button>)}
            </td>
            <td className="spam-column type-centered">{email.markedSpam == 1 && CheckMarkSvg}</td>
            <td>
                <div className={email.deliveries == 0 ? 'type-grey' : ''}>Deliveries: {email.deliveries}</div>
                <div className={email.bounces == 0 ? 'type-grey' : ''}>Bounces: {email.bounces}</div>
                <div className={email.complaints == 0 ? 'type-grey' : ''}>Complaints: {email.complaints}</div>
            </td>
            <td>{formatDate(email.creationTimestamp)}</td>
        </tr>;
    }
}

EmailRow.propTypes = {
    email: PropTypes.object.isRequired,
    onBounceReset: PropTypes.func,
    updateModalContent: PropTypes.func,
};
