import React from 'react';

import LearnMoreFooter from './LearnMoreFooter';

const SelfServeComplete = () => {
	return (
		<>
		<h2
			className="type-normal-subhead-special spacing-60 type-centered"
		>
			Online Onboarding Complete
		</h2>
		
		<div className="onboarding-plain-section">
			<div className="onboarding-section__content">
				<h3 className="type-large-subhead type-heavy">
					Thank you for completing Onboarding.
				</h3>
				<p className="type-large-body">
					Our Team will review your information and build your Campaigns. We’ll email you within a few business days when your Campaigns are ready to start generating Leads.
				</p>
				
				<LearnMoreFooter />
			</div>
		</div>
		</>
	);
};

export default SelfServeComplete;