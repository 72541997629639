import React from 'react';

const PopupFormDivider = (props) => {
	let classes = "popup-form-divider";
	
	if (props.extraClass) {
		classes += ` ${props.extraClass}`;
	}
	
	return <hr className={classes} />;
};

export default PopupFormDivider;
