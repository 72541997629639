import React, { useState } from 'react';

import NeedHelpSection from './NeedHelpSection';

import { IconCaretUpSvg, IconCaretDownSvg } from '../common/Svgs';

const Sidebar = (props) => {
	const [expanded, setExpanded] = useState(false);
	
	const stepClasses = [
		`${(props.step === 1) ? "active" : "inactive"} ${(props.step > 1) ? "passed" : ""}`,
		`${(props.step === 2) ? "active" : "inactive"} ${(props.step > 2) ? "passed" : ""}`,
		`${(props.step === 3) ? "active" : "inactive"} ${(props.step > 3) ? "passed" : ""}`,
		`${(props.step === 4) ? "active" : "inactive"} ${(props.step > 4) ? "passed" : ""}`,
		`${(props.step === 5) ? "active" : "inactive"} ${(props.step > 5) ? "passed" : ""}`,
	];
	
	const listContents = (
		<ol className="type-large-body type-heavy">
			<li className={stepClasses[0]}>
				How Service Direct Works
				{(props.step === 1) && (
					<ul className="type-normal-body type-heavy">
						<li>Welcome to Service Direct</li>
						<li>What Does Service Direct Do?</li>
						<li>What’s the Catch?</li>
					</ul>
				)}
			</li>
			<li className={stepClasses[1]}>
				How Much Do Leads Cost and What Affects Lead Volume?
				{(props.step === 2) && (
					<ul className="type-normal-body type-heavy">
						<li>How Much Do Leads Cost?</li>
						<li>What Affects Lead Volume?</li>
					</ul>
				)}
			</li>
			<li className={stepClasses[2]}>
				Billable vs. Non-Billable Leads
				{(props.step === 3) && (
					<ul className="type-normal-body type-heavy">
						<li>Examples</li>
						<li>The Lead Review Process</li>
					</ul>
				)}
			</li>
			<li className={stepClasses[3]}>
				How You Are Billed
				{(props.step === 4) && (
					<ul className="type-normal-body type-heavy">
						<li>Current Billing Cycle and Billing Statements</li>
						<li>Billing Threshold and Monthly Lead Cost Notification</li>
					</ul>
				)}
			</li>
			<li className={stepClasses[4]}>
				The mySD Platform
				{(props.step === 5) && (
					<ul className="type-normal-body type-heavy">
						<li>mySD Lead Manager</li>
						<li>mySD Campaigns Manager</li>
						<li>mySD Users, Support, Billing</li>
					</ul>
				)}
			</li>
			{(props.step === 6) && (
				<li className="active">
					Your Business Info
				</li>
			)}
		</ol>
	);
	
	return (
		<div className="onboarding-sidebar">
			<div className="onboarding-sidebar__full-view">
				<div className="onboarding-sidebar__header">
					<p className="type-normal-subhead-special type-single-line">
						Contents
					</p>
				</div>
				<div className="onboarding-sidebar__contents">
					{listContents}
				</div>
			</div>
			<div className="onboarding-sidebar__mobile-view">
				<div className="onboarding-sidebar__header">
					<p className="type-normal-subhead-special type-single-line">
						Contents
					</p>
					<button
						onClick={(e) => setExpanded(!expanded)}
					>
						<div className="actions type-normal-body">
							<span className="label">
								<span className="icon">
									{expanded ? IconCaretUpSvg : IconCaretDownSvg }
								</span>
							</span>
						</div>
					</button>	
				</div>
				<div className={`onboarding-sidebar__contents ${expanded ? 'expanded' : ''}`}>
					{listContents}
				</div>
			</div>
			<NeedHelpSection {...props} />
		</div>
	);
};

export default Sidebar;