import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import queryString from "query-string";

import LoadingGif from "../../common/components/LoadingGif";
import UserRow from "./UserRow";
import Pagination from "../../common/components/Pagination/v1.0.1/Pagination";
import type {IPaginationInterface} from "../../Interfaces/IPaginationInterface";
import {formatNumber, toCamel} from "../../Util";
import UserService from "../../service/UserService";
import SearchField from "../../common/components/form_elements/SearchField";
import Checkbox from "../../common/components/form_elements/Checkbox";
import Tooltip from "../../common/Tooltip";
import Modal from "../../common/Modal";
import type {User} from "../../Interfaces/User";

/**
 * The component in charge of the users view
 */
class UsersAdminView extends Component {
    basePagination = {
        // rowsPerPage: process.env.REACT_APP_PAGINATION_NUM_PER_PAGE,
        rowsPerPage: 50, // TEMP: setting to 100 until we add pagination to the [non-admin] users view
        pageNum: 1,
        totalRows: 0,
        totalPages: 0
    };

    constructor(props) {
        super(props);
        document.title = 'Users';

        this.state = {
            loading: true,
            users: [],
            pagination: {...this.basePagination},
            orderBy: 'creation_timestamp',
            orderByReverse: true,
            search: '',
            bouncedEmails: false,

            modal: {
                content: null,
                header: null,
                width: ''
            }
        };

        this.userService = new UserService();

        this.searchFieldRef = React.createRef();
        this.scrollboxRef = React.createRef();
        this.tooltipRef = React.createRef();
    }

    componentDidMount() {
        this.setAppliedFiltersFromUrl();
    }

    componentWillUnmount() {
        this.userService.cancelSignal.cancel();
    }

    /**
     * get the users from the server based on the current filters
     */
    getUsers = () => {
        this.setState({loading: true});
        const filter = {orderBy: this.parseOrderBy()};
        if (this.state.search) {
            filter.search = this.state.search;
        }
        if (this.state.bouncedEmails) {
            filter.bouncedEmails = true;
        }

        this.userService.get(this.state.pagination, filter)
            .then((data) => this.setState({
                users: data.data.users,
                pagination: data.pagination
            }))
            .finally(() => this.setState({loading: false}));
    };

    /**
     * returns the className of a header based on the current sort criteria and the base class
     * @param {string} orderBy - sort variable of this header
     */
    getHeaderClass = (orderBy) => {
        let baseClass = 'sortable';
        if (this.state.orderBy === orderBy) {
            baseClass += this.state.orderByReverse
                ? ' sortable-down'
                : ' sortable-up';
        }

        return baseClass;
    };

    /**
     * parses the filter message based on the
     * @return {any} the JSX message
     */
    getFilterMessage = () => {
        if (this.state.loading) {
            return <span className="loading-results-message">Loading Users...</span>;
        }

        if (this.state.pagination.totalRows === 1) {
            return 'Showing 1 User';
        }

        return <>
            Showing{' '}
            <span className="type-heavy">
                {formatNumber(Math.min((this.state.pagination.pageNum - 1) * this.state.pagination.rowsPerPage + 1, this.state.pagination.totalRows), 0)}
                –
                {formatNumber(Math.min(this.state.pagination.pageNum * this.state.pagination.rowsPerPage, this.state.pagination.totalRows), 0)}
                </span>{' '}
            Users out of <span className="type-heavy">{formatNumber(this.state.pagination.totalRows, 0)}</span>
        </>;
    };

    /**
     * applies filters from the URL
     */
    setAppliedFiltersFromUrl = () => {
        const queryParams = queryString.parse(this.props.location.search, {
            arrayFormat: 'bracket',
            parseBooleans: true
        });

        const newState = {pagination: {...this.basePagination}};
        const fields = ['page_num', 'order_by', 'search', 'bounced_emails'];

        fields.forEach((filter) => {
            if (typeof queryParams[filter] === 'undefined') {
                return;
            }

            const camelFilter = toCamel(filter);
            switch (filter) {
                case 'page_num':
                    newState.pagination[camelFilter] = queryParams[filter];
                    break;

                case 'order_by':
                    if (queryParams.order_by[0] === '-') {
                        queryParams.order_by = queryParams.order_by.substr(1);
                        newState.orderByReverse = true;
                    }
                    newState.orderBy = queryParams.order_by;
                    break;

                case 'bounced_emails':
                    newState[camelFilter] = queryParams[filter];
                    break;

                default:
                    newState[camelFilter] = queryParams[filter];
            }
        });

        this.setState(newState, this.getUsers);
    };

    /**
     * sorts the users by a given field name
     * @param {string} orderBy - the field to sort by
     */
    applySort = (orderBy) => {
        this.setState(
            {
                orderBy,
                orderByReverse: this.state.orderBy === orderBy
                    ? !this.state.orderByReverse
                    : false
            },
            this.updateUrlAndUsers
        );
    };

    /**
     * Update the URL and fetch the corresponding users based on the component's state
     */
    updateUrlAndUsers = () => {
        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});

        queryParams.page_num = this.state.pagination.pageNum;
        queryParams.order_by = this.parseOrderBy();

        if (this.state.search) {
            queryParams.search = this.state.search;
        }
        else {
            delete queryParams.search;
        }

        if (this.state.bouncedEmails) {
            queryParams.bounced_emails = true;
        }
        else {
            delete queryParams.bounced_emails;
        }

        const newPath = '/admin/users?' + queryString.stringify(queryParams, {arrayFormat: 'bracket'});

        this.props.history.push(newPath);
        this.getUsers();
    };

    /**
     * Parses the state orders into the API expected value
     * @return {string}
     */
    parseOrderBy = () => {
        let orderBy = this.state.orderByReverse
            ? '-'
            : '';

        return orderBy + this.state.orderBy;
    };

    /**
     * Handles a page change
     * @param {Event} event
     * @param {number} newPageNum
     */
    handlePageChange = (event, newPageNum) => {
        const {pagination}: IPaginationInterface = this.state;
        pagination.pageNum = newPageNum;
        this.setState({pagination});
        this.updateUrlAndUsers();
    };

    /**
     * updates the search string
     * @param {KeyboardEvent} event
     */
    handleSearchChange = (event) =>
        this.setState({search: event.target.value});

    /**
     * handles update "bounced emails only" change
     */
    handleToggleBouncedEmails = () =>
        this.setState({
                bouncedEmails: !this.state.bouncedEmails,
                pagination: {...this.basePagination}
            },
            this.updateUrlAndUsers);

    /**
     * handles submitting the search via the search button or clicking enter in the input field
     * @param {MouseEvent|KeyboardEvent} event
     */
    handleSearch = (event) => {
        event.preventDefault();
        this.setState({pagination: {...this.basePagination}, bouncedEmails: false},
            this.updateUrlAndUsers);
    };

    /**
     * handles the deletion of a user by removing it from the view
     * @param {number} userId
     */
    handleDeleteUser = (userId: number) => {
        const users: User[] = this.state.users;
        const userIndex = users.findIndex((user: User) => user.userId == userId);
        users.splice(userIndex, 1);

        let callback = () => null;
        const pagination: IPaginationInterface = this.state.pagination;
        pagination.totalRows--;
        // if we deleted the last user of the page, while not being in the first page, go back one page
        if (pagination.totalRows % pagination.rowsPerPage === 0 &&
            pagination.pageNum === pagination.totalPages &&
            pagination.pageNum > 0) {
            pagination.pageNum--;
            callback = this.updateUrlAndUsers;
        }

        this.setState({users}, callback);
    };

    updateModalContent = (content, options = {}) =>
        this.setState({
            modal: {
                content,
                header: options.header,
                width: options.wide
                    ? 'wide'
                    : ''
            }
        });

    render() {
        const fieldsCount = 10;
        let userRows;
        if (this.state.loading) {
            userRows = <tr>
                <td colSpan={fieldsCount}>
                    <div className="type-centered padding-30">
                        <LoadingGif backgroundColor="white"/>
                    </div>
                </td>
            </tr>;
        }
        else if (this.state.users.length === 0) {
            let searchString = this.state.search.trim();
            userRows = <tr>
                <td colSpan={fieldsCount}>
                    {searchString
                        ? <div className="type-centered padding-30">
                            No records found for:<br/>
                            <span className="type-heavy">{this.state.search}</span>
                        </div>
                        : <div className="type-centered padding-30">No records found</div>}
                </td>
            </tr>;
        }
        else {
            userRows = this.state.users.map((user, index) =>
                <UserRow key={index} user={user} scrollboxRef={this.scrollboxRef}
                         onDeleteUser={this.handleDeleteUser}
                         updateModalContent={this.updateModalContent}
                         updateMessageBlocks={this.props.updateMessageBlocks}/>
            );
        }

        return <div className="page-width-wide">
            <Modal
                content={this.state.modal.content}
                header={this.state.modal.header}
                width={this.state.modal.width}
                updateModalContent={this.updateModalContent}
                flatBottom={true}
            />
            {this.state.loading &&
            <span className="spinny-loader shadowed fixed"/>}

            <div className="row padding-50-top padding-30-bottom">
                <div className="wide-format-col">
                    <div className="simpleflex__row">
                        <div className="simpleflex__cell">
                            <h2 className="type-normal-headline type-heavy padding-20-bottom">
                                Users
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="clear-block"/>
            </div>

            <div className="row">
                <div className="wide-format-col page__contentbox">
                    <div className="simpleflex__row simpleflex__row__wrap spacing-18-bottom">
                        <div className="simpleflex__cell">
                            <SearchField
                                placeholder="Search by Email, Name, or ID"
                                onChange={this.handleSearchChange}
                                value={this.state.search}
                                searchFieldRef={this.searchFieldRef}
                                onFormSubmit={this.handleSearch}
                                textLabel={true}
                                wide={true}
                            />
                        </div>
                        <div className="simpleflex__cell spacing-12-mobile spacing-12-tablet">
                            <div className="type-normal-body type-single-line">
                                <Checkbox name="bounced-emails"
                                          label="Users with bounced email address"
                                          checked={this.state.bouncedEmails} onChange={this.handleToggleBouncedEmails}
                                          tooltip={<Tooltip
                                              position="top"
                                              content={
                                                  <span
                                                      className="type-small-body type-black type-force-newline type-notransform type-align-left type-narrow-line-height">
                                                      Filters the Users list to only display Users with email addresses
                                                      that have a bounce of 3 - i.e. "hard bounce".
                                                  </span>
                                              }
                                              modalContainerRef={this.tooltipRef}
                                          />}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="simpleflex__row simpleflex__row__wrap__mobile type-centered-mobile spacing-18-bottom">
                        <div className="simpleflex__cell simpleflex__cell__middlealigned simpleflex__cell__maxed">
                            <div
                                className="type-normal-subhead type-narrow-line-height no-margin-top spacing-18-bottom-mobile">
                                {this.getFilterMessage()}
                            </div>
                        </div>
                    </div>

                    <div className="scroll-table__container">
                        <div className="scroll-table__container__shadowedge__left" ref={this.leftShadowEdgeRef}/>
                        <div className="scroll-table__container__shadowedge" ref={this.rightShadowEdgeRef}/>
                        <div
                            className="scroll-table__container__scrollbox scroll-table__container__scrollbox__flexible-height scroll-table__container__scrollbox-mobile"
                            ref={this.scrollboxRef}
                        >
                            <table className="scroll-table__table type-normal-body type-single-line sortable-table">
                                <thead>
                                <tr className="type-small-body type-heavy">
                                    <th className={this.getHeaderClass('email_address')}
                                        onClick={() => this.applySort('email_address')}>
                                        <div>Email Address</div>
                                    </th>
                                    <th className={this.getHeaderClass('contractor_name')}
                                        onClick={() => this.applySort('contractor_name')}>
                                        <div>Client</div>
                                    </th>
                                    <th className={this.getHeaderClass('phone_number')}
                                        onClick={() => this.applySort('phone_number')}>
                                        <div>Phone Number</div>
                                    </th>
                                    <th className={this.getHeaderClass('hubspot_contact_vid')}
                                        onClick={() => this.applySort('hubspot_contact_vid')}>
                                        <div>HS Contact ID</div>
                                    </th>
                                    <th className={this.getHeaderClass('last_login')}
                                        onClick={() => this.applySort('last_login')}>
                                        <div>Last Login</div>
                                    </th>
                                    <th className={this.getHeaderClass('last_sent')}
                                        onClick={() => this.applySort('last_sent')}>
                                        <div>Last Email Sent</div>
                                    </th>
                                    <th className={this.getHeaderClass('bounce')}
                                        onClick={() => this.applySort('bounce')}>
                                        <div>Bounce Score</div>
                                    </th>
                                    <th className={this.getHeaderClass('marked_spam')}
                                        onClick={() => this.applySort('marked_spam')}>
                                        <div>Marked as Spam</div>
                                    </th>
                                    <th>
                                        Deliveries
                                    </th>
                                    <th className={this.getHeaderClass('creation_timestamp')}
                                        onClick={() => this.applySort('creation_timestamp')}>
                                        <div>Creation Date</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {userRows}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="spacing-34-top">
                        <Pagination
                            pageNum={this.state.pagination.pageNum}
                            totalRows={this.state.pagination.totalRows}
                            rowsPerPage={this.state.pagination.rowsPerPage}
                            totalPages={this.state.pagination.totalPages}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>

                    <div id="TEMP"
                         style={{height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {/*TODO remove after Brian Jones fixes the contextual menu overflow bug*/}
                        Don't mind me, I'm just a huge temporary div
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(UsersAdminView);
