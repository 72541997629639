import React, { Component } from 'react';
import {
    handleInputBlur,
    appInput,
    updateStateCloneWithValue
} from '../../formHelpers';

import Input from "../../common/components/form_elements/Input";
import Checkbox from "../../common/components/form_elements/Checkbox";

export default class EditServiceModal extends Component {
    constructor(props) {
        super(props);

        let service = this.props.service;

        this.state = {
            industry: this.props.industry,
            service: service,
            inputs : {
                name: appInput("name", "name", service ? service.name  : ""),
                subcategory: appInput("subcategory", "subcategory", service ? service.subcategory : ""),
                is_required: appInput("is_required", "is_required", service ? Number(service.is_required) : "")
            },
            modalContent: undefined,
            modalHeader: undefined,
            modalWidth: undefined,
            modalFlatBottom: undefined,
        };
    }

    /**
     * @todo These also exist in CreateClient.js.  How can we refactor to
     * provide these input handlers in a uniform way w/out duplication?
     * @param event
     */
    updateValuesToState = (event) => {
        let stateClone = {...this.state};
        updateStateCloneWithValue(stateClone,event);
        this.setState(stateClone);
    };

    handleInputBlur = (event) => {
        let stateClone = {...this.state};
        handleInputBlur(stateClone, event);
        this.setState(stateClone);
    };


    render() {

        let desktopRemoveButtonJsx = "",
            mobileRemoveButtonJsx = "",
            serviceFromInputs = {
                industry_id: this.state.industry.industry_id,
                industry_service_id: this.state.service ? this.state.service.industry_service_id : null,
                name: this.state.inputs.name.value,
                is_required:  this.state.inputs.is_required.value,
                subcategory: this.state.inputs.subcategory.value
            };

        if (this.state.service) {
            mobileRemoveButtonJsx = (
            <div className="popup__form__cell popup__form__cell__100__mobile">
                <button type={"button"}
                    className="button button__outline  ui-normal-button ui-full-width-button"
                    onClick={ (event) => {
                        event.preventDefault();
                        this.props.removeService({...this.state.service});
                    }}
                >
                    Remove Service
                </button>
            </div>
            );

            desktopRemoveButtonJsx = (
            <div className="popup__form__cell">
                <button className="button button__outline ui-normal-button"
                    type={"button"}
                    onClick={ (event) => {
                        event.preventDefault();
                        this.props.removeService({...this.state.service});
                    }}
                >
                Remove Service
                </button>
            </div>
            );
        }

        return (
            <>
                <form>
                <div className="popup__form__row spacing-30-bottom">
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <label className="type-small-body type-heavy type-narrow-line-height no-margin-top spacing-5-bottom" >
                            Service Category
                        </label>
                        <p>{this.state.industry.name}</p>
                    </div>
                </div>
                <div className="popup__form__row spacing-30-bottom">
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <Input
                            type={"text"}
                            id="name"
                            name="name"
                            className="ui-textarea ui-normal-text-input"
                            label={"Service Name"}
                            value={this.state.inputs.name.value}
                            inputRef={this.state.inputs.name.ref}
                            hasError={!!this.state.inputs.name.error}
                            required={false}
                            onChange={ this.updateValuesToState }
                            onBlur={ this.handleInputBlur }
                        />
                    </div>
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <Input
                            label={"Subcategory"}
                            type={"text"}
                            id="subcategory"
                            name="subcategory"
                            value={this.state.inputs.subcategory.value}
                            inputRef={this.state.inputs.subcategory.ref}
                            hasError={!!this.state.inputs.subcategory.error}
                            required={false}
                            onChange={ this.updateValuesToState }
                            onBlur={ this.handleInputBlur }
                            disabled={this.state.inputs.is_required.value}
                        />
                    </div>
                </div>
                <div className="popup__form__row spacing-30-bottom">
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                        <label
                            htmlFor="is_required"
                            className="type-small-body type-heavy type-narrow-line-height no-margin-top spacing-5-bottom"
                        >
                            Required
                        </label>
                        <Checkbox
                            label={""}
                            id="is_required"
                            name="is_required"
                            value={this.state.inputs.is_required.value}
                            checked={!!this.state.inputs.is_required.value}
                            onChange={ (event) => {

                                //
                                // Before calling the usual event handler
                                // (updateValuesToState), we need to set the
                                // subcategory to "Required" if the Required
                                // checkbox is checked.  - mz 18 July 2019
                                //
                                let inputsClone = { ...this.state.inputs },
                                    subcategoryInput = inputsClone.subcategory,
                                    requiredChecked = event.target.checked;

                                if (requiredChecked) {
                                    subcategoryInput.value = "Required Services";
                                }
                                else {
                                    subcategoryInput.value = "";
                                }

                                this.setState({
                                    inputs: {subcategory: subcategoryInput}
                                });

                                this.updateValuesToState(event);
                            } }
                            onBlur={ this.handleInputBlur }
                        />
                    </div>
                </div>
                <div className="ui-hide-tablet ui-hide-full">
                    { mobileRemoveButtonJsx }
                    <div className="popup__form__row spacing-40-top-mobile">
                        <div className="popup__form__cell popup__form__cell__100__mobile">
                            <button
                                className="button ui-normal-button ui-full-width-button"
                                onClick={ (event) => {
                                    event.preventDefault();
                                    this.props.submitService(serviceFromInputs);
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                <div className="ui-hide-mobile">
                    <div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
                        <div className="popup__form__row popup__form__row__slam-right">
                            { desktopRemoveButtonJsx }
                            <div className="popup__form__cell">
                                <button
                                    className="button ui-normal-button"
                                    onClick={ (event) => {
                                        event.preventDefault();
                                        this.props.submitService(serviceFromInputs);
                                    }}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </>
        );
    }
}
