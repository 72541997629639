import React, { useState } from 'react'
import RadioGroup from '../../../../../common/components/form_elements/RadioGroup'
import { EditNumberWrapper, EditNumberCell } from './styles'
import Input from '../../../../../common/components/form_elements/Input'
import {
    formatPhoneNumber,
    formattedTimeAndDate,
    isDateWithinDaysFromToday,
    safeDate,
} from '../../../../../Util'
import { InputWrapper } from '../styles'
import { IconButton } from '../../../../../common/components/form_elements/Button'
import { Delete, PencilSvg, Restore, SvgWrapper } from '../../../../../common/Svgs'
import Tippy from '@tippyjs/react'
import { DeleteContainer } from '../CreateNewNumber/styles'

export default function EditNumber(props) {
    const {
        onUpdateNumber = () => null,
        configuration,
        onRemoveNumber,
        isDeleted,
    } = props
    const {
        did_phone_number_id,
        industry_name,
        phone_name,
        phone_number,
        is_whisper_audio,
        creation_timestamp,
        scheduled_delete_timestamp,
        is_retired,
    } = configuration
    const [whisperVal, setVal] = useState(is_whisper_audio || '')
    const [phoneName, setPhoneName] = useState(phone_name || '')
    const [isEditPhoneName, setIsEditPhoneName] = useState(false)
    const creationTimestamp = formattedTimeAndDate(creation_timestamp, {
        timeLeadingZero: true,
    })

    // decide how to display the restorable info
    let isRestorable = false
    let tooltipContent = null
    console.log(configuration)
    if (is_retired == '1' && scheduled_delete_timestamp != null) {
        // is restorable calculation
        console.log('is restorable, scheduled delete timestamp not null')
        const scheduledDeleteDate = safeDate(scheduled_delete_timestamp)
        // const scheduledDeleteDate = safeDate('2024-02-09 10:10:10')
        isRestorable = isDateWithinDaysFromToday(scheduledDeleteDate, 10)
        // tooltip content
        const scheduleDeleteDateFromatted = formattedTimeAndDate(
            scheduled_delete_timestamp,
            {
                timeLeadingZero: true,
            }
        )
        if (isRestorable) {
            tooltipContent = (
                <span>
                    This phone number has been disabled and will be fully removed on{' '}
                    <span className="type-heavy">{scheduleDeleteDateFromatted.date}</span>
                    . <br />
                    <br />
                    If this phone number needs to be recovered, please open a{' '}
                    <a
                        target="_blank"
                        href="https://form.asana.com/?hash=2c9f4aed1678909059249924b55d85f9b48d0c41f0d7665cf9196851de9c233c&id=1194275989784161"
                    >
                        Product Request
                    </a>
                </span>
            )
        } else {
            tooltipContent = (
                <span>This phone number has been disabled and cannot be recovered.</span>
            )
        }
    } else {
        tooltipContent = (
            <span>(╯°□°）╯︵ ┻━┻  Phone number error</span>
        )
    }

    const handleChange = (name, val) => {
        setVal(val)
        // add to the list of ids being edited
        onUpdateNumber(did_phone_number_id, {
            ...configuration,
            phone_name: phoneName,
            is_whisper_audio: val,
        })
    }

    const handleTextChange = (e) => {
        setPhoneName(e.target.value)
        onUpdateNumber(did_phone_number_id, {
            ...configuration,
            phone_name: e.target.value,
            is_whisper_audio: whisperVal,
        })
    }

    const editPhoneName = () => {
        setIsEditPhoneName(true)
    }

    const isRetiredOrScheduledToRetire = isDeleted || is_retired == '1'
    const deletedClassName = isRetiredOrScheduledToRetire ? 'type-disabled' : ''
    const restoreFill = isRestorable ? '#0094d9' : '#adc3db'
    const trashcanFill = isDeleted ? '#e10008' : '#0094d9'
    return (
        <EditNumberWrapper>
            <EditNumberCell>
                <span className={`${deletedClassName}`}>{industry_name}</span>
            </EditNumberCell>
            <EditNumberCell>
                <InputWrapper style={{ marginRight: '5px' }}>
                    <Input
                        type="text"
                        id="did_phone_name"
                        name="did_phone_name"
                        onChange={handleTextChange}
                        value={phoneName}
                        readOnly={!isEditPhoneName}
                        disabled={isRetiredOrScheduledToRetire}
                    />
                </InputWrapper>
                <IconButton
                    variant="span"
                    icon={PencilSvg}
                    onClick={editPhoneName}
                    disabled={isRetiredOrScheduledToRetire}
                />
            </EditNumberCell>
            <EditNumberCell>
                <span className={`${deletedClassName}`}>
                    {formatPhoneNumber(phone_number)}
                </span>
            </EditNumberCell>
            <EditNumberCell>
                <RadioGroup
                    showLabel={false}
                    name={`is_whisper_audio_audio_${did_phone_number_id}`}
                    options={[
                        { label: 'Yes', value: '1' },
                        { label: 'No', value: '0' },
                    ]}
                    onChange={handleChange}
                    checkedOption={whisperVal}
                    isClearable={false}
                    disabled={isRetiredOrScheduledToRetire}
                />
            </EditNumberCell>
            <EditNumberCell>
                <span className={`type-extra-small-body ${deletedClassName}`}>
                    {creationTimestamp.date}
                    <br />
                    {`${creationTimestamp.time} ${creationTimestamp.amPm.toUpperCase()}`}
                </span>
            </EditNumberCell>
            <DeleteContainer>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* If the number is restorable show the restore helper. otherwise show a delete icon */}
                    {is_retired == '1' || isRestorable ? (
                        <Tippy
                            placement="top"
                            content={tooltipContent}
                            duration={10}
                            interactive={true}
                            allowHTML={true}
                            theme="sd-tooltip"
                            zIndex={1000}
                            // trigger="hover"
                        >
                            <div>
                                <SvgWrapper height={24} width={24} fill={restoreFill}>
                                    {Restore}
                                </SvgWrapper>
                            </div>
                        </Tippy>
                    ) : (
                        <IconButton
                            variant="span"
                            // fill
                            icon={<SvgWrapper fill={trashcanFill}>{Delete}</SvgWrapper>}
                            onClick={(e) => {
                                e.stopPropagation()
                                onRemoveNumber && onRemoveNumber(did_phone_number_id)
                            }}
                            disabled={is_retired == '1'} // the delete button is only disabled if the number is currently disabled
                            // style={{ marginRight: '5px' }}
                        />
                    )}
                </div>
            </DeleteContainer>
        </EditNumberWrapper>
    )
}
