import React from 'react';
import {formatNumber} from "../../Util";
import AuthService from "../../service/AuthService";

export const CallsAnsweredConfidence = (total, minConfidence) => <>
    <span
        className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
        Calls Answered Confidence
    </span>
    <p
        className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        "Calls Answered" is based on whether a lead is marked Call Answered Yes or No.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        You need to manage at least{' '}
        {formatNumber(total * minConfidence / 100, 0)} out of {total}{' '}
        Billable Leads currently filtered in order to unlock a
        Moderately Confident understanding of your Call Answered Rate.
    </p>
    <ul className="spacing-5">
        <li className="type-red">Poor Confidence (0% - 59%)</li>
        <li className="type-gold">Moderate Confidence (60% - 79%)</li>
        <li className="type-green">Good Confidence (80% - 100%)</li>
    </ul>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">
        Would you like help Managing your Leads? Contact us about our{' '}
        <a target="_blank" href={AuthService.isMarketplace
            ? 'https://support.servicedirect.com/call-insights-service'
            : 'https://support.servicedirect.com/select/call-insights-service'}>Call Insights Service.</a>
    </p>
</>;

export const CallsAnsweredRateUnlocked = <>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">Calls Answered Rate = (Number of Calls Answered + Number of Forms Responded to) ÷ (Number of Leads)</p>
</>;

export const CallsAnsweredRateLocked = (total, minConfidence) => <>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        Call Answered Rate is locked until at least 60% of the leads currently filtered have Calls Answered Yes or No
        managed.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        You need to manage at least{' '}
        {formatNumber(total * minConfidence / 100, 0)} out of {total}{' '}
        Billable Leads currently filtered in order to unlock a Moderately Confident understanding of your Call Answered
        Rate.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">
        Would you like help Managing your Leads? Contact us about our{' '}
        <a target="_blank" href={AuthService.isMarketplace
            ? 'https://support.servicedirect.com/call-insights-service'
            : 'https://support.servicedirect.com/select/call-insights-service'}>Call Insights Service.</a>
    </p>
</>;

export const CallsAnsweredLikely = <>
    Likely Calls Answered calculates the approximate number of Calls Answered based on your completion of Lead
    Progress. We use averages across all of our clients and combine with your data to extrapolate and make an
    educated guess about the leads that you have <b>not managed</b> Lead Progress on in order to give you a realistic
    number.
</>;

export const AppointmentBookedConfidence = (total, minConfidence) => <>
    <span
        className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
        Booked Appointments Confidence
    </span>
    <p
        className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        "Booked Appointments" is based on whether a lead is marked Booked Appointment Yes or No.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        You need to manage at least{' '}
        {formatNumber(total * minConfidence / 100, 0)} out of {total}{' '}
        Billable Leads currently filtered in order to unlock a
        Moderately Confident understanding of your Booked Appointment Rate.
    </p>
    <ul className="spacing-5">
        <li className="type-red">Poor Confidence (0% - 59%)</li>
        <li className="type-gold">Moderate Confidence (60% - 79%)</li>
        <li className="type-green">Good Confidence (80% - 100%)</li>
    </ul>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">
        Would you like help Managing your Leads? Contact us about our{' '}
        <a target="_blank" href={AuthService.isMarketplace
            ? 'https://support.servicedirect.com/call-insights-service'
            : 'https://support.servicedirect.com/select/call-insights-service'}>Call Insights Service.</a>
    </p>
</>;

export const AppointmentBookedRateUnlocked = <>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">
        Booked Appointments Rate = (Number of Leads with Booked Appointment Yes) ÷
        (Number of Leads with Booked Appointment Yes + Number of Leads with Booked Appointment No)
    </p>
</>;

export const AppointmentBookedRateLocked = (total, minConfidence) => <>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        Booked Appointments Rate is locked until at least 60% of the leads currently filtered have Booked Appointment
        Yes or No Managed.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        You need to manage at least{' '}
        {formatNumber(total * minConfidence / 100, 0)} out of {total}{' '}
        Billable Leads currently filtered in order to unlock a Moderately Confident understanding of your Booked
        Appointment
        Rate.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">
        Would you like help Managing your Leads? Contact us about our{' '}
        <a target="_blank" href={AuthService.isMarketplace
            ? 'https://support.servicedirect.com/call-insights-service'
            : 'https://support.servicedirect.com/select/call-insights-service'}>Call Insights Service.</a>
    </p>
</>;

export const AppointmentBookedLikely = <>
    Likely Booked Appointments calculates the approximate number of Booked Appointments based on your completion of
    Lead Progress. We use averages across all of our clients and combine with your data to extrapolate and make an
    educated guess about the leads that you have <b>not managed</b> Lead Progress on in order to give you a realistic
    number.
</>;

export const JobsWonConfidence = (total, minConfidence) => <>
    <p
        className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
        Jobs Won Confidence
    </p>
    <p
        className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        "Jobs Won" is based on whether a lead is marked Job Won or Job Lost.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        You need to manage at least{' '}
        {formatNumber(total * minConfidence / 100, 0)} out of {total}{' '}
        Billable Leads currently filtered in order to unlock a
        Moderately Confident understanding of your Jobs Won Rage.
    </p>
    <ul className="spacing-5">
        <li className="type-red">Poor Confidence (0% - 59%)</li>
        <li className="type-gold">Moderate Confidence (60% - 79%)</li>
        <li className="type-green">Good Confidence (80% - 100%)</li>
    </ul>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">
        Would you like help Managing your Leads? Contact us about our{' '}
        <a target="_blank" href={AuthService.isMarketplace
            ? 'https://support.servicedirect.com/call-insights-service'
            : 'https://support.servicedirect.com/select/call-insights-service'}>Call Insights Service.</a>
    </p>
</>;

export const JobsWonRateUnlocked = <>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">Jobs Won Rate = (Jobs Won) ÷ (Jobs Won + Jobs Not Won)</p>
</>;

export const JobsWonRateLocked = (total, minConfidence) => <>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        Jobs Won Rate is locked until at least 60% of the leads currently filtered have Jobs Won Yes or No managed.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        You need to manage at least{' '}
        {formatNumber(total * minConfidence / 100, 0)} out of {total}{' '}
        Billable Leads currently filtered in order to unlock a Moderately Confident understanding of your Jobs Won Rate.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">
        Would you like help Managing your Leads? Contact us about our{' '}
        <a target="_blank" href={AuthService.isMarketplace
            ? 'https://support.servicedirect.com/call-insights-service'
            : 'https://support.servicedirect.com/select/call-insights-service'}>Call Insights Service.</a>
    </p>
</>;

export const JobsWonLikely = <>
    Likely Jobs Won calculates the approximate number of Jobs Won based on your completion of Lead Progress. We use
    averages across all of our clients and combine with your data to extrapolate and make an educated guess about
    the leads that you have <b>not managed</b> Lead Progress on in order to give you a realistic number.
</>;

export const RoiConfidence = (total, minConfidence) => <>
    <p
        className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
        Revenue, Cost, Return On Investment Confidence
    </p>
    <p
        className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        "Revenue Managed" means that a user has saved Revenue on the lead or the lead has been Marked Lost, which
        records $0 Revenue.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        You need to manage at least{' '}
        {formatNumber(total * minConfidence / 100, 0)} out of {total}{' '}
        Billable Leads currently filtered in order to unlock a
        Moderately Confident understanding of your ROI.
    </p>
    <ul className="spacing-5">
        <li className="type-red">Poor Confidence (0% - 59%)</li>
        <li className="type-gold">Moderate Confidence (60% - 79%)</li>
        <li className="type-green">Good Confidence (80% - 100%)</li>
    </ul>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">
        Would you like help Managing your Leads? Contact us about our{' '}
        <a target="_blank" href={AuthService.isMarketplace
            ? 'https://support.servicedirect.com/call-insights-service'
            : 'https://support.servicedirect.com/select/call-insights-service'}>Call Insights Service.</a>
    </p>
</>;

export const RoiUnlocked = <>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">Return On Investment % = (Revenue - Cost) ÷ (Cost)</p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">
        Would you like help Managing your Leads? Contact us about our{' '}
        <a target="_blank" href="https://support.servicedirect.com/select/call-insights-service">Call Insights Service.</a>
    </p>
</>;

export const RoiLocked = (total, minConfidence) => <>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        Return On Investment is locked until at least 60% of the leads currently filtered have Revenue managed.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        You need to manage at least{' '}
        {formatNumber(total * minConfidence / 100, 0)} out of {total}{' '}
        Billable Leads currently filtered in order to unlock a Moderately Confident understanding of your ROI.
    </p>
    <p className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height">
        Would you like help Managing your Leads? Contact us about our{' '}
        <a target="_blank" href={AuthService.isMarketplace
            ? 'https://support.servicedirect.com/call-insights-service'
            : 'https://support.servicedirect.com/select/call-insights-service'}>Call Insights Service.</a>
    </p>
</>;

export const RoiLikely = <>
    <p>
        Likely Return On Investment calculates the approximate Return On Investment based on your completion of Lead
        Progress. We use averages across all of our clients and combine with your data to extrapolate and make an
        educated guess about the leads that you have <b>not managed</b> Lead Progress on in order to give you a realistic
        number.
    </p>
    <p className="spacing-5">
        <b>Note:</b> this ROI only accounts for the 1st interaction, it does not include
        the Lifetime Value of your new
        customers acquired through Service Direct.
    </p>
</>;
