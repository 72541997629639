import React, { useEffect, useState } from 'react'
import FullWidthLayout from '../../common/layouts/FullWidthLayout'
import Section from '../../common/layouts/Section'
import QALeadManagerLink from './QALeadManagerLink'
import { Content, LinkWrapper, MarginBottom } from './styles'
import LeadService from '../../service/LeadService'
import { jsDateToSqlDateString } from '../../Util'
import { format } from 'date-fns'
import LeadReviewProgress from './LeadReviewProgress'
import { IconButton } from '../../common/components/form_elements/Button'
import { Sync } from '../../common/Svgs'

export default (props) => {
    const ls = new LeadService()
    const { host, protocol } = document.location
    const [freshnessTimestamp, setFreshnessTimestamp] = useState(Date.now())

    // effect to set the document (page) title
    useEffect(() => {
        document.title = 'QA Dashboard'
    }, [])

    let lurLink = `${protocol}//${host}/leads?lead_review_select_list_value=in%20review\
&is_valid=null&call_played_select_list_value=0&flagged_select_list_value=0\
&followup_select_list_value=0&call_answered_select_list_value=0\
&booked_appointment_select_list_value=0&job_won_select_list_value=0\
&revenue_entered_select_list_value=0&managed_select_list_value=all\
&campaign_type=all&status_id=all&contractor_type_id=all&`
    // for LUR, use date range of last 30 days plus today
    // set the to and from date so that the UI renders it correctly
    const _from = new Date()
    _from.setDate(_from.getDate() - 30)
    const dateFrom = jsDateToSqlDateString(_from)
    const _to = new Date()
    const dateTo = jsDateToSqlDateString(_to)
    lurLink += `&date_range_id=9&date_from=${dateFrom}&date_to=${dateTo}`
    // sort by date, ascending
    lurLink += '&sort_column=lead-review&sort_order=desc'

    const _d = new Date(freshnessTimestamp)
    const now = format(_d, "MMMM d, yyyy 'at' h:mm b")

    const updateFreshness = () => {
        setFreshnessTimestamp(Date.now())
    }

    return (
        <FullWidthLayout title="Quality Assurance Dashboard">
            <Section>
                <Content>
                    <div>
                        <MarginBottom>
                            <span className="type-large-subhead">Lead Manager Links</span>
                        </MarginBottom>
                        <LinkWrapper>
                            <QALeadManagerLink
                                getLink={() => ls.getCallInsightsClients(true)}
                                label="Call Insights Free Trial (CIFT) Client Leads, Last 90 Days"
                                leadDateProperty="creation_timestamp"
                                freshnessTimestamp={freshnessTimestamp}
                            />
                            <QALeadManagerLink
                                getLink={() => ls.getCallInsightsClients()}
                                label="Call Insights (CI) Client Leads, This Month"
                                leadDateProperty="creation_timestamp"
                                freshnessTimestamp={freshnessTimestamp}
                            />
                            <QALeadManagerLink
                                getLink={() => Promise.resolve(lurLink)}
                                label="Leads Under Review (LUR) Last 30 Days"
                                leadDateProperty="lead_review_submit_date"
                                freshnessTimestamp={freshnessTimestamp}
                            />
                        </LinkWrapper>
                    </div>
                    <div />
                    <div>
                        <MarginBottom>
                            <span className="type-large-subhead">
                                Lead Review Progress
                            </span>
                        </MarginBottom>
                        <LinkWrapper>
                            <LeadReviewProgress freshnessTimestamp={freshnessTimestamp} />
                        </LinkWrapper>
                    </div>
                </Content>
            </Section>
            <Section>
                <span>Data fresh as of {now} </span>
                <div style={{ marginTop: '12px' }}>
                    <IconButton
                        onClick={updateFreshness}
                        height="18px"
                        width="18px"
                        variant="span"
                        icon={Sync}
                    >
                        <span className="type-normal-body">Refresh Data</span>
                    </IconButton>
                </div>
            </Section>
        </FullWidthLayout>
    )
}
