import React, { Component } from 'react';

import BulkEditCampaignsStep2Footer from './BulkEditCampaignsStep2Footer';
import {CALL_RECORDING_TEXT} from "../StaticValues";

export default class BulkEditCampaignsStep2CallRecording extends Component {
  render() {
      //
      // Create the list of campaigns along with their current setting
      //
      let campaignsList = this.props.campaigns.map((campaign, idx) => {
          if (campaign.campaign_id in this.props.selectedCampaignIds) {
              let callRecordingText = this.props.getOptionValueTxt('call_recording', campaign.call_recording_setting);
              return <div key={idx} className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                  <span className="type-large-body type-force-newline type-heavy">
                      {typeof(campaign.company_name) !== 'undefined'
                          ? <>{campaign.company_name} &mdash; </>
                          : ''}
                      {campaign.campaign_name}
                  </span>
                  <span className="type-normal-body type-force-newline">Call Recording & Lead Review Preference: { callRecordingText }</span>
              </div>
          } else {
              return null;
          }
      });

      return (
          <>
            <div className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                <p className="type-large-body">
                    You are Bulk Editing the <b>Call Recording & Lead Review Preference</b> for the Campaigns listed below.
                </p>
                <p className="type-normal-body">
                    Their current settings are noted individually, and if you choose new settings, all Campaigns will be updated to those.
                </p>
            </div>
            <div className="spacing-60-bottom-full spacing-60-bottom-tablet spacing-40-bottom-mobile">
                {campaignsList}
            </div>

            <p className="type-large-body type-heavy type-single-line type-bottom-stroke no-margin-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                NEW SETTINGS (Replaces current settings)
            </p>

            <div className="spacing-40-bottom-full spacing-40-bottom-tablet spacing-30-bottom-mobile">
                <div className="spacing-20-bottom">
                    <p className="type-large-body type-heavy type-single-line no-margin">
                        Call Recording & Lead Review
                    </p>
                </div>
                {/* NOTE - this copy/text is more detailed than what's given by getOptionsValueTxt */}
                <div className="radio-container radio-container__top-align spacing-10-bottom">
                    <input type="radio"
                           name="callRecording"
                           id="popup-call-recording-yes"
                           checked={ this.props.formData.call_recording === 1 ? 'checked' : false }
                           onChange={() => this.props.updateFormData('call_recording', 1)}
                    />
                    <label htmlFor="popup-call-recording-yes" className="type-normal-body">
                        <span className="radio">
                            <span className="type-force-newline">Yes, Record Phone Calls – Lead Review Enabled</span>
                            <span className="type-force-newline type-small-body">{CALL_RECORDING_TEXT}</span>
                        </span>
                    </label>
                </div>
                <div className="radio-container">
                    <input type="radio"
                           name="callRecording"
                           id="popup-call-recording-no"
                           defaultValue="callRecordingNo"
                           checked={ this.props.formData.call_recording === 0 ? 'checked' : false }
                           onChange={() => this.props.updateFormData('call_recording', 0)}
                    />
                    <label htmlFor="popup-call-recording-no" className="type-normal-body">
                        <span className="radio">
                            No, Do Not Record Phone Calls – Leads Ineligible for Lead Review
                        </span>
                    </label>
                </div>
            </div>

            <BulkEditCampaignsStep2Footer
                commitSettings={ this.props.commitSettings }
                updateModalContent={ this.props.updateModalContent } 
                updateModalHeader={ this.props.updateModalHeader }
                confirmationHeader="Call Recording & Lead Review Confirmation"
            />
          </>
      );
  }
}