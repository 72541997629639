import axios from "axios";

import {get, post} from '../Requests';

export default class StatementService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    payStatement = (invoiceId) => {
        return post(`statements/${invoiceId}/payment`, this.cancelSignal.token)
            .then((response) => response.data);
    };

    /**
     * Fetches unpaid statements for a given contractor and passes
     * them as an array to the provided callback.
     */
    getUnpaidStatements = () => {
        return get('statements?paid=0', this.cancelSignal.token)
            .then(resp => resp.data.data.statements);
    }
}