import React, {Component} from 'react';

import Checkbox from '../form_elements/Checkbox';

/** @var {string} the local storage key for remembering first deselecting of a basic service subcategory */
const LOCAL_STORAGE_REMEMBER_KEY = 'sd-deselect-service-first';

export default class ServicesSubcategory extends Component {
    /**
     * call this.props.updateServiceCategoryCheckbox with coinciding array indexes
     * this is put in a function to not mud up the markup in `render`
     */
    updateCheckBoxCallBack = (event, serviceIndex) => {
        const checked = event.target.checked;

        if (!checked) {
            try {
                const alreadyChecked = !!localStorage.getItem(LOCAL_STORAGE_REMEMBER_KEY);
                if (!alreadyChecked) {
                    localStorage.setItem(LOCAL_STORAGE_REMEMBER_KEY, true);
                    this.props.updateMessageBlocks(<><u>Disclaimer</u>: We can not block leads from non-selected
                        sub-services but we'll use the selected ones for our advertising</>, 'info');
                }
            } catch (ignore) {
            }
        }

        this.props.updateServiceCategoryCheckBox(
            checked,
            serviceIndex,
            this.props.subcategoryIndex,
            this.props.serviceCategoryIndex
        );
    };

    render() {
        let serviceSubcategory = this.props.serviceSubcategory;

        // get all of the serviceCheckBoxes and fill out their data
        const serviceCheckBoxes = serviceSubcategory.services.map((service, key, array) => {
            let isRequired = service.required === "1";

            //
            // We need a name that is unique for each Service Name + Service Category combination
            // Addresses https://app.asana.com/0/691849787814198/1125377797948803/f
            //
            let formInputName = service.name.replace(/ /g, '_') + '__serviceCategory_' + this.props.serviceCategoryIndex;

            return (
                <div
                    className={"form__cell form__cell__33 form__cell__100__mobile" + ((key < array.length - 1) ? " spacing-10-bottom-mobile" : "")}
                    key={'serviceSubcategory-checkbox-' + key}
                >
                    <Checkbox
                        name={formInputName}
                        onChange={(event) => this.updateCheckBoxCallBack(event, key)}
                        id={"signup-" + service.name}
                        checked={service.selected || isRequired}
                        label={service.name}
                        disabled={isRequired ? "disabled" : ""}
                    />
                </div>
            );
        });

        // we are going to to need to start a new form__row
        // for every 3 checkboxes so i'm sanctioning an array of 3
        // checkboxes
        let serviceCheckBoxesRows = [];
        let checkboxRowIndex = -1;
        for (let i in serviceCheckBoxes) {
            // if this iter is divisible by 3 then
            // increment the checkboxRowIndex and
            // push another [] to fill in
            if (i % 3 === 0) {
                checkboxRowIndex++;
                serviceCheckBoxesRows.push([]);
            }
            // push a checkbox in the checkBox onto the serviceCheckBoxesRows
            serviceCheckBoxesRows[checkboxRowIndex].push(serviceCheckBoxes[i]);
        }

        // now we can just map em out and return rows
        const services = serviceCheckBoxesRows.map((serviceCheckBoxesRow, key) => {
            return (
                <div className="form__row spacing-5-bottom no-margin-bottom-mobile"
                     key={'serviceCheckBoxesRow-' + key}
                >
                    {serviceCheckBoxesRow}
                </div>
            );
        });


        return (
            <div className="spacing-30-bottom">
                <div className="form__row spacing-10-bottom">
                    <div className="form__cell form__cell__label form__cell__100">
                        <label className="type-normal-body type-heavy type-single-line">
                            {serviceSubcategory.name}
                        </label>
                    </div>
                </div>
                {services}
            </div>
        );
    }
}
