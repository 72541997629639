import React, {Component} from "react";
import queryString from "query-string";

import SignupMarketplaceService from '../service/SignupMarketplaceService';
import Modal from "../common/Modal";
import {errorHandler} from "../Requests";
import LoadingGif from "../common/components/LoadingGif";
import AuthService from "../service/AuthService";
import GoogleTagManagerService from "../service/GoogleTagManagerService";

export default class StepConfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: AuthService.getItem('username'),
            loading: true,
            modalHeader: null,
            modalContent: null
        };

        this.signupMarketplaceId = AuthService.getItem('signupMarketplaceId');

        let query = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});
        this.token = query.token;

        this.signupMarketplaceService = new SignupMarketplaceService();
    }

    componentDidMount() {
        // case of coming from the email link with the token
        if (this.token) {
            this.signupMarketplaceService.confirmEmail(this.token)
                .then((response) => {
                    // update data layer parameter stepEmailConfirmationComplete
                    let mpStep4CompletedByAdminUserId = response?.mpStep4CompletedByAdminUserId || 0
                    GoogleTagManagerService.updateDataLayer({event: "stepEmailConfirmationComplete", assistingAdminUserId : mpStep4CompletedByAdminUserId})
                    AuthService.updateLoginData(response);

                    // #NL 30-JUL-2020
                    // using hard reload to update the auth variable in App.js
                    // maybe we can soft reload the parent instead for smoother UI
                    // window.location = response.location;
                    // this.props.history.push(response.location);
                })
                .catch((error) => {
                    if (errorHandler(error)) {
                        console.log('invalid email confirmation token; logging out', this.token);
                        AuthService.clear();
                        this.props.updateMessageBlocks(
                            'The link you used is no longer valid, please login to continue.',
                            'error'
                        );
                        this.props.history.push('/login');
                    }
                });
        }
        // invalid case
        else if (isNaN(this.signupMarketplaceId) || this.signupMarketplaceId == 0) {
            window.location = '/login';
        }
        // case after logging in, but before confirming the email address
        else {
            // update the Data Layer now that there is a User with an active MP Sign Up
            GoogleTagManagerService.updateDataLayer()
            this.setState({loading: false});
        }
    }

    componentWillUnmount() {
        this.signupMarketplaceService.cancelSignal.cancel();
    }

    updateModalContent = (modalContent, modalHeader, modalWidth, modalFlatBottom) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom
        });
    };

    resend = () => {
        this.signupMarketplaceService.resendConfirmationEmail(this.signupMarketplaceId)
            .then(() => this.setState({
                modalHeader: 'Email Confirmation Request Set',
                modalContent: <p>Please check your inbox to confirm your email.</p>
            }))
            .catch((error) => {
                if (errorHandler(error)) {
                    this.props.updateMessageBlocks(error.response.data.message, 'error');
                }
            });
    };

    render() {
        if (this.state.loading) {
            return <LoadingGif/>;
        }

        return <div>
            <Modal
                content={this.state.modalContent}
                header={this.state.modalHeader}
                updateModalContent={this.updateModalContent}
            />
            <h1 className="module__twothirdswidth type-large-subhead type-single-line module-header">
                Please Check Your Email Inbox
            </h1>
            <div className="module__contentbox module__twothirdswidth">
                <h2 className="type-normal-subhead type-heavy spacing-10-bottom type-centered">
                    And verify that you have access to the email address you used.
                </h2>
                <p className="type-normal-body type-centered">
                    We've sent an email to <b>{this.state.email}</b> with a link to verify your address.
                    <br/>
                    Please click on the link to continue.{' '}
                </p>
                <p className="type-normal-body spacing-50-bottom type-centered spacing-18-top">
                    If you did not get the email,{' '}
                    <button className="button-clean type-blue type-heavy type-normal-body" onClick={this.resend}>
                        click here to resend.
                    </button>
                </p>
                <p className="type-centered">Psst! Check your spam folder if you don't see the email.</p>
            </div>
        </div>;
    }
}
