import React from 'react';
import ReactDOM from 'react-dom';

export function validateAllInputs(stateClone) {
    let error, fieldClone, fieldName,
        validationPassed = true,
        domInputs = Array.from(document.getElementsByTagName("input")),
        inputsClone = {...stateClone.inputs};

    domInputs = domInputs.concat(Array.from(document.getElementsByTagName("select")),
        Array.from(document.getElementsByTagName("textarea")));

    for (let key in domInputs) {

        // special case of avoiding service category
        if (
            !domInputs.hasOwnProperty(key)
            || domInputs[key].name.indexOf("service_category") !== -1
        ) {
            continue;
        }

        //
        // Special case for service_area_* text areas.  Service area text areas
        // are intended to be named to imply position.  E.g. a service area text
        // area can be 1 of n service area text areas displaying in a view at
        // one time.  In order to know which state input to update, we need to
        // reference the text area input by it's position in the
        // state.service_categories array.  A better alternative may be to
        // id the text areas uniquely and consistently in the dom and state,
        // and reference by that id instead of having to maintain a position
        // index (which would need to be maintained when a a service category
        // is removed by the user, etc.)
        //
        if (
            !domInputs.hasOwnProperty(key)
            || domInputs[key].name.indexOf("service_area_") !== -1
        ) {

            let field = domInputs[key];

            fieldName = field.name;
            error = inputValidation(field);

            if (error) {
                let serviceCategoryInputs = [...stateClone.service_categories],
                    erroneousServiceAreaPosition = field.getAttribute('data-position-in-group'),
                    serviceAreaInput = serviceCategoryInputs[erroneousServiceAreaPosition]["service_area"];

                validationPassed = false;
                fieldClone = { ...inputsClone[fieldName] };
                serviceAreaInput.error = error;
            }

            // the if block we're in handles service_area_* inputs, and
            // the code after this if block can't, so we need to continue here
            // to prevent that code from running in this case.
            continue;
        }


        error = inputValidation(domInputs[key]);
        fieldName = domInputs[key].name;

        if (error) {

            //
            // Leaving this here for debug purposes:  This debug conditional passes if
            // there isn't a dom input named after a corresponding state.inputs property.
            // An example is the contact_email dom element on the Campaign Setup page.
            // It doesn't map to state.inputs.contact_email like this code assumes, b/c
            // that property simply doesn't exist in state.inputs when the view constructs.
            //
            // That turns into a problem b/c state.inputs are initialized to an
            // inputObj().  The code below, on the other hand, writes back to
            // state.inputs an object that wasn't in state.inputs before, and
            // one that's not compatible with inputObj().  Instead, it writes back an
            // object with an "error" property, lacking two other required properties:
            // "ref" and "value" (both of which inputObj() has).
            //
            // This difference causes callers to crash, b/c they assume state.inputs
            // are all inputObj().
            //
            // We could add conditional code to work around this, but that'd be needed
            // here, and in callers, continuing a messy spiral.  Instead, we've
            // decided to refactor validation logic in a way that'll offer the following
            // benefits; it'll:
            // 1. do away w/the problem being described above
            // 2. also do away w/hardcoded conditional logic above on "service_category"
            //      and "service_area_".
            // 3. handle checkboxes and radio buttons, which this method currently doesn't
            // 4. provide a standard, consistent link between a view's local state object
            //      and corresponding form elements.
            // 5. consolidate our validation logic to one place
            //
            if (!inputsClone.hasOwnProperty(domInputs[key].name)) {
                console.log("key '"+domInputs[key].name+"' does not exist in state.inputs");
                // continue // uncomment to skip past an erroneous condition introduced below.
            }

            validationPassed = false;
            fieldClone = { ...inputsClone[fieldName] };
            fieldClone.error = error;
            inputsClone[fieldName] = fieldClone;
            stateClone.inputs = inputsClone;
        }
    }
    // At this point, stateClone now has properties it didn't originally.
    // console.log(stateClone);
    return validationPassed;
}

/**
* @arg this object
* @arg inputs elems 
* @arg stateClone to update on
* Runs validation on fields on submit
* TODO Add in arg for extra objects to check (cpl, service areas)
 * DEPRECATED - validateAllInputs
**/
export function validateOnSubmit(component, inputs, stateClone) {
    let error;
    let inputsClone = { ...stateClone.inputs };
    let fieldClone;

    let validationPassed = true;
    for (let key in inputs) {
        // special case of avoiding service category
        if (
            !inputs.hasOwnProperty(key)
            || inputs[key].name.indexOf("service_category") !== -1
        ) {
            continue;
        }
        error = inputValidation(inputs[key]);
        let fieldName = inputs[key].name;

        if (error) {
            validationPassed = false;
            fieldClone = { ...inputsClone[fieldName] };
            fieldClone.error = error;
            inputsClone[fieldName] = fieldClone;
            stateClone.inputs = inputsClone;
        }
    }
    return validationPassed;
}

/*
 *@description
 * alternative function to syncInputValueToState
 * this function takes in a state object, clones it, mutates the clone,
 * and then returns the mutated clone
 * so if you have an onchange you can just do this
 * onChange={ (event) => this.setState(setInputValue(this.state, event) }
 * DEPRECATED - use updateStateCloneWithValue
 */
export function setInputValue(state, event, differentInputKey) {
    let inputKey = differentInputKey || "inputs";
    let stateClone = {...state};
    let inputClone = {...stateClone[inputKey]};
    inputClone[event.target.name].value = event.target.value;
    stateClone[inputKey] = inputClone;
    return stateClone;
}

/**
* @arg inputsClone from state
* structures and returns payload object for axiox calls
**/
export function getPayload(inputsClone) {
    let payload = {};
    for (let field in inputsClone) {
        if (!inputsClone.hasOwnProperty(field)) {
            continue;
        }
        payload[field] = inputsClone[field].value;
    }

    return payload;
}

/**
 * Returns a payload object for fields in which appInput.changed is true.
 * @todo: appInput.changed eventually needs to reset to false on success response.
 * @arg inputsClone from state.
 */
export function getPayloadFromChangedFields(inputsClone) {
    let payload = {};
    for (let field in inputsClone) {
        if (!inputsClone[field].changed) {
            continue;
        }
        payload[field] = inputsClone[field].value;
    }

    return payload;
}

/**
* get default input error messages
*   Also does custom Password validation
**/
export function inputValidation(elem) {
    let validity = elem.validity;

    // replace _ with a space and capitalize the first letter
    //    let displayName = name.replace(/_/ig," ");
    //    displayName = displayName.charAt(0).toUpperCase() + displayName.slice(1);

    // if valid return empty string
    if (validity.valid) {
        return "";
    }
    else if (validity.patternMismatch) {
        //return  displayName + " is not the right format.";
        return "Incorrect format";
    }
    else if (validity.valueMissing) {
        // return empty string.  We are already displaying it with "*"
        //    But we still want to trigger the truthiness of this error
        //   return displayName + " is required.";
        return " ";
    }
    else if (validity.badInput) {
        //return displayName
        return "Must be Number";
    }
}

/**
* Return the default input structure in state
**/
export const inputObj = (value, error) => {
    value = value === undefined ? "" : value;
    error = error === undefined ? "" : error;

    return { value: value, error: error, ref: React.createRef() };
};

export const appInput = (elementId = '', elementName = '', value = '', error = '') => {
    return {
        element_id: elementId,
        element_name: elementName,
        value: value,
        error: error,
        ref: React.createRef(),
        changed: false
    };
};

/**
 * Runs the input through validation and updates the error in state
 * deprecated-ish: use updateStateCloneWithError
 */
export const handleInputBlur = (stateClone, event) => {
    let inputClone = { ...stateClone.inputs };

    let elem = event.target;
    let error = inputValidation(elem);

    let name = elem.name;
    let fieldClone = { ...inputClone[name] };

    fieldClone.error = error;
    inputClone[name] = fieldClone;
    stateClone.inputs = inputClone;
};

export const updateStateCloneWithValue = (stateClone, event) => {
    let inputClone = { ...stateClone.inputs },
        elem = event.target,
        name = elem.name,
        value = elem.value,
        fieldClone = { ...inputClone[name] };

    // alter if it's a checkbox
    if (elem.type === "checkbox") {
        value = elem.checked;
    }

    // add the value to the state.inputs
    fieldClone.changed = false;
    if (fieldClone.value != value) {
        fieldClone.changed = true;
    }
    fieldClone.value = value;
    inputClone[name] = fieldClone;
    stateClone.inputs = inputClone;

    return stateClone;
};

/* TODO: Generalize this for other objects
      And not just lead_delivery_settings
 */
export const updateStateObjectWithValue = (event, stateClone, collectionName, key) => {
    let elem = event.target,
        //leadDeliverySettingsClone = {...stateClone['lead_delivery_settings']},
        collection = stateClone[collectionName][key];

    collection.value = elem.value;
    stateClone[collectionName][key] = collection;
    //stateClone['lead_delivery_settings'] = leadDeliverySettingsClone;

    return stateClone;
};

export const updateStateInputObjectWithValue = (event, stateClone, collectionName, key) => {
    let elem = event.target,
        inputsClone = stateClone.inputs,
        collection = inputsClone[collectionName][key];

    collection.value = elem.value;

    inputsClone[collectionName][key] = collection;
    stateClone.inputs = inputsClone;

    return stateClone;
};



/**
 * Runs the input through validation and updates the error in state
 **/
export const updateStateCloneWithError = (stateClone, event) => {
    let inputClone = { ...stateClone.inputs },
        elem = event.target,
        name = elem.name,
        fieldClone = { ...inputClone[name] };

    //
    // if this is a "grouped" element, e.g. one service area text area in a
    // group of service area text areas, then we can't immediately act on the
    // provided event.target.  We first must determine where to look for its
    // corresponding data in stateClone.
    //
    // In order to do that, we rely on the element's state input key and index
    // position in its group to tell us whether this is the 2nd, or 3rd, or nth
    // text area, in its group.  To supplement, a given service area text area's
    // position in state will be determined as:
    //
    // state[data-state-input-key][data-position-in-group]
    //
    if (elem.hasAttribute('data-state-input-key')) {

        /**
         * @todo: this if block, and component properties that's been added to
         * it elsewhere, are needed to get the service area text area to return
         * to a non-error state when the user fills it in and blurs out of it.
         * The "service_area" string below is hardcoded and needs to change,
         * and I still need to make a second pass at these data-state-input-key
         * and data-position-in-group properties.  I'm not yet confident with
         * them, but going w/them for now while a solution pattern continues to
         * form.
         */
        let stateInputKey = elem.getAttribute('data-state-input-key'),
            positionInGroup = elem.getAttribute('data-position-in-group'),
            stateValueProperty = elem.getAttribute('data-state-value-property'),
            stateObject = stateClone[stateInputKey][positionInGroup][stateValueProperty];

        stateObject.error = inputValidation(elem);
    }
    else {
        // this else block's code was already here, and continues to work for
        // non grouped inputs.
        //fieldClone.error = inputValidation(elem);
        fieldClone.error = "";
        inputClone[name] = fieldClone;
        stateClone.inputs = inputClone;
    }

    return stateClone;
};

/**
 * Add the input value to the state
 * deprecated-ish: use updateStateCloneWithValue inline setState within the onChange
 */
export const syncInputValueToState = (stateClone, event, value) => {
    let inputClone = { ...stateClone.inputs };
    let name = typeof event === 'object'
        ? event.target.name
        : event;
    let fieldClone = { ...inputClone[name] };

    /* Uncomment to get live error feedback
    // do validation on the field and get the errors
    let error = inputValidation(elem, component);


    // error will either be a message or empty string if no error
    fieldClone.error = error;
    */

    value = value || event.target.value;

    // alter if it's a checkbox
    if (typeof event === 'object' && event.target.type === "checkbox") {
        value = event.target.checked;
    }

    // add the value to the state.inputs
    fieldClone.value = value;
    inputClone[name] = fieldClone;
    stateClone.inputs = inputClone;

    return stateClone;
};

/**
* Customize handler on clicks of checkbox
 * DEPRECATED this is handled within updateStateCloneWithValue now
**/
export const handleCheckboxClick = (stateClone, event) => {
    let elem = event.target;
    let name = elem.name;
    
    let inputClone = { ...stateClone.inputs };
    let fieldClone = { ...inputClone[name] };
    
    // validate on the click
    let error = inputValidation(elem);

    fieldClone.value = !fieldClone.value;
    fieldClone.error = error;

    inputClone[name] = fieldClone;
    stateClone.inputs = inputClone;

    return stateClone;
};

/**
* Jumps to a place on the dom
*   using the React.ref
*   @deprecated  Now use Validator.jumpToError.
*
**/
export const jumpToError = (ref, scrollTo) => {
    if (!ref) { return }
    let block = scrollTo ? scrollTo : "center";

    const domNode = ReactDOM.findDOMNode(ref.current);
    domNode.scrollIntoView({ 
        behavior: "smooth",
        block: block,
        inline: "start"
    });    
};

/**
 * @deprecated  Now use Validator.updateApiErrorMessages
 */
export const updateApiErrorMessages = (errors, type, stateClone) => {
    let apiMessageClone = {...stateClone.apiErrorMessage};
    let errorsArray = [];
    let urlsArray = [];

    for(let errorIndex in errors) {
        errorsArray.push(errors[errorIndex].message);
        urlsArray.push(errors[errorIndex].urls);
    }

    apiMessageClone.messages = errorsArray;
    apiMessageClone.urls = urlsArray;

    apiMessageClone.type = type;
    stateClone.apiErrorMessage = apiMessageClone;

    return stateClone;
    //this.setState(stateClone);
};

/**
 * @deprecated  Now use Validator.updateErrorAndScrolls.
 */
export const updateErrorsAndScroll = (stateClone, response, extraRefs) => {
    if (response.data.errors) {
        let errors = response.data.errors;
        let ref = this.validator.setApiErrorsOnStateClone(errors, stateClone, extraRefs);
        // update the messages for the message block
        updateApiErrorMessages(errors, "error", stateClone);
        // this.setState(stateClone);
        jumpToError(ref);
        return stateClone;
    }
};


/**
 * Grab errors of api response and update stateClone
 * @deprecated
 * @return the React.ref to jump to.
 */
export const setApiErrorsOnStateClone = (errors, stateClone, extraRefs, ) => {
    let ref;

    for(let errorIndex in errors) {
        let error = errors[errorIndex];

        /**
         * If this is a special case in the extraRefs
         *      Just grab that ref and go with it
         **/
        if (extraRefs && !error.parameter_details) {
            ref = extraRefs[error.parameter];

            if (ref) {
                return ref;
            }
        }
        
        // handle if we have param details
        if (error.parameter) {
            let parameter = error.parameter;
            let paramDetails = error.parameter_details;
            // if we have parameter details handle differently
            if (paramDetails) {
                let groupFieldName = paramDetails.key.field;
                let groupFieldValue = paramDetails.key.value !== undefined ? paramDetails.key.value : "";

                // this needs to look at state.inputs too.
                let groupClone = [...stateClone[parameter]];

                let fieldWithError = paramDetails.field;
                let inputToUpdate;
                
                for(let i in groupClone) {
                    if (typeof groupClone[i][groupFieldName] === 'object'
                        && groupClone[i][groupFieldName].value === groupFieldValue) {
                        inputToUpdate = {...groupClone[i] };
                        //
                        // error is intended to be an error message.  It's set
                        // to a single space here just so that it's seen as a
                        // truthy value when we set the hasError property on a
                        // components.  For context, this is the statement that
                        // sets the hasError property on components:
                        //
                        // hasError = !!error
                        //
                        inputToUpdate[fieldWithError].error = " ";
                        ref = ref ? ref : inputToUpdate[fieldWithError].ref;
                        groupClone[i] = inputToUpdate;
                    } else if (groupClone[i].value === groupFieldValue) {
                        if (groupFieldName == i) {
                            inputToUpdate = {...groupClone[i]};
                            inputToUpdate.error = " ";
                            ref = ref ? ref : inputToUpdate.ref;
                            groupClone[i] = inputToUpdate;
                        }
                    }          
                }

                stateClone[parameter] = groupClone;
           }
            else {
                let inputsClone = { ...stateClone.inputs };
                let fieldName = error.parameter;
                // if we don't have a specific field skip this
                if (inputsClone[fieldName]) {
                    inputsClone[fieldName].error = " ";
                    ref = ref || inputsClone[fieldName].ref;
                }
                stateClone.inputs = inputsClone;
            }
        }
    }
    return ref;
};


export const clearErrorMessages = (stateClone) => {
    let apiMessageClone = { ...stateClone.apiErrorMessage };

    apiMessageClone.messages = [];
    apiMessageClone.type = "";

    stateClone.apiErrorMessage = apiMessageClone;

    return stateClone;
};

/**
 * The callback to update the country
 *   from the dropdown
 **/
export const updateCountryCallback = (value, stateClone) => {
    const inputClone = { ...stateClone.inputs };

    if (value !== inputClone.country.value) {

        let stClone = { ...inputClone.state };
        stClone.value = "";
        inputClone.state = stClone;
    }

    let field = { ...inputClone.country };
    field.value = value;

    inputClone.country = field;
    stateClone.inputs = inputClone;

    return stateClone;
};

/**
 * callback to update the state from dropdown
 *
 * o praise our lord, sanctor of javascript
 * ol Saint Callback
 *   - wmb
 **/
export const updateStCallback = (value, stateClone) => {
    const inputClone = {...stateClone.inputs}
    const field = {...inputClone.state};

    field.value = value;

    inputClone.state = field;
    stateClone.inputs = inputClone;

    return stateClone;
};

export default validateOnSubmit;
