import React from 'react'
import SkeletonText from '../../common/components/Skeleton/SkeletonText'
import { OpenNewWindow } from '../../common/Svgs'
import { LinkContainer } from './styles'

export const Link = ({
    newWindow = true,
    url,
    display,
    classNames = 'type-heavy type-blue type-large-body',
    context,
    loading,
}) => {
    let linkDisplay = display
    if (newWindow && typeof display === 'string') {
        linkDisplay = (
            <>
                {`${display} `}
                <span className="inline-icon inline-icon__middle inline-icon__20">
                    {OpenNewWindow}
                </span>
            </>
        )
    }
    if (loading) {
        return (
            <LinkContainer>
                <span className="type-large-body">
                    <SkeletonText width={400} />
                </span>
                <span className="type-normal-body">
                    <SkeletonText width={300} />
                </span>
            </LinkContainer>
        )
    }
    return (
        <LinkContainer>
            <a className={classNames} target={newWindow ? '_blank' : '_self'} href={url}>
                {linkDisplay}
            </a>
            {context && <span className="type-normal-body">{context}</span>}
        </LinkContainer>
    )
}
