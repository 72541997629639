import React, {Component} from "react";
import downloadJs from "downloadjs";
import PropTypes from 'prop-types';

import {IconExportSvg} from "./Svgs";
import LeadService from "../service/LeadService";
import LoadingGif from "./components/LoadingGif";

export default class ExportLeadsButton extends Component {
    LEAD_EXPORT_THRESHOLD_HIGH = 10000;
    LEAD_EXPORT_THRESHOLD_LIMIT = 50000;

    constructor(props) {
        super(props);

        this.state = {
            loadingExport: false
        };

        this.leadService = new LeadService();
    }

    componentWillUnmount() {
        this.leadService.cancelSignal.cancel();
    }

    exportLeads = () => {
        this.setState({loadingExport: true});
        this.leadService.export(this.props.apiQueryStr)
            .then((fileData: Object) => {
                downloadJs(fileData.contents, fileData.name, fileData.type);
                this.setState({loadingExport: false});
            });
    };

    render() {
        if (this.props.totalRows > this.LEAD_EXPORT_THRESHOLD_LIMIT) {
            return <span className="type-grey">
                <span className="inline-icon inline-icon__middle inline-icon__24">
                    {IconExportSvg}
                </span>
                Export Disabled for more than 50k records.
            </span>;
        }

        return this.state.loadingExport
            ? <LoadingGif />
            : <button className="button-clean type-large-body type-heavy type-blue type-align-left"
                      onClick={this.exportLeads}>
                <span className="inline-icon inline-icon__middle inline-icon__24">
                    {IconExportSvg}
                </span>{' '}
                Export Currently Filtered Leads to CSV
                {this.props.totalRows > this.LEAD_EXPORT_THRESHOLD_HIGH &&
                ' - Warning: more than 10k records. Export will take several minutes.'}
            </button>;
    }
}

ExportLeadsButton.propTypes = {
    totalRows: PropTypes.number,
    apiQueryStr: PropTypes.string
};
