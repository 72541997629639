import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import MajorAlert from './MajorAlert';
import { get } from '../Requests';

export default class MajorAlerts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            majorAlerts: {
                unpaid_invoice_ids: [],
                credit_card_expirations: [],
                contractor_paused_billing: false,
            }
        };

        this.cancelSignal = axios.CancelToken.source();
    }

    componentDidMount() {
        this.getMajorAlerts();
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
    }

    hasMajorAlerts = () => {
        return this.state.majorAlerts.unpaid_invoice_ids.length > 0 ||
            this.state.majorAlerts.credit_card_expirations.length > 0 ||
            this.state.majorAlerts.contractor_paused_billing
    };

    getMajorAlerts = () => {
        get('notifications/major_alerts', this.cancelSignal.token)
            .then((resp) => {
                if (!resp.data) {
                    return;
                }

                this.setState({majorAlerts: resp.data.data.major_alerts});
            });
    };

    /**
     * When the refreshCounter is incremented, re-load the Major Alerts.
     * This is used to e.g. hide Alerts that are no longer true, like statements that are now Paid.
     * @param prevProps
     */
    componentDidUpdate = (prevProps) => {
        if (this.props.refreshCounter > prevProps.refreshCounter) {
            this.getMajorAlerts();
        }
    };

    render() {
        let now = new Date();

        let campaignsPausedAlert = this.state.majorAlerts.contractor_paused_billing
            ? <MajorAlert alertText={<>
                Your Campaigns are paused due to Unpaid Statements. You must pay to resume service.{' '}
                <a href="/billing#billing-center-statements-table">View Statements →</a>
            </>}/>
            : '';

        let unpaidAlerts = this.state.majorAlerts.unpaid_invoice_ids.map((unpaidInvoiceId, key) => {
                let alertText = <span>
                        Statement {unpaidInvoiceId} is unpaid and due now. Please pay now to
                        avoid disruption of service.{' '}
                        <a href="/billing#billing-center-statements-table">
                            View Statement →
                        </a>
                    </span>;

                return <MajorAlert key={key} alertText={alertText} />;
            });

        let ccExpirationAlerts = this.state.majorAlerts.credit_card_expirations
                .map((creditCard, key) => {
                //
                // @todo Move this to a util function, and call if here, and
                // in CreditCardRow.js.
                //
                let expirationMessage,
                    cardExpireMonth = Number(creditCard.expiration_month) < 10
                        ? "0" + creditCard.expiration_month
                        : creditCard.expiration_month,
                    primaryText = creditCard.is_primary == 1
                        ? <b>Primary</b>
                        : '';

                /* check if the card has already expired */
                if (creditCard.expiration_year < now.getFullYear() ||
                    (creditCard.expiration_year == now.getFullYear() && creditCard.expiration_month <= now.getMonth())
                ) {
                    expirationMessage = ' has expired ';

                    /* only show expired error if it's the primary card */
                    if (creditCard.is_primary != 1) {
                        return null;
                    }
                }
                else {
                    expirationMessage = ' is near expiration ';
                }

                let alertText = (<span>
                    Your {primaryText} Payment Method ending in {creditCard.last_four}{' '}
                    {expirationMessage}
                    ({cardExpireMonth}/{creditCard.expiration_year}).
                    Please ensure your Payment Methods are up to date.{' '}
                    <a href="/billing#billing-center-credit-cards-table">
                        Review Payment Methods →
                    </a>
                    </span>
                );

                return <MajorAlert key={key} alertText={alertText} />;
            });

        if (this.hasMajorAlerts()) {
            return <div className="row padding-10-top page-width">
                <div className="col alpha omega grid-12">
                    { campaignsPausedAlert || unpaidAlerts }
                    { ccExpirationAlerts }
                </div>
                <div className="clear-block"></div>
            </div>;
        }
        else {
            return null;
        }
    }
}

MajorAlerts.propTypes = {
    refreshCounter: PropTypes.number
};
