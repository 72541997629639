import React, {useState} from 'react';

import Checkbox from '../../common/components/form_elements/Checkbox';
import Input from '../../common/components/form_elements/Input';
import AuthService from "../../service/AuthService";
import type {User} from "../../Interfaces/User";
import MultiSelectList from '../../common/components/form_elements/MultiSelectList';
import Validator from '../../lib/Validator';

const AddUserModal = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const timeZoneList = [...props.timeZones];
    const [emailAddress, setEmailAddress] = useState('');
    const [emailAddressErrorMessage, setEmailAddressErrorMessage] = useState('');
    const [name, setName] = useState('');
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [timeZoneId, setTimeZoneId] = useState('');
    const [timeZoneIdErrorMessage, setTimeZoneIdErrorMessage] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [phone, setPhone] = useState('');
    const [billingNotifications, setBillingNotifications] = useState(false);
    const [subAccountBillingNotifications, setSubAccountBillingNotifications] = useState(false);
    const [leadReviewNotifications, setLeadReviewNotifications] = useState(false);
    const [subAccountLeadReviewNotifications, setSubAccountLeadReviewNotifications] = useState(false);
    const [accountNotifications, setAccountNotifications] = useState(false);

    const changeEmailAddress = (emailAddress) => {
        setEmailAddress(emailAddress);
        setEmailAddressErrorMessage('');
    };
    const changeName = (name) => {
        setName(name);
        setNameErrorMessage('');
    };
    const handleSelectTimeZone = (values) => {
        setTimeZoneId(values[0]);
        setTimeZoneIdErrorMessage('');
    };

    const validate = (data: Partial<User>) => {
        let valid = true;
        if (!Validator.validateEmail(data.contactEmailAddress)) {
            setEmailAddressErrorMessage('Please fill in a valid Email Address');
            valid = false;
        }

        if (!data.name) {
            setNameErrorMessage('Please fill in a Name');
            valid = false;
        }

        if (!data.timeZoneId) {
            setTimeZoneIdErrorMessage('Please select a Time Zone');
            valid = false;
        }

        return valid;
    };

    const addUser = () => {
        const userProps: Partial<User> = {
            contactEmailAddress: emailAddress,
            name: name.trim(),
            timeZoneId,
            jobTitle,
            phoneNumber: phone,
            billingNotifications: Number(billingNotifications),
            leadReviewNotifications: Number(leadReviewNotifications),
            accountNotifications: Number(accountNotifications)
        };

        if (AuthService.isParentOrManager) {
            userProps.subaccountBillingNotifications = Number(subAccountBillingNotifications);
            userProps.subaccountLeadReviewNotifications = Number(subAccountLeadReviewNotifications);
        }

        if (!validate(userProps)) {
            return;
        }
        setSubmitting(true);

        props.addUser(userProps)
            .then(() => setSubmitting(false));
    };

    return <>
        <div className="spacing-30-bottom">
            <div className="popup__form__row spacing-20-bottom">
                <div
                    className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                    <Input
                        type="email"
                        label="Email *"
                        required={true}
                        value={emailAddress}
                        name="email_address"
                        subLabel="(will also be their Username)"
                        errorMessage={emailAddressErrorMessage}
                        onChange={(event) => changeEmailAddress(event.target.value)}
                    />
                </div>
                <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile popup__form__cell_start">
                    <p className="type-small-body type-narrow-line-height type-heavy spacing-10-bottom">
                        Password
                    </p>
                    <p className="type-small-body type-narrow-line-height">
                        This new user will receive an email invitation to set their own password.
                    </p>
                </div>
            </div>
            <p className="type-small-body type-narrow-line-height type-heavy spacing-10-bottom">
                Receive Notifications for
            </p>
            <div className="simpleflex__row simpleflex__row__wrap__mobile spacing-20-bottom">
                <div className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-20-bottom-mobile">
                    <Checkbox name="billing_notifications"
                              label="Billing"
                              checked={billingNotifications}
                              onChange={(event) => setBillingNotifications(event.target.checked)}/>
                    {AuthService.isParentOrManager &&
                    <Checkbox name="subaccount_billing_notifications"
                              label="Sub-Account Billing"
                              customClasses="spacing-10-top"
                              checked={subAccountBillingNotifications}
                              onChange={(event) => setSubAccountBillingNotifications(event.target.checked)}/>}
                </div>
                <div className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-20-bottom-mobile">
                    <Checkbox name="lead_review_notifications"
                              label="Lead Review"
                              checked={leadReviewNotifications}
                              onChange={(event) => setLeadReviewNotifications(event.target.checked)}/>
                    {AuthService.isParentOrManager &&
                    <Checkbox name="subaccount_lead_review_notifications"
                              label="Sub-Account Lead Review"
                              customClasses="spacing-10-top"
                              checked={subAccountLeadReviewNotifications}
                              onChange={(event) => setSubAccountLeadReviewNotifications(event.target.checked)}/>}
                </div>
                <div className="simpleflex__cell simpleflex__cell__full-width__mobile">
                    <Checkbox name="account_notifications"
                              label="Account"
                              checked={accountNotifications}
                              onChange={(event) => setAccountNotifications(event.target.checked)}/>
                </div>
            </div>
            <div className="popup__form__row spacing-20-bottom">
                <div
                    className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                    <Input
                        type="text"
                        label="Name *"
                        name="name"
                        value={name}
                        errorMessage={nameErrorMessage}
                        onChange={(event) => changeName(event.target.value)}
                        required={true}
                    />
                </div>
                <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile">
                    <Input
                        type="text"
                        label="Title / Role"
                        name="job_title"
                        value={jobTitle}
                        onChange={(event) => setJobTitle(event.target.value)}
                        required={false}
                        errorMessage=""/* keep this error message empty to align the job title and name fields */
                    />
                </div>
            </div>
            <div className="popup__form__row">
                <div
                    className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                    <Input
                        type="tel"
                        label="Phone"
                        name="phone"
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        required={false}
                        errorMessage=""/* keep this error message empty to align the phone and time zone fields */
                    />
                </div>
                <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile">
                    <MultiSelectList
                        required={true}
                        setGroups={true}
                        hasGroups={true}
                        useTextFilter={true}
                        searchFromBeginning={false}
                        label="Time Zone"
                        name="time-zone"
                        emptyLabel="Select Time Zone"
                        options={timeZoneList}
                        onChange={handleSelectTimeZone}
                        errorMessage={timeZoneIdErrorMessage}
                    />
                </div>
            </div>
        </div>
        <div className="ui-hide-mobile">
            <div className="popup__form__gray-bar padding-24">
                <div className="popup__form__row popup__form__row__slam-right">
                    <div className="popup__form__cell">
                        <button className="button ui-normal-button"
                                disabled={submitting}
                                onClick={() => addUser()}>
                            Add User
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="ui-hide-full ui-hide-tablet">
            <div className="popup__form__row padding-40-bottom">
                <button className="button ui-normal-button ui-full-width-button"
                        disabled={submitting}
                        onClick={() => addUser()}>
                    Add User
                </button>
            </div>
        </div>
    </>;
};

export default AddUserModal;
