import styled from 'styled-components'

export const HeaderLabel = styled.span`
    display: flex;
    /* flex-shrink: 1; */
    flex-grow: 1;
    align-items: center;
    font-weight: bold;
    color: #fff;
    height: 100%;
    white-space: pre-wrap;
    cursor: pointer;
`

export const ArrowWrapper = styled(HeaderLabel)`
    margin-left: 9px;
    height: 15px;
    width: 15px;
    flex-shrink: 0;
`

export const Column = styled.div`
    cursor: ${(props) => (props.selectable ? 'pointer' : 'default')};
    height: 100%;
`

export const MenuColumn = styled(Column)`
    overflow-x: visible;
    height: 100%;
    padding: 0px 10px;
    display: flex;
    align-items: center;
`
export const HeaderCell = styled(Column)`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
`

export const MarginPercent = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
`

export const Dot = styled.div`
    height: 17px;
    width: 17px;
    background-color: ${props => props.color};
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
`

export const SkeletonWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
`;

