import React, { Fragment, useContext, useEffect, useState } from 'react'

import ButtonContext from '../ButtonContext'
import MajorAlerts from '../../../common/MajorAlerts'
import Modal from '../../../common/Modal'
import { BottomToolbar, PageWrapper } from './styles'
import {
    getDIDNumbers,
    getDIDNumbersUI,
    saveDIDNumbers,
} from '../../../service/AffiliateService'
import CreateNewNumber from './Modals/CreateNewNumber'
import NumberConfirmation from './Modals/NumberConfirmation/NumberConfirmation'
import EditsInProgressModal from '../lead_sources/Modals/EditsInProgressModal'
import Toolbar from '../service_category_rules/Toolbar'
import { TableWrapper } from '../lead_sources/styles'
import LoadingGif from '../../../common/components/LoadingGif'
import DataTable from './DataTable'
import { orderBy } from 'lodash'
import ConfirmationSuccess from './Modals/NumberConfirmation/ConfirmationSuccess'
import ViewEditNumber from './Modals/ViewEditNumbers'
import EditConfirmation from './Modals/ViewEditNumbers/EditConfirmation'

export default function DIDHome() {
    // Modal state
    const [modalContent, setModalContent] = useState()
    const [modalTitle, setModalTitle] = useState('Set Up New DID Number(s)')

    // UI state
    const [affiliates, setAffiliates] = useState()
    const [industries, setIndustries] = useState()
    const [filters, setFilters] = useState([])
    const [filterValues, setFilterValues] = useState()
    const [filteredRows, setFilteredRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [sortColumn, setSortColumn] = useState()
    const [sortOrder, setSortOrder] = useState()
    const [newNumberTemplate, setNewNumberTemplate] = useState()

    // effect to set the button context in the main view
    const setButtonContext = useContext(ButtonContext)
    useEffect(() => {
        setButtonContext('Set Up New DID Numbers', () => {
            setModalTitle('Set Up New DID Number(s)')
            setModalContent(
                <CreateNewNumber
                    affiliates={affiliates}
                    serviceCategories={industries}
                    onPreview={handlePreviewNewNumbers}
                    onClose={() => setModalContent(null)}
                    newNumberTemplate={newNumberTemplate}
                />
            )
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affiliates, industries, newNumberTemplate])

    const fetchDIDData = () => {
        setIsLoading(true)
        getDIDNumbersUI()
            .then((data) => {
                const { filters, columns, sort, new_number_template } = data
                setFilters(filters)
                setColumns(columns)
                setFilteredRows(rows)
                setSortColumn(sort.column)
                setSortOrder(sort.order)
                setIndustries(filters.filter((f) => f.name == 'industry_ids')[0]?.values)
                setAffiliates(
                    filters.filter((f) => f.name == 'ad_network_ids')[0]?.values
                )
                setNewNumberTemplate(new_number_template)
                return getDIDNumbers()
            })
            .then((data) => {
                const { rows } = data
                setRows(rows)
                setFilteredRows(rows)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    // effect to fetch initial UI data and DID numbers
    useEffect(() => {
        fetchDIDData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleGoBack =
        ({ affiliate, action, ...args }) =>
        () => {
            if (action == 'edit') {
                setModalContent(
                    <ViewEditNumber
                        affiliates={affiliates}
                        selectedAffiliate={affiliate}
                        onPreview={handlePreviewEditNumbers}
                        onClose={() => setModalContent(null)}
                        newNumberTemplate={newNumberTemplate}
                        serviceCategories={industries}
                        {...args}
                    />
                )
            } else {
                setModalContent(
                    <CreateNewNumber
                        affiliates={affiliates}
                        selectedAffiliate={affiliate}
                        serviceCategories={industries}
                        onPreview={handlePreviewNewNumbers}
                        onClose={() => setModalContent(null)}
                        newNumberTemplate={newNumberTemplate}
                        {...args}
                    />
                )
            }
        }

    // this function is called on the confirmation modal when the user
    // clicks the "Confirm" button
    const handleConfirmNewNumbers = ({
        affiliate,
        changes,
        changedIds,
        newNumbers,
        deletedNumberIds,
    }) => {
        // post the numbers, await a response?
        setModalContent(<EditsInProgressModal />)
        let updates = []
        // if these are new numbers, add the affiliate to the updates
        newNumbers &&
            newNumbers.forEach((c) => {
                c['ad_network_id'] = affiliate.value
                updates.push(c)
            })
        // if there are updates, add the update action
        changes &&
            changes
                .filter((c) => changedIds.includes(c.did_phone_number_id))
                .forEach((c) => {
                    c['action'] = 'update'
                    updates.push(c)
                })
        // if there are deletes, add the delete action
        changes &&
            changes
                .filter((c) => deletedNumberIds.includes(c.did_phone_number_id))
                .forEach((c) => {
                    c['action'] = 'delete'
                    updates.push(c)
                })

        saveDIDNumbers({ updates })
            .then((response) => {
                // refetch data to update the UI
                setModalContent(
                    <ConfirmationSuccess
                        serviceCategories={industries}
                        numbers={response.data}
                        affiliate={affiliate}
                        changedIds={changedIds}
                        onClose={() => setModalContent(null)}
                    />
                )
                return getDIDNumbers()
            })
            .then((data) => {
                const { rows } = data
                setRows(rows)
                setFilteredRows(rows)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    // this function is called on the first modal when the user
    // clicks the "Preview Changes" button for new numbers
    const handlePreviewNewNumbers = (args) => {
        setModalContent(
            <NumberConfirmation
                onConfirm={handleConfirmNewNumbers}
                onGoBack={handleGoBack}
                {...args}
            />
        )
    }

    // this function is called on the first modal when the user
    // clicks the "Preview Changes" button for new numbers
    const handlePreviewEditNumbers = (args) => {
        setModalContent(
            <EditConfirmation
                onConfirm={handleConfirmNewNumbers}
                onGoBack={handleGoBack}
                {...args}
            />
        )
    }

    const handleApplyFilters = (filterVals) => {
        setFilterValues(filterVals)
        // update the filtered rows
        // compute the filtered rules
        let rulesClone = [...rows]
        // filter the rules based on service category (industry id)
        // but require at least one value to be set
        if (filterVals?.industry_ids && filterVals?.industry_ids?.length > 0) {
            rulesClone = rulesClone.filter((r) =>
                filterVals?.industry_ids.includes(r.industry_id)
            )
        }
        // filter the rules based on lead source (ad network)
        // but require at least one value to be set
        if (filterVals?.ad_network_ids && filterVals?.ad_network_ids?.length > 0) {
            rulesClone = rulesClone.filter((r) =>
                filterVals?.ad_network_ids.includes(r.ad_network_id)
            )
        }
        // setFilteredRules(rulesClone)
        setFilteredRows(rulesClone)
    }

    const handleOrderBy = (property) => {
        let order = sortOrder
        // if clicking the same column
        if (sortColumn === property) {
            if (sortOrder === 'asc') {
                order = 'desc'
            } else {
                order = 'asc'
            }
        } else {
            // if the column is new or different, set to desc
            order = 'desc'
        }
        setSortColumn(property)
        setSortOrder(order)
        // update the filtered rows to the correct order
        setFilteredRows(orderBy(filteredRows, property, order))
    }

    const handleClickAffiliate = (id) => {
        const affiliate = affiliates.filter((a) => a.value == id)[0]
        // get numbers for the affiliate, pass to modal
        setModalTitle(`DID Numbers for ${affiliate.text}`)
        getDIDNumbers({ ad_network_ids: [id] }).then((data) => {
            setModalContent(
                <ViewEditNumber
                    affiliates={affiliates}
                    selectedAffiliate={affiliate}
                    onPreview={handlePreviewEditNumbers}
                    onClose={() => setModalContent(null)}
                    changes={data.rows}
                    newNumberTemplate={newNumberTemplate}
                    serviceCategories={industries}
                />
            )
        })
    }

    const dataDisplay = isLoading ? (
        <LoadingGif />
    ) : columns == null ? (
        <div>No data</div>
    ) : (
        <DataTable
            rows={filteredRows}
            columns={columns}
            onEditHandler={handleClickAffiliate}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            onOrderBy={handleOrderBy}
        />
    )

    return (
        <Fragment>
            <MajorAlerts />
            <Modal
                content={modalContent}
                header={modalTitle}
                onCloseButton={() => setModalContent(null)}
                updateModalContent={() => setModalContent(null)}
                flat={true}
                hideCloseButton={false}
                width="extra_wide"
            />
            <PageWrapper>
                <Toolbar
                    filters={filters}
                    filterValues={filterValues}
                    resultsCount={filteredRows?.length}
                    onApplyFilters={handleApplyFilters}
                    label="DID Number"
                />
                <TableWrapper border={false}>{dataDisplay}</TableWrapper>
                <BottomToolbar>
                    {/* <ExportCSV
                        dataGetter={() => null}
                        downloadPrefix="did_numbers_"
                        isDisabled={true}
                    /> */}
                </BottomToolbar>
            </PageWrapper>
        </Fragment>
    )
}
