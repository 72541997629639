import styled from "styled-components";

export const SectionContent = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 20px 0;
  /* &:last-child {
    margin-bottom: 0px;
  } */
`

export const SectionContentCenter = styled(SectionContent)`
  justify-content: center;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: ${props => props.border == false ? 'none' : 'solid 1px #d1d9e2'};
  padding: 1.7857143%;
  margin: 10px 0;
  &:first-child {
    margin-top: 0px;
  }
  width: 100%;
`

export const SectionGrow = styled(Section)`
  flex: 1;
`;

export default Section