import React from 'react';
import PropTypes from 'prop-types';

import { IconSearchNoCircleSvg } from '../../Svgs';

const SearchField = props => {
    let searchFieldClasses = 'searchfield';
    searchFieldClasses += props.popup ? ' searchfield__popup-variant' : '';
    searchFieldClasses += props.rightAlign ? ' right-align' : '';
    searchFieldClasses += props.allowHighlight && props.value !== '' ? ' ui-highlight' : '';
    searchFieldClasses += props.wide ? ' wide' : '';

    /**
     * 2 ways of handling submissions via the SearchField
     *   1. onChange, which activates each keypress
     *   2. onFormSubmit, which wraps a <form> around and uses its submit event
     *
     * Note that onButtonClick handler only works for (1)
     */
    let input = (
        <div className="searchfield__input">
            <input
                type="text"
                placeholder={props.placeholder || "Search by Keyword"}
                ref={props.searchFieldRef}
                value={props.value}
                onChange={props.onChange}
                id={props.id}
            />
        </div>
    );

    let buttonLabel = props.textLabel
        ? "Search"
        : IconSearchNoCircleSvg;

    if (typeof props.onFormSubmit === 'function') {
        return (
            <form onSubmit={props.onFormSubmit}>
                <div className={searchFieldClasses}>
                    {input}
                    <div className="searchfield__button">
                        <button className="ui-small-button" type="submit">
                            { buttonLabel }
                        </button>
                    </div>

                    {props.tooltip &&
                    <div className="tooltip-wrap">{props.tooltip}</div>}
                </div>
            </form>
        );
    }
    else {
        return (
            <div className={searchFieldClasses}>
                {input}
                <div className="searchfield__button">
                    <button className="ui-small-button" onClick={props.onButtonClick}>
                        { buttonLabel }
                    </button>
                </div>

                {props.tooltip &&
                <div className="tooltip-wrap">{props.tooltip}</div>}
            </div>
        );
    }
};

export default SearchField;

SearchField.propTypes = {
    popup: PropTypes.bool,
    rightAlign: PropTypes.bool,
    allowHighlight: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    searchFieldRef: PropTypes.object,
    onChange: PropTypes.func,
    id: PropTypes.string,
    textLabel: PropTypes.bool,
    onFormSubmit: PropTypes.func,
    onButtonClick: PropTypes.func,
    tooltip: PropTypes.object
};
