import React from 'react';

import CallOutBox from './CallOutBox';
import CoreSection from './CoreSection';
import ExpandableSection from './ExpandableSection';
import ImageBox from './ImageBox';
import QuizSection from './QuizSection';
import Sidebar from './sidebar';
import WistiaPlayer from '../common/WistiaPlayer';

const StepFive = (props) => {
	return (
		<div className="onboarding-contents page-width">
			<Sidebar step={5} {...props} />
			
			<div className="onboarding-main-column">
				<CoreSection
					headerLabel="Section 5"
					header="The mySD Platform"
				>
					<p className="type-normal-subhead type-narrow-line-height spacing-40-bottom">
						The mySD Online Platform is your home base to manage your Campaigns, Leads, Billing, and other Account settings. Let’s review the most common features.
					</p>
					
					<WistiaPlayer
						videoId="uqwaltlupd"
						wrapper="wistia-player-1"
					/>
				</CoreSection>
				
				<ExpandableSection
					header="mySD Lead Manager"
				>
					<div className="onboarding__columns-block spacing-40-bottom">
						<div className="column">
							<ul className="type-large-body type-narrow-line-height">
								<li>
									See and manage all your Billable and Non-Billable Leads across all your Campaigns.
								</li>
								<li>
									Find Leads quickly using Filters and Search.
								</li>
								<li>
									Click any Lead Row to expand it for Detail View:
									<ul className="type-large-body type-narrow-line-height">
										<li>
											Listen to Phone Call Lead recordings, and view Form Lead messages.
										</li>
										<li>
											Track Lead Progress, including Call Answered, Booked Appointment, Job Won, and Revenue info to help see the ROI of your Campaigns.
										</li>
										<li>
											Star or mark Leads for Follow-Up.
										</li>
										<li>
											See the Lead’s Billable Status or Submit Leads for Review.
										</li>
										<li>
											See Lead History notes or add your own.
										</li>
									</ul>
								</li>
							</ul>
						</div>
						<div className="column">
							<ImageBox
								url="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Onboarding%20Screenshots/mySD-LeadManager-v12-Marketplace-KB-Expanded-PhoneCall.jpg"
								alt="Screenshot of mySD Lead Manager"
								caption="mySD Lead Manager"
								showPopup={props.showPopup}
							/>
						</div>
					</div>
					
					<CallOutBox
						headline="Learn More"
					>
						<ul className="type-large-body type-narrow-line-height">
							<li>
								Read our Help Center article <a href="https://support.servicedirect.com/lead-manager" target="_blank" className="type-heavy">Using the Lead Manager</a>
							</li>
						</ul>
					</CallOutBox>
				</ExpandableSection>
				
				<ExpandableSection
					header="mySD Campaigns Manager"
				>
					<div className="onboarding__columns-block">
						<div className="column">
							<h4 className="type-large-subhead type-heavy spacing-24-bottom">
								Campaign Quick View
							</h4>
							
							<ul className="type-large-body type-narrow-line-height">
								<li>
									In the Campaigns Manager, each Campaign has an individual Quick View row where you can see the most important information and settings for that particular Campaign, including:
									<ul className="type-large-body">
										<li>
											Campaign Name and Tracking Number.
										</li>
										<li>
											Campaign Status and Update Campaign Status link to Pause or Enable your Campaign.
										</li>
										<li>
											Leads Received All Time and Last 30 Days.
										</li>
										<li>
											Missed Opportunities of Leads in the Last 7 Days in your Service Category and Service Area.
										</li>
										<li>
											Phone Leads Cost Per Lead and Edit link to increase or decrease your CPL.
										</li>
										<li>
											Service Area Zip Codes abbreviated list and Edit link to see all zips and make changes.
										</li>
										<li>
											Lead Delivery & Notifications Settings and Edit link to see settings and make changes.
										</li>
									</ul>
								</li>
							</ul>
						</div>
						<div className="column">
							<ImageBox
								url="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Onboarding%20Screenshots/mySD-Campaigns-Manager-v10-MP-HomeServices.jpg"
								alt="Screenshot of mySD Campaigns Manager"
								caption="mySD Campaigns Manager"
								showPopup={props.showPopup}
							/>
						</div>
					</div>
					
					<hr className="spacing-40" />
					
					<div className="onboarding__columns-block">
						<div className="column">
							<h4 className="type-large-subhead type-heavy spacing-24-bottom">
								Pause or Enable Campaigns
							</h4>
							
							<ul className="type-large-body type-narrow-line-height">
								<li>
									The Campaign Status indicates whether or not a given Campaign is currently being advertised and competing for Leads. The Campaign Status can either be Enabled, Paused, or Canceled.
								</li>
								<li>
									To make changes to the status of a Campaign, click the "Update Status" button in the Campaign's Quick View.
								</li>
							</ul>
						</div>
						<div className="column">
							<ImageBox
								url="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Onboarding%20Screenshots/mySD-Campaigns-Manager-v10-MP-CampaignStatus.jpg"
								alt="Screenshot of mySD Campaigns Manager with Campaign Status area highlighted"
								caption="mySD Campaigns Manager — Campaign Status"
								showPopup={props.showPopup}
							/>
						</div>
					</div>
					
					<hr className="spacing-40" />
					
					<div className="onboarding__columns-block">
						<div className="column">
							<h4 className="type-large-subhead type-heavy spacing-24-bottom">
								Adjust Cost Per Lead (CPL)
							</h4>
							
							<ul className="type-large-body type-narrow-line-height">
								<li>
									A Campaign's Cost Per Lead (CPL) determines how much you are willing to pay for each Billable Lead.
								</li>
								<li>
									In general, increasing the CPL results in an increase in Lead Volume, while decreasing will reduce the number of Leads you can expect to receive. A recommended range will be displayed next to the Cost Per Lead field for each Service Category.
								</li>
								<li>
									To adjust your Cost Per Lead, click the Edit link in the Phone Leads or Forms Leads sections in Campaign Quick View.
								</li>
							</ul>
						</div>
						<div className="column">
							<ImageBox
								url="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Onboarding%20Screenshots/mySD-Campaigns-Manager-v10-MP-CostPerLead.jpg"
								alt="Screenshot of mySD Campaigns Manager with Cost Per Lead section highlighted"
								caption="mySD Campaigns Manager — Adjust Cost Per Lead"
								showPopup={props.showPopup}
							/>
						</div>
					</div>
					
					<hr className="spacing-40" />
					
					<div className="onboarding__columns-block">
						<div className="column">
							<h4 className="type-large-subhead type-heavy spacing-24-bottom">
								Adjust Service Area
							</h4>
							
							<ul className="type-large-body type-narrow-line-height">
								<li>
									Your Service Area is the Zip/Postal Codes where your Campaigns are being advertised to New Potential Customers. The broader your Service Area, the more Leads you can compete for.
								</li>
								<li>
									In the Service Area section of your Campaign Quick View, you can review or change the Zip/Postal Codes with our map tool in the popup.
								</li>
							</ul>
						</div>
						<div className="column">
							<ImageBox
								url="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Onboarding%20Screenshots/mySD-Campaigns-Manager-v10-MP-ServiceArea.jpg"
								alt="Screenshot of mySD Campaigns Manager with Service Area highlighted"
								caption="mySD Campaigns Manager — Adjust Service Area"
								showPopup={props.showPopup}
							/>
						</div>
					</div>
					
					<hr className="spacing-40" />
					
					<div className="onboarding__columns-block">
						<div className="column">
							<h4 className="type-large-subhead type-heavy spacing-24-bottom">
								Lead Delivery & Notification Settings
							</h4>
							
							<ul className="type-large-body type-narrow-line-height">
								<li>
									The Lead Delivery & Notifications section displays the phone number and email address that your Leads are delivered to and also shows your Caller ID and SMS notification settings.
								</li>
								<li>
									These are editable by clicking the “Edit” link.
								</li>
							</ul>
						</div>
						<div className="column">
							<ImageBox
								url="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Onboarding%20Screenshots/mySD-Campaigns-Manager-v10-MP-LeadDeliveryNotifications.jpg"
								alt="Screenshot of mySD Campaigns Manager with Lead Delivery & Notifications section highlighted"
								caption="mySD Campaigns Manager — Lead Delivery & Notifications"
								showPopup={props.showPopup}
							/>
						</div>
					</div>
					
					<CallOutBox
						headline="Learn More"
						extraClasses="spacing-40-top"
					>
						<ul className="type-large-body type-narrow-line-height">
							<li>
								Read our Help Center article <a href="https://support.servicedirect.com/campaigns-manager" target="_blank" className="type-heavy">Using the Campaigns Manager</a>
							</li>
						</ul>
					</CallOutBox>
				</ExpandableSection>
				
				<ExpandableSection
					header="mySD Users, Support, and Billing"
				>
					<ul className="type-large-body type-narrow-line-height">
						<li>
							In the mySD Main Navigation’s “More” tab, you can find Users, Support, and Billing links.
						</li>
						<li>
							<span className="type-heavy">Users</span> allows you to manage adding and editing other Users on your Account.
						</li>
						<li>
							<span className="type-heavy">Support</span> takes you to our Knowledge Base that features searchable Articles and Videos as well as a way to easily contact us via a Support Ticket.
						</li>
						<li>
							<span className="type-heavy">Billing</span> takes you to the mySD Billing Center where you can manage your Credit Card Payment Methods, see your Statements, and find info about your Billing Cycle, Billing Threshold, and more.
						</li>
					</ul>
					
					<CallOutBox
						headline="Learn More"
						extraClasses="spacing-40-top"
					>
						<ul className="type-large-body type-narrow-line-height">
							<li>
								Visit the <a href="https://support.servicedirect.com/" target="_blank" className="type-heavy">Service Direct Help Center</a>
							</li>
						</ul>
					</CallOutBox>
				</ExpandableSection>
				
				<QuizSection
					{...props}
					questions={[
						{
							name: "step-5-question-1",
							question: "What does the mySD Platform do?",
							answers: [
								"Lets me see and manage my Leads",
								"Lets me see and manage my Campaigns",
								"Lets me track Lead Progress to see Return on Investment",
								"All of the above",
							],
							correctAnswer: 4,
						},
						{
							name: "step-5-question-2",
							question: "Where do I change my Cost Per Lead or Pause a Campaign?",
							answers: [
								"In the mySD Lead Manager",
								"In the mySD Campaigns Manager",
								"In the mySD Billing Center",
								"I can’t change the Cost Per Lead",
							],
							correctAnswer: 2,
						},
						{
							name: "step-5-question-3",
							question: "How do I get help using the mySD Platform?",
							answers: [
								"Using the question mark icons located in many parts of mySD",
								"Watching the Video Library in the Help Center",
								"Reading step by step articles in the Help Center",
								"Asking questions in the Support Chat",
								"Open a Support Ticket to message our support team directly",
								"All of the above",
							],
							correctAnswer: 6,
						}
					]}
				/>
			</div>
		</div>
	);
};

export default StepFive;