import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import Header from './Header';
import CreateClient from './clients/SignUpNewClient/CreateClient';
import AdminLeadManagerView from './lead_manager/AdminLeadManagerView';
import SignupAndLaunchStatus from './clients/signup_status/SignupAndLaunchStatus';
import YourCompany from "../client/signup/your_company/YourCompany";
import Industries from "./industries/Index";
import IndustryDetail from "./industries/IndustryDetail";
import MpTargetedZips from "./industries/MpTargetedZips";
import MapSandbox from "./MapSandbox";
import AdminHome from "./AdminHome";
import ContractorsView from "./contractors/ContractorsView";
import TagManager from "react-gtm-module";
import MarketplaceCampaignOptimizer from './mp_campaign_optimizer';
import LeadSources from './lead_sources'
import UsersAdminView from "./users/UsersAdminView";
import EmailsView from "./emails/EmailsView";
import QADashboard from './qa_dashboard';
import ExternalBuyersView from "./external_buyers/ExternalBuyersView";
import CreateTestClient from "./CreateTestClient";

export default class AdminApp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            header: {
                newLeadCount: 0,
                notificationCount: 0,
                lastUpdate: '',
            },
            leads: [],
        };

        this.secondaryNavRef = React.createRef();
    }

    componentDidMount() {
        this.setGoogleTagManager();
    }

    setGoogleTagManager = () => {

        // This must be a loose check, not string (===), b/c the config value is a string, not an int.
        if (process.env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER == 0) {
            return;
        }

        const tagManagerArgs = {
            dataLayer: {
                blockHubSpotTracker: true
            }
        };
        TagManager.dataLayer(tagManagerArgs);
    };

    render() {
        return <div>
            {this.props.children}
            <Header
                whiteLabelPartnership={this.props.whiteLabelPartnership}
                headerValues={this.state.header}
                secondaryNavRef={this.secondaryNavRef}
            />
            <Switch>
                <Route path="/create-client/:contractor_id?">
                    <CreateClient {...this.props} updateMessageBlocks={this.props.updateMessageBlocks}/>
                </Route>
                <Route path="/account-activations/:contractor_id/campaign-setup">
                    <div className="interface-container">
                        <section className="white-background">
                            <div className="page-width">
                                <div className="row padding-50-top padding-20-bottom">
                                    <YourCompany {...this.props}
                                                 whiteLabelPartnership={this.props.whiteLabelPartnership}
                                                 updateMessageBlocks={this.props.updateMessageBlocks}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </Route>
                <Route
                    path="/leads"
                    render={(props) => <AdminLeadManagerView
                        {...props}
                        updateMessageBlocks={this.props.updateMessageBlocks}
                        secondaryNavRef={this.secondaryNavRef}
                    />}
                />
                <Route path="/signup-and-launch-status">
                    <SignupAndLaunchStatus {...this.props} updateMessageBlocks={this.props.updateMessageBlocks}/>
                </Route>
                <Route path="/industries/mp-targeted-zips">
                    <MpTargetedZips/>
                </Route>
                <Route path="/industries/:industry_id">
                    <IndustryDetail {...this.props} updateMessageBlocks={this.props.updateMessageBlocks}/>
                </Route>
                <Route path="/industries">
                    <Industries {...this.props} updateMessageBlocks={this.props.updateMessageBlocks}/>
                </Route>
                <Route path="/admin/map-sandbox">
                    <MapSandbox/>
                </Route>
                <Route path="/contractors">
                    <ContractorsView/>
                </Route>
                {/* marketplace-campaign-editor is deprecated, using 'optimizer' now */}
                <Route path="/marketplace-campaign-editor">
                    <Redirect to={{
                        pathname: "/marketplace-campaign-optimizer",
                        search: this.props.history?.location?.search,
                    }} />
                </Route>
                <Route path="/marketplace-campaign-optimizer">
                    <MarketplaceCampaignOptimizer/>
                </Route>
                <Route path="/admin/lead-sources/:tab?">
                    <LeadSources {...this.props} updateMessageBlocks={this.props.updateMessageBlocks}/>
                </Route>
                <Route path="/admin/external-buyers">
                    <ExternalBuyersView {...this.props} updateMessageBlocks={this.props.updateMessageBlocks}/>
                </Route>
                <Route path="/admin/users">
                    <UsersAdminView updateMessageBlocks={this.props.updateMessageBlocks}/>
                </Route>
                <Route path="/admin/emails">
                    <EmailsView/>
                </Route>
                <Route path="/admin/qa-dashboard">
                    <QADashboard/>
                </Route>
                <Route path="/admin/create-test-client">
                    <CreateTestClient/>
                </Route>
                <Route path="/">
                    <AdminHome/>
                </Route>
            </Switch>
        </div>;
    }
}
