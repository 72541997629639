import React from 'react'
import styled from 'styled-components'

const DividerWrapper = styled.div`
    display: flex;
    width: 100%;
    align-content: stretch;
    margin: 10px 0 20px 0;
    align-items: center;
    `
const Divider = styled.div`
    /* border-top: solid 1px lightgrey; */
    border-top: none;
    display: flex;
    flex-grow: 1;
`
const DividerLabel = styled.div`
    margin-right: 15px;
    display: flex;
`

export default ({
    label
}) => {
    return (
        <DividerWrapper>
            {label && <DividerLabel>
                <span className='type-large-body-special'>{label}</span>
            </DividerLabel>}
            <Divider />
        </DividerWrapper>
    )
}