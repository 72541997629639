import axios, {AxiosResponse} from "axios";
import type {CancelTokenSource} from 'axios';

import {post} from '../Requests';
import {toCamelObject} from "../Util";
import AuthService from "./AuthService";

export interface AuthData {
    accessLevelName: string;

    userId: number;
    username: string;
    timeZoneId: number;
    timeZone: string;
    isSignup: boolean;
    isOnboarding: boolean;
    isAwaitingOnboarding: boolean;
	dateCompletedSignup: string;

    contractorId?: number;
    parentContractorId?: number;
    isParentContractor?: boolean;
    isManagerContractor?: boolean;
    completedOnboarding?: boolean;
    requestedOnboarding?: boolean;
    blockLeadSubmissions?: boolean;
    contractorType?: string;
    contractorName?: string;

    location?: string;
    signupMarketplaceId?: number;
    authenticated?: boolean;
    token?: string;

    canceledSsOnboarding?: boolean;
    onboardingStep1Complete?: boolean;
    onboardingStep2Complete?: boolean;
    onboardingStep3Complete?: boolean;
    onboardingStep4Complete?: boolean;
    onboardingStep5Complete?: boolean;
    onboardingStep6Complete?: boolean;

    partnership?: Object;

    assistingAdminUserId?: number;

    // the id of the admin who logged in as the current user session; if there is one
    vicariousAdminId?: number;

    // whether the logged-in user is a developer or not
    isDeveloper: boolean;

    // has the contractor Quit?
    hasQuit?: boolean;
}

export interface TokenPayload {
    iss: string; // issuer. Who's issuing this key? perhaps "service_direct_api", etc.
    aud: string; // audience. Who's receiving this key?  "admin_app", "client_app", etc.
    iat: number; // issued at. What time was this token issued?
    nbf: number; // not-before timestamp. As in "this token is valid, if it's not used before this timestamp"
    exp: number; // expires. When the token expires

    userId: number; // the ID of the user to whom the token belongs to
    isDeveloper: boolean; // whether the user is a developer or not
    vicariousAdminId: number; // the vicarious admin user id; the admin who is actually using the token in lieu of the user
    contractorId: number; // the ID of the contractor associated with the user
    accessLevelId: number; // the ID of the user's access level
    parentContractorId: number; // the ID of the parent contractor to the contractor
    isParentContractor: boolean; // whether the user is a parent contractor
    isManagerContractor: boolean; // whether the user is a manager contractor
    whiteLabelPartnershipId: number; // the while label partnership ID; used for Nexstar, FCI, etc.
    environment: string; // the environment in which the token was generated; e.g. "dev", "app2", etc.
}

export const AccessLevelNames = {
    SuperUser: 'superuser',
    Admin: 'admin',
    Client: 'client',
    LeadReviewer: 'lead reviewer',
    Accountant: 'accountant',
    HomeSquire: 'HomeSquire',
};

export default class TokenService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal: CancelTokenSource;

    /** @member {boolean} set to true when in the process of refreshing the token */
    refreshingToken = false;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    /**
     * performs a login attempt
     * @param {string} username the "username", actually their email address
     * @param {string} password the user's password
     * @param {boolean} [isAdmin = false] if set to true, will attempt to log in via admin
     * @param {number} [vicariousAdminId = 0] if an admin id is passed, will be associated to the JWT
     * @return {Promise<AuthData>}
     */
    login = (username: string, password: string, isAdmin: boolean = false, vicariousAdminId: number = 0): Promise<AuthData> => {
        let data = {username};

        // if this request is coming from the admin, the API expects the pass_word parameter
        if (isAdmin) {
            data.pass_word = password;
            data.vicarious_admin_id = vicariousAdminId;
        }
        else {
            data.password = password;
        }

        return post('auth', data, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));
    };

    /**
     * logs the user out of the system
     * @return {Promise<AxiosResponse<T>>}
     */
    logout = () =>
        post("auth/logout")
            .then(() => {
                if (process.env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER == 1) {
                    window.dataLayer.push(function () {
                        this.reset();
                    })
                }
                AuthService.clear();
                window.location = "/";
            });

    static getTokenPayload(token: string): TokenPayload {
        const tokenParts = token.split('.');
        token = tokenParts[1];
        token = atob(token);
        return toCamelObject(JSON.parse(token));
    }

    /**
     * returns the token issued at timestamp
     * @param {string} token - the JWT
     * @return {number} - the expiration timestamp of the token
     */
    getTokenIssuedAt(token: string) {
        const payload = TokenService.getTokenPayload(token);

        return payload.iat * 1000;
    }

    /**
     * get the billing summary of a contractor
     * @param {AxiosResponse} mainResponse
     * @param {boolean} forceRefresh
     * @return {Promise<AxiosResponse<any> | boolean>}
     */
    refreshToken = (mainResponse: AxiosResponse, forceRefresh = false) => {
        let token = AuthService.token;
        let responseHasToken;

        // check if the mainResponse has a token but ignore exceptions
        try {
            responseHasToken = !!mainResponse.data.token;
        } catch (ignore) {
        }

        // refresh the token if needed
        if (token && (!responseHasToken || forceRefresh)) {
            let tokenIssuedAt = this.getTokenIssuedAt(token);
            let hoursSinceIssuing = (Date.now() - tokenIssuedAt) / 3600000;

            // if there's less than (...LEEWAY_HOURS) since the token was issued, refresh it
            if (hoursSinceIssuing > process.env.REACT_APP_TOKEN_REFRESH_LEEWAY_IN_HOURS || forceRefresh) {
                if (!this.refreshingToken) {
                    this.refreshingToken = true;
                    return post('auth/refresh', {vicarious_admin_id: AuthService.vicariousAdminId})
                        .then((resp) => {
                            this.refreshingToken = false;
                            return toCamelObject(resp.data.data);
                        });
                }
            }
        }

        return Promise.reject();
    };
}