import React from "react";
import PropTypes from 'prop-types';

export const Checkbox = props => {
    let checkboxClass = 'checkbox-container';

    if (props.locked) {
        checkboxClass += " checkbox-container__locked";
    }

    if (props.hasError || props.errorMessage) {
        checkboxClass += " ui-text-field ui-checkbox-alert";
    }

    if (props.customClasses) {
        checkboxClass += " " + props.customClasses;
    }

    return <div className={checkboxClass} ref={props.inputRef}>
        <input
            type="checkbox"
            name={props.name}
            id={props.name}
            checked={props.checked}
            defaultChecked={props.defaultChecked}
            onChange={props.onChange}
            disabled={props.disabled}
        />
        <label htmlFor={props.name}>
            <span className={'checkbox' + (props.partiallyChecked ? ' partially-checked' : '')}>
                {props.label}
                {props.tooltip &&
                    <> {props.tooltip}</>}
            </span>
        </label>
        {props.errorMessage !== undefined &&
            <div className="input-error">{props.errorMessage}</div>}
    </div>;
};

export default Checkbox;

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    // when checked and partiallyChecked are set to true, the dash symbol will be displayed instead of the checkmark
    partiallyChecked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    tooltip: PropTypes.object,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    inputRef: PropTypes.object
};
