import React from "react";

import { TopArrowSvg } from "../../common/Svgs";

export default (props) => (
  <div ref={props.secondaryNavRef} className="secondary-nav">
    <div className="ui-hide-mobile">
      <div className="secondary-nav-container page-width-wide">
        <div className="wide-format-col secondary-nav-contents">
          {props.filterMessage && (
            <div className="type-normal-body type-narrow-line-height padding-20">
              <span className="type-right-side-bump">
                {props.filterMessage}
              </span>
            </div>
          )}
          <div className="type-normal-body type-narrow-line-height padding-20">
            <a
              href="*"
              className="type-heavy"
              onClick={(e) => {
                e.preventDefault();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <span className="inline-icon inline-icon__middle inline-icon__24">
                {TopArrowSvg}
              </span>{" "}
              Top of Page
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="ui-hide-full ui-hide-tablet">
      <div className="secondary-nav-container-mobile">
        <div className="secondary-nav-contents-mobile type-normal-body type-narrow-line-height">
          <a
            href="*"
            className="type-heavy"
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <span className="inline-icon inline-icon__middle inline-icon__24">
              {TopArrowSvg}
            </span>{" "}
            Top of Page
          </a>
        </div>
      </div>
    </div>
  </div>
);
