import React, { memo, useEffect, useState } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import {
    DropdownWrapper,
    InputSpan,
    InputsWrapper,
    InputWrapper,
    Wrapper,
} from './styles'
import MultiSelectList from '../MultiSelectList'
import { formatCurrency, formatNumber } from '../../../../Util'

const RangeInput = (props) => {
    const {
        min,
        max,
        rangeMin = 0,
        rangeMax = 100,
        rangeStep = 1,
        onChange,
        format = 'number',
        formatSymbol = '$',
    } = props
    const [minVal, setMinVal] = useState(min || rangeMin)
    const [maxVal, setMaxVal] = useState(max || rangeMax)
    const [inputVals, setInputVals] = useState({
        min: min || rangeMin,
        max: max || rangeMax,
    })
    // effect to update the state vals whenever new props are passed
    useEffect(() => {
        setMinVal(min || rangeMin)
        setMaxVal(max || rangeMax)
        setInputVals({
            min: min || rangeMin,
            max: max || rangeMax,
        })
    }, [max, min, rangeMax, rangeMin])

    const handleSliderChange = ([min, max]) => {
        setMinVal(min)
        setMaxVal(max)
        setInputVals({ min, max })
        onChange && onChange([min, max])
    }

    const handleInputBlur = (name) => (e) => {
        let value = e.currentTarget.textContent
        // validate the input
        const regex = /^-?\d*\.?\d{0,2}$/
        let match = value.match(regex)
        if (!match) {
            return false
        }
        value = Number(value)
        setInputVals({ ...inputVals, [name]: value })
        if (name === 'min' && value <= maxVal) {
            setMinVal(value)
            onChange && onChange([value, maxVal])
        } else if (name === 'max' && value >= minVal) {
            setMaxVal(value)
            onChange && onChange([minVal, value])
        }
    }

    const handleInputKeyDown = (name) => (e) => {
        const { key } = e
        const allowedKeys = ['Backspace', 'ArrowRight', 'ArrowLeft', 'Tab', '.', 'Delete', '-']

        // if enter is pressed, treat it like the user is confirming the value
        if (key === 'Enter') {
            e.preventDefault()
            e.target.blur()
            return
        }

        // ignore non numeric keys
        if (!allowedKeys.includes(key) && !isFinite(key)) {
            e.preventDefault()
            // e.target.blur()
            return
        }
    }

    const currencyAdornment = <span className="type-normal-body">{formatSymbol}</span>
    const percentAdornment = <span className="type-normal-body">%</span>

    const formatVal = (val) => {
        // return formatNumber(val, 0)
        return val
    }

    return (
        <Wrapper>
            <Slider
                defaultValue={[rangeMin, rangeMax]}
                value={[minVal, maxVal]}
                min={rangeMin}
                max={rangeMax}
                allowCross={false}
                range={true}
                step={rangeStep}
                onChange={handleSliderChange}
                // onAfterChange={handleSliderChange}
                trackStyle={{ backgroundColor: '#0094d9' }}
                handleStyle={{ backgroundColor: '#0094d9', borderColor: '#0094d9' }}
            />
            <InputsWrapper>
                <InputWrapper>
                    {format === 'currency' && currencyAdornment}
                    <InputSpan
                        role="textbox"
                        onBlur={handleInputBlur('min')}
                        className="type-normal-body"
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                        // value={inputVals?.min}
                        // onInput={handleInputChange('min')}
                        onKeyDown={handleInputKeyDown('min')}
                    >
                        {formatVal(inputVals?.min)}
                    </InputSpan>
                    {format === 'percent' && percentAdornment}
                </InputWrapper>
                <InputWrapper>
                    {format === 'currency' && currencyAdornment}
                    <InputSpan
                        role="textbox"
                        className="type-normal-body"
                        onBlur={handleInputBlur('max')}
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                        // onInput={handleInputChange('max')}
                        onKeyDown={handleInputKeyDown('max')}
                    >
                        {formatVal(inputVals?.max)}
                    </InputSpan>
                    {format === 'percent' && percentAdornment}
                </InputWrapper>
            </InputsWrapper>
        </Wrapper>
    )
}

export const DropdownRangeInput = memo((props) => {
    const {
        label,
        onChange,
        rangeMin = 0,
        rangeMax = 0,
        min,
        max,
        format = 'number',
        extraClass,
        disabled,
        emptyLabel,
        ...rest
    } = props

    let minVal = min
    let maxVal = max
    if (isNaN(min)) {
        minVal = rangeMin
    }
    if (isNaN(max)) {
        maxVal = rangeMax
    }
    const [range, setRange] = useState([minVal, maxVal])

    // effect to update the label values when the component props
    // are updated
    useEffect(() => {
        let minVal = min
        let maxVal = max
        if (isNaN(min)) {
            minVal = rangeMin
        }
        if (isNaN(max)) {
            maxVal = rangeMax
        }
        setRange([minVal, maxVal])
    }, [max, min, rangeMax, rangeMin, label])

    const handleDropdownChange = (vals) => {
        setRange(vals)
        onChange && onChange(vals)
    }

    const rangeProps = {
        min,
        max,
        rangeMin,
        rangeMax,
        format,
        ...rest,
    }

    const dropdownContent = (
        <DropdownWrapper>
            <RangeInput onChange={handleDropdownChange} name={label} {...rangeProps} />
        </DropdownWrapper>
    )

    // create the label for the multiselect
    let _min = `${range[0]}`
    let _max = `${range[1]}`
    if (format === 'currency') {
        _min = formatCurrency(range[0], 0)
        _max = formatCurrency(range[1], 0)
    } else if (format === 'percent') {
        _min = `${formatNumber(range[0], 0)}%`
        _max = `${formatNumber(range[1], 0)}%`
    } else if (format === 'decimal') {
        _min = `${formatNumber(range[0], 2)}`
        _max = `${formatNumber(range[1], 2)}`
    } else {
        _min = formatNumber(range[0], 0)
        _max = formatNumber(range[1], 0)
    }

    // if the number is negative, wrap it in parenthesis
    if (range[0] < 0) {
        _min = `(${_min})`
    }
    if (range[1] < 0) {
        _max = `(${_max})`
    }

    let rangeStatement = `${_min} - ${_max}`
    const isEmpty = (min == null && max == null)
    if (isEmpty) {
        rangeStatement = emptyLabel
    }

    return (
        <MultiSelectList
            label={label}
            isEmpty={isEmpty}
            dropdownContent={dropdownContent}
            dropdownLabel={rangeStatement}
            extraClass={extraClass}
            disabled={disabled}
        />
    )
})

export default RangeInput
