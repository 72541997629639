import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";

export const SubMenu = ({children, text, ...rest}) => {
    const [open, setOpen] = useState(false);
    const subNavLinkRef = useRef(null);

    /**
     * set up a click listener to listen for clicks anywhere in the document except in the input ref.
     * If a click is detected anywhere except the input ref, the submenu is closed
     * @param {Node} ref
     */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (subNavLinkRef.current && !subNavLinkRef.current.contains(event.target)) {
                setOpen(false);
            }
        }

        // Register the event listener
        document.addEventListener("click", handleClickOutside);

        return () => {
            // Cleanup / remove the click listener
            document.removeEventListener("click", handleClickOutside);
        }
    }, [subNavLinkRef]);

    return <li className={open ? 'active' : ''}
               {...rest}>
        <button ref={subNavLinkRef}
                onClick={() => setOpen(!open)}
                className="header-submenu-toggler">
            {text}
        </button>
        <ul className="header-submenu">
            {children}
        </ul>
    </li>;
}

SubMenu.propTypes = {
    text: PropTypes.string.isRequired
}
