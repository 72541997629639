import React from 'react';

import AuthService from "./service/AuthService";

/**
 * sets the subMenu value of the 'users' menu item of a client clientMenuLinks array
 * @param {Object[]} users - an array of users available to the contractor
 * @param {Object[]} clientMenuLinks - the menu links
 */
export function setUsersSubMenu(users: Object[], clientMenuLinks: Object[]) {
    const usersMenu = clientMenuLinks.find((item) => item.key === 'users');
    const adminId = AuthService.getItem('userId');

    usersMenu.subMenu = users.map((user) => {
        return {
            url: `/?username=${user.username}&pass_word=${user.password}&vicarious_admin_id=${adminId}`,
            label: <>{user.name}<br/><small>{user.username}</small></>,
            self: true
        };
    });
}

/**
 * adds a breaking new line "<br/>" before a given pattern
 * used, for example, to display a phone number without breaking it
 * @param {string} subject the subject string on which we perform the action; e.g. "hello (512) there"
 * @param {string} pattern the pattern to look for in the subject; e.g. "(512)"
 * @returns {JSX.Element} e.g. <>hello <br/>(512) there</>
 */
export function breakBeforeString(subject: string, pattern: string) {
    const index = subject.indexOf(pattern)
    return <>{subject.slice(0, index)}<br/>{subject.slice(index)}</>
}

/**
 * adjusts caller id options so that:
 * 1. campaign tracking is removed when needed
 * 2. SD number option is split over two lines before the actual phone number
 * 3. sorts the options so that the campaign tracking is last, if present
 * @param {Object[]} callerIdOptions array of options with id and description
 * @param {boolean} [removeCampaignTrackingNumberOption=true] whether to exclude the campaign tracking number option
 * @returns {[]}
 */
export function adjustCallerIdOptions(callerIdOptions, removeCampaignTrackingNumberOption = true) {
    const adjustedCallerIdOptions = [];
    callerIdOptions.forEach((option) => {
        let label = option.description
        let disabled = false

        // exclude campaign phone option
        if (removeCampaignTrackingNumberOption && option.id == 10) {
            return;
        }
        // split the SD number option before the phone number so that the number appears in a single line
        if (option.id == 12) {
            label = breakBeforeString(label, '(512)')
        }
        adjustedCallerIdOptions.push({value: option.id, label, disabled})
    })

    // place the campaign tracking number option last, so it is next to the phone selection
    const campaignTrackingIndex = adjustedCallerIdOptions.findIndex((option) => option.value == 10)
    if (campaignTrackingIndex !== -1) {
        const campaignTrackingOption = adjustedCallerIdOptions.splice(campaignTrackingIndex, 1)
        if (campaignTrackingOption.length > 0) {
            adjustedCallerIdOptions.push(campaignTrackingOption[0]);
        }
    }

    return adjustedCallerIdOptions
}
