import React, { useEffect, useState } from 'react'
import LeadService from '../../../../service/LeadService'
import AdminLeadTags from './AdminLeadTags'
import styled from 'styled-components'
// import DropdownSelect from '../../../components/DropdownSelect'
// import SkeletonText from '../../../components/Skeleton/SkeletonText'
import LeadDetailBlock from '../LeadDetailBlock'

const Wrapper = styled.div`
    width: 100%;
`

const AdminLeadTagsLoadable = ({ leadId, ...rest }) => {
    const [tagOptions, setTagOptions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [leadTags, setLeadTags] = useState()
    const leadService = new LeadService()

    // fetch lead tags and tag options
    useEffect(() => {
        setIsLoading(true)
        leadService
            .getLeadTags()
            .then((response) => {
                setTagOptions(response.data)
                return leadService.getLeadTagsForLead(leadId)
            })
            .then((response) => {
                setLeadTags(response.data)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log('error getting tags', err)
                setIsLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Save the lead tags, then make a request for the updated lead
    // tags
    const handleSaveLeadTags = (tags) => {
        return leadService
            .saveLeadTags(leadId, tags)
            .then((response) => {
                // fetch the updated lead options
                return leadService.getLeadTags()
            })
            .then((response) => {
                setTagOptions(response.data)
                // also fetch updated lead tag options
                return leadService.getLeadTagsForLead(leadId)
            })
            .then((response) => {
                setLeadTags(response.data)
            })
            .catch((err) => {
                console.log('error getting tags', err)
            })
    }

    if (isLoading || !leadTags) {
        return (
            <LeadDetailBlock title="Admin Lead Tags">
                <Wrapper>
                    {/* <DropdownSelect
                        label={<SkeletonText width={60} />}
                        isDisabled={true}
                        placeholder={'Loading...'}
                    /> */}
                    <AdminLeadTags options={[]} loading={true} />
                </Wrapper>
            </LeadDetailBlock>
        )
    }

    return (
        <LeadDetailBlock title="Admin Lead Tags">
            <Wrapper>
                <AdminLeadTags
                    options={tagOptions}
                    onSaveLeadTags={handleSaveLeadTags}
                    leadTags={leadTags}
                    {...rest}
                />
            </Wrapper>
        </LeadDetailBlock>
    )
}

export default AdminLeadTagsLoadable
