import React from 'react';

const DeleteUserModal = (props) => {
    return <>
        <div className="spacing-30-bottom">
            <div className="spacing-20-bottom">
                <div className="type-large-body type-force-newline spacing-30-bottom">
                    Are you sure you want to remove user <b>{props.user.username}</b>?
                    {props.updateHubSpot &&
                    <div>This will also remove the Contact to Company association in HubSpot.</div>}
                </div>
            </div>
        </div>
        <div className="ui-hide-mobile">
            <div className="popup__form__gray-bar padding-24">
                <div className="popup__form__row popup__form__row__slam-right">
                    <div className="popup__form__cell">
                    <span role="button"
                          className="type-small-body type-heavy type-blue"
                          onClick={() => props.updateModalContent()}>
                        Cancel
                    </span>
                    </div>
                    <div className="popup__form__cell">
                        <button className="button ui-normal-button"
                                onClick={() => props.deleteUser(props.user.userId, props.updateHubSpot)}>
                            Delete User
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="ui-hide-full ui-hide-tablet">
            <div className="popup__form__row padding-40-bottom">
                <button className="button ui-normal-button ui-full-width-button"
                        onClick={() => props.deleteUser(props.user.userId, props.updateHubSpot)}>
                    Delete This User
                </button>
            </div>
        </div>
    </>
};

export default DeleteUserModal;
