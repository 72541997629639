import React, {Component} from 'react';
import SignupLaunchStatusTableHeaders from './SignupLaunchStatusTableHeaders';
import SignupLaunchStatusTableRow from './SignupLaunchStatusTableRow';

export default class SignupLaunchStatusTable extends Component {
    render() {
        const tableRows = this.props.signupRows.map((signupRow, key) => {
            let index = `signupLaunchStatusTableRow-${key}`;
            return <SignupLaunchStatusTableRow
                signupRow={signupRow}
                key={index}
                index={key}
                csManagers={this.props.csManagers}
                updateSignupRow={this.props.updateSignupRow}
                updateMessageBlocks={this.props.updateMessageBlocks}
                updateModalContent={this.props.updateModalContent}
                shouldShowSendEmailButton={this.props.shouldShowSendEmailButton}
                scrollboxRef={ this.props.scrollboxRef }
            />;
        });

        if (tableRows.length === 0) {
            tableRows.push(<tr key="empty-data"><td colSpan="21">&nbsp;</td></tr>);
        }

        return <table className="signup-status-table sticky-header-and-column">
            <colgroup>
                <col className="client-name"/>
                <col span="12" className="client-details"/>
                <col span="11" className="campaign-details"/>
            </colgroup>
            <thead>
                <SignupLaunchStatusTableHeaders/>
            </thead>
            <tbody>
                {tableRows}
            </tbody>
        </table>;
    }
}
