import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ShortStatNumber from "../../common/components/ShortStatNumber";
import {IconArrowLeftSvg, IconArrowRightSvg, IconLockSvg} from '../../common/Svgs';
import LeadSummaryCell from "../../common/lead_log/LeadSummaryCell";
import LoadingGif from '../../common/components/LoadingGif'
import AuthService from "../../service/AuthService";
import {
    CallsAnsweredRateLocked,
    CallsAnsweredRateUnlocked,
    JobsWonRateLocked,
    JobsWonRateUnlocked
} from "../../common/lead_manager/FilteredLeadsStatsModalTooltips";
import type {ILeadSummary} from "../../Interfaces/ILeadSummary";

export default class LeadSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lastLeadChangeTimestamp: null,
            isLoading: true,
            scrollPosition: 0,
            contentLength: AuthService.isMarketplace
                ? 6
                : 8,
            contentWidth: undefined,
            cellWidth: undefined,
            cellGutter: undefined
        };

        this.contentScreenRef = React.createRef();
        this.contentsRef = React.createRef();
        this.modalRef = React.createRef();
    }

    checkScrollThreshold = () => {
        if ((this.state.scrollPosition + this.columnCount()) > this.state.contentLength) {
            this.setState({
                scrollPosition: this.state.contentLength - this.columnCount()
            });
        }
    };

    columnCount = () => {
        const windowWidth = window.visualViewport
            ? window.visualViewport.width
            : window.innerWidth;

        if (windowWidth <= 640) {
            return 2;
        }
        else if (windowWidth <= 960) {
            return 4;
        }
        else if (windowWidth <= 1269) {
            return 5;
        }

        return 6;
    };

    /**
     * Detects if a change was made to the lastLeadChangeTimestamp.  If so,
     * then makes an API request to refresh header stats.
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.lastLeadChangeTimestamp != this.props.lastLeadChangeTimestamp) {
            this.setState({lastLeadChangeTimestamp: this.props.lastLeadChangeTimestamp});
        }
    }

    componentDidMount() {
        this.sizeStatCells();

        window.addEventListener('resize', this.sizeStatCells);
        window.addEventListener('resize', this.checkScrollThreshold);
    }

    enableLeftArrow = () => {
        return this.state.scrollPosition > 0;
    };

    enableRightArrow = () => {
        return (this.state.scrollPosition + this.columnCount()) < this.state.contentLength;
    };

    getContentsHeight = () => {
        if (!this.contentsRef.current) {
            return '';
        }

        return `${this.contentsRef.current.offsetHeight}px`;
    };

    /**
     * Resizes header stat cells when the view loads, but also when the user's
     * browser window is resized.
     *
     * The latter requires this.contentScreenRef.current not be null, which
     * isn't always the case.  B/c since this method calls setState(),
     * re-renders occur while the user is resizing their browser, and that
     * introduces race conditions that sometimes have
     * this.contentScreenRef.current null when this method executes. All
     * that leads to this kind of bug:
     *
     * https://app.asana.com/0/918599342387311/1148318734974761
     *
     * The included if() check at the start of this method is a work around, but
     * ultimately a CSS solution is likely more idea.
     */
    sizeStatCells = () => {
        if (!this.contentScreenRef.current) {
            return;
        }

        const ratioGutter = 0.03509202;
        const ratioMobileGutter = 0.0781253;
        const ratio2Columns = 0.42187515;
        const ratio4Columns = 0.22368098125;
        const ratio5Columns = 0.171926384;
        const ratio6Columns = 0.137423313;

        const contentScreenWidth = this.contentScreenRef.current.offsetWidth;

        let cellWidth, contentWidth, gutterWidth;

        switch (this.columnCount()) {
            case 2:
                gutterWidth = ratioMobileGutter * contentScreenWidth;
                cellWidth = ratio2Columns * contentScreenWidth;
                break;
            case 4:
                gutterWidth = ratioGutter * contentScreenWidth;
                cellWidth = ratio4Columns * contentScreenWidth;
                break;
            case 5:
                gutterWidth = ratioGutter * contentScreenWidth;
                cellWidth = ratio5Columns * contentScreenWidth;
                break;
            default:
                gutterWidth = ratioGutter * contentScreenWidth;
                cellWidth = ratio6Columns * contentScreenWidth;
        }

        contentWidth = (this.state.contentLength * cellWidth) +
            (this.state.contentLength * gutterWidth);

        this.setState({
            cellWidth: cellWidth,
            cellGutter: gutterWidth,
            contentWidth: contentWidth
        });
    };

    /**
     * a quick filter function builder; returns a function if the quickFilter function was set
     * or null otherwise. this is done so that the LeadSummaryCell will display a clickable
     * filter or not respectively
     * @param {object} options - the filter options to be passed to the quickFilter function
     * @return {null|(function(): null)}
     */
    quickFilterFunction = (options) => {
        if (!this.props.quickFilter) {
            return null;
        }

        return () => this.props.quickFilter(options);
    };

    /**
     * wrapping function for the quickFilterFunction
     * @return {function(): null}
     */
    followUpStatClick = () => {
        return this.quickFilterFunction({needs_follow_up: 1});
    };

    /**
     * wrapping function for the quickFilterFunction
     * @return {function(): null}
     */
    billableLeadsAllTimeStatClick = () => {
        return this.quickFilterFunction({is_valid: 1, date_range_id: 7});
    };

    /**
     * wrapping function for the quickFilterFunction
     * @return {function(): null}
     */
    billableLeadsLast90DaysStatClick = () => {
        return this.quickFilterFunction({is_valid: 1, date_range_id: 10});
    };

    /**
     * Renders <LeadSummaryCell>s, possibly as loading gifs until values finish
     * initializing (while initializing, stat state values are undefined).
     * ts undefined
     * @return {*}
     */
    render() {
        let leadSummary: ILeadSummary = this.props.leadSummary;

        // we have to have at least one part of the equation numeric so that we compare numbers and not strings
        leadSummary.minConfidence = Number(leadSummary.minConfidence);

        let isManagedRoiLast90DaysLocked = leadSummary.minConfidence > leadSummary.managedRoiLast90DaysConfidence;
        let isCallAnsweredConfidenceLast90DaysLocked = leadSummary.minConfidence > leadSummary.callAnsweredConfidenceLast90Days;
        let isBookedAppointmentsLast90DaysConfidenceLocked = leadSummary.minConfidence > leadSummary.bookedAppointmentsLast90DaysConfidence;
        let isJobsWonLast90DaysConfidenceLocked = leadSummary.minConfidence > leadSummary.jobsWonLast90DaysConfidence;
        let position = 0;

        return <div
            className="row padding-50-top-full padding-50-top-tablet padding-40-mobile padding-70-bottom-full padding-70-bottom-tablet padding-40-bottom-mobile">
            <div className="wide-format-col">
                <div className="stats-bar__container">
                    <div className="stats-bar__arrow-container__left">
                        <span role="button"
                              className={`stats-bar__arrow stats-bar__left-arrow ${this.enableLeftArrow() ? 'stats-bar__arrow__active' : ''}`}
                              onClick={() => {
                                  if (this.enableLeftArrow()) {
                                      this.setState({scrollPosition: this.state.scrollPosition - 1});
                                  }
                              }}
                        >
                            {IconArrowLeftSvg}
                        </span>
                    </div>
                    <div className="stats-bar__arrow-container__right">
                        <span role="button"
                              className={`stats-bar__arrow stats-bar__right-arrow ${this.enableRightArrow() ? 'stats-bar__arrow__active' : ''}`}
                              onClick={() => {
                                  if (this.enableRightArrow()) {
                                      this.setState({scrollPosition: this.state.scrollPosition + 1});
                                  }
                              }}
                        >
                            {IconArrowRightSvg}
                        </span>
                    </div>
                    <div className="stats-bar__content-screen" ref={this.contentScreenRef}
                         style={{height: this.getContentsHeight()}}>
                        <div className="stats-bar__contents" ref={this.contentsRef}
                             style={{width: `${this.state.contentWidth}px`}}>
                            {!AuthService.isMarketplace &&
                            <LeadSummaryCell
                                statContent={leadSummary.adImpressionsAllTime === undefined
                                    ? <LoadingGif/>
                                    : <ShortStatNumber number={leadSummary.adImpressionsAllTime}/>
                                }
                                statType="text"
                                statDescription="Ad Impressions All Time"
                                color="orange"
                                cellPosition={position++}
                                scrollPosition={this.state.scrollPosition}
                                columnCount={this.columnCount()}
                                width={this.state.cellWidth}
                                gutter={this.state.cellGutter}
                                toolTipTitle="Ad Impressions All Time"
                                toolTipBody={
                                    <span
                                        className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                        This metric identifies how many times the Ads from Service Direct
                                        Custom Search Campaigns have been seen by a potential customer searching for the
                                        advertised services.
                                   </span>
                                }
                                toolTipPosition="bottom"
                                modalContainerRef={this.modalRef}
                            />}
                            <LeadSummaryCell
                                statContent={leadSummary.needsFollowUp === undefined
                                    ? <LoadingGif/>
                                    : <ShortStatNumber number={leadSummary.needsFollowUp}/>
                                }
                                statType="text"
                                statDescription="Leads Marked for Follow-Up"
                                color="orange"
                                cellPosition={position++}
                                scrollPosition={this.state.scrollPosition}
                                columnCount={this.columnCount()}
                                width={this.state.cellWidth}
                                gutter={this.state.cellGutter}
                                onClick={this.followUpStatClick()}
                                toolTipTitle="Leads Marked for Follow-Up"
                                toolTipBody={
                                    <>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            This metric identifies the total number of Billable Leads
                                            where Lead Progress has been set as "Needs Follow-Up."
                                            These high priority Leads require further communication with
                                            the customer, and can be easily filtered in your{' '}
                                            <a className="type-heavy" href="/leads">Lead Manager</a>.
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            Learn more about{' '}
                                            <a href={AuthService.isMarketplace
                                                ? "https://support.servicedirect.com/lead-manager"
                                                : "https://support.servicedirect.com/select/lead-manager"}
                                               className="type-heavy" target="_blank">
                                                Getting the Most Out of Your Leads
                                            </a>
                                        </span>
                                    </>
                                }
                                toolTipPosition="right"
                                modalContainerRef={this.modalRef}
                            />
                            <LeadSummaryCell
                                statContent={leadSummary.billableLeadsAllTimeCount === undefined
                                    ? <LoadingGif/>
                                    : <ShortStatNumber number={leadSummary.billableLeadsAllTimeCount}/>
                                }
                                statType="text"
                                statDescription="Billable Leads All Time"
                                color="orange"
                                cellPosition={position++}
                                scrollPosition={this.state.scrollPosition}
                                columnCount={this.columnCount()}
                                width={this.state.cellWidth}
                                gutter={this.state.cellGutter}
                                onClick={this.billableLeadsAllTimeStatClick()}
                                toolTipTitle="Billable Leads All Time"
                                toolTipBody={
                                    <>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            This metric identifies the total number of Billable Leads generated by Service Direct Campaigns.
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            Learn more about{' '}
                                            <a href={AuthService.isMarketplace
                                                ? "https://support.servicedirect.com/billable-and-non-billable-leads"
                                                : "https://support.servicedirect.com/select/billable-and-non-billable-leads"}
                                               className="type-heavy" target="_blank">
                                                Billable and Non-Billable Leads.
                                            </a>
                                        </span>
                                    </>
                                }
                                toolTipPosition="bottom"
                                modalContainerRef={this.modalRef}
                            />
                            <LeadSummaryCell
                                statContent={leadSummary.billableLeadsLast90DaysCount === undefined
                                    ? <LoadingGif/>
                                    : <ShortStatNumber number={leadSummary.billableLeadsLast90DaysCount}/>
                                }
                                statType="text"
                                statDescription="Billable Leads Last 90 Days"
                                color="orange"
                                cellPosition={position++}
                                scrollPosition={this.state.scrollPosition}
                                columnCount={this.columnCount()}
                                width={this.state.cellWidth}
                                gutter={this.state.cellGutter}
                                onClick={this.billableLeadsLast90DaysStatClick()}
                                toolTipTitle="Billable Leads Last 90 Days"
                                toolTipBody={
                                    <>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            This is the total number of Billable Leads generated by your Service Direct Campaigns in the last 90 days.
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            Learn more about{' '}
                                            <a className="type-heavy" target="_blank"
                                               href={
                                                   AuthService.isMarketplace
                                                       ? "https://support.servicedirect.com/billable-and-non-billable-leads"
                                                       : "https://support.servicedirect.com/select/billable-and-non-billable-leads"
                                               }>
                                                Billable and Non-Billable Leads.
                                            </a>
                                        </span>
                                    </>
                                }
                                toolTipPosition="bottom"
                                modalContainerRef={this.modalRef}
                            />
                            <LeadSummaryCell
                                statContent={
                                    leadSummary.managedRoiLast90Days === undefined
                                        ? <LoadingGif/>
                                        : (isManagedRoiLast90DaysLocked
                                            ? IconLockSvg
                                            : (leadSummary.managedRoiLast90Days > 0
                                                ? <ShortStatNumber number={leadSummary.managedRoiLast90Days} suffix="%"/>
                                                : 'N/A')
                                        )
                                }
                                statType={isManagedRoiLast90DaysLocked ? "icon" : ""}
                                statDescription="Managed ROI Last 90 Days"
                                color={isManagedRoiLast90DaysLocked ? "gray" : "orange"}
                                confidence={leadSummary.managedRoiLast90DaysConfidence}
                                cellPosition={position++}
                                scrollPosition={this.state.scrollPosition}
                                columnCount={this.columnCount()}
                                width={this.state.cellWidth}
                                gutter={this.state.cellGutter}
                                toolTipTitle="Managed ROI Last 90 Days"
                                toolTipBody={
                                    <>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            This metric is an estimate of your Return On Investment of Managed Leads,
                                            and is calculated based on the Job Revenue you track in Lead Manager for jobs you have won.
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            This metric is <span className="type-heavy">Locked</span> until you have
                                            tracked enough Job Revenue data for Leads with Job Progress set as Won. Once
                                            unlocked, the <span className="type-heavy">Confidence Percentage</span>{' '}
                                            reflects how confident we are in the accuracy of this metric. The more
                                            Leads you Manage, the more accurate these metrics become.
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            <b>Note</b>: A Billable Lead is considered "Managed" when the Lead Progress and/or Job Progress has been set.
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            Learn more about{' '}
                                            <a className="type-heavy" target="_blank"
                                               href={AuthService.isMarketplace
                                                   ? 'https://support.servicedirect.com/lead-performance-metrics'
                                                   : 'https://support.servicedirect.com/select/lead-performance-metrics'}> Understanding Lead Performance Metrics.
                                            </a>
                                        </span>

                                    </>
                                }
                                toolTipPosition="left"
                                modalContainerRef={this.modalRef}
                            />
                            <LeadSummaryCell
                                statContent={
                                    leadSummary.callAnsweredRateLast90Days === undefined
                                        ? <LoadingGif/>
                                        : (isCallAnsweredConfidenceLast90DaysLocked
                                            ? IconLockSvg
                                            : <ShortStatNumber number={leadSummary.callAnsweredRateLast90Days} suffix="%"/>
                                        )
                                }
                                statType={isCallAnsweredConfidenceLast90DaysLocked ? "icon" : ""}
                                statDescription="Call Answered Rate Last 90 Days"
                                color={isCallAnsweredConfidenceLast90DaysLocked ? "gray" : "orange"}
                                confidence={leadSummary.callAnsweredConfidenceLast90Days}
                                cellPosition={position++}
                                scrollPosition={this.state.scrollPosition}
                                columnCount={this.columnCount()}
                                width={this.state.cellWidth}
                                gutter={this.state.cellGutter}
                                toolTipTitle="Call Answered Rate Last 90 Days"
                                toolTipBody={isCallAnsweredConfidenceLast90DaysLocked
                                    ? CallsAnsweredRateLocked(leadSummary.billableLeadsLast90DaysCount, leadSummary.minConfidence)
                                    : CallsAnsweredRateUnlocked}
                                toolTipPosition="left"
                                modalContainerRef={this.modalRef}
                            />
                            <LeadSummaryCell
                                statContent={
                                    leadSummary.bookedAppointmentsRateLast90Days === undefined
                                        ? <LoadingGif/>
                                        : (isBookedAppointmentsLast90DaysConfidenceLocked
                                            ? IconLockSvg
                                            : <ShortStatNumber number={leadSummary.bookedAppointmentsRateLast90Days} suffix="%"/>
                                        )
                                }
                                statType={isBookedAppointmentsLast90DaysConfidenceLocked ? "icon" : ""}
                                confidence={leadSummary.bookedAppointmentsLast90DaysConfidence}
                                statDescription="Booked Appointments Rate Last 90 Days"
                                color={isBookedAppointmentsLast90DaysConfidenceLocked ? "gray" : "orange"}
                                cellPosition={position++}
                                scrollPosition={this.state.scrollPosition}
                                columnCount={this.columnCount()}
                                width={this.state.cellWidth}
                                gutter={this.state.cellGutter}
                                toolTipTitle="Booked Appointments Rate Last 90 Days"
                                toolTipBody={
                                    <>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            This metric calculates the total number of Billable Leads generated by Service
                                            Direct in the Last 90 Days that also have a Lead Progress set as Booked
                                            Appointment. This metric is Locked until you have Managed enough Billable
                                            Leads that resulted in a Booked Appointment.
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            This metric is <span className="type-heavy">Locked</span> until you have tracked enough Job Revenue data for
                                            Leads with Job Progress set as Won. Once unlocked, the <span
                                            className="type-heavy">Confidence Percentage</span>{' '}
                                            reflects how confident we are in the accuracy of this metric. The more
                                            Leads you Manage, the more accurate these metrics become.
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            <i>Note: A Billable Lead is considered "Managed" when the Lead Progress and/or Job Progress has been set.</i>
                                        </span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height spacing-10-bottom">
                                            Learn more about{' '}
                                            <a className="type-heavy"
                                               href={AuthService.isMarketplace
                                                   ? 'https://support.servicedirect.com/lead-performance-metrics'
                                                   : 'https://support.servicedirect.com/select/lead-performance-metrics'}
                                               target="_blank"> Understanding Lead Performance Metrics.
                                            </a>
                                        </span>

                                    </>
                                }
                                toolTipPosition="bottom"
                                modalContainerRef={this.modalRef}
                            />
                            <LeadSummaryCell
                                statContent={
                                    leadSummary.jobsWonRateLast90Days === undefined
                                        ? <LoadingGif/>
                                        : (isJobsWonLast90DaysConfidenceLocked
                                            ? IconLockSvg
                                            : <ShortStatNumber number={leadSummary.jobsWonRateLast90Days} suffix="%"/>
                                        )
                                }
                                statType={isJobsWonLast90DaysConfidenceLocked ? "icon" : ""}
                                confidence={leadSummary.jobsWonLast90DaysConfidence}
                                statDescription="Job Won Rate Last 90 Days"
                                color={isJobsWonLast90DaysConfidenceLocked ? "gray" : "orange"}
                                cellPosition={position++}
                                scrollPosition={this.state.scrollPosition}
                                columnCount={this.columnCount()}
                                width={this.state.cellWidth}
                                gutter={this.state.cellGutter}
                                toolTipTitle="Job Won Rate Last 90 Days"
                                toolTipBody={isJobsWonLast90DaysConfidenceLocked
                                    ? JobsWonRateLocked(leadSummary.billableLeadsLast90DaysCount, leadSummary.minConfidence)
                                    : JobsWonRateUnlocked}
                                toolTipPosition="bottom"
                                modalContainerRef={this.modalRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

LeadSummary.propTypes = {
    leadSummary: PropTypes.object.isRequired,
    quickFilter: PropTypes.func,
    lastLeadChangeTimestamp: PropTypes.number
};
