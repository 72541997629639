import React, { useEffect, useState } from 'react'
import qs from 'query-string'
import LeadService from '../../service/LeadService'
import { Link } from './Link'
import { differenceInDays, format, parseISO } from 'date-fns'
import { toTitleCase } from '../../Util'
import { MarginBottom } from './styles'

export default ({
    label,
    getLink,
    newWindow = true,
    leadDateProperty = 'creation_timestamp',
    freshnessTimestamp,
}) => {
    const [linkUrl, setLinkUrl] = useState(null)
    const [summary, setSummary] = useState(null)
    const [parsed, setParsed] = useState(null)
    const [leads, setLeads] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    // fetch data
    useEffect(() => {
        setIsLoading(true)
        const ls = new LeadService()
        if (getLink) {
            let _query
            getLink()
                .then((link) => {
                    setLinkUrl(link)
                    const _parsed = qs.parse(link?.split('?')[1]?.replaceAll('[]', ''), {
                        parseNumbers: true,
                    })
                    setParsed(_parsed)
                    _query = qs.stringify(_parsed, { arrayFormat: 'comma' })
                    return ls.getLeads(_query)
                })
                .then((data) => {
                    setLeads(data.data.leads)
                    setSummary(data.data.filtered_summary)
                })
                .catch((err) => {
                    console.error(err)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            setIsLoading(false)
        }
        // update the data any time the parent updates the freshness timestamp
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freshnessTimestamp])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    if (isLoading) {
        return (
            <MarginBottom>
                <Link loading={true} />
            </MarginBottom>
        )
    }

    if (linkUrl == null) {
        return (
            <MarginBottom>
                <Link display={`Error getting link for "${label}"`} />
            </MarginBottom>
        )
    }

    // create the context sentence
    let contextSentence
    // leads count
    if (summary && summary.leads_count) {
        contextSentence = <b>{summary.leads_count} Leads</b>
        if (summary.leads_count == 0) {
            contextSentence = <>{contextSentence} 🎉</>
        }
    }
    // get the date of the first lead in the query
    let dateTimestamp
    if (leads[0] && leads[0][leadDateProperty] != null) {
        dateTimestamp = leads[0][leadDateProperty]
        const creationDate = parseISO(dateTimestamp)
        const diff = differenceInDays(new Date(), creationDate)
        const formatted = format(creationDate, 'MMMM d, yyyy')
        let label = 'Lead'
        if (parsed != null) {
            const { sort_column } = parsed
            if (sort_column != 'date') {
                label = toTitleCase(sort_column)
            }
        }
        let diffStatement = `${diff} days ago`
        if (diff == 0) {
            diffStatement = 'today'
        } else if (diff == 1) {
            diffStatement = `${diff} day ago`
        }
        contextSentence = (
            <>
                {contextSentence}, earliest {label} from <b>{formatted}</b> (
                {diffStatement})
            </>
        )
    }
    return (
        <MarginBottom>
            <Link
                newWindow={newWindow}
                url={linkUrl}
                display={label}
                context={<>{contextSentence}</>}
            />
        </MarginBottom>
    )
}
