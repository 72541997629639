import React, { useState } from 'react';
import axios from 'axios';
import { Switch, Route, Redirect } from 'react-router-dom';

import CampaignService from '../service/CampaignService';
import OnboardingService from '../service/OnboardingService';
import {put, errorHandler} from '../Requests';
import TermsAndConditions from "../client/TermsAndConditions";
import PrivacyPolicy from "../client/PrivacyPolicy";
import Modal from "../common/Modal";
import AuthService from '../service/AuthService';
import TokenService from '../service/TokenService';

import { HamburgerSvg } from "../common/Svgs";

import OnboardingOverlay from './OnboardingOverlay';
import SelfServeOnboardingStep from './self-serve-onboarding-step';
import SelfServeOnboardingFinalStep from './self-serve-onboarding-final-step';
import SelfServeComplete from './self-serve-complete';


const SelfServeOnboardingView = (props) => {
	const [modalContent, setModalContent] = useState();
	const [modalHeader, setModalHeader] = useState();
	const [modalHideCloseButton, setModalHideCloseButton] = useState();
	
	const [showMobileOverlay, setShowMobileOverlay] = useState(false);
	
	const [completionChecked, setCompletionChecked] = useState(false);
	const [quizAnsweredState, setQuizAnsweredState] = useState([]);
	const [quizErrors, setQuizErrors] = useState([]);
	const [highlightErrors, setHighlightErrors] = useState(false);
	const [loadingNextStep, setLoadingNextStep] = useState(false);
	
	const [finalStepState, setFinalStepState] = useState({
		"contactTimeZone": undefined,
		"yearsInBusiness": undefined,
		"currentMarketing": undefined,
		"schedule": undefined,
		"scheduleText": "",
		"additionalBusinessInfo": "",
		"phoneNumber": "",
		"callerId": "",
		"signature": "",
		"certification1": false,
		"certification2": false,
		"certification3": false,
	});
	
	const stepProps = {
		completionChecked, setCompletionChecked,
		quizAnsweredState, setQuizAnsweredState,
		quizErrors, setQuizErrors,
		highlightErrors, setHighlightErrors,
		loadingNextStep,
	};
	
	const finalStepProps = {
		finalStepState, setFinalStepState,
		highlightErrors, setHighlightErrors,
		loadingNextStep,
	};
	
	const campaignService = new CampaignService();
	const onboardingService = new OnboardingService();
	const tokenService = new TokenService();
	
	const cancelSignal = axios.CancelToken.source();
	
	const stepStrings = ['step-one', 'step-two', 'step-three', 'step-four', 'step-five', 'step-six', 'step-complete'];
	
	const currentStepString = (currentStep) => {
		return stepStrings[currentStep - 1];
	};
	
	const nextStepString = (currentStep) => {
		return stepStrings[currentStep];
	};
	
	const cancelSelfServeOnboarding = (event) => {
		onboardingService.cancel()
		.then((response) => {
			AuthService.setItem('isOnboarding', false);
			AuthService.setItem('isAwaitingOnboarding', true);
			AuthService.setItem('canceledSsOnboarding', true);
			window.location =`/onboarding/schedule-onboarding`;
		})
		.catch((error) => {
			if (errorHandler(error)) {
				// TODO: handle error
			}
		})
	};
	
	const submitQuizStep = (event, currentStep) => {
		if (!completionChecked || quizErrors.length > 0) {
			setHighlightErrors(true);
			return;
		}
		
		setLoadingNextStep(true);
		
		onboardingService.advanceStep({step: currentStep})
			.then((response) => {
				AuthService.setItem(`onboardingStep${currentStep}Complete`, true);
				window.location = `/onboarding/${nextStepString(currentStep)}`;
			})
			.catch((error) => {
				if (errorHandler(error)) {
					// TODO: handle error
				}
			});
	};
	
	const submitFinalStep = (event) => {
		setLoadingNextStep(true);
		
		onboardingService.finish({
			contactTimeZone: finalStepState.contactTimeZone,
			yearsInBusiness: finalStepState.yearsInBusiness,
			currentMarketing: finalStepState.currentMarketing,
			desiredForwardingNumber: finalStepState.phoneNumber,
			desiredCallerIdPreferences: finalStepState.callerId,
			adScheduleData: finalStepState.schedule,
			desiredAdSchedule: finalStepState.scheduleText,
			additionalInfo: finalStepState.additionalBusinessInfo,
			fullName: finalStepState.signature,
		})
		.then((response) => {
			campaignService.getCampaigns(`contractor_id=${AuthService.contractorId}`)
			.then((response) => {
				const campaignIds = response['campaigns'].map(val => val.campaign_id);
				let data = {
					'data' : {
						'forwarded_phone': [finalStepState.phoneNumber],
					}
				};
				
				Promise.all(campaignIds.map(campaignId => {
					let url = `campaigns/${campaignId}/lead_delivery_settings`;
					return put(url, data, cancelSignal.token);
				}))
				.then(responses => {
					AuthService.setItem(`onboardingStep6Complete`, true);
					window.location =`/onboarding/step-complete`;
				})
				.catch(error => {
					if (errorHandler(error)) {
						// TODO: handle error
					}
				})
			})
			.catch((error) => {
				if (errorHandler(error)) {
					// TODO: handle error
				}
			})
		})
		.catch((error) => {
			if (errorHandler(error)) {
				// TODO: handle error
			}
		})
	}
	
	/**
	 * @param {Component} content
	 * @param {string} header
	 * @param {boolean} hideCloseButton
	 */
	const updateModalContent = (content: Component, header: string, hideCloseButton: boolean = false) => {
		setModalContent(content);
		setModalHeader(header);
		setModalHideCloseButton(hideCloseButton);
	};
	
	/**
	 * Logs user out
	 */
	const logout = () => {
		if (AuthService.token) {
			tokenService.logout()
				.catch((error) => {
					if (errorHandler(error)) {
						console.log(
							'Caught an exception when posting to "auth/logout" in' +
							'<SelfServeOnboardingView> component, error =', error
						);
					}
				});
		}
		else {
			AuthService.clear();
			window.location = "/";
		}
	};
	
	return (
		<>
		<Modal
			content={modalContent}
			header={modalHeader}
			updateModalContent={updateModalContent}
			hideCloseButton={modalHideCloseButton}
		/>
		<OnboardingOverlay
			visible={showMobileOverlay}
			closePopup={(e) => {
				setShowMobileOverlay(false);
			}}
			cancelSelfServeOnboarding={cancelSelfServeOnboarding}
			logout={logout}
		/>
		<header className="onboarding-header">
			<div className="header-container">
				<div
					className="page-width header-contents padding-20"
				>
					<div className="simpleflex__row">
						<div className="header__logo">
                        	<img src={props.whiteLabelPartnership.logo_uri}
                             	alt={props.whiteLabelPartnership.white_label_name}/>
						</div>
						<div className="header__button-wrap">
							<button onClick={logout}
							     className="button button__outline ui-normal-button"
							>
								Logout
							</button>
						</div>
					</div>
				</div>
				<div className="header__hamburger-wrap">
					<button
						className="hamburger-button"
						onClick={(e) => {
							setShowMobileOverlay(true);
						}}
					>
						{HamburgerSvg}
					</button>
				</div>
			</div>
		</header>
		<section className="grey-background full-page-height">
			<div className="onboarding-page page-width spacing-120-bottom">
				<Switch>
					<Route path="/onboarding/step-one">
						<SelfServeOnboardingStep step={1} cancelSelfServeOnboarding={cancelSelfServeOnboarding} submit={submitQuizStep} {...stepProps} />
					</Route>
					<Route path="/onboarding/step-two">
						<SelfServeOnboardingStep step={2} cancelSelfServeOnboarding={cancelSelfServeOnboarding} submit={submitQuizStep} {...stepProps} />
					</Route>
					<Route path="/onboarding/step-three">
						<SelfServeOnboardingStep step={3} cancelSelfServeOnboarding={cancelSelfServeOnboarding} submit={submitQuizStep} {...stepProps} />
					</Route>
					<Route path="/onboarding/step-four">
						<SelfServeOnboardingStep step={4} cancelSelfServeOnboarding={cancelSelfServeOnboarding} submit={submitQuizStep} {...stepProps} />
					</Route>
					<Route path="/onboarding/step-five">
						<SelfServeOnboardingStep step={5} cancelSelfServeOnboarding={cancelSelfServeOnboarding} submit={submitQuizStep} {...stepProps} />
					</Route>
					<Route path="/onboarding/step-six">
						<SelfServeOnboardingFinalStep 
							step={6}
							submit={submitFinalStep}
							updateModalContent={updateModalContent}
							cancelSelfServeOnboarding={cancelSelfServeOnboarding}
							stepProps={finalStepProps}
							{...props}
						/>
					</Route>
					<Route path="/onboarding/step-complete">
						<SelfServeComplete {...stepProps} {...props} />
					</Route>
					<Redirect to={`/onboarding/${currentStepString(AuthService.onboardingStep)}`} />
				</Switch>
			</div>
		</section>
		<footer className="padding-30-top padding-50-bottom grey-background">
			<div className="page-width">
				<div className="row">
					<div className="col alpha omega grid-12">
						<p className="type-extra-small-body type-single-line type-centered-mobile">
							<span
								className="type-right-side-bump-full type-right-side-bump-tablet type-force-newline-mobile spacing-10-bottom-mobile">
								&copy; {(new Date()).getFullYear()} {props.whiteLabelPartnership.white_label_name}
							</span>
							<button className="type-heavy type-blue button-clean"
									onClick={() => updateModalContent(
										<TermsAndConditions
											whiteLabelPartnership={props.whiteLabelPartnership}
											inAccountActivation={true}
										/>,
										props.whiteLabelPartnership.white_label_name +
										" Advertising Program Terms"
									)}
							>
								{props.whiteLabelPartnership.white_label_name} Terms and Conditions
							</button>
							{' '}and our{' '}
							<button className="type-heavy type-blue button-clean"
									onClick={() => updateModalContent(
										<PrivacyPolicy
											whiteLabelPartnership={props.whiteLabelPartnership}
										/>,
										props.whiteLabelPartnership.white_label_name +
										" Privacy Policy"
									)}
							>
								Privacy Policy
							</button>
						</p>
					</div>
				</div>
			</div>
		</footer>
		</>
	);
};

export default SelfServeOnboardingView;
