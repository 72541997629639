import React, {Component} from 'react';

import AddUserModal from './AddUserModal';
import Modal from '../../common/Modal'
import {PlusSignSvg} from '../../common/Svgs';
import UserNotificationsTable from './UserNotificationsTable';
import MajorAlerts from "../../common/MajorAlerts";
import ResourcesService from "../../service/ResourcesService";
import UserService from "../../service/UserService";
import {toCamelObject} from "../../Util";
import type {User} from "../../Interfaces/User";

export default class UsersView extends Component {
    constructor(props) {
        super(props);

        this.modalScrollerRef = React.createRef();

        this.state = {
            modalContent: undefined,
            modalHeader: undefined,
            modalWidth: undefined,
            modalFlatBottom: undefined,
            users: [],
            timeZones: [],
        };

        this.resourcesService = new ResourcesService();
        this.userService = new UserService();
    }

    componentDidMount() {
        this.getUsers();
        this.resourcesService.getTimeZones()
            .then((timeZones) => this.setState({timeZones}));
    }

    componentWillUnmount(): void {
        this.userService.cancelSignal.cancel();
        this.resourcesService.cancelSignal.cancel();
    }

    updateModalContent = (modalContent, modalHeader, modalWidth, modalFlatBottom) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom,
        });
    };

    /**
     * handles changing of notification setting value (e.g. checking the billing notification)
     * @param {string} type - the settings type (e.g. "billingNotifications")
     * @param {number} id - the user id
     * @param {boolean} value - whether the notification setting is checked or not
     */
    handleNotificationSettingClick = (type: string, id: number, value: number) => {
        const {users} = this.state;
        const user: User = users.find((user) => user.userId == id);
        const originalValue = user[type];
        value = Number(value);

        user[type] = value;
        this.userService.update(user.userId, {[type]: value})
            .then((resp) => {
                // if an error occurs, reset the value in the user object
                if (resp.status !== 200) {
                    user[type] = originalValue;
                    this.setState({users});
                }
            })
            .catch(err => {
                console.log("Error in UserNotificationsTable.handleNotificationSettingClick", err);
            });

        this.setState({users});
    };

    /**
     *  Function to hit the endpoint and grab the leads
     */
    getUsers = () => {
        this.userService.get()
            .then((response) => {
                if (!response) {
                    return;
                }

                this.setState({users: response.data.users});
            })
            .catch((error) =>
                console.log("Error in UsersView.getUsers(), ", error));
    };

    /**
     * Accepts key/value pairs of user attributes and updates the user through
     * the PUT /users/<user_id> API endpoint.
     */
    updateUser = (userId, userProps) => {
        let uneatableProps = [
                "name",
                "username",
                "phoneNumber",
                "jobTitle",
                "timeZoneId"
            ],
            updatedUser = null,
            usersClone = [...this.state.users];

        return this.userService.update(userId, userProps)
            .then(resp => {
                if (!resp || resp.status !== 200) {
                    let errorMsg = "There was an error saving your changes.  Please try again.";

                    if (resp.data && resp.data.errors) {
                        errorMsg = resp.data.errors[0].message;
                    }

                    this.props.updateMessageBlocks([errorMsg], 'error');
                    return;
                }

                updatedUser = toCamelObject(resp.data.data.user);

                if (!updatedUser) {
                    return;
                }

                for (let i = 0; i < usersClone.length; i++) {
                    let staleUser = usersClone[i];
                    if (userId != staleUser.userId) {
                        continue;
                    }

                    for (let j = 0; j < uneatableProps.length; j++) {
                        let userProp = uneatableProps[j];

                        // checking for undefined to prevent JS false values like false, "", 0, null, etc.
                        if (updatedUser[userProp] === undefined) {
                            continue;
                        }

                        usersClone[i][userProp] = updatedUser[userProp];
                    }
                    break;
                }

                let today = new Date();

                // close the modal
                this.updateModalContent();
                this.setState({
                    users: usersClone,
                    lastLeadChangeTimestamp: today.getTime()
                });

                this.props.updateMessageBlocks([resp.data.message], 'success');
            })
            .catch((error) => {
                console.log("Error in UsersView.updateUser", error);
            });
    };

    addUser = (userProps) => {
        let usersClone = [...this.state.users];

        return this.userService.addUser(userProps)
            .then(resp => {
                if (!resp || resp.status !== 200) {
                    let errorMsg = "There was an error saving your changes.  Please try again.";

                    if (resp.data && resp.data.errors) {
                        errorMsg = resp.data.errors[0].message;
                    }

                    this.props.updateMessageBlocks([errorMsg], 'error');
                    return;
                }

                if (!resp.data.data.user) {
                    return;
                }

                usersClone.unshift(toCamelObject(resp.data.data.user));

                const today = new Date();

                this.updateModalContent();
                this.setState({
                    users: usersClone,
                    lastLeadChangeTimestamp: today.getTime()
                });

                this.props.updateMessageBlocks([resp.data.message], 'success');
            })
            .catch(err => {
                console.log("Error in UsersView.addUser()", err);
            });
    };

    resendInvite = (userId) => {
        return this.userService.resendInvitation(userId)
            .then((resp) => {
                if (!resp || resp.status !== 200) {
                    let errorMsg = "There was an error saving your changes.  Please try again.";

                    if (resp.data && resp.data.errors) {
                        errorMsg = resp.data.errors[0].message;
                    }

                    this.props.updateMessageBlocks([errorMsg], 'error');
                    return;
                }

                // close the modal
                this.updateModalContent();

                this.props.updateMessageBlocks([resp.data.message], 'success');
            })
            .catch(err => {
                console.log("Error in UsersView.addUser()", err);
            });
    };

    sendResetPasswordEmail = (email) => {
        this.userService.sendResetPasswordEmail(email)
            .then((resp) => {
                if (!resp || resp.status !== 200) {
                    let errorMsg = "There was an error saving your changes.  Please try again.";

                    if (resp.data && resp.data.errors) {
                        errorMsg = resp.data.errors[0].message;
                    }

                    this.props.updateMessageBlocks([errorMsg], 'error');
                    return;
                }

                this.updateModalContent();

                this.props.updateMessageBlocks([resp.data.message], 'success');
            })
            .catch(err => {
                console.log("Error in UsersView.addUser()", err);
            });
    };

    deleteUser = (userId) => {
        const usersClone = [...this.state.users];

        this.userService.delete(userId)
            .then((resp) => {
                if (!resp || resp.status !== 200) {
                    let errorMsg = "There was an error saving your changes.  Please try again.";

                    if (resp.data && resp.data.errors) {
                        errorMsg = resp.data.errors[0].message;
                    }

                    this.props.updateMessageBlocks([errorMsg], 'error');
                    return;
                }

                for (let i = 0; i < usersClone.length; i++) {
                    if (userId == usersClone[i].userId) {
                        usersClone.splice(i, 1);
                        break;
                    }
                }

                let today = new Date();

                // close the modal
                this.updateModalContent();

                this.setState({
                    users: usersClone,
                    lastLeadChangeTimestamp: today.getTime()
                });

                this.props.updateMessageBlocks([resp.data.message], 'success');
            })
            .catch((error) => {
                console.log("Error in UsersView.deleteUser", error);
            });
    };

    render() {
        return (
            <>
                <Modal
                    content={this.state.modalContent}
                    header={this.state.modalHeader}
                    width={this.state.modalWidth}
                    flatBottom={this.state.modalFlatBottom}
                    updateModalContent={this.updateModalContent}
                    scrollerRef={this.modalScrollerRef}
                />
                <MajorAlerts/>
                <div className="page-width-wide">
                    <div
                        className="row padding-70-top-full padding-70-top-tablet padding-40-top-mobile padding-50-bottom-full padding-50-bottom-tablet padding-30-bottom-mobile">
                        <div class="wide-col-wrapper">
                            <div className="wide-format-col">
                                <h2 className="type-normal-headline type-heavy">
                                    Users
                                </h2>
                            </div>
                            <div className="clear-block"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="wide-col-wrapper">
                            <div className="wide-format-col">
                                <div className="module">
                                    <div className="module__header module__fullwidth">
                                        <h3 className="type-large-subhead type-single-line">
                                            mySD Users
                                        </h3>
                                    </div>
                                    <div className="module__contentbox module__fullwidth">
                                        <p className="type-normal-body spacing-30-bottom">
                                            Manage who on your team has access to your mySD Account.
                                        </p>
    
                                        <UserNotificationsTable
                                            users={this.state.users}
                                            timeZones={this.state.timeZones}
                                            lastUpdateTimestamp={new Date().getTime()}
                                            updateModalContent={this.updateModalContent}
                                            handleNotificationSettingClick={this.handleNotificationSettingClick}
                                            updateUser={this.updateUser}
                                            deleteUser={this.deleteUser}
                                            resendInvite={this.resendInvite}
                                            sendResetPasswordEmail={this.sendResetPasswordEmail}
                                        />
    
                                        <div className="simpleflex__row simpleflex__row__wrap__mobile">
                                            <div className="simpleflex__cell">
                                                <p className="type-small-body">
                                                <span role="button"
                                                      className="type-heavy type-blue"
                                                      onClick={() => this.updateModalContent(
                                                          <AddUserModal
                                                              addUser={this.addUser}
                                                              timeZones={this.state.timeZones}
                                                          />,
                                                          'Add New mySD User',
                                                          null,
                                                          true
                                                      )}
                                                >
                                                    <span className="inline-icon inline-icon__middle inline-icon__20">
                                                        {PlusSignSvg}
                                                    </span>
                                                    Add Another mySD User
                                                </span>
                                                </p>
                                            </div>
                                            <div className="simpleflex__cell">
                                                <p className="type-small-body type-grey-2 type-align-right type-align-left-mobile no-margin-bottom no-margin-top-full no-margin-top-tablet spacing-40-top-mobile">
                                                    <b>TIP:</b> You can set the phone numbers and emails that receive Leads
                                                    in <a href="/campaigns" className="type-heavy">Campaigns Manager</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clear-block"/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}