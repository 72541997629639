import React from 'react';

const PopupFormCell = (props) => {
	let classes = 'popup-form-cell';
	
	if (props.slamRight) {
		classes += ' popup-form-cell__slam-right';
	}
	
	if (props.extraClass) {
		classes += ` ${props.extraClass}`;
	}
	
	return (
		<div className={classes}>
			{props.children}
		</div>
	);
};

export default PopupFormCell;
