import React from 'react';

import {
    adminBillableOptions,
    bookedAppointmentOptions,
    callAnsweredOptions,
    callPlayedOptions,
    campaignTypeOptions,
    campaignStatusOptions,
    dateRangeOptions,
    jobWonOptions,
    managedOptions,
    needsFollowUpOptions,
    adminCallAnsweredOptions,
    adminBookedAppointmentOptions,
    adminJobWonOptions,
    adminRevenueOptions,
    adminManagedOptions,
    leadReviewOptions,
    clientLeadReviewOptions,
    textOfOption,
    leadReviewInitiatedOptions,
} from '../StaticValues';
import {
    formatDate,
    formatNumber,
} from '../../Util';
import EnglishyList from '../components/EnglishyList';
// import { map } from 'lodash';

/**
 * generates the filter message based on the filter settings
 * @param managerViewState
 * @param {Object} options
 * @param {boolean} [options.includeShowingPrefix=true]
 * @return {Object}
 */
export const filterMessage = (managerViewState, options = {}) => {
    // const includeShowingPrefix = (options.includeShowingPrefix === undefined) ? true : options.includeShowingPrefix;
    // const leadStatusOptions = (options.leadStatusOptions === undefined) ? [] : options.leadStatusOptions;
    // const contractorTypeOptions = (options.contractorTypeOptions === undefined) ? [] : options.contractorTypeOptions;
    // const adNetworkOptions = (options.adNetworkOptions === undefined) ? [] : options.adNetworkOptions;
    // const serviceCategoryOptions = (options.serviceCategoryOptions === undefined) ? [] : options.serviceCategoryOptions;
    // const parentContractorOptions = (options.parentContractorOptions === undefined) ? [] : options.parentContractorOptions;
    // const managerAccountOptions = (options.managerAccountOptions === undefined) ? [] : options.managerAccountOptions;
    // const clientOptions = (options.clientOptions === undefined) ? [] : options.clientOptions;
    // const leadReviewCompleteOptions = (options.leadReviewCompleteOptions === undefined) ? [] : options.leadReviewCompleteOptions;
    const { 
        includeShowingPrefix = true, 
        leadStatusOptions = [],
        contractorTypeOptions = [],
        adNetworkOptions = [],
        serviceCategoryOptions = [],
        parentContractorOptions = [],
        managerAccountOptions = [],
        clientOptions = [],
        leadReviewCompleteOptions = [],
        defaultFilters 
    } = options;

    if (managerViewState.loadingResults || managerViewState.loadingMessageMinDelayTimer) {
        return <span className="loading-results-message">Loading your Leads...</span>;
    }

    let showingStart = managerViewState.pagination.page_min_row_number,
        showingEnd = managerViewState.pagination.page_max_row_number,
        total = Number(managerViewState.pagination.total_rows),
        appliedFilters = managerViewState.appliedFilters,
        leadTypeFilterStr = "",
        leadFromFilterSelections = [],
        markedSelectionsArray = [],
        markedSelectionsJsx = null,
        selectedDateRangeId = appliedFilters.date_range_id;

    //
    // Lead ID filter
    // Note: in the subsequent filters there's logic to not show other filters if filtering on lead_id
    //
    let leadId = appliedFilters.lead_id;
    if (leadId) {
        let prependStr = "with Lead ID ";
        leadFromFilterSelections.push(
            <span key={ "filter_msg_lead_id"}> {prependStr}
                <span className="type-heavy type-no-break">{ leadId }</span>
            </span>
        );
    }

    // Billable selection
    let billableFilterSelectionText = appliedFilters.billable_select_list_value !== 'all'
        ? textOfOption(adminBillableOptions, appliedFilters.billable_select_list_value)
        : '';

    let managedText = textOfOption(managedOptions, appliedFilters.managed);
    if (managedText) {
        if (billableFilterSelectionText.length > 0) {
            billableFilterSelectionText = managedText + ', ' + billableFilterSelectionText;
        } else {
            billableFilterSelectionText = managedText + billableFilterSelectionText;
        }
    }

    if (appliedFilters.managed_select_list_value && appliedFilters.managed_select_list_value !== 'all') {
        billableFilterSelectionText = textOfOption(adminManagedOptions, appliedFilters.managed_select_list_value) + billableFilterSelectionText;
    }

    if (appliedFilters.starred == 1) {
        billableFilterSelectionText = 'Starred ' + billableFilterSelectionText;
    } else if (appliedFilters.starred == 0) {
        billableFilterSelectionText = 'Not Starred ' + billableFilterSelectionText;
    }

    if (appliedFilters.flagged_select_list_value === '1') {
        if (billableFilterSelectionText) {
            billableFilterSelectionText = 'Flagged ' + billableFilterSelectionText;
        } else {
            billableFilterSelectionText = 'Flagged ';
        }
    }

    if (appliedFilters.needs_follow_up) {
        markedSelectionsArray.push(textOfOption(needsFollowUpOptions, appliedFilters.needs_follow_up));
    }

    if (appliedFilters.call_answered) {
        markedSelectionsArray.push(textOfOption(callAnsweredOptions, appliedFilters.call_answered));
    }

    if (appliedFilters.booked_appointment) {
        markedSelectionsArray.push(textOfOption(bookedAppointmentOptions, appliedFilters.booked_appointment));
    }

    if (appliedFilters.job_won) {
        markedSelectionsArray.push(textOfOption(jobWonOptions, appliedFilters.job_won));
    }

    if (appliedFilters.call_answered_select_list_value && appliedFilters.call_answered_select_list_value !== '0') {
        markedSelectionsArray.push(textOfOption(adminCallAnsweredOptions, appliedFilters.call_answered_select_list_value));
    }

    if (appliedFilters.booked_appointment_select_list_value && appliedFilters.booked_appointment_select_list_value !== '0') {
        markedSelectionsArray.push(textOfOption(adminBookedAppointmentOptions, appliedFilters.booked_appointment_select_list_value));
    }

    if (appliedFilters.job_won_select_list_value && appliedFilters.job_won_select_list_value !== '0') {
        markedSelectionsArray.push(textOfOption(adminJobWonOptions, appliedFilters.job_won_select_list_value));
    }

    if (appliedFilters.lead_review_select_list_value && appliedFilters.lead_review_select_list_value !== 'all') {
        markedSelectionsArray.push(textOfOption(leadReviewOptions, appliedFilters.lead_review_select_list_value));
    }

    if (appliedFilters.client_lead_review_select_list_value && appliedFilters.client_lead_review_select_list_value !== 'all') {
        markedSelectionsArray.push(textOfOption(clientLeadReviewOptions, appliedFilters.client_lead_review_select_list_value));
    }

    if (appliedFilters.revenue_entered_select_list_value && appliedFilters.revenue_entered_select_list_value !== '0') {
        markedSelectionsArray.push(textOfOption(adminRevenueOptions, appliedFilters.revenue_entered_select_list_value));
    }

    if (appliedFilters.campaign_type && appliedFilters.campaign_type !== 'all') {
        markedSelectionsArray.push(textOfOption(campaignTypeOptions, appliedFilters.campaign_type));
    }

    if (appliedFilters.contractor_type_id && appliedFilters.contractor_type_id !== 'all') {
        if (contractorTypeOptions && contractorTypeOptions.length > 0) {
            markedSelectionsArray.push(textOfOption(contractorTypeOptions, appliedFilters.contractor_type_id) + ' Contractor');
        }
    }

    // lead status
    if (appliedFilters.status_id 
        && appliedFilters.status_id?.length > 0 
        && appliedFilters.status_id?.length !== defaultFilters?.status_id?.length
        && appliedFilters.status_id?.length !== leadStatusOptions?.length
    ) {
        if (leadStatusOptions && leadStatusOptions.length > 0) {
            const temp = leadStatusOptions
            .filter(option => appliedFilters?.status_id?.includes(option.value))
            .map(option => option.text)
            // markedSelectionsArray.push(textOfOption(leadStatusOptions, appliedFilters.status_id));
            markedSelectionsArray.push(...temp);
        }
    }

    if (appliedFilters.call_played_select_list_value && appliedFilters.call_played_select_list_value !== '0') {
        markedSelectionsArray.push(textOfOption(callPlayedOptions, appliedFilters.call_played_select_list_value));
    }

    if (markedSelectionsArray.length) {
        markedSelectionsJsx = <span> marked{' '}
            <span className="type-heavy type-no-break">{ markedSelectionsArray.join(", ") }</span>
        </span>;
    }

    //
    // Campaign Statuses
    //
    let selectedCampaignStatuses = [];
    if (appliedFilters.campaign_statuses &&
        appliedFilters.campaign_statuses.length !== defaultFilters?.campaign_statuses?.length) {
        campaignStatusOptions.forEach((val, idx) => {
            const campaignStatusValue = val.value;
            const campaignStatusLabel = val.text;

            if (appliedFilters.campaign_statuses.indexOf(campaignStatusValue) !== -1) {
                selectedCampaignStatuses.push(campaignStatusLabel);
            }
        });

        if (selectedCampaignStatuses.length) {
            const selectedCampaignStatusNamesStr = selectedCampaignStatuses.join(", ");

            leadFromFilterSelections.push(
                <span key={ "filter_msg_campaign_statuses" }>from <span className="type-heavy">{ selectedCampaignStatusNamesStr } Campaigns</span> </span>
            );
        }
    }
    //
    // Lead Sources
    //
    let selectedAdNetworks = [];
    if (appliedFilters.ad_network_ids && 
        appliedFilters.ad_network_ids.length !== defaultFilters?.ad_network_ids?.length) {
        adNetworkOptions.forEach((val, idx) => {
            const adNetworkId = val.value;
            const adNetworkName = val.text;

            if (appliedFilters.ad_network_ids.indexOf(adNetworkId) !== -1) {
                selectedAdNetworks.push(adNetworkName);
            }
        });

        if (selectedAdNetworks.length) {
            const selectedAdNetworkNamesStr = selectedAdNetworks.join(", ");
            const adNetworkLabel = selectedAdNetworks.length === 1
                    ? "Lead Source"
                    : "Lead Sources";

            leadFromFilterSelections.push(
                <span key={ "filter_msg_ad_network_id" }>from {adNetworkLabel}
                    <span className="type-heavy"> { selectedAdNetworkNamesStr } </span>
                </span>
            );
        }
    }

    //
    // Service Categories
    //
    let selectedServiceCategories = [];
    if (appliedFilters.service_category_ids &&
        appliedFilters.service_category_ids.length !== defaultFilters?.service_category_ids?.length) {
        serviceCategoryOptions.forEach((val, idx) => {
            const serviceCategoryId = val.value;
            const serviceCategoryName = val.text;

            if (appliedFilters.service_category_ids.indexOf(serviceCategoryId) !== -1) {
                selectedServiceCategories.push(serviceCategoryName);
            }
        });

        if (selectedServiceCategories.length) {
            const selectedServiceCategoryNamesStr = selectedServiceCategories.join(", ");
            const serviceCategoryLabel = selectedServiceCategories.length === 1
                    ? "Service Category"
                    : "Service Categories";

            leadFromFilterSelections.push(
                <span key={ "filter_msg_service_category_id" }>from {serviceCategoryLabel}
                    <span className="type-heavy"> { selectedServiceCategoryNamesStr } </span>
                </span>
            );
        }
    }

    // Review Initiated By
    let reviewInitiatedSelections = [];
    if (appliedFilters.review_initiated_by
        && appliedFilters.review_initiated_by?.length !== defaultFilters.review_initiated_by?.length) {
        leadReviewInitiatedOptions.forEach((val, idx) => {
            const optionKey = val.value;
            const optionName = val.text;

            if (appliedFilters.review_initiated_by.indexOf(optionKey) !== -1) {
                reviewInitiatedSelections.push(optionName);
            }
        });

        if (reviewInitiatedSelections.length) {
            const reviewInitiatedStr = reviewInitiatedSelections.join(", ");

            leadFromFilterSelections.push(
                <span key={ "filter_msg_review_initiated" }>with Review Initiated By
                    <span className="type-heavy"> { reviewInitiatedStr } </span>
                </span>
            );
        }
    }

    // Review Completed By
    let reviewCompletedSelections = [];
    if (appliedFilters.review_completed_by 
        && appliedFilters.review_completed_by?.length !== defaultFilters.review_completed_by?.length) {
        leadReviewCompleteOptions.forEach((val, idx) => {
            const optionKey = val.value;
            const optionName = val.text;

            if (appliedFilters.review_completed_by.indexOf(optionKey) !== -1) {
                reviewCompletedSelections.push(optionName);
            }
        });

        if (reviewCompletedSelections.length) {
            const reviewCompletedStr = reviewCompletedSelections.join(", ");

            leadFromFilterSelections.push(
                <span key={ "filter_msg_review_completed" }>with Review Completed By
                    <span className="type-heavy"> { reviewCompletedStr } </span>
                </span>
            );
        }
    }

    //
    // Parent Contractors
    //
    let selectedParentContractors = [];
    if (appliedFilters.parent_contractor_ids
        && (!appliedFilters.manager_account_ids || appliedFilters.manager_account_ids.length === 0)
        && (!appliedFilters.client_ids || appliedFilters.client_ids.length === 0)) {
        parentContractorOptions.forEach((val, idx) => {
            const parentContractorId = val.value;
            const parentContractorName = val.text;

            if (appliedFilters.parent_contractor_ids.indexOf(parentContractorId) !== -1) {
                selectedParentContractors.push(parentContractorName);
            }
        });

        if (selectedParentContractors.length) {
            const selectedParentContractorNamesStr = selectedParentContractors.join(", ");
            const parentContractorLabel = selectedParentContractors.length === 1
                    ? "Parent Contractor"
                    : "Parent Contractors";

            leadFromFilterSelections.push(
                <span key={ "filter_msg_parent_contractor_id" }>from {parentContractorLabel}
                    <span className="type-heavy"> { selectedParentContractorNamesStr } </span>
                </span>
            );
        }
    }

    //
    // Manager Accounts
    //
    let selectedManagerAccounts = [];
    if (appliedFilters.manager_account_ids && (!appliedFilters.client_ids || appliedFilters.client_ids.length === 0)) {
        managerAccountOptions.forEach((val, idx) => {
            const managerAccountId = val.value;
            const managerAccountName = val.text;

            if (appliedFilters.manager_account_ids.indexOf(managerAccountId) !== -1) {
                selectedManagerAccounts.push(managerAccountName);
            }
        });

        if (selectedManagerAccounts.length) {
            const selectedManagerAccountNamesStr = selectedManagerAccounts.join(", ");
            const managerAccountLabel = selectedManagerAccounts.length === 1
                    ? "Manager Account"
                    : "Manager Accounts";

            leadFromFilterSelections.push(
                <span key={ "filter_msg_manager_account_id" }>from {managerAccountLabel}
                    <span className="type-heavy"> { selectedManagerAccountNamesStr } </span>
                </span>
            );
        }
    }

    //
    // Client Selections
    //
    let selectedClients = [];
    if (appliedFilters.client_ids) {
        clientOptions.forEach((val, idx) => {
            const clientId = val.value;
            const clientName = val.text;

            if (appliedFilters.client_ids.indexOf(clientId) !== -1) {
                selectedClients.push(clientName);
            }
        });

        if (selectedClients.length) {
            let selectedClientNamesStr
            const NUM_CLIENTS_TO_SHOW = 10
            if (selectedClients.length > NUM_CLIENTS_TO_SHOW) {
                selectedClientNamesStr = selectedClients.slice(0, NUM_CLIENTS_TO_SHOW - 1).join(', ')
                selectedClientNamesStr += ` and ${selectedClients.length - NUM_CLIENTS_TO_SHOW} more`
            } else {
                selectedClientNamesStr = selectedClients.join(", ");
            }
            const clientLabel = selectedClients.length === 1
                    ? "Client"
                    : "Clients";

            leadFromFilterSelections.push(
                <span key={ "filter_msg_client_id" }>from {clientLabel}
                    <span className="type-heavy"> { selectedClientNamesStr } </span>
                </span>
            );
        }
    }

    //
    // Admin campaign filter
    //
    if (appliedFilters.campaign_id) {
        if (managerViewState.campaign) {
            leadFromFilterSelections.push(
                <span>from Campaign
                    <span className="type-heavy"> { managerViewState.campaign.name } </span>
                </span>
            );
        }
    }

    //
    // Campaign Selections
    //
    let selectedCampaignNames = [];
    if (managerViewState.campaigns) {
        for (let i = 0; i < managerViewState.campaigns.length; i++) {
            let campaignId = managerViewState.campaigns[i].campaign_id,
                // different property names in client and admin
                campaignName = managerViewState.campaigns[i].campaign_name || managerViewState.campaigns[i].name;

            if (appliedFilters.campaign_ids.indexOf(campaignId) !== -1) {
                selectedCampaignNames.push(campaignName)
            }
        }

        if (selectedCampaignNames.length) {
            let selectedCampaignNamesStr = selectedCampaignNames.join(", "),
                campaignLabel = selectedCampaignNames.length === 1
                    ? "Campaign"
                    : "Campaigns";

            leadFromFilterSelections.push(
                <span key={ "filter_msg_campaign_id" }>from {campaignLabel}
                    <span className="type-heavy"> { selectedCampaignNamesStr }</span>
                </span>
            );
        }
    }

    //
    // Service Category selections
    //
    let selectedIndustryNames = [];
    if (managerViewState.industries) { // No industries list on Admin
        for (let i = 0; i < managerViewState.industries.length; i++) {
            let industryId = managerViewState.industries[i].industry_id,
                industryName = managerViewState.industries[i].industry_name;

            if (appliedFilters.industry_ids.indexOf(industryId) !== -1) {
                selectedIndustryNames.push(industryName);
            }
        }
    }

    if (selectedIndustryNames.length) {
        let selectedNamesStr = selectedIndustryNames.join(", "),
            label = selectedIndustryNames.length === 1
                ? "Service Category"
                : "Service Categories";

        leadFromFilterSelections.push(
            <span key={ "filter_msg_campaign_id" }>in {label}
                <span className="type-heavy type-no-break"> { selectedNamesStr }</span>
            </span>
        );
    }

    //
    // Parent Contractors only: Child Company selection
    //
    let selectedContractorNames = [];
    if (appliedFilters.contractor_ids) { // No child contractor IDs on Admin
        if (appliedFilters.contractor_ids.length > 0) {
            for (let i = 0; i < managerViewState.contractorOptions.length; i++) {
                let contractorId = managerViewState.contractorOptions[i].value,
                    contractorName = managerViewState.contractorOptions[i].text;

                if (appliedFilters.contractor_ids.indexOf(contractorId) !== -1) {
                    selectedContractorNames.push(contractorName)
                }
            }
        }
        if (selectedContractorNames.length > 0) {
            let label = selectedContractorNames.length === 1
                ? " for company"
                : " for companies";

            leadFromFilterSelections.push(
                <span key={ "filter_msg_contractor_id" }>
                    {/* {label} { englishyListJsx(selectedContractorNames, "and") } */}
                    {label} <EnglishyList items={selectedIndustryNames} andOr='and' />
                </span>
            );
        }
    }

    //
    // Date Range
    //
    if (selectedDateRangeId === "custom") {
        let dateFrom = appliedFilters.date_from,
            dateTo = appliedFilters.date_to,
            fromToStr = "",
            prependStr = leadFromFilterSelections.length ? "and from " :  "from ";

        if (dateFrom && dateTo) {
            fromToStr = formatDate(dateFrom) + " - " + formatDate(dateTo);
        }
        else if (dateFrom) {
            fromToStr = formatDate(dateFrom);
        }
        else if (dateTo) {
            fromToStr = formatDate(dateTo);
        }

        // Checking fromToStr as an edge case when a user selected a
        // "custom" date range, but didn't provide from or to dates.  In
        // such cases we should skip attempting to display empty input.
        if (fromToStr) {
            leadFromFilterSelections.push(
                <span key={ "filter_msg_custom_date_range" }> {prependStr}
                    <span className="type-heavy type-no-break">{ fromToStr }</span>
                </span>
            );
        }
    }
    else if (!leadId) {
        selectedDateRangeId = parseInt(selectedDateRangeId, 10);

        let prependStr = leadFromFilterSelections.length
            ? "and from "
            : "from ";

        for (let i = 0; i < dateRangeOptions.length; i++) {
            let option = dateRangeOptions[i];
            if (parseInt(option.value, 10) === selectedDateRangeId) {
                leadFromFilterSelections.push(
                <span key={ "filter_msg_date_range"}> {prependStr}
                    <span className="type-heavy type-no-break">{ option.text }</span>
                </span>
                );
                break;
            }
        }
    }

    //
    // Keyword
    //
    let keyword = appliedFilters.keyword;
    if (keyword) {
        let prependStr = "matching search term ";
        leadFromFilterSelections.push(
            <span key={ "filter_msg_keyword"}> {prependStr}
                <span className="type-heavy type-no-break">"{ keyword }"</span>
            </span>
        );
    }

    let leadTypeTxt = appliedFilters.lead_type === 'phone'
        ? "Phone Call "
        : (appliedFilters.lead_type === 'form'
            ? "Form "
            : "");

    let leadsTxt = (showingStart && total == 1)
        ? "Lead"
        : "Leads";

    let blacklistTxt = (appliedFilters.show_blacklisted == 1)
        ? ' (including Blacklisted)'
        : '';

    if (appliedFilters.sort_column === 'lead-review') {
        if (appliedFilters.lead_review_select_list_value === 'in review' ||
            appliedFilters.lead_review_select_list_value === 'in review submitted by client') {
                leadFromFilterSelections.push(' sorted by Lead Review Submitted Date');
        } else if (appliedFilters.lead_review_select_list_value === 'completed') {
            leadFromFilterSelections.push(' sorted by Lead Review Completed Date');
        }
    }

    return <>
        {includeShowingPrefix &&
        getShowingFilterMessagePrefix(total, showingStart, showingEnd)}
        <span className="type-heavy type-no-break"> {billableFilterSelectionText}
            </span> {leadTypeFilterStr} <span className="type-heavy type-no-break">{leadTypeTxt}{leadsTxt}{blacklistTxt}</span>
        { markedSelectionsJsx } { leadFromFilterSelections }
    </>;
};

/**
 * displays the pagination segment
 * @param {number} total
 * @param {number} showingStart
 * @param {number} showingEnd
 * @return {*}
 */
const getShowingFilterMessagePrefix = (total, showingStart, showingEnd) => {
    if (showingStart) {
        return <>
            Showing <span className="type-heavy type-no-break">
            { formatNumber(showingStart, 0) }-
            { formatNumber(showingEnd, 0) }</span> of{' '}
            <span className="type-heavy type-no-break  qa-leads-count">
                { formatNumber(total, 0) }
            </span>{' '}
        </>;
    }

    return <>
        Showing <span className="type-heavy type-no-break">
        { showingStart } </span>{' '}
    </>;
};
