import React from 'react';
import PropTypes from 'prop-types';

export const MockButton = (props) => {
    if (!process.env.REACT_APP_DEV) {
        return null;
    }

    return <button onClick={props.onClick} type="button"
                   className="button__green pointer ui-normal-button ui-full-width-button-mobile mock-button">
        Mock!
    </button>;
};

MockButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};
