import React, {Component} from 'react';
import axios from 'axios';
import {withRouter} from "react-router-dom";
import set from 'lodash/set';

import PrimaryContactForm from "../../../common/components/form_sections/PrimaryContactForm";
import {
    appInput,
    getPayload,
    getPayloadFromChangedFields,
    updateCountryCallback,
    updateStateCloneWithError,
    updateStateCloneWithValue,
    updateStateInputObjectWithValue,
    updateStCallback
} from '../../../formHelpers';
import {get, put} from '../../../Requests';
import CompanyDetailsForm from "../../../common/components/form_sections/CompanyDetailsForm";
import {formatCurrency, upperAllFirst} from "../../../Util";
import TextArea from "../../../common/components/form_elements/TextArea";
import Input from "../../../common/components/form_elements/Input";
import Checkbox from "../../../common/components/form_elements/Checkbox";
import LeadDeliverySettingsForm from "../../../common/components/form_sections/LeadDeliverySettingsForm";
import CompanyLogoAndImages from "./CompanyLogoAndImages";
import ServicesToAdvertise from "../../../common/components/service_category/ServicesToAdvertise";
import Validator from "../../../lib/Validator";
import FloatingButtonsBlock from "../../../common/FloatingButtonsBlock";
import AuthService from "../../../service/AuthService";
import Modal from "../../../common/Modal";
import ResourcesService from "../../../service/ResourcesService";
import {NextArrow} from "../../../common/Svgs";
import PricingSummary from "../../../common/components/form_sections/PricingSummary";
import LoadingGif from "../../../common/components/LoadingGif";
import {handleCallRecordingUncheck} from "../../../common/StaticValues";

/**
 * Expected props
 *
 * whiteLabelPartnership
 * ...
 */
class YourCompany extends Component {
    validator;
    contractorId;

    constructor(props) {
        super(props);

        this.contractorId = AuthService.isAdmin
            ? this.props.match.params.contractor_id
            : AuthService.getItem('contractorId');

        this.state = {
            initiating: true,

            inputs: {
                primary_contact_name: appInput("primary_contact_name"),
                primary_contact_job_title: appInput("primary_job_title"),
                primary_contact_email: appInput("primary_contact_email"),
                primary_contact_phone: appInput("primary_contact_phone"),
                primary_contact_time_zone: appInput("primary_contact_time_zone"),
                company_name: appInput("company_name"),
                country: appInput("country"),
                street_address: appInput("street_address"),
                unit: appInput("unit"),
                city: appInput("city"),
                state: appInput("state"),
                postal_code: appInput("postal_code"),
                website: appInput("website"),
                licenses_and_certifications: appInput("licenses_and_certifications"),
                free_estimates: appInput("free_estimates"),
                bond: appInput("bond"),
                insured: appInput("insured"),
                bbb_member: appInput("bbb_member"),
                awards_and_recognitions: appInput("awards_and_recognitions"),
                discounts_and_specials: appInput("discounts_and_specials"),
                warranties_and_guarantees: appInput("warranties_and_guarantees"),
                why_choose_us: appInput("why_choose_us"),
                signed_advertising_agreement_name: appInput("signed_advertising_agreement_name"),
                service_categories: [],
                call_recording: appInput("call_recording"),
                caller_id_option: appInput(),
                contact_email: [],
                forwarded_lead_phone: [],
                sms_lead_alert_multiple: []
            },

            //
            // Doing this so it can be updated on blur and not on change
            // like the value used by the inputs object.
            // Still using inputs.company_name for the state of the controlled input
            //
            companyNameText: "",
            submitting: false,
            enroll: false,

            //
            // callerIdRadioOptions, as returned from GET request
            // to avoid fragile string match of caller_id_option description
            //
            callerIdRadioOptions: [],

            // pricing information
            startupFee: 0,
            discountAmount: 0,
            lastFour: '',
            monthlyFee: 0,
            isPaid: false,
            clientCanEditCpl: false,
            retailStartupFee: 0,
            tenderData: [],

            modal: {
                content: null,
                header: null,
                width: ''
            }
        };

        this.cancelSignal = axios.CancelToken.source();
        this.resourcesService = new ResourcesService();
        this.profileId = null;

        this.modalScrollerRef = React.createRef();
        this.modalContainerRef = React.createRef();

        this.validator = new Validator();
    }

    componentDidMount() {
        get(`signup/${this.contractorId}/your-company`, this.cancelSignal.token)
            .then((resp) => {
                if (resp && typeof resp.data === 'object') {
                    if (!AuthService.isAdmin && resp.data.data.enroll_completed) {
                        this.props.history.push('/select-enroll-confirmation');
                        return;
                    }

                    let stateClone = {...this.state};
                    stateClone.initiating = false;
                    stateClone = this.setGetResponseToStateClone(stateClone, resp.data.data);

                    stateClone.enroll = resp.data.data.enroll;

                    stateClone.callerIdRadioOptions = resp.data.options.caller_id_options;
                    stateClone.startupFee = Number(resp.data.data.startup_fee);
                    stateClone.discountAmount = Number(resp.data.data.discount_amount);
                    stateClone.lastFour = resp.data.data.last_four;
                    stateClone.monthlyFee = Number(resp.data.data.monthly_fee);
                    stateClone.isPaid = resp.data.data.is_paid == 1;
                    stateClone.retailStartupFee = Number(resp.data.data.retail_startup_fee);
                    stateClone.tenderData = resp.data.data.tender_data || [];
                    stateClone.clientCanEditCpl = !!resp.data.data.client_can_edit_cpl;

                    this.setState(stateClone);
                }
            })
            .catch((error) => {
                this.setState({initiating: false});
                this.props.updateMessageBlocks(error.response.data.error, 'error');
            });
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
        this.resourcesService.cancelSignal.cancel();
    }

    /**
     * set the updated text on the service_area text
     */
    updateServiceAreaText = (value, serviceCategoryIndex, serviceAreaIndex) => {
        let inputsClone = {...this.state.inputs},
            serviceAreas = inputsClone.service_categories[serviceCategoryIndex].service_area.value;

        if (serviceAreaIndex !== undefined) {
            let originalValue = serviceAreas[serviceAreaIndex];

            if (originalValue != value) {
                inputsClone.service_categories[serviceCategoryIndex].service_area.changed = true;

                // if the value equals false, then remove the service area
                if (value === false) {
                    inputsClone.service_categories[serviceCategoryIndex].service_area.value.splice(serviceAreaIndex, 1);
                }
                else {
                    inputsClone.service_categories[serviceCategoryIndex].service_area.value[serviceAreaIndex] = value;
                }
            }
        }
        else {
            // if no index if passed, add a new service area
            inputsClone.service_categories[serviceCategoryIndex].service_area.changed = true;
            inputsClone.service_categories[serviceCategoryIndex].service_area.value.push(value);
            serviceAreaIndex = 0;
        }

        this.setState({inputs: inputsClone});

        // when changing a service area, update the map
        if (value) {
            this.resourcesService.getMapByZips(value.zip_codes.zips, inputsClone.country.value === 'CAN')
                .then((mapUrl) => {
                    let inputs = {...this.state.inputs};
                    inputs.service_categories[serviceCategoryIndex].service_area.value[serviceAreaIndex].map = mapUrl;
                    this.setState({inputs});
                });
        }

        this.closeModal();
    };

    /**
     * set the updated text on the `other_services` text
     */
    updateOtherText = (value, key, serviceCategoryIndex) => {

        let inputsClone = {...this.state.inputs},
            originalValue = inputsClone.service_categories[serviceCategoryIndex][key].value;

        if (originalValue != value) {
            inputsClone.service_categories[serviceCategoryIndex][key].changed = true;
            inputsClone.service_categories[serviceCategoryIndex][key].value = value;
        }

        this.setState({inputs: inputsClone});
    };

    /**
     * Set the /get response into the correct state values
     * @param stateClone
     * @param data
     */
    setGetResponseToStateClone = (stateClone, data) => {
        let inputClone = {...stateClone.inputs};
        this.profileId = data.profile_id;
        for (let key in data) {
            if (!data.hasOwnProperty(key)) {
                continue;
            }

            if (key === "lead_delivery_settings") {
                this.setLeadDeliveryOnStateClone(inputClone, data);
                continue;
            }
            else if (key === "service_category") {
                inputClone.service_categories = data[key];

                // turn the service_area and `other_services` strings into inputObjs
                let i = 0;
                for (let x in inputClone.service_categories) {
                    if (!inputClone.service_categories.hasOwnProperty(x)) {
                        continue;
                    }

                    let serviceAreaText = inputClone.service_categories[x].service_area;
                    inputClone.service_categories[x].service_area = appInput(
                        "service_area_" + i,
                        "service_area_" + i,
                        serviceAreaText,
                    );

                    let otherText = inputClone.service_categories[x].other_services || "";
                    inputClone.service_categories[x].other_services = appInput(
                        "other_services_" + i,
                        "other_services_" + i,
                        otherText,
                    );

                    i++;
                }
            }

            if (!inputClone[key]) {
                continue;
            }

            inputClone[key].value = data[key];

            // set the initial companyNameText here
            if (key === "company_name") {
                stateClone.companyNameText = upperAllFirst(data[key]);
            }
        }

        stateClone.inputs = inputClone;
        return stateClone;
    };

    setLeadDeliveryOnStateClone(inputsClone, data) {

        let leadDeliveryKeysToMap = [
                'forwarded_lead_phone',
                'sms_lead_alert_multiple',
                'contact_email'
            ],
            newValue,
            object = data['lead_delivery_settings'];

        for (let key in object) {
            if (!object.hasOwnProperty(key)) {
                continue;
            }

            newValue = object[key];

            if (!inputsClone[key]) {
                continue;
            }

            if (leadDeliveryKeysToMap.includes(key)) {
                if (object[key].length === 0) {
                    if (key === 'forwarded_lead_phone') {
                        inputsClone[key].push(appInput(null, "forwarded_lead_phone", data.primary_contact_phone));
                    }
                    if (key === 'contact_email') {
                        inputsClone[key].push(appInput(null, "contact_email", data.primary_contact_email));
                    }
                    if (key === 'sms_lead_alert_multiple') {
                        inputsClone[key].push(appInput(null, "sms_lead_alert_multiple"));
                    }
                }
                else {
                    inputsClone[key] = object[key].map(setting => appInput(null, key, setting))
                }
            }
                //
                // This branch handles e.g. caller_id_option and call_recording
                // (items that are not collections)
            //
            else {
                inputsClone[key].value = newValue;
            }
        }
    }

    /**
     * Sets the companyNameText on state object.  This is an oBlur() handler
     * instead of an onChange() handler so the company name in the page header
     * doesn't update on every keystroke, but rather only when the user blurs
     * out of the field.
     *
     * @param event
     */
    updateCompanyNameText = (event) => {
        this.setState({companyNameText: upperAllFirst(event.target.value)});
    };

    updateServiceCategoryCheckBox = (checked, serviceIndex, subcategoryIndex, serviceCategoryIndex) => {
        let stateClone = {...this.state};

        stateClone.inputs.service_categories[serviceCategoryIndex]
            .categories[subcategoryIndex]
            .services[serviceIndex]
            .selected = checked;

        this.setState(stateClone);
    };

    updateLeadDeliveryCheckbox = (name, value) => {
        const {inputs} = this.state;

        if (name === 'call_recording') {
            handleCallRecordingUncheck(value, this.props.updateMessageBlocks);
        }

        inputs[name].value = value;

        this.setState({inputs});
    }

    addAnother = (collectionName) => {
        let inputsClone = {...this.state.inputs};
        inputsClone[collectionName].push(appInput());
        return inputsClone;
    };

    deleteInput = (collectionName, key) => {
        let inputsClone = {...this.state.inputs};
        inputsClone[collectionName].splice(key, 1);
        return inputsClone;
    };

    /**
     * Called onClick for the Submit button and Save Your Progress button.
     * @param {boolean} finishSetup - When true, graduate to the next Step. When false, save and remain on the page.
     */
    submit = (finishSetup) => {
        if (this.submitting) {
            return;
        }

        let stateClone = {...this.state};

        this.validator.setStateClone(stateClone);

        let payload;
        if (finishSetup) {
            if (!stateClone.inputs.signed_advertising_agreement_name.value.trim()) {
                const errorMessage = 'Advertising agreement is required.';
                stateClone.inputs.signed_advertising_agreement_name.error = errorMessage;
                this.setState(stateClone);
                this.props.updateMessageBlocks(errorMessage, 'error');
                return;
            }

            payload = getPayload({...this.state.inputs});
        }
        else {
            payload = getPayloadFromChangedFields({...this.state.inputs});

            //
            // Address fields should always submit/
            //
            payload.city = this.state.inputs.city.value;
            payload.state = this.state.inputs.state.value;
            payload.country = this.state.inputs.country.value;
            payload.postal_code = this.state.inputs.postal_code.value;
        }

        this.setState({submitting: true});

        //
        // Some values that are being submitted here have moved from state to
        // state.inputs as part of a refactor to structure form inputs and handle
        // validation consistently.  One example is caller_id_option; it moved
        // from state.caller_id_option to state.inputs.caller_id_option.
        //
        // But as a result, we wind up with duplicates of such parameters when
        // we submit.  E.g. both payload.caller_id_option and
        // payload.lead_delivery_settings.caller_id_option exist.  So to avoid
        // confusion or possible user error, I'm deleting such payload
        // properties here until I figure out why this is happening.
        //
        delete payload.contact_email;
        delete payload.caller_id_option;
        delete payload.call_recording;
        delete payload.service_categories;

        payload.profile_id = this.profileId;
        this.addLeadDeliverySettingsToPayload(payload);
        this.addServiceCategoriesToPayload(payload, finishSetup);

        if (finishSetup) {
            for (let category of payload.service_category) {
                if (category.service_area[0].zip_codes.zips.length === 0) {
                    this.props.updateMessageBlocks('Service Area is required.', 'error');
                }
            }
            payload.finish_setup = 1;
        }

        put(`signup/${this.contractorId}/your-company`, payload, this.cancelSignal.token)
            .then(resp => {
                if (!resp) {
                    return;
                }
                this.setState({submitting: false}, () => {
                    if (resp.status === 400) {
                        this.validator.updateErrorsAndScroll(stateClone, resp);
                    }
                });
                stateClone.submitting = false;
                this.setState(stateClone);

                //
                // Show success message for Save Your Progress button. Not needed for button Submit button which goes
                // to the next step. Error messages already handled in axios interceptor in App.js.
                //
                if (!finishSetup && resp.status == 200) {
                    this.props.updateMessageBlocks(resp.data.message,
                        resp.status == 200 ? "success" : "error");
                }
                else if (finishSetup && this.state.enroll) {
                    this.props.updateHighlights();
                    this.props.history.push('/select-enroll-confirmation');
                }
            })
            .catch((resp) => this.setState({submitting: false}, () => {
                if (resp.status === 400) {
                    this.validator.updateErrorsAndScroll(stateClone, resp);
                }
            }));
    };

    /**
     * need to build this structure
     *
     *  service_category: [{
     *      industry_id: 587,
     *      industry_service_ids: [123, 456] // the selected industry_service_id values,
     *      service_area: {
     *          zip_codes: {
     *              zips: ['78717', '77515'],
     *              cities: ['Austin']
     *          },
     *          radius: 10,
     *          main_zip_code: 78750
     *      },
     *      other_services: “”
     *  }]
     */
    addServiceCategoriesToPayload = (payload, finishSetup) => {
        let serviceCategoriesData = [];
        for (let x in this.state.inputs.service_categories) {
            if (!this.state.inputs.service_categories.hasOwnProperty(x)) {
                continue;
            }

            const serviceCategory = this.state.inputs.service_categories[x];
            let industryServiceIds = [];

            for (let i in serviceCategory.categories) {
                if (!serviceCategory.categories.hasOwnProperty(i)) {
                    continue;
                }
                const category = serviceCategory.categories[i];

                for (let k in category.services) {
                    if (!category.services.hasOwnProperty(k)) {
                        continue
                    }

                    const service = category.services[k];
                    let isRequired = service.required === "1";
                    if (service.selected || isRequired) {
                        industryServiceIds.push(service.industry_service_id);
                    }
                }
            }

            serviceCategoriesData[x] = {
                industry_id: serviceCategory.industry.industry_id,
                industry_service_ids: industryServiceIds
            };

            // when in client view, the client might be able to edit the cpl; we send it if so
            const tenderData = this.state.tenderData
                .find((industry) => industry.industry_id == serviceCategory.industry.industry_id);
            if (tenderData) {
                serviceCategoriesData[x].cost_per_lead = tenderData.cost_per_lead;
            }

            //
            // If the user clicked Finish Setup, then send Service Area and
            // Other values.  Otherwise the user clicked Save Your Progress,
            // in which case we only send those values if they changed, to avoid
            // tripping validation that'd prevent saving in-progress data entry.
            //
            // - mz 30 July 2019
            //
            if (finishSetup) {
                serviceCategoriesData[x]["service_area"] = serviceCategory.service_area.value;
                serviceCategoriesData[x]["other_services"] = serviceCategory.other_services.value;
            }
            else {
                if (serviceCategory.service_area.changed) {
                    serviceCategoriesData[x]["service_area"] = serviceCategory.service_area.value;
                }

                if (serviceCategory.other_services.changed) {
                    serviceCategoriesData[x]["other_services"] = serviceCategory.other_services.value;
                }
            }
        }
        payload.service_category = serviceCategoriesData;
    };

    addLeadDeliverySettingsToPayload = (payload) => {
        let forwardedLeadPhone = this.state.inputs.forwarded_lead_phone,
            smsLeadAlertMultiple = this.state.inputs.sms_lead_alert_multiple,
            contactEmail = this.state.inputs.contact_email;


        payload.lead_delivery_settings = {
            'forwarded_phone': forwardedLeadPhone.map(input => input.value),
            'sms_lead_alert_multiple': smsLeadAlertMultiple.map(input => input.value),
            'contact_email': contactEmail.map(input => input.value),
            'call_recording': this.state.inputs.call_recording.value,
            'caller_id_option': this.state.inputs.caller_id_option.value,
        };
    };

    updateStateObjectError = (event, stateClone, collectionName, key) => {
        let elem = event.target,
            inputsClone = stateClone.inputs,
            //leadDeliverySettingsClone = {...stateClone['lead_delivery_settings']},
            collection = inputsClone[collectionName][key];


        if (elem.validity.valueMissing) {
            collection.error = " ";
        }
        else {
            collection.error = "";
        }

        //  collection.error = elem.value === "" ? " " : "";
        inputsClone[collectionName][key] = collection;
        stateClone.inputs = inputsClone;
        //stateClone['lead_delivery_settings'] = leadDeliverySettingsClone;

        return stateClone;
    };

    /**
     * Update the correct tender
     */
    updateTender = (index, tender) => {
        const {tenderData} = this.state;
        const tenderObject = tenderData[index];
        const minTender = Math.abs(tenderObject.default_min_tender);

        // check if the value is too low
        if (minTender > tender) {
            tenderObject.error = "Cost per lead for the " + tenderObject.name + " industry cannot be lower than " +
                formatCurrency(minTender, 2);
        }
        else if (isNaN(tender)) {
            tenderObject.error = "Cost per lead must be a number.";
        }
        else {
            delete tenderObject.error;
        }

        tenderData[index].cost_per_lead = tender.toFixed(2).toString();

        this.setState({tenderData});
    };

    closeModal = () =>
        this.setState({
            modal: {
                content: null,
                header: null,
                width: ''
            }
        });

    updateModalContent = (content, options = {}) =>
        this.setState({
            modal: {
                content,
                header: options.header,
                width: options.wide
                    ? 'wide'
                    : ''
            }
        });

    updateInputProperty = (name, value) => {
        const stateClone = set({...this.state}, ['inputs', name], { value })
        this.setState(stateClone)
    }

    render() {
        if (this.state.initiating) {
            return <div className="loading-gif-container full-height">
            <LoadingGif/>
            </div>;
        }

        const {inputs} = this.state;

        let pleaseCompleteJsx = "",
            advertAgreementJsx = "",
            finishSetupButtonJsx = "";

        if (!AuthService.isAdmin) {
            pleaseCompleteJsx = (
                <div className="module__contentbox module__twothirdswidth">
                    <p className="type-large-body spacing-20-bottom">
                        Please complete and confirm the following information
                        about {this.state.companyNameText}.
                        This information will be used to get started on your
                        advertising campaigns.
                        The more details you provide, the better we can optimize
                        your campaigns and the faster we can get you up and
                        running.
                    </p>
                    <p className="type-large-body type-red">
                        Please note: our team cannot get started until you
                        submit this information.
                    </p>
                </div>
            );

            advertAgreementJsx = (
                <div className="row padding-50-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">
                        <div className="module">
                            <div className="module__header module__twothirdswidth">
                                <h3 className="type-large-subhead type-single-line">
                                    Advertising Agreement
                                </h3>
                            </div>
                            <div className="module__contentbox module__twothirdswidth">
                                <p className="type-large-body spacing-30-bottom">
                                    By typing your name below, you agree that all the above information is correct and
                                    allow {this.props.whiteLabelPartnership.white_label_name} to use this information
                                    for the purposes of advertising.
                                </p>
                                <div className="form__row">
                                    <div className="form__cell form__cell__50 form__cell__100__mobile">
                                        <Input
                                            name="signed_advertising_agreement_name"
                                            label="Please type your First and Last Name to agree"
                                            type="text"
                                            onChange={event => this.setState(updateStateCloneWithValue({...this.state}, event))}
                                            onBlur={(event) => {
                                                let stateClone = {...this.state};
                                                this.validator.setStateClone(stateClone);
                                                stateClone = this.validator.updateStateCloneWithError(stateClone, event);
                                                this.setState(stateClone);
                                            }}
                                            required={true}
                                            value={inputs.signed_advertising_agreement_name.value}
                                            hasError={!!inputs.signed_advertising_agreement_name.error}
                                            inputRef={inputs.signed_advertising_agreement_name.ref}
                                        />
                                    </div>
                                    <div
                                        className="form__cell form__cell__label form__cell__50 form__cell__hidden__mobile"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
            );

            finishSetupButtonJsx = (
                <div className="row padding-120-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">
                        <div className="type-align-right">
                            <button className="button ui-large-button icon-right" disabled={this.state.submitting}
                                    onClick={() => this.submit(true)}>
                                Finish Setup
                                {NextArrow}
                            </button>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
            );
        }

        return (
            <div>
                <Modal
                    content={this.state.modal.content}
                    header={this.state.modal.header}
                    width={this.state.modal.width}
                    updateModalContent={this.updateModalContent}
                    scrollerRef={this.modalScrollerRef}
                    modalContainerRef={this.modalContainerRef}
                />

                <FloatingButtonsBlock
                    onClick={() => {
                        this.submit(0)
                    }}
                    disabled={this.state.submitting}
                />
                <div className="row padding-20-bottom">
                    <div className="col alpha grid-2 empty" />
                    <div className="col grid-8 no-margin-mobile">
                        <div className="module">
                            <div className="module__header module__twothirdswidth">
                                <h3 className="type-large-subhead type-single-line">
                                    Campaign Setup - {this.state.companyNameText}
                                </h3>
                            </div>
                            {pleaseCompleteJsx}
                        </div>
                    </div>
                    <div className="col omega grid-2 empty" />
                    <div className="clear-block" />
                </div>

                {this.state.enroll && (
                    <PricingSummary
                        discountAmount={this.state.discountAmount}
                        tenderData={this.state.tenderData}
                        updateTender={this.updateTender}
                        lastFour={this.state.lastFour}
                        monthlyFee={this.state.monthlyFee}
                        paid={this.state.isPaid}
                        retailStartupFee={this.state.retailStartupFee}
                        startupFee={this.state.startupFee}
                        clientCanEditCpl={this.state.clientCanEditCpl}
                    />
                )}
                <PrimaryContactForm
                    primaryContactName={inputs.primary_contact_name}
                    primaryContactJobTitle={inputs.primary_contact_job_title}
                    primaryContactEmail={inputs.primary_contact_email}
                    primaryContactPhone={inputs.primary_contact_phone}
                    primaryContactTimeZone={inputs.primary_contact_time_zone}
                    isAdmin={false}
                    onChange={(event) =>
                        this.setState(updateStateCloneWithValue({ ...this.state }, event))
                    }
                    updateInputProperty={this.updateInputProperty}
                    onBlur={(event) => {
                        let stateClone = { ...this.state }
                        this.validator.setStateClone(stateClone)
                        stateClone = this.validator.updateStateCloneWithError(
                            stateClone,
                            event
                        )
                        this.setState(stateClone)
                    }}
                />
                <CompanyDetailsForm
                    companyName={inputs.company_name}
                    country={inputs.country}
                    streetAddress={inputs.street_address}
                    unit={inputs.unit}
                    city={inputs.city}
                    state={inputs.state}
                    postalCode={inputs.postal_code}
                    website={inputs.website}
                    onChange={(event) =>
                        this.setState(updateStateCloneWithValue({ ...this.state }, event))
                    }
                    onBlur={(event) => {
                        let stateClone = { ...this.state }
                        this.validator.setStateClone(stateClone)
                        stateClone = this.validator.updateStateCloneWithError(
                            stateClone,
                            event
                        )
                        this.setState(stateClone)

                        //
                        // I wasn't sure why this was here at first .... it's
                        // here onBlur() instead of onChange() to avoid updating
                        // the company name in the Campaign Setup header on
                        // every key press.
                        //
                        if (event.target.name === 'company_name') {
                            this.updateCompanyNameText(event)
                        }
                    }}
                    updateCountryCallback={(vals) => {
                        this.setState(updateCountryCallback(vals[0], { ...this.state }))
                    }}
                    updateStCallback={(vals) => {
                        this.setState(updateStCallback(vals[0], { ...this.state }))
                    }}
                />
                <CompanyLogoAndImages contractorId={this.contractorId} />

                <ServicesToAdvertise
                    serviceCategories={this.state.inputs.service_categories}
                    updateServiceCategoryCheckBox={this.updateServiceCategoryCheckBox}
                    updateServiceAreaText={this.updateServiceAreaText}
                    updateMessageBlocks={this.props.updateMessageBlocks}
                    updateModalContent={this.updateModalContent}
                    closeModal={this.closeModal}
                    serviceCategoryStateKey="service_categories"
                    updateOtherText={this.updateOtherText}
                    country={inputs.country.value}
                />

                <LeadDeliverySettingsForm
                    forwardedLeadPhone={inputs.forwarded_lead_phone}
                    contactEmail={inputs.contact_email}
                    smsLeadAlertMultiple={inputs.sms_lead_alert_multiple}
                    leadDeliveryOnChange={(event, collectionName, key) =>
                        this.setState(
                            updateStateInputObjectWithValue(
                                event,
                                { ...this.state },
                                collectionName,
                                key
                            )
                        )
                    }
                    leadDeliveryOnBlur={(event, collectionName, key) =>
                        this.setState(
                            this.updateStateObjectError(
                                event,
                                { ...this.state },
                                collectionName,
                                key
                            )
                        )
                    }
                    addAnother={(collectionName) =>
                        this.setState({ inputs: this.addAnother(collectionName) })}
                    deleteInput={(collectionName, key) =>
                        this.setState({ inputs: this.deleteInput(collectionName, key) })}
                    callRecording={inputs.call_recording}
                    callerIdOption={inputs.caller_id_option}
                    updateLeadDeliveryCheckbox={this.updateLeadDeliveryCheckbox}
                    callerIdRadioOptions={this.state.callerIdRadioOptions}
                />
                <div className="row padding-50-bottom">
                    <div className="col alpha grid-2 empty" />
                    <div className="col grid-8 no-margin-mobile">
                        <div className="module">
                            <div className="module__contentbox module__contentbox__no-header module__twothirdswidth">
                                <h4 className="type-normal-subhead type-heavy type-allcaps spacing-10-bottom">
                                    Company Profile Information
                                </h4>
                                <p className="type-normal-body spacing-40-bottom">
                                    Please include the following information to help us
                                    better optimize your campaigns for optimum conversion
                                    rates from the moment they launch. The more ammunition
                                    we have to differentiate your company from competitors
                                    in your area, the better your campaigns will perform
                                    and the more leads you will get.
                                </p>

                                <div className="form__row spacing-30-bottom">
                                    <div className="form__cell form__cell__100">
                                        <label
                                            htmlFor="signup-licenses"
                                            className="type-normal-body type-heavy type-narrow-line-height spacing-5-bottom"
                                        >
                                            <div>
                                                <span className="type-right-side-bump">
                                                    List any Licenses and Certifications
                                                </span>
                                                <span className="type-small-body type-no-break">
                                                    (if applicable)
                                                </span>
                                            </div>
                                        </label>
                                        <TextArea
                                            name="licenses_and_certifications"
                                            hasError={
                                                !!inputs.licenses_and_certifications.error
                                            }
                                            value={
                                                inputs.licenses_and_certifications.value
                                            }
                                            inputRef={
                                                inputs.licenses_and_certifications.ref
                                            }
                                            onChange={(event) =>
                                                this.setState(
                                                    updateStateCloneWithValue(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            onBlur={(event) =>
                                                this.setState(
                                                    updateStateCloneWithError(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            required={false}
                                        />
                                    </div>
                                </div>

                                <div className="form__row spacing-30-bottom">
                                    <div className="form__cell form__cell__25 form__cell__100__mobile spacing-10-bottom-mobile">
                                        <Checkbox
                                            name="free_estimates"
                                            label="Free Estimates"
                                            onChange={(event) =>
                                                this.setState(
                                                    updateStateCloneWithValue(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            checked={!!inputs.free_estimates.value}
                                            hasError={!!inputs.free_estimates.error}
                                            inputRef={inputs.free_estimates.ref}
                                        />
                                    </div>
                                    <div className="form__cell form__cell__25 form__cell__100__mobile spacing-10-bottom-mobile">
                                        <Checkbox
                                            name="bond"
                                            label="Bonded"
                                            onChange={(event) =>
                                                this.setState(
                                                    updateStateCloneWithValue(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            checked={!!inputs.bond.value}
                                            hasError={!!inputs.bond.error}
                                            inputRef={inputs.bond.ref}
                                        />
                                    </div>
                                    <div className="form__cell form__cell__25 form__cell__100__mobile spacing-10-bottom-mobile">
                                        <Checkbox
                                            name="insured"
                                            label="Insured"
                                            onChange={(event) =>
                                                this.setState(
                                                    updateStateCloneWithValue(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            checked={!!inputs.insured.value}
                                            hasError={!!inputs.insured.error}
                                            inputRef={inputs.insured.ref}
                                        />
                                    </div>
                                    <div className="form__cell form__cell__25 form__cell__100__mobile">
                                        <Checkbox
                                            name="bbb_member"
                                            label="Member BBB"
                                            onChange={(event) =>
                                                this.setState(
                                                    updateStateCloneWithValue(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            checked={!!inputs.bbb_member.value}
                                            hasError={!!inputs.bbb_member.error}
                                            inputRef={inputs.bbb_member.ref}
                                        />
                                    </div>
                                </div>

                                <div className="form__row spacing-30-bottom">
                                    <div className="form__cell form__cell__100">
                                        <label
                                            htmlFor="signup-awards"
                                            className="type-normal-body type-heavy type-narrow-line-height spacing-5-bottom"
                                        >
                                            <div>
                                                <span className="type-right-side-bump">
                                                    List any Awards and Recognitions
                                                </span>
                                                <span className="type-small-body type-no-break">
                                                    (if applicable)
                                                </span>
                                            </div>
                                        </label>
                                        <TextArea
                                            name="awards_and_recognitions"
                                            value={inputs.awards_and_recognitions.value}
                                            hasError={
                                                !!inputs.awards_and_recognitions.error
                                            }
                                            inputRef={inputs.awards_and_recognitions.ref}
                                            onChange={(event) =>
                                                this.setState(
                                                    updateStateCloneWithValue(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            onBlur={(event) =>
                                                this.setState(
                                                    updateStateCloneWithError(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            required={false}
                                        />
                                    </div>
                                </div>

                                <div className="form__row spacing-30-bottom">
                                    <div className="form__cell form__cell__100">
                                        <label
                                            htmlFor="signup-discounts"
                                            className="type-normal-body type-heavy type-narrow-line-height spacing-5-bottom"
                                        >
                                            <div>
                                                <span className="type-right-side-bump">
                                                    List any Discounts or Specials.
                                                    Discuss any Financing Options
                                                    provided.
                                                </span>
                                                <span className="type-small-body type-no-break">
                                                    (if applicable)
                                                </span>
                                            </div>
                                        </label>
                                        <TextArea
                                            name="discounts_and_specials"
                                            value={inputs.discounts_and_specials.value}
                                            hasError={
                                                !!inputs.discounts_and_specials.error
                                            }
                                            inputRef={inputs.discounts_and_specials.ref}
                                            onChange={(event) =>
                                                this.setState(
                                                    updateStateCloneWithValue(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            onBlur={(event) =>
                                                this.setState(
                                                    updateStateCloneWithError(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            required={false}
                                        />
                                    </div>
                                </div>

                                <div className="form__row spacing-30-bottom">
                                    <div className="form__cell form__cell__100">
                                        <label
                                            htmlFor="signup-warranties"
                                            className="type-normal-body type-heavy type-narrow-line-height spacing-5-bottom"
                                        >
                                            <div>
                                                <span className="type-right-side-bump">
                                                    List any Warranties or Guarantees
                                                </span>
                                                <span className="type-small-body type-no-break">
                                                    (if applicable)
                                                </span>
                                            </div>
                                        </label>
                                        <p className="type-normal-body spacing-10-bottom">
                                            Examples include 1 Year Labor Warranty, Parts
                                            and Manufacturer Warranties Offered, Work
                                            Guaranteed In Writing, Satisfaction
                                            Guaranteed, etc.
                                        </p>
                                        <TextArea
                                            name="warranties_and_guarantees"
                                            value={inputs.warranties_and_guarantees.value}
                                            hasError={
                                                !!inputs.warranties_and_guarantees.error
                                            }
                                            inputRef={
                                                inputs.warranties_and_guarantees.ref
                                            }
                                            onChange={(event) =>
                                                this.setState(
                                                    updateStateCloneWithValue(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            onBlur={(event) =>
                                                this.setState(
                                                    updateStateCloneWithError(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            required={false}
                                        />
                                    </div>
                                </div>

                                <div className="form__row">
                                    <div className="form__cell form__cell__100">
                                        <label
                                            htmlFor="signup-pitch"
                                            className="type-normal-body type-heavy type-narrow-line-height spacing-5-bottom"
                                        >
                                            <div>
                                                Any other unique value offers? What sets
                                                you apart from your competition?
                                            </div>
                                        </label>
                                        <TextArea
                                            name="why_choose_us"
                                            value={inputs.why_choose_us.value}
                                            hasError={!!inputs.why_choose_us.error}
                                            inputRef={inputs.why_choose_us.ref}
                                            onChange={(event) =>
                                                this.setState(
                                                    updateStateCloneWithValue(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            onBlur={(event) =>
                                                this.setState(
                                                    updateStateCloneWithError(
                                                        { ...this.state },
                                                        event
                                                    )
                                                )
                                            }
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty" />
                    <div className="clear-block" />
                </div>
                {advertAgreementJsx}
                {finishSetupButtonJsx}
            </div>
        )
    }
}

export default withRouter(YourCompany);
