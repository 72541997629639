import React, {useRef, useState} from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import {MenuItem, StyledLink, StyledAction, MenuWrapper, MenuOpener, StyledAnchor} from './styles'
import {OpenNewWindow} from "../../Svgs";

export default (props) => {
    const tippyRef = useRef()
    const [visible, setVisible] = useState(false)
    // const show = () => setVisible(true)
    const hide = () => setVisible(false)

    function linkToMenuItem(link) {
        const {label, url, self, action, disabled} = link
        // if the link has no URL, don't generate an anchor / link
        if (!url || disabled) {
            // adding the setVisible(false) to close the menu after a click
            // this is needed because of a bug of the AgGrid system
            // we might be able to drop it later on
            const actionCallback = action
                ? () => {
                    action()
                    setVisible(false)
                }
                : null
            return (
                <MenuItem onClick={actionCallback} key={`${label}`}>
                    <StyledAction disabled={disabled}>{label}</StyledAction>
                </MenuItem>
            )
        }
        return (
            <MenuItem key={`${label}`}>
                <StyledLink
                    href={url}
                    target={self ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                >
                    {label}
                    {!self &&
                        <StyledAnchor>{OpenNewWindow}</StyledAnchor>}
                </StyledLink>
            </MenuItem>
        )
    }

    // primary menu items
    const menuLinks = props.links.map((link, i) => {
        return linkToMenuItem(link)
    })

    const Menu = <MenuWrapper>{menuLinks}</MenuWrapper>

    return (
        <Tippy
            ref={tippyRef}
            content={Menu}
            visible={visible}
            onClickOutside={hide}
            allowHTML={true}
            arrow={false}
            appendTo={document.body}
            interactive={true}
            placement={props.placement || 'right-end'}
            theme="sd-context-menu"
        >
            <MenuOpener onClick={() => setVisible(!visible)}>
                {props.linkLabel}
            </MenuOpener>
        </Tippy>
    )
}
