import React, { useState } from 'react';

import Input from '../../common/components/form_elements/Input';
import { CloseSvg } from '../../common/Svgs';
import DeleteUserModal from "./DeleteUserModal";
import SendPasswordResetModal from "./SendPasswordResetModal";
import type {User} from "../../Interfaces/User";
import MultiSelectList from '../../common/components/form_elements/MultiSelectList';

const EditUserModal = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const user: User = props.user;
    const [timeZoneId, setTimeZoneId] = useState()
    
    const updateUser = () => {
        let emailAddress = document.getElementById("username-" + user.userId).value,
            name = document.getElementById("name-" + user.userId).value,
            jobTitle = document.getElementById("job_title-" + user.userId).value,
            phoneNumber = document.getElementById("phone-" + user.userId).value,
            userProps: Partial<User> = {
                contactEmailAddress: emailAddress,
                timeZoneId,
                name: name,
                jobTitle: jobTitle,
                phoneNumber: phoneNumber
            };

        setSubmitting(true);
        props.updateUser(user.userId, userProps)
            .then(() => setSubmitting(false));
    };

    const handleSelectTimeZone = vals => {
        setTimeZoneId(vals[0])
    }

    return <>
        <div className="spacing-30-bottom">
            <div className="popup__form__row spacing-20-bottom">
                <div
                    className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                    <Input
                        label="Email *"
                        type="email"
                        required={true}
                        name={"username-" + user.userId}
                        defaultValue={user.username}
                    />
                </div>
                <div
                    className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile popup__form__cell__top-align">
                    <p className="type-small-body type-narrow-line-height type-heavy">
                        <span className="type-force-newline spacing-10-bottom">Password</span>
                        <span className="type-force-newline">
                        <span role="button" className="type-heavy"
                              onClick={() => props.updateModalContent(
                                  <SendPasswordResetModal
                                      user={user}
                                      updateModalContent={props.updateModalContent}
                                      sendResetPasswordEmail={props.sendResetPasswordEmail}
                                  />,
                                  'Reset Password',
                                  null,
                                  true
                              )}
                        >
                            Send password reset link to this user
                        </span>
                    </span>
                    </p>
                </div>
            </div>
            <div className="popup__form__row spacing-20-bottom">
                <div
                    className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile spacing-20-bottom-mobile">
                    <Input
                        type="text"
                        label="Name *"
                        name={"name-" + user.userId}
                        required={true}
                        defaultValue={user.name}
                    />
                </div>
                <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile">
                    <Input
                        type="text"
                        label="Title / Role"
                        name={"job_title-" + user.userId}
                        required={false}
                        defaultValue={user.jobTitle}
                    />
                </div>
            </div>
            <div className="popup__form__row spacing-20-bottom">
                <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile">
                    <Input
                        type="tel"
                        label="Phone"
                        name={"phone-" + user.userId}
                        required={false}
                        defaultValue={user.phoneNumber}
                    />
                </div>
                <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile">
                    <MultiSelectList
                        required={true}
                        setGroups={true}
                        hasGroups={true}
                        useTextFilter={true}
                        searchFromBeginning={false}
                        label="Time Zone"
                        name={"time-zone-" + user.userId}
                        selections={[user.timeZoneId]}
                        emptyLabel="Select Time Zone"
                        options={props.timeZones}
                        onChange={handleSelectTimeZone}
                    />
                </div>
            </div>
            <p className="type-small-body spacing-20">
            <span role="button" className="type-heavy"
                  onClick={() => props.updateModalContent(
                      <DeleteUserModal
                          user={user}
                          updateModalContent={props.updateModalContent}
                          deleteUser={props.deleteUser}
                      />,
                      'Delete mySD User',
                      null,
                      true
                  )}
            >
                <span className="inline-icon inline-icon__middle inline-icon__20">
                    {CloseSvg}
                </span>
                Delete this User
            </span>
            </p>
        </div>
        <div className="ui-hide-mobile">
            <div className="popup__form__gray-bar padding-24">
                <div className="popup__form__row popup__form__row__slam-right">
                    <div className="popup__form__cell">
                        <span role="button" className="type-small-body type-heavy type-blue"
                              onClick={() => props.updateModalContent()}>
                            Cancel Edits
                        </span>
                    </div>
                    <div className="popup__form__cell">
                        <button className="button ui-normal-button"
                                disabled={submitting}
                                onClick={() => updateUser()}>
                            Update This User
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="ui-hide-full ui-hide-tablet">
            <div className="popup__form__row padding-40-bottom">
                <button className="button ui-normal-button ui-full-width-button"
                        disabled={submitting}
                        onClick={() => updateUser()}>
                    Update This User
                </button>
            </div>
        </div>
    </>;
};

export default EditUserModal;