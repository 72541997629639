import React from 'react';

import Tooltip from "../../../common/Tooltip";
import LeadDeliverySettingsModal from "../../../common/LeadDeliverySettingsModal";
import AuthService from "../../../service/AuthService";
import {LeadDeliveryNotificationsSvg, PencilSvg} from "../../../common/Svgs";
import {CampaignTypes} from "../../../common/StaticValues";
import {useModal} from "../../../common/ModalContext";

const LeadDeliveryAndNotifications = (props) => {
    const {campaign} = props;

    const isMarketplace = campaign.type === CampaignTypes.MARKETPLACE;
    const updateModalContent = useModal();

    const phoneNumberDisplayList = campaign.phones
        ? campaign.phones
            .map(phone => phone.phone_number)
            .join(', ')
        : '';

    const emailDisplayList = campaign.emails
        ? campaign.emails
            .map(email => email.email_address)
            .join(', ')
        : '';

    const smsDisplayList = campaign.lead_notification_phones
        ? campaign.lead_notification_phones
            .map(email => email.phone_number)
            .join(', ')
        : '';

    const displayCallerIdPhoneNumber = () => {
        // we parse into number because switch uses strict comparison
        switch (parseInt(campaign.caller_id_option)) {
            case 9:
                return 'Show the Caller ID of the Phone Lead';

            case 10:
                return campaign.caller_id_phone_id === null
                    ? 'Number not set'
                    : `Show Tracking Number - ${campaign.caller_id_phone_number}`;

            case 12:
                return 'Show SD Lead Caller ID Number, (512) 877-3656';

            default:
                return '';
        }
    };

    return <div>
        <div className="type-grey inline-icon inline-icon__28">
            {LeadDeliveryNotificationsSvg}
        </div>

        <div className="campaigns-table__segment-header">
            <span className="type-normal-subhead type-heavy">
                Lead Delivery{' '}
                <Tooltip
                    position="right"
                    content={<>
                    <span
                        className="type-large-body type-force-newline type-heavy type-narrow-line-height spacing-10-bottom">
                        Lead Delivery Settings
                    </span>
                        <span
                            className="type-small-body type-narrow-line-height">
                        The listed phone numbers and email addresses are where you have requested
                        to receive all Leads generated from our services. To add or remove
                        phone numbers and emails, click Edit.
                    </span>
                    </>}
                />
            </span>

            <button className="button-clean type-blue type-small-body ui-float-right type-heavy"
                    onClick={() => updateModalContent({
                        content: <LeadDeliverySettingsModal
                            campaign={campaign}
                            submitLeadDeliverySettings={props.submitLeadDeliverySettings}
                            campaignIndex={props.campaignIndex}
                            callerIdOptions={props.callerIdOptions}
                            callerIdTrackingPhoneOptions={props.callerIdTrackingPhoneOptions}
                            updateMessageBlocks={props.updateMessageBlocks}
                        />,
                        header: `Edit Lead Delivery Settings for ${campaign.campaign_name}`,
                        headerLink: {
                            description: 'Learn More About Lead Delivery',
                            url: AuthService.isMarketplace
                                ? 'https://support.servicedirect.com/lead-delivery-notifications'
                                : 'https://support.servicedirect.com/select/lead-delivery-notifications'
                        },
                        width: "wide",
                        flatBottom: true
                    })}>
                <span className="inline-icon inline-icon__16 inline-icon__16__10">
                    {PencilSvg}
                </span>
                Edit
            </button>
        </div>

        <div className="campaigns-table__lead-delivery">
            <div className="type-heavy">Calls:</div>
            <div>{phoneNumberDisplayList}</div>

            <div className="type-heavy">Emails:</div>
            <div>{emailDisplayList}</div>

            <div className="type-heavy">Caller ID:</div>
            <div>
                {campaign.status !== 'canceled' &&
                    <div>
                        {displayCallerIdPhoneNumber()}
                        <Tooltip
                            position="left"
                            content={
                                campaign.caller_id_option == 9
                                    ? <>
                                        <span
                                            className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                            Caller ID Shows Caller</span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            When set to "Show the Caller ID of the Phone Lead", the answering party will see the actual Caller ID information of the inbound caller.</span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                            See here for more information.{' '}
                                            <a className="type-heavy" target="_blank"
                                               href={isMarketplace
                                                   ? "https://support.servicedirect.com/lead-delivery-notifications"
                                                   : "https://support.servicedirect.com/select/lead-delivery-notifications"}>
                                                Lead Delivery Settings.</a>
                                        </span>
                                    </>
                                    : campaign.caller_id_option == 10
                                        ? <>
                                        <span
                                            className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                            Caller ID Shows a specific Tracking Number</span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            When set to "Show Tracking Number", the answering party will see the selected Tracking Number as Caller ID.
                                            Common Use Case: so that the answering party recognizes the incoming call immediately, before answering.</span>
                                        <span
                                            className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                            See here for more information.{' '}
                                            <a className="type-heavy" target="_blank"
                                               href="https://support.servicedirect.com/select/lead-delivery-notifications">
                                                Lead Delivery Settings.</a>
                                        </span>
                                    </>
                                        : /* case of 12 */
                                        <>
                                            <span
                                                className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                Caller ID Show SD Lead Caller ID Number</span>
                                            <span
                                                className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                When set to "Show SD Lead Caller ID Number, (512) 877-3656", the
                                                answering party will always see the number (512) 877-3656 as Caller ID.
                                                This allows the answering party to immediately know when a call originates
                                                from a Service Direct campaign
                                            </span>
                                            <span
                                                className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                                See here for more information.{' '}
                                                <a className="type-heavy" target="_blank"
                                                   href={isMarketplace
                                                       ? "https://support.servicedirect.com/lead-delivery-notifications"
                                                       : "https://support.servicedirect.com/select/lead-delivery-notifications"}>
                                                    Lead Delivery Settings.</a>
                                            </span>
                                        </>
                            }
                        />
                    </div>}
            </div>
            {smsDisplayList &&
                <>
                    <div className="type-heavy">SMS:</div>
                    <div>{smsDisplayList}</div>
                </>}
        </div>
    </div>;
};

export default LeadDeliveryAndNotifications;
