import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "../../../Util";
import SkeletonText from "../Skeleton/SkeletonText";
import { Metric } from "./styles";

function PaginationSentence(props) {
  const {
    pageNum = 0,
    rowsPerPage = 0,
    totalRows = 0,
    loading,
    label = "Results",
  } = props;

  if (loading) {
    return <SkeletonText />
  }

  // calculate the starting metric
  let startMetric = 1;
  if (pageNum > 1) {
    startMetric += (pageNum - 1) * rowsPerPage;
  } else if (totalRows === 0) {
    startMetric = 0
  }

  // calculate the ending metric
  let endMetric = totalRows;
  if (totalRows > pageNum * rowsPerPage) {
    endMetric = pageNum * rowsPerPage;
  }

  let labelText = label;
  if (totalRows !== 1) {
    labelText = `${label}s`;
  }

  return (
    <span>
      Showing{" "}
      <Metric>
        {formatNumber(startMetric, 0)} - {formatNumber(endMetric, 0)}
      </Metric>{" "}
      of <Metric>{formatNumber(totalRows, 0)}</Metric> {labelText}
    </span>
  );
}

PaginationSentence.propTypes = {
  pageNum: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  label: PropTypes.string,
};

export default PaginationSentence;
