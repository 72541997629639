import React, { useEffect, useState } from 'react'
import qs from 'query-string'
import LeadService from '../../../service/LeadService'
import LeadReviewProgress from './LeadReviewProgress'

export default ({ freshnessTimestamp }) => {
    const [loading, setLoading] = useState(false)
    const [lrData, setLrData] = useState()

    const ls = new LeadService()
    useEffect(() => {
        setLoading(true)
        ls.getLeadReviews(qs.stringify())
            .then((res) => {
                setLrData(res.data)
                // setLrData({...res.data, count_my_reviews_today: "109"})
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freshnessTimestamp])

    return <LeadReviewProgress loading={loading} leadReviewData={lrData} />
}
