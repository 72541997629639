import React, {Component} from 'react';

import {formatCurrency, formatDate} from "../../Util";
import UpdateStatusPausedModal from "../campaign_manager/UpdateStatusPausedModal";
import {leadManagerUrl} from "../../service/LeadService";
import CostPerLeadModal from "../../common/CostPerLeadModal";
import {CampaignTypes, marketplaceIndustryScreenshots} from "../../common/StaticValues";
import AuthService from "../../service/AuthService";

export default class RecentCampaigns extends Component {
    constructor(props) {
        super(props);

        this.state = {
            //
            // Track which Campaigns had screenshots fail to load, to show placeholder.
            // A dictionary with campaign_ids as keys and true as the (throwaway) value.
            //
            screenshotsFailedToLoad: {},
        };

        //
        // IDs in the date_range table. TODO avoid hard-coding or find a better place.
        //
        // import {dateRangeOptions} from "../../common/StaticValues";
        this.DATE_RANGE_LAST_30_DAYS = 8;
    }

    updateModalContent = (modalContent, modalHeader, modalHeaderLink, modalWidth, modalFlatBottom) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalHeaderLink: modalHeaderLink,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom
        });
        //
        // If opening a new modal, clear message blocks
        //
        if (modalContent) {
            this.props.updateMessageBlocks([], 'success');
        }
    };

    enableCampaign = (campaign, index) => {
        this.props.updateModalContent(
            <UpdateStatusPausedModal
                updateModalContent={this.props.updateModalContent}
                campaign={campaign}
                campaignIndex={index}
                submitPutStatus={this.props.submitPutStatus}
                modalContainerRef={this.props.modalContainerRef}
            />,
            'Update Campaign Status',
            null,
            true
        );
    };

    getCampaignCpl(campaign) {
        return Array.isArray(campaign.tender)
            ? formatCurrency(campaign.tender[0]?.price_per_lead)
            : 'N/A';
    }

    openCostPerLeadModal = (campaign, index) => {
        this.props.updateModalContent(
            <CostPerLeadModal
                campaign={campaign}
                campaignIndex={index}
                submitCplChange={this.props.submitCplChange}
            />,
            `Edit Cost Per Lead for ${campaign.campaign_name}`,
            'normal',
            true
        );
    };

    /**
     * copy from CampaignManagerView until we build a shared component like LeadLog
     */
    isAffiliate(campaign) {
        return campaign.type === CampaignTypes.MARKETPLACE;
    }

    /**
     * copy from CampaignRow until we build a shared component like LeadLog
     * difference with the campaign reference
     */
    campaignScreenshot = (campaign) => {
        if (this.isAffiliate(campaign)) {
            let marketplaceIdx = campaign.industry_id in marketplaceIndustryScreenshots
                ? campaign.industry_id
                : 'generic';

            return marketplaceIndustryScreenshots.base + marketplaceIndustryScreenshots[marketplaceIdx];
        }

        return (campaign.campaign_id in this.state.screenshotsFailedToLoad || !campaign.screenshot_url)
            ? '/images/mySD-Campaigns-CampaignSiteScreenshotUnavailable.png'
            : campaign.screenshot_url;
    };

    render() {
        return (
            <ul className="recent-campaigns-list">
                {this.props.campaigns.map((campaign, idx) => {
                    if (!campaign.campaign_name || !campaign.tender) {
                        return null;
                    }

                    const paused = campaign.is_paused_to_client == 1;

                    let maybeCompanyNameJsx = campaign.company_name &&
                        <div className="type-small-body type-allcaps type-single-line-full spacing-10-bottom">
                            {campaign.company_name}
                        </div>;

                    return (
                        <li key={`campaign-${idx}`}>
                            <div
                                className="recent-campaigns-list__campaign-screenshot grid-image spacing-24-bottom-mobile">
                                <img
                                    className="campaigns__screenshot"
                                    src={this.campaignScreenshot(campaign)}
                                    alt={`${campaign.campaign_name} Website Screenshot`}
                                    onError={() =>
                                        //
                                        // Add this campaign's campaign_id to screenshotsFailedToLoad
                                        //
                                        this.setState({
                                            screenshotsFailedToLoad: {
                                                ...this.state.screenshotsFailedToLoad,
                                                [campaign.campaign_id]: true
                                            }
                                        })
                                    }
                                />
                            </div>
                            <div className="type-large-body type-narrow-line-height spacing-20-bottom grid-name">
                                {maybeCompanyNameJsx}
                                {campaign.campaign_name}
                            </div>
                            <div className="type-normal-body type-narrow-line-height grid-leads">
                                {campaign.count_of_leads_last_30_days > 0
                                    ? <a href={leadManagerUrl(this.DATE_RANGE_LAST_30_DAYS, campaign.campaign_id)}
                                         className="type-heavy type-small-headline">
                                        {campaign.count_of_leads_last_30_days}
                                        {' '}
                                        {campaign.count_of_leads_last_30_days == 1 ? 'Lead' : 'Leads'}
                                    </a>
                                    : <span className="type-small-headline type-heavy">0 Leads</span>}
                                {' '}
                                <span className="sub-text">Last 30 Days</span>
                            </div>
                            <div className="type-normal-body type-narrow-line-height spacing-20-bottom grid-cpl">
                                <span role="button" className="type-heavy type-blue type-small-headline"
                                      onClick={() => this.openCostPerLeadModal(campaign, idx)}>
                                    {this.getCampaignCpl(campaign)}
                                </span>
                                {' '}
                                <span className="sub-text">Cost Per Lead</span>
                            </div>
                            <div className="type-small-body type-narrow-line-height grid-status">
                                {paused
                                    ? <p className="type-small-body type-narrow-line-height">
                                        {AuthService.canUnpauseCampaign
                                            ? (campaign.auto_unpause_timestamp ?
                                                <>
                                                    Campaign is scheduled to<br/>
                                                    enable on {formatDate(campaign.auto_unpause_timestamp)}
                                                </>
                                                : <>Campaign is paused <b>indefinitely</b></>)
                                            : 'An Onboarding Specialist will be contacting you soon to enable this campaign'}
                                        {AuthService.canUnpauseCampaign &&
                                        <>
                                            <br/>
                                            <span role="button" className="type-heavy type-blue"
                                                  onClick={() => this.enableCampaign(campaign, idx)}>
                                            Enable Campaign
                                        </span>
                                        </>}
                                    </p>
                                    : <>
                                        <span className="type-force-newline">
                                            <a href={`/campaigns?campaign_id=${campaign.campaign_id}`}
                                               className="type-heavy">
                                                View in Campaigns Manager
                                            </a>
                                        </span>
                                        {!this.isAffiliate(campaign) &&
                                        <span className="type-force-newline">
                                            <a target="_blank" rel="noopener noreferrer" className="type-heavy"
                                               href={'https://' + campaign.url}>
                                                View Campaign Site
                                            </a>
                                        </span>}
                                    </>
                                }
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    }
}
