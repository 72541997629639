import React from 'react';

import {MoneyInput, parseMonetaryAmount} from "../../../common/components/form_elements/MoneyInput";
import {formatCurrency} from "../../../Util";

const JobRevenueBlock = (props) => {
    let revenuePlaceholder = props.industryRevenueAverage
        ? formatCurrency(props.industryRevenueAverage, 0) + " Category Avg."
        : "";

    let inputRef = React.createRef();

    let buttonClasses = 'button ui-normal-button';
    if (props.parsedButtonState.saving) {
        buttonClasses += ' button__green';
    }

    let handleChange = (event) => {
        event.target.value = parseMonetaryAmount(event.target.value);
        props.updateButtonState(props.buttonStateOptions.ENABLED);
    };

    let handleSubmit = (event) => {
        event.preventDefault();

        let clientRevenue = parseMonetaryAmount(inputRef.current.value,  true);
        inputRef.current.value = clientRevenue !== '' /* allow displaying of "0" value */
            ? clientRevenue.toFixed(2)
            : '';

        props.updateButtonState(props.buttonStateOptions.SAVING);
        props.updateLeadProgress({client_revenue: clientRevenue})
            .then(() => {
                props.updateButtonState(props.buttonStateOptions.DISABLED)
            })
            .catch(err => {
                props.updateButtonState(props.buttonStateOptions.DISABLED_SAVE)
            })
    };

    return (
        <>
        <div className="leadlog__revenue">
            <div className="no-margin-top spacing-34-bottom-full spacing-34-bottom-tablet">
                <form onSubmit={handleSubmit} className="flex-inline-field-with-button">
                    <MoneyInput
                        type="text"
                        className="ui-text-field ui-normal-text-input inline spacing-10-bottom-mobile"
                        name="job_revenue"
                        onChange={ handleChange }
                        onFocus={ () => props.updateButtonState(props.buttonStateOptions.ENABLED) }
                        required={false}
                        defaultValue={props.leadProgress.client_revenue}
                        hasError={false}
                        placeholder={revenuePlaceholder}
                        inputRef={inputRef}
                        uncontrolled={true}
                    />
                    <button
                        type="submit"
                        disabled={props.parsedButtonState.disabled}
                        className={buttonClasses}>
                        {props.parsedButtonState.label}
                    </button>
                </form>
            </div>
        </div>
        </>
    );
};

export default JobRevenueBlock;
