import { AgGridReact } from 'ag-grid-react'
import { GridOptions } from 'ag-grid-community'
import React, { useMemo, useRef } from 'react'
import getColumnDef from './getColumnDef'

const DataTable = ({
    rows,
    columns,
    onOrderBy,
    onEditHandler,
    loading,
}) => {
    const gridRef = useRef()
    const colState = useRef()

    const columnDefs = useMemo(() => columns.map((col, i) => {
        return getColumnDef(col, { loading }, onEditHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [])

    const defaultColDef = useMemo(() => ({
        resizable: true,
        icons: {
            // clear the default sorting icons
            sortAscending: () => '',
            sortDescending: () => '',
        },
        wrapHeaderText: true,
    }), [])

    const gridOptions: GridOptions = useMemo(() => ({
        onGridReady: (params) => {
            // apply a default sort to the data
            const defaultSortModel = [
                { colId: 'ad_network_name', sort: 'asc', sortIndex: 0 },
              ];
            params.columnApi.applyColumnState({ state: defaultSortModel })
        },
        onColumnResized: (params) => {
            if (params.finished) {
                // ignore column resizes from grid options changed
                if (params.source == "gridOptionsChanged") {
                    return
                }
                colState.current = params.columnApi.getColumnState()
            }
        },
        onRowDataUpdated: (params) => {
            // when new row data is provided, restore the current column state
            colState?.current && params.columnApi.applyColumnState(colState.current)
        },
        onFirstDataRendered: (params) => {
            // when the data is rendered, adjust the columns to fit
            params.api.sizeColumnsToFit()
        },
        suppressRowClickSelection: true,
        suppressRowHoverHighlight: true,
        suppressScrollOnNewData: true,
        overlayNoRowsTemplate: '<span>No Data</span>',
    }), [])

    const ROW_HEIGHT = 55
    const HEADER_HEIGHT = 80

    let tableHeight = Math.min(rows.length * ROW_HEIGHT + 100, window.innerHeight - 120)
    if (rows?.length === 0) {
        tableHeight = 400
    }

    return (
        <div
            className="ag-theme-alpine"
            style={{ width: '100%', height: `${tableHeight}px` }}
        >
            <AgGridReact
                rowData={rows}
                ref={gridRef}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowSelection="single"
                rowHeight={ROW_HEIGHT}
                headerHeight={HEADER_HEIGHT}
                gridOptions={gridOptions}
            />
        </div>
    )
}

export default DataTable
