import React from 'react';
import Tippy from "@tippyjs/react";
import queryString from "query-string";
import type {ValueGetterParams} from "ag-grid-community";

import {formattedTimeAndDate, sdAdminUrl} from "../../Util";
import ContextMenu from "../../common/components/ContextMenu";
import {CampaignTypes, ContractorTypeIds, externalBuyerStatusOptions, textOfOption} from "../../common/StaticValues";
import type {IIndustry} from "../../Interfaces/IIndustry";
import type {IExternalBuyer} from "../../Interfaces/IExternalBuyer";
import AuthService from "../../service/AuthService";

/**
 * generates a JSX button with the external lead buyer's name as text and the callback as the click action
 * @param {(eb: IExternalBuyer) => void} openEditExternalBuyerModal a callback function that opens the edit external lead buyer modal
 * @param {(eb: IExternalBuyer) => void} openEditLeadSourcesModal a callback function that opens the edit lead sources modal
 * @returns {Object} a JSX button
 */
export const cellDefEditExternalBuyerAnchor = (openEditExternalBuyerModal: (eb: IExternalBuyer) => void,
                                               openEditLeadSourcesModal: (eb: IExternalBuyer) => void,
                                               openTestPingPostModal: (eb: IExternalBuyer) => void,
                                            ) => {
    return (params: ValueGetterParams) => {
        const externalBuyer: IExternalBuyer = params.data;
        // build the Lead Manager URL based on the contractor ID and the EB static values
        const leadManagerUrl = queryString.stringifyUrl({
            url: '/leads', query: {
                client_ids: [externalBuyer.contractorId],
                contractor_type_ids: [ContractorTypeIds.ExternalBuyer],
                campaign_types: [CampaignTypes.EXTERNAL_BUYER]
            }
        }, {
            arrayFormat: 'bracket'
        });
        const clientMenuLinks = [
            {
                label: 'Edit External Lead Buyer',
                action: () => openEditExternalBuyerModal(externalBuyer)
            },
            {
                label: 'Add/Edit Service Categories',
                action: () => openEditLeadSourcesModal(externalBuyer)
            },
            {
                label: 'View Campaigns',
                url: `${sdAdminUrl}campaigns/?contractor_id=${externalBuyer.contractorId}`
            },
            {
                label: 'View Lead Manager',
                url: leadManagerUrl,
            },
        ];

        // for developers, and in a development environment, include the option to test ping post
        // process.env.REACT_APP_INCLUDE_TEST_CLIENT_LINKS is a reliable way to determine if the app is in dev mode
        if (AuthService.isDeveloper && process.env.REACT_APP_INCLUDE_TEST_CLIENT_LINKS == 1) {
            clientMenuLinks.unshift({
                label: 'Test Ping Post',
                action: () => openTestPingPostModal(externalBuyer)
            });
        }

        return <ContextMenu
            links={clientMenuLinks}
            linkLabel={externalBuyer.name}
            placement="bottom-start"
        />
    }
}

export const cellDefOnOff = (params: ValueGetterParams) =>
    params.getValue() == 1
        ? 'Yes'
        : 'No';

export const cellDefIndustries = (params: ValueGetterParams<IExternalBuyer, Element>) => {
    const industries: IIndustry[] = params.data.industries;

    // if there are no enabled industries, display a warning
    if (!industries?.length) {
        return '(0) No Service Category Enabled';
    }

    const label = industries.map((industry: IIndustry) => industry.name)
        .join(', ');

    const tooltipContent = <ul className='tool-tip__content'>
        {industries.map((industry: IIndustry) =>
            <li key={industry.industryId}>{industry.name}</li>
        )}
    </ul>;

    return <span className="type-regular">
        <Tippy
            placement="right"
            appendTo={() => document.body}
            content={tooltipContent}
            duration={0}
            interactive={true}
            allowHTML={true}

            theme="sd-tooltip"
            zIndex={1000}
        >
            <span className="industries-cell-tippy-wrapper">({industries.length}) {label}</span>
        </Tippy>
    </span>;
}

export const cellDefDate = (params: ValueGetterParams) => {
    const date = formattedTimeAndDate(params.getValue());
    return <span title={`${date.date} ${date.time} ${date.amPm}`}>{date.date}</span>;
}

export const cellDefStatus = (params: ValueGetterParams) => {
    const externalBuyer: IExternalBuyer = params.data;
    return textOfOption(externalBuyerStatusOptions, externalBuyer.status);
}

export const cellDefRelatedAdNetwork = (params: ValueGetterParams) => {
    return params.getValue() || 'None';
}

export const caseInsensitiveComparator = (a, b) =>
    a?.toLowerCase().localeCompare(b?.toLowerCase());

export const arrayLengthComparator = (a: Array, b: Array) =>
    b?.length - a?.length;
