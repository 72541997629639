import React, { Component } from 'react';

import BulkEditCamapaignsStep2Footer from './BulkEditCampaignsStep2Footer';
import PopUpModalInputSection from '../PopUpModalInputSection';
import { inputObj } from '../../formHelpers';

export default class BulkEditCampaignsStep2PhoneLeadDelivery extends Component {
    constructor(props) {
        super(props);

        let state = {
          //
          // store collections in the format PopUpModalInputSection expects
          // using inputObj().
          //
          // Note that the BulkEditCampaignsModalFrame also contains state
          // which receives an update to match this data prior to sending
          // the Bulk Edit request payload. That state however is just raw
          // values, not inputObj()'s.
          //
          forwarded_phone: [inputObj()],
          sms_lead_alert_multiple: [inputObj()],
        };

        //
        // Populate form data:
        // In case we got here through the Cancel link from Step 3:
        // populate the state using the formData prop passed in
        // NOTE - similar code exists in BulkEditCampaignStep2FormLeadDelivery.js
        //
        let keys = ["forwarded_phone", "sms_lead_alert_multiple"];
        for (let i=0; i < keys.length; i++) {
            if (props.formData[ keys[i] ].length) {
                state[ keys[i] ] = props.formData[ keys[i] ].map((item) => {
                    return inputObj(item);
                });
            }
        }

        this.state = state;
    }


    /**
     * NOTE - the following 3 functions are duplicated in common with
     * BulkEditCampaignsStep2FormLeadDelivery.js - could factor together
     */

    /**
     * onChange handler for when one of the inputs changes.
     * Goes to lengths to avoid mutating deeply nested state.
     * @param event
     * @param collectionName
     * @param key
     */
    handleLeadDeliveryInputChange = (event, collectionName, key) => {
      let elem = event.target;

      let collectionClone = [...this.state[collectionName]];

      let collectionItemClone = {...collectionClone[key]};
      collectionClone[key] = collectionItemClone;

      //
      // This whole function is just to do this: O_o
      //
      collectionItemClone.value = elem.value;

      let stateChanges = {};
      stateChanges[collectionName] = collectionClone;
      this.setState(stateChanges);
    };

    addAnotherLeadDeliveryInput = (collectionName) => {
      let collectionClone = [...this.state[collectionName]];

      //
      // Add the new item
      //
      collectionClone.push(inputObj());

      let stateChanges = {};
      stateChanges[collectionName] = collectionClone;
      this.setState(stateChanges);
    };

    deleteLeadDeliveryInput = (collectionName, key) => {
      let collectionClone = [ ...this.state[collectionName] ];
      collectionClone.splice(key,1);

      let stateChanges = {};
      stateChanges[collectionName] = collectionClone;
      this.setState(stateChanges);
    };



    render() {
        const phoneInputs = (
            <PopUpModalInputSection
                collection={this.state.forwarded_phone}
                collectionName="forwarded_phone"
                name="callLeadNumbers"
                handleInputChange={this.handleLeadDeliveryInputChange}
                addAnother={this.addAnotherLeadDeliveryInput}
                deleteInput={this.deleteLeadDeliveryInput}
                handleInputBlur={
                    // Adding this NULL function addresses https://app.asana.com/0/918610915373744/1116913922875836/f
                    // TODO - are we missing out on any front-end error handling that we want?
                    (event, collectionName, key) => {} /*this.setState(
                        this.updateStateObjectError(event, {...this.state}, collectionName, key)
                    )*/
                }
                placeholderOnFirst={"leave blank if no change required"}
            />
        );
        /*
        const phoneLeadEmailInputs = (
            <PopUpModalInputSection
                collection={[inputObj()]}
                collectionName="leadDeliveryEmails"
                name="callAndFormLeadEmails"
                handleInputChange={this.props.handleInputChange}
                addAnother={null}
                deleteInput={null}
                handleInputBlur={
                    // Adding this NULL function addresses https://app.asana.com/0/918610915373744/1116913922875836/f
                    // TODO - are we missing out on any front-end error handling that we want?
                    (event, collectionName, key) => {}
                    //this.setState(
                    //    this.updateStateObjectError(event, {...this.state}, collectionName, key)
                    //)
                }
            />
        );
        */
        const phoneLeadSmsInputs = (
            <PopUpModalInputSection
                collection={this.state.sms_lead_alert_multiple}
                collectionName="sms_lead_alert_multiple"
                name="callAndFormLeadSmsPhones"
                handleInputChange={this.handleLeadDeliveryInputChange}
                addAnother={this.addAnotherLeadDeliveryInput}
                deleteInput={this.deleteLeadDeliveryInput}
                handleInputBlur={
                    // Adding this NULL function addresses https://app.asana.com/0/918610915373744/1116913922875836/f
                    // TODO - are we missing out on any front-end error handling that we want?
                    (event, collectionName, key) => {} /*this.setState(
                        this.updateStateObjectError(event, {...this.state}, collectionName, key)
                    )*/
                }
                placeholderOnFirst={"leave blank if no change required"}
            />
        );

        //
        // Create the list of campaigns along with their current setting
        //
        let campaignsList = this.props.campaigns.map((campaign, idx) => {
            if (campaign.campaign_id in this.props.selectedCampaignIds) {
                let valueTxt1 = this.props.getOptionValueTxt('phone_lead_delivery', campaign.phones);
                let valueTxt2 = this.props.getOptionValueTxt('phone_lead_delivery', campaign.lead_notification_phones);
                return <div key={idx} className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    <span className="type-large-body type-force-newline type-heavy">
                        {typeof(campaign.company_name) !== 'undefined'
                            ? <>{campaign.company_name} &mdash; </>
                            : ''}
                        {campaign.campaign_name}
                    </span>
                    <span className="type-normal-body type-force-newline">Phone Number(s) for inbound Leads: { valueTxt1 }</span>
                    <span className="type-normal-body type-force-newline">Text Message Notification of Lead: { valueTxt2 }</span>
                </div>
            } else {
                return null;
            }
        });

        return (
            <>
                <div className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    <p className="type-large-body">
                        You are Bulk Editing the <b>Phone Lead Delivery</b> for the Campaigns listed below.
                    </p>
                    <p className="type-normal-body">
                        Current Campaign settings are noted individually.
                        <> </>
                        All current settings will be <span className="type-alert type-heavy">replaced</span> by the new ones you set here.
                        <> </>
                        If a field is left blank, no changes will be applied to that setting.
                    </p>
                </div>
                <div className="spacing-60-bottom-full spacing-60-bottom-tablet spacing-40-bottom-mobile">
                  {campaignsList}
                </div>

                <p className="type-large-body type-heavy type-single-line type-bottom-stroke no-margin-top spacing-30-bottom-full spacing-30-bottom-tablet spacing-20-bottom-mobile">
                    NEW SETTINGS (Replaces current settings)
                </p>

                <div className="popup__form__row">
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile popup__form__cell__top-align">
                      <p className="type-large-body type-heavy type-single-line no-margin-top spacing-20-bottom">
                        Phone Lead Delivery
                      </p>
                      <div className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                        <label htmlFor="popup-call-leads" className="type-normal-body type-narrow-line-height type-force-newline spacing-5-bottom">
                          The following phone number(s) will receive inbound calls from Phone Leads:
                        </label>
                        {phoneInputs}
                      </div>
                    </div>
                    {/**
                      * This one moved up here from the lower row because we still
                      * don't have all the new form fields implemented on the backend yet
                      */}
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile popup__form__cell__top-align">
                      <p className="type-large-body type-heavy type-single-line no-margin-top spacing-20-bottom">
                        Phone Lead Notifications: Text Message
                      </p>
                      <div className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                        <label htmlFor="popup-phone-leads-sms" className="type-normal-body type-narrow-line-height type-force-newline spacing-5-bottom">
                          The following mobile phone numbers will receive Text Message Notifications when a Phone Lead completes:
                        </label>
                        {phoneLeadSmsInputs}
                      </div>
                    </div>
                </div>
              {/*
                <div className="popup__form__row spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile popup__form__cell__top-align">
                        <p className="type-large-body type-heavy type-single-line no-margin-top spacing-20-bottom">
                            Phone Lead Notifications: Email
                        </p>
                        <div className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                            <label htmlFor="popup-phone-leads-email" className="type-normal-body type-narrow-line-height type-force-newline spacing-5-bottom">
                                The following email addresses will receive Email Notifications when a Phone Lead completes:
                            </label>
                            {phoneLeadEmailInputs}
                        </div>
                    </div>
                    <div className="popup__form__cell popup__form__cell__50 popup__form__cell__100__mobile popup__form__cell__top-align">
                        <p className="type-large-body type-heavy type-single-line no-margin-top spacing-20-bottom">
                            Phone Lead Notifications: Text Message
                        </p>
                        <div className="spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                            <label htmlFor="popup-phone-leads-sms" className="type-normal-body type-narrow-line-height type-force-newline spacing-5-bottom">
                                The following mobile phone numbers will receive Text Message Notifications when a Phone Lead completes:
                            </label>
                            {phoneLeadSmsInputs}
                        </div>
                    </div>
                </div>
                */}

                <BulkEditCamapaignsStep2Footer
                    commitSettings={ this.props.commitSettings }
                    updateModalContent={ this.props.updateModalContent }
                    updateModalHeader={ this.props.updateModalHeader }
                    confirmationHeader="Phone Lead Delivery Confirmation"
                    leadDeliverySettings={ this.state }
                />
            </>
        );
    }
}
