import React, {Component} from 'react';

import apiUrl from "../Config";

export default class CreateTestClient extends Component {
    constructor(props) {
        super(props);
        document.title = 'Create Test Client';
    }

    createTestAccountLink(step) {
        const baseUrl = `${apiUrl}signup/create_test_client?step_number=${step}&step_name=`;

        switch (step) {
            case 1:
                return `${baseUrl}account_setup`;

            case 2:
                return `${baseUrl}your_company`;

            case 3:
                return `${baseUrl}building_your_campaigns`;

            // no default
        }
    }

    render() {
        if (!process.env.REACT_APP_INCLUDE_TEST_CLIENT_LINKS) {
            return <div>This feature is for dev environments only</div>;
        }

        return <section className="interface-container">
            <div className="page-width">
                <div className="row padding-50-top padding-20-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8">
                        <div className="module">
                            <div className="module__header module__twothirdswidth padding-20-top">
                                <h3 className="type-large-subhead type-single-line">
                                    Create Test Client for mySD Account Activation
                                </h3>
                            </div>
                            <div className="spacing-40-bottom module__contentbox module__twothirdswidth">
                                <p className="spacing-10-bottom">
                                    Use these links to create a test client to view any step of the Account
                                    Activation.
                                </p>
                                <ul>
                                    <li className='spacing-10-bottom'>
                                        <a className="qa-step-one-link" target="_blank"
                                           href={this.createTestAccountLink(1)}>
                                            Step 1 - Account Setup
                                        </a>
                                    </li>
                                    <li className='spacing-10-bottom'>
                                        <a className="qa-step-two-link" target="_blank"
                                           href={this.createTestAccountLink(2)}>
                                            Step 2 - Campaign Setup
                                        </a>
                                    </li>
                                    <li className='spacing-10-bottom'>
                                        <a className="qa-step-three-link" target="_blank"
                                           href={this.createTestAccountLink(3)}>
                                            Step 3 - Campaign Production
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
            </div>
        </section>;
    }
}
