export const footerLinks = {
    dashboard: [
        {
            label: 'Using the Lead Manager',
            urlSelect: 'https://support.servicedirect.com/select/lead-manager',
            urlMarketplace: 'https://support.servicedirect.com/lead-manager',
        },
        {
            label: 'Managing Your Leads',
            urlSelect: 'https://support.servicedirect.com/select/managing-your-leads',
            urlMarketplace: 'https://support.servicedirect.com/managing-your-leads',
        },
        {
            label: 'Controlling Your Lead Volume',
            urlSelect: 'https://support.servicedirect.com/select/lead-volume',
            urlMarketplace: 'https://support.servicedirect.com/lead-volume',
        },
        {
            label: 'Using the Campaigns Manager',
            urlSelect: 'https://support.servicedirect.com/select/campaigns-manager',
            urlMarketplace: 'https://support.servicedirect.com/campaigns-manager',
        },
    ],
    clients: [
        {
            label: 'Managing Users and Notifications',
            urlSelect: 'https://support.servicedirect.com/select/manage-users',
            urlMarketplace: 'https://support.servicedirect.com/manage-users',
        },
        {
            label: 'Bulk Edit Your Campaign Settings',
            urlSelect: 'https://support.servicedirect.com/select/bulk-edit',
            urlMarketplace: 'https://support.servicedirect.com/bulk-edit',
        },
        {
            label: 'Using the Lead Manager',
            urlSelect: 'https://support.servicedirect.com/select/lead-manager',
            urlMarketplace: 'https://support.servicedirect.com/lead-manager',
        },
        {
            label: 'How to Pause or Enable a Campaign',
            urlSelect: 'https://support.servicedirect.com/select/pause-or-enable-campaign',
            urlMarketplace: 'https://support.servicedirect.com/pause-or-enable-campaign',
        },
        {
            label: 'How You Are Billed',
            urlSelect: 'https://support.servicedirect.com/select/how-you-are-billed',
            urlMarketplace: 'https://support.servicedirect.com/how-you-are-billed',
        }
    ],
    leads: [
        {
            label: 'Controlling Your Lead Volume',
            urlSelect: 'https://support.servicedirect.com/select/lead-volume',
            urlMarketplace: 'https://support.servicedirect.com/lead-volume',
        },
        {
            label: 'How to Pause or Enable a Campaign',
            urlSelect: 'https://support.servicedirect.com/select/pause-or-enable-campaign',
            urlMarketplace: 'https://support.servicedirect.com/pause-or-enable-campaign',
        },
        {
            label: 'Managing Your Leads',
            urlSelect: 'https://support.servicedirect.com/select/managing-your-leads',
            urlMarketplace: 'https://support.servicedirect.com/managing-your-leads',
        },
        {
            label: 'Using the Lead Manager',
            urlSelect: 'https://support.servicedirect.com/select/lead-manager',
            urlMarketplace: 'https://support.servicedirect.com/lead-manager',
        },
    ],
    campaigns: [
        {
            label: 'Using the Campaigns Manager',
            urlSelect: 'https://support.servicedirect.com/select/campaigns-manager',
            urlMarketplace: 'https://support.servicedirect.com/campaigns-manager',
        },
        {
            label: 'How to Pause or Enable a Campaign',
            urlSelect: 'https://support.servicedirect.com/select/pause-or-enable-campaign',
            urlMarketplace: 'https://support.servicedirect.com/pause-or-enable-campaign',
        },
        {
            label: 'Request a Select Campaign Microsite Content Edit',
            urlSelect: 'https://support.servicedirect.com/select/campaign-microsite-edit',
            hideForMarketplace: true
        },
        {
            label: 'Understanding Select Campaigns',
            urlSelect: 'https://support.servicedirect.com/select/select-campaigns',
            hideForMarketplace: true
        },
    ],
    billing: [
        {
            label: 'How You Are Billed',
            urlSelect: 'https://support.servicedirect.com/select/how-you-are-billed',
            urlMarketplace: 'https://support.servicedirect.com/how-you-are-billed',
        },
        {
            label: 'Controlling Your Lead Volume',
            urlSelect: 'https://support.servicedirect.com/select/lead-volume',
            urlMarketplace: 'https://support.servicedirect.com/lead-volume',
        },
        {
            label: 'Manage Payment Methods',
            urlSelect: 'https://support.servicedirect.com/select/payment-methods',
            urlMarketplace: 'https://support.servicedirect.com/payment-methods',
        },
        {
            label: 'Using the Billing Center',
            urlSelect: 'https://support.servicedirect.com/select/billing-center',
            urlMarketplace: 'https://support.servicedirect.com/billing-center'
        },
    ],
    users: [
        {
            label: 'Manage Users, Notifications & Reset Passwords',
            urlSelect: 'https://support.servicedirect.com/select/manage-users',
            urlMarketplace: 'https://support.servicedirect.com/manage-users',
        },
        {
            label: 'Managing Your Leads',
            urlSelect: 'https://support.servicedirect.com/select/managing-your-leads',
            urlMarketplace: 'https://support.servicedirect.com/managing-your-leads',
        },
        {
            label: 'How to Pause or Enable a Campaign',
            urlSelect: 'https://support.servicedirect.com/select/pause-or-enable-campaign',
            urlMarketplace: 'https://support.servicedirect.com/pause-or-enable-campaign',
        },
    ],
    selectEnroll: [
        {
            label: 'Select Program Enrollment & Program Overview',
            urlSelect: 'https://support.servicedirect.com/select/select-program-enrollment-guide',
            hideForMarketplace: true
        }
    ]
};

export const footerHeaders = {
    dashboard: 'Helpful Tips About mySD',
    clients: 'Helpful Tips About Client Accounts',
    leads: 'Helpful Tips About Leads',
    campaigns: 'Helpful Tips About Campaigns',
    billing: 'Helpful Tips About Billing',
    users: 'Helpful Tips About mySD',
    selectEnroll: 'Helpful Tips About Select Enrollment'
};
