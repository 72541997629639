import axios from 'axios';
import apiUrl from './Config';
import AuthService from "./service/AuthService";

const defaultConfig = (cancelSignal) => {
    // console.log("cancelSignal", cancelSignal);
    let config = { // axios config
        validateStatus: (status) => {
            //
            // NOTE: it might seem like "status >= 200" makes more sense - and it does.
            // However, we currently rely on our axios "error" interceptor to fire for 303s
            // to handle the (non-error-related) functionality of redirecting, e.g. from one
            // Account Activation page to the next.
            //
            return status <= 200 && status < 400;
        },
        baseURL: apiUrl,
        cancelToken: cancelSignal
    };

    if (AuthService.isAuthenticated && AuthService.token) {
        config.headers = {
            'Authorization': `Bearer ${AuthService.token}`
        };
    }

    return config;
};

/**
 * API error handler
 * returns true iff it is NOT a cancel "error" and should be handled
 * @param {any} error
 * @return {boolean}
 */
export function errorHandler(error) {
    if (!axios.isCancel(error)) {
        return true;
    }
}

export function post(url, data, cancelSignal) {
    return axios.post(url, data, defaultConfig(cancelSignal));
}

export function get(url, cancelSignal) {
    return axios.get(url, defaultConfig(cancelSignal));
}

export function put(url, data, cancelSignal) {
    return axios.put(url, data, defaultConfig(cancelSignal));
}

export function del(url, cancelSignal) {
    return axios.delete(url, defaultConfig(cancelSignal));
}

export function patch(url, data, cancelSignal) {
    return axios.patch(url, data, defaultConfig(cancelSignal));
}

export default post;
