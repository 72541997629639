import React, { Component } from 'react';
import { MoneyInput } from '../common/components/form_elements/MoneyInput';

export default class CostPerLeadForm extends Component {
    render() {
        return (
            <>
                <div className="popup__form__row spacing-10-bottom">
                    <div className="popup__form__cell popup__form__cell__label popup__form__cell__40 popup__form__cell__100__mobile">
                        <label htmlFor="popup-cpl-cpl" className="type-small-body type-heavy type-narrow-line-height no-margin">
                            Cost Per Lead
                        </label>
                    </div>
                </div>
                <div className="popup__form__row spacing-30-bottom-full spacing-30-bottom-tablet spacing-24-bottom-mobile">
                    <div className="popup__form__cell popup__form__cell__40 popup__form__cell__100__mobile spacing-10-bottom-mobile">
                        <MoneyInput
                            id="popup-cpl-cpl"
                            name="cpl"
                            defaultValue={this.props.value}
                            onChange={this.props.onChange}
                        />
                    </div>
                    <div className="popup__form__cell popup__form__cell__60 popup__form__cell__100__mobile">
                        <p className="type-small-body no-margin">
                            Based on your business type and location, we recommend a Cost Per Lead of <b>${this.props.min}-{this.props.max}</b>.
                        </p>
                    </div>
                </div>
            </>
        );
    }
}
