import React, {Component} from 'react';

import TermsAndConditions from "./TermsAndConditions";
import Modal from "../common/Modal";
import PrivacyPolicy from "./PrivacyPolicy";
import AuthService from "../service/AuthService";

/**
 * Expected props
 * whiteLabelPartnership
 */
export default class Footer extends Component {
    constructor(props) {
        super(props);

        this.modalScrollerRef = React.createRef();

        this.state = {
            modalContent: undefined,
            modalHeader: undefined,
            modalWidth: undefined,
            modalFlatBottom: undefined,
        };
    }

    updateModalContent = (modalContent, modalHeader, modalWidth, modalFlatBottom) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom,
        });
    };

    render() {
        return <>
            <Modal
                content={this.state.modalContent}
                header={this.state.modalHeader}
                width={this.state.modalWidth}
                flatBottom={this.state.modalFlatBottom}
                updateModalContent={this.updateModalContent}
                scrollerRef={this.modalScrollerRef}
            />
            <section className="grey-background padding-50-top padding-80-bottom">
                {this.props.footerHeader &&
                <p className="type-normal-subhead type-heavy type-single-line type-centered spacing-24-bottom">
                    {this.props.footerHeader}
                </p>}
                {this.props.footerLinks &&
                <p className="type-large-body type-heavy type-centered spacing-24-bottom">
                    {this.props.footerLinks.map((link, key) => {
                        if (AuthService.isMarketplace && link.hideForMarketplace) {
                            return null;
                        }

                        return <span className="type-force-newline" key={key}>
                                <a href={AuthService.isMarketplace ? link.urlMarketplace : link.urlSelect}
                                   target="_blank" rel="noopener">{link.label}</a>
                            </span>
                    })}
                </p>}
                <p className="type-large-body type-heavy type-single-line type-centered spacing-24-bottom">
                    <a href={AuthService.isMarketplace
                        ? "https://support.servicedirect.com/get-support"
                        : "https://support.servicedirect.com/select/get-support"}
                       target="_blank">How to Get Support</a>
                </p>
                <p className="type-large-body type-heavy type-single-line type-centered">
                    <a href='https://info.servicedirect.com/service-direct-product-updates-and-release-notes'
                       target="_blank">Service Direct Product Updates</a>
                </p>
                {this.props.is404 &&
                <div className="space-404-page"/>}
            </section>
            <section className="padding-80-top padding-50-bottom grey-background">
                <p className="type-small-body type-centered type-single-line ui-hide-mobile">
                        <span className="type-right-side-bump">
                            &copy;{new Date().getFullYear()} {this.props.whiteLabelPartnership.white_label_name}
                        </span>
                    <span className="type-heavy type-right-side-bump type-blue" role="button"
                          onClick={() => this.updateModalContent(
                              <TermsAndConditions whiteLabelPartnership={this.props.whiteLabelPartnership}
                                                  inAccountActivation={false}
                              />,
                              this.props.whiteLabelPartnership.white_label_name + " Terms and Conditions"
                          )}
                    > Terms and Conditions </span>
                    <span className="type-heavy type-blue" role="button"
                          onClick={() => this.updateModalContent(
                              <PrivacyPolicy whiteLabelPartnership={this.props.whiteLabelPartnership}/>,
                              this.props.whiteLabelPartnership.white_label_name + " Privacy Policy"
                          )}
                    > Privacy Policy </span>

                </p>
                <p className="type-small-body type-centered ui-hide-full ui-hide-tablet">
                    <span className="type-force-newline">&copy;{new Date().getFullYear()} Service Direct</span>
                    <span className="type-force-newline">
                            <span className="type-heavy type-right-side-bump type-blue" role="button"
                                  onClick={() => this.updateModalContent(
                                      <TermsAndConditions whiteLabelPartnership={this.props.whiteLabelPartnership}
                                                          inAccountActivation={false}
                                      />,
                                      this.props.whiteLabelPartnership.white_label_name + " Terms and Conditions"
                                  )}
                            > Terms and Conditions </span>
                            <span className="type-heavy type-blue" role="button"
                                  onClick={() => this.updateModalContent(
                                      <PrivacyPolicy whiteLabelPartnership={this.props.whiteLabelPartnership}/>,
                                      this.props.whiteLabelPartnership.white_label_name + " Privacy Policy"
                                  )}
                            > Privacy Policy </span>
                        </span>
                </p>
            </section>
        </>;
    }
}
