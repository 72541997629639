import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Input from "./form_elements/Input";
import {maskExpDate} from "../../Util";

export default class CreditCardForm extends Component {
    render() {
        let name = this.props.name;
        let number = this.props.number;
        let expiration = this.props.expiration;
        let cvv = this.props.cvv;
        let zip = this.props.zip;
        let makePrimary = this.props.makePrimary;
        let required = this.props.required;

        let rowSpacing = this.props.inPopup ? "spacing-20-bottom" : "spacing-30-bottom";
        let cellLabel = this.props.inPopup ? "popup__form__cell" : "form__cell";
        let rowLabel = this.props.inPopup ? "popup__form__row" : "form__row";

        let cellWidth75 = this.props.inPopup ? "popup__form__cell__75" : "form__cell__75";
        let cellWidth50 = this.props.inPopup ? "popup__form__cell__50" : "form__cell__50";
        let cellWidth25 = this.props.inPopup ? "popup__form__cell__25" : "form__cell__25";
        let mobileCellWidth50 = this.props.inPopup ? "popup__form__cell__50__mobile" : "form__cell__50__mobile";

        return (
            <form id="credit-card-form">
                <div className={`${rowLabel} ${rowSpacing}`}>
                    <div className={`${cellLabel} ${cellWidth50}`}>
                        <Input
                            inputRef={name.ref}
                            label="Name on Card"
                            type="text"
                            name="cc_name"
                            value={name.value}
                            onChange={this.props.onChange}
                            onBlur={this.props.onBlur}
                            hasError={!!name.error}
                            required={required}
                            autoComplete="cc-name"
                        />
                    </div>
                    <div className={`${cellLabel} ${cellWidth50} popup__form__cell__bottom-align padding-10-bottom`}>
                        {makePrimary &&
                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                name="make_primary"
                                id="popup-cc-edit-primary"
                                checked={makePrimary.value}
                                onChange={this.props.onChange}
                            />
                            <label htmlFor="popup-cc-edit-primary">
                                <span className="checkbox">Make Primary Card</span>
                            </label>
                        </div>}
                    </div>
                </div>
                <div className={`${rowLabel} ${rowSpacing}`}>
                    <div className={`${cellLabel} ${cellWidth75} ${mobileCellWidth50}`}>
                        <Input
                            inputRef={number.ref}
                            type="text"
                            name="cc_number"
                            value={number.value}
                            onChange={this.props.onChange}
                            onBlur={this.props.onBlur}
                            hasError={!!number.error}
                            label="Credit Card Number"
                            required={required}
                            autoComplete="cc-number"
                        />
                    </div>
                    <div className={`${cellLabel} ${cellWidth25} ${mobileCellWidth50}`}>
                        <Input
                            name="cc_expiration"
                            label="Expiration"
                            type="text"
                            inputRef={expiration.ref}
                            value={expiration.value}
                            hasError={!!expiration.error}
                            onKeyUp={(event) => {
                                event.target.value = maskExpDate(event);
                                this.props.onChange(event);
                            }}
                            onChange={this.props.onChange}
                            onBlur={this.props.onBlur}
                            required={required}
                            placeholder="MM/YY"
                            maxLength="5"
                        />
                    </div>
                </div>
                <div className={`${rowLabel} ${rowSpacing}`}>
                    <div className={`${cellLabel} ${cellWidth50}`}>
                        <Input
                            name="cc_zip"
                            label="Billing Zip / Postal Code"
                            type="text"
                            onChange={this.props.onChange}
                            onBlur={this.props.onBlur}
                            required={required}
                            value={zip.value}
                            hasError={!!zip.error}
                            inputRef={zip.ref}
                            autoComplete="cc-exp-year"
                        />
                    </div>
                    <div className={`${cellLabel} ${cellWidth25} ${mobileCellWidth50}`}>
                        <Input
                            name="cc_cvv"
                            label="CVV"
                            type="text"
                            value={cvv.value}
                            hasError={!!cvv.error}
                            onChange={this.props.onChange}
                            onBlur={this.props.onBlur}
                            required={required}
                            inputRef={cvv.ref}
                            autoComplete="cc-csc"
                        />
                    </div>
                </div>
            </form>
        );
    }
}

CreditCardForm.propTypes = {
    name: PropTypes.object.isRequired,
    number: PropTypes.object.isRequired,
    expiration: PropTypes.object.isRequired,
    cvv: PropTypes.object.isRequired,
    zip: PropTypes.object.isRequired,
    makePrimary: PropTypes.object,
    required: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func
};
