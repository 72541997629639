import React, { Fragment, useRef, useState } from 'react'
import sortBy from 'lodash/sortBy'
import Button from '../../../../../common/components/form_elements/Button'
import MultiSelectList from '../../../../../common/components/form_elements/MultiSelectList'
import { ContentWindowScroll } from '../../../../../common/Modal'
import { ActionsWrapper, InputWrapper } from '../styles'
import NewNumber from './NewNumber'
import { ContentWrapper, Divider, NewNumberWrapper, Wrapper } from './styles'
import { findHighestValue } from '../../../../../Util'

export default function CreateNewNumber(props) {
    const {
        affiliates,
        serviceCategories,
        onClose,
        onPreview,
        changes,
        selectedAffiliate,
        newNumberTemplate,
    } = props
    const [selectedAffiliateId, setSelectedAffiliateId] = useState(
        selectedAffiliate?.value
    )
    const [_selectedAffiliate, setSelectedAffiliate] = useState(selectedAffiliate)
    // initialize to the highest id of incoming changes
    const highestNewId = findHighestValue(changes, '_id', 0)
    const newNumberId = useRef(highestNewId + 1)
    const [numberConfigs, setNumberConfigs] = useState(changes || [])

    const handleSelectAffiliate = async (ids) => {
        setSelectedAffiliateId(ids[0])
        const a = affiliates?.filter((a) => a.value === ids[0])[0]
        setSelectedAffiliate(a)

        // if there are no existing changes, intialize with one empty number so that
        // the user experience feels more natural
        if (!numberConfigs || numberConfigs.length == 0) {
            handleAddAnotherNumber()
        }
    }

    const handleAddAnotherNumber = () => {
        // create a new number using a temporary id and action
        // along with the template supplied by the api
        const newNumber = Object.assign(
            {},
            {
                // _id: `new_${newNumberId.current}`,
                _id: newNumberId.current,
                action: 'create',
            },
            newNumberTemplate
        )
        newNumberId.current++
        setNumberConfigs([...numberConfigs, newNumber])
    }

    const handleRemoveNumber = (id) => {
        setNumberConfigs([...numberConfigs.filter((n) => n._id != id)])
    }

    // handle the config update by replacing the configuration in the array of new configs
    const handleUpdateNumber = (id, config) => {
        // ensure sorting is respected
        const newConfigs = sortBy(
            [...numberConfigs.filter((n) => id != n._id), config],
            '_id'
        )
        setNumberConfigs(newConfigs)
    }

    const newNumbersView = numberConfigs?.map((n) => (
        <NewNumber
            key={`new_${n._id}`}
            serviceCategories={serviceCategories}
            configuration={n}
            onUpdateNumber={handleUpdateNumber}
            onRemoveNumber={handleRemoveNumber}
        />
    ))

    // function the check if all new numbers are configured
    const areAllNewNumbersConfigured = () => {
        // if an affiliate has not been selected, return false
        if (!selectedAffiliateId) {
            return false
        }
        // all numbers are configured if the industry id is set
        // the whisper setting is an unclearable radio button so it is
        // always set to true or false
        // if an area code has been set, it should either be empty, or
        // it should be 3 characters exactly
        for (const n of numberConfigs) {
            if (!n.industry_id) {
                return false
            }
            if (n.area_code && (n.area_code.length != 0 && n.area_code.length != 3)) {
                return false
            }

        }
        return true
    }

    return (
        <Fragment>
            <ContentWindowScroll>
                <Wrapper>
                    <ContentWrapper>
                        <NewNumberWrapper>
                            <InputWrapper>
                                {/* select affiliate */}
                                <MultiSelectList
                                    name="lead_source"
                                    label="Lead Source"
                                    emptyLabel="Required"
                                    useTextFilter
                                    selections={
                                        selectedAffiliateId ? [selectedAffiliateId] : null
                                    }
                                    options={affiliates}
                                    onChange={handleSelectAffiliate}
                                />
                            </InputWrapper>
                            <InputWrapper />
                        </NewNumberWrapper>
                        {newNumbersView}
                        <Divider />
                        <InputWrapper>
                            <div>
                                <Button
                                    size="small"
                                    style={{ margin: 0 }}
                                    onClick={handleAddAnotherNumber}
                                    disabled={!areAllNewNumbersConfigured()}
                                >
                                    Add Another Service Category DID Number
                                </Button>
                            </div>
                        </InputWrapper>
                    </ContentWrapper>
                </Wrapper>
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button onClick={onClose} variant="text">
                    <span className="type-heavy">Cancel</span>
                </Button>
                <Button
                    disabled={numberConfigs?.length == 0 || !areAllNewNumbersConfigured()}
                    onClick={() => {
                        onPreview &&
                            onPreview({
                                changes: numberConfigs,
                                affiliate: _selectedAffiliate,
                            })
                    }}
                >
                    Continue To Confirmation
                </Button>
            </ActionsWrapper>
        </Fragment>
    )
}
