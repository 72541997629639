import styled from 'styled-components'

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    /* grid-auto-rows: 40px; */
    grid-gap: 10px;
    padding: 0 20px;
`

export const GridCol = styled.div`
    grid-column: span ${props => props?.span || '1'};
`

export const NoteBox = styled.div`
    border: 1px #a1a1a1 solid;
    display: block;
    background-color: #fafffa;
    padding: 1.1rem;
    line-height: initial;
`
