import React, { useEffect, useState } from 'react'
import Button from '../../../../common/components/form_elements/Button'
import LoadingGif from '../../../../common/components/LoadingGif'
import { FullColumn } from '../../../../common/layouts'
import { ContentRow, Actions } from '../styles'
import { getIntBetween } from '../../../../Util'
import { LoadingMessages } from '../../../../common/StaticValues'

export default ({ total, completed, remaining }) => {
    const [phrase, setPhrase] = useState('Hang tight! Edits in progress.')
    const PHRASE_UPDATE_INTERVAL = 10000 // milliseconds

    useEffect(() => {
        function updatePhrase() {
            const nextPhrase =
                LoadingMessages[getIntBetween(0, LoadingMessages.length - 1)]
            setPhrase(nextPhrase)
        }
        const interval = setInterval(updatePhrase, PHRASE_UPDATE_INTERVAL)
        return () => clearInterval(interval)
    }, [])

    let progressText = (
        <span>
            <span className="type-heavy">{completed == null ? 'NULL' : completed}</span> of{' '}
            <span className="type-heavy">{total == null ? 'NULL' : total}</span> complete
        </span>
    )
    if (total == null && completed == null) {
        progressText = 'Checking progress - hang tight'
    }
    if (remaining === 0) {
        progressText = 'All done! Reloading...'
    }

    return (
        <FullColumn>
            <ContentRow>
                <span className="type-normal-subhead">{phrase}</span>
            </ContentRow>
            <ContentRow>
                <LoadingGif backgroundColor="white" size="large" />
            </ContentRow>
            <ContentRow>{progressText}</ContentRow>
            <ContentRow />
            <Actions>
                <Button disabled={true} variant="text">
                    <span className="type-heavy">Cancel</span>
                </Button>
                <Button disabled={true}>Hang Tight</Button>
            </Actions>
        </FullColumn>
    )
}
