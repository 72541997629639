import React, {Component} from 'react';
import type {AxiosResponse} from 'axios';
import axios from 'axios';
import {Redirect, Route, Switch} from 'react-router-dom';

import {get} from "./Requests";
import type {AuthData} from "./service/TokenService";
import TokenService from "./service/TokenService";
import ClientApp from './client/ClientApp';
import AdminApp from './admin/AdminApp';
import SignupView from "./client/signup/common/SignupView";
import SignupMarketplaceView from "./signup-marketplace/signup-marketplace-view";
import SelfServeOnboardingView from "./onboarding/self-serve-onboarding-view";
import AwaitingOnboardingView from "./onboarding/AwaitingOnboardingView";
import Login from "./common/Login";
import RouteNotFound from './common/RouteNotFound';
import PasswordResetView from "./common/PasswordResetView";
import LoginHelpView from "./common/LoginHelpView";
import MessageBlocks from "./common/MessageBlocks";
import Highlights from "./common/Highlights";
import './App.css';
import {toCamelObject} from "./Util";
import AuthService from './service/AuthService';
import Validator from "./lib/Validator";
import {Modal} from "./common/ModalContext";
import {ConfirmModal, ConfirmModalFooter} from "./common/ConfirmModal";
import GoogleTagManagerService from "./service/GoogleTagManagerService";


export default class App extends Component {
    firstOpened = true;

    constructor(props) {
        super(props);

        // pulling this out from the state.  We can't switch this to true before we 
        // rerender again which forces another redirect and breaks the app
        this.toRedirect = false;

        this.state = {
            contractor: {},
            errorMessages: [],
            isLoading: true,
            processedAutomatedLogin: false,
            redirectLocation: null,
            redirectMessage: '',
            apiErrorMessage: {
                messages: [],
                type: "",
                urls: [],
                stayForever: false,
            },

            // Definitive data is in DB. Gets populated by API with white label details from the partnership table.
            // We pre-populate "Service Direct" due to a race condition that at one point led to the white_label_name
            // being blank in the Terms of Service in /leads?terms=1 ~ RM,YS 2020-11-16
            whiteLabelPartnership: {
                logo_uri: "",
                logo_uri_for_login_page: "",
                white_label_name: "Service Direct",
                white_label_app_name: "mySD",
                color_scheme_login_page_gradient_left_color: "",
                color_scheme_login_page_gradient_right_color: "",
                color_scheme_login_page_no_gradient_fallback_color: "",
                color_scheme_heading_text_color: "",
            },
            //
            // Another partnership object - sometimes but NOT always the same as whiteLabelPartnership
            // because some partnerships are not is_white_label=true, and therefore e.g. FCI's whiteLabelPartnership
            // is simply Service Direct, though they have their own partnership record
            //
            partnership: {
                centralized_billing: "",
            },
            // TODO standardize this - just a loose contractor_name? a contractor obj?
            // camelCase instead of snake_case?
            contractor_name: ""
        };

        if (axios.defaults !== undefined) {
            axios.defaults.withCredentials = true;
        }

        this.tokenService = new TokenService();

        this.highlightRef = React.createRef();
    }

    /**
     * Contains axios interceptor for redirects / error handling.
     */
    componentDidMount() {
        //
        // Interceptor
        // - if we get a 400-499 response, show the error in a red msg block
        // - if we get a 401 auth issue when hitting the api, redirect back to the client
        // - redirects
        // - flow CONTINUES into the regular callback.
        //
        axios.interceptors.response.use(
            //
            // Success - check the token expiry and refresh it if needed
            //
            (response: AxiosResponse) => {
                this.tokenService.refreshToken(response, this.firstOpened)
                    .then((loginResponse) => AuthService.updateLoginData(loginResponse))
                    .catch(ignore => {
                    });

                this.firstOpened = false;

                return response;
            },
            //
            // error handler implies anything other 200s?
            //
            (error) => {
                if (error.response) {
                    //
                    // 303 special redirect behavior
                    // special (nonstandard) redirect behavior - not compatible with Edge
                    //
                    if (error.response.status === 303) {
                        // console.log("Doing special 303 behavior");
                        this.toRedirect = true;
                        let response: AuthData | any = toCamelObject(error.response.data.data);

                        // case of absolute path redirect (known case for partners like Nexstar)
                        if (/^https?:\/\//.test(response.location)) {
                            window.location = response.location;
                            return;
                        }

                        let dataToSet = {redirectLocation: response.location};

                        //
                        // Add if we have a message with the redirect
                        //
                        if (response.message) {
                            dataToSet.redirectMessage = response.message;
                        }

                        // if it has authenticated data, update our auth service
                        if (response.authenticated) {
                            AuthService.updateLoginData(response);
                        }

                        //
                        // pop to top of the next view so we aren't scrolled midway down
                        //
                        window.scrollTo(0, 0);
                        //
                        // Since App.js mounted while we were on the Login page, it didn't get all the
                        // detailed Contractor Partnership data. Now that we're logged in, get that data,
                        // and while we're at it, set the dataToSet into the state.
                        //
                        this.updateWhiteLabel(dataToSet);

                        return Promise.resolve(error.response);
                    }
                        //
                        // 400s and 500s
                    //
                    else if (!this.state.isLoading) {
                        if (
                            error.response.status === 400 ||
                            error.response.status === 404 ||
                            error.response.status === 500
                        ) {
                            let response = error.response;
                            if (response && [400, 500].includes(response.status) && typeof response.data === "object") {
                                let stateClone = {...this.state};
                                let errors = response.data.errors;

                                if (errors) {
                                    // update the messages for the message block
                                    this.updateApiErrorMessages(errors, "error", stateClone);
                                    this.setState(stateClone);
                                }
                            }

                            return Promise.reject(error);
                        }

                        /**
                         * Send user back to the login page if they get a 401 response from any API call.
                         * However, note that this includes the POST /auth call that the <Login> component
                         * does - and we don't wish to have a loop where you are redirected to Login even
                         * though you were already on Login. That causes issues like the apparent
                         * nonfunctioning of error message UI in Login Page.
                         *
                         * See https://app.asana.com/0/918571211873921/1111789315668708
                         *
                         * To avoid this, we detect if they are already authed, and only if so do we
                         * de-auth them.
                         */
                        else if (error.response.status === 401) {
                            if (AuthService.isAuthenticated) {
                                AuthService.clear();
                                this.setState({isLoading: false});

                                // updateWhiteLabel again since we may have tried to fetch it with invalid auth
                                this.updateWhiteLabel();
                            }
                            else {
                                /**
                                 * If we're already not authed, we're likely on the Login Page already
                                 * in which case we really need this return value so the post().then()
                                 * can receive the response object for correct processing.
                                 */
                                return Promise.reject(error);
                            }
                        }
                    }
                }
                else if (axios.isCancel(error)) {
                    return Promise.reject(error);
                }
            });

        //
        // handle if auth and is_signup === true
        // This forces the user to be right where they need to be
        // #NL 3/AUG/2020 - added exception for the case of signup marketplace
        //
        // console.log("Checking if signup redirect is needed");
        if (AuthService.isAuthenticated && AuthService.isSignup && !AuthService.getItem('signupMarketplaceId')) {
            let step = this.props.location.pathname;
            // console.log("In signup. Got step off end of location:", step);
            get(`signup/step?step=${step}`)
                .then((response) => {
                    // If client is no longer in signup, redirect to dashboard
                    // NOTE: We desired this to land the client, logged in, on the Dashboard, but it's currently landing
                    // them on the Login page instead.
                    if (response && response.data && response.data.data && response.data.data.contractor
                        && response.data.data.contractor.date_completed_signup
                    ) {
                        AuthService.isSignup = false;
                        window.location = '/';
                    }
                });
        }


        //
        // Redirect to Self-Serve Onboarding if user is logged in but has not finished self-serve onboarding
        if (AuthService.isAuthenticated && AuthService.isOnboarding) {
            let redirect = false;
            const stepLabels = ['one', 'two', 'three', 'four', 'five', 'six', 'complete'];

            if (this.props.location.pathname.split('/')[1] !== 'onboarding') {
                // The user is in onboarding mode, but loading a non-onboarding path
                redirect = true;
            } else {
                const pathParts = this.props.location.pathname.split('/');

                if (pathParts[2]) {
                    const stepMatch = this.props.location.pathname.split('/')[2].match(/^step-(one|two|three|four|five|six)/);

                    if (stepMatch) {
                        // The user is loading a well-defined step path
                        const stepString = stepMatch[1];

                        if (stepString !== stepLabels[AuthService.onboardingStep - 1]) {
                            // The user is loading a different step than server state says they should be on
                            redirect = true;
                        }
                    } else if (AuthService.onboardingStep < 7) {
                        // The user has not completed onboarding mode, but loading an onboarding path that is not a defined step
                        redirect = true;
                    }
                } else {
                    redirect = true;
                }
            }

            if (redirect) {
                this.props.history.push(`/onboarding/step-${stepLabels[AuthService.onboardingStep  -1]}`);
            }
        }

		// Redirect to appropriate page if user is waiting onboarding based on
		// whether or not onboarding has been scheduled.
        if (AuthService.isAuthenticated && AuthService.isAwaitingOnboarding) {
            if (AuthService.requestedOnboarding && !this.props.location.pathname.includes('onboarding/scheduled')) {
                this.props.history.push('/onboarding/scheduled');
            } else if (!AuthService.requestedOnboarding && !this.props.location.pathname.includes('onboarding/schedule-onboarding')) {
                this.props.history.push('/onboarding/schedule-onboarding');
            }
        }


		this.setState({isLoading: false});

        this.updateWhiteLabel();

        this.setHubSpotUser();

        GoogleTagManagerService.initializeGoogleTagManager();
    }

    componentWillUnmount() {
        this.tokenService.cancelSignal.cancel();
    }

    /**
     * identifies the user in hubspot with the logged in user email address
     */
    setHubSpotUser = () => {
        // verify that the user is logged in, has the username set, and is NOT an admin
        if (!AuthService.getItem('username') || AuthService.isAdmin) {
            return;
        }

        window._hsq = window._hsq || [];
        window._hsq.push(
            [
                'identify',
                {email: AuthService.getItem('username')}
            ]
        );
        window._hsq.push(['trackPageView']);
    };

    /**
     * Update local storage etc, following a successful POST /auth
     * @param {AuthData} loginResponse
     */
    postLogin = (loginResponse: AuthData) => {
        // If the API offered a specific dashboard page to redirect to, go there.
        if (loginResponse.location) {
            return;
        }

        // save user data to local storage
        AuthService.updateLoginData(loginResponse);

        if (process.env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER == 1) {
            GoogleTagManagerService.updateDataLayer()
        }

        this.setHubSpotUser();

        let partnership = loginResponse.partnership;
        if (partnership) {
            this.setState({'partnership': partnership});
        }

        this.updateWhiteLabel();

        // Scroll to the top, regardless of what page we're going to
        window.scrollTo(0, 0);
    };

    /**
     * UpdateMessageBlocks
     * @param {Array|string} messages
     * @param {string} messageType One of "success" or "error"
     * @param {boolean} stayForever - when true, don't disappear after some seconds
     */
    updateMessageBlocks = (messages, messageType, stayForever = false) => {

        // NOTE - despite the name apiErrorMessages, this also handles success messages
        let apiErrorMsgClone = {...this.state.apiErrorMessage};

        apiErrorMsgClone.messages = [];

        if (Array.isArray(messages)) {
            for (let i = 0; i < messages.length; i++) {
                apiErrorMsgClone.messages.push(messages[i]);
            }
        }
        else {
            apiErrorMsgClone.messages.push(messages);
        }

        apiErrorMsgClone.type = messageType;

        apiErrorMsgClone.stayForever = stayForever;

        this.setState({apiErrorMessage: apiErrorMsgClone});
    };

    /**
     * 2 different cases for getting the suitable white label data:
     *
     * 1. User is logged in / has contractorId -
     *    in this case, use GET /contractors/<id>/partnership
     *
     * 2. User is not logged in / has no contractorId -
     *    in this case, go based on the host with GET /partnerships?host=...
     *
     * In either case, put into the App state.whiteLabelPartnership
     *
     * @param newState - optional new state to set in addition to the white label state
     */
    updateWhiteLabel = (newState = {}) => {
        let contractorId = AuthService.getItem('contractorId');

        // TODO: we probably don't need the second part where the user is already logged in because
        // we always return the contractor name in the login response
        // might need better testing in the signup process
        if (!contractorId) {
            get('partnerships?host=' + document.location.host)
                .then((response) => {
                    this.setState({
                        ...newState,
                        whiteLabelPartnership: response?.data?.data?.whiteLabelPartnership
                    });
                });
        }
        else {
            get(`contractors/${contractorId}/partnership`)
                .then((response) => {
                    AuthService.setItem("contractorName", response.data.data.contractor_name);

                    this.setState({
                        ...newState,
                        whiteLabelPartnership: response.data.data.whiteLabelPartnership,
                        partnership: response.data.data.partnership,
                        contractor_name: response.data.data.contractor_name
                    });
                })
                // This endpoint may fail, e.g. with an access error such as a 403, when the browser's session has
                // gotten out of sync somehow. For instance, the localStorage.contractorId could still refer to a
                // contractor, but it's not the one that's currently logged in. Exact ways to reproduce this are unknown.
                // The solution for now: clear the localStorage and reload the page, to make them log in again.
                .catch((error) => {
                    console.log("Caught error during GET contractors/<id>/partnership:", error);
                    AuthService.clear();
                    window.location.reload();
                });
        }
    };

    /**
     * directly updates the white label data
     * @param {Object} whiteLabelPartnership
     */
    updateWhiteLabelData = (whiteLabelPartnership) => {
        this.setState({whiteLabelPartnership});
    };

    /***************************
     * Message Block Functions *
     ***************************/

    /**
     * Clears the errors in the state. Callback for MessageBlocks
     */
    clearErrorMessages = () => {
        let stateClone = {...this.state};
        let apiMessageClone = {...stateClone.apiErrorMessage};

        apiMessageClone.messages = [];
        apiMessageClone.urls = [];
        apiMessageClone.type = "";

        stateClone.apiErrorMessage = apiMessageClone;

        this.setState(stateClone);
    };

    /**
     * Update the state.apiErrorMessage from 400 api response
     *
     * NOTE as of 2020-03-10 this always clears any existing messages or urls before updating.
     *
     * @param {Object[]|Object} errors
     * @param {string} type
     * @param {Object} stateClone
     */
    updateApiErrorMessages = (errors: Object[]|Object, type: string, stateClone: Object) => {
        let apiMessageClone = {...stateClone.apiErrorMessage};

        apiMessageClone.messages = [];
        apiMessageClone.urls = [];

        if (!Array.isArray(errors)) {
            errors = [errors];
        }

        for (let i in errors) {
            apiMessageClone.messages.push(errors[i].message);
            apiMessageClone.urls = apiMessageClone.urls.concat(errors[i].urls);
        }

        apiMessageClone.type = type;
        stateClone.apiErrorMessage = apiMessageClone;
        this.setState(stateClone);
    };


    /**
     * Builds an object of style rules for the branded/white-labeled gradient in Login page / Need Help / Password Reset
     * @returns object of style rules for gradient
     */
    loginGradientStyle = () => {
        let left_color = this.state.whiteLabelPartnership.color_scheme_login_page_gradient_left_color;
        let right_color = this.state.whiteLabelPartnership.color_scheme_login_page_gradient_right_color;
        let fallback_color = this.state.whiteLabelPartnership.color_scheme_login_page_no_gradient_fallback_color;
        // Don't attempt styles without the needed colors:
        // If we're missing left or right colors, we'll use just fallback_color if we have that
        // otherwise return no style at all.
        let has_left_right_color = true;
        if (!left_color) {
            has_left_right_color = false;
        }
        if (!right_color) {
            has_left_right_color = false;
        }
        if (!has_left_right_color) {
            if (!fallback_color) {
                return {};
            }
            return {background: fallback_color};
        }
        // Build up styles - if we're here, we have left & right colors
        // and may or may not have a fallback_color.
        let styles = {
            background: 'linear-gradient(90deg, ' + left_color + ' 0%, ' + right_color + ' 100%)'
        };
        if (fallback_color) {
            styles.background += ', ' + fallback_color;
        }
        return styles;
    };

    updateHighlights = () =>
        this.highlightRef.current.getHighlights();

    render() {
        // Setting the <title> correctly
        let whiteLabelName = this.state.whiteLabelPartnership.white_label_name;
        if (!document.title && document.title !== whiteLabelName) {
            document.title = whiteLabelName;
        }

        // Handle any redirecting
        if (this.toRedirect) {
            this.toRedirect = false;

            return <Redirect push to={{
                pathname: this.state.redirectLocation,
                state: {message: this.state.redirectMessage}
            }}/>;
        }

        const credentials = AuthService.getUrlCredentials();
        if (
            !this.state.processedAutomatedLogin &&
            Validator.validateEmail(credentials.username) &&
            credentials.password &&
            AuthService.isAuthenticated &&
            credentials.username.toUpperCase() !== AuthService.getItem('username').toUpperCase()
        ) {
            return <Modal
                content={<ConfirmModal
                    content={<p className="type-normal-body">
                        You are currently logged in as {AuthService.isAdmin ? 'the admin ' : ''}
                        <b>{AuthService.getItem('username').toLowerCase()}</b>
                        <br/>
                        Would you like to switch to <b>{credentials.username.toLowerCase()}</b>?
                    </p>}
                />}
                footer={<ConfirmModalFooter
                    handleCancel={() => this.setState({processedAutomatedLogin: true})}
                    handleConfirm={() =>
                        this.tokenService.login(credentials.username, credentials.password, true,
                            credentials.vicariousAdminId)
                            .then(this.postLogin)
                            .catch(() =>
                                console.log('Error automatically logging in. `App.js` will handle the error'))
                    }
                />}
                header="Switch User"
                onCloseButton={() => this.setState({processedAutomatedLogin: true})}
                scrollerRef={React.createRef()}
            />;
        }

        if (this.state.isLoading) {
            return <div/>;
        }
        // render based on if the user is logged in or not
        // 1) authenticated user, not in Signup - go to Client or Admin app
        if (AuthService.isAuthenticated
            && !AuthService.isSignup
            && !AuthService.isOnboarding
            && !AuthService.isAwaitingOnboarding
            && AuthService.getItem('accessLevelName')
        ) {
            let accessLevelName = AuthService.getItem('accessLevelName').toLowerCase();
            if (accessLevelName === 'client') {
                return <ClientApp
                    updateMessageBlocks={this.updateMessageBlocks}
                    whiteLabelPartnership={this.state.whiteLabelPartnership}
                    partnership={this.state.partnership}
                    history={this.props.history}
                    updateHighlights={this.updateHighlights}
                >
                    <MessageBlocks
                        messages={this.state.apiErrorMessage.messages}
                        type={this.state.apiErrorMessage.type}
                        clearErrors={this.clearErrorMessages}
                        urls={this.state.apiErrorMessage.urls}
                        stayForever={this.state.apiErrorMessage.stayForever}
                    />
                    <Highlights ref={this.highlightRef} location={this.props.location}/>
                </ClientApp>;
            }
            else if (accessLevelName === "admin" || accessLevelName === "superuser") {
                return <AdminApp
                    whiteLabelPartnership={this.state.whiteLabelPartnership}
                    updateMessageBlocks={this.updateMessageBlocks}
                    history={this.props.history}
                >
                    <MessageBlocks
                        messages={this.state.apiErrorMessage.messages}
                        type={this.state.apiErrorMessage.type}
                        clearErrors={this.clearErrorMessages}
                        urls={this.state.apiErrorMessage.urls}
                        stayForever={this.state.apiErrorMessage.stayForever}
                    />
                </AdminApp>;
            }
            else {
                return <RouteNotFound/>;
            }
        }
        else if (AuthService.isAuthenticated
            && AuthService.isAwaitingOnboarding
            && AuthService.getItem('accessLevelName').toLowerCase() === 'client'
        ) {
            return (
                <AwaitingOnboardingView
                    {...this.props}
                    updateMessageBlocks={this.updateMessageBlocks}
                    whiteLabelPartnership={this.state.whiteLabelPartnership}
                >
                    <MessageBlocks
                        messages={this.state.apiErrorMessage.messages}
                        type={this.state.apiErrorMessage.type}
                        clearErrors={this.clearErrorMessages}
                        urls={this.state.apiErrorMessage.urls}
                    />
                </AwaitingOnboardingView>
            );
        }
            //
            // 2) authenticated client user in Signup - go to Signup
        //
        else if (AuthService.isAuthenticated
            && AuthService.isSignup
            && AuthService.getItem('accessLevelName').toLowerCase() === 'client'
        ) {
            //
            // Note: localStorage.signupMarketplaceId was string 'null' or 'undefined' in some cases, causing an issue:
            // https://app.asana.com/0/691849787814198/1191561383953689/f
            //
            // new note: using the AuthService will now guarantee a Number value;
            // so we just check if it's not 0 - i.e. "true"
            //
            return AuthService.getItem('signupMarketplaceId')
                ? <SignupMarketplaceView
                    {...this.props}
                    auth={true}
                    updateMessageBlocks={this.updateMessageBlocks}
                    whiteLabelPartnership={this.state.whiteLabelPartnership}
                >
                    <MessageBlocks
                        messages={this.state.apiErrorMessage.messages}
                        type={this.state.apiErrorMessage.type}
                        clearErrors={this.clearErrorMessages}
                        urls={this.state.apiErrorMessage.urls}
                    />
                </SignupMarketplaceView>
                : <SignupView
                    {...this.props}
                    auth={AuthService.isAuthenticated}
                    whiteLabelPartnership={this.state.whiteLabelPartnership}
                    contractor_name={this.state.contractor_name}
                    updateMessageBlocks={this.updateMessageBlocks}
                    history={this.props.history}
                >
                    <MessageBlocks
                        messages={this.state.apiErrorMessage.messages}
                        type={this.state.apiErrorMessage.type}
                        clearErrors={this.clearErrorMessages}
                        urls={this.state.apiErrorMessage.urls}
                    />
                </SignupView>;
        }
        //
        // 3) authenticated client user in Self-Serve Onboarding
        //
        else if (AuthService.isAuthenticated
            && AuthService.isOnboarding
            && AuthService.getItem('accessLevelName').toLowerCase() === 'client'
        ) {
            return <SelfServeOnboardingView
                {...this.props}
                whiteLabelPartnership={this.state.whiteLabelPartnership}
            />;
        }
            //
            // 4) non-authenticated user - go to Login
        //
        else {
            return <Switch>
                <Route
                    exact strict path="/login-help/:token"
                    render={(props) =>
                        <PasswordResetView
                            {...props}
                            whiteLabelPartnership={this.state.whiteLabelPartnership}
                            updateMessageBlocks={this.updateMessageBlocks}
                            postLogin={this.postLogin}
                            loginGradientStyle={this.loginGradientStyle}
                        >
                            <MessageBlocks
                                messages={this.state.apiErrorMessage.messages}
                                type={this.state.apiErrorMessage.type}
                                clearErrors={this.clearErrorMessages}
                                urls={this.state.apiErrorMessage.urls}
                            />
                        </PasswordResetView>
                    }
                />
                <Route
                    exact path="/login-help"
                    render={props =>
                        <LoginHelpView
                            {...props}
                            whiteLabelPartnership={this.state.whiteLabelPartnership}
                            loginGradientStyle={this.loginGradientStyle}
                        >
                            <MessageBlocks
                                messages={this.state.apiErrorMessage.messages}
                                type={this.state.apiErrorMessage.type}
                                clearErrors={this.clearErrorMessages}
                                urls={this.state.apiErrorMessage.urls}
                            />
                        </LoginHelpView>
                    }
                />
                {/*                                                                */}
                {/* Signup Step 1 case where user is not technically logged in yet */}
                {/*                                                                */}
                <Route
                    exact path="/signup/account-setup/:token"
                    render={(props) =>
                        <SignupView
                            {...props}
                            auth={AuthService.isAuthenticated}
                            whiteLabelPartnership={this.state.whiteLabelPartnership}
                            contractor_name={this.state.contractor_name}
                            updateWhiteLabel={this.updateWhiteLabelData}
                            history={this.props.history}
                            // addHelpScoutJs={ this.addHelpScoutJs }
                        >
                            <MessageBlocks
                                messages={this.state.apiErrorMessage.messages}
                                type={this.state.apiErrorMessage.type}
                                clearErrors={this.clearErrorMessages}
                                urls={this.state.apiErrorMessage.urls}
                            />
                        </SignupView>
                    }
                />
                {/* Signup Standalone Step One: case where user is not logged in yet */}
                <Route
                    path="/signup-marketplace/"
                    render={(props) =>
                        <SignupMarketplaceView
                            {...props}
                            auth={false}
                            updateMessageBlocks={this.updateMessageBlocks}
                            whiteLabelPartnership={this.state.whiteLabelPartnership}
                        >
                            <MessageBlocks
                                messages={this.state.apiErrorMessage.messages}
                                type={this.state.apiErrorMessage.type}
                                clearErrors={this.clearErrorMessages}
                                urls={this.state.apiErrorMessage.urls}
                            />
                        </SignupMarketplaceView>
                    }
                />
                <Route
                    render={(props) =>
                        <Login
                            {...props}
                            errorMessages={this.state.errorMessages}
                            updateAppErrorMessages={this.updateAppErrorMessages}
                            setOnLogin={this.setOnLogin}
                            postLogin={this.postLogin}
                            whiteLabelPartnership={this.state.whiteLabelPartnership}
                            loginGradientStyle={this.loginGradientStyle}
                        >
                            <MessageBlocks
                                messages={this.state.apiErrorMessage.messages}
                                type={this.state.apiErrorMessage.type}
                                clearErrors={this.clearErrorMessages}
                                urls={this.state.apiErrorMessage.urls}
                            />
                        </Login>
                    }
                />
            </Switch>;
        }
    }
}
