import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 1.548387097rem 2.064516129rem;
`

export const ActionsWrapper = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    bottom: 0;
    justify-content: flex-end;
    background-color: #F2F8FC;
    padding: 25px 20px;
`

export const InputWrapper = styled.div`
    display: flex;
    width: 500px;
    flex-grow: 1;
    flex-direction: column;
`