import React, {Component} from 'react';
import PropTypes from 'prop-types';

import type {Contractor} from "../../Interfaces/Contractor";
import {ContractorTypeIds} from "../../common/StaticValues";
import {formatDate, getClientMenuLinks} from "../../Util";
import {setUsersSubMenu} from "../../UtilJsx";
import ContextualMenu from "../../common/ContextualMenu";
import UserService from "../../service/UserService";
import {Link} from "react-router-dom";

export default class ContractorRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientMenuLinks: []
        };

        this.userService = new UserService();
    }

    componentDidMount(): void {
        const {contractor}: Contractor = this.props;
        const clientMenuLinks = getClientMenuLinks(contractor.contractorId, contractor.hubspotCompanyId);
        this.setState({clientMenuLinks});
    }

    componentWillUnmount(): void {
        this.userService.cancelSignal.cancel();
    }

    getUsersLinks = (): void => {
        const {clientMenuLinks} = this.state;
        this.userService.getByAdmin(this.props.contractor.contractorId)
            .then((users) => {
                setUsersSubMenu(users, clientMenuLinks);
                this.setState({clientMenuLinks});
            });
    };

    parseType(typeId) {
        if (typeId == 1) {
            return 'Select';
        }
        else if (typeId == 2) {
            return 'Marketplace';
        }
        else {
            return 'Affiliate';
        }
    }

    isEligibleSelectEnrollment(contractor: Contractor) {
        return contractor.contractorTypeId == ContractorTypeIds.Marketplace &&
            contractor.dateCompletedSignup &&
            contractor.mpOnboardingCompletedTimestamp &&
            !contractor.quitDate;
    }

    render() {
        const contractor: Contractor = this.props.contractor;

        return <tr className={'sortable-table__row' + (contractor.quitDate ? ' disabled' : '')}>
            <td>
                <div className="simpleflex__row">
                    <div className="simpleflex__cell">
                        <ContextualMenu
                            linkLabel={contractor.name}
                            menuLinks={this.state.clientMenuLinks}
                            onFirstOpen={this.getUsersLinks}
                            scrollboxRef={ this.props.scrollboxRef }
                        />
                    </div>
                    {this.isEligibleSelectEnrollment(contractor) &&
                    <div className="simpleflex__cell type-align-right">
                        <Link to={`/create-client/${contractor.contractorId}`}>Enroll in Select</Link>
                    </div>}
                </div>
            </td>
            <td>
                {this.parseType(contractor.contractorTypeId)}
            </td>
            <td>{contractor.contractorId}</td>
            <td>
                {formatDate(contractor.creationTimestamp)}
            </td>
            <td>
                {formatDate(contractor.dateCompletedSignup)}
            </td>
            <td>
                {formatDate(contractor.mpOnboardingCompletedTimestamp)}
            </td>
        </tr>;
    }
}

ContractorRow.propTypes = {
    contractor: PropTypes.object.isRequired
};
