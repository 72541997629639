import React, {Component} from "react";

import NavigationFooter from './navigation-footer';
import SignupMarketplaceService from '../service/SignupMarketplaceService';
import {errorHandler} from "../Requests";
import Input from "../common/components/form_elements/Input";
import {formatCurrency, toCamel} from "../Util";
import {MoneyInput} from "../common/components/form_elements/MoneyInput";
import Validator from "../lib/Validator";
import Label from "../common/components/form_elements/Label";
import {MockButton} from "../common/MockButton";
import Tooltip from "../common/Tooltip";
import PropTypes from "prop-types";
import AuthService from "../service/AuthService";
import Checkbox from "../common/components/form_elements/Checkbox";
import {CALL_RECORDING_TEXT, handleCallRecordingUncheck} from "../common/StaticValues";
import GoogleTagManagerService from "../service/GoogleTagManagerService";
import RadioGroup from "../common/components/form_elements/RadioGroup";
import {CallerIdTooltip} from "../common/TooltipsCopy";
import {adjustCallerIdOptions} from "../UtilJsx";
import PrivacyPolicy from "../client/PrivacyPolicy";

export default class StepThree extends Component {
    constructor(props) {
        super(props);

        // errorMessage having value means there's an error
        this.state = {
            data: {
                campaigns: [],
                leadDeliveryNumber: {
                    value: '',
                    errorMessage: ''
                },
                callRecording: true,
                callerId: {
                    value: '9',
                    errorMessage: ''
                }
            },
            serviceCategoryInformation: {},
            callerIdOptions: [],
            processing: false
        };

        this.signupMarketplaceService = new SignupMarketplaceService();
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.signupMarketplaceService.getStepThree()
            .then((responseData: Object) => {
                const {data} = this.state;
                data.leadDeliveryNumber.value = responseData.leadDeliveryNumber;
                data.callRecording = responseData.callRecording;
                const serviceCategoryInformation = responseData.serviceCategoryInformation;
                const callerIdOptions = adjustCallerIdOptions(responseData.callerIdOptions)

                data.campaigns = [];
                responseData.campaigns.forEach((campaign: Object) => {
                    campaign.errorMessage = '';

                    // convert the "cpl" key to "value" for consistency
                    campaign.value = campaign.cpl;
                    delete campaign.cpl;
                    data.campaigns.push(campaign);
                });
                this.setState({data, serviceCategoryInformation, callerIdOptions})

            })
            .catch((error) => {
                if (errorHandler(error)) {
                    window.location = error.response.data.location;
                }
            });
    }

    componentWillUnmount() {
        this.signupMarketplaceService.cancelSignal.cancel();
    }

    mockData = (event) => {
        event.preventDefault();

        let data = {
            campaigns: [],
            leadDeliveryNumber: {
                value: '(555) 555-5555',
                errorMessage: ''
            },
            callRecording: true,
            callerId: {
                value: '9',
                errorMessage: ''
            }
        };
        this.state.data.campaigns.forEach((campaign) => {
            campaign.value = Number(campaign.minSuggestedCpl) + 1;
            data.campaigns.push(campaign);
        });

        this.setState({data});
    };

    /**
     * updates input with value and errors based on the input's name
     * TODO: this functionality can be shared between the different steps
     * @param {FocusEvent} event
     */
    handleLeadDeliveryNumberChange = (event) => {
        let {data} = this.state;
        data[event.target.name].value = event.target.value;

        this.setState({data});
    };

    /**
     * updates checkbox change based on the input's name
     * @param {FocusEvent} event
     */
    handleCallRecordingChange = (event) => {
        const checked: boolean = event.target.checked;

        handleCallRecordingUncheck(checked, this.props.updateMessageBlocks);

        const {data} = this.state;
        data[event.target.name] = checked;

        this.setState({data});
    };

    /**
     * updates changes to the caller id preference
     * @param name the settings name: i.e. "callerId"
     * @param value the settings value; e.g. 7, or 9
     */
    handleCallerIdChange = (name: string, value: string) => {
        const {data} = this.state;
        data[name].value = value;
        data[name].errorMessage = '';
        this.setState({data});
    };

    /**
     * handles validation of the field
     * @param {FocusEvent} event
     */
    handleBlurLeadDeliveryNumber = (event) => {
        let data = {...this.state.data};
        data.leadDeliveryNumber.errorMessage = this.getLeadDeliveryErrorMessage(event.target.value);

        this.setState({data});
    };

    /**
     * calculates and returns the error message of a given campaign; returns empty string when there's no error
     * @param {Object} campaign
     * @return {string}
     */
    getCampaignErrorMessage(campaign: Object): string {
        let value = Number(campaign.value);
        if (!value) {
            return 'CPL is required';
        }

        if (value < campaign.defaultMinTender) {
            return `Minimum CPL is $${campaign.defaultMinTender}`;
        }
        else if (value > campaign.maxSuggestedCpl * 4) {
            return `Maximum CPL is $${campaign.maxSuggestedCpl * 4}`;
        }

        return '';
    }

    /**
     * calculates and returns the error message of the lead delivery phone number input;
     * returns empty string when there's no error
     * @param {string} value - the phone number
     * @return {string}
     */
    getLeadDeliveryErrorMessage(value: string): string {
        value = value.trim();
        if (value.length == 0) {
            return 'Lead delivery number is required';
        }

        if (!Validator.validatePhoneNumber(value)) {
            return 'Invalid phone number';
        }

        return '';
    }

    /**
     * handles changing of campaign's CPL
     * @param {number} index
     * @param {number} cpl
     */
    handleCampaignChange = (index: number, cpl: number) => {
        let {data} = this.state;
        data.campaigns[index].value = cpl;
        this.setState({data});
    };

    /**
     * handles blurring out of a campaign's CPL field, and displaying an error if necessary
     * @param {number} index
     */
    handleCampaignBlur = (index: number) => {
        let {data} = this.state;
        data.campaigns[index].errorMessage = this.getCampaignErrorMessage(data.campaigns[index]);
        this.setState({data});
    };

    /**
     * builds the data that will be sent to the server
     * @return {object|boolean} the data object on success, or false on error
     */
    buildPostData = () => {
        let {data} = this.state,
            hasError = false,
            assistingAdminUserId = AuthService.assistingAdminUserId,
            response: Object;

        // check campaigns
        data.campaigns.forEach((campaign) => {
            campaign.errorMessage = this.getCampaignErrorMessage(campaign);
            if (campaign.errorMessage) {
                hasError = true;
            }
        });

        // check lead delivery number
        data.leadDeliveryNumber.errorMessage = this.getLeadDeliveryErrorMessage(data.leadDeliveryNumber.value);

        if (hasError || data.leadDeliveryNumber.errorMessage) {
            this.setState({data});
            return false;
        }

        response = {
            campaigns: data.campaigns.reduce((result, campaign) => {
                result.push({
                    industryId: campaign.industryId,
                    cpl: campaign.value
                });
                return result;
            }, []),
            leadDeliveryNumber: data.leadDeliveryNumber.value,
            callRecording: data.callRecording,
            callerId: data.callerId.value
        };
        
        if (localStorage.getItem("mpSignupPromoCode") && localStorage.getItem("mpSignupPromoCode").length > 0) {
            response.promo_code = localStorage.getItem("mpSignupPromoCode");
        }

        // Add assistingUserId if present
        if (assistingAdminUserId) {
            response.assistingAdminUserId = assistingAdminUserId;
        }

        return response;
    };

    /**
     * submits the form to the API
     * @param {Event} event
     */
    submit = (event) => {
        event.preventDefault();

        const data = this.buildPostData();
        if (!data) {
            return;
        }

        this.setState({processing: true});
        this.signupMarketplaceService.updateStepThree(data)
            .then(() => {
                // send step3Complete parameter to Data Layer
                GoogleTagManagerService.updateDataLayer({
                    event: "step3Complete",
                    assistingAdminUserId: AuthService.assistingAdminUserId,
                    masterServiceCategory: this.state.serviceCategoryInformation.masterServiceCategory,
                    serviceCategories: this.state.serviceCategoryInformation.serviceCategories.join(',')
                })
                this.props.history.push('/signup-marketplace/step-four')
            })
            .catch((error) => {
                this.setState({processing: false});
                if (errorHandler(error)) {
                    let {data} = this.state;
                    data[toCamel(error.response.data.parameter)].errorMessage = error.response.data.message;
                    this.setState({data});
                }
            });
    };

    render() {
        return <form onSubmit={this.submit}>
            <MockButton onClick={this.mockData}/>
            <h1 className="module__twothirdswidth type-large-subhead type-single-line module-header">
                Your Settings
            </h1>
            <div className="module__contentbox module__twothirdswidth">
                <h3 className="form-segment-header type-large-body">
                    Cost Per Lead
                </h3>
                <p className="type-normal-body">
                    Your cost per lead (CPL) is what you are bidding to acquire a Lead. The more aggressive your CPL,
                    the more likely you are to win new Leads. We've suggested a starting point below, but you can
                    always adjust your CPL at any time.
                </p>
                <div className="spacing-24">
                    What is a billable lead?{' '}
                    <Tooltip content={<>
                        <h3 className="type-heavy">Definitions</h3>
                        <ul>
                            <li>
                                <b className="type-heavy">Billable Lead: </b>
                                Any Lead that occurs when a new potential customer is seeking a service you
                                offer.
                            </li>
                            <li>
                                <b className="type-heavy">Non-Billable Lead: </b>
                                Answered Call Leads that had no service need from your company or Form Leads
                                with non-working contact information.
                            </li>
                        </ul>
                        <h3 className="type-heavy spacing-10-top">Examples of Non-Billable Leads</h3>
                        <ul>
                            <li>The caller has the wrong number.</li>
                            <li>The caller is seeking employment.</li>
                            <li>The caller is soliciting.</li>
                            <li>The caller is seeking a service you cannot provide (e.g. You are a plumber, but
                                this caller requires an electrician).
                            </li>
                            <li>The caller is a previous customer.</li>
                            <li>The caller is located outside of your service area.</li>
                            <li>The caller was referred to you directly from another person.</li>
                        </ul>
                        <h3 className="type-heavy spacing-10-top">
                            Examples of Billable Leads with No Booked Appointment
                        </h3>
                        <p>
                            Our service is designed to put you in front of real, new potential customers
                            seeking your services. Although many Billable Leads will result in booked
                            appointments, this does not mean every lead opportunity we send your way can be
                            closed. The following are examples of when you may still pay for a Lead when
                            you are unable to close:
                        </p>
                        <ul>
                            <li>Missed Calls</li>
                            <li>Price Shoppers</li>
                            <li>Call Drops / Hangups</li>
                            <li>Caller Books Service with a Competitor After Reaching You</li>
                            <li>Cannot Agree on Pricing</li>
                            <li>Caller Cancels Their Appointment</li>
                        </ul>
                    </>}
                    />
                </div>
                <div className="form__row spacing-24-bottom">
                    {this.state.data.campaigns.map((campaign: Object, index) =>
                        <div className="form__cell form__cell__25 form__cell__100__mobile" key={index}>
                            <Label
                                className="cplLabel"
                                label={`${campaign.industryName} CPL`}
                                title={`${campaign.industryName} CPL`}
                                name={`campaign-${index}`}
                                hasError={!!campaign.errorMessage}
                            />
                            <MoneyInput
                                name={`campaign-${index}`}
                                defaultValue={campaign.value}
                                step={1}
                                onChange={(event: FocusEvent) => this.handleCampaignChange(index, event.target.value)}
                                onBlur={() => this.handleCampaignBlur(index)}
                            />
                            <span className="type-grey narrow-side-rails spacing-5-top">
                            {formatCurrency(campaign.minSuggestedCpl, 0)}
                                -{campaign.maxSuggestedCpl}
                                {' '}average
                            </span>
                            <div className="input-error">{campaign.errorMessage}</div>
                        </div>
                    )}
                    <div
                        className="form__cell__50 form__cell__100__mobile type-grey type-normal-body flex__row align-center">
                        <div>
                            <span className="type-heavy">Note:</span> The averages are based on our data indicating
                            success for your Service Categories and Service Area zip codes.
                        </div>
                    </div>
                </div>
                <div className="form-segment-header spacing-24-top spacing-30-bottom type-large-body">
                    Lead Delivery
                </div>
                <div className="form__row">
                    <div className="form__cell form__cell__50 form__cell__100__mobile">
                        <Input
                            name="leadDeliveryNumber"
                            type="text"
                            label="The Phone Number where you want to receive your Leads"
                            value={this.state.data.leadDeliveryNumber.value}
                            errorMessage={this.state.data.leadDeliveryNumber.errorMessage}
                            onChange={this.handleLeadDeliveryNumberChange}
                            onBlur={this.handleBlurLeadDeliveryNumber}
                            required={true}
                        />
                    </div>
                    <div
                        className="form__cell__50 form__cell__100__mobile type-grey type-normal-body flex__row align-center">
                        <div>
                            <span className="type-heavy">Note:</span> You can edit this, as well as set notifications
                            for when a Lead has been delivered, in your account. By submitting this form you agree to receive
                            SMS messages as detailed in our <span className="type-heavy type-blue" role="button"
                                                                  onClick={() => this.props.updateModalContent(
                                                                    <PrivacyPolicy whiteLabelPartnership={this.props.whiteLabelPartnership}/>,
                                                                    this.props.whiteLabelPartnership.white_label_name + " Privacy Policy")}
                                                                > Privacy Policy </span>.
                        </div>
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__cell form__cell__100">
                        <Checkbox
                            name="callRecording"
                            label={<span className="type-normal-body">
                                    <span className="type-heavy">
                                        Record Phone Calls & Enable Lead Review
                                    </span>
                                </span>}
                            onChange={this.handleCallRecordingChange}
                            checked={this.state.data.callRecording}
                            tooltip={<Tooltip
                                content={<>
                                    <span
                                        className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                        Phone Call Recording & Lead Review
                                    </span>
                                    <span
                                        className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                            You must opt in to activate Call Recording. Call Recording is required to be eligible for Lead Review and if this option is not selected, all Leads longer than 20 seconds are considered Billable.
                                        </span>
                                    <span
                                        className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                        Learn more about{' '}
                                        <a className="type-heavy" target="_blank"
                                           href="https://support.servicedirect.com/billable-and-non-billable-leads">
                                            Understanding Billable and Non-Billable Leads
                                        </a>
                                        .
                                    </span>
                                </>}
                                position="right"
                            />}
                        />
                    </div>

                    <div className="form__cell form__cell__100">
                        <div className="type-normal-body">
                            <span className="type-heavy">REQUIRED for Lead Review.</span>{' '}
                            {CALL_RECORDING_TEXT}
                        </div>
                    </div>

                    <div className="form__cell form__cell__100">
                        <span className="type-large-body type-heavy spacing-20-top">
                            DISPLAYED CALLER ID{' '}
                            <Tooltip
                                content={CallerIdTooltip(false)}
                                position="right"
                            />
                        </span>
                        <label className="type-normal-body">
                            You can choose to see the Caller ID information of the incoming caller,
                            or you may choose to see a Tracking Number if you want to quickly recognize a Service Direct
                            sourced caller.
                        </label>
                        <RadioGroup
                            label="Caller ID"
                            showLabel={false}
                            name="callerId"
                            vertical={true}
                            isClearable={false}
                            checkedOption={this.state.data.callerId.value}
                            errorMessage={this.state.data.callerId.errorMessage}
                            options={this.state.callerIdOptions}
                            onChange={this.handleCallerIdChange}
                        />
                    </div>
                </div>
            </div>
            <NavigationFooter nextStep={this.submit} processing={this.state.processing} history={this.props.history}
                              previousStep="/signup-marketplace/step-two"/>
        </form>;
    }
}

StepThree.propTypes = {
    history: PropTypes.object.isRequired,
};
