import React, { Fragment } from 'react'
import Button from '../../../../../common/components/form_elements/Button'
import CopyToClipboard from '../../../../../common/components/CopyToClipboard'
import { ContentWindowScroll } from '../../../../../common/Modal'
import { ActionsWrapper, Divider } from '../styles'
import { formatPhoneNumber, formattedTimeAndDate } from '../../../../../Util'
import Label from '../../../../../common/components/form_elements/Label'
import { ContentWrapper, Wrapper, SuccessLine, CopyWrapper } from './styles'
import { OpenNewWindow } from '../../../../../common/Svgs'
import {
    StyledList,
    StyledListItem,
} from '../../../../mp_campaign_optimizer/Modals/styles'

export default function ConfirmationSuccess(props) {
    const { affiliate, numbers, onClose, serviceCategories = [] } = props
    // find successful new numbers to display
    const numberUpdates = numbers?.filter((n) => !!n.phone_number)
    const hasNumberUpdates = numberUpdates?.length > 0
    const failedNumbers = numbers?.filter((n) => n.phone_number == null)
    const hasFailures = failedNumbers?.length > 0
    // get the area codes of the failed numbers to display
    const areaCodesWithoutNumbers = new Set()
    failedNumbers &&
        failedNumbers.forEach((n) => {
            areaCodesWithoutNumbers.add(n.area_code)
        })

    // create the context message. only display a success message if there
    // are number updates
    let contextMessage = null
    if (numberUpdates?.length > 0) {
        contextMessage = `Success! DID Number Updates for ${affiliate.text}`
    }

    const newNumbersView = numberUpdates.map((n) => {
        const {
            did_phone_number_id,
            phone_name,
            name,
            phone_number,
            is_whisper_audio,
            is_retired,
        } = n

        const serviceCategoryLabel = serviceCategories.filter(
            (s) => s.value == n.industry_id
        )[0]?.text
        const { date, time, amPm } = formattedTimeAndDate(n.creation_timestamp, {
            timeLeadingZero: true,
        })

        const disabledClassName = is_retired == '1' ? 'type-disabled' : null
        return (
            <Fragment key={did_phone_number_id}>
                <SuccessLine borderLight>
                    <span className={`type-large-body ${disabledClassName}`}>
                        {serviceCategoryLabel}
                    </span>
                    <span className={`type-large-body ${disabledClassName}`}>
                        {formatPhoneNumber(phone_number)}
                    </span>
                    <span className={`type-large-body ${disabledClassName}`}>
                        {phone_name || name}
                    </span>
                    <span className={`type-large-body ${disabledClassName}`}>
                        {is_whisper_audio == '1' ? 'Yes' : 'No'}
                    </span>
                    <span className={`type-extra-small-body ${disabledClassName}`}>
                        {date}
                        <br />
                        {`${time} ${amPm.toUpperCase()}`}
                    </span>
                </SuccessLine>
                <Divider borderLight />
            </Fragment>
        )
    })

    const numbersPlainText = () =>
        numbers?.reduce((acc, n, i) => {
            // if the number is retired, do not include it in the csv
            if (n.is_retired == '1') {
                return acc
            }
            const serviceCategoryLabel = serviceCategories.filter(
                (s) => s.value == n.industry_id
            )[0]?.text
            acc += `${serviceCategoryLabel} `
            if (n.name) {
                acc += `(${n.name}) `
            }
            acc += `- ${formatPhoneNumber(n.phone_number)}`
            if (i < numbers.length - 1) {
                acc += '\n'
            }
            return acc
        }, '')

    return (
        <Fragment>
            <ContentWindowScroll>
                <Wrapper>
                    <ContentWrapper>
                        {hasNumberUpdates && (
                            <div className="spacing-30-bottom">
                                <div className="spacing-10-bottom">
                                    <span className="type-normal-subhead type-heavy">
                                        {contextMessage}
                                    </span>
                                </div>
                                <div>
                                    <SuccessLine>
                                        <Label label="Service Category" />
                                        <Label label="Phone Number" />
                                        <Label label="Phone Name" />
                                        <Label label="Play Whisper File" />
                                        <Label label="Created" />
                                    </SuccessLine>
                                    <Divider />
                                    {newNumbersView}
                                    <CopyWrapper>
                                        <CopyToClipboard
                                            dataToCopy={numbersPlainText()}
                                            buttonText="Copy Numbers To Clipboard"
                                        />
                                    </CopyWrapper>
                                </div>
                            </div>
                        )}
                        {hasFailures && (
                            <div>
                                <div className="spacing-10-bottom">
                                    <span className="type-normal-subhead type-heavy">
                                        The following errors occurred while attempting to
                                        purchase Twilio numbers
                                    </span>
                                </div>
                                <div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <span>
                                            Numbers could not be purchased for the
                                            following area code(s):
                                        </span>
                                    </div>
                                    <StyledList>
                                        {[...areaCodesWithoutNumbers].map((t, i) => (
                                            <StyledListItem key={`${t}_i`}>
                                                {t}
                                            </StyledListItem>
                                        ))}
                                    </StyledList>
                                    <br />
                                    <br />
                                    <span>
                                        Try again in a few minutes or{' '}
                                        <a
                                            href="https://www.allareacodes.com/area-code-map.htm"
                                            target="_blank"
                                        >
                                            {`find alternate area codes `}
                                            <span className="inline-icon inline-icon__middle inline-icon__20">
                                                {OpenNewWindow}
                                            </span>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        )}
                    </ContentWrapper>
                </Wrapper>
            </ContentWindowScroll>
            <ActionsWrapper>
                <Button
                    onClick={() => {
                        onClose && onClose({ numbers, affiliate })
                    }}
                >
                    Close And Go To Main
                </Button>
            </ActionsWrapper>
        </Fragment>
    )
}
