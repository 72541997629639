import React, {Component} from "react";

import NavigationFooter from './navigation-footer';
import SignupMarketplaceService from '../service/SignupMarketplaceService';
import {errorHandler} from "../Requests";
import {isValidAmericanZip, toCamel} from "../Util";
import {MockButton} from "../common/MockButton";
import ServiceCategorySelection from "../common/ServiceCategorySelection";
import MapZipCodeSelection from "../common/MapZipCodeSelection";
import PropTypes from "prop-types";
import AuthService from "../service/AuthService";
import GoogleTagManagerService from "../service/GoogleTagManagerService";
import ResourcesService from "../service/ResourcesService";

export default class StepTwo extends Component {
    constructor(props) {
        super(props);

        // errorMessage having value means there's an error
        this.state = {
            data: {
                masterIndustry: '0',
                industryIds: {
                    /** @member string[] (should be  number[]) */
                    value: [],
                    errorMessage: ''
                },
                zipCodes: {
                    value: [],
                    errorMessage: ''
                },
                mainZipCode: {
                    value: '',
                    errorMessage: ''
                },
                radius: {
                    value: 10
                }
            },
            initiating: true,
            processing: false,
            masterServiceCategories: {},
            serviceCategories: {}
        };

        this.signupMarketplaceService = new SignupMarketplaceService();
        this.resourcesService = new ResourcesService();
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.signupMarketplaceService.getStepTwo()
            .then((responseData: Object) => {
                let {data} = this.state;
                data.industryIds.value = responseData.industryIds;
                data.zipCodes.value = responseData.zipCodes;
                data.mainZipCode.value = responseData.mainZipCode;
                data.radius.value = responseData.radius
                    ? Number(responseData.radius)
                    : '';
                data.masterIndustry = responseData.masterIndustry;
                this.setState({data, initiating: false});
            })
            .catch((error) => {
                if (errorHandler(error)) {
                    window.location = error.response.data.location;
                }
            });

        this.resourcesService.getIndustries(true)
            .then((response: Object) => {
                const masterServiceCategories = {};
                const serviceCategories = {};

                // build master categories
                response.masterServiceCategories.forEach((masterCategory: Object) =>
                    masterServiceCategories[masterCategory.id] = masterCategory.name);

                // build categories
                response.serviceCategories.forEach((category: Object) =>
                    serviceCategories[category.id] = category.name);
                this.setState({masterServiceCategories, serviceCategories});
            });
    }

    componentWillUnmount() {
        this.signupMarketplaceService.cancelSignal.cancel();
        this.resourcesService.cancelSignal.cancel();
    }

    mockData = (event: MouseEvent) => {
        event.preventDefault();

        this.setState({
            data: {
                masterIndustry: '5',
                industryIds: {
                    value: ['195', '196'],
                    errorMessage: ''
                },
                zipCodes: {
                    value: ['78750', '78758'],
                    errorMessage: ''
                },
                mainZipCode: {
                    value: '78750',
                    errorMessage: ''
                },
                radius: {
                    value: 4
                }
            }
        });
    };

    /**
     * updates the master industry value
     * @param {array} vals
     */
    handleMasterIndustryChange = (vals: Array) => {
        let {data} = this.state;
        // reset industries
        data.industryIds.value = [];
        data.masterIndustry = vals[0];

        this.setState({data});
    };

    /**
     * updates industries change
     * @param {Number[]} value - array of industry ids
     */
    handleIndustriesChange = (value) => {
        let {data} = this.state;
        data.industryIds.value = value;
        data.industryIds.errorMessage = this.getErrorMessage('industryIds', value);
        this.setState({data});
    };

    /**
     * updates the state's value (and error message) whenever a value is changed in a child component
     * @param {String} name
     * @param {any} value
     */
    handleValueChange = (name, value: any) => {
        let {data} = this.state;
        data[name].value = value;
        data[name].errorMessage = this.getErrorMessage(name, value);
        this.setState({data});
    };

    /**
     * returns an error message for the specific set of field name and its value; empty string when there's no error
     * @param {string} name - the fields name
     * @param {ZipCode[]|number[]|string} value - the fields value
     * @return {string}
     */
    getErrorMessage(name, value): string {
        switch (name) {
            case 'zipCodes':
                if (value.length == 0) {
                    return 'Choosing at least one Zip Code is required';
                }
                break;

            case 'industryIds':
                if (value.length == 0) {
                    return 'Choosing at least one Service Category is required';
                }
                break;

            case 'mainZipCode':
                value = value.trim();
                if (value && !isValidAmericanZip(value)) {
                    return 'Invalid zip code';
                }

            // no default
        }

        return '';
    }

    /**
     * builds the data that will be sent to the server
     * @return {Object|boolean} the data object on success, or false on error
     */
    buildPostData = (): Object | boolean => {
        let {data} = this.state,
            assistingAdminUserId = AuthService.assistingAdminUserId,
            response: Object;

        // validate the industry ids
        data.industryIds.errorMessage = this.getErrorMessage('industryIds', data.industryIds.value);

        // validate the zip codes
        data.zipCodes.errorMessage = this.getErrorMessage('zipCodes', data.zipCodes.value);

        // validate the main zip code
        data.mainZipCode.errorMessage = this.getErrorMessage('mainZipCode', data.mainZipCode.value);

        // the radius is validated in the child component
        if (
            data.industryIds.errorMessage ||
            data.zipCodes.errorMessage ||
            data.mainZipCode.errorMessage
        ) {
            this.setState({data});
            return false;
        }

        response = {
            masterIndustry: data.masterIndustry,
            industryIds: data.industryIds.value,
            mainZipCode: data.mainZipCode.value,
            radius: data.radius.value,
            zipCodes: data.zipCodes.value
        };
        
        if (localStorage.getItem("mpSignupPromoCode") && localStorage.getItem("mpSignupPromoCode").length > 0) {
            response.promo_code = localStorage.getItem("mpSignupPromoCode");
        }

        // Add assistingUserId if present
        if (assistingAdminUserId) {
            response.assistingAdminUserId = assistingAdminUserId;
        }

        return response;
    };

    /**
     * submits the form to the API
     * @param {Event} event
     */
    submit = (event) => {
        event.preventDefault();

        const data = this.buildPostData();
        if (!data) {
            return;
        }

        this.setState({processing: true});
        this.signupMarketplaceService.updateStepTwo(data)
            .then(() => {
                // build service categories value for the data layer
                const serviceCategoriesArray = [];
                this.state.data.industryIds.value.forEach(id => serviceCategoriesArray.push(this.state.serviceCategories[id]));
                const serviceCategories = serviceCategoriesArray.join(',');

                // send step2Complete parameter to Data Layer
                GoogleTagManagerService.updateDataLayer({
                    event: "step2Complete",
                    assistingAdminUserId: AuthService.assistingAdminUserId,
                    masterServiceCategory: this.state.masterServiceCategories[this.state.data.masterIndustry],
                    serviceCategories
                })
                this.props.history.push('/signup-marketplace/step-three')
            })
            .catch((error) => {
                this.setState({processing: false});
                if (errorHandler(error)) {
                    let {data} = this.state;
                    data[toCamel(error.response.data.parameter)].errorMessage = error.response.data.message;
                    this.setState({data});
                }
            });
    };

    render() {
        if (this.state.initiating) {
            return null;
        }

        return <>
            <MockButton onClick={this.mockData}/>
            <h1 className="module__twothirdswidth type-large-subhead type-single-line module-header">
                Your Customers
            </h1>
            <div className="module__contentbox module__twothirdswidth">
                <p className="type-normal-subhead type-heavy spacing-50-bottom type-centered">
                    {AuthService.assistingAdminUserId
                        ? ''
                        : 'Thanks for confirming your email. '}
                    Now let's find you the right customers.
                </p>
                <ServiceCategorySelection selectedIndustries={this.state.data.industryIds.value}
                                          masterIndustry={this.state.data.masterIndustry}
                                          selectedIndustriesErrorMessage={this.state.data.industryIds.errorMessage}
                                          handleIndustriesChange={this.handleIndustriesChange}
                                          handleMasterIndustryChange={this.handleMasterIndustryChange}/>
                <MapZipCodeSelection
                    mainZipCode={this.state.data.mainZipCode.value}
                    mainZipCodeErrorMessage={this.state.data.mainZipCode.errorMessage}
                    radius={this.state.data.radius.value}
                    zipCodes={this.state.data.zipCodes.value}
                    zipCodesErrorMessage={this.state.data.zipCodes.errorMessage}
                    onValueChange={this.handleValueChange}
                    updateMessageBlocks={this.props.updateMessageBlocks}
                />
            </div>
            <NavigationFooter nextStep={this.submit} processing={this.state.processing}/>
        </>;
    }
}

StepTwo.propTypes = {
    history: PropTypes.object.isRequired,
};