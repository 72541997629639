import axios from 'axios'
import { format, subDays } from 'date-fns'

export default class DateRangeService {
    dateRanges = []

    constructor() {
        this.cancelSignal = axios.CancelToken.source()

        this.dateRanges = [
            { value: '1', text: 'Today', daySubFrom: 0, daySubTo: 0 },
            { value: '2', text: 'Yesterday', daySubFrom: 1, daySubTo: 1 },
            { value: '3', text: 'Last 7 Days', daySubFrom: 7, daySubTo: 1 },
            { value: '13', text: 'Last 7 Days + Today', daySubFrom: 7, daySubTo: 0 },
            { value: '4', text: 'This Month', setter: this.setFromToThisMonth },
            { value: '8', text: 'Last 30 Days', daySubFrom: 30, daySubTo: 1 },
            { value: '9', text: 'Last 30 + Today', daySubFrom: 30, daySubTo: 0 },
            { value: '5', text: 'Last Month', setter: this.setFromToLastMonth },
            { value: '10', text: 'Last 90 Days', daySubFrom: 90, daySubTo: 1 },
            { value: '11', text: 'Last 90 Days + Today', daySubFrom: 90, daySubTo: 0 },
            { value: '6', text: 'This Year', setter: this.setFromToThisYear },
            { value: '12', text: 'Last Year', setter: this.setFromToLastYear },
            { value: '7', text: 'All Time' },
            { value: 'custom', text: 'Custom' },
        ]
    }

    getPresetFromRange = (dateFromStr, dateToStr) => {
        if (!dateFromStr) {
            // all time
            return this.dateRanges[12]
        }
        let dateRangeFrom
        let dateRangeFromStr
        let dateRangeTo
        let dateRangeToStr
        const _tempFrom = new Date()
        const _tempTo = new Date()
        const dateRangeObj = this.dateRanges.filter((dateRange) => {
            if (dateRange.daySubFrom !== undefined) {
                dateRangeFrom = subDays(new Date(), dateRange.daySubFrom)
                dateRangeFromStr = this.formatDateYYYYMMDD(dateRangeFrom)
                dateRangeTo = subDays(new Date(), dateRange.daySubTo)
                dateRangeToStr = this.formatDateYYYYMMDD(dateRangeTo)
                if (dateRangeFromStr === dateFromStr && dateRangeToStr === dateToStr) {
                    return true
                }
            } else if (dateRange.setter !== undefined) {
                dateRange.setter(_tempFrom, _tempTo)
                const _tempFromStr = this.formatDateYYYYMMDD(_tempFrom)
                const _tempToStr = this.formatDateYYYYMMDD(_tempTo)
                if (_tempFromStr === dateFromStr && _tempToStr === dateToStr) {
                    return true
                }
            }
            return false
        })
        // default to 'custom'
        return dateRangeObj[0] || this.dateRanges[13]
    }

    getFromToDates = (dateRangeId, options) => {
        const defaultOptions = {
            format: true,
        }
        const userOptions = {
            ...defaultOptions,
            ...options,
        }
        let dateRange = this.dateRanges.find((range) => range.value == dateRangeId),
            today = new Date(),
            fromDate = new Date(today),
            toDate = new Date(today)

        let dateFrom
        let dateTo
        if (dateRange.daySubFrom !== undefined) {
            fromDate.setDate(today.getDate() - dateRange.daySubFrom)
            toDate.setDate(today.getDate() - dateRange.daySubTo)

            // return {
            //     dateFrom: this.formatDateYYYYMMDD(fromDate),
            //     dateTo: this.formatDateYYYYMMDD(toDate),
            // };
            dateFrom = fromDate
            dateTo = toDate
        } else if (dateRange.setter !== undefined) {
            dateRange.setter(fromDate, toDate)

            // return {
            //     dateFrom: this.formatDateYYYYMMDD(fromDate),
            //     dateTo: this.formatDateYYYYMMDD(toDate),
            // };
            dateFrom = fromDate
            dateTo = toDate
        } else {
            // return {
            //     dateFrom: "",
            //     dateTo: "",
            // };
            dateFrom = null
            dateTo = null
        }
        return {
            dateFrom: userOptions?.format ? this.formatDateYYYYMMDD(dateFrom) : dateFrom,
            dateTo: userOptions?.format ? this.formatDateYYYYMMDD(dateTo) : dateTo,
        }
    }

    /**
     * Sets from/to dates for this month.
     * @param {Date} fromDate
     * @param {Date} toDate
     */
    setFromToThisMonth = (fromDate, toDate) => {
        fromDate.setDate(1)

        toDate.setMonth(toDate.getMonth() + 1)
        toDate.setDate(1)
        toDate.setHours(-1)
    }

    /**
     * Sets from/to dates for last month.
     * @param {Date} fromDate
     * @param {Date} toDate
     */
    setFromToLastMonth = (fromDate, toDate) => {
        fromDate.setDate(1)
        fromDate.setMonth(fromDate.getMonth() - 1)

        toDate.setDate(1)
        toDate.setHours(-1)
    }

    /**
     * Sets from/to dates for this year.
     * @param {Date} fromDate
     * @param {Date} toDate
     */
    setFromToThisYear = (fromDate, toDate) => {
        fromDate.setMonth(0)
        fromDate.setDate(1)

        toDate.setMonth(11)
        toDate.setDate(31)
    }

    /**
     * Sets from/to dates for last year.
     * @param {Date} fromDate
     * @param {Date} toDate
     */
    setFromToLastYear = (fromDate, toDate) => {
        fromDate.setFullYear(fromDate.getFullYear() - 1)
        fromDate.setMonth(0)
        fromDate.setDate(1)

        toDate.setFullYear(fromDate.getFullYear())
        toDate.setMonth(11)
        toDate.setDate(31)
    }

    /**
     * Helper function that accepts a JS Date instance and returns the date
     * formatted in YYYY-MM-DD format.
     * @param {Date} jsDateObject
     */
    formatDateYYYYMMDD = (jsDateObject) => {
        if (jsDateObject === null) {
            return ''
        }
        let month = jsDateObject.getMonth() + 1

        if (month < 10) {
            month = '0' + month
        }

        let day = jsDateObject.getDate()

        if (day < 10) {
            day = '0' + day
        }

        let year = jsDateObject.getFullYear()

        return year + '-' + month + '-' + day
    }

    formatDate = (date, formatStr = 'MM/dd/yyyy') => {
        if (date === null) {
            return ''
        }
        return format(date, formatStr)
    }
}
