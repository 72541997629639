import React, { Component } from 'react';
import EditCompanyLogo from "../../../common/components/form_sections/EditCompanyLogo";
import EditCompanyImages from "../../../common/components/form_sections/EditCompanyImages";

export default class CompanyLogoAndImages extends Component {
    render() {
        return (
            <div className="row padding-20-bottom">
                <div className="col alpha grid-2 empty"/>
                <div className="col grid-8 no-margin-mobile">
                    <div className="module">
                        <div className="module__contentbox module__contentbox__no-header module__twothirdswidth">
                            <p className="type-normal-subhead type-heavy type-allcaps type-single-line spacing-40-bottom">
                                Company Logos & Images
                            </p>
                            <EditCompanyLogo
                                contractorId={this.props.contractorId}
                                updateModalContent={this.updateModalContent}
                            />
                            <EditCompanyImages
                                contractorId={this.props.contractorId}
                                updateModalContent={this.updateModalContent}
                            />
                        </div>
                    </div>
                </div>
                <div className="col omega grid-2 empty"/>
                <div className="clear-block"/>
            </div>
        )
    }
}