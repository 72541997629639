import qs from 'query-string'
import _reduce from 'lodash/reduce'

export function getParamsFromSearch(querystring, options) {
    const query = qs.parse(querystring)
    const params = _reduce(
        query,
        (acc, val, key) => {
            if (val && key) {
                // these properties are arrays so they need to be
                // parsed as arrays
                if (options?.arrayVals && options.arrayVals.includes(key)) {
                    acc[key] = val.split(',')
                } else {
                    acc[key] = val
                }
            }
            return acc
        },
        {}
    )
    return params
}

export function getQueryParam(querystring, param) {
    try {
        const query = qs.parse(querystring)
        if (query[param]) {
            return query[param]
        } else {
            // console.log(`'${param}' not found in ${querystring}`)
            return null
        }
    } catch (err) {
        console.error(err)
    }
}

// get the existing querystring and update with input values
// return the new querystring
export function updateQueryString(
    queryString,
    { filters, campaignIds, sortColumn, sortOrder, page, adNetwork2CampaignIds }
) {
    let currentQuery = qs.parse(queryString)
    let newQuery = {}
    // get filter values
    // note that filters may be empty. if they are, reset all filters
    if (filters) {
        if (Object.keys(filters).length === 0) {
            delete currentQuery['contractor_ids']
            delete currentQuery['campaign_ids']
            delete currentQuery['industry_ids']
            delete currentQuery['ad_network_ids']
            delete currentQuery['ad_network2campaign_ids']
            delete currentQuery['payout_model']
        }
        const _filters = _reduce(
            filters,
            (acc, val, key) => {
                if (val?.constructor === Array) {
                    acc[key] = val.join(',')
                } else {
                    acc[key] = val
                }
                return acc
            },
            {}
        )
        newQuery = { ...newQuery, ..._filters }
    }

    // add campaign ids if they are present
    // note that an empty string is a valid value
    if (campaignIds || campaignIds === '') {
        // if there are no campaign ids, delete them from the query
        if (campaignIds === '') {
            delete currentQuery['campaign_ids']
        } else {
            newQuery['campaign_ids'] = campaignIds
        }
    }

    // add adNetwork2CampaignIds if they are present
    // note that an empty string is a valid value
    if (adNetwork2CampaignIds || adNetwork2CampaignIds === '') {
        // if there are no campaign ids, delete them from the query
        if (adNetwork2CampaignIds === '') {
            delete currentQuery['ad_network2campaign_ids']
        } else {
            newQuery['ad_network2campaign_ids'] = adNetwork2CampaignIds
        }
    }

    // sort column
    if (sortColumn) {
        newQuery['sort_column'] = sortColumn
    }

    // sort order
    if (sortOrder) {
        newQuery['sort_order'] = sortOrder
    }

    // merge with current query, stringify and return
    newQuery = { ...currentQuery, ...newQuery }
    return qs.stringify(newQuery)
}

/**
 * Merges two arrays of objects using the provided property.
 *
 * This function has a time complexity of O(N) for building the lookup table and a
 * time complexity of O(M) for merging, where N is the length of array2 and M is
 * the length of array1. It's efficient even for large arrays.
 * @param {array} arr1 array of objects
 * @param {array} arr2 array of objects
 * @param {string} key the property to use for the merge
 * @param {MergeOptions} options merge options
 * @param {boolean} options.omitMissing when `true`, objects found in arr2 but not found in arr1 will be discarded and not included in the merge result. Default is `false`
 * @returns array of objects representing the merge result of objects in arr1 and arr2
 */
export function mergeArraysByKey(arr1, arr2, key, options = { omitMissing: false }) {
    if ((arr1.length == 0 || arr2.length == 0) && options.omitMissing) {
        return []
    }
    // Create a lookup table from arr2
    const lookupTable = new Map()
    for (const obj of arr2) {
        let _k = obj[key]
        // if the key can be parsed as a number, convert it to a number
        if (parseFloat(_k)) {
            _k = +obj[key]
        }
        lookupTable.set(_k, obj)
    }

    // if the option is set to keeps objects that are not found in both
    // arrays, follow the map function route.
    if (!options.omitMissing) {
        return arr1.map((obj1) => {
            let _k = obj1[key]
            // if the key can be parsed as a number, convert it to a number
            if (parseFloat(_k)) {
                _k = +obj1[key]
            }
            const obj2 = lookupTable.get(_k)
            if (obj2) {
                return { ...obj1, ...obj2 }
            }
            return obj1
        })
    } else {
        // otherwise, if the option is set to omit objects that are not found
        // in both arrays, use this reduce function
        return arr1.reduce((acc, obj1) => {
            let _k = obj1[key]
            if (!_k) {
                throw Error(`key ${key} not found in obj`, obj1)
            }
            // if the key can be parsed as a number, convert it to a number
            if (parseFloat(_k)) {
                _k = +obj1[key]
            }
            const obj2 = lookupTable.get(_k)
            if (obj2) {
                acc.push({ ...obj1, ...obj2 })
            }
            // else {
            //     console.log(`could not find ${_k} in array 1`)
            // }
            return acc
        }, [])
    }
}

/**
 *
 * @param {string} querystring
 * @param  {...string} properties
 */
export function deleteQueryParams(querystring, ...properties) {
    try {
        const query = qs.parse(querystring)
        properties.forEach((property) => {
            if (query[property]) {
                delete query[property]
            }
        })
        return qs.stringify(query)
    } catch (err) {
        console.error(err)
    }
}

/**
 *
 * @param {string} querystring
 * @param {string} property
 * @param {any} value
 * @returns
 */
export function updateQueryParam(querystring, property, value) {
    try {
        const query = qs.parse(querystring)
        if (query[property]) {
            query[property] = value
        }
        return qs.stringify(query)
    } catch (err) {
        console.error(err)
    }
}

export function intersection(arr1, arr2) {
    // Create a Set to store unique values from arr2
    const set2 = new Set(arr2.map((v) => +v))

    // Use filter to return values from arr1 that are also in set2
    const commonValues = arr1.map((v) => +v).filter((value) => set2.has(value))

    return commonValues
}
