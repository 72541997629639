import React, {Component} from 'react';

import Highlight from './Highlight';
import {errorHandler} from '../Requests';
import HighlightService from "../service/HighlightService";
import type {HighlightInterface} from "../Interfaces/HighlightInterface";

export default class Highlights extends Component {
    constructor(props) {
        super(props);

        this.state = {
            highlights: []
        };

        this.highlightService = new HighlightService();
    }

    componentDidMount() {
        this.getHighlights();
    }

    componentWillUnmount() {
        this.highlightService.cancelSignal.cancel();
    }

    getHighlights = () =>
        this.highlightService.get()
            .then((highlights) => this.setState({highlights}));

    removeHighlight = (highlight: HighlightInterface) => {
        const highlights = [...this.state.highlights];
        const index = highlights.findIndex((item: HighlightInterface) =>
            item.highlightId == highlight.highlightId);
        highlights.splice(index, 1);
        this.setState({highlights});

        this.highlightService.dismiss(highlight.highlightId)
            .then((success) => {
                if (!success) {
                    highlights.splice(index, 0, highlight);
                    this.setState({highlights});
                }
            })
            .catch(errorHandler);
    };

    /**
     * Checks if the current path should show or hide highlights
     *      add a path to the array to hide the highlights component when the user is in them
     * @return {boolean} - true iff the path should not have highlights
     */
    showHighlightsInPath = () =>
        ['/select-enroll', '/select-enroll-confirmation'].indexOf(this.props.location.pathname) === -1;

    render() {
        if (this.state.highlights.length === 0 || !this.showHighlightsInPath()) {
            return null;
        }

        let highlights = this.state.highlights.map((highlight, key) =>
            <Highlight key={key} highlight={highlight} removeHighlight={this.removeHighlight}/>
        );

        return <div className="grey-background">
            <div className="page-width">
                <div className="row padding-10-top">
                    <div className="col alpha omega grid-12">
                        {highlights}
                    </div>
                    <div className="clear-block"/>
                </div>
            </div>
        </div>;
    }
}
