import React, { Fragment, useState } from 'react'
import Button from '../../../common/components/form_elements/Button'
import Input from '../../../common/components/form_elements/Input'
// import Radio from '../../../common/components/form_elements/Radio'
import RadioGroup from '../../../common/components/form_elements/RadioGroup'
// import MetricSentence from '../../../common/components/MetricSentence'
import { capitalizeFirstLetter } from '../../../Util'
import { FullColumn } from '../../../common/layouts'
import { ContentRow, InputWrapper, Actions, GridRow, ContextSentenceList } from './styles'
import { sanitizeBidMultiplierInput } from './utils'
import { ContentWindowScroll } from '../../../common/Modal'

export default ({ onCancel = () => null, onPreviewEdits = () => null, numEdits = 0 }) => {
    // phone bid multiplier properties
    const [phoneEditType, setPhoneEditType] = useState(null)
    const [phoneEditValue, setPhoneEditValue] = useState('')
    // form bid multiplier properties
    const [formEditType, setFormEditType] = useState(null)
    const [formEditValue, setFormEditValue] = useState('')

    const [phoneLeadsConnected, setPhoneLeadsConnected] = useState()
    const [formLeadsConnected, setFormLeadsConnected] = useState()
    const [campaignStatus, setCampaignStatus] = useState()
    const [phoneLeadsConnectedReason, setPhoneLeadsConnectedReason] = useState()
    const [formLeadsConnectedReason, setFormLeadsConnectedReason] = useState()
    const [campaignStatusReason, setCampaignStatusReason] = useState()
    const [inputErrors, setInputErrors] = useState({})

    const onSetEditType = (property, value) => {
        // phone property changes
        if (property?.includes('phone')) {
            if (value == null || sanitizeBidMultiplierInput(phoneEditValue, value) === false) {
                setPhoneEditValue('')
            }
            setPhoneEditType(value)
        }
        // form property changes
        if (property?.includes('form')) {
            if (value == null || sanitizeBidMultiplierInput(formEditValue, value) === false) {
                setFormEditValue('')
            }
            setFormEditType(value)
        }
    }

    const handleUpdateValue = (e) => {
        const { value, name } = e.target
        // try to sanitize the input. if its not good,
        // set it back to the previous edit value
        if (name?.includes('phone')) {
            let input = sanitizeBidMultiplierInput(value, phoneEditType)
            if (input === false) {
                input = phoneEditValue
            }
            setPhoneEditValue(input)
        }
        // form value
        if (name?.includes('form')) {
            let input = sanitizeBidMultiplierInput(value, formEditType)
            if (input === false) {
                input = formEditValue
            }
            setFormEditValue(input)
        }
    }

    const handlePreviewEdits = (e) => {
        // for error tracking
        const _errors = {}
        // transfor the data into the required format and pass the
        // data to the parent via the onPreviewEdits prop
        let result = []
        // phone bid multiplier
        if (phoneEditType != null && phoneEditValue != null) {
            const type = phoneEditType?.includes('percent') ? 'percent' : 'value'
            let value = Number(phoneEditValue)
            // convert to percent if a percent is set
            if (type === 'percent') {
                value = Number(phoneEditValue / 100)
            }
            if (phoneEditType?.includes('decrease')) {
                value = -value
            }
            result.push({
                property: 'phone_bid_multiplier',
                value,
                value_type: type,
            })
        }
        // form bid multiplier
        if (formEditType != null && formEditValue != null) {
            const type = formEditType?.includes('percent') ? 'percent' : 'value'
            let value = Number(formEditValue)
            // convert to percent if a percent is set
            if (type === 'percent') {
                value = Number(formEditValue / 100)
            }
            if (formEditType?.includes('decrease')) {
                value = -value
            }
            result.push({
                property: 'form_bid_multiplier',
                value,
                value_type: type,
            })
        }
        if (phoneLeadsConnected != null) {
            // if the reason is not set, add an error
            if (!phoneLeadsConnectedReason) {
                _errors.phone_leads_connected_reason = 'Reason required.'
            }
            result.push({
                property: 'phone_leads_connected',
                value: phoneLeadsConnected,
                reason: phoneLeadsConnectedReason,
            })
        }
        if (formLeadsConnected != null) {
            // if the reason is not set, add an error
            if (!formLeadsConnectedReason) {
                _errors.form_leads_connected_reason = 'Reason required.'
            }
            result.push({
                property: 'form_leads_connected',
                value: formLeadsConnected,
                reason: formLeadsConnectedReason,
            })
        }
        if (campaignStatus != null) {
            // if the reason is not set, add an error
            if (!campaignStatusReason) {
                _errors.campaign_status_reason = 'Reason required.'
            }
            result.push({
                property: 'campaign_status',
                value: campaignStatus,
                reason: campaignStatusReason,
            })
        }

        // if there are any errors, set the state and do not proceed
        if (Object.keys(_errors)?.length > 0) {
            setInputErrors(_errors)
            return
        }

        // only proceed if there are no errors
        onPreviewEdits(result)
    }

    // preview is disabled if all values are empty / null
    let previewDisabled =
        (!phoneEditValue || phoneEditValue === '' || phoneEditValue === '.') &&
        (!formEditValue || formEditValue === '' || formEditValue === '.') &&
        !phoneLeadsConnected &&
        !formLeadsConnected &&
        !campaignStatus
    // if there are any input errors, preview is disabled
    if (Object.keys(inputErrors)?.length > 0) {
        previewDisabled = true
    }
    // if there is a status change without a reason, preview is disabled
    if (
        (phoneLeadsConnected != null && !phoneLeadsConnectedReason) ||
        (formLeadsConnected != null && !formLeadsConnectedReason) ||
        (campaignStatus != null && !campaignStatusReason)
    ) {
        previewDisabled = true
    }

    const getContextSentence = () => {
        if (previewDisabled) {
            return null
        }
        let result = []
        // context for phone bid multiplier
        if (phoneEditType != null && phoneEditValue != null) {
            const action = capitalizeFirstLetter(phoneEditType.split('_')[0])
            const adverb = action === 'Set' ? 'to' : 'by'
            let metric = `${Number(phoneEditValue)}`
            if (adverb === 'by') {
                metric = `${metric}%`
            }
            result.push(
                <li key="li-bm">
                    <span className="type-heavy">{action}</span> Phone Bid Multiplier{' '}
                    {adverb} <span className="type-heavy">{metric}</span>
                </li>
            )
        }
        // context for form bid multiplier
        if (formEditType != null && formEditValue != null) {
            const action = capitalizeFirstLetter(formEditType.split('_')[0])
            const adverb = action === 'Set' ? 'to' : 'by'
            let metric = `${Number(formEditValue)}`
            if (adverb === 'by') {
                metric = `${metric}%`
            }
            result.push(
                <li key="li-bm">
                    <span className="type-heavy">{action}</span> Form Bid Multiplier{' '}
                    {adverb} <span className="type-heavy">{metric}</span>
                </li>
            )
        }
        // connect / disconnect phone leads 
        if (phoneLeadsConnected != null) {
            result.push(
                <li key="li-ancs">
                    <span className="type-heavy">
                        {phoneLeadsConnected == '1' ? 'Connect' : 'Disconnect'}
                    </span>{' '}
                    All Campaign Lead Source <span className="type-heavy">Phone Connection</span> Statuses{' '}
                </li>
            )
        }
        // connect / disconnect form leads 
        if (formLeadsConnected != null) {
            result.push(
                <li key="li-ancs">
                    <span className="type-heavy">
                        {formLeadsConnected == '1' ? 'Connect' : 'Disconnect'}
                    </span>{' '}
                    All Campaign Lead Source <span className="type-heavy">Form Connection</span> Connection Statuses{' '}
                </li>
            )
        }
        // enable / pause campaign statuses
        if (campaignStatus != null) {
            result.push(
                <li key="li-cs">
                    <span className="type-heavy">
                        {campaignStatus == '1' ? 'Enable' : 'Pause'}
                    </span>{' '}
                    All Campaign Statuses{' '}
                </li>
            )
        }
        return <ContextSentenceList>{result.map((l) => l)}</ContextSentenceList>
    }

    const handleRadioChange = (property, value) => {
        const propertyMap = {
            phone_leads_connected: setPhoneLeadsConnected,
            form_leads_connected: setFormLeadsConnected,
            campaign_status: setCampaignStatus,
        }
        propertyMap[property](value)
    }

    const handleSetReason = (property) => (e) => {
        const { value } = e.target
        const propertyMap = {
            phone_leads_connected_reason: setPhoneLeadsConnectedReason,
            form_leads_connected_reason: setFormLeadsConnectedReason,
            campaign_status_reason: setCampaignStatusReason,
        }
        // clear the error
        let errors = Object.assign({}, inputErrors)
        delete errors[property]
        setInputErrors(errors)

        // set the property
        propertyMap[property](value)
    }

    return (
        <Fragment>
            <ContentWindowScroll>
                <FullColumn>
                    <ContentRow>
                        <span className="type-normal-subhead">Change <b>Phone</b> Bid Multiplier</span>
                    </ContentRow>
                    <ContentRow>
                        <GridRow>
                            <RadioGroup
                                name="phone_edit_type"
                                showLabel={false}
                                vertical={true}
                                align="left"
                                options={[
                                    { label: 'Set New Value', value: 'set_new_value' },
                                    {
                                        label: 'Increase By Percent',
                                        value: 'increase_by_percent',
                                    },
                                    {
                                        label: 'Decrease By Percent',
                                        value: 'decrease_by_percent',
                                    },
                                ]}
                                onChange={onSetEditType}
                                checkedOption={phoneEditType}
                            />
                            <InputWrapper height={90} width={150}>
                                <Input
                                    disabled={phoneEditType == null}
                                    label="Phone Bid Multiplier"
                                    name="phone_bid_multiplier_input"
                                    type="text"
                                    valueType={
                                        !phoneEditType || phoneEditType === 'set_new_value'
                                            ? null
                                            : 'percent'
                                    }
                                    value={phoneEditValue}
                                    onChange={handleUpdateValue}
                                />
                            </InputWrapper>
                        </GridRow>
                    </ContentRow>
                    <ContentRow>
                        <span className="type-normal-subhead">Change <b>Form</b> Bid Multiplier</span>
                    </ContentRow>
                    <ContentRow>
                        <GridRow>
                            <RadioGroup
                                name="form_edit_type"
                                showLabel={false}
                                vertical={true}
                                align="left"
                                options={[
                                    { label: 'Set New Value', value: 'set_new_value' },
                                    {
                                        label: 'Increase By Percent',
                                        value: 'increase_by_percent',
                                    },
                                    {
                                        label: 'Decrease By Percent',
                                        value: 'decrease_by_percent',
                                    },
                                ]}
                                onChange={onSetEditType}
                                checkedOption={formEditType}
                            />
                            <InputWrapper height={90} width={150}>
                                <Input
                                    disabled={formEditType == null}
                                    label="Form Bid Multiplier"
                                    name="form_bid_multiplier_input"
                                    type="text"
                                    valueType={
                                        !formEditType || formEditType === 'set_new_value'
                                            ? null
                                            : 'percent'
                                    }
                                    value={formEditValue}
                                    onChange={handleUpdateValue}
                                />
                            </InputWrapper>
                        </GridRow>
                    </ContentRow>
                    <ContentRow>
                        <span className="type-normal-subhead">
                            Connect/Disconnect Phone Leads
                        </span>
                    </ContentRow>
                    <ContentRow>
                        <GridRow>
                            <RadioGroup
                                // disabled={disabled}
                                name="phone_leads_connected"
                                showLabel={false}
                                vertical={true}
                                align="left"
                                options={[
                                    { label: 'Connected', value: '1' },
                                    { label: 'Disconnected', value: '0' },
                                ]}
                                onChange={handleRadioChange}
                                checkedOption={
                                    phoneLeadsConnected ? `${phoneLeadsConnected}` : null
                                }
                            />
                            <InputWrapper>
                                <Input
                                    disabled={phoneLeadsConnected == null}
                                    label="Change Reason"
                                    name="phone_leads_connected_reason"
                                    type="text"
                                    onChange={handleSetReason(
                                        'phone_leads_connected_reason'
                                    )}
                                    hasError={!!inputErrors?.phone_leads_connected_reason}
                                    errorMessage={
                                        inputErrors?.phone_leads_connected_reason
                                    }
                                />
                            </InputWrapper>
                        </GridRow>
                    </ContentRow>
                    <ContentRow>
                        <span className="type-normal-subhead">
                            Connect/Disconnect Form Leads
                        </span>
                    </ContentRow>
                    <ContentRow>
                        <GridRow>
                            <RadioGroup
                                name="form_leads_connected"
                                showLabel={false}
                                vertical={true}
                                align="left"
                                options={[
                                    { label: 'Connected', value: '1' },
                                    { label: 'Disconnected', value: '0' },
                                ]}
                                onChange={handleRadioChange}
                                checkedOption={
                                    formLeadsConnected ? `${formLeadsConnected}` : null
                                }
                            />
                            <InputWrapper>
                                <Input
                                    disabled={formLeadsConnected == null}
                                    label="Change Reason"
                                    name="form_leads_connected_reason"
                                    type="text"
                                    onChange={handleSetReason(
                                        'form_leads_connected_reason'
                                    )}
                                    hasError={!!inputErrors?.form_leads_connected_reason}
                                    errorMessage={
                                        inputErrors?.form_leads_connected_reason
                                    }
                                />
                            </InputWrapper>
                        </GridRow>
                    </ContentRow>
                    <ContentRow>
                        <span className="type-normal-subhead">Set Campaign Status</span>
                    </ContentRow>
                    <ContentRow>
                        <GridRow>
                            <RadioGroup
                                // disabled={disabled}
                                name="campaign_status"
                                showLabel={false}
                                align="spread"
                                vertical={true}
                                options={[
                                    { label: 'Enabled', value: '1' },
                                    { label: 'Paused', value: '0' },
                                ]}
                                onChange={handleRadioChange}
                                checkedOption={
                                    campaignStatus ? `${campaignStatus}` : null
                                }
                            />
                            <InputWrapper>
                                <Input
                                    disabled={campaignStatus == null}
                                    label="Change Reason"
                                    name="campaign_status_reason"
                                    type="text"
                                    onChange={handleSetReason('campaign_status_reason')}
                                    hasError={!!inputErrors?.campaign_status_reason}
                                    errorMessage={inputErrors?.campaign_status_reason}
                                />
                            </InputWrapper>
                        </GridRow>
                    </ContentRow>
                    <ContentRow>
                        <InputWrapper>{getContextSentence()}</InputWrapper>
                    </ContentRow>
                    {/* <ContentRow /> */}
                </FullColumn>
            </ContentWindowScroll>

            <Actions>
                <Button variant="text" onClick={onCancel}>
                    <span className="type-heavy">Cancel</span>
                </Button>
                <Button disabled={previewDisabled} onClick={handlePreviewEdits}>
                    Preview Changes
                </Button>
            </Actions>
        </Fragment>
    )
}
