import React from 'react';

const PopupForm = (props) => {
	return (
		<div className="popup-form-container">
			{props.children}
		</div>
	);
};

export default PopupForm;
