import React from 'react';

import TermsAndConditions from "../client/TermsAndConditions";
import PrivacyPolicy from "../client/PrivacyPolicy";

const SignupFooter = (props) => {
	return (
		<footer className="padding-30-top padding-50-bottom grey-background">
			<div className="page-width">
				<div className="row">
					<div className="col alpha omega grid-12">
						<p className="type-extra-small-body type-single-line type-centered-mobile">
							<span
								className="type-right-side-bump-full type-right-side-bump-tablet type-force-newline-mobile spacing-10-bottom-mobile">
								&copy; {(new Date()).getFullYear()} {props.whiteLabelPartnership.white_label_name}
							</span>
							<button className="type-heavy type-blue button-clean"
									onClick={() => props.updateModalContent(
										<TermsAndConditions
											whiteLabelPartnership={props.whiteLabelPartnership}
											inAccountActivation={true}
										/>,
										props.whiteLabelPartnership.white_label_name +
										" Advertising Program Terms"
									)}
							>
								{props.whiteLabelPartnership.white_label_name} Terms and Conditions
							</button>
							{' '}and our{' '}
							<button className="type-heavy type-blue button-clean"
									onClick={() => props.updateModalContent(
										<PrivacyPolicy
											whiteLabelPartnership={props.whiteLabelPartnership}
										/>,
										props.whiteLabelPartnership.white_label_name +
										" Privacy Policy"
									)}
							>
								Privacy Policy
							</button>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default SignupFooter;