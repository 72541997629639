import React from 'react';

import Radio from '../../common/components/form_elements/Radio';
import TextArea from '../../common/components/form_elements/TextArea';
import Tooltip from '../../common/Tooltip';
import {getDate, safeDate} from "../../Util";
import FormModal from '../../common/components/modal/FormModal';
import AuthService from "../../service/AuthService";
import CostPerLeadModal from "../../common/CostPerLeadModal";

/**
 * Update Campaign Status modal for when a campaign is Enabled
 * and can be Paused.
 *
 * Extends FormModal (subclass of React.Component) in order to get desired
 * scroll-to-top behavior each time the modal is opened.
 */
 
const formatReEnableDate = (dateString) => {
    const yearString = dateString.substring(0,4);
    const monthString = dateString.substring(5,7);
    const dayString = dateString.substring(8,10);
    
    return `${monthString}/${dayString}/${yearString}`;
}
 
const leadVolumeTooltipContents = (
    <>
    <span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        If you’d like to simply reduce your Lead Volume, consider the following better alternatives to completely Pausing your Campaign:
    </span>
    <span className="type-normal-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
        Reduce Cost Per Lead
    </span>
    <ul>
        <li className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
            You can increase or decrease your desired Lead Volume by adjusting your{' '}
			<a
				href={
					AuthService.isMarketplace
						? "https://support.servicedirect.com/adjust-cost-per-lead"
						: "https://support.servicedirect.com/select/adjust-cost-per-lead"
				}
				target="_blank"
				rel="noopener noreferrer"
			>
				Cost Per Lead
			</a>.
        </li>
    </ul>
    <span className="type-normal-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
        Adjust Campaign Schedule
    </span>
    <ul>
        <li className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
            The Campaign Schedule is the time of day during which your Campaigns are actively competing for Leads.
        </li>
        <li className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
            If you’d like to reduce your Lead Volume, then set your Campaign Schedule to fewer hours per day of the week. Learn more about{' '}
			<a
				href={
					AuthService.isMarketplace
						? "https://support.servicedirect.com/lead-volume"
						: "https://support.servicedirect.com/select/lead-volume"
				}
				target="_blank"
				rel="noopener noreferrer"
			>
				Lead Volume
			</a>.
        </li>
    </ul>
    </>
);

const pauseTooltipContents = (
	<>
	<span className="type-large-body type-black type-force-newline type-heavy type-notransform type-narrow-line-height spacing-10-bottom">
		Add an Ad Schedule
	</span>
	<span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
		{
		AuthService.isMarketplace
			? "Set up a Campaign Schedule to control the time you are actively receiving Leads."
			: "Set up an Ad Schedule to control the time you are actively advertised on Google, Microsoft, and other affiliates."
		}
	</span>
	<span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
		<span className="type-heavy">Note: </span>We are unable to guarantee you will never receive a call outside
		of these times and after hours calls are still subject to normal Billable Lead guidelines.	
	</span>
	<span className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
		When Paused, all Lead generation for the Campaign is stopped. Pausing may only be done once per hour and may require several hours to take effect.
	</span>
	</>
);

export default class UpdateStatusEnabledModal extends FormModal {
    constructor(props) {
        super(props);

        let reEnableDate = this.props.campaign.auto_unpause_timestamp
            ? getDate(this.props.campaign.auto_unpause_timestamp)
            : '';

        let pauseLater = false, pauseDate = '';
        if (this.props.campaign.auto_pause_timestamp) {
            pauseLater = true;
            pauseDate = getDate(this.props.campaign.auto_pause_timestamp);
        }

        this.pauseReasonInputRef = React.createRef();

        this.state = {
            reason: '',
            missingReasonError: false,

            pause_later: pauseLater,
            pause_date: pauseDate,
            pause_date_error: false,

            re_enable: 'on_date',
            re_enable_date: reEnableDate,
            re_enable_date_error: false,

            //
            // Set to true when form is submitting in order to
            // visually disable the submit button
            //
            submitting: false,

            //
            // When true, the modal shifts to become a Confirmation Modal
            // to make sure the client really wants to pause.
            //
            confirming: false,
        };
    }

    openCostPerLeadModal = () => {
        this.props.updateModalContent({
            content: <CostPerLeadModal
                campaign={this.props.campaign}
                campaignIndex={this.props.campaignIndex}
                submitCplChange={this.props.submitCplChange}
            />,
            header: `Edit Cost Per Lead for ${this.props.campaign.campaign_name}`,
            flatBottom: true
        });
    };

    submitStatusChange = () => {
        //
        // Set submitting = true so that submit button will be disabled
        //
        this.setState({submitting: true});

        //
        // If already on confirmation popup, make the request
        // for the actual status change
        //
        if (this.state.confirming) {
            this.props.submitPutStatus(
                'paused',
                this.state,
                this.props.campaignIndex,
                () => {
                    // Set submitting = false so that submit button will be re-enabled
                    this.setState({submitting: false});
                    this.props.updateModalContent();
                },
                false
            );
        }
            //
            // If not yet on the confirmation page, still make a request, but
            // with validation_only=1 so we get any error messages but don't make
            // the status change
        //
        else {
            this.props.submitPutStatus(
                'paused',
                this.state,
                this.props.campaignIndex,
                (resp) => {
                    //
                    // Set submitting = false so that submit button will be re-enabled
                    //
                    let stateChanges = {submitting: false};

                    //
                    // If successful, advance to the confirmation screen
                    // - Set state.confirming = true (automatically makes most copy changes)
                    // - Change Modal Header
                    // - Clear message blocks
                    //
                    if (resp.status == 200) {
                        stateChanges.confirming = true;
                        this.props.updateModalContent({header: 'Campaign Status'}, false);
                        this.props.updateMessageBlocks([], 'success');
                    } else if (resp.status == 400) {
                        if (this.state.reason.length === 0) {
                            stateChanges.missingReasonError = true;
                            this.pauseReasonInputRef.current.focus();
                        }
                    }

                    stateChanges.pause_date_error = resp.data.errors && resp.data.errors.length
                        && resp.data.errors[0].parameter === 'pause_date';
                    stateChanges.re_enable_date_error = resp.data.errors && resp.data.errors.length
                        && resp.data.errors[0].parameter === 're_enable_date';

                    this.setState(stateChanges);
                },
                true
            );
        }
    };

    render() {
        let notAllowedToPauseYet = this.props.campaign.next_allowed_pause_timestamp
            && safeDate(this.props.campaign.next_allowed_pause_timestamp) > new Date();

        return <>
            <p className="type-alert-text type-alert-text__success type-alert-text__no-margin-left type-small-body spacing-5-bottom">
                Campaign Enabled
            </p>
            {!this.state.confirming
            ? (
            <p className="type-normal-subhead no-margin-top spacing-30-bottom">
                <span className="type-heavy">{this.props.campaign.campaign_name}</span>
            </p>
            ) : (
            <p className="type-large-body no-margin-top spacing-20-bottom">
                <span className="type-heavy">Please confirm you want to Pause {this.props.campaign.campaign_name}</span>
            </p>
            )}
            
            {this.state.confirming && (
                <p className="type-normal-body spacing-20-bottom">
                    <span className="type-heavy type-red">
                        Campaign will be Paused now
                        {this.state.re_enable_date && <>{' '}and is scheduled to Re-Enable on {formatReEnableDate(this.state.re_enable_date)}</>}.
                    </span>
                    {' '}Pausing may only be done once per hour and may require several hours to take effect.
                </p>
            )}
            
            <div className={!this.state.confirming ? 'spacing-30-bottom' : 'spacing-60-bottom'}>
                {!this.state.confirming ? (
                <p className="type-large-body type-heavy">
                    Do you want to reduce Lead Volume for this Campaign?{' '}
                    <Tooltip
                        position="bottom"
                        modalContainerRef={this.props.modalContainerRef}
                        content={leadVolumeTooltipContents}
                    />
                </p>
                ) : (
                <p className="type-large-body type-heavy">
                    If you would like to simply reduce your Lead Volume, consider these better alternatives to completely Pausing:{' '}
                    <Tooltip
                        position="bottom"
                        modalContainerRef={this.props.modalContainerRef}
                        content={leadVolumeTooltipContents}
                    />
                </p>
                )}
                <div className="spacing-10-top">
                    <button
                        className="ui-normal-button button"
                        onClick={this.openCostPerLeadModal}
                    >
                        Lower Cost per Lead
                    </button>
                    <button
                        className="ui-normal-button button"
                        onClick={() => this.props.updateCampaignScheduleModal({ status: 'enabled'})}
                    >
                        Limit Campaign Schedule
                    </button>
                </div>
            </div>
            
            {!this.state.confirming && (
            <>
            <p className="type-large-body type-heavy spacing-10-bottom">
                Do you want to completely Pause this Campaign?{' '}
                <Tooltip
                    position="bottom"
                    modalContainerRef={this.props.modalContainerRef}
                    content={pauseTooltipContents}
                />
            </p>
            
            <div className="type-normal-body spacing-20-bottom">
                <span className="type-heavy type-red">When Paused, all Lead generation for the Campaign is stopped.</span> Pausing may only be done once per hour and may require several hours to take effect.
            </div>
            

            <p className="type-large-body type-heavy spacing-10-bottom">
                Pause Options
            </p>
            <div className="radio-container spacing-10-bottom-mobile">
                <Radio
                    name="pause-when"
                    label="Pause my Campaign Now"
                    id="pause-now"
                    value="pause-now"
                    checked={this.state.pause_later === false}
                    onChange={() => this.setState({pause_later: false})}
                />
            </div>
            <div className="radio-container spacing-30-bottom">
                <Radio
                    name="pause-when"
                    label={
                        <div>
                            <span className="type-right-side-bump">Pause my Campaign on</span>
                            <input
                                // type={(this.state.pause_date) ? 'date' : 'text'}
                                type='date'
                                className={'ui-text-field ui-normal-text-input ui-text-field-inline'
                                   + (this.state.pause_date_error ? 'ui-alert' : '')}
                                id="pause-date-picker"
                                name="pause-date"
                                // placeholder="Select Date"
                                value={this.state.pause_date}
                                onFocus={(event) => {
                                //   event.target.type = 'date';
                                  event.target.click();
                                  event.target.focus();
                                }}
                                // onBlur={(event) => {
                                //     if (event.target.value.length === 0) {
                                //         event.target.type = 'text';
                                //     }
                                // }}
                                onChange={(event) => this.setState({pause_date: event.target.value})}
                            />
                        </div>
                    }
                    id="pause-later"
                    value="pause-later"
                    checked={this.state.pause_later === true}
                    onChange={() => this.setState({pause_later: true})}
                />
            </div>
            <p className="type-large-body type-heavy spacing-10-bottom">
                Re-Enable Options
            </p>
            <div className="radio-container spacing-10-bottom-mobile">
                <Radio
                    name="re-enable-when"
                    label={
                        <div>
                        <span className="type-right-side-bump">Re-Enable my Campaign on</span>
                        <input
                            // type={(this.state.re_enable_date) ? 'date' : 'text'}
                            type='date'
                            className={'ui-text-field ui-normal-text-input ui-text-field-inline flex'
                               + (this.state.re_enable_date_error ? 'ui-alert' : '')}
                            id="reenable-date-picker"
                            name="re-enable-date"
                            // placeholder="Select Date"
                            value={this.state.re_enable_date}
                            onFocus={(event) => {
                            //   event.target.type = 'date';
                              event.target.click();
                              event.target.focus();
                            }}
                            // onBlur={(event) => {
                            //     if (event.target.value.length === 0) {
                            //         event.target.type = 'text';
                            //     }
                            //  }}
                            onChange={(event) => this.setState({re_enable_date: event.target.value})}
                        />
                    </div>}
                    id="date-pause"
                    value="date-pause"
                    checked={this.state.re_enable == 'on_date'}
                    onChange={() => this.setState({re_enable: 'on_date'})}
                />
            </div>
            <div className="radio-container spacing-30-bottom">
                <Radio
                    name="re-enable-when"
                    label="Leave my Campaign Paused"
                    id="indefinite-pause"
                    value="indefinite-pause"
                    checked={this.state.re_enable == 'never'}
                    onChange={() => this.setState({re_enable: 'never'})}
                />
            </div>

            <div className="spacing-60-bottom">
                <TextArea
                    name="reason"
                    label="Reason for Pausing"
                    value={this.state.reason}
                    onChange={(event) => {
                        let stateChanges = {reason: event.target.value};

                        if (this.state.missingReasonError) {
                            stateChanges.missingReasonError = false;
                        }

                        this.setState(stateChanges);
                    }}
                    required={true}
                    displayRequiredAsText={true}
                    hasError={this.state.missingReasonError}
                    inputRef={this.pauseReasonInputRef}
                />
            </div>
            </>
            )}
            
            <div className="ui-hide-full ui-hide-tablet">
                <div className="popup__form__row spacing-40-top-mobile">
                    <div className="popup__form__cell popup__form__cell__100__mobile">
                        {notAllowedToPauseYet
                            ? <button className="button ui-normal-button ui-full-width-button" disabled={true}>
                                Campaigns can Pause only once per hour
                            </button>
                            : <button className="button ui-normal-button ui-full-width-button"
                                      onClick={this.submitStatusChange}
                                      disabled={this.state.submitting || this.state.missingReasonError}>
                                {this.state.confirming ? 'Confirm Campaign Pause' : 'Pause Campaign'}
                            </button>}
                    </div>
                </div>
				<div className="popup__form__row spacing-20-top-mobile">
					<div className="popup__form__cell popup__form__cell__100__mobile type-centered">
                        {this.state.confirming
                            ? <span role="button" className="type-small-body type-heavy type-blue"
                                    onClick={() => {
                                        this.setState({'confirming': false});
                                        this.props.updateModalContent({header: 'Campaign Status'}, false);
                                }}>
                                	Back to Previous Step
                            	</span>
                            	: <span role="button"
                                    	onClick={() => this.props.updateModalContent()}
                                    	className="type-small-body type-heavy type-blue">
                            	Cancel Edits
                        	</span>
						}
					</div>
				</div>
            </div>
            <div className="ui-hide-mobile">
                <div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
                    <div className="popup__form__row popup__form__row__slam-right">
                        <div className="popup__form__cell">
                            {this.state.confirming
                                ? <span role="button" className="type-small-body type-heavy type-blue"
                                        onClick={() => {
                                            this.setState({'confirming': false});
                                            this.props.updateModalContent({header: 'Campaign Status'}, false);
                                        }}>
                                    Back to Previous Step
                                </span>
                                : <span role="button"
                                        onClick={() => this.props.updateModalContent()}
                                        className="type-small-body type-heavy type-blue">
                                Cancel Edits
                            </span>}
                        </div>
                        <div className="popup__form__cell">
                            {notAllowedToPauseYet
                                ? <button className="button ui-normal-button" disabled={true}>
                                    Campaigns can Pause only once per hour
                                </button>
                                : <button className="button ui-normal-button" onClick={this.submitStatusChange}
                                          disabled={this.state.submitting || this.state.missingReasonError}>
                                    {this.state.confirming ? 'Confirm Campaign Pause' : 'Pause Campaign'}
                                </button>}
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}
