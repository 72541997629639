import React from 'react';
import PropTypes from 'prop-types';

export const InputWithButton = (props) => {
    let inputClass = 'ui-text-field ui-normal-text-input',
        labelClass = 'type-small-body type-narrow-line-height type-heavy spacing-5-bottom';

    if (props.hasError || props.errorMessage) {
        inputClass += ' ui-text-field ui-alert';
        labelClass += ' type-alert';
    }

    if (props.disabled) {
        inputClass += ' disabled';
    }

    const buildLabel = (label) => {
        let words = label.split(' ');
        const lastWord = words.pop();
        let otherWords = '';
        if (words.length > 0) {
            otherWords = words.join(' ');
        }

        return <span>
            {otherWords + ' '}
            <span className="type-no-break">{lastWord}</span>
        </span>;
    };

    let button;
    const buttonSide = props.buttonSide || 'right';
    if (props.buttonOnClick) {
        button = <button className="button ui-normal-button" type={props.buttonType || 'button'}
                         onClick={props.buttonOnClick}
                         disabled={props.buttonDisabled}>
            {props.buttonText || "Save"}
        </button>;
    }

    return <>
        {props.label &&
        <label htmlFor={props.id || props.name} className={labelClass}>
            {buildLabel(props.label)}
        </label>}
        <div className="input-group-wrapper">
            {buttonSide === 'left' && button}
            <input
                type={props.type}
                id={props.id || props.name}
                name={props.name}
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                onKeyUp={props.onKeyUp}
                required={props.required}
                className={inputClass}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                ref={props.inputRef}
                maxLength={props.maxLength}
                readOnly={!!props.readOnly}
                disabled={!!props.disabled}
                min={props.min}
                max={props.max}
                autoComplete={props.autoComplete}
            />
            {buttonSide === 'right' && button}
        </div>
        {props.errorMessage !== undefined ? (
            <div className="input-error">{props.errorMessage}</div>
        ) : null}
    </>;
};

export default InputWithButton;

InputWithButton.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    inputRef: PropTypes.object,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    onBlur: PropTypes.func,
    autoComplete: PropTypes.string,
    buttonOnClick: PropTypes.func,
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttonSide: PropTypes.oneOf(['left', 'right']),
    buttonDisabled: PropTypes.bool,
    buttonType: PropTypes.oneOf(['button', 'submit', 'reset']),
};
