import React, { useEffect, useState } from 'react'
import CampaignService from '../../../../service/CampaignService'
import EditsInProgressModal from './EditsInProgressModal'

export default ({ requestId, handleEditsComplete }) => {
    const [progressTotal, setProgressTotal] = useState()
    const [progressCompleted, setProgressCompleted] = useState()
    const [progressRemaining, setProgressRemaining] = useState()
    const PROGRESS_POLLING_INTERVAL = 5000 // milliseconds

    useEffect(() => {
        const campaignService = new CampaignService()
        const getProgressData = () => {
            campaignService
                .getMarketplaceCampaignEditProgress(requestId)
                .then((result) => {
                    const { task_summary } = result?.data
                    if (task_summary == null) {
                        return
                    }
                    const { total, successful, remaining } = task_summary
                    setProgressTotal(total)
                    setProgressCompleted(successful)
                    setProgressRemaining(remaining)
                    // when the updates are finished
                    if (remaining === 0) {
                        setTimeout(() => {
                            handleEditsComplete()
                        }, 1000)
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        }
        // start a timer to periodically make an API call
        const progressInterval = setInterval(getProgressData, PROGRESS_POLLING_INTERVAL)
        // cancel the timer on close
        return () => clearInterval(progressInterval)
    }, [handleEditsComplete, requestId])

    return (
        <EditsInProgressModal
            total={progressTotal}
            completed={progressCompleted}
            remaining={progressRemaining}
        />
    )
}
