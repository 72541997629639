import React, { forwardRef } from 'react'
import SkeletonText from '../Skeleton/SkeletonText'

export default forwardRef((props, ref) => {
    const { value, label } = props
    return (
        <div>
            <div className="popup-stats__cell">
                <div>
                    <span
                        ref={ref}
                        className="type-small-headline type-semibold type-narrow-line-height stats__value"
                    >
                        {value}
                    </span>
                </div>
                <div className="type-normal-body type-narrow-line-height">{label}</div>
            </div>
        </div>
    )
})

export const MetricSkeleton = ({ label = true }) => {
    return (
        <div>
            <div className="popup-stats__cell">
                <div className="">
                    <SkeletonText variant="h3" width={60} height={33} />
                </div>
                {label && (
                    <div className="type-normal-body type-narrow-line-height">
                        <SkeletonText width={150} />
                    </div>
                )}
            </div>
        </div>
    )
}
