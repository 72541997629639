import React from 'react';

import SearchField from '../../common/components/form_elements/SearchField';
import FormModal from "../../common/components/modal/FormModal";
import {
    billableOptions, clientLeadReviewOptions, dateRangeOptions, starredOptions, needsFollowUpOptions,
    jobWonOptions, bookedAppointmentOptions, callAnsweredOptions, managedOptions, leadTypeOptions
} from "../../common/StaticValues";
import DateRangeService from "../../service/DateRange";
import MultiSelectList from '../../common/components/form_elements/MultiSelectList';
import PopupForm from '../../common/popup_form/PopupForm';
import PopupFormCell from '../../common/popup_form/PopupFormCell';
import PopupFormDivider from '../../common/popup_form/PopupFormDivider';
import PopupFormGroup from '../../common/popup_form/PopupFormGroup';
import PopupFormRow from '../../common/popup_form/PopupFormRow';

/**
 * Modal containing Filters for filtering leads within the Client Lead Manager.
 * NOTE: The Admin Lead Manager does not use this, instead it has all the filters straight at the top of the View.
 */

export interface LeadsFilters {
    lead_id: number,
    is_valid: boolean,
    needs_follow_up: boolean,
    call_answered: boolean,
    booked_appointment: boolean,
    job_won: boolean,
    lead_type: string,
    contractor_ids: number[],
    campaign_ids: number[],
    industry_ids: number[],
    date_range_id: number,
    date_from: string,
    date_to: string,
    keyword: string,
    under_review: boolean,
    billable_select_list_value: string,
    starred: boolean,
    managed: boolean
}

export default class FilterLeadsModal extends FormModal {
    constructor(props) {
        super(props);

        //
        // To avoid having to re-render components, the Filter Modal keeps its own state of which filters are selected.
        // The state is based on the passed in selectedFilters - the keys are extracted out to be the main state keys
        //
        this.state = {...props.selectedFilters};

        this.billableStatusSelectRef = React.createRef();
        this.leadReviewSelectRef = React.createRef();
        this.starredSelectRef = React.createRef();
        this.needsFollowUpSelectRef = React.createRef();
        this.managedProgressSelectRef = React.createRef();
        this.bookedAppointmentSelectRef = React.createRef();
        this.callAnsweredSelectRef = React.createRef();
        this.jobWonSelectRef = React.createRef();
        this.jobProgressSelectRef = React.createRef();
        this.campaignsSelectRef = React.createRef();
        this.contractorsSelectRef = React.createRef();
        this.serviceCategoriesSelectRef = React.createRef();
        this.dateRangeSelectRef = React.createRef();
        this.searchFieldRef = React.createRef();
        this.leadTypeSelectRef = React.createRef();

        /* prepend the empty value to the options */
        this.starredOptionsFull = [{"value": "", "text": "All"}]
            .concat(starredOptions);
        this.needsFollowUpOptionsFull = [{"value": "", "text": "All"}]
            .concat(needsFollowUpOptions);
        this.jobWonOptionsFull = [{"value": "", "text": "All"}]
            .concat(jobWonOptions);
        this.bookedAppointmentOptionsFull = [{"value": "", "text": "All"}]
            .concat(bookedAppointmentOptions);
        this.callAnsweredOptionsFull = [{"value": "", "text": "All"}]
            .concat(callAnsweredOptions);
        this.managedOptionsFull = [{"value": "", "text": "All"}]
            .concat(managedOptions);

        this.dateRangeService = new DateRangeService();
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props.clickAction === "search") {
            this.searchFieldRef.current.focus();
        }
        else {
            this.billableStatusSelectRef.current.focus();
        }

        if (this.props.scrollToSearch) {
            const scrollBox = this.props.scrollerRef.current;
            const scrollingContent = scrollBox.querySelector('.popup__contentbox');

            // Delay hack prevents React from interfering with setting the scroll state.
            setTimeout(() => {
                scrollBox.scrollTop = scrollingContent.scrollHeight
            }, 100);
        }
    }

    resetFilters = () => {
        let defaultFilters = {...this.props.defaultFilters};
        defaultFilters.lead_progress = '';
        this.setState(defaultFilters);
    };

    isDateRangeAllTime = () => {
        // Filter to selected date range option
        const filteredDateRangeOptions = dateRangeOptions.filter(option => option.value === this.state.date_range_id);

        return (filteredDateRangeOptions.length === 0) || (filteredDateRangeOptions[0].text === 'All Time');
    }
    handleMultiSelect = (key, values: Array) => {
        this.setState({[key]: values})
    }

    updateDateValue = (dateRangeId) => {
        let data = {
            'date_range_id': dateRangeId
        };
        if (dateRangeId !== "custom") {
            let fromToDates = this.dateRangeService.getFromToDates(dateRangeId);

            data["date_from"] = fromToDates.dateFrom;
            data["date_to"] = fromToDates.dateTo;
        }

        this.setState(data);
    };

    updateValue = (key, value) => {
        let data = {[key]: value};

        if (["date_from", "date_to"].includes(key)) {
            data["date_range_id"] = "custom";
        }

        this.setState(data);
    };

    isManaged = () => !!this.state.call_answered || !!this.state.booked_appointment || !!this.state.job_won;

    render() {
        // console.log('filter leads modal', this.state, dateRangeOptions)
        let contractorMultiSelectJsx = null;
        if (this.props.isParentContractor) {
            contractorMultiSelectJsx = (
                <PopupFormRow>
                    <PopupFormCell>
                        <MultiSelectList
                            multiselect={true}
                            label="Company Names"
                            name="contractor_ids"
                            emptyLabel="All"
                            disabledLabel=""
                            options={this.props.contractorOptions}
                            selections={this.state.contractor_ids || []}
                            onChange={(values) => this.handleMultiSelect('contractor_ids', values)}
                        />
                    </PopupFormCell>
                </PopupFormRow>
            );
        }
        return <>
            <div className="ui-hide-full ui-hide-tablet">
                <div className="popup__form__row spacing-30-bottom">
                    <div className="popup__form__cell popup__form__cell__100">
                        <p className="type-normal-body type-narrow-line-height type-heavy spacing-10-bottom">
                            Quick Filters
                        </p>
                        <ul>
                            <li className="type-normal-body type-heavy spacing-10-bottom">
                                <span role="button" onClick={() =>
                                    this.props.quickFilter({needs_follow_up: "1"}, true)}>
                                    Leads for Follow-Up
                                </span>
                            </li>
                            <li className="type-normal-body type-heavy spacing-10-bottom">
                                <span role="button" onClick={() =>
                                    this.props.quickFilter({starred: "1"}, true)}>
                                    Starred Leads
                                </span>
                            </li>
                            <li className="type-normal-body type-heavy spacing-10-bottom">
                                <span role="button" onClick={() => this.props.quickFilter({managed: 0}, true)}>
                                    Unmanaged Leads
                                </span>
                            </li>
                            <li className="type-normal-body type-heavy spacing-10-bottom">
                                <span role="button" onClick={() => this.props.quickFilter({managed: 1}, true)}>
                                    Partially Managed Leads
                                </span>
                            </li>
                            <li className="type-normal-body type-heavy spacing-10-bottom">
                                <span role="button" onClick={() => this.props.quickFilter({managed: 2}, true)}>
                                    Fully Managed Leads
                                </span>
                            </li>
                            <li className="type-normal-body type-heavy spacing-10-bottom">
                                <span role="button" onClick={() =>
                                    this.props.quickFilter({is_valid: 'null'}, true)}>
                                    All Leads
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <PopupForm>
                <PopupFormGroup>
                    {contractorMultiSelectJsx}
                    <PopupFormRow
                        fullWidthOnMobile={true}
                    >
                        <PopupFormCell>
                            <MultiSelectList
                                label="Billable Status"
                                name="billable-status"
                                selections={[this.state.billable_select_list_value]}
                                options={billableOptions}
                                onChange={(vals) => this.updateValue("billable_select_list_value", vals[0])}
                                required={false}
                                inputRef={this.billableStatusSelectRef}
                                extraClass={
                                    (this.state.billable_select_list_value !== billableOptions[0].value) ? 'ui-highlight' : ''
                                }
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            <MultiSelectList
                                label="Lead Review Status"
                                name="lead-review-status"
                                selections={[this.state.client_lead_review_select_list_value]}
                                options={clientLeadReviewOptions}
                                onChange={(vals) => this.updateValue("client_lead_review_select_list_value", vals[0])}
                                inputRef={this.leadReviewSelectRef}
                                extraClass={
                                    (this.state.client_lead_review_select_list_value && this.state.client_lead_review_select_list_value !== clientLeadReviewOptions[0].value) &&
                                    'ui-highlight'
                                }
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            <MultiSelectList
                                label="Lead Type"
                                name="lead-type"
                                selections={[this.state.lead_type || ""]}
                                options={leadTypeOptions}
                                onChange={(vals) => this.updateValue("lead_type", vals[0])}
                                inputRef={this.leadTypeSelectRef}
                                extraClass={
                                    this.state.lead_type !== leadTypeOptions[0].value
                                        ? 'ui-highlight'
                                        : ''
                                }
                            />
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>
                
                <PopupFormDivider />
                
                <PopupFormGroup>
                    <PopupFormRow
                        fullWidthOnMobile={true}
                    >
                        <PopupFormCell>
                            <MultiSelectList
                                label="Managed Lead Progress"
                                name="managed"
                                disabled={!this.state.managed && this.isManaged()}
                                selections={[this.state.managed || ""]}
                                options={this.managedOptionsFull}
                                onChange={(vals) => this.updateValue("managed", vals[0])}
                                inputRef={this.managedProgressSelectRef}
                                extraClass={this.state.managed ? 'ui-highlight' : ''}
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            <MultiSelectList
                                label="Starred Status"
                                name="starred-status"
                                selections={[this.state.starred || ""]}
                                options={this.starredOptionsFull}
                                onChange={(vals) => this.updateValue("starred", vals[0])}
                                inputRef={this.starredSelectRef}
                                extraClass={
                                    (this.state.starred && this.state.starred !== this.starredOptionsFull[0].value) &&
                                    'ui-highlight'
                                }
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            <MultiSelectList
                                label="Needs Follow-Up"
                                name="needs-follow-up"
                                selections={[this.state.needs_follow_up || ""]}
                                options={this.needsFollowUpOptionsFull}
                                onChange={(vals) => this.updateValue("needs_follow_up", vals[0])}
                                inputRef={this.needsFollowUpSelectRef}
                                extraClass={
                                    (this.state.needs_follow_up && this.state.needs_follow_up !== this.needsFollowUpOptionsFull[0].value) &&
                                    'ui-highlight'
                                }
                            />
                        </PopupFormCell>
                    </PopupFormRow>
                    <PopupFormRow
                        fullWidthOnMobile={true}
                    >
                        <PopupFormCell>
                            <MultiSelectList
                                label="Call Answered"
                                name="call-answered"
                                wideDropdown={true}
                                disabled={!!this.state.managed && !this.state.call_answered}
                                options={this.callAnsweredOptionsFull}
                                selections={[this.state.call_answered || ""]}
                                onChange={(vals) => this.updateValue("call_answered", vals[0])}
                                extraClass={
                                    (this.state.call_answered && this.state.call_answered !== this.callAnsweredOptionsFull[0].value) &&
                                    'ui-highlight'
                                }
                                inputRef={this.callAnsweredSelectRef}
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            <MultiSelectList
                                label="Booked Appointment"
                                name="booked-appointment"
                                disabled={!!this.state.managed && !this.state.booked_appointment}
                                options={this.bookedAppointmentOptionsFull}
                                selections={[this.state.booked_appointment || ""]}
                                onChange={(vals) => this.updateValue("booked_appointment", vals[0])}
                                extraClass={
                                    (this.state.booked_appointment && this.state.booked_appointment !== this.bookedAppointmentOptionsFull[0].value) &&
                                    'ui-highlight'
                                }
                                inputRef={this.bookedAppointmentSelectRef}
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            <MultiSelectList
                                label="Job Won"
                                name="job-won"
                                disabled={!!this.state.managed && !this.state.job_won}
                                options={this.jobWonOptionsFull}
                                selections={[this.state.job_won || ""]}
                                onChange={(vals) => this.updateValue("job_won", vals[0])}
                                extraClass={
                                    (this.state.job_won && this.state.job_won !== this.jobWonOptionsFull[0].value) &&
                                    'ui-highlight'
                                }
                                inputRef={this.jobWonSelectRef}
                            />
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>
                
                <PopupFormDivider />
                
                <PopupFormGroup>
                    <PopupFormRow
                        fullWidthOnMobile={true}
                    >
                        <PopupFormCell>
                            <MultiSelectList
                                multiselect={true}
                                label="Service Categories"
                                selectAllByDefault={true}
                                name="industry_ids"
                                emptyLabel="All Categories"
                                disabledLabel="Disabled when Campaigns are selected."
                                options={this.props.industries}
                                getOptionText={(option) => option.industry_name}
                                getOptionValue={(option) => option.industry_id}
                                disabled={this.state.campaign_ids.length > 0}
                                selections={this.state.industry_ids || []}
                                onChange={(values) => this.handleMultiSelect('industry_ids', values)}
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            <MultiSelectList
                                multiselect={true}
                                label="Campaigns"
                                name="campaign_ids"
                                selectAllByDefault={true}
                                emptyLabel="All Campaigns"
                                disabledLabel={this.props.campaigns.length == 0
                                    ? "No active Campaigns"
                                    : "Disabled when Service Categories are selected."}
                                options={this.props.campaigns}
                                disabled={this.props.campaigns.length == 0 || this.state.industry_ids.length > 0}
                                getOptionText={(option) => option.campaign_name}
                                getOptionValue={(option) => option.campaign_id}
                                selections={this.state.campaign_ids || []}
                                onChange={(values) => this.handleMultiSelect('campaign_ids', values)}
                            />
                        </PopupFormCell>
                    </PopupFormRow>
                    <PopupFormRow
                        fullWidthOnMobile={true}
                    >
                        <PopupFormCell>
                            <MultiSelectList
                                label="Date Range"
                                name="date-range"
                                selections={[`${this.state.date_range_id}` || ""]}
                                options={dateRangeOptions}
                                onChange={(vals) => this.updateDateValue(vals[0])}
                                require={false}
                                inputRef={this.dateRangeSelectRef}
                                extraClass={(this.isDateRangeAllTime()) ? '' : 'ui-highlight'}
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            <label htmlFor="popup-filter-from-date"
                                   className="type-normal-body type-heavy type-narrow-line-height no-margin-top spacing-5-bottom">
                                From Date
                            </label>
                            <input
                                type="date"
                                className={`ui-text-field ui-normal-text-input ${(this.state.date_from.length > 0) ? 'ui-highlight' : ''}`}
                                id="popup-filter-from-date"
                                name="from-date"
                                placeholder="mm/dd/yyyy"
                                value={this.state.date_from}
                                onChange={(event) =>
                                    this.updateValue("date_from", event.target.value)}
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            <label htmlFor="popup-filter-to-date"
                                   className="type-normal-body type-heavy type-narrow-line-height no-margin-top spacing-5-bottom">
                                To Date
                            </label>
                            <input
                                type="date"
                                className={`ui-text-field ui-normal-text-input ${(this.state.date_to.length > 0) ? 'ui-highlight' : ''}`}
                                id="popup-filter-to-date"
                                name="to-date"
                                placeholder="mm/dd/yyyy"
                                value={this.state.date_to}
                                onChange={(event) =>
                                    this.updateValue("date_to", event.target.value)}
        
                            />
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>
                
                <PopupFormDivider />
                
                <PopupFormGroup>
                    <PopupFormRow
                        noBottomMargin={true}
                    >
                        <PopupFormCell>
                            <label htmlFor="popup-filter-keyword"
                                   className="type-normal-body type-heavy type-narrow-line-height no-margin-top spacing-5-bottom">
                                Search by Keyword&nbsp;<span className="type-light type-force-newline-mobile">within Applied Filters</span>
                            </label>
                        </PopupFormCell>
                    </PopupFormRow>
                    <PopupFormRow
                        fullWidthOnMobile={true}
                        unevenColumns2and1={true}
                    >
                        <PopupFormCell>
                            <SearchField
                                placeholder={"Search by Keyword, Phone Number, Name, etc."}
                                value={this.state.keyword}
                                searchFieldRef={this.searchFieldRef}
                                popup={true}
                                onFormSubmit={() => this.props.applyFilters({...this.state})}
                                onChange={(event) => this.updateValue("keyword", event.target.value)}
                                allowHighlight={true}
                            />
                        </PopupFormCell>
                        <PopupFormCell>
                            <p className="type-small-body type-heavy type-align-right type-centered-mobile">
                                <span
                                    role="button"
                                    onClick={() => {
                                        this.resetFilters();
                                        this.updateValue("keyword", "");
                                    }}
                                >
                                    Reset Filters and Search to Default
                                </span>
                            </p>
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>
                
                {/* Mobile View Button Group */}
                <PopupFormGroup
                    extraClass="ui-hide-full ui-hide-tablet"
                >
                    <PopupFormRow>
                        <PopupFormCell>
                            <button className="button ui-normal-button ui-full-width-button"
                                    onClick={() => this.props.applyFilters({...this.state})}>
                                Apply Filters
                            </button>
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>
                
                {/* Full View Button Group */}
                <PopupFormGroup
                    grayBackground={true}
                    noBottomMargin={true}
                    extraClass="ui-hide-mobile"
                >
                    <PopupFormRow
                        noBottomMargin={true}
                    >
                        <PopupFormCell
                            slamRight={true}
                        >
                            <button className="button ui-normal-button"
                                    onClick={() => this.props.applyFilters({...this.state})}>
                                Apply Filters
                            </button>
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>
            </PopupForm>
        </>;
    }
}
