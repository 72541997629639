import styled from 'styled-components'

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
`
export const Toolbar = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-gap: 10px;
    align-items: end;
    padding: 20px 20px 0 20px;
`

export const InputWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

export const BottomToolbar = styled.div`
    align-self: center;
    margin-bottom: 20px;
`
