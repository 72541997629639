import React, { Fragment } from 'react'

export default ({ label, tooltipDefinitions = [] }) => {
    const tooltipContent = tooltipDefinitions.map((o, _i) => {
        const { label, definition } = o
        return (
            <Fragment key={`tooltip${_i}`}>
                <span className="type-black type-heavy">{label}</span>
                <br />
                <ul>
                    <li>{definition}</li>
                </ul>
                <br />
            </Fragment>
        )
    })
    return (
        <div
            className="type-small-body"
            style={{ minWidth: '400px' }}
        >
            <span className="type-normal-subhead type-heavy">{label}</span>
            <br />
            <br />
            {tooltipContent}
        </div>
    )
}
