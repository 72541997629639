import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const InputsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

export const InputWrapper = styled.div`
    margin-top: 7px;
    display: flex;
    align-items: center;
`

export const InputSpan = styled.span`
    border: none;
    padding: 3px;
    /* width: 100%; */
    /* text-align: ${props => props.align || 'left'}; */
`

export const DropdownWrapper = styled.div`
    padding: 10px 20px;
`