import React from 'react';
import PropTypes from 'prop-types';

/**
 * returns a valid amount (e.g. "80.55.4f" => "80.55")
 * @param {number|string} amount - the value to parse
 * @param {boolean} [returnNumeric=false] - if set to true, will return a number unless empty, otherwise returns a string
 * @return {number|string}
 */
export const parseMonetaryAmount = (amount, returnNumeric = false) => {
    amount = amount.toString().replace(/[^\d.-]+/g, '');

    if (returnNumeric) {
        amount = parseFloat(amount);
    }

    return Number.isNaN(amount)
        ? ''
        : amount;
};

export const MoneyInput = props => {

    let inputClass = "money-input ui-text-field ui-normal-text-input",
        inputJsx;

    if (props.hasError) {
        inputClass += " ui-text-field ui-alert";
    }

    /**
     * Added uncontrolled as a prop to allow callers more control over whether
     * they want the DOM or React to have control over an input's value.  The
     * expanded lead view prefers the former, AA prefers the latter.
     *
     * The former also uses an input's defaultValue property, which is more
     * intuitively mapped from props.defaultValue.  Implementers may find that
     * more intuitive than the latter's mapping of props.defaultValue to the
     * input's value property.  That's the only difference between the two input
     * versions.
     */
    if (props.uncontrolled) {
        inputJsx = <input
            type={props.type || "number"}
            step={props.step || 0.01}
            min={props.min}
            max={props.max}
            id={props.id}
            name={props.name}
            defaultValue={props.defaultValue || ""}
            onChange={props.onChange}
            required={props.required}
            className={inputClass}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            ref={props.inputRef || React.createRef()}
            onFocus={props.onFocus}
        />;
    }
    else {
        inputJsx = <input
            type={props.type || "number"}
            step={props.step || 0.01}
            min={props.min}
            max={props.max}
            id={props.id}
            name={props.name}
            value={props.defaultValue || ""}
            onChange={props.onChange}
            required={props.required}
            className={inputClass}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            ref={props.inputRef || React.createRef()}
            onFocus={props.onFocus}
            disabled={props.disabled}
        />;
    }

    return <>
        <span className={`money-input-field-container inline${props.disabled ? ' disabled' : ''}`}>
            {inputJsx}
        </span>
        {props.errorMessage !== undefined &&
        <span className="input-error">{props.errorMessage}</span>}
    </>;
};

MoneyInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,

    //
    // These props have historically been required, but lately MoneyInput has been
    // used successfully without them, e.g. on Campaigns Manager and in the Step 4 CostPerLeadForm.
    // ~ 2019-11-15 RFM
    //

    onBlur: PropTypes.func,
    required: PropTypes.bool,
    inputRef: PropTypes.object,
    hasError: PropTypes.bool
};