import React, { useCallback, useState, useEffect, Fragment, useContext } from 'react'
import LoadingGif from '../../../common/components/LoadingGif'
import MajorAlerts from '../../../common/MajorAlerts'
import Modal from '../../../common/Modal'
import {
    connectCampaigns,
    getAffiliates,
    saveAffiliateUpdates,
    saveNewAffiliate,
    saveServiceCategoryRules,
} from '../../../service/AffiliateService'
import DataTable from './DataTable'
import EditAffiliateModal from './Modals/EditAffiliateModal'
import { useHistory } from 'react-router-dom'
import { getParamsFromSearch } from '../../mp_campaign_optimizer/utils'
import { updateQueryString } from './utils'
import ConfirmationModal from './Modals/ConfirmationModal'
import EditsInProgressModal from './Modals/EditsInProgressModal'
import { TableWrapper } from './styles'
import ButtonContext from '../ButtonContext'
import AddNewRules from './Modals/AddNewRules'
import FinalConfirmation from './Modals/FinalConfirmation'
import usePreventUnload from '../../../common/hooks/usePreventUnload'

export default (props) => {
    const [affiliates, setAffiliates] = useState()
    const [columns, setColumns] = useState()
    const [defaultNewAdNetwork, setDefaultNewAdNetwork] = useState()
    const [modalContent, setModalContent] = useState()
    const [affiliateToEdit, setAffiliateToEdit] = useState()
    const [loading, setLoading] = useState(false)
    // get the URL parameters and initialize state with the data
    const history = useHistory()
    const { search = '' } = history.location
    const urlParams = getParamsFromSearch(search)
    const { sort_column, sort_order } = urlParams
    const [sortColumn, setSortColumn] = useState(sort_column)
    const [sortOrder, setSortOrder] = useState(sort_order)
    const [hideCloseButton, setHideCloseButton] = useState(false)

    // effect to fetch lead sources on initla load
    useEffect(() => {
        fetchAffiliates(search.replace('?', ''))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // effect to update button context after the default ad network
    // has been set, because the default ad network state is required
    // for the new ad network action
    const setButtonContext = useContext(ButtonContext)
    useEffect(() => {
        if (defaultNewAdNetwork != null) {
            setButtonContext('Set Up New Lead Source', handleNewAdNetwork)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultNewAdNetwork])

    // hook to prevent the user from accidentally
    // exiting the page while the modal is active
    usePreventUnload(modalContent != null)

    // handler for the add new ad network action
    const handleNewAdNetwork = () => {
        setAffiliateToEdit(defaultNewAdNetwork)
        setModalContent(
            <EditAffiliateModal
                affiliate={defaultNewAdNetwork}
                onClose={handleCloseModal}
                onPreview={handlePreviewChanges}
                isNewAffiliate={true}
            />
        )
    }

    const fetchAffiliates = useCallback((query) => {
        setLoading(true)
        return getAffiliates(query)
            .then((data) => {
                const { affiliates, columns, sort, default_ad_network = {} } = data
                setAffiliates(affiliates)
                setColumns(columns)
                setLoading(false)
                setDefaultNewAdNetwork(default_ad_network)
                if (sort && sort.column) {
                    setSortColumn(sort.column)
                }
                if (sort && sort.order) {
                    setSortOrder(sort.order)
                }
            })
            .catch((err) => {
                console.log('Unable to fetch affiliates', err)
                setLoading(false)
            })
    }, [])

    const handleOrderBy = useCallback(
        (property) => {
            let order = sortOrder
            // if clicking the same column
            if (sortColumn === property) {
                if (sortOrder === 'asc') {
                    order = 'desc'
                } else {
                    order = 'asc'
                }
            } else {
                // if the column is new or different, set to asc
                order = 'asc'
            }
            setSortColumn(property)
            setSortOrder(order)

            const currentQuerystring = history.location.search
            const querystring = updateQueryString(currentQuerystring, {
                sortColumn: property,
                sortOrder: order,
            })

            // update history
            history.push({
                search: `?${querystring}`,
            })

            // fetch new data
            fetchAffiliates(querystring)
        },
        [fetchAffiliates, history, sortColumn, sortOrder]
    )

    const handleUpdateAffiliate = useCallback(
        (affiliate, updates) => {
            saveAffiliateUpdates(affiliate.ad_network_id, updates)
                .then(() => {
                    setModalContent(null)
                    props.updateMessageBlocks(
                        [`${affiliate.ad_network_name} has been updated!`],
                        'success'
                    )
                    // refetch affilate data
                    fetchAffiliates()
                })
                .catch((err) => {
                    console.log('Error saving affiliate updates - ', { updates })
                    console.error(err)
                })
        },
        [fetchAffiliates, props]
    )

    const handlePreviewChanges = useCallback(
        (affiliate, updates, isNewAffiliate) => {
            // check the value type against the column definition
            setModalContent(
                <ConfirmationModal
                    affiliate={affiliate}
                    updates={updates}
                    columns={columns}
                    adNetworks={affiliates}
                    onSave={
                        isNewAffiliate ? handleSaveNewAffiliate : handleUpdateAffiliate
                    }
                    isNewAffiliate={isNewAffiliate}
                    onGoBack={handleGoBack}
                />
            )
        },
        // eslint-disable-next-line no-use-before-define
        [affiliates, columns, handleGoBack, handleSaveNewAffiliate, handleUpdateAffiliate]
    )

    const handleConfirmRules = useCallback(
        (args) => {
            setModalContent(<EditsInProgressModal />)
            // save rules
            const { newRules = [], deletedRules = [] } = args?.rules
            saveServiceCategoryRules([...newRules, ...deletedRules])
                .then((res) => {
                    return connectCampaigns(args.affiliate)
                })
                .then((res) => {
                    setModalContent(null)
                    props.updateMessageBlocks(
                        [
                            `${
                                args?.affiliate?.ad_network_name ||
                                'Lead Source'
                            } has been added!`,
                        ],
                        'success'
                    )
                })
                .catch((err) => {
                    console.log({ err })
                })
                .finally(() => {
                    // refetch affilate data
                    fetchAffiliates()
                })
        },
        [fetchAffiliates, props]
    )

    const handlePreviewRules = useCallback(
        ({ rules, ...rest }) => {
            setModalContent(
                <FinalConfirmation
                    {...rest}
                    rules={rules}
                    columns={columns}
                    onConfirm={handleConfirmRules}
                    onGoBack={() => {
                        setModalContent(
                            <AddNewRules
                                onSkip={handlePreviewRules}
                                onPreview={handlePreviewRules}
                                changes={rules}
                                {...rest}
                            />
                        )
                    }}
                />
            )
        },
        [columns, handleConfirmRules]
    )

    const handleSaveNewAffiliate = useCallback(
        (affiliate, updates, isNewAffiliate) => {
            // create the correct api data structure
            const _affiliate = updates.reduce((acc, u) => {
                acc[u.property] = u.value
                return acc
            }, {})
            // add the reference ad network
            setHideCloseButton(true)
            setModalContent(<EditsInProgressModal />)

            // --------------------------
            // temporary for testing
            // --------------------------
            // const name =
            //     updates.filter((u) => u.property === 'ad_network_name')[0]?.value ||
            //     'test'
            // Promise.resolve(true)
            //     .then(() => {
            //         setModalContent(
            //             <AddNewRules
            //                 onSkip={handlePreviewRules}
            //                 onPreview={handlePreviewRules}
            //                 changes={{}}
            //                 affiliate={{ ad_network_id: '111', ad_network_name: name }}
            //                 affiliateRules={[]}
            //                 updates={updates}
            //             />
            //         )
            //     })
            //     .catch((err) => {
            //         console.log(err)
            //     })
            // --------------------------
            // end temporary
            // --------------------------

            saveNewAffiliate(_affiliate)
                .then((response) => {
                    setModalContent(
                        <AddNewRules
                            onSkip={handlePreviewRules}
                            onPreview={handlePreviewRules}
                            changes={{}}
                            affiliate={{ ..._affiliate, ...response?.data }}
                            affiliateRules={[]}
                            updates={updates}
                        />
                    )
                })
                .catch((err, res) => {
                    console.log('Error saving new affiliate - ', { _affiliate })
                    console.log({ err })
                    let message = 'The API responded with the following errors: \n'
                    if (err?.response?.data && err.response.data.length) {
                        message += err.response.data.join('\n')
                    } else {
                        message += err.response.data?.message
                    }
                    window.alert(message)
                    props.updateMessageBlocks([`${message}`], 'error')
                    setModalContent(
                        <EditAffiliateModal
                            affiliate={affiliate}
                            updates={updates}
                            onClose={handleCloseModal}
                            onPreview={handlePreviewChanges}
                            isNewAffiliate={isNewAffiliate}
                        />
                    )
                })
        },
        [handlePreviewChanges, handlePreviewRules, props]
    )

    const editAffiliate = useCallback(
        (id) => {
            const _a = affiliates.filter((a) => a.ad_network_id == id)
            setAffiliateToEdit(_a[0])
            setModalContent(
                <EditAffiliateModal
                    affiliate={_a[0]}
                    onClose={handleCloseModal}
                    onPreview={handlePreviewChanges}
                />
            )
        },
        [affiliates, handlePreviewChanges]
    )

    const handleCloseModal = () => {
        setModalContent(null)
    }

    const handleGoBack = useCallback(
        (affiliate, updates, isNewAffiliate) => {
            setModalContent(
                <EditAffiliateModal
                    affiliate={affiliate}
                    updates={updates}
                    onClose={handleCloseModal}
                    onPreview={handlePreviewChanges}
                    isNewAffiliate={isNewAffiliate}
                />
            )
        },
        [handlePreviewChanges]
    )

    let dataDisplay
    if (loading) {
        dataDisplay = <LoadingGif />
    } else if (affiliates == null || columns == null) {
        dataDisplay = (
            <div>
                <span>Something went wrong =/ Thats all I know</span>
            </div>
        )
    } else {
        dataDisplay = (
            <DataTable
                rows={affiliates}
                columns={columns}
                onEditHandler={editAffiliate}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onOrderBy={handleOrderBy}
            />
        )
    }

    const modalHeader = affiliateToEdit?.ad_network_name
        ? `Edit ${affiliateToEdit?.ad_network_name}`
        : 'Set Up New Lead Source'

    return (
        <Fragment>
            <MajorAlerts />
            <Modal
                content={modalContent}
                header={modalHeader}
                onCloseButton={handleCloseModal}
                updateModalContent={() => setModalContent(null)}
                flat={true}
                width="wide"
                hideCloseButton={hideCloseButton}
            />
            <TableWrapper border={false}>{dataDisplay}</TableWrapper>
        </Fragment>
    )
}
