import React, {Component} from 'react';

import {appInput} from '../../../formHelpers';
import Input from "../../../common/components/form_elements/Input";

export default class CampaignCompletionDateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //
            // we utilize the properties of the native input[type="date"] field
            // expecting and returning the format "YYYY-MM-DD", which is what we use in our API
            //
            expected_campaign_completion_date: appInput(
                "expected_campaign_completion_date",
                "expected_campaign_completion_date",
                props.expectedCampaignCompletionDate || ''
            )
        };
    }

    submitDateSelection = (event) => {
        event.preventDefault();
        this.props.submitExpectedCampaignCompletionDate(
            this.state.expected_campaign_completion_date.value
        );
    };

    submitDateRemoval = (event) => {
        event.preventDefault();
        this.props.removeExpectedCampaignCompletionDate();
    };

    render() {
        return <form>
            <div className="popup__form__row spacing-30-bottom content-centered">
                <div className="popup__form__cell popup__form__cell__40 popup__form__cell__50__mobile">
                    <Input
                        type="date"
                        label="Set Expected Completion Date"
                        id="expected_campaign_completion_date"
                        name="expected_campaign_completion_date"
                        className="ui-textarea ui-normal-text-input"
                        value={this.state.expected_campaign_completion_date.value}
                        onChange={(event) => {
                            let {expected_campaign_completion_date} = this.state;
                            expected_campaign_completion_date.value = event.target.value;
                            this.setState({expected_campaign_completion_date});
                        }}
                        placeholder="MM/DD/YYYY"/>
                </div>
            </div>
            <div className="ui-hide-tablet ui-hide-full">
                <div className="popup__form__row spacing-40-top-mobile">
                    <div className="popup__form__cell popup__form__cell__100__mobile">
                        <button type="button"
                                className="button button__outline  ui-normal-button ui-full-width-button"
                                onClick={this.submitDateRemoval}>
                            Remove Date
                        </button>
                    </div>
                    <div className="popup__form__cell popup__form__cell__100__mobile">
                        <button type="submit"
                                className="button ui-normal-button ui-full-width-button"
                                onClick={this.submitDateSelection}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <div className="ui-hide-mobile">
                <div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
                    <div className="popup__form__row popup__form__row__slam-right">
                        <div className="popup__form__cell">
                            <button className="button button__outline ui-normal-button"
                                    type="button"
                                    onClick={this.submitDateRemoval}>
                                Remove Date
                            </button>
                        </div>
                        <div className="popup__form__cell">
                            <button type="submit"
                                    className="button ui-normal-button"
                                    onClick={this.submitDateSelection}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>;
    }
}
