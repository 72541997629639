import React from 'react';
import PropTypes from 'prop-types';

import {formatCurrency} from '../../Util';

const SuccessMessageContent = (props) => {
    if (props.enroll) {
        return <div id="success-message-modal">
            <div>{props.name} has started Select Program Enrollment.</div>
            <div>"Please complete your Select Enrollment" email sent to {props.email}.</div>
            <div>Program Enrollment {formatCurrency(props.amountDue)} was paid successfully.</div>
        </div>
    }

    return <div id="success-message-modal">
            <div>{props.name} was created.</div>
        {props.isMarketplaceOnly
            ? <div>"Welcome to Service Direct Marketplace" email sent to {props.email}.</div>
            : <div>"Please complete your Account Setup" email sent to {props.email}.</div>}
        {props.amountDue > 0 &&
        <div>
            Account Activation{' '}
            {formatCurrency(props.amountDue)}
            {props.isPaid
                ? ' was paid successfully'
                : ' due upon receipt'}.
        </div>}
    </div>;
};

export default SuccessMessageContent;

SuccessMessageContent.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    amountDue: PropTypes.number,
    isMarketplaceOnly: PropTypes.bool,
    enroll: PropTypes.bool
};
