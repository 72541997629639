import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import type {IModal} from "../Interfaces/IModal";

export const ModalContext = React.createContext();

/**
 * returns a function that, when called, returns a function that accepts {IModal} and opens a modal
 * @return {(IModal) => void}
 */
export const useModal = (): ((data: IModal) => void) => useContext(ModalContext);

export const ModalProvider = ({children}) => {
    const [modalContent: IModal, setModalProperties] = useState({
        content: '',
        header: '',
        footer: '',
        scrollerRef: null,
        containerRef: null,
        width: '', // empty string or 'wide'
        size: '', // empty string, 'small', or 'extra-small'
        headerLink: {}, // {url?: string, description?: string}
        flatBottom: false,
        flat: false,
        disableScrollLock: false,
        hideCloseButton: false,
        onCloseButton: () => null
    });

    const updateProperties = (properties = {}, overwrite = true) => {
        setModalProperties((previousProperties: IModal) => overwrite
            ? properties
            : {...previousProperties, ...properties});
    };

    return <ModalContext.Provider value={updateProperties}>
        <Modal content={modalContent.content}
               header={modalContent.header}
               footer={modalContent.footer}
               scrollerRef={modalContent.scrollerRef}
               containerRef={modalContent.containerRef}
               width={modalContent.width}
               size={modalContent.size}
               headerLink={modalContent.headerLink}
               flatBottom={modalContent.flatBottom}
               flat={modalContent.flat}
               hideCloseButton={modalContent.hideCloseButton}
               disableScrollLock={modalContent.disableScrollLock}
               onCloseButton={modalContent.onCloseButton}/>
        {children}
    </ModalContext.Provider>
};

export const Modal = (props: IModal) => {
    const modalParentRef = useRef();
    const containerRef = props.containerRef || React.createRef();
    const scrollerRef = props.scrollerRef || React.createRef();
    const updateModalContent = useModal();

    const handleCloseButton = useCallback(() => {
        updateModalContent();

        // if there's an onCloseButton function, execute it
        if (typeof props.onCloseButton === 'function') {
            props.onCloseButton();
        }

        clearAllBodyScrollLocks();
    }, [props, updateModalContent]);

    // wrap the "on mount" and DOM functionality in a useEffect hook as needed by React
    useEffect(() => {
        const scrollerRefCurrent = scrollerRef.current;

        if (props.content) {
            disableBodyScroll(scrollerRefCurrent);
        }

        /**
         * closes the modal when the Esc button is clicked ont he keyboard
         * @param {KeyboardEvent} event
         */
        const handleEscapeClick = (event) => {
            if (event.key === 'Escape') {
                handleCloseButton();
            }
        };

        const handleScrolling = () => {
            const modalParent = modalParentRef.current;
            const container = containerRef.current;

            //
            // Encountered an error submitting Step 2 where container
            // didn't exist and thus couldn't have a classList etc.
            // Adding this early return to address.
            // https://app.asana.com/0/928250771089872/1121495489097577
            // ~ RFM 2019-05-06
            //
            if (!container || !modalParent) {
                return;
            }

            modalParent.classList.remove('popup__scrolling');
            container.classList.remove('popup__scrolling');

            if (container.offsetHeight > .8 * window.innerHeight) {
                modalParent.classList.add('popup__scrolling');
                container.classList.add('popup__scrolling');
            }
        };

        let scrollerElement = scrollerRef.current;

        if (scrollerElement) {
            new MutationObserver(() => handleScrolling())
                .observe(scrollerElement, {childList: true, subtree: true});
        }

        window.addEventListener('resize', () => handleScrolling());

        document.body.addEventListener('keyup', handleEscapeClick);

        handleScrolling();

        return () => {
            document.body.removeEventListener('keyup', handleEscapeClick);
            enableBodyScroll(scrollerRefCurrent);
        }
    }, [handleCloseButton, containerRef, scrollerRef, props.content]);

    let content = props.content || null;
    let modalClass = content
        ? "popup__shade show"
        : "popup__shade";

    let classNameAdds = [props.size || ''];
    if (props.width === 'wide') {
        classNameAdds.push('popup__wide');
    }
    classNameAdds = classNameAdds.join(' ');
    if (classNameAdds.length > 0) {
        classNameAdds = ' ' + classNameAdds;
    }

    // If content is a function, run it before injecting into the JSX
    if (typeof content == 'function') {
        content = content();
    }

    return (
        <div className={modalClass}>
            <div className="popup__container__wrap">
                <div className={'modal__parent' + classNameAdds}
                     ref={modalParentRef}>
                    <div className={'popup__container modal' + classNameAdds}
                         ref={containerRef}>
                        {props.header &&
                            <div className="popup__header">
                                <p className="type-normal-subhead type-heavy type-single-line no-margin">
                                    {props.header}
                                </p>
                                {props.headerLink &&
                                    <div className="popup__header-link spacing-10-top-mobile">
                                        <a target="_blank" rel="noopener" href={props.headerLink.url}>
                                            {props.headerLink.description}
                                        </a>
                                    </div>}

                                {!props.hideCloseButton &&
                                    <div className="popup__close-button">
                                        <img src="/images/icon-popup-close.svg" role="button"
                                             className="pointer"
                                             alt="Close"
                                             onClick={handleCloseButton}/>
                                    </div>}
                            </div>}
                        <div className="popup__scrollbox__clipper">
                            <div className="popup__scrollbox__scroller" ref={scrollerRef}>
                                <div className={"popup__contentbox " +
                                    (props.flatBottom ? "popup__contentbox__flat-bottom " : "") +
                                    (props.flat ? "popup__contentbox__flat" : "")}
                                >
                                    {content}
                                </div>
                            </div>
                        </div>
                        {props.footer &&
                            <div className="popup__footer">
                                {props.footer}
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Modal;

Modal.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hideCloseButton: PropTypes.bool,
    onCloseButton: PropTypes.func,
    // commented out because we also need to allow undefined/null/'' for closing the modal:
    //content: PropTypes.oneOf([PropTypes.object, PropTypes.func]),
};
