import React, {createRef, useState} from 'react';

import Tooltip from "../../../../common/Tooltip";
import {MoneyInput} from "../../../../common/components/form_elements/MoneyInput";
import {useModal} from "../../../../common/ModalContext";
import AuthService from "../../../../service/AuthService";
import ToggleSwitch from "../../../../common/components/form_elements/ToggleSwitch";
import CampaignService from "../../../../service/CampaignService";
import type {ISubcategory} from "../../../../Interfaces/ISubcategory";
import {toCamelObject, toSnakeObject} from "../../../../Util";

const MarketplaceFormCostPerLeadModal = (props) => {
    const {campaign} = props;
    const updateModalContent = useModal();
    const campaignService = new CampaignService();
    const modalRef = createRef();

    // if these constants are changed, update the function "getMpFormsIndustryCplRange" in "PutSubcategories.php"
    let maxMpCpl = campaign.industry.max_suggested_tender_mp * 0.25;
    if (maxMpCpl < 1) {
        maxMpCpl = 1;
    }

    let minMpCpl = campaign.industry.min_suggested_tender_mp * 0.2;
    if (minMpCpl < 1) {
        minMpCpl = 1;
    }

    // calculate the default CPL; used when first enabling forms
    let defaultCpl = (campaign.tender[0]?.price_per_lead || 0) * 0.3;
    if (defaultCpl < minMpCpl) {
        defaultCpl = minMpCpl;
    }
    else if (defaultCpl > maxMpCpl) {
        defaultCpl = maxMpCpl;
    }

    // clone the subcategories and set default CPL so we don't overwrite the parent component property
    const clonedSubcategories = JSON.parse(JSON.stringify(toCamelObject(campaign.forms_subcategories)));
    clonedSubcategories.forEach((subcategory: ISubcategory) => subcategory.cpl = subcategory.cpl || defaultCpl);

    // we create a deep copy so that we do not change the source until we successfully save the changes
    const [subcategories: ISubcategory[], updateSubcategories] = useState(clonedSubcategories);
    const [submitting, setSubmitting] = useState(false);

    const updateSubcategory = (subcategoryIndex, fieldName, value) => {
        const updatedSubcategories = [...subcategories];
        updatedSubcategories[subcategoryIndex][fieldName] = value;
        updateSubcategories(updatedSubcategories);
    };

    const submitSubcategoriesChange = () => {
        setSubmitting(true);

        // clean the subcategories before submitting so we only send the needed data
        const cleanSubcategories = [];
        subcategories.forEach((subcategory: ISubcategory) => {
            cleanSubcategories.push({
                cpl: subcategory.cpl,
                subcategoryId: subcategory.subcategoryId,
                enabled: subcategory.enabled
            });
        });

        campaignService.updateCampaignSubcategories(campaign.campaign_id, cleanSubcategories)
            .then((response) => {
                props.updateMessageBlocks('Successfully updated your subcategory preferences', 'success');
                updateModalContent();

                // convert subcategories into snake_case because we are not yet converting the campaign rows into camelCase
                props.handleSubcategoriesChange(props.campaignIndex, toSnakeObject(subcategories), response.data.data.first_enabled_mp_forms);
            })
            .catch(() => setSubmitting(false));
    };

    return <div ref={modalRef}>
        <div className="type-normal-body no-margin-top spacing-30-bottom">
            <h3 className="spacing-18-bottom type-large-body">
                {props.editing ? 'Edit' : 'Enable'} Form Leads for Campaign{' '}
                <span className="type-heavy">{campaign.campaign_name}</span>
            </h3>

            {!campaign.first_enabled_mp_forms &&
            <span className="type-heavy">
                We're happy to announce that Exclusive Form Leads are now available for this Service Category.{' '}
            </span>}
            You control which Service Subcategories you'd like to receive Form Leads for as well as the
            Cost Per Lead (CPL) to compete with.{' '}
            <Tooltip
                anchor="Learn More"
                position="bottom"
                content={<>
                    <div className="type-heavy">
                        Form Lead Service Subcategories & Cost Per Lead
                    </div>
                    <div className="spacing-18">
                        You can Enable and Disable individual Service Subcategories, giving you greater
                        flexibility with the type of Form Leads that you want.
                    </div>
                    <div>
                        You can also specify an individual Cost Per Lead (CPL) for each Service Subcategory.
                        This allows you to set different CPLs for different project types,
                        based on their value to your company. To improve your chance of winning more Leads,
                        increase your CPL to be more competitive. Learn more about CPL and Lead Volume.{' '}
                        <a target="_blank" className="type-heavy"
                           href={AuthService.isMarketplace
                               ? "https://support.servicedirect.com/lead-volume"
                               : "https://support.servicedirect.com/select/lead-volume"}>
                            CPL and Lead Volume.</a>
                    </div>
                </>}
                modalContainerRef={props.containerRef}
            />
        </div>
        <p className="type-normal-body type-heavy spacing-30">
            Recommended Cost Per Lead
            ${minMpCpl} - {maxMpCpl}
        </p>
        <div className="campaigns-table__form-leads">
            <div className="campaigns-table__form-leads-header">
                <div className="subcategory-header-name">Service Subcategories</div>
                <div className="subcategory-header-cpl">Cost Per Lead</div>
                <div className="subcategory-header-status">Status</div>
            </div>
            {subcategories.map((subcategory: ISubcategory, index) =>
                <div key={index}>
                    <div className="subcategory-name">
                        {subcategory.subcategoryName}
                        {subcategory.isGeneral == 1 &&
                        <Tooltip
                            content={<span className="type-small-body type-narrow-line-height">
                                Sometimes customers are unsure about their request and do not know which Subcategory
                                to pick when filling in a form. In these cases, rather than passing up on these
                                opportunities, you can opt into the "General" Subcategory and set a specific Cost
                                Per Lead (CPL) you are willing to pay. This allows you to receive additional Form
                                Leads at a CPL you are comfortable with.
                            </span>}
                            modalContainerRef={modalRef}
                        />}
                    </div>
                    <div>
                        <MoneyInput name={`sub-${subcategory.subcategoryId}-cpl`}
                                    step={1}
                                    min={minMpCpl}
                                    max={maxMpCpl}
                                    defaultValue={subcategory.cpl}
                                    onChange={(event) => updateSubcategory(index, 'cpl', event.target.value)}
                                    disabled={subcategory.enabled != 1}/>
                    </div>
                    <div>
                        <ToggleSwitch name={`sub-${subcategory.subcategoryId}-status`}
                                      checked={subcategory.enabled == 1}
                                      onChange={(checked) => updateSubcategory(index, 'enabled', checked)}/>
                    </div>
                </div>)}
        </div>

        <div className="popup__form__gray-bar padding-24 no-padding-mobile spacing-30-top no-margin-bottom">
            <div className="popup__form__row popup__form__row__slam-right popup__form__row-column-mobile">
                <div className="popup__form__cell ui-hide-mobile-important">
                    <button onClick={() => updateModalContent()}
                            className="button-clean type-small-body type-heavy type-blue ui-full-width-button-mobile">
                        Cancel
                    </button>
                </div>
                <div className="popup__form__cell popup__form__cell__100__mobile">
                    <button className="button ui-normal-button ui-full-width-button-mobile"
                            onClick={submitSubcategoriesChange}
                            disabled={submitting}>
                        Save Form Lead Settings
                    </button>
                </div>
            </div>
        </div>
    </div>;
};

export default MarketplaceFormCostPerLeadModal;
