import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatCurrency} from "../../Util";

export default class ClientAccountsRow extends Component {
    render() {
        let client = this.props.client;

        let campaignIndustries = client.campaign_industries.map((industry, index) =>
            <span key={index} className="industry-campaign-count">
                {industry.name}:{' '}
                <a href={`/campaigns?contractor_ids[]=${client.contractor_id}&industry_ids[]=${industry.industry_id}`}
                   className="type-heavy">
                    {industry.campaignCount}
                </a>
            </span>
        );

        return <tr className="sortable-table__row">
            <td className="client-accounts__name">
                <span className="type-force-newline type-narrow-line-height type-normal-body">
                    <a href={`/campaigns?contractor_ids[]=${client.contractor_id}`} className="type-heavy">
                        {client.name}
                    </a>
                </span>
            </td>
            <td className="client-accounts__campaign">
                <div className="type-narrow-line-height type-normal-body">
                    Total Campaigns:{' '}
                    <a href={`/campaigns?contractor_ids[]=${client.contractor_id}`} className="type-heavy">
                        {client.campaignCount}
                    </a>
                    <br/>
                    {campaignIndustries}
                    <br/>
                    Enabled:{' '}
                    <a href={`/campaigns?contractor_ids[]=${client.contractor_id}&statuses[]=enabled`}
                       className="type-heavy">
                        {client.enabledCampaigns}
                    </a>{' '}
                    Paused:{' '}
                    <a href={`/campaigns?contractor_ids[]=${client.contractor_id}&statuses[]=paused`}
                       className="type-heavy">
                        {client.pausedCampaigns}
                    </a>
                </div>
            </td>
            <td className="client-accounts__leads-count">
                <span className="type-force-newline type-narrow-line-height type-normal-body">
                    <a href={`/leads?contractor_ids[]=${client.contractor_id}`} className="type-heavy">
                        {client.leads_count_last_30}
                    </a>{' '}
                    {client.leads_count_last_30 == 1 ? 'Lead' : 'Leads'}
                </span>
            </td>
            <td className="client-accounts__leads-cost">
                <span className="type-force-newline type-narrow-line-height type-normal-body">
                    <a href={`/leads?contractor_ids[]=${client.contractor_id}&is_valid=1&date_range_id=8`}
                       className="type-heavy">
                        {formatCurrency(client.lead_cost_last_30_days)}
                    </a>
                </span>
            </td>
            {/*<td>
                <span className="type-force-newline type-narrow-line-height type-normal-body">
                    <a href={`/leads?contractor_ids[]=${client.contractor_id}&is_valid=1&date_range_id=8`}
                       className="type-heavy">
                        {formatCurrency(client.reported_revenue_last_30_days) || '--'}
                    </a>{' '}
                    <span className="type-small-body type-grey-1 minor-side-rails">
                        {client.confidence_last_30_days}% Confidence
                    </span>
                </span>
            </td>*/}
        </tr>;
    }
}

ClientAccountsRow.propTypes = {
    client: PropTypes.object.isRequired
};
