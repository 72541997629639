import React from 'react'
import SkeletonText from '../../../../common/components/Skeleton/SkeletonText'
// import Button, { IconButton } from '../../../common/components/form_elements/Button'
import Button from '../../../../common/components/form_elements/Button'
import { formatCurrency, formatNumber, toTitleCase } from '../../../../Util'
import { Link } from '../../../qa_dashboard/Link'
// import { PencilSvg } from '../../../common/Svgs'

const defaultOptions = {
    summaryLoading: false,
}
export default (col, options = defaultOptions, onClick) => {
    const { summaryLoading } = { ...defaultOptions, ...options }
    const { property, label, format = 'string', width, ...rest } = col

    const formatMap = {
        currency: (col) => ({
            valueGetter: (params) => +params?.node?.data[property],
            field: property,
            // valueFormatter: ({ value = 0 }) => formatCurrency(value),
            cellRenderer: (params) => {
                let spanClass
                let val
                if (params?.node?.data[property] != null) {
                    val = formatCurrency(params?.node?.data[property] || 0, 0)
                } else {
                    val = '--'
                    // val = 'N/A'
                }
                // case for summary (pinned) bottom row
                if (params.node?.rowPinned) {
                    spanClass = 'type-heavy'
                    if (params?.node?.data[property] == null) {
                        val = null
                    }
                    // case when the summary is loading
                    if (summaryLoading) {
                        val = <SkeletonText />
                    }
                }

                return <span className={spanClass}>{val}</span>
            },
        }),
        string: (col) => ({
            field: property,
            // to ensure proper sorting, convert to lowercase. ag-grid
            // sorts lowercase before uppercase by default
            valueGetter: (params) => `${params?.node?.data[property]}`.toLowerCase(),
            cellRenderer: (params) => {
                let spanClass = []
                let val = toTitleCase(params?.node?.data[property] || '')
                if (val == null) {
                    val = '--'
                    // val = 'N/A'
                }
                return <span className={spanClass.join(' ')}>{val}</span>
            },
        }),
        boolean: (col) => ({
            field: property,
            valueGetter: (params) => params?.node?.data[property],
            cellRenderer: (params) => {
                let val
                const propertyVal = params?.node?.data[property]
                if (propertyVal == null) {
                    val = '--'
                    // val = 'N/A'
                } else {
                    val = `${propertyVal}` == '1' ? 'Yes' : 'No'
                }
                return <span className="">{val}</span>
            },
        }),
        percent: (col) => ({
            field: property,
            valueGetter: (params) => +params?.node?.data[property],
            cellRenderer: (params) => {
                let spanClass
                let val
                if (params?.node?.data[property] != null) {
                    val = `${formatNumber(params?.node?.data[property] || 0, 2)} %`
                } else {
                    val = '--'
                    // val = 'N/A'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        decimal: (col) => ({
            field: property,
            valueGetter: (params) => +params?.node?.data[property],
            cellRenderer: (params) => {
                // case for the summary (pinned) row
                let spanClass
                let val
                if (params?.node?.data[property] != null) {
                    val = formatNumber(params?.node?.data[property] || 0, 2)
                } else {
                    val = '--'
                    // val = 'N/A'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        integer: (col) => ({
            field: property,
            valueGetter: (params) => +params?.node?.data[property],
            cellRenderer: (params) => {
                // case for the summary (pinned) row
                let spanClass
                let val
                if (params?.node?.data[property] != null) {
                    val = formatNumber(params?.node?.data[property] || 0, 0)
                } else {
                    val = '--'
                    // val = 'N/A'
                }
                return <span className={spanClass}>{val}</span>
            },
        }),
        edit: (col) => ({
            cellRenderer: (params) => {
                return (
                    <Button
                        variant="span"
                        size="large"
                        onClick={() => onClick(params.data.ad_network_id)}
                    >
                        {params.data[property]}
                    </Button>
                )
            },
        }),
        hubspot_link: (col) => ({
            cellRenderer: (params) => {
                const hubSpotEnvironmentId = process.env.REACT_APP_HUBSPOT_ID
                const url = `https://app.hubspot.com/contacts/${hubSpotEnvironmentId}/company/${params.data.hubspot_company_id}`
                return (
                    // <IconButton
                    //     icon={PencilSvg}
                    //     variant="span"
                    //     onClick={() => onClick(params.data.ad_network_id)}
                    // />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <Link
                            url={url}
                            classNames="type-normal-body type-blue"
                            display={params.data.hubspot_company_id}
                        />
                    </div>
                )
            },
        }),
    }

    return {
        headerName: label,
        ...formatMap[format](col),
        width: width || 120,
        ...rest,
    }
}
