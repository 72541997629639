import React, { Component } from 'react';

export default class AreYouSureModal extends Component {
    render() {
        return (
            <div className="type-normal-body spacing-20-bottom modal-2-button">
                <button
                    className="button ui-normal-button"
                    onClick={() => this.props.deleteImage(this.props.imageId, this.props.imageIndex)}
                >
                    Yes
                </button>
                <button
                    className="button ui-normal-button"
                    onClick={() => this.props.updateModalContent(undefined, undefined)}
                >
                    No
                </button>
            </div>
        )
    }
}
