import React from 'react';

import { ButtonCloseXSvg } from '../../common/Svgs';
import Tooltip from '../../common/Tooltip';

const CreditCardRow = (props) => {
    const cardExpireMonth = Number(props.creditCard.expiration_month) < 10
        ? "0" + props.creditCard.expiration_month
        : props.creditCard.expiration_month;

    const now = new Date();
    const hasExpired = props.creditCard.expiration_year < now.getFullYear() ||
        (props.creditCard.expiration_year == now.getFullYear() && props.creditCard.expiration_month <= now.getMonth());

    const tooltipRef = React.createRef();

    /**
     * A hack to close an open tool tip programmatically, vs. requiring the
     * user to click outside of it.  Called when delete confirmation tool tip's
     * Cancel link is clicked, and in the response handler to the delete request.
     * @todo: add said Cancel link to the delete confirmation tool tip.
     */
    let closeTooltip = () => {
        document.querySelector('body').click();
    };

    let classNames = 'type-normal-body';
    if (props.className) {
        classNames += ' ' + props.className;
    }

    return (
        <tr className={classNames}>
            <td headers="creditcard-name" className="creditcard__col__name">
                <span className="type-small-body type-heavy type-hide-full type-hide-tablet type-force-newline">
                    Name on Card
                </span>
                { props.creditCard.first_name + " " + props.creditCard.last_name }
            </td>
            <td headers="creditcard-number" className="creditcard__col__number">
                <span className="type-small-body type-heavy type-hide-full type-hide-tablet type-force-newline">
                    Card Number
                </span>
                { props.creditCard.abbreviation + " XXXXXXXX" + props.creditCard.last_four }
            </td>
            <td headers="creditcard-expiration" className="creditcard__col__expiration">
                <span className="type-small-body type-heavy type-hide-full type-hide-tablet type-force-newline">
                    Expiration
                </span>
                { cardExpireMonth + "/" + props.creditCard.expiration_year }
            </td>
            <td headers="creditcard-notes" className="creditcard__col__notes">
                {hasExpired
                    ? <span className="type-hide-mobile type-alert">
                        Expired
                    </span>
                    : (
                        props.creditCard.is_primary == 1
                        ? <span className="type-hide-mobile">
                            Primary
                        </span>
                        : <div className="spacing-10-mobile">
                            <span role="button"
                                className="type-small-body type-heavy type-blue"
                                onClick={(event) => props.setAsPrimary(event)}
                            >
                                <span
                                    id={props.creditCard.credit_card_id}
                                    style={{width: "100%"}}
                                >
                                    Set as Primary Payment Method
                                </span>
                            </span>
                        </div>
                    )}
            </td>
            <td headers="creditcard-actions" className="creditcard__col__actions">
                <div className="spacing-10-top-mobile">
                    {Number(props.creditCard.is_primary)
                        ? <span className="type-small-body type-heavy type-no-break type-grey">
                            <Tooltip
                                position="left"
                                content={
                                    <span className="type-small-body type-black type-force-newline type-notransform type-align-left type-narrow-line-height">
                                        You cannot delete your Primary Payment method. Add or specify another credit card
                                        as your Primary to delete this one.
                                    </span>
                                }
                                anchor={
                                    <span className="type-no-break ui-pointer-cursor">
                                        <span className="inline-icon inline-icon__middle inline-icon__16">
                                            { ButtonCloseXSvg }
                                        </span>{' '}
                                        Delete
                                    </span>
                                }
                                disabledStyle={ true }
                                modalContainerRef={tooltipRef}
                            />
                        </span>
                        : <span className="type-small-body type-heavy type-no-break type-grey">
                            <Tooltip
                                position="left"
                                content={
                                    <>
                                        <span className="type-small-body type-black type-force-newline type-notransform type-align-left">
                                            Are you sure you want to delete this card ending in {props.creditCard.last_four}?
                                        </span>
                                        <div className="ui-hide-mobile">
                                            <div className="popup__form__row popup__form__row__slam-right padding-24-top">
                                                <div className="popup__form__cell">
                                                    <span role="button"
                                                        className="type-small-body type-heavy type-blue"
                                                        onClick={() => {
                                                            //
                                                            // This simulates a click on the document body, to close
                                                            // the tool tip.
                                                            //
                                                            document.querySelector('body').click();
                                                        }}
                                                    >
                                                        Cancel
                                                    </span>
                                                </div>
                                                <div className="popup__form__cell">
                                                    <button
                                                        className="button ui-normal-button"
                                                        onClick={() =>
                                                            props.deleteCard(props.creditCard.credit_card_id)
                                                                .then(closeTooltip)
                                                        }
                                                    >
                                                        Delete Card
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-hide-full ui-hide-tablet">
                                            <div className="popup__form__row padding-40-bottom">
                                                <button
                                                    className="button ui-normal-button ui-full-width-button"
                                                    onClick={() =>
                                                        props.deleteCard(props.creditCard.credit_card_id)
                                                            .then(closeTooltip)
                                                    }
                                                >
                                                    Delete Card
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                                anchor={
                                    <span className="type-no-break ui-pointer-cursor">
                                        <span className="inline-icon  inline-icon__middle inline-icon__16 blue-normal">
                                            { ButtonCloseXSvg }
                                        </span>{' '}
                                        Delete
                                    </span>
                                }
                            />
                        </span>
                    }
                </div>
            </td>
        </tr>
    )
};

export default CreditCardRow;
