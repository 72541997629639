import axios from "axios";
import type {CancelTokenSource, AxiosResponse} from "axios";

import {get, post, put} from "../Requests";
import {toCamelObject, toSnakeObject} from "../Util";
import type {IExternalBuyer} from "../Interfaces/IExternalBuyer";
import type {IIndustry} from "../Interfaces/IIndustry";

export default class ExternalBuyerService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal: CancelTokenSource;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    getToken: CancelTokenSource;
    /**
     * get external lead buyers based on filtering
     * only valid for admin users
     * @param {number[]} [ids=[]] array of external lead buyers ids to filter by
     * @return {Promise<{data:{externalBuyers:IExternalBuyer[]}}>}
     */
    get = (ids = []): Promise<{ data: { externalBuyers: IExternalBuyer[] } }> => {
        if (this.getToken) {
            this.getToken.cancel();
        }
        let url = 'external_buyers';

        if (ids.length > 0) {
            url += '?external_buyer_ids=' + ids.join(',');
        }

        this.getToken = axios.CancelToken.source();
        return get(url, this.getToken.token)
            .then((response) => toCamelObject(response.data));
    };

    /**
     * creates a new external lead buyer
     * @param properties
     * @returns {Promise<AxiosResponse<{message: string, data: IExternalBuyer}>>}
     */
    create = (properties: Partial<IExternalBuyer>): Promise<AxiosResponse<{ externalBuyer: IExternalBuyer }>> =>
        post('external_buyers', toSnakeObject(properties), this.cancelSignal.token)
            .then((response) => toCamelObject(response.data));

    /**
     * creates a new external lead buyer
     * @param externalBuyerId the external lead buyer id
     * @param properties an object with values to update
     * @returns {Promise<AxiosResponse>}
     */
    update = (externalBuyerId: number, properties: Partial<IExternalBuyer>): Promise<AxiosResponse<{ externalBuyer: IExternalBuyer }>> =>
        put(`external_buyers/${externalBuyerId}`, toSnakeObject(properties), this.cancelSignal.token)
            .then((response) => toCamelObject(response.data));

    getIndustriesToken: CancelTokenSource;
    /**
     * get the external lead buyer industry settings
     * returns all the industries with industry specific settings when available
     * @param {number} externalBuyerId
     * @return {Promise<{data:{externalBuyers:IExternalBuyer[]},pagination:object}>}
     */
    getIndustries = (externalBuyerId: number): Promise<AxiosResponse<{ industries: IIndustry[] }>> => {
        if (this.getIndustriesToken) {
            this.getIndustriesToken.cancel();
        }

        this.getIndustriesToken = axios.CancelToken.source();
        return get(`external_buyers/${externalBuyerId}/industries`, this.getIndustriesToken.token)
            .then((response) => toCamelObject(response.data.data));
    };

    /**
     * updates the external lead buyer industries configurations
     * @param {number} externalBuyerId the external lead buyer id
     * @param {IIndustry[]} industries the industries configurations
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateIndustries = (externalBuyerId: number, industries: IIndustry[]): Promise<AxiosResponse<{industries: IIndustry[]}>> =>
        put(`external_buyers/${externalBuyerId}/industries`, toSnakeObject({industries}), this.cancelSignal.token)
            .then((response) => toCamelObject(response.data));

    /**
     * test the external lead buyer ping post settings
     * @param {object} pingPostBody the ping post body
     * @returns {Promise<AxiosResponse<{message: string} | {error: string}>>}
     */
    testPingPost = (pingPostBody: any): Promise<AxiosResponse<{ message: string } | { error: string }>> => {
        return post('external_buyers/ping_post_test', pingPostBody, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data));
    };  
}
