import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class UploadFileButton extends Component {
    chooseFile = () => {
        document.getElementById(this.props.name).click();
    };

    render() {
        return (
            <div className={(this.props.bottomSpacing) ? "spacing-20-bottom" : null}>
                <input
                    type="file"
                    id={this.props.name}
                    name={this.props.name}
                    onChange={this.props.onChange}
                    style={{display: "none"}}
                    multiple={this.props.multiple}
                />
                <button
                    className="button ui-normal-button"
                    onClick={this.chooseFile}
                >
                    Upload {this.props.name}
                </button>
            </div>
        )
    }
};

UploadFileButton.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    multiple: PropTypes.bool.isRequired,
};