import React from 'react';
import PropTypes from 'prop-types';

// import { ButtonCloseXSvg } from './Svgs';

const MajorAlert = (props) => (
    <p className="flash-message flash-message__alert type-normal-body pin-container">
        {/* TODO FIXME: close button not completed yet
        <span role="button" className="close-button" onClick={ () => props.close() }>
            { ButtonCloseXSvg }
        </span>
        */}
        <b>ALERT:</b> { props.alertText }
    </p>
);

export default MajorAlert;

MajorAlert.propTypes = {
    alertText: PropTypes.element.isRequired
};
