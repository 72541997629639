import React from 'react';
import PropTypes from 'prop-types';

import {formatCurrency} from '../../Util';
import CreditCardForm from "../../common/components/CreditCardForm";
import CreditCardFormReadOnly from "../../common/components/CreditCardFormReadOnly";
import MultiSelectList from '../../common/components/form_elements/MultiSelectList';

/*
 Validation for this PaymentMethodSection happens on submit
    An example of this happening can be found on the
    AccountSetup component.
*/


/*
 * we might want a required flag as a prop in the creditCardForm component
 * because then we can dynamically specify whether or not these creditCardForms
 * run through validation or not
 * basically
 * required={props.required}
 * in the elem
 */
const PaymentMethodSection = (props) => {
    let name = props.name;
    let number = props.number;
    let expiration = props.expiration;
    let cvv = props.cvv;
    let zip = props.zip;
    let amountDue = props.amountDue;
    let required = props.required;
    let showCheckbox = props.showCheckbox;

    const parseCreditCardsOptions = (creditCards: Object[]) => {
        creditCards = creditCards.map((creditCard, index) => {
            let text = `${creditCard.firstName} ${creditCard.lastName} (${creditCard.lastFour})`;
            if (creditCards.length > 1 && creditCard.isPrimary) {
                text += '[Primary]';
            }
            return {
                value: index,
                text
            };
        });

        creditCards.push({
            value: -1,
            text: 'Charge now to a new Payment Method'
        });

        return creditCards;
    };

    return <div className="row padding-50-bottom">
        <div className="col alpha grid-2 empty"/>
        <div className="col grid-8 no-margin-mobile">
            <div className="module">
                <div className="module__header module__twothirdswidth">
                    <h3 className="type-large-subhead type-narrow-line-height">
                        Primary Payment Method
                    </h3>
                </div>
                {props.primaryPaymentMethodRequired
                    ? <div className="module__contentbox module__twothirdswidth">
                        <p className="type-large-body type-heavy spacing-20-bottom type-red">
                            {formatCurrency(amountDue)} due now for
                            {props.enroll
                                ? ' Select Program Enrollment.'
                                : ' Account Activation.'}
                        </p>
                        {showCheckbox &&
                        <div className={"form__row " + (props.showCreditCardForm ? "spacing-20-bottom" : '')}>
                            <div className="form__cell form__cell__100">
                                <div className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        name="show_credit_card_form"
                                        id="show-credit-card-form"
                                        checked={!props.showCreditCardForm}
                                        onChange={props.checkboxCallback}
                                    />
                                    <label htmlFor="show-credit-card-form">
                                        <span className="checkbox">
                                            Client will complete payment in Step 1 - Account Setup
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>}
                        {props.showCreditCardForm &&
                        <div>
                            {props.creditCards && props.creditCards.length > 0 &&
                            <MultiSelectList options={parseCreditCardsOptions(props.creditCards)}
                                        name="selected-card"
                                        label="Select From Available Cards"
                                        selections={[props.selectedCreditCardIndex]}
                                        onChange={props.handleSelectingCard}/>}

                            <p className="type-normal-body spacing-40-bottom">
                                This card will remain on file as the Client’s Primary Payment Method for future billing.
                                The Client can change their Primary Payment Method at any time in their mySD Account
                                Settings.
                            </p>

                            {props.selectedCreditCardIndex != -1
                                ? <CreditCardFormReadOnly
                                    creditCard={props.creditCards[props.selectedCreditCardIndex]}/>
                                : <CreditCardForm
                                    name={name}
                                    number={number}
                                    expiration={expiration}
                                    cvv={cvv}
                                    zip={zip}
                                    required={required}
                                    onChange={props.syncStateToInput}
                                    onBlur={props.validateInputOnBlur}
                                />}
                        </div>}
                    </div>
                    : <div className="module__contentbox module__twothirdswidth">
                        <p className="type-large-body type-heavy">
                            Primary Payment Method is not required for a {props.parentContractorName} client.
                        </p>
                    </div>}
            </div>
        </div>
        <div className="col omega grid-2 empty"/>
        <div className="clear-block"/>
    </div>;
};

export default PaymentMethodSection;

PaymentMethodSection.propTypes = {
    creditCards: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    selectedCreditCardIndex: PropTypes.number.isRequired,
    enroll: PropTypes.bool
};
