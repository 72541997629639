import React, {useRef} from 'react';

import {FormLeadsSvg, PencilSvg} from "../../../common/Svgs";
import Tooltip from "../../../common/Tooltip";
import {CampaignTypes} from "../../../common/StaticValues";
import {useModal} from "../../../common/ModalContext";
import MarketplaceFormCostPerLeadModal from "./Modals/MarketplaceFormCostPerLeadModal";
import type {ISubcategory} from "../../../Interfaces/ISubcategory";
import AuthService from "../../../service/AuthService";

const FormLeads = (props) => {
    const {campaign} = props;
    const updateModalContent = useModal();
    const containerRef = useRef();

    // if a Select campaign, return an empty div to keep the forms section empty
    if (campaign.type !== CampaignTypes.MARKETPLACE) {
        return <div/>;
    }

    const enabledSubcategoriesCount = campaign.first_enabled_mp_forms
        ? campaign.forms_subcategories.filter((subcategory: ISubcategory) => subcategory.enabled == 1).length
        : 0;
    const mpFormsAvailable = props.mpFormsEarlyAccess &&
        props.campaign.industry.is_mp_forms == 1 &&
        props.campaign.industry.is_mp_forms_client_facing == 1;

    const openCostPerLeadModal = () => {
        updateModalContent({
            content: <MarketplaceFormCostPerLeadModal
                campaign={campaign}
                campaignIndex={props.campaignIndex}
                editing={!!campaign.first_enabled_mp_forms}
                handleSubcategoriesChange={props.handleSubcategoriesChange}
                containerRef={containerRef}
                updateMessageBlocks={props.updateMessageBlocks}
            />,
            containerRef: containerRef,
            header: 'Form Lead Settings',
            flatBottom: true
        });
    };

    const mpFormsDisabledMessage = props.campaign.industry.is_mp_forms == 1
        ? 'Coming Soon!'
        : 'Form Leads Not Available for this Service Category';

    return <div className="early-access-temp-parent-class">
        <div className="type-grey inline-icon inline-icon__28">
            {FormLeadsSvg}
        </div>

        <div ref={containerRef} className="campaigns-table__segment-header">
            <span className="type-normal-subhead type-heavy">
                Form Leads{' '}
                <Tooltip
                    position="right"
                    content={<>
                        <span
                            className="type-large-body type-force-newline type-heavy type-narrow-line-height spacing-10-bottom">
                            Form Leads Settings
                        </span>
                        <span className="type-small-body type-narrow-line-height">
                            As part of our continued efforts to generate as many new customer leads as possible,
                            we are now offering Marketplace Form Leads in a limited number of Service Categories.
                            Different from our Phone Leads, Form Leads involve Customers submitting a contact request online.
                            Once you receive the request, it's important to follow up with the customer as quickly as possible.
                            If you opt in to purchase Form Leads you will be notified when each new Form Lead is generated
                            (according to your Campaign Lead Delivery & Notifications settings).
                            You can also access your Form Leads in your mySD Lead Manager any time.{' '}
                            <a target="_blank" className="type-heavy" href={AuthService.isMarketplace
                                ? 'https://support.servicedirect.com/marketplace-form-leads'
                                : 'https://support.servicedirect.com/select/marketplace-form-leads'}>Learn More</a>
                        </span>
                    </>}
                />
                {mpFormsAvailable &&
                <span
                    className="type-alert-text orange-background type-small-body early-access-flag">
                    New!</span>}
            </span>
            {mpFormsAvailable && enabledSubcategoriesCount > 0 &&
            <button className="button-clean type-blue type-small-body type-heavy"
                    onClick={openCostPerLeadModal}>
                <span className="inline-icon inline-icon__16 inline-icon__16__10">
                    {PencilSvg}
                </span>
                Edit
            </button>}
        </div>

        <p className="type-normal-body type-narrow-line-height">
            {mpFormsAvailable
                ? <>
                    <span className="type-heavy">
                        Enabled {enabledSubcategoriesCount} out of {campaign.forms_subcategories.length}
                    </span>{' '}
                    Service Subcategories
                </>
                : mpFormsDisabledMessage}

            {mpFormsAvailable && enabledSubcategoriesCount === 0 &&
            <button className="button ui-normal-button type-force-newline spacing-12"
                    onClick={openCostPerLeadModal}>
                Enable Form Leads
            </button>}
        </p>
    </div>;
};

export default FormLeads;
