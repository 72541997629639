import axios from "axios";
import type {CancelTokenSource, AxiosResponse} from "axios";

import {get} from "../Requests";
import {toCamelObject} from "../Util";
import type {IPaginationInterface} from "../Interfaces/IPaginationInterface";

export default class ContractorService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal: CancelTokenSource;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    /**
     * get all of the contractors associated with the requesting user
     * only valid for users of a parent or manager account
     * @return {Promise<AxiosResponse<any> | boolean>}
     */
    getOfParent = (): Promise<AxiosResponse> =>
        get('contractors/of_parent', this.cancelSignal.token)
            .then((response) => response.data.data.contractors);

    getToken: CancelTokenSource;
    /**
     * get contractors based on filtering
     * only valid for admin users
     * @param {IPaginationInterface} pagination
     * @param {Object} [filter={}]
     * @param {string} [filter.search]
     * @param {string} [filter.orderBy]
     * @param {string} [filter.type]
     * @return {Promise<Object>}
     */
    get = (pagination: IPaginationInterface, filter): Promise<Object> => {
        if (this.getToken) {
            this.getToken.cancel();
        }
        let url = `contractors?order_by=${filter.orderBy}`;
        if (filter.search) {
            url += `&search=${encodeURIComponent(filter.search)}`;
        }
        if (filter.type) {
            url += `&type=${encodeURIComponent(filter.type)}`;
        }
        if (filter.enrollable) {
            url += `&enrollable=1`;
        }

        if (pagination.rowsPerPage) {
            url += `&pagination[rows_per_page]=${pagination.rowsPerPage}`;
        }
        if (pagination.pageNum !== undefined) {
            url += `&pagination[page_num]=${pagination.pageNum}`;
        }

        this.getToken = axios.CancelToken.source();
        return get(url, this.getToken.token)
            .then((response) => toCamelObject(response.data));
    };

    /**
     * get all parent contractors for admin views
     * @return {Promise<AxiosResponse<any> | boolean>}
     */
    getParentContractors = () => {
        return get('contractors/admin/parent_contractors', this.cancelSignal.token).then(
            (response) => response.data.data.contractors
        );
    };

    /**
     * get manager accounts for admin dropdown
     * @return {Promise<AxiosResponse<any> | boolean>}
     */
    getManagerAccounts = () => {
        return get('contractors/admin/manager_contractors', this.cancelSignal.token).then(
            (response) => response.data.data.contractors
        );
    };

    /**
     * Get the clients? // TODO: check the description
     */
    getClients = () => {
        return get('contractors/admin/clients', this.cancelSignal.token).then(
            (response) => response.data.data.contractors
        );
    };

    /**
     * Gets the data of a given contractor
     * @param {number} contractorId
     * @return {Promise<Object>}
     */
    getContractorData = (contractorId: number): Promise<Object> =>
        get(`contractors/${contractorId}`, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));

    /**
     * returns the schedule a call link for a contractor
     * @param {number} contractorId - the contractor's id
     * @return {Promise<Object>}
     */
    getScheduleCallLink = (contractorId) =>
        get(`contractors/${contractorId}/schedule_a_call_link`, this.cancelSignal.token)
            .then((resp) => toCamelObject(resp.data.data));
            
    
    /**
     *
     */
    isMQL = (contractorId) =>
        get(`contractors/${contractorId}/mql`, this.cancelSignal.token)
            .then((resp) => resp.data.data.is_mql === 1);
    
    getIndustries = (contractorId) => 
        get(`contractors/${contractorId}/industries`, this.cancelSignal.token)
            .then((resp) => resp.data.data.industries);
    
    getOnboardingBusinessInfo = (contractorId) =>
        get(`contractors/${contractorId}/onboarding_business_info`, this.cancelSignal.token)
            .then((resp) => resp.data.data);
	
	getCampaignNames = (contractorId) => 
		get(`contractors/${contractorId}/campaign_names`, this.cancelSignal.token)
			.then((resp) => resp.data.data);

    getPrimaryContact = (contractorId) =>
        get(`contractors/${contractorId}/primary_contact`, this.cancelSignal.token)
            .then((resp) => resp.data.data);
}
