import React from 'react';

const CallOutBox = (props) => {
	return (
		<div className={`onboarding__call-out-box ${props.extraClasses ? props.extraClasses : ''}`}>
			<p className="type-large-body type-heavy type-allcaps spacing-10-bottom">
				{props.headline}
			</p>
			<div className="contents">
				{props.children}
			</div>
		</div>
	);
};

export default CallOutBox;