import axios from "axios";
import type {CancelTokenSource} from 'axios';

import {del, get, post, put} from '../Requests';
import {buildFilterUrl, toCamelObject, toSnakeObject} from "../Util";
import type {IPaginationInterface} from "../Interfaces/IPaginationInterface";
import type {User} from "../Interfaces/User";

export default class UserService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal: CancelTokenSource;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    addUser = (properties: Partial<User>): Promise<Object> =>
        post('users', toSnakeObject(properties), this.cancelSignal.token);

    get = (pagination: IPaginationInterface = {}, filter = {}): Promise<Object> => {
        let url = 'users';

        url += buildFilterUrl(pagination, filter);

        return get(url, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data));
    };

    getByAdmin = (contractorId: number): Promise<Object> =>
        get(`users/${contractorId}/login`, this.cancelSignal.token)
            .then((response) => response.data.data.users);

    getLeadReviewUsers = (): Promise<Object> =>
        get('users/lead_review', this.cancelSignal.token)
            .then((response) => response.data.data.users);

    update = (userId: number, properties: Partial<User>): Promise<Object> =>
        put(`users/${userId}`, toSnakeObject(properties), this.cancelSignal.token);

    delete = (userId: number, updateHubSpot = false): Promise<Object> => {
        let url = `users/${userId}`;
        if (updateHubSpot) {
            url += '?update_hubspot=true';
        }
        return del(url, this.cancelSignal.token);
    };

    resendInvitation = (userId: number): Promise<Object> =>
        post(`users/${userId}/invite`, null, this.cancelSignal.token);

    sendResetPasswordEmail = (email: string): Promise<Object> =>
        post('auth/request_password_reset', {email}, this.cancelSignal.token);
}