import React from 'react';

import Tooltip from "../../../common/Tooltip";
import {LocationSvg, PencilSvg} from "../../../common/Svgs";
import EditZipsModal from "../EditZipsModal";
import {useModal} from "../../../common/ModalContext";

const MarketplaceServiceArea = (props) => {
    const {campaign} = props;
    const updateModalContent = useModal();

    const editZips = () =>
        updateModalContent({
            content: <EditZipsModal
                zipCodes={campaign.sd_networks_target_postal_codes}
                campaignId={campaign.campaign_id}
                campaignIndex={props.campaignIndex}
                updateMessageBlocks={props.updateMessageBlocks}
                handleChange={props.submitAreaCodeChange}
            />,
            header: `Edit Service Area for ${campaign.campaign_name}`,
            width: 'wide'
        });

    return <div>
        <div className="type-grey inline-icon inline-icon__28">
            {LocationSvg}
        </div>

        <div className="campaigns-table__segment-header">
            <span className="type-normal-subhead type-heavy">
                Service Area Zips{' '}
                <Tooltip
                    position="right"
                    content={<>
                        <span
                            className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                            Service Area Zip Codes
                        </span>
                        <span className="type-small-body type-narrow-line-height">
                                Within Service Direct Marketplace, our publishing partners help
                                drive purchase-ready customers to your business based on the zip
                                code of the home in which the person needs service. We want to
                                make it easy for you to help us pinpoint the exact zip codes
                                you want these Leads in, so we give you the ability to easily
                                change your list of zip codes.{' '}
                            <a href="https://support.servicedirect.com/service-area"
                               className="type-heavy" target="_blank" rel="noopener">
                                Learn how to adjust your Service Area
                            </a>
                        </span>
                    </>}
                />
            </span>
            <button className="button-clean type-blue type-small-body type-heavy"
                    onClick={editZips}>
            <span className="inline-icon inline-icon__16 inline-icon__16__10">
                {PencilSvg}
            </span>
                Edit
            </button>
        </div>

        <p className="type-force-newline type-small-body campaign__zip-codes">
            {campaign.sd_networks_target_postal_codes}
        </p>
    </div>;
};

export default MarketplaceServiceArea;
