import React from 'react';

import CallOutBox from './CallOutBox';
import CoreSection from './CoreSection';
import ExpandableSection from './ExpandableSection';
import ImageBox from './ImageBox';
import QuizSection from './QuizSection';
import Sidebar from './sidebar';
import WistiaPlayer from '../common/WistiaPlayer';

const StepFour = (props) => {
	return (
		<div className="onboarding-contents page-width">
			<Sidebar step={4} {...props} />
			
			<div className="onboarding-main-column">
				<CoreSection
					headerLabel="Section 4"
					header="How Are You Billed"
				>
					<h4 className="type-large-subhead type-heavy spacing-40-bottom">
						Let’s Review How You Are Billed
					</h4>
					
					<div className="spacing-40-bottom">
						<WistiaPlayer
							videoId="b8tr0o7hhm"
							wrapper="wistia-player-1"
						/>
					</div>
					
					<h5 className="type-normal-subhead type-heavy spacing-24-bottom">
						Key Concepts
					</h5>
					
					<p class="type-normal-subhead type-narrow-line-height spacing-24">
						We pride ourselves on being transparent with our billing model and will work with you in good faith to make sure you only pay for Billable Leads.
					</p>
					
					<div className="onboarding__columns-block">
						<div className="column">
							<ul className="type-large-body type-narrow-line-height">
								<li>
									Your Payment Method will be charged at the end of the day once your Lead Cost reaches or surpasses your Billing Threshold.
								</li>
							</ul>
						</div>
						<div className="column">
							<ul className="type-large-body type-narrow-line-height">
								<li>
									Monitor your spend, see itemized Billing Statements, and set a monthly Lead Cost Notification in your Billing Center.
								</li>
							</ul>
						</div>
					</div>
				</CoreSection>
				
				<ExpandableSection
					header="Current Billing Cycle and Billing Statements"
				>
					<div className="onboarding__columns-block spacing-40-bottom">
						<div className="column">
							<h4 className="type-large-subhead type-heavy spacing-24-bottom">
								Current Billing Cycle
							</h4>
							<ul className="type-large-body type-narrow-line-height">
								<li>
									Your Billing Cycle is the period of time from your last Billing Statement until now.
								</li>
								<li>
									In the Current Billing Cycle section, we display your Current Balance, which is calculated by combining your:
									<ul className="type-large-body type-narrow-line-height">
										<li>
											<span className="type-heavy">Lead Cost –</span> Your Campaigns’ Cost Per Lead multiplied by the number of Leads we’ve delivered.
										</li>
										<li>
											<span className="type-heavy">Lead Credit –</span> Often we catch Non-Billable Leads through our automated and human Lead Review systems prior to them counting towards your Lead Cost, but other times Leads must be Submitted for Review by you so we are made aware and can take a look. If we confirm those submitted Leads to be indeed Non-Billable, we will apply Lead Credits and reduce your Current Balance.
										</li>
										<li>
											<span className="type-heavy">Unpaid Statement –</span> If you haven’t successfully made payment to an existing Billing Statement, it will be displayed here and added to your Current Balance total.
										</li>
									</ul>
								</li>
							</ul>
							
							<h4 className="type-large-subhead type-heavy spacing-40-top spacing-24-bottom">
								Billing Statements
							</h4>
							
							<ul className="type-large-body type-narrow-line-height">
								<li>
									You can see and download all your Billing Statements in the Statements section of the mySD Billing Center.
								</li>
								<li>
									Any Unpaid Statement will display a “Pay Now” button.
								</li>
							</ul>
						</div>
						<div className="column">
							<ImageBox
								url="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Onboarding%20Screenshots/mySD-Billing-v5-MP.jpg"
								alt="Screenshot of the mySD Billing Center"
								caption="mySD Billing Center"
								showPopup={props.showPopup}
								extraClasses="spacing-40-bottom"
							/>
							
							<CallOutBox
								headline="Important Note"
							>
								<ul className="type-large-body type-narrow-line-height">
									<li>
										If our Lead Review process finds a previously Billable Lead to be Non-Billable, we apply a Lead Credit to your Current Balance. It’s not feasible for us to refund your Payment Method directly, but rest assured you will be credited for all Non-Billable Leads.
									</li>
								</ul>
							</CallOutBox>
						</div>
					</div>
					
					<CallOutBox
						headline="Learn More"
					>
						<ul className="type-large-body type-narrow-line-height">
							<li>
								Read our Help Center article <a href="https://support.servicedirect.com/how-you-are-billed" target="_blank" className="type-heavy">How You Are Billed</a>
							</li>
						</ul>
					</CallOutBox>
				</ExpandableSection>
				
				<ExpandableSection
					header="Billing Threshold and Monthy Lead Cost Notification"
				>
					<div className="onboarding__columns-block">
						<div className="column">
							<h4 className="type-large-subhead type-heavy spacing-24-bottom">
								Billing Threshold
							</h4>
							<ul className="type-large-body type-narrow-line-height">
								<li>
									Rather than charging your Payment Method in real-time for each Billable Lead you receive, we use a Billing Threshold system which accounts for Lead Costs accrued each 24-hour period.
								</li>
								<li>
									You will not be charged until your Current Balance reaches the Billing Threshold.
								</li>
								<li>
									Once the Billing Threshold has been met, we will charge your Primary Payment Method at the end of the day.
								</li>
								<li>
									In the event you have not reached your Billing Threshold by the end of the month, we will bill you on the first of the following month.
								</li>
								<li>
									After successful payment, your Billing Threshold is reset and the process starts over.
								</li>
							</ul>
						</div>
						<div className="column">
							<CallOutBox
								headline="Billing Threshold Examples"
							>
								<ul className="type-large-body type-narrow-line-height">
									<li>
										It may take several days before enough Billable Leads get generated to reach your Billing Threshold. Therefore, you may see multiple days worth of Leads on your Statement.
									</li>
									<li>
										Our Billing system checks your Balance at the end of each day and will create a Statement once you have reached or exceeded your Threshold. The exact amount of your Statement will depend on how many Leads you receive on the day you exceeded your Threshold.
									</li>
								</ul>
							</CallOutBox>
						</div>
					</div>
					
					<div className="onboarding__columns-block spacing-40-top">
						<div className="column">
							<h4 className="type-large-subhead type-heavy spacing-24-bottom">
								Monthly Lead Cost Notification
							</h4>
							<ul className="type-large-body type-narrow-line-height">
								<li>
									You can set a dollar amount that triggers an email notification to the Users who are set to receive Billing Notifications, alerting them that your specified Lead Cost amount has been reached that month.
								</li>
								<li>
									The system only sends one Lead Cost Notification per month, even if your Lead Cost reaches the Lead Cost Notification dollar amount multiple
									times in a given month.
								</li>
							</ul>
						</div>
						<div className="column">
							<CallOutBox
								headline="Important Note"
							>
								<ul className="type-large-body type-narrow-line-height">
									<li>
										Your Lead Cost Notification amount is just a trigger that sends you an email alert.
									</li>
									<li>
										Lead Cost Notifications do not Pause your Campaigns or stop incoming Leads (instead, those can be managed in your Campaigns Manager, to be discussed later).
									</li>
								</ul>
							</CallOutBox>
						</div>
					</div>
				</ExpandableSection>
				
				<QuizSection
					{...props}
					questions={[
						{
							name: "step-4-question-1",
							question: "When do you get billed to your credit card?",
							answers: [
								"As soon as any Lead arrives",
								"As soon as the Current Balance meets the Billing Threshold",
								"End of day after Current Balance meets the Billing Threshold",
							],
							correctAnswer: 3,
						},
						{
							name: "step-4-question-2",
							question: "Can you receive a notification when your Lead Cost reaches a certain amount?",
							answers: [
								"No",
								"Yes, and I set the amount",
								"Yes, but Service Direct sets the amount",
							],
							correctAnswer: 2,
						}
					]}
				/>
			</div>
		</div>
	);
};

export default StepFour;