import axios from "axios";

import {get, del} from '../Requests';
import {toCamelObject} from "../Util";
import type {HighlightInterface} from "../Interfaces/HighlightInterface";

export default class HighlightService {
    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    /**
     * get all highlights of the contractor that the user did not dismiss
     * @return {Promise<HighlightInterface[]>}
     */
    get = (): Promise<HighlightInterface[]> =>
        get('notifications/highlights', this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data.highlights));

    /**
     * dismisses a [dismissible] highlight
     * @param {number} highlightId
     * @return {Promise<Boolean>}
     */
    dismiss = (highlightId): Promise<Boolean> =>
        del(`notifications/highlights/${highlightId}`, this.cancelSignal.token)
            .then((response) => response.data.success);
}
