import React from 'react'
import { formatNumber, toTitleCase } from '../../../Util'
import Input from '../form_elements/Input'
import { GridCol, NoteBox, Wrapper } from './styles'

export default ({ rule, note }) => {
    const {
        action,
        industry_name = '',
        rule_display,
        has_input,
        input_type,
        input_unit,
        rule_name,
        rule_value,
        rule_value_display = '',
    } = rule
    const isDeleted = action == 'delete'
    const hasNote = note != null
    const showRuleValue = has_input == '1'

    const displayRuleValue = (val) => {
        let r
        if (input_type == 'currency' || input_type == 'integer') {
            r = `${formatNumber(val, 0)}`
        }
        else if (input_type == 'decimal') {
            r = `${formatNumber(val, 2)}`
        }
        if (val == '1') {
            r = 'Yes'
        } else if (val == '0') {
            r = 'No'
        } else {
            r = val
        }
        if (input_unit != null) {
            r += ` ${input_unit}`
        }
        return r
    }

    return (
        <Wrapper>
            {isDeleted && (
                <GridCol span={12}>
                    <span className="type-heavy type-red">This rule will be deleted</span>
                </GridCol>
            )}
            <GridCol span={4}>
                <Input
                    name="service_category"
                    type="text"
                    label="Service Category"
                    value={industry_name}
                    readOnly={true}
                />
            </GridCol>
            <GridCol span={6}>
                <Input
                    name="rule_type"
                    type="text"
                    label="Rule Type"
                    value={rule_display || toTitleCase(rule_name)}
                    readOnly={true}
                />
            </GridCol>
            {showRuleValue && (
                <GridCol span={2}>
                    <Input
                        name="rule_value"
                        type="text"
                        label={rule_value_display || 'Value'}
                        value={displayRuleValue(rule_value)}
                        readOnly={true}
                    />
                </GridCol>
            )}
            {hasNote && (
                <GridCol span={12}>
                    <NoteBox>
                        <span className="type-heavy">Note: </span>
                        <span>{note}</span>
                    </NoteBox>
                </GridCol>
            )}
        </Wrapper>
    )
}
