import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {getPayload, inputObj} from "../../formHelpers";
import CreditCardForm from "../../common/components/CreditCardForm";
import Validator from "../../lib/Validator";
import {isValidZipCode} from "../../Util";
import CreditCardService from "../../service/CreditCard";

/**
 * TODO Move this to be called with the Modal Component.
 *    Strip Modal Functionality for this and just have credit card form functionality
 *    BO dec 19 2018
 */

export default class AddCreditCardModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputs: {
                cc_name: inputObj(),
                cc_number: inputObj(),
                cc_expiration: inputObj(),
                cc_cvv: inputObj(),
                cc_zip: inputObj(),
                make_primary: inputObj(false),
            },
            submitting: false,
        };

        this.creditCardService = new CreditCardService();

        // get the default state for the inputs
        let stateClone = {...this.state};
        stateClone.inputs = {...stateClone.inputs};
        this.initialStateClone = stateClone;
    }

    componentWillUnmount() {
        this.creditCardService.cancelSignal.cancel();
    }

    /**
     * The onclick callback for form elements
     * @param {SyntheticEvent} event
     */
    syncCreditCardValues = (event) => {
        const inputs = {...this.state.inputs};
        inputs[event.target.name].value = event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value;
        inputs[event.target.name].error = '';
        this.setState({inputs});
    };

    /**
     * Validate the fields
     * check if we are already submitting
     * call adding new card
     * @returns {boolean}
     */
    handleSubmit = () => {
        // prevent multiple consecutive submissions
        if (this.state.submitting) {
            return false;
        }

        // we haven't passed validation yet
        if (!this.handleValidationBeforePost()) {
            return false;
        }

        // set to submitting and attempt to add the new credit card
        this.setState({submitting: true});

        const payload = getPayload(this.state.inputs);

        // clean CC number spaces, dashes, and any non-numeric characters
        payload.cc_number = payload.cc_number.replace(/\D/g, '');

        this.creditCardService.create(payload)
            .then((data) => {
                const creditCards = data.data.credit_cards;

                // update the app credit card list with the new credit card
                this.props.updateCreditCards(creditCards);

                // clear the state?
                this.setState(this.initialStateClone);

                // close the modal
                this.props.updateModalContent();

                // display the success message
                this.props.updateMessageBlocks(data.message, "success");
            })
            .catch(() => this.setState({submitting: false}));
    };

    /**
     * Validate the form fields before posting
     *@returns boolean
     **/
    handleValidationBeforePost = () => {
        const {inputs} = this.state;
        const errors = [];
        let valid = true;

        if (!Validator.validateFullName(inputs.cc_name.value)) {
            inputs.cc_name.error = 'Invalid value, must be First and Last name';
            errors.push(inputs.cc_name.error);
            valid = false;
        }
        if (!Validator.validateCreditCard(inputs.cc_number.value)) {
            inputs.cc_number.error = 'Invalid card number';
            errors.push(inputs.cc_number.error);
            valid = false;
        }
        if (!Validator.validateCardExpiration(inputs.cc_expiration.value)) {
            inputs.cc_expiration.error = 'Invalid expiration date';
            errors.push(inputs.cc_expiration.error);
            valid = false;
        }
        if (!Validator.validateCvv(inputs.cc_cvv.value)) {
            inputs.cc_cvv.error = 'Invalid CVV';
            errors.push(inputs.cc_cvv.error);
            valid = false;
        }
        if (!isValidZipCode(inputs.cc_zip.value, 'both')) {
            inputs.cc_zip.error = 'Invalid Zip / Postal Code';
            errors.push(inputs.cc_zip.error);
            valid = false;
        }

        this.setState({inputs});
        if (errors.length > 0) {
            this.props.updateMessageBlocks(errors, 'error');
        }

        return valid;
    };

    render() {
        return (
            <>
            <CreditCardForm
                name={this.state.inputs.cc_name}
                number={this.state.inputs.cc_number}
                expiration={this.state.inputs.cc_expiration}
                cvv={this.state.inputs.cc_cvv}
                zip={this.state.inputs.cc_zip}
                makePrimary={this.state.inputs.make_primary}
                required={true}
                inPopup={true}
                onChange={this.syncCreditCardValues}
            />
            <div className="spacing-30-top spacing-30-bottom type-normal-body">
                We will run an authorization to verify your payment method. If successful, the authorization will be
                immediately voided and nothing will be charged to your payment method at this time.
            </div>
            <div className="ui-hide-mobile">
                <div className="popup__form__gray-bar padding-24 spacing-50-top">
                    <div className="popup__form__row popup__form__row__slam-right">
                        <div className="popup__form__cell">
                            <span role="button"
                                className="type-small-body type-heavy type-blue"
                                onClick={() => this.props.updateModalContent()}
                            >
                                Cancel
                            </span>
                        </div>
                        <div className="popup__form__cell">
                            <button
                                className="button ui-normal-button qa-button-credit-card-submit"
                                onClick={() => this.handleSubmit()}
                                disabled={this.state.submitting}
                            >
                                Add Credit Card
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ui-hide-full ui-hide-tablet">
                <div className="popup__form__row padding-40-bottom">
                    <button
                        className="button ui-normal-button ui-full-width-button"
                        onClick={() => this.handleSubmit()}
                        disabled={this.state.submitting}
                    >
                        Add Credit Card
                    </button>
                </div>
            </div>
            </>
        );
    }
}


AddCreditCardModal.propTypes = {
    showModal: PropTypes.bool,
    updateAppCreditCards: PropTypes.func,
    updateMessages: PropTypes.func,
};
