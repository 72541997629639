import React from 'react';
import PropType from 'prop-types';

import FormModal from '../../../common/components/modal/FormModal';
import MapZipCodeSelection from "../../../common/MapZipCodeSelection";
import {isValidZipCode} from "../../../Util";

/**
 * Edit signup service areas modal
 */
export default class EditServiceAreaModal extends FormModal {
    cachedZips: {};

    constructor(props) {
        super(props);

        let zipCodes: string[] = Array.isArray(props.zipCodes)
            ? [...props.zipCodes]
            : props.zipCodes.split(/[\s,]+/);

        let cleanZips = [];
        zipCodes.forEach((zip) => {
            if (isValidZipCode(zip, this.props.country)) {
                cleanZips.push(zip.trim());
            }
        });

        this.state = {
            loading: true,
            zipCodes: cleanZips,
            mainZipCode: props.mainZipCode,
            radius: props.radius
        }
    }

    updateValue = (key, value, cachedZips = null) => {
        if (cachedZips) {
            this.cachedZips = cachedZips;
        }
        this.setState({[key]: value});
    };

    submitChanges = (event) => {
        event.preventDefault();

        let cities = [];
        if (this.cachedZips) {
            this.state.zipCodes.forEach((zip: string) => {
                // using hash object to prevent duplicate and stay performance conscious
                if (!cities.includes(this.cachedZips[zip].city)) {
                    cities.push(this.cachedZips[zip].city);
                }
            });

            // order the cities alphabetically
            cities.sort();
        }

        this.props.handleChange({
            zip_codes: {
                zips: this.state.zipCodes,
                cities
            },
            main_zip_code: this.state.mainZipCode,
            radius: this.state.radius
        });
    };

    render() {
        return <form onSubmit={(event) => this.submitChanges(event)}
                     className="padding-60-bottom">
            <div className="popup__form__cell popup__form__cell__100">
                <MapZipCodeSelection
                    zipCodes={this.state.zipCodes}
                    mainZipCode={this.state.mainZipCode}
                    radius={this.state.radius}
                    onValueChange={this.updateValue}
                    updateMessageBlocks={this.props.updateMessageBlocks}
                    country={this.props.country}
                    onFinishLoading={() => this.setState({loading: false})}
                    containerHeight="400px"
                />
            </div>
            <div className="zip-code-modal-footer padding-24">
                <button className="button ui-normal-button" type="button"
                        onClick={this.props.closeModal}>
                    Cancel
                </button>
                <button className="button ui-normal-button" type="submit"
                        disabled={this.state.zipCodes.length == 0 || this.state.loading}>
                    Save Service Area
                </button>
            </div>
        </form>;
    }
}

EditServiceAreaModal.propTypes = {
    closeModal: PropType.func.isRequired,
    updateMessageBlocks: PropType.func.isRequired,
    handleChange: PropType.func.isRequired,
    zipCodes: PropType.array,
    mainZipCode: PropType.string,
    radius: PropType.number
};
