export const questionsContent = [
    {
        question: 'What happens next?',
        answer: 'After you fill out your credit card details for verification, you will move to the Onboarding process. Your account will not be activated until we’ve confirmed your account is correctly set up and you understand how our Marketplace works.',
    },
    {
        question: 'What is Onboarding?',
        answer: 'Onboarding is a chance for us to make sure your account is set up properly, explain exactly how our Marketplace works, how to manage your account, and answer any questions you might have. We’ll also explain what constitutes a Billable Lead, how Leads are delivered, how to track your performance with us, and much more! ',
    },
    {
        question: 'Why do you need my credit card?',
        answer: 'When we deliver Leads to your business, your credit card on file is what we bill. During signup, your credit card is used for a temporary $200 authorization to verify you are an actual business with funds available to pay for Leads. <b>This amount will be immediately voided, meaning you will not pay anything to create your account.</b>',
    },
    {
        question: 'Will I be charged right now?',
        answer: 'No. In order to confirm your business has the funds available to pay for our Leads, we will perform an authorization on your credit card in the amount of $200. <b>This amount will be immediately voided, meaning you will not pay anything to create your account.</b>',
    },
    {
        question: 'What is a Billable Lead?',
        answer: 'A Billable Lead is a decision maker in your area who has a service need typically handled by providers in your Service Category(ies). Leads from solicitors, wrong numbers, etc. are not considered Billable and can be easily disputed within your mySD Account. We’ll be able to provide further insight and answer any questions you may have here during your Onboarding.',
    },
    {
        question: 'When will I start getting Leads?',
        answer: 'Your Account will not go live until you have completed your Onboarding. Once your Campaign(s) are live in our Marketplace you may receive your first Lead within a day or it may take considerably longer. It depends upon how many Leads are available in your Service Area and how competitive your Cost Per Lead is, all of which is discussed in your Onboarding.',
    },
    {
        question: 'How do Potential Customers contact me?',
        answer: 'Our Marketplace drives exclusive, inbound phone calls directly to your business. They call you directly at the exact moment they are searching for your services, meaning all you have to do is answer your phone and provide top-notch customer service.',
    },
    {
        question: 'Can I talk to someone first?',
        answer: 'Yes. If you would like to speak with someone right now, please feel free to call us at <a href="tel:5122336446" class="type-heavy">512-233-6446</a>. Or, after you enter your credit card information and verify your account you will have the opportunity to schedule a call with an Onboarding Specialist.',
    },
];