import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Nav = styled.ul`
  display: flex;
`;

export const Item = styled.li`
  cursor: pointer;
  display: flex;
  align-items: flex-end;
`;

export const Link = styled(NavLink)`
  cursor: pointer;
  max-width: ${props => props.width || 150}px;
  padding: 1.5em 1em 1em 1em;
  text-align: ${(props) => (props.align === "center" ? "center" : "left")};
  line-height: 120%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.align === "center" ? "center" : "flex-start"};
  align-items: flex-end;
  color: #0094d9;
  &.here {
    background-color: #0094d9;
    color: #fff;
  }
  &:hover {
    background-color: #006ea1;
    color: #fff;
  }
`;

export const SubNav = styled.ul`
  flex-direction: column;
`;

export const SubNavContent = styled.div`
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.07);
  min-width: 150px;
  background-color: #fff;
  flex-direction: column;
  position: absolute;
  display: ${(props) => (props.open ? "flex" : "none")};
`;
