import React, {memo, useState} from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Eye, EyeOff, SvgWrapper } from '../../Svgs'

const Adornment = styled.span`
    padding: ${({ padding }) => (padding ? `${padding}px` : 0)};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    /* background-color: ${({ readOnly }) => (readOnly ? 'none' : '#edf0f2')}; */
    background-color: #edf0f2;
    border: 1px solid ${(props) => (props.error ? '#e10008' : '#91abc6')};
    ${({ placement }) =>
        placement === 'right'
            ? css`
                  border-left: none;
              `
            : css`
                  border-right: none;
              `}
    ${({ readOnly }) =>
        readOnly
            ? css`
                  background-color: transparent;
                  border: none;
              `
            : null}
`

const InputWrapper = styled.div`
    display: flex;
    /* align-items: flex-end; */
    width: ${(props) => (props.width ? `${props.width}px` : null)};
`

export const Input = memo((props) => {
    const { valueType, valueSymbol, adornment, inputWidth, unit, readOnly } = props
    let inputClass = 'ui-text-field',
        labelClass = 'type-small-body type-narrow-line-height type-heavy spacing-5-bottom'

    if (props.hasError || props.errorMessage) {
        inputClass += ' ui-alert'
        labelClass += ' type-alert '
    }

    if (props.disabled) {
        inputClass += ' disabled'
        labelClass += ' disabled'
    }

    if (props.readOnly) {
        inputClass += ' ui-large-text-input read-only'
    }
    else {
        inputClass += ' ui-normal-text-input'
    }

    if (props.highlighted) {
        inputClass += ' highlighted'
    }

    const buildRequiredLabel = (label) => {
        let words = label.split(' ')
        const lastWord = words.pop()
        let otherWords = ''
        if (words.length > 0) {
            otherWords = words.join(' ')
        }

        return (
            <span>
                {otherWords + ' '}
                <span className="type-no-break">{lastWord}</span>
            </span>
        )
    }

    let inputMessageClass = 'type-small-body'
    let inputMessageText = ''
    // If using the errorMessage/statusMessage paradigm in props, output the input error/status div,
    // even if the text will be blank, in order to preserve vertical spacing assumptions in layout.
    if (props.errorMessage != null || props.statusMessage != null) {
        if (props.errorMessage != null && props.errorMessage.length > 0) {
            inputMessageClass += ' input-error'
            inputMessageText = props.errorMessage
        } else {
            inputMessageClass += ' input-status'
            inputMessageText = props.statusMessage // NB: The name of this variable suggests it is a string, but it can be a component (e.g. promo code status on MP Signup Step 4)
        }
    }
    const inputUnitLeftPadding = readOnly ? '0' : '10px'
    const inputUnitBottomPadding = readOnly ? '7px' : '5px'

    return (
        <div className={props.className}>
            {props.label && (
                <label
                    // ref={props.inputRef}
                    htmlFor={props.name}
                    className={labelClass}
                >
                    {props.required ? buildRequiredLabel(props.label) : props.label}
                    {props.subLabel && (
                        <>
                            &nbsp;
                            <span className="type-regular">{props.subLabel}</span>
                        </>
                    )}
                </label>
            )}
            <InputWrapper width={inputWidth}>
                {valueType === 'currency' && (
                    <Adornment onClick={() => null} error={props.hasError}>
                        {valueSymbol || '$'}
                    </Adornment>
                )}
                <input
                    type={props.type}
                    id={props.id || props.name || ''}
                    name={props.name}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    onChange={props.onChange}
                    onKeyUp={props.onKeyUp}
                    required={props.required}
                    className={inputClass}
                    onBlur={props.onBlur}
                    placeholder={props.placeholder}
                    ref={props.inputRef}
                    maxLength={props.maxLength}
                    readOnly={!!props.readOnly}
                    disabled={!!props.disabled}
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    data-hj-whitelist
                    autoComplete={props.autoComplete}
                />
                {valueType === 'percent' && (
                    <Adornment placement="right" error={props.hasError}>
                        {valueSymbol || '%'}
                    </Adornment>
                )}
                {unit && (
                    <span
                        className="type-normal-body"
                        style={{ padding: `0 0 ${inputUnitBottomPadding} ${inputUnitLeftPadding}`, alignSelf: 'flex-end' }}
                    >
                        {unit}
                    </span>
                )}
                {adornment}
            </InputWrapper>
            {inputMessageClass.length > 0 && (
                <div className={inputMessageClass}>{inputMessageText}</div>
            )}
        </div>
    )
})
Input.displayName = 'Input'
export default Input

export const HideableInput = (props) => {
    const [inputType, setInputType] = useState('password')
    const [inputAdornment, setInputAdornment] = useState(Eye)

    const EyeAdornment = (
        <Adornment
            readOnly={props.readOnly}
            padding={6}
            onClick={() => {
                if (inputType === 'password') {
                    setInputType('text')
                    setInputAdornment(EyeOff)
                } else {
                    setInputType('password')
                    setInputAdornment(Eye)
                }
            }}
            placement="right"
            error={props.hasError}
        >
            <SvgWrapper role="button" height={'25px'}>
                {inputAdornment}
            </SvgWrapper>
        </Adornment>
    )
    return <Input type={inputType} adornment={EyeAdornment} {...props} />
}

Input.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    inputRef: PropTypes.object,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onBlur: PropTypes.func,
    autoComplete: PropTypes.string,
    valueType: PropTypes.oneOf(['percent', 'currency']),
    valueSymbol: PropTypes.string,
    // step segment for type="number"
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    maxLength: PropTypes.number
}
