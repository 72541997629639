export const editAffiliateTooltips = {
    ad_network_name: ``,
    mp_phone_api_access: `When MP Phone Leads is enabled, the Lead Source can sell MP Phone Call Leads to Service Direct via DID or API. The Lead Source will be able to access the MP Phones API. When disabled, the Lead Source will no longer have access the MP Phones API and will receive an error message when trying to access.`,
    mp_phone_payout_model: `This setting determines how Service
    Direct will pay the Lead Seller
    Affiliates for purchase Leads. When an
    Lead Source is set to Revenue Share,
    Service Direct will pay the Affiliate
    for all Billable Leads. Furthermore,
    Lead Sources set to Revenue Share have
    a Locked MP Phone Multiplier. When an
    Lead Source is set to Duration Based,
    Service Direct will pay the Affiliate
    for all Leads that meet or exceed the
    Minimum Call Duration.`,
    minimum_valid_call_duration: `This change impacts all existing
    MP Campaigns and any created after
    the change. New Leads created
    after this change will use the new
    Minimum Call Duration in their
    Marketplace Cost Calculations. The
    change will not affect previously
    created Leads, their Cost is not
    updated.`,
    locked_mp_phone_bid_multiplier: `When this is set to Yes, Service
    Direct will use the Ad Nework's
    default MP Phone Bid Multiplier to
    calculate the Effective Bid Amount,
    instead of using the Campaign's Ad
    Network level MP Phone Bid Multiplier.
    I.e. The Campaign level Lead Source MP
    Phone Bid Multiplier cannot be
    changed.`,
    mp_phone_bid_multiplier: `For Lead Sources that have an Editable
    (ie. not Locked) MP Phone Bid
    Multiplier, this change does not
    update any existing MP Campaigns, only
    MP Campaigns that are created after
    this change. However, for Lead Sources
    with a Locked MP Phone Bid Multiplier,
    both existing and MP Campaigns to be
    created in the future will use this
    Bid Multiplier value.`,
    phone_numbers_limit: `This limit caps the number of Tracking Phone Numbers the system will automatically purchase for the Lead Source. I.e. it is the maximum number of Tracking Phone Numbers the system will keep in the Marketplace Phone Back for rotation for the Lead Source.`,
    disable_whisper_file: `This change impacts new Leads coming
    in after the change. Existing Leads
    are not impacted. This setting
    overrides the Contractor level Disable
    Call Recording Announcement setting.
    The Contractor level setting isn't
    changed, rather it is ignored when
    this Lead Source level setting is set
    to Yes.`,
    mp_phone_api_requires_auth: `Whether or not the affiliate has an
    authentication method set up with the
    Service Direct Phone System.`,
    mp_form_api_access: `When MP Form Leads is enabled, the Lead Source can sell MP Form Leads to Service Direct. The Lead Source will be able to access the MP Forms API. When disabled, the Lead Source will no longer have access the MP Forms API and will receive an error message when trying to access.`,
    default_mp_form_bid_multiplier: `For Lead Sources that have an Editable
    (i.e. not Locked) MP Form Bid
    Multiplier, this change does not
    update existing MP Campaigns, only MP
    Campaigns that are created after the
    change. However, for Lead Sources with
    a Locked MP Form Bid Multiplier, both
    existing and MP Campaigns to be
    created in the future will use this
    Bid Multiplier value.`,
    feed: `When Feed is enabled for an Ad
    Network, the Affiliate will be able to
    download the Feed file. When disabled,
    the Affiliate will no longer be able
    to download the Feed file.`,
    sem: `When this is set to Yes it means that
    the Lead Source is generating Leads
    directly via SEM activities rather
    than Leads being bought through other
    Affiliates.`,
    active: `When this is set to Yes it means
    the Lead Source is active and
    Service Direct is buying Leads
    from them. When this is set to No
    it means the Lead Source is
    inactive and Service Direct is not
    buying Leads from them altogether.`,
}

export const newAffiliateTooltips = {
    ad_network_name: `Provide a unique name for the Lead Source. 
    This name will be displayed throughout the Service Direct system.`,
    mp_phone_api_access: `When MP Phone Leads is enabled, the Lead Source can sell MP Phone Call Leads to Service Direct via DID or API. The Lead Source will be able to access the MP Phones API. When disabled, the Lead Source will no longer have access the MP Phones API and will receive an error message when trying to access.`,
    mp_phone_payout_model: `This setting determines how Service Direct will pay the Lead Seller Affiliates for purchased Leads. When an Lead Source is set to Revenue Share, Service Direct will pay the Affiliate for all Billable Leads. Payout or Revenue Share is determined using the Affiliate’s Default MP Phone Bid Multiplier, which is Locked in order to enforce a consistent Revenue Share rate on all MP Campaigns. When an Lead Source is set to Duration Based, Service Direct will pay the Affiliate for all Leads that meet or exceed the Minimum Call Duration.`,
    minimum_valid_call_duration: `When an Lead Source’s MP Phone Payout Model is set to Duration Based, this setting controls the Minimum Call Duration used to determine if Service Direct will pay the Lead Seller Affiliate for a purchased MP Phone Call Lead. This setting is not used when an Lead Source’s MP Phone Payout Model is set to Revenue Share.`,
    locked_mp_phone_bid_multiplier: `This setting shows whether or not the Default MP Phone Bid Multiplier can be changed. It is determined by the MP Phone Payout Model. When an Lead Source has a Locked MP Phone Multiplier, then the MP Phone Bid Multiplier value stored at the Campaign level cannot be changed`,
    mp_phone_bid_multiplier: `This setting determines the margin Service Direct will take out of a Campaign’s MP Phone CPL, and it is used to calculate the Effective Bid. E.g. If a MP Campaign CPL is $100 and the MP Phone Bid Multiplier is .6, then the Effective Bid is $60 (100 x .6), and Service Direct’s margin is $40.
    For Lead Sources with a Duration Based MP Phone Payout Model the MP Phone Bid Multiplier is editable. For Lead Sources with a Revenue Share MP Phone Payout Model it is Locked.
    `,
    phone_numbers_limit: `This limit caps the number of Tracking Phone Numbers the system will automatically purchase for the Lead Source. I.e. it is the maximum number of Tracking Phone Numbers the system will keep in the Marketplace Phone Back for rotation for the Lead Source.`,
    disable_whisper_file: `This setting determines if Service Direct’s Whisper File is played to callers at the beginning every Phone Call coming from this Lead Source. The Whisper File explains to the caller that the phone call is being recorded for quality control. Sometimes Lead Sources play their own recording and therefore playing Service Direct’s Whisper File is not needed.`,
    mp_phone_api_requires_auth: `This setting determines if the Lead Source needs to authenticate with the MP Phone API. Some Lead Sources - like those who use the Ringba platform - are not able to use our authentication scheme and this setting can disable the API requirement.`,
    mp_form_api_access: `When MP Form Leads is enabled, the Lead Source can sell MP Form Leads to Service Direct. The Lead Source will be able to access the MP Forms API. When disabled, the Lead Source will no longer have access the MP Forms API and will receive an error message when trying to access.`,
    default_mp_form_bid_multiplier: `This setting determines the margin Service Direct will take out of a Campaign’s MP Form CPL. and it is used to calculate the Effective Bid. E.g. If a MP Campaign Subcategory CPL is $100 and the MP Form Bid Multiplier is .6, then the Effective Bid is $60 (100 x .6), and Service Direct’s margin is $40.`,
    feed: `This setting determines if the Lead Source receives a Feed File of all MP Campaigns.`,
    sem: `This setting determines if the Lead Source is generating Leads directly via SEM activities and channels rather than buying Leads from other Affiliates`,
    active: ``,
}
