import axios from "axios";
import type {CancelTokenSource, AxiosResponse} from "axios";

import post, {get, put} from "../Requests";
import {formatDate, toCamelObject, toSnakeObject} from "../Util";

export default class CampaignService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal: CancelTokenSource;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    /**
     * update a campaign status
     * @param {function} updateMessageBlocks - function used to update the message block
     * @param {object} campaign - the campaign having its status updated
     * @param {string} desiredStatus - the new campaign status
     * @param {object} properties - extra properties to set along with the status
     * @param {function} callback - a function to execute once the API request was returned
     * @param {boolean} validationOnly - if true, will only validate the new status and properties
     * @return {Promise<AxiosResponse>}
     */
    updateStatus = (
        updateMessageBlocks,
        campaign,
        desiredStatus,
        properties,
        callback,
        validationOnly
    ): Promise<AxiosResponse> => {
        //
        // Get variables in order
        //
        validationOnly = !!validationOnly;
        let url = `campaigns/${campaign.campaign_id}/status`;

        // console.log('updateStatus properties', properties);
        let data = {
            status: desiredStatus,
            re_enable: properties.re_enable,
            pause_later: properties.pause_later,
            pause_date: properties.pause_date,
            validation_only: validationOnly,
        }
        if (desiredStatus === 'paused') {
            data.reason = properties.reason;
        }
        if (properties.re_enable_date) {
            data.re_enable_date = properties.re_enable_date;
        }

        // Clear message blocks at beginning of request
        // This way, if they submit twice and receive the same error message,
        // they will see the the message disappear and re-appear, so they will know
        // that the re-submit was attempted.
        updateMessageBlocks([], 'success');

        // Make the request
        return put(url, data, this.cancelSignal.token)
            .then((response) => {
                // Call a function the caller passed to be executed
                // upon request completion, e.g. re-enabling submit button
                if (typeof callback === 'function') {
                    callback(response);
                }

                // If this was just the initial click to the Confirmation Popup,
                // don't try to change the campaign status etc.
                if (validationOnly) {
                    return;
                }

                // Ad API error will result in our API returning a 500.
                // Axios interceptor will have displayed the message, but here,
                // avoid returning a campaign so the CampaignManagerView doesn't
                // try to do stuff with it.
                if (response.status === 500) {
                    console.log("500 in CampaignService");
                    return;
                }

                /**
                 * @var {object} - the campaign after alteration
                 */
                let campaign = response.data.data.campaign;

                let successMsg;

                if (properties.re_enable === 'on_date') {
                    if (desiredStatus === 'enabled') {
                        successMsg = "Your Campaign has been scheduled to enable on "
                            + formatDate(properties.re_enable_date) + ".";
                    }
                    else if (desiredStatus === 'paused') {
                        // if the campaign is schedule to pause now
                        if (properties.pause_date === '' && !properties.pause_later) {
                            successMsg = 'Your Campaign has been paused'
                        }
                        else {
                            // otherwise, show the scheduled pause date
                            successMsg = "Your Campaign has been scheduled to pause on "
                                + formatDate(properties.pause_date) + ".";
                        }
                    }
                }
                else {
                    successMsg = `"${campaign.campaign_name}" Campaign status updated successfully.`;
                }

                updateMessageBlocks(successMsg, 'success');

                return campaign
            })
            .catch((error) => {
                console.error(error)
                if (typeof callback === 'function') {
                    callback(error.response);
                }
            });
    };

    updateZipCodes = (campaignId, zipCodes) => {
        return put(`campaigns/${campaignId}/zip_codes`, {zip_codes: zipCodes})
            .then((response) => response.data.data.campaign);
    };
	
	getAdSchedule = (campaignId) => {
		return get(`campaigns/${campaignId}/ad_schedule`)
			.then((response) => response.data.data.campaign_schedule);
	};
	
	updateAdSchedule = (
		updateMessageBlocks,
		campaignId,
		payload,
        callback,
	): Promise<AxiosResponse> => {
		let url = `campaigns/${campaignId}/ad_schedule`;
		
		// Clear message blocks at beginning of request
		// This way, if they submit twice and receive the same error message,
		// they will see the the message disappear and re-appear, so they will know
		// that the re-submit was attempted.
		updateMessageBlocks([], 'success');
		
		return put(url, payload, this.cancelSignal.token)
			.then((response) => {
                // Call a function the caller passed to be executed
                // upon request completion, e.g. re-enabling submit button
                if (typeof callback === 'function') {
                    callback(response);
                }
                
				const primaryCampaign = response.data.data.primary_campaign;
				const allCampaigns = response.data.data.all_campaigns;
				
				const messages = [
					`${primaryCampaign.campaign_name} Campaign Status updated successfully.`
				];
				if (allCampaigns.length > 1) {
					messages.push(`Schedule was copied to ${allCampaigns.length - 1} other Campaign${(allCampaigns.length > 2) ? 's': ''}.`);
				}
				
				updateMessageBlocks(messages, 'success');
				
				return allCampaigns;
			})
			.catch((error) => {
				console.error(error);
			});
	};
	
	setAdSchedule = (campaignId, payload) => {
		return put(`campaigns/${campaignId}/ad_schedule`, payload)
			.then((response) => toCamelObject(response.data.data));
	};

    getCampaigns = (query) => {
        return get(`campaigns?${query}`, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data, false));
    };

    // getMissedOpportunities = (query) => {
    //     return get(`campaigns/missed_opportunities?${query}`, this.cancelSignal.token).then((response) =>
    //         // toCamelObject(response.data.data, false)
    //         response.data.data
    //     )
    // }

    adminGetCampaigns = (query) => {
        return get(`campaigns/admin/campaigns?${query}`, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data, false));
    };

    adminGetCampaign = (campaignId) => {
        return get('campaigns/admin/campaign/' + campaignId, this.cancelSignal.token)
    };

    //
    // MCO Endpoints
    //

    getMarketplaceCampaignUiDefinition = (query = "") => {
        const endpoint = `campaigns/admin/marketplace_campaigns/mco_ui_definition?${query}`
        return get(endpoint, this.cancelSignal.token)
    }

    getMarketplaceCampaignSettings = (query = "") => {
        const endpoint = `campaigns/admin/marketplace_campaigns/settings?${query}`
        return get(endpoint, this.cancelSignal.token)
    }

    getMarketplaceCampaignMetrics = (query = "") => {
        const endpoint = `campaigns/admin/marketplace_campaigns/metrics?${query}`
        return get(endpoint, this.cancelSignal.token)
    }

    getMarketplaceCampaignsSummary = (query = "") => {
        const endpoint = `campaigns/admin/marketplace_campaigns/summary?${query}`
        return get(endpoint, this.cancelSignal.token)
    }

    updateCampaignAdNetworks = ({ query, body }) => {
        const endpoint = `campaigns/admin/marketplace_campaigns?${query}`
        return post(endpoint, body, this.cancelSignal.token)
    }

    // end MCO endpoints

    updateCampaignSubcategories = (campaignId, subcategories) =>
        put(`campaigns/${campaignId}/subcategories`, {subcategories: toSnakeObject(subcategories)});

    adminGetCampaignForContractors = (contractorIds) => {
        let paramStr = '';
        if (contractorIds.length > 0) {
            paramStr = contractorIds.join("&contractor_ids[]=");
            paramStr = "contractor_ids[]=" + paramStr;
        }

        return get(
            'campaigns/admin/campaigns_for_contractors?' + paramStr,
            this.cancelSignal.token
        ).then((response) => response.data.data)
    }

    getMarketplaceCampaignEditProgress = (requestId) => {
        let endpoint = `campaigns/admin/marketplace_campaigns/bulk_edit_progress/${requestId}`
        return get(endpoint, this.cancelSignal.token)
    }

}
