import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    GoogleMap,
    LoadScript,
    Marker,
    Circle,
    InfoWindow,
    Polygon
} from "@react-google-maps/api";

import ZipCodeApiService, {ZipCode} from "../service/ZipCodeApiService";
import {errorHandler} from "../Requests";
import {isValidZipCode} from "../Util";
import TextArea from "./components/form_elements/TextArea";
import {LocationSvg, PlusSignSvg} from "./Svgs";
import LoadingGif from './components/LoadingGif';
import MultiSelectList from './components/form_elements/MultiSelectList';

// this declaration helps IDEs handle the external Google library
declare var window: Window |
    { google: { maps: { Geocoder: { geocode: () => {} } } }, visualViewport: { width: 0 } };

export default class MapZipCodeSelection extends Component {
    MILES_TO_METERS_MODIFIER = 1609.344;
    DEFAULT_ZOOM = 10;
    MAP_FETCH_ZIPS_RADIUS = 30;
    MAP_FETCH_ZIPS_DRAG_RADIUS = 10;

    // libraries loaded by Google's API
    libraries = ["places"];

    /** number of milliseconds to delay between executing the next fetch zip codes call on map drag  */
    MIN_DRAG_LEEWAY = 1000;

    /** reject function to cancel fetch zip code calls while dragging the map within the this.MIN_DRAG_LEEWAY */
    fetchCenterZipsCancel: (reason?: any) => void;

    mapContainerStyle = {
        height: "500px",
        width: "100%",
    };

    isMobile = (window.visualViewport
        ? window.visualViewport.width
        : window.innerWidth) <= 640;

    /** @property {boolean} if true, the user OS is iOS and a special CSS padding is added */
    isMobileIos = /(iPad|iPhone|iPod)/g.test(window.navigator.userAgent);

    options = {
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: 'greedy'
    };

    // cache are fetched zip codes so that we don't redundantly calculate and transfer the zip code shapes
    cachedZipCodes = {};

    radiusOptions = [
        {value: 1, text: '1 mile'},
        {value: 2, text: '2 miles'},
        {value: 5, text: '5 miles'},
        {value: 10, text: '10 miles'},
        {value: 20, text: '20 miles'},
        {value: 30, text: '30 miles'},
        {value: 40, text: '40 miles'},
        {value: 50, text: '50 miles'},
        {value: 60, text: '60 miles'},
        {value: 80, text: '80 miles'},
        {value: 100, text: '100 miles'}
    ];

    /** @property {array[]} - first item is radius, second item is zoom */
    zoomRatio = [
        [1, 14],
        [2, 13],
        [4, 12],
        [7, 11],
        [15, 10],
        [30, 9],
        [60, 8],
        [120, 7],
        [240, 6],
        [315, 5]
    ];

    constructor(props) {
        super(props);

        if (props.containerHeight) {
            this.mapContainerStyle.height = props.containerHeight;
        }

        let newZipCodeSelection = props.zipCodes.length == 0;
        this.state = {
            newZipCodeSelection: newZipCodeSelection,
            mainZipCode: props.mainZipCode,
            radius: props.radius || this.MAP_FETCH_ZIPS_RADIUS,
            manualZips: '',
            manualZipsErrorMessage: '',

            zoom: this.zoomOfRadius(
                newZipCodeSelection
                    ? null
                    : (props.radius || this.MAP_FETCH_ZIPS_RADIUS)
            ),
            center: this.startingCoordinates,
            mousePosition: this.startingCoordinates,

            /** @property {ZipCode[]} the collection of visible zip codes markers */
            markers: [],

            /** @property {ZipCode} the currently selected marker - displayed tooltip */
            selected: null,

            /** @property {boolean} when true, means that the zip codes were fetched based on current variables (main zip code and radius) */
            alreadyGotZips: false,

            /** @property {boolean} when true, in the process of getting the zips from the server */
            gettingZips: false,

            /** @property {boolean} when true, in the process of getting zips from the server after a map drag */
            fetchingZipsOnDrag: false,

            findingLocation: false,
            displayManualZips: false,
            processingManuallyAddedZipCodes: false,

            mainZipCodeErrorMessage: props.mainZipCodeErrorMessage,
            zipCodesErrorMessage: props.zipCodesErrorMessage,
            radiusErrorMessage: ''
        };

        this.mapRef = React.createRef();
        this.zipCodeApiService = new ZipCodeApiService();
    }

    get isCanada() {
        return this.props.country === 'CAN';
    }

    get validCountry() {
        return this.isCanada
            ? 'CA'
            : 'US';
    }

    get zipCodeLabel() {
        return this.isCanada
            ? 'Postal Code'
            : 'Zip Code';
    }

    get startingCoordinates() {
        return this.isCanada
            ? {
                lat: 56.1304,
                lng: -106.3468
            }
            : {
                lat: 37.0902,
                lng: -95.7129
            };
    }

    componentDidMount() {
        // we use a promise to perform the two API requests simultaneously, while not overwriting the user selection
        let getZipCodesCoordinates$: Promise;
        let centerZip = this.getCenterZip();
        if (this.props.zipCodes && this.props.zipCodes.length > 0) {
            getZipCodesCoordinates$ = this.zipCodeApiService
                .getZipCodesCoordinates(this.props.zipCodes, centerZip)
                .then(({zipCodes}) => this.setAndSortMarkers(zipCodes));
        }
        else {
            getZipCodesCoordinates$ = Promise.resolve();
        }

        // if the main zip code and radius are set on "return to step two scenario", get the default "within radius" zips
        if (centerZip && this.state.radius > 0) {
            this.setState({alreadyGotZips: true, gettingZips: true});
            this.zipCodeApiService.getZipCodesByRadius(centerZip, this.state.radius)
                .then((markers: ZipCode[]) => {
                    getZipCodesCoordinates$.then(
                        () => {
                            this.setAndSortMarkers(markers, true);
                            this.setState({gettingZips: false});
                        }
                    );
                })
                .catch((error) => {
                    if (errorHandler(error)) {
                        this.setState({alreadyGotZips: false, gettingZips: false});
                        console.log(error);
                    }
                });
        }
    }

    componentWillUnmount() {
        this.zipCodeApiService.cancelSignal.cancel();
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        if (prevProps.zipCodesErrorMessage != this.props.zipCodesErrorMessage) {
            this.setState({zipCodesErrorMessage: this.props.zipCodesErrorMessage});
        }

        if (prevProps.mainZipCodeErrorMessage != this.props.mainZipCodeErrorMessage) {
            this.setState({mainZipCodeErrorMessage: this.props.mainZipCodeErrorMessage});
        }

        if (prevProps.country != this.props.country) {
            this.setState({
                center: this.startingCoordinates,
                mousePosition: this.startingCoordinates,
            });
        }
    }

    /**
     * executes when the map finishes loading the `window.google` SDK is available
     */
    googleMapsLoaded() {
        this.getLatLngFromZipCode(this.getCenterZip());
        if (typeof this.props.onFinishLoading == 'function') {
            this.props.onFinishLoading();
        }
    }

    getCenterZip = () => {
        if (this.state.mainZipCode) {
            return this.state.mainZipCode;
        }
        if (this.props.mainZipCode) {
            return this.props.mainZipCode;
        }
        if (this.state.markers.length > 0) {
            return this.state.zipCodes[0];
        }
        if (this.props.zipCodes.length > 0) {
            return this.props.zipCodes[0];
        }

        return '';
    };

    /**
     * checks if the map is enabled
     * @param {boolean} [buttonOnly=false] - if set to true, will ignore the map got zips check
     * @return {boolean}
     */
    mapEnabled = (buttonOnly: boolean = false) => {
        if (this.props.clean) {
            return true;
        }

        // radius is set and there's no radius error
        return this.state.radius && !this.state.radiusErrorMessage &&
            (
                (
                    // main zip code is set and there's no main zip code error
                    this.state.mainZipCode && !this.state.mainZipCodeErrorMessage &&

                    // and button only or already got zips
                    (buttonOnly || this.state.alreadyGotZips)
                ) ||
                !this.state.newZipCodeSelection
            );
    };

    /**
     * handles a map zoom change event firing
     */
    handleZoomChange = () => {
        if (!this.mapRef.current) {
            return;
        }

        this.setState({zoom: this.mapRef.current.getZoom()});
    };

    /**
     * handles drag end event firing
     */
    handleDragEnd = () => {
        if (!this.mapRef.current || this.state.zoom < this.DEFAULT_ZOOM) {
            return;
        }

        this.getZipsOfCenter();
    };

    /**
     * retrieves the zip shapes of surrounding the map center
     */
    getZipsOfCenter = () => {
        // if the function is called again within the leeway, cancel the previous call
        if (this.fetchCenterZipsCancel) {
            this.fetchCenterZipsCancel();
        }

        let center = this.mapRef.current.getCenter();
        let _resolve = () => null;
        let readyToFetchZips$ = new Promise(
            (resolve, reject) => {
                _resolve = resolve;
                this.fetchCenterZipsCancel = reject;
            },
        );
        setTimeout(_resolve, this.MIN_DRAG_LEEWAY);

        this.setState({fetchingZipsOnDrag: true});
        this.getZipCodeFromLatLng(
            center.lat(),
            center.lng(),
            (zipCode: ZipCode) => {
                if (!zipCode) {
                    this.setState({fetchingZipsOnDrag: false});
                    return;
                }

                readyToFetchZips$
                    .then(
                        () =>
                            this.zipCodeApiService.getZipCodesByRadius(
                                zipCode,
                                this.MAP_FETCH_ZIPS_DRAG_RADIUS,
                                Object.keys(this.cachedZipCodes)
                            )
                                .then((zipCodes: ZipCode[]) => {
                                    // first set all found zip codes as not chosen
                                    zipCodes.map((zipCode: ZipCode) => zipCode.chosen = false);
                                    this.setAndSortMarkers(zipCodes, true);
                                })
                                .catch((error) => {
                                    if (errorHandler(error)) {
                                        this.props.updateMessageBlocks(error.response.data.message, 'error');
                                        console.log(error);
                                    }
                                })
                                .finally(() => this.setState({fetchingZipsOnDrag: false}))
                    )
                    .catch(errorHandler);
            }
        );
    };

    /**
     * sets the current map zoom
     * also fetches the zips surrounding the current map center
     * @param {number} [newZoom=this.DEFAULT_ZOOM] - the distance to set the map's zoom to
     */
    setZoom = (newZoom = this.DEFAULT_ZOOM) => {
        this.getZipsOfCenter();
        this.setState({zoom: newZoom});
    };

    /**
     * resets the current zip codes selection and clears all data
     */
    reset = () => {
        if (!window.confirm(`Are you sure you want to clear all ${this.zipCodeLabel}s, start over, using a new radius?`)) {
            return;
        }

        this.props.onValueChange('mainZipCode', '');
        this.props.onValueChange('radius', this.MAP_FETCH_ZIPS_RADIUS);
        this.props.onValueChange('zipCodes', []);

        this.setState({
            newZipCodeSelection: true,
            mainZipCode: '',
            radius: this.MAP_FETCH_ZIPS_RADIUS,
            manualZips: '',
            manualZipsErrorMessage: '',

            zoom: this.zoomOfRadius(this.MAP_FETCH_ZIPS_RADIUS),

            /** @property {ZipCode[]} the collection of visible zip codes markers */
            markers: [],

            /** @property {ZipCode} the currently selected marker - displayed tooltip */
            selected: null,

            /** @property {boolean} when true, means that the zip codes were fetched based on current variables (main zip code and radius) */
            alreadyGotZips: false,

            /** @property {boolean} when true, in the processes of getting the zips from the server */
            gettingZips: false,

            findingLocation: false,
            displayManualZips: false,
            processingManuallyAddedZipCodes: false,

            mainZipCodeErrorMessage: '',
            zipCodesErrorMessage: '',
            radiusErrorMessage: ''
        });
    };

    /**
     * updates the main zip code in the state as well as the parent component
     * @param {string} mainZipCode - the zip code to update
     * @param {boolean} [fetchLatLng=true] - if false, will not update the latitude and longitude
     */
    updateMainZipCode = (mainZipCode: string, fetchLatLng: boolean = true) => {
        if (!mainZipCode) {
            return;
        }

        this.props.onValueChange('mainZipCode', mainZipCode);
        if (fetchLatLng) {
            this.getLatLngFromZipCode(mainZipCode);
        }
        this.setState({mainZipCode, mainZipCodeErrorMessage: ''});
    };

    /**
     * sort given markers and set them in the state
     * @param {ZipCode[]} markers - the zip codes to set/add to the list of selected markers
     * @param {boolean} [append=false] - if set to true, will append the values instead of replacing
     */
    setAndSortMarkers = (markers: ZipCode[], append = false) => {
        if (append) {
            // to prevent duplicates, we filter by existence of the new zip in the current set
            markers = markers.filter((newZip: ZipCode) =>
                !this.state.markers.find((zip: ZipCode) => {
                    if (zip.zip == newZip.zip) {
                        zip.chosen = newZip.chosen || zip.chosen;
                    }
                    return zip.zip == newZip.zip;
                }))
                .concat(this.state.markers);
        }

        // sort the zip codes by ascending order (e.g. 78704, 78705, 78750, ...)
        markers.sort((a, b) => Number(a.zip) - Number(b.zip));
        markers.forEach((zip: ZipCode) => {
            if (!this.cachedZipCodes[zip.zip]) {
                this.cachedZipCodes[zip.zip] = zip;
            }

            // if the shape is empty, fetch the shape, latitude, and longitude from the cache
            if (!zip.shape) {
                zip.shape = this.cachedZipCodes[zip.zip].shape;
                zip.latitude = this.cachedZipCodes[zip.zip].latitude;
                zip.longitude = this.cachedZipCodes[zip.zip].longitude;
            }

            if (zip.shape && !zip.paths) {
                zip.paths = [];
                if (zip.shape['MULTIPOLYGON']) {
                    zip.shape['MULTIPOLYGON'].forEach((polygon) => {
                        let parsedShape = [];
                        polygon.forEach((shape) => {
                            parsedShape.push({
                                lat: shape[0],
                                lng: shape[1]
                            });
                        });
                        zip.paths.push(parsedShape);
                    });
                }
                else {
                    zip.shape.forEach((shape) => {
                        zip.paths.push({
                            lat: shape[0],
                            lng: shape[1]
                        });
                    });
                }
            }
        });

        let zipCodes = [];
        markers.forEach((zip: ZipCode) => {
            if (zip.chosen) {
                zipCodes.push(zip.zip);
            }
        });
        this.props.onValueChange('zipCodes', zipCodes, this.cachedZipCodes);
        this.setState({markers, selected: null});
    };

    /**
     * centers the map on the user current real world coordinates
     */
    setCurrentLocation = () => {
        this.setState({findingLocation: true});
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.setState({
                    center: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    zoom: this.zoomOfRadius(this.state.radius),
                    alreadyGotZips: false
                });
                this.getZipCodeFromLatLng(
                    position.coords.latitude,
                    position.coords.longitude,
                    this.updateMainZipCode
                );
            }
            ,
            (error) => {
                this.setState({findingLocation: false});
                console.log('Could not get the current position:', error);
            }
        );
    };

    /**
     * converts latitude and longitude to zip code
     * @param {number} lat
     * @param {number} lng
     * @param {function} callback
     */
    getZipCodeFromLatLng(lat, lng, callback: (zipCode: ZipCode, fetchLatLng: boolean) => void) {
        const geocoder = new window.google.maps.Geocoder();

        const latLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        };

        // TODO: use our own system for this after we update our DB
        geocoder.geocode(
            {location: latLng},
            (results: any[], status: string) => {
                this.setState({findingLocation: false});

                if (status === "OK") {
                    if (!results[0]) {
                        alert(`Did not find a ${this.zipCodeLabel} associated with this location.\n` +
                            'Try a different location');
                        return;
                    }
                    let result = results[0];
                    let zipCode;
                    let index;

                    for (index = 0; index < result.address_components.length; index++) {
                        let {long_name, short_name, types} = result.address_components[index];

                        // if not in the US, return as if no result was found
                        if (types.includes('country') && this.validCountry !== short_name.toUpperCase()) {
                            console.log('Location not in a supported country');
                            callback(false);
                            return;
                        }
                        if (types.includes("postal_code")) {
                            zipCode = long_name;
                        }
                    }

                    if (!zipCode) {
                        callback(false);
                        alert(`no ${this.zipCodeLabel} in your location error message`);
                        return;
                    }

                    callback(zipCode, false);
                }
                else {
                    console.log(`Geocoder failed finding latLng ${JSON.stringify(latLng)} due to: ${status}`);
                }
            }
        );
    }

    /**
     * converts zip code to latitude and longitude
     * @param {string} zipCode
     */
    getLatLngFromZipCode(zipCode) {
        if (!isValidZipCode(zipCode, this.props.country)) {
            return;
        }

        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode(
            {componentRestrictions: {postalCode: zipCode, country: this.validCountry}},
            async (results: any[], status) => {
                this.setState({findingLocation: false});

                if (status === "OK") {
                    if (!results[0]) {
                        alert(`No location with association to your ${this.zipCodeLabel} found`);
                        return;
                    }
                    let result = results[0];

                    let latLng = {
                        lat: result.geometry.location.lat(),
                        lng: result.geometry.location.lng()
                    };
                    this.mapRef.current.panTo(latLng);
                    this.setState({
                        center: latLng,
                        zoom: this.zoomOfRadius(this.state.radius)
                    });
                }
                else {
                    console.log(`Geocoder failed finding the zip ${zipCode} due to: ${status}`);
                }
            }
        );
    }

    /**
     * toggle a zip code selection and update the parent component of the change
     * @param {MouseEvent} event
     * @param {ZipCode} marker
     */
    toggleZipCode = (event, marker) => {
        // in case there's an issue with the Polygon event of the @react-google-maps library
        if (event && typeof event.stopPropagation == 'function') {
            event.stopPropagation();
        }

        let {markers} = this.state;
        let index = markers.indexOf(marker);
        markers[index].chosen = !markers[index].chosen;
        this.setAndSortMarkers(markers);
    };

    /**
     * sets the selected marker
     * @param {ZipCode} selected
     */
    setSelected = (selected) => {
        this.setState({selected})
    };

    /**
     * handles change of the radius field
     * @param {Event} event
     */
    handleMainZipCodeChange = (event) => {
        this.setState({
            mainZipCode: event.target.value,
            mainZipCodeErrorMessage: '',
            alreadyGotZips: false
        });
    };

    /**
     * handles change of the radius field
     * @param {number[]} values
     */
    handleRadiusChange = (values) => {
        let radius = Number(values[0]);

        if (radius <= 0 || !isValidZipCode(this.state.mainZipCode, this.props.country)) {
            this.setState({radius});
            return;
        }

        this.props.onValueChange('radius', radius);

        let zoom = this.zoomOfRadius(radius);

        this.setState({
            radius,
            zoom,
            radiusErrorMessage: '',
            alreadyGotZips: false
        });
    };

    /**
     * finds the best fitting zoom for a given radius
     * @param {number} radius
     * @return {number}
     */
    zoomOfRadius(radius: number) {
        // if non supplied, zoom to the US
        if (!radius) {
            return 4;
        }

        // find the smallest zoom that is still larger than the radius
        for (let i = 0; i < this.zoomRatio.length; i++) {
            if (this.zoomRatio[i][0] >= radius) {
                return this.zoomRatio[i][1];
            }
        }

        // default zoom to maximum
        return this.zoomRatio[this.zoomRatio.length - 1][1];
    }

    /**
     * sets the zip code shape hover state
     * @param marker
     * @param state
     */
    setHover = (marker: ZipCode, state: boolean) => {
        let {markers} = this.state;
        markers.forEach((aMarker: ZipCode) => {
            aMarker.hover = state && aMarker === marker;
        });
        this.setState({markers});
    };

    /**
     * gets a given area <Polygon> options
     * @param {ZipCode} marker
     * @return {{fillOpacity: number, strokeOpacity: number}}
     */
    areaOptions(marker: ZipCode) {
        let isHover = marker.hover || 0;
        let isChosen = marker.chosen || 0;

        return {
            fillOpacity: 0.02 + isHover * 0.15 + isChosen * 0.25,
            strokeOpacity: 0.3 + (isHover + isChosen) * 0.35,
            strokeWeight: 1
        };
    }

    /**
     * handles changing of the manual zip code field
     * @param {MouseEvent} event
     */
    handleManualZipsChange = (event) => {
        this.setState({
            manualZips: event.target.value,
            manualZipsErrorMessage: ''
        });
    };

    /**
     * cancels adding manual zips; clears the data and closes the text area
     */
    cancelManualZips = () => {
        this.setState({
            manualZips: '',
            manualZipsErrorMessage: '',
            displayManualZips: false
        });
    };

    /**
     * adds the manually filled zip codes to the list of selected markers
     */
    addManualZips = () => {
        this.setState({processingManuallyAddedZipCodes: true});

        let zipCodes: string[] = this.parseZipCodes(this.state.manualZips);
        let filteredZipCodes: Object = this.validateZipCodes(zipCodes);
        let {markers}: ZipCode[] = this.state;
        let newZips: string[] = [];
        filteredZipCodes.valid.forEach((validZip: string) => {
            let newZip = markers.find((zip: ZipCode) => zip.zip == validZip);
            if (!newZip || !newZip.chosen) {
                newZips.push(validZip);
            }
        });

        if (newZips.length > 0) {
            this.zipCodeApiService.getZipCodesCoordinates(newZips, this.state.mainZipCode)
                .then((data) => {
                    this.setAndSortMarkers(data.zipCodes, true);
                    filteredZipCodes.invalid = filteredZipCodes.invalid.concat(data.invalidZipCodes);

                    this.setState({
                        manualZips: filteredZipCodes.invalid.join(', '),
                        manualZipsErrorMessage: filteredZipCodes.invalid.length > 0 &&
                            `Invalid ${this.zipCodeLabel}s: ${filteredZipCodes.invalid.join(', ')}`,
                        displayManualZips: filteredZipCodes.invalid.length > 0,
                        processingManuallyAddedZipCodes: false
                    });
                });
        }
        else {
            this.setState({
                manualZips: filteredZipCodes.invalid.join(', '),
                manualZipsErrorMessage: filteredZipCodes.invalid.length > 0 &&
                    `Invalid ${this.zipCodeLabel}s: ${filteredZipCodes.invalid.join(', ')}`,
                processingManuallyAddedZipCodes: false
            });
        }
    };

    /**
     * parses zip codes from string to clean array (does not remove invalid ones)
     * e.g. "78750, 78758,  \n 22222 err" => ["78750", "78758", "22222", "err"]
     * @param {string} zipCodes
     * @return {string[]}
     */
    parseZipCodes(zipCodes): string[] {
        // split by commas, new line, and spaces
        let parsedZipCodes = zipCodes.replace(/(\r?\n)/gm, ',').split(/,| +/);

        // remove empty values
        parsedZipCodes = parsedZipCodes.filter((zip) => zip);

        // trim each zip code
        return parsedZipCodes.map((zip) => zip.trim());
    }

    /**
     * returns an object with
     * @param {string[]} zipCodes
     * @return {Object} key "invalid" - array of strings of the invalid zip codes
     *      key "valid" - array of strings of the valid zip codes
     */
    validateZipCodes(zipCodes): string[] {
        let result = {
            invalid: [],
            valid: []
        };
        zipCodes.forEach((zip) => {
            if (isNaN(Number(zip)) || zip.length != 5) {
                result.invalid.push(zip);
            }
            else {
                result.valid.push(zip);
            }
        });

        return result;
    }

    /**
     * executes the getting of the zip codes when the user presses enter while in the radius
     * @param {KeyboardEvent} event
     */
    enterClicked = (event: KeyboardEvent) => {
        if (event.key == 'Enter') {
            event.target.name == 'radius'
                ? this.findZipCodes()
                : this.updateMainZipCode(this.state.mainZipCode);
        }
    };

    /**
     * gets the zip codes within the radius of the centered zip code
     */
    findZipCodes = () => {
        let data = {};
        if (!isValidZipCode(this.state.mainZipCode, this.props.country)) {
            data.mainZipCodeErrorMessage = `${this.zipCodeLabel} required for search`;
        }

        if (this.state.radius <= 0) {
            data.radiusErrorMessage = 'Radius required for search';
        }

        if (this.state.radius > 100) {
            data.radiusErrorMessage = 'Max radius 100 miles';
        }

        if (Object.keys(data).length > 0) {
            this.setState(data);
            return;
        }

        this.setState({alreadyGotZips: true, gettingZips: true});

        this.zipCodeApiService.getZipCodesByRadius(
            this.state.mainZipCode,
            this.state.radius,
            Object.keys(this.cachedZipCodes),
            true
        )
            .then((zipCodes: ZipCode[]) => {
                this.setAndSortMarkers(zipCodes);

                this.mapRef.current.panTo(this.state.center);
                this.setState({
                    zoom: this.zoomOfRadius(this.state.radius),
                    gettingZips: false
                });
            })
            .catch((error) => {
                this.setState({alreadyGotZips: false, gettingZips: false});
                if (errorHandler(error)) {
                    this.props.updateMessageBlocks(error.response.data.message, 'error');
                    console.log(error);
                }
            });
    };

    render() {
        return <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                           libraries={this.libraries}
                           onLoad={() => this.googleMapsLoaded()}
                           onError={(error) => console.log(error)}>
            {!this.props.clean &&
            <h3 className="form-segment-header spacing-30-bottom type-large-body">
                Your Service Area
            </h3>}
            {this.state.newZipCodeSelection &&
            <>
                {!this.props.clean &&
                <div className="spacing-30-bottom type-normal-body">
                    <p className="spacing-10-bottom">Define your Service Area with Starting {this.zipCodeLabel} and
                        Radius.</p>
                    <p>If needed, refine your Service Area - add or remove {this.zipCodeLabel} Areas from the map by
                        clicking on
                        them.</p>
                </div>}
                <div className="form__row spacing-24-bottom">
                    <div className="form__cell form__cell__25 form__cell__100__mobile">
                        <label htmlFor="mainZipCode"
                               className={'type-small-body type-narrow-line-height type-heavy spacing-5-bottom' +
                               (this.state.mainZipCodeErrorMessage ? ' type-alert' : '')}>
                            Starting {this.zipCodeLabel}
                        </label>
                        <div className="location-input-container">
                            <input name="mainZipCode" id="mainZipCode"
                                   className={'ui-text-field ui-normal-text-input' + (this.state.mainZipCodeErrorMessage ? ' ui-alert' : '')}
                                   type="text"
                                   onKeyUp={this.enterClicked}
                                   value={this.state.mainZipCode}
                                   onBlur={(event) => this.updateMainZipCode(event.target.value)}
                                   onChange={this.handleMainZipCodeChange}/>
                        </div>
                        <div className="spacing-5-top">
                            {this.state.findingLocation
                                ? <LoadingGif/>
                                : <button className="button-clean type-blue padding-5 type-small-body"
                                          onClick={this.setCurrentLocation} type="button">
                                    <span
                                        className="inline-icon inline-icon__middle inline-icon__20">{LocationSvg}</span>
                                    Use my location
                                </button>}
                        </div>
                        <div className="input-error">{this.state.mainZipCodeErrorMessage}</div>
                    </div>
                    <div className="form__cell form__cell__25 form__cell__100__mobile form__cell_start">
                        <MultiSelectList
                            name="radius"
                            label="Radius"
                            hasError={!!this.state.radiusErrorMessage}
                            options={this.radiusOptions}
                            onChange={this.handleRadiusChange}
                            selections={[this.state.radius]}
                        />
                        <div className="input-error">{this.state.radiusErrorMessage}</div>
                    </div>

                    <div className="form__cell form__cell__50 form__cell__100__mobile form__cell_start">
                        <div>
                            {this.state.gettingZips
                                ? (<div className="spacing-24-top-full spacing-24-top-tablet">
                                    <LoadingGif/>
                                </div>)
                                : <button
                                    disabled={(!this.props.clean && this.state.alreadyGotZips) || !this.mapEnabled(true)}
                                    type="button"
                                    title={this.state.alreadyGotZips ? `Change your Starting ${this.zipCodeLabel} or Radius to enable` : ''}
                                    className={'button ui-normal-button spacing-24-top-full spacing-24-top-tablet ui-full-width-button-mobile' +
                                    (this.mapEnabled(true) ? '' : ' not-allowed')}
                                    onClick={this.findZipCodes}>
                                    Find {this.zipCodeLabel}s
                                </button>}
                        </div>
                    </div>
                </div>
            </>}
            <div className="form__row">
                <div className={'form__cell form__cell__100' + (this.mapEnabled() ? '' : ' type-grayed')}>
                    <GoogleMap
                        id="serviceAreaMap"
                        mapContainerStyle={this.mapContainerStyle}
                        zoom={this.state.zoom}
                        onZoomChanged={this.handleZoomChange}
                        onDragEnd={this.handleDragEnd}
                        center={this.state.center}
                        options={this.options}
                        onLoad={(map) => this.mapRef.current = map}>
                        {this.state.mainZipCode &&
                        <>
                            {!this.state.alreadyGotZips &&
                            <Circle center={{lat: this.state.center.lat, lng: this.state.center.lng}}
                                    radius={this.state.radius * this.MILES_TO_METERS_MODIFIER}
                                    options={{fillColor: '#f6891e', strokeColor: '#f6891e'}}
                            />}
                            <Marker position={{lat: this.state.center.lat, lng: this.state.center.lng}}
                                    title={this.state.mainZipCode}
                                    zIndex={2}
                                    icon='https://images.servicedirect.com/images/shared/mysd/map-marker.png'/>
                        </>}

                        {this.mapEnabled()
                            ? (this.state.zoom < this.DEFAULT_ZOOM &&
                                <button className="map-message map-message--top button-clean" type="button"
                                        onClick={() => this.setZoom()}>
                                    Click here to load more {this.zipCodeLabel}s in current view
                                </button>)
                            : <div className="map-message-container type-centered">
                                <div className="map-message">Enter {this.zipCodeLabel}, Select Radius, Click
                                    "Find {this.zipCodeLabel}s"
                                </div>
                            </div>}

                        {this.state.fetchingZipsOnDrag && (
                            <div className="fetching-zips-on-drag-loader">
                                <LoadingGif/>
                            </div>
                        )}

                        {this.state.gettingZips &&
                        <div className="map-message-container filled">
                            <LoadingGif size='large'/>
                        </div>}

                        {this.state.markers.map((marker: ZipCode, index) =>
                            <React.Fragment key={index}>
                                {marker.shape &&
                                <>
                                    <Polygon paths={marker.paths} options={this.areaOptions(marker)}
                                             onMouseOver={() => this.setHover(marker, true)}
                                             onMouseOut={() => this.setHover(marker, false)}
                                             onMouseMove={(event) => this.setState({mousePosition: event.latLng})}
                                             onClick={(event) => this.isMobile
                                                 ? this.setSelected(marker)
                                                 : this.toggleZipCode(event.vb, marker)}/>
                                    {!this.isMobile && marker.hover &&
                                    <InfoWindow position={this.state.mousePosition}
                                                options={
                                                    {
                                                        hasCloseButton: false,
                                                        pixelOffset: {width: 0, height: -20},
                                                        disableAutoPan: true
                                                    }
                                                }>
                                        <div className="type-centered type-normal-body">
                                            Click to{' '}
                                            {marker.chosen
                                                ? <span className="type-red">remove</span>
                                                : <span className="type-green">add</span>}
                                            {' '}
                                            the {this.zipCodeLabel}:
                                            <br/>
                                            {marker.zip}, {marker.city}, {marker.state}
                                            {marker.distance > 0 &&
                                            <>
                                                <br/>
                                                {marker.distance} miles away
                                            </>}
                                        </div>
                                    </InfoWindow>}
                                </>}
                            </React.Fragment>)}

                        {this.isMobile && this.state.selected &&
                        <InfoWindow onCloseClick={() => this.setSelected(null)}
                                    position={{
                                        lat: this.state.selected.latitude,
                                        lng: this.state.selected.longitude
                                    }}>
                            <div
                                className={'type-centered type-normal-body' + (this.isMobileIos ? ' info-window-ios' : '')}>
                                {this.state.selected.zip}, {this.state.selected.city}, {this.state.selected.state}
                                {this.state.selected.distance &&
                                <>
                                    <br/>
                                    {this.state.selected.distance} miles away
                                </>}
                                <div className="spacing-10-top">
                                    {this.state.selected.chosen
                                        ? <button className="button-clean type-red type-normal-body" type="button"
                                                  onClick={(event) =>
                                                      this.toggleZipCode(event, this.state.selected)}>
                                            Remove {this.zipCodeLabel}
                                        </button>
                                        : <button className="button-clean type-blue type-normal-body" type="button"
                                                  onClick={(event) =>
                                                      this.toggleZipCode(event, this.state.selected)}>
                                            Add {this.zipCodeLabel}
                                        </button>}
                                </div>
                            </div>
                        </InfoWindow>}
                    </GoogleMap>
                </div>
            </div>
            <div className="form__row spacing-10-top">
                <div className="form__cell form__cell__100 type-small-body">
                    <p className="type-heavy type-large-body spacing-20">
                        {this.state.markers.filter((zip: ZipCode) => zip.chosen).length}{' '}
                        Selected {this.zipCodeLabel}s
                        {this.state.markers.filter((zip: ZipCode) => zip.chosen).length == 0 &&
                        <span
                            className="type-red type-regular"> You must enter at least one {this.zipCodeLabel} to save</span>}
                    </p>
                    <p>
                        {this.state.markers.map((marker: ZipCode, index) =>
                            marker.chosen &&
                            <span className="zip-badge" key={index}
                                  title={`Center on ${marker.zip}, ${marker.city}, ${marker.state}`}
                                  onClick={() => {
                                      // if the zip has a shape, select said shape; i.e. display the tooltip
                                      if (marker.shape) {
                                          this.setSelected(marker);
                                      }
                                      this.mapRef.current.panTo({lat: marker.latitude, lng: marker.longitude});
                                  }}>
                                {marker.zip}
                                <span className="close" onClick={(event) =>
                                    this.toggleZipCode(event, marker)} title="Remove">x</span>
                            </span>)}
                    </p>
                    <div className="input-error">{this.state.zipCodesErrorMessage}</div>
                </div>
            </div>
            <div className="spacing-24">
                {this.state.displayManualZips
                    ? <div className="type-align-right">
                        <p className="type-align-left type-normal-body spacing-10">
                            Enter {this.zipCodeLabel}s separated by spaces, commas, or new lines; then press Add
                            Target {this.zipCodeLabel}s
                        </p>
                        <TextArea name="manualZips" onChange={this.handleManualZipsChange}
                                  value={this.state.manualZips}/>
                        <span className="input-error type-right-side-bump">{this.state.manualZipsErrorMessage}</span>
                        <button onClick={this.cancelManualZips} type="button"
                                className="button-clean button-clean-padded type-blue type-small-body padding-10">
                            Cancel
                        </button>
                        {this.state.processingManuallyAddedZipCodes
                            ? <LoadingGif/>
                            : <button className="button ui-normal-button ui-full-width-button-mobile spacing-10-top"
                                      type="button" onClick={this.addManualZips}>
                                Add target {this.zipCodeLabel}s
                            </button>}
                    </div>
                    : <button className="button-clean type-blue padding-5 type-small-body type-heavy" type="button"
                              onClick={() => this.setState({displayManualZips: true})}>
                        <span className="inline-icon inline-icon__middle inline-icon__20">
                            {PlusSignSvg}
                        </span>{' '}
                        Add {this.zipCodeLabel}s Manually
                    </button>}
            </div>
            <div className="spacing-24 type-align-right">
                <button onClick={this.reset} className="button-clean type-red" type="button">
                    Clear all {this.zipCodeLabel}s, Start Over, Use New Radius
                </button>
            </div>
        </LoadScript>;
    }
}

MapZipCodeSelection.propTypes = {
    zipCodes: PropTypes.array.isRequired,
    zipCodesErrorMessage: PropTypes.string,
    mainZipCode: PropTypes.string.isRequired,
    mainZipCodeErrorMessage: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    country: PropTypes.string,
    onFinishLoading: PropTypes.func,
    containerHeight: PropTypes.string,
    clean: PropTypes.bool
};
