import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";

import {useModal} from "../../common/ModalContext";
import {errorHandler} from "../../Requests";
import ExternalBuyerService from "../../service/ExternalBuyerService";
import {getAffiliates, getServiceCategoryRulesGrouped} from "../../service/AffiliateService";
import {toCamelObject} from "../../Util";
import SkeletonText from "../../common/components/Skeleton/SkeletonText";
import IndustryRow from "./IndustryRow";
import type {IExternalBuyer} from "../../Interfaces/IExternalBuyer";
import type {IIndustry} from "../../Interfaces/IIndustry";
import type {IAdNetwork} from "../../Interfaces/IAdNetwork";
import type {IIndustryRule} from "../../Interfaces/IIndustryRule";

export interface IOption {
    value: number;
    text: string;
    disabled: boolean;
}

export default function ConfigureIndustriesModal(props) {
    const DEFAULT_MULTIPLIER = '0.80';
    const externalBuyer: IExternalBuyer = props.externalBuyer;
    const updateModalContent = useModal();
    const modalRef = useRef();
    const {current: externalBuyerService} = useRef(new ExternalBuyerService());

    const [submitting, setSubmitting] = useState(false);
    // sets the UI to be in loading state until the service categories are retrieved
    const [loading, setLoading] = useState(true);
    // the external lead buyer is in edit mode: meaning it, currently, does not have any industry-specific settings
    const [inEditMode, setInEditMode] = useState(false);
    // the connected industry rows or rows being currently set up
    const [industryRows: IIndustry[], setIndustryRows] = useState([]);
    // industry definitions of the external lead buyer
    const [industryOptions: IOption[], setIndustryOptions] = useState([]);
    // the available ad networks
    const [adNetworks: IAdNetwork[], setAdNetworks] = useState([]);
    // the industry-adNetwork specific rules; default disconnected, bid multiplier, etc.
    const [industryRules: IIndustryRule[], setIndustryRules] = useState([]);

    useEffect(() => {
        const affiliatesPromise = getAffiliates('mp_only=1');
        const rulesPromise = getServiceCategoryRulesGrouped();
        const industriesPromise = externalBuyerService.getIndustries(externalBuyer.externalBuyerId);

        Promise.all([affiliatesPromise, rulesPromise, industriesPromise])
            .then((resolved) => {
                // filter out ad networks that are blocked for this external buyer
                const adNetworks: IAdNetwork[] = toCamelObject(resolved[0].affiliates)
                    .filter((adNetwork: IAdNetwork) =>
                        externalBuyer.blockedAdNetworkIds.indexOf(adNetwork.adNetworkId) === -1);
                setAdNetworks(adNetworks);
                setIndustryRules(toCamelObject(resolved[1].service_category_rules));

                // store the ad networks by their ids for quicker access
                const adNetworksById = {};
                adNetworks.forEach((adNetwork) =>
                    adNetworksById[adNetwork.adNetworkId] = adNetwork);

                // collect connected industries as the *already* enabled industries
                // while also sorting the connections alphabetically
                const nextIndustryRows: IIndustry[] = resolved[2].industries;
                let inEdit = false;
                nextIndustryRows.forEach((industry: IIndustry) => {
                    // if the industry has connections, sort them by the ad network name
                    if (industry.connections) {
                        industry.connections.sort((a, b) => {
                                const aName = adNetworksById[a.adNetworkId]?.adNetworkName;
                                const bName = adNetworksById[b.adNetworkId]?.adNetworkName;
                                return aName?.localeCompare(bName) || 0;
                            }
                        );
                    }

                    // set the inEdit state to true if the industry is initially enabled
                    industry.inEdit = industry.enabled == 1;

                    // se the inEdit mode to true if at least one service category is enabled on init
                    if (industry.inEdit) {
                        inEdit = true;
                    }
                });

                // if there are enabled service categories on init, set the state to edit
                if (inEdit) {
                    setInEditMode(true);
                }
                // if there are no enabled industries to begin with, add an empty industry
                else {
                    nextIndustryRows.push({enabled: true, empty: true});
                }
                setIndustryRows(nextIndustryRows);

                const nextIndustryOptions: IOption[] = resolved[2].industries
                    .map((industry: IIndustry) => ({
                        value: industry.industryId,
                        text: industry.name,
                        disabled: industry.enabled == 1
                    }));
                setIndustryOptions(nextIndustryOptions);
                setLoading(false);
            })
            .catch((error) =>
                console.log('Unable to fetch data from our API', error));

        return () => {
            externalBuyerService.getIndustriesToken.cancel();
        }
    }, [externalBuyerService, externalBuyer]);

    const addIndustry = () => {
        // add empty row to the industries
        const nextIndustryRows = [...industryRows];
        nextIndustryRows.push({enabled: true, empty: true});
        setIndustryRows(nextIndustryRows);

        // set a timeless timeout to execute a scroll to bottom after the state changes
        setTimeout(() => modalRef.current.scrollIntoView({behavior: "smooth", block: "end"}));
    }

    /**
     * changes the status (enabled or disabled) of an array of industries
     * @param {{industryId: number; disabled: boolean;}[]} changes
     */
    const changeIndustryOptionStatus = useCallback((changes: { industryId: number; disabled: boolean; }[]) => {
        setIndustryOptions((prevIndustryOptions) => {
            const nextIndustryOptions: IIndustry[] = cloneDeep(prevIndustryOptions);
            changes.forEach((change) => {
                if (!change.industryId) {
                    return;
                }
                const updatedIndustryOption = nextIndustryOptions.find((industryOption: IOption) =>
                    industryOption.value == change.industryId);
                updatedIndustryOption.disabled = change.disabled;
            });
            return nextIndustryOptions;
        });
    }, []);

    const updateIndustry = useCallback((index: number, updatedIndustry: IIndustry) => {
        function getFlatBid(adNetworkId: number) {
            const rule = industryRules.find((rule: IIndustryRule) =>
                rule.adNetworkId == adNetworkId && rule.phoneFlatBidOverride !== undefined);

            return rule?.phoneFlatBidOverride || 0;
        }

        setIndustryRows((prevIndustryRows) => {
            const nextIndustryRows: IIndustry[] = cloneDeep(prevIndustryRows);
            const previousIndustry = nextIndustryRows[index];
            // if we selected an industry for the first time (i.e. the empty key will be true) remove this "industry" record
            // we will add the actual record down the line
            if (updatedIndustry.empty) {
                // delete the empty industry
                nextIndustryRows.splice(index, 1);
            }
            // otherwise, set it as disabled
            else {
                previousIndustry.enabled = false;
            }

            // if the industry id was changed (i.e. a previous value was selected), disable it, so it is available to select again
            // and move it to the previous industry location so that the UI persists
            if (previousIndustry.industryId != updatedIndustry.industryId) {
                // remove the industry record from the array
                const industryActualIndex = nextIndustryRows
                    .findIndex((industry) => industry.industryId == updatedIndustry.industryId);
                updatedIndustry = nextIndustryRows.splice(industryActualIndex, 1)[0];
                updatedIndustry.enabled = true;
                updatedIndustry.inEdit = false;

                // if the industry is being changed, remove the modified flag so that it is filtered out of the API call
                previousIndustry.modified = false;

                // reset the connections
                if (!updatedIndustry.connections) {
                    updatedIndustry.connections = [];
                    adNetworks.forEach((adNetwork: IAdNetwork) =>
                        updatedIndustry.connections.push({
                            adNetworkId: adNetwork.adNetworkId,
                            connectedMpPhonesApi: false,
                            lockedPhoneMultiplier: adNetwork.lockedMpPhoneBidMultiplier == 1,
                            multiplier: adNetwork.lockedMpPhoneBidMultiplier == 1
                                ? adNetwork.mpPhoneBidMultiplier
                                : DEFAULT_MULTIPLIER,
                            phoneFlatBidOverride: getFlatBid(adNetwork.adNetworkId)
                        })
                    );
                }

                // place the industry in the previous industry spot
                nextIndustryRows.splice(index, 0, updatedIndustry);

                changeIndustryOptionStatus([
                    {industryId: previousIndustry.industryId, disabled: false},
                    {industryId: updatedIndustry.industryId, disabled: true}
                ]);
            }
            else {
                nextIndustryRows[index] = updatedIndustry;
            }
            updatedIndustry.modified = true;

            return nextIndustryRows;
        });
    }, [adNetworks, changeIndustryOptionStatus, industryRules]);

    const deleteIndustry = useCallback((index) => {
        setIndustryRows((prevIndustryRows: IIndustry[]) => {
            // remove the industry from the industry rows
            const nextIndustryRows = [...prevIndustryRows];
            nextIndustryRows[index].enabled = false;

            // if the industry has a campaign id, mark it as modified, so it can be "deleted" (campaign paused) by the API
            if (nextIndustryRows[index].campaignId) {
                nextIndustryRows[index].modified = true;
            }

            // enable the industry option, so that it is available again for selection
            if (nextIndustryRows[index].industryId) {
                changeIndustryOptionStatus([{industryId: nextIndustryRows[index].industryId, disabled: false}]);
            }

            return nextIndustryRows;
        });
    }, [changeIndustryOptionStatus]);

    let hasInvalid = false;
    let hasModified = false;
    industryRows.forEach((industry: IIndustry) => {
        if (industry.enabled == 1 && !industry.identifier) {
            hasInvalid = true;
        }
        if (industry.modified) {
            hasModified = true;
        }
    });

    // can save if no invalid rows were found and at least one modification was made
    let canSave = !hasInvalid && hasModified;

    const submit = () => {
        // filter out to include only industries that were modified
        const industries: IIndustry[] = industryRows.filter(industry => industry.modified);
        if (industries.length === 0) {
            return;
        }

        setSubmitting(true);

        externalBuyerService.updateIndustries(externalBuyer.externalBuyerId, industries)
            .then((response) => {
                props.updateIndustries(response.data.industries, externalBuyer.externalBuyerId);
                props.updateMessageBlocks(["Successfully update the External Lead Buyer's Service Categories"], 'success');
                updateModalContent();
            })
            .catch((error) => {
                if (errorHandler(error)) {
                    setSubmitting(false);
                }
            });
    }

    if (loading) {
        return <div ref={modalRef} className="padding-30-bottom">
            <SkeletonText/>
        </div>;
    }

    return <div ref={modalRef}>
        <div className="type-normal-subhead type-heavy spacing-12-bottom">
            {inEditMode
                ? 'Add / Edit Service Category Lead Sources'
                : 'Add Service Category Lead Sources'}
        </div>

        <div className="padding-120-bottom-full padding-120-bottom-tablet padding-30-bottom-mobile">
            {industryRows.map((industry: IIndustry, index) =>
                industry.enabled == 1 &&
                <IndustryRow key={`industryRow-${index}`}
                             index={index}
                             industry={industry}
                             adNetworks={adNetworks}
                             industryRules={industryRules}
                             industryOptions={industryOptions}
                             updateIndustry={updateIndustry}
                             deleteIndustry={deleteIndustry}
                />
            )}
            <button type="button"
                    onClick={addIndustry}
                    disabled={hasInvalid}
                    className="button ui-small-button ui-full-width-button-mobile">
                Add Another Service Category
            </button>
        </div>

        <div className="popup__form__gray-bar fixed padding-24 no-padding-mobile">
            <div className="popup__form__row popup__form__row__slam-right popup__form__row-column-mobile">
                <div className="popup__form__cell ui-hide-mobile-important">
                    <button onClick={() => updateModalContent()}
                            disabled={submitting}
                            className="button-clean type-small-body type-heavy type-blue ui-full-width-button-mobile">
                        Cancel
                    </button>
                </div>
                <div className="popup__form__cell popup__form__cell__100__mobile">
                    {submitting &&
                        <button className="button button__green ui-normal-button ui-full-width-button-mobile"
                                disabled>Processing...</button>}
                    {!submitting &&
                        <button className="button ui-normal-button ui-full-width-button-mobile"
                                onClick={submit}
                                disabled={!canSave}>
                            {inEditMode ? 'Save Changes' : 'Complete Setup'}
                        </button>}
                </div>
            </div>
        </div>
    </div>;
}

ConfigureIndustriesModal.propTypes = {
    externalBuyer: PropTypes.object,
    updateIndustries: PropTypes.func,
    updateMessageBlocks: PropTypes.func
}
