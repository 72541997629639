import React, { Component } from 'react';
import ImageUploadCell from "./ImageUploadCell";
import PropTypes from 'prop-types';

export default class ImageUploadGrid extends Component {
    render() {
        const imageUploadCells = this.props.images.map((image, key) => {
           return <ImageUploadCell
                    image={image}
                    key={key}
                    imageIndex={key}
                    showAreYouSureModal={this.props.showAreYouSureModal}
                />
        });

        return (
            <div className={(this.props.bottomSpacing) ? "spacing-40-bottom" : null}>
                {(this.props.images.length > 0) ? (
                    <>
                        <p className="type-normal-body type-heavy type-single-line spacing-10-bottom">
                            Images Uploaded
                        </p>
                        <div className="image-uploads__grid">
                            {imageUploadCells}
                        </div>
                    </>
                ) : null}
            </div>
        );
    }
}

ImageUploadGrid.propTypes = {
    images: PropTypes.array.isRequired,
};
