import React, { useEffect, useRef, useState } from 'react';

import MultiSelectList, { MultiSelectListSkeleton } from './components/form_elements/MultiSelectList';

const AdScheduler = (props) => {
	const { updateScheduleState, updateScheduleTextState, extraClass } = props;
	
	const [initialized, setInitialized] = useState(false);
	const [schedule, setSchedule] = useState();
	
	const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	const timeValues = {
		"OFF":  -2,
		"24h":  -1,
		"12am":  0,
		"1am":   1,
		"2am":   2,
		"3am":   3,
		"4am":   4,
		"5am":   5,
		"6am":   6,
		"7am":   7,
		"8am":   8,
		"9am":   9,
		"10am": 10,
		"11am": 11,
		"12pm": 12,
		"1pm":  13,
		"2pm":  14,
		"3pm":  15,
		"4pm":  16,
		"5pm":  17,
		"6pm":  18,
		"7pm":  19,
		"8pm":  20,
		"9pm":  21,
		"10pm": 22,
		"11pm": 23,
		"12midnight": 24,
	};
	
	const timeOptions = [
		{ text:  "24h", value:  timeValues["24h"], },
		{ text: "12am", value: timeValues["12am"], },
		{ text:  "1am", value:  timeValues["1am"], },
		{ text:  "2am", value:  timeValues["2am"], },
		{ text:  "3am", value:  timeValues["3am"], },
		{ text:  "4am", value:  timeValues["4am"], },
		{ text:  "5am", value:  timeValues["5am"], },
		{ text:  "6am", value:  timeValues["6am"], },
		{ text:  "7am", value:  timeValues["7am"], },
		{ text:  "8am", value:  timeValues["8am"], },
		{ text:  "9am", value:  timeValues["9am"], },
		{ text: "10am", value: timeValues["10am"], },
		{ text: "11am", value: timeValues["11am"], },
		{ text: "12pm", value: timeValues["12pm"], },
		{ text:  "1pm", value:  timeValues["1pm"], },
		{ text:  "2pm", value:  timeValues["2pm"], },
		{ text:  "3pm", value:  timeValues["3pm"], },
		{ text:  "4pm", value:  timeValues["4pm"], },
		{ text:  "5pm", value:  timeValues["5pm"], },
		{ text:  "6pm", value:  timeValues["6pm"], },
		{ text:  "7pm", value:  timeValues["7pm"], },
		{ text:  "8pm", value:  timeValues["8pm"], },
		{ text:  "9pm", value:  timeValues["9pm"], },
		{ text: "10pm", value: timeValues["10pm"], },
		{ text: "11pm", value: timeValues["11pm"], },
		{ text: "12midnight", value: timeValues["12midnight"], },
		{ text:  "OFF", value:  timeValues["OFF"], },
	];
	
	
	const handleStartChange = (event, day) => {
		const _schedule = {...schedule};
		_schedule[day].start = Object.keys(timeValues).find(key => timeValues[key] === event[0]);
		if (event[0] < 0) {
			// If start has been set to 24h or Off, then make end for same day match
			_schedule[day].end = _schedule[day].start;
		} else if (event[0] >= timeValues[_schedule[day].end]) {
			// If start has been set to a specific time, but the end time is equal or before it, push
			// end time to 11pm
			_schedule[day].end = "11pm";
		}
		
		setSchedule(_schedule);
	};
	
	const handleEndChange = (event, day) => {
		const _schedule = {...schedule};
		_schedule[day].end = Object.keys(timeValues).find(key => timeValues[key] === event[0]);
		if (event[0] < 0) {
			// if end has been set to 24h or Off, then make start for same day match
			_schedule[day].start = _schedule[day].end;
		}
		setSchedule(_schedule);
	};
	
	const startTimeOptions = () => timeOptions.filter(option => option.text !== '12midnight');
	
	const endTimeOptions = (day) => {
		return timeOptions.map(option => {
			const _option = {...option};
			
			if (option.value <= timeValues[schedule[day].start]) {
				_option['disabled'] = true;
			}
			
			return _option;
		}).filter(option => option.text !== '12am');
	};
	
	const startMultiSelect = (dayKey, context) => (initialized) ?
			<MultiSelectList
				name={`${dayKey}-start`}
				selections={[timeValues[schedule[dayKey].start]]}
				options={startTimeOptions()}
				onChange={(event) => handleStartChange(event, dayKey)}
				disableVirtualList={context === 'modal'}
			/>
		: 
			<MultiSelectListSkeleton label={false} />
		;
	
	const endMultiSelect = (dayKey, context) => (initialized) ?
			<MultiSelectList
				name={`${dayKey}-end`}
				selections={[timeValues[schedule[dayKey].end]]}
				options={endTimeOptions(dayKey)}
				onChange={(event) => handleEndChange(event, dayKey)}
				disabled={timeValues[schedule[dayKey].start] < 0}
				disableVirtualList={context === 'modal'}
			/>
		:
			<MultiSelectListSkeleton label={false} />
		;
	
	const formatScheduleData = (scheduleData)  => {
		if (scheduleData) {
			const output = daysOfWeek.reduce((acc, val) => {
				const dayData = scheduleData[val.toLowerCase()];
				
				return `${acc}${val.substring(0,3)}: Start ${dayData['start']} End ${dayData['end']}\n`;
			}, '');
			
			return output;
		}
		
		return '';
	};
	
	const formatScheduleDataRef = useRef(formatScheduleData);
	
	useEffect(() => {
		if (!initialized && props.seedSchedule) {
			setInitialized(true);
			setSchedule(props.seedSchedule);
		}
	}, [props.seedSchedule, initialized]);
	
	useEffect(() => {
		if (updateScheduleState) {
			updateScheduleState(schedule);
		}
		if (updateScheduleTextState) {
			updateScheduleTextState(formatScheduleDataRef.current(schedule));
		}
	}, [schedule, updateScheduleState, updateScheduleTextState]);
	
	let containerClass = "ad-scheduler-container";
	
	// This switch adds classes that apply different breakpoints for where the flexible grid
	// presentation takes over.
	if (props.context === 'content') {
		containerClass += ` content-placement`;
	} else {
		// Right now the only non-modal placement is in OOB
		// so we treat modal as the default
		containerClass += ` modal-placement`;
	}
	
	if (extraClass) {
		containerClass += ` ${extraClass}`;
	}
	
	return (
		<div className={containerClass}>
			<table className="ad-scheduler-table__full-width">
				<thead>
					<tr>
						<th></th>
						{daysOfWeek.map(day => (
							<th className="day-column" scope="col" key={`${day}-header`}>{day}</th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row">Start</th>
						{daysOfWeek.map(day => (
							<td key={`${day}-start-cell`}>
								{startMultiSelect(day.toLowerCase(), props.context)}
							</td>
						))}
					</tr>
					<tr>
						<th scope="row">End</th>
						{daysOfWeek.map(day => (
							<td key={`${day}-end-cell`}>
								{endMultiSelect(day.toLowerCase(), props.context)}
							</td>
						))}
					</tr>
				</tbody>
			</table>
			<div className="ad-scheduler-table__flexible-grid">
				{daysOfWeek.map(day => (
					<div key={`${day}-flex-grid-area`}>
						<table>
							<thead>
								<tr>
									<th></th>
									<th scope="col" className="day-column">{day}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">Start</th>
									<td>
										{startMultiSelect(day.toLowerCase(), props.context)}
									</td>
								</tr>
								<tr>
									<th scope="row">End</th>
									<td>
										{endMultiSelect(day.toLowerCase(), props.context)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				))}
			</div>
		</div>
	);
};

export default AdScheduler;