import qs from 'query-string'

// get the existing querystring and update with input values
// return the new querystring
export function updateQueryString(queryString, { sortColumn, sortOrder }) {
    let currentQuery = qs.parse(queryString)
    let newQuery = {}

    // sort column
    if (sortColumn) {
        newQuery['sort_column'] = sortColumn
    }

    // sort order
    if (sortOrder) {
        newQuery['sort_order'] = sortOrder
    }

    // merge with current query, stringify and return
    newQuery = { ...currentQuery, ...newQuery }
    return qs.stringify(newQuery)
}

export const beforeUnloadListener = (event) => {
    event.preventDefault()

    // Customize the confirmation message
    const confirmationMessage =
        'Are you sure you want to leave this page? Your operation is still in progress.'

    // Display the confirmation dialog
    event.returnValue = confirmationMessage
    return confirmationMessage
}
