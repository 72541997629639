import React, { memo, useEffect, useState } from 'react'
import LeadService from '../../../../service/LeadService'
import CallInsightsBlock from './CallInsightsBlock'

const CallInsightsLoadable = (props) => {
    const { leadId, enrolledInCallInsights } = props
    const [callInsights, setCallInsights] = useState()
    const leadService = new LeadService()

    // get call insights for the lead
    useEffect(() => {
        leadService
            .getCallInsightsForLead(leadId)
            .then((resp) => {
                setCallInsights(resp.data)
            })
            .catch((err) => {
                console.error('Error getting call insights', err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // function to save call insights data for the lead
    const updateCallInsights = (data) => {
        return leadService
            .updateCallInsightsForLead(leadId, data)
            .then((res) => {
                setCallInsights(res.data)
            })
            .catch((err) => {
                console.error('Error updating call insights', err)
            })
    }

    return (
        <CallInsightsBlock
            enrolledInCallInsights={enrolledInCallInsights}
            callInsights={callInsights}
            updateCallInsights={updateCallInsights}
            {...props}
        />
    )
}

export default memo(CallInsightsLoadable)
