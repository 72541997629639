import React, { useEffect, useState } from 'react'
import qs from 'query-string'
import Button from '../../../../../common/components/form_elements/Button'
import ServiceCategoryRule from '../../../../../common/components/ServiceCategoryRule'
import { Wrapper, ContentWrapper, RuleWrapper } from './styles'
import LoadingGif from '../../../../../common/components/LoadingGif'
import uniqueId from 'lodash/uniqueId'
import { rulesAreValid } from '../CreateNew/utils'
import { getServiceCategoryRules, getServiceCategoryRuleTypes } from '../../../../../service/AffiliateService'

export default (props) => {
    const {
        getRuleTypes = () => Promise.resolve(null),
        getRules = () => Promise.resolve(null),
        affiliateId,
        serviceCategories,
        affiliate,
        changes = {},
        onChange,
        affiliateRules,
        showBlankRuleByDefault = false,
    } = props
    const [loading, setLoading] = useState(false)
    const [ruleTypes, setRuleTypes] = useState([])
    const [rules, setRules] = useState(affiliateRules || [])
    const [defaultValues, setDefaultValues] = useState([])
    const [newRules, setNewRules] = useState(changes?.newRules || [])
    const [editValues, setEditValues] = useState(changes?.edits || [])
    const [deletedRules, setDeletedRules] = useState(changes?.deletedRules || [])
    const PHONE_BID_MULTIPLIER_RULE_ID = '1'
    const FORM_BID_MULTIPLIER_RULE_ID = '2'

    const defaultGetRules = () => {
        if (getRules != null && getRules?.then != null) {
            return getRules
        }
        else if (affiliateId != null) {
            const query = qs.stringify({
                ad_network_ids: affiliateId,
            })
            return getServiceCategoryRules(query)
        } else {
            return () => Promise.resolve({rules: [], default_values: []})
        }
    }

    const defaultGetRuleTypes = () => {
        if (getRuleTypes != null && getRuleTypes?.then != null) {
            return getRuleTypes
        } else {
            return getServiceCategoryRuleTypes()
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affiliate])

    const fetchData = () => {
        setLoading(true)
        // first get the rule types
        defaultGetRuleTypes()
            .then((data) => {
                setRuleTypes(data)
                // then get the rules for the given ad network and/or
                // service category
                return defaultGetRules()
            })
            .then((data) => {
                const { rules, default_values } = data
                setDefaultValues(default_values)
                setRules(rules)
                // if the user has added rules, then changes the affiliate in the dropdown,
                // clear all rules (but show a new rule if the prop is set)
                if (newRules?.length > 0 && newRules[0].ad_network_id != affiliateId) {
                    const _newRules = []
                    if (showBlankRuleByDefault) {
                        _newRules.push({
                            _id: uniqueId('new_'),
                            action: 'create',
                            ad_network_id: affiliateId,
                        })
                    }
                    setNewRules(_newRules)
                    setDeletedRules([])
                    setEditValues([])
                    // also notify the change handler
                    onChange({ deletedRules: [], newRules: _newRules, edits: [] })
                } else {
                    if (
                        rules?.length == 0 &&
                        showBlankRuleByDefault &&
                        newRules?.length == 0
                    ) {
                        handleAddAnotherRule()
                    }
                }
            })
            .catch((err) => {
                console.error('Error getting rule types', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleAddAnotherRule = () => {
        const newRule = {
            _id: uniqueId('new_'),
            action: 'create',
            ad_network_id: affiliateId,
        }
        setNewRules([...newRules, newRule])
        // also notify the change handler
        onChange({ deletedRules, newRules: [...newRules, newRule], edits: editValues })
    }

    const getEditById = (id) => {
        return newRules.filter((r) => r.rule_id == id || r._id == id)[0]
    }

    const getRuleById = (id) => {
        return rules.filter((r) => r.rule_id == id || r._id == id)[0]
    }

    const updateNewRule = (id, properties) => {
        const _r = getEditById(id)
        const ruleToEdit = Object.assign({}, _r, ...properties)
        const _newRules = [...newRules.filter((e) => e._id != id), ruleToEdit]
        setNewRules([..._newRules])
        // also notify the change handler
        onChange({ deletedRules, newRules: _newRules, edits: editValues })
    }


    const handleSetServiceCategory = (id) => (val) => {
        // merge with service category properties
        const sc = serviceCategories.values.filter((sc) => sc.value == val)[0]
        updateNewRule(id, [{ industry_name: sc?.text }, { industry_id: val }])
    }

    const handleSetInputVal = (id) => (val) => {
        updateNewRule(id, [{ rule_value: val }])
    }

    const handleEditInputVal = (id) => (val) => {
        const _r = getRuleById(id)
        // merge with rule type info
        const ruleType = ruleTypes.filter((r) => r.rule_type_id == _r?.rule_type_id)[0]
        const ruleToEdit = Object.assign({}, _r, ruleType, {
            rule_value: val,
            action: 'update',
        })

        const edits = [...editValues.filter((e) => e.rule_id != id), ruleToEdit]
        setEditValues([...edits])
        // notify change listener
        onChange({ deletedRules, newRules, edits })
    }

    const handleSetRuleType = (id) => (val) => {
        // get the corresponding rule type object
        const ruleType = ruleTypes.filter((r) => r.rule_type_id == val)[0]
        // convert the object to array of properties
        const properties = Object.entries(ruleType).map(([k, v]) => ({ [k]: v }))
        // if the rule type is disconnect form or phone, add "value = 1"
        if (ruleType.rule_type_id == '3' || ruleType.rule_type_id == '4') {
            properties.push({ rule_value: '1' })
        }
        updateNewRule(id, properties)
    }

    const handleDeleteRule = (id) => (isDeleted) => {
        let rule = getRuleById(id)
        if (rule == null) {
            rule = getEditById(id)
        }
        // merge with rule type info
        const ruleType = ruleTypes.filter((r) => r.rule_type_id == rule?.rule_type_id)[0]
        const ruleToDelete = { ...rule, ...ruleType }
        // if the rule is a new rule and its being deleted, just delete it
        // no need to keep a record of it
        const { action, _id } = ruleToDelete
        if (rule == null || (action && action == 'create')) {
            setNewRules([...newRules.filter((e) => e._id != _id)])
            // also notify the change handler
            onChange({
                deletedRules,
                newRules: [...newRules.filter((e) => e._id != _id)],
                edits: editValues
            })
            return
        }
        // if its deleted, update the action to delete.
        ruleToDelete.action = 'delete'
        if (isDeleted) {
            setDeletedRules([...deletedRules, ruleToDelete])
            onChange({ deletedRules: [...deletedRules, ruleToDelete], newRules,  edits: editValues })
        } else {
            // otherwise, remove it from the deleted rules
            setDeletedRules([...deletedRules.filter((d) => d.rule_id != id)])
            onChange({
                deletedRules: [...deletedRules.filter((d) => d.rule_id != id)],
                newRules,
                edits: editValues
            })
        }
    }

    // existing rules view
    const serviceCategoryRulesView = rules.map((r, i) => {
        const { rule_type_id, ad_network_id, industry_id, rule_id } = r

        // check the deleted rules to see if this rule is deleted
        const _d = deletedRules.filter((r) => r.rule_id == rule_id)[0]
        const isDeleted = _d?.action == 'delete'

        // check the edited rules to see if the rule value has been edited
        // if so, and if the value is different than the original, then
        // pass the input value to the service category rule
        let inputValue = null
        const _e = editValues.filter((e) => e.rule_id == rule_id)[0]
        if (_e != null && _e.rule_value != r?.rule_value) {
            inputValue = _e.rule_value
        }
        return (
            <RuleWrapper key={`${i}_${rule_type_id}_${ad_network_id}_${industry_id}`}>
                <ServiceCategoryRule
                    ruleTypes={ruleTypes}
                    serviceCategories={serviceCategories}
                    rule={r}
                    defaults={defaultValues}
                    onSetServiceCategory={handleSetServiceCategory(rule_id)}
                    onSetRuleType={handleSetRuleType(rule_id)}
                    onDeleteRule={handleDeleteRule(rule_id)}
                    onSetInputVal={handleEditInputVal(rule_id)}
                    isDeleted={isDeleted}
                    inputValue={inputValue}
                />
            </RuleWrapper>
        )
    })

    const newRulesView = newRules.map((r, i) => {
        const { _id, industry_id } = r
        // for new rules, disable all rule types if a new or existing
        // rule already has the same rule type for a given service category
        const disabledRuleTypeIds = new Set()
        newRules.forEach((_newRule) => {
            if (!_newRule?.rule_type_id) {
                return
            }
            if (industry_id == _newRule.industry_id) {
                disabledRuleTypeIds.add(_newRule.rule_type_id)
            }
        })

        // if the affiliate has a locked bid multiplier, do not allow
        // the user to set a bid multiplier rule
        if (affiliate?.locked_mp_phone_bid_multiplier == '1') {
            disabledRuleTypeIds.add(PHONE_BID_MULTIPLIER_RULE_ID)
        }
        if (affiliate?.locked_mp_form_bid_multiplier == '1') {
            disabledRuleTypeIds.add(FORM_BID_MULTIPLIER_RULE_ID)
        }

        rules.forEach((_rule) => {
            if (!_rule?.rule_type_id) {
                return
            }
            if (industry_id == _rule.industry_id) {
                disabledRuleTypeIds.add(_rule.rule_type_id)
            }
        })

        return (
            <RuleWrapper key={`${_id}_${i}`}>
                <ServiceCategoryRule
                    defaults={defaultValues}
                    ruleTypes={ruleTypes}
                    disabledRuleTypeIds={[...disabledRuleTypeIds]}
                    serviceCategories={serviceCategories}
                    rule={r}
                    onSetServiceCategory={handleSetServiceCategory(_id)}
                    onSetRuleType={handleSetRuleType(_id)}
                    onDeleteRule={handleDeleteRule(_id)}
                    onSetInputVal={handleSetInputVal(_id)}
                />
            </RuleWrapper>
        )
    })

    const isAddRuleButtonEnabled =
        rulesAreValid({ newRules, deletedRules }) || newRules?.length == 0

    return (
        <Wrapper>
            {loading ? (
                <LoadingGif />
            ) : (
                <ContentWrapper>
                    {serviceCategoryRulesView}
                    {newRulesView}
                    <div>
                        <Button
                            disabled={!isAddRuleButtonEnabled}
                            style={{ margin: 0 }}
                            onClick={handleAddAnotherRule}
                        >
                            Add Another Rule
                        </Button>
                    </div>
                </ContentWrapper>
            )}
        </Wrapper>
    )
}
