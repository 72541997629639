import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './index.css';
import App from './App';
import { unregister} from "./registerServiceWorker";
import AuthService from "./service/AuthService";
import {ModalProvider} from "./common/ModalContext";
// import registerServiceWorker from './registerServiceWorker';

/* IE check */
if (/MSIE|Trident/.test(navigator.userAgent)) {
    alert('Service Direct does not support Internet Explorer.\n' +
        'Please use a different - modern - browser like Chrome, Firefox, Safari, or Edge');
}
AuthService.init();

ReactDOM.render(
    <Router>
        <ModalProvider>
            <Route component={App} />
        </ModalProvider>
    </Router>
    , document.getElementById('root')
);
// registerServiceWorker();
unregister();
