import React, {Component} from 'react';
import {errorHandler, get} from '../../Requests';

import PaymentConfirmationModal from '../billing_center/PaymentConfirmationModal';

import axios from 'axios';
import StatementService from "../../service/StatementService";
import {formatCurrency, getCurrentDate} from "../../Util";
import apiUrl from "../../Config";

export default class RecentStatements extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statements: []
        };

        this.cancelSignal = axios.CancelToken.source();

        this.statementService = new StatementService();
    }

    componentDidMount() {
        this.getStatements();
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
    }

    payStatement = (index) => {
        let statements = [...this.state.statements];

        this.statementService.payStatement(statements[index].invoice_id)
            .then((response) => {
                let statement = response.data.statement;
                statement.date = getCurrentDate();

                statements[index] = statement;
                this.setState({statements});

                // give success messaging
                this.props.updateModalContent();
                this.props.updateMessageBlocks(response.message, "success");
                this.props.updateBalanceSummary();
            })
            .catch(errorHandler);
    };

    /**
     * Gets the statements for this contractor
     */
    getStatements = () => {
        get("/statements", this.cancelSignal.token)
            .then( resp => {
                if (resp.data) {
                    let statements = resp.data.data.statements.slice(0, 3);
                    this.setState({statements});
                }
            })
            .catch(errorHandler);
    };

    render() {
        return (
            <table className="recent-statements-table">
                <tbody>
                    {this.state.statements.map((statement, key) => {
                        const amountTotal = parseInt(statement.amount_total);
                        const amountPaid = parseInt(statement.amount_paid);
                        const amountCredited = parseInt(statement.amount_credited);
                        let totalDisplay = parseInt(statement.amount_total - statement.amount_credited);
                        let invoicePdfUrl = `${apiUrl}statements/${statement.invoice_id}/pdf`;
                        let unpaid = amountTotal > amountPaid + amountCredited;

                        return (
                            <tr className={unpaid ? 'unpaid' : ''} key={"recent_statements_row_"+key}>
                                <td>
                                    <div
                                        className={`type-small-body type-narrow-line-height ${unpaid ? 'type-heavy' : ''} statement-details`}
                                    >
                                        <div>
                                            <a href={invoicePdfUrl} className="type-heavy">
                                                { statement.original_invoice_id }
                                            </a>
                                        </div>
                                        <div>
                                            { statement.date }
                                        </div>
                                        <div>
                                            Card …{ statement.last_four }
                                        </div>
                                        <div>
                                            { formatCurrency(totalDisplay) }
                                        </div>
                                        <div>
                                            <span className={unpaid ? 'type-alert type-heavy' : ''}>
                                                { unpaid ? 'Unpaid' : 'Paid' }
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className={`type-small-body type-narrow-line-height ${unpaid ? 'type-heavy' : ''} statement-description`}
                                    >
                                        { statement.description }
                                    </div>
                                </td>
                                <td>
                                    {unpaid ? (
                                        <button 
                                            className="button ui-small-button type-no-break"
                                            onClick={() => {
                                                // TODO: Re-connect payment API action with modal payment confirmation
                                                // this.payStatement(key); 
                                                this.props.updateModalContent(
                                                    <PaymentConfirmationModal
                                                        statement={{...statement}}
                                                        payStatement={() => this.payStatement(key)}
                                                        submitPayment={this.props.submitPayment}
                                                        primaryCardLastFour={this.props.primaryCardLastFour}
                                                        updateModalContent={this.props.updateModalContent}
                                                    />,
                                                    'Make Payment',
                                                    null,
                                                    true
                                                );
                                            }}
                                        >
                                            Pay Now
                                        </button>
                                    ) : null}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
}