
import styled from "styled-components"
import { FullColumn, Row } from '../../../common/layouts'

export const ContentRow = styled(Row)`
    padding: 0 30px 20px 30px;
    &:first-of-type {
        padding-top: 20px;
    }
    align-items: center;
    `

export const GridRow = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
    column-gap: 20px;
`

export const InputWrapper = styled(FullColumn)`
    margin-right: 0px;
`

export const Actions = styled(ContentRow)`
    justify-content: flex-end;
    background-color: #F2F8FC;
    padding: 25px 20px;
`

export const StyledList = styled.ul`
    list-style-position: inside;
`

export const StyledListItem = styled.li`
    list-style-type: disc;
`
export const ContextSentenceList = styled.ul`
    li {
        margin-bottom: 10px;
    }
`