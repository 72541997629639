import React, { useState } from 'react';

import ImagePopup from './ImagePopup';
import NavigationFooter from './navigation-footer';
import StepOne from './step-one';
import StepTwo from './step-two';
import StepThree from './step-three';
import StepFour from './step-four';
import StepFive from './step-five';


const SelfServeOnboardingStep = (props) => {
	const [imagePopupOptions, setImagePopupOptions] = useState(null);
	const [imagePopupVisible, setImagePopupVisible] = useState(false);
	
	const showPopup = (options) => {
		setImagePopupOptions(options);
		setImagePopupVisible(true);
	}
	
	const closePopup = () => {
		setImagePopupOptions(null);
		setImagePopupVisible(false);
	};
	
	return (
		<>
			{imagePopupOptions && (
				<ImagePopup
					url={imagePopupOptions.url}
					alt={imagePopupOptions.alt}
					caption={imagePopupOptions.caption}
					visible={imagePopupVisible}
					closePopup={closePopup}
				/>
			)}
			
			<h2
				className="type-normal-subhead-special onboarding-page-header-spacing"
			>
				Service Direct Online Onboarding
			</h2>
			
			{props.step === 1 && <StepOne showPopup={showPopup} {...props} />}
			{props.step === 2 && <StepTwo showPopup={showPopup} {...props} />}
			{props.step === 3 && <StepThree showPopup={showPopup} {...props} />}
			{props.step === 4 && <StepFour showPopup={showPopup} {...props} />}
			{props.step === 5 && <StepFive showPopup={showPopup} {...props} />}
			
			<NavigationFooter {...props} />
		</>
	)
};

export default SelfServeOnboardingStep;