// import React from 'react';
import { upperFirst } from './Util';

    export const getInputError = (labelText, elem) => {
        const validity = elem.validity;
        let error = '';

        if (validity.valid) {
            return error;
        }

        if (validity.valueMissing) {
            error = labelText + " is required.";
        }
        else if (validity.patternMismatch) {
            error = labelText + ' field has invalid input.';
        }
        else if (validity.tooShort) {
            error = labelText + ' needs to be at least 5 characters.'
        }


        return error;
    }

    export const showInputError = (fieldName, elem, error) => {
        if (error === undefined) {
            const labelText = upperFirst(
                document.getElementById(fieldName).textContent
            );
            error = getInputError(labelText, elem);
        }

        if (error !== '') {
            elem.classList.add("ui-alert");
        }
        else {
            elem.classList.remove("ui-alert");
        }

        return error;
    }

export default getInputError;
