import styled from "styled-components";

export const Toolbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`

export const TabsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
`

export const ButtonWrapper = styled.div`
    position: relative;
    bottom: -20px;
`