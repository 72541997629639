import React from 'react';

import Checkbox from '../../common/components/form_elements/Checkbox';
import Tooltip from '../../common/Tooltip';
import AuthService from "../../service/AuthService";

const UserNotificationCheckbox = (props) => {
    const tooltipContents = <>
        <span className="type-small-body type-black type-force-newline type-narrow-line-height spacing-10-bottom">
            There must be at least one User set to receive {props.label} notifications.
        </span>
        <span className="type-small-body type-black type-force-newline type-narrow-line-height">
            Learn more in{' '}
            <a className="type-heavy" target="_blank"
               href={AuthService.isMarketplace
                   ? "https://support.servicedirect.com/manage-users"
                   : "https://support.servicedirect.com/select/manage-users"}>
                Manage Users and Notifications.
            </a>
        </span>
    </>;

    const tooltipRef = React.createRef();

    const moreProps = {};
    if (props.secondRow) {
        moreProps.customClasses = 'spacing-10-top';
    }

    const checkbox = <Checkbox
        label={props.label}
        name={props.name}
        checked={props.checked == true}
        locked={props.locked}
        onChange={props.onChange}
        disabled={props.locked}
        {...moreProps}
    />;

    return props.locked
        ? <Tooltip
            content={tooltipContents}
            anchor={checkbox}
            position="left"
            modalContainerRef={tooltipRef}
        />
        : checkbox;
}

export default UserNotificationCheckbox;