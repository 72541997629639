import React from 'react';

import {MoneyInput} from "../../common/components/form_elements/MoneyInput";

/**
 * Started as a functional component, but apparently you can't
 *   use refs within stateless functional components.  Switching to class component
 **/
export default function CostPerLead(props) {
    let error = props.error;
    let hasError = !!error;
    let required = props.isRequired;
    let minTender = props.service.min_suggested_tender;
    let maxTender = props.service.max_suggested_tender;

    let placeholder = (minTender && maxTender && minTender != 0 && maxTender != 0)
        ? "Avg: " + minTender + " - " + maxTender
        : "";

    return <div
        className="form__cell form__cell__bottom-align form__cell__25 form__cell__100__mobile spacing-24-bottom-mobile">
        <label
            htmlFor={"admin-cpl-" + props.index}
            className={"type-normal-body type-heavy type-narrow-line-height spacing-10-bottom" + (hasError ? " type-alert" : "")}
            ref={props.inputRef}
        >
            Cost Per Lead for {props.service.name} {required ? " *" : ""}
            <span className={hasError ? "text-alert" : ""}>{error}</span>
        </label>
        <MoneyInput
            id={"admin-cpl-" + props.index}
            name={"admin-cpl-name-" + props.index}
            placeholder={placeholder}
            onChange={(event) => props.updateServiceCategoryAndGeo(event.target.value, "cpl", props.index)}
            onBlur={(event) => props.onBlurServiceCategoryAndGeo(event.target.value, "cpl", props.index)}
            uncontrolled={true}
            defaultValue={props.value}
        />
    </div>;
};
