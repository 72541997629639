/**
 * This function converts the summary data from the Marketplace Campaign
 * Optimizer API into the same structure
 * as the table columns so that the data is displayed in the correct
 * column
 */
export default (summaryData) => {
    /* 
    "summary": {
		"total_leads": 2495,
		"total_billable_leads": 1570,
		"total_revenue": 32172,
		"total_cost": 18948.5999999999985448084771633148193359375,
		"total_margin": 13223.399999999999636202119290828704833984375,
		"average_margin": 41,
		"total_contractor": 1,
		"total_campaign": 2
	},
    */
   return {
        client_name: summaryData?.total_contractor,
        campaign_name: summaryData?.total_campaign,
        campaign_enabled_percent: summaryData?.average_campaign_enabled_percent,
        count_total_leads: summaryData?.total_leads,
        count_billable_leads: summaryData?.total_billable_leads,
        margin: summaryData?.total_margin,
        margin_percent: summaryData?.average_margin,
        cost: summaryData?.total_cost,
        revenue: summaryData?.total_revenue,
   }
}