import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import Header from "./Header";
import Footer from "./Footer";
import {footerLinks, footerHeaders} from './FooterLinks';
import DashboardView from "./dashboard/DashboardView";
import LeadManagerView from "./lead_manager/LeadManagerView";
import BillingCenterView from "./billing_center/BillingCenterView";
import CampaignManagerView from "./campaign_manager/CampaignManagerView";
import UsersView from "./settings/UsersView";
import RouteNotFound from "../common/RouteNotFound.js";
import ClientAccountsView from "./client_account/ClientAccountsView";
import {defaultClientUri} from "../Config";
import YourCompany from "./signup/your_company/YourCompany";
import SelectEnrollmentConfirmation from "./signup/SelectEnrollConfirmation";

/**
 * Expected props
 *
 * whiteLabelPartnership
 */
export default class ClientApp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            header: {
                newLeadCount: 0,
                notificationCount: 0,
                lastUpdate: '',
            },
        };

        this.secondaryNavRef = React.createRef();
    }

    setAppAuth = (isAuthenticated) => {
        this.setState({auth: isAuthenticated});
    };

    render() {
        return <div>
            <Header
                // updateAppHeaderCounts={ this.updateAppHeaderCounts }
                headerValues={this.state.header}
                setAppAuth={this.setAppAuth}
                secondaryNavRef={this.secondaryNavRef}
                whiteLabelPartnership={this.props.whiteLabelPartnership}
            />
            {this.props.children}
            <Switch>
                <Route
                    path="/dashboard"
                    render={(props) => (
                        <>
                            <section>
                                <DashboardView {...props}
                                               updateMessageBlocks={this.props.updateMessageBlocks}
                                />
                            </section>
                            <Footer
                                footerHeader={footerHeaders['dashboard']}
                                footerLinks={footerLinks['dashboard']}
                                whiteLabelPartnership={this.props.whiteLabelPartnership}
                            />
                        </>
                    )}
                />
                <Route
                    path="/clients"
                    render={(props) => (
                        <>
                            <section>
                                <ClientAccountsView {...props} />
                            </section>
                            <Footer
                                footerHeader={footerHeaders['clients']}
                                footerLinks={footerLinks['clients']}
                                whiteLabelPartnership={this.props.whiteLabelPartnership}
                            />
                        </>
                    )}
                />
                <Route
                    path="/leads"
                    render={(props) => (
                        <>
                            <section>
                                <LeadManagerView {...props}
                                                 secondaryNavRef={this.secondaryNavRef}
                                                 updateMessageBlocks={this.props.updateMessageBlocks}
                                                 whiteLabelPartnership={this.props.whiteLabelPartnership}
                                />
                            </section>
                            <Footer
                                footerHeader={footerHeaders['leads']}
                                footerLinks={footerLinks['leads']}
                                whiteLabelPartnership={this.props.whiteLabelPartnership}
                            />
                        </>
                    )}
                />

                <Route
                    path="/billing"
                    render={(props) => (
                        <>
                            <section>
                                <BillingCenterView
                                    {...props}
                                    contractor={this.state.contractor}
                                    updateMessageBlocks={this.props.updateMessageBlocks}
                                    whiteLabelPartnership={this.props.whiteLabelPartnership}
                                    partnership={this.props.partnership}
                                />
                            </section>
                            <Footer
                                footerHeader={footerHeaders['billing']}
                                footerLinks={footerLinks['billing']}
                                whiteLabelPartnership={this.props.whiteLabelPartnership}
                            />
                        </>
                    )}
                />
                <Route
                    path="/campaigns"
                    render={(props) => (
                        <>
                            <section>
                                <CampaignManagerView {...props}
                                                     secondaryNavRef={this.secondaryNavRef}
                                                     updateMessageBlocks={this.props.updateMessageBlocks}
                                />
                            </section>
                            <Footer
                                footerHeader={footerHeaders['campaigns']}
                                footerLinks={footerLinks['campaigns']}
                                whiteLabelPartnership={this.props.whiteLabelPartnership}
                            />
                        </>
                    )}
                />
                <Route
                    path="/users"
                    render={(props) => (
                        <>
                            <section>
                                <UsersView {...props}
                                           updateMessageBlocks={this.props.updateMessageBlocks}
                                />
                            </section>
                            <Footer
                                footerHeader={footerHeaders['users']}
                                footerLinks={footerLinks['users']}
                                whiteLabelPartnership={this.props.whiteLabelPartnership}
                            />
                        </>
                    )}
                />
                <Route path="/select-enroll">
                    <section>
                        <YourCompany {...this.props}
                                     updateMessageBlocks={this.props.updateMessageBlocks}
                                     whiteLabelPartnership={this.props.whiteLabelPartnership}
                                     updateHighlights={this.props.updateHighlights}/>
                    </section>
                    <Footer
                        footerHeader={footerHeaders.selectEnroll}
                        footerLinks={footerLinks.selectEnroll}
                        whiteLabelPartnership={this.props.whiteLabelPartnership}
                    />
                </Route>
                <Route path="/select-enroll-confirmation">
                    <section>
                        <SelectEnrollmentConfirmation/>
                    </section>
                    <Footer
                        footerHeader={footerHeaders.selectEnroll}
                        footerLinks={footerLinks.selectEnroll}
                        whiteLabelPartnership={this.props.whiteLabelPartnership}
                    />
                </Route>
                <Redirect exact from="/" to={defaultClientUri}/>
                <Redirect exact from="/login-help/:token" to={defaultClientUri}/>

                {/* redirect fallback for any of the signup routes */}
                <Redirect from="/signup(-marketplace)?/" to={defaultClientUri}/>

                <Route>
                    <>
                        <section>
                            <RouteNotFound/>
                        </section>
                        <Footer
                            is404={true}
                            whiteLabelPartnership={this.props.whiteLabelPartnership}
                        />
                    </>
                </Route>
            </Switch>
        </div>;
    }
}
