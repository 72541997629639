import React, {useCallback, useRef, useState} from 'react';

import AuthService from '../service/AuthService';
import HubspotScheduler from './HubspotScheduler';
import LearnMoreFooter from './LearnMoreFooter';
import OnboardingService from '../service/OnboardingService';

const ScheduleOnboarding = (props) => {
	const [scheduleRequested, setScheduleRequested] = useState(false);
	const {current: onboardingService} = useRef(new OnboardingService());

	const beginSelfServe = useCallback(() => {
		onboardingService.beginSelfServeOnboarding({
			contractor_id: AuthService.contractorId,
			user_id: AuthService.userId,
		}).then((response) => {
			AuthService.isAwaitingOnboarding = false;
			AuthService.isOnboarding = true;
			window.location = '/onboarding/step-one';
		});
	}, [onboardingService]);
	
	return (
		<div className="onboarding-plain-section">
			<div className="onboarding-section__content">
				<h3 className="type-large-subhead type-heavy">
					Schedule your Onboarding to start getting Leads for your business.
				</h3>
				<p className="type-large-body spacing-40-bottom">
					Onboarding explains how Service Direct works and is required before we can launch your Campaigns.
				</p>
				
				{props.isMQL && !AuthService.canceledSsOnboarding ? (
					<>
					{!scheduleRequested && (
						<>
						<h4 className="type-normal-subhead type-heavy">
							Onboarding Option 1: Self-Serve Online Course
						</h4>
						<p className="type-large-body">
							Onboard in 15-20 minutes by watching videos and answering a few questions.
						</p>
						<div className="spacing-20">
							<button
								onClick={beginSelfServe}
								className="button ui-large-button ui-full-width-button-mobile"
								type="submit"
							>
								Start Online Onboarding
								<img src="/images/icon-next-arrow.svg" alt="Right Arrow" className="right"/>
							</button>
						</div>
						<div className="spacing-60-bottom">
							<img
								src="/images/onboarding/SRVP-Onboarding-SelfServe-Section2-LeadCostAndVolume-v2-Crop-XL.jpg"
								alt="Screenshot demonstrating the Online Onboarding experience"
								width="852"
								height="614"
								style={{height: "auto"}}
							/>
						</div>
						</>
					)}
					
					<h4 className="type-normal-subhead type-heavy">
						Onboarding Option 2: Call with a Specialist
					</h4>
					<p className="type-large-body spacing-20-bottom">
						Schedule an in-person phone call to Onboard.
					</p>
					<HubspotScheduler
						wrapper="schedule-onboarding"
						setScheduleRequested={setScheduleRequested}
					/>
					</>
				) : (
					<HubspotScheduler
						wrapper="schedule-onboarding"
						setScheduleRequested={setScheduleRequested}
					/>
				)}
			
				<LearnMoreFooter />
			</div>
		</div>
	);
};

export default ScheduleOnboarding;
