import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FixedSizeList as List } from 'react-window'
import styled, { css } from 'styled-components'
import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'

import Label from './Label'
import SkeletonText from '../Skeleton/SkeletonText'
import { IconCaretDownSvg, SvgWrapper } from '../../Svgs'

const StyledUnordredList = styled.ul`
    width: ${(props) => (props.wide ? '120%' : '100%')};
    min-width: ${(props) => props.minWidth || null};
    left: ${(props) => (props.open === 'left' ? 'auto' : 0)};
    right: ${(props) => (props.open === 'left' ? 0 : 'auto')};

    // when the virtual list (AKA "react-window") is disabled, manually limit the size of the visible list
    ${(props) =>
        props.disabledVirtualList &&
        css`
            max-height: ${(props) => props.maxHeight + 'px'};
            overflow-y: auto;
        `}
`

export default class MultiSelectList extends Component {
    constructor(props) {
        super(props)

        this.containerRef = React.createRef()
        this.labelRef = React.createRef()
        this.dropdownRef = React.createRef()
        this.searchRef = React.createRef()

        let selections = props.selections || []
        if (props.selectAllByDefault) {
            selections = props.options.map(props.getOptionValue)
        }

        this.state = {
            expanded: false,
            selections,
            truncatedLabel: false,
            textFilter: '',
            error: false,
            showSelected: false,
        }

        // warn us in case the empty label equals to the value of one of the multi select options
        if (props.emptyLabel) {
            props.options.forEach((option) => {
                if (
                    props.getOptionText(option)?.toLowerCase() ===
                    props.emptyLabel?.toLowerCase()
                ) {
                    console.warn(
                        'The emptyLabel value is the same as the text of one of the options'
                    )
                }
            })
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.handleOutsideClick)
        this.handleTextOverflow()
    }

    componentDidUpdate(prevProps) {
        this.handleTextOverflow()
        if (
            JSON.stringify(prevProps.selections) != JSON.stringify(this.props.selections)
        ) {
            this.setState({ selections: this.props.selections })
        }

        // if using the text search option, focus the text input when the dropdown
        // is expanded
        if (this.props.useTextFilter && this.state.expanded && this.searchRef?.current) {
            this.searchRef.current.focus()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleOutsideClick)
    }

    /**
     * Combines the selections into an overall Label
     * @returns string - the label to display at top of MultiSelect
     */
    compiledLabel = () => {
        // if a custom label is provided, render it
        if (this.props.dropdownLabel != null) {
            return this.props.dropdownLabel
        }

        // if a custom label function is provided, render it
        if (this.props.compileCustomLabel != null) {
            return this.props.compileCustomLabel(this.state.selections)
        }

        // If disabledLabel is specified, show disabledLabel
        if (this.props.disabled && this.props.disabledLabel) {
            return this.props.disabledLabel
        }

        // if all items are selected, display the selectAllLabel prop,
        // which defaults to "All"
        if (
            this.state.options?.length > 0 &&
            this.state.selections?.length === this.props.options.length
        ) {
            return this.props.selectAllLabel
        }
        const { getOptionText, getOptionValue } = this.props

        if (this.state.selections.length === 0) {
            return this.props.emptyLabel
        }

        let labels = []
        this.props.options.forEach((item) => {
            if (this.state.selections.includes(getOptionValue(item))) {
                labels.push(getOptionText(item))
            }
        })

        return labels.join(', ')
    }

    handleTextOverflow = () => {
        const label = this.labelRef.current
        const truncated = this.state.truncatedLabel

        if (label?.offsetWidth < label?.scrollWidth) {
            if (!truncated) {
                this.setState({ truncatedLabel: true })
            }
        } else {
            if (truncated) {
                this.setState({ truncatedLabel: false })
            }
        }
    }

    handleOutsideClick = (event) => {
        // ignore clicks that are inside the container and the dropdown
        if (
            this.containerRef.current &&
            !this.containerRef.current.contains(event.target) &&
            this.dropdownRef.current &&
            !this.dropdownRef.current.contains(event.target)
        ) {
            this.setState({
                expanded: false,
                textFilter: '',
            })
        }
    }

    updateSelectionState = (selections) => {
        this.setState({ selections })
        // notify change handler
        this.props.onChange && this.props.onChange(selections)
    }

    toggleItem = (event, value) => {
        let selections: Array = this.state.selections,
            index = this.state.selections.indexOf(value)
        if (!this.props.multiselect) {
            // in single select mode, simple replace the current selection with the new one
            selections = [value]
        } else {
            // in multiselect mode, determine if the item needs to be checked or unchecked
            if (index === -1) {
                if (this.props.internalDisabled) {
                    return
                }
                selections.push(value)
            } else {
                selections.splice(index, 1)
            }
        }
        // if the item is being unchecked, and there are no selections,
        // make sure the showSelected state is reset
        if (selections.length === 0) {
            this.setState({ showSelected: false })
        }

        this.updateSelectionState(selections)
    }

    handleDeselectAll = (options) => () => {
        let selections
        if (this.state.selections?.length === 0) {
            // selections = this.props.options.map(this.props.getOptionValue)
            selections = options.map(this.props.getOptionValue)
        } else {
            selections = []
        }
        this.updateSelectionState(selections)
    }

    /**
     * Show only the selected items in the dropdown
     */
    handleShowSelected = () => {
        this.setState({ showSelected: !this.state.showSelected })
    }

    handleInputChange = (event: Event) =>
        // when search is active, reset the showSelected state
        this.setState({ textFilter: event.target.value, showSelected: false })

    handleInputClick = (event: Event) => event.stopPropagation()

    handleContainerClick = () => {
        if (!this.props.disabled) {
            this.setState({
                expanded: !this.state.expanded,
                textFilter: '',
            })
        }
    }

    render() {
        const { getOptionText, getOptionValue, variant } = this.props
        let label = this.props.label ? (
            <Label
                label={this.props.label}
                inputRef={this.props.inputRef}
                name={this.props.name}
                hasError={this.props.hasError}
                required={this.props.required}
                addOn={this.props.addOn}
            />
        ) : null

        let extraClasses = ''
        if (this.props.extraClass) {
            extraClasses += this.props.extraClass
        }
        if (this.state.error || this.props.hasError) {
            extraClasses += ' ui-alert'
        }

        let dropdownClasses = 'dropdown__menu'
        if (this.state.expanded) {
            dropdownClasses += ' dropdown__menu__expanded'
        }

        if (this.props.variant === 'list') {
            dropdownClasses = ''
        }

        let menuOptions = this.props.options
        // if grouping is required, perform grouping. The end result
        // is still an array of objects, but the group values are in the array
        if (this.props.hasGroups) {
            menuOptions = reduce(
                groupBy(menuOptions, (option) => option.group),
                (acc, options, group) => {
                    acc.push({ text: group, groupLabel: true })
                    acc.push(...options)
                    return acc
                },
                []
            )
        }

        // text filter
        if (this.props.useTextFilter && this.state.textFilter.length > 0) {
            menuOptions = menuOptions.filter((option) => {
                const filter = this.state.textFilter
                const optionText = getOptionText(option)
                if (!optionText) {
                    return false
                }

                if (filter?.length > optionText?.length) {
                    return false
                }

                // the default text search looks for the text string
                // anywhere in the search option. if the 'searchFromBeginning'
                // prop is provided, the text search will look at the beginning
                // of the text option
                if (this.props.searchFromBeginning) {
                    if (!optionText?.toLowerCase().startsWith(filter.toLowerCase())) {
                        return false
                    }
                }
                if (!optionText?.toLowerCase().includes(filter.toLowerCase())) {
                    return false
                }

                return true
            })
        }

        // show selected filter
        if (this.state.showSelected) {
            menuOptions = menuOptions.filter((option) => {
                return this.state.selections.includes(getOptionValue(option))
            })
        }

        const handleItemClick = (index) => (event) => {
            event.preventDefault()
            event.stopPropagation()

            const selectedOption = menuOptions[index]
            // if a group label is clicked, don't do anything
            if (
                (this.props.hasGroups && selectedOption.groupLabel) ||
                selectedOption.disabled
            ) {
                return
            }
            this.toggleItem(event, getOptionValue(selectedOption))
            // if the component is in single select mode, close the dropdown
            // and clear the search on select
            if (!this.props.multiselect) {
                // _state.expanded = false,
                this.setState({
                    expanded: false,
                    textFilter: '',
                })
            }
        }

        // Row display options
        const OPTION_ITEM_HEIGHT = this.props.multiselect ? 49 : 44 // height of an item in the list
        const MAX_ITEMS_TO_DISPLAY = this.props.itemsToDisplay || 6
        const LIST_HEIGHT =
            menuOptions.length < MAX_ITEMS_TO_DISPLAY
                ? OPTION_ITEM_HEIGHT * menuOptions.length
                : OPTION_ITEM_HEIGHT * MAX_ITEMS_TO_DISPLAY
        // Render a row for the windowed list
        const Row = ({ index, style }) => {
            const option = menuOptions[index]

            // if the option is disabled
            const { disabled = false } = option

            let isChecked = this.state.selections.includes(getOptionValue(option))
            // let isChecked = false;
            let spanClasses = 'type-no-break'
            let labelClasses = ''
            if (this.props.multiselect) {
                spanClasses += ' checkbox'
            } else {
                labelClasses += 'single-select'
                // in single select mode, if the item is selected, apply the correct class
                if (isChecked) {
                    spanClasses += ' type-heavy type-blue'
                }
            }
            // if the option is disabled
            if (disabled) {
                labelClasses += ' disabled'
            }
            const itemText = getOptionText(option)
            if (!itemText || itemText === '') {
                spanClasses += ' type-grey-1'
            }

            let listItemContent
            // if the option is a group label, create the group label option
            if (option?.groupLabel) {
                listItemContent = (
                    <div className="dropdown__menu__option__label">
                        <div className="group-label">
                            <span className="type-heavy">{option.text}</span>
                        </div>
                    </div>
                )
            } else {
                // otherwise create the normal menu option
                let classNames = 'dropdown__menu__option__target'
                if (disabled) {
                    classNames += ' disabled'
                }
                classNames += ' ui-normal-text-size'
                listItemContent = (
                    <span className={classNames}>
                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                name={`${this.props.name}-${index}`}
                                value={getOptionValue(option)}
                                id={`${this.props.name}-${index}`}
                                disabled={
                                    (this.props.internalDisabled && !isChecked) ||
                                    disabled
                                }
                                checked={isChecked}
                                readOnly
                            />
                            <label
                                className={labelClasses}
                                htmlFor={`${this.props.name}-${index}`}
                                title={option.disabledText || ''}
                            >
                                <span className={spanClasses}>
                                    {itemText}
                                    {option.disabledText && ` ${option.disabledText}`}
                                </span>
                            </label>
                        </div>
                    </span>
                )
            }

            return (
                <li
                    className="dropdown__menu__option"
                    key={`multiselect-${this.props.name}-${index}`}
                    onClick={disabled ? null : handleItemClick(index)}
                    style={style}
                >
                    {listItemContent}
                </li>
            )
        }

        // Show Selected is disabled when there are no selections
        const isShowSelectedDisabled = this.state.selections?.length === 0
        let showSelectedClassnames = 'ui-normal-text-size'
        if (isShowSelectedDisabled) {
            showSelectedClassnames = `${showSelectedClassnames} disabled`
        }

        const renderedLabel = this.compiledLabel()

        if (this.props.readOnly) {
            return (
                <>
                    {label}{' '}
                    <input
                        className="ui-text-field ui-large-text-input"
                        readOnly
                        value={renderedLabel}
                    />
                </>
            )
        }

        const list = (
            <StyledUnordredList
                minWidth={this.props.minDropdownWidth}
                wide={this.props.wideDropdown}
                className={dropdownClasses}
                ref={this.dropdownRef}
                open={this.props.open}
                disabledVirtualList={this.props.disableVirtualList}
                maxHeight={LIST_HEIGHT + this.props.useTextFilter * OPTION_ITEM_HEIGHT}
            >
                {this.props.dropdownContent ? (
                    this.props.dropdownContent
                ) : (
                    <>
                        {/* Text Search */}
                        {this.props.useTextFilter && (
                            <li className="dropdown__search-row">
                                <input
                                    type="text"
                                    ref={this.searchRef}
                                    className="dropdown__search-field"
                                    onChange={this.handleInputChange}
                                    onClick={this.handleInputClick}
                                    value={this.state.textFilter}
                                    placeholder="Search Options"
                                />
                            </li>
                        )}

                        {/* Deselect All and Show Selected button */}
                        {((this.props.allowDeselectAll && this.props.multiselect) ||
                            (this.props.allowShowSelected && this.props.multiselect)) && (
                            <li className="dropdown__menu__option">
                                <div
                                    style={{
                                        padding: '0.7978035009em 1em',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {this.props.allowDeselectAll &&
                                        this.props.multiselect && (
                                            <span
                                                className="ui-normal-text-size"
                                                onClick={this.handleDeselectAll(
                                                    menuOptions
                                                )}
                                                role="button"
                                            >
                                                {this.state.selections?.length === 0
                                                    ? 'Select All'
                                                    : 'Deselect All'}
                                            </span>
                                        )}

                                    {/* ensure Show Selected button renders on the right side even when there is no "select all" button */}
                                    {this.props.allowShowSelected &&
                                        !this.props.allowDeselectAll &&
                                        this.props.multiselect && <span />}

                                    {this.props.allowShowSelected &&
                                        this.props.multiselect && (
                                            <span
                                                className={showSelectedClassnames}
                                                onClick={
                                                    !isShowSelectedDisabled ? this.handleShowSelected : undefined
                                                }
                                                role="button"
                                                disabled={
                                                    this.state.selections?.length === 0
                                                }
                                            >
                                                {!this.state.showSelected
                                                    ? 'Show Selected'
                                                    : 'Show All'}
                                            </span>
                                        )}
                                </div>
                            </li>
                        )}
                        {this.props.disableVirtualList ? (
                            menuOptions.map((item, index) => Row({ index }))
                        ) : (
                            <List
                                height={LIST_HEIGHT}
                                itemCount={menuOptions.length}
                                itemSize={OPTION_ITEM_HEIGHT}
                            >
                                {Row}
                            </List>
                        )}
                    </>
                )}
            </StyledUnordredList>
        )

        if (variant === 'list') {
            return <div>{list}</div>
        }

        return (
            <>
                {label}
                <div
                    className="dropdown__container dropdown__multiselect"
                    ref={this.containerRef}
                >
                    <div
                        className={`dropdown__anchor ui-normal-text-size ${extraClasses}`}
                        onClick={this.handleContainerClick}
                    >
                        <div
                            className={`label ui-normal-text-size${
                                this.props.disabled ||
                                (this.props.multiselect &&
                                    this.props.selections?.length === 0 &&
                                    !this.props.selectAllByDefault) ||
                                this.props.isEmpty ||
                                this.props.emptyLabel === renderedLabel ||
                                (this.props.grayedEmptyValueSelection &&
                                    this.props.selections?.length === 1 &&
                                    this.props.selections[0] === '')
                                    ? ' type-grey-1'
                                    : ''
                            }`}
                            ref={this.labelRef}
                        >
                            {renderedLabel}
                        </div>
                        {this.state.truncatedLabel ? (
                            <div className="overlay-screen" />
                        ) : null}

                        <div className={this.props.dropdownClassname}>
                            <SvgWrapper fill={this.props.disabled ? '#58728E' : 'black'}>
                                {this.props.dropdownIcon}
                            </SvgWrapper>
                        </div>
                    </div>
                    {list}
                </div>
                {this.props.errorMessage !== undefined ? (
                    <div className="input-error">{this.props.errorMessage}</div>
                ) : null}
            </>
        )
    }
}

MultiSelectList.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    name: PropTypes.string.isRequired,
    selections: PropTypes.array,
    options: PropTypes.array,
    onChange: PropTypes.func,
    inputRef: PropTypes.object,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    required: PropTypes.bool,
    addOn: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    emptyLabel: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    useTextFilter: PropTypes.bool,
    internalDisabled: PropTypes.bool,
    wideDropdown: PropTypes.bool,
    disabledLabel: PropTypes.string,
    getOptionValue: PropTypes.func,
    getOptionText: PropTypes.func,
    compileCustomLabel: PropTypes.func,
    selectAllLabel: PropTypes.string,
    selectAllByDefault: PropTypes.bool,
    multiselect: PropTypes.bool,
    hasGroups: PropTypes.bool,
    isEmpty: PropTypes.bool,
    dropdownIcon: PropTypes.any,
    dropdownClassname: PropTypes.string,
    minDropdownWidth: PropTypes.string,
    itemsToDisplay: PropTypes.number,
    variant: PropTypes.oneOf(['list']),
    open: PropTypes.oneOf(['left', 'right']),
    searchFromBeginning: PropTypes.bool,
    allowDeselectAll: PropTypes.bool,
    allowShowSelected: PropTypes.bool,
    grayedEmptyValueSelection: PropTypes.bool,
    disableVirtualList: PropTypes.bool,
}

MultiSelectList.defaultProps = {
    options: [],
    dropdownIcon: IconCaretDownSvg,
    dropdownClassname: 'dropdown__arrow',
    getOptionValue: (option) => option.value,
    getOptionText: (option) => option.text,
    selectAllLabel: 'All',
    multiselect: false,
    required: false,
    searchFromBeginning: true,
    useTextFilter: false,
    allowShowSelected: true,
}

export const MultiSelectListSkeleton = ({ label = true, labelText }) => {
    return (
        <MultiSelectList
            name="loading"
            options={[]}
            label={label && (labelText || <SkeletonText width={150} />)}
            disabled={true}
        />
    )
}
