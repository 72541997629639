import axios from "axios";
import type {CancelTokenSource} from "axios";

import {get} from "../Requests";
import {toCamelObject} from "../Util";

export default class PromoCodesService {
	/** @member {CancelTokenSource} Axios cancel token */
	cancelSignal: CancelTokenSource;
	
	constructor() {
		this.cancelSignal = axios.CancelToken.source();
	}
	
	getPromoCodes = (): Promise<Object> =>
		get('promo_codes', this.cancelSignal.token)
			.then((response) => toCamelObject(response.data.data));
}