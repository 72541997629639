import React, { Component } from 'react';

import ImageUploadGrid from "../ImageUploadGrid";
import UploadFileButton from "../form_elements/UploadFileButton";
import { get, post, del } from '../../../Requests';
import AreYouSureModal from "../AreYouSureModal";
import Modal from "../../Modal";

export default class EditCompanyLogo extends Component {
    contractorId;

    constructor(props) {
        super(props);

        this.contractorId = props.contractorId;

        this.state = {
            images: [],
            modalContent: undefined,
            modalHeader: undefined,
        };
    }

    componentDidMount() {
        this.getCompanyLogo();
    }

    /**
     * update state with new modal
     **/
    updateModalContent = (content, header) => {
        this.setState({modalContent: content, modalHeader: header});
    };

    /**
     * sets the loading gif and posts the image to the api
     **/
    uploadImage = (event) => {
        let imageClone = [...this.state.images],
            loadingImage = {
              'relative_path': 'loading.gif',
            },
            url = `contractors/${this.contractorId}/logo`,
            file = event.target.files[0],
            formData = new FormData();

        this.setState({images: [loadingImage]});

        formData.append("image", file);

        post(url, formData)
            .then( resp => {
                // Hack Alert: wait 2 seconds after the request comes back to give the background server task
                // on app2 a chance to copy the file to sd2, where as of Aug 2022 images are still hosted.
                // This timeout will no longer be needed if we host images on app2 or use an external Images service.
                // ~ RFM 2022
                setTimeout(() => {
                    if (resp && resp.status === 200 && typeof resp.data === "object") {
                        if (resp.data.image) {
                            this.setState({images: [resp.data.image]});
                        } else {
                            // reset the image to the previous image
                            //    if we don't get an image object back
                            this.setState({images: imageClone})
                        }
                    } else if (resp && resp.status === 400 && typeof resp.data === "object") {
                        let stateClone = {...this.state};
                        let errors = resp.data.errors;

                        if (errors) {
                            stateClone.images = imageClone;
                            this.setState(stateClone);
                        }
                    }
                }, 2000);
            });

        //
        // This is required, otherwise subsequent uploads using the same upload
        // dialog, w/the same file name, will not trigger the file input's
        // onChange event.  This line addresses this bug:
        // https://app.asana.com/0/search/1119585067299069/1118114336433064
        //
        event.target.value = "";
    };

    showAreYouSureModal = (imageId, imageIndex) => {
        this.updateModalContent(
            <AreYouSureModal
                deleteImage={this.deleteImage}
                updateModalContent={this.updateModalContent}
                imageId={imageId}
                imageIndex={imageIndex}
            />, "Are you sure you want to delete the image?");
    };

    /**
     * Takes the image id and sends delete Method to server.
     *    removes the image from the state on success
     **/
    deleteImage = (imageId, imageIndex) => {
        if (!imageId) {
            return;
        }

        let url = `contractors/${this.contractorId}/image/${imageId}`;

        del(url)
            .then(resp => {
               let imageClone = [...this.state.images];
               imageClone.splice(imageIndex,1);

               this.setState({images: imageClone});

               this.updateModalContent(undefined, undefined);
            });
    };

    /**
     * on page load get the existing company logo
     **/
    getCompanyLogo = () => {
        let url = `contractors/${this.contractorId}/logo`,
            images = [];
        get(url)
            .then(resp => {
               if (resp && typeof resp.data === 'object' ) {
                   let logo = resp.data.data.logo;
                   if (logo.length !== 0) {
                       images.push(resp.data.data.logo);
                       this.setState({images: images});
                   }
               }
            });
    };

    render() {
        return (
            <div>
                <Modal
                    header={this.state.modalHeader}
                    content={this.state.modalContent}
                    updateModalContent={this.updateModalContent}
                    size="extra-small"
                />
                <p className="type-normal-subhead type-heavy type-single-line spacing-10-bottom">
                    Company Logo
                </p>
                <p className="type-normal-body spacing-20-bottom">
                    Please provide a high resolution image of your logo.
                    File formats accepted are JPG, PNG, or GIF. Please avoid vertically shaped logos if possible.
                </p>
                <UploadFileButton
                    name="logo"
                    onChange={this.uploadImage}
                    multiple={false}
                    bottomSpacing={this.state.images.length > 0}
                />
                <ImageUploadGrid
                    images={this.state.images}
                    showAreYouSureModal={this.showAreYouSureModal}
                    bottomSpacing={true}
                />
            </div>
        )
    }
}