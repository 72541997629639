import React, {useRef, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";

import {useModal} from "../../common/ModalContext";
import Input from "../../common/components/form_elements/Input";
import RadioGroup from "../../common/components/form_elements/RadioGroup";
import {getAffiliates} from "../../service/AffiliateService";
import MultiSelectList from "../../common/components/form_elements/MultiSelectList";
import {toCamelObject} from "../../Util";
import ExternalBuyerService from "../../service/ExternalBuyerService";
import {errorHandler} from "../../Requests";
import {requireCallerIdOptions, externalBuyerStatusOptions} from "../../common/StaticValues";
import AuthService from "../../service/AuthService";
import Label from "../../common/components/form_elements/Label";
import type {IExternalBuyer} from "../../Interfaces/IExternalBuyer";
import type {IAdNetwork} from "../../Interfaces/IAdNetwork";

export default function EditExternalBuyerModal(props) {
    const externalBuyer: IExternalBuyer = props.externalBuyer;
    const inUpdate = externalBuyer.externalBuyerId > 0;
    const updateModalContent = useModal();
    const modalRef = useRef();
    const apiKeyEditRef = useRef();
    const onOffOptions = [{value: '1', label: 'On'}, {value: '0', label: 'Off'}];
    const emptyRelatedAdNetworkLabel = 'None';
    const [relatedAdNetworkOptions, setRelatedAdNetworkOptions] = useState([{
        text: emptyRelatedAdNetworkLabel,
        value: ''
    }]);
    const [blockedAdNetworkOptions, setBlockedAdNetworkOptions] = useState([]);

    useEffect(() => {
        getAffiliates()
            .then((data) => {
                const adNetworks: IAdNetwork[] = toCamelObject(data.affiliates);
                const nextRelatedAdNetworkOptions = [{text: emptyRelatedAdNetworkLabel, value: ''}];
                const nextBlockedAdNetworkOptions = [];
                adNetworks.forEach((adNetwork: IAdNetwork) => {
                    nextRelatedAdNetworkOptions.push({
                        text: adNetwork.adNetworkName,
                        value: adNetwork.adNetworkId,
                        disabled: adNetwork.externalBuyerId != externalBuyer.externalBuyerId &&
                            adNetwork.externalBuyerId > 0,
                        disabledText: adNetwork.externalBuyerId &&
                            adNetwork.externalBuyerId != externalBuyer.externalBuyerId &&
                            ` ⇒ ${adNetwork.externalBuyerName}`
                    });
                    nextBlockedAdNetworkOptions.push({
                        text: adNetwork.adNetworkName,
                        value: adNetwork.adNetworkId,
                        disabled: adNetwork.adNetworkId == externalBuyer.adNetworkId
                    });
                });
                setRelatedAdNetworkOptions(nextRelatedAdNetworkOptions);
                setBlockedAdNetworkOptions(nextBlockedAdNetworkOptions);
            })
            .catch((error) => {
                console.log('Unable to fetch affiliates', error)
            })
    }, [externalBuyer]);

    const [submitting, setSubmitting] = useState(false);
    const [name, setName] = useState(externalBuyer.name || '');
    const [apiKey, setApiKey] = useState(externalBuyer.apiKey || '');
    const [apiKeyTooltipVisible, setApiKeyTooltipVisible] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [requireCallerId, setRequireCallerId] = useState(externalBuyer.requireCallerId || 'disabled');
    const [callAudioRecording, setCallAudioRecording] = useState(externalBuyer.callAudioRecording || '1');
    const [passSource, setPassSource] = useState(externalBuyer.passSource || '0');
    const [whisperFile, setWhisperFile] = useState(externalBuyer.whisperFile || '0');
    const [enforceCallDuration, setEnforceCallDuration] = useState(externalBuyer.enforceCallDuration || '1');
    const [adNetworkId, setAdNetworkId] = useState(externalBuyer.adNetworkId || '');
    const [blockedAdNetworkIds, setBlockedAdNetworkIds] = useState(externalBuyer.blockedAdNetworkIds || []);
    const [status, setStatus] = useState(externalBuyer.status || 'in development');

    const updateName = (event: KeyboardEvent) => {
        if (nameError) {
            setNameError(false);
        }
        setName(event.target.value);
    }

    /**
     * updates call audio record
     * @param name passed via the RadioGroup component but is not needed in this case
     * @param {boolean} callAudioRecording the call audio recording selected option
     */
    const updateCallAudioRecording = (name, callAudioRecording) =>
        setCallAudioRecording(callAudioRecording);

    /**
     * updates call audio record
     * @param name passed via the RadioGroup component but is not needed in this case
     * @param {boolean} passSource the call audio recording selected option
     */
    const updatePassSource = (name, passSource) =>
        setPassSource(passSource);

    /**
     * updates caller ID requirement
     * @param {number[]} selections the caller ID requirement selected option
     */
    const updateRequireCallerId = (selections) =>
        setRequireCallerId(selections[0]);

    /**
     * updates the whisper file option
     * @param name passed via the RadioGroup component but is not needed in this case
     * @param {number} whisperFile the whisper file selected option
     */
    const updateWhisperFile = (name, whisperFile) =>
        setWhisperFile(whisperFile);

    /**
     * updates the whisper file option
     * @param name passed via the RadioGroup component but is not needed in this case
     * @param {number} enforceCallDuration the whisper file selected option
     */
    const updateEnforceCallDuration = (name, enforceCallDuration) =>
        setEnforceCallDuration(enforceCallDuration);

    const updateRelatedAdNetwork = (selections) => {
        // update the selected ad network id
        setAdNetworkId(selections[0]);

        // update the blocked ad network disabled option
        const nextBlockedAdNetworkOptions = [...blockedAdNetworkOptions];
        nextBlockedAdNetworkOptions.forEach((adNetwork) => adNetwork.disabled = adNetwork.value == selections[0]);
        setBlockedAdNetworkOptions(nextBlockedAdNetworkOptions);

        // update the blocked ad networks: [1] removing the previous option and [2] adding the new option if any
        const nextBlockedAdNetworkIds = blockedAdNetworkIds.filter((value) => value != adNetworkId);
        if (selections[0] && !nextBlockedAdNetworkIds.includes(selections[0])) {
            nextBlockedAdNetworkIds.push(selections[0]);
        }
        setBlockedAdNetworkIds(nextBlockedAdNetworkIds);
    }

    const updateStatus = (selections) =>
        setStatus(selections[0]);

    const submit = () => {
        setSubmitting(true);

        if (!name) {
            return;
        }

        const data: Partial<IExternalBuyer> = {
            name,
            apiKey,
            requireCallerId,
            passSource,
            callAudioRecording,
            whisperFile,
            enforceCallDuration,
            adNetworkId,
            blockedAdNetworkIds,
            status
        };

        const externalBuyerService = new ExternalBuyerService();

        const promise = inUpdate
            ? externalBuyerService.update(externalBuyer.externalBuyerId, data)
            : externalBuyerService.create(data);

        promise.then((response) => {
            const nextExternalBuyer = response.data.externalBuyer;
            const message = inUpdate
                ? `Successfully update the External Lead Buyer ${nextExternalBuyer.name}`
                : `Successfully created the External Lead Buyer ${nextExternalBuyer.name}`;
            props.updateExternalBuyer(nextExternalBuyer, inUpdate);
            props.updateMessageBlocks([message], 'success');
            updateModalContent();
        })
            .catch((error) => {
                if (errorHandler(error)) {
                    setSubmitting(false);
                }
            });
    }

    return <div ref={modalRef}>
        <div className="padding-120-bottom-full padding-120-bottom-tablet padding-30-bottom-mobile">
            <div className="popup__form__row spacing-20-bottom">
                <Label name="name"
                       label="External Lead Buyer Name"
                       className="popup__form__cell popup__form__cell__40"
                       required={true}
                       hasError={nameError}/>
                <div className="popup__form__cell popup__form__cell__60">
                    <Input
                        type="text"
                        name="name"
                        value={name}
                        hasError={nameError}
                        onChange={updateName}
                        required={true}
                        readOnly={!!externalBuyer.externalBuyerId}/>
                </div>
            </div>
            {externalBuyer.externalBuyerId &&
                <div className="popup__form__row spacing-20-full">
                    <div className="popup__form__cell popup__form__cell__40">
                        <Label name="externalBuyerId"
                               modalContainerRef={modalRef}
                               label="External Lead Buyer ID"
                               tooltipPosition="bottom"
                               tooltipContent={<span className="type-small-body">This read-only field displays a
                               system-generated identifier for the External Lead Buyer. You cannot modify this value as
                               it is automatically generated by the system.</span>}/>
                    </div>
                    <div className="popup__form__cell popup__form__cell__60">
                        <Input
                            type="text"
                            name="externalBuyerId"
                            value={externalBuyer.externalBuyerId}
                            readOnly={true}/>
                    </div>
                </div>}
            <div className="popup__form__row spacing-20-full">
                <div className="popup__form__cell popup__form__cell__40">
                    <Label name="apiKey"
                           modalContainerRef={modalRef}
                           tooltipContent={<span className="type-small-body">The API Key provided by the External Lead Buyer that is required to connect
                               to their API.</span>}
                           label="External Lead Buyer API Key"/>
                </div>
                <div className="popup__form__cell popup__form__cell__60">
                    <div className="input-group">
                        <Input
                            className="input-field"
                            type="text"
                            name="apiKey"
                            value={apiKey}
                            onChange={(event: KeyboardEvent) =>
                                setApiKey(event.target.value)}
                            readOnly={!!externalBuyer.externalBuyerId}/>
                        {externalBuyer.externalBuyerId &&
                            <Tippy
                                content={<>
                                    <Input type="text"
                                           label="Edit External Lead Buyer API Key"
                                           inputRef={apiKeyEditRef}
                                           defaultValue={apiKey}
                                           name="apiKeyEdit"/>
                                    <div className="spacing-10-top">
                                        <button type="button"
                                                onClick={() => {
                                                    setApiKey(apiKeyEditRef.current.value);
                                                    setApiKeyTooltipVisible(false);
                                                }}
                                                className="button ui-normal-button no-margin">
                                            Update
                                        </button>
                                        <button type="button"
                                                onClick={() => setApiKeyTooltipVisible(false)}
                                                className="button-clean type-blue">
                                            Cancel
                                        </button>
                                    </div>
                                </>}
                                allowHTML={true}
                                visible={apiKeyTooltipVisible}
                                appendTo={() => document.body}
                                interactive={true}
                                theme="sd-tooltip"
                            >
                                <div className="button-clean type-blue input-addon"
                                     role="button"
                                     onClick={() => setApiKeyTooltipVisible(!apiKeyTooltipVisible)}>
                                    Edit
                                </div>
                            </Tippy>}
                    </div>
                </div>
            </div>
            <div className="popup__form__row spacing-20-full">
                <div className="popup__form__cell popup__form__cell__40">
                    <Label modalContainerRef={modalRef}
                           tooltipPosition="bottom"
                           tooltipContent={<div className="type-small-body">
                               This setting determines which Pings we receive from Lead Sources can be passed through to
                               the External Lead Buyer (ELB) based on the ELB's Caller ID requirement.
                               <ul>
                                   <li><span className="type-heavy">Disabled</span> - The system will not send Caller ID
                                       to the ELB.
                                   </li>
                                   <li><span className="type-heavy">Optional</span> - The system will send all Pings to
                                       the ELB and include Caller ID in the Ping and/or Post if the Lead Source has
                                       provided it.
                                   </li>
                                   <li><span className="type-heavy">Required in Post</span> - The system will only send
                                       Pings to the ELB if a Lead Source has guaranteed that they are providing Caller
                                       ID in the Post or the Lead Source has provided Caller ID in the Ping.
                                   </li>
                                   <li><span className="type-heavy">Required in Ping (and Post)</span> - The system will
                                       only send Pings to the ELB if a Lead Source has provided Caller ID in the Ping.
                                       If the ELB also requires Caller ID in Post, the system will use the Caller ID
                                       provided by the Lead Source in their Ping to populate the ELB's Post.
                                   </li>
                               </ul>
                           </div>}
                           label="Caller ID Requirement"/>
                </div>
                <div className="popup__form__cell popup__form__cell__60">
                    <MultiSelectList
                        name="requireCallerId"
                        selections={[requireCallerId]}
                        options={requireCallerIdOptions}
                        onChange={updateRequireCallerId}/>
                </div>
            </div>
            <div className="popup__form__row spacing-20-full">
                <div className="popup__form__cell popup__form__cell__40">
                    <Label modalContainerRef={modalRef}
                           tooltipContent={<span className="type-small-body">Setting that determines if Service Direct will pass the Source (aka SubID) field to the ELB.</span>}
                           label="Pass Source to ELB"/>
                </div>
                <RadioGroup
                    showLabel={false}
                    name="passSource"
                    isClearable={false}
                    checkedOption={passSource}
                    options={onOffOptions}
                    onChange={updatePassSource}/>
            </div>
            <div className="popup__form__row spacing-20-full">
                <div className="popup__form__cell popup__form__cell__40">
                    <Label modalContainerRef={modalRef}
                           tooltipContent={<span className="type-small-body">Setting that determines if Service Direct will record the phone call audio.</span>}
                           label="Call Audio Recording"/>
                </div>
                <RadioGroup
                    showLabel={false}
                    name="callAudioRecording"
                    isClearable={false}
                    checkedOption={callAudioRecording}
                    options={onOffOptions}
                    onChange={updateCallAudioRecording}/>
            </div>
            <div className="popup__form__row spacing-20-full">
                <div className="popup__form__cell popup__form__cell__40">
                    <Label modalContainerRef={modalRef}
                           tooltipContent={<span className="type-small-body">Setting that determines if Service Direct plays an informational message that
                            the call is being recorded. If the audio is being recorded, this message must be played by
                               at least one party involved in the lead transaction.</span>}
                           label="Whisper File"/>
                </div>
                <RadioGroup
                    showLabel={false}
                    name="whisperFile"
                    isClearable={false}
                    checkedOption={whisperFile}
                    options={onOffOptions}
                    onChange={updateWhisperFile}/>
            </div>
            <div className="popup__form__row spacing-20-full">
                <div className="popup__form__cell popup__form__cell__40">
                    <Label modalContainerRef={modalRef}
                           tooltipContent={<span className="type-small-body">This setting restricts Leads sold to
                               External Lead Buyers, guaranteeing that the Lead Source's Payable Call Duration is equal
                               to or greater than the External Lead Buyer's Billable Call Duration. This ensures we
                               don't pay the Lead Source for a Phone Call if we wouldn't receive payment from the
                               External Lead Buyer. This rule only applies to Lead Sources with a Call Duration Payout
                               Model.</span>}
                           label="Enforce Billable Call Duration"/>
                </div>
                <RadioGroup
                    showLabel={false}
                    name="enforceCallDuration"
                    isClearable={false}
                    checkedOption={enforceCallDuration}
                    options={onOffOptions}
                    onChange={updateEnforceCallDuration}/>
            </div>
            <div className="popup__form__row spacing-20-full">
                <div className="popup__form__cell popup__form__cell__40">
                    <Label modalContainerRef={modalRef}
                           tooltipContent={<span className="type-small-body">This setting is to link an External Lead Buyer to their Lead Source record.
                               This is required so we don’t sell leads back to their source.</span>}
                           label="Related Lead Source"/>
                </div>
                <div className="popup__form__cell popup__form__cell__60">
                    <MultiSelectList
                        name="adNetworkId"
                        selections={[adNetworkId]}
                        options={relatedAdNetworkOptions}
                        onChange={updateRelatedAdNetwork}
                        useTextFilter={true}
                        disableVirtualList={true}
                        grayedEmptyValueSelection={true}
                        searchFromBeginning={false}/>
                </div>
            </div>
            <div className="popup__form__row spacing-20-full">
                <div className="popup__form__cell popup__form__cell__40">
                    <Label modalContainerRef={modalRef}
                           tooltipContent={<span className="type-small-body">This setting captures the Lead Sources that an External Lead Buyer should
                               never buy from.</span>}
                           label="Blocklisted Lead Sources"/>
                </div>
                <div className="popup__form__cell popup__form__cell__60">
                    <MultiSelectList
                        name="blockedAdNetworkIds"
                        selections={blockedAdNetworkIds}
                        options={blockedAdNetworkOptions}
                        onChange={setBlockedAdNetworkIds}
                        useTextFilter={true}
                        searchFromBeginning={false}
                        disableVirtualList={true}
                        multiselect={true}
                        emptyLabel="None"/>
                </div>
            </div>
            {externalBuyer.externalBuyerId &&
                <div className="popup__form__row spacing-20-full">
                    <div className="popup__form__cell popup__form__cell__40">
                        <Label modalContainerRef={modalRef}
                               tooltipContent={<ul className="type-small-body">
                                   <li><span className="type-heavy">In Development</span> - API integration is being
                                       developed
                                   </li>
                                   <li><span className="type-heavy">In Setup</span> - API integration and testing
                                       complete, configuration of Service
                                       Categories can take place
                                   </li>
                                   <li><span className="type-heavy">Ping Only</span> - Service Direct will Ping the
                                       External Lead Buyer can store
                                       responses, but will not sell Leads to the
                                       External Lead Buyer
                                   </li>
                                   <li><span className="type-heavy">Ping and Post</span> - Service Direct will sell
                                       Leads to the External Lead Buyer
                                   </li>
                                   <li><span className="type-heavy">Deactivated</span> - Service Direct is neither
                                       Pinging or Posting (aka selling)
                                       Leads to the External Lead Buyer
                                       anymore
                                   </li>
                               </ul>}
                               label="Status"/>
                    </div>
                    <div className="popup__form__cell popup__form__cell__60">
                        <MultiSelectList
                            name="status"
                            selections={[status]}
                            options={externalBuyerStatusOptions}
                            onChange={updateStatus}
                            readOnly={!AuthService.isDeveloper && status === 'in development'}/>
                    </div>
                </div>}
        </div>

        <footer className="popup__form__gray-bar fixed padding-24 no-padding-mobile">
            <div className="popup__form__row popup__form__row__slam-right popup__form__row-column-mobile">
                <div className="popup__form__cell ui-hide-mobile-important">
                    <button onClick={() => updateModalContent()}
                            disabled={submitting}
                            className="button-clean type-small-body type-heavy type-blue ui-full-width-button-mobile">
                        Cancel
                    </button>
                </div>
                <div className="popup__form__cell popup__form__cell__100__mobile">
                    {submitting &&
                        <button className="button button__green ui-normal-button ui-full-width-button-mobile"
                                disabled>Processing...</button>}
                    {!submitting &&
                        <button className="button ui-normal-button ui-full-width-button-mobile"
                                onClick={submit}>
                            {inUpdate ? 'Save Changes' : 'Create External Lead Buyer'}
                        </button>}
                </div>
            </div>
        </footer>
    </div>;
}

EditExternalBuyerModal.propTypes = {
    externalBuyer: PropTypes.object,
    updateExternalBuyer: PropTypes.func,
    updateMessageBlocks: PropTypes.func
}
