import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LoadingGif from './components/LoadingGif';

export default class ContextualMenu extends Component {
    constructor(props) {
        super(props);

        this.launcherRef = React.createRef();

        this.state = {
            isOpen: !!props.forceOpen,
            bodyClickListener: null,
            windowScrollListener: null,
            scrollboxScrollListener: null,
            resizeListener: null,
            ranOnFirstOpen: false,
            menuLinks: props.menuLinks,
            top: 0,
            left: 0,
        };
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (this.props.menuLinks != prevProps.menuLinks) {
            this.setState({menuLinks: this.props.menuLinks});
        }
        if (this.props.forceOpen != prevProps.forceOpen) {
            this.setState({isOpen: this.props.forceOpen});
        }
    }

    toggleMenu = () => {
        if (this.state.isOpen) {
            this.closeTooltip();
        }
        else {
            this.openTooltip();
        }
    };

    openTooltip = () => {
        // Set a click handler on the body to close the menu.
        // Store the click handler in state so that it can be removed.
        let bodyClickListener = {
            handleEvent: this.closeTooltip
        };

        // Set event handlers to track launcher link movement in viewport for repositioning the menu.
        // Store the event handlers in state so that they can be removed.
        let windowScrollListener = {
            handleEvent: this.positionMenu
        };
        let scrollboxScrollListener = {
            handleEvent: this.positionMenu
        };
        let resizeListener = {
            handleEvent: this.positionMenu
        };

        this.positionMenu();

        if (typeof this.props.onFirstOpen === 'function' && !this.state.ranOnFirstOpen) {
            this.props.onFirstOpen();
        }
        this.setState({
            isOpen: true,
            bodyClickListener: bodyClickListener,
            windowScrollListener: windowScrollListener,
            scrollboxScrollListener: scrollboxScrollListener,
            resizeListener: resizeListener,
            ranOnFirstOpen: true
        });
        document.body.addEventListener('click', bodyClickListener);

        window.addEventListener('scroll', windowScrollListener);
        if (this.props.scrollboxRef && this.props.scrollboxRef.current) {
            this.props.scrollboxRef.current.addEventListener('scroll', scrollboxScrollListener);
        }
        window.addEventListener('resize', resizeListener);

        if (typeof this.props?.onToggle === 'function') {
            this.props.onToggle('open');
        }
    };

    closeTooltip = (event) => {
        // don't close when click is inside of menu
        let ignoreClickOnMenu = '.contextual-menu__container *';
        if (!event.target.matches(ignoreClickOnMenu)) {
            // Don't reopen the menu on link click
            event.preventDefault();
            event.stopPropagation();

            // Remove the body click handler
            if (this.state.bodyClickListener) {
                document.body.removeEventListener('click', this.state.bodyClickListener);
            }

            // Remove menu positioning event handlers
            if (this.state.windowScrollListener) {
                window.removeEventListener('scroll', this.state.windowScrollListener);
            }
            if (this.state.scrollboxScrollListener && this.props.scrollboxRef && this.props.scrollboxRef.current) {
                this.props.scrollboxRef.current.removeEventListener('scroll', this.state.scrollboxScrollListener);
            }
            if (this.state.resizeListener) {
                window.removeEventListener('resize', this.state.resizeListener);
            }

            this.setState({
                isOpen: false,
                bodyClickListener: null,
                windowScrollListener: null,
                scrollboxScrollListener: null,
                resizeListener: null,
            });

            if (typeof this.props?.onToggle === 'function') {
                this.props.onToggle('close');
            }
        }
    };

    handleMouseEnter = (link, index) => {
        const {menuLinks} = this.state;
        menuLinks[index].hover = true;
        this.setState({menuLinks});
    };

    handleMouseLeave = (link, index) => {
        const {menuLinks} = this.state;
        menuLinks[index].hover = false;
        this.setState({menuLinks});
    };

    positionMenu = () => {
        const { top, left, width, height } = this.launcherRef.current.getBoundingClientRect();

        this.setState({
            top: top + (height / 2),
            left: left + (width / 3) });
    };

    render() {
        return (
            <span className={`contextual-menu__wrapper${this.props.forceOpen ? ' force-open' : ''}`}>
                {this.props.linkLabel &&
                <span
                    role="button"
                    ref={ this.launcherRef }
                    className="contextual-menu__launcher"
                    onClick={(e) => {
                        e.stopPropagation();
                        this.toggleMenu();
                    }}
                >
                    {this.props.linkLabel}
                </span>}
                <span
                    className={`contextual-menu__container${this.state.isOpen ? ' contextual-menu__expanded' : ''}`}
                    onClick={(e) => e.stopPropagation()}
                    style={ { 'top': `${this.state.top}px`, 'left': `${this.state.left}px` }}
                >
                    <ul>
                        {this.state.menuLinks.map((link, idx) => (
                            <li key={`cm-${idx}`} onMouseEnter={() => this.handleMouseEnter(link, idx)}
                                onMouseLeave={() => this.handleMouseLeave(link, idx)}>
                                <a href={link.url} target={link.self ? '_self' : "_blank"}
                                   rel="noopener noreferrer">
                                    {link.label}
                                </a>
                                {link.subMenu === null
                                    ? <LoadingGif />
                                    : (Array.isArray(link.subMenu) &&
                                        <div className="submenu-button">
                                            &gt;
                                            {link.hover &&
                                            <ContextualMenu menuLinks={link.subMenu} forceOpen={link.hover} scrollboxRef={this.props.scrollboxRef} />}
                                        </div>)}
                            </li>
                        ))}
                    </ul>
                </span>
            </span>
        );
    }
}

ContextualMenu.propTypes = {
    menuLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
    linkLabel: PropTypes.string,
    onFirstOpen: PropTypes.func,
    forceOpen: PropTypes.bool,
    onToggle: PropTypes.func
};
