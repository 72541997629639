import React from 'react';

import Checkbox from '../../../common/components/form_elements/Checkbox';
import InputWithButton from "../../components/form_elements/InputWithButton";

const LostReasonBlock = (props) => {
    let lostReasons = props.lostReasons || {};
    const {leadId} = props
    // let leadId = props.lead.lead_id,
    const lostReasonOptions = [
        {
            name: `lost_reason_${leadId}_1`,
            label: "Chose Other Company",
            value: "1"
        },
        {
            name: `lost_reason_${leadId}_2`,
            label: "Pricing",
            value: "2"
        },
        {
            name: `lost_reason_${leadId}_3`,
            label: "Not Ready to Buy",
            value: "3"
        },
        {
            name: `lost_reason_${leadId}_4`,
            label: "Couldn't Service",
            value: "4"
        }
    ];

    let hasLostReason = (checkboxReasonId) =>
        lostReasons[checkboxReasonId] !== undefined;

    let changeLostReason = (event) => {
        let selectedLostReasons = {...lostReasons},
            checked = event.target.checked,
            elId = event.target.id,
            reasonIdPieces = elId.split("_"),
            reasonId = reasonIdPieces[reasonIdPieces.length - 1];

        //
        // If the reason was checked, add it to the lead's lost
        // reasons, otherwise remove it.
        //
        if (checked) {
            selectedLostReasons[reasonId] = "";
        }
        else {
            delete selectedLostReasons[reasonId];
        }
        props.updateLeadProgress({lost_reasons: selectedLostReasons})
        // props.updateLead(props.lead.lead_id, {lost_reasons: selectedLostReasons});
    }

    let lostReasonJsx = lostReasonOptions.map((option) =>
        <div key={option.value} className={`type-normal-body type-single-line ${option.value > 1 ? 'spacing-20' : 0}`}>
            <Checkbox
                name={option.name}
                label={option.label}
                value={option.value}
                checked={hasLostReason(option.value)}
                onChange={changeLostReason}
            />
        </div>
    );

    let otherReasonTxt = lostReasons[5] || "";

    const saveOtherReason = (event) => {
        event.preventDefault();

        let selectedLostReasons = {...lostReasons};
        selectedLostReasons[5] = document.getElementById("lost_reason_other_" + leadId).value;

        props.updateButtonState(props.buttonStateOptions.SAVING);
        props.updateLeadProgress({lost_reasons: selectedLostReasons})
            .then(() => props.updateButtonState(props.buttonStateOptions.DISABLED))
            .catch(() => props.updateButtonState(props.buttonStateOptions.DISABLED_SAVE));
    };

    return <>
        <div className="leadlog__revenue">
            <div className="no-margin-top spacing-20-bottom-mobile spacing-34-bottom-full spacing-34-bottom-tablet">
                {lostReasonJsx}
                <form className="spacing-20" onSubmit={saveOtherReason}>
                    <InputWithButton
                        className="ui-text-field ui-normal-text-input inline spacing-10-bottom-mobile"
                        name="other-lost-reason"
                        type="text"
                        id={"lost_reason_other_" + leadId}
                        onChange={() => props.updateButtonState(props.buttonStateOptions.ENABLED)}
                        onFocus={() => props.updateButtonState(props.buttonStateOptions.ENABLED)}
                        placeholder="Other"
                        defaultValue={otherReasonTxt}
                        buttonOnClick={saveOtherReason}
                        buttonType="submit"
                        buttonText={props.parsedButtonState.label}
                        buttonDisabled={props.parsedButtonState.disabled}/>
                </form>
            </div>
        </div>
    </>;
};

export default LostReasonBlock;
