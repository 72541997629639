import React, {Component} from 'react';
import {errorHandler, get} from '../../Requests';
import axios from 'axios';

import PaymentConfirmationModal from './PaymentConfirmationModal';

import PropTypes from 'prop-types';
import Pagination from "../../common/components/Pagination/v1.0.1/Pagination";
import {formatCurrency, getCurrentDate} from "../../Util";
import apiUrl from "../../Config";
import StatementService from "../../service/StatementService";

export default class StatementsSection extends Component {

    statementService;

    constructor(props) {
        super(props);

        // set the initial state
        this.state = {
            statements: [],
            pagination: {
                rows_per_page: process.env.REACT_APP_PAGINATION_NUM_PER_PAGE,
                page_num: 1,
                total_rows: null,
                total_pages: null,
                page_min_row_number: 1,
                page_max_row_number: process.env.REACT_APP_PAGINATION_NUM_PER_PAGE
            }
        };

        this.cancelSignal = axios.CancelToken.source();

        this.statementService = new StatementService();
    }

    componentDidMount() {
       this.getStatements();
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
        this.statementService.cancelSignal.cancel();
    }

    /**
     * Receives a new page number and forwards it to getStatements
     * @param event
     * @param newPageNum
     */
    handlePageChange = (event, newPageNum) => {
        event.preventDefault();
        this.getStatements(newPageNum);
    };

    /**
    * Gets the statements for this contractor
    **/
    getStatements = (pageNum) => {
        let url = "statements?";

        pageNum = pageNum || 1;

        url+="&pagination[rows_per_page]="+this.state.pagination.rows_per_page;
        url+="&pagination[page_num]="+pageNum;

        get(url, this.cancelSignal.token)
            .then( resp => {
                //
                // If for example the request was canceled, return
                //
                if (!resp) return;

                if (resp.data) {
                    let statements = resp.data.data.statements,
                        paginationValues = resp.data.pagination;

                    this.setState({
                        statements: statements,
                        pagination: {
                            rows_per_page: paginationValues.rows_per_page,
                            total_rows: paginationValues.total_rows,
                            total_pages: paginationValues.total_pages,
                            page_num: paginationValues.page_num,
                            page_min_row_number: paginationValues.page_min_row_number,
                            page_max_row_number: paginationValues.page_max_row_number
                        }
                    });
                }
            })
            .catch(errorHandler);
    };

    /**
     * payStatement()
     * @param index int - "key" prop of the <td> row of the Statement to pay
     * @param callback function - gets called 1st thing upon AJAX response
     */
    payStatement = (index, callback) => {
        let statements = [...this.state.statements];

        this.statementService.payStatement(statements[index].invoice_id)
            .then((response) => {
                //
                // We may later want the axios interceptor should catch 400/404 errors and they should never get here.
                // But in this case at least, it is are getting through, so early return here.
                // NOTE: I couldn't access the response.status so instead tested for an 'errors' key.
                // ~ RFM 2020-04-21
                //
                if ('errors' in response) {
                    // don't close modal or show success msg
                    return;
                }

                let statement = response.data.statement;
                statement.date = getCurrentDate();

                statements[index] = statement;
                this.setState({statements});

                // close window
                this.props.updateModalContent();
                // give success messaging
                this.props.updateMessageBlocks(response.message, "success");
                this.props.updateBalanceSummary();
                this.props.refreshMajorAlerts();
            })
            .catch(errorHandler)
            // handles Submit Button state - do it regardless of success or fail
            .finally(callback);
    };

    /**
     * opens the payment confirmation modal
     * @param {object} statement - the target statement
     * @param {int} key - the index of the statement in the component's statements array
     */
    payNowModal = (statement, key) => {
        // TODO: Re-connect payment API action with modal payment confirmation
        // this.payStatement(key);
        this.props.updateModalContent(
            <PaymentConfirmationModal
                statement={{...statement}}
                payStatement={(callback) => {
                    //
                    // key variable comes from outer <tr> for Statement
                    //
                    this.payStatement(key, callback);
                }}
                submitPayment={this.props.submitPayment}
                primaryCardLastFour={this.props.primaryCardLastFour}
                updateModalContent={this.props.updateModalContent}
            />,
            'Make Payment',
            null,
            true
        );
    };

    render() {
        const StatementRows = this.state.statements.map((statement, key) => {

            let invoicePdfUrl = apiUrl + "statements/" + statement.invoice_id + "/pdf";

            const invoiceLink = <a
                href={invoicePdfUrl}
                className="type-heavy"
                target="_blank">
                { statement.original_invoice_id }
            </a>;
            let amountTotal = parseFloat(statement.amount_total);
            let amountPaid = parseFloat(statement.amount_paid);
            let amountCredited = parseFloat(statement.amount_credited);
            let totalDisplay = parseFloat(statement.amount_total - statement.amount_credited);
            let unpaid = amountTotal > amountPaid + amountCredited;

            return (
                <tr 
                    className={ unpaid ? "type-normal-body statements__statement-row statements__unread" : "type-normal-body statements__statement-row"}
                    key={"statement-row-"+key}
                >
                    <td className="statements__col statements__col__flag-wrapper">
                        {(unpaid) ? (
                            <div className="type-small-body type-single-line statements__unpaid-tag">
                                Unpaid
                            </div>
                        ) : null }
                    </td>
                    <td headers="statement-number"
                        className="statements__col statements__col__number">
                        <span className="ui-hide-mobile">
                            { invoiceLink }
                        </span>
                        <span className="type-large-subhead ui-hide-full ui-hide-tablet">
                            { invoiceLink }
                        </span>
                    </td>
                    <td headers="statement-date"
                        className="statements__col statements__col__date">
                        <span className="type-regular ui-hide-full ui-hide-tablet">Date: </span>
                        { statement.date }
                    </td>
                    <td headers="statement-description"
                        className="statements__col statements__col__description">
                        <span className="type-regular ui-hide-full ui-hide-tablet">Description: </span>
                        { statement.description }
                    </td>
                    <td headers="statement-card"
                        className="statements__col statements__col__card">
                        <span className="type-regular ui-hide-full ui-hide-tablet">Card: </span>
                        …{statement.last_four}
                    </td>
                    <td headers="statement-amount"
                        className="statements__col statements__col__amount">
                        <span className="type-regular ui-hide-full ui-hide-tablet">Amount: </span>
                        {formatCurrency(totalDisplay)}
                    </td>
                    <td headers="statement-status"
                        className="statements__col statements__col__status">
                        <span className="type-force-newline-mobile spacing-10-bottom-mobile">
                            <span className="type-regular ui-hide-full ui-hide-tablet">Status: </span>
                            <span className={ unpaid ? "type-heavy type-alert" : ""}>{ unpaid ? "Unpaid" : "Paid" }</span>
                        </span>
                        {unpaid ? (
                            <button 
                                className="button ui-small-button type-no-break"
                                onClick={() => this.payNowModal(statement, key)}
                            >
                                Pay Now
                            </button>
                        ) : null}
                    </td>
                </tr>
            )
        });


        return (

            <div className="row">
            <div className="wide-col-wrapper">
                <div className="wide-format-col">
                    <div className="module">
                        <div className="module__header module__fullwidth">
                            <h3 id="billing-center-statements-table" className="type-large-subhead type-single-line">
                                Statements
                            </h3>
                        </div>
                        <div className="module__contentbox module__fullwidth module__contentbox__invisible__mobile">
                            <table className="statements__table standardtable">
                                <thead className="standardtable__lightheader">
                                <tr className="type-small-body type-heavy statements__header-row">
                                    <th className="statements__col__flag-wrapper"></th>
                                    <th id="statement-number"
                                        className="statements__col__number">
                                        <div className="statements__header__label">
                                            Statement
                                        </div>
                                    </th>
                                    <th id="statement-date" className="statements__col__date">
                                        <div className="statements__header__label">
                                            Date
                                        </div>
                                    </th>
                                    <th id="statement-description"
                                        className="statements__col__description">
                                        <div className="statements__header__label">
                                            Description
                                        </div>
                                    </th>
                                    <th id="statement-card" className="statements__col__card">
                                        <div className="statements__header__label">
                                            Card
                                        </div>
                                    </th>
                                    <th id="statement-amount"
                                        className="statements__col__amount">
                                        <div className="statements__header__label">
                                            Amount
                                        </div>
                                    </th>
                                    <th id="statement-status"
                                        className="statements__col__status">
                                        <div className="statements__header__label">
                                            Status
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    { StatementRows }
                                </tbody>
                            </table>
                            { /* Full Desktop */ }
                            <div className="spacing-34-top ui-hide-mobile ui-hide-tablet">
                                <div className="simpleflex__row">
                                    <div className="simpleflex__cell simpleflex__cell__middlealigned">
                                    </div>
                                    <div className="simpleflex__cell simpleflex__cell__middlealigned simpleflex__cell__one-half">
                                        <Pagination
                                            pageNum={this.state.pagination.page_num}
                                            totalRows={this.state.pagination.total_rows}
                                            rowsPerPage={this.state.pagination.rows_per_page}
                                            totalPages={this.state.pagination.total_pages}
                                            handlePageChange={ this.handlePageChange }
                                        />
                                    </div>
                                </div>
                            </div>
                            { /* Tablet */ }
                            <div className="spacing-34-top spacing-left ui-hide-mobile ui-hide-full">
                                <div className="simpleflex__row">
                                    <div className="simpleflex__cell simpleflex__cell__middlealigned simpleflex__cell__one-half">
                                    </div>
                                    <div className="simpleflex__cell simpleflex__cell__middlealigned">
                                        <Pagination
                                            pageNum={this.state.pagination.page_num}
                                            totalRows={this.state.pagination.total_rows}
                                            rowsPerPage={this.state.pagination.rows_per_page}
                                            totalPages={this.state.pagination.total_pages}
                                            handlePageChange={ this.handlePageChange }
                                        />
                                    </div>
                                </div>
                            </div>
                            { /* Mobile */ }
                            <div className="spacing-34-top ui-hide-full ui-hide-tablet">
                                <Pagination
                                    pageNum={this.state.pagination.page_num}
                                    totalRows={this.state.pagination.total_rows}
                                    rowsPerPage={this.state.pagination.rows_per_page}
                                    totalPages={this.state.pagination.total_pages}
                                    handlePageChange={ this.handlePageChange }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clear-block"></div>
            </div>
            </div>
        )       
    }
} 

StatementsSection.propTypes = {
    updateAppStatements: PropTypes.func,
}

