import React from 'react';

export const CallerIdTooltip = (isSelect = true) => <>
    <span
        className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
        Caller ID Preference
    </span>
    <div
        className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
        When you receive an inbound Phone Lead, you have the options to see:
        <ul>
            <li>The inbound caller's Caller ID</li>
            <li>The SD Lead Caller ID Number, (512) 877-3656</li>
            {isSelect && <li>For Select Campaigns: A Tracking Number from any Select Campaign</li>}
        </ul>
    </div>
    <span className="type-small-body type-black type-force-newline type-notransform spacing-10-bottom">
        We recommend setting the Caller ID Settings to the SD Lead Caller ID Number,
        so that you always know when a Service Direct Lead is calling you.
        This ensures that your team knows to answer the phone and that you don't miss an opportunity.
    </span>
    <span className="type-small-body type-black type-force-newline type-notransform spacing-10-bottom">
        Tip: Save the SD Lead Caller ID Number as a contact in your phone.
        If you see a missed call from a Service Direct campaign, you will know to
        quickly check your email or login to your Lead Manager to follow-up.
    </span>
</>
