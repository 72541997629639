import React, {Component} from "react";
import downloadJs from "downloadjs";

import ResourcesService from "../../service/ResourcesService";

/**
 * this whole component is used to redirect [authenticated] users to the correct step in case of no specific path
 */
export default class MpTargetedZips extends Component {
    constructor(props) {
        super(props);
        document.title = 'MP Targeted Zips';

        this.state = {
            targetedZips: []
        };

        this.resourcesService = new ResourcesService();
    }

    componentDidMount() {
        this.resourcesService.getTargetedZips()
            .then((data) => this.setState({targetedZips: data.targetedZips}));
    }

    componentWillUnmount() {
        this.resourcesService.cancelSignal.cancel();
    }

    downloadIndustry = (industryName) => {
        if (industryName) {
            downloadJs(this.state.targetedZips[industryName].join('\n'), industryName, 'text/csv; charset=utf8');
            return;
        }

        let tableHeaders = Object.keys(this.state.targetedZips);

        let maxCount = 0;
        tableHeaders.forEach((industryName) => {
            maxCount = this.state.targetedZips[industryName].length > maxCount
                ? this.state.targetedZips[industryName].length
                : maxCount;
        });

        let data = '';
        function appendData(industryName, i, zip) {
            if (i == -1) {
                data += industryName + ',';
            }
            else {
                data += (zip || '') + ',';
            }
        }
        for (let i = -1; i < maxCount; i++) {
            tableHeaders.forEach((industryName) =>
                appendData(industryName, i, this.state.targetedZips[industryName][i]));
            data = data.replace(/,$/, "\n");
        }
        // data += industryName + ',' + this.state.targetedZips[industryName].join(',') + "\n"

        downloadJs(
            data,
            'all-marketplace-targeted-zips',
            'text/csv; charset=utf8'
        );
    };

    render() {
        let tableHeaders = Object.keys(this.state.targetedZips);

        return <section className="white-background">
            <div className="page-width">
                <div className="row padding-120-bottom">
                    <div className="col alpha omega grid-12">
                        <table className="type-normal-body">
                            <thead>
                            <tr>
                                <th className="type-heavy padding-20 type-align-left">Industry Name</th>
                                <th className="type-heavy padding-20">
                                    Zip Code Count (Click to download list)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th style={{padding: '5px', textAlign: 'left'}}>All Industries</th>
                                <td>
                                    <button className="button-clean type-blue" onClick={() => this.downloadIndustry()}>
                                        Download all
                                    </button>
                                </td>
                            </tr>
                            {tableHeaders.map((industryName: string, index) =>
                                <tr key={`row-${index}`}>
                                    <th style={{padding: '5px', textAlign: 'left'}}>{industryName}</th>
                                    <td style={{padding: '5px'}}>
                                        <button className="button-clean type-blue"
                                                onClick={() => this.downloadIndustry(industryName)}>
                                            {this.state.targetedZips[industryName].length}
                                        </button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>;
    }
}
