import React from 'react';
import { inputObj, setInputValue } from '../formHelpers';
import CostPerLeadForm from './CostPerLeadForm';
import FormModal from '../common/components/modal/FormModal';
import AuthService from "../service/AuthService";
import {CampaignTypes} from "./StaticValues";

/**
 * CostPerLeadModal
 *
 * Extends FormModal (subclass of React.Component) in order to get desired
 * scroll-to-top behavior each time the modal is opened.
 */
export default class CostPerLeadModal extends FormModal {
    constructor(props) {
        super(props);
        let campaign = this.props.campaign;
        let cpl = campaign.tender
            ? campaign.tender[0].price_per_lead
            : 0;

        this.state = {
            campaign : campaign,
            inputs : {
                cpl : inputObj(cpl)
            },

            //
            // Set to true when form is submitting in order to
            // visually disable the submit button
            //
            submitting: false
        };

        this.isMarketplace = campaign.type === CampaignTypes.MARKETPLACE;
    }

    render() {
        const campaign = this.props.campaign;
        const min = this.isMarketplace
            ? campaign.industry.min_suggested_tender_mp
            : campaign.industry.min_suggested_tender;
        const max = this.isMarketplace
            ? campaign.industry.max_suggested_tender_mp
            : campaign.industry.max_suggested_tender;

        return (
            <>
            <p className="type-normal-body no-margin-top spacing-30-bottom">
                {this.isMarketplace
                    ? <>Your Cost Per Lead is the amount that you will pay for each Billable Phone Call Lead generated
                        for this Campaign. If you want more Leads, increase your Cost Per Lead.
                        If you want fewer Leads, reduce your Cost Per Lead.</>
                    : <>Your Cost Per Lead is the amount that you will pay for each Billable Lead (both Phone Call and Form)
                        generated for this campaign.</>}
            </p>
            <p className="type-normal-body spacing-30">
                <a href={AuthService.isMarketplace
                    ? "https://support.servicedirect.com/lead-volume"
                    : "https://support.servicedirect.com/select/adjust-cost-per-lead"}
                   target="_blank" className="type-heavy" rel="noopener">Learn More</a>
                {' '}
                about how your Cost Per Lead impacts your lead volume.
            </p>
            <form onSubmit={ (event) => {
                    event.preventDefault();
                    this.setState({submitting: true});
                    //
                    // NOTE - the callback passed as the 3rd arg is dirty, because we'd like to change the axios
                    // interceptor to reject the promise instead of returning, but then this callback could not run.
                    // Then, we believe .finally() could be used for this type of behavior that is supposed to run
                    // on both success and reject. ~ RFM 2020-03-27
                    //
                    this.props.submitCplChange(
                        this.state,
                        this.props.campaignIndex,
                        () => {
                            // Todo: this callback can be removed
                            this.setState({submitting: false});
                        }
                    ).finally((error) => {
                        this.setState({submitting: false})
                    });
                    return false;
                }}>
                <CostPerLeadForm
                    value={this.state.inputs.cpl.value}
                    onChange={ event => this.setState(setInputValue(this.state, event)) }
                    min={min}
                    max={max}
                />
                <div className="ui-hide-full ui-hide-tablet">
                    <div className="popup__form__row spacing-40-top-mobile">
                        <div className="popup__form__cell popup__form__cell__100__mobile">
                            <button className="button ui-normal-button ui-full-width-button" disabled={this.state.submitting}
                                    id="clicked-save-cost-per-lead-button">
                                Save Cost Per Lead
                            </button>
                        </div>
                    </div>
                </div>
                <div className="ui-hide-mobile">
                    <div className="popup__form__gray-bar padding-24">
                        <div className="popup__form__row popup__form__row__slam-right">
                            <div className="popup__form_cell">
                                {/* todo add some juice here because this request takes a bit of time */}
                                <button className="button ui-normal-button qa-lead-submit-button" disabled={this.state.submitting}
                                        id="clicked-save-cost-per-lead-button">
                                    Save Cost Per Lead
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </>
        )
    }
}
