import React from 'react';

export default (props) => (
    <div className="admin-rows-per-page-toggle type-body-small type-single-line type-narrow-line-height" style={{flexShrink: 0}}>
        <span>Display per page:</span>
        {[25, 50, 100].map(val => (<React.Fragment key={val}>
            {(props.rowsPerPage === val) ? (
                <span className="type-heavy">{val}</span>
            ) : (
                <span
                    role="button"
                    className="type-heavy"
                    onClick={ () => props.setRowsPerPage(val) }
                >
                    {val}
                </span>
            )}
        </React.Fragment>))}
    </div>
);