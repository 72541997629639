import { Component } from 'react';

export default class FormModal extends Component {

    autoScrollTop = true;

    componentDidMount() {
        //
        // Ensures modals render at their top-most scroll position.
        //
        // https://app.asana.com/0/671042459444729/1147172582625885
        //
        // - mz 18 Dec 2019
        //
        if (this.autoScrollTop) {
            setTimeout(() => {
                let scrollboxScroller = document.querySelector('.popup__scrollbox__scroller');
                scrollboxScroller.scrollTop = 0;
            }, 0);
        }
    }
}