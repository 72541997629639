import React from 'react'
import DropdownSelect from '../../../components/DropdownSelect'
import { ciAppointmentLostReasons } from '../../../StaticValues'
import RadioGroup from '../../../components/form_elements/RadioGroup'
import { LabelText } from '../../../components/form_elements/RadioGroup/styles'
import Tooltip from '../../../Tooltip'
import { BasicInsights } from './InsightDefinitions'
import SkeletonText from '../../../components/Skeleton/SkeletonText'
import { times } from 'lodash'
import { MultiSelectListSkeleton } from '../../../components/form_elements/MultiSelectList'

const defaultRadioOptions = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
]

export default (props) => {
    const {
        callInsights,
        leadProgress,
        updateCallInsights,
        updateLeadProgress,
        disabled,
    } = props

    const handleRadioChange = (property, value) => {
        // Call Answered and Booked Appointment are stored on the lead object,
        // which is updated a la the "update progress" function
        value = parseInt(value)
        if (property === 'human_answered') {
            updateLeadProgress({ call_answered: value })
        } else if (property === 'appointment_booked') {
            updateLeadProgress({ booked_appointment: value })
        } else {
            updateCallInsights({ [property]: value })
        }
    }

    if (callInsights == null) {
        return <BasicInsightsSkeleton />
    }

    return (
        <>
            <table className="call-insights spacing-30-bottom">
                <thead>
                    <tr>
                        <th
                            colSpan="3"
                            className="type-normal-body type-single-line type-heavy"
                        >
                            Basic Insights{' '}
                            <Tooltip position="left" content={<BasicInsights />} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="label">
                            <LabelText>Call Answered</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                disabled={disabled}
                                name="human_answered"
                                showLabel={false}
                                align="spread"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={
                                    leadProgress?.call_answered
                                        ? `${leadProgress?.call_answered}`
                                        : null
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Service Needed</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending}
                                showLabel={false}
                                align="spread"
                                name="service_needed"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                checkedOption={callInsights?.service_needed}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Booked Appointment</LabelText>
                        </td>
                        <td>
                            <RadioGroup
                                // disabled={isPending || isUpdatingProgress}
                                disabled={disabled}
                                showLabel={false}
                                align="spread"
                                name="appointment_booked"
                                options={defaultRadioOptions}
                                onChange={handleRadioChange}
                                // the lead object is automatically mapped and converted from strings to numbers
                                // if the value is null, we want to treat it like null. but if it's
                                // a value, we want to treat it like a string so it matches the radio
                                // options and the API options.
                                checkedOption={
                                    leadProgress?.booked_appointment
                                        ? `${leadProgress?.booked_appointment}`
                                        : null
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="label">
                            <LabelText>Appointment Lost Reason</LabelText>
                        </td>
                        <td>
                            <div style={{ margin: '8px 0' }}>
                                <DropdownSelect
                                    // label={'Tags'}
                                    options={ciAppointmentLostReasons}
                                    defaultValue={ciAppointmentLostReasons.filter(
                                        (option) =>
                                            option.value ==
                                            callInsights?.appointment_lost_reason
                                    )}
                                    placeholder={'Select...'}
                                    getOptionValue={(option) => option.value}
                                    getOptionLabel={(option) =>
                                        option.text || option.value
                                    }
                                    onChange={(option) => {
                                        updateCallInsights({
                                            appointment_lost_reason: option.value,
                                        })
                                    }}
                                    closeMenuOnSelect={true}
                                    allowCreate={false}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

const BasicInsightsSkeleton = () => {
    return <table className="call-insights spacing-30-bottom">
    <thead>
        <tr>
            <th colSpan="3" className="type-normal-body type-single-line type-heavy">
                Basic Insights
            </th>
        </tr>
    </thead>
    <tbody>
        {times(3, (i) => (
            <tr key={`basic-insights-row-skeleton-${i}`}>
                <td className="label">
                    <SkeletonText width={180} />
                </td>
                <td>
                    <RadioGroup
                        disabled={true}
                        showLabel={false}
                        align="spread"
                        options={defaultRadioOptions}
                    />
                </td>
            </tr>
        ))}
        <tr>
            <td className="label">
                <SkeletonText width={180} />
            </td>
            <td>
                <div style={{ margin: '8px 0' }}>
                    <MultiSelectListSkeleton label={false} />
                </div>
            </td>
        </tr>
    </tbody>
</table>
}
