import React from 'react';

import RequestContentEditModal from "../../../common/RequestContentEditModal";
import {CampaignSiteSvg} from "../../../common/Svgs";
import {useModal} from "../../../common/ModalContext";

const SelectCampaignSite = (props) => {
    const {campaign} = props;
    const updateModalContent = useModal();

    const openRequestContentEditModal = () => updateModalContent({
        content: <RequestContentEditModal
            campaign={campaign}
            industryOptions={props.industryOptions}
            updateModalContent={updateModalContent}
            updateMessageBlocks={props.updateMessageBlocks}
        />,
        header: 'Request Content Edit',
        flatBottom: true
    });

    return <div>
        <div className="type-grey inline-icon inline-icon__28">
            {CampaignSiteSvg}
        </div>

        <div className="type-normal-subhead type-heavy campaigns-table__segment-header">Campaign Site</div>

        <p className="type-normal-body">
            <a className="type-force-newline type-small-body type-heavy"
               target="_blank" href={`https://${campaign.url}`}>
                View Campaign Site
            </a>
            <button className="button-clean type-blue type-small-body type-heavy"
                    onClick={openRequestContentEditModal}>
                Request Content Edit
            </button>
        </p>
    </div>;
};

export default SelectCampaignSite;
