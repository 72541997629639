import React, {Component} from 'react';
import axios from 'axios';

import {get} from '../../../Requests';
import {formatDate} from "../../../Util";

export const EXPECTED_COMPLETION_DEFAULT_DATE = "1-2 Weeks";

export default class YourCampaigns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign_build_status: "",
            estimated_completion_date: "",

            // temporary state for "Clone Campaigns for Contractor" link UI feedback
            cloned_campaigns: false,
            cloning_campaigns: false
        };

        /**
         * Expected props (not complete):
         *  schedule_a_call_link
         */
        this.cancelSignal = axios.CancelToken.source();
    }

    componentDidMount() {
        this.getYourCampaignsData();
    }

    getYourCampaignsData() {
        let url = "signup/your-campaigns";
        get(url, this.cancelSignal.token).then(resp => {
            if (!resp || resp.status !== 200) {
                console.log(`Unexpected response from url ${url}, response = :`, resp);
                return;
            }
            this.setState({
                campaign_build_status: resp.data.campaign_build_status,
                estimated_completion_date: resp.data.estimated_completion_date
            });
        });
    }

    render() {
        const estimatedCompletionDate = this.state.estimated_completion_date
            ? formatDate(this.state.estimated_completion_date)
            : EXPECTED_COMPLETION_DEFAULT_DATE;

        return <div>
            <div className="row padding-50-bottom">
                <div className="col alpha grid-2 empty"/>
                <div className="col grid-8 no-margin-mobile">
                    <div className="module">
                        <div className="module__header module__twothirdswidth">
                            <h3 className="type-large-subhead type-single-line">
                                Sign Up Complete! Your Campaigns Are In Production
                            </h3>
                        </div>
                        <div className="module__contentbox module__twothirdswidth">
                            <p className="type-large-body spacing-30-bottom">
                                Thanks for completing your Sign Up.
                                We will use this information to build your Campaigns.
                                If we have any questions about your information,
                                we will reach out to you for clarification.
                            </p>

                            <div className="simpleflex__row simpleflex__row__wrap__mobile spacing-30-bottom">
                                <div
                                    className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-30-bottom-mobile">
                                    <p className="type-normal-subhead type-allcaps type-heavy type-narrow-line-height spacing-20-bottom">
                                        Campaign Status
                                    </p>
                                    <div>
                                        <span
                                            className="type-normal-headline type-orange type-heavy type-single-line">
                                            {this.state.campaign_build_status}
                                        </span>
                                    </div>
                                </div>
                                <div className="simpleflex__cell simpleflex__cell__full-width__mobile">
                                    <p className="type-normal-subhead type-allcaps type-heavy type-narrow-line-height spacing-20-bottom">
                                        Expected Completion
                                    </p>
                                    <div>
                                        <span
                                            className="type-normal-headline type-orange type-heavy type-single-line">
                                            {estimatedCompletionDate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <p className="type-large-body">
                                If you need more help or have any questions, please <a
                                href={this.props.schedule_a_call_link} className="type-heavy" target="_blank" rel="noopener noreferrer">schedule
                                a call</a> with your Select Onboarding Specialist.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col omega grid-2 empty"/>
                <div className="clear-block"/>
            </div>
            <div className="row padding-50-bottom">
                <div className="col alpha grid-2 empty"/>
                <div className="col grid-8 no-margin-mobile">
                    <div className="module">
                        <div className="module__header module__twothirdswidth">
                            <h3 className="type-large-subhead type-single-line">
                                What Happens Next?
                            </h3>
                        </div>
                        <div className="module__contentbox module__twothirdswidth">
                            <div className="type-large-body">
                                <div className="spacing-10-bottom">
                                    <p className="type-normal-subhead type-allcaps type-heavy ">Content</p>
                                    <p className="type-left-side-bump">Our Content Optimization Team will review
                                        your company information and start building your campaign landing pages.</p>
                                </div>
                                <div className="spacing-10-bottom">
                                    <p className="type-normal-subhead type-allcaps type-heavy">Search Ads</p>
                                    <p className="type-left-side-bump">Our Search Advertising Analysts will review
                                        your service offerings and service areas to develop a custom strategy.</p>
                                </div>
                                <div className="spacing-10-bottom">
                                    <p className="type-normal-subhead type-allcaps type-heavy">Launch</p>
                                    <p className="type-left-side-bump">
                                        Once your Campaigns have been built, you will be notified and invited to log
                                        in.
                                        Your Campaigns will be scheduled to go live on the next business day after
                                        you get the invite
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col omega grid-2 empty"/>
                <div className="clear-block"/>
            </div>

            {/* We are stepping away from the FAQ idea
<div className="row padding-50-bottom">
    <div className="col alpha grid-2 empty"></div>
    <div className="col grid-8">
        <div className="module">
            <div className="module__header module__twothirdswidth">
                <h3 className="type-large-subhead type-single-line">
                    Frequently Asked Questions
                </h3>
            </div>
            <div className="module__contentbox module__twothirdswidth">
                <div className="simpleflex__row">
                    <div className="simpleflex__cell simpleflex__grid__2-up-in-grid-8">
                        <p className="type-normal-subhead type-heavy type-allcaps type-bottom-stroke no-margin-top spacing-30-bottom">
                            Billing
                        </p>
                        
                        

                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="billing" data-idx="1" href="#" className="signup-faq__question-link no-color-change type-heavy">This is a question like this here?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-billing-1">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="billing" data-idx="2" href="#" className="signup-faq__question-link no-color-change type-heavy">When can we visit the holographic universe?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-billing-2">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="billing" data-idx="3" href="#" className="signup-faq__question-link no-color-change type-heavy">A different existential question?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-billing-3">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="billing" data-idx="4" href="#" className="signup-faq__question-link no-color-change type-heavy">How do I change my credit card info?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-billing-4">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        

                        <p className="type-normal-subhead type-heavy type-allcaps type-bottom-stroke spacing-50-top spacing-30-bottom">
                            Another Question Section
                        </p>
                        
                        

                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="another_question" data-idx="1" href="#" className="signup-faq__question-link no-color-change type-heavy">This is a question like this here?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-another_question-1">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="another_question" data-idx="2" href="#" className="signup-faq__question-link no-color-change type-heavy">Mumblecore artisan leggings lumbersexual migas?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-another_question-2">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="another_question" data-idx="3" href="#" className="signup-faq__question-link no-color-change type-heavy">Gastropub palo santo squid succulents hammock?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-another_question-3">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="another_question" data-idx="4" href="#" className="signup-faq__question-link no-color-change type-heavy">Enamel pin butcher woke swag?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-another_question-4">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="another_question" data-idx="5" href="#" className="signup-faq__question-link no-color-change type-heavy">Dreamcatcher waistcoast direct trade sustainable?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-another_question-5">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="another_question" data-idx="6" href="#" className="signup-faq__question-link no-color-change type-heavy">Banjo shoreditch bicycle rights messenger bag?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-another_question-6">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="another_question" data-idx="7" href="#" className="signup-faq__question-link no-color-change type-heavy">Brunch street art fanny pack tacos kombucha?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-another_question-7">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="another_question" data-idx="8" href="#" className="signup-faq__question-link no-color-change type-heavy">Farm-to-table seitan man braid polaroid typewriter?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-another_question-8">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="another_question" data-idx="9" href="#" className="signup-faq__question-link no-color-change type-heavy">Banh mi green juice pop-up bitters pug?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-another_question-9">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                    </div>
                    <div className="simpleflex__cell simpleflex__grid__2-up-in-grid-8">
                        <p className="type-normal-subhead type-heavy type-allcaps type-bottom-stroke no-margin-top spacing-30-bottom">
                            Question Collection
                        </p>
                        
                        

                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="question_collection" data-idx="1" href="#" className="signup-faq__question-link no-color-change type-heavy">Hopefully people won’t randomly call us?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-question_collection-1">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="question_collection" data-idx="2" href="#" className="signup-faq__question-link no-color-change type-heavy">This is a question like this here?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-question_collection-2">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="question_collection" data-idx="3" href="#" className="signup-faq__question-link no-color-change type-heavy">A different existential question?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-question_collection-3">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="question_collection" data-idx="4" href="#" className="signup-faq__question-link no-color-change type-heavy">Some questions are long and just seem to go on forever like this for two lines or more?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-question_collection-4">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="question_collection" data-idx="5" href="#" className="signup-faq__question-link no-color-change type-heavy">Lorem ipsum dolor amet mustache tattooed?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-question_collection-5">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="question_collection" data-idx="6" href="#" className="signup-faq__question-link no-color-change type-heavy">Vexillologist forage typewriter letterpress roof?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-question_collection-6">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="question_collection" data-idx="7" href="#" className="signup-faq__question-link no-color-change type-heavy">Mumblecore artisan leggings lumbersexual migas?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-question_collection-7">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="question_collection" data-idx="8" href="#" className="signup-faq__question-link no-color-change type-heavy">Lyft gastropub palo santo succulents hammock?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-question_collection-8">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="question_collection" data-idx="9" href="#" className="signup-faq__question-link no-color-change type-heavy">Enamel pin butcher woke swag?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-question_collection-9">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="question_collection" data-idx="10" href="#" className="signup-faq__question-link no-color-change type-heavy">Dreamcatcher waistcoat direct trade sustainable?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-question_collection-10">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        

                        <p className="type-normal-subhead type-heavy type-allcaps type-bottom-stroke spacing-50-top spacing-30-bottom">
                            Organize This Shit
                        </p>
                        
                        
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="organize" data-idx="1" href="#" className="signup-faq__question-link no-color-change type-heavy">Snackwave tilde brooklyn, four dollar toast?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-organize-1">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="organize" data-idx="2" href="#" className="signup-faq__question-link no-color-change type-heavy">Hell of normcore pabst ennui marfa pop-up?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-organize-2">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="organize" data-idx="3" href="#" className="signup-faq__question-link no-color-change type-heavy">Mustache everyday carry farm-to-table cray?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-organize-3">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="organize" data-idx="4" href="#" className="signup-faq__question-link no-color-change type-heavy">Pinterest shabby chic kickstarter, adaptogen photo booth organic activated charcoal?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-organize-4">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="organize" data-idx="5" href="#" className="signup-faq__question-link no-color-change type-heavy">Chia vinyl fixie affogato fashion?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-organize-5">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="organize" data-idx="6" href="#" className="signup-faq__question-link no-color-change type-heavy">Vexillologist forage typewriter roof party?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-organize-6">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="organize" data-idx="7" href="#" className="signup-faq__question-link no-color-change type-heavy">Oh. You need a little dummy text for your mockup?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-organize-7">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="organize" data-idx="8" href="#" className="signup-faq__question-link no-color-change type-heavy">Lyft gastropub palo santo squid hammock?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-organize-8">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                            <div className="signup-faq__block spacing-30-bottom">
    <p className="type-large-body">
        <a data-group="organize" data-idx="9" href="#" className="signup-faq__question-link no-color-change type-heavy">Echo park VHS taiyaki hot chicken blog?</a>
    </p>
    <div className="signup-faq__answer" id="answer-block-organize-9">
    <p className="type-normal-body spacing-10">
        This is the answer to this question. Opened in a drawer like this. Optional image or video embed. This is the answer to this question. Opened in a drawer like this. The user just has to click to open it. This is the answer to this question. Opened in a drawer like this. The user just has to <a href="#" className="type-heavy">click to open</a> it.
    </p>
    <p className="type-normal-body">
        <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
    </p>
</div>
</div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="col omega grid-2 empty"></div>
    <div className="clear-block"></div>
</div>

<div className="row padding-120-bottom">
    <div className="col alpha grid-2 empty"></div>
    <div className="col grid-8">
        <div className="module">
            <div className="module__header module__twothirdswidth">
                <h3 className="type-large-subhead type-single-line">
                    Tutorials
                </h3>
            </div>
            <div className="module__contentbox module__twothirdswidth">
                <div className="signup-tutorials__grid">
                    <div className="signup-tutorials__cell">
                        <div className="videoframe spacing-10-bottom">
                            <a href="#">
                                <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
                                <div className="videoframe__button-overlay">
                                    <div className="videoframe__play-button">
                                        <img src="/images/Button-PlayOverlay-2x.png" />
                                    </div>
                                </div>
                            </a>
                        </div>
                        <p className="type-normal-subhead type-narrow-line-height type-heavy spacing-10-bottom">
                            How to Time Travel Yesterday
                        </p>
                        <p className="type-normal-body">
                            Do we need a description for these videos? It can be an optional farther writeup about the video.
                        </p>
                    </div>
                    <div className="signup-tutorials__cell">
                        <div className="videoframe spacing-10-bottom">
                            <a href="#">
                                <img src="/images/Tutorial-Video-Temp2-2x.jpg" />
                                <div className="videoframe__button-overlay">
                                    <div className="videoframe__play-button">
                                        <img src="/images/Button-PlayOverlay-2x.png" />
                                    </div>
                                </div>
                            </a>
                        </div>
                        <p className="type-normal-subhead type-narrow-line-height type-heavy spacing-10-bottom">
                            We’re All More Than One Thing
                        </p>
                        <p className="type-normal-body">
                            Irony hot chicken venmo iceland, poke bushwick scenester
                        </p>
                    </div>
                    <div className="signup-tutorials__cell">
                        <div className="videoframe spacing-10-bottom">
                            <a href="#">
                                <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
                                <div className="videoframe__button-overlay">
                                    <div className="videoframe__play-button">
                                        <img src="/images/Button-PlayOverlay-2x.png" />
                                    </div>
                                </div>
                            </a>
                        </div>
                        <p className="type-normal-subhead type-narrow-line-height type-heavy spacing-10-bottom">
                            How to Time Travel Yesterday
                        </p>
                        <p className="type-normal-body">
                            Do we need a description for these videos? It can be an optional farther writeup about the video.
                        </p>
                    </div>
                    <div className="signup-tutorials__cell">
                        <div className="videoframe spacing-10-bottom">
                            <a href="#">
                                <img src="/images/Tutorial-Video-Temp2-2x.jpg" />
                                <div className="videoframe__button-overlay">
                                    <div className="videoframe__play-button">
                                        <img src="/images/Button-PlayOverlay-2x.png" />
                                    </div>
                                </div>
                            </a>
                        </div>
                        <p className="type-normal-subhead type-narrow-line-height type-heavy spacing-10-bottom">
                            How to Time Travel Yesterday
                        </p>
                        <p className="type-normal-body">
                            Irony hot chicken venmo iceland, poke bushwick scenester
                        </p>
                    </div>
                    <div className="signup-tutorials__cell">
                        <div className="videoframe spacing-10-bottom">
                            <a href="#">
                                <img src="/images/Tutorial-Video-Temp1-2x.jpg" />
                                <div className="videoframe__button-overlay">
                                    <div className="videoframe__play-button">
                                        <img src="/images/Button-PlayOverlay-2x.png" />
                                    </div>
                                </div>
                            </a>
                        </div>
                        <p className="type-normal-subhead type-narrow-line-height type-heavy spacing-10-bottom">
                            We’re All More Than One Thing
                        </p>
                        <p className="type-normal-body">
                            Do we need a description for these videos? It can be an optional farther writeup about the video.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="col omega grid-2 empty"></div>
    <div className="clear-block"></div>
</div>
*/}

            <script src="/scripts/faq.js"/>
            {/*
<script>
buildSignupFaqLinks();
</script>
*/}

        </div>;
    }
}
