import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {errorHandler} from "../../../Requests";
import SignupMarketplaceService from "../../../service/SignupMarketplaceService";

export default class CreateMarketplaceClientForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            mpSignupLink: '',
            mpSignupLinkText: '',
            submitting: false
        };

        this.signupMarketplaceService = new SignupMarketplaceService();
    }

    componentWillUnmount() {
        this.signupMarketplaceService.cancelSignal.cancel();
    }

    /**
     * Looks up, and validates, the email address with the server
     * @param {KeyboardEvent} event
     */
    lookupMpSignupByEmail = (event: KeyboardEvent) => {
        event.preventDefault();

        if (!this.state.email) {
            return;
        }

        // blank out link momentarily so that selected-row-fading style will do fading effect
        this.setState({mpSignupLink: '', mpSignupLinkText: ''});

        this.signupMarketplaceService.lookupMpSignupByEmail(this.state.email)
            .then((data) => {
                this.setState({
                    mpSignupLink: data.mpSignupUrl,
                    mpSignupLinkText: data.mpSignupLinkText
                });
                if (data.message) {
                    this.props.updateMessageBlocks([data.message], 'success');
                }
            })
            .catch((error) => {
                if (error.response.data.data.message) {
                    this.props.updateMessageBlocks([error.response.data.data.message], 'error');
                }
                if ('mpSignupUrl' in error.response.data.data) {
                    this.setState({
                        mpSignupLink: '',
                        mpSignupLinkText: ''
                    });
                }
                if (errorHandler(error)) {
                    this.setState({submitting: false});
                }
            });
    };

    render() {
        return <section className="white-background">
            <form className="page-width" onSubmit={this.lookupMpSignupByEmail}>
                <div className="row padding-50-top padding-20-bottom">
                    <div className="col alpha grid-2 empty"/>
                    <div className="col grid-8 no-margin-mobile">
                        <div className="module spacing-50-bottom">
                            <div className="module__header module__twothirdswidth">
                                <h3 className="type-large-subhead type-narrow-line-height">
                                    Start or Resume Marketplace Sign Up
                                </h3>
                            </div>
                            <div className="module__contentbox module__twothirdswidth">
                                <div className="form__row">
                                    <div className="form__cell form__cell__100 spacing-10-bottom">
                                        <div>
                                        <span role="button" className="type-blue type-extra-small-body"
                                              onClick={this.props.onReset}>
                                            Go Back to Choose Client Type
                                        </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div
                                        className="form__cell form__cell__50 form__cell__100__mobile no-margin-bottom-mobile">
                                        <label
                                            htmlFor="lookup_email"
                                            className={'type-normal-body type-heavy type-narrow-line-height spacing-10-bottom'}
                                        >
                                            Email Address
                                        </label>
                                        <input
                                            id="lookup_email"
                                            type="email"
                                            className={'ui-text-field ui-normal-text-input'}
                                            name="lookup_email"
                                            value={this.state.email}
                                            onChange={(event) =>
                                                this.setState({email: event.target.value})}
                                        />
                                    </div>
                                    <div
                                        className="form__cell form__cell__50 form__cell__100__mobile spacing-24-bottom-mobile">
                                        <label
                                            className="type-normal-body type-heavy type-narrow-line-height spacing-10-bottom"
                                            htmlFor="fetch_hubspot_deal_button">
                                            &nbsp;
                                        </label>
                                        <button className="button ui-normal-button ui-full-width-button-mobile"
                                                type="submit"
                                                id="submit-button">
                                            Look UP MP Sign Up
                                        </button>
                                    </div>
                                </div>
                                {!this.state.mpSignupLink ||
                                <div className="form__row spacing-20-top">
                                    <div
                                        className="form__cell form__cell__100 no-margin-bottom-mobile selected-row-fading">
                                        <a href={this.state.mpSignupLink}>
                                            {this.state.mpSignupLinkText}
                                        </a>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="col omega grid-2 empty"/>
                    <div className="clear-block"/>
                </div>
            </form>
        </section>;
    }
};

CreateMarketplaceClientForm.propTypes = {
    onReset: PropTypes.func.isRequired,
    updateMessageBlocks: PropTypes.func
};
