import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";

const RouteNotFound = () => {
    const REDIRECT_TIMER = 6;

    const [timeLeft, setTime] = useState(REDIRECT_TIMER);

    useEffect(
        () => {
            const timer = setTimeout(
                () => {
                    setTime(() => timeLeft - 1);
                },
                1000
            );

            return () => clearTimeout(timer);
        }
    );

    if (timeLeft < 1) {
        return <Redirect to="/dashboard"/>
    }

    return <div className="page-not-found">
        <h1>404 - Page not found</h1>
        <p>
            You will be automatically transferred to your{' '}
            <a href="/dashboard">Dashboard</a>{' '}
            in {timeLeft} seconds
        </p>
    </div>;
};

export default RouteNotFound;
