import React from 'react';
import PropTypes from 'prop-types';

export const ToggleSwitch = props => {
    const onChange = (event: MouseEvent) => {
        if (typeof props.onChange === 'function') {
            props.onChange(event.target.checked);
        }
    };

    return <label className="toggle-switch-container">
        <span className="toggle-switch">
            <input
                type="checkbox"
                name={props.name}
                id={props.id}
                checked={props.checked}
                onChange={onChange}
                disabled={props.disabled}
            />
            <span className="slider"/>
        </span>
        {props.checked ? 'Enabled' : 'Disabled'}

        {props.tooltip
            ? <> {props.tooltip}</>
            : null}
    </label>;
};

export default ToggleSwitch;

ToggleSwitch.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltip: PropTypes.object
};
