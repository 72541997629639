import React, { Component } from 'react';
import Input from './components/form_elements/Input';
import MultiSelectList from './components/form_elements/MultiSelectList';
import TextArea from './components/form_elements/TextArea';
import {
    PlusSignSvg,
    ButtonCloseXSvg,
} from './Svgs';

export default class RequestContentEditForm extends Component {
    render() {

        let requestNum = this.props.editRequestIdx + 1;

        return (
            <>
            <p className="type-large-body type-heavy type-narrow-line-height no-margin-top spacing-30-bottom">
                EDIT REQUEST #{requestNum}
            </p>
            <div className="popup__form__row spacing-30-bottom">
                <div className="popup__form__cell popup__form__cell__75 popup__form__cell__100__mobile">
                    <MultiSelectList
                        label="Where would you like the edits done?"
                        name="scope"
                        selections={ [this.props.editRequest.scope] }
                        options={ this.props.scopeOptions(this.props.campaign) }
                        onChange={ (vals) => this.props.updateValue(this.props.editRequestIdx, "scope", vals[0]) }
                        required={ false }
                    />
                </div>
            </div>
            <div className="popup__form__row spacing-30-bottom">
                <div className="popup__form__cell popup__form__cell__100">
                    <TextArea
                        label="Describe your content edit requests"
                        name="description"
                        placeholder="Examples of Content Edits:&#10;Updating text or image content.&#10;Adding a new Coupon or Special."
                        onBlur={(event) => this.props.updateValue(this.props.editRequestIdx, "description", event.target.value)}
                    />
                </div>
            </div>
            <div className="popup__form__row spacing-30-bottom">
                <div className="popup__form__cell popup__form__cell__100">
                    <Input
                        label="Campaign Site page link"
                        subLabel="(leave blank if change is to whole site)"
                        name="site_page_url"
                        value={ this.props.editRequest.site_page_url }
                        placeholder="Copy and paste the link from the address bar of the page"
                        onChange={ (event) => this.props.updateValue(this.props.editRequestIdx, "site_page_url", event.target.value) }
                        type={"text"}
                        required={false}
                        hasError={false}
                    />
                </div>
            </div>
            <div className="type-small-body spacing-30-bottom padding-10-bottom type-bottom-stroke">
                <div className="spacing-10-bottom">
                    <ul>
                        {/**
                         * Attachments
                         *
                         * We use an invisible <input type="file"> to store the file data and
                         * open the Browse Files dialog. When we call addAttachment, it adds a new
                         * attachment to the state, causing a new invisible input to be added, and
                         * then it clicks on the invisible input, opening the Browse Files dialog.
                         *
                         * Note that we don't want to display anything until the file is selected.
                         * Thus, while filename is blank, the JSX of this whole element is blank
                         * except for the invisible input.
                         */ }
                        {this.props.editRequest.attachments.map((attachment, idx) => {
                            let keyName = `attachments-${this.props.editRequestIdx}-${idx}`;
                            return (
                                <li key={keyName} id={keyName}>
                                    {(!!attachment.filename) ? (
                                        <>
                                            <span className="type-heavy">File:</span>
                                            <span className="type-left-side-bump type-right-side-bump">
                                                {attachment.filename}
                                            </span>
                                            <span role="button" className="type-heavy type-blue"
                                               onClick={() => this.props.deleteAttachment(this.props.editRequestIdx, idx)}
                                            >
                                                <span className="inline-icon inline-icon__middle inline-icon__20">
                                                    {ButtonCloseXSvg}
                                                </span>
                                                Delete
                                            </span>
                                        </>
                                    ) : ''}
                                    <input type="file" style={{display: "none"}} accept="image/*,.pdf,.doc,.docx"
                                           onChange={(event) => this.props.updateAttachment(
                                               this.props.editRequestIdx,
                                               idx,
                                               // files[0].name contains just the basename,
                                               // whereas event.target.value had C:\fakepath\...
                                               event.target.files[0].name
                                           )}
                                    />
                                </li>
                            );
                         })
                        }
                    </ul>
                </div>
                <div>
                    <span role="button"
                        className="type-heavy type-blue"
                        onClick={() => this.props.addAttachment(this.props.editRequestIdx)}
                    >
                        <span className="inline-icon inline-icon__middle inline-icon__20">
                            { PlusSignSvg }
                        </span>
                        Add Attachment
                        &nbsp;<span className="type-regular type-grey">(JPG, PNG, PDF, Word formats)</span>
                    </span>
                </div>
                <div className="spacing-10-top type-align-right">
                    <span role="button"
                        className="type-heavy type-blue"
                        onClick={() => this.props.deleteEditRequest(this.props.editRequestIdx)}
                    >
                        <span className="inline-icon inline-icon__middle inline-icon__20">
                            { ButtonCloseXSvg }
                        </span>
                        Delete Request #{requestNum}
                    </span>
                </div>
            </div>
            </>
        );
    }
}