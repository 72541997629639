import { AgGridReact } from 'ag-grid-react'
import React, { Fragment, useMemo, useRef } from 'react'
import TableHeaderCell from '../../../../common/components/TableHeaderCell'
import getColumnDef from './getColumnDef'

const DataTable = ({
    rows,
    columns,
    sortColumn,
    sortOrder,
    onOrderBy,
    onEditHandler,
    loading,
}) => {
    const gridRef = useRef()
    const rowData = useMemo(() => rows, [rows])
    const columnDefs = useMemo(
        () =>
            columns.map((col, i) => {
                // if the column is sortable, add the custom sort function
                // so that it can be used by the custom header component a la
                // `headerComponentParams`
                // @see https://www.ag-grid.com/javascript-data-grid/component-header/?#example-custom-header-component
                if (col.sortable) {
                    col.headerComponentParams = { sort: () => onOrderBy(col.property) }
                }
                // if the column is the current sort column, add the sort data
                if (col.property === sortColumn) {
                    col.sort = sortOrder
                }
                // hide the sort arrow
                col.headerComponentParams = {
                    ...col.headerComponentParams,
                    hideArrow: true,
                }
                return getColumnDef(col, { loading }, onEditHandler)
            }),
        [columns, loading, onEditHandler, onOrderBy, sortColumn, sortOrder]
    )

    const defaultColDef = useMemo(() => ({
        resizable: true,
        // apply a custom header class when the column is sorted
        // so that the background color can be changed
        headerClass: (params) => {
            if (params?.column?.sort) {
                return 'header_sort_active'
            }
        },
    }), [])

    const components = useMemo(() => ({
        agColumnHeader: TableHeaderCell,
    }),[])

    const gridOptions = useMemo(() => ({
        onFirstDataRendered: ({ api }) => {
            const MIN_SCREEN_WIDTH = 1900
            if (window.innerWidth > MIN_SCREEN_WIDTH) {
                api.sizeColumnsToFit()
            }
        },
        suppressRowClickSelection: true,
        overlayNoRowsTemplate: '<span>No Data</span>',
    }), [])

    const ROW_HEIGHT = useMemo(() => 55, [])
    const HEADER_HEIGHT = useMemo(() => 80, [])
    let tableHeight = useMemo(() => {
        let _val = Math.min(rows.length * ROW_HEIGHT + 100, window.innerHeight - 120)
        if (rows?.length === 0) {
            _val = 400
        }
        return _val
    }, [ROW_HEIGHT, rows.length])
    // const height = rows.length * ROW_HEIGHT + 100
    return (
        <Fragment>
            <div
                className="ag-theme-alpine"
                style={{ width: '100%', height: `${tableHeight}px` }}
            >
                <AgGridReact
                    rowData={rowData}
                    ref={gridRef}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="single"
                    rowHeight={ROW_HEIGHT}
                    headerHeight={HEADER_HEIGHT}
                    components={components}
                    gridOptions={gridOptions}
                />
            </div>
        </Fragment>
    )
}

export default DataTable
