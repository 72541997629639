import React, {Component} from 'react';
import axios from 'axios';
import {get} from '../../../Requests';
import SignupLaunchStatusTable from './SignupLaunchStatusTable';
import MessageBlocks from '../../../common/MessageBlocks';
import Modal from "../../../common/Modal";
import MultiSelectList from '../../../common/components/form_elements/MultiSelectList';

export default class SignupAndLaunchStatus extends Component {
    constructor(props) {
        super(props);
        document.title = 'Account Activation Launch Status';

        this.defaultFilterOption = {
            display_name: 'All',
            user_id: ''
        };

        this.state = {
            signupRows: [],
            csManagers: [],
            currentFilterOption: this.defaultFilterOption,
            messages: [],
            messageType: '',
        };

        this.scrollboxRef = React.createRef();

        this.cancelSignal = axios.CancelToken.source();
    }

    componentDidMount() {
        this.getSignupAndLaunchData(null);
    }

    componentWillUnmount(): void {
        this.cancelSignal.cancel();
    }

    updateModalContent = (modalContent, modalHeader, modalWidth, modalFlatBottom) => {
        this.setState({
            modalContent: modalContent,
            modalHeader: modalHeader,
            modalWidth: modalWidth,
            modalFlatBottom: modalFlatBottom
        });
    };

    /**
     * Callback when an update action on a row is taken. E.g. when customer
     * success manager is updated on a signup row, the signupRow is updated
     * before being passed to this method, where its updated to state for
     * re-rendering.
     */
    updateSignupRow = (index, signupRow) => {
        let signupRows = [...this.state.signupRows];
        signupRows[index] = signupRow;
        this.setState({signupRows: signupRows});
    };

    /**
     * Requests all signup rows, optionally by cs manager user id, and writes
     * them to state for rendering.
     */
    getSignupAndLaunchData = (userId) => {
        let url = 'signup/signup-and-launch';
        if (userId) {
            url = `${url}?cs_manager_user_id=${userId}`
        }

        get(url, this.cancelSignal.token).then(resp => {
            // TODO add pop modal here 
            if (resp.status !== 200) {
                return;
            }

            let {signupRows} = resp.data;

            // (modify signupRows in-place)
            this.writeReadyForClientApprovalOnSignupRows(signupRows);

            this.setState({
                signupRows,
                csManagers: resp.data.csManagers
            });

        });
    };

    /**
     * modify all signupRows to contain a ready_for_client_approval field, true/false,
     * which will be true iff the SEND EMAIL button will appear for that Client.
     */
    writeReadyForClientApprovalOnSignupRows(signupRows) {
        for (let i = 0; i < signupRows.length; i++) {
            let signupRow = signupRows[i];
            signupRows[i].ready_for_client_approval = this.shouldShowSendEmailButton(signupRow);
        }
        // returns nothing - modifies signupRows in place
    };

    /**
     * Given a signupRow, loop through its campaigns and determine if at least
     * has ready_for_client_approval_timestamp or similar.
     *
     * Result is a true/false value that can be set at the signupRow level and
     * is true iff we need to show the SHOW EMAIL button for that Client.
     *
     * If ANY 1 Campaign has is ready for client approval, we'll offer the SEND
     * EMAIL button for the admin to send the email if they choose.
     */
    shouldShowSendEmailButton(signupRow) {
        if (!('campaigns' in signupRow)) {
            return false;
        }

        for (let i = 0; i < signupRow.campaigns.length; i++) {
            let campaign = signupRow.campaigns[i];
            if (campaign.ready_for_client_approval) {
                return true;
            }
        }
        return false;
    };


    /**
     * Updates the current filter based on the selected customer success user id
     * before calling getSignupAndLaunchData() again to re-render the signup
     * table based on the selected customer success user id.  If there isn't a
     * selected customer success user id, all rows are returned and rendered.
     */
    updateFilters(userId) {
        let stateClone = {...this.state};
        if (!userId) {
            stateClone.currentFilterOption = this.defaultFilterOption;
        }
        else {
            for (let i in stateClone.csManagers) {
                if (!stateClone.csManagers.hasOwnProperty(i)) {
                    continue;
                }

                if (userId === stateClone.csManagers[i].user_id) {
                    stateClone.currentFilterOption = stateClone.csManagers[i];
                    break;
                }
            }
        }
        this.setState(stateClone);
        this.getSignupAndLaunchData(userId);
    }

    render() {
        // filter options will be 'all' plus whatever cs managers we get back from the backend
        let filterOptions = [this.defaultFilterOption].concat(this.state.csManagers)

        return (
            <div className="interface-container">
                <Modal
                    content={this.state.modalContent}
                    header={this.state.modalHeader}
                    flatBottom={this.state.modalFlatBottom}
                    updateModalContent={this.updateModalContent}
                    size="small"
                />

                <MessageBlocks
                    messages={this.state.messages}
                    type={this.state.messageType}
                    clearErrors={() => this.setState({ messages: [], messageType: '' })}
                />
                <section className="white-background">
                    <div className="page-width page-width-wide">
                        <div className="row padding-10 scroll-table__header">
                            <div className="side-rails">
                                <div className="simpleflex__row">
                                    <div className="simpleflex__cell simpleflex__cell__middlealigned">
                                        <h1 className="type-normal-subhead type-heavy">
                                            Account Activation Launch Status
                                        </h1>
                                        <span>
                                            <a
                                                href="https://datastudio.google.com/reporting/1Dx4HBAYAre-NhSOzp97DyU0EdqoPF5Lp/page/Rn1r"
                                                target="_blank"
                                            >
                                                GDS Report - Clients Per "Stage"
                                            </a>
                                        </span>
                                    </div>
                                    <div className="simpleflex__cell">
                                        <div className="signup-status-filters">
                                            <div className="signup-status-filters__cell signup-status-filters__cell__label">
                                                <p>Onboarding Specialist:</p>
                                            </div>
                                            <div className="signup-status-filters__cell signup-status-filters__cell__state">
                                            <MultiSelectList
                                                name="signup-status-filter-list"
                                                options={filterOptions}
                                                selections={[this.state.currentFilterOption.user_id || '']}
                                                onChange={(vals) => this.updateFilters(vals[0])}
                                                getOptionValue={option => option.user_id}
                                                getOptionText={option => option.display_name}
                                            />
                                            </div>
                                            <div className="signup-status-filters__cell signup-status-filters__cell__clear">
                                                <span
                                                    className="type-single-line type-small-body type-heavy"
                                                    role="button"
                                                    onClick={() => this.updateFilters()}
                                                >
                                                    Clear Filters
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clear-block" />
                        </div>

                        <div className="row padding-10-bottom">
                            <div className="side-rails">
                                <div className="scroll-table__container">
                                    <div className="scroll-table__container__shadowedge" />
                                    <div
                                        className="scroll-table__container__scrollbox"
                                        ref={this.scrollboxRef}
                                    >
                                        <SignupLaunchStatusTable
                                            signupRows={this.state.signupRows}
                                            csManagers={this.state.csManagers}
                                            updateSignupRow={this.updateSignupRow}
                                            updateMessageBlocks={
                                                this.props.updateMessageBlocks
                                            }
                                            updateModalContent={this.updateModalContent}
                                            shouldShowSendEmailButton={
                                                this.shouldShowSendEmailButton
                                            }
                                            scrollboxRef={this.scrollboxRef}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="clear-block" />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
