import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 25px 0;
`

export const ContentWrapper = styled.div`
    display: grid;
    row-gap: 30px;
`

export const RuleWrapper = styled.div`
    padding-bottom: 30px;
    border-bottom: ${({border = true}) => border ? '1px solid #D0E0F2' : 'none'};
`
