import React from 'react';
import PropTypes from 'prop-types';

import {ButtonNavCloseSvg} from './Svgs';
import type {HighlightInterface} from "../Interfaces/HighlightInterface";
import {HighlightTypes} from "./StaticValues";

export default function Highlight(props) {
    const highlight: HighlightInterface = props.highlight;

    return <div className="flash-message flash-message__update type-normal-body pin-container">
        {highlight.dismissible == 1 &&
        <span role="button" className="close-button" onClick={() => props.removeHighlight(highlight)}>
                {ButtonNavCloseSvg}
            </span>}
        {highlight.type === HighlightTypes.GENERAL &&
        <b>UPDATES:</b>}
        {' '}
        <span dangerouslySetInnerHTML={{__html: highlight.text}}/>
    </div>;
}

Highlight.propTypes = {
    highlight: PropTypes.object.isRequired,
    removeHighlight: PropTypes.func
};
