import React from 'react';
// import {
//         validateOnSubmit, getPayload,
//         inputValidation, inputObj,
//         syncInputValueToState, handleInputBlur,
//         handleCheckboxClick, jumpToError,
//         setInputValue,
//         setApiErrorsOnStateClone
// } from '../formHelpers';
// import PopUpModalInputSection from './PopUpModalInputSection';
import RequestContentEditForm from './RequestContentEditForm';
// import TextArea from "./components/form_elements/TextArea";
import { PlusSignSvg } from './Svgs';
import { post } from "../Requests";
import FormModal from '../common/components/modal/FormModal';


/**
 * RequestContentEditModal
 *
 * Extends FormModal (subclass of React.Component) in order to get desired
 * scroll-to-top behavior each time the modal is opened.
 */
export default class RequestContentEditModal extends FormModal {

    constructor(props) {
        super(props);
        
        this.state = {
            //
            // form data for the API, (in snake_case to match API)
            //
            content_edit_requests: [ this.newEditSetObj() ],

            //
            // when true, Submit button will be disabled
            //
            requestUnderway: false,
        };

        //this.addEditSet = this.addEditSet.bind(this);
    }

    newEditSetObj = () => {
        let campaignIndustry = this.getCampaignIndustryOption();
        let scope;

        //
        // we should never get here: campaign should always have an industry in industryOptions
        //
        if (campaignIndustry === null) {
            scope = 'all';
        }
        else {
            scope = 'industry_' + campaignIndustry.value.toString();
        }

        return {
            scope: scope,
            description: '',
            site_page_url: "https://" + this.props.campaign.url,
            attachments: [],
        }
    };

    addEditSet = () => {
        let contentEditRequests = this.state.content_edit_requests;

        //
        // WARNING - if you change this structure, you may also need
        // to change deepCopyContentEditRequests() accordingly.
        //
        contentEditRequests.push(this.newEditSetObj());

        this.setState({ content_edit_requests: contentEditRequests });
    };

    getCampaignIndustryOption = () => {
        //
        // Find the name of the industry that the current campaign is
        // so that we can populate the <Select> option below, e.g.
        // "All Plumbing Sites for <Company Name>"
        //
        let campaignIndustry = null;
        for (let i=0; i < this.props.industryOptions.length; i++) {
            let industryObj = this.props.industryOptions[i];
            if (Number(industryObj.value) === Number(this.props.campaign.industry_id)) {
                campaignIndustry = industryObj;
                break;
            }
        }
        return campaignIndustry;
    };



    /**
     * update a Value within the state of one of the Content Edit Requests
     *
     * idx - 0-based index of which content_edit_request to modify
     * key - the string key within the object of the content_edit_request
     * value - the new value to assign to that key of the state
     */
    updateValue = (idx, key, value) => {
        //
        // Make a clone of just this particular content_edit_request obj
        //
        let editRequestClone = {...this.state.content_edit_requests[idx]};

        //
        // Change the value under the designated key
        //
        editRequestClone[key] = value;

        //
        // Clone the overall content_edit_requests array and overwrite the new value
        //
        let contentEditRequestsClone = [...this.state.content_edit_requests];
        contentEditRequestsClone[idx] = editRequestClone;

        //
        // Make the actual state change
        //
        this.setState({
            content_edit_requests: contentEditRequestsClone,
        });
    };


    /**
     * Add a new Attachment to this current contentEditRequest.
     *
     * - If the last attachment is already blank, just re-use that one,
     *   rather than adding on more and more to the same array.
     *
     * contentEditRequestIdx - 0-based index of which content_edit_request to add to
     */
    addAttachment = (contentEditRequestIdx) => {
        //
        // Object to be populated
        //
        let newAttachment = {
            filename: '',
            file: null
        };

        //
        // Make a clone of just this particular content_edit_request obj
        //
        let editRequestClone = {...this.state.content_edit_requests[contentEditRequestIdx]};

        //
        // See if the last attachment has a filename. If not, we can re-use it.
        //
        let lastAttachment = null;
        let numAttachments = editRequestClone.attachments.length;
        if (numAttachments > 0) {
            lastAttachment = editRequestClone.attachments[numAttachments - 1];
        }
        //
        // No attachments yet, or last attachment has filename present?
        // Need to add a new attachment.
        //
        let attachmentsClone = [...editRequestClone.attachments];
        if (!lastAttachment || lastAttachment.filename) {
            //
            // Add the attachment within our clone
            //
            attachmentsClone.push(newAttachment);
            editRequestClone.attachments = attachmentsClone;
        }
        //
        // (Else, we'll just grab the last one
        //

        //
        // Get the new attachments idx so we can find the <input type="file"> by id later
        //
        let newIdx = attachmentsClone.length - 1;

        //
        // Clone the overall content_edit_requests array and overwrite the new value
        //
        let contentEditRequestsClone = [...this.state.content_edit_requests];
        contentEditRequestsClone[contentEditRequestIdx] = editRequestClone;

        //
        // Make the actual state change
        //
        this.setState({content_edit_requests: contentEditRequestsClone,},
            () => {
                //
                // Issue a click on the <input type="file" to open the Browse dialog
                //
                let hiddenFileInput = this.getFileInputElem(contentEditRequestIdx, newIdx);
                hiddenFileInput.click();
            }
        );
    };


    /**
     * Change the filename of an attachment. Used after the Browse dialog to set the filename in the state based on
     * what's in the <input type="false">.
     *
     * Also, put the File() object found in the <input type="false"> into the state, since React seems to
     * mess up what's in the DOM in cases where you e.g. add 2 attachments and remove the 1st one.
     * (see bug report https://app.asana.com/0/1137742015909414/1151385689321892)
     *
     * @param editRequestIdx
     * @param attachmentIdx
     * @param filename
     */
    updateAttachment = (editRequestIdx, attachmentIdx, filename) => {
        //
        // Make a clone of just this particular content_edit_request attachments array
        //
        let attachmentsClone = [...this.state.content_edit_requests[editRequestIdx].attachments];

        //
        // Build the new attachment object
        //
        let fileInput = this.getFileInputElem(editRequestIdx, attachmentIdx);
        let newAttachment = {
            filename: filename,
            file: fileInput.files[0]
        };

        //
        // Change the value at the designated index of the attachments array
        //
        attachmentsClone[attachmentIdx] = newAttachment;

        //
        // Clone the overall content_edit_requests array and overwrite the new value
        //
        let contentEditRequestsClone = [...this.state.content_edit_requests];
        let editRequestClone = {...this.state.content_edit_requests[editRequestIdx]};
        editRequestClone.attachments = attachmentsClone;
        contentEditRequestsClone[editRequestIdx] = editRequestClone;

        //
        // Make the actual state change
        //
        this.setState({
            content_edit_requests: contentEditRequestsClone,
        });
    };


    /**
     * Remove an attachment from the form / state
     *
     * @param editRequestIdx
     * @param attachmentIdx
     */
    deleteAttachment = (editRequestIdx, attachmentIdx) => {
        //
        // Make a clone of just this particular content_edit_request attachments array
        //
        let attachmentsClone = [...this.state.content_edit_requests[editRequestIdx].attachments];

        //
        // Remove the value at the designated index
        //
        attachmentsClone.splice(attachmentIdx, 1);

        //
        // Clone the overall content_edit_requests array and overwrite the new value
        //
        let contentEditRequestsClone = [...this.state.content_edit_requests];
        let editRequestClone = {...this.state.content_edit_requests[editRequestIdx]};
        editRequestClone.attachments = attachmentsClone;
        contentEditRequestsClone[editRequestIdx] = editRequestClone;

        //
        // Make the actual state change
        //
        this.setState({
            content_edit_requests: contentEditRequestsClone,
        });
    };


    /**
     * Remove a content_edit_request from the form / state
     *
     * @param editRequestIdx - the 0-based index within this.state.content_edit_requests
     */
    deleteEditRequest = (editRequestIdx) => {
        //
        // Clone the overall content_edit_requests array and remove the request in question
        //
        let contentEditRequestsClone = [...this.state.content_edit_requests];
        contentEditRequestsClone.splice(editRequestIdx, 1);

        //
        // Make the actual state change
        //
        this.setState({
            content_edit_requests: contentEditRequestsClone,
        });
    };


    /**
     * Get the <input type="file"> element associated with a certain attachment.
     * @param editRequestIdx
     * @param attachmentIdx
     * @returns {Element}
     */
    getFileInputElem = (editRequestIdx, attachmentIdx) => {
        let elemId = 'attachments-'+editRequestIdx.toString()+'-'+attachmentIdx.toString();
        let attachmentLi = document.getElementById(elemId);
        let hiddenFileInput = attachmentLi.querySelector('input[type=file]');
        return hiddenFileInput;
    };


    /**
     * Given contentEditRequestsData, remove the blank attachments
     * at the end of each attachments array.
     *
     * @param contentEditRequestsData array of objects,
     *        each with an attachments array
     */
    removeEmptyAttachments = (contentEditRequestsData) => {
        for (let i = 0; i < contentEditRequestsData.length; i++) {
            let editRequest = contentEditRequestsData[i];
            let numAttachments = editRequest.attachments.length;
            if (numAttachments === 0) { break; }

            let lastAttachmentIdx = numAttachments - 1;
            let lastAttachment = editRequest.attachments[lastAttachmentIdx];
            if (!lastAttachment.filename) {
                editRequest.attachments.splice(lastAttachmentIdx,1);
            }
        }
    };


    /**
     * @param filename string
     * @returns [string, string]
     *   e.g. hello.exe => ['hello', '.exe']
     */
    splitFilenameAtExtension = (filename) => {
        let lastDotPos = filename.lastIndexOf('.');
        if (lastDotPos !== -1) {
            return [filename.substring(0,lastDotPos), filename.substring(lastDotPos)];
        }
        else {
            return [filename, ''];
        }
    };


    numberFileName = (filename, num) => {
        let basenameAndExt = this.splitFilenameAtExtension(filename)
        let basename = basenameAndExt[0], ext = basenameAndExt[1];
        let numberedFilename = basename + "." + num.toString() + ext;
        return numberedFilename;
    };


    /**
     * We need to deep-copy the content_edit_requests prior to doing the AJAX post
     * because of otherwise destructive changes we need to make to the array
     * (numbering the files when there are duplicates, removing empty attachments).
     * Such changes aren't allowed to be made straight to state, and we don't want
     * to modify the core state anyway - just what we send the API.
     *
     * @returns {Array}
     */
    deepCopyContentEditRequests = () => {
        //return JSON.parse(JSON.stringify(this.state.content_edit_requests));

        let contentEditRequestsClone = [];
        for (let i=0; i < this.state.content_edit_requests.length; i++) {

            //
            // clone the content_edit_request
            //
            let attachmentsClone = [];
            let contentEditRequest = {
                scope: this.state.content_edit_requests[i].scope,
                description: this.state.content_edit_requests[i].description,
                site_page_url: this.state.content_edit_requests[i].site_page_url,
                attachments: attachmentsClone,
            };

            //
            // clone the attachments array
            //
            for (let j=0; j < this.state.content_edit_requests[i].attachments.length; j++) {
                let fileClone = null;
                let file = this.state.content_edit_requests[i].attachments[j].file;
                //
                // Check if file is null to avoid https://app.asana.com/0/0/1154479519961769/f
                //
                if (file !== null) {
                    fileClone = new File([file], file.name, { type: file.type });
                }
                let attachment = {
                    filename: this.state.content_edit_requests[i].attachments[j].filename,
                    file: fileClone,
                };
                attachmentsClone.push(attachment);
            }

            //
            // add the cloned item to our new array
            //
            contentEditRequestsClone.push(contentEditRequest);
        }
        return contentEditRequestsClone;
    };


    /**
     * Remove the File() objects from the content_edit_requests array
     * Destructively modifies the array.
     */
    removeFileObjects = (contentEditRequests) => {
        for (let i=0; i < contentEditRequests.length; i++) {
            for (let j=0; j < contentEditRequests[i].attachments.length; j++) {
                delete contentEditRequests[i].attachments[j].file;
            }
        }
    };


    /**
     * Build a FormData object that includes all the File data, with the filenames unique-ified as needed.
     * @returns {FormData}
     */
    createFormDataWithFiles = (editRequestsClone) => {

        let formData = new FormData();
        //
        // Object to track the number of occurrences of each filename, to number them uniquely as needed.
        //
        let fileNameCounts = {};

        for (let i=0; i < editRequestsClone.length; i++) {
            let editRequest = editRequestsClone[i];
            for (let j=0; j < editRequest.attachments.length; j++) {
                let attachment = editRequest.attachments[j];
                let fileName = attachment.file.name;
                let fileData = attachment.file;

                //
                // If fileName is already there, number the filename it accordingly to avoid duplicates.
                // Keep track in fileNameCounts of the encountered names and number of occurrences.
                //
                if (fileName in fileNameCounts) {
                    let num = ++fileNameCounts[fileName];
                    fileName = this.numberFileName(fileName, num);
                    //
                    // update the attachments array too
                    //
                    editRequest.attachments[j].filename = fileName;
                }
                else {
                    fileNameCounts[fileName] = 1;
                }

                //
                // Recreate the File object with the possibly new name
                //
                let fileClone = new File([fileData], fileName, { type: fileData.type });
                formData.append(fileName, fileClone);
            }
        }

        return formData;
    };




    /**
     * Send an API Request to POST new content_edit_requests.
     */
    makeContentEditRequest = () => {

        let url = "campaigns/" + this.props.campaign.campaign_id.toString() + "/content_edit_request_full";

        //
        // set requestUnderway so button will be grayed out
        //
        this.setState({requestUnderway: true});

        //
        // start with a deep clone of the content_edit_requests
        // so we don't accidentally touch state improperly when we e.g. remove empty attachments
        //
        let editRequestsClone = this.deepCopyContentEditRequests();

        //
        // Remove empty attachments that may have been introduced
        // by clicking "Add Attachments" followed by Cancel"
        //
        this.removeEmptyAttachments(editRequestsClone);

        //
        // Create FormData() object with file data included, filenames unique-ified as needed.
        //
        let formData = this.createFormDataWithFiles(editRequestsClone);

        //
        // Attach the non-file-data params to the FormData to send to the API.
        //
        // First, remove the File() objects, already included in the FormData.
        //
        this.removeFileObjects(editRequestsClone);
        let data = {
            //
            // Currently the representation in state of the content_edit_requests
            // is identical to the JSON representation needed for the API payload.
            // If that changes, we'll need to be explicit about the sub-keys.
            //
            content_edit_requests: editRequestsClone
        };
        formData.append('multipart_post_params', JSON.stringify(data));

        //
        // Clear message blocks at beginning of request
        // This way, if they submit twice and receive the same error message,
        // they will see the the message disappear and re-appear, so they will know
        // that the re-submit was attempted.
        //
        this.props.updateMessageBlocks([], 'success');

        //
        // Make the Request
        //
        post(url, formData).then( resp => {

            //
            // set requestUnderway false - reactivate Submit button
            //
            this.setState({requestUnderway: false});

            if (resp.status >= 400) {
                //
                // NOTE: Error messages will populate into Red Message Blocks due to an
                // axios interceptor in App.js, around line 81
                //
                return;
            }

            //
            // Success - tell the user their request was sent, and close the modal
            //
            this.props.updateMessageBlocks([
                'Thank you for submitting your Content Edit Request. A member of our Content Team will'
                +' typically respond within 1-2 business days.'
            ], 'success');
            this.props.updateModalContent();

            //
            // Optional callback, used by AA Step 4 to e.g. change approval state to "EDITS PENDING"
            //
            if (typeof this.props.afterSubmitCallback == 'function') {
                this.props.afterSubmitCallback(this.props.campaign, this.props.campaignIndex);
            }
        });
    };


    /**
     * Provides the options needed for the <Select> for each Content Edit Request's Scope
     * @returns {Array}
     */
    scopeOptions = (campaign) => {

        let scopeOptions = [];

        // Removed this option: https://app.asana.com/0/0/1154130343793455/f
        // {"value": "campaign", "text": "Just this Campaign Site"},


        //
        // Parent contractor case - has 2 additional scope options
        //
        if (typeof(campaign.company_name) !== 'undefined') {
            //
            // Find the name of the industry that the current campaign, for e.g.
            // "All Plumbing Sites for <Company Name>"
            //
            let campaignIndustry = this.getCampaignIndustryOption();
            scopeOptions.push({
                value: "contractor_wide_within_industry",
                text: "All " + campaignIndustry.text + " Sites for " + this.props.campaign.company_name
            });

            //
            // And an option for all sites for this company
            //
            scopeOptions.push({
                value: "contractor_wide",
                text: "All Sites for " + this.props.campaign.company_name}
            );
        }


        //
        // Include an options for each industry: "All My <Industry> Sites"
        //
        scopeOptions = scopeOptions.concat(this.props.industryOptions.map(
            (industryObj) => ({
                value: "industry_" + industryObj.value.toString(),
                text: "All My " + industryObj.text + " Sites"
            })
        ));


        scopeOptions.push({value: "all", text: "All My Sites"});


        return scopeOptions;
    };




    render() {
        return (
            <>
                <p className="type-large-body no-margin-top padding-20-bottom spacing-20-bottom type-bottom-stroke">
                    Please specify any changes you are requesting to your Campaign Microsite(s) by filling out the form below. You are also welcome to include attachments.
                </p>

                {this.state.content_edit_requests.map((editRequest, idx) => (
                    <RequestContentEditForm key={idx}
                        campaign={this.props.campaign}
                        editRequestIdx={idx}
                        editRequest={ this.state.content_edit_requests[idx] }

                        updateValue={ this.updateValue }
                        addAttachment={ this.addAttachment }
                        updateAttachment={ this.updateAttachment }
                        deleteAttachment={ this.deleteAttachment }
                        deleteEditRequest={ this.deleteEditRequest }
                        scopeOptions={ this.scopeOptions }
                    />
                ))}

                <div className="type-small-body">
                    <span role="button"
                        className="type-heavy type-blue"
                        onClick={() => this.addEditSet() }
                    >
                        <span className="inline-icon inline-icon__middle inline-icon__20">
                            { PlusSignSvg }
                        </span>
                        Add Another Content Edit Request
                    </span>
                </div>

                <div className="ui-hide-tablet ui-hide-full">
                    <div className="popup__form__row spacing-40-top-mobile">
                        <div className="popup__form__cell popup__form__cell__100__mobile">
                            <button
                                className="button ui-normal-button ui-full-width-button"
                                onClick={ (event) => {
                                    event.preventDefault();
                                    this.makeContentEditRequest();
                                }}
                                disabled={ this.state.requestUnderway }
                            >
                                Request Content Edits
                            </button>
                        </div>
                    </div>
                </div>
                <div className="ui-hide-mobile">
                    <div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
                        <div className="popup__form__row popup__form__row__slam-right">
                            <div className="popup__form__cell">
                                <span role="button" className="type-small-body type-heavy type-blue"
                                      onClick={() => this.props.updateModalContent()}
                                >
                                    Cancel
                                </span>
                            </div>
                            <div className="popup__form__cell">
                                <button className="button ui-normal-button qa-content-submit-button"
                                    onClick={ (event) => {
                                        event.preventDefault();
                                        this.makeContentEditRequest();
                                    }}
                                    disabled={ this.state.requestUnderway }
                                >
                                    Request Content Edits
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
