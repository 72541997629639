import React, {Component, memo} from 'react';
import axios from "axios";

import AudioPlayer, {AudioPlayerSkeleton} from '../../common/AudioPlayer';
import BillableLeadBlock from './lead_detail_blocks/BillableLeadBlock';
import Checkbox from "../../common/components/form_elements/Checkbox";
import ControlsBlock from './lead_detail_blocks/ControlsBlock';
import JobRevenueBlock from './lead_detail_blocks/JobRevenueBlock';
import LeadNoteView from './lead_detail_blocks/LeadNoteView';
import LeadReviewBlock from './lead_detail_blocks/LeadReviewBlock';
import CallInsightsBlock from './lead_detail_blocks/CallInsightsBlock';
import CallTranscriptBlock from './lead_detail_blocks/CallTranscriptBlock';
import RelatedLeadsBlock from './lead_detail_blocks/RelatedLeadsBlock';
import LeadProgressHeader from './lead_progress/LeadProgressHeader';
import Radio from '../../common/components/form_elements/Radio';
import LostReasonBlock from './lead_detail_blocks/LostReasonBlock';
import {errorHandler, post} from "../../Requests";
import Tooltip from "../../common/Tooltip";
import TextArea from "../../common/components/form_elements/TextArea";
import {formatPhoneNumber, formattedTimeAndDate, separateTimeAndDate} from "../../Util";
import SubmitForReviewModal from "../../client/lead_manager/SubmitForReviewModal";
import {OutlinedPlusSignSvg, QualityCheckedSvg, Twilio} from '../Svgs';
import AuthService from "../../service/AuthService";
import AdminLeadTags from './lead_detail_blocks/AdminLeadTags';
import LeadService from '../../service/LeadService';
import Validator from '../../lib/Validator';
import type {ILead, IQnA} from "../../Interfaces/ILead";
import type {IAddress} from "../../Interfaces/IAddress";
import {ContractorTypeIds} from "../StaticValues";

export default memo(class LeadDetail extends Component {
    autoPlayCallAudio = false;

    //
    // TODO create a ProcessingButton component to house all this logic
    //
    BUTTON_STATES = {
        ENABLED: 1, /* button enabled */
        DISABLED: 2, /* button disabled */
        SAVING: 3, /* button disabled */
        DISABLED_SAVE: 4 /* button disabled with "Save" label */
    };

    constructor(props) {
        super(props);

        this.state = {
            expanded: props.isExpanded,
            claimingCert: false,
            revenue: undefined,
            note: '',
            isForm: props.lead.lead_type === 'form',
            privateNote: true,
            noteTextAreaExpanded: false,
            // progressSelected: this.props.lead.lead_progress,
            lostButtonState: this.BUTTON_STATES.DISABLED_SAVE,
            revenueButtonState: this.BUTTON_STATES.DISABLED_SAVE,
            audioPlaying: false,
            isUpdatingProgress: false,
            leadProgress: {},
            audioUrl: props.lead.call_file_url
        };

        this.cancelSignal = axios.CancelToken.source();
        this.autoPlayCallAudio = this.props.autoPlayCallAudio;
        this.toolTipRef = React.createRef();
        this.noteTextAreaRef = React.createRef();
        this.leadService = new LeadService();
    }

    /**
     * This component renders even if its corresponding lead row isn't expanded.
     * But we don't want getLead()'s API request being issued unconditionally,
     * but rather only when a row is expanded.
     *
     * The isExpanded conditional ensures that, but also requires reference the
     * hasLoaded flag in order to avoid an infinite loop.  This is certainly a
     * code smell; maybe we should be issuing the call to getLead() elsewhere.
     *
     * For now, just be aware of why those flags exist, and what the conditional
     * below helps avoid.  - mz 27 Aug 2019
     */
    componentDidUpdate(prevProps: Readonly, prevState: Readonly, ignore): void {
        this.autoPlayCallAudio = this.props.autoPlayCallAudio;

        if (!this.props.isExpanded && this.state.noteTextAreaExpanded) {
            this.setState({noteTextAreaExpanded: false, note: '', privateNote: true});
        }

        if (prevProps?.lead?.call_file_url !== this.props.lead.call_file_url) {
            this.setState({audioUrl: this.props.lead.call_file_url})
        }
    }

    componentDidMount() {
        this.leadService.getLeadProgressForLead(this.props.lead.lead_id)
            .then(res => this.setState({leadProgress: res.data}));
    }

    componentWillUnmount() {
        this.cancelSignal.cancel();
    }

    handleAudioLoadError = () => {
        // if fetching of the URL fails, assume there's no recording
        // set the URL to boolean type false, so we can display the non-playable audio player
        this.setState({audioUrl: false});
        this.props.updateLeadErrorUrl();
    }

    /**
     * Returns a lead message's label and content jsx. For phone leads, content
     * jsx renders the call player, otherwise the form message body from the form read.
     * @return {{messageJsx: string, label: string}}
     */
    getLeadMessage = () => {
        const {lead} = this.props
        let label
        let lastPlayed
        let lastAdminPlayed
        let messageJsx;

        if (this.state.audioUrl) {
            label = "Listen to Call"
            messageJsx = (
                <div className="lead-message-grid">
                    <div>
                        <AudioPlayer
                            autoPlayCallAudio={this.autoPlayCallAudio}
                            leadId={lead.lead_id}
                            path={this.state.audioUrl}
                            onError={this.handleAudioLoadError}
                            format="audio/mp3"
                            addAudioPlayerRefToLogRow={this.props.addAudioPlayerRefToLogRow}
                            addLeadMessagePlayer={this.props.addLeadMessagePlayer}
                            stopAllLeadMessagePlayers={this.props.stopAllLeadMessagePlayers}
                            onPlay={this.props.handleCallRecordingPlayed}
                            audioPlaying={this.state.audioPlaying}
                            updateAudioPlaying={this.updateAudioPlaying}
                        />
                    </div>
                </div>
            );
        }
        else if (lead.lead_call_id) {
            // if the value is a boolean false, it means the recording is not available
            label = this.state.audioUrl === false
                ? 'Recording Not Available'
                : 'Loading Call'
            messageJsx = <AudioPlayerSkeleton/>
        }
        else {
            label = "Message";
            messageJsx = lead.notes
                ? lead.notes
                : (<span className="type-italics">No form lead message provided.</span>);
        }

        if (lead.latest_timestamp_played_recording) {
            const lastPlayedUsername = this.props.getUserNameForUserId(lead.latest_user_id_played_recording);
            const lastPlayedTimestamp = formattedTimeAndDate(lead.latest_timestamp_played_recording);
            const lastPlayedTimestampJsx = <>
                {lastPlayedTimestamp.date} {lastPlayedTimestamp.time}
                <span className="type-extra-small-body">{lastPlayedTimestamp.amPm}</span>
            </>;

            lastPlayed = <>Last played on {lastPlayedTimestampJsx} by {lastPlayedUsername}</>;
            if (this.props.adminView) {
                lastPlayed = <>Client: <span className="type-italics">{lastPlayed}</span></>;
            }
            else {
                lastPlayed = <><span className="type-italics">({lastPlayed})</span></>
            }
        }

        if (lead.latest_admin_timestamp_played_recording) {
            const lastPlayedAdminUsername = lead.call_played_admin_username;
            const lastPlayedAdminTimestamp = formattedTimeAndDate(lead.latest_admin_timestamp_played_recording);
            const lastPlayedAdminTimestampJsx = <>
                {lastPlayedAdminTimestamp.date} {lastPlayedAdminTimestamp.time}
                <span className="type-extra-small-body">{lastPlayedAdminTimestamp.amPm}</span>
            </>;
            lastAdminPlayed = <>Admin: <span
                className="type-italics">Last played on {lastPlayedAdminTimestampJsx} by {lastPlayedAdminUsername}</span></>;
        }

        return {
            label: label,
            lastPlayed: lastPlayed,
            lastAdminPlayed: lastAdminPlayed,
            messageJsx: messageJsx
        };
    };

    hasProgressValue = (leadProgressStringKey) => {
        let leadProgressRows = this.state.leadProgress;

        for (let i = 0; i < leadProgressRows.length; i++) {
            let leadProgressRow = leadProgressRows[i];
            if (leadProgressRow.string_key === leadProgressStringKey) {
                return true;
            }
        }

        return false;
    };

    updateAudioPlaying = (value) => {
        this.setState({audioPlaying: value});
    };

    updateLeadProgress = (body, callback) => {
        this.setState({isUpdatingProgress: true})
        return this.leadService
            .updateLeadProgressForLead(this.props.lead.lead_id, body)
            .then((res) => {
                this.setState({
                    leadProgress: res.data,
                    isUpdatingProgress: false
                })
                callback && typeof callback === 'function' && callback(res.data)
            })
            .catch((err) => {
                console.error('Error updating lead progress', err)
            })
    };

    claimCertificate = () => {
        this.setState({claimingCert: true});

        this.leadService.claimCertificate(this.props.lead.lead_id)
            .then((claimedState) =>
                this.props.updateLead(this.props.lead.lead_id, {trusted_form_cert_claimed: claimedState})
            )
            .finally(() => this.setState({claimingCert: false}));
    };

    /**
     * returns an object representing the button's state based on its state id
     * @param {number} stateId - the button state id
     * @return {object}
     *
     * TODO create a ProcessingButton component to house all this logic
     */
    parseButtonState = stateId => {
        switch (stateId) {
            case this.BUTTON_STATES.ENABLED:
                return {
                    label: 'Save',
                    disabled: false
                };

            case this.BUTTON_STATES.DISABLED:
                return {
                    label: 'Saved',
                    disabled: true
                };

            case this.BUTTON_STATES.SAVING:
                return {
                    label: 'Saving...',
                    disabled: true,
                    saving: true
                };

            // case this.BUTTON_STATES.DISABLED_SAVE:
            default:
                return {
                    label: 'Save',
                    disabled: true
                };
        }
    };

    updateRevenueButtonState = stateId => {
        this.setState({revenueButtonState: stateId});
    };

    updateLostButtonState = stateId => {
        this.setState({lostButtonState: stateId});
    };

    renderSubmitForReviewPopup = (leadId) =>
        this.props.updateModalContent(<SubmitForReviewModal
                leadId={leadId}
                submitForReview={this.submitForReview}
                updateMessageBlocks={this.props.updateMessageBlocks}
                isForm={this.state.isForm}
            />,
            'Submit Lead For Review',
            '',
            false
        );

    /**
     * Accepts a leadId and review params and submis the lead for review.
     * @param leadId
     * @param {leadId, message} params
     */
    submitForReview = (leadId, params) =>
        post(`leads/${leadId}/review`, params)
            .then(resp => {
                this.props.getLead(leadId);
                this.props.updateModalContent();
                this.props.updateMessageBlocks(resp.data.message, 'success');
            })
            .catch(errorHandler);

    /**
     * Sends the provided note text to the API's POST /leads/<lead_id>/notes
     * endpoint, creating the note for the lead.
     */
    submitNote = () => {
        const leadId = this.props.lead.lead_id,
            data = {note: this.state.note.trim()};

        if (!data.note) {
            return;
        }

        if (this.props.adminView) {
            data.is_private = this.state.privateNote ? '1' : '0';
        }

        post(`leads/${leadId}/notes`, data, this.cancelSignal.token)
            .then(() => {
                this.setState({noteTextAreaExpanded: false, note: '', privateNote: true});
                this.props.getLead(leadId);
            })
            .catch(errorHandler);
    };

    /**
     * returns a JSX array of lead notes
     * @param {boolean} [notesForReview = false] if set to true, will return only notes to be reviewed
     * @return {Object[]}
     */
    getNoteJsx = (notesForReview = false) => {
        let lead_notes = this.props.lead.lead_notes || [];
        if (notesForReview) {
            lead_notes = lead_notes.filter((note) => note.submitted_to_support == 1 || note.subtype);
        }

        return lead_notes.map((note, index) => (
            <LeadNoteView
                key={index}
                note={note}
                dateTime={separateTimeAndDate(note.creation_timestamp)}
                modifiedDateTime={note.last_modified ? separateTimeAndDate(note.last_modified) : null}
                adminView={this.props.adminView}
                lead={this.props.lead}
                updateNote={this.props.updateNote}
                excludeButtons={notesForReview}
            />
        ));
    };

    /**
     * formats an address from a lead into two parts: street and location
     *  e.g. street: "2121 Avenue of the Stars, #666"
     *      location: "Los Angeles, CA, 90067"
     *
     * @param {IAddress} address
     * @return {{street: string, location: string}}
     */
    formatAddress(address: IAddress) {
        if (!address) {
            return {};
        }

        let street = address.address;
        if (address.unit) {
            street += ', ';
            if (address.unit[0] !== '#') {
                street += '#';
            }
            street += address.unit;
        }

        let location = '';
        if (address.city) {
            location = address.city;
        }
        if (address.state) {
            if (location) {
                location += ', ';
            }
            location += address.state;
        }
        if (address.zip) {
            if (location) {
                location += ', ';
            }
            location += address.zip;
        }

        return {street, location};
    }

    hasProjectInfo(lead: ILead) {
        return lead.subcategory || lead.homeowner || lead.time_frame || lead.property_type || lead.details;
    }

    /**
     * returns true iff the lead is won with revenue or lost with a reason
     */
    isWonOrLostWithReason({client_revenue, job_won, lost_reasons}) {
        return (job_won == null && client_revenue != null) ||
            (job_won == 1 && client_revenue != null) ||
            (job_won == 0 && lost_reasons && Object.keys(lost_reasons).length > 0);
    }

    /**
     * converts a homeowner enum value to a readable string
     *  e.g. "no_but_can_make_changes" => "No, but can make changes"
     * @param {string} value
     * @return {string}
     */
    parseHomeOwner(value: number) {
        switch (value) {
            case 'yes':
                return 'Yes';
            case 'no':
                return 'No';
            case 'no_but_can_make_changes':
                return 'No, but can make changes';
            default:
                return 'N/A';
        }
    }

    /**
     * returns a separator if the question of a Q&A item doesn't have a ':' or '?' at the end of it
     * assumes qna property values to be trimmed
     * @param {IQnA} qna
     */
    qnaSeparator(qna: IQnA) {
        const lastChar = qna.q?.charAt(qna.q?.length - 1);
        return lastChar !== '?' && lastChar !== ':'
            ? ':'
            : '';
    }

    parseTrustedFormCertificateLabel = () => {
        const lead: ILead = this.props.lead;

        // in the process of claiming a certificate
        if (this.state.claimingCert) {
            return <span className="spinny-loader small-loader type-left-side-bump-small"/>;
        }

        // invalid certificate
        else if (!Validator.validateUrl(lead.trusted_form_cert_url)) {
            return <span className="type-grey">Invalid Certificate</span>;
        }

        // certificate available to be claimed
        else if (lead.trusted_form_cert_claimed === null) {
            return <button className="button-clean type-blue type-heavy type-normal-body"
                           onClick={this.claimCertificate}>Retain Certificate</button>;
        }

        // certificate expired or declined and can not be claimed
        else if (lead.trusted_form_cert_claimed == 0) {
            return <span className="type-grey">Certificate Expired</span>;
        }

        // certificate claimed and can be viewed
        else if (lead.trusted_form_cert_claimed == 1) {
            return <a href={lead.trusted_form_cert_url} target="_blank">View Certificate</a>;
        }

        // should never happen
        else {
            return <span className="type-heavy">Unexpected; contact the dev team</span>
        }
    };

    render() {
        let lead: ILead = this.props.lead,
            leadId = lead.lead_id,
            leadMessage = this.getLeadMessage(),
            noteJsx = this.getNoteJsx(),
            submittedNotes = this.getNoteJsx(true),
            hideTextAreaNotesClass,
            hideFirstAddNoteButtonClass,
            address = this.formatAddress(lead.address);
        const {leadProgress} = this.state;

        // clean the details
        if (Array.isArray(lead.details)) {
            lead.details.forEach((qna: IQnA) => {
                qna.q = qna.q?.trim();
                qna.a = qna.a?.trim();
            });
        }

        if (this.state.noteTextAreaExpanded === true) {
            hideTextAreaNotesClass = "";
            hideFirstAddNoteButtonClass = "invisible";
        }
        else {
            hideTextAreaNotesClass = "ui-hide-full ui-hide-tablet ui-hide-mobile";
            hideFirstAddNoteButtonClass = "type-heavy";
        }

        let detailsClass = 'padding-34-top no-padding-top-mobile padding-70-bottom no-padding-bottom-mobile' +
            ' leadlog__controls__row leadlog_type_' + lead.contractor_type.replace(' ', '_').toLowerCase();
        if (this.props.adminView) {
            detailsClass += ' leadlog__admin-view';
        }
        if (this.state.isForm) {
            detailsClass += ' leadlog__form-details';
        }

        let trackingNumber = lead.tracking_number
        let isDidNumber = false
        let didPhoneName = lead.did_phone_name
        if (lead.did_number) {
            trackingNumber = formatPhoneNumber(lead.did_number)
            isDidNumber = true
        }


        const twilioLink = `https://console.twilio.com/us1/monitor/logs/calls?frameUrl=/console/voice/calls/logs/${lead.call_id}`;

        return <div className={detailsClass}>
            {this.props.adminView && (
                <div className="leadlog__lead-details">
                    <div>
                        <span className="type-large-body type-heavy type-force-newline">
                            Lead ID
                        </span>
                        <span className="type-normal-body type-force-newline type-heavy">
                            <a href={`/leads?lead_id=${leadId}`}>
                                {leadId}
                            </a>
                        </span>
                    </div>
                    <div>
                        <span className="type-large-body type-heavy type-force-newline">
                            Zip Code
                        </span>
                        <span className="type-normal-body type-force-newline">
                            {lead.ping_zip_code ? lead.ping_zip_code : 'N/A'}
                        </span>
                    </div>
                    <div>
                        <span className="type-large-body type-heavy type-force-newline">
                            Lead Source
                        </span>
                        <span className="type-normal-body type-force-newline">
                            {lead.ad_network_name ? lead.ad_network_name : 'N/A'}
                        </span>
                    </div>
                    {trackingNumber && <div>
                        <span className="type-large-body type-heavy type-force-newline">
                        {isDidNumber ? "DID Phone Number" : "Tracking Number"}
                        </span>
                        <span className="type-normal-body type-force-newline">
                            {trackingNumber}
                            <a href={twilioLink}
                               target="_blank"
                               className="spacing-14-left"
                               title="Call Details in Twilio">
                                <span className="inline-icon inline-icon__middle inline-icon__20">
                                    {Twilio}</span>
                            </a>
                        </span>
                    </div>}
                    <div>
                        {isDidNumber && <div>
                        <span className="type-large-body type-heavy type-force-newline">
                        {isDidNumber}DID Phone Name
                        </span>
                            <span className="type-normal-body type-force-newline">
                            {didPhoneName}
                        </span>
                        </div>
                        }
                    </div>
                </div>
            )}

            {this.state.isForm &&
            <>
                <div className="leadlog__contact_info padding-24-bottom-mobile">
                    <div className="type-large-body type-heavy no-margin">
                        Contact Info
                    </div>
                    <div>{lead.name}</div>
                    <a href={`mailto:${lead.email}`} className="type-heavy">{lead.email}</a>
                    <a href={`tel:${lead.phone_number}`} className="type-heavy">{lead.phone_number}</a>
                    {address.street &&
                    <div>{address.street}</div>}
                    {address.location &&
                    <div>{address.location}</div>}
                </div>

                {this.hasProjectInfo(lead) &&
                <div className="leadlog__project_info padding-24-bottom-mobile">
                    <div className="type-large-body type-heavy no-margin">
                        Project Info
                    </div>
                    {AuthService.isAdmin &&
                    <div>TrustedForm Certificate: {this.parseTrustedFormCertificateLabel()}</div>}
                    {lead.subcategory &&
                    <div>Subcategory: {lead.subcategory}</div>}
                    {lead.homeowner &&
                    <div>Homeowner: {this.parseHomeOwner(lead.homeowner)}</div>}
                    {lead.time_frame &&
                    <div>Time Frame: {lead.time_frame}</div>}
                    {lead.property_type &&
                    <div>Property Type: {lead.property_type}</div>}
                    {AuthService.isAdmin && lead.source &&
                    <div>Source: {lead.source}</div>}
                    {lead.details &&
                    <div>
                        Project Details:
                        {lead.details.map((qna, index) => <div key={index}>
                            {qna.q}{this.qnaSeparator(qna)} {qna.a}
                        </div>)}
                    </div>}
                </div>}
            </>}

            <div className="leadlog__audioplayer padding-60-bottom-mobile">
                <div
                    className={`leadlog__detail-section ${this.props.adminView ? 'leadlog__detail-section__admin' : ''}`}>
                    <div className="leadlog__audioplayer__header">
                        <div className="type-large-body type-heavy no-margin">
                            {leadMessage.label}
                        </div>
                        {(leadMessage.lastPlayed || (this.props.adminView && leadMessage.lastAdminPlayed)) &&
                        <div className="type-small-body no-margin">
                            {leadMessage.lastPlayed &&
                            <span className="type-force-newline">{leadMessage.lastPlayed}</span>}
                            {this.props.adminView && leadMessage.lastAdminPlayed &&
                            <span className="type-force-newline">{leadMessage.lastAdminPlayed}</span>}
                        </div>}
                    </div>
                    <div className="leadlog_form_message">
                        {leadMessage.messageJsx}
                    </div>
                    {this.props.adminView && this.props.lead.call_transcript && (
                        <CallTranscriptBlock
                            lead={this.props.lead}
                            audioPlayerRef={this.props.audioPlayerRef}
                            updateAudioPlaying={this.updateAudioPlaying}
                        />
                    )}
                </div>
            </div>

            <div className="leadlog__controls padding-60-bottom-mobile">
                <ControlsBlock
                    leadId={leadId}
                    onFollowUpClick={(e) =>
                        this.props.updateLeadProgress({followUp: e.target.dataset.checked !== 'true'})}
                    onStarClick={() => {
                        let starred = parseInt(lead.starred, 10);
                        this.props.updateLead(leadId, {starred: starred ? 0 : 1});
                    }}
                    onFlagLeadClick={(e) =>
                        this.props.onFlagLeadClick(e, leadId, lead.contractor_name)}
                    onLeadReviewClick={() => this.props.onLeadReviewClick(leadId)}
                    starred={lead.starred}
                    needsFollowUp={this.hasProgressValue('needs_follow_up')}
                    leadReview={lead.lead_review_status}
                    adminView={this.props.adminView}
                    flagged={this.props.isFlagged()}
                    ticketId={this.props.lead.hubspot_ticket_id}
                />
            </div>

            {lead.contractor_type_id != ContractorTypeIds.ExternalBuyer &&
                <div className="leadlog__progress-management padding-60-bottom-mobile">
                    <div
                        className={`leadlog__detail-section ${this.props.adminView ? 'leadlog__detail-section__admin' : ''}`}>
                        <p className="type-large-body type-heavy type-single-line no-margin-top spacing-5-bottom">
                            Lead Progress and Revenue{' '}
                            {!this.props.adminView && (
                                <Tooltip
                                    position="right"
                                    content={
                                        <>
                                            <span
                                                className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                Track Lead Progress
                                            </span>
                                            <span
                                                className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                Use the Lead Progress features to ensure you convert as many calls as possible into Won Jobs and Revenue.
                                                Lead Progress data is also aggregated into Lead Performance Metrics to give you insight into the overall
                                                picture of your performance.
                                            </span>
                                            <span
                                                className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                Job Revenue
                                            </span>
                                            <span
                                                className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                It is very important to track Job Revenue on each booked job so that you can calculate your Return On Investment.
                                                By providing this transparent data, we are confident our service will prove its value over time.
                                            </span>
                                            <span
                                                className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                                Learn More about{' '}
                                                <a className="type-heavy" target="_blank"
                                                   href={AuthService.isMarketplace
                                                       ? "https://support.servicedirect.com/managing-your-leads"
                                                       : "https://support.servicedirect.com/select/managing-your-leads"}>
                                                    Managing Your Leads.
                                                </a>
                                            </span>
                                        </>
                                    }
                                    modalContainerRef={this.toolTipRef}
                                />
                            )}
                        </p>
                        {!this.props.adminView &&
                        <p className="type-small-body spacing-5-top">
                            Managing Progress helps us optimize your Campaigns and tracking Revenue enables ROI Metrics.
                        </p>}
                        <div className="leadlog__management-stages spacing-30-top">
                            <div className="leadlog__management-stages__stage">
                                <LeadProgressHeader
                                    label={lead.lead_type === 'phone' ? 'Call Answered' : 'Responded to Customer'}
                                    showTimeline={true}
                                    checked={leadProgress?.call_answered != null}
                                    // checked={false}
                                />
                                <div className="leadlog__management-stages__controls padding-20-bottom-mobile">
                                    <div className="type-normal-body type-single-line no-margin-top spacing-20-bottom">
                                        <Radio
                                            name={"call_answered_" + leadId}
                                            id={"call_answered_true_" + leadId}
                                            label="Yes"
                                            value="true"
                                            onChange={() => this.updateLeadProgress({call_answered: 1})}
                                            checked={leadProgress?.call_answered == '1'}
                                            disabled={this.state.isUpdatingProgress}
                                        />
                                    </div>
                                    <div className="type-normal-body type-single-line spacing-20">
                                        <Radio
                                            name={"call_answered_" + leadId}
                                            id={"call_answered_false_" + leadId}
                                            label="No"
                                            value="false"
                                            onChange={() => this.updateLeadProgress({call_answered: 0})}
                                            checked={leadProgress?.call_answered == '0'}
                                            disabled={this.state.isUpdatingProgress}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="leadlog__management-stages__stage">
                                <LeadProgressHeader
                                    label="Booked Appointment"
                                    showTimeline={true}
                                    checked={leadProgress.booked_appointment != null}
                                />
                                <div className="leadlog__management-stages__controls padding-20-bottom-mobile">
                                    <div className="type-normal-body type-single-line no-margin-top spacing-20-bottom">
                                        <Radio
                                            name={'booked_appointment_' + leadId}
                                            id={'booked_appointment_true_' + leadId}
                                            label="Yes"
                                            value="true"
                                            onChange={() => this.updateLeadProgress({booked_appointment: 1})}
                                            checked={leadProgress?.booked_appointment === '1'}
                                            disabled={this.state.isUpdatingProgress}
                                        />
                                    </div>
                                    <div className="type-normal-body type-single-line spacing-20">
                                        <Radio
                                            name={'booked_appointment_' + leadId}
                                            id={'booked_appointment_false_' + leadId}
                                            label="No"
                                            value="false"
                                            onChange={() => this.updateLeadProgress({booked_appointment: 0})}
                                            checked={leadProgress?.booked_appointment === '0'}
                                            disabled={this.state.isUpdatingProgress}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="leadlog__management-stages__stage">
                                <LeadProgressHeader
                                    label="Job Won"
                                    showTimeline={true}
                                    checked={leadProgress?.job_won != null}
                                />
                                <div className="leadlog__management-stages__controls padding-20-bottom-mobile">
                                    <div className="type-normal-body type-single-line no-margin-top spacing-20-bottom">
                                        <Radio
                                            name={'job_status_' + leadId}
                                            id={'job_status_true_' + leadId}
                                            label="Yes"
                                            value="true"
                                            onChange={() => this.updateLeadProgress({job_won: 1})}
                                            checked={leadProgress?.job_won == '1'}
                                            disabled={this.state.isUpdatingProgress}
                                        />
                                    </div>
                                    <div className="type-normal-body type-single-line spacing-20">
                                        <Radio
                                            name={'job_status_' + leadId}
                                            id={'job_status_false_' + leadId}
                                            label="No"
                                            value="false"
                                            onChange={() => this.updateLeadProgress({job_won: 0})}
                                            checked={leadProgress?.job_won == '0'}
                                            disabled={this.state.isUpdatingProgress}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="leadlog__management-stages__stage">
                                <LeadProgressHeader
                                    label={leadProgress?.job_won == 0 ? 'Lost Reason' : 'Revenue'}
                                    showTimeline={false}
                                    checked={this.isWonOrLostWithReason(leadProgress)}
                                    tooltip={leadProgress?.job_won == 0 &&
                                    <Tooltip
                                        position="right"
                                        content={
                                            <>
                                                <span
                                                    className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                    Lost Reason
                                                </span>
                                                <span
                                                    className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                    Use Lost Reason to track objections and primary reasons when a potential job is not won.
                                                    This will help you gain insight into the effectiveness of your sales process and identify
                                                    new best practices to help you close more leads.
                                                </span>
                                                <span
                                                    className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                    Lost Reason data is available in the Lead Export for further analysis and reporting.
                                                    This also will help our team optimize your Campaigns for improved lead quality.
                                                </span>
                                                <span
                                                    className="type-small-body type-black type-force-newline type-align-left type-notransform type-narrow-line-height type-charcoal spacing-10-bottom">
                                                    Learn More about{' '}
                                                    <a className="type-heavy" target="_blank"
                                                       href={AuthService.isMarketplace
                                                           ? "https://support.servicedirect.com/managing-your-leads"
                                                           : "https://support.servicedirect.com/select/managing-your-leads"}>
                                                        Managing Your Leads.
                                                    </a>
                                                </span>
                                            </>
                                        }

                                    />}
                                />
                                <div className="leadlog__management-stages__controls">
                                    {leadProgress.job_won == 0
                                        ? <LostReasonBlock
                                            leadId={lead.lead_id}
                                            updateLeadProgress={this.updateLeadProgress}
                                            lostReasons={leadProgress.lost_reasons}
                                            parsedButtonState={this.parseButtonState(this.state.lostButtonState)}
                                            updateButtonState={this.updateLostButtonState}
                                            buttonStateOptions={this.BUTTON_STATES}
                                        />
                                        : <JobRevenueBlock
                                            leadId={lead.lead_id}
                                            leadProgress={leadProgress}
                                            updateLeadProgress={this.updateLeadProgress}
                                            industryRevenueAverage={this.props.industryRevenueAverage}
                                            parsedButtonState={this.parseButtonState(this.state.revenueButtonState)}
                                            updateButtonState={this.updateRevenueButtonState}
                                            buttonStateOptions={this.BUTTON_STATES}
                                        />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            <div className="leadlog__leads padding-60-bottom-mobile">
                {this.props.adminView ? (
                    <>
                        <LeadReviewBlock
                            lead={this.props.lead}
                            leadId={lead.lead_id}
                            leadStatusOptions={this.props.leadStatusOptions}
                            updateLead={this.props.updateLead}
                            submittedNotes={submittedNotes}
                        />
                        <RelatedLeadsBlock lead={this.props.lead}/>
                        <AdminLeadTags
                            leadId={lead.lead_id}
                        />
                        {this.props.lead.lead_type === 'phone' && (
                            <CallInsightsBlock
                                leadId={this.props.lead.lead_id}
                                leadProgress={leadProgress}
                                updateLeadProgress={this.updateLeadProgress}
                                callInsightsStarted={this.props.lead.call_insights_started}
                                callInsightsComplete={this.props.lead.call_insights_complete}
                                enrolledInCallInsights={this.props.lead.enrolled_in_call_insights}
                                updateLead={this.props.updateLead}
                                disabled={this.state.isUpdatingProgress}
                            />
                        )}
                    </>
                ) : (
                    <div className="wrap">
                        {this.props.lead.is_quality_checked === '1' && (
                            <div className="leadlog__quality-checked">
                                <div className="badge">{QualityCheckedSvg}</div>
                                <span>&nbsp;</span>
                                <Tooltip
                                    position="left"
                                    content={
                                        <>
                                            <span
                                                className="type-large-body type-black type-force-newline type-heavy type-narrow-line-height type-notransform spacing-10-bottom">
                                                Quality Check
                                            </span>
                                            <span
                                                className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                In our continual effort to improve Lead quality, SD Quality Check proactively reviews some Leads through a combination of technology and our Customer Success team.
                                            </span>
                                            <span
                                                className="type-small-body type-black type-force-newline type-notransform type-narrow-line-height spacing-10-bottom">
                                                <a className="type-heavy" target="_blank"
                                                   href="https://blog.servicedirect.com/introducing-quality-check">
                                                    Learn More
                                                </a>
                                            </span>
                                        </>
                                    }
                                    modalContainerRef={this.toolTipRef}
                                />
                            </div>
                        )}
                        <div>
                            <p className="type-large-body type-heavy type-single-line no-margin-top spacing-10-bottom">
                                Lead Status
                            </p>
                            <p className="type-normal-body type-narrow-line-height">
                                {lead.is_valid == 1
                                    ? "Billable"
                                    : `Non-Billable – ${lead.lead_status}`}
                            </p>
                        </div>
                        {!AuthService.blockLeadSubmissions &&
                        <BillableLeadBlock
                            lead={lead}
                            renderSubmitForReviewPopup={() => this.renderSubmitForReviewPopup(leadId)}/>}
                    </div>
                )}
            </div>

            <div className="leadlog__history">
                <div
                    className={`leadlog__detail-section ${this.props.adminView ? 'leadlog__detail-section__admin' : ''}`}>
                    <div
                        className="simpleflex__row simpleflex__row__bottomaligned simpleflex__row__wrap__mobile spacing-30-bottom spacing-10-bottom-mobile-important">
                        <div className="simpleflex__cell spacing-10-bottom-mobile simpleflex__cell__one-half">
                            <p className="type-large-body type-heavy type-single-line no-margin">
                                <label htmlFor={"lead_notes_" + leadId}>Lead History</label>
                            </p>
                        </div>
                        <div
                            className="type-align-right simpleflex__cell spacing-10-bottom-mobile simpleflex__cell__one-half">
                            <span className={hideFirstAddNoteButtonClass}
                                  role="button"
                                  onClick={() => this.setState({noteTextAreaExpanded: true},
                                      () => this.noteTextAreaRef.current.focus())}>
                                <span className="inline-icon inline-icon__middle inline-icon__20">
                                    {OutlinedPlusSignSvg}
                                </span>{' '}
                                Add Note
                            </span>
                        </div>
                    </div>
                    <div className={hideTextAreaNotesClass}>
                        <div
                            className={hideTextAreaNotesClass + " simpleflex__row simpleflex__row__bottomaligned simpleflex__row__wrap__mobile spacing-30-bottom spacing-40-mobile"}>
                            <TextArea
                                name={"lead_notes" + leadId}
                                id={"lead_notes_" + leadId}
                                value={this.state.note}
                                onChange={(event) => this.setState({note: event.target.value})}
                                inputRef={this.noteTextAreaRef}
                            />
                        </div>
                        <div
                            className={hideTextAreaNotesClass + " simpleflex__row simpleflex__row__bottomaligned simpleflex__row__wrap__mobile spacing-30-bottom spacing-40-mobile"}>
                            <div className="simpleflex__cell simpleflex__cell__full-width__mobile">
                                <div className="leadlog__controls__lead-history__add-note-controls">
                                    <div>
                                        <span
                                            role="button"
                                            className="type-small-body type-single-line type-heavy"
                                            onClick={() => this.setState({
                                                note: '',
                                                noteTextAreaExpanded: false,
                                                privateNote: true
                                            })}
                                        >
                                            Cancel
                                        </span>
                                    </div>
                                    {this.props.adminView && (
                                        <div>
                                            <Checkbox
                                                name={`privateNote-${this.props.lead.lead_id}`}
                                                label="Private"
                                                onChange={(e) => this.setState({privateNote: e.target.checked})}
                                                checked={this.state.privateNote}
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <button className="button ui-small-button" onClick={this.submitNote}>
                                            Add Note
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="leadlog__controls__lead-history">
                        {noteJsx}
                    </div>
                </div>
            </div>
        </div>;
    }
})
